import { defineMessages } from 'react-intl';
export const templateMessages = defineMessages({
    templatePageTitle: {
        id: 'TemplatePageTitle',
        defaultMessage: 'Template',
        description: 'Title for page indicating this is the template page',
    },
    exportToSharedImageGallery: {
        id: 'ExportToSharedImageGallery',
        defaultMessage: 'Export to Azure Compute Gallery',
        description: 'Text for exporting the template to Azure Compute Gallery.',
    },
    publishTemplate: {
        id: 'PublishTemplate',
        defaultMessage: 'Publish',
        description: 'Text on command bar button to publish the template.',
    },
    templateUnpublishedTooltipText: {
        id: 'TemplateUnpublishedTooltipText',
        defaultMessage: 'An unpublished template is not visible to users in the lab. Publishing provides a copy for each user. The template can be published again after it is published once, but this will delete any changes made by the users to their copies.',
        description: 'Text on tooltip for info icon next to unpublished status',
    },
    templateUnpublishedStatus: {
        id: 'TemplateUnpublishedStatus',
        defaultMessage: 'Unpublished',
        description: 'Text to indicate template is is not published',
    },
    templateGpuDriverInstallationFailed: {
        id: 'TemplateGpuDriverInstallationFailed',
        defaultMessage: 'Installation of GPU drivers failed.',
        description: 'Text to indicate installation of GPU drivers failed.',
    },
    templateGpuDriverInstallationMissing: {
        id: 'TemplateGpuDriverInstallationMissing',
        defaultMessage: 'GPU drivers must be installed manually on the template virtual machine.',
        description: 'Text to indicate installation of GPU drivers need to be manually added.',
    },
    templateGpuDriverMoreInfo: {
        id: 'templateGpuDriverMoreInfo',
        defaultMessage: 'Please install the drivers manually.',
        description: 'Text of a link navigating to a page with more information.',
    },
    templateGpuDriverLearnMore: {
        id: 'templateGpuDriverLearnMore',
        defaultMessage: 'Learn more',
        description: 'Text of a link navigating to a page with more information.',
    },
    twentyMinutesFormatted: {
        id: 'twentyMinutesFormatted',
        defaultMessage: '20 minutes',
        description: 'Message to indicate 20 minutes',
    },
    oneHourFormatted: {
        id: 'OneHourFormatted',
        defaultMessage: '1 hour',
        description: 'Message to indicate 1 hour',
    },
});
export default templateMessages;
