import { Record } from 'immutable';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { CoreLimitActionType, } from '../../actions/core-limit/core-limit-actions';
import { Context } from '../../action-context';
function getCoreRestrictionsAndUsages(state, action) {
    return state.merge({
        loadState: state.get('loadState') === LoadingStoreState.Loaded
            ? LoadingStoreState.Updating
            : LoadingStoreState.Loading,
    });
}
function getCoreRestrictionsAndUsagesSuccess(state, action) {
    const { coreUsage } = action;
    return state.merge({
        loadState: LoadingStoreState.Loaded,
        coreUsage,
    });
}
function getCoreRestrictionsAndUsagesError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: state.get('loadState') === LoadingStoreState.Updating
            ? LoadingStoreState.UpdateFailed
            : LoadingStoreState.LoadingFailed,
        error,
    });
}
function getCoreRestrictionsAndUsagesCancelled(state, action) {
    return state.merge({
        loadState: state.get('loadState') === LoadingStoreState.Loading
            ? LoadingStoreState.NotStarted
            : LoadingStoreState.Loaded,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    coreUsage: {
        used: {
            standard: 0,
            gpu: 0,
        },
        alloted: {
            standard: 0,
            gpu: 0,
        },
    },
    error: undefined,
})();
export const coreUsageReducer = (state = initialState, action) => {
    switch (action.type) {
        case CoreLimitActionType.CLEAR_USER_SESSION:
        case CoreLimitActionType.SELECT_TENANT:
        case CoreLimitActionType.SELECT_LAB_PARENT_RESOURCE:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    switch (action.type) {
                        case CoreLimitActionType.SELECT_LAB:
                        case CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE:
                            return getCoreRestrictionsAndUsages(state, action);
                        case CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_SUCCESS:
                            return getCoreRestrictionsAndUsagesSuccess(state, action);
                        case CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_ERROR:
                            return getCoreRestrictionsAndUsagesError(state, action);
                        case CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_CANCELLED:
                            return getCoreRestrictionsAndUsagesCancelled(state, action);
                        default:
                            return state;
                    }
                default:
                    return state;
            }
    }
};
export default coreUsageReducer;
