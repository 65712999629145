import * as React from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter as Router } from 'connected-react-router/immutable';
import { Fabric } from '@fluentui/react/lib/Fabric';
import { App } from './app';
class Root extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (<Provider store={this.props.store}>
                <Router history={this.props.history}>
                    <Fabric>
                        <App />
                    </Fabric>
                </Router>
            </Provider>);
    }
}
export default Root;
