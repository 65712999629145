import { Stack, TextField, Toggle, Link, Shimmer } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import LinuxRdpDialog from '../../common/connection-type/linux-rdp-dialog';
import InfoTip from '../../common/info-tip';
import { OperatingSystem } from '../../data/models/environment-common';
import Constants, { DefaultCurrencyCode } from '../../utils/constants';
import { messages } from './create-lab-messages';
import { isGeographyAvailable, areAllGeosAvailable, getVCurrentSizeData, getVCurrentGeographyData, areAllVCurrentSizesAvailable, isVCurrentGpuSize, } from './create-lab-selectors';
import { validateLabName } from './lab-name-validator';
import { VmGeographyPicker } from './vm-geography-picker';
import { VmImagePicker } from './vm-image-picker';
import { VmSizePicker } from './vm-size-picker';
import { CoresNotAvailableMessage, LabConfigShimmer } from './create-lab-config-page-shared';
export const NewLabConfigPage = (props) => {
    const { name, validateNameOnLoad, image, size, geography, pricingAndAvailabilityData, coreQuotaData, images, labNames, labParentId, currentTenantId, canSelectGeography, isRdpEnabled, installGpuDriverEnabled, customizeTemplate, isBastionEnabled, addImagesUrl, isLoadingLabPlanImages, onNameChange, onNameValidation, onImageChange, onSizeChange, onGeographyChange, onRdpEnabledChange, onInstallGpuDriverEnabledChange, onCustomizeTemplateChange, } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const newLabConfigIntro = messages.newLabConfigPageIntro;
    const labNameLabel = msg(messages.nameLabel);
    const vmImagePickerLabel = msg(messages.imageLabel);
    const vmSizePickerLabel = msg(messages.sizeLabel);
    const vmGeographyPickerLabel = msg(messages.locationLabel);
    const [linuxRdpDialogAcknowledged, setLinuxRdpDialogAcknowledged] = React.useState(false);
    const [showLinuxRdpDialog, setShowLinuxRdpDialog] = React.useState(false);
    let sizes;
    let geographies;
    let shouldShowSizeGeoAvailabilityLink;
    if (!isLoadingLabPlanImages && !!image && !!pricingAndAvailabilityData) {
        sizes = getVCurrentSizeData(pricingAndAvailabilityData, image.isWindows ? OperatingSystem.Windows : OperatingSystem.Linux);
        geographies = size ? getVCurrentGeographyData(sizes, size) : {};
        shouldShowSizeGeoAvailabilityLink =
            !areAllGeosAvailable(geographies) || !areAllVCurrentSizesAvailable(sizes, coreQuotaData);
    }
    const handleSizeChange = (value) => {
        if (!pricingAndAvailabilityData) {
            return;
        }
        if (!!size) {
            if (value.sizeName === size.sizeName) {
                return;
            }
        }
        const sizeData = getVCurrentSizeData(pricingAndAvailabilityData, image.isWindows ? OperatingSystem.Windows : OperatingSystem.Linux);
        const geoData = getVCurrentGeographyData(sizeData, value);
        const geoNames = Object.keys(geoData)
            .map((key) => geoData[key])
            .filter((geo) => isGeographyAvailable(geo))
            .map((geo) => geo.geographyName);
        if (geoNames.length === 1) {
            const geo = geoData[geoNames[0]];
            onSizeChange(value, geo);
        }
        else {
            onSizeChange(value, geography);
        }
    };
    return (<>
            <Stack tokens={{ childrenGap: 30 }}>
                <Stack.Item>
                    <FormattedMessage {...newLabConfigIntro}/>
                    {shouldShowSizeGeoAvailabilityLink && (<div style={{ fontSize: '12px' }}>
                            <Link href={Constants.Urls.DisabledSizesGeo} styles={{ root: { textDecoration: 'underline' } }} target="_blank">
                                <FormattedMessage {...messages.sizesAndGeoNotAvailableCreateLabLinkText}/>
                            </Link>
                        </div>)}
                    {!!sizes && (<CoresNotAvailableMessage labParentId={labParentId} currentTenantId={currentTenantId} sizes={sizes} coreQuotaData={coreQuotaData}/>)}
                </Stack.Item>
                <Stack.Item>
                    <TextField label={labNameLabel} placeholder={msg(messages.namePlaceholder)} autoFocus={true} spellCheck={false} maxLength={100} value={name} validateOnLoad={validateNameOnLoad} onChange={(_, value) => onNameChange(value || '')} required={true} aria-required={true} onGetErrorMessage={(value) => {
        // trimming for validation name so we compare correctly against the list of lab
        // names that already exist
        const result = validateLabName(value.trim(), labNames, intl);
        onNameValidation(!result);
        return result || '';
    }}/>
                </Stack.Item>
                <Shimmer isDataLoaded={!isLoadingLabPlanImages} customElementsGroup={LabConfigShimmer} width="100%">
                    {!!sizes && !!geographies && (<Stack tokens={{ childrenGap: 30 }}>
                            <Stack.Item>
                                <VmImagePicker label={vmImagePickerLabel} searchPlaceholder={msg(messages.searchImages)} options={images} selectedKey={image.key} required={true} addImagesUrl={addImagesUrl} onChange={onImageChange}/>
                            </Stack.Item>
                            {props.isSkipTemplateEnabled && (<Stack.Item>
                                    <Stack horizontal styles={{ root: { marginTop: '15px !important' } }}>
                                        <Stack.Item>
                                            <Toggle label={msg(messages.customizeImageLabel)} ariaLabel={msg(messages.customizeImageLabel)} inlineLabel={true} checked={customizeTemplate} onChange={(_, value) => onCustomizeTemplateChange(value || false)}/>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <InfoTip content={msg(messages.customizeImageDescription)} styles={{ root: { paddingTop: '7px' } }}/>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>)}
                            <Stack.Item>
                                <Stack>
                                    <Stack.Item>
                                        <VmSizePicker label={vmSizePickerLabel} data={sizes} coreQuotaData={coreQuotaData} selected={size} required={true} onChange={handleSizeChange}/>
                                    </Stack.Item>
                                    {size && isVCurrentGpuSize(size) && (<Stack.Item>
                                            <Stack horizontal styles={{ root: { paddingTop: '15px' } }}>
                                                <Stack.Item>
                                                    <Toggle label={msg(messages.gpuLabel)} inlineLabel={true} checked={installGpuDriverEnabled} onChange={(_, value) => onInstallGpuDriverEnabledChange(value || false)}/>
                                                </Stack.Item>
                                                <Stack.Item styles={{ root: { paddingTop: '7px' } }}>
                                                    <InfoTip content={msg(messages.gpuDriverTooltipText)} styles={{ root: { paddingTop: '7px' } }}/>
                                                </Stack.Item>
                                            </Stack>
                                            {!installGpuDriverEnabled && (<Stack.Item>
                                                    <FormattedMessage {...messages.newLabConfigPageGpuOptOutText}/>
                                                </Stack.Item>)}
                                        </Stack.Item>)}
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack>
                                    {canSelectGeography && (<Stack.Item>
                                            <VmGeographyPicker label={vmGeographyPickerLabel} data={geographies} selected={geography} required={true} onChange={onGeographyChange}/>
                                        </Stack.Item>)}
                                </Stack>
                            </Stack.Item>
                            {!image.isWindows && !isBastionEnabled && (<Stack.Item>
                                    <Toggle label={msg(messages.rdpLabel)} inlineLabel={true} checked={isRdpEnabled} onChange={(_, value) => !linuxRdpDialogAcknowledged && !isRdpEnabled
        ? setShowLinuxRdpDialog(true)
        : onRdpEnabledChange(value || false)}/>
                                </Stack.Item>)}
                            <Stack.Item>
                                <span id="totalPice" style={{ backgroundColor: '#F3F2F1', fontWeight: 600, padding: 8 }}>
                                    <FormattedMessage {...messages.newLabConfigPageTotalPriceFormat} values={{
        price: (<FormattedNumber value={geography ? geography.price : 0} style="currency" currency={geography ? geography.currencyCode : DefaultCurrencyCode}/>),
    }}/>
                                </span>
                            </Stack.Item>
                        </Stack>)}
                </Shimmer>
            </Stack>
            {showLinuxRdpDialog && (<LinuxRdpDialog onSubmit={() => {
        setLinuxRdpDialogAcknowledged(true);
        onRdpEnabledChange(true);
        setShowLinuxRdpDialog(false);
    }} onDismiss={() => setShowLinuxRdpDialog(false)}/>)}
        </>);
};
export default NewLabConfigPage;
