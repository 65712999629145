import Constants from '../../utils/constants';
import { ResourceId } from '../../utils/resource-id';
import { VmState } from '../models/environment-common';
import { ProvisioningState } from '../models/common';
import { getMlClient } from './arm-data-provider';
import { processPowerState, processEnvironmentState, processProvisioningState, processVmState, processOperationSystem, } from '../helpers/environment-helpers';
function _convertEnvironmentFromListAllEnvironmentDetails(serverEnvironment) {
    const { lastKnownPowerState: powerState, environmentState: envState, id, name, description, latestOperationResult, passwordLastReset, provisioningState: provState, virtualMachineDetails, usageQuota, totalUsage, runningInSchedule, sharedPasswordEnabled, osType, supportInfo, } = serverEnvironment;
    const lastKnownPowerState = processPowerState(powerState);
    const environmentState = processEnvironmentState(envState);
    const provisioningState = processProvisioningState(provState);
    const vmState = processVmState(lastKnownPowerState, environmentState, latestOperationResult);
    const vmProvisioningState = virtualMachineDetails
        ? processProvisioningState(virtualMachineDetails.provisioningState)
        : ProvisioningState.None;
    const os = processOperationSystem(osType);
    return {
        id: id || '',
        name,
        description,
        lastKnownPowerState,
        latestOperationResult,
        useSharedPassword: sharedPasswordEnabled,
        passwordLastReset,
        provisioningState,
        vmState,
        pendingVmState: VmState.None,
        vmUsername: virtualMachineDetails ? virtualMachineDetails.userName : undefined,
        vmSshAuthority: virtualMachineDetails ? virtualMachineDetails.sshAuthority : undefined,
        vmSshBrowserUrl: virtualMachineDetails ? virtualMachineDetails.sshInBrowserUrl : undefined,
        vmRdpAuthority: virtualMachineDetails ? virtualMachineDetails.rdpAuthority : undefined,
        vmRdpBrowserUrl: virtualMachineDetails ? virtualMachineDetails.rdpInBrowserUrl : undefined,
        vmProvisioningState,
        usageQuota,
        totalUsage,
        environmentState,
        runningInSchedule: !!runningInSchedule,
        os,
        supportInfo,
    };
}
export async function listEnvironments(accessToken, locale, language, aadGroupId) {
    const mlClient = getMlClient(Constants.NoSubscriptionId, accessToken, locale, language);
    const options = {
        customHeaders: !!aadGroupId ? { 'x-ml-aad-group-id': aadGroupId } : undefined,
    };
    const response = await mlClient.globalUsers.listAllEnvironments(Constants.NoUsername, options);
    if (!response || !response.environments) {
        return [];
    }
    const environments = response.environments.map((environment) => _convertEnvironmentFromListAllEnvironmentDetails(environment));
    return environments;
}
export async function start(environmentId, accessToken, locale, language) {
    const mlClient = getMlClient(Constants.NoSubscriptionId, accessToken, locale, language);
    await mlClient.globalUsers.beginStartEnvironment(Constants.NoUsername, {
        environmentId,
    });
}
export async function stop(environmentId, accessToken, locale, language) {
    const mlClient = getMlClient(Constants.NoSubscriptionId, accessToken, locale, language);
    await mlClient.globalUsers.beginStopEnvironment(Constants.NoUsername, {
        environmentId,
    });
}
export async function resetPassword(environmentId, username, password, accessToken, locale, language) {
    const mlClient = getMlClient(Constants.NoSubscriptionId, accessToken, locale, language);
    const resourceId = new ResourceId(environmentId);
    await mlClient.globalUsers.beginResetPassword(resourceId.name, {
        environmentId,
        username: username.trim(),
        password: password.trim(),
    });
}
const UserEnvironmentProvider = {
    listEnvironments,
    start,
    stop,
    resetPassword,
};
export default UserEnvironmentProvider;
