export function getSshConnectData(sshAuthority) {
    const sshParts = sshAuthority.split(':');
    const port = sshParts[1];
    const url = sshParts[0];
    return { port, url };
}
export function getSshCommand(port, username, url) {
    return `ssh -p ${port} ${username}@${url}`;
}
const invalidFilenameChars = /["<>:*?\\\/\|]|[\u0000-\u001F]/g;
export function getRdpConnectData(rdpAuthority, isWindows, username, title) {
    const fileContent = `full address:s:${rdpAuthority}\nprompt for credentials:i:1\nusername:s:${isWindows ? '~\\' : ''}${username}`;
    const safeTitle = (title ?? '').replace(invalidFilenameChars, '') || 'connect';
    const fileName = `${safeTitle}.rdp`;
    return { fileContent, fileName };
}
const VmConnectUtilities = {
    getSshConnectData,
    getRdpConnectData,
    getSshCommand,
};
export default VmConnectUtilities;
