import { fork, takeLatest, select } from 'redux-saga/effects';
import { isVNextFeatureEnabledOrLms } from '../selectors/common-selectors';
function featureBasedTaskHelper(vCurrentWorker, vNextWorker) {
    return function* (action) {
        const isVNext = yield select(isVNextFeatureEnabledOrLms);
        if (isVNext && !!vNextWorker) {
            yield fork(vNextWorker, action);
        }
        else if (!!vCurrentWorker) {
            yield fork(vCurrentWorker, action);
        }
    };
}
//todo: figure out the correct type for the parameters so that we do not need to cast the worker type to any when calling featureBasedTakeLeast
export function featureBasedTakeLatest(pattern, vCurrentWorker, vNextWorker) {
    const helper = featureBasedTaskHelper(vCurrentWorker, vNextWorker);
    return takeLatest(pattern, helper);
}
