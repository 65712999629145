import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { Announced, Link, MessageBarType, Stack, CommandBar, MessageBar } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import RdpInfoDialog from '../common/connect-to-vm/rdp-info-dialog';
import ConnectToVmDialog from '../common/connect-to-vm/ssh-connect-dialog';
import { LoadingContainer } from '../common/loading-section';
import ResetPasswordDialog from '../common/reset-password-dialog/reset-password-dialog';
import TroubleshootDialog from '../common/troubleshoot-dialog/troubleshoot-dialog';
import { getCustomTheme } from '../common/themes/selectors';
import VmPowerState from '../common/vm/vm-power-state';
import { LabErrorBanner } from '../lab/lab-error-banner';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import { Urls } from '../utils/constants';
import { isHealthyState } from '../utils/provisioning-state';
import DismissGpuBannerDialog from './dismiss-gpu-banner-dialog';
import ExportSigDialog from './export-sig-dialog';
import OperationIndicator from './operation-indicator';
import PublishTemplateDialog from './publish-template-dialog';
import QuotaBanner from './quota-banners';
import StartWarningTemplateDialog from './start-warning-dialog';
import { GpuDriverBanner, PublishSuccessBanner, TemplateErrorBanners } from './template-banners';
import TemplateCommandBar from './template-command-bar';
import { TemplateErrorPage } from './template-error-page';
import { TemplateDescriptionField, TemplateOsInfo, TemplateSizeInfo, TemplateTitleField } from './template-fields';
import templateMessages from './template-messages';
import { getTemplateVmState } from './template-selectors';
import { VmState } from '../data/models/environment-common';
import ResetVmsDialog from '../vm-list/reset-vms-dialog';
import { isVNextLab } from '../redux/selectors/lab-selectors';
import { OperationStatus, OperationType } from '../data/models/vnext/operation';
import { messages as VmListMessages } from '../vm-list/vm-list-messages';
import { mapResourceErrorToOperation } from '../data/models/vnext/operation';
import './template.css';
export const Template = (props) => {
    const { templateViewModel, getTemplateWithExpand, publishTemplate, updateTitleOrDescription, resetPassword, startTemplate, stopTemplate, reimageTemplate, createSharedImage, updateSharedImage, clearError, clearLoadError, clearLabUpdateError, clearPublishExceedsCoresError, dismissExportStartedMessageBar, redeployTemplate, navigationItems, } = props;
    const { template, lab, dismissedGpuBanner, sharedGallery, coreQuotaData, isReadOnly, currentTenantId, sharedImages, isDismissing, isGroupSyncModeEnabled, groupName, errors, labUpdateError, publishExceedsCoresError, isSharedImagesLoading, isBastionEnabled, locale, isLoading, loadError, shouldDisableLabUpdate, labPlanSharedGalleryId, shouldShowExportStartedMessage, exportedImageName, vNextPublishError, } = templateViewModel;
    const intl = useIntl();
    const [templateTitle, setTemplateTitle] = React.useState(template ? template.title : '');
    const [templateDescription, setTemplateDescription] = React.useState(template ? template.description : '');
    const [showResetPassword, setShowResetPassword] = React.useState(false);
    const [showExportSig, setShowExportSig] = React.useState(false);
    const [showPublish, setShowPublish] = React.useState(false);
    const [showCustomizeWarning, setShowCustomizeWarning] = React.useState(false);
    const [showSshConnectDialog, setShowSshConnectDialog] = React.useState(false);
    const [showPublishSuccessBanner, setShowPublishSuccessBanner] = React.useState(false);
    const [isTitleValid, setIsTitleValid] = React.useState(true);
    const [isDescriptionValid, setIsDescriptionValid] = React.useState(true);
    const [showDismissDialog, setShowDismissDialog] = React.useState(false);
    const [showRdpInfoDialog, setShowRdpInfoDialog] = React.useState(false);
    const [showTroubleshootDialog, setShowTroubleshoot] = React.useState(false);
    const [showReimageTemplateDialog, setShowReimageTemplateDialog] = React.useState(false);
    const isReimaging = templateViewModel.template?.isReimaging;
    const [announce, setAnnounce] = React.useState(null);
    const { pageTitleStyle, secondaryBackgroundColor, primaryCommandBarStyles, messageBarStyles } = getCustomTheme();
    const vmOperation = templateViewModel.operations?.find((o) => o.ResourceId?.id == template?.id) || mapResourceErrorToOperation(templateViewModel.resourceOperationError);
    const vmOperationStatus = vmOperation?.status;
    const vmOperationType = vmOperation?.operationType || OperationType.Unknown;
    const VmOperationError = templateViewModel.resourceOperationError;
    const templateVirtualMachine = intl.formatMessage(templateMessages.templatePageTitle);
    React.useEffect(() => {
        if (template && !template.isSaving) {
            setTemplateTitle(template.title);
            setTemplateDescription(template.description);
        }
    }, [template]);
    const previousTemplate = usePrevious(template);
    React.useEffect(() => {
        if (template &&
            previousTemplate &&
            previousTemplate.isPublishing &&
            !template.isPublishing &&
            template.isPublished &&
            !vNextPublishError) {
            setShowPublishSuccessBanner(true);
        }
    }, [template]);
    if (isLoading) {
        return (<div id="template-container" style={{ backgroundColor: secondaryBackgroundColor }}>
                <div id="template-content">
                    {navigationItems && (<div id="template-header">
                            <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                        </div>)}
                    <LoadingContainer />
                </div>
            </div>);
    }
    const isVNext = isVNextLab(lab?.id);
    if (!template ||
        ((!isVNext || (isVNext && template.hasTemplateVm)) && !isHealthyState(template.provisioningState))) {
        return (<div id="template-container" style={{ backgroundColor: secondaryBackgroundColor }}>
                <div id="template-content">
                    {navigationItems && (<div id="template-header">
                            <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                        </div>)}
                    <TemplateErrorPage template={template}/>
                </div>
            </div>);
    }
    const onStartTemplate = () => {
        if (template.id) {
            if (!template.isPublished || template.hasEdits) {
                const templateStartAnnounce = (<Announced message={intl.formatMessage(commonMessages.startingState)} aria-live="assertive"/>);
                setAnnounce(templateStartAnnounce);
                startTemplate(template.id);
            }
            else {
                setShowCustomizeWarning(true);
            }
        }
    };
    const onConfirmedStartTemplate = () => {
        if (template.id) {
            const templateStartAnnounce = (<Announced message={intl.formatMessage(commonMessages.startingState)} aria-live="assertive"/>);
            setAnnounce(templateStartAnnounce);
            startTemplate(template.id);
        }
    };
    const onStopTemplate = () => {
        if (template.id) {
            const templateStopAnnounce = (<Announced message={intl.formatMessage(commonMessages.stoppingState)} aria-live="assertive"/>);
            setAnnounce(templateStopAnnounce);
            stopTemplate(template.id);
        }
    };
    const onRedeployTemplate = () => {
        if (template.id) {
            const templateRedeployAnnounce = (<Announced message={intl.formatMessage(commonMessages.redeployingState)} aria-live="assertive"/>);
            setAnnounce(templateRedeployAnnounce);
            redeployTemplate(template.id);
        }
    };
    const onReimageTemplate = () => {
        if (template.id) {
            const templateReimageAnnounce = (<Announced message={intl.formatMessage(commonMessages.reimagingState)} aria-live="assertive"/>);
            setAnnounce(templateReimageAnnounce);
            setShowReimageTemplateDialog(false);
            reimageTemplate(template.id);
        }
    };
    let exportedImageLink = '';
    if (labPlanSharedGalleryId) {
        exportedImageLink = Urls.ExportedImageLink(currentTenantId, labPlanSharedGalleryId);
        if (exportedImageName) {
            exportedImageLink += `/${exportedImageName}/overview`;
        }
    }
    return (<>
            <div id="template-container" style={{ backgroundColor: secondaryBackgroundColor }}>
                <div id="template-content">
                    <div id="template-header">
                        <LabErrorBanner updateError={labUpdateError} clearUpdateError={clearLabUpdateError}/>
                        {(errors.size > 0 || loadError || publishExceedsCoresError) && (<TemplateErrorBanners labId={lab?.id} errors={errors} loadError={loadError} publishExceedsCoresError={publishExceedsCoresError} clearError={clearError} clearLoadError={clearLoadError} clearPublishExceedsCoresError={clearPublishExceedsCoresError} currentTenantId={currentTenantId}/>)}
                        {!!shouldShowExportStartedMessage && (<MessageBar dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)} onDismiss={dismissExportStartedMessageBar}>
                                <FormattedMessage id="ExportImageStarted" defaultMessage="Export has been started. You can track the progress in the <Link>Azure portal</Link>. It may take a few minutes for the resource to appear." description="Message shown when kicking off image exporting with link to Azure portal to see progress." values={{
        Link: (chunks) => (<Link href={exportedImageLink} target="_blank">
                                                {chunks}
                                            </Link>),
    }}/>
                            </MessageBar>)}
                        {showPublishSuccessBanner && (<PublishSuccessBanner onDismiss={() => setShowPublishSuccessBanner(false)}/>)}
                        {!dismissedGpuBanner && (<>
                                {template.gpuDriverState == Ml.GpuDriverState.Failed && (<GpuDriverBanner onDismiss={!isReadOnly ? () => setShowDismissDialog(true) : undefined} messageBarType={MessageBarType.error} isWindows={template.isWindows} message={templateMessages.templateGpuDriverInstallationFailed} linkText={templateMessages.templateGpuDriverMoreInfo} styles={messageBarStyles}/>)}
                                {template.gpuDriverState == Ml.GpuDriverState.Missing && (<GpuDriverBanner onDismiss={!isReadOnly ? () => setShowDismissDialog(true) : undefined} messageBarType={MessageBarType.warning} isWindows={template.isWindows} message={templateMessages.templateGpuDriverInstallationMissing} linkText={templateMessages.templateGpuDriverLearnMore} styles={messageBarStyles}/>)}
                            </>)}
                        <QuotaBanner lab={lab} template={template} coreQuotaData={coreQuotaData} currentTenantId={currentTenantId} isGroupSyncModeEnabled={isGroupSyncModeEnabled} styles={messageBarStyles}/>
                        <TemplateCommandBar template={template} sharedGallery={sharedGallery} isBastionEnabled={isBastionEnabled} isReadOnly={isReadOnly} isGroupSyncModeEnabled={isGroupSyncModeEnabled} coreQuotaData={coreQuotaData} lab={lab} shouldDisableLabUpdate={shouldDisableLabUpdate} navigationItems={navigationItems} labPlanSharedGalleryId={labPlanSharedGalleryId} onStop={onStopTemplate} onStart={onConfirmedStartTemplate} onRedeploy={onRedeployTemplate} onOpenResetPasswordDialog={() => setShowResetPassword(true)} onOpenPublishDialog={() => setShowPublish(true)} onOpenExportSigDialog={() => setShowExportSig(true)} onOpenCustomizeWarningDialog={() => setShowCustomizeWarning(true)} onShowRdpInfoDialog={() => setShowRdpInfoDialog(true)} showSshConnectDialog={() => setShowSshConnectDialog(true)} getTemplateWithExpand={() => getTemplateWithExpand(template.id, true)} onOpenTroubleshootDialog={() => setShowTroubleshoot(true)} onReimageTemplateDialog={() => setShowReimageTemplateDialog(true)}/>
                        <Stack>
                            {vmOperationStatus && vmOperationStatus == OperationStatus.Failed && (<MessageBar messageBarType={MessageBarType.error}>
                                <FormattedMessage id="VmOperationErrorBannerGenericMessageTemplate" defaultMessage="Virtual machine {Operation} operation failed. Try {redeploying} the virtual machine. If the issue persists, consider {reimaging} it. For further assistance, submit a support request." description="Message displayed on a student VM card when the VM is under maintenance and can't be accessed." values={{
        redeploying: (<Link onClick={() => setShowTroubleshoot(true)}>
                                            <FormattedMessage id="VmOperationErrorBannerRedeployTemplate" defaultMessage="redeploying" description="clickable action for redeploying the VM in the error message."/>
                                        </Link>),
        reimaging: (<Link onClick={() => setShowReimageTemplateDialog(true)}>
                                            <FormattedMessage id="VmOperationErrorBannerReimageTemplate" defaultMessage='reimaging' description="clickable action for reimaging the VM in the error message."/>
                                        </Link>),
        Operation: vmOperationType === OperationType.StartOperation
            ? intl.formatMessage(VmListMessages.StartOperation)
            : vmOperationType === OperationType.StopOperation
                ? intl.formatMessage(VmListMessages.StopOperation)
                : vmOperationType === OperationType.ReimageOperation
                    ? intl.formatMessage(VmListMessages.ReimageOperation)
                    : vmOperationType === OperationType.RedeployOperation
                        ? intl.formatMessage(VmListMessages.RedeployOperation)
                        : '',
    }}/>
                                </MessageBar>)}
                            {VmOperationError && VmOperationError.code == "HeartbeatNotReceived" && (<MessageBar messageBarType={MessageBarType.warning}>
                                <FormattedMessage id="VmHeartbeatErrorTemplate" defaultMessage="Your cloud virtual machine has become non-responsive and will be stopped automatically to avoid overbilling. You may want to refer to the {TroubleshootingGuide} to resolve the issue." description="The error message displayed on message bar for template virtual machine when VM heartbeat was not received." values={{
        TroubleshootingGuide: (<Link target="_blank" style={{ paddingLeft: '0px' }} href={"https://learn.microsoft.com/en-us/azure/lab-services/troubleshoot-access-lab-vm"}>
                                                <FormattedMessage id="templateTroubleshootingGuideLink" defaultMessage="troubleshooting guidelines" description="a link to the vm issues troubleshooting guidelines page."/>
                                        </Link>)
    }}/>
                                </MessageBar>)}
                            </Stack>
                        <Stack horizontal verticalAlign="center" horizontalAlign="space-between" styles={{ root: { maxWidth: '828px' } }}>
                            <Stack.Item className="template__page-header">
                                <h1 style={pageTitleStyle}>{templateVirtualMachine}</h1>
                            </Stack.Item>
                            {template.hasTemplateVm && (<>
                                    {announce}
                                    <Stack.Item className="template__power-state-indicator--container">
                                        <VmPowerState vmState={template.isReimaging ? VmState.Reimaging : getTemplateVmState(template)} forceDisable={isReadOnly ||
        template.isCreating ||
        template.isResettingPassword ||
        template.isScaling ||
        template.isPublishing ||
        template.isReimaging} onStart={onStartTemplate} onStop={onStopTemplate}/>
                                    </Stack.Item>
                                </>)}
                        </Stack>   
                    </div>
                    <div id="template-body" className="vertical-scrollable-content">
                        <div className="template__card-wrapper">
                            <div className="template__card ms-bgColor-white">
                                <Stack horizontal horizontalAlign="space-between">
                                    <Stack.Item>
                                        <Stack horizontal tokens={{ childrenGap: '20px' }}>
                                            <Stack.Item>
                                                <TemplateOsInfo isWindows={template.isWindows}/>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <TemplateSizeInfo template={template}/>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <OperationIndicator template={template} locale={locale} groupName={groupName}/>
                                    </Stack.Item>
                                </Stack>
                                <div className="template__card-text-field-wrapper">
                                    <TemplateTitleField templateTitle={templateTitle} template={template} isTitleValid={isTitleValid} setTemplateTitle={setTemplateTitle} setIsTitleValid={setIsTitleValid} updateTitleOrDescription={updateTitleOrDescription} isReadOnly={isReadOnly || !!shouldDisableLabUpdate || !!isReimaging}/>
                                    <TemplateDescriptionField templateDescription={templateDescription} template={template} isDescriptionValid={isDescriptionValid} setTemplateDescription={setTemplateDescription} setIsDescriptionValid={setIsDescriptionValid} updateTitleOrDescription={updateTitleOrDescription} isReadOnly={isReadOnly || !!shouldDisableLabUpdate || !!isReimaging}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showCustomizeWarning && (<StartWarningTemplateDialog startTemplate={onConfirmedStartTemplate} onDismiss={() => setShowCustomizeWarning(false)}/>)}
            {showResetPassword && (<ResetPasswordDialog username={template.username} canShowNonLocalizedMessages={true} id={template && template.id} isResetting={true} isWindows={template.isWindows} onDismiss={() => setShowResetPassword(false)} onDismissed={() => setShowResetPassword(false)} onSubmit={(id, username, password) => resetPassword(id, username, password)}/>)}
            {showExportSig && (<ExportSigDialog sharedImages={sharedImages} sharedImage={template.sharedImage} isLoading={isSharedImagesLoading} templateId={template && template.id} onDismiss={() => setShowExportSig(false)} onCreate={createSharedImage} onUpdate={updateSharedImage}/>)}
            {showPublish && (<PublishTemplateDialog lab={lab} currentTenantId={currentTenantId} coreQuotaData={coreQuotaData} isGpu={template.isGpu} coresPerVm={template.cores} hasBeenPublished={template.isPublished} templateId={template.id} onDismiss={() => setShowPublish(false)} publishTemplate={publishTemplate} isGroupSyncModeEnabled={isGroupSyncModeEnabled} groupName={groupName}/>)}
            {showSshConnectDialog && (<ConnectToVmDialog sshCommand={template.sshCommand} onDismiss={() => setShowSshConnectDialog(false)} onDismissed={() => setShowSshConnectDialog(false)}/>)}
            {showDismissDialog && (<DismissGpuBannerDialog isSubmitting={isDismissing} onDismiss={() => setShowDismissDialog(false)} onSubmit={() => props.acknowledgeGpuDriverWarning()}/>)}
            {showRdpInfoDialog && (<RdpInfoDialog rdpInfo={template.rdpAuthority ?? ''} onDismiss={() => setShowRdpInfoDialog(false)}/>)}
            {showTroubleshootDialog && (<TroubleshootDialog onDismiss={() => setShowTroubleshoot(false)} onRedeploy={() => onRedeployTemplate()}/>)}

            {showReimageTemplateDialog && (<ResetVmsDialog numberOfMachines={1} isStudentVM={false} onSubmit={() => onReimageTemplate()} onDismiss={() => setShowReimageTemplateDialog(false)}/>)}
        </>);
};
export default Template;
