import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as History from 'history';
import './data/providers/msal-shim';
import Root from './root';
import * as serviceWorker from './serviceWorker';
import { init as initMocks } from './mocks/mocks';
import { configureStore } from './redux/configureStore';
import { init as initMsal, isMsalIframe, isMsalPopup } from './data/providers/msal-provider';
import { setStore, trackTrace } from './utils/telemetry/telemetry-channel';
import { init as initStorage } from './utils/storage';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { initializeIcons as initializeExtraIcons } from './images/icons/src';
import { initLocaleData } from './language/languages';
import './polyfills';
import './index.css';
async function init() {
    await initStorage();
    // we don't want to do anything when in an MSAL iframe or pop up, just let MSAL do it's thing
    if (isMsalIframe() || isMsalPopup()) {
        initMsal();
        trackTrace('init: isMsalIframeOrPopup = true');
        return;
    }
    initMocks();
    // loads all Office UI Fabric icons for use throughout the app
    initializeIcons();
    initializeExtraIcons();
    // initializes our language data
    initLocaleData();
    const rootElement = document.getElementById('root');
    const history = History.createBrowserHistory();
    const store = configureStore(history);
    setStore(store);
    ReactDOM.render(<Root store={store} history={history}/>, rootElement);
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
}
init();
