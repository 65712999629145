import * as React from 'react';
import { connect } from 'react-redux';
import { AuthenticationState } from '../data/models/identity';
import { SignInReturnPage } from './sign-in-return-page';
import { getSearch, isMfaReturn } from '../redux/selectors/route-selector';
import { resetShowRetirementAlert } from '../redux/actions/identity/identity-action-creators';
class SignInReturnPageContainerInjected extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        // ****** IMPORTANT ******Keep the flag as false until we're ready to show the retirement alert******
        const shouldShowRetirementAlert = true;
        if (shouldShowRetirementAlert) {
            this.props.resetShowRetirementAlert();
        }
    }
    render() {
        const { isAuthenticated, search, returnUrl, isMfaReturnUrl } = this.props;
        return (<SignInReturnPage isAuthenticated={isAuthenticated} search={search} returnUrl={returnUrl} isMfaReturnUrl={isMfaReturnUrl}/>);
    }
}
const mapStateToProps = (state) => {
    const identityStore = state.get('identityStore');
    const identityState = identityStore.get('state');
    const returnUrl = identityStore.get('returnUrl');
    const isAuthenticated = identityState === AuthenticationState.Authenticated;
    const search = getSearch(state);
    const isMfaReturnUrl = isMfaReturn(state);
    return {
        isAuthenticated,
        search,
        returnUrl,
        isMfaReturnUrl,
    };
};
const mapDispatchToProps = {
    resetShowRetirementAlert,
};
export const SignInReturnPageContainer = connect(mapStateToProps, mapDispatchToProps)(SignInReturnPageContainerInjected);
export default SignInReturnPageContainer;
