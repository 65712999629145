import moment from 'moment-timezone';
import memoize from 'memoize-one';
import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import PowerState from '../utils/power-state';
import { ResourceId } from '../utils/resource-id';
import { getSshConnectData, getSshCommand as getSshConnectionCommand } from '../common/connect-to-vm/connect-utilities';
import { ProvisioningState } from '../utils/provisioning-state';
import { VmState } from '../data/models/environment-common';
import { getConnectionTypes, getVNextConnectionTypes } from '../common/selectors/vm-connect';
import { DefaultCurrencyCode } from '../utils/constants';
import { getCores, getIsGpu, getRdpAuthority, getSshAuthority, isTemplateCreating, isTemplatePublished, isTemplatePublishing, isTemplateResettingPassword, isTemplateScaling, isTemplateStarting, isTemplateStopping, } from '../redux/selectors/template-selectors';
import { getCurrentLab, isLabGroupSynced, isCurrentLabReadOnly } from '../redux/selectors/lab-selectors';
import { getLabPricingAndAvailabilityData, getLabPricingAndAvailabilityLoadState, } from '../redux/selectors/pricing-and-availability-selectors';
import { getGroupName, isLoadingGroupName } from '../redux/selectors/group-selectors';
import { getLocale, isBastionFeatureEnabled, isTeamsOrLmsMode, storeIsLoading, } from '../redux/selectors/common-selectors';
import { getSearch } from '../redux/selectors/route-selector';
import { getTenantId } from '../redux/selectors/identity-selector';
import { isCurrentLabParentLabAccount } from '../redux/selectors/lab-parent-resource-selectors';
import { getCurrentLabImage, getVNextSharedImages } from '../redux/selectors/vnext/image-selectors';
import { getTemplateErrors, getTemplateVM } from '../redux/selectors/vnext/virtual-machine-selectors';
import { caseInsensitiveCultureInvariantCompare } from '../utils/string-comparison';
import { LabServicesModels } from 'lab-services';
import { convertVNextVirtualMachineStateToVmState } from '../vm-list/vm-list-selectors';
import { getCurrentLabErrors, getCurrentLabUpdateError, getLabErrorsByOperation, getVNextLabGroupName, hasTemplateVM, isLoadingLabMetadata, shouldDisableCurrentLabUpdate, } from '../redux/selectors/vnext/lab-selectors';
import { getLabPlan, getLabPlans } from '../redux/selectors/vnext/lab-plan-selectors';
import { FailureOperation } from '../data/ml-client-error';
import { getCurrentLabSkuInfo } from '../redux/selectors/vnext/sku-selectors';
import { getCurrentLabCoreUsageInfo, getVNextUsageLoadState } from '../redux/selectors/vnext/usage-selectors';
function getImage(template) {
    const galleryImage = template && template.resourceSettings && template.resourceSettings.imageName;
    const sigImage = template &&
        template.resourceSettings &&
        template.resourceSettings.sharedImageResourceId &&
        new ResourceId(template.resourceSettings.sharedImageResourceId).name;
    return galleryImage || sigImage;
}
function getMemorySize(sizeData) {
    return sizeData ? sizeData.size.memoryInGb : 0;
}
function getPrice(sizeData) {
    return sizeData ? sizeData.price : undefined;
}
function getCurrencyCode(sizeData) {
    return sizeData ? sizeData.currencyCode : DefaultCurrencyCode;
}
function getSize(sizeData) {
    return sizeData ? sizeData.size.localizedDisplayName : undefined;
}
function getOsType(template) {
    return template && template.resourceSettings && template.resourceSettings.osType;
}
function getGpuDriverState(template) {
    return template && template.resourceSettings && template.resourceSettings.gpuDriverState;
}
function getUsername(template) {
    return (template &&
        template.resourceSettings &&
        template.resourceSettings.referenceVm &&
        template.resourceSettings.referenceVm.userName);
}
function getSshCommand(sshAuthority, username) {
    if (sshAuthority) {
        const { port, url } = getSshConnectData(sshAuthority);
        return getSshConnectionCommand(port, username, url);
    }
}
export function getPowerState(template) {
    return (template &&
        template.resourceSettings &&
        template.resourceSettings.referenceVm &&
        template.resourceSettings.referenceVm.vmStateDetails &&
        template.resourceSettings.referenceVm.vmStateDetails.lastKnownPowerState);
}
export function getRdpInBrowserUrl(template) {
    return (template &&
        template.resourceSettings &&
        template.resourceSettings.referenceVm &&
        template.resourceSettings.referenceVm.vmStateDetails &&
        template.resourceSettings.referenceVm.vmStateDetails.rdpInBrowserUrl);
}
export function getSshInBrowserUrl(template) {
    return (template &&
        template.resourceSettings &&
        template.resourceSettings.referenceVm &&
        template.resourceSettings.referenceVm.vmStateDetails &&
        template.resourceSettings.referenceVm.vmStateDetails.sshInBrowserUrl);
}
export function getIsTemplateVmPresent(template) {
    return template && template.hasTemplateVm ? template.hasTemplateVm : false;
}
export function getTemplateVmState(template) {
    if (template.isCreating) {
        return VmState.Creating;
    }
    else if (template.isStarting) {
        return VmState.Starting;
    }
    else if (template.isStopping) {
        return VmState.Stopping;
    }
    else if (template.isRunning) {
        return VmState.Running;
    }
    else if (template.isRedeploying) {
        return VmState.Redeploying;
    }
    else {
        return VmState.Stopped;
    }
}
function isTemplateLinuxRdpEnabled(template) {
    return template && template.resourceSettings && template.resourceSettings.linuxRdpEnabled
        ? template.resourceSettings.linuxRdpEnabled === Ml.EnableState.Enabled
        : false;
}
export function isSharedGalleryValid(sharedGallery) {
    return sharedGallery && sharedGallery.provisioningState
        ? sharedGallery.provisioningState === ProvisioningState.Succeeded
        : false;
}
export function isUploadStateFailed(template) {
    return template ? template.uploadState === Ml.UploadState.Failed : false;
}
function hasNewEdits(template) {
    if (!template) {
        return false;
    }
    const correctedLastPublished = getLastPublishedDateCorrected(template.lastPublished);
    return template.lastChanged && correctedLastPublished ? template.lastChanged > correctedLastPublished : false;
}
function getSharedImage(template, sharedImages) {
    if (sharedImages &&
        template &&
        template.resourceSettings &&
        template.resourceSettings.sharedImageResourceIdForUpload) {
        const sharedImageId = template.resourceSettings.sharedImageResourceIdForUpload;
        const index = sharedImages.findIndex((image) => image.id.toLowerCase() === sharedImageId.toLowerCase());
        if (index > -1) {
            return sharedImages.get(index);
        }
    }
}
function getCurrentTemplate(templates, currentTemplateId) {
    if (!currentTemplateId || templates.size <= 0) {
        return;
    }
    const index = templates.findIndex((template) => template.id.toLowerCase() === currentTemplateId.toLowerCase());
    if (index > -1) {
        return templates.get(index);
    }
}
export function getLastUploadedRelativeTime(template, locale) {
    const now = moment(new Date()).locale(locale);
    const lastUploaded = template && template.lastUploaded && moment(template.lastUploaded).locale(locale);
    return lastUploaded && lastUploaded.from(now);
}
export function getLastPublishedDate(template, locale) {
    return template && template.lastPublished && moment(template.lastPublished).locale(locale).format('LL');
}
export function getLastPublishedTime(template, locale) {
    return template && template.lastPublished && moment(template.lastPublished).locale(locale).format('LT');
}
/** LastPublished is the only date in the environment setting document stored without a 'Z' at the end,
 * so the dates can be mismatched.  Correct it here
 */
function getLastPublishedDateCorrected(lastPublished) {
    if (!lastPublished) {
        return;
    }
    const date = new Date();
    const offset = date.getTimezoneOffset() / 60;
    const newLastPublished = new Date(lastPublished);
    newLastPublished.setHours(lastPublished.getHours() - offset);
    return newLastPublished;
}
export const convertToTemplateVm = (templates, currentTemplateId, isSaving, sharedImages, sizeData) => {
    const template = getCurrentTemplate(templates, currentTemplateId);
    if (!template) {
        return;
    }
    const { id, title, description, provisioningState, uploadState, publishingState, lastChanged, lastUploaded, lastPublished: lastPublishedUtc, createdDate, } = template;
    const image = getImage(template);
    const cores = getCores(sizeData);
    const isGpu = getIsGpu(sizeData);
    const memorySize = getMemorySize(sizeData);
    const price = getPrice(sizeData);
    const currency = getCurrencyCode(sizeData);
    const size = getSize(sizeData);
    const username = getUsername(template);
    const powerState = getPowerState(template);
    const sshAuthority = getSshAuthority(template);
    const sshCommand = getSshCommand(sshAuthority, username);
    const rdpAuthority = getRdpAuthority(template);
    const rdpInBrowserUrl = getRdpInBrowserUrl(template);
    const sshInBrowserUrl = getSshInBrowserUrl(template);
    const sharedImage = getSharedImage(template, sharedImages);
    const lastPublished = getLastPublishedDateCorrected(lastPublishedUtc);
    const gpuDriverState = getGpuDriverState(template);
    const isStarting = isTemplateStarting(template);
    const isStopping = isTemplateStopping(template);
    const isCreating = isTemplateCreating(template);
    const isUploadingImage = template ? template.uploadState === Ml.UploadState.InProgress : false;
    const isPublishing = isTemplatePublishing(template);
    const isPublished = isTemplatePublished(template);
    const isScaling = isTemplateScaling(template);
    const isResettingPassword = isTemplateResettingPassword(template);
    const isLinuxRdpEnabled = isTemplateLinuxRdpEnabled(template);
    const hasEdits = hasNewEdits(template);
    const hasTemplateVm = getIsTemplateVmPresent(template);
    const osType = getOsType(template);
    const isWindows = osType === Ml.OsType.Windows ? true : false;
    const isRunning = powerState === PowerState.Running;
    const connectionTypes = getConnectionTypes(rdpAuthority, rdpInBrowserUrl, sshAuthority, sshInBrowserUrl, isWindows, isLinuxRdpEnabled);
    return {
        id,
        title,
        description,
        provisioningState,
        uploadState,
        publishingState,
        image,
        cores,
        isGpu,
        username,
        memorySize,
        price,
        currency,
        size,
        rdpAuthority,
        rdpInBrowserUrl,
        sshAuthority,
        sshInBrowserUrl,
        sshCommand,
        lastChanged,
        lastUploaded,
        lastPublished,
        createdDate,
        sharedImage,
        isWindows,
        hasEdits,
        hasTemplateVm,
        isSaving,
        isCreating,
        isStarting,
        isStopping,
        isRunning,
        isUploadingImage,
        isPublishing,
        isPublished,
        isReimaging: false,
        isScaling,
        isResettingPassword,
        connectionTypes,
        gpuDriverState,
        isVNext: false,
    };
};
export function convertVNextVirtualMachineToTemplateVm(template, lab, isSaving, image, labSkuInfo) {
    if (!lab) {
        return;
    }
    const isUploadingImage = false;
    // todo: compare lastPublished date with the vm last changed date to determine whether we should show 'changed since last published'
    const hasEdits = false;
    const { title, description, systemData, virtualMachineProfile, lastPublished } = lab;
    let { state: labState, provisioningState: labProvisioningState, pendingLabState, pendingProvisioningState } = lab; // eslint-disable-line prefer-const
    labState = pendingLabState ?? labState;
    labProvisioningState = pendingProvisioningState ?? labProvisioningState;
    const createdAt = systemData?.createdAt;
    const isCreating = labProvisioningState === LabServicesModels.ProvisioningState.Creating;
    const cores = labSkuInfo?.cores;
    const isGpu = labSkuInfo?.isGpu ?? false;
    const memorySize = labSkuInfo?.memoryGB;
    const storageSize = labSkuInfo?.storageGB;
    const storageType = labSkuInfo?.storageType;
    const price = labSkuInfo?.price;
    const currency = labSkuInfo?.currency;
    const isWindows = lab.virtualMachineProfile?.osType === LabServicesModels.OsType.Windows ? true : false;
    const isPublishing = labState === LabServicesModels.LabState.Publishing;
    const isPublished = labState === LabServicesModels.LabState.Published;
    const isScaling = labState === LabServicesModels.LabState.Scaling;
    const isSyncing = labState === LabServicesModels.LabState.Syncing;
    const shouldHaveTemplateVM = virtualMachineProfile?.createOption === LabServicesModels.CreateOption.TemplateVM;
    const lastPublishedDate = !lastPublished ? undefined : new Date(lastPublished);
    if (!shouldHaveTemplateVM || !template) {
        return {
            title,
            description,
            createdDate: createdAt,
            lastPublished: lastPublishedDate,
            image: image?.displayName,
            cores,
            isGpu,
            storageSize,
            storageType,
            memorySize,
            price,
            currency,
            isWindows,
            hasTemplateVm: shouldHaveTemplateVM,
            isSaving,
            isPublishing,
            isPublished,
            isScaling,
            hasEdits,
            isUploadingImage,
            isCreating,
            isStarting: false,
            isStopping: false,
            isRunning: false,
            isReimaging: false,
            isResettingPassword: false,
            connectionTypes: [],
            isSyncing,
            labSkuInfo,
            isVNext: true,
        };
    }
    const { id, provisioningState, connectionProfile, state, pendingVmState } = template;
    const vmState = pendingVmState !== VmState.None
        ? pendingVmState
        : convertVNextVirtualMachineStateToVmState(state, provisioningState);
    const { adminUser } = virtualMachineProfile;
    const privateIpAddress = connectionProfile?.privateIpAddress;
    const rdpAuthority = connectionProfile?.rdpAuthority;
    const rdpInBrowserUrl = connectionProfile?.rdpInBrowserUrl;
    const sshAuthority = connectionProfile?.sshAuthority;
    const sshCommand = getSshCommand(sshAuthority, adminUser.username);
    const sshInBrowserUrl = connectionProfile?.sshInBrowserUrl;
    const sharedImage = !!image?.sharedGalleryId ? image : undefined;
    const isStarting = vmState === VmState.Starting;
    const isStopping = vmState === VmState.Stopping;
    const isResettingPassword = vmState === VmState.ResettingPassword;
    const isRunning = vmState === VmState.Running;
    const isRedeploying = vmState === VmState.Redeploying;
    const isReimaging = vmState === VmState.Reimaging;
    const connectionTypes = getVNextConnectionTypes(rdpAuthority, rdpInBrowserUrl, sshAuthority, sshInBrowserUrl);
    return {
        id,
        title,
        description,
        provisioningState,
        image: image?.displayName,
        cores,
        isGpu,
        username: adminUser.username,
        memorySize,
        storageSize,
        storageType,
        price,
        currency,
        privateIpAddress,
        rdpAuthority,
        rdpInBrowserUrl,
        sshAuthority,
        sshInBrowserUrl,
        sshCommand,
        createdDate: createdAt,
        sharedImage,
        isWindows,
        hasEdits,
        hasTemplateVm: shouldHaveTemplateVM,
        isSaving,
        isCreating,
        isStarting,
        isStopping,
        isRunning,
        isUploadingImage,
        isPublishing,
        isPublished,
        isReimaging,
        isScaling,
        isResettingPassword,
        connectionTypes,
        lastPublished: lastPublishedDate,
        isRedeploying,
        isSyncing,
        labSkuInfo,
        isVNext: true,
    };
}
function getGallery(galleries) {
    if (galleries && galleries.count() > 0) {
        return galleries.get(0);
    }
}
function getDismissedGpuBanner(lab) {
    // todo: compare with vNext DismissState if vNext dismiss state is different from vCurrent
    return lab ? lab.gpuDriverStateDismissed === Ml.DismissState.Dismissed : false;
}
export const getTemplateViewModel = memoize((state) => {
    const isVNext = !isCurrentLabParentLabAccount(state);
    const aadGroupName = getGroupName(state);
    const currentTenantId = getTenantId(state);
    const isReadOnly = isCurrentLabReadOnly(state);
    const isBastionEnabled = isBastionFeatureEnabled(state);
    const lab = getCurrentLab(state);
    const isGroupSyncModeEnabled = isLabGroupSynced(lab);
    const locale = getLocale(state);
    if (!isVNext) {
        const templateStore = state.get('templateStore');
        const sharedImageStore = state.get('sharedImageStore');
        const labStore = state.get('labStore');
        const coreLimitStore = state.get('coreLimitStore');
        const loadError = templateStore.get('loadError');
        const coreQuotaLoadState = coreLimitStore.get('loadState');
        const templateStoreLoadState = templateStore.get('loadState');
        const labLoadState = labStore.get('loadState');
        const sizeLoadState = getLabPricingAndAvailabilityLoadState(state);
        const isLoading = storeIsLoading(templateStoreLoadState) ||
            storeIsLoading(labLoadState) ||
            storeIsLoading(sizeLoadState) ||
            storeIsLoading(coreQuotaLoadState) ||
            isLoadingGroupName(state);
        const sharedImages = sharedImageStore.get('images');
        const sharedGalleries = sharedImageStore.get('sharedGalleries');
        const sharedImageLoadState = sharedImageStore.get('loadState');
        const isSharedImagesLoading = storeIsLoading(sharedImageLoadState);
        const isReadOnly = isCurrentLabReadOnly(state);
        const coreQuotaData = coreLimitStore.get('coreQuotaData');
        const sizeData = getLabPricingAndAvailabilityData(state);
        const errors = templateStore.get('errors');
        const templates = templateStore.get('templates');
        const currentTemplate = templateStore.get('currentTemplate');
        const publishExceedsCoresError = templateStore.get('publishExceedsCoresError');
        const isSaving = templateStore.get('isUpdating');
        const labUpdateError = labStore.get('updateError');
        const isDismissing = labStore.get('isUpdating');
        const template = convertToTemplateVm(templates, currentTemplate, isSaving, sharedImages, sizeData);
        const sharedGallery = getGallery(sharedGalleries);
        const dismissedGpuBanner = getDismissedGpuBanner(lab);
        const vNextPublishError = false;
        return {
            lab,
            templates,
            template,
            sharedGallery,
            sharedImages,
            coreQuotaData,
            dismissedGpuBanner,
            currentTenantId,
            isDismissing,
            isReadOnly,
            groupName: aadGroupName,
            isGroupSyncModeEnabled,
            isSharedImagesLoading,
            errors,
            labUpdateError,
            publishExceedsCoresError,
            isBastionEnabled,
            isLoading,
            loadError,
            locale,
            vNextPublishError,
        };
    }
    else {
        const virtualMachineStore = state.get('vNextVirtualMachineStore');
        const imagesStore = state.get('vNextImageStore');
        const labStore = state.get('vNextLabStore');
        const coreUsgaeStore = state.get('vNextCoreUsageStore');
        const pricingAndAvailabilityStore = state.get('vNextPricingAndAvailabilityStore');
        const skuStore = state.get('vNextSkuStore');
        const loadVirtualMachinesError = virtualMachineStore.get('loadError');
        const getErrors = virtualMachineStore.get('getErrors');
        const vNextTemplateVM = getTemplateVM(state);
        const resourceOperationError = !!vNextTemplateVM ? vNextTemplateVM.resourceOperationError : undefined;
        const getTemplateVirtualMachineError = !!vNextTemplateVM
            ? getErrors.find((o) => caseInsensitiveCultureInvariantCompare(o.id, vNextTemplateVM.id) === 0)
            : undefined;
        const coreQuotaLoadState = coreUsgaeStore.get('loadState');
        const virtualMachineStoreLoadState = virtualMachineStore.get('loadState');
        const labLoadState = labStore.get('loadState');
        const pricingAndAvailabilityLoadState = pricingAndAvailabilityStore.get('loadState');
        const skuLoadState = skuStore.get('loadState');
        const usageLoadState = getVNextUsageLoadState(state);
        const operations = state.get('vNextVirtualMachineStore').get('operations');
        let isLoading = storeIsLoading(labLoadState) ||
            storeIsLoading(pricingAndAvailabilityLoadState) ||
            storeIsLoading(coreQuotaLoadState) ||
            isLoadingGroupName(state) ||
            isLoadingLabMetadata(state) ||
            storeIsLoading(skuLoadState) ||
            storeIsLoading(usageLoadState);
        let loadTemplateError;
        if (hasTemplateVM(state)) {
            isLoading = isLoading || storeIsLoading(virtualMachineStoreLoadState);
            loadTemplateError = loadVirtualMachinesError || getTemplateVirtualMachineError;
        }
        const sharedImages = getVNextSharedImages(state);
        const sharedImageLoadState = imagesStore.get('loadState');
        const isSharedImagesLoading = storeIsLoading(sharedImageLoadState);
        const coreUsageData = getCurrentLabCoreUsageInfo(state);
        const templateErrors = getTemplateErrors(state);
        const labErrors = getCurrentLabErrors(state);
        const labPublishErrors = getLabErrorsByOperation(labErrors, FailureOperation.PublishTemplate);
        let vNextPublishError = false;
        let errors = templateErrors;
        if (labPublishErrors.size > 0) {
            vNextPublishError = true;
            errors = errors.set(labPublishErrors.get(0).id, labPublishErrors);
        }
        const isSaving = labStore.get('isUpdating');
        const labUpdateError = getCurrentLabUpdateError(state);
        const isDismissing = labStore.get('isUpdatingMetadata');
        const shouldShowExportStartedMessage = virtualMachineStore.get('shouldShowExportStartedMessage');
        const exportedImageName = virtualMachineStore.get('exportedImageName');
        const image = getCurrentLabImage(state);
        const labSku = getCurrentLabSkuInfo(state);
        const template = convertVNextVirtualMachineToTemplateVm(vNextTemplateVM, lab, isSaving, image, labSku);
        const shouldDisableLabUpdate = shouldDisableCurrentLabUpdate(state);
        const labPlans = getLabPlans(state);
        const labPlanId = lab.labPlanId;
        let sharedGalleryId;
        if (!!labPlanId) {
            const labPlan = getLabPlan(labPlans, labPlanId);
            sharedGalleryId = labPlan?.sharedGalleryId;
        }
        const groupName = getVNextLabGroupName(state);
        return {
            lab,
            template,
            sharedImages,
            coreQuotaData: coreUsageData,
            currentTenantId,
            isDismissing,
            isReadOnly,
            groupName,
            isGroupSyncModeEnabled,
            isSharedImagesLoading,
            errors,
            labUpdateError,
            isBastionEnabled,
            isLoading,
            loadError: loadTemplateError,
            locale,
            shouldDisableLabUpdate,
            labPlanSharedGalleryId: sharedGalleryId,
            shouldShowExportStartedMessage,
            exportedImageName,
            vNextPublishError,
            operations,
            resourceOperationError,
        };
    }
});
export const getTemplateContainerModel = memoize((state) => {
    const isVNext = !isCurrentLabParentLabAccount(state);
    const search = getSearch(state);
    const templateViewModel = getTemplateViewModel(state);
    const isTeamsOrLmsIntegrationEnabled = isTeamsOrLmsMode(state);
    return {
        isVNext,
        search,
        templateViewModel,
        isTeamsOrLmsIntegrationEnabled,
    };
});
const TemplateSelectors = {
    getTemplateContainerModel,
    getTemplateViewModel,
    convertToTemplateVm,
    isSharedGalleryValid,
    isUploadStateFailed,
    getLastUploadedRelativeTime,
    getLastPublishedDate,
    getLastPublishedTime,
    getPowerState,
};
export default TemplateSelectors;
