import { call, put, takeLatest, take, cancel, fork, delay, race, cancelled, select } from 'redux-saga/effects';
import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { TemplateActionType, } from '../../actions/template/template-actions';
import TemplateProvider from '../../../data/providers/template-provider';
import { getTemplateSuccess, getTemplateError, getTemplate as getTemplateActionCreator, pollTemplateCancelled, getTemplateCancelled, publishTemplateError, startTemplateError, stopTemplateError, resetPasswordError, createTemplateError, } from '../../actions/template/template-action-creators';
import { isTemplateStarting, isTemplateStopping, hasTemplateCreationFailed, isTemplateResettingPassword, isTemplatePublishingOrScaling, getActionFromUri, } from '../../selectors/template-selectors';
import { ResourceId } from '../../../utils/resource-id';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getTemplate as getTemplateSelector } from '../../../redux/selectors/template-selectors';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { createSharedImageError } from '../../actions/shared-image/shared-image-action-creators';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
function getErrorForOperation(template, failureOp) {
    switch (failureOp) {
        case FailureOperation.StartTemplate:
        case FailureOperation.StopTemplate:
        case FailureOperation.CreateTemplate:
        case FailureOperation.ResetPassword:
            return new MlClientError(template.latestOperationResult.errorMessage, template.id, failureOp, template.latestOperationResult.errorCode);
        case FailureOperation.SaveSharedImage:
            if (getActionFromUri(template) === 'saveImage') {
                return new MlClientError(template.latestOperationResult.errorMessage, template.id, failureOp, template.latestOperationResult.errorCode);
            }
            return new MlClientError('', template.id, FailureOperation.PublishTemplate);
        case FailureOperation.PublishTemplate:
            if (getActionFromUri(template) === 'publish') {
                return new MlClientError(template.latestOperationResult.errorMessage, template.id, failureOp, template.latestOperationResult.errorCode);
            }
            return new MlClientError('', template.id, failureOp);
        default:
            return new MlClientError('', template.id, failureOp);
    }
}
function hasOperationFailed(previousTemplate, currentTemplate) {
    return previousTemplate &&
        previousTemplate.latestOperationResult &&
        currentTemplate &&
        currentTemplate.latestOperationResult
        ? previousTemplate.latestOperationResult.status === 'Running' &&
            currentTemplate.latestOperationResult.status === 'Failed'
        : false;
}
export function* getTemplate(action) {
    const { templateId, expand } = action;
    try {
        const resourceId = new ResourceId(templateId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { template, changed } = yield race({
            template: call(TemplateProvider.getTemplate, resourceId, accessToken, locale, language, expand),
            // TODO: Add other actions that should cancel update here
            changed: race([
                take(TemplateActionType.SELECT_LAB_PARENT_RESOURCE),
                take(TemplateActionType.SELECT_LAB),
                take(TemplateActionType.SELECT_TEMPLATE),
                take(TemplateActionType.POLL_TEMPLATE_STOP),
                take(TemplateActionType.POLL_TEMPLATE_CANCELLED),
                take(TemplateActionType.CREATE_SHARED_IMAGE),
                take(TemplateActionType.START_TEMPLATE),
                take(TemplateActionType.STOP_TEMPLATE),
                take(TemplateActionType.UPDATE_SHARED_IMAGE),
                take(TemplateActionType.RESET_PASSWORD),
                take(TemplateActionType.PUBLISH_TEMPLATE),
            ]),
        });
        if (changed) {
            yield put(getTemplateCancelled());
        }
        else if (hasTemplateCreationFailed(template)) {
            const error = getErrorForOperation(template, FailureOperation.CreateTemplate);
            yield put(createTemplateError(error));
        }
        else {
            // get the previous template we fetched so we can compare it to the most recently fetched template.
            // we compare the two states to see if an ongoing operation has failed, and if so we'll send the
            // appropriate error action so the UI can be updated with the correct error message.
            const previousTemplate = yield select(getTemplateSelector, templateId);
            if (previousTemplate) {
                if (isTemplatePublishingOrScaling(previousTemplate) &&
                    template.publishingState === Ml.PublishingState.PublishFailed) {
                    const error = getErrorForOperation(template, FailureOperation.PublishTemplate);
                    yield put(publishTemplateError(templateId, error));
                }
                if (previousTemplate.uploadState === Ml.UploadState.InProgress &&
                    template.uploadState === Ml.UploadState.Failed) {
                    const error = getErrorForOperation(template, FailureOperation.SaveSharedImage);
                    yield put(createSharedImageError(template.id, error));
                }
                if (hasOperationFailed(previousTemplate, template)) {
                    if (isTemplateStarting(previousTemplate)) {
                        const error = getErrorForOperation(template, FailureOperation.StartTemplate);
                        yield put(startTemplateError(error));
                    }
                    else if (isTemplateStopping(previousTemplate)) {
                        const error = getErrorForOperation(template, FailureOperation.StopTemplate);
                        yield put(stopTemplateError(error));
                    }
                    else if (isTemplateResettingPassword(previousTemplate)) {
                        const error = getErrorForOperation(template, FailureOperation.ResetPassword);
                        yield put(resetPasswordError(error));
                    }
                }
            }
            yield put(getTemplateSuccess(template, expand));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, templateId, FailureOperation.GetTemplate, e ? e.code : undefined);
        yield put(getTemplateError(error));
    }
}
function* pollTemplate(action) {
    try {
        // allowing for an initial delay before we kick off polling
        // to mitigate a state flashing bug due to issues in the RP
        const { initialDelayMs, id } = action;
        if (!!initialDelayMs) {
            yield delay(initialDelayMs);
        }
        // this looks like an infinite loop, but the finally block will get triggered when
        // the task that is running this is canceled, which happens when the POLL_TEMPLATE_STOP
        // action is dispatched
        while (true) {
            yield put(getTemplateActionCreator(id));
            yield race({
                success: take(TemplateActionType.GET_TEMPLATE_SUCCESS),
                cancelled: take(TemplateActionType.GET_TEMPLATE_CANCELLED),
                error: take(TemplateActionType.GET_TEMPLATE_ERROR),
            });
            const { intervalMs } = action;
            yield delay(intervalMs);
        }
    }
    finally {
        if (yield cancelled()) {
            yield put(pollTemplateCancelled());
        }
    }
}
export function* pollTemplateSaga() {
    // while this looks like an infinite loop, yield take(POLL_TEMPLATE_START)
    // will pause this code until polling is started
    while (true) {
        const action = yield take(TemplateActionType.POLL_TEMPLATE_START);
        // forking so the polling task runs without blocking
        const pollTask = yield fork(pollTemplate, action);
        // waits until the stop is triggered so we can cancel the polling task
        yield take(TemplateActionType.POLL_TEMPLATE_STOP);
        yield cancel(pollTask);
    }
}
export function* getTemplateSaga() {
    yield takeLatest(TemplateActionType.GET_TEMPLATE, getTemplate);
}
