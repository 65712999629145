import { defineMessages } from 'react-intl';
export const scheduleMessages = defineMessages({
    todayLabel: {
        id: 'TodayLabel',
        defaultMessage: 'Today',
        description: 'Text on button on calendar to take user to this week.',
    },
    addEvent: {
        id: 'AddScheduleEvent',
        defaultMessage: 'Add scheduled event',
        description: 'Text to indicate creating a new schedule.',
    },
    editEvent: {
        id: 'EditScheduleEvent',
        defaultMessage: 'Edit scheduled event',
        description: 'Text to indicate editing an existing schedule.',
    },
    dateLabel: {
        id: 'DateLabel',
        defaultMessage: 'Date',
        description: 'Label for date of schedule',
    },
    startTimeLabel: {
        id: 'StartTimeLabel',
        defaultMessage: 'Start time',
        description: 'Label for start time of schedule',
    },
    stopTimeLabel: {
        id: 'StopTimeLabel',
        defaultMessage: 'Stop time',
        description: 'Label for stop time of schedule',
    },
    timeZoneLabel: {
        id: 'TimeZoneLabel',
        defaultMessage: 'Time zone',
        description: 'Label for time zone of schedule',
    },
    notesLabel: {
        id: 'NotesLabel',
        defaultMessage: 'Notes (optional)',
        description: 'Label for schedule notes, which are optional.',
    },
    selectTimezonePlaceholder: {
        id: 'SelectTimezonePlaceholder',
        defaultMessage: 'Select a timezone',
        description: `Placeholder for time zone picker, if we cannot determine the browser's local time.`,
    },
    selectedDaysValidationMessage: {
        id: 'SelectedDaysValidationMessage',
        defaultMessage: 'At least one day must be selected',
        description: `Validation message for day picker in flyout, specifying at least one day must be selected.`,
    },
    startTimeValidationMessage: {
        id: 'StartTimeValidationMessage',
        defaultMessage: 'Start time must be before stop time',
        description: `Validation message for start time control in flyout, specifying start time must be before stop time.`,
    },
    stopTimeValidationMessage: {
        id: 'StopTimeValidationMessage',
        defaultMessage: 'Stop time must be after start time',
        description: `Validation message for stop time control in flyout, specifying stop time must be after start time.`,
    },
    minimumDurationValidationMessage: {
        id: 'MinimumDurationValidationMessage',
        defaultMessage: 'Schedule duration must be at least 15 minutes',
        description: `Validation message for stop time control in flyout, specifying schedule duration must be at least 15 minutes.`,
    },
    timezoneValidationMessage: {
        id: 'TimezoneValidationMessage',
        defaultMessage: 'Time zone is required',
        description: `Validation message for time zone control in flyout, specifying time zone must be selected.`,
    },
    notesValidationMessage: {
        id: 'NotesValidationMessage',
        defaultMessage: 'Notes cannot be longer than 500 characters',
        description: `Validation message for notes control in flyout, specifying notes cannot be longer than 500 characters.`,
    },
    startWithTimeLabel: {
        id: 'StartWithTimeLabel',
        defaultMessage: 'Start: {startTime}',
        description: `Label for start time.`,
    },
    stopWithTimeLabel: {
        id: 'StopWithTimeLabel',
        defaultMessage: 'Stop: {stopTime}',
        description: `Label for stop time.`,
    },
    repeatLabel: {
        id: 'RepeatLabel',
        defaultMessage: 'Repeat',
        description: `Label for repeat.`,
    },
    deleteRecurrenceLabel: {
        id: 'DeleteRecurrenceLabel',
        defaultMessage: 'Delete recurrence',
        description: `Title for button to delete recurrence.`,
    },
    occurenceNoEndDateLabel: {
        id: 'OccurenceNoEndDateLabel',
        defaultMessage: 'Every {days}',
        description: `Label for recurring schedule, indicating the days it occurs on.`,
    },
    occurenceWithEndDateLabel: {
        id: 'OccurenceWithEndDateLabel',
        defaultMessage: 'Every {days} until {date}',
        description: `Label for recurring schedule, indicating the days it occurs on and the date the schedule ends at`,
    },
    sundayShort: {
        id: 'SundayShort',
        defaultMessage: 'S',
        description: `One character abbreviation for Sunday. If Sunday cannot be abbreviated down to one character, then the shortest possible representation for Sunday`,
    },
    mondayShort: {
        id: 'MondayShort',
        defaultMessage: 'M',
        description: `One character abbreviation for Monday. If Monday cannot be abbreviated down to one character, then the shortest possible representation for Monday`,
    },
    tuesdayShort: {
        id: 'TuesdayShort',
        defaultMessage: 'T',
        description: `One character abbreviation for Tuesday. If Tuesday cannot be abbreviated down to one character, then the shortest possible representation for Tuesday`,
    },
    wednesdayShort: {
        id: 'WednesdayShort',
        defaultMessage: 'W',
        description: `One character abbreviation for Wednesday. If Wednesday cannot be abbreviated down to one character, then the shortest possible representation for Wednesday`,
    },
    thursdayShort: {
        id: 'ThursdayShort',
        defaultMessage: 'T',
        description: `One character abbreviation for Thursday. If Thursday cannot be abbreviated down to one character, then the shortest possible representation for Thursday`,
    },
    fridayShort: {
        id: 'fridayShort',
        defaultMessage: 'F',
        description: `One character abbreviation for Friday. If Friday cannot be abbreviated down to one character, then the shortest possible representation for Friday`,
    },
    saturdayShort: {
        id: 'SaturdayShort',
        defaultMessage: 'S',
        description: `One character abbreviation for Saturday. If Saturday cannot be abbreviated down to one character, then the shortest possible representation for Saturday`,
    },
});
export default scheduleMessages;
