import memoize from 'memoize-one';
import { isHealthyState } from '../utils/provisioning-state';
import { compareByName } from '../utils/common';
import { storeIsLoading } from '../redux/selectors/common-selectors';
import { isTeamsAdmin, isRegularChannel } from '../redux/selectors/teams-selectors';
import { getCurrentLabParentId, getResourceGroups, hasRefreshedLabParentResources, hasRefreshLabParentResourcesError, isRefreshingLabParentResources, } from '../redux/selectors/lab-parent-resource-selectors';
import { mapLabParentResourcesToItems } from '../common/page-header/page-header-selectors';
import { caseInsensitiveCultureInvariantCompare } from '../utils/string-comparison';
import { List } from 'immutable';
export const getTeamsConfigViewModel = memoize((state) => {
    const labParentResourcesStore = state.get('labParentResourceStore');
    const loadState = labParentResourcesStore.get('loadState');
    const isLoading = storeIsLoading(loadState);
    const isStudent = !isTeamsAdmin(state);
    const isInvalidChannel = !isRegularChannel(state);
    let defaultSelectedId = getCurrentLabParentId(state);
    let labParentItems = List();
    if (!isLoading) {
        const labAccountStore = state.get('labAccountStore');
        const labAccounts = labAccountStore
            .get('labAccounts')
            .filter((o) => isHealthyState(o))
            .sort(compareByName);
        const resourceGroups = getResourceGroups(state);
        labParentItems = mapLabParentResourcesToItems(labAccounts, resourceGroups).sort(compareByName);
        if (!labParentItems.find((o) => caseInsensitiveCultureInvariantCompare(o.id, defaultSelectedId) === 0)) {
            defaultSelectedId = labParentItems.get(0)?.id;
        }
    }
    const isRefreshing = isRefreshingLabParentResources(state);
    const hasRefreshError = hasRefreshLabParentResourcesError(state);
    const hasRefreshed = hasRefreshedLabParentResources(state);
    return {
        isLoading,
        isRefreshing,
        hasRefreshError,
        hasRefreshed,
        labParentItems,
        isStudent,
        isInvalidChannel,
        defaultSelectedId,
    };
});
export const getTeamsConfigContainerModel = memoize((state) => {
    const teamsConfigViewModel = getTeamsConfigViewModel(state);
    return {
        teamsConfigViewModel,
    };
});
