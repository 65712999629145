export function getDateInLocalTime(dateString) {
    const dateAndTimeSplit = dateString.split('T');
    const dateSplit = dateAndTimeSplit[0].split('-');
    const timeSplit = dateAndTimeSplit[1].split(':');
    const year = +dateSplit[0];
    const month = +dateSplit[1] - 1;
    const date = +dateSplit[2];
    const hour = +timeSplit[0];
    const minute = +timeSplit[1];
    const second = +timeSplit[2].slice(0, 2);
    return new Date(year, month, date, hour, minute, second);
}
