export const settingsCacheKey = 'user_settings';
export const defaultSettings = {
    lastUsedParentResource: '',
    dismissedLabListFirstRun: false,
    showRetirementAlert: true,
    disableHints: false,
    hasDismissedCovidBanner: false,
    hasDismissedOldV1GpuLabBanner: false,
};
export const settingsUpdateDebounceInMs = 5000;
