export const LabParentRoutePrefix = '/:labParentId+';
export const LabIdRoutePrefix = '/:labId+';
export const StudentView = '/virtualmachines';
export const Routes = {
    Home: '/',
    LoggedOut: '/user/loggedout',
    Welcome: '/welcome',
    StudentView: StudentView,
    LabList: (labParentId = LabParentRoutePrefix) => encodeURI(`${labParentId}/labs`),
    VNextLabList: (labParentId = LabParentRoutePrefix) => encodeURI(`${labParentId}/providers/microsoft.labservices/labs`),
    NewLab: (labParentId = LabParentRoutePrefix) => encodeURI(`${labParentId}/newlab`),
    NewVNextLab: (labParentId = LabParentRoutePrefix) => encodeURI(`${labParentId}/providers/microsoft.labservices/newlab`),
    Lab: (subscriptionId = ':subscriptionId', resourceGroup = ':resourceGroup', labAccount = ':labAccount', lab = ':lab') => encodeURI(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroup}/providers/microsoft.labservices/labaccounts/${labAccount}/labs/${lab}`),
    VNextLab: (subscriptionId = ':subscriptionId', resourceGroup = ':resourceGroup', lab = ':lab') => encodeURI(`/subscriptions/${subscriptionId}/resourcegroups/${resourceGroup}/providers/microsoft.labservices/labs/${lab}`),
    Template: (labId = LabIdRoutePrefix) => encodeURI(`${labId}/template`),
    Schedule: (labId = LabIdRoutePrefix) => encodeURI(`${labId}/schedules`),
    Users: (labId = LabIdRoutePrefix) => encodeURI(`${labId}/users`),
    VirtualMachines: (labId = LabIdRoutePrefix) => encodeURI(`${labId}/virtualmachines`),
    Register: (registrationCode = ':registrationCode') => encodeURI(`/register/${registrationCode}`),
    Dashboard: (labId = LabIdRoutePrefix) => encodeURI(`${labId}/dashboard`),
    Settings: (labId = LabIdRoutePrefix) => encodeURI(`${labId}/settings`),
    SignInReturn: '/signin-oidc-aad',
    TeamsConfig: '/teams-config',
    Privacy: '/privacy',
    TermsOfUse: '/terms-of-use',
    TeamsStartMfa: '/teams-start-mfa',
    TeamsCompleteMfa: '/teams-complete-mfa',
    LtiStartOidc: '/lti-start-oidc',
    LtiReturnOidc: '/lti-return-oidc',
};
export const LabParentRoutes = [Routes.LabList(), Routes.NewLab()];
export const VNextLabParentRoutes = [Routes.VNextLabList(), Routes.NewVNextLab()];
export const LabRoutes = [
    Routes.Dashboard(),
    Routes.Template(),
    Routes.Schedule(),
    Routes.Users(),
    Routes.VirtualMachines(),
    Routes.Settings(),
];
// order for this is important, routes need to go from
// most restrictive to least restrictive otherwise we won't
// match them correctly when resolving our route name for telemetry
export const AllRoutes = [
    Routes.Template(),
    Routes.Schedule(),
    Routes.Users(),
    Routes.VirtualMachines(),
    Routes.Register(),
    Routes.Dashboard(),
    Routes.Settings(),
    Routes.Lab(),
    Routes.VNextLab(),
    Routes.LabList(),
    Routes.NewLab(),
    Routes.LoggedOut,
    Routes.Welcome,
    Routes.StudentView,
    Routes.SignInReturn,
    Routes.LtiStartOidc,
    Routes.Home,
];
export const MfaRoutes = [Routes.TeamsStartMfa, Routes.TeamsCompleteMfa];
export const RoutePathToRouteNameMap = {};
RoutePathToRouteNameMap[Routes.Home] = 'Home';
RoutePathToRouteNameMap[Routes.LoggedOut] = 'LoggedOut';
RoutePathToRouteNameMap[Routes.Welcome] = 'Welcome';
RoutePathToRouteNameMap[Routes.StudentView] = 'StudentView';
RoutePathToRouteNameMap[Routes.LabList()] = 'LabList';
RoutePathToRouteNameMap[Routes.NewLab()] = 'NewLab';
RoutePathToRouteNameMap[Routes.Template()] = 'Template';
RoutePathToRouteNameMap[Routes.Schedule()] = 'Schedule';
RoutePathToRouteNameMap[Routes.Users()] = 'Users';
RoutePathToRouteNameMap[Routes.VirtualMachines()] = 'VirtualMachines';
RoutePathToRouteNameMap[Routes.Register()] = 'Register';
RoutePathToRouteNameMap[Routes.Dashboard()] = 'Dashboard';
RoutePathToRouteNameMap[Routes.Settings()] = 'Settings';
RoutePathToRouteNameMap[Routes.Lab()] = 'Lab';
RoutePathToRouteNameMap[Routes.VNextLab()] = 'VNextLab';
RoutePathToRouteNameMap[Routes.SignInReturn] = 'SignInReturn';
RoutePathToRouteNameMap[Routes.TeamsConfig] = 'TeamsConfig';
RoutePathToRouteNameMap[Routes.TeamsStartMfa] = 'TeamsStartMfa';
RoutePathToRouteNameMap[Routes.TeamsCompleteMfa] = 'TeamsCompleteMfa';
RoutePathToRouteNameMap[Routes.LtiStartOidc] = 'LtiStartOidc';
export default Routes;
