import * as React from 'react';
import { connect } from 'react-redux';
import { SignInRedirectPage } from './sign-in-redirect-page';
import { signIn } from '../redux/actions/identity/identity-action-creators';
class SignInRedirectPageContainerInjected extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.signIn(true, true);
    }
    render() {
        return <SignInRedirectPage />;
    }
}
const mapDispatchToProps = {
    signIn,
};
export const SignInRedirectPageContainer = connect(null, mapDispatchToProps)(SignInRedirectPageContainerInjected);
export default SignInRedirectPageContainer;
