import memoize from 'memoize-one';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { isHealthyState } from '../../../utils/provisioning-state';
import { ResourceId } from '../../../utils/resource-id';
import { getCurrentLabParentId } from '../lab-parent-resource-selectors';
import { getCurrentLab, isVNextLab } from '../lab-selectors';
import { getCurrentLmsInstance } from '../lti-selectors';
import { isLmsHost } from '../common-selectors';
import { Roles } from '../../../data/models/roles';
export const getCurrentLabPlanId = memoize((store) => {
    const currentLabPlanId = store.get('labPlanStore').get('currentLabPlanId');
    return currentLabPlanId;
});
export const getLabPlans = memoize((store) => {
    const labPlanStore = store.get('labPlanStore');
    const labPlans = labPlanStore.get('labPlans');
    return labPlans;
});
export const isLinkedLmsInstanceValid = memoize((labPlan, lmsInstance, isLms) => {
    if (!isLms) {
        return true;
    }
    else {
        return (!!labPlan.linkedLmsInstance &&
            caseInsensitiveCultureInvariantCompare(labPlan.linkedLmsInstance, lmsInstance) === 0);
    }
});
export const isValidLabPlan = memoize((labPlan, lmsInstance, isLms) => {
    return isHealthyState(labPlan) && isLinkedLmsInstanceValid(labPlan, lmsInstance, isLms);
});
export const getValidLabPlans = memoize((store) => {
    const labPlans = getLabPlans(store);
    const currentLmsInstance = getCurrentLmsInstance(store);
    const isLms = isLmsHost(store);
    return labPlans.filter((o) => isValidLabPlan(o, currentLmsInstance, isLms));
});
export const getCurrentResourceGroupLabPlans = memoize((store) => {
    const labPlans = getValidLabPlans(store);
    const currentLabParentId = getCurrentLabParentId(store);
    return labPlans.filter((o) => doesLabPlanResourceGroupMatch(o, currentLabParentId));
});
function doesLabPlanResourceGroupMatch(labPlan, labParentId) {
    const resourceId = new ResourceId(labPlan.id, true);
    return caseInsensitiveCultureInvariantCompare(resourceId.getResourceGroupId(), labParentId) === 0;
}
export function getLabPlan(labPlans, labPlanId) {
    return labPlans.find((o) => caseInsensitiveCultureInvariantCompare(o.id, labPlanId) === 0);
}
export const getCurrentLabPlan = memoize((store) => {
    const labPlans = getCurrentResourceGroupLabPlans(store);
    const currentLabPlanId = getCurrentLabPlanId(store);
    return getLabPlan(labPlans, currentLabPlanId);
});
export const getLabPlanForCurrentLab = memoize((store) => {
    const labPlans = getLabPlans(store);
    const currentLab = getCurrentLab(store);
    if (isVNextLab(currentLab.id)) {
        return labPlans.find((o) => o.id === currentLab.labPlanId);
    }
});
export const getLinkableLabPlans = memoize((store) => {
    let labPlans = getLabPlans(store);
    const currentLmsInstance = getCurrentLmsInstance(store);
    // get lab plans not linked to any LMS instance or linked to current lms instance
    labPlans = labPlans.filter((o) => !!o.roles &&
        o.roles.indexOf(Roles.Write) > -1 &&
        (!o.linkedLmsInstance ||
            caseInsensitiveCultureInvariantCompare(o.linkedLmsInstance, currentLmsInstance) === 0));
    return labPlans;
});
export const getUpdateLmsErrors = memoize((store) => {
    const labPlanStore = store.get('labPlanStore');
    return labPlanStore.get('updateLmsErrors');
});
