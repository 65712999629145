import { getTheme } from '@fluentui/react';
import { DefaultCustomTheme } from './custom-themes';
let currentTheme = undefined;
// Loads custom theme to become current.
export function loadCustomTheme(theme) {
    const fluentTheme = getTheme();
    currentTheme = { ...fluentTheme, ...theme };
}
// Returns current custom theme including Fluent theme + custom styles.
export function getCustomTheme() {
    if (!currentTheme) {
        loadCustomTheme(DefaultCustomTheme);
    }
    return currentTheme;
}
export function getPageHeaderRootExpandedColor() {
    const { pageHeaderButtonStyles } = getCustomTheme();
    return pageHeaderButtonStyles?.rootExpanded;
}
