import { createVNextArmApiClient } from '../../arm-data-provider';
import { Roles } from '../../../../data/models/roles';
import { hasAccess, VNextActionPermissions } from '../../permissions-provider';
import { trackTrace } from '../../../../utils/telemetry/telemetry-channel';
async function getLabPlanRoles(lab, accessToken, locale, language) {
    const roles = [Roles.Read];
    try {
        if (await hasAccess(VNextActionPermissions.WriteLabPlan, lab.id, accessToken, locale, language)) {
            roles.push(Roles.Write);
        }
    }
    catch (e) {
        trackTrace(`Unable to determine write access for lab ${lab.name} due to error ${e.message}, assuming no access.`);
    }
    return roles;
}
export async function listSubscriptionLabPlans(subscription, accessToken, locale, language, includeRoles) {
    const client = createVNextArmApiClient(subscription.subscriptionId, accessToken, locale, language);
    const response = await client.labPlans.listBySubscription();
    if (includeRoles) {
        const labPlans = [];
        await Promise.all(response.map(async (labPlan, index) => {
            const roles = await getLabPlanRoles(labPlan, accessToken, locale, language);
            labPlans[index] = { ...labPlan, roles };
        }));
        return labPlans;
    }
    else {
        return response;
    }
}
export async function getLabPlan(labPlanId, accessToken, locale, language) {
    const client = createVNextArmApiClient(labPlanId.subscriptionId, accessToken, locale, language);
    const result = await client.labPlans.get(labPlanId.resourceGroupName, labPlanId.name);
    return result;
}
export async function updateLabPlan(labPlanId, labPlanUpdate, accessToken, locale, language) {
    const client = createVNextArmApiClient(labPlanId.subscriptionId, accessToken, locale, language);
    const result = await client.labPlans.update(labPlanUpdate, labPlanId.resourceGroupName, labPlanId.name);
    return result;
}
export async function saveImage(labPlanId, accessToken, locale, language, name, labVirtualMachineId) {
    const client = createVNextArmApiClient(labPlanId.subscriptionId, accessToken, locale, language);
    const saveImageBody = {
        name,
        labVirtualMachineId,
    };
    await client.labPlans.beginSaveImage(saveImageBody, labPlanId.resourceGroupName, labPlanId.name);
}
const LabPlanProvider = {
    listSubscriptionLabPlans,
    saveImage,
    getLabPlan,
    updateLabPlan,
};
export default LabPlanProvider;
