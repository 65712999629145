import { createLabsApiClient } from './labs-api-client-factory';
export async function listPrice(subscriptionId, region, accessToken, locale, language) {
    const labsApiClient = createLabsApiClient(subscriptionId, accessToken, locale, language);
    let currentPage = await labsApiClient.subscriptionPrices.list(region);
    let usage = currentPage;
    while (!!currentPage.nextLink) {
        const nextPage = await labsApiClient.subscriptionPrices.listNext(currentPage.nextLink);
        currentPage = nextPage;
        usage = usage.concat(nextPage);
    }
    return usage;
}
const PriceProvider = {
    listPrice,
};
export default PriceProvider;
