import { Map } from 'immutable';
import memoize from 'memoize-one';
import { getConnectionTypes, getVNextConnectionTypes } from '../common/selectors/vm-connect';
import { ProvisioningState } from '../data/models/common';
import { VmState, OperatingSystem } from '../data/models/environment-common';
import { storeIsLoading, isTeamsMode, isVNextFeatureEnabledOrLms, isLmsMode, isBastionFeatureEnabled, } from '../redux/selectors/common-selectors';
import { getGroupId, shouldFilterLabsByGroupId } from '../redux/selectors/group-selectors';
import { isValidSupportInfo } from '../redux/selectors/lab-account-selectors';
import { stringSorter } from '../utils/sorting';
import { LabsApiModels } from 'lab-services-internal';
import moment from 'moment';
import { LabServicesModels } from 'lab-services';
import { isLmsStudent } from '../redux/selectors/lti-selectors';
export const mustResetPasswordBeforeConnect = (item) => !item.useSharedPassword && !item.lastPasswordReset;
export const vmCanStartAgain = (item) => item.isScheduleRunning || item.hasUnlimitedQuota || item.hoursUsed < item.quotaHours;
export const vmCanRdpAndSsh = (item) => !!item.rdpAuthority && !!item.sshAuthority;
export const isVmMoving = (environment) => {
    return environment.provisioningState.toLowerCase() === ProvisioningState.Moving;
};
export const studentVmList = memoize((environments) => {
    const studentVms = [];
    environments.forEach((environment) => {
        const { provisioningState } = environment;
        if (provisioningState === ProvisioningState.Deleting) {
            return;
        }
        const { id, name: title, vmState, pendingVmState, usageQuota, totalUsage, runningInSchedule: isScheduleRunning, description, passwordLastReset: lastPasswordReset, vmUsername: username, vmRdpAuthority: rdpAuthority, vmRdpBrowserUrl: rdpBrowserUrl, vmSshAuthority: sshAuthority, vmSshBrowserUrl: sshBrowserUrl, useSharedPassword, os, supportInfo, } = environment;
        const quotaHours = usageQuota ? usageQuota.asHours() : undefined;
        const hoursUsed = totalUsage ? totalUsage.asHours() : undefined;
        // prefer our pending state if we have one to track in progress operations
        const state = pendingVmState !== VmState.None ? pendingVmState : vmState;
        const isMoving = isVmMoving(environment);
        const isStarting = state === VmState.Starting;
        const isStopping = state === VmState.Stopping;
        const isRunning = state === VmState.Running;
        const isStopped = state === VmState.Stopped;
        const isResettingPassword = state === VmState.ResettingPassword;
        const isCreating = state === VmState.Creating;
        const canResetPassword = !isMoving && (isRunning || isStopped) && !isResettingPassword && !useSharedPassword;
        const hasUnlimitedQuota = usageQuota === undefined;
        const isScheduleOnly = !hasUnlimitedQuota && !(quotaHours > 0);
        const canStart = !isMoving && isStopped && (isScheduleRunning || hasUnlimitedQuota || hoursUsed < quotaHours);
        const connectionTypes = getConnectionTypes(rdpAuthority, rdpBrowserUrl, sshAuthority, sshBrowserUrl);
        studentVms.push({
            vmState: state,
            canConnect: isRunning,
            canStart,
            canStop: isRunning,
            canResetPassword,
            description,
            hoursUsed,
            id,
            quotaHours,
            title,
            hasUnlimitedQuota,
            isScheduleOnly,
            isCreating,
            isStarting,
            isRunning,
            isStopping,
            isStopped,
            isMoving,
            isResettingPassword,
            lastPasswordReset,
            username,
            rdpAuthority,
            rdpBrowserUrl,
            sshAuthority,
            sshBrowserUrl,
            isScheduleRunning,
            isWindows: os === OperatingSystem.Windows,
            isVNextLab: false,
            useSharedPassword,
            connectionTypes,
            supportInfo,
        });
    });
    return studentVms;
});
function convertLabsApiVirtualMachineStateToVmState(virutalMachineState) {
    switch (virutalMachineState) {
        case LabsApiModels.VirtualMachineState.Creating:
            return VmState.Creating;
        case LabsApiModels.VirtualMachineState.Deleting:
            return VmState.Deleting;
        case LabsApiModels.VirtualMachineState.Moving:
            return VmState.Moving;
        case LabsApiModels.VirtualMachineState.ResettingPassword:
            return VmState.ResettingPassword;
        case LabsApiModels.VirtualMachineState.Reimaging:
            return VmState.Reimaging;
        case LabsApiModels.VirtualMachineState.Redeploying:
            return VmState.Redeploying;
        case LabsApiModels.VirtualMachineState.Running:
            return VmState.Running;
        case LabsApiModels.VirtualMachineState.Starting:
            return VmState.Starting;
        case LabsApiModels.VirtualMachineState.Stopped:
            return VmState.Stopped;
        case LabsApiModels.VirtualMachineState.Stopping:
            return VmState.Stopping;
        case LabsApiModels.VirtualMachineState.Updating:
            return VmState.Updating;
        default:
            return VmState.Unknown;
    }
}
export const studentVNextVmList = memoize((virtualMachines, operations) => {
    const studentVms = [];
    let operationsArr = operations?.toArray();
    virtualMachines.forEach((virtualMachine) => {
        const { state: originalState } = virtualMachine;
        if (originalState === LabsApiModels.VirtualMachineState.Deleting) {
            return;
        }
        const { id, title, pendingVmState, quotaUsed, totalQuota, description, username, rdpAuthority, rdpInBrowserUrl: rdpBrowserUrl, sshAuthority, sshInBrowserUrl: sshBrowserUrl, osType, supportInfo, lastPasswordReset, scheduleEndTime, useSharedPassword, resourceOperationError, } = virtualMachine;
        const quotaHours = moment.duration(totalQuota).asHours();
        const hoursUsed = moment.duration(quotaUsed).asHours();
        const state = convertLabsApiVirtualMachineStateToVmState(originalState);
        const connectionTypes = getVNextConnectionTypes(rdpAuthority, rdpBrowserUrl, sshAuthority, sshBrowserUrl);
        const vmState = pendingVmState !== VmState.None ? pendingVmState : state;
        const isMoving = vmState === VmState.Moving;
        const isStarting = vmState === VmState.Starting;
        const isStopping = vmState === VmState.Stopping;
        const isRunning = vmState === VmState.Running;
        const isStopped = vmState === VmState.Stopped;
        const isResettingPassword = vmState === VmState.ResettingPassword;
        const isUnavailable = vmState === VmState.Creating;
        const isReimaging = vmState === VmState.Reimaging;
        const isRedeploying = vmState === VmState.Redeploying;
        const hasUnlimitedQuota = quotaHours === undefined;
        const isScheduleOnly = !hasUnlimitedQuota && !(quotaHours > 0);
        const quotaNotExceeded = hasUnlimitedQuota || hoursUsed < quotaHours;
        const canResetPassword = !isMoving && (isRunning || isStopped) && !isResettingPassword && quotaNotExceeded;
        const canStart = !isMoving && isStopped && quotaNotExceeded;
        const canStop = isRunning && quotaNotExceeded;
        const canReimage = (isRunning || isStopped) && quotaNotExceeded;
        const canRedeploy = (isRunning || isStopped) && quotaNotExceeded;
        const operation = operationsArr?.find((op) => op.ResourceId?.id == id);
        studentVms.push({
            vmState,
            canConnect: isRunning,
            canStart,
            canStop,
            canResetPassword,
            description,
            hoursUsed,
            id,
            quotaHours,
            title,
            hasUnlimitedQuota,
            isScheduleOnly,
            isStarting,
            isRunning,
            isStopping,
            isStopped,
            isMoving,
            isResettingPassword,
            isReimaging,
            isRedeploying,
            username,
            rdpAuthority,
            rdpBrowserUrl,
            sshAuthority,
            sshBrowserUrl,
            isWindows: osType === LabsApiModels.OsType.Windows,
            isVNextLab: true,
            connectionTypes,
            supportInfo,
            isScheduleRunning: !!scheduleEndTime,
            useSharedPassword: useSharedPassword === LabServicesModels.EnableState.Enabled,
            lastPasswordReset,
            canRedeploy,
            canReimage,
            isUnavailable,
            operation,
            resourceOperationError,
        });
    });
    return studentVms;
});
export const getStudentVmListViewModel = memoize((state) => {
    const isVNext = isVNextFeatureEnabledOrLms(state);
    const isBastionEnabled = isBastionFeatureEnabled(state);
    const isTeamsIntegrationEnabled = isTeamsMode(state);
    const isLmsIntegrationEnabled = isLmsMode(state);
    const shouldHideUserMenu = isLmsStudent(state);
    const registerStore = state.get('registerStore');
    const userEnvironmentStore = state.get('userEnvironmentStore');
    const environments = userEnvironmentStore.get('environments');
    let vms = studentVmList(environments);
    const vCurrentStartErrors = userEnvironmentStore.get('startErrors');
    const vCurrentStopErrors = userEnvironmentStore.get('stopErrors');
    const vCurrentResetPasswordErrors = userEnvironmentStore.get('resetPasswordErrors');
    const sorter = stringSorter(false);
    let supportSections = Map(vms
        .filter((o) => o.title)
        .sort((a, b) => sorter(a.title ?? '', b.title ?? ''))
        .map((o) => [o.title, isValidSupportInfo(o.supportInfo) ? o.supportInfo : {}]));
    let registrationState = registerStore.get('registrationState');
    if (!isVNext) {
        const isLoading = storeIsLoading(userEnvironmentStore.loadState);
        const listEnvironmentsError = userEnvironmentStore.get('listEnvironmentsError');
        return {
            vms,
            listEnvironmentsError,
            vCurrentStartErrors,
            vCurrentStopErrors,
            vCurrentResetPasswordErrors,
            supportSections,
            registrationState,
            isLoading,
            isBastionEnabled,
            isTeamsIntegrationEnabled,
            isVNextFeaureEnabled: isVNext,
            isLmsIntegrationEnabled,
            shouldHideUserMenu,
        };
    }
    else {
        const vNextRegisterStore = state.get('vNextRegisterStore');
        const vNextUserVirtualMachineStore = state.get('vNextUserVirtualMachineStore');
        const vNextVirtualMachines = vNextUserVirtualMachineStore.get('virtualMachines');
        const vNextListEnvironmentsError = vNextUserVirtualMachineStore.get('listVirtualMachinesError');
        const vNextStartErrors = vNextUserVirtualMachineStore.get('startErrors');
        const vNextStopErrors = vNextUserVirtualMachineStore.get('stopErrors');
        const vNextResetPasswordErrors = vNextUserVirtualMachineStore.get('resetPasswordErrors');
        const reimageErrors = vNextUserVirtualMachineStore.get('reimageErrors');
        const redeployErrors = vNextUserVirtualMachineStore.get('redeployErrors');
        const operations = vNextUserVirtualMachineStore.get('operations');
        const vNextVms = studentVNextVmList(vNextVirtualMachines, operations);
        const vNextRegistrationState = vNextRegisterStore.get('registrationState');
        const vNextStoreIsLoading = storeIsLoading(vNextUserVirtualMachineStore.loadState);
        vms = vms.concat(vNextVms);
        supportSections = Map(vms
            .filter((o) => o.title)
            .sort((a, b) => sorter(a.title ?? '', b.title ?? ''))
            .map((o) => [o.title, isValidSupportInfo(o.supportInfo) ? o.supportInfo : {}]));
        registrationState = vNextRegistrationState;
        return {
            vms,
            supportSections,
            listEnvironmentsError: vNextListEnvironmentsError,
            vCurrentStartErrors,
            vCurrentStopErrors,
            vCurrentResetPasswordErrors,
            isLoading: vNextStoreIsLoading,
            registrationState,
            isBastionEnabled,
            isTeamsIntegrationEnabled,
            reimageErrors,
            redeployErrors,
            isVNextFeaureEnabled: isVNext,
            vNextStartErrors,
            vNextStopErrors,
            vNextResetPasswordErrors,
            isLmsIntegrationEnabled,
            shouldHideUserMenu,
            operations,
        };
    }
});
export const getStudentVmListContainerModel = memoize((state) => {
    const groupId = getGroupId(state);
    const studentVmListViewModel = getStudentVmListViewModel(state);
    const shouldFilterByGroupId = shouldFilterLabsByGroupId(state);
    return {
        studentVmListViewModel,
        groupId,
        shouldFilterByGroupId,
    };
});
