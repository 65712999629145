import { ConnectionType } from '../../data/models/environment-common';
export const areConnectionTypesValid = (isLinux, connectionTypes, isVNext) => {
    if (!connectionTypes || connectionTypes.length === 0) {
        // Must have at least one connection type
        return false;
    }
    else if (!isVNext &&
        isLinux &&
        !(connectionTypes.includes(ConnectionType.Ssh) || connectionTypes.includes(ConnectionType.SshInBrowser))) {
        // Linux OS must have at least one SSH connection type
        return false;
    }
    else {
        return true;
    }
};
