import { Stack, TextField } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PasswordField from '../../common/password-field/password-field';
import Constants from '../../utils/constants';
import { validateUsername } from './username-validator';
export const CreateLabCredentials = (props) => {
    const { username, password, isPasswordValid, usernamePlaceholder, passwordPlaceholder, usernameLabel, passwordLabel, passwordInfoTipEnabled, headerMessage, isWindows, isVNext, onUsernameChange, onPasswordChange, onUsernameValidationChange, onPasswordValidationChange, } = props;
    const intl = useIntl();
    return (<Stack tokens={{ childrenGap: isVNext ? 19 : 30 }}>
            <Stack.Item style={{ paddingTop: 0 }}>
                <FormattedMessage {...headerMessage}/>
            </Stack.Item>
            <Stack.Item>
                <TextField label={usernameLabel} placeholder={usernamePlaceholder} autoFocus={true} spellCheck={false} maxLength={isWindows ? 20 : 64} value={username} validateOnLoad={!!username} required={true} aria-required={true} onChange={(_event, value) => onUsernameChange(value || '')} onGetErrorMessage={(value) => {
        const result = validateUsername(value, isWindows, intl);
        onUsernameValidationChange(!result);
        return result || '';
    }}/>
            </Stack.Item>
            <Stack.Item>
                <PasswordField infoTipEnabled={passwordInfoTipEnabled} customLabel={passwordLabel} placeholder={passwordPlaceholder} {...(isWindows ? Constants.WindowsPasswordLength : Constants.LinuxPasswordLength)} value={password} validateOnLoad={!!password} isValid={isPasswordValid} required={true} onValidationChange={(value) => onPasswordValidationChange(value)} onChange={(_event, value) => onPasswordChange(value || '')}/>
            </Stack.Item>
        </Stack>);
};
export default CreateLabCredentials;
