import _ from 'lodash';
import ArmProvider from './arm-data-provider';
import { SubscriptionEnabledState } from '../models/subscription';
import Constants from '../../utils/constants';
function processSubs(response) {
    const subs = response && response.value ? response.value : [];
    const enabledSubs = _.filter(subs, (sub) => sub.state.toLowerCase() === SubscriptionEnabledState);
    return enabledSubs;
}
export async function listSubscriptionsForTenant(accessToken, locale, language) {
    let response = await ArmProvider.get(Constants.Urls.Subscriptions, accessToken, locale, language);
    let subsResponse = response && response.json ? (await response.json()) : undefined;
    let enabledSubs = processSubs(subsResponse);
    while (!!subsResponse && !!subsResponse.nextLink) {
        response = await ArmProvider.get(subsResponse.nextLink.slice('https://management.azure.com'.length), accessToken, locale, language);
        subsResponse = response && response.json ? (await response.json()) : undefined;
        const pageSubs = processSubs(subsResponse);
        enabledSubs = enabledSubs.concat(pageSubs);
    }
    return enabledSubs;
}
const SubscriptionsProvider = {
    listSubscriptionsForTenant,
};
export default SubscriptionsProvider;
