import * as React from 'react';
import { DefaultButton, Spinner, SpinnerSize } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { commonMessages } from '../../../language/common-messages';
export const LoadingItem = (props) => {
    const intl = useIntl();
    const { formatMessage } = intl;
    const label = formatMessage(commonMessages.loadingText);
    return (<DefaultButton {...props}>
            <Spinner labelPosition="right" size={SpinnerSize.medium} label={label} styles={{
        root: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            verticalAlign: 'middle',
            backgroundColor: 'inherit',
            color: 'inherit',
        },
        label: {
            backgroundColor: 'inherit',
            color: 'inherit',
        },
    }}/>
        </DefaultButton>);
};
export default LoadingItem;
