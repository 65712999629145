import { routerActions } from 'connected-react-router/immutable';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PageTitle from '../common/page/page-title';
import { clearGetError, clearLoadError as clearEnvironmentError, } from '../redux/actions/environment/environment-action-creators';
import { clearLoadScheduleError as clearScheduleError } from '../redux/actions/schedule/schedule-action-creators';
import { clearLoadTemplateError as clearTemplateError } from '../redux/actions/template/template-action-creators';
import { clearLoadUsersError as clearUsersError } from '../redux/actions/user/user-action-creators';
import Routes from '../utils/routes';
import Dashboard from './dashboard';
import dashboardMessages from './dashboard-messages';
import { getDashboardContainerModel } from './dashboard-selectors';
class DashboardContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this.loadTemplate = this.loadTemplate.bind(this);
        this.loadVirtualMachines = this.loadVirtualMachines.bind(this);
        this.loadUsers = this.loadUsers.bind(this);
        this.loadSchedules = this.loadSchedules.bind(this);
        this.navigateRoute = this.navigateRoute.bind(this);
        this._clearTemplateError = this._clearTemplateError.bind(this);
    }
    loadTemplate(id) {
        this.props.push({ pathname: Routes.Template(id), search: this.props.search });
    }
    loadVirtualMachines(id) {
        this.props.push({ pathname: Routes.VirtualMachines(id), search: this.props.search });
    }
    loadUsers(id) {
        this.props.push({ pathname: Routes.Users(id), search: this.props.search });
    }
    loadSchedules(id) {
        this.props.push({ pathname: Routes.Schedule(id), search: this.props.search });
    }
    navigateRoute(pathname) {
        const { push, search } = this.props;
        push({ pathname, search });
    }
    _clearTemplateError() {
        const { isVNext, clearTemplateError, clearGetError, dashboardViewModel } = this.props;
        const { templateLoadError } = dashboardViewModel;
        if (!isVNext) {
            clearTemplateError();
        }
        else {
            if (!!templateLoadError) {
                clearGetError(templateLoadError.id);
            }
        }
    }
    render() {
        const { intl, dashboardViewModel } = this.props;
        const msg = intl.formatMessage;
        return (<>
                <PageTitle subtitle={msg(dashboardMessages.dashboardPageTitle)}/>
                <Dashboard dashboardViewModel={dashboardViewModel} clearTemplateError={this._clearTemplateError} clearScheduleError={this.props.clearScheduleError} clearEnvironmentError={this.props.clearEnvironmentError} clearUsersError={this.props.clearUsersError} loadTemplate={this.loadTemplate} loadVirtualMachines={this.loadVirtualMachines} loadUsers={this.loadUsers} loadSchedules={this.loadSchedules} navigateRoute={this.navigateRoute}/>
            </>);
    }
}
const mapDispatchToProps = {
    push: routerActions.push,
    clearTemplateError,
    clearScheduleError,
    clearEnvironmentError,
    clearUsersError,
    clearGetError,
};
const mapStateToProps = (state) => {
    return getDashboardContainerModel(state);
};
export const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(DashboardContainerInjected));
export default DashboardContainer;
