import Settings from '../../settings/settings';
export const AuthenticationState = {
    NotStarted: 'NotStarted',
    Redirecting: 'Redirecting',
    PoppingUp: 'PoppingUp',
    Authenticating: 'Authenticating',
    Authenticated: 'Authenticated',
    AuthenticationFailed: 'AuthenticationFailed',
    AuthenticationError: 'AuthenticationError',
    SigningOut: 'SigningOut',
};
export const UserType = {
    Unknown: '',
    LabUser: 'LabUser',
    LabOwner: 'LabOwner',
};
export const IdentityErrorCode = {
    LoginRequired: 'login_required',
    Renewal: 'token_renewal_error',
    InteractionRequired: 'interaction_required',
    DuplicateRequest: 'acquiretoken_progress_error',
    UserLogin: 'user_login_error',
    InvalidState: 'invalid_state_error',
    UserCanceled: 'user_canceled',
    AccessDenied: 'access_denied',
    Failed: 'failed',
    EmptyIdToken: 'null_or_empty_id_token',
    EndpointsError: 'endpoints_resolution_error',
    InvalidRequest: 'invalid_request',
    PopupWindowError: 'popup_window_error',
    Unknown: 'unknown',
};
export const graphScopes = ['openid', 'email', 'profile', 'GroupMember.Read.All'];
export const armScopes = ['https://management.azure.com/.default'];
export const preAuthorizedScopes = [...armScopes];
export const labsApiScopes = [Settings.LabsApiScope];
