// move to config?
const graphGroupsUrlBase = 'https://graph.microsoft.com/v1.0/groups/';
const graphGroupRequestContentType = 'content/json';
async function getGroup(accessToken, groupId) {
    const headers = new Headers();
    headers.append('Content-Type', graphGroupRequestContentType);
    headers.append('Authorization', `Bearer ${accessToken}`);
    const result = await fetch(graphGroupsUrlBase + groupId, {
        method: 'GET',
        headers,
        mode: 'cors',
    });
    if (result.status === 404) {
        return undefined;
    }
    else if (result.status === 401) {
        const response = await result.json();
        throw new Error(`An error occurred while acquiring the group ${groupId}. Status: ${result.status}, Response: ${response}`);
    }
    else if (result.status === 200) {
        const response = await result.json();
        const group = response;
        return group;
    }
    const response = await result.json();
    throw new Error(`An error occurred while acquiring the group ${groupId}. Status: ${result.status}, Response: ${response}`);
}
async function listGroups(accessToken, filterText) {
    const headers = new Headers();
    headers.append('Content-Type', graphGroupRequestContentType);
    headers.append('Authorization', `Bearer ${accessToken}`);
    const groupsFetchUrl = filterText == ''
        ? graphGroupsUrlBase
        : `${graphGroupsUrlBase}?$filter=startsWith(displayName, '${filterText}')`;
    const result = await fetch(groupsFetchUrl, {
        method: 'GET',
        headers,
        mode: 'cors',
    });
    if (result.status === 404) {
        return [];
    }
    else if (result.status === 401) {
        const response = await result.json();
        throw new Error(`An error occurred while attempting to list groups. Status: ${result.status}, Response: ${response}`);
    }
    else if (result.status === 200) {
        const response = await result.json();
        const groups = response.value;
        return groups;
    }
    const response = await result.json();
    throw new Error(`An error occurred while attempting to list groups. Status: ${result.status}, Response: ${response}`);
}
export async function getGroupNameById(accessToken, groupId) {
    const group = await getGroup(accessToken, groupId);
    return group?.displayName;
}
function minimizeGroup(group) {
    return {
        name: group.displayName,
        id: group.id,
        description: group.description,
    };
}
function isTeamsGroup(group) {
    if (!!group.creationOptions && group.creationOptions.includes('Team')) {
        return true;
    }
    if (!!group.resourceProvisioningOptions && group.resourceProvisioningOptions.includes('Team')) {
        return true;
    }
    return false;
}
export async function getGroups(accessToken, filterText) {
    const groups = await listGroups(accessToken, filterText);
    return groups.filter((group) => !isTeamsGroup(group)).map(minimizeGroup);
}
