import { defineMessages } from 'react-intl';
export const labAppNavMessages = defineMessages({
    template: {
        id: 'LabAppNavTemplateButton',
        defaultMessage: 'Template',
        description: 'Button text and aria label for the template page navigation item.',
    },
    virtualMachinePool: {
        id: 'LabAppNavVirtualMachinePoolButton',
        defaultMessage: 'Virtual machine pool',
        description: 'Button text and aria label for the virtual machine pool page navigation item.',
    },
    dashboard: {
        id: 'LabAppNavDashboardButton',
        defaultMessage: 'Dashboard',
        description: 'Button text and aria label for the dashboard page navigation item.',
    },
    settings: {
        id: 'LabAppNavSettingsButton',
        defaultMessage: 'Settings',
        description: 'Button text and aria label for the settings page navigation item.',
    },
});
export default labAppNavMessages;
