import memoize from 'memoize-one';
import { caseInsensitiveCultureInvariantCompare } from '../../utils/string-comparison';
import { ResourceId } from '../../utils/resource-id';
import { isVNextFeatureEnabledOrLms, storeIsLoading } from './common-selectors';
import { getCurrentLabAccount } from './lab-account-selectors';
import { getCurrentResourceGroupLabPlans, getValidLabPlans } from './vnext/lab-plan-selectors';
import { CoreQuotaStatus, determineVCurrentQuotaStatus, getCoreLimits } from './core-limit-selectors';
import { InitializationState } from '../state/common-store';
import { getVNextSkuData } from './vnext/sku-selectors';
export const getCurrentLabParentId = memoize((state) => {
    const resourceId = state.get('labParentResourceStore').get('currentLabParentId');
    return resourceId;
});
export const getCurrentLabAccountId = memoize((store) => {
    const currentLabParentId = getCurrentLabParentId(store);
    const isVNext = isVNextFeatureEnabledOrLms(store);
    return !isVNext ? currentLabParentId : isLabParentLabAccount(currentLabParentId) ? currentLabParentId : '';
});
export const isCurrentLabParentLabAccount = memoize((store) => {
    const currentLabParentId = getCurrentLabParentId(store);
    return isLabParentLabAccount(currentLabParentId);
});
export const getCurrentResourceGroup = memoize((store) => {
    const resourceGroups = getResourceGroups(store);
    const currentLabParentId = getCurrentLabParentId(store);
    return resourceGroups.find((id) => caseInsensitiveCultureInvariantCompare(id, currentLabParentId) === 0);
});
export const isCurrentLabParentResourceGroup = memoize((store) => {
    const currentLabParentId = getCurrentLabParentId(store);
    return isLabParentResourceGroup(currentLabParentId);
});
export function isLabParentLabAccount(id) {
    const resource = new ResourceId(id);
    return resource.type?.toLowerCase() === 'labaccounts' ? true : false;
}
export function isLabParentResourceGroup(id) {
    const resource = new ResourceId(id);
    return resource.type?.toLowerCase() === 'resourcegroups' ? true : false;
}
export const isLoadingLabParents = memoize((state) => {
    const loadState = state.get('labParentResourceStore').get('loadState');
    return storeIsLoading(loadState);
});
export const getResourceGroups = memoize((state) => {
    const resourceGroupLabsMap = state.get('vNextLabStore').get('resourceGroupLabsMap');
    const labPlans = getValidLabPlans(state);
    const labsResourceGroups = resourceGroupLabsMap.keySeq();
    let resourceGroups = labsResourceGroups.toList();
    for (let index = 0; index < labPlans.size; index++) {
        const labPlanId = labPlans.get(index).id;
        const resourceId = new ResourceId(labPlanId, true);
        const resourceGroup = resourceId.getResourceGroupId();
        if (!!resourceGroup &&
            !resourceGroups.find((id) => caseInsensitiveCultureInvariantCompare(id, resourceGroup) === 0)) {
            resourceGroups = resourceGroups.push(resourceGroup);
        }
    }
    return resourceGroups;
});
export const doesCurrentLabParentExist = memoize((store) => {
    return !!getCurrentLabAccount(store) || !!getCurrentResourceGroup(store);
});
export const shouldDisableNewLabCreation = memoize((store) => {
    const isLabAccount = isCurrentLabParentLabAccount(store);
    if (isLabAccount) {
        const coreQuotaData = getCoreLimits(store);
        const quotaStatus = determineVCurrentQuotaStatus(coreQuotaData);
        return quotaStatus === CoreQuotaStatus.Restricted || quotaStatus === CoreQuotaStatus.QuotaExceeded;
    }
    else {
        const labPlans = getCurrentResourceGroupLabPlans(store);
        const sku = getVNextSkuData(store);
        return labPlans.size === 0 || !sku || sku.length === 0;
    }
});
export const isRefreshingLabParentResources = memoize((state) => {
    const labParentResourcesStore = state.get('labParentResourceStore');
    const refreshInitializationState = labParentResourcesStore.get('refreshInitializationState');
    return refreshInitializationState === InitializationState.Initializing;
});
export const hasRefreshLabParentResourcesError = memoize((state) => {
    const loadSubscriptionError = state.get('subscriptionStore').get('error');
    const labParentResourcesStore = state.get('labParentResourceStore');
    const labParentResourceErrors = labParentResourcesStore.get('errors');
    const refreshError = labParentResourcesStore.get('refreshError');
    return !!loadSubscriptionError || labParentResourceErrors.size > 0 || !!refreshError;
});
export const hasRefreshedLabParentResources = memoize((state) => {
    const labParentResourcesStore = state.get('labParentResourceStore');
    const refreshInitializationState = labParentResourcesStore.get('refreshInitializationState');
    return refreshInitializationState === InitializationState.Success;
});
