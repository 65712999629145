import { call, put, select, takeLatest, race, take } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import LabAccountProvider from '../../../data/providers/lab-account-provider';
import { ResourceId } from '../../../utils/resource-id';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { CoreLimitActionType } from '../../actions/core-limit/core-limit-actions';
import { getCoreRestrictionsAndUsages as getCoreRestrictionsActionCreator, getCoreRestrictionsAndUsagesSuccess, getCoreRestrictionsAndUsagesError, getCoreRestrictionsAndUsagesCancelled, getVNextCoreUsagesSuccess, } from '../../actions/core-limit/core-limit-action-creators';
import { trackException } from '../../../utils/telemetry/telemetry-channel';
import { getCoreLimits, convertToRestModel } from '../../selectors/core-limit-selectors';
import { MlCoreFamily } from '../../../data/models/core-quota-data';
import { getLabPricingAndAvailabilityData } from '../../selectors/pricing-and-availability-selectors';
import { getIsGpu, getCores, getCurrentTemplate, getTemplate, hasBeenPublished, } from '../../selectors/template-selectors';
import { isRouteTeamsConfig } from '../../selectors/route-selector';
import CoreUsageProvider from '../../../data/providers/vnext/labs-api/core-usage-provider';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { LabServicesModels } from 'lab-services';
import { getVNextLab } from '../../selectors/vnext/lab-selectors';
export function* getCoreRestrictionsAndUsage(action) {
    const { id } = action;
    try {
        const resourceId = new ResourceId(id);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { coreRestrictionsAndUsages, changed } = yield race({
            coreRestrictionsAndUsages: call(LabAccountProvider.getCoreRestrictionsAndUsages, resourceId, accessToken, locale, language),
            changed: race([
                take(CoreLimitActionType.SELECT_LAB_PARENT_RESOURCE),
                take(CoreLimitActionType.SELECT_LAB),
                take(CoreLimitActionType.PUBLISH_TEMPLATE),
                take(CoreLimitActionType.UPDATE_LAB_SUCCESS),
                take(CoreLimitActionType.CREATE_LAB_SUCCESS),
            ]),
        });
        if (changed) {
            yield put(getCoreRestrictionsAndUsagesCancelled());
        }
        else {
            yield put(getCoreRestrictionsAndUsagesSuccess(coreRestrictionsAndUsages));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, id, FailureOperation.GetCoreRestrictionsAndUsages, err ? err.code : undefined);
        yield put(getCoreRestrictionsAndUsagesError(id, error));
    }
}
export function* getCoreLimitsOnLabParentSelect(action) {
    const { resourceId } = action;
    const isTeamsConfigPage = yield select(isRouteTeamsConfig);
    if (!isTeamsConfigPage) {
        try {
            yield put(getCoreRestrictionsActionCreator(resourceId));
        }
        catch (err) {
            trackException(undefined, err);
        }
    }
}
export function* getCoreLimitsOnLabSelect(action) {
    const { labId } = action;
    try {
        const resourceId = new ResourceId(labId);
        const labAccountId = resourceId.parent?.id ?? '';
        yield put(getCoreRestrictionsActionCreator(labAccountId || labId));
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* getCoreLimitsOnPublishTemplate(action) {
    const { maxUsersInLab, templateId } = action;
    try {
        const template = yield select(getTemplate, templateId);
        // if template has already been published, cores are already accounted for
        if (!hasBeenPublished(template)) {
            // unfortunately the short running publish call does not update core usage in the DB
            //  as the core usage is updated in the publish job
            // to avoid polling and to keep the UI data consistent with what's happening in the DB, calculate what the usage would be here
            const sizeData = yield select(getLabPricingAndAvailabilityData);
            const isGpu = getIsGpu(sizeData);
            const coresPerVm = getCores(sizeData);
            const previousCoreData = yield select(getCoreLimits);
            if (previousCoreData && previousCoreData.usages && coresPerVm) {
                const newCores = maxUsersInLab * coresPerVm;
                const usages = previousCoreData.usages;
                const coreFamily = isGpu ? MlCoreFamily.GPU : MlCoreFamily.Standard;
                const newUsage = usages.get(coreFamily) + newCores;
                const newUsages = usages.set(coreFamily, newUsage);
                const newCoreData = { ...previousCoreData, usages: newUsages };
                const coreRestrictionsAndUsages = convertToRestModel(newCoreData);
                yield put(getCoreRestrictionsAndUsagesSuccess(coreRestrictionsAndUsages));
            }
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* getCoreLimitsOnCreateLabSuccess(action) {
    const { lab } = action;
    try {
        const labId = lab.id;
        const resourceId = new ResourceId(labId);
        const labAccountId = resourceId.parent?.id ?? '';
        yield put(getCoreRestrictionsActionCreator(labAccountId || labId));
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* getCoreLimitsOnUpdateLabSuccess(action) {
    const { lab } = action;
    try {
        const template = yield select(getCurrentTemplate);
        if (hasBeenPublished(template)) {
            const labId = lab.id;
            const resourceId = new ResourceId(labId);
            const labAccountId = resourceId.parent?.id ?? '';
            yield put(getCoreRestrictionsActionCreator(labAccountId || labId));
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
//vNext
export function* getVNextCoreUsage(action) {
    const { id } = action;
    try {
        const resourceId = new ResourceId(id);
        const accessToken = yield call(getLabsApiAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { coreUsage, changed } = yield race({
            coreUsage: call(CoreUsageProvider.getCoreUsage, resourceId, accessToken, locale, language),
            changed: race([
                take(CoreLimitActionType.SELECT_LAB_PARENT_RESOURCE),
                take(CoreLimitActionType.SELECT_LAB),
                take(CoreLimitActionType.PUBLISH_TEMPLATE),
                take(CoreLimitActionType.UPDATE_LAB_SUCCESS),
                take(CoreLimitActionType.CREATE_LAB_SUCCESS),
            ]),
        });
        if (changed) {
            yield put(getCoreRestrictionsAndUsagesCancelled());
        }
        else {
            yield put(getVNextCoreUsagesSuccess(coreUsage));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, id, FailureOperation.GetCoreRestrictionsAndUsages, err ? err.code : undefined);
        yield put(getCoreRestrictionsAndUsagesError(id, error));
    }
}
export function* getVNextCoreUsageOnUpdateLabSuccess(action) {
    const { lab } = action;
    try {
        if (lab.state === LabServicesModels.LabState.Published) {
            const labId = lab.id;
            const resourceId = new ResourceId(labId, true);
            const labParentId = resourceId.parent?.id ?? '';
            yield put(getCoreRestrictionsActionCreator(labParentId));
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* getCoreUsageOnPublishVNextLab(action) {
    const { labId } = action;
    try {
        const lab = yield select(getVNextLab, labId);
        if (lab.state != LabServicesModels.LabState.Published) {
            const resourceId = new ResourceId(labId, true);
            const labParentId = resourceId.parent?.id ?? '';
            yield put(getCoreRestrictionsActionCreator(labParentId));
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* getCoreLimitsSaga() {
    yield labParentBasedTakeLatest(CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE, getCoreRestrictionsAndUsage, getVNextCoreUsage);
}
export function* getCoreLimitsOnSelectLabParentResourceSaga() {
    yield takeLatest(CoreLimitActionType.SELECT_LAB_PARENT_RESOURCE, getCoreLimitsOnLabParentSelect);
}
export function* getCoreLimitsOnSelectLabSaga() {
    yield takeLatest(CoreLimitActionType.SELECT_LAB, getCoreLimitsOnLabSelect);
}
export function* getCoreLimitsOnPublishTemplateSaga() {
    yield labParentBasedTakeLatest(CoreLimitActionType.PUBLISH_TEMPLATE, getCoreLimitsOnPublishTemplate, undefined);
}
export function* getCoreLimitsOnCreateLabSuccessSaga() {
    yield takeLatest(CoreLimitActionType.CREATE_LAB_SUCCESS, getCoreLimitsOnCreateLabSuccess);
}
export function* getCoreLimitsOnUpdateLabSuccessSaga() {
    yield labParentBasedTakeLatest(CoreLimitActionType.UPDATE_LAB_SUCCESS, getCoreLimitsOnUpdateLabSuccess, getVNextCoreUsageOnUpdateLabSuccess);
}
export function* getCoreUsageOnPublishVNextLabSaga() {
    yield labParentBasedTakeLatest(CoreLimitActionType.PUBLISH_LAB, undefined, getCoreUsageOnPublishVNextLab);
}
