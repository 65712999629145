import { call, put, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import ScheduleProvider from '../../../data/providers/schedule-provider';
import { default as VNextScheduleProvider } from '../../../data/providers/vnext/arm-api/schedule-provider';
import { ResourceId } from '../../../utils/resource-id';
import { ScheduleActionType, } from '../../actions/schedule/schedule-actions';
import { listSchedulesSuccess, listVNextSchedulesSuccess, listSchedulesError, } from '../../actions/schedule/schedule-action-creators';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
export function* listSchedules(action) {
    const { environmentId } = action;
    try {
        const resourceId = new ResourceId(environmentId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const schedules = yield call(ScheduleProvider.listSchedules, resourceId, accessToken, locale, language);
        yield put(listSchedulesSuccess(schedules));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, environmentId, FailureOperation.LoadSchedules, e ? e.code : undefined);
        yield put(listSchedulesError(error));
    }
}
export function* listVNextSchedules(action) {
    const { labId } = action;
    try {
        const resourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const schedules = yield call(VNextScheduleProvider.listSchedules, resourceId, accessToken, locale, language);
        yield put(listVNextSchedulesSuccess(schedules));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.LoadSchedules, e ? e.code : undefined);
        yield put(listSchedulesError(error));
    }
}
export function* listSchedulesSaga() {
    yield labParentBasedTakeLatest(ScheduleActionType.LIST_SCHEDULES, listSchedules, listVNextSchedules);
}
