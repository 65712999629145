import { v4 as uuid } from 'uuid';
import { Storage } from '../../utils/storage';
import { RestError, URLBuilder } from '@azure/ms-rest-js';
import { getAcceptLanguage } from '../../language/languages';
import Settings from '../../settings/settings';
import { ApiVersions, Urls } from '../../utils/constants';
import { browserName } from '../../utils/browser';
const stateStorageKey = 'lti_oidc_state';
const nonceStorageKey = 'lti_oidc_nonce';
const ltiTokenStorageKey = 'lti_token';
export function initialize() {
    setState();
    setNonce();
}
export function oidcAuth(oidcUrl) {
    const ltiAuthUrl = new URLBuilder();
    ltiAuthUrl.setHost(oidcUrl);
    ltiAuthUrl.setQueryParameter('state', Storage.getItem(stateStorageKey));
    ltiAuthUrl.setQueryParameter('nonce', Storage.getItem(nonceStorageKey));
    window.location.assign(ltiAuthUrl.toString());
}
function setState() {
    Storage.setItem(stateStorageKey, uuid(), true);
}
function setNonce() {
    Storage.setItem(nonceStorageKey, btoa(uuid()), true);
}
export function getState() {
    return Storage.getItem(stateStorageKey);
}
export function getNonce() {
    return Storage.getItem(nonceStorageKey);
}
export function setLtiToken(token) {
    Storage.setItem(ltiTokenStorageKey, token);
}
export function getLtiToken() {
    return Storage.getItem(ltiTokenStorageKey);
}
export function setLtiItems(state, nonce, token) {
    Storage.setItem(stateStorageKey, state);
    Storage.setItem(nonceStorageKey, nonce);
    Storage.setItem(ltiTokenStorageKey, token);
}
export async function hasStorageAccess() {
    return await document.hasStorageAccess();
}
export async function requestStorageAccess() {
    return await document.requestStorageAccess();
}
export async function isEdgeBrowser() {
    debugger;
    if (browserName === 'edge-chromium') {
        return true;
    }
    else {
        return false;
    }
}
export async function linkLms(labPlanId, accessToken, locale, language, ltiToken, region) {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    headers.append('Accept-Language', getAcceptLanguage(locale, language));
    headers.append('x-ml-lti-id-token', ltiToken);
    headers.set('Content-Type', 'application/json;charset=utf-8');
    const baseUri = Settings.LabsApiUrl;
    const relativeUrl = `/${region}${Urls.LinkLabPlan}?api-version=${ApiVersions.labsCurrentPreview}`;
    const url = `${baseUri}${relativeUrl}`;
    const response = await fetch(url, {
        headers: headers,
        method: 'POST',
        body: JSON.stringify({
            labPlanId,
        }),
    });
    if (response.status >= 400) {
        const errorResponse = (await response.json());
        const { Error } = errorResponse;
        throw new RestError(Error?.Message || 'A request error occurred.', Error?.Code || response.statusText);
    }
}
const LtiProvider = {
    initialize,
    oidcAuth,
    getState,
    getNonce,
    setLtiToken,
    getLtiToken,
    setLtiItems,
    hasStorageAccess,
    requestStorageAccess,
    linkLms,
    isEdgeBrowser,
};
export default LtiProvider;
