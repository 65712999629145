import { call, put, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import ScheduleProvider from '../../../data/providers/schedule-provider';
import { default as VNextScheduleProvider } from '../../../data/providers/vnext/arm-api/schedule-provider';
import { ResourceId } from '../../../utils/resource-id';
import { ScheduleActionType } from '../../actions/schedule/schedule-actions';
import { deleteScheduleSuccess, deleteScheduleError } from '../../actions/schedule/schedule-action-creators';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
export function* deleteSchedule(action) {
    const { scheduleId } = action;
    try {
        const resourceId = new ResourceId(scheduleId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(ScheduleProvider.deleteSchedule, resourceId, accessToken, locale, language);
        yield put(deleteScheduleSuccess(scheduleId));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, scheduleId, FailureOperation.DeleteSchedule, e ? e.code : undefined);
        yield put(deleteScheduleError(error));
    }
}
export function* deleteVNextSchedule(action) {
    const { scheduleId } = action;
    try {
        const resourceId = new ResourceId(scheduleId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(VNextScheduleProvider.deleteSchedule, resourceId, accessToken, locale, language);
        yield put(deleteScheduleSuccess(scheduleId));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, scheduleId, FailureOperation.DeleteSchedule, e ? e.code : undefined);
        yield put(deleteScheduleError(error));
    }
}
export function* deleteScheduleSaga() {
    yield labParentBasedTakeLatest(ScheduleActionType.DELETE_SCHEDULE, deleteSchedule, deleteVNextSchedule);
}
