import * as React from 'react';
import { Field } from 'react-final-form';
import { ConnectionTypeDropdown } from './connection-type-dropdown';
import { areConnectionTypesValid } from './validators';
export const ConnectionTypeDropdownField = (props) => {
    const { name, isLinux, isBastionSupported, clientTypesDisabled, disabled, isVNext } = props;
    return (<Field name={name} validate={(value) => {
        // final form expects undefined if valid and an error value if not
        const isValid = areConnectionTypesValid(isLinux, value, isVNext);
        const error = isValid ? undefined : true;
        return error;
    }}>
            {(fieldProps) => (<ConnectionTypeDropdown disabled={disabled} isLinux={isLinux} isVNext={isVNext} isBastionSupported={isBastionSupported} clientTypesDisabled={clientTypesDisabled} value={fieldProps.input.value} valid={fieldProps.meta.valid || false} pristine={fieldProps.meta.pristine} onChange={fieldProps.input.onChange}/>)}
        </Field>);
};
