export const LtiActionType = {
    LTI_START_OIDC: 'LTI_START_OIDC',
    LTI_START_OIDC_ERROR: 'LTI_START_OIDC_ERROR',
    LTI_START_OIDC_REDIRECTING: 'LTI_START_OIDC_REDIRECTING',
    LTI_SIGN_IN: 'LTI_SIGN_IN',
    LTI_SIGN_IN_SUCCESS: 'LTI_SIGN_IN_SUCCESS',
    LTI_SIGN_IN_ERROR: 'LTI_SIGN_IN_ERROR',
    SET_LTI_CONTEXT: 'SET_LTI_CONTEXT',
    SET_MEMBERSHIP_URL: 'SET_MEMBERSHIP_URL',
    SET_LTI_ROLES: 'SET_LTI_ROLES',
    GET_LTI_TOKEN: 'GET_LTI_TOKEN',
    GET_LTI_TOKEN_ERROR: 'GET_LTI_TOKEN_ERROR',
    GET_LTI_TOKEN_SUCCESS: 'GET_LTI_TOKEN_SUCCESS',
    LTI_REQUEST_WEB_STORAGE_ACCESS: 'LTI_REQUEST_WEB_STORAGE_ACCESS',
    LTI_REQUEST_WEB_STORAGE_ACCESS_SUCCESS: 'LTI_REQUEST_WEB_STORAGE_ACCESS_SUCCESS',
    LTI_REQUEST_WEB_STORAGE_ACCESS_ERROR: 'LTI_REQUEST_WEB_STORAGE_ACCESS_ERROR',
    SET_LMS_INSTANCE: 'SET_LMS_INSTANCE',
};
