import { List, Record, Map } from 'immutable';
import { UserEnvironmentActionType, } from '../../actions/user-environment/user-environment-actions';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { Context } from '../../action-context';
import { VmState } from '../../../data/models/environment-common';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { mergeOperations } from './virtual-machine-reducer';
import { OperationsResultActionType, } from '../../../redux/actions/operations/operations-result-actions';
function setPendingVirtualMachineState(virtualMachineId, virtualMachines, pendingVmState) {
    virtualMachines = virtualMachines.update(virtualMachines.findIndex((vm) => caseInsensitiveCultureInvariantCompare(vm.id, virtualMachineId) === 0), (vm) => {
        return { ...vm, pendingVmState };
    });
    return virtualMachines;
}
function clearPendingVirtualMachineState(virtualMachineId, virtualMachines) {
    virtualMachines = virtualMachines.update(virtualMachines.findIndex((vm) => caseInsensitiveCultureInvariantCompare(vm.id, virtualMachineId) === 0), (vm) => {
        return { ...vm, pendingVmState: VmState.None };
    });
    return virtualMachines;
}
function addVirtualMachineError(virtualMachineId, error, errorMap) {
    let errors = errorMap.get(virtualMachineId);
    errors = errors ? errors.concat(error) : List([error]);
    errorMap = errorMap.set(virtualMachineId, errors);
    return errorMap;
}
function clearSingleVirtualMachineError(virtualMachineId, errorIndex, errorMap) {
    let errors = errorMap.get(virtualMachineId);
    if (!errors || errorIndex >= errors.count()) {
        return errorMap;
    }
    errors = errors.remove(errorIndex);
    errorMap = errors.count() > 0 ? errorMap.set(virtualMachineId, errors) : errorMap.remove(virtualMachineId);
    return errorMap;
}
function clearVirtualMachineError(virtualMachineId, errorMap) {
    return errorMap.remove(virtualMachineId);
}
function listUserVirtualMachines(state, action) {
    const oldLoadState = state.get('loadState');
    return state.merge({
        loadState: oldLoadState === LoadingStoreState.NotStarted || oldLoadState === LoadingStoreState.Loading
            ? LoadingStoreState.Loading
            : LoadingStoreState.Updating,
    });
}
function listUserVirtualMachinesCancelled(state, action) {
    const oldLoadState = state.get('loadState');
    return state.merge({
        loadState: oldLoadState == LoadingStoreState.Loading ? LoadingStoreState.NotStarted : LoadingStoreState.Loaded,
    });
}
function listAllUserVirtualMachinesSuccess(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loaded,
    });
}
function listVNextUserVirtualMachinesSuccess(state, action) {
    const { virtualMachines } = action;
    return state.merge({
        virtualMachines: List(virtualMachines),
        listVirtualMachinesError: undefined,
    });
}
function listUserVirtualMachinesError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: state.loadState === LoadingStoreState.Loading
            ? LoadingStoreState.LoadingFailed
            : LoadingStoreState.UpdateFailed,
        listVirtualMachinesError: error,
    });
}
function clearListUserVirtualMachinesError(state, action) {
    return state.merge({
        listVirtualMachinesError: undefined,
    });
}
function clearErrors(state, action) {
    return state.merge({
        listVirtualMachinesError: undefined,
        startErrors: Map(),
        stopErrors: Map(),
        resetPasswordErrors: Map(),
    });
}
function startVirtualMachine(state, action) {
    const { environmentId } = action;
    let startErrors = state.get('startErrors');
    startErrors = clearVirtualMachineError(environmentId, startErrors);
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingVirtualMachineState(environmentId, virtualMachines, VmState.Starting);
    return state.merge({
        virtualMachines,
        startErrors,
    });
}
function startVirtualMachineAccepted(state, action) {
    const { operationIds } = action;
    let prevOperations = state.get('operations') || List();
    let operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function stopVirtualMachineAccepted(state, action) {
    const { operationIds } = action;
    let prevOperations = state.get('operations') || List();
    let operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function reimageVirtualMachineAccepted(state, action) {
    const { operationIds } = action;
    let prevOperations = state.get('operations') || List();
    let operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function redeployVirtualMachineAccepted(state, action) {
    const { operationIds } = action;
    let prevOperations = state.get('operations') || List();
    let operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function userOperationsResultSuccess(state, action) {
    const { operationIds } = action;
    let prevOperations = state.get('operations') || List();
    let operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function startVirtualMachineFailed(state, action) {
    const { error, environmentId } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = clearPendingVirtualMachineState(environmentId, virtualMachines);
    let startErrors = state.get('startErrors');
    startErrors = addVirtualMachineError(environmentId, error, startErrors);
    return state.merge({
        virtualMachines,
        startErrors,
    });
}
function clearStartVirtualMachineError(state, action) {
    const { environmentId, errorIndex } = action;
    let startErrors = state.get('startErrors');
    startErrors = clearSingleVirtualMachineError(environmentId, errorIndex, startErrors);
    return state.merge({
        startErrors,
    });
}
function stopVirtualMachine(state, action) {
    const { environmentId } = action;
    let stopErrors = state.get('stopErrors');
    stopErrors = clearVirtualMachineError(environmentId, stopErrors);
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingVirtualMachineState(environmentId, virtualMachines, VmState.Stopping);
    return state.merge({
        virtualMachines,
        stopErrors,
    });
}
function stopVirtualMachineFailed(state, action) {
    const { error, environmentId } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = clearPendingVirtualMachineState(environmentId, virtualMachines);
    let stopErrors = state.get('stopErrors');
    stopErrors = addVirtualMachineError(environmentId, error, stopErrors);
    return state.merge({
        virtualMachines,
        stopErrors,
    });
}
function clearStopVirtualMachineError(state, action) {
    const { environmentId, errorIndex } = action;
    let stopErrors = state.get('stopErrors');
    stopErrors = clearSingleVirtualMachineError(environmentId, errorIndex, stopErrors);
    return state.merge({
        stopErrors,
    });
}
// we use the same vCurrent action creator rather than creating a new one for resetting password.
// we don't create new action creators to avoid passing two DispatchProps and deciding which action to dispatch in the component.
function resetVirtualMachinePassword(state, action) {
    const { environmentId } = action;
    let resetPasswordErrors = state.get('resetPasswordErrors');
    resetPasswordErrors = clearVirtualMachineError(environmentId, resetPasswordErrors);
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingVirtualMachineState(environmentId, virtualMachines, VmState.ResettingPassword);
    return state.merge({
        virtualMachines,
        resetPasswordErrors,
    });
}
function resetVirtualMachinePasswordFailed(state, action) {
    const { error, environmentId } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = clearPendingVirtualMachineState(environmentId, virtualMachines);
    let resetPasswordErrors = state.get('resetPasswordErrors');
    resetPasswordErrors = addVirtualMachineError(environmentId, error, resetPasswordErrors);
    return state.merge({
        virtualMachines,
        resetPasswordErrors,
    });
}
function clearResetVirtualMachinePasswordError(state, action) {
    const { environmentId, errorIndex } = action;
    let resetPasswordErrors = state.get('resetPasswordErrors');
    resetPasswordErrors = clearSingleVirtualMachineError(environmentId, errorIndex, resetPasswordErrors);
    return state.merge({
        resetPasswordErrors,
    });
}
function reimageUserVirtualMachine(state, action) {
    const { environmentId } = action;
    let reimageErrors = state.get('reimageErrors');
    reimageErrors = clearVirtualMachineError(environmentId, reimageErrors);
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingVirtualMachineState(environmentId, virtualMachines, VmState.Reimaging);
    return state.merge({
        virtualMachines,
        reimageErrors,
    });
}
function reimageUserVirtualMachineError(state, action) {
    const { error, environmentId } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = clearPendingVirtualMachineState(environmentId, virtualMachines);
    let reimageErrors = state.get('reimageErrors');
    reimageErrors = addVirtualMachineError(environmentId, error, reimageErrors);
    return state.merge({
        virtualMachines,
        reimageErrors,
    });
}
function clearReimageUserVirtualMachineError(state, action) {
    const { environmentId, errorIndex } = action;
    let reimageErrors = state.get('reimageErrors');
    reimageErrors = clearSingleVirtualMachineError(environmentId, errorIndex, reimageErrors);
    return state.merge({
        reimageErrors,
    });
}
function redeployUserVirtualMachine(state, action) {
    const { environmentId } = action;
    let redeployErrors = state.get('redeployErrors');
    redeployErrors = clearVirtualMachineError(environmentId, redeployErrors);
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingVirtualMachineState(environmentId, virtualMachines, VmState.Redeploying);
    return state.merge({
        virtualMachines,
        redeployErrors,
    });
}
function redeployUserVirtualMachineError(state, action) {
    const { error, environmentId } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = clearPendingVirtualMachineState(environmentId, virtualMachines);
    let redeployErrors = state.get('redeployErrors');
    redeployErrors = addVirtualMachineError(environmentId, error, redeployErrors);
    return state.merge({
        virtualMachines,
        redeployErrors,
    });
}
function clearRedeployUserVirtualMachineError(state, action) {
    const { environmentId, errorIndex } = action;
    let redeployErrors = state.get('redeployErrors');
    redeployErrors = clearSingleVirtualMachineError(environmentId, errorIndex, redeployErrors);
    return state.merge({
        redeployErrors,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    virtualMachines: List(),
    listVirtualMachinesError: undefined,
    startErrors: Map(),
    stopErrors: Map(),
    resetPasswordErrors: Map(),
    reimageErrors: Map(),
    redeployErrors: Map(),
    operations: List(),
})();
export const userVirtualMachineReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserEnvironmentActionType.CLEAR_USER_SESSION:
        case UserEnvironmentActionType.SELECT_TENANT:
            return state.merge(initialState);
        case UserEnvironmentActionType.LIST_VNEXT_USER_VIRTUAL_MACHINES_SUCCESS:
            return listVNextUserVirtualMachinesSuccess(state, action);
        default:
            switch (action.context) {
                case Context.VNext:
                    switch (action.type) {
                        case UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS:
                            return listUserVirtualMachines(state, action);
                        case UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED:
                            return listUserVirtualMachinesCancelled(state, action);
                        case UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_ERROR:
                            return listUserVirtualMachinesError(state, action);
                        case UserEnvironmentActionType.CLEAR_LIST_GLOBAL_USER_ENVIRONMENTS_ERROR:
                            return clearListUserVirtualMachinesError(state, action);
                        case UserEnvironmentActionType.CLEAR_GLOBAL_USER_ENVIRONMENT_ERRORS:
                            return clearErrors(state, action);
                        case UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT:
                            return startVirtualMachine(state, action);
                        case UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT_FAILED:
                            return startVirtualMachineFailed(state, action);
                        case UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT_ACCEPTED:
                            return startVirtualMachineAccepted(state, action);
                        case UserEnvironmentActionType.CLEAR_START_USER_ENVIRONMENT_ERROR:
                            return clearStartVirtualMachineError(state, action);
                        case UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT:
                            return stopVirtualMachine(state, action);
                        case UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT_FAILED:
                            return stopVirtualMachineFailed(state, action);
                        case UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT_ACCEPTED:
                            return stopVirtualMachineAccepted(state, action);
                        case UserEnvironmentActionType.CLEAR_STOP_USER_ENVIRONMENT_ERROR:
                            return clearStopVirtualMachineError(state, action);
                        case UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD:
                            return resetVirtualMachinePassword(state, action);
                        case UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD_FAILED:
                            return resetVirtualMachinePasswordFailed(state, action);
                        case UserEnvironmentActionType.CLEAR_RESET_USER_ENVIRONMENT_PASSWORD_ERROR:
                            return clearResetVirtualMachinePasswordError(state, action);
                        case UserEnvironmentActionType.REIMAGE_USER_VIRTUAL_MACHINE:
                            return reimageUserVirtualMachine(state, action);
                        case UserEnvironmentActionType.REIMAGE_USER_VIRTUAL_MACHINE_ERROR:
                            return reimageUserVirtualMachineError(state, action);
                        case UserEnvironmentActionType.CLEAR_REIMAGE_USER_VIRTUAL_MACHINE_ERROR:
                            return clearReimageUserVirtualMachineError(state, action);
                        case UserEnvironmentActionType.REDEPLOY_USER_VIRTUAL_MACHINE_ACCEPTED:
                            return redeployVirtualMachineAccepted(state, action);
                        case UserEnvironmentActionType.REDEPLOY_USER_VIRTUAL_MACHINE:
                            return redeployUserVirtualMachine(state, action);
                        case UserEnvironmentActionType.REDEPLOY_USER_VIRTUAL_MACHINE_ERROR:
                            return redeployUserVirtualMachineError(state, action);
                        case UserEnvironmentActionType.CLEAR_REDEPLOY_USER_VIRTUAL_MACHINE_ERROR:
                            return clearRedeployUserVirtualMachineError(state, action);
                        case UserEnvironmentActionType.REIMAGE_USER_VIRTUAL_MACHINE_ACCEPTED:
                            return reimageVirtualMachineAccepted(state, action);
                        case UserEnvironmentActionType.LIST_ALL_USER_VIRTUAL_MACHINES_SUCCESS:
                            return listAllUserVirtualMachinesSuccess(state, action);
                        case OperationsResultActionType.POLL_USER_OPERATIONS_RESULT_SUCCESS:
                            return userOperationsResultSuccess(state, action);
                        default:
                            return state;
                    }
                default:
                    return state;
            }
    }
};
export default userVirtualMachineReducer;
