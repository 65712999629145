import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import memoize from 'memoize-one';
import PowerState from '../../utils/power-state';
import { ProvisioningState } from '../../utils/provisioning-state';
export const getCurrentTemplateId = memoize((state) => {
    const templateStore = state.get('templateStore');
    const currentTemplateId = templateStore.get('currentTemplate');
    return currentTemplateId;
});
export const getTemplates = memoize((state) => {
    const templateStore = state.get('templateStore');
    const templates = templateStore.get('templates');
    return templates;
});
export const getTemplate = memoize((state, templateId) => {
    const templates = getTemplates(state);
    const template = templates.find((t) => t.id === templateId);
    return template;
});
export const getCurrentTemplate = memoize((state) => {
    const currentTemplateId = getCurrentTemplateId(state);
    if (!currentTemplateId) {
        return undefined;
    }
    const currentTemplate = getTemplate(state, currentTemplateId);
    return currentTemplate;
});
export const getCores = memoize((sizeData) => {
    return sizeData ? sizeData.size.coresCount : undefined;
});
export const getIsGpu = memoize((sizeData) => {
    return sizeData ? sizeData.size.gpu === Ml.GpuType.Gpu : false;
});
export const getTemplateCreateError = memoize((state) => {
    const templateStore = state.get('templateStore');
    const createTemplateError = templateStore.get('createTemplateError');
    return createTemplateError;
});
/**
 * As publishing state is updated immediately in the store, use this instead to determine
 * whether a template has been published yet.
 */
export const hasBeenPublished = memoize((template) => {
    return !!template.lastPublished;
});
export const getSshAuthority = memoize((template) => {
    return (template &&
        template.resourceSettings &&
        template.resourceSettings.referenceVm &&
        template.resourceSettings.referenceVm.vmStateDetails &&
        template.resourceSettings.referenceVm.vmStateDetails.sshAuthority);
});
export const getRdpAuthority = memoize((template) => {
    return (template &&
        template.resourceSettings &&
        template.resourceSettings.referenceVm &&
        template.resourceSettings.referenceVm.vmStateDetails &&
        template.resourceSettings.referenceVm.vmStateDetails.rdpAuthority);
});
export const isTemplateCreating = memoize((template) => {
    return template && template.provisioningState ? template.provisioningState === ProvisioningState.Creating : false;
});
export const isTemplateStarting = memoize((template) => {
    return template &&
        template.resourceSettings &&
        template.resourceSettings.referenceVm &&
        template.resourceSettings.referenceVm.vmStateDetails
        ? template.resourceSettings.referenceVm.vmStateDetails.lastKnownPowerState === PowerState.Starting
        : false;
});
export const isTemplateStopping = memoize((template) => {
    return template &&
        template.resourceSettings &&
        template.resourceSettings.referenceVm &&
        template.resourceSettings.referenceVm.vmStateDetails
        ? template.resourceSettings.referenceVm.vmStateDetails.lastKnownPowerState === PowerState.Stopping
        : false;
});
export const isTemplateResettingPassword = memoize((template) => {
    return template &&
        template.latestOperationResult &&
        template.latestOperationResult.status &&
        template.latestOperationResult.requestUri
        ? template.latestOperationResult.status === PowerState.Running &&
            template.latestOperationResult.requestUri.indexOf('resetPassword') > -1
        : false;
});
export const isTemplateScaling = memoize((template) => {
    return template && template.publishingState ? template.publishingState === Ml.PublishingState.Scaling : false;
});
export const isTemplatePublished = memoize((template) => {
    return template && template.publishingState ? template.publishingState === Ml.PublishingState.Published : false;
});
export const isTemplatePublishing = memoize((template) => {
    return template && template.publishingState ? template.publishingState === Ml.PublishingState.Publishing : false;
});
export const isTemplatePublishingOrScaling = memoize((template) => {
    return isTemplatePublishing(template) || isTemplateScaling(template);
});
/** Some operations don't update the latestOperationResult correctly, so we could be returning
 * an error message for a previous operation.  This gets the action from the request uri so we can
 * check to make sure it is the correct operation.
 */
export const getActionFromUri = memoize((template) => {
    if (template.latestOperationResult && template.latestOperationResult.requestUri) {
        const split = template.latestOperationResult.requestUri.split('?');
        const split2 = split.length > 0 && split[0].split('/');
        if (split2 && split2.length > 0) {
            return split2[split2.length - 1];
        }
    }
});
export const hasTemplateCreationFailed = memoize((template) => {
    if (template && template.provisioningState && template.provisioningState === ProvisioningState.Failed) {
        if (template &&
            template.latestOperationResult &&
            template.latestOperationResult.httpMethod === 'PUT' &&
            template.latestOperationResult.status === 'Failed') {
            return true;
        }
    }
    return false;
});
