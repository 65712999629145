import memoize from 'memoize-one';
import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { LabServicesModels } from 'lab-services';
import { getDefaultIdleConfig, getLabIdleConfig, getVNextLabIdleConfig } from '../common/shutdown-policy/selectors';
import { getCombinedState, LoadingStoreState } from '../data/models/loading-store-state';
import { isTeamsMode, getTimezone, storeIsLoading, getLocale, isTeamsOrLmsMode, } from '../redux/selectors/common-selectors';
import { isCurrentLabParentLabAccount } from '../redux/selectors/lab-parent-resource-selectors';
import { getCurrentLabId, getCurrentLab, isCurrentLabReadOnly } from '../redux/selectors/lab-selectors';
import { getSearch } from '../redux/selectors/route-selector';
import { isHealthyState, ProvisioningState } from '../utils/provisioning-state';
import { shouldDisableCurrentLabUpdate } from '../redux/selectors/vnext/lab-selectors';
export const getScheduleViewModel = memoize((state) => {
    const isLabAccount = isCurrentLabParentLabAccount(state);
    const isTeamsIntegrationEnabled = isTeamsMode(state);
    const isTeamsOrLmsIntegrationEnabled = isTeamsOrLmsMode(state);
    const defaultIdleConfig = getDefaultIdleConfig(state);
    const lab = getCurrentLab(state);
    const labId = getCurrentLabId(state);
    const isReadOnly = isCurrentLabReadOnly(state);
    const timezone = getTimezone(state);
    const locale = getLocale(state);
    if (isLabAccount) {
        const scheduleStore = state.get('scheduleStore');
        const templateStore = state.get('templateStore');
        const errors = scheduleStore.get('errors');
        const schedules = scheduleStore.get('schedules').toJS();
        let loadState = getCombinedState(scheduleStore.loadState, templateStore.loadState);
        let isTemplateCreating = false;
        const templateId = templateStore.currentTemplate;
        let isWindows = false;
        if (templateStore.loadState === LoadingStoreState.Updating ||
            templateStore.loadState === LoadingStoreState.Loaded) {
            if (!templateId) {
                loadState = LoadingStoreState.LoadingFailed;
            }
            else {
                const template = templateStore.templates.find((o) => o.id === templateId);
                isWindows = template?.resourceSettings?.osType === Ml.OsType.Windows;
                if (!isHealthyState(template)) {
                    loadState = LoadingStoreState.LoadingFailed;
                }
                else if (template && template.provisioningState === ProvisioningState.Creating) {
                    isTemplateCreating = true;
                }
            }
        }
        const isLoading = storeIsLoading(loadState);
        const showLoadError = loadState === LoadingStoreState.LoadingFailed;
        const isCreating = scheduleStore.get('isCreating');
        const isUpdating = scheduleStore.get('isUpdating');
        const isDeleting = scheduleStore.get('isDeleting');
        const isSaving = isCreating || isUpdating || isDeleting;
        const idleConfig = lab ? getLabIdleConfig(defaultIdleConfig, lab, isWindows) : defaultIdleConfig;
        return {
            labId,
            isCurrentLabParentLabAccount: isLabAccount,
            isSaving,
            isTemplateCreating,
            isLoading,
            showLoadError,
            schedules,
            isReadOnly,
            errors,
            isTeamsIntegrationEnabled,
            idleConfig,
            timezone,
            locale,
            isTeamsOrLmsIntegrationEnabled,
        };
    }
    else {
        const scheduleStore = state.get('vNextScheduleStore');
        let loadState = scheduleStore.get('loadState');
        const errors = scheduleStore.get('errors');
        const schedules = scheduleStore
            .get('schedules')
            .filter((o) => o.provisioningState !== LabServicesModels.ProvisioningState.Deleting)
            .toJS();
        const isTemplateCreating = !!lab &&
            !!lab.provisioningState &&
            lab.provisioningState === LabServicesModels.ProvisioningState.Creating;
        if (lab) {
            if (!isHealthyState(lab.provisioningState)) {
                loadState = LoadingStoreState.LoadingFailed;
            }
        }
        else if (!isTemplateCreating) {
            loadState = LoadingStoreState.LoadingFailed;
        }
        const isLoading = storeIsLoading(loadState);
        const showLoadError = loadState === LoadingStoreState.LoadingFailed;
        const isCreating = scheduleStore.get('isCreating');
        const isUpdating = scheduleStore.get('isUpdating');
        const isDeleting = scheduleStore.get('isDeleting');
        const isSaving = isCreating || isUpdating || isDeleting;
        const idleConfig = lab ? getVNextLabIdleConfig(lab) : defaultIdleConfig;
        const shouldDisableScheduleUpdate = shouldDisableCurrentLabUpdate(state);
        return {
            labId,
            isCurrentLabParentLabAccount: isLabAccount,
            isSaving,
            isTemplateCreating,
            isLoading,
            showLoadError,
            schedules,
            isReadOnly,
            errors,
            isTeamsIntegrationEnabled,
            idleConfig,
            timezone,
            locale,
            isTeamsOrLmsIntegrationEnabled,
            shouldDisableScheduleUpdate,
        };
    }
});
export const getScheduleContainerModel = memoize((state) => {
    const search = getSearch(state);
    const scheduleViewModel = getScheduleViewModel(state);
    if (scheduleViewModel.isCurrentLabParentLabAccount) {
        const templateStore = state.get('templateStore');
        const templateId = templateStore.currentTemplate;
        return {
            scheduleViewModel,
            search,
            templateId,
        };
    }
    else {
        return {
            scheduleViewModel,
            search,
        };
    }
});
