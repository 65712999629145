import { List } from 'immutable';
import { LabServicesModels } from 'lab-services';
import memoize from 'memoize-one';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { isLmsHost, isTerminalState, storeIsLoading } from '../common-selectors';
import { getCurrentLabParentId } from '../lab-parent-resource-selectors';
import { getCurrentLab, getCurrentLabId } from '../lab-selectors';
import { getGroupName } from '../group-selectors';
import { getLtiContextTitle } from '../lti-selectors';
export const getResourceGroupLabs = memoize((store) => {
    const labStore = store.get('vNextLabStore');
    const resourceGroupLabs = labStore.get('resourceGroupLabsMap');
    const currentLabParentId = getCurrentLabParentId(store);
    let labs = List();
    for (const [key, value] of resourceGroupLabs) {
        if (caseInsensitiveCultureInvariantCompare(currentLabParentId, key) === 0) {
            labs = value;
            break;
        }
    }
    return labs;
});
export const getVNextLabAccessType = memoize((store) => {
    const labStore = store.get('vNextLabStore');
    const labAccessType = labStore.get('labAccessType');
    return labAccessType;
});
export const getVNextCurrentLabId = memoize((store) => {
    const labStore = store.get('vNextLabStore');
    const currentLabId = labStore.get('currentLabId');
    return currentLabId;
});
export function doesVNextLabGroupIdMatch(lab, aadGroupId) {
    return lab.rosterProfile?.activeDirectoryGroupId === aadGroupId;
}
export const isVNextLabBlocked = memoize((lab) => {
    return !!lab && !!lab.systemData?.createdBy && lab.systemData.createdBy.endsWith('-blocked');
});
export const isVNextLabCreating = memoize((lab) => {
    return lab.provisioningState === LabServicesModels.ProvisioningState.Creating;
});
export const isLoadingVNextLabs = memoize((store) => {
    const labStore = store.get('vNextLabStore');
    const loadState = labStore.get('loadState');
    return storeIsLoading(loadState);
});
export const getCreateLabPermissionOnLabPlan = memoize((store) => {
    const labStore = store.get('vNextLabStore');
    if (labStore.has('hasCreateLabPermissionOnLabPlan')) {
        const hasCreateLabPermissionOnLabPlan = labStore.get('hasCreateLabPermissionOnLabPlan');
        return hasCreateLabPermissionOnLabPlan;
    }
    return undefined;
});
export const getVnextLabPermissionPropagating = memoize((store) => {
    const labStore = store.get('vNextLabStore');
    if (labStore.has('isVnextLabPermissionPropagating')) {
        const isVnextLabPermissionPropagating = labStore.get('isVnextLabPermissionPropagating');
        return isVnextLabPermissionPropagating;
    }
    return undefined;
});
export const getVNextLab = memoize((store, labId) => {
    if (!labId) {
        return undefined;
    }
    const labs = getResourceGroupLabs(store);
    const lab = labs.find((lab) => caseInsensitiveCultureInvariantCompare(lab.id, labId) === 0);
    return lab;
});
export const hasTemplateVM = memoize((store) => {
    const currentLabId = getCurrentLabId(store);
    const currentVNextLab = getVNextLab(store, currentLabId);
    return currentVNextLab?.virtualMachineProfile?.createOption === LabServicesModels.CreateOption.TemplateVM;
});
export const shouldDisableCurrentLabUpdate = memoize((store) => {
    const currentLabId = getCurrentLabId(store);
    const currentVNextLab = getVNextLab(store, currentLabId);
    const labProvisioningState = currentVNextLab?.pendingProvisioningState ?? currentVNextLab?.provisioningState;
    return !isTerminalState(labProvisioningState);
});
export const isVNextLabSyncing = (lab) => {
    return getLabState(lab) === LabServicesModels.LabState.Syncing;
};
export const getLabCapacity = (lab) => {
    return lab.virtualMachineProfile?.sku?.capacity;
};
export const getCurrentLabErrors = memoize((store) => {
    const labStore = store.get('vNextLabStore');
    const errors = labStore.get('errors');
    const currentLabId = getCurrentLabId(store);
    return errors.get(currentLabId);
});
export const getLabErrorsByOperation = (errors, operation) => {
    if (!errors) {
        return List();
    }
    return errors.filter((o) => o.failureOperation === operation);
};
export const isLoadingLabMetadata = memoize((store) => {
    const labStore = store.get('vNextLabStore');
    const labMetadataLoadState = labStore.get('labMetadataLoadState');
    return storeIsLoading(labMetadataLoadState);
});
export const getCurrentLabUpdateError = memoize((store) => {
    const labStore = store.get('vNextLabStore');
    const labUpdateError = labStore.get('updateError');
    const currentLabId = getCurrentLabId(store);
    return !!labUpdateError?.id && caseInsensitiveCultureInvariantCompare(currentLabId, labUpdateError.id) === 0
        ? labUpdateError
        : undefined;
});
function getLabState(lab) {
    return lab.pendingLabState ?? lab.state;
}
export const isVNextLabScaling = (lab) => {
    return getLabState(lab) === LabServicesModels.LabState.Scaling;
};
export const isVNextLabDraft = (lab) => {
    return getLabState(lab) === LabServicesModels.LabState.Draft;
};
export const getVNextLabGroupId = (lab) => {
    return lab.rosterProfile?.activeDirectoryGroupId ?? lab.rosterProfile?.ltiContextId ?? undefined;
};
export const getVNextLabGroupName = (state) => {
    let groupName = '';
    const isLms = isLmsHost(state);
    const aadGroupName = getGroupName(state);
    const lab = getCurrentLab(state);
    if (isLms) {
        const courseTitle = getLtiContextTitle(state);
        groupName = courseTitle ?? '';
    }
    else if (lab?.rosterProfile?.activeDirectoryGroupId) {
        groupName = aadGroupName;
    }
    return groupName;
};
export const isVNextLabLmsConnected = (lab) => {
    return !!lab?.rosterProfile?.lmsInstance;
};
