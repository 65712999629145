import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { LabActionType } from '../../actions/lab/lab-actions';
import PermissionsProvider from '../../../data/providers/permissions-provider';
import { trackTrace } from '../../../utils/telemetry/telemetry-channel';
import { updateVnextCreatelabPermission, updateVnextPermissionPropagation } from '../../actions/lab/lab-action-creators';
import { getCreateLabPermissionOnLabPlan } from '../../selectors/vnext/lab-selectors';
export function* checkVNextCreateLabPermissionOnLabPlan(action) {
    const { labPlanId } = action;
    const armAccessToken = yield call(getArmAccessTokenSaga);
    const locale = yield select(getLocale);
    const language = yield select(getLanguage);
    const hasCreateLabPermissionOnLabPlan = yield select(getCreateLabPermissionOnLabPlan);
    try {
        // check CreateLabInLabPlan dataAction permission for VNext lab permission propagation scenario, 
        // to update initial flag hasCreateLabPermissionOnLabPlan and propagation status flag isVnextLabPermissionPropagating in lab store
        const newHasCreateLabPermissionOnLabPlan = yield call(PermissionsProvider.hasAccess, PermissionsProvider.VNextDataActionPermission.CreateLabInLabPlan, labPlanId, armAccessToken, locale, language);
        if (newHasCreateLabPermissionOnLabPlan) {
            yield put(updateVnextPermissionPropagation(true));
            if (!hasCreateLabPermissionOnLabPlan) {
                yield put(updateVnextCreatelabPermission(true));
            }
        }
        // when permission has changed, sync this flag in lab store
        if (!newHasCreateLabPermissionOnLabPlan && hasCreateLabPermissionOnLabPlan) {
            yield put(updateVnextCreatelabPermission(false));
        }
    }
    catch (e) {
        trackTrace(`Unable to determine write access for lab plan ${labPlanId} due to error ${e.message}, assuming no access.`);
    }
}
export function* checkVNextCreateLabPermissionOnLabPlanSaga() {
    yield takeLatest(LabActionType.CHECK_VNEXT_CREATELAB_PERMISSION, checkVNextCreateLabPermissionOnLabPlan);
}
