import { LOCATION_CHANGE, CALL_HISTORY_METHOD } from 'connected-react-router/immutable';
import { trackEvent, trackTrace } from '../../utils/telemetry/telemetry-channel';
import { CommonActionType } from '../actions/common/common-actions';
import { IdentityActionType } from '../actions/identity/identity-actions';
// these actions are noisy and fire a lot and are not particularly interesting to track
const ignoreActions = [
    IdentityActionType.GET_ACCESS_TOKEN,
    IdentityActionType.GET_ACCESS_TOKEN_SUCCESS,
    CommonActionType.FATAL_ERROR,
];
export function logAction(action) {
    const { type } = action;
    const scrubbedAction = cleanPii(action);
    if (scrubbedAction) {
        delete scrubbedAction['type'];
    }
    // used for our funnels
    trackEvent(type);
    // used for debugging
    trackEvent('ActionDispatched', {
        type,
        payload: JSON.stringify(scrubbedAction),
    });
}
export const actionLoggerMiddleware = () => (next) => (action) => {
    const result = next(action);
    const { type } = action;
    if (type === LOCATION_CHANGE || type === CALL_HISTORY_METHOD) {
        const payload = action.payload;
        trackEvent('ActionDispatched', { type, payload: JSON.stringify(cleanPii(payload)) });
    }
    else if (ignoreActions.indexOf(type) < 0) {
        logAction(action);
    }
    return result;
};
const safeParse = (payload) => {
    const seen = [];
    const stringified = JSON.stringify(payload, (_, value) => {
        if (value && typeof value === 'object') {
            if (seen.indexOf(value) >= 0) {
                return;
            }
            seen.push(value);
        }
        return value;
    });
    return JSON.parse(stringified);
};
export const cleanPii = (payload) => {
    try {
        const scrubbedPayload = safeParse(payload);
        deleteProperty(scrubbedPayload, 'claimedByUserPrincipalId');
        deleteProperty(scrubbedPayload, 'createdByUserPrincipalName');
        deleteProperty(scrubbedPayload, 'homeAccountIdentifier');
        deleteProperty(scrubbedPayload, 'accountIdentifier');
        deleteProperty(scrubbedPayload, 'createdByObjectId');
        deleteProperty(scrubbedPayload, 'preferred_username');
        deleteProperty(scrubbedPayload, 'emailAddresses');
        deleteProperty(scrubbedPayload, 'familyName');
        deleteProperty(scrubbedPayload, 'givenName');
        deleteProperty(scrubbedPayload, 'userName');
        deleteProperty(scrubbedPayload, 'username');
        deleteProperty(scrubbedPayload, 'password');
        deleteProperty(scrubbedPayload, 'email');
        deleteProperty(scrubbedPayload, 'puid');
        deleteProperty(scrubbedPayload, 'oid');
        deleteProperty(scrubbedPayload, 'aud');
        deleteProperty(scrubbedPayload, 'sub');
        deleteProperty(scrubbedPayload, 'data'); // pricing data is pretty large, no reason to log the whole thing
        deleteProperty(scrubbedPayload, 'hash');
        deleteProperty(scrubbedPayload, 'description');
        if (scrubbedPayload['account']) {
            deleteProperty(scrubbedPayload, 'name');
        }
        return scrubbedPayload;
    }
    catch (e) {
        const actionName = payload ? payload['type'] : 'Unknown';
        trackTrace(`Unable to clean payload for action ${actionName} due to error ${e ? e.message : 'Unknown error'}, will not send payload.`);
    }
};
export const deleteProperty = (object, key) => {
    if (!object) {
        return;
    }
    Object.keys(object).forEach((k) => {
        if (k === key) {
            delete object[key];
            return;
        }
        if (object[k] && typeof object[k] === 'object') {
            deleteProperty(object[k], key);
        }
    });
};
export default actionLoggerMiddleware;
