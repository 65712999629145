import { fork, takeLatest, select, takeEvery } from 'redux-saga/effects';
import { isCurrentLabParentLabAccount } from '../selectors/lab-parent-resource-selectors';
function labParentBasedTaskHelper(labAccountWorker, resourceGroupWorker) {
    return function* (action) {
        const isLabAccount = yield select(isCurrentLabParentLabAccount);
        if (!isLabAccount && !!resourceGroupWorker) {
            yield fork(resourceGroupWorker, action);
        }
        else if (isLabAccount && !!labAccountWorker) {
            yield fork(labAccountWorker, action);
        }
    };
}
//todo: figure out the correct type for the parameters so that we do not need to cast the worker type to any when calling featureBasedTakeLeast
export function labParentBasedTakeLatest(pattern, labAccountWorker, resourceGroupWorker) {
    const helper = labParentBasedTaskHelper(labAccountWorker, resourceGroupWorker);
    return takeLatest(pattern, helper);
}
export function labParentBasedTakeEvery(pattern, labAccountWorker, resourceGroupWorker) {
    const helper = labParentBasedTaskHelper(labAccountWorker, resourceGroupWorker);
    return takeEvery(pattern, helper);
}
