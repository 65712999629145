export const OperationType = {
    StartOperation: 'StartOperation',
    StopOperation: 'StopOperation',
    ReimageOperation: 'ReimageOperation',
    RedeployOperation: 'RedeployOperation',
    Unknown: '',
};
export const OperationStatus = {
    Succeeded: 'Succeeded',
    Failed: 'Failed',
    InProgress: 'InProgress',
};
function getOperationTypeFromAction(action) {
    switch (action) {
        case 'StartOperation':
            return OperationType.StartOperation;
        case 'StopOperation':
            return OperationType.StopOperation;
        case 'ReimageOperation':
            return OperationType.ReimageOperation;
        case 'RedeployOperation':
            return OperationType.RedeployOperation;
        default:
            return OperationType.Unknown;
    }
}
export function mapResourceErrorToOperation(resourceError) {
    if (!resourceError) {
        return;
    }
    if (getOperationTypeFromAction(resourceError.action) === OperationType.Unknown) {
        return;
    }
    const operation = {
        operationType: getOperationTypeFromAction(resourceError.action),
        status: OperationStatus.Failed,
        id: 'resourceOperationError',
        name: 'resourceOperationError',
        error: {
            code: resourceError.code,
            message: resourceError.message,
        },
        endTime: resourceError.timestamp.toDateString(),
        startTime: '',
    };
    return operation;
}
