import memoize from 'memoize-one';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { getCurrentLabId } from '../lab-selectors';
import { getVNextLab } from './lab-selectors';
export const getVNextImages = memoize((store) => {
    return store.get('vNextImageStore').get('images');
});
export const getVNextSharedImages = memoize((store) => {
    const images = getVNextImages(store);
    return images.filter((o) => !!o.sharedGalleryId);
});
export const getCurrentLabImage = memoize((store) => {
    const images = getVNextImages(store);
    const labId = getCurrentLabId(store);
    const lab = getVNextLab(store, labId);
    if (!lab?.virtualMachineProfile?.imageReference) {
        return;
    }
    const imageReference = lab.virtualMachineProfile.imageReference;
    if (!!imageReference.id) {
        return images.find((o) => !!o.sharedGalleryId &&
            caseInsensitiveCultureInvariantCompare(o.sharedGalleryId, imageReference.id) === 0);
    }
    if (!!imageReference.sku && !!imageReference.offer && !!imageReference.publisher) {
        return images.find((o) => !!o.sku &&
            !!o.offer &&
            !!o.publisher &&
            caseInsensitiveCultureInvariantCompare(o.sku, imageReference.sku) === 0 &&
            caseInsensitiveCultureInvariantCompare(o.offer, imageReference.offer) === 0 &&
            caseInsensitiveCultureInvariantCompare(o.publisher, imageReference.publisher) === 0);
    }
});
