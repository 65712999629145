import * as React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import './carousel-cards.css';
// NOTE: This is a quick port of an outlook control that removes their dependencies
export class CarouselCards extends React.Component {
    constructor(props) {
        super(props);
        this.UNSAFE_componentWillReceiveProps = (nextProps) => {
            const { currentCard: oldCard } = this.props;
            const { currentCard: newCard } = nextProps;
            if (newCard !== oldCard) {
                this.setState({
                    previousCard: oldCard,
                });
            }
        };
        this.render = () => {
            const { currentCard, cards } = this.props;
            const { previousCard } = this.state;
            const visibleCard = cards.length > currentCard ? cards[currentCard] : undefined;
            const visibleContent = visibleCard ? visibleCard.content() : null;
            const movingBackwards = previousCard > currentCard;
            return (<div className="cardContainer">
                <div className="ariaCard" aria-live="assertive" aria-atomic="true" aria-label={visibleCard?.ariaLabel}/>
                <TransitionGroup>
                    <CSSTransition classNames={movingBackwards
                ? {
                    enter: 'movingBackwards-next',
                    enterActive: 'current',
                    exit: 'movingBackwards-previous',
                }
                : {
                    enter: 'movingForwards-next',
                    enterActive: 'current',
                    exit: 'movingForwards-previous',
                }} timeout={200} key={`carousel-card-${currentCard}`}>
                        <div className="card">{visibleContent}</div>
                    </CSSTransition>
                </TransitionGroup>
            </div>);
        };
        this.state = {
            previousCard: -1,
        };
    }
}
export default CarouselCards;
