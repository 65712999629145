// move to config?
const graphPictureUrl = 'https://graph.microsoft.com/v1.0/me/photos/48x48/$value';
const graphPictureContentType = 'image/jpg';
export async function getUserPhotoUrl(accessToken) {
    const headers = new Headers();
    headers.append('Content-Type', graphPictureContentType);
    headers.append('Authorization', `Bearer ${accessToken}`);
    const result = await fetch(graphPictureUrl, {
        method: 'GET',
        headers,
        mode: 'cors',
    });
    if (result.status === 404 || result.status === 401) {
        return undefined;
    }
    else if (result.status === 200) {
        const pictureBlob = await result.blob();
        const pictureUrl = URL.createObjectURL(pictureBlob);
        return pictureUrl;
    }
    const response = await result.json();
    throw new Error(`An error occurred while acquiring the user photo. Status: ${result.status}, Response: ${response}`);
}
