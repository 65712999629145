import { defineMessages } from 'react-intl';
export const sharedImageNameValidatorMessages = defineMessages({
    sigNameRequiredError: {
        id: 'SigNameRequiredError',
        defaultMessage: 'Image Name is required.',
        description: 'Error shown when no image name is provided.',
    },
    sigNameTooLongError: {
        id: 'SigNameTooLongError',
        defaultMessage: 'Virtual machine image name cannot exceed 255 characters.',
        description: 'Error shown when image name is too long.',
    },
    sigNameContainsSpecialCharactersError: {
        id: 'SigNameContainsSpecialCharactersError',
        defaultMessage: 'Virtual machine image name cannot contain special characters.',
        description: 'Error shown when image name contains special characters.',
    },
    sigNameNotUniqueError: {
        id: 'SigNameNotUniqueError',
        defaultMessage: 'Virtual machine image names must be unique.',
        description: 'Error shown when image name is not unique.',
    },
});
const blockedCharacters = [
    '<',
    '>',
    '%',
    '&',
    ':',
    '\\',
    '?',
    '/',
    '@',
    '#',
    '$',
    '^',
    '*',
    '(',
    ')',
    '.',
    ';',
    '+',
    '_',
    '!',
    '~',
    '`',
];
export function validateSharedImageName(name, sharedImages, intl) {
    if (!name || name.trim().length === 0) {
        return intl.formatMessage(sharedImageNameValidatorMessages.sigNameRequiredError);
    }
    else if (name.length > 255) {
        return intl.formatMessage(sharedImageNameValidatorMessages.sigNameTooLongError);
    }
    else if (blockedCharacters.some((char) => name.includes(char))) {
        return intl.formatMessage(sharedImageNameValidatorMessages.sigNameContainsSpecialCharactersError);
    }
    else if (sharedImages && sharedImages.some((image) => image.displayName.toLowerCase() === name.toLowerCase())) {
        return intl.formatMessage(sharedImageNameValidatorMessages.sigNameNotUniqueError);
    }
    return undefined;
}
