import * as React from 'react';
import { Dialog, DialogFooter, DialogType, DefaultButton, PrimaryButton } from '@fluentui/react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import commonMessages from '../language/common-messages';
import { Constants } from '../utils/constants';
import './template.css';
const messages = defineMessages({
    startTemplateTitle: {
        id: 'StartTemplateTitle',
        defaultMessage: 'Start template',
        description: 'Title for start template warning dialog',
    },
});
const StartWarningTemplateDialogInjected = (props) => {
    const { startTemplate, onDismiss } = props;
    const title = props.intl.formatMessage(messages.startTemplateTitle);
    const continueLabel = props.intl.formatMessage(commonMessages.continue);
    const cancelLabel = props.intl.formatMessage(commonMessages.cancel);
    return (<Dialog onDismiss={onDismiss} hidden={false} dialogContentProps={{
        type: DialogType.normal,
        title: title,
    }} modalProps={{
        onDismissed: onDismiss,
        isBlocking: false,
        styles: {
            main: {
                fontSize: '14px',
            },
        },
    }} minWidth={Constants.DefaultDialogWidth}>
            <FormattedMessage id="CustomizeTemplateWarningMessage" defaultMessage="Once you start the template and make changes, it will no longer have the same setup as the virtual machines last published to your users." description="Text to explain how customizing the template works in the customize warning template dialog."/>
            <div style={{ fontWeight: 600, paddingTop: 20 }}>
                <FormattedMessage id="ChangingPublishedTemplateClarification" defaultMessage="Changing the template will not affect your users’ current virtual machines until you publish again. Publishing will delete all data on the users’ machines." description="Bolded text in start template warning dialog."/>
            </div>
            <DialogFooter styles={{ actions: { marginTop: '52px' } }}>
                <PrimaryButton text={continueLabel} ariaLabel={continueLabel} onClick={() => {
        startTemplate();
        onDismiss();
    }}/>
                <DefaultButton text={cancelLabel} onClick={onDismiss}/>
            </DialogFooter>
        </Dialog>);
};
export const StartWarningTemplateDialog = injectIntl(StartWarningTemplateDialogInjected);
export default StartWarningTemplateDialog;
