import { all, fork } from 'redux-saga/effects';
import { ltiStartOidcSaga } from './lti-start-oidc';
import { ltiSignInSaga } from './lti-sign-in';
import { getLtiTokenRequestSaga } from './lti-token';
import { ltiRequestStorageAccessSaga } from './lti-request-storage-access';
export function* rootSaga() {
    yield all([
        fork(ltiStartOidcSaga),
        fork(ltiSignInSaga),
        fork(getLtiTokenRequestSaga),
        fork(ltiRequestStorageAccessSaga),
    ]);
}
