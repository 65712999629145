import { Link } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ErrorCode } from '../common/error-codes';
import FullPageMessage from '../full-page-message/full-page-message';
import { commonMessages } from '../language/common-messages';
import { Urls } from '../utils/constants';
class ErrorViewInjected extends React.Component {
    render() {
        const { errorCode, hasMultipleTenants, isLmsHosted, hasWebStorageAccessGranted } = this.props;
        let image;
        const imageWidth = 300;
        let imageHeight = 300;
        let message;
        let messageDetails = undefined;
        let buttonText = undefined;
        let buttonOnClick = undefined;
        let messageDetailsMaxWidth;
        const popupBlockedMessage = (<FormattedMessage id="ErrorMessageInvalidGrantPopupBlocked" defaultMessage="Pop-up blocked" description="Error message displayed during an invalid grant flow when the authentication popup has been blocked."/>);
        switch (errorCode) {
            case ErrorCode.SessionExpired:
            case ErrorCode.IdleSessionExpired:
                image = 'error-expired';
                message = (<FormattedMessage id="ErrorMessageSessionExpired" defaultMessage="You have been logged out because your session has expired." description="The error message displayed on the error page when the user has an expired session."/>);
                buttonText = this.props.intl.formatMessage(commonMessages.signInButtonText);
                buttonOnClick = () => {
                    this.props.clearError();
                    this.props.signIn();
                };
                break;
            case ErrorCode.InvalidGrant:
                image = 'error-registration';
                imageHeight = 250;
                message = (<FormattedMessage id="ErrorMessageInvalidGrant" defaultMessage="Sign in required" description="Error message displayed during an invalid grant flow."/>);
                messageDetails = (<FormattedMessage id="ErrorMessageDetailsInvalidGrant" defaultMessage="Additional steps are required in order to connect to Azure Lab Services. Please sign in to continue." description="Error message details displayed during an invalid grant flow."/>);
                buttonText = this.props.intl.formatMessage(commonMessages.signInButtonText);
                buttonOnClick = () => {
                    this.props.requestUserConsent();
                };
                break;
            case ErrorCode.InvalidGrantPopupBlocked:
                image = 'error-access';
                imageHeight = 250;
                message = popupBlockedMessage;
                messageDetails = (<FormattedMessage id="ErrorMessageDetailsInvalidGrantPopupBlocked" defaultMessage="We need to sign you in to your account, but your browser is set to block pop-ups. Please <Link>enabled pop-ups for this site</Link>, and try again." description="Error message details displayed during an invalid grant flow when the authentication popup has been blocked." values={{
                    Link: (chunks) => (<Link href={Urls.PopupHelp} target="_blank">
                                    {chunks}
                                </Link>),
                }}/>);
                buttonText = this.props.intl.formatMessage(commonMessages.signInButtonText);
                buttonOnClick = () => {
                    this.props.requestUserConsent();
                };
                break;
            case ErrorCode.PopupWindowBlocked:
                image = 'error-access';
                imageHeight = 250;
                message = popupBlockedMessage;
                messageDetails = (<FormattedMessage id="ErrorMessageDetailsBrowserPopupBlocked" defaultMessage="Your browser is set to block pop-ups, which are required to access Azure Lab Services. Please <Link>enable pop-ups for this site</Link>, or try again." description="Error message details displayed when the popups has been blocked in the browser." values={{
                    Link: (chunks) => (<Link href={Urls.PopupHelp} target="_blank">
                                    {chunks}
                                </Link>),
                }}/>);
                messageDetailsMaxWidth = 335;
                buttonText = this.props.intl.formatMessage(commonMessages.retry);
                buttonOnClick = () => {
                    this.props.clearError();
                    this.props.signIn();
                };
                break;
            case ErrorCode.AuthenticationFailed:
                image = 'error-access';
                message = (<FormattedMessage id="ErrorMessageAuthenticationFailed" defaultMessage="We were unable to sign you in. Please try again." description="The error message displayed on the error page when we receive an authentication failure."/>);
                buttonText = this.props.intl.formatMessage(commonMessages.signInButtonText);
                buttonOnClick = () => {
                    this.props.clearError();
                    this.props.signIn();
                };
                break;
            case ErrorCode.AuthorizationFailed:
            case ErrorCode.LabAccountAuthorizationFailed:
            case ErrorCode.LabAuthorizationFailed:
                image = 'error-access';
                message = (<FormattedMessage id="ErrorMessage401" defaultMessage="You are not authorized to access this resource." description="The error message displayed on the error page when we receive a 401 response code."/>);
                break;
            case ErrorCode.LabFailed:
                image = 'error-failed';
                message = (<FormattedMessage id="ErrorMessageLabFailed" defaultMessage="Your lab is in a failed state." description="The error message displayed on the error page when the selected lab is in a failed state."/>);
                messageDetails = (<FormattedMessage id="ErrorMessageLabFailedDetails" defaultMessage="Delete and recreate the lab, or contact your lab administrator or support to correct the problem." description="The error message displayed on the error page when the selected lab is in a failed state."/>);
                break;
            case ErrorCode.ResourceGroupNotFound:
            case ErrorCode.ResourceNotFound:
                image = 'error-not-found';
                message = (<FormattedMessage id="ErrorMessageResourceNotFound" defaultMessage="We couldn't find that resource." description="The error message displayed on the error page when the selected lab is in a failed state."/>);
                messageDetails = hasMultipleTenants ? (<FormattedMessage id="ErrorMessageResouceNotFoundWhenMultiTenants" defaultMessage="It may be deleted, in another organization, or you may no longer have access. Please select a different organization." description="The error message displayed on the error page when resouce is not found in the current tenant."/>) : (<FormattedMessage id="ErrorMessageResourceNotFoundDetails" defaultMessage="It may be deleted or outside your organization. Please double check the URL." description="The error message displayed on the error page when the selected lab is in a failed state."/>);
                break;
            case ErrorCode.LtiTokenExpired:
                image = 'session-expired';
                imageHeight = 160;
                messageDetailsMaxWidth = 335;
                message = (<FormattedMessage id="ErrorMessageAccessExpired" defaultMessage="Access expired" description="Error message displayed when lti token is expired."/>);
                messageDetails = (<FormattedMessage id="ErrorMessageDetailsAccessExpired" defaultMessage="Please refresh your browser to access Azure Lab Services." description="Error message details displayed when lti token is expired and users need to refresh the page to acquire a new token."/>);
                break;
            case ErrorCode.CookiesAndDataBlocked:
                image = 'error-failed';
                messageDetailsMaxWidth = 282;
                message = (<FormattedMessage id="ErrorMessageCookiesAndDataBlocked" defaultMessage="Cookies and data blocked" description="Error message displayed when the browser is set to block third-party cookies and site data."/>);
                messageDetails = (<FormattedMessage id="ErrorMessageDetailsCookiesAndDataBlocked" defaultMessage="To use Azure Lab Services with your learning management system, third-party cookies and site data must be enabled in your browser." description="Error message details displayed when the browser is set to block third-party cookies and site data."/>);
                break;
            case ErrorCode.LtiRequestStorageAccessNeeded:
                switch (hasWebStorageAccessGranted) {
                    case true:
                        image = 'session-expired';
                        messageDetailsMaxWidth = 282;
                        message = (<FormattedMessage id="AccessGranted" defaultMessage="Access granted" description="Error message displayed when need to reload the window after access has been granted."/>);
                        messageDetails = (<FormattedMessage id="ErrorMessageDetailsReloadWindowNeeded" defaultMessage="Thank you for granting access. Please reload this window to use Azure Lab Services with your learning management system." description="Error message details displayed when when need to reload the window after access has been granted."/>);
                        break;
                    default:
                        image = 'error-failed';
                        messageDetailsMaxWidth = 282;
                        message = (<FormattedMessage id="ErrorMessageRequestAccessNeeded" defaultMessage="Access needed" description="Error message displayed when users need to request web storage access."/>);
                        messageDetails = (<FormattedMessage id="ErrorMessageDetailsRequestAccessNeeded" defaultMessage="To use Azure Lab Services with your learning management system, please grant access to the application." description="Error message details displayed when users need to request web storage access."/>);
                        (buttonText = this.props.intl.formatMessage(commonMessages.grantAccess)),
                            (buttonOnClick = () => {
                                this.props.ltiRequestWebStorageAccess();
                            });
                        break;
                }
                break;
            case ErrorCode.GenericError:
            default:
                image = 'error-general';
                message = (<FormattedMessage id="ErrorMessageGeneral" defaultMessage="Oops, something went wrong." description="The generic error message we display on the error page when we do not have a more specific message to display."/>);
                messageDetails = !isLmsHosted ? (<FormattedMessage id="ErrorMessageDetailsGeneral" defaultMessage="Please <Link>refresh</Link>, or contact your lab administrator if this problem continues." description="Error message displayed when we do not have a more specific message to display. The value of {link} is a link back to the home page of the site." values={{ Link: (chunks) => <Link href="https://labs.azure.com">{chunks}</Link> }}/>) : (<FormattedMessage id="ErrorMessageDetailsGeneralLms" defaultMessage="Please refresh your browser to access Azure Lab Services. Contact your lab administrator if this problem continues." description="Error message displayed when we do not have a more specific message to display in Lms."/>);
                break;
        }
        return (<FullPageMessage image={image} imageSize={{ width: imageWidth, height: imageHeight }} messageDetailsMaxWidth={messageDetailsMaxWidth} message={message} messageDetails={messageDetails} buttonText={buttonText} buttonOnClick={buttonOnClick} styles={{ root: { backgroundColor: 'inherit' } }}/>);
    }
}
export const ErrorView = injectIntl(ErrorViewInjected);
export default ErrorView;
