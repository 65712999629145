import { takeLatest, call, select, put } from 'redux-saga/effects';
import { ResourceId } from '../../../utils/resource-id';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { getPricingAndAvailability } from '../../../data/providers/lab-account-provider';
import { getPricingAndAvailability as getVNextPricingAndAvailabilityData } from '../../../data/providers/vnext/labs-api/pricing-and-availability-providers';
import { MlClientError, FailureOperation } from '../../../data/ml-client-error';
import { PricingAndAvailabilityActionType, } from '../../actions/pricing-and-availability/pricing-and-availability-actions';
import { getPricingAndAvailabilityData as getPricingAndAvailabilityDataActionCreator, getPricingAndAvailabilityDataSuccess, getPricingAndAvailabilityDataError, getVNextPricingAndAvailabilityDataSuccess, } from '../../actions/pricing-and-availability/pricing-and-availability-action-creators';
import { trackException } from '../../../utils/telemetry/telemetry-channel';
import { isRouteTeamsConfig } from '../../selectors/route-selector';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
export function* getPricingAndAvailabilityData(action) {
    const { labAccountId } = action;
    try {
        const resourceId = new ResourceId(labAccountId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const data = yield call(getPricingAndAvailability, resourceId, accessToken, locale, language);
        yield put(getPricingAndAvailabilityDataSuccess(data));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, labAccountId, FailureOperation.GetPricingAndAvailabilityData, err ? err.code : undefined);
        yield put(getPricingAndAvailabilityDataError(error));
    }
}
export function* getPricesAndAvailabilityOnLabParentSelect(action) {
    const { resourceId } = action;
    const isTeamsConfigPage = yield select(isRouteTeamsConfig);
    if (!isTeamsConfigPage) {
        try {
            yield put(getPricingAndAvailabilityDataActionCreator(resourceId));
        }
        catch (err) {
            trackException(undefined, err);
        }
    }
}
//vNext saga
export function* getVnextPricingAndAvailabilityData(action) {
    const { labAccountId } = action;
    try {
        const resourceId = new ResourceId(labAccountId);
        const accessToken = yield call(getLabsApiAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const data = yield call(getVNextPricingAndAvailabilityData, resourceId, accessToken, locale, language);
        yield put(getVNextPricingAndAvailabilityDataSuccess(data));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, labAccountId, FailureOperation.GetPricingAndAvailabilityData, err ? err.code : undefined);
        yield put(getPricingAndAvailabilityDataError(error));
    }
}
export function* getPricesAndAvailabilityOnLabParentSelectSaga() {
    yield takeLatest(PricingAndAvailabilityActionType.SELECT_LAB_PARENT_RESOURCE, getPricesAndAvailabilityOnLabParentSelect);
}
export function* getPricingAndAvailabilityDataSaga() {
    yield labParentBasedTakeLatest(PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA, getPricingAndAvailabilityData, getVnextPricingAndAvailabilityData);
}
