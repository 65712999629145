import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Loading } from '../welcome/loading';
import { Routes } from '../utils/routes';
import { LabListContainer } from '../lab-list/lab-list-container';
import { LabAppContainer } from '../lab-app/lab-app-container';
import { WelcomeContainer } from '../welcome/welcome-container';
import { TeamsConfigContainer } from '../teams-app/teams-config-container';
export const ProfessorApp = (props) => {
    const { isInitializing, isTeamsModeEnabled } = props;
    if (isInitializing) {
        return (<Switch>
                <Route exact={true} path={Routes.TeamsConfig} component={TeamsConfigContainer}/>
                <Route render={() => <Loading hideHeader={isTeamsModeEnabled}/>}/>
            </Switch>);
    }
    return (<Switch>
            <Route exact={true} path={Routes.TeamsConfig} component={TeamsConfigContainer}/>
            <Route exact={true} path={Routes.LabList()} render={() => <LabListContainer showNewLabFlyout={false}/>}/>
            <Route exact={true} path={Routes.NewLab()} render={() => <LabListContainer showNewLabFlyout={true}/>}/>
            <Route exact={true} path={[
        Routes.Dashboard(),
        Routes.Template(),
        Routes.Schedule(),
        Routes.Users(),
        Routes.VirtualMachines(),
        Routes.Settings(),
        Routes.Lab(),
        Routes.VNextLab(),
    ]} component={LabAppContainer}/>
            <Route component={WelcomeContainer}/>
        </Switch>);
};
export default ProfessorApp;
