import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    pageTitle: {
        id: 'VmListPageTitle',
        defaultMessage: 'Virtual machine pool',
        description: 'Virtual machines page title.',
    },
    operationResultGenericSuccess: {
        id: 'OperationResultSuccess',
        defaultMessage: '{Operation} operation succeeded.',
        description: 'The message displayed on tooltip  icon for virtual machines when start or stop operation succeeded.',
    },
    SubmitSupportRequest: {
        id: 'SubmitSupportRequest',
        defaultMessage: 'submit a support request',
        description: 'A Link to submit a support request for requesting help on start or stop failure operations.',
    },
    StartOperation: {
        id: 'StartOperation',
        defaultMessage: 'start',
        description: 'Start virtual machine Operation',
    },
    StopOperation: {
        id: 'StopOperation',
        defaultMessage: 'stop',
        description: 'Stop virtual machine Operation',
    },
    ReimageOperation: {
        id: 'ResetOperation',
        defaultMessage: 'reimage',
        description: 'Reimage virtual machine Operation',
    },
    RedeployOperation: {
        id: 'RedeployOperation',
        defaultMessage: 'redeploy',
        description: 'Redeploy virtual machine Operation',
    },
});
export default messages;
