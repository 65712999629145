import { race, put, take, takeLatest } from 'redux-saga/effects';
import { LabAccountActionType, } from '../../actions/lab-account/lab-account-actions';
import { loadSubscriptions } from '../../actions/subscription/subscription-action-creators';
import { SubscriptionActionType } from '../../actions/subscription/subscription-actions';
import { refreshSubscriptionsAndLabAccountsError, listLabAccounts, refreshSubscriptionsAndLabAccountsSuccess, } from '../../actions/lab-account/lab-account-action-creators';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
export function* refreshSubscriptionsAndLabAccounts(action) {
    try {
        yield put(loadSubscriptions());
        const { error: loadSubscriptionErrorAction } = yield race({
            success: take(SubscriptionActionType.LOAD_SUBSCRIPTIONS_SUCCESS),
            error: take(SubscriptionActionType.LOAD_SUBSCRIPTIONS_ERROR),
        });
        if (loadSubscriptionErrorAction) {
            const { error } = loadSubscriptionErrorAction;
            yield put(refreshSubscriptionsAndLabAccountsError(error));
        }
        else {
            yield put(listLabAccounts(false, false));
            const { error: listLabAccountsErrorAction } = yield race({
                success: take(LabAccountActionType.LIST_LAB_ACCOUNTS_SUCCESS),
                error: take(LabAccountActionType.LIST_LAB_ACCOUNTS_ERROR),
            });
            if (listLabAccountsErrorAction) {
                const { error } = listLabAccountsErrorAction;
                yield put(refreshSubscriptionsAndLabAccountsError(error));
            }
            else {
                yield put(refreshSubscriptionsAndLabAccountsSuccess());
            }
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.RefreshSubscriptionsAndLabAccounts, err ? err.code : undefined);
        yield put(refreshSubscriptionsAndLabAccountsError(error));
    }
}
export function* refreshSubscriptionsAndLabAccountsSaga() {
    yield takeLatest(LabAccountActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS, refreshSubscriptionsAndLabAccounts);
}
