import { Checkbox, Dropdown, Separator } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { DefaultCurrencyCode } from '../../utils/constants';
import { numberSorter } from '../../utils/sorting';
import { getSkuCostsFromImage, isLocationAllowedForImage, isSkuLocationAvailable } from './create-lab-selectors';
import { getLocalizedLocationName } from './location-helpers';
import { messages } from './vm-geography-picker-shared';
const Option = (option) => {
    const { cost, disabled, text } = option;
    return disabled ? (<span>{text}</span>) : (<FormattedMessage id="VNext_VmLocationPickerTitle" defaultMessage="{location} - {price}/hr" description="The name of a location and its price per hour." values={{
        location: text,
        price: <FormattedNumber value={cost} style="currency" currency={DefaultCurrencyCode}/>,
    }}/>);
};
const getOptions = (sku, vmImageInfo, intl) => {
    const locations = sku.locations.filter((o) => isLocationAllowedForImage(vmImageInfo, o.name));
    const options = [];
    const costs = getSkuCostsFromImage(locations, vmImageInfo);
    locations.forEach((location) => {
        const option = {
            disabled: !isSkuLocationAvailable(sku, location.name, vmImageInfo),
            key: location.name,
            text: getLocalizedLocationName(location.name, intl),
            cost: costs.get(location.name) ?? 0,
            location,
            title: '',
        };
        options.push(option);
    });
    // Sort by price (min to max cost)
    const sorter = numberSorter();
    return options.sort((a, b) => sorter(a.cost, b.cost));
};
export const VNextVmLocationPicker = (props) => {
    const { sku, selected, vmImageInfo, onChange, onChangeReset } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const [showUnavailable, setShowUnavailable] = React.useState(false);
    const options = getOptions(sku, vmImageInfo, intl);
    const filteredOptions = !showUnavailable ? options.filter((o) => !o.disabled) : options;
    const hasDisabledOptions = options.some((o) => o.disabled);
    const dropdownProps = {
        label: msg(messages.simplifiedLocationLabel),
        options: filteredOptions,
        placeholder: msg(messages.placeholder),
        selectedKey: selected ? [selected.key] : [],
        styles: {
            callout: { maxHeight: 300, overflowY: 'auto' },
            root: { paddingBottom: '8px' },
        },
        onChange: (_, value) => {
            const option = value;
            // ignoring changes on focus so the dropdown doesn't pre-select something
            // when the user clicks on it / focuses on it for the first time
            if (_.type.toLowerCase() !== 'focus' && !option.disabled) {
                onChange(option.location);
                onChangeReset();
            }
        },
        onRenderOption: (option) => (option ? <Option {...option}/> : null),
        onRenderTitle: (options) => (options ? <Option {...options[0]}/> : null),
        onRenderList: (renderProps, defaultRender) => {
            return (<>
                    <div style={{ maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {defaultRender && defaultRender(renderProps)}
                    </div>
                    {hasDisabledOptions && (<>
                            <Separator styles={{ root: { padding: '0', margin: '0', height: '1px' } }}/>
                            <Checkbox styles={{ root: { padding: '15px' } }} checked={showUnavailable} onChange={(_event, value) => setShowUnavailable(value || false)} label={msg(messages.showUnavailableSimplified)}/>
                        </>)}
                </>);
        },
    };
    return <Dropdown {...dropdownProps} aria-required={true}/>;
};
export default VNextVmLocationPicker;
