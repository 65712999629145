import { call, put, select, takeLatest } from 'redux-saga/effects';
import { TenantActionType } from '../../actions/tenant/tenant-actions';
import { getSearch } from '../../selectors/route-selector';
import { loginPopup, loginRedirect } from '../../../data/providers/msal-provider';
import { getLoginHint } from '../../selectors/identity-selector';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { selectTenantError } from '../../actions/tenant/tenant-action-creators';
import { fatalError, initialize } from '../../actions/common/common-action-creators';
import { ErrorCode } from '../../../common/error-codes';
import Routes from '../../../utils/routes';
import { isLmsHost } from '../../selectors/common-selectors';
import { routerActions } from 'connected-react-router';
import { setTenantId } from '../../actions/identity/identity-action-creators';
export function* selectTenant(action) {
    const { tenantId } = action;
    try {
        const search = yield select(getSearch);
        const loginHint = yield select(getLoginHint);
        const isLms = yield select(isLmsHost);
        if (!isLms) {
            yield call(loginRedirect, `${Routes.Home}${search}`, tenantId, loginHint, false);
        }
        else {
            yield put(setTenantId(tenantId));
            yield put(routerActions.replace({ pathname: Routes.Home, search }));
            yield call(loginPopup, tenantId, loginHint, false);
            yield put(initialize());
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, tenantId, FailureOperation.SelectTenant, e ? e.code : undefined);
        yield put(selectTenantError(error));
        yield put(fatalError(ErrorCode.AuthenticationFailed, error));
    }
}
export function* selectTenantSaga() {
    yield takeLatest(TenantActionType.SELECT_TENANT, selectTenant);
}
