import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import * as React from 'react';
import FullPageMessage from '../full-page-message/full-page-message';
const messages = defineMessages({
    dashboardPageErrorMessageDetails: {
        id: 'DashboardPageErrorMessageDetails',
        defaultMessage: 'The lab or template is in a failed state.',
        description: 'Error details for dashboard error page when lab or template is in a failed state',
    },
});
const DashboardErrorPageInjected = (props) => {
    const msg = props.intl.formatMessage;
    return (<FullPageMessage image="error-failed" message={<FormattedMessage id="DashboardLoadError" defaultMessage="Cannot load dashboard" description="Error shown on dashboard page when the lab or template is in a failed state."/>} messageDetails={msg(messages.dashboardPageErrorMessageDetails)}/>);
};
const DashboardErrorPage = injectIntl(DashboardErrorPageInjected);
export default DashboardErrorPage;
