import { put, takeLatest, select, all } from 'redux-saga/effects';
import ErrorCode from '../../../common/error-codes';
import { trackException } from '../../../utils/telemetry/telemetry-channel';
import { CommonActionType } from '../../actions/common/common-actions';
import { clearUserSession, clearFatalError } from '../../actions/common/common-action-creators';
import { Host } from '../../../utils/hosts';
import { getHost, getReferrer } from '../../selectors/common-selectors';
import { routerActions } from 'connected-react-router/immutable';
import { Routes } from '../../../utils/routes';
import { getSearch } from '../../selectors/route-selector';
export function* fatalError(action) {
    try {
        const { errorCode } = action;
        switch (errorCode) {
            case ErrorCode.SessionExpired:
            case ErrorCode.IdleSessionExpired:
            case ErrorCode.AuthenticationFailed:
                yield put(clearUserSession());
                break;
            case ErrorCode.LabAccountAuthorizationFailed:
            case ErrorCode.ResourceNotFound:
                const host = yield select(getHost);
                const referrer = yield select(getReferrer);
                // when running in Teams or coming from Teams, if a user can't access the lab account
                // then we will send them to the student view
                if (host === Host.Teams || referrer === Host.Teams) {
                    const search = yield select(getSearch);
                    yield all([
                        put(clearFatalError()),
                        put(routerActions.replace({ pathname: Routes.StudentView, search })),
                    ]);
                }
                break;
            default:
                break;
        }
    }
    catch (err) {
        trackException(err);
    }
}
export function* fatalErrorSaga() {
    yield takeLatest(CommonActionType.FATAL_ERROR, fatalError);
}
