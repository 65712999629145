import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestLimitIncreaseLink } from '../../common/request-limit-increase-link';
import { getVCurrentMinMaxVmCores, getVCurrentRemainingCores } from './create-lab-selectors';
const labelShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.line, width: '75%', height: 18 },
    { type: ShimmerElementType.gap, width: '25%', height: 18 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 7 }]}/>
    </>);
const gapShimmer = (<ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: '100%', height: 31 }]}/>);
export const LabConfigShimmer = (<>
        {labelShimmer}
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: '100%', height: 50 }]}/>
        {gapShimmer}
        {labelShimmer}
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: '100%', height: 50 }]}/>
        {gapShimmer}
        {labelShimmer}
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: '100%', height: 33 }]}/>
    </>);
export const CoresNotAvailableMessage = (props) => {
    const { labParentId, currentTenantId, coreQuotaData, sizes } = props;
    const { minVmCores: minGpuVmCores, maxVmCores: maxGpuVmCores } = getVCurrentMinMaxVmCores(Ml.GpuType.Gpu, sizes);
    const { minVmCores: minStandardVmCores, maxVmCores: maxStandardVmCores } = getVCurrentMinMaxVmCores(Ml.GpuType.None, sizes);
    if (!coreQuotaData) {
        return <></>;
    }
    const { remainingGpuCores, remainingStandardCores } = getVCurrentRemainingCores(coreQuotaData);
    if (minGpuVmCores === undefined ||
        maxGpuVmCores === undefined ||
        minStandardVmCores === undefined ||
        maxStandardVmCores === undefined ||
        remainingGpuCores === undefined ||
        remainingStandardCores === undefined) {
        return <></>;
    }
    let message;
    if ((remainingGpuCores < minGpuVmCores && remainingStandardCores < maxStandardVmCores) ||
        (remainingStandardCores < minStandardVmCores && remainingGpuCores < maxGpuVmCores)) {
        message = (<FormattedMessage id="SizesLimitedForLabCreationMessage" defaultMessage="Some sizes are not available due to virtual machine limits on your account." description="Message on lab create flyout informing user some sizes are limited for lab creation."/>);
    }
    else if (remainingGpuCores < minGpuVmCores) {
        message = (<FormattedMessage id="GpuSizeNotAvailableForLabCreationMessage" defaultMessage="GPU sizes are not available due to virtual machine limits on your account." description="Message on lab create flyout informing user they cannot create any GPU machines."/>);
    }
    else if (remainingStandardCores < minStandardVmCores) {
        message = (<FormattedMessage id="StandardSizeNotAvailableForLabCreationMessage" defaultMessage="Standard sizes are not available due to virtual machine limits on your account." description="Message on lab create flyout informing user they cannot create any Standard machines."/>);
    }
    if (!message) {
        return <></>;
    }
    return (<div style={{ fontSize: '12px', marginTop: '30px' }}>
            {message}
            <RequestLimitIncreaseLink labParentId={labParentId} tenantId={currentTenantId}/>
        </div>);
};
