import { DefaultButton, Dialog, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { SubmitButton } from '../common/submit-button';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import Constants from '../utils/constants';
const messages = defineMessages({
    title: {
        id: 'DeleteUsersDialogTitle',
        defaultMessage: 'Delete user(s)',
        description: 'Title of the delete users dialog.',
    },
    subText: {
        id: 'DeleteUsersDialogTextFormat',
        defaultMessage: '{numberOfUsers} user(s) and their virtual machines will be deleted from the lab. {cantBeUndoneSpan}',
        description: 'Message shown when deleting users from a lab. {numberOfUsers} is the number of users being deleted (one or more). {cantBeUndoneSpan} is the bolded resolved value of DeleteUsersDialogCantBeUndoneMessage.',
    },
});
export const DeleteUsersDialog = (props) => {
    const { numberOfUsers, isSubmitting, onDismiss, onSubmit } = props;
    // close the dialog after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const cantBeUndoneSpan = (<b>
            <FormattedMessage id="DeleteUsersDialogCantBeUndoneMessage" defaultMessage="This operation cannot be undone." description="This message will be displayed as part of DeleteUsersDialogTextFormat"/>
        </b>);
    return (<Dialog hidden={false} onDismiss={isSubmitting ? undefined : onDismiss} dialogContentProps={{
        title: msg(messages.title),
        subText: msg(messages.subText, { numberOfUsers, cantBeUndoneSpan }),
    }} closeButtonAriaLabel={msg(commonMessages.cancel)} maxWidth={Constants.DefaultDialogSmallWidth}>
            <Stack horizontal tokens={{ childrenGap: '8px' }} styles={{ root: { width: '100%' } }} verticalAlign="end" horizontalAlign="end">
                <Stack.Item>
                    <SubmitButton isSubmitting={isSubmitting} buttonText={msg(commonMessages.delete)} onClick={onSubmit}/>
                </Stack.Item>
                <Stack.Item>
                    <DefaultButton autoFocus text={msg(commonMessages.cancel)} disabled={isSubmitting} onClick={onDismiss}/>
                </Stack.Item>
            </Stack>
        </Dialog>);
};
export default DeleteUsersDialog;
