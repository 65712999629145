import * as React from 'react';
import { Stack } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';
import { ClockIcon } from '../common/clock-icon';
export const ClockInfoProcessEstimate = (props) => {
    const { timeEstimateMessage } = props;
    return (<Stack horizontal tokens={{ childrenGap: '8px' }}>
            <Stack.Item>
                <ClockIcon />
            </Stack.Item>
            <Stack.Item>
                <FormattedMessage id="TimeEstimation" defaultMessage="This process can take up to <span>{timeEstimateMessage}</span>." description="Text to inform the user the estimated duration of a process. <span> and </span> should not be localized, but the text between them should." values={{
        span: (chunks) => <span style={{ fontWeight: 600 }}>{chunks}</span>,
        timeEstimateMessage,
    }}/>
            </Stack.Item>
        </Stack>);
};
export default ClockInfoProcessEstimate;
