import { Record } from 'immutable';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { PricingAndAvailabilityActionType, } from '../../actions/pricing-and-availability/pricing-and-availability-actions';
import { Context } from '../../action-context';
function getPricingAndAvailabilityData(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loading,
        error: undefined,
    });
}
function getPricingAndAvailabilityDataSuccess(state, action) {
    const { data } = action;
    return state.merge({
        loadState: LoadingStoreState.Loaded,
        data,
    });
}
function getPricingAndAvailabilityDataError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: LoadingStoreState.LoadingFailed,
        error,
        data: undefined,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    error: undefined,
    data: undefined,
})();
export const pricingAndAvailabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case PricingAndAvailabilityActionType.CLEAR_USER_SESSION:
        case PricingAndAvailabilityActionType.SELECT_LAB_PARENT_RESOURCE:
        case PricingAndAvailabilityActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    switch (action.type) {
                        case PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA:
                            return getPricingAndAvailabilityData(state, action);
                        case PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA_SUCCESS:
                            return getPricingAndAvailabilityDataSuccess(state, action);
                        case PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA_ERROR:
                            return getPricingAndAvailabilityDataError(state, action);
                        default:
                            return state;
                    }
                default:
                    return state;
            }
    }
};
export default pricingAndAvailabilityReducer;
