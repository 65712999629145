/**
 * @deprecated Const enum use is deprecated. See GitHub issue #7110: https://github.com/OfficeDev/office-ui-fabric-react/issues/7110
 */
export var IconNames;
(function (IconNames) {
    IconNames["GlobalNavButton"] = "GlobalNavButton";
    IconNames["InternetSharing"] = "InternetSharing";
    IconNames["Brightness"] = "Brightness";
    IconNames["MapPin"] = "MapPin";
    IconNames["Airplane"] = "Airplane";
    IconNames["Tablet"] = "Tablet";
    IconNames["QuickNote"] = "QuickNote";
    IconNames["ChevronDown"] = "ChevronDown";
    IconNames["ChevronUp"] = "ChevronUp";
    IconNames["Edit"] = "Edit";
    IconNames["Add"] = "Add";
    IconNames["Cancel"] = "Cancel";
    IconNames["More"] = "More";
    IconNames["Settings"] = "Settings";
    IconNames["Video"] = "Video";
    IconNames["Mail"] = "Mail";
    IconNames["People"] = "People";
    IconNames["Phone"] = "Phone";
    IconNames["Pin"] = "Pin";
    IconNames["Shop"] = "Shop";
    IconNames["Stop"] = "Stop";
    IconNames["Link"] = "Link";
    IconNames["Filter"] = "Filter";
    IconNames["AllApps"] = "AllApps";
    IconNames["Zoom"] = "Zoom";
    IconNames["ZoomOut"] = "ZoomOut";
    IconNames["Microphone"] = "Microphone";
    IconNames["Search"] = "Search";
    IconNames["Camera"] = "Camera";
    IconNames["Attach"] = "Attach";
    IconNames["Send"] = "Send";
    IconNames["FavoriteList"] = "FavoriteList";
    IconNames["PageSolid"] = "PageSolid";
    IconNames["Forward"] = "Forward";
    IconNames["Back"] = "Back";
    IconNames["Refresh"] = "Refresh";
    IconNames["Share"] = "Share";
    IconNames["Lock"] = "Lock";
    IconNames["BlockedSite"] = "BlockedSite";
    IconNames["ReportHacked"] = "ReportHacked";
    IconNames["EMI"] = "EMI";
    IconNames["MiniLink"] = "MiniLink";
    IconNames["Blocked"] = "Blocked";
    IconNames["FavoriteStar"] = "FavoriteStar";
    IconNames["FavoriteStarFill"] = "FavoriteStarFill";
    IconNames["ReadingMode"] = "ReadingMode";
    IconNames["Favicon"] = "Favicon";
    IconNames["Remove"] = "Remove";
    IconNames["Checkbox"] = "Checkbox";
    IconNames["CheckboxComposite"] = "CheckboxComposite";
    IconNames["CheckboxFill"] = "CheckboxFill";
    IconNames["CheckboxIndeterminate"] = "CheckboxIndeterminate";
    IconNames["CheckboxCompositeReversed"] = "CheckboxCompositeReversed";
    IconNames["CheckMark"] = "CheckMark";
    IconNames["BackToWindow"] = "BackToWindow";
    IconNames["FullScreen"] = "FullScreen";
    IconNames["Print"] = "Print";
    IconNames["Up"] = "Up";
    IconNames["Down"] = "Down";
    IconNames["OEM"] = "OEM";
    IconNames["Delete"] = "Delete";
    IconNames["Save"] = "Save";
    IconNames["ReturnKey"] = "ReturnKey";
    IconNames["Cloud"] = "Cloud";
    IconNames["Flashlight"] = "Flashlight";
    IconNames["CommandPrompt"] = "CommandPrompt";
    IconNames["Sad"] = "Sad";
    IconNames["RealEstate"] = "RealEstate";
    IconNames["SIPMove"] = "SIPMove";
    IconNames["EraseTool"] = "EraseTool";
    IconNames["GripperTool"] = "GripperTool";
    IconNames["Dialpad"] = "Dialpad";
    IconNames["PageLeft"] = "PageLeft";
    IconNames["PageRight"] = "PageRight";
    IconNames["MultiSelect"] = "MultiSelect";
    IconNames["KeyboardClassic"] = "KeyboardClassic";
    IconNames["Play"] = "Play";
    IconNames["Pause"] = "Pause";
    IconNames["ChevronLeft"] = "ChevronLeft";
    IconNames["ChevronRight"] = "ChevronRight";
    IconNames["InkingTool"] = "InkingTool";
    IconNames["Emoji2"] = "Emoji2";
    IconNames["GripperBarHorizontal"] = "GripperBarHorizontal";
    IconNames["System"] = "System";
    IconNames["Personalize"] = "Personalize";
    IconNames["SearchAndApps"] = "SearchAndApps";
    IconNames["Globe"] = "Globe";
    IconNames["EaseOfAccess"] = "EaseOfAccess";
    IconNames["ContactInfo"] = "ContactInfo";
    IconNames["Unpin"] = "Unpin";
    IconNames["Contact"] = "Contact";
    IconNames["Memo"] = "Memo";
    IconNames["IncomingCall"] = "IncomingCall";
    IconNames["Paste"] = "Paste";
    IconNames["WindowsLogo"] = "WindowsLogo";
    IconNames["Error"] = "Error";
    IconNames["GripperBarVertical"] = "GripperBarVertical";
    IconNames["Unlock"] = "Unlock";
    IconNames["Slideshow"] = "Slideshow";
    IconNames["Calendar"] = "Calendar";
    IconNames["Megaphone"] = "Megaphone";
    IconNames["AutoEnhanceOn"] = "AutoEnhanceOn";
    IconNames["AutoEnhanceOff"] = "AutoEnhanceOff";
    IconNames["Color"] = "Color";
    IconNames["SaveAs"] = "SaveAs";
    IconNames["Light"] = "Light";
    IconNames["Filters"] = "Filters";
    IconNames["AspectRatio"] = "AspectRatio";
    IconNames["Contrast"] = "Contrast";
    IconNames["Redo"] = "Redo";
    IconNames["Undo"] = "Undo";
    IconNames["Crop"] = "Crop";
    IconNames["PhotoCollection"] = "PhotoCollection";
    IconNames["Album"] = "Album";
    IconNames["Rotate"] = "Rotate";
    IconNames["PanoIndicator"] = "PanoIndicator";
    IconNames["Translate"] = "Translate";
    IconNames["RedEye"] = "RedEye";
    IconNames["ThumbnailView"] = "ThumbnailView";
    IconNames["Package"] = "Package";
    IconNames["Telemarketer"] = "Telemarketer";
    IconNames["Warning"] = "Warning";
    IconNames["Financial"] = "Financial";
    IconNames["Education"] = "Education";
    IconNames["ShoppingCart"] = "ShoppingCart";
    IconNames["Train"] = "Train";
    IconNames["Flag"] = "Flag";
    IconNames["Move"] = "Move";
    IconNames["Page"] = "Page";
    IconNames["TouchPointer"] = "TouchPointer";
    IconNames["Merge"] = "Merge";
    IconNames["TurnRight"] = "TurnRight";
    IconNames["Ferry"] = "Ferry";
    IconNames["Highlight"] = "Highlight";
    IconNames["PowerButton"] = "PowerButton";
    IconNames["Tab"] = "Tab";
    IconNames["Admin"] = "Admin";
    IconNames["TVMonitor"] = "TVMonitor";
    IconNames["Speakers"] = "Speakers";
    IconNames["Game"] = "Game";
    IconNames["UnstackSelected"] = "UnstackSelected";
    IconNames["StackIndicator"] = "StackIndicator";
    IconNames["Nav2DMapView"] = "Nav2DMapView";
    IconNames["StreetsideSplitMinimize"] = "StreetsideSplitMinimize";
    IconNames["Car"] = "Car";
    IconNames["Bus"] = "Bus";
    IconNames["EatDrink"] = "EatDrink";
    IconNames["SeeDo"] = "SeeDo";
    IconNames["LocationCircle"] = "LocationCircle";
    IconNames["Home"] = "Home";
    IconNames["SwitcherStartEnd"] = "SwitcherStartEnd";
    IconNames["ParkingLocation"] = "ParkingLocation";
    IconNames["IncidentTriangle"] = "IncidentTriangle";
    IconNames["Touch"] = "Touch";
    IconNames["MapDirections"] = "MapDirections";
    IconNames["CaretHollow"] = "CaretHollow";
    IconNames["CaretSolid"] = "CaretSolid";
    IconNames["History"] = "History";
    IconNames["Location"] = "Location";
    IconNames["MapLayers"] = "MapLayers";
    IconNames["SearchNearby"] = "SearchNearby";
    IconNames["Work"] = "Work";
    IconNames["Recent"] = "Recent";
    IconNames["Hotel"] = "Hotel";
    IconNames["Bank"] = "Bank";
    IconNames["LocationDot"] = "LocationDot";
    IconNames["Dictionary"] = "Dictionary";
    IconNames["ChromeBack"] = "ChromeBack";
    IconNames["FolderOpen"] = "FolderOpen";
    IconNames["Pinned"] = "Pinned";
    IconNames["PinnedFill"] = "PinnedFill";
    IconNames["RevToggleKey"] = "RevToggleKey";
    IconNames["USB"] = "USB";
    IconNames["View"] = "View";
    IconNames["Previous"] = "Previous";
    IconNames["Next"] = "Next";
    IconNames["Clear"] = "Clear";
    IconNames["Sync"] = "Sync";
    IconNames["Download"] = "Download";
    IconNames["Help"] = "Help";
    IconNames["Upload"] = "Upload";
    IconNames["Emoji"] = "Emoji";
    IconNames["MailForward"] = "MailForward";
    IconNames["ClosePane"] = "ClosePane";
    IconNames["OpenPane"] = "OpenPane";
    IconNames["PreviewLink"] = "PreviewLink";
    IconNames["ZoomIn"] = "ZoomIn";
    IconNames["Bookmarks"] = "Bookmarks";
    IconNames["Document"] = "Document";
    IconNames["ProtectedDocument"] = "ProtectedDocument";
    IconNames["OpenInNewWindow"] = "OpenInNewWindow";
    IconNames["MailFill"] = "MailFill";
    IconNames["ViewAll"] = "ViewAll";
    IconNames["Switch"] = "Switch";
    IconNames["Rename"] = "Rename";
    IconNames["Go"] = "Go";
    IconNames["Remote"] = "Remote";
    IconNames["SelectAll"] = "SelectAll";
    IconNames["Orientation"] = "Orientation";
    IconNames["Import"] = "Import";
    IconNames["Folder"] = "Folder";
    IconNames["Picture"] = "Picture";
    IconNames["ChromeClose"] = "ChromeClose";
    IconNames["ShowResults"] = "ShowResults";
    IconNames["Message"] = "Message";
    IconNames["CalendarDay"] = "CalendarDay";
    IconNames["CalendarWeek"] = "CalendarWeek";
    IconNames["MailReplyAll"] = "MailReplyAll";
    IconNames["Read"] = "Read";
    IconNames["Cut"] = "Cut";
    IconNames["PaymentCard"] = "PaymentCard";
    IconNames["Copy"] = "Copy";
    IconNames["Important"] = "Important";
    IconNames["MailReply"] = "MailReply";
    IconNames["Sort"] = "Sort";
    IconNames["GotoToday"] = "GotoToday";
    IconNames["Font"] = "Font";
    IconNames["FontColor"] = "FontColor";
    IconNames["FolderFill"] = "FolderFill";
    IconNames["Permissions"] = "Permissions";
    IconNames["DisableUpdates"] = "DisableUpdates";
    IconNames["Unfavorite"] = "Unfavorite";
    IconNames["Italic"] = "Italic";
    IconNames["Underline"] = "Underline";
    IconNames["Bold"] = "Bold";
    IconNames["MoveToFolder"] = "MoveToFolder";
    IconNames["Dislike"] = "Dislike";
    IconNames["Like"] = "Like";
    IconNames["AlignRight"] = "AlignRight";
    IconNames["AlignCenter"] = "AlignCenter";
    IconNames["AlignLeft"] = "AlignLeft";
    IconNames["OpenFile"] = "OpenFile";
    IconNames["ClearSelection"] = "ClearSelection";
    IconNames["FontDecrease"] = "FontDecrease";
    IconNames["FontIncrease"] = "FontIncrease";
    IconNames["FontSize"] = "FontSize";
    IconNames["CellPhone"] = "CellPhone";
    IconNames["Tag"] = "Tag";
    IconNames["RepeatOne"] = "RepeatOne";
    IconNames["RepeatAll"] = "RepeatAll";
    IconNames["Calculator"] = "Calculator";
    IconNames["Library"] = "Library";
    IconNames["PostUpdate"] = "PostUpdate";
    IconNames["NewFolder"] = "NewFolder";
    IconNames["CalendarReply"] = "CalendarReply";
    IconNames["UnsyncFolder"] = "UnsyncFolder";
    IconNames["SyncFolder"] = "SyncFolder";
    IconNames["BlockContact"] = "BlockContact";
    IconNames["AddFriend"] = "AddFriend";
    IconNames["Accept"] = "Accept";
    IconNames["BulletedList"] = "BulletedList";
    IconNames["Preview"] = "Preview";
    IconNames["News"] = "News";
    IconNames["Chat"] = "Chat";
    IconNames["Group"] = "Group";
    IconNames["World"] = "World";
    IconNames["Comment"] = "Comment";
    IconNames["DockLeft"] = "DockLeft";
    IconNames["DockRight"] = "DockRight";
    IconNames["Repair"] = "Repair";
    IconNames["Accounts"] = "Accounts";
    IconNames["Street"] = "Street";
    IconNames["RadioBullet"] = "RadioBullet";
    IconNames["Stopwatch"] = "Stopwatch";
    IconNames["Clock"] = "Clock";
    IconNames["WorldClock"] = "WorldClock";
    IconNames["AlarmClock"] = "AlarmClock";
    IconNames["Photo"] = "Photo";
    IconNames["ActionCenter"] = "ActionCenter";
    IconNames["Hospital"] = "Hospital";
    IconNames["Timer"] = "Timer";
    IconNames["FullCircleMask"] = "FullCircleMask";
    IconNames["LocationFill"] = "LocationFill";
    IconNames["ChromeMinimize"] = "ChromeMinimize";
    IconNames["ChromeRestore"] = "ChromeRestore";
    IconNames["Annotation"] = "Annotation";
    IconNames["Fingerprint"] = "Fingerprint";
    IconNames["Handwriting"] = "Handwriting";
    IconNames["ChromeFullScreen"] = "ChromeFullScreen";
    IconNames["Completed"] = "Completed";
    IconNames["Label"] = "Label";
    IconNames["FlickDown"] = "FlickDown";
    IconNames["FlickUp"] = "FlickUp";
    IconNames["FlickLeft"] = "FlickLeft";
    IconNames["FlickRight"] = "FlickRight";
    IconNames["MiniExpand"] = "MiniExpand";
    IconNames["MiniContract"] = "MiniContract";
    IconNames["Streaming"] = "Streaming";
    IconNames["MusicInCollection"] = "MusicInCollection";
    IconNames["OneDriveLogo"] = "OneDriveLogo";
    IconNames["CompassNW"] = "CompassNW";
    IconNames["Code"] = "Code";
    IconNames["LightningBolt"] = "LightningBolt";
    IconNames["Info"] = "Info";
    IconNames["CalculatorMultiply"] = "CalculatorMultiply";
    IconNames["CalculatorAddition"] = "CalculatorAddition";
    IconNames["CalculatorSubtract"] = "CalculatorSubtract";
    IconNames["CalculatorPercentage"] = "CalculatorPercentage";
    IconNames["CalculatorEqualTo"] = "CalculatorEqualTo";
    IconNames["PrintfaxPrinterFile"] = "PrintfaxPrinterFile";
    IconNames["StorageOptical"] = "StorageOptical";
    IconNames["Communications"] = "Communications";
    IconNames["Headset"] = "Headset";
    IconNames["Health"] = "Health";
    IconNames["FrontCamera"] = "FrontCamera";
    IconNames["ChevronUpSmall"] = "ChevronUpSmall";
    IconNames["ChevronDownSmall"] = "ChevronDownSmall";
    IconNames["ChevronLeftSmall"] = "ChevronLeftSmall";
    IconNames["ChevronRightSmall"] = "ChevronRightSmall";
    IconNames["ChevronUpMed"] = "ChevronUpMed";
    IconNames["ChevronDownMed"] = "ChevronDownMed";
    IconNames["ChevronLeftMed"] = "ChevronLeftMed";
    IconNames["ChevronRightMed"] = "ChevronRightMed";
    IconNames["Devices2"] = "Devices2";
    IconNames["PC1"] = "PC1";
    IconNames["PresenceChickletVideo"] = "PresenceChickletVideo";
    IconNames["Reply"] = "Reply";
    IconNames["HalfAlpha"] = "HalfAlpha";
    IconNames["ConstructionCone"] = "ConstructionCone";
    IconNames["DoubleChevronLeftMed"] = "DoubleChevronLeftMed";
    IconNames["Volume0"] = "Volume0";
    IconNames["Volume1"] = "Volume1";
    IconNames["Volume2"] = "Volume2";
    IconNames["Volume3"] = "Volume3";
    IconNames["Chart"] = "Chart";
    IconNames["Robot"] = "Robot";
    IconNames["Manufacturing"] = "Manufacturing";
    IconNames["LockSolid"] = "LockSolid";
    IconNames["FitPage"] = "FitPage";
    IconNames["FitWidth"] = "FitWidth";
    IconNames["BidiLtr"] = "BidiLtr";
    IconNames["BidiRtl"] = "BidiRtl";
    IconNames["RightDoubleQuote"] = "RightDoubleQuote";
    IconNames["Sunny"] = "Sunny";
    IconNames["CloudWeather"] = "CloudWeather";
    IconNames["Cloudy"] = "Cloudy";
    IconNames["PartlyCloudyDay"] = "PartlyCloudyDay";
    IconNames["PartlyCloudyNight"] = "PartlyCloudyNight";
    IconNames["ClearNight"] = "ClearNight";
    IconNames["RainShowersDay"] = "RainShowersDay";
    IconNames["Rain"] = "Rain";
    IconNames["Thunderstorms"] = "Thunderstorms";
    IconNames["RainSnow"] = "RainSnow";
    IconNames["Snow"] = "Snow";
    IconNames["BlowingSnow"] = "BlowingSnow";
    IconNames["Frigid"] = "Frigid";
    IconNames["Fog"] = "Fog";
    IconNames["Squalls"] = "Squalls";
    IconNames["Duststorm"] = "Duststorm";
    IconNames["Unknown"] = "Unknown";
    IconNames["Precipitation"] = "Precipitation";
    IconNames["SortLines"] = "SortLines";
    IconNames["Ribbon"] = "Ribbon";
    IconNames["AreaChart"] = "AreaChart";
    IconNames["Assign"] = "Assign";
    IconNames["FlowChart"] = "FlowChart";
    IconNames["CheckList"] = "CheckList";
    IconNames["Diagnostic"] = "Diagnostic";
    IconNames["Generate"] = "Generate";
    IconNames["LineChart"] = "LineChart";
    IconNames["Equalizer"] = "Equalizer";
    IconNames["BarChartHorizontal"] = "BarChartHorizontal";
    IconNames["BarChartVertical"] = "BarChartVertical";
    IconNames["Freezing"] = "Freezing";
    IconNames["FunnelChart"] = "FunnelChart";
    IconNames["Processing"] = "Processing";
    IconNames["Quantity"] = "Quantity";
    IconNames["ReportDocument"] = "ReportDocument";
    IconNames["StackColumnChart"] = "StackColumnChart";
    IconNames["SnowShowerDay"] = "SnowShowerDay";
    IconNames["HailDay"] = "HailDay";
    IconNames["WorkFlow"] = "WorkFlow";
    IconNames["HourGlass"] = "HourGlass";
    IconNames["StoreLogoMed20"] = "StoreLogoMed20";
    IconNames["TimeSheet"] = "TimeSheet";
    IconNames["TriangleSolid"] = "TriangleSolid";
    IconNames["UpgradeAnalysis"] = "UpgradeAnalysis";
    IconNames["VideoSolid"] = "VideoSolid";
    IconNames["RainShowersNight"] = "RainShowersNight";
    IconNames["SnowShowerNight"] = "SnowShowerNight";
    IconNames["Teamwork"] = "Teamwork";
    IconNames["HailNight"] = "HailNight";
    IconNames["PeopleAdd"] = "PeopleAdd";
    IconNames["Glasses"] = "Glasses";
    IconNames["DateTime2"] = "DateTime2";
    IconNames["Shield"] = "Shield";
    IconNames["Header1"] = "Header1";
    IconNames["PageAdd"] = "PageAdd";
    IconNames["NumberedList"] = "NumberedList";
    IconNames["PowerBILogo"] = "PowerBILogo";
    IconNames["Info2"] = "Info2";
    IconNames["MusicInCollectionFill"] = "MusicInCollectionFill";
    IconNames["List"] = "List";
    IconNames["Asterisk"] = "Asterisk";
    IconNames["ErrorBadge"] = "ErrorBadge";
    IconNames["CircleRing"] = "CircleRing";
    IconNames["CircleFill"] = "CircleFill";
    IconNames["Record2"] = "Record2";
    IconNames["AllAppsMirrored"] = "AllAppsMirrored";
    IconNames["BookmarksMirrored"] = "BookmarksMirrored";
    IconNames["BulletedListMirrored"] = "BulletedListMirrored";
    IconNames["CaretHollowMirrored"] = "CaretHollowMirrored";
    IconNames["CaretSolidMirrored"] = "CaretSolidMirrored";
    IconNames["ChromeBackMirrored"] = "ChromeBackMirrored";
    IconNames["ClearSelectionMirrored"] = "ClearSelectionMirrored";
    IconNames["ClosePaneMirrored"] = "ClosePaneMirrored";
    IconNames["DockLeftMirrored"] = "DockLeftMirrored";
    IconNames["DoubleChevronLeftMedMirrored"] = "DoubleChevronLeftMedMirrored";
    IconNames["GoMirrored"] = "GoMirrored";
    IconNames["HelpMirrored"] = "HelpMirrored";
    IconNames["ImportMirrored"] = "ImportMirrored";
    IconNames["ImportAllMirrored"] = "ImportAllMirrored";
    IconNames["ListMirrored"] = "ListMirrored";
    IconNames["MailForwardMirrored"] = "MailForwardMirrored";
    IconNames["MailReplyMirrored"] = "MailReplyMirrored";
    IconNames["MailReplyAllMirrored"] = "MailReplyAllMirrored";
    IconNames["MiniContractMirrored"] = "MiniContractMirrored";
    IconNames["MiniExpandMirrored"] = "MiniExpandMirrored";
    IconNames["OpenPaneMirrored"] = "OpenPaneMirrored";
    IconNames["ParkingLocationMirrored"] = "ParkingLocationMirrored";
    IconNames["SendMirrored"] = "SendMirrored";
    IconNames["ShowResultsMirrored"] = "ShowResultsMirrored";
    IconNames["ThumbnailViewMirrored"] = "ThumbnailViewMirrored";
    IconNames["Media"] = "Media";
    IconNames["Devices3"] = "Devices3";
    IconNames["Focus"] = "Focus";
    IconNames["VideoLightOff"] = "VideoLightOff";
    IconNames["Lightbulb"] = "Lightbulb";
    IconNames["StatusTriangle"] = "StatusTriangle";
    IconNames["VolumeDisabled"] = "VolumeDisabled";
    IconNames["Puzzle"] = "Puzzle";
    IconNames["EmojiNeutral"] = "EmojiNeutral";
    IconNames["EmojiDisappointed"] = "EmojiDisappointed";
    IconNames["HomeSolid"] = "HomeSolid";
    IconNames["Ringer"] = "Ringer";
    IconNames["PDF"] = "PDF";
    IconNames["HeartBroken"] = "HeartBroken";
    IconNames["StoreLogo16"] = "StoreLogo16";
    IconNames["MultiSelectMirrored"] = "MultiSelectMirrored";
    IconNames["Broom"] = "Broom";
    IconNames["Cocktails"] = "Cocktails";
    IconNames["Wines"] = "Wines";
    IconNames["Articles"] = "Articles";
    IconNames["Cycling"] = "Cycling";
    IconNames["DietPlanNotebook"] = "DietPlanNotebook";
    IconNames["Pill"] = "Pill";
    IconNames["ExerciseTracker"] = "ExerciseTracker";
    IconNames["HandsFree"] = "HandsFree";
    IconNames["Medical"] = "Medical";
    IconNames["Running"] = "Running";
    IconNames["Weights"] = "Weights";
    IconNames["Trackers"] = "Trackers";
    IconNames["AddNotes"] = "AddNotes";
    IconNames["AllCurrency"] = "AllCurrency";
    IconNames["BarChart4"] = "BarChart4";
    IconNames["CirclePlus"] = "CirclePlus";
    IconNames["Coffee"] = "Coffee";
    IconNames["Cotton"] = "Cotton";
    IconNames["Market"] = "Market";
    IconNames["Money"] = "Money";
    IconNames["PieDouble"] = "PieDouble";
    IconNames["PieSingle"] = "PieSingle";
    IconNames["RemoveFilter"] = "RemoveFilter";
    IconNames["Savings"] = "Savings";
    IconNames["Sell"] = "Sell";
    IconNames["StockDown"] = "StockDown";
    IconNames["StockUp"] = "StockUp";
    IconNames["Lamp"] = "Lamp";
    IconNames["Source"] = "Source";
    IconNames["MSNVideos"] = "MSNVideos";
    IconNames["Cricket"] = "Cricket";
    IconNames["Golf"] = "Golf";
    IconNames["Baseball"] = "Baseball";
    IconNames["Soccer"] = "Soccer";
    IconNames["MoreSports"] = "MoreSports";
    IconNames["AutoRacing"] = "AutoRacing";
    IconNames["CollegeHoops"] = "CollegeHoops";
    IconNames["CollegeFootball"] = "CollegeFootball";
    IconNames["ProFootball"] = "ProFootball";
    IconNames["ProHockey"] = "ProHockey";
    IconNames["Rugby"] = "Rugby";
    IconNames["SubstitutionsIn"] = "SubstitutionsIn";
    IconNames["Tennis"] = "Tennis";
    IconNames["Arrivals"] = "Arrivals";
    IconNames["Design"] = "Design";
    IconNames["Website"] = "Website";
    IconNames["Drop"] = "Drop";
    IconNames["HistoricalWeather"] = "HistoricalWeather";
    IconNames["SkiResorts"] = "SkiResorts";
    IconNames["Snowflake"] = "Snowflake";
    IconNames["BusSolid"] = "BusSolid";
    IconNames["FerrySolid"] = "FerrySolid";
    IconNames["AirplaneSolid"] = "AirplaneSolid";
    IconNames["TrainSolid"] = "TrainSolid";
    IconNames["Heart"] = "Heart";
    IconNames["HeartFill"] = "HeartFill";
    IconNames["Ticket"] = "Ticket";
    IconNames["WifiWarning4"] = "WifiWarning4";
    IconNames["Devices4"] = "Devices4";
    IconNames["AzureLogo"] = "AzureLogo";
    IconNames["BingLogo"] = "BingLogo";
    IconNames["MSNLogo"] = "MSNLogo";
    IconNames["OutlookLogoInverse"] = "OutlookLogoInverse";
    IconNames["OfficeLogo"] = "OfficeLogo";
    IconNames["SkypeLogo"] = "SkypeLogo";
    IconNames["Door"] = "Door";
    IconNames["EditMirrored"] = "EditMirrored";
    IconNames["GiftCard"] = "GiftCard";
    IconNames["DoubleBookmark"] = "DoubleBookmark";
    IconNames["StatusErrorFull"] = "StatusErrorFull";
    IconNames["Certificate"] = "Certificate";
    IconNames["FastForward"] = "FastForward";
    IconNames["Rewind"] = "Rewind";
    IconNames["Photo2"] = "Photo2";
    IconNames["OpenSource"] = "OpenSource";
    IconNames["Movers"] = "Movers";
    IconNames["CloudDownload"] = "CloudDownload";
    IconNames["Family"] = "Family";
    IconNames["WindDirection"] = "WindDirection";
    IconNames["Bug"] = "Bug";
    IconNames["SiteScan"] = "SiteScan";
    IconNames["BrowserScreenShot"] = "BrowserScreenShot";
    IconNames["F12DevTools"] = "F12DevTools";
    IconNames["CSS"] = "CSS";
    IconNames["JS"] = "JS";
    IconNames["DeliveryTruck"] = "DeliveryTruck";
    IconNames["ReminderPerson"] = "ReminderPerson";
    IconNames["ReminderGroup"] = "ReminderGroup";
    IconNames["TabletMode"] = "TabletMode";
    IconNames["Umbrella"] = "Umbrella";
    IconNames["NetworkTower"] = "NetworkTower";
    IconNames["CityNext"] = "CityNext";
    IconNames["CityNext2"] = "CityNext2";
    IconNames["Section"] = "Section";
    IconNames["OneNoteLogoInverse"] = "OneNoteLogoInverse";
    IconNames["ToggleFilled"] = "ToggleFilled";
    IconNames["ToggleBorder"] = "ToggleBorder";
    IconNames["SliderThumb"] = "SliderThumb";
    IconNames["ToggleThumb"] = "ToggleThumb";
    IconNames["MiracastLogoLarge"] = "MiracastLogoLarge";
    IconNames["Documentation"] = "Documentation";
    IconNames["Badge"] = "Badge";
    IconNames["Giftbox"] = "Giftbox";
    IconNames["VisualStudioLogo"] = "VisualStudioLogo";
    IconNames["HomeGroup"] = "HomeGroup";
    IconNames["ExcelLogoInverse"] = "ExcelLogoInverse";
    IconNames["WordLogoInverse"] = "WordLogoInverse";
    IconNames["PowerPointLogoInverse"] = "PowerPointLogoInverse";
    IconNames["Cafe"] = "Cafe";
    IconNames["SpeedHigh"] = "SpeedHigh";
    IconNames["Commitments"] = "Commitments";
    IconNames["ThisPC"] = "ThisPC";
    IconNames["MusicNote"] = "MusicNote";
    IconNames["MicOff"] = "MicOff";
    IconNames["PlaybackRate1x"] = "PlaybackRate1x";
    IconNames["EdgeLogo"] = "EdgeLogo";
    IconNames["CompletedSolid"] = "CompletedSolid";
    IconNames["AlbumRemove"] = "AlbumRemove";
    IconNames["MessageFill"] = "MessageFill";
    IconNames["TabletSelected"] = "TabletSelected";
    IconNames["MobileSelected"] = "MobileSelected";
    IconNames["LaptopSelected"] = "LaptopSelected";
    IconNames["TVMonitorSelected"] = "TVMonitorSelected";
    IconNames["DeveloperTools"] = "DeveloperTools";
    IconNames["Shapes"] = "Shapes";
    IconNames["InsertTextBox"] = "InsertTextBox";
    IconNames["LowerBrightness"] = "LowerBrightness";
    IconNames["WebComponents"] = "WebComponents";
    IconNames["OfflineStorage"] = "OfflineStorage";
    IconNames["DOM"] = "DOM";
    IconNames["CloudUpload"] = "CloudUpload";
    IconNames["ScrollUpDown"] = "ScrollUpDown";
    IconNames["DateTime"] = "DateTime";
    IconNames["Event"] = "Event";
    IconNames["Cake"] = "Cake";
    IconNames["Tiles"] = "Tiles";
    IconNames["Org"] = "Org";
    IconNames["PartyLeader"] = "PartyLeader";
    IconNames["DRM"] = "DRM";
    IconNames["CloudAdd"] = "CloudAdd";
    IconNames["AppIconDefault"] = "AppIconDefault";
    IconNames["Photo2Add"] = "Photo2Add";
    IconNames["Photo2Remove"] = "Photo2Remove";
    IconNames["Calories"] = "Calories";
    IconNames["POI"] = "POI";
    IconNames["FacebookLogo"] = "FacebookLogo";
    IconNames["TwitterLogo"] = "TwitterLogo";
    IconNames["AddTo"] = "AddTo";
    IconNames["RadioBtnOff"] = "RadioBtnOff";
    IconNames["RadioBtnOn"] = "RadioBtnOn";
    IconNames["ExploreContent"] = "ExploreContent";
    IconNames["Embed"] = "Embed";
    IconNames["Product"] = "Product";
    IconNames["ProgressLoopInner"] = "ProgressLoopInner";
    IconNames["ProgressLoopOuter"] = "ProgressLoopOuter";
    IconNames["Blocked2"] = "Blocked2";
    IconNames["FangBody"] = "FangBody";
    IconNames["PageHeader"] = "PageHeader";
    IconNames["Glimmer"] = "Glimmer";
    IconNames["ChatInviteFriend"] = "ChatInviteFriend";
    IconNames["Brush"] = "Brush";
    IconNames["Shirt"] = "Shirt";
    IconNames["Crown"] = "Crown";
    IconNames["Diamond"] = "Diamond";
    IconNames["ScaleUp"] = "ScaleUp";
    IconNames["QRCode"] = "QRCode";
    IconNames["Feedback"] = "Feedback";
    IconNames["SharepointLogoInverse"] = "SharepointLogoInverse";
    IconNames["YammerLogo"] = "YammerLogo";
    IconNames["Hide"] = "Hide";
    IconNames["Uneditable"] = "Uneditable";
    IconNames["ReturnToSession"] = "ReturnToSession";
    IconNames["OpenFolderHorizontal"] = "OpenFolderHorizontal";
    IconNames["CalendarMirrored"] = "CalendarMirrored";
    IconNames["SwayLogoInverse"] = "SwayLogoInverse";
    IconNames["OutOfOffice"] = "OutOfOffice";
    IconNames["Trophy"] = "Trophy";
    IconNames["ReopenPages"] = "ReopenPages";
    IconNames["EmojiTabSymbols"] = "EmojiTabSymbols";
    IconNames["AADLogo"] = "AADLogo";
    IconNames["AccessLogo"] = "AccessLogo";
    IconNames["AdminALogoInverse32"] = "AdminALogoInverse32";
    IconNames["AdminCLogoInverse32"] = "AdminCLogoInverse32";
    IconNames["AdminDLogoInverse32"] = "AdminDLogoInverse32";
    IconNames["AdminELogoInverse32"] = "AdminELogoInverse32";
    IconNames["AdminLLogoInverse32"] = "AdminLLogoInverse32";
    IconNames["AdminMLogoInverse32"] = "AdminMLogoInverse32";
    IconNames["AdminOLogoInverse32"] = "AdminOLogoInverse32";
    IconNames["AdminPLogoInverse32"] = "AdminPLogoInverse32";
    IconNames["AdminSLogoInverse32"] = "AdminSLogoInverse32";
    IconNames["AdminYLogoInverse32"] = "AdminYLogoInverse32";
    IconNames["BoxLogo"] = "BoxLogo";
    IconNames["DelveLogoInverse"] = "DelveLogoInverse";
    IconNames["DropboxLogo"] = "DropboxLogo";
    IconNames["ExchangeLogoInverse"] = "ExchangeLogoInverse";
    IconNames["LyncLogo"] = "LyncLogo";
    IconNames["OfficeVideoLogoInverse"] = "OfficeVideoLogoInverse";
    IconNames["ParatureLogo"] = "ParatureLogo";
    IconNames["SocialListeningLogo"] = "SocialListeningLogo";
    IconNames["VisioLogoInverse"] = "VisioLogoInverse";
    IconNames["Balloons"] = "Balloons";
    IconNames["Cat"] = "Cat";
    IconNames["MailAlert"] = "MailAlert";
    IconNames["MailCheck"] = "MailCheck";
    IconNames["MailLowImportance"] = "MailLowImportance";
    IconNames["MailPause"] = "MailPause";
    IconNames["MailRepeat"] = "MailRepeat";
    IconNames["SecurityGroup"] = "SecurityGroup";
    IconNames["Table"] = "Table";
    IconNames["VoicemailForward"] = "VoicemailForward";
    IconNames["VoicemailReply"] = "VoicemailReply";
    IconNames["Waffle"] = "Waffle";
    IconNames["RemoveEvent"] = "RemoveEvent";
    IconNames["EventInfo"] = "EventInfo";
    IconNames["ForwardEvent"] = "ForwardEvent";
    IconNames["WipePhone"] = "WipePhone";
    IconNames["AddOnlineMeeting"] = "AddOnlineMeeting";
    IconNames["JoinOnlineMeeting"] = "JoinOnlineMeeting";
    IconNames["RemoveLink"] = "RemoveLink";
    IconNames["PeopleBlock"] = "PeopleBlock";
    IconNames["PeopleRepeat"] = "PeopleRepeat";
    IconNames["PeopleAlert"] = "PeopleAlert";
    IconNames["PeoplePause"] = "PeoplePause";
    IconNames["TransferCall"] = "TransferCall";
    IconNames["AddPhone"] = "AddPhone";
    IconNames["UnknownCall"] = "UnknownCall";
    IconNames["NoteReply"] = "NoteReply";
    IconNames["NoteForward"] = "NoteForward";
    IconNames["NotePinned"] = "NotePinned";
    IconNames["RemoveOccurrence"] = "RemoveOccurrence";
    IconNames["Timeline"] = "Timeline";
    IconNames["EditNote"] = "EditNote";
    IconNames["CircleHalfFull"] = "CircleHalfFull";
    IconNames["Room"] = "Room";
    IconNames["Unsubscribe"] = "Unsubscribe";
    IconNames["Subscribe"] = "Subscribe";
    IconNames["HardDrive"] = "HardDrive";
    IconNames["RecurringTask"] = "RecurringTask";
    IconNames["TaskManager"] = "TaskManager";
    IconNames["TaskManagerMirrored"] = "TaskManagerMirrored";
    IconNames["Combine"] = "Combine";
    IconNames["Split"] = "Split";
    IconNames["DoubleChevronUp"] = "DoubleChevronUp";
    IconNames["DoubleChevronLeft"] = "DoubleChevronLeft";
    IconNames["DoubleChevronRight"] = "DoubleChevronRight";
    IconNames["Ascending"] = "Ascending";
    IconNames["Descending"] = "Descending";
    IconNames["TextBox"] = "TextBox";
    IconNames["TextField"] = "TextField";
    IconNames["NumberField"] = "NumberField";
    IconNames["Dropdown"] = "Dropdown";
    IconNames["PenWorkspace"] = "PenWorkspace";
    IconNames["BookingsLogo"] = "BookingsLogo";
    IconNames["ClassNotebookLogoInverse"] = "ClassNotebookLogoInverse";
    IconNames["DelveAnalyticsLogo"] = "DelveAnalyticsLogo";
    IconNames["DocsLogoInverse"] = "DocsLogoInverse";
    IconNames["Dynamics365Logo"] = "Dynamics365Logo";
    IconNames["DynamicSMBLogo"] = "DynamicSMBLogo";
    IconNames["OfficeAssistantLogo"] = "OfficeAssistantLogo";
    IconNames["OfficeStoreLogo"] = "OfficeStoreLogo";
    IconNames["OneNoteEduLogoInverse"] = "OneNoteEduLogoInverse";
    IconNames["PlannerLogo"] = "PlannerLogo";
    IconNames["PowerApps"] = "PowerApps";
    IconNames["Suitcase"] = "Suitcase";
    IconNames["ProjectLogoInverse"] = "ProjectLogoInverse";
    IconNames["CaretLeft8"] = "CaretLeft8";
    IconNames["CaretRight8"] = "CaretRight8";
    IconNames["CaretUp8"] = "CaretUp8";
    IconNames["CaretDown8"] = "CaretDown8";
    IconNames["CaretLeftSolid8"] = "CaretLeftSolid8";
    IconNames["CaretRightSolid8"] = "CaretRightSolid8";
    IconNames["CaretUpSolid8"] = "CaretUpSolid8";
    IconNames["CaretDownSolid8"] = "CaretDownSolid8";
    IconNames["ClearFormatting"] = "ClearFormatting";
    IconNames["Superscript"] = "Superscript";
    IconNames["Subscript"] = "Subscript";
    IconNames["Strikethrough"] = "Strikethrough";
    IconNames["Export"] = "Export";
    IconNames["ExportMirrored"] = "ExportMirrored";
    IconNames["SingleBookmark"] = "SingleBookmark";
    IconNames["SingleBookmarkSolid"] = "SingleBookmarkSolid";
    IconNames["DoubleChevronDown"] = "DoubleChevronDown";
    IconNames["FollowUser"] = "FollowUser";
    IconNames["ReplyAll"] = "ReplyAll";
    IconNames["GoogleDriveLogo"] = "GoogleDriveLogo";
    IconNames["WorkforceManagement"] = "WorkforceManagement";
    IconNames["RecruitmentManagement"] = "RecruitmentManagement";
    IconNames["Questionnaire"] = "Questionnaire";
    IconNames["ManagerSelfService"] = "ManagerSelfService";
    IconNames["ProductRelease"] = "ProductRelease";
    IconNames["ReplyMirrored"] = "ReplyMirrored";
    IconNames["ReplyAllMirrored"] = "ReplyAllMirrored";
    IconNames["Medal"] = "Medal";
    IconNames["AddGroup"] = "AddGroup";
    IconNames["QuestionnaireMirrored"] = "QuestionnaireMirrored";
    IconNames["TemporaryUser"] = "TemporaryUser";
    IconNames["CaretSolid16"] = "CaretSolid16";
    IconNames["GroupedDescending"] = "GroupedDescending";
    IconNames["GroupedAscending"] = "GroupedAscending";
    IconNames["SortUp"] = "SortUp";
    IconNames["SortDown"] = "SortDown";
    IconNames["AwayStatus"] = "AwayStatus";
    IconNames["MyMoviesTV"] = "MyMoviesTV";
    IconNames["SyncToPC"] = "SyncToPC";
    IconNames["GenericScan"] = "GenericScan";
    IconNames["AustralianRules"] = "AustralianRules";
    IconNames["WifiEthernet"] = "WifiEthernet";
    IconNames["TrackersMirrored"] = "TrackersMirrored";
    IconNames["DateTimeMirrored"] = "DateTimeMirrored";
    IconNames["StopSolid"] = "StopSolid";
    IconNames["DoubleChevronUp12"] = "DoubleChevronUp12";
    IconNames["DoubleChevronDown12"] = "DoubleChevronDown12";
    IconNames["DoubleChevronLeft12"] = "DoubleChevronLeft12";
    IconNames["DoubleChevronRight12"] = "DoubleChevronRight12";
    IconNames["CalendarAgenda"] = "CalendarAgenda";
    IconNames["ConnectVirtualMachine"] = "ConnectVirtualMachine";
    IconNames["AddEvent"] = "AddEvent";
    IconNames["AssetLibrary"] = "AssetLibrary";
    IconNames["DataConnectionLibrary"] = "DataConnectionLibrary";
    IconNames["DocLibrary"] = "DocLibrary";
    IconNames["FormLibrary"] = "FormLibrary";
    IconNames["FormLibraryMirrored"] = "FormLibraryMirrored";
    IconNames["ReportLibrary"] = "ReportLibrary";
    IconNames["ReportLibraryMirrored"] = "ReportLibraryMirrored";
    IconNames["ContactCard"] = "ContactCard";
    IconNames["CustomList"] = "CustomList";
    IconNames["CustomListMirrored"] = "CustomListMirrored";
    IconNames["IssueTracking"] = "IssueTracking";
    IconNames["IssueTrackingMirrored"] = "IssueTrackingMirrored";
    IconNames["PictureLibrary"] = "PictureLibrary";
    IconNames["OfficeAddinsLogo"] = "OfficeAddinsLogo";
    IconNames["OfflineOneDriveParachute"] = "OfflineOneDriveParachute";
    IconNames["OfflineOneDriveParachuteDisabled"] = "OfflineOneDriveParachuteDisabled";
    IconNames["LargeGrid"] = "LargeGrid";
    IconNames["TriangleSolidUp12"] = "TriangleSolidUp12";
    IconNames["TriangleSolidDown12"] = "TriangleSolidDown12";
    IconNames["TriangleSolidLeft12"] = "TriangleSolidLeft12";
    IconNames["TriangleSolidRight12"] = "TriangleSolidRight12";
    IconNames["TriangleUp12"] = "TriangleUp12";
    IconNames["TriangleDown12"] = "TriangleDown12";
    IconNames["TriangleLeft12"] = "TriangleLeft12";
    IconNames["TriangleRight12"] = "TriangleRight12";
    IconNames["ArrowUpRight8"] = "ArrowUpRight8";
    IconNames["ArrowDownRight8"] = "ArrowDownRight8";
    IconNames["DocumentSet"] = "DocumentSet";
    IconNames["DelveAnalytics"] = "DelveAnalytics";
    IconNames["ArrowUpRightMirrored8"] = "ArrowUpRightMirrored8";
    IconNames["ArrowDownRightMirrored8"] = "ArrowDownRightMirrored8";
    IconNames["CompanyDirectory"] = "CompanyDirectory";
    IconNames["OpenEnrollment"] = "OpenEnrollment";
    IconNames["CompanyDirectoryMirrored"] = "CompanyDirectoryMirrored";
    IconNames["OneDriveAdd"] = "OneDriveAdd";
    IconNames["ProfileSearch"] = "ProfileSearch";
    IconNames["Header2"] = "Header2";
    IconNames["Header3"] = "Header3";
    IconNames["Header4"] = "Header4";
    IconNames["RingerSolid"] = "RingerSolid";
    IconNames["Eyedropper"] = "Eyedropper";
    IconNames["MarketDown"] = "MarketDown";
    IconNames["CalendarWorkWeek"] = "CalendarWorkWeek";
    IconNames["SidePanel"] = "SidePanel";
    IconNames["GlobeFavorite"] = "GlobeFavorite";
    IconNames["CaretTopLeftSolid8"] = "CaretTopLeftSolid8";
    IconNames["CaretTopRightSolid8"] = "CaretTopRightSolid8";
    IconNames["ViewAll2"] = "ViewAll2";
    IconNames["DocumentReply"] = "DocumentReply";
    IconNames["PlayerSettings"] = "PlayerSettings";
    IconNames["ReceiptForward"] = "ReceiptForward";
    IconNames["ReceiptReply"] = "ReceiptReply";
    IconNames["ReceiptCheck"] = "ReceiptCheck";
    IconNames["Fax"] = "Fax";
    IconNames["RecurringEvent"] = "RecurringEvent";
    IconNames["ReplyAlt"] = "ReplyAlt";
    IconNames["ReplyAllAlt"] = "ReplyAllAlt";
    IconNames["EditStyle"] = "EditStyle";
    IconNames["EditMail"] = "EditMail";
    IconNames["Lifesaver"] = "Lifesaver";
    IconNames["LifesaverLock"] = "LifesaverLock";
    IconNames["InboxCheck"] = "InboxCheck";
    IconNames["FolderSearch"] = "FolderSearch";
    IconNames["CollapseMenu"] = "CollapseMenu";
    IconNames["ExpandMenu"] = "ExpandMenu";
    IconNames["Boards"] = "Boards";
    IconNames["SunAdd"] = "SunAdd";
    IconNames["SunQuestionMark"] = "SunQuestionMark";
    IconNames["LandscapeOrientation"] = "LandscapeOrientation";
    IconNames["DocumentSearch"] = "DocumentSearch";
    IconNames["PublicCalendar"] = "PublicCalendar";
    IconNames["PublicContactCard"] = "PublicContactCard";
    IconNames["PublicEmail"] = "PublicEmail";
    IconNames["PublicFolder"] = "PublicFolder";
    IconNames["WordDocument"] = "WordDocument";
    IconNames["PowerPointDocument"] = "PowerPointDocument";
    IconNames["ExcelDocument"] = "ExcelDocument";
    IconNames["GroupedList"] = "GroupedList";
    IconNames["ClassroomLogo"] = "ClassroomLogo";
    IconNames["Sections"] = "Sections";
    IconNames["EditPhoto"] = "EditPhoto";
    IconNames["Starburst"] = "Starburst";
    IconNames["ShareiOS"] = "ShareiOS";
    IconNames["AirTickets"] = "AirTickets";
    IconNames["PencilReply"] = "PencilReply";
    IconNames["Tiles2"] = "Tiles2";
    IconNames["SkypeCircleCheck"] = "SkypeCircleCheck";
    IconNames["SkypeCircleClock"] = "SkypeCircleClock";
    IconNames["SkypeCircleMinus"] = "SkypeCircleMinus";
    IconNames["SkypeCheck"] = "SkypeCheck";
    IconNames["SkypeClock"] = "SkypeClock";
    IconNames["SkypeMinus"] = "SkypeMinus";
    IconNames["SkypeMessage"] = "SkypeMessage";
    IconNames["ClosedCaption"] = "ClosedCaption";
    IconNames["ATPLogo"] = "ATPLogo";
    IconNames["OfficeFormsLogoInverse"] = "OfficeFormsLogoInverse";
    IconNames["RecycleBin"] = "RecycleBin";
    IconNames["EmptyRecycleBin"] = "EmptyRecycleBin";
    IconNames["Hide2"] = "Hide2";
    IconNames["iOSAppStoreLogo"] = "iOSAppStoreLogo";
    IconNames["AndroidLogo"] = "AndroidLogo";
    IconNames["Breadcrumb"] = "Breadcrumb";
    IconNames["BirthdayCake"] = "BirthdayCake";
    IconNames["ClearFilter"] = "ClearFilter";
    IconNames["Flow"] = "Flow";
    IconNames["TimeEntry"] = "TimeEntry";
    IconNames["CRMProcesses"] = "CRMProcesses";
    IconNames["PageEdit"] = "PageEdit";
    IconNames["PageArrowRight"] = "PageArrowRight";
    IconNames["PageRemove"] = "PageRemove";
    IconNames["Database"] = "Database";
    IconNames["DataManagementSettings"] = "DataManagementSettings";
    IconNames["CRMServices"] = "CRMServices";
    IconNames["EditContact"] = "EditContact";
    IconNames["ConnectContacts"] = "ConnectContacts";
    IconNames["AppIconDefaultAdd"] = "AppIconDefaultAdd";
    IconNames["AppIconDefaultList"] = "AppIconDefaultList";
    IconNames["ActivateOrders"] = "ActivateOrders";
    IconNames["DeactivateOrders"] = "DeactivateOrders";
    IconNames["ScatterChart"] = "ScatterChart";
    IconNames["DocumentManagement"] = "DocumentManagement";
    IconNames["CRMReport"] = "CRMReport";
    IconNames["KnowledgeArticle"] = "KnowledgeArticle";
    IconNames["Relationship"] = "Relationship";
    IconNames["HomeVerify"] = "HomeVerify";
    IconNames["ZipFolder"] = "ZipFolder";
    IconNames["SurveyQuestions"] = "SurveyQuestions";
    IconNames["TextDocument"] = "TextDocument";
    IconNames["TextDocumentShared"] = "TextDocumentShared";
    IconNames["PageCheckedOut"] = "PageCheckedOut";
    IconNames["SaveAndClose"] = "SaveAndClose";
    IconNames["Script"] = "Script";
    IconNames["Archive"] = "Archive";
    IconNames["ActivityFeed"] = "ActivityFeed";
    IconNames["Compare"] = "Compare";
    IconNames["EventDate"] = "EventDate";
    IconNames["ArrowUpRight"] = "ArrowUpRight";
    IconNames["CaretRight"] = "CaretRight";
    IconNames["SetAction"] = "SetAction";
    IconNames["CaretSolidLeft"] = "CaretSolidLeft";
    IconNames["CaretSolidDown"] = "CaretSolidDown";
    IconNames["CaretSolidRight"] = "CaretSolidRight";
    IconNames["CaretSolidUp"] = "CaretSolidUp";
    IconNames["PowerAppsLogo"] = "PowerAppsLogo";
    IconNames["PowerApps2Logo"] = "PowerApps2Logo";
    IconNames["SearchIssue"] = "SearchIssue";
    IconNames["SearchIssueMirrored"] = "SearchIssueMirrored";
    IconNames["FabricAssetLibrary"] = "FabricAssetLibrary";
    IconNames["FabricDataConnectionLibrary"] = "FabricDataConnectionLibrary";
    IconNames["FabricDocLibrary"] = "FabricDocLibrary";
    IconNames["FabricFormLibrary"] = "FabricFormLibrary";
    IconNames["FabricFormLibraryMirrored"] = "FabricFormLibraryMirrored";
    IconNames["FabricReportLibrary"] = "FabricReportLibrary";
    IconNames["FabricReportLibraryMirrored"] = "FabricReportLibraryMirrored";
    IconNames["FabricPublicFolder"] = "FabricPublicFolder";
    IconNames["FabricFolderSearch"] = "FabricFolderSearch";
    IconNames["FabricMovetoFolder"] = "FabricMovetoFolder";
    IconNames["FabricUnsyncFolder"] = "FabricUnsyncFolder";
    IconNames["FabricSyncFolder"] = "FabricSyncFolder";
    IconNames["FabricOpenFolderHorizontal"] = "FabricOpenFolderHorizontal";
    IconNames["FabricFolder"] = "FabricFolder";
    IconNames["FabricFolderFill"] = "FabricFolderFill";
    IconNames["FabricNewFolder"] = "FabricNewFolder";
    IconNames["FabricPictureLibrary"] = "FabricPictureLibrary";
    IconNames["AddFavorite"] = "AddFavorite";
    IconNames["AddFavoriteFill"] = "AddFavoriteFill";
    IconNames["BufferTimeBefore"] = "BufferTimeBefore";
    IconNames["BufferTimeAfter"] = "BufferTimeAfter";
    IconNames["BufferTimeBoth"] = "BufferTimeBoth";
    IconNames["PublishContent"] = "PublishContent";
    IconNames["ClipboardList"] = "ClipboardList";
    IconNames["ClipboardListMirrored"] = "ClipboardListMirrored";
    IconNames["CannedChat"] = "CannedChat";
    IconNames["SkypeForBusinessLogo"] = "SkypeForBusinessLogo";
    IconNames["TabCenter"] = "TabCenter";
    IconNames["PageCheckedin"] = "PageCheckedin";
    IconNames["PageList"] = "PageList";
    IconNames["ReadOutLoud"] = "ReadOutLoud";
    IconNames["CaretBottomLeftSolid8"] = "CaretBottomLeftSolid8";
    IconNames["CaretBottomRightSolid8"] = "CaretBottomRightSolid8";
    IconNames["FolderHorizontal"] = "FolderHorizontal";
    IconNames["MicrosoftStaffhubLogo"] = "MicrosoftStaffhubLogo";
    IconNames["GiftboxOpen"] = "GiftboxOpen";
    IconNames["StatusCircleOuter"] = "StatusCircleOuter";
    IconNames["StatusCircleInner"] = "StatusCircleInner";
    IconNames["StatusCircleRing"] = "StatusCircleRing";
    IconNames["StatusTriangleOuter"] = "StatusTriangleOuter";
    IconNames["StatusTriangleInner"] = "StatusTriangleInner";
    IconNames["StatusTriangleExclamation"] = "StatusTriangleExclamation";
    IconNames["StatusCircleExclamation"] = "StatusCircleExclamation";
    IconNames["StatusCircleErrorX"] = "StatusCircleErrorX";
    IconNames["StatusCircleCheckmark"] = "StatusCircleCheckmark";
    IconNames["StatusCircleInfo"] = "StatusCircleInfo";
    IconNames["StatusCircleBlock"] = "StatusCircleBlock";
    IconNames["StatusCircleBlock2"] = "StatusCircleBlock2";
    IconNames["StatusCircleQuestionMark"] = "StatusCircleQuestionMark";
    IconNames["StatusCircleSync"] = "StatusCircleSync";
    IconNames["Toll"] = "Toll";
    IconNames["ExploreContentSingle"] = "ExploreContentSingle";
    IconNames["CollapseContent"] = "CollapseContent";
    IconNames["CollapseContentSingle"] = "CollapseContentSingle";
    IconNames["InfoSolid"] = "InfoSolid";
    IconNames["GroupList"] = "GroupList";
    IconNames["ProgressRingDots"] = "ProgressRingDots";
    IconNames["CaloriesAdd"] = "CaloriesAdd";
    IconNames["BranchFork"] = "BranchFork";
    IconNames["AddHome"] = "AddHome";
    IconNames["AddWork"] = "AddWork";
    IconNames["MobileReport"] = "MobileReport";
    IconNames["ScaleVolume"] = "ScaleVolume";
    IconNames["HardDriveGroup"] = "HardDriveGroup";
    IconNames["FastMode"] = "FastMode";
    IconNames["ToggleLeft"] = "ToggleLeft";
    IconNames["ToggleRight"] = "ToggleRight";
    IconNames["TriangleShape"] = "TriangleShape";
    IconNames["RectangleShape"] = "RectangleShape";
    IconNames["CubeShape"] = "CubeShape";
    IconNames["Trophy2"] = "Trophy2";
    IconNames["BucketColor"] = "BucketColor";
    IconNames["BucketColorFill"] = "BucketColorFill";
    IconNames["Taskboard"] = "Taskboard";
    IconNames["SingleColumn"] = "SingleColumn";
    IconNames["DoubleColumn"] = "DoubleColumn";
    IconNames["TripleColumn"] = "TripleColumn";
    IconNames["ColumnLeftTwoThirds"] = "ColumnLeftTwoThirds";
    IconNames["ColumnRightTwoThirds"] = "ColumnRightTwoThirds";
    IconNames["AccessLogoFill"] = "AccessLogoFill";
    IconNames["AnalyticsLogo"] = "AnalyticsLogo";
    IconNames["AnalyticsQuery"] = "AnalyticsQuery";
    IconNames["NewAnalyticsQuery"] = "NewAnalyticsQuery";
    IconNames["AnalyticsReport"] = "AnalyticsReport";
    IconNames["WordLogo"] = "WordLogo";
    IconNames["WordLogoFill"] = "WordLogoFill";
    IconNames["ExcelLogo"] = "ExcelLogo";
    IconNames["ExcelLogoFill"] = "ExcelLogoFill";
    IconNames["OneNoteLogo"] = "OneNoteLogo";
    IconNames["OneNoteLogoFill"] = "OneNoteLogoFill";
    IconNames["OutlookLogo"] = "OutlookLogo";
    IconNames["OutlookLogoFill"] = "OutlookLogoFill";
    IconNames["PowerPointLogo"] = "PowerPointLogo";
    IconNames["PowerPointLogoFill"] = "PowerPointLogoFill";
    IconNames["PublisherLogo"] = "PublisherLogo";
    IconNames["PublisherLogoFill"] = "PublisherLogoFill";
    IconNames["ScheduleEventAction"] = "ScheduleEventAction";
    IconNames["FlameSolid"] = "FlameSolid";
    IconNames["ServerProcesses"] = "ServerProcesses";
    IconNames["Server"] = "Server";
    IconNames["SaveAll"] = "SaveAll";
    IconNames["LinkedInLogo"] = "LinkedInLogo";
    IconNames["Decimals"] = "Decimals";
    IconNames["SidePanelMirrored"] = "SidePanelMirrored";
    IconNames["ProtectRestrict"] = "ProtectRestrict";
    IconNames["Blog"] = "Blog";
    IconNames["UnknownMirrored"] = "UnknownMirrored";
    IconNames["PublicContactCardMirrored"] = "PublicContactCardMirrored";
    IconNames["GridViewSmall"] = "GridViewSmall";
    IconNames["GridViewMedium"] = "GridViewMedium";
    IconNames["GridViewLarge"] = "GridViewLarge";
    IconNames["Step"] = "Step";
    IconNames["StepInsert"] = "StepInsert";
    IconNames["StepShared"] = "StepShared";
    IconNames["StepSharedAdd"] = "StepSharedAdd";
    IconNames["StepSharedInsert"] = "StepSharedInsert";
    IconNames["ViewDashboard"] = "ViewDashboard";
    IconNames["ViewList"] = "ViewList";
    IconNames["ViewListGroup"] = "ViewListGroup";
    IconNames["ViewListTree"] = "ViewListTree";
    IconNames["TriggerAuto"] = "TriggerAuto";
    IconNames["TriggerUser"] = "TriggerUser";
    IconNames["PivotChart"] = "PivotChart";
    IconNames["StackedBarChart"] = "StackedBarChart";
    IconNames["StackedLineChart"] = "StackedLineChart";
    IconNames["BuildQueue"] = "BuildQueue";
    IconNames["BuildQueueNew"] = "BuildQueueNew";
    IconNames["UserFollowed"] = "UserFollowed";
    IconNames["ContactLink"] = "ContactLink";
    IconNames["Stack"] = "Stack";
    IconNames["Bullseye"] = "Bullseye";
    IconNames["VennDiagram"] = "VennDiagram";
    IconNames["FiveTileGrid"] = "FiveTileGrid";
    IconNames["FocalPoint"] = "FocalPoint";
    IconNames["RingerRemove"] = "RingerRemove";
    IconNames["TeamsLogoInverse"] = "TeamsLogoInverse";
    IconNames["TeamsLogo"] = "TeamsLogo";
    IconNames["TeamsLogoFill"] = "TeamsLogoFill";
    IconNames["SkypeForBusinessLogoFill"] = "SkypeForBusinessLogoFill";
    IconNames["SharepointLogo"] = "SharepointLogo";
    IconNames["SharepointLogoFill"] = "SharepointLogoFill";
    IconNames["DelveLogo"] = "DelveLogo";
    IconNames["DelveLogoFill"] = "DelveLogoFill";
    IconNames["OfficeVideoLogo"] = "OfficeVideoLogo";
    IconNames["OfficeVideoLogoFill"] = "OfficeVideoLogoFill";
    IconNames["ExchangeLogo"] = "ExchangeLogo";
    IconNames["ExchangeLogoFill"] = "ExchangeLogoFill";
    IconNames["Signin"] = "Signin";
    IconNames["DocumentApproval"] = "DocumentApproval";
    IconNames["CloneToDesktop"] = "CloneToDesktop";
    IconNames["InstallToDrive"] = "InstallToDrive";
    IconNames["Blur"] = "Blur";
    IconNames["Build"] = "Build";
    IconNames["ProcessMetaTask"] = "ProcessMetaTask";
    IconNames["BranchFork2"] = "BranchFork2";
    IconNames["BranchLocked"] = "BranchLocked";
    IconNames["BranchCommit"] = "BranchCommit";
    IconNames["BranchCompare"] = "BranchCompare";
    IconNames["BranchMerge"] = "BranchMerge";
    IconNames["BranchPullRequest"] = "BranchPullRequest";
    IconNames["BranchSearch"] = "BranchSearch";
    IconNames["BranchShelveset"] = "BranchShelveset";
    IconNames["RawSource"] = "RawSource";
    IconNames["MergeDuplicate"] = "MergeDuplicate";
    IconNames["RowsGroup"] = "RowsGroup";
    IconNames["RowsChild"] = "RowsChild";
    IconNames["Deploy"] = "Deploy";
    IconNames["Redeploy"] = "Redeploy";
    IconNames["ServerEnviroment"] = "ServerEnviroment";
    IconNames["VisioDiagram"] = "VisioDiagram";
    IconNames["HighlightMappedShapes"] = "HighlightMappedShapes";
    IconNames["TextCallout"] = "TextCallout";
    IconNames["IconSetsFlag"] = "IconSetsFlag";
    IconNames["VisioLogo"] = "VisioLogo";
    IconNames["VisioLogoFill"] = "VisioLogoFill";
    IconNames["VisioDocument"] = "VisioDocument";
    IconNames["TimelineProgress"] = "TimelineProgress";
    IconNames["TimelineDelivery"] = "TimelineDelivery";
    IconNames["Backlog"] = "Backlog";
    IconNames["TeamFavorite"] = "TeamFavorite";
    IconNames["TaskGroup"] = "TaskGroup";
    IconNames["TaskGroupMirrored"] = "TaskGroupMirrored";
    IconNames["ScopeTemplate"] = "ScopeTemplate";
    IconNames["AssessmentGroupTemplate"] = "AssessmentGroupTemplate";
    IconNames["NewTeamProject"] = "NewTeamProject";
    IconNames["CommentAdd"] = "CommentAdd";
    IconNames["CommentNext"] = "CommentNext";
    IconNames["CommentPrevious"] = "CommentPrevious";
    IconNames["ShopServer"] = "ShopServer";
    IconNames["LocaleLanguage"] = "LocaleLanguage";
    IconNames["QueryList"] = "QueryList";
    IconNames["UserSync"] = "UserSync";
    IconNames["UserPause"] = "UserPause";
    IconNames["StreamingOff"] = "StreamingOff";
    IconNames["MoreVertical"] = "MoreVertical";
    IconNames["ArrowTallUpLeft"] = "ArrowTallUpLeft";
    IconNames["ArrowTallUpRight"] = "ArrowTallUpRight";
    IconNames["ArrowTallDownLeft"] = "ArrowTallDownLeft";
    IconNames["ArrowTallDownRight"] = "ArrowTallDownRight";
    IconNames["FieldEmpty"] = "FieldEmpty";
    IconNames["FieldFilled"] = "FieldFilled";
    IconNames["FieldChanged"] = "FieldChanged";
    IconNames["FieldNotChanged"] = "FieldNotChanged";
    IconNames["RingerOff"] = "RingerOff";
    IconNames["PlayResume"] = "PlayResume";
    IconNames["BulletedList2"] = "BulletedList2";
    IconNames["BulletedList2Mirrored"] = "BulletedList2Mirrored";
    IconNames["ImageCrosshair"] = "ImageCrosshair";
    IconNames["GitGraph"] = "GitGraph";
    IconNames["Repo"] = "Repo";
    IconNames["RepoSolid"] = "RepoSolid";
    IconNames["FolderQuery"] = "FolderQuery";
    IconNames["FolderList"] = "FolderList";
    IconNames["FolderListMirrored"] = "FolderListMirrored";
    IconNames["LocationOutline"] = "LocationOutline";
    IconNames["POISolid"] = "POISolid";
    IconNames["CalculatorNotEqualTo"] = "CalculatorNotEqualTo";
    IconNames["BoxSubtractSolid"] = "BoxSubtractSolid";
    IconNames["BoxAdditionSolid"] = "BoxAdditionSolid";
    IconNames["BoxMultiplySolid"] = "BoxMultiplySolid";
    IconNames["BoxPlaySolid"] = "BoxPlaySolid";
    IconNames["BoxCheckmarkSolid"] = "BoxCheckmarkSolid";
    IconNames["CirclePauseSolid"] = "CirclePauseSolid";
    IconNames["CirclePause"] = "CirclePause";
    IconNames["MSNVideosSolid"] = "MSNVideosSolid";
    IconNames["CircleStopSolid"] = "CircleStopSolid";
    IconNames["CircleStop"] = "CircleStop";
    IconNames["NavigateBack"] = "NavigateBack";
    IconNames["NavigateBackMirrored"] = "NavigateBackMirrored";
    IconNames["NavigateForward"] = "NavigateForward";
    IconNames["NavigateForwardMirrored"] = "NavigateForwardMirrored";
    IconNames["UnknownSolid"] = "UnknownSolid";
    IconNames["UnknownMirroredSolid"] = "UnknownMirroredSolid";
    IconNames["CircleAddition"] = "CircleAddition";
    IconNames["CircleAdditionSolid"] = "CircleAdditionSolid";
    IconNames["FilePDB"] = "FilePDB";
    IconNames["FileTemplate"] = "FileTemplate";
    IconNames["FileSQL"] = "FileSQL";
    IconNames["FileJAVA"] = "FileJAVA";
    IconNames["FileASPX"] = "FileASPX";
    IconNames["FileCSS"] = "FileCSS";
    IconNames["FileSass"] = "FileSass";
    IconNames["FileLess"] = "FileLess";
    IconNames["FileHTML"] = "FileHTML";
    IconNames["JavaScriptLanguage"] = "JavaScriptLanguage";
    IconNames["CSharpLanguage"] = "CSharpLanguage";
    IconNames["CSharp"] = "CSharp";
    IconNames["VisualBasicLanguage"] = "VisualBasicLanguage";
    IconNames["VB"] = "VB";
    IconNames["CPlusPlusLanguage"] = "CPlusPlusLanguage";
    IconNames["CPlusPlus"] = "CPlusPlus";
    IconNames["FSharpLanguage"] = "FSharpLanguage";
    IconNames["FSharp"] = "FSharp";
    IconNames["TypeScriptLanguage"] = "TypeScriptLanguage";
    IconNames["PythonLanguage"] = "PythonLanguage";
    IconNames["PY"] = "PY";
    IconNames["CoffeeScript"] = "CoffeeScript";
    IconNames["MarkDownLanguage"] = "MarkDownLanguage";
    IconNames["FullWidth"] = "FullWidth";
    IconNames["FullWidthEdit"] = "FullWidthEdit";
    IconNames["Plug"] = "Plug";
    IconNames["PlugSolid"] = "PlugSolid";
    IconNames["PlugConnected"] = "PlugConnected";
    IconNames["PlugDisconnected"] = "PlugDisconnected";
    IconNames["UnlockSolid"] = "UnlockSolid";
    IconNames["Variable"] = "Variable";
    IconNames["Parameter"] = "Parameter";
    IconNames["CommentUrgent"] = "CommentUrgent";
    IconNames["Storyboard"] = "Storyboard";
    IconNames["DiffInline"] = "DiffInline";
    IconNames["DiffSideBySide"] = "DiffSideBySide";
    IconNames["ImageDiff"] = "ImageDiff";
    IconNames["ImagePixel"] = "ImagePixel";
    IconNames["FileBug"] = "FileBug";
    IconNames["FileCode"] = "FileCode";
    IconNames["FileComment"] = "FileComment";
    IconNames["BusinessHoursSign"] = "BusinessHoursSign";
    IconNames["FileImage"] = "FileImage";
    IconNames["FileSymlink"] = "FileSymlink";
    IconNames["AutoFillTemplate"] = "AutoFillTemplate";
    IconNames["WorkItem"] = "WorkItem";
    IconNames["WorkItemBug"] = "WorkItemBug";
    IconNames["LogRemove"] = "LogRemove";
    IconNames["ColumnOptions"] = "ColumnOptions";
    IconNames["Packages"] = "Packages";
    IconNames["BuildIssue"] = "BuildIssue";
    IconNames["AssessmentGroup"] = "AssessmentGroup";
    IconNames["VariableGroup"] = "VariableGroup";
    IconNames["FullHistory"] = "FullHistory";
    IconNames["Wheelchair"] = "Wheelchair";
    IconNames["SingleColumnEdit"] = "SingleColumnEdit";
    IconNames["DoubleColumnEdit"] = "DoubleColumnEdit";
    IconNames["TripleColumnEdit"] = "TripleColumnEdit";
    IconNames["ColumnLeftTwoThirdsEdit"] = "ColumnLeftTwoThirdsEdit";
    IconNames["ColumnRightTwoThirdsEdit"] = "ColumnRightTwoThirdsEdit";
    IconNames["StreamLogo"] = "StreamLogo";
    IconNames["PassiveAuthentication"] = "PassiveAuthentication";
    IconNames["AlertSolid"] = "AlertSolid";
    IconNames["MegaphoneSolid"] = "MegaphoneSolid";
    IconNames["TaskSolid"] = "TaskSolid";
    IconNames["ConfigurationSolid"] = "ConfigurationSolid";
    IconNames["BugSolid"] = "BugSolid";
    IconNames["CrownSolid"] = "CrownSolid";
    IconNames["Trophy2Solid"] = "Trophy2Solid";
    IconNames["QuickNoteSolid"] = "QuickNoteSolid";
    IconNames["ConstructionConeSolid"] = "ConstructionConeSolid";
    IconNames["PageListSolid"] = "PageListSolid";
    IconNames["PageListMirroredSolid"] = "PageListMirroredSolid";
    IconNames["StarburstSolid"] = "StarburstSolid";
    IconNames["ReadingModeSolid"] = "ReadingModeSolid";
    IconNames["SadSolid"] = "SadSolid";
    IconNames["HealthSolid"] = "HealthSolid";
    IconNames["ShieldSolid"] = "ShieldSolid";
    IconNames["GiftBoxSolid"] = "GiftBoxSolid";
    IconNames["ShoppingCartSolid"] = "ShoppingCartSolid";
    IconNames["MailSolid"] = "MailSolid";
    IconNames["ChatSolid"] = "ChatSolid";
    IconNames["RibbonSolid"] = "RibbonSolid";
    IconNames["FinancialSolid"] = "FinancialSolid";
    IconNames["FinancialMirroredSolid"] = "FinancialMirroredSolid";
    IconNames["HeadsetSolid"] = "HeadsetSolid";
    IconNames["PermissionsSolid"] = "PermissionsSolid";
    IconNames["ParkingSolid"] = "ParkingSolid";
    IconNames["ParkingMirroredSolid"] = "ParkingMirroredSolid";
    IconNames["DiamondSolid"] = "DiamondSolid";
    IconNames["AsteriskSolid"] = "AsteriskSolid";
    IconNames["OfflineStorageSolid"] = "OfflineStorageSolid";
    IconNames["BankSolid"] = "BankSolid";
    IconNames["DecisionSolid"] = "DecisionSolid";
    IconNames["Parachute"] = "Parachute";
    IconNames["ParachuteSolid"] = "ParachuteSolid";
    IconNames["FiltersSolid"] = "FiltersSolid";
    IconNames["ColorSolid"] = "ColorSolid";
    IconNames["ReviewSolid"] = "ReviewSolid";
    IconNames["ReviewRequestSolid"] = "ReviewRequestSolid";
    IconNames["ReviewRequestMirroredSolid"] = "ReviewRequestMirroredSolid";
    IconNames["ReviewResponseSolid"] = "ReviewResponseSolid";
    IconNames["FeedbackRequestSolid"] = "FeedbackRequestSolid";
    IconNames["FeedbackRequestMirroredSolid"] = "FeedbackRequestMirroredSolid";
    IconNames["FeedbackResponseSolid"] = "FeedbackResponseSolid";
    IconNames["WorkItemBar"] = "WorkItemBar";
    IconNames["WorkItemBarSolid"] = "WorkItemBarSolid";
    IconNames["Separator"] = "Separator";
    IconNames["NavigateExternalInline"] = "NavigateExternalInline";
    IconNames["PlanView"] = "PlanView";
    IconNames["TimelineMatrixView"] = "TimelineMatrixView";
    IconNames["EngineeringGroup"] = "EngineeringGroup";
    IconNames["ProjectCollection"] = "ProjectCollection";
    IconNames["CaretBottomRightCenter8"] = "CaretBottomRightCenter8";
    IconNames["CaretBottomLeftCenter8"] = "CaretBottomLeftCenter8";
    IconNames["CaretTopRightCenter8"] = "CaretTopRightCenter8";
    IconNames["CaretTopLeftCenter8"] = "CaretTopLeftCenter8";
    IconNames["DonutChart"] = "DonutChart";
    IconNames["ChevronUnfold10"] = "ChevronUnfold10";
    IconNames["ChevronFold10"] = "ChevronFold10";
    IconNames["DoubleChevronDown8"] = "DoubleChevronDown8";
    IconNames["DoubleChevronUp8"] = "DoubleChevronUp8";
    IconNames["DoubleChevronLeft8"] = "DoubleChevronLeft8";
    IconNames["DoubleChevronRight8"] = "DoubleChevronRight8";
    IconNames["ChevronDownEnd6"] = "ChevronDownEnd6";
    IconNames["ChevronUpEnd6"] = "ChevronUpEnd6";
    IconNames["ChevronLeftEnd6"] = "ChevronLeftEnd6";
    IconNames["ChevronRightEnd6"] = "ChevronRightEnd6";
    IconNames["EgnyteLogo"] = "EgnyteLogo";
    IconNames["GoogleDriveLogoLeftGreen"] = "GoogleDriveLogoLeftGreen";
    IconNames["GoogleDriveLogoBottomBlue"] = "GoogleDriveLogoBottomBlue";
    IconNames["GoogleDriveLogoRightYellow"] = "GoogleDriveLogoRightYellow";
    IconNames["ContextMenu"] = "ContextMenu";
    IconNames["AzureAPIManagement"] = "AzureAPIManagement";
    IconNames["AzureServiceEndpoint"] = "AzureServiceEndpoint";
    IconNames["VSTSLogo"] = "VSTSLogo";
    IconNames["VSTSAltLogo1"] = "VSTSAltLogo1";
    IconNames["VSTSAltLogo2"] = "VSTSAltLogo2";
    IconNames["FileTypeSolution"] = "FileTypeSolution";
    IconNames["WordLogoInverse16"] = "WordLogoInverse16";
    IconNames["WordLogo16"] = "WordLogo16";
    IconNames["WordLogoFill16"] = "WordLogoFill16";
    IconNames["PowerPointLogoInverse16"] = "PowerPointLogoInverse16";
    IconNames["PowerPointLogo16"] = "PowerPointLogo16";
    IconNames["PowerPointLogoFill16"] = "PowerPointLogoFill16";
    IconNames["ExcelLogoInverse16"] = "ExcelLogoInverse16";
    IconNames["ExcelLogo16"] = "ExcelLogo16";
    IconNames["ExcelLogoFill16"] = "ExcelLogoFill16";
    IconNames["OneNoteLogoInverse16"] = "OneNoteLogoInverse16";
    IconNames["OneNoteLogo16"] = "OneNoteLogo16";
    IconNames["OneNoteLogoFill16"] = "OneNoteLogoFill16";
    IconNames["OutlookLogoInverse16"] = "OutlookLogoInverse16";
    IconNames["OutlookLogo16"] = "OutlookLogo16";
    IconNames["OutlookLogoFill16"] = "OutlookLogoFill16";
    IconNames["PublisherLogoInverse16"] = "PublisherLogoInverse16";
    IconNames["PublisherLogo16"] = "PublisherLogo16";
    IconNames["PublisherLogoFill16"] = "PublisherLogoFill16";
    IconNames["VisioLogoInverse16"] = "VisioLogoInverse16";
    IconNames["VisioLogo16"] = "VisioLogo16";
    IconNames["VisioLogoFill16"] = "VisioLogoFill16";
    IconNames["TestBeaker"] = "TestBeaker";
    IconNames["TestBeakerSolid"] = "TestBeakerSolid";
    IconNames["TestExploreSolid"] = "TestExploreSolid";
    IconNames["TestAutoSolid"] = "TestAutoSolid";
    IconNames["TestUserSolid"] = "TestUserSolid";
    IconNames["TestImpactSolid"] = "TestImpactSolid";
    IconNames["TestPlan"] = "TestPlan";
    IconNames["TestStep"] = "TestStep";
    IconNames["TestParameter"] = "TestParameter";
    IconNames["TestSuite"] = "TestSuite";
    IconNames["TestCase"] = "TestCase";
    IconNames["Sprint"] = "Sprint";
    IconNames["SignOut"] = "SignOut";
    IconNames["TriggerApproval"] = "TriggerApproval";
    IconNames["Rocket"] = "Rocket";
    IconNames["AzureKeyVault"] = "AzureKeyVault";
    IconNames["Onboarding"] = "Onboarding";
    IconNames["Transition"] = "Transition";
    IconNames["LikeSolid"] = "LikeSolid";
    IconNames["DislikeSolid"] = "DislikeSolid";
    IconNames["CRMCustomerInsightsApp"] = "CRMCustomerInsightsApp";
    IconNames["EditCreate"] = "EditCreate";
    IconNames["SearchData"] = "SearchData";
    IconNames["UnSetColor"] = "UnSetColor";
    IconNames["DeclineCall"] = "DeclineCall";
    IconNames["RectangularClipping"] = "RectangularClipping";
    IconNames["TeamsLogo16"] = "TeamsLogo16";
    IconNames["TeamsLogoFill16"] = "TeamsLogoFill16";
    IconNames["Spacer"] = "Spacer";
    IconNames["SkypeLogo16"] = "SkypeLogo16";
    IconNames["SkypeForBusinessLogo16"] = "SkypeForBusinessLogo16";
    IconNames["SkypeForBusinessLogoFill16"] = "SkypeForBusinessLogoFill16";
    IconNames["FilterSolid"] = "FilterSolid";
    IconNames["MailUndelivered"] = "MailUndelivered";
    IconNames["MailTentative"] = "MailTentative";
    IconNames["MailTentativeMirrored"] = "MailTentativeMirrored";
    IconNames["MailReminder"] = "MailReminder";
    IconNames["ReceiptUndelivered"] = "ReceiptUndelivered";
    IconNames["ReceiptTentative"] = "ReceiptTentative";
    IconNames["ReceiptTentativeMirrored"] = "ReceiptTentativeMirrored";
    IconNames["Inbox"] = "Inbox";
    IconNames["IRMReply"] = "IRMReply";
    IconNames["IRMReplyMirrored"] = "IRMReplyMirrored";
    IconNames["IRMForward"] = "IRMForward";
    IconNames["IRMForwardMirrored"] = "IRMForwardMirrored";
    IconNames["VoicemailIRM"] = "VoicemailIRM";
    IconNames["EventAccepted"] = "EventAccepted";
    IconNames["EventTentative"] = "EventTentative";
    IconNames["EventTentativeMirrored"] = "EventTentativeMirrored";
    IconNames["EventDeclined"] = "EventDeclined";
    IconNames["IDBadge"] = "IDBadge";
    IconNames["BackgroundColor"] = "BackgroundColor";
    IconNames["OfficeFormsLogoInverse16"] = "OfficeFormsLogoInverse16";
    IconNames["OfficeFormsLogo"] = "OfficeFormsLogo";
    IconNames["OfficeFormsLogoFill"] = "OfficeFormsLogoFill";
    IconNames["OfficeFormsLogo16"] = "OfficeFormsLogo16";
    IconNames["OfficeFormsLogoFill16"] = "OfficeFormsLogoFill16";
    IconNames["OfficeFormsLogoInverse24"] = "OfficeFormsLogoInverse24";
    IconNames["OfficeFormsLogo24"] = "OfficeFormsLogo24";
    IconNames["OfficeFormsLogoFill24"] = "OfficeFormsLogoFill24";
    IconNames["PageLock"] = "PageLock";
    IconNames["NotExecuted"] = "NotExecuted";
    IconNames["NotImpactedSolid"] = "NotImpactedSolid";
    IconNames["FieldReadOnly"] = "FieldReadOnly";
    IconNames["FieldRequired"] = "FieldRequired";
    IconNames["BacklogBoard"] = "BacklogBoard";
    IconNames["ExternalBuild"] = "ExternalBuild";
    IconNames["ExternalTFVC"] = "ExternalTFVC";
    IconNames["ExternalXAML"] = "ExternalXAML";
    IconNames["IssueSolid"] = "IssueSolid";
    IconNames["DefectSolid"] = "DefectSolid";
    IconNames["LadybugSolid"] = "LadybugSolid";
    IconNames["MTMLogo"] = "MTMLogo";
    IconNames["NugetLogo"] = "NugetLogo";
    IconNames["TFVCLogo"] = "TFVCLogo";
    IconNames["ProjectLogo32"] = "ProjectLogo32";
    IconNames["ProjectLogoFill32"] = "ProjectLogoFill32";
    IconNames["ProjectLogo16"] = "ProjectLogo16";
    IconNames["ProjectLogoFill16"] = "ProjectLogoFill16";
    IconNames["SwayLogo32"] = "SwayLogo32";
    IconNames["SwayLogoFill32"] = "SwayLogoFill32";
    IconNames["SwayLogo16"] = "SwayLogo16";
    IconNames["SwayLogoFill16"] = "SwayLogoFill16";
    IconNames["ClassNotebookLogo32"] = "ClassNotebookLogo32";
    IconNames["ClassNotebookLogoFill32"] = "ClassNotebookLogoFill32";
    IconNames["ClassNotebookLogo16"] = "ClassNotebookLogo16";
    IconNames["ClassNotebookLogoFill16"] = "ClassNotebookLogoFill16";
    IconNames["ClassNotebookLogoInverse32"] = "ClassNotebookLogoInverse32";
    IconNames["ClassNotebookLogoInverse16"] = "ClassNotebookLogoInverse16";
    IconNames["StaffNotebookLogo32"] = "StaffNotebookLogo32";
    IconNames["StaffNotebookLogoFill32"] = "StaffNotebookLogoFill32";
    IconNames["StaffNotebookLogo16"] = "StaffNotebookLogo16";
    IconNames["StaffNotebookLogoFill16"] = "StaffNotebookLogoFill16";
    IconNames["StaffNotebookLogoInverted32"] = "StaffNotebookLogoInverted32";
    IconNames["StaffNotebookLogoInverted16"] = "StaffNotebookLogoInverted16";
    IconNames["KaizalaLogo"] = "KaizalaLogo";
    IconNames["TaskLogo"] = "TaskLogo";
    IconNames["ProtectionCenterLogo32"] = "ProtectionCenterLogo32";
    IconNames["NonprofitLogo32"] = "NonprofitLogo32";
    IconNames["GallatinLogo"] = "GallatinLogo";
    IconNames["Globe2"] = "Globe2";
    IconNames["Guitar"] = "Guitar";
    IconNames["Breakfast"] = "Breakfast";
    IconNames["Brunch"] = "Brunch";
    IconNames["BeerMug"] = "BeerMug";
    IconNames["Vacation"] = "Vacation";
    IconNames["Teeth"] = "Teeth";
    IconNames["Taxi"] = "Taxi";
    IconNames["Chopsticks"] = "Chopsticks";
    IconNames["SyncOccurence"] = "SyncOccurence";
    IconNames["UnsyncOccurence"] = "UnsyncOccurence";
    IconNames["GIF"] = "GIF";
    IconNames["PrimaryCalendar"] = "PrimaryCalendar";
    IconNames["SearchCalendar"] = "SearchCalendar";
    IconNames["VideoOff"] = "VideoOff";
    IconNames["MicrosoftFlowLogo"] = "MicrosoftFlowLogo";
    IconNames["BusinessCenterLogo"] = "BusinessCenterLogo";
    IconNames["ToDoLogoBottom"] = "ToDoLogoBottom";
    IconNames["ToDoLogoTop"] = "ToDoLogoTop";
    IconNames["EditSolid12"] = "EditSolid12";
    IconNames["EditSolidMirrored12"] = "EditSolidMirrored12";
    IconNames["UneditableSolid12"] = "UneditableSolid12";
    IconNames["UneditableSolidMirrored12"] = "UneditableSolidMirrored12";
    IconNames["UneditableMirrored"] = "UneditableMirrored";
    IconNames["AdminALogo32"] = "AdminALogo32";
    IconNames["AdminALogoFill32"] = "AdminALogoFill32";
    IconNames["ToDoLogoInverse"] = "ToDoLogoInverse";
    IconNames["Snooze"] = "Snooze";
    IconNames["WaffleOffice365"] = "WaffleOffice365";
    IconNames["ImageSearch"] = "ImageSearch";
    IconNames["NewsSearch"] = "NewsSearch";
    IconNames["VideoSearch"] = "VideoSearch";
    IconNames["R"] = "R";
    IconNames["FontColorA"] = "FontColorA";
    IconNames["FontColorSwatch"] = "FontColorSwatch";
    IconNames["LightWeight"] = "LightWeight";
    IconNames["NormalWeight"] = "NormalWeight";
    IconNames["SemiboldWeight"] = "SemiboldWeight";
    IconNames["GroupObject"] = "GroupObject";
    IconNames["UngroupObject"] = "UngroupObject";
    IconNames["AlignHorizontalLeft"] = "AlignHorizontalLeft";
    IconNames["AlignHorizontalCenter"] = "AlignHorizontalCenter";
    IconNames["AlignHorizontalRight"] = "AlignHorizontalRight";
    IconNames["AlignVerticalTop"] = "AlignVerticalTop";
    IconNames["AlignVerticalCenter"] = "AlignVerticalCenter";
    IconNames["AlignVerticalBottom"] = "AlignVerticalBottom";
    IconNames["HorizontalDistributeCenter"] = "HorizontalDistributeCenter";
    IconNames["VerticalDistributeCenter"] = "VerticalDistributeCenter";
    IconNames["Ellipse"] = "Ellipse";
    IconNames["Line"] = "Line";
    IconNames["Octagon"] = "Octagon";
    IconNames["Hexagon"] = "Hexagon";
    IconNames["Pentagon"] = "Pentagon";
    IconNames["RightTriangle"] = "RightTriangle";
    IconNames["HalfCircle"] = "HalfCircle";
    IconNames["QuarterCircle"] = "QuarterCircle";
    IconNames["ThreeQuarterCircle"] = "ThreeQuarterCircle";
    IconNames["SixPointStar"] = "SixPointStar";
    IconNames["TwelvePointStar"] = "TwelvePointStar";
    IconNames["ArrangeBringToFront"] = "ArrangeBringToFront";
    IconNames["ArrangeSendToBack"] = "ArrangeSendToBack";
    IconNames["ArrangeSendBackward"] = "ArrangeSendBackward";
    IconNames["ArrangeBringForward"] = "ArrangeBringForward";
    IconNames["BorderDash"] = "BorderDash";
    IconNames["BorderDot"] = "BorderDot";
    IconNames["LineStyle"] = "LineStyle";
    IconNames["LineThickness"] = "LineThickness";
    IconNames["WindowEdit"] = "WindowEdit";
    IconNames["HintText"] = "HintText";
    IconNames["MediaAdd"] = "MediaAdd";
    IconNames["AnchorLock"] = "AnchorLock";
    IconNames["AutoHeight"] = "AutoHeight";
    IconNames["ChartSeries"] = "ChartSeries";
    IconNames["ChartXAngle"] = "ChartXAngle";
    IconNames["ChartYAngle"] = "ChartYAngle";
    IconNames["Combobox"] = "Combobox";
    IconNames["LineSpacing"] = "LineSpacing";
    IconNames["Padding"] = "Padding";
    IconNames["PaddingTop"] = "PaddingTop";
    IconNames["PaddingBottom"] = "PaddingBottom";
    IconNames["PaddingLeft"] = "PaddingLeft";
    IconNames["PaddingRight"] = "PaddingRight";
    IconNames["NavigationFlipper"] = "NavigationFlipper";
    IconNames["AlignJustify"] = "AlignJustify";
    IconNames["TextOverflow"] = "TextOverflow";
    IconNames["VisualsFolder"] = "VisualsFolder";
    IconNames["VisualsStore"] = "VisualsStore";
    IconNames["PictureCenter"] = "PictureCenter";
    IconNames["PictureFill"] = "PictureFill";
    IconNames["PicturePosition"] = "PicturePosition";
    IconNames["PictureStretch"] = "PictureStretch";
    IconNames["PictureTile"] = "PictureTile";
    IconNames["Slider"] = "Slider";
    IconNames["SliderHandleSize"] = "SliderHandleSize";
    IconNames["DefaultRatio"] = "DefaultRatio";
    IconNames["NumberSequence"] = "NumberSequence";
    IconNames["GUID"] = "GUID";
    IconNames["ReportAdd"] = "ReportAdd";
    IconNames["DashboardAdd"] = "DashboardAdd";
    IconNames["MapPinSolid"] = "MapPinSolid";
    IconNames["WebPublish"] = "WebPublish";
    IconNames["PieSingleSolid"] = "PieSingleSolid";
    IconNames["BlockedSolid"] = "BlockedSolid";
    IconNames["DrillDown"] = "DrillDown";
    IconNames["DrillDownSolid"] = "DrillDownSolid";
    IconNames["DrillExpand"] = "DrillExpand";
    IconNames["DrillShow"] = "DrillShow";
    IconNames["SpecialEvent"] = "SpecialEvent";
    IconNames["OneDriveFolder16"] = "OneDriveFolder16";
    IconNames["ChronosLogo"] = "ChronosLogo";
    IconNames["FunctionalManagerDashboard"] = "FunctionalManagerDashboard";
    IconNames["BIDashboard"] = "BIDashboard";
    IconNames["CodeEdit"] = "CodeEdit";
    IconNames["RenewalCurrent"] = "RenewalCurrent";
    IconNames["RenewalFuture"] = "RenewalFuture";
    IconNames["SplitObject"] = "SplitObject";
    IconNames["BulkUpload"] = "BulkUpload";
    IconNames["DownloadDocument"] = "DownloadDocument";
    IconNames["Flower"] = "Flower";
    IconNames["WaitlistConfirm"] = "WaitlistConfirm";
    IconNames["WaitlistConfirmMirrored"] = "WaitlistConfirmMirrored";
    IconNames["LaptopSecure"] = "LaptopSecure";
    IconNames["DragObject"] = "DragObject";
    IconNames["EntryView"] = "EntryView";
    IconNames["EntryDecline"] = "EntryDecline";
    IconNames["ContactCardSettings"] = "ContactCardSettings";
    IconNames["ContactCardSettingsMirrored"] = "ContactCardSettingsMirrored";
    IconNames["CalendarSettings"] = "CalendarSettings";
    IconNames["CalendarSettingsMirrored"] = "CalendarSettingsMirrored";
    IconNames["HardDriveLock"] = "HardDriveLock";
    IconNames["HardDriveUnlock"] = "HardDriveUnlock";
    IconNames["AccountManagement"] = "AccountManagement";
    IconNames["TransitionPop"] = "TransitionPop";
    IconNames["TransitionPush"] = "TransitionPush";
    IconNames["TransitionEffect"] = "TransitionEffect";
    IconNames["LookupEntities"] = "LookupEntities";
    IconNames["ExploreData"] = "ExploreData";
    IconNames["AddBookmark"] = "AddBookmark";
    IconNames["SearchBookmark"] = "SearchBookmark";
    IconNames["DrillThrough"] = "DrillThrough";
    IconNames["MasterDatabase"] = "MasterDatabase";
    IconNames["CertifiedDatabase"] = "CertifiedDatabase";
    IconNames["MaximumValue"] = "MaximumValue";
    IconNames["MinimumValue"] = "MinimumValue";
    IconNames["VisualStudioIDELogo32"] = "VisualStudioIDELogo32";
    IconNames["ApacheIvyLogo32"] = "ApacheIvyLogo32";
    IconNames["BitbucketLogo32"] = "BitbucketLogo32";
    IconNames["LinuxLogo32"] = "LinuxLogo32";
    IconNames["GradleLogo32"] = "GradleLogo32";
    IconNames["PasteAsText"] = "PasteAsText";
    IconNames["PasteAsCode"] = "PasteAsCode";
    IconNames["BrowserTab"] = "BrowserTab";
    IconNames["BrowserTabScreenshot"] = "BrowserTabScreenshot";
    IconNames["DesktopScreenshot"] = "DesktopScreenshot";
    IconNames["FileYML"] = "FileYML";
    IconNames["ClipboardSolid"] = "ClipboardSolid";
    IconNames["FabricUserFolder"] = "FabricUserFolder";
    IconNames["FabricNetworkFolder"] = "FabricNetworkFolder";
    IconNames["AnalyticsView"] = "AnalyticsView";
    IconNames["Video360Generic"] = "Video360Generic";
    IconNames["Leave"] = "Leave";
    IconNames["Trending12"] = "Trending12";
    IconNames["Blocked12"] = "Blocked12";
    IconNames["Warning12"] = "Warning12";
    IconNames["CheckedOutByOther12"] = "CheckedOutByOther12";
    IconNames["CheckedOutByYou12"] = "CheckedOutByYou12";
    IconNames["CircleShapeSolid"] = "CircleShapeSolid";
    IconNames["SquareShapeSolid"] = "SquareShapeSolid";
    IconNames["TriangleShapeSolid"] = "TriangleShapeSolid";
    IconNames["DropShapeSolid"] = "DropShapeSolid";
    IconNames["RectangleShapeSolid"] = "RectangleShapeSolid";
    IconNames["ZoomToFit"] = "ZoomToFit";
    IconNames["InsertColumnsLeft"] = "InsertColumnsLeft";
    IconNames["InsertColumnsRight"] = "InsertColumnsRight";
    IconNames["InsertRowsAbove"] = "InsertRowsAbove";
    IconNames["InsertRowsBelow"] = "InsertRowsBelow";
    IconNames["DeleteColumns"] = "DeleteColumns";
    IconNames["DeleteRows"] = "DeleteRows";
    IconNames["DeleteRowsMirrored"] = "DeleteRowsMirrored";
    IconNames["DeleteTable"] = "DeleteTable";
    IconNames["AccountBrowser"] = "AccountBrowser";
    IconNames["GitLogo"] = "GitLogo";
    IconNames["GitHubLogo"] = "GitHubLogo";
    IconNames["ApacheMavenLogo"] = "ApacheMavenLogo";
    IconNames["NPMLogo"] = "NPMLogo";
    IconNames["GitFork"] = "GitFork";
    IconNames["SVNLogo"] = "SVNLogo";
    IconNames["JenkinsLogo"] = "JenkinsLogo";
    IconNames["VersionControlPush"] = "VersionControlPush";
    IconNames["ExternalGit"] = "ExternalGit";
    IconNames["StackedColumnChart2"] = "StackedColumnChart2";
    IconNames["TripleColumnWide"] = "TripleColumnWide";
    IconNames["QuadColumn"] = "QuadColumn";
    IconNames["WhiteBoardApp16"] = "WhiteBoardApp16";
    IconNames["WhiteBoardApp32"] = "WhiteBoardApp32";
    IconNames["PinnedSolid"] = "PinnedSolid";
    IconNames["InsertSignatureLine"] = "InsertSignatureLine";
    IconNames["ArrangeByFrom"] = "ArrangeByFrom";
    IconNames["Phishing"] = "Phishing";
    IconNames["CreateMailRule"] = "CreateMailRule";
    IconNames["PublishCourse"] = "PublishCourse";
    IconNames["DictionaryRemove"] = "DictionaryRemove";
    IconNames["UserRemove"] = "UserRemove";
    IconNames["UserEvent"] = "UserEvent";
    IconNames["Encryption"] = "Encryption";
    IconNames["PasswordField"] = "PasswordField";
    IconNames["OpenInNewTab"] = "OpenInNewTab";
    IconNames["Hide3"] = "Hide3";
    IconNames["VerifiedBrandSolid"] = "VerifiedBrandSolid";
    IconNames["MarkAsProtected"] = "MarkAsProtected";
    IconNames["AuthenticatorApp"] = "AuthenticatorApp";
    IconNames["WebTemplate"] = "WebTemplate";
    IconNames["DefenderTVM"] = "DefenderTVM";
    IconNames["MedalSolid"] = "MedalSolid";
    IconNames["D365TalentLearn"] = "D365TalentLearn";
    IconNames["D365TalentInsight"] = "D365TalentInsight";
    IconNames["D365TalentHRCore"] = "D365TalentHRCore";
    IconNames["BacklogList"] = "BacklogList";
    IconNames["ButtonControl"] = "ButtonControl";
    IconNames["TableGroup"] = "TableGroup";
    IconNames["MountainClimbing"] = "MountainClimbing";
    IconNames["TagUnknown"] = "TagUnknown";
    IconNames["TagUnknownMirror"] = "TagUnknownMirror";
    IconNames["TagUnknown12"] = "TagUnknown12";
    IconNames["TagUnknown12Mirror"] = "TagUnknown12Mirror";
    IconNames["Link12"] = "Link12";
    IconNames["Presentation"] = "Presentation";
    IconNames["Presentation12"] = "Presentation12";
    IconNames["Lock12"] = "Lock12";
    IconNames["BuildDefinition"] = "BuildDefinition";
    IconNames["ReleaseDefinition"] = "ReleaseDefinition";
    IconNames["SaveTemplate"] = "SaveTemplate";
    IconNames["UserGauge"] = "UserGauge";
    IconNames["BlockedSiteSolid12"] = "BlockedSiteSolid12";
    IconNames["TagSolid"] = "TagSolid";
    IconNames["OfficeChat"] = "OfficeChat";
    IconNames["OfficeChatSolid"] = "OfficeChatSolid";
    IconNames["MailSchedule"] = "MailSchedule";
    IconNames["WarningSolid"] = "WarningSolid";
    IconNames["Blocked2Solid"] = "Blocked2Solid";
    IconNames["SkypeCircleArrow"] = "SkypeCircleArrow";
    IconNames["SkypeArrow"] = "SkypeArrow";
    IconNames["SyncStatus"] = "SyncStatus";
    IconNames["SyncStatusSolid"] = "SyncStatusSolid";
    IconNames["ProjectDocument"] = "ProjectDocument";
    IconNames["ToDoLogoOutline"] = "ToDoLogoOutline";
    IconNames["VisioOnlineLogoFill32"] = "VisioOnlineLogoFill32";
    IconNames["VisioOnlineLogo32"] = "VisioOnlineLogo32";
    IconNames["VisioOnlineLogoCloud32"] = "VisioOnlineLogoCloud32";
    IconNames["VisioDiagramSync"] = "VisioDiagramSync";
    IconNames["Event12"] = "Event12";
    IconNames["EventDateMissed12"] = "EventDateMissed12";
    IconNames["UserOptional"] = "UserOptional";
    IconNames["ResponsesMenu"] = "ResponsesMenu";
    IconNames["DoubleDownArrow"] = "DoubleDownArrow";
    IconNames["DistributeDown"] = "DistributeDown";
    IconNames["BookmarkReport"] = "BookmarkReport";
    IconNames["FilterSettings"] = "FilterSettings";
    IconNames["GripperDotsVertical"] = "GripperDotsVertical";
    IconNames["MailAttached"] = "MailAttached";
    IconNames["AddIn"] = "AddIn";
    IconNames["LinkedDatabase"] = "LinkedDatabase";
    IconNames["PromotedDatabase"] = "PromotedDatabase";
    IconNames["BarChartVerticalFilter"] = "BarChartVerticalFilter";
    IconNames["BarChartVerticalFilterSolid"] = "BarChartVerticalFilterSolid";
    IconNames["MicrosoftTranslatorLogo"] = "MicrosoftTranslatorLogo";
    IconNames["ShowTimeAs"] = "ShowTimeAs";
    IconNames["FileRequest"] = "FileRequest";
    IconNames["WorkItemAlert"] = "WorkItemAlert";
    IconNames["PowerBILogo16"] = "PowerBILogo16";
    IconNames["PowerBILogoBackplate16"] = "PowerBILogoBackplate16";
    IconNames["BulletedListText"] = "BulletedListText";
    IconNames["BulletedListBullet"] = "BulletedListBullet";
    IconNames["BulletedListTextMirrored"] = "BulletedListTextMirrored";
    IconNames["BulletedListBulletMirrored"] = "BulletedListBulletMirrored";
    IconNames["NumberedListText"] = "NumberedListText";
    IconNames["NumberedListNumber"] = "NumberedListNumber";
    IconNames["NumberedListTextMirrored"] = "NumberedListTextMirrored";
    IconNames["NumberedListNumberMirrored"] = "NumberedListNumberMirrored";
    IconNames["RemoveLinkChain"] = "RemoveLinkChain";
    IconNames["RemoveLinkX"] = "RemoveLinkX";
    IconNames["FabricTextHighlight"] = "FabricTextHighlight";
    IconNames["ClearFormattingA"] = "ClearFormattingA";
    IconNames["ClearFormattingEraser"] = "ClearFormattingEraser";
    IconNames["Photo2Fill"] = "Photo2Fill";
    IconNames["IncreaseIndentText"] = "IncreaseIndentText";
    IconNames["IncreaseIndentArrow"] = "IncreaseIndentArrow";
    IconNames["DecreaseIndentText"] = "DecreaseIndentText";
    IconNames["DecreaseIndentArrow"] = "DecreaseIndentArrow";
    IconNames["IncreaseIndentTextMirrored"] = "IncreaseIndentTextMirrored";
    IconNames["IncreaseIndentArrowMirrored"] = "IncreaseIndentArrowMirrored";
    IconNames["DecreaseIndentTextMirrored"] = "DecreaseIndentTextMirrored";
    IconNames["DecreaseIndentArrowMirrored"] = "DecreaseIndentArrowMirrored";
    IconNames["CheckListText"] = "CheckListText";
    IconNames["CheckListCheck"] = "CheckListCheck";
    IconNames["CheckListTextMirrored"] = "CheckListTextMirrored";
    IconNames["CheckListCheckMirrored"] = "CheckListCheckMirrored";
    IconNames["NumberSymbol"] = "NumberSymbol";
    IconNames["Coupon"] = "Coupon";
    IconNames["VerifiedBrand"] = "VerifiedBrand";
    IconNames["ReleaseGate"] = "ReleaseGate";
    IconNames["ReleaseGateCheck"] = "ReleaseGateCheck";
    IconNames["ReleaseGateError"] = "ReleaseGateError";
    IconNames["M365InvoicingLogo"] = "M365InvoicingLogo";
    IconNames["ShieldAlert"] = "ShieldAlert";
    IconNames["FabricTextHighlightComposite"] = "FabricTextHighlightComposite";
    IconNames["Dataflows"] = "Dataflows";
    IconNames["GenericScanFilled"] = "GenericScanFilled";
    IconNames["DiagnosticDataBarTooltip"] = "DiagnosticDataBarTooltip";
    IconNames["SaveToMobile"] = "SaveToMobile";
    IconNames["Orientation2"] = "Orientation2";
    IconNames["ScreenCast"] = "ScreenCast";
    IconNames["ShowGrid"] = "ShowGrid";
    IconNames["SnapToGrid"] = "SnapToGrid";
    IconNames["ContactList"] = "ContactList";
    IconNames["NewMail"] = "NewMail";
    IconNames["EyeShadow"] = "EyeShadow";
    IconNames["DockerLogo"] = "DockerLogo";
    IconNames["JavaLogo"] = "JavaLogo";
    IconNames["KeubernetesLogo"] = "KeubernetesLogo";
    IconNames["ReactLogo"] = "ReactLogo";
    IconNames["SwiftLogo"] = "SwiftLogo";
    IconNames["XamarinLogo"] = "XamarinLogo";
    IconNames["AmazonWebServicesLogo"] = "AmazonWebServicesLogo";
    IconNames["FabricFolderConfirm"] = "FabricFolderConfirm";
    IconNames["InformationBarriers"] = "InformationBarriers";
    IconNames["CommentActive"] = "CommentActive";
    IconNames["ColumnVerticalSectionEdit"] = "ColumnVerticalSectionEdit";
    IconNames["WavingHand"] = "WavingHand";
    IconNames["ShakeDevice"] = "ShakeDevice";
    IconNames["SmartGlassRemote"] = "SmartGlassRemote";
    IconNames["Rotate90Clockwise"] = "Rotate90Clockwise";
    IconNames["Rotate90CounterClockwise"] = "Rotate90CounterClockwise";
    IconNames["CampaignTemplate"] = "CampaignTemplate";
    IconNames["ChartTemplate"] = "ChartTemplate";
    IconNames["PageListFilter"] = "PageListFilter";
    IconNames["SecondaryNav"] = "SecondaryNav";
    IconNames["ColumnVerticalSection"] = "ColumnVerticalSection";
    IconNames["SkypeCircleSlash"] = "SkypeCircleSlash";
    IconNames["SkypeSlash"] = "SkypeSlash";
    IconNames["CustomizeToolbar"] = "CustomizeToolbar";
    IconNames["DuplicateRow"] = "DuplicateRow";
    IconNames["RemoveFromTrash"] = "RemoveFromTrash";
    IconNames["MailOptions"] = "MailOptions";
    IconNames["Childof"] = "Childof";
    IconNames["Footer"] = "Footer";
    IconNames["Header"] = "Header";
    IconNames["BarChartVerticalFill"] = "BarChartVerticalFill";
    IconNames["StackedColumnChart2Fill"] = "StackedColumnChart2Fill";
    IconNames["PlainText"] = "PlainText";
    IconNames["AccessibiltyChecker"] = "AccessibiltyChecker";
    IconNames["OneDriveFileVault"] = "OneDriveFileVault";
    IconNames["DatabaseSync"] = "DatabaseSync";
    IconNames["ReservationOrders"] = "ReservationOrders";
    IconNames["TabOneColumn"] = "TabOneColumn";
    IconNames["TabTwoColumn"] = "TabTwoColumn";
    IconNames["TabThreeColumn"] = "TabThreeColumn";
    IconNames["PythonLogoBlue"] = "PythonLogoBlue";
    IconNames["PythonLogoYellow"] = "PythonLogoYellow";
    IconNames["RustLanguageLogo"] = "RustLanguageLogo";
    IconNames["RubyGemsLogo"] = "RubyGemsLogo";
    IconNames["MicrosoftTranslatorLogoGreen"] = "MicrosoftTranslatorLogoGreen";
    IconNames["MicrosoftTranslatorLogoBlue"] = "MicrosoftTranslatorLogoBlue";
    IconNames["InternalInvestigation"] = "InternalInvestigation";
    IconNames["AddReaction"] = "AddReaction";
    IconNames["ContactHeart"] = "ContactHeart";
    IconNames["VisuallyImpaired"] = "VisuallyImpaired";
    IconNames["EventToDoLogo"] = "EventToDoLogo";
    IconNames["Variable2"] = "Variable2";
    IconNames["ModelingView"] = "ModelingView";
    IconNames["DisconnectVirtualMachine"] = "DisconnectVirtualMachine";
    IconNames["ReportLock"] = "ReportLock";
    IconNames["Uneditable2"] = "Uneditable2";
    IconNames["Uneditable2Mirrored"] = "Uneditable2Mirrored";
    IconNames["BarChartVerticalEdit"] = "BarChartVerticalEdit";
    IconNames["GlobalNavButtonActive"] = "GlobalNavButtonActive";
    IconNames["PollResults"] = "PollResults";
    IconNames["Rerun"] = "Rerun";
    IconNames["QandA"] = "QandA";
    IconNames["QandAMirror"] = "QandAMirror";
    IconNames["BookAnswers"] = "BookAnswers";
    IconNames["ExcelLogo16Mono"] = "ExcelLogo16Mono";
    IconNames["ExcelLogo16L01"] = "ExcelLogo16L01";
    IconNames["ExcelLogo16L02"] = "ExcelLogo16L02";
    IconNames["ExcelLogo16L03"] = "ExcelLogo16L03";
    IconNames["ExcelLogo16L04"] = "ExcelLogo16L04";
    IconNames["ExcelLogo16L05"] = "ExcelLogo16L05";
    IconNames["ExcelLogo16L06"] = "ExcelLogo16L06";
    IconNames["ExcelLogo16L07"] = "ExcelLogo16L07";
    IconNames["ExcelLogo16L08"] = "ExcelLogo16L08";
    IconNames["ExcelLogo32Mono"] = "ExcelLogo32Mono";
    IconNames["ExcelLogo32L01"] = "ExcelLogo32L01";
    IconNames["ExcelLogo32L02"] = "ExcelLogo32L02";
    IconNames["ExcelLogo32L03"] = "ExcelLogo32L03";
    IconNames["ExcelLogo32L04"] = "ExcelLogo32L04";
    IconNames["ExcelLogo32L05"] = "ExcelLogo32L05";
    IconNames["ExcelLogo32L06"] = "ExcelLogo32L06";
    IconNames["ExcelLogo32L07"] = "ExcelLogo32L07";
    IconNames["ExcelLogo32L08"] = "ExcelLogo32L08";
    IconNames["ExcelLogo32L09"] = "ExcelLogo32L09";
    IconNames["ExcelLogo32L10"] = "ExcelLogo32L10";
    IconNames["ExcelLogo32L11"] = "ExcelLogo32L11";
    IconNames["ExcelLogo32L12"] = "ExcelLogo32L12";
    IconNames["WordLogo16Mono"] = "WordLogo16Mono";
    IconNames["WordLogo16L01"] = "WordLogo16L01";
    IconNames["WordLogo16L02"] = "WordLogo16L02";
    IconNames["WordLogo16L03"] = "WordLogo16L03";
    IconNames["WordLogo16L04"] = "WordLogo16L04";
    IconNames["WordLogo16L05"] = "WordLogo16L05";
    IconNames["WordLogo16L06"] = "WordLogo16L06";
    IconNames["WordLogo16L07"] = "WordLogo16L07";
    IconNames["WordLogo32Mono"] = "WordLogo32Mono";
    IconNames["WordLogo32L01"] = "WordLogo32L01";
    IconNames["WordLogo32L02"] = "WordLogo32L02";
    IconNames["WordLogo32L03"] = "WordLogo32L03";
    IconNames["WordLogo32L04"] = "WordLogo32L04";
    IconNames["WordLogo32L05"] = "WordLogo32L05";
    IconNames["WordLogo32L06"] = "WordLogo32L06";
    IconNames["WordLogo32L07"] = "WordLogo32L07";
    IconNames["WordLogo32L08"] = "WordLogo32L08";
    IconNames["WordLogo32L09"] = "WordLogo32L09";
    IconNames["WordLogo32L10"] = "WordLogo32L10";
    IconNames["PowerPointLogo16Mono"] = "PowerPointLogo16Mono";
    IconNames["PowerPointLogo16L01"] = "PowerPointLogo16L01";
    IconNames["PowerPointLogo16L02"] = "PowerPointLogo16L02";
    IconNames["PowerPointLogo16L03"] = "PowerPointLogo16L03";
    IconNames["PowerPointLogo16L04"] = "PowerPointLogo16L04";
    IconNames["PowerPointLogo16L05"] = "PowerPointLogo16L05";
    IconNames["PowerPointLogo16L06"] = "PowerPointLogo16L06";
    IconNames["PowerPointLogo32Mono"] = "PowerPointLogo32Mono";
    IconNames["PowerPointLogo32L01"] = "PowerPointLogo32L01";
    IconNames["PowerPointLogo32L02"] = "PowerPointLogo32L02";
    IconNames["PowerPointLogo32L03"] = "PowerPointLogo32L03";
    IconNames["PowerPointLogo32L04"] = "PowerPointLogo32L04";
    IconNames["PowerPointLogo32L05"] = "PowerPointLogo32L05";
    IconNames["PowerPointLogo32L06"] = "PowerPointLogo32L06";
    IconNames["PowerPointLogo32L07"] = "PowerPointLogo32L07";
    IconNames["PowerPointLogo32L08"] = "PowerPointLogo32L08";
    IconNames["PowerPointLogo32L09"] = "PowerPointLogo32L09";
    IconNames["OneNoteLogo16Mono"] = "OneNoteLogo16Mono";
    IconNames["OneNoteLogo16L01"] = "OneNoteLogo16L01";
    IconNames["OneNoteLogo16L02"] = "OneNoteLogo16L02";
    IconNames["OneNoteLogo16L03"] = "OneNoteLogo16L03";
    IconNames["OneNoteLogo16L04"] = "OneNoteLogo16L04";
    IconNames["OneNoteLogo16L05"] = "OneNoteLogo16L05";
    IconNames["OneNoteLogo16L06"] = "OneNoteLogo16L06";
    IconNames["OneNoteLogo16L07"] = "OneNoteLogo16L07";
    IconNames["OneNoteLogo32Mono"] = "OneNoteLogo32Mono";
    IconNames["OneNoteLogo32L01"] = "OneNoteLogo32L01";
    IconNames["OneNoteLogo32L02"] = "OneNoteLogo32L02";
    IconNames["OneNoteLogo32L03"] = "OneNoteLogo32L03";
    IconNames["OneNoteLogo32L04"] = "OneNoteLogo32L04";
    IconNames["OneNoteLogo32L05"] = "OneNoteLogo32L05";
    IconNames["OneNoteLogo32L06"] = "OneNoteLogo32L06";
    IconNames["OneNoteLogo32L07"] = "OneNoteLogo32L07";
    IconNames["OneNoteLogo32L08"] = "OneNoteLogo32L08";
    IconNames["OneNoteLogo32L09"] = "OneNoteLogo32L09";
    IconNames["OneNoteLogo32L10"] = "OneNoteLogo32L10";
    IconNames["OutlookLogo16Mono"] = "OutlookLogo16Mono";
    IconNames["OutlookLogo16L01"] = "OutlookLogo16L01";
    IconNames["OutlookLogo16L02"] = "OutlookLogo16L02";
    IconNames["OutlookLogo16L03"] = "OutlookLogo16L03";
    IconNames["OutlookLogo16L04"] = "OutlookLogo16L04";
    IconNames["OutlookLogo16L05"] = "OutlookLogo16L05";
    IconNames["OutlookLogo16L06"] = "OutlookLogo16L06";
    IconNames["OutlookLogo16L07"] = "OutlookLogo16L07";
    IconNames["OutlookLogo16L08"] = "OutlookLogo16L08";
    IconNames["OutlookLogo16L09"] = "OutlookLogo16L09";
    IconNames["OutlookLogo16L10"] = "OutlookLogo16L10";
    IconNames["OutlookLogo16L11"] = "OutlookLogo16L11";
    IconNames["OutlookLogo16L12"] = "OutlookLogo16L12";
    IconNames["OutlookLogo32Mono"] = "OutlookLogo32Mono";
    IconNames["OutlookLogo32L01"] = "OutlookLogo32L01";
    IconNames["OutlookLogo32L02"] = "OutlookLogo32L02";
    IconNames["OutlookLogo32L03"] = "OutlookLogo32L03";
    IconNames["OutlookLogo32L04"] = "OutlookLogo32L04";
    IconNames["OutlookLogo32L05"] = "OutlookLogo32L05";
    IconNames["OutlookLogo32L06"] = "OutlookLogo32L06";
    IconNames["OutlookLogo32L07"] = "OutlookLogo32L07";
    IconNames["OutlookLogo32L08"] = "OutlookLogo32L08";
    IconNames["OutlookLogo32L09"] = "OutlookLogo32L09";
    IconNames["OutlookLogo32L10"] = "OutlookLogo32L10";
    IconNames["OutlookLogo32L11"] = "OutlookLogo32L11";
    IconNames["OutlookLogo32L12"] = "OutlookLogo32L12";
    IconNames["OutlookLogo32L13"] = "OutlookLogo32L13";
    IconNames["OutlookLogo32L14"] = "OutlookLogo32L14";
    IconNames["OutlookLogo32L15"] = "OutlookLogo32L15";
    IconNames["OutlookLogo32L16"] = "OutlookLogo32L16";
    IconNames["OutlookLogo32L17"] = "OutlookLogo32L17";
    IconNames["OutlookLogo32L18"] = "OutlookLogo32L18";
    IconNames["OutlookLogo32L19"] = "OutlookLogo32L19";
    IconNames["OutlookLogo32L20"] = "OutlookLogo32L20";
    IconNames["OutlookLogo32L21"] = "OutlookLogo32L21";
    IconNames["SharePointLogo16Mono"] = "SharePointLogo16Mono";
    IconNames["SharePointLogo16L01"] = "SharePointLogo16L01";
    IconNames["SharePointLogo16L02"] = "SharePointLogo16L02";
    IconNames["SharePointLogo16L03"] = "SharePointLogo16L03";
    IconNames["SharePointLogo16L04"] = "SharePointLogo16L04";
    IconNames["SharePointLogo16L05"] = "SharePointLogo16L05";
    IconNames["SharePointLogo16L06"] = "SharePointLogo16L06";
    IconNames["SharePointLogo32Mono"] = "SharePointLogo32Mono";
    IconNames["SharePointLogo32L01"] = "SharePointLogo32L01";
    IconNames["SharePointLogo32L02"] = "SharePointLogo32L02";
    IconNames["SharePointLogo32L03"] = "SharePointLogo32L03";
    IconNames["SharePointLogo32L04"] = "SharePointLogo32L04";
    IconNames["SharePointLogo32L05"] = "SharePointLogo32L05";
    IconNames["SharePointLogo32L06"] = "SharePointLogo32L06";
    IconNames["SharePointLogo32L07"] = "SharePointLogo32L07";
    IconNames["SharePointLogo32L08"] = "SharePointLogo32L08";
    IconNames["SharePointLogo32L09"] = "SharePointLogo32L09";
    IconNames["YammerLogo16Mono"] = "YammerLogo16Mono";
    IconNames["YammerLogo16L01"] = "YammerLogo16L01";
    IconNames["YammerLogo16L02"] = "YammerLogo16L02";
    IconNames["YammerLogo16L03"] = "YammerLogo16L03";
    IconNames["YammerLogo16L04"] = "YammerLogo16L04";
    IconNames["YammerLogo16L05"] = "YammerLogo16L05";
    IconNames["YammerLogo16L06"] = "YammerLogo16L06";
    IconNames["YammerLogo32Mono"] = "YammerLogo32Mono";
    IconNames["YammerLogo32L01"] = "YammerLogo32L01";
    IconNames["YammerLogo32L02"] = "YammerLogo32L02";
    IconNames["YammerLogo32L03"] = "YammerLogo32L03";
    IconNames["YammerLogo32L04"] = "YammerLogo32L04";
    IconNames["YammerLogo32L05"] = "YammerLogo32L05";
    IconNames["YammerLogo32L06"] = "YammerLogo32L06";
    IconNames["YammerLogo32L07"] = "YammerLogo32L07";
    IconNames["YammerLogo32L08"] = "YammerLogo32L08";
    IconNames["YammerLogo32L09"] = "YammerLogo32L09";
    IconNames["TeamsLogo16Mono"] = "TeamsLogo16Mono";
    IconNames["TeamsLogo16L01"] = "TeamsLogo16L01";
    IconNames["TeamsLogo16L02"] = "TeamsLogo16L02";
    IconNames["TeamsLogo16L03"] = "TeamsLogo16L03";
    IconNames["TeamsLogo16L04"] = "TeamsLogo16L04";
    IconNames["TeamsLogo16L05"] = "TeamsLogo16L05";
    IconNames["TeamsLogo32Mono"] = "TeamsLogo32Mono";
    IconNames["TeamsLogo32L01"] = "TeamsLogo32L01";
    IconNames["TeamsLogo32L02"] = "TeamsLogo32L02";
    IconNames["TeamsLogo32L03"] = "TeamsLogo32L03";
    IconNames["TeamsLogo32L04"] = "TeamsLogo32L04";
    IconNames["TeamsLogo32L05"] = "TeamsLogo32L05";
    IconNames["TeamsLogo32L06"] = "TeamsLogo32L06";
    IconNames["TeamsLogo32L07"] = "TeamsLogo32L07";
    IconNames["TeamsLogo32L08"] = "TeamsLogo32L08";
    IconNames["OneDriveLogo16Mono"] = "OneDriveLogo16Mono";
    IconNames["OneDriveLogo16L01"] = "OneDriveLogo16L01";
    IconNames["OneDriveLogo16L02"] = "OneDriveLogo16L02";
    IconNames["OneDriveLogo16L03"] = "OneDriveLogo16L03";
    IconNames["OneDriveLogo16L04"] = "OneDriveLogo16L04";
    IconNames["OneDriveLogo32Mono"] = "OneDriveLogo32Mono";
    IconNames["OneDriveLogo32L01"] = "OneDriveLogo32L01";
    IconNames["OneDriveLogo32L02"] = "OneDriveLogo32L02";
    IconNames["OneDriveLogo32L03"] = "OneDriveLogo32L03";
    IconNames["OneDriveLogo32L04"] = "OneDriveLogo32L04";
    IconNames["SkypeLogo16Mono"] = "SkypeLogo16Mono";
    IconNames["SkypeLogo16L01"] = "SkypeLogo16L01";
    IconNames["SkypeLogo16L02"] = "SkypeLogo16L02";
    IconNames["SkypeLogo32Mono"] = "SkypeLogo32Mono";
    IconNames["SkypeLogo32L01"] = "SkypeLogo32L01";
    IconNames["SkypeLogo32L02"] = "SkypeLogo32L02";
    IconNames["SkypeLogo32L03"] = "SkypeLogo32L03";
    IconNames["SkypeLogo32L04"] = "SkypeLogo32L04";
    IconNames["DecreaseIndentLegacy"] = "DecreaseIndentLegacy";
    IconNames["IncreaseIndentLegacy"] = "IncreaseIndentLegacy";
    IconNames["SizeLegacy"] = "SizeLegacy";
})(IconNames || (IconNames = {}));
