import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import memoize from 'memoize-one';
import { List } from 'immutable';
import moment from 'moment';
import { defineMessages } from 'react-intl';
import { ProvisioningState } from '../data/models/common';
import { adjustDate, createIcal, createIcalVNext, expandEvents } from '../schedule/calendar-utilities';
import { mapDayToLocaleDayShort } from '../schedule/flyout-utilities';
import { DefaultCurrencyCode } from '../utils/constants';
import { getLocale, isTeamsOrLmsMode, storeIsLoading } from '../redux/selectors/common-selectors';
import { getCurrentLab, getLabCapacity, isLabGroupSynced, isCurrentLabReadOnly, isVNextLab, } from '../redux/selectors/lab-selectors';
import { convertToTemplateVm, convertVNextVirtualMachineToTemplateVm } from '../template/template-selectors';
import { getLabPricingAndAvailabilityData, getLabPricingAndAvailabilityLoadError, getLabPricingAndAvailabilityLoadState, } from '../redux/selectors/pricing-and-availability-selectors';
import { getSearch } from '../redux/selectors/route-selector';
import { isCurrentLabParentLabAccount } from '../redux/selectors/lab-parent-resource-selectors';
import { getTemplateVM, getUserVMs } from '../redux/selectors/vnext/virtual-machine-selectors';
import { getCurrentLabImage } from '../redux/selectors/vnext/image-selectors';
import { caseInsensitiveCultureInvariantCompare } from '../utils/string-comparison';
import { isLoadingLabMetadata } from '../redux/selectors/vnext/lab-selectors';
import { getCurrentLabSkuInfo } from '../redux/selectors/vnext/sku-selectors';
import { getVNextPriceLoadError } from '../redux/selectors/vnext/price-selectors';
const messages = defineMessages({
    startOnlyRecurringWithEndDateScheduleMessage: {
        id: 'StartOnlyRecurringWithEndDateScheduleMessage',
        defaultMessage: 'Start at {startTime} every {days} until {endDate}',
        description: 'Text on schedule tile to summarize a start only recurring schedule with end date.  {startTime} is the time at which the schedule will start, {days} is the weekdays the schedule will run, and {endDate} is the last day of the schedule.',
    },
    startOnlyRecurringWithNoEndDateScheduleMessage: {
        id: 'StartOnlyRecurringWithNoEndDateScheduleMessage',
        defaultMessage: 'Start at {startTime} every {days} (no end date)',
        description: 'Text on schedule tile to summarize a start only recurring schedule with no end date.  {startTime} is the time at which the schedule will start, {days} is the weekdays the schedule will run.',
    },
    startOnlyNonRecurringScheduleMessage: {
        id: 'StartOnlyNonRecurringScheduleMessage',
        defaultMessage: 'Start at {startTime} on {date}',
        description: 'Text on schedule tile to summarize a one time start only schedule.  {startTime} is the time at which the schedule will start, {date} is the day the schedule will run.',
    },
    stopOnlyRecurringWithEndDateScheduleMessage: {
        id: 'StopOnlyRecurringWithEndDateScheduleMessage',
        defaultMessage: 'Stop at {stopTime} every {days} until {endDate}',
        description: 'Text on schedule tile to summarize a stop only recurring schedule with end date.  {stopTime} is the time at which the schedule will run, {days} is the weekdays the schedule will run, and {endDate} is the last day of the schedule.',
    },
    stopOnlyRecurringWithNoEndDateScheduleMessage: {
        id: 'StopOnlyRecurringWithNoEndDateScheduleMessage',
        defaultMessage: 'Stop at {stopTime} every {days} (no end date)',
        description: 'Text on schedule tile to summarize a stop only recurring schedule with no end date.  {stopTime} is the time at which the schedule will run, {days} is the weekdays the schedule will run.',
    },
    stopOnlyNonRecurringScheduleMessage: {
        id: 'StopOnlyNonRecurringScheduleMessage',
        defaultMessage: 'Stop at {stopTime} on {date}',
        description: 'Text on schedule tile to summarize a one time stop only schedulee.  {stopTime} is the time at which the schedule will run, {date} is the day the schedule will run.',
    },
    standardRecurringWithEndDateScheduleMessage: {
        id: 'StandardRecurringWithEndDateScheduleMessage',
        defaultMessage: '{startTime}-{stopTime} every {days} until {endDate}',
        description: 'Text on schedule tile to summarize a standard recurring schedule with end date.  {startTime} is the time at which the schedule will start, {stopTime} is the time at which the schedule will stop, {days} is the weekdays the schedule will run, and {endDate} is the last day of the schedule.',
    },
    standardRecurringWithNoEndDateScheduleMessage: {
        id: 'StandardRecurringWithNoEndDateScheduleMessage',
        defaultMessage: '{startTime}-{stopTime} every {days} (no end date)',
        description: 'Text on schedule tile to summarize a standard recurring schedule with no end date.  {startTime} is the time at which the schedule will start, {stopTime} is the time at which the schedule will stop, {days} is the weekdays the schedule will run.',
    },
    standardNonRecurringScheduleMessage: {
        id: 'StandardNonRecurringScheduleMessage',
        defaultMessage: '{startTime}-{stopTime} on {date}',
        description: 'Text on schedule tile to summarize a one time standard schedule.  {startTime} is the time at which the schedule will start, {date} is the day the schedule will run.',
    },
});
export const isFailed = (provisioningState) => {
    return !!provisioningState && provisioningState.toLowerCase() === ProvisioningState.Failed;
};
export const compareByStartDate = (a, b) => {
    if (a.start.getTime() < b.start.getTime()) {
        return -1;
    }
    else if (a.start.getTime() > b.start.getTime()) {
        return 1;
    }
    else {
        return 0;
    }
};
export const compareVNextScheduleByDate = (a, b) => {
    const aDate = a.startAt ?? a.stopAt;
    const bDate = b.startAt ?? b.stopAt;
    if (!aDate && !bDate) {
        return 0;
    }
    if (!aDate || !bDate) {
        return !aDate ? 1 : -1;
    }
    if (aDate.getTime() < bDate.getTime()) {
        return -1;
    }
    else if (aDate.getTime() > bDate.getTime()) {
        return 1;
    }
    else {
        return 0;
    }
};
const isRecurring = (schedule) => {
    return !!schedule.recurrencePattern;
};
const hasEndDate = (isLabParentLabAccount, schedule) => {
    if (isLabParentLabAccount) {
        const vCurrentschedule = schedule;
        return !!vCurrentschedule.recurrencePattern && !!vCurrentschedule.recurrencePattern.until;
    }
    else {
        const vNextSchedule = schedule;
        return (!!vNextSchedule.recurrencePattern &&
            !!vNextSchedule.recurrencePattern.expirationDate);
    }
};
const isStandard = (isLabParentLabAccount, schedule) => {
    if (isLabParentLabAccount) {
        const vCurrentschedule = schedule;
        return vCurrentschedule.startAction && vCurrentschedule.endAction
            ? vCurrentschedule.startAction.enableState === Ml.EnableState.Enabled &&
                vCurrentschedule.endAction.enableState === Ml.EnableState.Enabled
            : false;
    }
    else {
        const vNextSchedule = schedule;
        return !!vNextSchedule.startAt && !!vNextSchedule.stopAt;
    }
};
export const individualHours = (isLabParentLabAccount, schedules) => {
    let totalIndividualHours = 0;
    if (isLabParentLabAccount) {
        const individualSchedules = schedules.filter((schedule) => !isRecurring(schedule) && isStandard(isLabParentLabAccount, schedule));
        individualSchedules.forEach((schedule) => {
            const startMom = moment(schedule.start);
            const endMom = moment(schedule.end);
            const duration = moment.duration(endMom.diff(startMom));
            totalIndividualHours += duration.as('hours');
        });
    }
    else {
        const individualSchedules = schedules.filter((schedule) => !isRecurring(schedule) && isStandard(isLabParentLabAccount, schedule));
        individualSchedules.forEach((schedule) => {
            const startMom = moment(schedule.startAt);
            const endMom = moment(schedule.stopAt);
            const duration = moment.duration(endMom.diff(startMom));
            totalIndividualHours += duration.as('hours');
        });
    }
    return totalIndividualHours;
};
export const recurringHours = (isLabParentLabAccount, schedules) => {
    let totalRecurringHours = 0;
    if (hasUnlimitedSchedule(isLabParentLabAccount, schedules)) {
        return undefined;
    }
    if (isLabParentLabAccount) {
        const recurringSchedules = schedules.filter((schedule) => isRecurring(schedule) && isStandard(isLabParentLabAccount, schedule));
        recurringSchedules.forEach((schedule) => {
            const cal = createIcal([schedule]);
            const eventsForTimeWindow = expandEvents(cal, schedule.start, schedule.recurrencePattern.until);
            const numberOfOccurrences = eventsForTimeWindow.length;
            const startMom = moment(schedule.start);
            const endMom = moment(schedule.end);
            const duration = moment.duration(endMom.diff(startMom));
            totalRecurringHours += duration.as('hours') * numberOfOccurrences;
        });
    }
    else {
        const recurringSchedules = schedules.filter((schedule) => isRecurring(schedule) && isStandard(isLabParentLabAccount, schedule));
        recurringSchedules.forEach((schedule) => {
            const cal = createIcalVNext([schedule]);
            const eventsForTimeWindow = expandEvents(cal, schedule.startAt, schedule.recurrencePattern.expirationDate);
            const numberOfOccurrences = eventsForTimeWindow.length;
            const startMom = moment(schedule.startAt);
            const endMom = moment(schedule.stopAt);
            const duration = moment.duration(endMom.diff(startMom));
            totalRecurringHours += duration.as('hours') * numberOfOccurrences;
        });
    }
    return totalRecurringHours;
};
export const calculateQuotaPerUser = (lab) => {
    if (!lab) {
        return 0;
    }
    const isVNext = isVNextLab(lab.id);
    if (!isVNext) {
        const usageQuota = lab.usageQuota;
        return !usageQuota ? 0 : usageQuota.asHours();
    }
    else {
        const usageQuota = lab.virtualMachineProfile?.usageQuota;
        return !usageQuota ? 0 : moment.duration(usageQuota).asHours();
    }
};
export const calculateScheduleHoursPerUser = (isLabParentLabAccount, schedules) => {
    const scheduleRecurringHours = recurringHours(isLabParentLabAccount, schedules);
    if (scheduleRecurringHours === undefined) {
        return undefined;
    }
    let hours = individualHours(isLabParentLabAccount, schedules) + scheduleRecurringHours;
    if (isLabParentLabAccount) {
        hours += startOnlyHours(schedules);
    }
    return hours;
};
export const calculateHoursPerUser = (schedules, lab) => {
    const quotaPerUser = calculateQuotaPerUser(lab);
    const isLabParentLabAccount = !isVNextLab(lab?.id);
    const scheduleHoursPerUser = calculateScheduleHoursPerUser(isLabParentLabAccount, schedules);
    if (scheduleHoursPerUser === undefined) {
        return undefined;
    }
    return quotaPerUser + scheduleHoursPerUser;
};
export const calculateNumberOfUsers = (lab) => {
    if (!lab) {
        return 0;
    }
    return getLabCapacity(lab) ?? 0;
};
export const calculateTotalHoursPerUser = (schedules, lab) => {
    const numberOfUsers = calculateNumberOfUsers(lab);
    const hoursPerUser = calculateHoursPerUser(schedules, lab);
    if (hoursPerUser === undefined) {
        return undefined;
    }
    return numberOfUsers * hoursPerUser;
};
export const calculateTotalIndividualQuota = (isVNext, users) => {
    const individualQuotas = !isVNext
        ? users.map((user) => user && user.additionalUsageQuota ? user.additionalUsageQuota.asHours() : 0)
        : users.map((user) => user && user.additionalUsageQuota ? moment.duration(user.additionalUsageQuota).asHours() : 0);
    return individualQuotas.reduce((a, b) => a + b, 0);
};
export const calculateTotalHoursForAllUsers = (schedules, users, lab) => {
    const isVNext = isVNextLab(lab?.id);
    const totalHoursPerUser = calculateTotalHoursPerUser(schedules, lab);
    const totalIndividualQuota = calculateTotalIndividualQuota(isVNext, users);
    if (totalHoursPerUser === undefined) {
        return undefined;
    }
    return totalHoursPerUser + totalIndividualQuota;
};
export const calculatePrice = (lab, sizeData) => {
    if (!sizeData) {
        return 0;
    }
    const isVNext = isVNextLab(lab?.id);
    if (!isVNext) {
        return sizeData.price;
    }
    else {
        return !lab?.location ? 0 : sizeData.price;
    }
};
export const calculateTotalPrice = (schedules, users, lab, sizeData) => {
    const totalHours = calculateTotalHoursForAllUsers(schedules, users, lab);
    if (totalHours === undefined) {
        return undefined;
    }
    const price = calculatePrice(lab, sizeData);
    return price ? totalHours * price : undefined;
};
export const getCurrencyCode = (lab, sizeData) => {
    if (!sizeData) {
        return DefaultCurrencyCode;
    }
    const isVNext = isVNextLab(lab?.id);
    if (!isVNext) {
        return sizeData.currencyCode;
    }
    else {
        const currencyCode = !lab?.location ? undefined : sizeData.currency;
        return currencyCode ?? DefaultCurrencyCode;
    }
};
export const hasUnlimitedSchedule = (isLabParentLabAccount, schedules) => {
    const isVCurrentScheduleInfinitelyRecurring = (schedule) => !!schedule.recurrencePattern && !schedule.recurrencePattern.until;
    const isVNextScheduleInfinitelyRecurring = (schedule) => !!schedule.recurrencePattern && !schedule.recurrencePattern.expirationDate;
    const index = isLabParentLabAccount
        ? schedules.findIndex((schedule) => isVCurrentScheduleInfinitelyRecurring(schedule))
        : schedules.findIndex((schedule) => isVNextScheduleInfinitelyRecurring(schedule));
    return index > -1;
};
const sortByDate = (a, b) => {
    if (moment(a).isAfter(b)) {
        return 1;
    }
    else if (moment(b).isAfter(a)) {
        return -1;
    }
    return 0;
};
const sortByStop = (a, b) => {
    if (moment(a.stop).isAfter(b.stop)) {
        return 1;
    }
    else if (moment(b.stop).isAfter(a.stop)) {
        return -1;
    }
    return 0;
};
const isStartOnly = (schedule) => schedule.startAction.enableState === Ml.EnableState.Enabled &&
    schedule.endAction.enableState === Ml.EnableState.Disabled;
const isVCurrentScheduleStopOnly = (schedule) => schedule.startAction.enableState === Ml.EnableState.Disabled &&
    schedule.endAction.enableState === Ml.EnableState.Enabled;
const isStopOnly = (isLabParentLabAccount, schedule) => {
    if (isLabParentLabAccount) {
        return (schedule.startAction.enableState === Ml.EnableState.Disabled &&
            schedule.endAction.enableState === Ml.EnableState.Enabled);
    }
    else {
        return !!schedule.stopAt && !schedule.startAt;
    }
};
/** Parse out start and stop events for processing later on
 *  Start events are added only if they come from a start only schedule
 *  Stop events are added if they come from a standard or stop only schedule
 */
export const unzipStartAndStopEvents = (schedules) => {
    let allExclusiveStarts = [];
    let allStops = [];
    const recurringSchedules = schedules.filter((schedule) => isRecurring(schedule));
    const individualSchedules = schedules.filter((schedule) => !isRecurring(schedule));
    recurringSchedules.forEach((schedule) => {
        const cal = createIcal([schedule]);
        const eventsForTimeWindow = expandEvents(cal, schedule.start, schedule.recurrencePattern.until);
        if (isStartOnly(schedule)) {
            const starts = eventsForTimeWindow.map((event) => {
                const start = new Date(event.startDate);
                start.setHours(schedule.start.getHours(), schedule.start.getMinutes());
                return start;
            });
            allExclusiveStarts = allExclusiveStarts.concat(starts);
        }
        else {
            const stops = eventsForTimeWindow.map((event) => {
                const stop = new Date(event.endDate);
                stop.setHours(schedule.end.getHours(), schedule.end.getMinutes());
                return isVCurrentScheduleStopOnly(schedule) ? { stop } : { stop, start: new Date(event.startDate) };
            });
            allStops = allStops.concat(stops);
        }
    });
    individualSchedules.forEach((schedule) => {
        if (isStartOnly(schedule)) {
            allExclusiveStarts.push(new Date(schedule.start));
        }
        else {
            if (isVCurrentScheduleStopOnly(schedule)) {
                allStops.push({ stop: new Date(schedule.end) });
            }
            else {
                allStops.push({ stop: new Date(schedule.end), start: new Date(schedule.start) });
            }
        }
    });
    allExclusiveStarts.sort(sortByDate);
    allStops.sort(sortByStop);
    return { allExclusiveStarts, allStops };
};
/** Given a list of schedules, determine if there is a start only event that
 *  causes the number of scheduled hours to be infinite
 */
export const hasInfiniteStartOnlySchedule = (schedules) => {
    let latestStart;
    let latestStop;
    const { allExclusiveStarts, allStops } = unzipStartAndStopEvents(schedules);
    allExclusiveStarts.forEach((start) => {
        if (!latestStart || moment(start).isAfter(latestStart)) {
            latestStart = new Date(start);
        }
    });
    allStops
        .map((_stop) => _stop.stop)
        .forEach((stop) => {
        if (!latestStop || moment(stop).isAfter(latestStop)) {
            latestStop = new Date(stop);
        }
    });
    if (!latestStart) {
        return false;
    }
    if (!latestStop) {
        return true;
    }
    return moment(latestStart).isAfter(latestStop);
};
const isFirstStopAfterStart = (stopTime, startTime) => {
    return moment(stopTime).isAfter(startTime);
};
const isInMiddleOfStandardSchedule = (startTime, standardStartTime) => {
    return standardStartTime ? moment(standardStartTime).isBefore(startTime) : false;
};
const isStopPartOfStandardSchedule = (standardStartTime) => {
    return !!standardStartTime;
};
const calculateDiffererenceInHours = (startTime, endTime) => {
    const startMom = moment(startTime);
    const endMom = moment(endTime);
    const duration = moment.duration(endMom.diff(startMom));
    return duration.as('hours');
};
const hasStartOnlySchedule = (schedules) => {
    const index = schedules.findIndex((schedule) => isStartOnly(schedule));
    return index > -1;
};
/** Calculates the hours in a list of schedule that are a consequence of a start only schedule
 *  Assumptions:
 *   - The list given does not contain an infinite start schedule
 *   - Individual and recurring hours for normal (standard) schedules are calculated elsewhere
 **/
export const startOnlyHours = (schedules) => {
    if (!hasStartOnlySchedule(schedules)) {
        return 0;
    }
    const { allExclusiveStarts, allStops } = unzipStartAndStopEvents(schedules);
    let totalhours = 0;
    allExclusiveStarts.forEach((start) => {
        for (let i = 0; i < allStops.length; i++) {
            if (isFirstStopAfterStart(allStops[i].stop, start)) {
                if (isInMiddleOfStandardSchedule(start, allStops[i].start)) {
                    totalhours += 0;
                }
                else if (isStopPartOfStandardSchedule(allStops[i].start)) {
                    const duration = calculateDiffererenceInHours(start, allStops[i].start);
                    totalhours += duration;
                }
                else {
                    // stop only schedule
                    const duration = calculateDiffererenceInHours(start, allStops[i].stop);
                    totalhours += duration;
                }
                break;
            }
        }
    });
    return totalhours;
};
const getTime = (date, locale) => {
    const time = moment(date).locale(locale).format('LT');
    return time.replace(' ', '').toLowerCase();
};
const getDate = (date, locale) => {
    return moment(date).locale(locale).format('l');
};
export const isScheduleAlreadyEnded = (isLabParentLabAccount, today, schedule) => {
    if (!schedule) {
        return false;
    }
    if (isLabParentLabAccount) {
        const vCurrentschedule = schedule;
        if (isRecurring(schedule)) {
            return hasEndDate(isLabParentLabAccount, schedule)
                ? moment(vCurrentschedule.recurrencePattern.until).isBefore(today)
                : false;
        }
        return moment(vCurrentschedule.end).isBefore(today);
    }
    else {
        const vNextSchedule = schedule;
        if (isRecurring(schedule)) {
            return hasEndDate(isLabParentLabAccount, schedule)
                ? moment(vNextSchedule.recurrencePattern.expirationDate).isBefore(today)
                : false;
        }
        return moment(vNextSchedule.stopAt).isBefore(today);
    }
};
/**
 * Picks 2 schedules to display, with a preference for displaying active schedules.
 * @param schedules is a list of sorted schedules by start date, assumed to be greater than 2 in length
 */
export const pickVCurrentRelevantSchedules = (today, schedules) => {
    let processedSchedules = List(schedules.toArray());
    let pickedSchedules = List([]);
    const isLabParentLabAccount = true;
    for (let i = 0, j = 0; i < schedules.count() && pickedSchedules.count() < 2; i++, j++) {
        if (!!schedules.get(i) && !isScheduleAlreadyEnded(isLabParentLabAccount, today, schedules.get(i))) {
            pickedSchedules = pickedSchedules.push(schedules.get(i));
            processedSchedules = processedSchedules.remove(j);
            j--;
        }
    }
    // fill in schedules so we return a list of 2
    // making sure to fill in the earliest schedule first
    if (pickedSchedules.count() === 0 && processedSchedules.count() > 1) {
        pickedSchedules = pickedSchedules.push(processedSchedules.get(0));
        pickedSchedules = pickedSchedules.push(processedSchedules.get(1));
    }
    else if (pickedSchedules.count() === 1 && processedSchedules.count() > 0) {
        pickedSchedules = pickedSchedules.unshift(processedSchedules.get(0));
    }
    return pickedSchedules;
};
export const pickVNextRelevantSchedules = (today, schedules) => {
    const isLabParentLabAccount = false;
    let processedSchedules = List(schedules.toArray());
    let pickedSchedules = List([]);
    for (let i = 0, j = 0; i < schedules.count() && pickedSchedules.count() < 2; i++, j++) {
        if (!!schedules.get(i) && !isScheduleAlreadyEnded(isLabParentLabAccount, today, schedules.get(i))) {
            pickedSchedules = pickedSchedules.push(schedules.get(i));
            processedSchedules = processedSchedules.remove(j);
            j--;
        }
    }
    // fill in schedules so we return a list of 2
    // making sure to fill in the earliest schedule first
    if (pickedSchedules.count() === 0 && processedSchedules.count() > 1) {
        pickedSchedules = pickedSchedules.push(processedSchedules.get(0));
        pickedSchedules = pickedSchedules.push(processedSchedules.get(1));
    }
    else if (pickedSchedules.count() === 1 && processedSchedules.count() > 0) {
        pickedSchedules = pickedSchedules.unshift(processedSchedules.get(0));
    }
    return pickedSchedules;
};
export const pickRelevantSchedules = (isLabParentLabAccount, today, schedules) => {
    return isLabParentLabAccount
        ? pickVCurrentRelevantSchedules(today, schedules)
        : pickVNextRelevantSchedules(today, schedules);
};
export const getReadableSchedule = (isLabParentLabAccount, schedule, intl, locale) => {
    const msg = intl.formatMessage;
    const start = isLabParentLabAccount
        ? schedule.start
        : schedule.startAt;
    const end = isLabParentLabAccount ? schedule.end : schedule.stopAt;
    const adjustedStart = !start ? undefined : adjustDate(start, schedule.timeZoneId);
    const adjustedEnd = adjustDate(end, schedule.timeZoneId);
    if (isRecurring(schedule)) {
        const days = isLabParentLabAccount
            ? schedule
                .recurrencePattern.weekDays.map((day) => mapDayToLocaleDayShort(locale, day))
                .join(', ')
            : schedule
                .recurrencePattern.weekDays.map((day) => mapDayToLocaleDayShort(locale, day))
                .join(', ');
        if (hasEndDate(isLabParentLabAccount, schedule)) {
            const adjustedUntil = isLabParentLabAccount
                ? adjustDate(schedule.recurrencePattern.until, schedule.timeZoneId)
                : adjustDate(schedule.recurrencePattern.expirationDate, schedule.timeZoneId);
            if (isLabParentLabAccount && isStartOnly(schedule)) {
                return msg(messages.startOnlyRecurringWithEndDateScheduleMessage, {
                    startTime: getTime(adjustedStart, locale),
                    days,
                    endDate: getDate(adjustedUntil, locale),
                });
            }
            else if (isStopOnly(isLabParentLabAccount, schedule)) {
                return msg(messages.stopOnlyRecurringWithEndDateScheduleMessage, {
                    stopTime: getTime(adjustedEnd, locale),
                    days,
                    endDate: getDate(adjustedUntil, locale),
                });
            }
            return msg(messages.standardRecurringWithEndDateScheduleMessage, {
                startTime: getTime(adjustedStart, locale),
                stopTime: getTime(adjustedEnd, locale),
                days,
                endDate: getDate(adjustedUntil, locale),
            });
        }
        if (isLabParentLabAccount && isStartOnly(schedule)) {
            return msg(messages.startOnlyRecurringWithNoEndDateScheduleMessage, {
                startTime: getTime(adjustedStart, locale),
                days,
            });
        }
        else if (isStopOnly(isLabParentLabAccount, schedule)) {
            return msg(messages.stopOnlyRecurringWithNoEndDateScheduleMessage, {
                stopTime: getTime(adjustedEnd, locale),
                days,
            });
        }
        return msg(messages.standardRecurringWithNoEndDateScheduleMessage, {
            startTime: getTime(adjustedStart, locale),
            stopTime: getTime(adjustedEnd, locale),
            days,
        });
    }
    if (isLabParentLabAccount && isStartOnly(schedule)) {
        return msg(messages.startOnlyNonRecurringScheduleMessage, {
            startTime: getTime(adjustedStart, locale),
            date: getDate(adjustedStart, locale),
        });
    }
    else if (isStopOnly(isLabParentLabAccount, schedule)) {
        return msg(messages.stopOnlyNonRecurringScheduleMessage, {
            stopTime: getTime(adjustedEnd, locale),
            date: getDate(adjustedEnd, locale),
        });
    }
    return msg(messages.standardNonRecurringScheduleMessage, {
        startTime: getTime(adjustedStart, locale),
        stopTime: getTime(adjustedEnd, locale),
        date: getDate(adjustedEnd, locale),
    });
};
export const getDashBoardViewModel = memoize((state) => {
    const isVNext = !isCurrentLabParentLabAccount(state);
    const isReadOnly = isCurrentLabReadOnly(state);
    const locale = getLocale(state);
    const isTeamsOrLmsIntegrationEnabled = isTeamsOrLmsMode(state);
    const lab = getCurrentLab(state);
    const isGroupSyncModeEnabled = isLabGroupSynced(lab);
    if (!isVNext) {
        const templateStore = state.get('templateStore');
        const scheduleStore = state.get('scheduleStore');
        const envStore = state.get('environmentStore');
        const userStore = state.get('userStore');
        const schedulesLoadState = scheduleStore.get('loadState');
        const isSchedulesLoading = storeIsLoading(schedulesLoadState);
        const scheduleLoadError = scheduleStore.get('loadError');
        const schedules = scheduleStore.get('schedules');
        const environmentsLoadState = envStore.get('loadState');
        const isEnvironmentsLoading = storeIsLoading(environmentsLoadState);
        const environments = envStore.get('environments');
        const environmentsLoadError = envStore.get('loadError');
        const usersLoadState = userStore.get('loadState');
        const isUsersLoading = storeIsLoading(usersLoadState);
        const users = userStore.get('users');
        const usersLoadError = userStore.get('loadError');
        const templatesLoadState = templateStore.get('loadState');
        const isTemplateLoading = storeIsLoading(templatesLoadState);
        const templates = templateStore.get('templates');
        const templateLoadError = templateStore.get('loadError');
        const currentTemplate = templateStore.get('currentTemplate');
        const isSaving = templateStore.get('isUpdating');
        const template = convertToTemplateVm(templates, currentTemplate, isSaving);
        const labPriceLoadState = getLabPricingAndAvailabilityLoadState(state);
        const isPriceDataLoading = storeIsLoading(labPriceLoadState);
        const sizeData = getLabPricingAndAvailabilityData(state);
        const pricingError = getLabPricingAndAvailabilityLoadError(state);
        return {
            lab,
            template,
            schedules,
            environments,
            users,
            sizeData,
            isTemplateLoading,
            isSchedulesLoading,
            isEnvironmentsLoading,
            isUsersLoading,
            isPriceDataLoading,
            isReadOnly,
            isTeamsOrLmsIntegrationEnabled,
            templateLoadError,
            scheduleLoadError,
            usersLoadError,
            environmentsLoadError,
            pricingError,
            locale,
            isGroupSyncModeEnabled,
        };
    }
    else {
        const lab = getCurrentLab(state);
        const virtualMachineStore = state.get('vNextVirtualMachineStore');
        const userStore = state.get('vNextUserStore');
        const pricingAndAvailabilityStore = state.get('vNextPricingAndAvailabilityStore');
        const labStore = state.get('vNextLabStore');
        const scheduleStore = state.get('vNextScheduleStore');
        const skuStore = state.get('vNextSkuStore');
        const priceStore = state.get('vNextPriceStore');
        const virtualMachinesLoadState = virtualMachineStore.get('loadState');
        const isEnvironmentsLoading = storeIsLoading(virtualMachinesLoadState);
        const environments = getUserVMs(state);
        const environmentsLoadError = virtualMachineStore.get('loadError');
        const usersLoadState = userStore.get('loadState');
        const isUsersLoading = storeIsLoading(usersLoadState);
        const users = userStore.get('users');
        const usersLoadError = userStore.get('loadError');
        const skuLoadState = skuStore.get('loadState');
        const pricingAndAvailabilityLoadState = pricingAndAvailabilityStore.get('loadState');
        const priceLoadState = priceStore.get('loadState');
        const isPriceDataLoading = storeIsLoading(pricingAndAvailabilityLoadState) ||
            storeIsLoading(skuLoadState) ||
            storeIsLoading(priceLoadState);
        const pricingError = pricingAndAvailabilityStore.get('error') || getVNextPriceLoadError(state);
        const vNextTemplate = getTemplateVM(state);
        const isSaving = labStore.get('isUpdating');
        const image = getCurrentLabImage(state);
        const labSku = getCurrentLabSkuInfo(state);
        const template = convertVNextVirtualMachineToTemplateVm(vNextTemplate, lab, isSaving, image, labSku);
        const getErrors = virtualMachineStore.get('getErrors');
        const templateLoadError = getErrors.find((o) => !!template?.id && caseInsensitiveCultureInvariantCompare(o.id, template.id) === 0);
        const schedulesLoadState = scheduleStore.get('loadState');
        const isSchedulesLoading = storeIsLoading(schedulesLoadState);
        const scheduleLoadError = scheduleStore.get('loadError');
        const schedules = scheduleStore.get('schedules');
        const isLabMetadataLoading = isLoadingLabMetadata(state);
        return {
            lab,
            template,
            schedules,
            environments,
            users,
            sizeData: labSku,
            isTemplateLoading: isEnvironmentsLoading || isLabMetadataLoading,
            isSchedulesLoading,
            isEnvironmentsLoading,
            isUsersLoading,
            isPriceDataLoading,
            isReadOnly,
            isTeamsOrLmsIntegrationEnabled,
            templateLoadError,
            usersLoadError,
            environmentsLoadError,
            pricingError,
            locale,
            scheduleLoadError,
            isGroupSyncModeEnabled,
        };
    }
});
export const getDashboardContainerModel = memoize((state) => {
    const search = getSearch(state);
    const dashboardViewModel = getDashBoardViewModel(state);
    const isVNext = !isCurrentLabParentLabAccount(state);
    return {
        dashboardViewModel,
        search,
        isVNext,
    };
});
const DashboardSelectors = {
    getDashboardContainerModel,
    getDashBoardViewModel,
    individualHours,
    recurringHours,
    calculateQuotaPerUser,
    calculateScheduleHoursPerUser,
    calculateHoursPerUser,
    calculateNumberOfUsers,
    calculateTotalHoursPerUser,
    calculateTotalIndividualQuota,
    calculateTotalHoursForAllUsers,
    calculatePrice,
    calculateTotalPrice,
    hasUnlimitedSchedule,
    hasInfiniteStartOnlySchedule,
    unzipStartAndStopEvents,
    startOnlyHours,
    getReadableSchedule,
    compareByStartDate,
    isFailed,
    isScheduleAlreadyEnded,
    pickRelevantSchedules,
};
export default DashboardSelectors;
