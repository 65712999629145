import { call, put, takeLatest, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { ResourceId } from '../../../utils/resource-id';
import { SharedImageActionType } from '../../actions/shared-image/shared-image-actions';
import { listSharedImagesSuccess, listSharedImagesError, } from '../../actions/shared-image/shared-image-action-creators';
import SharedImageProvider from '../../../data/providers/shared-image-provider';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
export function* listSharedImages(action) {
    const { labAccountId } = action;
    try {
        const resourceId = new ResourceId(labAccountId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const images = yield call(SharedImageProvider.listSharedImages, resourceId, accessToken, locale, language);
        yield put(listSharedImagesSuccess(images));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labAccountId, FailureOperation.ListSharedImages, e ? e.code : undefined);
        yield put(listSharedImagesError(error));
    }
}
export function* listSharedImagesSaga() {
    yield takeLatest(SharedImageActionType.LIST_SHARED_IMAGES, listSharedImages);
}
