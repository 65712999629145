import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { Checkbox, DirectionalHint, Dropdown, Separator, Stack, TooltipHost, } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import memoize from 'memoize-one';
import { DefaultCurrencyCode } from '../../utils/constants';
import { numberSorter } from '../../utils/sorting';
import { messages as createLabMessages } from './create-lab-messages';
import { messages } from './vm-size-picker-shared';
import { isVCurrentSizeDisabled } from './create-lab-selectors';
const Option = (option) => {
    const { data, minPrice, maxPrice, currencyCode, disabled } = option;
    const localizedDescription = data.localizedDescription;
    const localizedDisplayName = data.localizedDisplayName;
    const coresCount = data.coresCount;
    const memoryInGb = data.memoryInGb;
    let label;
    if (disabled) {
        label = <span>{localizedDisplayName}</span>;
    }
    else if (minPrice !== maxPrice) {
        label = (<FormattedMessage id="VmSizePickerTitleMinAndMaxPrice" defaultMessage="{size} ({minPrice}-{maxPrice}/hr)" description="The name of a size and its min and max price per hour. {size} is the name of the size, {minPrice} is the minimum price, {maxPrice} is the maximum price." values={{
            size: localizedDisplayName,
            minPrice: <FormattedNumber value={minPrice} style="currency" currency={currencyCode}/>,
            maxPrice: <FormattedNumber value={maxPrice} style="currency" currency={currencyCode}/>,
        }}/>);
    }
    else {
        label = (<FormattedMessage id="VmSizePickerTitle" defaultMessage="{size} ({price}/hr)" description="The name of a size and its price per hour. {size} is the name of the size and {price} is the price." values={{
            size: localizedDisplayName,
            price: <FormattedNumber value={minPrice} style="currency" currency={currencyCode}/>,
        }}/>);
    }
    return (<TooltipHost content={localizedDescription} directionalHint={DirectionalHint.leftCenter} styles={{ root: { width: '100%' } }}>
            <Stack styles={{ root: { width: '100%' } }}>
                <Stack.Item>{label}</Stack.Item>
                <Stack.Item>
                    <div style={{ color: !option.disabled ? '#555555' : undefined, fontSize: 12 }}>
                        <FormattedMessage {...createLabMessages.vmSizePickerFormat} values={{
        numberOfCores: coresCount,
        memorySize: memoryInGb,
    }}/>
                    </div>
                </Stack.Item>
            </Stack>
        </TooltipHost>);
};
const getKey = (size) => {
    return size.sizeName;
};
const getText = (size) => {
    return size.localizedDisplayName;
};
const getMinPriceMaxPriceAndCurrency = (size) => {
    let minPrice = 0;
    let maxPrice = 0;
    let currency = undefined;
    const geographies = size.geographies || size.locations;
    Object.keys(geographies).forEach((key) => {
        const geography = geographies[key];
        if (geography.availability !== Ml.Availability.Available) {
            return;
        }
        const { price, currencyCode } = geography;
        if (!currency) {
            currency = currencyCode;
        }
        if (!minPrice || minPrice > price) {
            minPrice = price;
        }
        if (!maxPrice || maxPrice < price) {
            maxPrice = price;
        }
    });
    return { minPrice, maxPrice, currencyCode: currency || DefaultCurrencyCode };
};
const getOptions = memoize((data, coreQuotaData) => {
    const options = [];
    Object.keys(data).forEach((key) => {
        const size = data[key];
        const disabled = isVCurrentSizeDisabled(size, coreQuotaData);
        const { minPrice, maxPrice, currencyCode } = getMinPriceMaxPriceAndCurrency(size);
        const option = {
            disabled,
            key: getKey(size),
            text: getText(size),
            minPrice,
            maxPrice,
            currencyCode,
            data: size,
        };
        options.push(option);
    });
    const sorter = numberSorter();
    return options.sort((a, b) => sorter(a.data.ordinal, b.data.ordinal));
});
const itemStyle = {
    paddingTop: 4,
    paddingBottom: 4,
    height: 'fit-content',
    selectors: {
        '.ms-Button-flexContainer': {
            width: '100%',
        },
    },
};
export const VmSizePicker = (props) => {
    const { label, data, coreQuotaData, selected, required, onChange } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const [showUnavailable, setShowUnavailable] = React.useState(false);
    const options = getOptions(data, coreQuotaData);
    const filteredOptions = !showUnavailable ? options.filter((o) => !o.disabled) : options;
    const hasDisabledSize = options.some((x) => x.disabled);
    const dropdownProps = {
        label,
        required,
        options: filteredOptions,
        placeholder: msg(messages.placeholder),
        selectedKey: selected ? [getKey(selected)] : [],
        styles: {
            callout: {
                maxHeight: '300px',
                overflowY: 'auto',
                overflowX: 'hidden',
            },
            title: {
                lineHeight: 20,
                height: 50,
                paddingTop: 5,
            },
            dropdownItem: itemStyle,
            dropdownItemSelected: itemStyle,
            dropdownItemDisabled: itemStyle,
            dropdownItemSelectedAndDisabled: itemStyle,
            root: {
                paddingBottom: '8px',
            },
        },
        onChange: (_, value) => {
            const option = value;
            if (!option.disabled) {
                onChange(option.data);
            }
        },
        onRenderOption: (option) => (option ? <Option {...option}/> : null),
        onRenderTitle: (options) => (options ? <Option {...options[0]}/> : null),
        onRenderList: (renderProps, defaultRender) => {
            return (<>
                    <div style={{ maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {defaultRender && defaultRender(renderProps)}
                    </div>
                    {hasDisabledSize && (<>
                            <Separator styles={{ root: { padding: '0', margin: '0', height: '1px' } }}/>
                            <Checkbox styles={{ root: { padding: '15px 5px 15px 15px' } }} checked={showUnavailable} onChange={(_, value) => setShowUnavailable(value || false)} label={msg(messages.showUnavailable)}/>
                        </>)}
                </>);
        },
    };
    return <Dropdown {...dropdownProps} aria-required={required || undefined}/>;
};
export default VmSizePicker;
