import { routerActions } from 'connected-react-router/immutable';
import { takeEvery, select, put, call, takeLatest, all } from 'redux-saga/effects';
import queryString from 'query-string';
import { Feature } from '../../../utils/features';
import { trackException, enableConsoleLog, disableConsoleLog } from '../../../utils/telemetry/telemetry-channel';
import { CommonActionType, } from '../../actions/common/common-actions';
import { enableFeature as enableFeatureActionCreator, enableFeatureError, enableFeatureSuccess, processUrlFeaturesError, processUrlFeaturesSuccess, } from '../../actions/common/common-action-creators';
import { getCurrentReturnUrl } from '../../selectors/identity-selector';
import { getSearch, getRoute } from '../../selectors/route-selector';
import Routes from '../../../utils/routes';
export const queryParamPrefix = 'feature_';
export function isFeatureParam(featureParam) {
    return featureParam.trim().toLowerCase().startsWith(queryParamPrefix);
}
export function formatFeatureParam(feature) {
    return `${queryParamPrefix}${feature.trim().toLowerCase()}`;
}
export function getFeature(featureParam) {
    const feature = featureParam.trim().toLowerCase().slice(queryParamPrefix.length);
    return feature;
}
export function resolveUrlFeature(searchParams, featureParam) {
    const urlParams = Object.keys(searchParams);
    for (let i = 0; i < urlParams.length; ++i) {
        const param = urlParams[i];
        if (param.trim().toLowerCase() === featureParam.trim().toLowerCase()) {
            return param;
        }
    }
    return undefined;
}
export function* processUrlFeature(featureParam) {
    try {
        const isFeatureFlag = yield call(isFeatureParam, featureParam);
        if (isFeatureFlag) {
            const feature = yield call(getFeature, featureParam);
            yield put(enableFeatureActionCreator(feature));
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* processUrlFeatures(action) {
    try {
        let search = yield select(getSearch);
        const route = yield select(getRoute);
        if (route === Routes.SignInReturn) {
            // in the case of interactive sign in, getSearch will be empty and we rely on getting params from returnUrl
            const returnUrl = yield select(getCurrentReturnUrl);
            const index = returnUrl.lastIndexOf('?');
            if (index > -1) {
                search = returnUrl.substring(index);
            }
        }
        const searchParams = yield call(queryString.parse, search);
        yield all(Object.keys(searchParams).map((param) => call(processUrlFeature, param)));
        yield put(processUrlFeaturesSuccess());
    }
    catch (err) {
        trackException(undefined, err);
        yield put(processUrlFeaturesError());
    }
}
export function* enableFeature(action) {
    try {
        const { feature } = action;
        if (feature === Feature.Dev) {
            yield call(enableConsoleLog);
        }
        const currentSearch = yield select(getSearch);
        const featureParam = yield call(formatFeatureParam, feature);
        const searchParams = yield call(queryString.parse, currentSearch);
        const urlFeatureParam = yield call(resolveUrlFeature, searchParams, featureParam);
        if (!urlFeatureParam) {
            searchParams[featureParam] = 'true';
            const pathname = yield select(getRoute);
            const search = yield call(queryString.stringify, searchParams);
            yield put(routerActions.replace({ pathname, search }));
        }
        yield put(enableFeatureSuccess());
    }
    catch (err) {
        trackException(undefined, err);
        yield put(enableFeatureError());
    }
}
export function* disableFeature(action) {
    try {
        const { feature } = action;
        if (feature === Feature.Dev) {
            yield call(disableConsoleLog);
        }
        const currentSearch = yield select(getSearch);
        const featureParam = yield call(formatFeatureParam, feature);
        const searchParams = yield call(queryString.parse, currentSearch);
        const urlFeatureParam = yield call(resolveUrlFeature, searchParams, featureParam);
        if (!!urlFeatureParam) {
            delete searchParams[urlFeatureParam];
            const pathname = yield select(getRoute);
            const search = yield call(queryString.stringify, searchParams);
            yield put(routerActions.replace({ pathname, search }));
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* processUrlFeaturesSaga() {
    yield takeLatest(CommonActionType.PROCESS_URL_FEATURES, processUrlFeatures);
}
export function* enableFeatureSaga() {
    yield takeEvery(CommonActionType.ENABLE_FEATURE, enableFeature);
}
export function* disableFeatureSaga() {
    yield takeEvery(CommonActionType.DISABLE_FEATURE, disableFeature);
}
