import memoize from 'memoize-one';
import { isVNextFeatureEnabledOrLms, storeIsLoading } from '../redux/selectors/common-selectors';
import { getSearch } from '../redux/selectors/route-selector';
import { getCurrentLabParentId, getResourceGroups, isLoadingLabParents, } from '../redux/selectors/lab-parent-resource-selectors';
export const getWelcomeViewModel = memoize((state) => {
    const search = getSearch(state);
    const isVNext = isVNextFeatureEnabledOrLms(state);
    const currentLabParentResourceId = getCurrentLabParentId(state);
    const labAccountStore = state.get('labAccountStore');
    const labAccounts = labAccountStore.get('labAccounts');
    if (!isVNext) {
        const loadState = labAccountStore.get('loadState');
        const isLoadingLabParentResources = storeIsLoading(loadState);
        return {
            search,
            isLoadingLabParentResources,
            labAccounts,
            currentLabParentResourceId,
        };
    }
    else {
        const resourceGroups = getResourceGroups(state);
        const isLoadingLabParentResources = isLoadingLabParents(state);
        return {
            search,
            isLoadingLabParentResources,
            labAccounts,
            currentLabParentResourceId,
            resourceGroups,
        };
    }
});
export const getWelcomeContainerModel = memoize((state) => {
    const welcomeViewModel = getWelcomeViewModel(state);
    return {
        welcomeViewModel,
    };
});
