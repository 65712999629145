import { call, put, delay, race, take, cancelled, cancel, fork, select, all } from 'redux-saga/effects';
import { EnvironmentActionType, } from '../../actions/environment/environment-actions';
import EnvironmentProvider from '../../../data/providers/environment-provider';
import { listEnvironmentsSuccess, listEnvironmentsError, listEnvironments as listEnvironmentsActionCreator, pollEnvironmentsCancelled, listEnvironmentsCancelled, listVNextVirtualMachinesSuccess, } from '../../actions/environment/environment-action-creators';
import { GetOperationsResult, operationsResultCancelled, operationsResultError, operationsResultSuccess, } from '../../actions/operations/operations-result-action-creators';
import { ResourceId } from '../../../utils/resource-id';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import VirtualMachineProvider from '../../../data/providers/vnext/arm-api/virtual-machine-provider';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { OperationsResultActionType, } from '../../../redux/actions/operations/operations-result-actions';
import { getOperationsInProgress } from '../../../redux/selectors/vnext/operations-selector';
import { FiveSecondInterval } from '../../../utils/constants';
export function* listEnvironments(action) {
    const { id: templateId } = action;
    try {
        const resourceId = new ResourceId(templateId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { environments, changed } = yield race({
            environments: call(EnvironmentProvider.listEnvironments, resourceId, accessToken, locale, language),
            changed: race([
                take(EnvironmentActionType.SELECT_LAB_PARENT_RESOURCE),
                take(EnvironmentActionType.SELECT_LAB),
                take(EnvironmentActionType.POLL_ENVIRONMENTS_CANCELLED),
                take(EnvironmentActionType.POLL_ENVIRONMENTS_STOP),
                take(EnvironmentActionType.START_ENVIRONMENTS),
                take(EnvironmentActionType.STOP_ENVIRONMENTS),
                take(EnvironmentActionType.RESET_ENVIRONMENTS),
            ]),
        });
        if (changed) {
            yield put(listEnvironmentsCancelled());
        }
        else {
            yield put(listEnvironmentsSuccess(environments));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, templateId, FailureOperation.LoadEnvironments, e ? e.code : undefined);
        yield put(listEnvironmentsError(error));
    }
}
export async function callGetOperationResult(operation, armAccessToken, labsApiAccessToken, locale, language, isUserEnvironment) {
    try {
        let operationResult = null;
        if (isUserEnvironment) {
            operationResult = await VirtualMachineProvider.getOperationResultLabsApi(operation, labsApiAccessToken, locale, language);
        }
        else {
            operationResult = await VirtualMachineProvider.getOperationResultARM(operation, armAccessToken, locale, language);
        }
        return operationResult;
    }
    catch (e) {
        throw e;
    }
}
export function* getOperationsResult(action) {
    const { labId } = action;
    const armAccessToken = yield call(getArmAccessTokenSaga);
    const locale = yield select(getLocale);
    const language = yield select(getLanguage);
    const pollingInterval = FiveSecondInterval;
    try {
        while (true) {
            yield delay(pollingInterval);
            const operations = yield select(getOperationsInProgress);
            if (operations.length <= 0) {
                yield put(operationsResultCancelled());
            }
            const { operationsResult, cancel } = yield race({
                operationsResult: yield all(operations.map((operation) => call(callGetOperationResult, operation, armAccessToken, "", locale, language, false))),
                cancel: take(OperationsResultActionType.POLL_OPERATIONS_RESULT_CANCELLED),
            });
            if (operationsResult) {
                yield put(operationsResultSuccess(operationsResult));
            }
            if (cancel) {
                yield put(operationsResultCancelled());
            }
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.GetOperationsResultFailure, e ? e.code : undefined);
        yield put(operationsResultError(error));
    }
    finally {
        if (yield cancelled()) {
            yield put(operationsResultCancelled());
        }
    }
}
export function* listVNextVirtualMachines(action) {
    const { id: labId } = action;
    try {
        const resourceId = new ResourceId(labId, true);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { virtualMachines, changed } = yield race({
            virtualMachines: call(VirtualMachineProvider.listVirtualMachines, resourceId, accessToken, locale, language),
            changed: race([
                take(EnvironmentActionType.SELECT_LAB_PARENT_RESOURCE),
                take(EnvironmentActionType.SELECT_LAB),
                take(EnvironmentActionType.POLL_ENVIRONMENTS_CANCELLED),
                take(EnvironmentActionType.POLL_ENVIRONMENTS_STOP),
                take(EnvironmentActionType.START_ENVIRONMENTS),
                take(EnvironmentActionType.STOP_ENVIRONMENTS),
                take(EnvironmentActionType.RESET_ENVIRONMENTS),
            ]),
        });
        if (changed) {
            yield put(listEnvironmentsCancelled());
        }
        else {
            yield put(listVNextVirtualMachinesSuccess(virtualMachines));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.LoadEnvironments, e ? e.code : undefined);
        yield put(listEnvironmentsError(error));
    }
}
function* pollEnvironments(action) {
    try {
        // allowing for an initial delay before we kick off polling
        // to mitigate a state flashing bug due to issues in the RP
        const { initialDelayMs, vmParentId } = action;
        if (!!initialDelayMs) {
            yield delay(initialDelayMs);
        }
        yield put(GetOperationsResult(vmParentId));
        // this looks like an infinite loop, but the finally block will get triggered when
        // the task that is running this is canceled, which happens when the POLL_ENVIRONMENTS_STOP
        // action is dispatched
        while (true) {
            yield put(listEnvironmentsActionCreator(vmParentId));
            yield race({
                success: take(EnvironmentActionType.LIST_ENVIRONMENTS_SUCCESS),
                cancelled: take(EnvironmentActionType.LIST_ENVIRONMENTS_CANCELLED),
                error: take(EnvironmentActionType.LIST_ENVIRONMENTS_ERROR),
            });
            const { intervalMs } = action;
            yield delay(intervalMs);
        }
    }
    finally {
        if (yield cancelled()) {
            yield put(pollEnvironmentsCancelled());
        }
    }
}
export function* pollOperationsSaga() {
    while (true) {
        const action = yield take(OperationsResultActionType.POLL_OPERATIONS_RESULT);
        const pollOperationsTask = yield fork(getOperationsResult, action);
        yield take(OperationsResultActionType.POLL_OPERATIONS_RESULT_CANCELLED);
        yield cancel(pollOperationsTask);
    }
}
export function* pollEnvironmentsSaga() {
    // while this looks like an infinite loop, yield take(POLL_ENVIRONMENTS_START)
    // will pause this code until polling is started
    while (true) {
        const action = yield take(EnvironmentActionType.POLL_ENVIRONMENTS_START);
        // forking so the polling task runs without blocking
        const pollEnvironmentsTask = yield fork(pollEnvironments, action);
        // waits until the stop is triggered so we can cancel the polling task
        yield take(EnvironmentActionType.POLL_ENVIRONMENTS_STOP);
        yield cancel(pollEnvironmentsTask);
    }
}
export function* listEnvironmentsSaga() {
    yield labParentBasedTakeLatest(EnvironmentActionType.LIST_ENVIRONMENTS, listEnvironments, listVNextVirtualMachines);
}
