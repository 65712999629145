import { ScheduleActionType, } from './schedule-actions';
export const listSchedules = (environmentId) => ({
    type: ScheduleActionType.LIST_SCHEDULES,
    environmentId,
});
export const listVNextSchedules = (labId) => ({
    type: ScheduleActionType.LIST_SCHEDULES,
    labId,
});
export const listSchedulesSuccess = (schedules) => ({
    type: ScheduleActionType.LIST_SCHEDULES_SUCCESS,
    schedules,
});
export const listVNextSchedulesSuccess = (schedules) => ({
    type: ScheduleActionType.LIST_SCHEDULES_SUCCESS,
    schedules,
});
export const listSchedulesError = (error) => ({
    type: ScheduleActionType.LIST_SCHEDULES_ERROR,
    error,
});
export const deleteSchedule = (scheduleId) => ({
    type: ScheduleActionType.DELETE_SCHEDULE,
    scheduleId,
});
export const deleteScheduleSuccess = (scheduleId) => ({
    type: ScheduleActionType.DELETE_SCHEDULE_SUCCESS,
    scheduleId,
});
export const deleteScheduleError = (error) => ({
    type: ScheduleActionType.DELETE_SCHEDULE_ERROR,
    error,
});
export const createSchedule = (templateId, schedule) => ({
    type: ScheduleActionType.CREATE_SCHEDULE,
    templateId,
    schedule,
});
export const createVNextSchedule = (labId, schedule) => ({
    type: ScheduleActionType.CREATE_SCHEDULE,
    labId,
    schedule,
});
export const createScheduleSuccess = (schedule) => ({
    type: ScheduleActionType.CREATE_SCHEDULE_SUCCESS,
    schedule,
});
export const createVNextScheduleSuccess = (schedule) => ({
    type: ScheduleActionType.CREATE_SCHEDULE_SUCCESS,
    schedule,
});
export const createScheduleError = (error) => ({
    type: ScheduleActionType.CREATE_SCHEDULE_ERROR,
    error,
});
export const updateSchedule = (templateId, schedule) => ({
    type: ScheduleActionType.UPDATE_SCHEDULE,
    templateId,
    schedule,
});
export const updateVNextSchedule = (labId, schedule) => ({
    type: ScheduleActionType.UPDATE_SCHEDULE,
    labId,
    schedule,
});
export const updateScheduleSuccess = (schedule) => ({
    type: ScheduleActionType.UPDATE_SCHEDULE_SUCCESS,
    schedule,
});
export const updateVNextScheduleSuccess = (schedule) => ({
    type: ScheduleActionType.UPDATE_SCHEDULE_SUCCESS,
    schedule,
});
export const updateScheduleError = (error) => ({
    type: ScheduleActionType.UPDATE_SCHEDULE_ERROR,
    error,
});
export const clearLoadScheduleError = () => ({
    type: ScheduleActionType.CLEAR_LOAD_SCHEDULE_ERROR,
});
export const clearError = (id, errorIndex) => ({
    type: ScheduleActionType.CLEAR_SCHEDULE_ERROR,
    id,
    errorIndex,
});
const actions = {
    listSchedules,
    listVNextSchedules,
    listSchedulesSuccess,
    listVNextSchedulesSuccess,
    listSchedulesError,
    deleteSchedule,
    deleteScheduleSuccess,
    deleteScheduleError,
    createSchedule,
    createVNextSchedule,
    createScheduleSuccess,
    createVNextScheduleSuccess,
    createScheduleError,
    updateSchedule,
    updateVNextSchedule,
    updateScheduleSuccess,
    updateVNextScheduleSuccess,
    updateScheduleError,
    clearLoadScheduleError,
    clearError,
};
export default actions;
