import { Stack, Icon, Link, ProgressIndicator } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import commonMessages from '../language/common-messages';
export const TeamsGetStartedContent = (props) => {
    const { isRefreshing, showNoFoundLabAccountsMessage, refreshLabParents } = props;
    const SectionNumber = (numberMessage) => {
        return (<span style={{ fontSize: '32px', color: '#0078D7', marginLeft: '9px', marginRight: '5px' }}>
                {numberMessage}
            </span>);
    };
    const numberOne = SectionNumber(<FormattedMessage {...commonMessages.numberOne}/>);
    const numberTwo = SectionNumber(<FormattedMessage {...commonMessages.numberTwo}/>);
    return (<Stack tokens={{ childrenGap: '25px' }} styles={{ root: { paddingLeft: '10px', paddingRight: '10px' } }}>
            <Stack.Item styles={{ root: { fontWeight: 600, fontSize: '20px', paddingTop: '10px' } }}>
                <FormattedMessage id="TeamsGetStartedTitle" defaultMessage="Welcome to Azure Lab Services! Follow these steps to get started." description="Title for instructions to get started on Azure Lab Services."/>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: '34px' }}>
                    <Stack.Item>
                        <Icon iconName="teams-illustration-1"/>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack tokens={{ childrenGap: '8px' }}>
                            <Stack.Item styles={{ root: { fontSize: '18px', fontWeight: 'bold' } }}>
                                <FormattedMessage {...commonMessages.stepGetAnAzureSubscription} values={{ numberSpan: numberOne }}/>
                            </Stack.Item>
                            <Stack.Item styles={{ root: { paddingLeft: '10px' } }}>
                                <FormattedMessage id="CreateAzureAccountText" defaultMessage="Use your personal Microsoft account (MSA) or a Work or school account to create an Azure subscription." description="Details about getting an Azure subscription."/>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontal tokens={{ childrenGap: '20px' }}>
                    <Stack.Item>
                        <Icon iconName="teams-illustration-2"/>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack tokens={{ childrenGap: '8px' }}>
                            <Stack.Item styles={{ root: { fontSize: '18px', fontWeight: 'bold' } }}>
                                <FormattedMessage {...commonMessages.stepAddLabAccount} values={{ numberSpan: numberTwo }}/>
                            </Stack.Item>
                            <Stack.Item styles={{ root: { paddingLeft: '10px' } }}>
                                <FormattedMessage id="AddLabAccountText" defaultMessage="In the Azure Portal, <Link1>create a lab plan (preview)</Link1> or <Link2>create a lab account</Link2>" description="Details about creating a lab plan or a lab account." values={{
        Link1: (chunks) => (<Link href="https://aka.ms/azlabs/new-labplan" target="_blank">
                                                {chunks}
                                            </Link>),
        Link2: (chunks) => (<Link href="https://aka.ms/labaccount" target="_blank">
                                                {chunks}
                                            </Link>),
    }}/>
                            </Stack.Item>
                            <Stack.Item styles={{ root: { paddingLeft: '10px', paddingTop: '15px', fontWeight: 'bold' } }}>
                                <FormattedMessage id="ConfirmToRefreshLabAccountsText" defaultMessage="Once the setup steps are complete, <Link>confirm the container</Link> you want to use with this team." description="Confirmation to get the latest lab accounts or resource groups." values={{
        Link: (chunks) => (<Link onClick={isRefreshing ? undefined : refreshLabParents}>{chunks}</Link>),
    }}/>
                            </Stack.Item>
                            <Stack.Item styles={{ root: { paddingLeft: '10px' } }}>
                                {isRefreshing && <ProgressIndicator styles={{ root: { marginTop: '35px' } }}/>}
                                {showNoFoundLabAccountsMessage && (<div style={{ color: '#C4314B', marginTop: '25px' }}>
                                        <FormattedMessage id="NoLabAccountErrorMessgae" defaultMessage="No lab plan or lab account found. Please create one to continue." description="Error message shown to the users when no lab plans or lab accounts found after refreshing."/>
                                    </div>)}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>);
};
export default TeamsGetStartedContent;
