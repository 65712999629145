import { getMlClient } from './arm-data-provider';
import { ResourceId } from '../../utils/resource-id';
export async function listSharedImages(labAccountId, accessToken, locale, language) {
    const id = labAccountId instanceof ResourceId ? labAccountId : new ResourceId(labAccountId);
    const client = getMlClient(id.subscriptionId, accessToken, locale, language);
    return await client.sharedImages.list(id.resourceGroupName, id.name);
}
export async function getSharedGallery(labAccountId, accessToken, locale, language) {
    const id = labAccountId instanceof ResourceId ? labAccountId : new ResourceId(labAccountId);
    const client = getMlClient(id.subscriptionId, accessToken, locale, language);
    return await client.sharedGalleries.list(id.resourceGroupName, id.name);
}
export async function createSharedImage(templateId, displayName, accessToken, locale, language) {
    const id = new ResourceId(templateId);
    const client = getMlClient(id.subscriptionId, accessToken, locale, language);
    return await client.environmentSettings.beginSaveImage(id.resourceGroupName, id.parent.parent.name, id.parent.name, id.name, { displayName });
}
export async function updateSharedImage(sharedImageId, templateId, accessToken, locale, language) {
    const sharedImageResourceId = new ResourceId(sharedImageId);
    const templateResourceId = new ResourceId(templateId);
    const client = getMlClient(sharedImageResourceId.subscriptionId, accessToken, locale, language);
    return await client.environmentSettings.beginSaveImage(templateResourceId.resourceGroupName, templateResourceId.parent.parent.name, templateResourceId.parent.name, templateResourceId.name, { sharedImageResourceId: sharedImageResourceId.id });
}
const SharedImageProvider = {
    listSharedImages,
    createSharedImage,
    updateSharedImage,
    getSharedGallery,
};
export default SharedImageProvider;
