import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { SafeSpinButton } from '../common/safe-spin-button';
import { SubmitButton } from '../common/submit-button';
import { getCustomTheme } from '../common/themes/selectors';
import commonMessages from '../language/common-messages';
import { determineVCurrentOrVNextLabRemainingVms } from '../redux/selectors/core-limit-selectors';
import { usePrevious } from '../utils/common';
import Constants from '../utils/constants';
import CoresRemainingMessage from './cores-remaining-message';
import { isVNextLab } from '../redux/selectors/lab-selectors';
const messages = defineMessages({
    title: {
        id: 'VmListCapacityFlyoutTitle',
        defaultMessage: 'Lab capacity',
        description: 'Title of flyout dialog for editing lab capacity number.',
    },
    label: {
        id: 'VmListCapacityLabel',
        defaultMessage: 'Maximum machines in lab',
        description: 'Label for number of machines text box (integer) on lab capacity flyout dialog.',
    },
});
export const CapacityFlyout = (props) => {
    const intl = useIntl();
    const { isGpu, coresPerVm, coreQuotaData, coreQuotaStatus, currentTenantId, labId, minCapacity, capacity: initialCapacity, isSubmitting, hasBeenPublished, onSubmit, onDismiss, } = props;
    let { maxCapacity } = props;
    // close the dialog after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    const msg = intl.formatMessage;
    const isVNext = isVNextLab(labId);
    const remainingVmsInLab = determineVCurrentOrVNextLabRemainingVms(isVNext, isGpu, coresPerVm, coreQuotaData, initialCapacity);
    if (remainingVmsInLab !== undefined) {
        const maxCapacityOfLab = hasBeenPublished && !isVNext ? remainingVmsInLab + initialCapacity : remainingVmsInLab;
        maxCapacity = Math.min(maxCapacity, maxCapacityOfLab);
    }
    return (<Form initialValues={{ capacity: initialCapacity }} onSubmit={(values) => onSubmit(values.capacity)}>
            {(formProps) => (<Panel type={PanelType.custom} customWidth={`${Constants.DefaultFlyoutWidth}px`} isOpen={true} isLightDismiss={!isSubmitting} onDismiss={isSubmitting ? undefined : onDismiss} headerText={msg(messages.title)} closeButtonAriaLabel={msg(commonMessages.cancel)} styles={getCustomTheme().panelStyles} onRenderFooterContent={() => (<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '8px' }}>
                            <Stack.Item>
                                <SubmitButton default={true} disabled={formProps.pristine || !formProps.valid} isSubmitting={isSubmitting} buttonText={msg(commonMessages.save)} onClick={() => formProps.handleSubmit()}/>
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton disabled={isSubmitting} onClick={onDismiss}>
                                    {msg(commonMessages.cancel)}
                                </DefaultButton>
                            </Stack.Item>
                        </Stack>)}>
                    <div style={{ marginTop: 10, marginBottom: 30 }}>
                        <FormattedMessage id="VmListCapacityFlyoutDescription" defaultMessage="Capacity determines the maximum number of machines in the lab. It should be at least the number of users registered in the lab." description="Description in lab capacity flyout explaining what capacity is."/>
                    </div>
                    <CoresRemainingMessage currentTenantId={currentTenantId} labId={labId} capacity={initialCapacity} quotaStatus={coreQuotaStatus} hasBeenPublished={hasBeenPublished} remainingVmsInLab={remainingVmsInLab} maxCapacity={maxCapacity} formCapacity={formProps.values.capacity}/>
                    <Field name="capacity">
                        {(fieldProps) => (<SafeSpinButton autoFocus label={msg(messages.label)} value={fieldProps.input.value} onChange={fieldProps.input.onChange} disabled={isSubmitting} min={minCapacity} max={maxCapacity}/>)}
                    </Field>
                </Panel>)}
        </Form>);
};
export default CapacityFlyout;
