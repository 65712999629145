import { Callout, CommandBar, DirectionalHint, Icon, Link, MessageBar, MessageBarType, } from '@fluentui/react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import 'moment/min/locales';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ManagedLabsModels } from '@azure-lab-services/ml-ts';
import { LabServicesModels } from 'lab-services';
import InfoSection from '../common/info-section';
import { getNavigationItems } from '../common/lab-nav-items';
import LoadingContainer from '../common/loading-section';
import { getCustomTheme } from '../common/themes/selectors';
import FullPageMessage from '../full-page-message/full-page-message';
import commonMessages from '../language/common-messages';
import LabNavKeys from '../utils/lab-nav-key';
import Routes from '../utils/routes';
import { getEvents, updateDateWindow } from './calendar-utilities';
import { Flyout, ScheduleType } from './flyout';
import { estimatedClassLength, firstDayOfWeek, getDefaultVNextWeeklySchedule, getDefaultWeeklySchedule, } from './flyout-utilities';
import { ScheduleDeleteDialog } from './schedule-delete-dialog';
import { getErrorMessage } from './schedule-error-handling';
import scheduleMessages from './schedule-messages';
import './calendar.css';
import './schedule.css';
export const ScheduleErrorBanner = (props) => {
    const intl = useIntl();
    return (<>
            {props.error && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)} onDismiss={() => props.clearError(props.error)}>
                    {getErrorMessage(intl, props.error)}
                </MessageBar>)}
        </>);
};
export const FlyoutMode = {
    add: 'add',
    edit: 'edit',
};
export const Schedules = (props) => {
    const { scheduleViewModel, createSchedule, updateSchedule, deleteSchedule, navigateRoute } = props;
    const { labId, isCurrentLabParentLabAccount, isTeamsIntegrationEnabled, idleConfig, locale, errors, timezone, isLoading, isTemplateCreating, showLoadError, schedules, isSaving, isReadOnly, showCalendarImmediately, isTeamsOrLmsIntegrationEnabled, shouldDisableScheduleUpdate, } = scheduleViewModel;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const [startWindow, setStartWindow] = React.useState(new Date());
    const [endWindow, setEndWindow] = React.useState(new Date());
    const [isPanelOpen, setIsPanelOpen] = React.useState(false);
    const [isCalloutOpen, setIsCalloutOpen] = React.useState(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = React.useState(false);
    const [mouseEvent, setMouseEvent] = React.useState(undefined);
    const [flyoutScheduleTarget, setFlyoutScheduleTarget] = React.useState(undefined);
    const [deleteScheduleTarget, setDeleteScheduleTarget] = React.useState(undefined);
    const [calloutScheduleTarget, setCalloutScheduleTarget] = React.useState(undefined);
    const [timesInCurrentZone, setTimesInCurrentZone] = React.useState({ start: undefined, end: undefined });
    const [flyoutMode, setFlyoutMode] = React.useState(FlyoutMode.add);
    const [lastDateClick, setLastDateClick] = React.useState(new Date());
    const { primaryBackgroundColor, pageTitleStyle, primaryCommandBarStyles, messageBarStyles } = getCustomTheme();
    // these are used to set a dynamic fixed height for the calendar so the scrollbar
    // works correctly and we don't need multiple styles for different container heights based
    // on message bars and other changes
    const bodyDiv = React.useRef();
    const [bodyHeight, setBodyHeight] = React.useState(undefined);
    React.useEffect(() => {
        if (bodyDiv &&
            bodyDiv.current &&
            bodyDiv.current.clientHeight !== 0 &&
            bodyDiv.current.clientHeight !== bodyHeight) {
            setBodyHeight(bodyDiv.current.clientHeight);
        }
    });
    // Set scrollable region to be focusable per acccesibility requirements
    // https://dev.azure.com/devdiv/OnlineServices/_workitems/edit/1278634/
    React.useEffect(() => {
        const items = document.getElementsByClassName('fc-scroller-liquid-absolute');
        if (items && items.length > 0) {
            const scrollableRegion = items[0];
            scrollableRegion.setAttribute('tabindex', '0');
        }
    });
    const onFlyoutOpen = (schedule, mode = FlyoutMode.add) => {
        setFlyoutScheduleTarget(schedule);
        setFlyoutMode(mode);
        setIsPanelOpen(true);
    };
    const onFlyoutClose = () => {
        setIsPanelOpen(false);
        setFlyoutMode(FlyoutMode.add);
        setFlyoutScheduleTarget(undefined);
    };
    const onEditClicked = (schedule) => {
        onFlyoutOpen(schedule, FlyoutMode.edit);
    };
    const onOpenDeleteDialog = (schedule) => {
        setDeleteScheduleTarget(schedule);
        setIsDeleteDialogOpen(true);
    };
    const onCloseDeleteDialog = () => {
        setIsDeleteDialogOpen(false);
        setDeleteScheduleTarget(undefined);
    };
    const onOpenCallout = (schedule, mouseEvent, start, end) => {
        setTimesInCurrentZone({ start, end });
        setCalloutScheduleTarget(schedule);
        setMouseEvent(mouseEvent);
        setIsCalloutOpen(true);
    };
    const onCloseCallout = () => {
        setIsCalloutOpen(false);
        setMouseEvent(undefined);
        setTimesInCurrentZone({ start: undefined, end: undefined });
        setCalloutScheduleTarget(undefined);
    };
    const onClickSettingsLink = () => {
        navigateRoute(Routes.Settings(labId));
    };
    let navigationItems = undefined;
    if (isTeamsOrLmsIntegrationEnabled) {
        navigationItems = getNavigationItems(intl, labId, LabNavKeys.Schedule, navigateRoute);
    }
    if (showLoadError) {
        return (<div id="schedule-container" style={{ backgroundColor: primaryBackgroundColor }}>
                <div id="schedule-content">
                    {navigationItems && (<div id="schedule-header">
                            <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                        </div>)}
                    <FullPageMessage image="error-general" message={<FormattedMessage id="ScheduleLoadError" defaultMessage="Cannot load lab schedules" description="Error shown on user schedule  page when it fails to load necessary data from the server."/>} messageDetails={<FormattedMessage id="ScheduleLoadErrorDetails" defaultMessage="Your lab does not appear to be in a state to view or edit schedules at this time. Please try again later or contact your Lab Services administator for assistance." description="Error shown on user schedule  page when it fails to load necessary data from the server."/>}/>
                </div>
            </div>);
    }
    if (isLoading) {
        return (<div id="schedule-container" style={{ backgroundColor: primaryBackgroundColor }}>
                <div id="schedule-content">
                    {navigationItems && (<div id="schedule-header">
                            <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                        </div>)}
                    <LoadingContainer />
                </div>
            </div>);
    }
    if (isTemplateCreating) {
        return (<div id="schedule-container" style={{ backgroundColor: primaryBackgroundColor }}>
                <div id="schedule-content">
                    {navigationItems && (<div id="schedule-header">
                            <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                        </div>)}
                    <FullPageMessage image="error-general" message={<FormattedMessage id="TemplateCreatingMessage" defaultMessage="Cannot load lab schedules" description="Message shown on user schedule page when template is still creating."/>} messageDetails={<FormattedMessage id="TemplateCreatingDescription" defaultMessage="Your template is still creating. Please wait until template creation is finished to manage schedules." description="Description shown on user schedule page when template is still creating."/>}/>
                </div>
            </div>);
    }
    const headerContent = (<>
            {errors.size > 0 &&
        errors
            .map((errors) => {
            return errors.map((error, errorIndex) => (<ScheduleErrorBanner key={`${error.id}-${error.failureOperation}`} error={error} clearError={() => props.clearError(error.id, errorIndex)}/>));
        })
            .toList()}
            {idleConfig.shutdownWhenNotConnected && (<MessageBar messageBarType={MessageBarType.info} styles={messageBarStyles}>
                    <FormattedMessage id="ScheduleShutdownOnNoConnectMessage" defaultMessage="Based on your <Link>auto-shutdown settings</Link>, virtual machines will automatically shut down when users don't connect within {gracePeriod} minutes of the scheduled event starting." description="Message shown on the Schedules page when Shutdown on No Connect is enabled on the lab. <Link> and </Link> should not be localized, but text between them should. {gracePeriod} is the number of minutes (integer)." values={{
        gracePeriod: idleConfig.shutdownOnNoConnectGracePeriod,
        Link: (chunks) => <Link onClick={onClickSettingsLink}>{chunks}</Link>,
    }}/>
                </MessageBar>)}
            <CommandBar styles={primaryCommandBarStyles} items={isReadOnly
        ? []
        : [
            {
                key: 'addEvent',
                text: msg(scheduleMessages.addEvent),
                iconProps: { iconName: 'Add' },
                onClick: () => onFlyoutOpen(),
                disabled: !!shouldDisableScheduleUpdate,
            },
        ]} farItems={navigationItems}/>
            <h1 style={pageTitleStyle}>
                <FormattedMessage {...commonMessages.schedule}/>
            </h1>
        </>);
    return (<>
            <div id="schedule-container" style={{ backgroundColor: primaryBackgroundColor }}>
                <div id="schedule-content">
                    <div id="schedule-header">{headerContent}</div>
                    <div id="schedule-body" ref={bodyDiv} className={'dynamic-fixed-size-no-scroll'}>
                        
                        {(showCalendarImmediately || bodyHeight !== undefined) && (<div className={isTeamsIntegrationEnabled ? 'schedule__teams-calendar' : undefined}>
                                <FullCalendar initialView="timeGridWeek" plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} dateClick={isReadOnly || !!shouldDisableScheduleUpdate
        ? undefined
        : (dateClickInfo) => {
            const thisClick = new Date();
            const delay = getDelayOfLastClick(thisClick, lastDateClick);
            setLastDateClick(thisClick);
            if (delay < 300) {
                const newSchedule = getScheduleFromDateClick(dateClickInfo.date, timezone, isCurrentLabParentLabAccount);
                onFlyoutOpen(newSchedule);
            }
        }} eventDidMount={(info) => {
        info.el.setAttribute('tabIndex', '0');
    }} slotEventOverlap={false} dayHeaderFormat={{ day: 'numeric', weekday: 'long' }} eventMinHeight={32} displayEventTime={false} firstDay={firstDayOfWeek(locale)} buttonText={{ today: msg(scheduleMessages.todayLabel) }} locale={locale} scrollTime={'08:00:00'} slotLabelFormat={{ hour: 'numeric', meridiem: 'short' }} titleFormat={{ year: 'numeric', month: 'long', day: 'numeric' }} allDaySlot={false} eventClick={(click) => {
        const start = click.event.extendedProps && click.event.extendedProps.start
            ? click.event.extendedProps.start
            : click.event.start;
        const end = click.event.extendedProps && click.event.extendedProps.end
            ? click.event.extendedProps.end
            : click.event.end;
        const scheduleIndex = schedules.findIndex((schedule) => schedule.id.toLowerCase() === click.event.id.toLowerCase());
        onOpenCallout(schedules[scheduleIndex], click.jsEvent, start, end);
    }} windowResize={() => {
        if (bodyDiv && bodyDiv.current && bodyDiv.current.clientHeight !== bodyHeight) {
            setBodyHeight(bodyDiv.current.clientHeight);
        }
    }} height={bodyHeight === undefined ? 'parent' : bodyHeight - 34} datesSet={(info) => updateDateWindow(startWindow, endWindow, info, setStartWindow, setEndWindow)} events={getEvents(isCurrentLabParentLabAccount, locale, intl, schedules, startWindow, endWindow)}/>
                            </div>)}
                    </div>
                </div>
            </div>
            <Flyout mode={flyoutMode} isCurrentLabParentLabAccount={isCurrentLabParentLabAccount} locale={locale} schedule={flyoutScheduleTarget} scheduleType={getScheduleType(flyoutScheduleTarget, flyoutMode, isCurrentLabParentLabAccount)} timezone={timezone} isPanelOpen={isPanelOpen} onClose={onFlyoutClose} createSchedule={createSchedule} updateSchedule={updateSchedule} isSaving={isSaving}/>
            {isDeleteDialogOpen && (<ScheduleDeleteDialog isSaving={isSaving} schedule={deleteScheduleTarget} onDismiss={onCloseDeleteDialog} onSubmit={deleteSchedule}/>)}
            {isCalloutOpen && (<ScheduleCallout isCurrentLabParentLabAccount={isCurrentLabParentLabAccount} locale={locale} schedule={calloutScheduleTarget} timesInCurrentZone={timesInCurrentZone} mouseEvent={mouseEvent} isReadOnly={isReadOnly} shouldDisableScheduleUpdate={shouldDisableScheduleUpdate} onClose={onCloseCallout} onEditClicked={onEditClicked} onDeleteClicked={onOpenDeleteDialog}/>)}
        </>);
};
export const isEndTimeSameDay = (date) => {
    const end = new Date(date);
    end.setHours(date.getHours() + estimatedClassLength);
    return moment(date).isSame(moment(end), 'day');
};
export const getScheduleFromDateClick = (date, timezone, isCurrentLabParentLabAccount) => {
    const day = moment().day(date.getDay()).format('dddd');
    if (isCurrentLabParentLabAccount) {
        const schedule = getDefaultWeeklySchedule(timezone);
        schedule.recurrencePattern.weekDays = [day];
        schedule.start = new Date(date);
        schedule.end = new Date(date);
        schedule.start.setHours(date.getHours());
        schedule.recurrencePattern.until = moment(schedule.start).add(4, 'months').toDate();
        const isSameDay = isEndTimeSameDay(date);
        if (!isSameDay) {
            schedule.end.setHours(23, 59);
        }
        else {
            schedule.end.setHours(date.getHours() + estimatedClassLength);
        }
        return schedule;
    }
    else {
        const schedule = getDefaultVNextWeeklySchedule(timezone);
        schedule.recurrencePattern.weekDays = [day];
        schedule.startAt = new Date(date);
        schedule.stopAt = new Date(date);
        schedule.startAt.setHours(date.getHours());
        schedule.recurrencePattern.expirationDate = moment(schedule.startAt).add(4, 'months').toDate();
        const isSameDay = isEndTimeSameDay(date);
        if (!isSameDay) {
            schedule.stopAt.setHours(23, 59);
        }
        else {
            schedule.stopAt.setHours(date.getHours() + estimatedClassLength);
        }
        return schedule;
    }
};
const getDelayOfLastClick = (thisClick, lastClick) => {
    const before = moment(lastClick);
    const now = moment(thisClick);
    const duration = moment.duration(now.diff(before));
    return duration.asMilliseconds();
};
const getScheduleType = (schedule, flyoutMode, isCurrentLabParentLabAccount) => {
    let scheduleType = ScheduleType.standard;
    if (flyoutMode === FlyoutMode.edit && schedule) {
        if (isCurrentLabParentLabAccount) {
            if (schedule.endAction.enableState === ManagedLabsModels.EnableState.Disabled) {
                scheduleType = ScheduleType.startOnly;
            }
            else if (schedule.startAction.enableState === ManagedLabsModels.EnableState.Disabled) {
                scheduleType = ScheduleType.stopOnly;
            }
        }
        else {
            if (!schedule.startAt) {
                scheduleType = ScheduleType.stopOnly;
            }
        }
    }
    return scheduleType;
};
const DividerLine = () => {
    return <div style={{ height: '1px' }} className="ms-bgColor-neutralLight"/>;
};
export const ScheduleCallout = (props) => {
    const { isCurrentLabParentLabAccount, locale, schedule, mouseEvent, timesInCurrentZone, isReadOnly, shouldDisableScheduleUpdate, onClose, onEditClicked, onDeleteClicked, } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const isLocked = schedule.provisioningState === LabServicesModels.ProvisioningState.Locked;
    let isStartEnabled = false;
    let isEndEnabled = false;
    let recurrenceStart = undefined;
    let recurrenceEnd = undefined;
    if (isCurrentLabParentLabAccount) {
        const sched = schedule;
        if (sched.startAction?.enableState === ManagedLabsModels.EnableState.Enabled) {
            isStartEnabled = true;
        }
        if (sched.endAction?.enableState === ManagedLabsModels.EnableState.Enabled) {
            isEndEnabled = true;
        }
        recurrenceStart = sched.start;
        if (sched.recurrencePattern) {
            recurrenceEnd = sched.recurrencePattern.until;
        }
    }
    else {
        isEndEnabled = true; // start-only schedules are not permitted in vnext
        const sched = schedule;
        if (sched.startAt !== undefined) {
            isStartEnabled = true;
        }
        recurrenceStart = sched.startAt;
        if (sched.recurrencePattern) {
            recurrenceEnd = sched.recurrencePattern.expirationDate;
        }
    }
    return (<Callout styles={{
        root: {
            overflowWrap: 'break-word',
            wordBreak: 'break-all',
            width: '300px',
            padding: '17px 28px 17px 28px',
        },
    }} className="schedules__event-callout" target={mouseEvent} onDismiss={onClose} isBeakVisible={true} directionalHint={DirectionalHint.rightCenter}>
            <div style={{ fontSize: '20px', fontWeight: 500, paddingBottom: '25px' }}>
                {isStartEnabled &&
        timesInCurrentZone.start &&
        msg(scheduleMessages.startWithTimeLabel, {
            startTime: moment(timesInCurrentZone.start).locale(locale).format('LT'),
        })}
                {isEndEnabled && timesInCurrentZone.end && (<div>
                        {msg(scheduleMessages.stopWithTimeLabel, {
        stopTime: moment(timesInCurrentZone.end).locale(locale).format('LT'),
    })}
                    </div>)}
            </div>
            <div style={{ paddingBottom: '25px' }}>
                {schedule.recurrencePattern && <Icon iconName="Sync" styles={{ root: { paddingRight: '10px' } }}/>}
                <span>{moment(recurrenceStart).locale(locale).format('ddd l')}</span>
                {schedule.recurrencePattern && recurrenceEnd && (<span> - {moment(recurrenceEnd).locale(locale).format('ddd l')}</span>)}
                {schedule.recurrencePattern && !recurrenceEnd && (<span>
                        {' '}
                        -{' '}
                        <FormattedMessage id="NoEndDateLabel" defaultMessage="no end date" description="Label indicating the schedule has no end date"/>
                    </span>)}
            </div>
            {schedule.notes && <div className="schedules__event-callout--notes">{schedule.notes}</div>}
            {!isReadOnly && !isLocked && (<>
                    <DividerLine />
                    <CommandBar styles={{ root: { backgroundColor: 'transparent', paddingLeft: '0px', paddingTop: '10px' } }} items={[
        {
            key: 'Edit',
            ariaLabel: msg(commonMessages.edit),
            title: msg(commonMessages.edit),
            iconOnly: true,
            iconProps: {
                iconName: 'Edit',
                styles: {
                    root: {
                        fontSize: '18px',
                    },
                },
            },
            disabled: !!shouldDisableScheduleUpdate,
            onClick: () => {
                onEditClicked(schedule);
                onClose();
            },
        },
        {
            key: 'Delete',
            ariaLabel: msg(commonMessages.delete),
            title: msg(commonMessages.delete),
            iconOnly: true,
            iconProps: {
                iconName: 'Delete',
            },
            onClick: () => {
                onDeleteClicked(schedule);
                onClose();
            },
        },
    ]}/>
                </>)}
            {isLocked && (<>
                    <DividerLine />
                    <InfoSection>
                        <div style={{ wordBreak: 'break-word' }}>
                            <FormattedMessage id="EditNotAllowedWhenQueuedOrRunning" defaultMessage="This event is currently queued to run or running, so it cannot be edited." description="Message shown when editing or deleting schedule is not allowed because it is queued to run or currently running."/>
                        </div>
                    </InfoSection>
                </>)}
        </Callout>);
};
export default Schedules;
