import * as React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DialogType } from '@fluentui/react';
import { defineMessages, useIntl } from 'react-intl';
import Constants from '../../utils/constants';
import commonMessages from '../../language/common-messages';
import './troubleshoot-dialog.css';
const messages = defineMessages({
    troubleshootDialogButtonText: {
        id: 'TroubleshootDialogTitle',
        defaultMessage: 'Redeploy virtual machine',
        description: 'Redeploy dialog title',
    },
    troubleshootDialogContent: {
        id: 'TroubleshootDialogContent',
        defaultMessage: 'If you are facing difficulties starting or accessing your virtual machine, redeploying the virtual machine may provide a quick resolution for the issue. Redeploying the virtual machine will shut it down, move it to a new Azure host, and restart it. All your data, saved in the operating system disk, will remain on the machine. Anything saved on the temporary disk will be lost. ',
        description: 'Troubleshoot dialog content',
    },
});
const TroubleshootDialog = (props) => {
    const intl = useIntl();
    const { onDismiss, onRedeploy } = props;
    const troubleshootDialogButtonText = intl.formatMessage(messages.troubleshootDialogButtonText);
    const troubleshootDialogContent = intl.formatMessage(messages.troubleshootDialogContent);
    const title = troubleshootDialogButtonText;
    const subText = troubleshootDialogContent;
    const dismiss = () => {
        onDismiss();
    };
    return (<Dialog hidden={false} onDismiss={dismiss} dialogContentProps={{
        showCloseButton: true,
        type: DialogType.normal,
        title,
        subText,
        styles: {
            subText: { fontSize: '14px', fontWeight: 'normal', lineHeight: '20px' },
        },
    }} modalProps={{
        containerClassName: 'troubleshoot-dialog',
    }} minWidth={Constants.DefaultDialogSmallWidth}>
            <DialogFooter>
                <PrimaryButton onClick={() => {
        onRedeploy();
        onDismiss();
    }} text={intl.formatMessage(commonMessages.redeploy)} ariaLabel={'redeploy'} disabled={false}/>
            </DialogFooter>
        </Dialog>);
};
export default TroubleshootDialog;
