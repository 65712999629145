import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import FullPageMessage from '../full-page-message/full-page-message';
import { ProvisioningState } from '../utils/provisioning-state';
export const TemplateErrorPage = (props) => {
    const { template } = props;
    let messageDetails = <></>;
    if (!template) {
        messageDetails = (<FormattedMessage id="TemplateLoadErrorDetails" defaultMessage="A template has not been created for this lab. Please try to create a new lab or contact your Lab Services administator for assistance." description="Error shown on template page when there is no template created for the lab."/>);
    }
    else if (template.provisioningState === ProvisioningState.Deleting) {
        messageDetails = (<FormattedMessage id="TemplateDeletingErrorDetails" defaultMessage="Your template is deleting. Please try to create a new lab or contact your Lab Services administator for assistance." description="Error shown on template page when the template is deleting."/>);
    }
    else if (template.provisioningState === ProvisioningState.Failed) {
        messageDetails = (<FormattedMessage id="TemplateLoadFailedErrorDetails" defaultMessage="Your template is in a failed state. Please try to create a new lab or contact your Lab Services administator for assistance." description="Error shown on template page when the template is in a failed provisioning state."/>);
    }
    return (<FullPageMessage image="error-general" message={<FormattedMessage id="TemplateLoadError" defaultMessage="Cannot load template" description="Error shown on template page when the template is in a failed state."/>} messageDetails={messageDetails}/>);
};
