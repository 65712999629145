import { isVNextFeatureEnabledOrLms } from '../selectors/common-selectors';
import { Context } from '../action-context';
import { CALL_HISTORY_METHOD, LOCATION_CHANGE } from 'connected-react-router/immutable';
import { getCurrentLabParentId } from '../selectors/lab-parent-resource-selectors';
import { isCurrentLabParentResourceGroup } from '../selectors/lab-parent-resource-selectors';
import { UserEnvironmentActionType } from '../actions/user-environment/user-environment-actions';
import { isValidResourceId } from '../../utils/resource-id';
const environmentIdBasedActions = [
    UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT,
    UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT_FAILED,
    UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT,
    UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT_FAILED,
    UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD,
    UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD_FAILED,
    UserEnvironmentActionType.CLEAR_START_USER_ENVIRONMENT_ERROR,
    UserEnvironmentActionType.CLEAR_STOP_USER_ENVIRONMENT_ERROR,
    UserEnvironmentActionType.CLEAR_RESET_USER_ENVIRONMENT_PASSWORD_ERROR,
];
const featureFlagOnlyBasedActions = [
    UserEnvironmentActionType.LIST_ALL_USER_VIRTUAL_MACHINES_SUCCESS,
    UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS,
    UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED,
    UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_ERROR,
    UserEnvironmentActionType.CLEAR_LIST_GLOBAL_USER_ENVIRONMENTS_ERROR,
    UserEnvironmentActionType.CLEAR_GLOBAL_USER_ENVIRONMENT_ERRORS,
];
export const vNextMiddleware = ({ getState }) => (next) => (action) => {
    const state = getState();
    const isVNext = isVNextFeatureEnabledOrLms(state);
    const currentLabParentId = getCurrentLabParentId(state);
    const isResourceGroup = isCurrentLabParentResourceGroup(state);
    if (isVNext && action.type !== LOCATION_CHANGE && action.type !== CALL_HISTORY_METHOD) {
        if (environmentIdBasedActions.indexOf(action.type) >= 0) {
            const environmentId = action.environmentId;
            if (!isValidResourceId(environmentId)) {
                action.context = Context.VNext;
            }
        }
        else if (featureFlagOnlyBasedActions.indexOf(action.type) >= 0) {
            action.context = Context.VNext;
        }
        else if (!currentLabParentId || isResourceGroup) {
            action.context = Context.VNext;
        }
    }
    const result = next(action);
    return result;
};
export default vNextMiddleware;
