import { all, fork } from 'redux-saga/effects';
import { listSharedImagesSaga } from './list-shared-images';
import { createSharedImageSaga } from './create-shared-image';
import { updateSharedImageSaga } from './update-shared-image';
import { getSharedGallerySaga } from './get-shared-gallery';
export function* rootSaga() {
    yield all([
        fork(listSharedImagesSaga),
        fork(createSharedImageSaga),
        fork(updateSharedImageSaga),
        fork(getSharedGallerySaga),
    ]);
}
