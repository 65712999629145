import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { getLabListRoute } from '../data/helpers/route-helpers';
import { Routes } from '../utils/routes';
import { Loading } from './loading';
export const Welcome = (props) => {
    const { clearSelectedLabParent } = props;
    const { search, isLoadingLabParentResources, labAccounts, currentLabParentResourceId, resourceGroups, } = props.welcomeViewModel;
    if (isLoadingLabParentResources) {
        return <Loading />;
    }
    if (labAccounts.size > 0 || (!!resourceGroups && resourceGroups.size > 0)) {
        return <Redirect to={{ pathname: getLabListRoute(currentLabParentResourceId), search }}/>;
    }
    clearSelectedLabParent();
    return <Redirect to={{ pathname: Routes.StudentView, search }}/>;
};
export default Welcome;
