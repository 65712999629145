import { defineMessages } from 'react-intl';
import { FailureOperation } from '../data/ml-client-error';
import { ErrorCode } from '../common/error-codes';
import { ResourceId } from '../utils/resource-id';
import commonMessages from '../language/common-messages';
import { trackTrace } from '../utils/telemetry/telemetry-channel';
//import { CostEstimateBreakdown } from './../dashboard/cost-estimate-tile';
const messages = defineMessages({
    deletionErrorResourceNotFound: {
        id: 'DeletionErrorResourceNotFound',
        defaultMessage: `The lab ‘{labName}’ was unable to be deleted because it could not be found.`,
        description: 'Message to indicate that deletion of lab {labName} failed because it could not be found.',
    },
    deletionErrorResourceNotReady: {
        id: 'DeletionErrorResourceNotReady',
        defaultMessage: `The lab ‘{labName}’ was unable to be deleted because it is currently updating.`,
        description: 'Message to indicate that deletion of lab {labName} failed because it is not in the correct state.',
    },
    deletionErrorAuthorizationFailed: {
        id: 'DeletionErrorAuthorizationFailed',
        defaultMessage: `The lab ‘{labName}’ was unable to be deleted because you did not have permissions.`,
        description: 'Message to indicate that deletion of lab {labName} failed because user did not have permissions.',
    },
    deletionErrorTimeout: {
        id: 'DeletionErrorTimeout',
        defaultMessage: `The lab ‘{labName}’ was unable to be deleted because the request timed out.`,
        description: 'Message to indicate that deletion of lab {labName} failed because the request timed out.',
    },
    deleteErrorGeneric: {
        id: 'LabDeleteGenericError',
        defaultMessage: 'Failed to delete lab ‘{labName}’.',
        description: 'Generic error displayed when we fail to delete a lab and have no specific error code mapped. {labName} is the name of the lab.',
    },
    createErrorGeneric: {
        id: 'LabCreateGenericError',
        defaultMessage: 'Failed to create lab ‘{labName}’.',
        description: 'Generic error displayed when we fail to create a lab and have no specific error code mapped. {labName} is the name of the lab.',
    },
    createErrorWebAccesVnetNotSupported: {
        id: 'LabCreateWebAccessVnetNotSupportedError',
        defaultMessage: 'Failed to create lab ‘{labName}’. Lab plans with advanced networking enabled currently do not support browser access.',
        description: 'Error message displayed when customer tries to create a advanced networking lab with web access enabled.',
    },
    createErrorInvalidSubnet: {
        id: 'LabCreateInvalidSubnetError',
        defaultMessage: 'Failed to create lab ‘{labName}’. The subnet attached to lab plan cannot be used to create labs, please use another subnet that is not used by other Azure products.',
        description: 'Error message displayed when customer tries to create a advanced networking lab with an invalid subnet.',
    },
    loadErrorResourceNotFound: {
        id: 'LoadErrorResourceNotFound',
        defaultMessage: `Unable to load lab account ‘{labAccountName}’ because it could not be found.`,
        description: 'Message to indicate that loading the labs of lab account {labAccountName} failed because it could not be found.',
    },
    loadErrorTimeout: {
        id: 'LoadErrorTimeout',
        defaultMessage: `Unable to load lab account ‘{labAccountName}’ because the request timed out.`,
        description: 'Message to indicate that loading the labs of lab account {labAccountName} failed because the request timed out.',
    },
    loadErrorGeneric: {
        id: 'LabLoadGenericError',
        defaultMessage: 'Failed to load lab account ‘{labAccountName}’.',
        description: 'Generic error displayed when we fail to load data for a lab account and have no specific error code mapped. {labAccountName} is the name of the lab account.',
    },
});
export const getErrorMessage = (intl, error) => {
    switch (error.failureOperation) {
        case FailureOperation.DeleteLab:
            return getDeleteErrorMessage(intl, error);
        case FailureOperation.LoadLabs:
        case FailureOperation.GetLabAccount:
            return getLoadErrorMessage(intl, error);
        case FailureOperation.CreateLab:
            return getCreateErrorMessage(intl, error);
        default:
            return getFallbackErrorMessage(intl, error);
    }
};
const getCreateErrorMessage = (intl, error) => {
    const labName = new ResourceId(error.id).name;
    switch (error.code) {
        case ErrorCode.LabCreationExceedsGpuCoresLimit:
        case ErrorCode.LabCreationExceedsStandardCoresLimit:
        case ErrorCode.AllowedRegionsLimitExceeded: // V2 backend needs localization
            return error.message;
        case ErrorCode.WebAccessVnetNotSupported:
            return intl.formatMessage(messages.createErrorWebAccesVnetNotSupported, { labName });
        case ErrorCode.InvalidSubnet:
            return intl.formatMessage(messages.createErrorInvalidSubnet, { labName });
        default:
            const errorMessage = intl.formatMessage(messages.createErrorGeneric, { labName });
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getDeleteErrorMessage = (intl, error) => {
    const labName = new ResourceId(error.id).name;
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
            return intl.formatMessage(messages.deletionErrorResourceNotFound, { labName });
        case ErrorCode.ResourceNotReady:
            return intl.formatMessage(messages.deletionErrorResourceNotReady, { labName });
        case ErrorCode.AuthorizationFailed:
            return intl.formatMessage(messages.deletionErrorAuthorizationFailed, { labName });
        case ErrorCode.TimeoutException:
            return intl.formatMessage(messages.deletionErrorTimeout, { labName });
        default:
            const errorMessage = intl.formatMessage(messages.deleteErrorGeneric, { labName });
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getLoadErrorMessage = (intl, error) => {
    const labAccountName = new ResourceId(error.id).name;
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
            return intl.formatMessage(messages.loadErrorResourceNotFound, { labAccountName });
        case ErrorCode.TimeoutException:
            return intl.formatMessage(messages.loadErrorTimeout, { labAccountName });
        default:
            const errorMessage = intl.formatMessage(messages.loadErrorGeneric, { labAccountName });
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
export const getFallbackErrorMessage = (intl, error, genericMessageForOperation) => {
    // for cases where we haven't recorded a code and corresponding message in this client code yet
    trackTrace(`lab-list-error-handling: getFallbackErrorMessage - Unmapped error code: ${error ? error.code : 'Unknown'}`);
    // NOTE: Not showing the raw error for now. Perhaps we should let the banner expand and enable the raw error as
    // a way of viewing details?
    return genericMessageForOperation || intl.formatMessage(commonMessages.genericErrorMessage);
};
const LabListErrorHandling = {
    getErrorMessage,
};
export default LabListErrorHandling;
