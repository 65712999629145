import { call, put, takeLatest, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../../data/ml-client-error';
import { getArmAccessTokenSaga } from '../../identity/access-tokens';
import { ResourceId } from '../../../../utils/resource-id';
import { getLocale, getLanguage } from '../../../selectors/common-selectors';
import { ImageActionType } from '../../../actions/vnext/images/image-actions';
import ImageProvider from '../../../../data/providers/vnext/arm-api/image-provider';
import { listImagesError, listImagesSuccess } from '../../../actions/vnext/images/image-action-creators';
export function* listImages(action) {
    const { labPlanId, listEnabledOnly } = action;
    try {
        const resourceId = new ResourceId(labPlanId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const images = yield call(ImageProvider.listImages, resourceId, accessToken, locale, language, listEnabledOnly);
        yield put(listImagesSuccess(images));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labPlanId, FailureOperation.ListLabPlanImages, e ? e.code : undefined);
        yield put(listImagesError(error));
    }
}
export function* listImagesSaga() {
    yield takeLatest(ImageActionType.LIST_IMAGES, listImages);
}
