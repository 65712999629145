import { call, put, select } from 'redux-saga/effects';
import { startUserEnvironmentFailed, stopUserEnvironmentFailed, resetUserEnvironmentPasswordFailed, startUserEnvironmentAccepted, stopUserEnvironmentAccepted, } from '../../actions/user-environment/user-environment-action-creators';
import { start, stop, resetPassword } from '../../../data/providers/user-environment-provider';
import { UserEnvironmentActionType, } from '../../actions/user-environment/user-environment-actions';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { start as startVNext, stop as stopVNext, resetPassword as resetPasswordVNext, } from '../../../data/providers/vnext/labs-api/user-virtual-machine-provider';
import { environmentIdBasedTakeLatest, environmentIdBasedTakeEvery } from '../../effects/environment-id-based';
import { getUserVirtualMachines } from '../../selectors/vnext/user-virtual-machine-selectors';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { ResourceId } from '../../../utils/resource-id';
export function* startEnvironment(action) {
    const { environmentId } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(start, environmentId, accessToken, locale, language);
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, 'all', FailureOperation.StartUserEnvironment, err ? err.code : undefined);
        yield put(startUserEnvironmentFailed(environmentId, error));
    }
}
export function* stopEnvironment(action) {
    const { environmentId } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(stop, environmentId, accessToken, locale, language);
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, 'all', FailureOperation.StopUserEnvironment, err ? err.code : undefined);
        yield put(stopUserEnvironmentFailed(environmentId, error));
    }
}
export function* resetEnvironmentPassword(action) {
    const { environmentId, username, password } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(resetPassword, environmentId, username, password, accessToken, locale, language);
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, 'all', FailureOperation.ResetUserEnvironmentPassword, err ? err.code : undefined);
        yield put(resetUserEnvironmentPasswordFailed(environmentId, error));
    }
}
//vNext saga
export function* startVNextVirtualMachine(action) {
    const { environmentId } = action;
    try {
        const accessToken = yield call(getLabsApiAccessTokenSaga, false, undefined, true);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const userVirtualMachines = yield select(getUserVirtualMachines);
        const userVirtualMachine = userVirtualMachines.find((o) => caseInsensitiveCultureInvariantCompare(o.id, environmentId) === 0);
        let startVmResult = yield call(startVNext, userVirtualMachine.subscriptionId, userVirtualMachine.location, environmentId, accessToken, locale, language);
        startVmResult.ResourceId = new ResourceId(userVirtualMachine.id, true);
        yield put(startUserEnvironmentAccepted(environmentId, [startVmResult]));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, environmentId, FailureOperation.StartUserEnvironment, err ? err.code : undefined);
        yield put(startUserEnvironmentFailed(environmentId, error));
    }
}
export function* stopVNextVirtualMachine(action) {
    const { environmentId } = action;
    try {
        const accessToken = yield call(getLabsApiAccessTokenSaga, false, undefined, true);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const userVirtualMachines = yield select(getUserVirtualMachines);
        const userVirtualMachine = userVirtualMachines.find((o) => caseInsensitiveCultureInvariantCompare(o.id, environmentId) === 0);
        let stopVmResult = yield call(stopVNext, userVirtualMachine.subscriptionId, userVirtualMachine.location, environmentId, accessToken, locale, language);
        stopVmResult.ResourceId = new ResourceId(userVirtualMachine.id, true);
        yield put(stopUserEnvironmentAccepted(environmentId, [stopVmResult]));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, environmentId, FailureOperation.StopUserEnvironment, err ? err.code : undefined);
        yield put(stopUserEnvironmentFailed(environmentId, error));
    }
}
export function* resetVNextVirtualMachinePassword(action) {
    const { environmentId, username, password } = action;
    try {
        const accessToken = yield call(getLabsApiAccessTokenSaga, false, undefined, true);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const userVirtualMachines = yield select(getUserVirtualMachines);
        const userVirtualMachine = userVirtualMachines.find((o) => caseInsensitiveCultureInvariantCompare(o.id, environmentId) === 0);
        yield call(resetPasswordVNext, userVirtualMachine.subscriptionId, userVirtualMachine.location, environmentId, username, password, accessToken, locale, language);
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, environmentId, FailureOperation.ResetUserEnvironmentPassword, err ? err.code : undefined);
        yield put(resetUserEnvironmentPasswordFailed(environmentId, error));
    }
}
export function* startEnvironmentSaga() {
    yield environmentIdBasedTakeEvery(UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT, startEnvironment, startVNextVirtualMachine);
}
export function* stopEnvironmentSaga() {
    yield environmentIdBasedTakeEvery(UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT, stopEnvironment, stopVNextVirtualMachine);
}
export function* resetEnvironmentPasswordSaga() {
    yield environmentIdBasedTakeLatest(UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD, resetEnvironmentPassword, resetVNextVirtualMachinePassword);
}
