import { take, put, race, call, all, select, cancelled } from 'redux-saga/effects';
import { CommonActionType } from '../../actions/common/common-actions';
import { labAppInitializeSuccess, labAppInitializeError, fatalError, labAppInitializeCancelled, } from '../../actions/common/common-action-creators';
import { listTemplates } from '../../actions/template/template-action-creators';
import { TemplateActionType } from '../../actions/template/template-actions';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import ErrorCode from '../../../common/error-codes';
import { listUsers } from '../../actions/user/user-action-creators';
import { listEnvironments } from '../../actions/environment/environment-action-creators';
import { listSchedules, listVNextSchedules } from '../../actions/schedule/schedule-action-creators';
import { getCurrentTemplateId } from '../../selectors/template-selectors';
import { labParentBasedCancelableTakeLeading } from '../../effects/cancelable';
import { getLab, getLabMetadata, pollLabUntilTerminalStateStart, updateVnextPermissionPropagation, removeVnextPermissionPropagatingLab } from '../../actions/lab/lab-action-creators';
import { getVCurrentLab as getLabFromLabStore, labStoreIsLoadingOrUpdating } from '../../selectors/lab-selectors';
import { LabActionType } from '../../actions/lab/lab-actions';
import { getGroupId, getGroupName as getGroupNameFromStore } from '../../selectors/group-selectors';
import { getGroupName, setGroupId, getGroups } from '../../actions/group/group-action-creators';
import { getVNextLab, isVNextLabCreating, getCreateLabPermissionOnLabPlan, getVnextLabPermissionPropagating } from '../../selectors/vnext/lab-selectors';
import { isTerminalState } from '../../selectors/common-selectors';
import { OneMinuteInterval } from '../../../utils/constants';
import { listUsageData } from '../../actions/vnext/usage/usage-action-creators';
import { ResourceId } from '../../../utils/resource-id';
import { listPriceData } from '../../actions/vnext/price/price-action-creators';
export function* labAppInitialize(action) {
    const { labId } = action;
    try {
        // make sure we can get an access token and allow for redirects
        try {
            yield call(getArmAccessTokenSaga, true);
        }
        catch (err) {
            const error = new MlClientError(err ? err.message : undefined, labId, FailureOperation.LabAppInitialize, err ? err.code : undefined);
            yield put(labAppInitializeError(error));
            return;
        }
        // if the labStore is marked as loading, then we have already called listLabs and don't need to call getLab
        // see bug for context: https://dev.azure.com/devdiv/OnlineServices/_workitems/edit/1240387
        // regardless, load templates since all pages need them and treat it as a blocking call
        const isListingLabs = yield select(labStoreIsLoadingOrUpdating);
        const listTemplatesEffect = put(listTemplates(labId, true, true));
        if (isListingLabs) {
            yield listTemplatesEffect;
        }
        else {
            yield all([put(getLab(labId, false)), listTemplatesEffect]);
        }
        const { labResult, templateResult } = yield all({
            labResult: race({
                success: race([take(LabActionType.GET_LAB_SUCCESS), take(LabActionType.LIST_LABS_SUCCESS)]),
                error: race([take(LabActionType.GET_LAB_ERROR), take(LabActionType.LIST_LABS_ERROR)]),
            }),
            templateResult: race({
                success: take(TemplateActionType.LIST_TEMPLATES_SUCCESS),
                error: take(TemplateActionType.LIST_TEMPLATES_ERROR),
            }),
        });
        if (templateResult.success && labResult.success) {
            // kicking off the loads for lab resources, but up to pages to wait on them or not
            const templateId = yield select(getCurrentTemplateId);
            if (!!templateId) {
                yield all([
                    put(listUsers(labId)),
                    put(listEnvironments(templateId)),
                    put(listSchedules(templateId)),
                    put(getGroups()),
                ]);
            }
            else {
                yield all([put(listUsers(labId)), put(getGroups())]);
            }
            // update the aadGroupId and aadGroupName when switching between labs
            // update group name only when it is changed due to the rate limit of graph call
            const lab = yield select(getLabFromLabStore, labId);
            const currentLabGroupId = yield select(getGroupId);
            const currentLabGroupName = yield select(getGroupNameFromStore);
            if (lab && lab.aadGroupId && (lab.aadGroupId !== currentLabGroupId || !currentLabGroupName)) {
                yield put(setGroupId(lab.aadGroupId));
                yield put(getGroupName(lab.aadGroupId));
            }
            yield put(labAppInitializeSuccess());
        }
        else {
            const labError = labResult.error ? labResult.error.error : undefined;
            const templateError = templateResult.error
                ? templateResult.error.error
                : undefined;
            const error = labError ||
                templateError ||
                new MlClientError('Unknown lab app init failure', labId, FailureOperation.LabAppInitialize);
            const code = error.code;
            switch (code) {
                case ErrorCode.AuthorizationFailed:
                    yield put(fatalError(ErrorCode.LabAuthorizationFailed, error));
                    break;
                case ErrorCode.ResourceGroupNotFound:
                    yield put(fatalError(ErrorCode.ResourceGroupNotFound, error));
                    break;
                case ErrorCode.ResourceInGroupDoesNotExist:
                case ErrorCode.ResourceNotFound:
                    yield put(fatalError(ErrorCode.ResourceNotFound, error));
                    break;
                default:
                    yield put(fatalError(ErrorCode.GenericError, error));
            }
            yield put(labAppInitializeError(error));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, labId, FailureOperation.LabAppInitialize, err ? err.code : undefined);
        yield put(labAppInitializeError(error));
        yield put(fatalError(ErrorCode.GenericError, error));
    }
    finally {
        if (yield cancelled()) {
            yield put(labAppInitializeCancelled());
        }
    }
}
export function* vNextLabAppInitialize(action) {
    const { labId } = action;
    try {
        // make sure we can get an access token and allow for redirects
        try {
            yield call(getArmAccessTokenSaga, true);
        }
        catch (err) {
            const error = new MlClientError(err ? err.message : undefined, labId, FailureOperation.LabAppInitialize, err ? err.code : undefined);
            yield put(labAppInitializeError(error));
            return;
        }
        // if the labStore is marked as loading, then we have already called listLabs and don't need to call getLab
        // see bug for context: https://dev.azure.com/devdiv/OnlineServices/_workitems/edit/1240387
        // regardless, load templates since all pages need them and treat it as a blocking call
        const isListingLabs = yield select(labStoreIsLoadingOrUpdating);
        if (!isListingLabs) {
            yield put(getLab(labId, false));
        }
        const { error: labError } = yield race({
            success: race([take(LabActionType.GET_LAB_SUCCESS), take(LabActionType.LIST_LABS_SUCCESS)]),
            error: race({
                getLabError: take(LabActionType.GET_LAB_ERROR),
                listLabsError: take(LabActionType.LIST_LABS_ERROR),
            }),
        });
        const oldVnextLabPermissionPropagating = yield select(getVnextLabPermissionPropagating);
        const hasCreateLabPermissionOnLabPlan = yield select(getCreateLabPermissionOnLabPlan);
        if (!labError) {
            // no error for get lab means the current lab is not propagating permission, need to clear old permission propagating flag
            if (oldVnextLabPermissionPropagating) {
                // update isVnextLabPermissionPropagating to false for the current selected lab
                yield put(updateVnextPermissionPropagation(false));
            }
            const lab = yield select(getVNextLab, labId);
            const resourceId = new ResourceId(labId, true);
            // remove the lab from the resourceGroupPropagatingLabsMap in lab store since this get lab succeeded from ARM, propagation finished
            if (hasCreateLabPermissionOnLabPlan) {
                yield put(removeVnextPermissionPropagatingLab(lab));
            }
            // kicking off the loads for lab resources, but up to pages to wait on them or not
            if (!isVNextLabCreating(lab)) {
                yield all([
                    put(listUsers(labId)),
                    put(listEnvironments(labId)),
                    put(listVNextSchedules(labId)),
                    put(getGroups()),
                    put(getLabMetadata(labId)),
                    put(listUsageData(resourceId.subscriptionId, [lab.location])),
                    put(listPriceData(resourceId.subscriptionId, [lab.location])),
                ]);
            }
            else {
                yield all([
                    put(listVNextSchedules(labId)),
                    put(getGroups()),
                    put(listUsers(labId)),
                    put(getLabMetadata(labId)),
                    put(listUsageData(resourceId.subscriptionId, [lab.location])),
                    put(listPriceData(resourceId.subscriptionId, [lab.location])),
                ]);
            }
            if (!isTerminalState(lab.provisioningState)) {
                // todo: set different polling interval based on lab state
                yield put(pollLabUntilTerminalStateStart(labId, OneMinuteInterval, OneMinuteInterval));
            }
            // update the aadGroupId and aadGroupName when switching between labs
            // update group name only when it is changed due to the rate limit of graph call
            const currentLabGroupId = yield select(getGroupId);
            const currentLabGroupName = yield select(getGroupNameFromStore);
            if (lab &&
                lab.rosterProfile?.activeDirectoryGroupId &&
                (lab.rosterProfile.activeDirectoryGroupId !== currentLabGroupId || !currentLabGroupName)) {
                yield put(setGroupId(lab.rosterProfile.activeDirectoryGroupId));
                yield put(getGroupName(lab.rosterProfile.activeDirectoryGroupId));
            }
            yield put(labAppInitializeSuccess());
        }
        else {
            let error = labError.getLabError
                ? labError.getLabError.error
                : labError.listLabsError
                    ? labError.listLabsError.error
                    : undefined;
            error =
                error || new MlClientError('Unknown lab app init failure', labId, FailureOperation.LabAppInitialize);
            const code = error.code;
            switch (code) {
                case ErrorCode.AuthorizationFailed:
                    if (hasCreateLabPermissionOnLabPlan) {
                        // VNext lab permission propagation scenario, poll lab until succeed and retry lab app initialize action
                        if (!oldVnextLabPermissionPropagating) {
                            // last time selected a differnt lab that had propagation already done, need to flip the flag in lab store for the current lab.
                            yield put(updateVnextPermissionPropagation(true));
                        }
                        yield put(pollLabUntilTerminalStateStart(labId, OneMinuteInterval, OneMinuteInterval));
                    }
                    else {
                        // Not related to VNext lab permission propagation scenario, put LabAuthorizationFailed error to display error view
                        yield put(fatalError(ErrorCode.LabAuthorizationFailed, error));
                    }
                    break;
                case ErrorCode.ResourceGroupNotFound:
                    yield put(fatalError(ErrorCode.ResourceGroupNotFound, error));
                    break;
                case ErrorCode.ResourceInGroupDoesNotExist:
                case ErrorCode.ResourceNotFound:
                    yield put(fatalError(ErrorCode.ResourceNotFound, error));
                    break;
                default:
                    yield put(fatalError(ErrorCode.GenericError, error));
            }
            yield put(labAppInitializeError(error));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, labId, FailureOperation.LabAppInitialize, err ? err.code : undefined);
        yield put(labAppInitializeError(error));
        yield put(fatalError(ErrorCode.GenericError, error));
    }
    finally {
        if (yield cancelled()) {
            yield put(labAppInitializeCancelled());
        }
    }
}
export function* labAppStartupSaga() {
    yield labParentBasedCancelableTakeLeading(CommonActionType.LAB_APP_INITIALIZE, labAppInitialize, vNextLabAppInitialize, CommonActionType.LAB_APP_INITIALIZE_SUCCESS, CommonActionType.LAB_APP_INITIALIZE_ERROR);
}
