import { Icon, Stack, TextField } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import Constants, { DefaultCurrencyCode } from '../utils/constants';
import { validateLength } from '../utils/validators';
import { LabSizeInfo } from '../common/lab-size-info';
import { getLocalizedLabSkuDisplayName } from '../common/selectors/sku-selectors';
import './template.css';
const messages = defineMessages({
    templateTitle: {
        id: 'TemplateTitle',
        defaultMessage: 'Title',
        description: 'Text above text field to indicate the template title.',
    },
    templateDescription: {
        id: 'TemplateDescription',
        defaultMessage: 'Description',
        description: 'Text above text field to indicate the template description.',
    },
    templateDescriptionPlaceholder: {
        id: 'TemplateDescriptionPlaceholder',
        defaultMessage: 'Enter your description of the virtual machine for the class. The title and this description will be visible to students.',
        description: 'Placeholder for the description text field',
    },
    templateTitleTooLongValidationMessage: {
        id: 'TemplateTitleTooLongValidationMessage',
        defaultMessage: 'Titles cannot be longer than 255 characters.',
        description: 'Validation message for title being too long',
    },
    templateDescriptionTooLongValidationMessage: {
        id: 'TemplateDescriptionTooLongValidationMessage',
        defaultMessage: 'Descriptions cannot be longer than 500 characters.',
        description: 'Validation message for description being too long',
    },
});
const TemplateTitleFieldInjected = (props) => {
    const { template, templateTitle, setTemplateTitle, isTitleValid, isReadOnly, setIsTitleValid, updateTitleOrDescription, } = props;
    const { isSaving, id, title, description } = template;
    const titleLabel = props.intl.formatMessage(messages.templateTitle);
    const titleLengthValidationMessage = props.intl.formatMessage(messages.templateTitleTooLongValidationMessage);
    return (<TextField label={titleLabel} ariaLabel={titleLabel} disabled={isSaving || isReadOnly} value={templateTitle || ''} onChange={(_, value) => setTemplateTitle(value)} onGetErrorMessage={(value) => {
        const result = validateLength(value, Constants.TemplateTitleMaxLength, titleLengthValidationMessage);
        setIsTitleValid(!result);
        return result || '';
    }} onBlur={(_e) => {
        if (isTitleValid && title !== templateTitle) {
            // id is undefined for vNext and is not needed for update API call
            updateTitleOrDescription(id ?? '', templateTitle, description);
        }
    }}/>);
};
export const TemplateTitleField = injectIntl(TemplateTitleFieldInjected);
const TemplateDescriptionFieldInjected = (props) => {
    const { template, templateDescription, setTemplateDescription, isDescriptionValid, setIsDescriptionValid, updateTitleOrDescription, isReadOnly, } = props;
    const { isSaving, id, title, description } = template;
    const descriptionLabel = props.intl.formatMessage(messages.templateDescription);
    const descriptionPlaceholder = props.intl.formatMessage(messages.templateDescriptionPlaceholder);
    const descriptionLengthValidationMessage = props.intl.formatMessage(messages.templateDescriptionTooLongValidationMessage);
    return (<TextField styles={{
        subComponentStyles: { label: { root: { marginTop: '10px' } } },
        field: {
            height: '97px',
        },
    }} label={descriptionLabel} ariaLabel={descriptionLabel} disabled={isSaving || isReadOnly} value={templateDescription || ''} multiline resizable={false} placeholder={descriptionPlaceholder} onChange={(_, value) => setTemplateDescription(value)} onGetErrorMessage={(value) => {
        const result = validateLength(value, Constants.TemplateDescriptionMaxLength, descriptionLengthValidationMessage);
        setIsDescriptionValid(!result);
        return result || '';
    }} onBlur={(_e) => {
        if (isDescriptionValid && description !== templateDescription) {
            // id is undefined for vNext and is not needed for update API call
            updateTitleOrDescription(id ?? '', title, templateDescription);
        }
    }}/>);
};
export const TemplateDescriptionField = injectIntl(TemplateDescriptionFieldInjected);
const TemplateOsInfoInjected = (props) => {
    const { isWindows } = props;
    return (<Icon id="template-page-os-type-icon" iconName={isWindows ? 'WindowsLogo' : 'LinuxLogo32'} styles={{
        root: {
            fontSize: '28px',
            lineHeight: '16px',
            color: '#8A8886',
            marginTop: '12px',
        },
    }}/>);
};
export const TemplateOsInfo = injectIntl(TemplateOsInfoInjected);
const TemplateSizeInfoInjected = (props) => {
    const { template, intl } = props;
    const { image, cores, memorySize, price, privateIpAddress, currency, size: sizeName, storageSize, storageType, labSkuInfo, } = template;
    const formattedPrice = price !== null && price !== undefined ? (<FormattedNumber value={price} style="currency" currency={currency || DefaultCurrencyCode}/>) : null;
    let hasSizeInfo, skuDisplayName;
    if (!labSkuInfo) {
        // vCurrent
        hasSizeInfo = cores !== undefined && memorySize !== undefined && sizeName !== undefined;
        skuDisplayName = sizeName;
    }
    else {
        // vNext
        hasSizeInfo = cores !== undefined && memorySize !== undefined && labSkuInfo.skuFamilyName !== undefined;
        skuDisplayName = getLocalizedLabSkuDisplayName(labSkuInfo, intl);
    }
    return (<Stack>
            <Stack.Item>
                <div className="template__image">{image}</div>
            </Stack.Item>
            <Stack.Item>
                <div id="template-page-size-info">
                    {hasSizeInfo && (<>
                            {skuDisplayName}
                            <span className="template__size-divider">|</span>
                            <LabSizeInfo cores={cores} memorySize={memorySize} storageSize={storageSize} storageType={storageType}/>
                        </>)}
                </div>
            </Stack.Item>
            <Stack.Item>
                <div id="template-page-price-info">
                    {formattedPrice && (<FormattedMessage id="TemplatePrice" defaultMessage="{price} per hour" description="Price of running the template, per hour.  {price} is the cost per hour in USD." values={{
        price: formattedPrice,
    }}/>)}
                    {formattedPrice && privateIpAddress && <span className="template__size-divider">|</span>}
                    {privateIpAddress && (<FormattedMessage id="TemplatePrivateIpAddress" defaultMessage="Private IP address: {privateIpAddress}" description="Private IP address of the template VM." values={{
        privateIpAddress,
    }}/>)}
                </div>
            </Stack.Item>
        </Stack>);
};
export const TemplateSizeInfo = injectIntl(TemplateSizeInfoInjected);
