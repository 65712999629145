import { newLabRoles } from '../../../../data/models/roles';
import { createLabsApiClient } from './labs-api-client-factory';
export async function createLab(labParentId, labData, accessToken, locale, language, ltiToken) {
    const client = createLabsApiClient(labParentId.subscriptionId, accessToken, locale, language);
    const options = {
        customHeaders: !!ltiToken ? { 'x-ml-lti-id-token': ltiToken } : undefined,
    };
    const lab = await client.lab.labPlanPut(labData, labData.location, labParentId.resourceGroupName, labData.name, options);
    return { ...lab, roles: [...newLabRoles] };
}
export async function publishLab(region, labId, accessToken, locale, language, capacity) {
    const client = createLabsApiClient(labId.subscriptionId, accessToken, locale, language);
    let publishLabResult = await client.lab.publish(region, labId.resourceGroupName, labId.name, {
        capacity,
    });
    return publishLabResult._response.parsedBody;
}
const LabProvider = {
    createLab,
    publishLab,
};
export default LabProvider;
