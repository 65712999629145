import { Map, Record } from 'immutable';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { PriceActionType, } from '../../actions/vnext/price/price-actions';
function listPriceData(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loading,
        error: undefined,
    });
}
function listPriceDataSuccess(state, action) {
    const { data } = action;
    return state.merge({
        loadState: LoadingStoreState.Loaded,
        prices: data,
    });
}
function listPriceDataError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: LoadingStoreState.LoadingFailed,
        error,
        prices: undefined,
    });
}
function listPriceDataCancelled(state, action) {
    return state.merge({
        loadState: LoadingStoreState.NotStarted,
        prices: Map(),
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    error: undefined,
    prices: Map(),
})();
export const priceReducer = (state = initialState, action) => {
    switch (action.type) {
        case PriceActionType.SELECT_TENANT:
        case PriceActionType.SELECT_LAB:
        case PriceActionType.SELECT_LAB_PLAN:
        case PriceActionType.SELECT_LAB_PARENT_RESOURCE:
        case PriceActionType.CLEAR_USER_SESSION:
        case PriceActionType.LIST_PRICE_DATA:
            return listPriceData(state, action);
        case PriceActionType.LIST_PRICE_DATA_SUCCESS:
            return listPriceDataSuccess(state, action);
        case PriceActionType.LIST_PRICE_DATA_ERROR:
            return listPriceDataError(state, action);
        case PriceActionType.LIST_PRICE_DATA_CANCELLED:
            return listPriceDataCancelled(state, action);
        default:
            return state;
    }
};
export default priceReducer;
