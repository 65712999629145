import { defineMessages } from 'react-intl';
export const getLocalizedLocationName = (location, intl) => {
    switch (location) {
        case AzureRegions.centralus:
            return intl.formatMessage(messages.centralus);
        case AzureRegions.eastus:
            return intl.formatMessage(messages.eastus);
        case AzureRegions.eastus2:
            return intl.formatMessage(messages.eastus2);
        case AzureRegions.northcentralus:
            return intl.formatMessage(messages.northcentralus);
        case AzureRegions.southcentralus:
            return intl.formatMessage(messages.southcentralus);
        case AzureRegions.westcentralus:
            return intl.formatMessage(messages.westcentralus);
        case AzureRegions.westus:
            return intl.formatMessage(messages.westus);
        case AzureRegions.westus2:
            return intl.formatMessage(messages.westus2);
        case AzureRegions.westus3:
            return intl.formatMessage(messages.westus3);
        case AzureRegions.australiacentral:
            return intl.formatMessage(messages.australiacentral);
        case AzureRegions.australiacentral2:
            return intl.formatMessage(messages.australiacentral2);
        case AzureRegions.australiaeast:
            return intl.formatMessage(messages.australiaeast);
        case AzureRegions.australiasoutheast:
            return intl.formatMessage(messages.australiasoutheast);
        case AzureRegions.brazilsouth:
            return intl.formatMessage(messages.brazilsouth);
        case AzureRegions.brazilsoutheast:
            return intl.formatMessage(messages.brazilsoutheast);
        case AzureRegions.canadacentral:
            return intl.formatMessage(messages.canadacentral);
        case AzureRegions.canadaeast:
            return intl.formatMessage(messages.canadaeast);
        case AzureRegions.francecentral:
            return intl.formatMessage(messages.francecentral);
        case AzureRegions.francesouth:
            return intl.formatMessage(messages.francesouth);
        case AzureRegions.germanynorth:
            return intl.formatMessage(messages.germanynorth);
        case AzureRegions.germanywestcentral:
            return intl.formatMessage(messages.germanywestcentral);
        case AzureRegions.japaneast:
            return intl.formatMessage(messages.japaneast);
        case AzureRegions.japanwest:
            return intl.formatMessage(messages.japanwest);
        case AzureRegions.koreacentral:
            return intl.formatMessage(messages.koreacentral);
        case AzureRegions.koreasouth:
            return intl.formatMessage(messages.koreasouth);
        case AzureRegions.northeurope:
            return intl.formatMessage(messages.northeurope);
        case AzureRegions.westeurope:
            return intl.formatMessage(messages.westeurope);
        case AzureRegions.norwayeast:
            return intl.formatMessage(messages.norwayeast);
        case AzureRegions.norwaywest:
            return intl.formatMessage(messages.norwaywest);
        case AzureRegions.southafricanorth:
            return intl.formatMessage(messages.southafricanorth);
        case AzureRegions.southafricawest:
            return intl.formatMessage(messages.southafricawest);
        case AzureRegions.centralindia:
            return intl.formatMessage(messages.centralindia);
        case AzureRegions.southindia:
            return intl.formatMessage(messages.southindia);
        case AzureRegions.westindia:
            return intl.formatMessage(messages.westindia);
        case AzureRegions.southeastasia:
            return intl.formatMessage(messages.southeastasia);
        case AzureRegions.eastasia:
            return intl.formatMessage(messages.eastasia);
        case AzureRegions.swedencentral:
            return intl.formatMessage(messages.swedencentral);
        case AzureRegions.swedensouth:
            return intl.formatMessage(messages.swedensouth);
        case AzureRegions.switzerlandnorth:
            return intl.formatMessage(messages.switzerlandnorth);
        case AzureRegions.switzerlandwest:
            return intl.formatMessage(messages.switzerlandwest);
        case AzureRegions.uaecentral:
            return intl.formatMessage(messages.uaecentral);
        case AzureRegions.uaenorth:
            return intl.formatMessage(messages.uaenorth);
        case AzureRegions.uksouth:
            return intl.formatMessage(messages.uksouth);
        case AzureRegions.ukwest:
            return intl.formatMessage(messages.ukwest);
        case AzureRegions.centraluseuap:
            return intl.formatMessage(messages.centraluseuap);
        case AzureRegions.eastus2euap:
            return intl.formatMessage(messages.eastus2euap);
        default:
            // TODO: this is unexpected. log?
            return intl.formatMessage(messages.unknown);
    }
};
var AzureRegions;
(function (AzureRegions) {
    AzureRegions["centralus"] = "centralus";
    AzureRegions["eastus"] = "eastus";
    AzureRegions["eastus2"] = "eastus2";
    AzureRegions["northcentralus"] = "northcentralus";
    AzureRegions["southcentralus"] = "southcentralus";
    AzureRegions["westcentralus"] = "westcentralus";
    AzureRegions["westus"] = "westus";
    AzureRegions["westus2"] = "westus2";
    AzureRegions["westus3"] = "westus3";
    AzureRegions["australiacentral"] = "australiacentral";
    AzureRegions["australiacentral2"] = "australiacentral2";
    AzureRegions["australiaeast"] = "australiaeast";
    AzureRegions["australiasoutheast"] = "australiasoutheast";
    AzureRegions["brazilsouth"] = "brazilsouth";
    AzureRegions["brazilsoutheast"] = "brazilsoutheast";
    AzureRegions["canadacentral"] = "canadacentral";
    AzureRegions["canadaeast"] = "canadaeast";
    AzureRegions["francecentral"] = "francecentral";
    AzureRegions["francesouth"] = "francesouth";
    AzureRegions["germanynorth"] = "germanynorth";
    AzureRegions["germanywestcentral"] = "germanywestcentral";
    AzureRegions["japaneast"] = "japaneast";
    AzureRegions["japanwest"] = "japanwest";
    AzureRegions["koreacentral"] = "koreacentral";
    AzureRegions["koreasouth"] = "koreasouth";
    AzureRegions["northeurope"] = "northeurope";
    AzureRegions["westeurope"] = "westeurope";
    AzureRegions["norwayeast"] = "norwayeast";
    AzureRegions["norwaywest"] = "norwaywest";
    AzureRegions["southafricanorth"] = "southafricanorth";
    AzureRegions["southafricawest"] = "southafricawest";
    AzureRegions["centralindia"] = "centralindia";
    AzureRegions["southindia"] = "southindia";
    AzureRegions["westindia"] = "westindia";
    AzureRegions["southeastasia"] = "southeastasia";
    AzureRegions["eastasia"] = "eastasia";
    AzureRegions["swedencentral"] = "swedencentral";
    AzureRegions["swedensouth"] = "swedensouth";
    AzureRegions["switzerlandnorth"] = "switzerlandnorth";
    AzureRegions["switzerlandwest"] = "switzerlandwest";
    AzureRegions["uaecentral"] = "uaecentral";
    AzureRegions["uaenorth"] = "uaenorth";
    AzureRegions["uksouth"] = "uksouth";
    AzureRegions["ukwest"] = "ukwest";
    AzureRegions["centraluseuap"] = "centraluseuap";
    AzureRegions["eastus2euap"] = "eastus2euap";
    AzureRegions["unknown"] = "unknown";
})(AzureRegions || (AzureRegions = {}));
const messages = defineMessages({
    centralus: {
        id: 'CentralUS',
        defaultMessage: 'Central US',
        description: 'Text for Azure region Central US',
    },
    eastus: {
        id: 'EastUS',
        defaultMessage: 'East US',
        description: 'Text for Azure region East US',
    },
    eastus2: {
        id: 'EastUS2',
        defaultMessage: 'East US 2',
        description: 'Text for Azure region East US 2',
    },
    northcentralus: {
        id: 'NorthCentralUS',
        defaultMessage: 'North Central US',
        description: 'Text for Azure region North Central US',
    },
    southcentralus: {
        id: 'SouthCentralUS',
        defaultMessage: 'South Central US',
        description: 'Text for Azure region South Central US',
    },
    westcentralus: {
        id: 'WestCentralUS',
        defaultMessage: 'West Central US',
        description: 'Text for Azure region West Central US',
    },
    westus: {
        id: 'WestUS',
        defaultMessage: 'West US',
        description: 'Text for Azure region West US',
    },
    westus2: {
        id: 'WestUS2',
        defaultMessage: 'West US 2',
        description: 'Text for Azure region West US 2',
    },
    westus3: {
        id: 'WestUS3',
        defaultMessage: 'West US 3',
        description: 'Text for Azure region West US 3',
    },
    australiacentral: {
        id: 'AustraliaCentral',
        defaultMessage: 'Australia Central',
        description: 'Text for Azure region Australia Central',
    },
    australiacentral2: {
        id: 'AustraliaCentral2',
        defaultMessage: 'Australia Central 2',
        description: 'Text for Azure region Australia Central 2',
    },
    australiaeast: {
        id: 'AustraliaEast',
        defaultMessage: 'Australia East',
        description: 'Text for Azure region Australia East',
    },
    australiasoutheast: {
        id: 'AustraliaSoutheast',
        defaultMessage: 'Australia Southeast',
        description: 'Text for Azure region Australia Southeast',
    },
    brazilsouth: {
        id: 'BrazilSouth',
        defaultMessage: 'Brazil South',
        description: 'Text for Azure region Brazil South',
    },
    brazilsoutheast: {
        id: 'BrazilSoutheast',
        defaultMessage: 'Brazil Southeast',
        description: 'Text for Azure region Brazil Southeast',
    },
    canadacentral: {
        id: 'CanadaCentral',
        defaultMessage: 'Canada Central',
        description: 'Text for Azure region Canada Central',
    },
    canadaeast: {
        id: 'CanadaEast',
        defaultMessage: 'Canada East',
        description: 'Text for Azure region Canada East',
    },
    francecentral: {
        id: 'FranceCentral',
        defaultMessage: 'France Central',
        description: 'Text for Azure region France Central',
    },
    francesouth: {
        id: 'FranceSouth',
        defaultMessage: 'France South',
        description: 'Text for Azure region France South',
    },
    germanynorth: {
        id: 'GermanyNorth',
        defaultMessage: 'Germany North',
        description: 'Text for Azure region Germany North',
    },
    germanywestcentral: {
        id: 'GermanyWestCentral',
        defaultMessage: 'Germany West Central',
        description: 'Text for Azure region Germany West Central',
    },
    japaneast: {
        id: 'JapanEast',
        defaultMessage: 'Japan East',
        description: 'Text for Azure region Japan East',
    },
    japanwest: {
        id: 'JapanWest',
        defaultMessage: 'Japan West',
        description: 'Text for Azure region Japan West',
    },
    koreacentral: {
        id: 'KoreaCentral',
        defaultMessage: 'Korea Central',
        description: 'Text for Azure region Korea Central',
    },
    koreasouth: {
        id: 'KoreaSouth',
        defaultMessage: 'Korea South',
        description: 'Text for Azure region Korea South',
    },
    northeurope: {
        id: 'NorthEurope',
        defaultMessage: 'North Europe',
        description: 'Text for Azure region North Europe',
    },
    westeurope: {
        id: 'WestEurope',
        defaultMessage: 'West Europe',
        description: 'Text for Azure region West Europe',
    },
    norwayeast: {
        id: 'NorwayEast',
        defaultMessage: 'Norway East',
        description: 'Text for Azure region Norway East',
    },
    norwaywest: {
        id: 'NorwayWest',
        defaultMessage: 'Norway West',
        description: 'Text for Azure region Norway West',
    },
    southafricanorth: {
        id: 'SouthAfricaNorth',
        defaultMessage: 'South Africa North',
        description: 'Text for Azure region South Africa North',
    },
    southafricawest: {
        id: 'SouthAfricaWest',
        defaultMessage: 'South Africa West',
        description: 'Text for Azure region South Africa West',
    },
    centralindia: {
        id: 'CentralIndia',
        defaultMessage: 'Central India',
        description: 'Text for Azure region Central India',
    },
    southindia: {
        id: 'SouthIndia',
        defaultMessage: 'South India',
        description: 'Text for Azure region South India',
    },
    westindia: {
        id: 'WestIndia',
        defaultMessage: 'West India',
        description: 'Text for Azure region West India',
    },
    southeastasia: {
        id: 'SoutheastAsia',
        defaultMessage: 'Southeast Asia',
        description: 'Text for Azure region Southeast Asia',
    },
    eastasia: {
        id: 'EastAsia',
        defaultMessage: 'East Asia',
        description: 'Text for Azure region East Asia',
    },
    swedencentral: {
        id: 'SwedenCentral',
        defaultMessage: 'Sweden Central',
        description: 'Text for Azure region Sweden Central',
    },
    swedensouth: {
        id: 'SwedenSouth',
        defaultMessage: 'Sweden South',
        description: 'Text for Azure region Sweden South',
    },
    switzerlandnorth: {
        id: 'SwitzerlandNorth',
        defaultMessage: 'Switzerland North',
        description: 'Text for Azure region Switzerland North',
    },
    switzerlandwest: {
        id: 'SwitzerlandWest',
        defaultMessage: 'Switzerland West',
        description: 'Text for Azure region Switzerland West',
    },
    uaecentral: {
        id: 'UAECentral',
        defaultMessage: 'UAE Central',
        description: 'Text for Azure region UAE Central',
    },
    uaenorth: {
        id: 'UAENorth',
        defaultMessage: 'UAE North',
        description: 'Text for Azure region UAE North',
    },
    uksouth: {
        id: 'UKSouth',
        defaultMessage: 'UK South',
        description: 'Text for Azure region UK South',
    },
    ukwest: {
        id: 'UKWest',
        defaultMessage: 'UK West',
        description: 'Text for Azure region UK West',
    },
    centraluseuap: {
        id: 'CentralUSEUAP',
        defaultMessage: 'Central US EUAP',
        description: 'Text for Azure region centraluseuap',
    },
    eastus2euap: {
        id: 'EastUS2EUAP',
        defaultMessage: 'East US 2 EUAP',
        description: 'Text for Azure region eastus2euap',
    },
    unknown: {
        id: 'UnknownRegion',
        defaultMessage: 'Unknown Region',
        description: 'Text for an Azure region that we could not find in the current display name map.',
    },
});
