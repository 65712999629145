import { getMlClient } from './arm-data-provider';
import { processPowerState, processProvisioningState, processVmState } from '../helpers/environment-helpers';
import { VmState, EnvironmentState } from '../models/environment-common';
function processEnvironmentState(mlEnvironment) {
    if (!mlEnvironment.resourceSets || !mlEnvironment.resourceSets.vmResourceId) {
        return EnvironmentState.Creating;
    }
    return undefined;
}
export function processTemplateEnvironment(mlEnvironment) {
    const { id, lastKnownPowerState, latestOperationResult, claimedByUserName: name, provisioningState: provState, networkInterface, claimedByUserPrincipalId, } = mlEnvironment;
    const powerState = processPowerState(lastKnownPowerState);
    const provisioningState = processProvisioningState(provState);
    const environmentState = processEnvironmentState(mlEnvironment);
    const state = environmentState || provisioningState;
    const vmState = processVmState(powerState, state, latestOperationResult);
    let environmentNetworkInterface = undefined;
    if (networkInterface &&
        (!!networkInterface.sshAuthority ||
            !!networkInterface.rdpAuthority ||
            !!networkInterface.rdpInBrowserUrl ||
            !!networkInterface.sshInBrowserUrl)) {
        const { rdpAuthority, rdpInBrowserUrl, sshAuthority, sshInBrowserUrl, username, privateIpAddress, } = networkInterface;
        environmentNetworkInterface = {
            rdpAuthority,
            rdpInBrowserUrl,
            sshAuthority,
            sshInBrowserUrl,
            username,
            privateIpAddress,
        };
    }
    const environment = {
        id: id,
        name: name,
        vmState,
        pendingVmState: VmState.None,
        lastKnownPowerState: powerState,
        provisioningState,
        networkInterface: environmentNetworkInterface,
        claimedByUserPrincipalId,
    };
    return environment;
}
export async function listEnvironments(templateId, accessToken, locale, language) {
    const mlClient = getMlClient(templateId.subscriptionId, accessToken, locale, language);
    const environments = await mlClient.environments.list(templateId.resourceGroupName, templateId.parent.parent.name, templateId.parent.name, templateId.name);
    const templateEnvironments = environments.map((env) => processTemplateEnvironment(env));
    return templateEnvironments;
}
export async function deleteEnvironment(environmentId, accessToken, locale, language) {
    const mlClient = getMlClient(environmentId.subscriptionId, accessToken, locale, language);
    await mlClient.environments.beginDeleteMethod(environmentId.resourceGroupName, environmentId.parent.parent.parent.name, environmentId.parent.parent.name, environmentId.parent.name, environmentId.name);
}
export async function startEnvironment(environmentId, accessToken, locale, language) {
    const mlClient = getMlClient(environmentId.subscriptionId, accessToken, locale, language);
    await mlClient.environments.beginStart(environmentId.resourceGroupName, environmentId.parent.parent.parent.name, environmentId.parent.parent.name, environmentId.parent.name, environmentId.name, { environmentId: environmentId.id });
}
export async function stopEnvironment(environmentId, accessToken, locale, language) {
    const mlClient = getMlClient(environmentId.subscriptionId, accessToken, locale, language);
    await mlClient.environments.beginStop(environmentId.resourceGroupName, environmentId.parent.parent.parent.name, environmentId.parent.parent.name, environmentId.parent.name, environmentId.name, { environmentId: environmentId.id });
}
export async function resetPassword(environmentId, username, password, accessToken, locale, language) {
    const mlClient = getMlClient(environmentId.subscriptionId, accessToken, locale, language);
    await mlClient.environments.beginResetPassword(environmentId.resourceGroupName, environmentId.parent.parent.parent.name, environmentId.parent.parent.name, environmentId.parent.name, environmentId.name, { environmentId: environmentId.id, username: username.trim(), password: password.trim() });
}
export async function getEnvironment(environmentId, accessToken, locale, language, includeNetwork = false) {
    const options = includeNetwork
        ? {
            expand: 'properties($expand=networkInterface)',
        }
        : {};
    const mlClient = getMlClient(environmentId.subscriptionId, accessToken, locale, language);
    const mlEnvironment = await mlClient.environments.get(environmentId.resourceGroupName, environmentId.parent.parent.parent.name, environmentId.parent.parent.name, environmentId.parent.name, environmentId.name, options);
    const environment = processTemplateEnvironment(mlEnvironment);
    return environment;
}
const EnvironmentProvider = {
    listEnvironments,
    deleteEnvironment,
    startEnvironment,
    stopEnvironment,
    resetPassword,
    getEnvironment,
};
export default EnvironmentProvider;
