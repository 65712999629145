import { CommonActionType } from '../common/common-actions';
export const CommonLabPlanActionType = {
    SELECT_LAB_PLAN: 'SELECT_LAB_PLAN',
};
export const LabPlanActionType = {
    ...CommonActionType,
    ...CommonLabPlanActionType,
    LIST_LAB_PLANS: 'LIST_LAB_PLANS',
    LIST_LAB_PLANS_SUCCESS: 'LIST_LAB_PLANS_SUCCESS',
    LIST_LAB_PLANS_ERROR: 'LIST_LAB_PLANS_ERROR',
    LOAD_SUBSCRIPTION_LAB_PLANS_ERROR: 'LOAD_SUBSCRIPTION_LAB_PLANS_ERROR',
    GET_LAB_PLAN: 'GET_LAB_PLAN',
    GET_LAB_PLAN_SUCCESS: 'GET_LAB_PLAN_SUCCESS',
    GET_LAB_PLAN_ERROR: 'GET_LAB_PLAN_ERROR',
    UPDATE_LAB_PLANS_LMS_SETTINGS: 'UPDATE_LAB_PLAN_LMS_SETTINGS',
    UPDATE_LAB_PLANS_LMS_SETTINGS_SUCCESS: 'UPDATE_LAB_PLANS_LMS_SETTINGS_SUCCESS',
    UPDATE_LAB_PLANS_LMS_SETTINGS_ERROR: 'UPDATE_LAB_PLANS_LMS_SETTINGS_ERROR',
    CLEAR_UPDATE_LMS_ERROR: 'CLEAR_UPDATE_LMS_ERRORS',
};
