import { routerActions } from 'connected-react-router/immutable';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { convertChannelType, convertTeamsTheme, convertUserTeamRole, UserTeamRole, } from '../../../data/models/teams';
import { getContext, initialize, notifyAppLoaded, notifySuccess } from '../../../data/providers/teams-provider';
import { Routes } from '../../../utils/routes';
import { Theme } from '../../../utils/themes';
import { setLocale, setTheme } from '../../actions/common/common-action-creators';
import { enableFilterByGroupId, setGroupId, setGroupName } from '../../actions/group/group-action-creators';
import { setLoginHint, setTenantId } from '../../actions/identity/identity-action-creators';
import { setTeamsChannelType, setTeamsTheme, setUserTeamRole, teamsInitializeError, teamsInitializeSuccess, } from '../../actions/teams/teams-action-creators';
import { TeamsActionType } from '../../actions/teams/teams-actions';
import { listTenantsSuccess } from '../../actions/tenant/tenant-action-creators';
import { getSearch, isRouteTeamsConfig, isMfaRouteOrRedirect } from '../../selectors/route-selector';
export function* teamsInitialize(action) {
    try {
        // initialize the Teams SDK and configure our ARM proxy
        yield call(initialize);
        // set our context
        const context = yield call(getContext);
        const { tid, loginHint, locale, groupId, teamName, userTeamRole: teamsUserTeamRole, channelType: teamsChannelType, theme: teamsTheme, } = context;
        const userTeamRole = yield call(convertUserTeamRole, teamsUserTeamRole);
        const channelType = yield call(convertChannelType, teamsChannelType);
        const theme = yield call(convertTeamsTheme, teamsTheme);
        const tenant = {
            id: tid || '',
            tenantId: tid || '',
            displayName: '',
            defaultDomain: '',
        };
        yield all([
            put(setTenantId(tid || '')),
            put(setLoginHint(loginHint || '')),
            put(setLocale(locale)),
            put(setGroupId(groupId || '')),
            put(setGroupName(teamName || '')),
            put(enableFilterByGroupId()),
            put(setUserTeamRole(userTeamRole)),
            put(setTeamsChannelType(channelType)),
            put(setTeamsTheme(theme)),
            put(setTheme(Theme.Teams)),
            put(listTenantsSuccess([tenant])),
        ]);
        // if this is not a config or MFA page and the user is not an admin, we will redirect them to the student view
        const isTeamsConfigPage = yield select(isRouteTeamsConfig);
        const isMfa = yield select(isMfaRouteOrRedirect);
        if (!isTeamsConfigPage && !isMfa) {
            switch (userTeamRole) {
                case UserTeamRole.Admin:
                    break;
                case UserTeamRole.User:
                case UserTeamRole.Guest:
                default:
                    const search = yield select(getSearch);
                    yield put(routerActions.replace({ pathname: Routes.StudentView, search }));
                    break;
            }
        }
        // tell Teams we have started successfully (no all because order is important)
        yield call(notifyAppLoaded);
        yield call(notifySuccess);
        yield put(teamsInitializeSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.TeamsInitialize, err ? err.code : undefined);
        yield put(teamsInitializeError(error));
    }
}
export function* teamsInitializeSaga() {
    yield takeLatest(TeamsActionType.TEAMS_INITIALIZE, teamsInitialize);
}
