import { fork, all, put } from 'redux-saga/effects';
import { rootSaga as userEnvironmentRootSaga } from './user-environment/root-saga';
import { rootSaga as registerRootSaga } from './register/root-saga';
import { rootSaga as labRootSaga } from './lab/root-saga';
import { rootSaga as labAccountRootSaga } from './lab-account/root-saga';
import { rootSaga as identityRootSaga } from './identity/root-saga';
import { rootSaga as scheduleRootSaga } from './schedule/root-saga';
import { rootSaga as templateRootSaga } from './template/root-saga';
import { rootSaga as commonRootSaga } from './common/root-saga';
import { rootSaga as subscriptionRootSaga } from './subscription/root-saga';
import { rootSaga as galleryImageRootSaga } from './gallery-image/root-saga';
import { rootSaga as sharedImageRootSaga } from './shared-image/root-saga';
import { rootSaga as userRootSaga } from './user/root-saga';
import { rootSaga as environmentRootSaga } from './environment/root-saga';
import { rootSaga as pricingAndAvailabilityRootSaga } from './pricing-and-availability/root-saga';
import { rootSaga as getCoreLimitsRootSaga } from './core-limit/root-saga';
import { rootSaga as groupRootSaga } from './group/root-saga';
import { rootSaga as teamsConfigRootSaga } from './teams/root-saga';
import { rootSaga as tenantRootSaga } from './tenant/root-saga';
import { rootSaga as vNextLabParentResourceRootSaga } from './lab-parent-resource/root-saga';
import { rootSaga as vNextImageRootSaga } from './vnext/image/root-saga';
import { rootSaga as ltiRootSaga } from './lti/root-saga';
import { rootSaga as skuRootSaga } from './vnext/sku/root-saga';
import { rootSaga as usageRootSaga } from './vnext/usage/root-saga';
import { rootSaga as priceRootSaga } from './vnext/price/root-sage';
import { rootSaga as labPlanRootSaga } from './lab-plan/root-saga';
import { fatalError } from '../actions/common/common-action-creators';
import { ErrorCode } from '../../common/error-codes';
import MlClientError, { FailureOperation } from '../../data/ml-client-error';
export function* rootSaga() {
    try {
        yield all([
            fork(userEnvironmentRootSaga),
            fork(registerRootSaga),
            fork(galleryImageRootSaga),
            fork(sharedImageRootSaga),
            fork(labRootSaga),
            fork(labAccountRootSaga),
            fork(identityRootSaga),
            fork(scheduleRootSaga),
            fork(templateRootSaga),
            fork(commonRootSaga),
            fork(subscriptionRootSaga),
            fork(userRootSaga),
            fork(environmentRootSaga),
            fork(pricingAndAvailabilityRootSaga),
            fork(getCoreLimitsRootSaga),
            fork(groupRootSaga),
            fork(teamsConfigRootSaga),
            fork(tenantRootSaga),
            fork(vNextLabParentResourceRootSaga),
            fork(vNextImageRootSaga),
            fork(ltiRootSaga),
            fork(skuRootSaga),
            fork(usageRootSaga),
            fork(priceRootSaga),
            fork(labPlanRootSaga),
        ]);
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : 'Redux sagas failed', '', FailureOperation.ReduxSagas, err ? err.code : undefined);
        yield put(fatalError(ErrorCode.GenericError, error));
    }
}
