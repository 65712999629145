import memoize from 'memoize-one';
export const determineVNextLabSkuRemainingVms = memoize((coreUsage, currentLabCapacity, coresPerVm) => {
    if (currentLabCapacity === undefined || !coresPerVm) {
        return undefined;
    }
    const { limit, currentValue } = coreUsage;
    const remainingCores = limit - currentValue;
    const remainingVms = Math.floor(remainingCores / coresPerVm);
    return remainingVms + currentLabCapacity;
});
