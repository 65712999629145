import { Icon, PrimaryButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
const messages = defineMessages({
    newLab: {
        id: 'NoLabsViewNewLab',
        defaultMessage: 'Create lab',
        description: 'Name of a button or command to create a new lab.',
    },
});
const NoLabsView = (props) => {
    const { isNewLabButtonDisabled, isLmsIntegrationEnabled, isTeamsIntegrationEnabled } = props;
    const intl = useIntl();
    const msg = intl.formatMessage;
    return (<Stack horizontalAlign="center" styles={{ root: { marginTop: 140 } }}>
            <Stack.Item>
                <Icon iconName="illustration-3" styles={{ root: { marginBottom: 20 } }}/>
            </Stack.Item>
            <Stack.Item>
                <b>
                    <FormattedMessage id="NoLabsViewMessage" defaultMessage="No labs have been created." description="Message shown on the labs page when no labs have yet been created in the container."/>
                </b>
            </Stack.Item>
            <Stack.Item>
                <div style={{ maxWidth: 610, marginBottom: 25, textAlign: 'center' }}>
                    <FormattedMessage id="NoLabsViewDescription" defaultMessage="Azure Lab Services enables you to easily set up a computer lab in the cloud that your students and users can access from anywhere, any time." description="Message shown on the labs page when no labs have yet been created in the container."/>
                    {isTeamsIntegrationEnabled && (<FormattedMessage id="TeamsNoLabsViewDescription" defaultMessage=" Only labs associated with this class will appear in this tab. If you have created other labs outside of Teams, they will not be shown here." description="Message shown on the labs page when no labs have yet been created for the Teams team."/>)}
                    {isLmsIntegrationEnabled && (<FormattedMessage id="LmsNoLabsViewDescription" defaultMessage=" If you have created other labs outside of your learning management system, they will not be shown here." description="Message shown on the labs page when no labs have yet been created for the LMS course."/>)}
                </div>
            </Stack.Item>
            <Stack.Item>
                <PrimaryButton text={msg(messages.newLab)} onClick={props.onNewLab} disabled={isNewLabButtonDisabled}/>
            </Stack.Item>
        </Stack>);
};
export default NoLabsView;
