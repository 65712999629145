import { DirectionalHint, DocumentCard, DocumentCardLogo, DocumentCardType, Separator, Shimmer, ShimmerElementsGroup, ShimmerElementType, Stack, } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';
import ErrorCode from '../common/error-codes';
import InfoTip from '../common/info-tip';
import { calculateHoursPerUser, calculateNumberOfUsers, calculatePrice, calculateQuotaPerUser, calculateScheduleHoursPerUser, calculateTotalHoursForAllUsers, calculateTotalHoursPerUser, calculateTotalIndividualQuota, calculateTotalPrice, getCurrencyCode, hasInfiniteStartOnlySchedule, hasUnlimitedSchedule, } from './dashboard-selectors';
import { isVNextLab } from '../redux/selectors/lab-selectors';
import './dashboard.css';
const messages = defineMessages({
    quotaHoursLabel: {
        id: 'QuotaHoursCostEstimateTileLabel',
        defaultMessage: 'Quota hours',
        description: 'Label for quota hours on cost estimate tile',
    },
    scheduledHoursLabel: {
        id: 'ScheduledHoursCostEstimateTileLabel',
        defaultMessage: 'Scheduled hours',
        description: 'Label for scheduled hours on cost estimate tile',
    },
    hoursPerUserLabel: {
        id: 'HoursPerUserCostEstimateTileLabel',
        defaultMessage: 'Hours/user',
        description: 'Label for hours allocated per user on cost estimate tile',
    },
    maximumUsersLabel: {
        id: 'MaximumUsersCostEstimateTileLabel',
        defaultMessage: 'Maximum users',
        description: 'Label for maximum number of users on cost estimate tile',
    },
    totalHoursPerUserLabel: {
        id: 'TotalHoursPerUserCostEstimateTileLabel',
        defaultMessage: 'Hours x users',
        description: 'Label for total hours per user on cost estimate tile',
    },
    individualQuotaLabel: {
        id: 'IndividualQuotaCostEstimateTileLabel',
        defaultMessage: 'Adjusted quota',
        description: 'Label for Adjusted quota on cost estimate tile',
    },
});
const CostEstimateLogo = () => {
    return (<DocumentCardLogo logoIcon="Calculator" styles={{
        root: {
            marginLeft: '6px',
            fontSize: '30px',
            lineHeight: '40px',
            height: '40px',
        },
    }}/>);
};
export const CostEstimateTitle = () => {
    return (<div className="dashboard__cost-estimate-tile--title">
            <FormattedMessage id="CostEstimateTileTitle" defaultMessage="Cost estimate" description="Title of cost estimate tile on dashboard page"/>
        </div>);
};
export const CostEstimateBreakdownColumns = (props) => {
    const { individualHoursRows, aggregatedHoursRows } = props;
    const getFontWeight = (row) => (row.isBold ? 600 : 'initial');
    return (<Stack horizontal styles={{ root: { paddingLeft: '26px' } }}>
            <Stack.Item>
                <Stack>
                    {individualHoursRows.map((row) => (<Stack.Item key={`container-${row.label}-${row.value}`}>
                            <Stack horizontalAlign="space-between" styles={{ inner: { fontWeight: row.isBold ? 600 : 'initial' } }} horizontal>
                                <Stack.Item key={row.label} styles={{ root: { paddingRight: '10px', fontWeight: getFontWeight(row) } }}>
                                    {row.label}
                                </Stack.Item>
                                <Stack.Item key={`${row.label}-${row.value}`} styles={{ root: { fontWeight: getFontWeight(row) } }}>
                                    <div style={{ textAlign: 'left', width: '20px' }}>{row.value}</div>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>))}
                </Stack>
            </Stack.Item>
            <Stack.Item>
                <Stack styles={{ root: { paddingLeft: '70px' } }}>
                    {aggregatedHoursRows.map((row) => (<Stack.Item key={`container-${row.label}-${row.value}`}>
                            <Stack horizontalAlign="space-between" horizontal>
                                <Stack.Item key={row.label} styles={{ root: { paddingRight: '10px', fontWeight: getFontWeight(row) } }}>
                                    {row.label}
                                </Stack.Item>
                                <Stack.Item key={`${row.label}-${row.value}`} styles={{ root: { fontWeight: getFontWeight(row) } }}>
                                    <div style={{ textAlign: 'left', width: '30px' }}>{row.value}</div>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>))}
                </Stack>
            </Stack.Item>
        </Stack>);
};
const CostEstimateBreakdownInjected = (props) => {
    const { intl, lab, schedules, users } = props;
    const isLabParentLabAccount = !isVNextLab(lab?.id);
    if (hasUnlimitedSchedule(isLabParentLabAccount, schedules)) {
        return (<div style={{ marginLeft: '26px', marginTop: '20px', fontWeight: 600 }}>
                <FormattedMessage id="CostEstimateTileUnlimitedEventText" defaultMessage="This lab contains an unlimited event." description="Text in cost estimate tile indicating that the lab contains an unlimited schedule"/>
                <div>
                    <FormattedMessage id="CostEstimateTileUnlimitedEventExplanation" defaultMessage="The current cost estimate is unlimited. Please modify this for an estimate." description="Text explaining that a cost estimate cannot be made for an unlimited event."/>
                </div>
            </div>);
    }
    if (isLabParentLabAccount && hasInfiniteStartOnlySchedule(schedules)) {
        return (<div style={{ marginLeft: '26px', marginTop: '20px', fontWeight: 600 }}>
                <FormattedMessage id="CostEstimateTileStartOnlyEventText" defaultMessage="This lab contains a start-only scheduled event." description="Text in cost estimate tile indicating that the lab contains a start only schedule"/>
                <div>
                    <FormattedMessage id="CostEstimateTileStartOnlyExplanation" defaultMessage="The current cost estimate is unlimited. Please add an event with a stop action or remove this for an estimate." description="Text explaining that a cost estimate cannot be made for a start only event without another stop action."/>
                </div>
            </div>);
    }
    const quotaHoursLabel = intl.formatMessage(messages.quotaHoursLabel);
    const scheduledHoursLabel = intl.formatMessage(messages.scheduledHoursLabel);
    const hoursPerUserLabel = intl.formatMessage(messages.hoursPerUserLabel);
    const usersLabel = intl.formatMessage(messages.maximumUsersLabel);
    const totalHoursPerUserLabel = intl.formatMessage(messages.totalHoursPerUserLabel);
    const individualQuotaLabel = intl.formatMessage(messages.individualQuotaLabel);
    const individualHoursRows = [
        {
            label: quotaHoursLabel,
            value: <FormattedNumber value={calculateQuotaPerUser(lab)} maximumFractionDigits={2}/>,
        },
        {
            label: scheduledHoursLabel,
            value: (<FormattedNumber value={calculateScheduleHoursPerUser(isLabParentLabAccount, schedules)} maximumFractionDigits={2}/>),
        },
        {
            label: hoursPerUserLabel,
            value: <FormattedNumber value={calculateHoursPerUser(schedules, lab)} maximumFractionDigits={2}/>,
            isBold: true,
        },
    ];
    const isVNext = isVNextLab(lab?.id);
    const numberOfUsers = calculateNumberOfUsers(lab);
    const aggregatedHoursRows = [
        {
            label: usersLabel,
            value: numberOfUsers || isVNext ? <FormattedNumber value={numberOfUsers} maximumFractionDigits={2}/> : '--',
        },
        {
            label: totalHoursPerUserLabel,
            value: <FormattedNumber value={calculateTotalHoursPerUser(schedules, lab)} maximumFractionDigits={2}/>,
            isBold: true,
        },
        {
            label: individualQuotaLabel,
            value: <FormattedNumber value={calculateTotalIndividualQuota(isVNext, users)} maximumFractionDigits={2}/>,
        },
    ];
    return (<CostEstimateBreakdownColumns individualHoursRows={individualHoursRows} aggregatedHoursRows={aggregatedHoursRows}/>);
};
export const CostEstimateBreakdown = injectIntl(CostEstimateBreakdownInjected);
export const CostEstimateCalculation = (props) => {
    const { schedules, lab, users, sizeData } = props;
    const isLabParentLabAccount = !isVNextLab(lab?.id);
    if (hasUnlimitedSchedule(isLabParentLabAccount, schedules) ||
        (isLabParentLabAccount && hasInfiniteStartOnlySchedule(schedules))) {
        return null;
    }
    const totalHours = calculateTotalHoursForAllUsers(schedules, users, lab);
    const price = calculatePrice(lab, sizeData);
    const currency = getCurrencyCode(lab, sizeData);
    return (<div style={{ padding: '5px 0px 0px 26px', fontWeight: 600 }}>
            <FormattedMessage id="TotalEstimatedCost" defaultMessage="{totalHours} total hours x {price}/hour" description="Estimated cost of the lab. {totalHours} is the total amount of billable hours, {price} is amount of US dollars" values={{
        totalHours: <FormattedNumber value={totalHours ? totalHours : 0} maximumFractionDigits={2}/>,
        price: price === undefined ? ('--') : (<FormattedNumber value={price} style="currency" currency={currency} maximumFractionDigits={2}/>),
    }}/>
        </div>);
};
export const CostEstimateTotalCost = (props) => {
    const { schedules, lab, users, sizeData } = props;
    const totalCost = calculateTotalPrice(schedules, users, lab, sizeData);
    if (totalCost === undefined || (!isVNextLab(lab?.id) && hasInfiniteStartOnlySchedule(schedules))) {
        return <div style={{ paddingBottom: '10px', fontWeight: 600, fontSize: '28px' }}>--</div>;
    }
    const currency = getCurrencyCode(lab, sizeData);
    return (<div style={{ paddingBottom: '10px', fontWeight: 600, fontSize: '28px' }}>
            <FormattedNumber value={totalCost} style="currency" currency={currency} maximumFractionDigits={2}/>
        </div>);
};
const CostEstimateTotalCostDescription = () => {
    return (<div style={{ width: '163px', lineHeight: '20px', paddingBottom: '3px', fontSize: '14px' }}>
            <FormattedMessage id="TotalEstimatedCostDescription" defaultMessage="estimated maximum cost for this lab with current settings*" description="Description given below total price of lab.  This string is supplemented with string with id 'CostEstimateExclusionMessage' in another part on the same page"/>
        </div>);
};
const CostEstimateHeaderShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 150, height: 21 }]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25 },
    { type: ShimmerElementType.circle, height: 30 },
    { type: ShimmerElementType.gap, width: 15 },
    { type: ShimmerElementType.line, width: 97, height: 16 },
    { type: ShimmerElementType.gap, width: 100 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 150, height: 19 }]}/>
    </>);
const CostEstimateBreakdownShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 400, height: 5 }]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25, height: 10 },
    { type: ShimmerElementType.line, width: 173, height: 14 },
    { type: ShimmerElementType.gap, width: 42 },
    { type: ShimmerElementType.line, width: 173, height: 14 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 400, height: 3 }]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25 },
    { type: ShimmerElementType.line, width: 173, height: 14 },
    { type: ShimmerElementType.gap, width: 42 },
    { type: ShimmerElementType.line, width: 173, height: 14 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 400, height: 3 }]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25 },
    { type: ShimmerElementType.line, width: 173, height: 14 },
    { type: ShimmerElementType.gap, width: 42 },
    { type: ShimmerElementType.line, width: 173, height: 14 },
]}/>
    </>);
const CostEstimateTotalCostShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 144, height: 2 }]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.line, width: 144, height: 31 },
    { type: ShimmerElementType.gap, width: 25 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 144, height: 16 }]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: 144, height: 14 }]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 144, height: 6 }]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: 144, height: 14 }]}/>
    </>);
const CostEstimateCalculationShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 200, height: 6 }]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25 },
    { type: ShimmerElementType.line, width: 200, height: 14 },
    { type: ShimmerElementType.gap, width: 25 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 200, height: 3 }]}/>
    </>);
const CostEstimateCalculationDescriptionShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.line, width: 320, height: 14 },
    { type: ShimmerElementType.gap, width: 30 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 350, height: 8 }]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: 350, height: 14 }]}/>

        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 350, height: 8 }]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.line, width: 100, height: 14 },
    { type: ShimmerElementType.gap, width: 250 },
]}/>
    </>);
const CostEstimateTileInjected = (props) => {
    const { lab, schedules, users, sizeData, pricingError, isTemplateLoading, isUsersLoading, isSchedulesLoading, isPriceDataLoading, } = props;
    const isDataLoaded = !isTemplateLoading && !isUsersLoading && !isSchedulesLoading && !isPriceDataLoading;
    const isLabParentLabAccount = !isVNextLab(lab?.id);
    let hasUnlimitedCost = hasUnlimitedSchedule(isLabParentLabAccount, schedules);
    if (isLabParentLabAccount) {
        hasUnlimitedCost = hasUnlimitedCost || hasInfiniteStartOnlySchedule(schedules);
    }
    const calloutContent = (<span style={{ display: 'block', margin: '17px 28px 37px 28px', fontSize: '12px' }}>
            <span style={{ display: 'block', fontWeight: 600, fontSize: '20px', marginBottom: '10px' }}>
                <FormattedMessage id="CostEstimateDetailsCalloutTitle" defaultMessage="Cost estimate details" description="Title of cost estimate details callout"/>
            </span>
            <span style={{ display: 'block', marginBottom: '10px' }}>
                <FormattedMessage id="QuotaHoursExplanationCallout" defaultMessage="Quota hours: The maximum number of hours a user can use the VM outside the scheduled hours." description="Description of quota hours on cost estimate callout"/>
            </span>
            <span style={{ display: 'block', marginBottom: '10px' }}>
                <FormattedMessage id="ScheduledHoursExplanationCallout" defaultMessage="Scheduled hours: Hours that will be incurred based on the schedule set in the lab. This value is only available if there is a from/to date set on all the schedule events." description="Description of scheduled hours on cost estimate callout"/>
            </span>
            <span style={{ display: 'block', marginBottom: '10px' }}>
                <FormattedMessage id="HoursPerUserExplanationCallout" defaultMessage="Hours/User: The sum of quota hours and scheduled hours." description="Description of hours per user on cost estimate callout"/>
            </span>
            <span style={{ display: 'block', marginBottom: '10px' }}>
                <FormattedMessage id="UsersExplanationCallout" defaultMessage="Users: Maximum number of users in the lab based on all virtual machines to be claimed." description="Description of users on cost estimate callout"/>
            </span>
            <span style={{ display: 'block', marginBottom: '10px' }}>
                <FormattedMessage id="TotalhoursPerUserExplanationCallout" defaultMessage="Hours x users: Hours/user multiplied by the number of users." description="Description of total hours per user on cost estimate callout"/>
            </span>
            <span style={{ display: 'block', marginBottom: '10px' }}>
                <FormattedMessage id="IndividualQuotaExplanationCallout" defaultMessage="Adjusted quota: The sum of the quota hours added to specific users." description="Description of Adjusted quota on cost estimate callout"/>
            </span>
            <span style={{ display: 'block', marginBottom: '10px' }}>
                <FormattedMessage id="PricePerHourExplanationCallout" defaultMessage="$/hour: The cost per hour based on the VM size selected. This is based on the regular pay as you go price." description="Description of price per hour on cost estimate callout"/>
            </span>
            <span style={{ display: 'block' }}>
                <FormattedMessage id="TotalEstimatedCostExplanationCallout" defaultMessage="Total estimated cost: This is the maximum price for this lab based on current settings." description="Description of total estimated cost on cost estimate callout"/>
            </span>
        </span>);
    return (<DocumentCard type={DocumentCardType.normal} styles={{
        root: {
            marginRight: '18px',
            marginLeft: '31px',
            marginTop: '22px',
            width: '678px',
            maxWidth: '678px',
            minHeight: '285px',
            display: 'inline-flex',
            flex: '0 0',
            flexDirection: 'column',
            border: '1px solid transparent',
            boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.108), 0px 6.4px 14.4px rgba(0, 0, 0, 0.132)',
            borderRadius: '2px',
        },
    }}>
            <Stack styles={{ root: { paddingBottom: '20px' } }}>
                <Stack.Item>
                    <Stack horizontal styles={{
        root: { fontSize: '14px', marginTop: '5px', height: '100%' },
    }}>
                        <Stack.Item>
                            <Stack styles={{ root: { width: '398px' } }}>
                                <Stack.Item>
                                    <Shimmer width={150} isDataLoaded={isDataLoaded} customElementsGroup={CostEstimateHeaderShimmer}>
                                        <Stack horizontal>
                                            <Stack.Item>
                                                <CostEstimateLogo />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <CostEstimateTitle />
                                            </Stack.Item>
                                            <Stack.Item>
                                                <InfoTip content={calloutContent} calloutProps={{ gapSpace: 10 }} directionalHint={DirectionalHint.rightTopEdge} iconStyles={{
        root: {
            marginTop: '29px',
            marginLeft: '0px',
            paddingLeft: '8px',
        },
    }}/>
                                            </Stack.Item>
                                        </Stack>
                                    </Shimmer>
                                </Stack.Item>
                                <Stack.Item>
                                    <Shimmer customElementsGroup={CostEstimateBreakdownShimmer} isDataLoaded={isDataLoaded} width={400}>
                                        {!sizeData && (<div id="cost-estimate-tile-error-message">
                                                {pricingError &&
        pricingError.code === ErrorCode.AuthorizationFailed && (<FormattedMessage id="LabPricingLoadAuthorizationFailedError" defaultMessage="You do not currently have permission to view lab pricing information." description="Message indicating there was a problem loading pricing data due to user permissions."/>)}
                                                {!pricingError ||
        (pricingError.code !== ErrorCode.AuthorizationFailed && (<FormattedMessage id="LabPricingLoadError" defaultMessage="There was an issue loading pricing information for this lab. Cost estimates are unavailable." description="Message indicating there was a problem loading pricing data."/>))}
                                            </div>)}
                                        {!!sizeData && (<CostEstimateBreakdown lab={lab} schedules={schedules} users={users}/>)}
                                    </Shimmer>
                                </Stack.Item>
                                {(!isDataLoaded || !!sizeData) && !hasUnlimitedCost && (<Stack.Item>
                                        <Separator styles={{
        root: {
            color: '#EDEBE9',
            lineHeight: '1px',
            paddingTop: '15px',
            marginLeft: '26px',
            width: '398px',
        },
    }}/>
                                    </Stack.Item>)}
                                <Stack.Item>
                                    <Shimmer width={200} styles={{ root: { height: '24px' } }} isDataLoaded={isDataLoaded} customElementsGroup={CostEstimateCalculationShimmer}>
                                        {!!sizeData && (<CostEstimateCalculation lab={lab} schedules={schedules} users={users} sizeData={sizeData}/>)}
                                    </Shimmer>
                                </Stack.Item>
                                <Stack.Item styles={{ root: { padding: '20px 0px 0px 26px' } }}>
                                    <Shimmer width={350} isDataLoaded={isDataLoaded} customElementsGroup={CostEstimateCalculationDescriptionShimmer}>
                                        <div>
                                            {!!sizeData && (<FormattedMessage id="CostEstimateCalculationDescription" defaultMessage="<span>This is not a complete estimate for the lab, and only shows costs from student usage.</span> Lab owner usage is not shown here." description="Description about the total estimation of cost." values={{
        span: (chunks) => (<span style={{ fontWeight: 600 }}>{chunks}</span>),
    }}/>)}
                                        </div>
                                    </Shimmer>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack verticalAlign="center" styles={{ root: { paddingLeft: '60px' } }} verticalFill>
                                <Stack.Item>
                                    <Shimmer width={144} styles={{ root: { height: '81px' } }} isDataLoaded={isDataLoaded} customElementsGroup={CostEstimateTotalCostShimmer}>
                                        {!!sizeData && (<>
                                                <CostEstimateTotalCost lab={lab} schedules={schedules} users={users} sizeData={sizeData}/>
                                                <CostEstimateTotalCostDescription />
                                            </>)}
                                    </Shimmer>
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
        </DocumentCard>);
};
const CostEstimateTile = injectIntl(CostEstimateTileInjected);
export default CostEstimateTile;
