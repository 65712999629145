import { CommandBar, FocusZone, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { getNavigationItems } from '../common/lab-nav-items';
import { getCustomTheme } from '../common/themes/selectors';
import LabNavKeys from '../utils/lab-nav-key';
import CostEstimateTile from './cost-estimate-tile';
import { DashboardErrorBanner } from './dashboard-error-banner';
import DashboardErrorPage from './dashboard-error-page';
import { isFailed } from './dashboard-selectors';
import { ScheduleOverviewCard, TemplateOverviewCard, UsersOverviewCard, VmPoolOverviewCard } from './overview-cards';
import { isVNextLab } from '../redux/selectors/lab-selectors';
import { ResourceId } from '../utils/resource-id';
import './dashboard.css';
const messages = defineMessages({
    costEstimateRegionTitle: {
        id: 'CostsAndBillingSectionTitleForDashboard',
        defaultMessage: 'Costs & Billing',
        description: 'Title of costs and billing section on dashboard page',
    },
    overviewRegionTitle: {
        id: 'OverviewSectionTitleForDashboard',
        defaultMessage: 'Overview',
        description: 'Title of overview section on dashboard page',
    },
});
const DashboardInjected = (props) => {
    const { dashboardViewModel, clearTemplateError, clearScheduleError, clearEnvironmentError, clearUsersError, loadTemplate, loadVirtualMachines, loadUsers, loadSchedules, intl, navigateRoute, } = props;
    const { template, lab, schedules, environments, users, sizeData, isTemplateLoading, isEnvironmentsLoading, isUsersLoading, isSchedulesLoading, isPriceDataLoading, isReadOnly, templateLoadError, scheduleLoadError, usersLoadError, environmentsLoadError, pricingError, locale, isTeamsOrLmsIntegrationEnabled, isGroupSyncModeEnabled, } = dashboardViewModel;
    const { formatMessage: msg } = intl;
    const { secondaryBackgroundColor, primaryCommandBarStyles, smallPageTitleStyle } = getCustomTheme();
    let navigationItems = undefined;
    if (isTeamsOrLmsIntegrationEnabled && lab && lab.id) {
        navigationItems = getNavigationItems(intl, lab.id, LabNavKeys.Dashboard, navigateRoute);
    }
    const isVNext = isVNextLab(lab?.id);
    if ((lab && isFailed(lab.provisioningState)) || (!isVNext && template && isFailed(template.provisioningState))) {
        return (<div id="dashboard-container" style={{ backgroundColor: secondaryBackgroundColor }}>
                <div id="dashboard-content">
                    {navigationItems && (<div id="dashboard-header">
                            <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                        </div>)}
                    <DashboardErrorPage />
                </div>
            </div>);
    }
    let labId;
    if (!isVNext) {
        labId = template && template.id ? new ResourceId(template.id).parent.id : '';
    }
    else {
        labId = lab?.id ?? '';
    }
    return (<div id="dashboard-container">
            <div id="dashboard-content" style={{ backgroundColor: secondaryBackgroundColor }}>
                <div id="dashboard-header">
                    <DashboardErrorBanner templateLoadError={templateLoadError} scheduleLoadError={scheduleLoadError} usersLoadError={usersLoadError} environmentsLoadError={environmentsLoadError} clearTemplateError={clearTemplateError} clearScheduleError={clearScheduleError} clearEnvironmentError={clearEnvironmentError} clearUsersError={clearUsersError}/>
                    {navigationItems && (<CommandBar styles={primaryCommandBarStyles} items={[]} farItems={navigationItems}/>)}
                </div>
                <div id="dashboard-body" className="vertical-scrollable-content">
                    <div id="dashboard-cost-estimate-section">
                        <FocusZone role="region" aria-label={msg(messages.costEstimateRegionTitle)}>
                            <div style={smallPageTitleStyle}>
                                <FormattedMessage {...messages.costEstimateRegionTitle}/>
                            </div>
                            <CostEstimateTile lab={lab} schedules={schedules} users={users} sizeData={sizeData} isTemplateLoading={isTemplateLoading} isSchedulesLoading={isSchedulesLoading} isUsersLoading={isUsersLoading} isPriceDataLoading={isPriceDataLoading} pricingError={pricingError}/>
                            <div className="dashboard__cost-estimate-tile--disclaimer">
                                <FormattedMessage id="CostEstimateExclusionMessage" defaultMessage="*Template hours, Azure compute gallery costs, and hours incurred when the lab creator starts a machine are excluded from the estimate. Please contact your IT Administrator for complete and accurate billing information." description="Message to indicate that the total cost of the lab does not include certain resources. Is meant to be supplementary text to string with id 'TotalEstimatedCostDescription'"/>
                            </div>
                        </FocusZone>
                    </div>
                    <div id="dashboard-overview-cards-section">
                        <FocusZone role="region" aria-label={msg(messages.overviewRegionTitle)}>
                            <span style={smallPageTitleStyle}>
                                <FormattedMessage {...messages.overviewRegionTitle}/>
                            </span>
                            <Stack horizontalAlign="start" wrap horizontal styles={{ root: { marginLeft: '31px', marginBottom: '25px' } }}>
                                <Stack.Item>
                                    <TemplateOverviewCard labId={labId} isLoading={isTemplateLoading} isReadOnly={isReadOnly} template={template} navigateRoute={loadTemplate}/>
                                </Stack.Item>
                                <Stack.Item>
                                    <VmPoolOverviewCard isVNext={isVNext} isLoading={isEnvironmentsLoading} isReadOnly={isReadOnly} environments={environments} labId={labId} navigateRoute={loadVirtualMachines}/>
                                </Stack.Item>
                                <Stack.Item>
                                    <UsersOverviewCard isVNext={isVNext} isLoading={isUsersLoading} isReadOnly={isReadOnly} labId={labId} users={users} navigateRoute={loadUsers} isGroupSyncModeEnabled={isGroupSyncModeEnabled}/>
                                </Stack.Item>
                                <Stack.Item>
                                    <ScheduleOverviewCard locale={locale} isLoading={isSchedulesLoading} isReadOnly={isReadOnly} labId={labId} schedules={schedules} navigateRoute={loadSchedules}/>
                                </Stack.Item>
                            </Stack>
                        </FocusZone>
                    </div>
                </div>
            </div>
        </div>);
};
const Dashboard = injectIntl(DashboardInjected);
export default Dashboard;
