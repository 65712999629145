import { Map } from 'immutable';
import { LabServicesModels } from 'lab-services';
import memoize from 'memoize-one';
import { addToListMap } from '../../../utils/common';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
export const getVNextVirtualMachines = memoize((store) => {
    return store.get('vNextVirtualMachineStore').get('virtualMachines');
});
export const getTemplateVM = memoize((store) => {
    const virtualMachines = getVNextVirtualMachines(store);
    return virtualMachines.find((o) => o.vmType === LabServicesModels.VirtualMachineType.Template);
});
export const getUserVMs = memoize((store) => {
    const virtualMachines = getVNextVirtualMachines(store);
    return virtualMachines.filter((o) => o.vmType === LabServicesModels.VirtualMachineType.User);
});
export const getStartErrors = memoize((store) => {
    const vNextVirtualMachineStore = store.get('vNextVirtualMachineStore');
    const startErrors = vNextVirtualMachineStore.get('startErrors');
    return startErrors;
});
export const getStopErrors = memoize((store) => {
    const vNextVirtualMachineStore = store.get('vNextVirtualMachineStore');
    const stopErrors = vNextVirtualMachineStore.get('stopErrors');
    return stopErrors;
});
export const getResetErrors = memoize((store) => {
    const vNextVirtualMachineStore = store.get('vNextVirtualMachineStore');
    const resetErrors = vNextVirtualMachineStore.get('resetErrors');
    return resetErrors;
});
export const getResetPasswordError = memoize((store) => {
    const vNextVirtualMachineStore = store.get('vNextVirtualMachineStore');
    const resetPasswordError = vNextVirtualMachineStore.get('resetPasswordError');
    return resetPasswordError;
});
export const getRedeployErrors = memoize((store) => {
    const vNextVirtualMachineStore = store.get('vNextVirtualMachineStore');
    const redeployErrors = vNextVirtualMachineStore.get('redeployErrors');
    return redeployErrors;
});
export const getTemplateErrors = memoize((store) => {
    let errors = Map();
    const templateVM = getTemplateVM(store);
    if (!templateVM) {
        return errors;
    }
    else {
        const startErrors = getStartErrors(store);
        const templateStartError = !!templateVM
            ? startErrors.find((o) => caseInsensitiveCultureInvariantCompare(o.id, templateVM.id) === 0)
            : undefined;
        const stopErrors = getStopErrors(store);
        const templateStopError = !!templateVM
            ? stopErrors.find((o) => caseInsensitiveCultureInvariantCompare(o.id, templateVM.id) === 0)
            : undefined;
        const resetPasswordError = getResetPasswordError(store);
        const saveImageError = getSaveImageError(store);
        const resetErrors = getResetErrors(store);
        const resetTemplateError = !!templateVM
            ? resetErrors.find((o) => caseInsensitiveCultureInvariantCompare(o.id, templateVM.id) === 0)
            : undefined;
        const redeployErrors = getRedeployErrors(store);
        const templateRedeployError = !!templateVM
            ? redeployErrors.find((o) => caseInsensitiveCultureInvariantCompare(o.id, templateVM.id) === 0)
            : undefined;
        if (!!templateStartError) {
            errors = addToListMap(errors, templateStartError, templateStartError.id);
        }
        if (!!templateStopError) {
            errors = addToListMap(errors, templateStopError, templateStopError.id);
        }
        if (!!resetPasswordError) {
            errors = addToListMap(errors, resetPasswordError, resetPasswordError.id);
        }
        if (!!saveImageError) {
            errors = addToListMap(errors, saveImageError, saveImageError.id);
        }
        if (!!templateRedeployError) {
            errors = addToListMap(errors, templateRedeployError, templateRedeployError.id);
        }
        if (!!resetTemplateError) {
            errors = addToListMap(errors, resetTemplateError, resetTemplateError.id);
        }
        return errors;
    }
});
export const getUserVMStartErrors = memoize((store) => {
    const startErrors = getStartErrors(store);
    const templateVM = getTemplateVM(store);
    if (!templateVM) {
        return startErrors;
    }
    else {
        return startErrors.filter((error, id) => caseInsensitiveCultureInvariantCompare(id, templateVM.id) !== 0);
    }
});
export const getUserVMStopErrors = memoize((store) => {
    const stopErrors = getStopErrors(store);
    const templateVM = getTemplateVM(store);
    if (!templateVM) {
        return stopErrors;
    }
    else {
        return stopErrors.filter((error, id) => caseInsensitiveCultureInvariantCompare(id, templateVM.id) !== 0);
    }
});
export const getSaveImageError = memoize((store) => {
    const vNextVirtualMachineStore = store.get('vNextVirtualMachineStore');
    const saveImageError = vNextVirtualMachineStore.get('saveImageError');
    return saveImageError;
});
export const getUserVMRedeployErrors = memoize((store) => {
    const redeployErrors = getRedeployErrors(store);
    const templateVM = getTemplateVM(store);
    if (!templateVM) {
        return redeployErrors;
    }
    else {
        return redeployErrors.filter((error, id) => caseInsensitiveCultureInvariantCompare(id, templateVM.id) !== 0);
    }
});
