import { takeLatest, call, select, put } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../../data/ml-client-error';
import SkuProvider from '../../../../data/providers/vnext/arm-api/sku-provider';
import { ResourceId } from '../../../../utils/resource-id';
import { SkuActionType } from '../../../actions/vnext/sku/sku-actions';
import { listSkuData, listSkuDataError, listSkuDataSuccess } from '../../../actions/vnext/sku/sku-action-creators';
import { getLanguage, getLocale } from '../../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../../identity/access-tokens';
import { isRouteTeamsConfig } from '../../../selectors/route-selector';
import { labParentBasedTakeLatest } from '../../../effects/lab-parent-based';
export function* listSkus(action) {
    const { labParentId } = action;
    try {
        const resourceId = new ResourceId(labParentId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const data = yield call(SkuProvider.listSkus, resourceId, accessToken, locale, language);
        yield put(listSkuDataSuccess(data));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, labParentId, FailureOperation.ListSkuData, err ? err.code : undefined);
        yield put(listSkuDataError(error));
    }
}
export function* listSkusOnLabParentSelect(action) {
    const { resourceId } = action;
    const isTeamsConfigPage = yield select(isRouteTeamsConfig);
    if (!isTeamsConfigPage) {
        yield put(listSkuData(resourceId));
    }
}
export function* listSkusSaga() {
    yield takeLatest(SkuActionType.LIST_SKU_DATA, listSkus);
}
export function* listSkusOnLabParentSelectSaga() {
    yield labParentBasedTakeLatest(SkuActionType.SELECT_LAB_PARENT_RESOURCE, undefined, listSkusOnLabParentSelect);
}
