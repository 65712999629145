import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PageTitle } from '../common/page/page-title';
import { VmState } from '../data/models/environment-common';
import { LabUpdateOperation } from '../data/models/lab';
import { clearLoadError, clearRedeployErrors, clearResetError, clearStartError, clearStartErrors, clearStopError, clearStopErrors, getEnvironment, pollEnvironmentsStart, pollEnvironmentsStop, redeployVirtualMachines, resetEnvironments, startEnvironments, stopEnvironments, } from '../redux/actions/environment/environment-action-creators';
import { clearLabCapacityExceedsCoresError, clearLabUpdateError, updateLab, updateVNextLab, } from '../redux/actions/lab/lab-action-creators';
import { pollUsersStart, pollUsersStop } from '../redux/actions/user/user-action-creators';
import { DefaultPollingInterval, FiveSecondInterval, OneMinuteInterval, TenSecondInterval } from '../utils/constants';
import { VmList } from './vm-list';
import { messages } from './vm-list-messages';
import { routerActions } from 'connected-react-router';
import { getVmListContainerModel } from './vm-list-selectors';
import { isVNextLab } from '../redux/selectors/lab-selectors';
import { isVNextLabDraft, isVNextLabScaling } from '../redux/selectors/vnext/lab-selectors';
import { determinePollingInitialDelay } from '../common/vm/vm-polling-initial-delay';
function determineEnvironmentsPollingIntervalLength(vmDetails, lab) {
    if (vmDetails.some((vm) => vm.vmState === VmState.Starting || vm.vmState === VmState.Stopping)) {
        return FiveSecondInterval;
    }
    const isVNext = isVNextLab(lab?.id);
    const isDraft = isVNext && isVNextLabDraft(lab);
    if (!isDraft &&
        (vmDetails.some((vm) => vm.vmState === VmState.Creating || vm.vmState === VmState.Deleting || vm.vmState === VmState.Redeploying) ||
            (isVNext && isVNextLabScaling(lab)))) {
        return TenSecondInterval;
    }
    return OneMinuteInterval;
}
function determineEnvironmentsPollingInitialDelay(vmDetails, lab) {
    const isVNext = isVNextLab(lab?.id);
    if (isVNext && isVNextLabScaling(lab)) {
        return TenSecondInterval;
    }
    return determinePollingInitialDelay(vmDetails.toJS(), isVNext);
}
function getAllPossibleErrorIds(vmDetails) {
    const results = vmDetails.map((o) => o.id);
    return [...results, 'all'];
}
class VmListContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._startPollingEnvironments = this._startPollingEnvironments.bind(this);
        this._startPollingUsers = this._startPollingUsers.bind(this);
        this._getEnvironmentNetworkData = this._getEnvironmentNetworkData.bind(this);
        this._updateLabCapacity = this._updateLabCapacity.bind(this);
        this._navigateRoute = this._navigateRoute.bind(this);
        this._startEnvironments = this._startEnvironments.bind(this);
        this._stopEnvironments = this._stopEnvironments.bind(this);
        this._clearStartError = this._clearStartError.bind(this);
        this._clearStopError = this._clearStopError.bind(this);
        this._redeployVirtualMachines = this._redeployVirtualMachines.bind(this);
        this._clearRedeployError = this._clearRedeployError.bind(this);
    }
    componentDidMount() {
        const { lab, vmParentId, vmDetails } = this.props.vmListViewModel;
        if (lab) {
            this._startPollingUsers(lab.id);
        }
        if (!!vmParentId) {
            const nextPollingIntervalMs = determineEnvironmentsPollingIntervalLength(vmDetails, lab);
            this._startPollingEnvironments(vmParentId, nextPollingIntervalMs);
        }
    }
    componentDidUpdate(prevProps) {
        const { lab, vmParentId, vmDetails } = this.props.vmListViewModel;
        if (lab && (!prevProps.vmListViewModel.lab || prevProps.vmListViewModel.lab.id !== lab.id)) {
            this.props.pollUsersStop();
            this._startPollingUsers(lab.id);
        }
        if (!!vmParentId) {
            const nextPollingIntervalMs = determineEnvironmentsPollingIntervalLength(vmDetails, lab);
            if (!prevProps.vmListViewModel.vmParentId || prevProps.vmListViewModel.vmParentId !== vmParentId) {
                this.props.pollEnvironmentsStop();
                this._startPollingEnvironments(vmParentId, nextPollingIntervalMs);
            }
            else if (this._environmentsPollingIntervalMs !== nextPollingIntervalMs) {
                this.props.pollEnvironmentsStop();
                const initialDelayMs = determineEnvironmentsPollingInitialDelay(vmDetails, lab);
                this._startPollingEnvironments(vmParentId, nextPollingIntervalMs, initialDelayMs);
            }
        }
    }
    _startPollingEnvironments(vmParentId, pollingIntervalMs, initialDelayMs) {
        this._environmentsPollingIntervalMs = pollingIntervalMs;
        this.props.pollEnvironmentsStart(vmParentId, pollingIntervalMs, initialDelayMs);
    }
    _startPollingUsers(labId, initialDelayMs) {
        this.props.pollUsersStart(labId, DefaultPollingInterval, initialDelayMs);
    }
    _getEnvironmentNetworkData(id) {
        this.props.getEnvironment(id, true);
    }
    _updateLabCapacity(maxUsersInLab) {
        const { vmListViewModel, updateLab, updateVNextLab } = this.props;
        const { lab } = vmListViewModel;
        if (!!lab) {
            if (!isVNextLab(lab.id)) {
                const currentLab = lab;
                const labUpdate = { ...currentLab, maxUsersInLab };
                updateLab(labUpdate, LabUpdateOperation.Capacity);
            }
            else {
                const currentLab = lab;
                const newSku = { ...currentLab.virtualMachineProfile.sku, capacity: maxUsersInLab };
                const labUpdate = {
                    virtualMachineProfile: { ...currentLab.virtualMachineProfile, sku: newSku },
                };
                updateVNextLab(currentLab.id, labUpdate, LabUpdateOperation.Capacity);
            }
        }
    }
    _navigateRoute(pathname) {
        const { push, search } = this.props;
        push({ pathname, search });
    }
    componentWillUnmount() {
        this.props.pollEnvironmentsStop();
        this.props.pollUsersStop();
    }
    _startEnvironments(ids) {
        const { isVNext, startEnvironments, clearStartErrors, vmListViewModel } = this.props;
        const { vmDetails } = vmListViewModel;
        if (isVNext) {
            const errorIds = getAllPossibleErrorIds(vmDetails);
            clearStartErrors([...errorIds]);
        }
        startEnvironments(ids);
    }
    _stopEnvironments(ids) {
        const { isVNext, stopEnvironments, clearStopErrors, vmListViewModel } = this.props;
        const { vmDetails } = vmListViewModel;
        if (isVNext) {
            const errorIds = getAllPossibleErrorIds(vmDetails);
            clearStopErrors([...errorIds]);
        }
        stopEnvironments(ids);
    }
    _redeployVirtualMachines(ids) {
        const { isVNext, redeployVirtualMachines, vmListViewModel, clearRedeployErrors } = this.props;
        const { vmDetails } = vmListViewModel;
        if (isVNext) {
            const errorIds = getAllPossibleErrorIds(vmDetails);
            clearRedeployErrors([...errorIds]);
            redeployVirtualMachines(ids);
        }
    }
    _clearStartError() {
        const { isVNext, clearStartError, clearStartErrors, vmListViewModel } = this.props;
        const { vmDetails } = vmListViewModel;
        if (isVNext) {
            const errorIds = getAllPossibleErrorIds(vmDetails);
            clearStartErrors([...errorIds]);
        }
        else {
            clearStartError();
        }
    }
    _clearStopError() {
        const { isVNext, clearStopError, clearStopErrors, vmListViewModel } = this.props;
        const { vmDetails } = vmListViewModel;
        if (isVNext) {
            const errorIds = getAllPossibleErrorIds(vmDetails);
            clearStopErrors([...errorIds]);
        }
        else {
            clearStopError();
        }
    }
    _clearRedeployError() {
        const { isVNext, clearRedeployErrors, vmListViewModel } = this.props;
        const { vmDetails } = vmListViewModel;
        if (isVNext) {
            const errorIds = getAllPossibleErrorIds(vmDetails);
            clearRedeployErrors([...errorIds]);
        }
    }
    render() {
        const { vmListViewModel, resetEnvironments, clearLoadError, clearLabUpdateError, clearLabCapacityExceedsCoresError, clearResetError, } = this.props;
        const pageTitle = this.props.intl.formatMessage(messages.pageTitle);
        return (<>
                <PageTitle subtitle={pageTitle}/>
                <VmList vmListViewModel={vmListViewModel} clearLabUpdateError={clearLabUpdateError} clearLabCapacityExceedsCoresError={clearLabCapacityExceedsCoresError} clearLoadError={clearLoadError} clearStartError={this._clearStartError} clearStopError={this._clearStopError} clearResetError={clearResetError} updateCapacity={this._updateLabCapacity} startEnvironments={this._startEnvironments} stopEnvironments={this._stopEnvironments} resetEnvironments={resetEnvironments} getEnvironmentNetworkData={this._getEnvironmentNetworkData} navigateRoute={this._navigateRoute} redeployVirtualMachines={this._redeployVirtualMachines} clearRedeployError={this._clearRedeployError}/>
            </>);
    }
}
const mapStateToProps = (state) => {
    return getVmListContainerModel(state);
};
const mapDispatchToProps = {
    updateLab,
    updateVNextLab,
    clearLabUpdateError,
    clearLabCapacityExceedsCoresError,
    startEnvironments,
    stopEnvironments,
    resetEnvironments,
    clearLoadError,
    clearStartError,
    clearStopError,
    clearResetError,
    pollEnvironmentsStart,
    pollEnvironmentsStop,
    getEnvironment,
    pollUsersStart,
    pollUsersStop,
    push: routerActions.push,
    clearStartErrors,
    clearStopErrors,
    redeployVirtualMachines,
    clearRedeployErrors,
};
export const VmListContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(VmListContainerInjected));
export default VmListContainer;
