export async function createAndDownloadFile(fileName, data, forceUtf8 = false) {
    const isAscii = !forceUtf8 && data.toString().match(/[\u0000-\u00FF]/);
    const blob = new Blob([(isAscii ? '' : '\ufeff') + data], {
        type: `application/x-rdp;charset=${isAscii ? 'us-ascii' : 'utf-8'};`,
    });
    if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveOrOpenBlob(blob, fileName);
    }
    else {
        const fileData = await readBlob(blob);
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', fileData);
        downloadLink.setAttribute('download', fileName);
        downloadLink.style.visibility = 'hidden';
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }
}
async function readBlob(blob) {
    const promise = new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = (event) => reject(event?.target?.error || new Error('Failed to create file.'));
        reader.readAsDataURL(blob);
    });
    const data = await promise;
    return data;
}
