import { LabsApiModels } from 'lab-services-internal';
import memoize from 'memoize-one';
import { ClassicSkuFriendlyName, getLocalizedSkuFriendlyName } from '../sku-display-name-helper';
import { caseInsensitiveCultureInvariantCompare } from '../../utils/string-comparison';
import { trim } from 'lodash';
export const SkuConstants = {
    vCPUs: 'vCPUs',
    MemoryGB: 'MemoryGB',
    StorageGB: 'StorageGB',
    StorageType: 'StorageType',
    IsGpu: 'IsGpu',
    StandardSSD: 'StandardSSD',
    PremiumSSD: 'PremiumSSD',
    Classic: 'Classic',
};
export const SkuFamilyName = {
    Fsv2: 'Fsv2',
    Av2: 'Av2',
    Dsv4: 'Dsv4',
    Dsv3: 'Dsv3',
    NCsv3: 'NCsv3',
    NCasT4v3: 'NCasT4v3',
    NC: 'NC',
    NVv4: 'NVv4',
    NVv3: 'NVv3',
    NVadsA10v5: 'NVadsA10v5',
    NV: 'NV',
    Esv4: 'Esv4',
};
export const getSkuCostTargetUnit = memoize((isWindows) => {
    return isWindows ? LabsApiModels.PriceUnit.WindowsHourly : LabsApiModels.PriceUnit.LinuxHourly;
});
const getSkuCapabilityValue = (capabilities, target) => {
    const targetCapability = capabilities.find((c) => caseInsensitiveCultureInvariantCompare(c.name, target) === 0);
    if (!!targetCapability && !!targetCapability.value) {
        return targetCapability.value;
    }
    return '';
};
const getSkuCapabilityAsNumber = (capabilities, target) => {
    const targetValue = getSkuCapabilityValue(capabilities, target);
    const targetAsNumber = Number(targetValue);
    if (!isNaN(targetAsNumber)) {
        return targetAsNumber;
    }
    return 0;
};
const getSkuCapabilityIsGpu = (capabilities) => {
    const targetValue = getSkuCapabilityValue(capabilities, SkuConstants.IsGpu);
    return !!targetValue && caseInsensitiveCultureInvariantCompare(targetValue, 'True') === 0;
};
export const convertSkuCapabilityToVmSzie = memoize((capabilities, size) => {
    return {
        cores: getSkuCapabilityAsNumber(capabilities, SkuConstants.vCPUs),
        memoryGB: getSkuCapabilityAsNumber(capabilities, SkuConstants.MemoryGB),
        storageGB: getSkuCapabilityAsNumber(capabilities, SkuConstants.StorageGB),
        storageType: getSkuCapabilityValue(capabilities, SkuConstants.StorageType),
        isGpu: getSkuCapabilityIsGpu(capabilities),
        sizeFullName: size ?? '',
    };
});
const getClassicSkuFriendlyName = (sku) => {
    const skuName = sku.name.toUpperCase();
    switch (skuName) {
        case 'CLASSIC_FSV2_2_4GB_128_S_SSD':
            return ClassicSkuFriendlyName.Small;
        case 'CLASSIC_FSV2_4_8GB_128_S_SSD':
            return ClassicSkuFriendlyName.Medium;
        case 'CLASSIC_DSV4_4_16GB_128_P_SSD':
            return ClassicSkuFriendlyName.MediumNestedVirtualization;
        case 'CLASSIC_FSV2_8_16GB_128_S_SSD':
            return ClassicSkuFriendlyName.Large;
        case 'CLASSIC_DSV4_8_32GB_128_P_SSD':
            return ClassicSkuFriendlyName.LargeNestedVirtualization;
        case 'CLASSIC_NCSV3_6_112GB_128_S_SSD':
            return ClassicSkuFriendlyName.SmallGpuCompute;
        case 'CLASSIC_NVV4_8_28GB_128_S_SSD':
            return ClassicSkuFriendlyName.SmallGpuVisualization;
        case 'CLASSIC_NVV3_12_112GB_128_S_SSD':
            return ClassicSkuFriendlyName.MediumGpuVisualization;
        default:
            return '';
    }
};
export const getSkuFriendlyName = memoize((sku) => {
    if (sku.isClassic) {
        return getClassicSkuFriendlyName(sku);
    }
    const familyName = sku.family;
    let displayName = '';
    switch (familyName) {
        case SkuFamilyName.Fsv2:
            displayName += 'Basic';
            break;
        case SkuFamilyName.Av2:
        case SkuFamilyName.Dsv3:
        case SkuFamilyName.NC:
        case SkuFamilyName.NV:
            displayName += 'Classic';
            break;
        case SkuFamilyName.Dsv4:
            displayName += 'Standard';
            break;
        case SkuFamilyName.NCsv3:
        case SkuFamilyName.NVadsA10v5:
            displayName += 'Alternative';
            break;
        default:
            break;
    }
    switch (sku.size.cores) {
        case 2:
            displayName += ' Small';
            break;
        case 4:
            displayName += familyName === SkuFamilyName.Esv4 || sku.size.isGpu ? ' Small' : ' Medium';
            break;
        case 6:
        case 8:
            displayName += sku.size.isGpu ? ' Small' : familyName === SkuFamilyName.Esv4 ? ' Medium' : ' Large';
            break;
        case 12:
        case 16:
            displayName += sku.size.isGpu ? ' Medium' : ' Experimental 16 Large';
            break;
        case 32:
            displayName += ' Experimental 32 Large';
            break;
        default:
            break;
    }
    switch (familyName) {
        case SkuFamilyName.NCsv3:
        case SkuFamilyName.NCasT4v3:
        case SkuFamilyName.NC:
        case SkuFamilyName.NVv4:
        case SkuFamilyName.NVv3:
        case SkuFamilyName.NVadsA10v5:
        case SkuFamilyName.NV:
            displayName += ' GPU';
            break;
        default:
            break;
    }
    switch (familyName) {
        case SkuFamilyName.Dsv3:
            displayName += ' (Nested virtualization)';
            break;
        case SkuFamilyName.NCsv3:
        case SkuFamilyName.NCasT4v3:
        case SkuFamilyName.NC:
            displayName += ' (Compute)';
            break;
        case SkuFamilyName.NVv4:
        case SkuFamilyName.NVv3:
        case SkuFamilyName.NVadsA10v5:
        case SkuFamilyName.NV:
            displayName += ' (Visualization)';
            break;
        case SkuFamilyName.Esv4:
            displayName += ' (Memory-optimized)';
            break;
        default:
            break;
    }
    displayName = trim(displayName);
    return displayName;
});
export const getLocalizedSkuDisplayName = (sku, intl) => {
    const name = getSkuFriendlyName(sku);
    const localizedName = getLocalizedSkuFriendlyName(name, intl);
    const isClassic = isClassicSku(sku.name);
    // Removing sku.skuFamilyName.
    // TODO: Add back sku.skuFamilyName conditional to private preview.
    return isClassic || !sku.family ? localizedName : localizedName;
};
export const getLocalizedLabSkuDisplayName = (sku, intl) => {
    const skuFriendlyName = sku.skuFriendlyName;
    const friendlyName = !skuFriendlyName ? '' : getLocalizedSkuFriendlyName(skuFriendlyName, intl);
    // Removing sku.skuFamilyName.
    // TODO: Add back sku.skuFamilyName conditional to private preview.
    const skuDisplayName = isClassicSku(sku.skuName) || !sku.skuFamilyName ? friendlyName : friendlyName;
    return skuDisplayName;
};
export const getNumberOfRemainingVms = memoize((sku, location) => {
    const coresPerVm = sku.size.cores;
    const availableCores = getNumberOfAvailableCores(location);
    let numberOfVms = 0;
    if (coresPerVm !== 0 && availableCores !== 0) {
        numberOfVms = Math.floor(availableCores / coresPerVm);
    }
    return numberOfVms;
});
export const getNumberOfAvailableCores = memoize((location) => {
    return !!location?.usage && location.usage.limit >= location.usage.currentValue
        ? location.usage.limit - location.usage.currentValue
        : 0;
});
export const isClassicSku = memoize((skuName) => {
    return !!skuName && skuName.startsWith(SkuConstants.Classic);
});
export const isLegacySku = memoize((skuFamily) => {
    return !!skuFamily && (skuFamily === 'AV2' || skuFamily === 'DSV3' || skuFamily === 'NV' || skuFamily === 'NC');
});
