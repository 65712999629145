import { ResourceId } from '../../../../utils/resource-id';
import { createVNextArmApiClient } from '../../arm-data-provider';
import { v4 as uuid } from 'uuid';
export async function listUsers(labId, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(labId.subscriptionId, accessToken, locale, language);
    let currentPage = await armApiClient.users.listByLab(labId.resourceGroupName, labId.name);
    let users = currentPage;
    while (!!currentPage.nextLink) {
        const nextPage = await armApiClient.users.listByLabNext(currentPage.nextLink);
        currentPage = nextPage;
        users = users.concat(nextPage);
    }
    return users;
}
export async function deleteUser(userId, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(userId.subscriptionId, accessToken, locale, language);
    await armApiClient.users.beginDeleteMethod(userId.resourceGroupName, userId.parent.name, userId.name);
}
export async function updateUser(user, accessToken, locale, language, additionalUsageQuota) {
    const userResourceId = new ResourceId(user.id);
    const labResourceId = userResourceId.parent;
    const armApiClient = createVNextArmApiClient(userResourceId.subscriptionId, accessToken, locale, language);
    const newUser = await armApiClient.users.update(userResourceId.resourceGroupName, labResourceId.name, userResourceId.name, {
        additionalUsageQuota,
    });
    return newUser;
}
export async function addUser(labId, accessToken, locale, language, email) {
    const user = {
        email,
    };
    const armApiClient = createVNextArmApiClient(labId.subscriptionId, accessToken, locale, language);
    const newUser = await armApiClient.users.createOrUpdate(user, labId.resourceGroupName, labId.name, 
    // PUT method requires the labName in the api route. Use GUID for now when sending the request.
    uuid());
    return newUser;
}
export async function inviteUser(userId, accessToken, locale, language, text) {
    const armApiClient = createVNextArmApiClient(userId.subscriptionId, accessToken, locale, language);
    await armApiClient.users.beginInvite(userId.resourceGroupName, userId.parent.name, userId.name, {
        text,
    });
}
const UserProvider = {
    listUsers,
    deleteUser,
    updateUser,
    inviteUser,
    addUser,
};
export default UserProvider;
