import { CommonActionType } from '../common/common-actions';
import { CommonLabActionType } from '../lab/lab-actions';
import { CommonTemplateActionType } from '../template/template-actions';
import { CommonLabParentResourceActionType } from '../lab-parent-resource/lab-parent-resource-actions';
export const CoreLimitActionType = {
    ...CommonActionType,
    ...CommonLabParentResourceActionType,
    ...CommonLabActionType,
    ...CommonTemplateActionType,
    GET_CORE_RESTRICTIONS_AND_USAGE: 'GET_CORE_RESTRICTIONS_AND_USAGE',
    GET_CORE_RESTRICTIONS_AND_USAGE_SUCCESS: 'GET_CORE_RESTRICTIONS_AND_USAGE_SUCCESS',
    GET_CORE_RESTRICTIONS_AND_USAGE_ERROR: 'GET_CORE_RESTRICTIONS_AND_USAGE_ERROR',
    GET_CORE_RESTRICTIONS_AND_USAGE_CANCELLED: 'GET_CORE_RESTRICTIONS_AND_USAGE_CANCELLED',
};
