import { Icon, ProgressIndicator, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ImageAndSizeInfo } from '../common/image-and-size-info';
import { getCustomTheme } from '../common/themes/selectors';
const LabCreatingView = (props) => {
    const { secondaryBackgroundColor } = getCustomTheme();
    return (<Stack horizontalAlign="center" grow verticalFill styles={{ root: { backgroundColor: secondaryBackgroundColor } }}>
            <Stack.Item>
                <Icon iconName="creating-template" styles={{ root: { marginTop: 140, marginBottom: 20 } }}/>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontalAlign="start">
                    <Stack.Item>
                        <div className="ms-fontSize-m ms-fontWeight-semibold">
                            <FormattedMessage id="LabCreatingViewMessage" defaultMessage="Creating lab" description="Message shown when the lab is being created."/>
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <ProgressIndicator barHeight={4} description={<>
                                    <FormattedMessage id="LabCreatingViewDescription" defaultMessage="Setting up virtual machine template." description="Description shown below the progress bar when the lab is being created."/>
                                    <ImageAndSizeInfo {...props}/>
                                </>} styles={{
        root: { minWidth: 250, width: '100%', marginBottom: 15 },
    }}/>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>);
};
export default LabCreatingView;
