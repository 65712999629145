import { call, put, select, takeLatest } from 'redux-saga/effects';
import { resolveSearchLocale } from '../../../language/languages';
import { getQueryParams } from '../../../utils/query-params';
import { trackException } from '../../../utils/telemetry/telemetry-channel';
import { setHost, setLocale, setReferrer } from '../../actions/common/common-action-creators';
import { CommonActionType } from '../../actions/common/common-actions';
import { setTenantId, setLoginHint } from '../../actions/identity/identity-action-creators';
import { setGroupId, enableFilterByGroupId } from '../../actions/group/group-action-creators';
import { getGroupId } from '../../selectors/group-selectors';
import { getHost, getLocale } from '../../selectors/common-selectors';
import { getTenantId, getLoginHint } from '../../selectors/identity-selector';
export function* processQueryParams(action) {
    try {
        const { search } = action;
        const queryParams = yield call(getQueryParams, search);
        const locale = yield select(getLocale);
        const host = yield select(getHost);
        const tenantId = yield select(getTenantId);
        const groupId = yield select(getGroupId);
        const loginHint = yield select(getLoginHint);
        const queryLocale = yield call(resolveSearchLocale, queryParams);
        if (locale !== queryLocale) {
            yield put(setLocale(queryLocale));
        }
        const { host: queryHost, tenantId: queryTenantId, groupId: queryGroupId, loginHint: queryLoginHint, referrer, } = queryParams;
        if (host !== queryHost && !!queryHost) {
            yield put(setHost(queryHost));
        }
        // the default tenant ID gets set as part of processing the ID token claims on a successful sign in
        // so if it is already set and it matches the query param we are also in single tenant mode, which gets
        // set as part of putting setTenantId on the store
        if ((!!tenantId && tenantId === queryTenantId) || (!tenantId && !!queryTenantId)) {
            yield put(setTenantId(queryTenantId));
        }
        if (!groupId && !!queryGroupId) {
            yield put(setGroupId(queryGroupId));
            yield put(enableFilterByGroupId());
        }
        if (!loginHint && !!queryLoginHint) {
            yield put(setLoginHint(queryLoginHint));
        }
        if (!!referrer) {
            yield put(setReferrer(referrer));
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* processQueryParamsSaga() {
    yield takeLatest(CommonActionType.PROCESS_QUERY_PARAMS, processQueryParams);
}
