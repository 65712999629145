import { Record } from 'immutable';
import { List, Map } from 'immutable';
import { LabPlanActionType, } from '../../actions/lab-plan/lab-plan-actions';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { addToListMap } from '../../../utils/common';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
function addOrUpdateLabPlan(labPlan, labPlans) {
    const index = labPlans.findIndex((o) => caseInsensitiveCultureInvariantCompare(o.id, labPlan.id) === 0);
    if (index > -1) {
        const oldLabPlan = labPlans.get(index);
        return labPlans.set(index, { ...labPlan, roles: oldLabPlan.roles });
    }
    else {
        return labPlans.push(labPlan);
    }
}
function listLabPlans(state, action) {
    return state.merge({
        errors: Map(),
        loadState: LoadingStoreState.Loading,
    });
}
function listLabPlansSuccess(state, action) {
    const { labPlans } = action;
    return state.merge({
        loadState: LoadingStoreState.Loaded,
        labPlans: List(labPlans),
    });
}
function selectLabPlan(state, action) {
    const { labPlanId } = action;
    return state.merge({
        currentLabPlanId: labPlanId,
    });
}
function loadSubscriptionLabPlansError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addToListMap(errors, error, error.id);
    return state.merge({
        errors,
    });
}
function getLabPlan(state, action) {
    return state.merge({
        isGettingLabPlan: true,
        getLabPlanError: undefined,
    });
}
function getLabPlanSuccess(state, action) {
    const { labPlan } = action;
    let labPlans = state.get('labPlans');
    labPlans = addOrUpdateLabPlan(labPlan, labPlans);
    return state.merge({
        labPlans,
        isGettingLabPlan: false,
    });
}
function getLabPlanError(state, action) {
    const { error } = action;
    return state.merge({
        getLabPlanError: error,
        isGettingLabPlan: false,
    });
}
function updateLabPlansLmsSettings(state, action) {
    return state.merge({
        isUpdatingLms: true,
    });
}
function updateLabPlansLmsSettingsSuccess(state, action) {
    const { labPlans: updatedLabPlans } = action;
    let labPlans = state.get('labPlans');
    updatedLabPlans.forEach((o) => (labPlans = addOrUpdateLabPlan(o, labPlans)));
    return state.merge({
        isUpdatingLms: false,
        labPlans,
    });
}
function updateLabPlansLmsSettingsError(state, action) {
    const { errors } = action;
    const labPlanIds = Object.keys(errors);
    let updateLmsErrors = state.get('updateLmsErrors');
    labPlanIds.map((id) => {
        updateLmsErrors = updateLmsErrors.set(id, errors[id]);
    });
    return state.merge({
        updateLmsErrors,
        isUpdatingLms: false,
    });
}
function clearUpdateLinkedLmsError(state, action) {
    const { id } = action;
    let updateLmsErrors = state.get('updateLmsErrors');
    if (id) {
        updateLmsErrors = updateLmsErrors.remove(id);
    }
    else {
        updateLmsErrors = Map();
    }
    return state.merge({
        updateLmsErrors,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    labPlans: List(),
    errors: Map(),
    currentLabPlanId: '',
    isGettingLabPlan: false,
    getLabPlanError: undefined,
    isUpdatingLms: false,
    updateLmsErrors: Map(),
})();
export const labPlanReducer = (state = initialState, action) => {
    switch (action.type) {
        case LabPlanActionType.CLEAR_USER_SESSION:
        case LabPlanActionType.SELECT_TENANT:
            return state.merge(initialState);
        case LabPlanActionType.LIST_LAB_PLANS:
            return listLabPlans(state, action);
        case LabPlanActionType.LIST_LAB_PLANS_SUCCESS:
            return listLabPlansSuccess(state, action);
        case LabPlanActionType.SELECT_LAB_PLAN:
            return selectLabPlan(state, action);
        case LabPlanActionType.LOAD_SUBSCRIPTION_LAB_PLANS_ERROR:
            return loadSubscriptionLabPlansError(state, action);
        case LabPlanActionType.GET_LAB_PLAN:
            return getLabPlan(state, action);
        case LabPlanActionType.GET_LAB_PLAN_SUCCESS:
            return getLabPlanSuccess(state, action);
        case LabPlanActionType.GET_LAB_PLAN_ERROR:
            return getLabPlanError(state, action);
        case LabPlanActionType.UPDATE_LAB_PLANS_LMS_SETTINGS:
            return updateLabPlansLmsSettings(state, action);
        case LabPlanActionType.UPDATE_LAB_PLANS_LMS_SETTINGS_SUCCESS:
            return updateLabPlansLmsSettingsSuccess(state, action);
        case LabPlanActionType.UPDATE_LAB_PLANS_LMS_SETTINGS_ERROR:
            return updateLabPlansLmsSettingsError(state, action);
        case LabPlanActionType.CLEAR_UPDATE_LMS_ERROR:
            return clearUpdateLinkedLmsError(state, action);
        default:
            return state;
    }
};
export default labPlanReducer;
