import { List, Map, Record, Set } from 'immutable';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { EnvironmentActionType, } from '../../actions/environment/environment-actions';
import { VmState } from '../../../data/models/environment-common';
import { Context } from '../../action-context';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { OperationsResultActionType, } from '../../actions/operations/operations-result-actions';
function listVirtualMachines(state, action) {
    const oldLoadState = state.get('loadState');
    return state.merge({
        loadError: undefined,
        loadState: oldLoadState === LoadingStoreState.Loaded ? LoadingStoreState.Updating : LoadingStoreState.Loading,
    });
}
function listVirtualMachinesCancelled(state, action) {
    const oldLoadState = state.get('loadState');
    return state.merge({
        loadState: oldLoadState === LoadingStoreState.Updating ? LoadingStoreState.Loaded : LoadingStoreState.NotStarted,
    });
}
export function listVirtualMachinesSuccess(state, action) {
    const { virtualMachines } = action;
    return state.merge({
        virtualMachines: List(virtualMachines),
        loadState: LoadingStoreState.Loaded,
    });
}
function listVirtualMachinesError(state, action) {
    const { error: loadError } = action;
    const oldState = state.get('loadState');
    return state.merge({
        loadState: oldState === LoadingStoreState.Updating ? LoadingStoreState.UpdateFailed : LoadingStoreState.LoadingFailed,
        loadError,
    });
}
function startVirtualMachines(state, action) {
    const { environmentIds: virtualMachineIds } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState(virtualMachineIds, virtualMachines, VmState.Starting, true);
    return state.merge({
        virtualMachines,
    });
}
function startVirtualMachinesAccepted(state, action) {
    const { operationIds } = action;
    const prevOperations = state.get('operations') || List();
    const operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function resetVirtualMachinesAccepted(state, action) {
    const { operationIds } = action;
    const prevOperations = state.get('operations') || List();
    const operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function redeployVirtualMachinesAccepted(state, action) {
    const { operationIds } = action;
    const prevOperations = state.get('operations') || List();
    const operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function pollOperationsResultSuccess(state, action) {
    const { operationIds } = action;
    const prevOperations = state.get('operations') || List();
    const operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function pollOperationsResultError(state, action) {
    const { error } = action;
    let operationsError = state.get('operationsError');
    operationsError = error;
    return state.merge({
        operationsError,
    });
}
export function mergeOperations(operations, operationIds) {
    const prevOperationsArr = operations.toArray();
    operationIds.map((operation) => {
        const index = prevOperationsArr.findIndex((prevOp) => prevOp.ResourceId?.id.toLocaleLowerCase() === operation.ResourceId?.id.toLocaleLowerCase());
        if (index > -1) {
            operations = operations.set(index, operation);
        }
        else {
            operations = operations.push(operation);
        }
    });
    return operations;
}
function startVirtualMachinesError(state, action) {
    const { errors } = action;
    const virtualMachineIds = Object.keys(errors);
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState(virtualMachineIds, virtualMachines, VmState.None, false);
    const startErrors = state.get('startErrors');
    return state.merge({
        virtualMachines,
        startErrors: addErrors(virtualMachineIds, startErrors, errors),
    });
}
function stopVirtualMachines(state, action) {
    const { environmentIds: virtualMachineIds } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState(virtualMachineIds, virtualMachines, VmState.Stopping, true);
    return state.merge({
        virtualMachines,
    });
}
function stopVirtualMachinesAccepted(state, action) {
    const { operationIds } = action;
    const prevOperations = state.get('operations') || List();
    const operations = mergeOperations(prevOperations, operationIds);
    return state.merge({
        operations,
    });
}
function stopVirutalMachinesError(state, action) {
    const { errors } = action;
    const virtualMachineIds = Object.keys(errors);
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState(virtualMachineIds, virtualMachines, VmState.None, false);
    const stopErrors = state.get('stopErrors');
    return state.merge({
        virtualMachines,
        stopErrors: addErrors(virtualMachineIds, stopErrors, errors),
    });
}
function resetVirtualMachines(state, action) {
    const { environmentIds: virtualMachineIds } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState(virtualMachineIds, virtualMachines, VmState.Reimaging, true);
    return state.merge({
        virtualMachines,
        resetErrors: Map(),
    });
}
function resetVirtualMachinesError(state, action) {
    const { errors } = action;
    const virtualMachineIds = Object.keys(errors);
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState(virtualMachineIds, virtualMachines, VmState.None, false);
    let resetErrors = state.get('resetErrors');
    resetErrors = addErrors(virtualMachineIds, resetErrors, errors);
    return state.merge({
        virtualMachines,
        resetErrors,
    });
}
function getVirtualMachine(state, action) {
    const { id } = action;
    let getErrors = state.get('getErrors');
    getErrors = getErrors.remove(id);
    let gettingVirtualMachines = state.get('gettingVirtualMachines');
    gettingVirtualMachines = gettingVirtualMachines.add(id);
    return state.merge({
        getErrors,
        gettingVirtualMachines,
    });
}
function getVirtualMachineSuccess(state, action) {
    const { virtualMachine } = action;
    let virtualMachines = state.get('virtualMachines');
    let gettingVirtualMachines = state.get('gettingVirtualMachines');
    gettingVirtualMachines = gettingVirtualMachines.remove(virtualMachine.id);
    virtualMachines = mergeVirtualMachine(virtualMachine, virtualMachines);
    return state.merge({
        virtualMachines,
        gettingVirtualMachines,
    });
}
function getVirtualMachineCancelled(state, action) {
    const { id } = action;
    let gettingVirtualMachines = state.get('gettingVirtualMachines');
    gettingVirtualMachines = gettingVirtualMachines.remove(id);
    return state.merge({
        gettingVirtualMachines,
    });
}
function getVirtualMachineError(state, action) {
    const { id, error } = action;
    let getErrors = state.get('getErrors');
    let gettingVirtualMachines = state.get('gettingVirtualMachines');
    gettingVirtualMachines = gettingVirtualMachines.remove(id);
    getErrors = getErrors.set(id, error);
    return state.merge({
        getErrors,
        gettingVirtualMachines,
    });
}
function clearLoadError(state, action) {
    return state.merge({
        loadError: undefined,
    });
}
function clearStartErrors(state, action) {
    const { ids } = action;
    let startErrors = state.get('startErrors');
    startErrors = clearErrors(ids, startErrors);
    return state.merge({
        startErrors,
    });
}
function clearStopErrors(state, action) {
    const { ids } = action;
    let stopErrors = state.get('stopErrors');
    stopErrors = clearErrors(ids, stopErrors);
    return state.merge({
        stopErrors,
    });
}
function clearResetError(state, action) {
    const { id } = action;
    let resetErrors = state.get('resetErrors');
    resetErrors = clearError(resetErrors, id);
    return state.merge({
        resetErrors,
    });
}
function addErrors(ids, errorMap, errors) {
    ids.map((id) => (errorMap = errorMap.set(id, errors[id])));
    return errorMap;
}
function clearErrors(ids, errorMap) {
    ids.map((id) => {
        errorMap = clearError(errorMap, id);
    });
    return errorMap;
}
function clearError(errorMap, id) {
    if (!!id) {
        return errorMap.remove(id);
    }
    return Map();
}
function setPendingState(virtualMachineIds, virtualMachines, pendingVmState, isUpdatePending) {
    virtualMachineIds.map((id) => {
        const index = virtualMachines.findIndex((vm) => caseInsensitiveCultureInvariantCompare(vm.id, id) === 0);
        if (index > -1) {
            virtualMachines = virtualMachines.update(index, (vm) => {
                return { ...vm, pendingVmState, isUpdatePending };
            });
        }
    });
    return virtualMachines;
}
function mergeVirtualMachine(newVirtualMachine, virtualMachines) {
    const index = virtualMachines.findIndex((vm) => caseInsensitiveCultureInvariantCompare(vm.id, newVirtualMachine.id) === 0);
    if (index > -1) {
        virtualMachines = virtualMachines.set(index, newVirtualMachine);
    }
    else {
        virtualMachines = virtualMachines.push(newVirtualMachine);
    }
    return virtualMachines;
}
function resetTemplatePassword(state, action) {
    const { templateId } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState([templateId], virtualMachines, VmState.ResettingPassword, true);
    return state.merge({
        virtualMachines,
        resetPasswordError: undefined,
    });
}
function resetTemplatePasswordError(state, action) {
    const { error } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState([error.id], virtualMachines, VmState.None, false);
    return state.merge({
        virtualMachines,
        resetPasswordError: error,
    });
}
function clearVirtualMachineGetError(state, action) {
    const { id } = action;
    let getErrors = state.get('getErrors');
    getErrors = clearError(getErrors, id);
    return state.merge({
        getErrors,
    });
}
function clearResetPasswordError(state, action) {
    return state.merge({
        resetPasswordError: undefined,
    });
}
function saveImage(state, action) {
    return state.merge({
        shouldShowExportStartedMessage: true,
        exportedImageName: action.displayName,
    });
}
function updateImage(state, action) {
    return state.merge({
        shouldShowExportStartedMessage: true,
    });
}
function saveImageError(state, action) {
    const { error } = action;
    return state.merge({
        shouldShowExportStartedMessage: false,
        saveImageError: error,
    });
}
function clearExportError(state, action) {
    return state.merge({
        saveImageError: undefined,
    });
}
function dismissExportStartedMessageBar(state, action) {
    return state.merge({
        shouldShowExportStartedMessage: false,
    });
}
function redeployVirtualMachines(state, action) {
    const { environmentIds: virtualMachineIds } = action;
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState(virtualMachineIds, virtualMachines, VmState.Redeploying, true);
    return state.merge({
        virtualMachines,
        redeployErrors: Map(),
    });
}
function redeployVirtualMachinesError(state, action) {
    const { errors } = action;
    const virtualMachineIds = Object.keys(errors);
    let virtualMachines = state.get('virtualMachines');
    virtualMachines = setPendingState(virtualMachineIds, virtualMachines, VmState.None, false);
    let redeployErrors = state.get('redeployErrors');
    redeployErrors = addErrors(virtualMachineIds, redeployErrors, errors);
    return state.merge({
        virtualMachines,
        redeployErrors,
    });
}
function clearRedeployErrors(state, action) {
    const { ids } = action;
    let redeployErrors = state.get('redeployErrors');
    redeployErrors = clearErrors(ids, redeployErrors);
    return state.merge({
        redeployErrors,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    virtualMachines: List(),
    gettingVirtualMachines: Set(),
    loadError: undefined,
    getErrors: Map(),
    startErrors: Map(),
    stopErrors: Map(),
    resetErrors: Map(),
    resetPasswordError: undefined,
    shouldShowExportStartedMessage: false,
    exportedImageName: '',
    saveImageError: undefined,
    redeployErrors: Map(),
    operations: List(),
    operationsError: undefined,
})();
export const vNextVirtualMachineReducer = (state = initialState, action) => {
    switch (action.type) {
        case EnvironmentActionType.CLEAR_USER_SESSION:
        case EnvironmentActionType.SELECT_LAB_PARENT_RESOURCE:
        case EnvironmentActionType.SELECT_LAB:
        case EnvironmentActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    switch (action.type) {
                        case EnvironmentActionType.LIST_ENVIRONMENTS:
                            return listVirtualMachines(state, action);
                        case EnvironmentActionType.LIST_ENVIRONMENTS_CANCELLED:
                            return listVirtualMachinesCancelled(state, action);
                        case EnvironmentActionType.LIST_ENVIRONMENTS_SUCCESS:
                            return listVirtualMachinesSuccess(state, action);
                        case EnvironmentActionType.LIST_ENVIRONMENTS_ERROR:
                            return listVirtualMachinesError(state, action);
                        case EnvironmentActionType.START_ENVIRONMENTS:
                            return startVirtualMachines(state, action);
                        case EnvironmentActionType.START_ENVIRONMENTS_ACCEPTED:
                            return startVirtualMachinesAccepted(state, action);
                        case EnvironmentActionType.START_ENVIRONMENTS_ERROR:
                            return startVirtualMachinesError(state, action);
                        case EnvironmentActionType.STOP_ENVIRONMENTS:
                            return stopVirtualMachines(state, action);
                        case EnvironmentActionType.STOP_ENVIRONMENTS_ERROR:
                            return stopVirutalMachinesError(state, action);
                        case EnvironmentActionType.STOP_ENVIRONMENTS_ACCEPTED:
                            return stopVirtualMachinesAccepted(state, action);
                        case EnvironmentActionType.RESET_ENVIRONMENTS:
                            return resetVirtualMachines(state, action);
                        case EnvironmentActionType.RESET_ENVIRONMENTS_ERROR:
                            return resetVirtualMachinesError(state, action);
                        case EnvironmentActionType.RESET_ENVIRONMENTS_ACCEPTED:
                            return resetVirtualMachinesAccepted(state, action);
                        case EnvironmentActionType.GET_ENVIRONMENT:
                            return getVirtualMachine(state, action);
                        case EnvironmentActionType.GET_ENVIRONMENT_SUCCESS:
                            return getVirtualMachineSuccess(state, action);
                        case EnvironmentActionType.GET_ENVIRONMENT_CANCELLED:
                            return getVirtualMachineCancelled(state, action);
                        case EnvironmentActionType.GET_ENVIRONMENT_ERROR:
                            return getVirtualMachineError(state, action);
                        case EnvironmentActionType.CLEAR_ENVIRONMENTS_LOAD_ERROR:
                            return clearLoadError(state, action);
                        case EnvironmentActionType.CLEAR_ENVIRONMENTS_START_ERROR:
                            return clearStartErrors(state, action);
                        case EnvironmentActionType.CLEAR_ENVIRONMENTS_STOP_ERROR:
                            return clearStopErrors(state, action);
                        case EnvironmentActionType.CLEAR_ENVIRONMENTS_RESET_ERROR:
                            return clearResetError(state, action);
                        case EnvironmentActionType.RESET_PASSWORD:
                            return resetTemplatePassword(state, action);
                        case EnvironmentActionType.RESET_PASSWORD_ERROR:
                            return resetTemplatePasswordError(state, action);
                        case EnvironmentActionType.CLEAR_VIRTUAL_MACHINE_GET_ERROR:
                            return clearVirtualMachineGetError(state, action);
                        case EnvironmentActionType.CLEAR_VIRTUAL_MACHINE_RESET_PASSWORD_ERROR:
                            return clearResetPasswordError(state, action);
                        case EnvironmentActionType.CREATE_SHARED_IMAGE:
                            return saveImage(state, action);
                        case EnvironmentActionType.UPDATE_SHARED_IMAGE:
                            return updateImage(state, action);
                        case EnvironmentActionType.CREATE_SHARED_IMAGE_ERROR:
                            return saveImageError(state, action);
                        case EnvironmentActionType.UPDATE_SHARED_IMAGE_ERROR:
                            return saveImageError(state, action);
                        case EnvironmentActionType.CLEAR_EXPORT_ERROR:
                            return clearExportError(state, action);
                        case EnvironmentActionType.DISMISS_EXPORT_STARTED_MESSAGE_BAR:
                            return dismissExportStartedMessageBar(state, action);
                        case EnvironmentActionType.REDEPLOY_VIRTUAL_MACHINE:
                            return redeployVirtualMachines(state, action);
                        case EnvironmentActionType.REDEPLOY_VIRTUAL_MACHINE_ERROR:
                            return redeployVirtualMachinesError(state, action);
                        case EnvironmentActionType.REDEPLOY_VIRTUAL_MACHINE_ACCEPTED:
                            return redeployVirtualMachinesAccepted(state, action);
                        case EnvironmentActionType.CLEAR_VIRTUAL_MACHINE_REDEPLOY_ERROR:
                            return clearRedeployErrors(state, action);
                        case OperationsResultActionType.POLL_OPERATIONS_RESULT_SUCCESS:
                            return pollOperationsResultSuccess(state, action);
                        default:
                            return state;
                    }
                default:
                    return state;
            }
    }
};
export default vNextVirtualMachineReducer;
