import { CoreLimitActionType, } from './core-limit-actions';
export const getCoreRestrictionsAndUsages = (id) => ({
    type: CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE,
    id,
});
export const getCoreRestrictionsAndUsagesSuccess = (coreRestrictionsAndUsages) => ({
    type: CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_SUCCESS,
    coreRestrictionsAndUsages,
});
export const getCoreRestrictionsAndUsagesError = (id, error) => ({
    type: CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_ERROR,
    id,
    error,
});
export const getCoreRestrictionsAndUsagesCancelled = () => ({
    type: CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_CANCELLED,
});
export const getVNextCoreUsagesSuccess = (coreUsage) => ({
    type: CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_SUCCESS,
    coreUsage,
});
const actions = {
    getCoreRestrictionsAndUsages,
    getCoreRestrictionsAndUsagesSuccess,
    getCoreRestrictionsAndUsagesError,
    getCoreRestrictionsAndUsagesCancelled,
    getVNextCoreUsagesSuccess,
};
export default actions;
