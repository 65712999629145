import { Record } from 'immutable';
import { List, Map } from 'immutable';
import { LabAccountActionType, } from '../actions/lab-account/lab-account-actions';
import { addToListMap } from '../../utils/common';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { InitializationState } from '../state/common-store';
function listLabAccounts(state, action) {
    return state.merge({
        refreshError: undefined,
        errors: Map(),
        loadState: state.loadState === LoadingStoreState.Loaded || state.loadState === LoadingStoreState.Updated
            ? LoadingStoreState.Updating
            : LoadingStoreState.Loading,
    });
}
function listLabAccountsSuccess(state, action) {
    const { labAccounts } = action;
    return state.merge({
        loadState: state.loadState === LoadingStoreState.Updating ? LoadingStoreState.Updated : LoadingStoreState.Loaded,
        labAccounts: List(labAccounts),
    });
}
function listLabAccountsError(state, action) {
    return state.merge({
        loadState: state.loadState === LoadingStoreState.Updating
            ? LoadingStoreState.UpdateFailed
            : LoadingStoreState.LoadingFailed,
    });
}
function refreshSubscriptionsAndLabAccounts(state, action) {
    return state.merge({
        refreshInitializationState: InitializationState.Initializing,
    });
}
function refreshSubscriptionsAndLabAccountsSuccess(state, action) {
    return state.merge({
        refreshInitializationState: InitializationState.Success,
    });
}
function refreshSubscriptionsAndLabAccountsError(state, action) {
    const { refreshError } = action;
    return state.merge({
        refreshInitializationState: InitializationState.Failed,
        refreshError,
    });
}
function loadSubscriptionLabAccountsError(state, action) {
    const { subscription, error } = action;
    let errors = state.get('errors');
    errors = addToListMap(errors, error, subscription.id);
    return state.merge({
        errors,
    });
}
function getLabAccount(state, action) {
    return state.merge({
        isGettingLabAccount: true,
    });
}
function getLabAccountSuccess(state, action) {
    const { labAccount } = action;
    let labAccounts = state.get('labAccounts');
    const index = labAccounts.findIndex((l) => l.id === labAccount.id);
    if (index > -1) {
        labAccounts = labAccounts.set(index, labAccount);
    }
    else {
        labAccounts = labAccounts.push(labAccount);
    }
    return state.merge({
        isGettingLabAccount: false,
        labAccounts,
    });
}
function getLabAccountError(state, action) {
    const { id, error: getLabAccountError } = action;
    let errors = state.get('errors');
    errors = addToListMap(errors, getLabAccountError, id);
    return state.merge({
        isGettingLabAccount: false,
        errors,
        getLabAccountError,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    isGettingLabAccount: false,
    labAccounts: List(),
    errors: Map(),
    getLabAccountError: undefined,
    refreshInitializationState: InitializationState.NotStarted,
    refreshError: undefined,
})();
export const labAccountReducer = (state = initialState, action) => {
    switch (action.type) {
        case LabAccountActionType.LIST_LAB_ACCOUNTS:
            return listLabAccounts(state, action);
        case LabAccountActionType.LIST_LAB_ACCOUNTS_SUCCESS:
            return listLabAccountsSuccess(state, action);
        case LabAccountActionType.LIST_LAB_ACCOUNTS_ERROR:
            return listLabAccountsError(state, action);
        case LabAccountActionType.LOAD_SUBSCRIPTION_LAB_ACCOUNTS_ERROR:
            return loadSubscriptionLabAccountsError(state, action);
        case LabAccountActionType.GET_LAB_ACCOUNT:
            return getLabAccount(state, action);
        case LabAccountActionType.GET_LAB_ACCOUNT_SUCCESS:
            return getLabAccountSuccess(state, action);
        case LabAccountActionType.GET_LAB_ACCOUNT_ERROR:
            return getLabAccountError(state, action);
        case LabAccountActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS:
            return refreshSubscriptionsAndLabAccounts(state, action);
        case LabAccountActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS_SUCCESS:
            return refreshSubscriptionsAndLabAccountsSuccess(state, action);
        case LabAccountActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS_ERROR:
            return refreshSubscriptionsAndLabAccountsError(state, action);
        case LabAccountActionType.CLEAR_USER_SESSION:
        case LabAccountActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            return state;
    }
};
export default labAccountReducer;
