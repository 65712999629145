import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { routerActions } from 'connected-react-router/immutable';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import PageTitle from '../common/page/page-title';
import { LabUpdateOperation } from '../data/models/lab';
import { clearLabUpdateError, pollLabStop, publishVNextLab, updateLab, updateVNextLab, clearLabsError, } from '../redux/actions/lab/lab-action-creators';
import { createSharedImage, getSharedGallery, listSharedImages, updateSharedImage, } from '../redux/actions/shared-image/shared-image-action-creators';
import { clearError, clearLoadTemplateError as clearLoadError, clearPublishExceedsCoresError, getTemplate, pollTemplateStart, pollTemplateStop, publishTemplate, resetPassword, startTemplate, stopTemplate, updateTemplate, } from '../redux/actions/template/template-action-creators';
import { DefaultPollingInterval, FiveSecondInterval, OneMinuteInterval, ThirtySecondInterval, } from '../utils/constants';
import { ResourceId } from '../utils/resource-id';
import CreatingView from './creating-view';
import { Template } from './template';
import templateMessages from './template-messages';
import { getTemplateContainerModel } from './template-selectors';
import LabNavKeys from '../utils/lab-nav-key';
import { getNavigationItems } from '../common/lab-nav-items';
import { listImages } from '../redux/actions/vnext/images/image-action-creators';
import { pollVirtualMachineStart, pollVirtualMachineStop, startEnvironments, stopEnvironments, resetEnvironments, clearLoadError as clearLoadVirtualMachinesError, clearGetError, clearStartErrors, clearStopErrors, clearResetPasswordError, dismissExportStartedMessageBar, clearExportError, redeployVirtualMachines, clearRedeployErrors, listEnvironments, } from '../redux/actions/environment/environment-action-creators';
import { caseInsensitiveCultureInvariantCompare } from '../utils/string-comparison';
import { FailureOperation } from '../data/ml-client-error';
import { getLabCapacity } from '../redux/selectors/vnext/lab-selectors';
import { determinePollingInitialDelay } from '../common/vm/vm-polling-initial-delay';
function determinePollingIntervalLength(template, isVNext) {
    const { isResettingPassword, isStarting, isStopping, isCreating, isPublishing, isScaling, isReimaging, isRunning } = template;
    if (isVNext && (isStarting || isStopping)) {
        return FiveSecondInterval;
    }
    else if (isResettingPassword || isStarting || isStopping || isReimaging) {
        return ThirtySecondInterval;
    }
    else if (isCreating || isPublishing || isScaling || isRunning) {
        return OneMinuteInterval;
    }
    return DefaultPollingInterval;
}
function shouldPollOnTemplateVM(template) {
    const { isCreating, isPublishing, isScaling, isReimaging } = template;
    return !isCreating && !isPublishing && !isScaling;
}
class TemplateContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._startTemplate = this._startTemplate.bind(this);
        this._stopTemplate = this._stopTemplate.bind(this);
        this._getTemplateWithExpand = this._getTemplateWithExpand.bind(this);
        this._publishTemplate = this._publishTemplate.bind(this);
        this._updateTitleOrDescription = this._updateTitleOrDescription.bind(this);
        this._resetPassword = this._resetPassword.bind(this);
        this._createSharedImage = this._createSharedImage.bind(this);
        this._updateSharedImage = this._updateSharedImage.bind(this);
        this._setPollingInterval = this._setPollingInterval.bind(this);
        this._acknowledgeGpuDriverWarning = this._acknowledgeGpuDriverWarning.bind(this);
        this._navigateRoute = this._navigateRoute.bind(this);
        this._clearLoadError = this._clearLoadError.bind(this);
        this._pollTemplateStop = this._pollTemplateStop.bind(this);
        this._clearError = this._clearError.bind(this);
        this._redeployTemplate = this._redeployTemplate.bind(this);
        this._reimageTemplateDialog = this._reimageTemplateDialog.bind(this);
    }
    componentDidMount() {
        const { labId, listSharedImages, getSharedGallery, templateViewModel, listImages, isVNext } = this.props;
        const { template, lab } = templateViewModel;
        if (!isVNext) {
            const labResourceId = new ResourceId(labId);
            listSharedImages(labResourceId.parent.id);
            getSharedGallery(labResourceId.parent.id);
        }
        else {
            const labPlanId = lab.labPlanId;
            // todo: get image if lab is created without lab plan
            if (!!labPlanId) {
                // todo: get image without listing all images
                listImages(labPlanId);
            }
        }
        if (template) {
            const pollingIntervalMs = determinePollingIntervalLength(template, isVNext);
            this._setPollingInterval(template.id, pollingIntervalMs);
        }
    }
    componentWillUnmount() {
        this._pollTemplateStop();
    }
    componentDidUpdate(prevProps) {
        const { labId, templateViewModel, listSharedImages, getSharedGallery, isVNext } = this.props;
        const { template, lab } = templateViewModel;
        if (labId && prevProps.labId !== labId) {
            if (!isVNext) {
                const labResourceId = new ResourceId(labId);
                listSharedImages(labResourceId.parent.id);
                getSharedGallery(labResourceId.parent.id);
            }
            else {
                const labPlanId = lab.labPlanId;
                if (!!labPlanId) {
                    listImages(labPlanId);
                }
            }
        }
        if (template) {
            const pollingIntervalMs = determinePollingIntervalLength(template, isVNext);
            const pollingIntialDelayMs = determinePollingInitialDelay(template, isVNext, true);
            if (pollingIntervalMs !== this._pollingIntervalMs ||
                !prevProps.templateViewModel.template ||
                template.id !== prevProps.templateViewModel.template.id) {
                this._pollTemplateStop();
                this._setPollingInterval(template.id, pollingIntervalMs, pollingIntialDelayMs);
            }
        }
    }
    _pollTemplateStop() {
        const { isVNext, pollTemplateStop, pollVirtualMachineStop } = this.props;
        if (!isVNext) {
            pollTemplateStop();
        }
        else {
            pollVirtualMachineStop();
        }
    }
    _clearLoadError() {
        const { isVNext, clearLoadError, clearLoadVirtualMachinesError, templateViewModel } = this.props;
        const { template } = templateViewModel;
        if (!isVNext) {
            clearLoadError();
        }
        else {
            clearLoadVirtualMachinesError();
            if (!!template?.id) {
                clearGetError(template.id);
            }
        }
    }
    _updateTitleOrDescription(templateId, title, description) {
        const { updateVNextLab, updateTemplate, labId, isVNext } = this.props;
        if (!isVNext) {
            updateTemplate(templateId, title, description);
        }
        else {
            const labUpdate = {
                title,
                description,
            };
            updateVNextLab(labId, labUpdate, LabUpdateOperation.TitleOrDesciption);
        }
    }
    _resetPassword(id, username, password) {
        this.props.resetPassword(id, username, password);
    }
    _createSharedImage(templateId, displayName) {
        this.props.createSharedImage(templateId, displayName);
    }
    _updateSharedImage(sharedImageId, templateId) {
        this.props.updateSharedImage(sharedImageId, templateId);
    }
    _publishTemplate(templateId, maxUsersInLab) {
        const { publishTemplate, isVNext, publishVNextLab, labId, templateViewModel } = this.props;
        const { lab } = templateViewModel;
        if (!isVNext) {
            publishTemplate(templateId, maxUsersInLab);
        }
        else {
            const shoudldUpdateCapacity = getLabCapacity(lab) !== maxUsersInLab;
            publishVNextLab(labId, maxUsersInLab, shoudldUpdateCapacity);
        }
    }
    _startTemplate(templateId) {
        const { isVNext, startTemplate, startEnvironments } = this.props;
        if (!isVNext) {
            startTemplate(templateId);
        }
        else {
            startEnvironments([templateId]);
        }
    }
    _stopTemplate(templateId) {
        const { isVNext, stopTemplate, stopEnvironments } = this.props;
        if (!isVNext) {
            stopTemplate(templateId);
        }
        else {
            stopEnvironments([templateId]);
        }
    }
    _redeployTemplate(templateId) {
        const { isVNext, redeployVirtualMachines } = this.props;
        if (isVNext) {
            redeployVirtualMachines([templateId]);
        }
    }
    _reimageTemplateDialog(templateId) {
        const { isVNext, resetEnvironments } = this.props;
        if (isVNext) {
            resetEnvironments([templateId]);
        }
    }
    _clearError(errorId, errorIndex) {
        const { isVNext, clearError, labId, clearLabsError, clearStartErrors, clearStopErrors, clearResetPasswordError, clearExportError, clearRedeployErrors, templateViewModel, } = this.props;
        const { template, errors } = templateViewModel;
        if (!isVNext) {
            clearError(errorId, errorIndex);
        }
        else {
            if (caseInsensitiveCultureInvariantCompare(errorId, labId) === 0) {
                clearLabsError(errorId, errorIndex);
            }
            else if (!!template?.id) {
                const error = errors.get(errorId.toLowerCase())?.get(errorIndex);
                if (!!error) {
                    switch (error.failureOperation) {
                        case FailureOperation.StartEnvironments:
                            clearStartErrors([template.id]);
                            break;
                        case FailureOperation.StopEnvironments:
                            clearStopErrors([template.id]);
                            break;
                        case FailureOperation.ResetPassword:
                            clearResetPasswordError();
                            break;
                        case FailureOperation.SaveSharedImage:
                            clearExportError();
                            break;
                        case FailureOperation.RedeployVirtualMachine:
                            clearRedeployErrors([template.id]);
                            break;
                        default:
                            return;
                    }
                }
            }
        }
    }
    _getTemplateWithExpand(templateId, expand = false) {
        this._pollingIntervalMs = 0;
        this.props.pollTemplateStop();
        this.props.getTemplate(templateId, expand);
    }
    _setPollingInterval(templateId, pollingIntervalMs, initialDelayMs) {
        const { isVNext, pollTemplateStart, templateViewModel, pollVirtualMachineStart } = this.props;
        const { template } = templateViewModel;
        this._pollingIntervalMs = pollingIntervalMs;
        if (templateId) {
            if (!isVNext) {
                pollTemplateStart(templateId, pollingIntervalMs, initialDelayMs);
            }
            else {
                if (shouldPollOnTemplateVM(template)) {
                    pollVirtualMachineStart(templateId, pollingIntervalMs, initialDelayMs);
                }
            }
        }
    }
    _acknowledgeGpuDriverWarning() {
        const { templateViewModel, updateLab, isVNext } = this.props;
        const { lab } = templateViewModel;
        if (!isVNext) {
            const labUpdate = { ...lab, gpuDriverStateDismissed: Ml.DismissState.Dismissed };
            updateLab(labUpdate, LabUpdateOperation.DismissGpuWarning);
        }
    }
    _navigateRoute(pathname) {
        const { push, search } = this.props;
        push({ pathname, search });
    }
    render() {
        const { labId, templateViewModel, isTeamsOrLmsIntegrationEnabled, clearLabUpdateError, clearPublishExceedsCoresError, dismissExportStartedMessageBar, } = this.props;
        const { template } = templateViewModel;
        // Declare navigationItems in the container rather than presentational view for now because create-view is seperated from template.
        // Todo: move the create-view inside the template.
        let navigationItems = undefined;
        if (isTeamsOrLmsIntegrationEnabled) {
            navigationItems = getNavigationItems(this.props.intl, labId, LabNavKeys.Template, this._navigateRoute);
        }
        const title = this.props.intl.formatMessage(templateMessages.templatePageTitle);
        if (template && template.isCreating) {
            return (<>
                    <PageTitle subtitle={title}/>
                    <CreatingView hasTemplateVm={template.hasTemplateVm} imageName={template.image} cores={template.cores} memorySize={template.memorySize} labSkuInfo={template.labSkuInfo} navigationItems={isTeamsOrLmsIntegrationEnabled ? navigationItems : undefined}/>
                </>);
        }
        const props = {
            templateViewModel,
            getTemplateWithExpand: this._getTemplateWithExpand,
            startTemplate: this._startTemplate,
            stopTemplate: this._stopTemplate,
            publishTemplate: this._publishTemplate,
            resetPassword: this._resetPassword,
            updateTitleOrDescription: this._updateTitleOrDescription,
            createSharedImage: this._createSharedImage,
            updateSharedImage: this._updateSharedImage,
            acknowledgeGpuDriverWarning: this._acknowledgeGpuDriverWarning,
            clearLabUpdateError,
            clearPublishExceedsCoresError,
            clearLoadError: this._clearLoadError,
            clearError: this._clearError,
            dismissExportStartedMessageBar,
            redeployTemplate: this._redeployTemplate,
            navigationItems: isTeamsOrLmsIntegrationEnabled ? navigationItems : undefined,
            reimageTemplate: this._reimageTemplateDialog,
        };
        return (<>
                <PageTitle subtitle={title}/>
                <Template {...props}/>
            </>);
    }
}
const mapStateToProps = (state) => {
    return getTemplateContainerModel(state);
};
const mapDispatchToProps = {
    getTemplate,
    startTemplate,
    stopTemplate,
    clearLabUpdateError,
    publishTemplate,
    resetPassword,
    updateTemplate,
    getSharedGallery,
    listSharedImages,
    createSharedImage,
    updateSharedImage,
    clearLoadError,
    clearError,
    clearPublishExceedsCoresError,
    pollTemplateStart,
    pollTemplateStop,
    push: routerActions.push,
    updateLab,
    listImages,
    pollVirtualMachineStart,
    pollVirtualMachineStop,
    updateVNextLab,
    startEnvironments,
    stopEnvironments,
    resetEnvironments,
    clearLoadVirtualMachinesError,
    pollLabStop,
    publishVNextLab,
    clearLabsError,
    clearStartErrors,
    clearStopErrors,
    clearResetPasswordError,
    dismissExportStartedMessageBar,
    clearExportError,
    redeployVirtualMachines,
    clearRedeployErrors,
    listEnvironments,
};
export const TemplateContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(TemplateContainerInjected));
export default TemplateContainer;
