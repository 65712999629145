import { Map } from 'immutable';
import { numberSorter } from '../../utils/sorting';
const skuFamilyOrdinal = Map({
    FSV2: 0,
    DSV4: 1,
    DSV3: 2,
    NCAST4V3: 3,
    NCSV3: 4,
    NC: 5,
    NVV4: 6,
    NVV3: 7,
    NVADSA10V5: 8,
    NV: 9,
    ESV4: 10,
});
const diskTypeOrdinal = Map({
    STANDARDSSD: 0,
    PREMIUMSSD: 1,
});
export const vmSkuSorter = () => {
    const vmSkuComparer = (a, b) => {
        if (!a && !b) {
            return 0;
        }
        else if (!a) {
            return 1;
        }
        else if (!b) {
            return -1;
        }
        // TODO: Classic skus will come first for now, but eventually we want standard skus to be default.
        if (a.isClassic && !b.isClassic) {
            return -1;
        }
        else if (!a.isClassic && b.isClassic) {
            return 1;
        }
        const numSorter = numberSorter();
        const aFamilyOrdinal = skuFamilyOrdinal.get(a.family.toUpperCase());
        const bFamliyOrdinal = skuFamilyOrdinal.get(b.family.toUpperCase());
        if (aFamilyOrdinal !== undefined && bFamliyOrdinal !== undefined) {
            const familyResult = numSorter(aFamilyOrdinal, bFamliyOrdinal);
            if (familyResult !== 0) {
                return familyResult;
            }
        }
        else if (bFamliyOrdinal !== undefined) {
            return 1;
        }
        else if (aFamilyOrdinal !== undefined) {
            return -1;
        }
        const coresResult = numSorter(a.size.cores, b.size.cores);
        if (coresResult !== 0) {
            return coresResult;
        }
        const diskSizeResult = numSorter(a.size.storageGB, b.size.storageGB);
        if (diskSizeResult !== 0) {
            return diskSizeResult;
        }
        const aDiskTypeOrdinal = diskTypeOrdinal.get(a.size.storageType.toUpperCase());
        const bDiskTypeOrdinal = diskTypeOrdinal.get(b.size.storageType.toUpperCase());
        if (aDiskTypeOrdinal !== undefined && bDiskTypeOrdinal !== undefined) {
            return numSorter(aDiskTypeOrdinal, bDiskTypeOrdinal);
        }
        else if (bDiskTypeOrdinal !== undefined) {
            return 1;
        }
        else if (aDiskTypeOrdinal !== undefined) {
            return -1;
        }
        return 0;
    };
    return vmSkuComparer;
};
