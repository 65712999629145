import { Checkbox, Dropdown, Separator } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { stringSorter } from '../../utils/sorting';
import { isGeographyAvailable } from './create-lab-selectors';
import { messages } from './vm-geography-picker-shared';
const Option = (option) => {
    const { data, disabled, text } = option;
    const { price, currencyCode } = data;
    return disabled ? (<span>{text}</span>) : (<FormattedMessage id="VmGeoPickerTitle" defaultMessage="{geo} ({price}/hr)" description="The name of a geography and its price per hour." values={{
        geo: text,
        price: <FormattedNumber value={price} style="currency" currency={currencyCode}/>,
    }}/>);
};
const getKey = (data) => {
    return data.geographyName;
};
const getText = (data) => {
    return data.localizedDisplayName;
};
const getOptions = (data) => {
    const options = [];
    Object.keys(data).forEach((key) => {
        const geo = data[key];
        const disabled = !isGeographyAvailable(geo);
        const option = {
            disabled,
            key: getKey(geo),
            text: getText(geo),
            data: geo,
        };
        options.push(option);
    });
    const sorter = stringSorter();
    return options.sort((a, b) => sorter(a.text, b.text));
};
export const VmGeographyPicker = (props) => {
    const { label, data, selected, required, onChange } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const [showUnavailable, setShowUnavailable] = React.useState(false);
    const options = getOptions(data);
    const filteredOptions = !showUnavailable ? options.filter((o) => !o.disabled) : options;
    const hasDisabledOptions = options.findIndex((o) => o.disabled === true) > -1;
    const dropdownProps = {
        label,
        required,
        options: filteredOptions,
        placeholder: msg(messages.placeholder),
        selectedKey: selected ? [getKey(selected)] : [],
        styles: {
            callout: { maxHeight: 300, overflowY: 'auto' },
            root: { paddingBottom: '8px' },
        },
        onChange: (_, value) => {
            const option = value;
            // ignoring changes on focus so the dropdown doesn't pre-select something
            // when the user clicks on it / focuses on it for the first time
            if (_.type.toLowerCase() !== 'focus' && !option.disabled) {
                onChange(option.data);
            }
        },
        onRenderOption: (option) => (option ? <Option {...option}/> : null),
        onRenderTitle: (options) => (options ? <Option {...options[0]}/> : null),
        onRenderList: (renderProps, defaultRender) => {
            return (<>
                    <div style={{ maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {defaultRender && defaultRender(renderProps)}
                    </div>
                    {hasDisabledOptions && (<>
                            <Separator styles={{ root: { padding: '0', margin: '0', height: '1px' } }}/>
                            <Checkbox styles={{ root: { padding: '15px' } }} checked={showUnavailable} onChange={(_event, value) => setShowUnavailable(value || false)} label={msg(messages.showUnavailable)}/>
                        </>)}
                </>);
        },
    };
    return <Dropdown {...dropdownProps} aria-required={required || undefined}/>;
};
export default VmGeographyPicker;
