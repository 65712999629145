import { Stack, Toggle } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import InfoTip from './info-tip';
export const PasswordSettingsField = (props) => {
    const { showHeader, disabled, disabledReason, input: { value, onChange }, } = props;
    return (<>
            {showHeader && (<div className="ms-fontWeight-semibold" style={{ marginBottom: 12 }}>
                    <FormattedMessage id="PasswordSettingsFieldHeader" defaultMessage="Password settings" description="Subtitle on the Settings page."/>
                </div>)}
            <Stack horizontal>
                <Stack.Item>
                    <Toggle label={<FormattedMessage id="PasswordSettingsFieldLabel" defaultMessage="Use same password for all virtual machines" description="Label for enable shared password toggle on the Settings page."/>} disabled={disabled} inlineLabel={true} checked={value} onChange={(_, value) => onChange(value)}/>
                </Stack.Item>
                {disabledReason && (<Stack.Item>
                        <InfoTip content={disabledReason} styles={{ root: { paddingTop: '7px' } }}/>
                    </Stack.Item>)}
            </Stack>
            <FormattedMessage id="PasswordSettingsFieldDescription" defaultMessage="If this setting is disabled, each student will be prompted for a new password at first logon." description="Text on the Settings page."/>
        </>);
};
