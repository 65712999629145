import { Checkbox, DefaultButton, Link, Panel, PanelType, Separator, SharedColors, Stack } from '@fluentui/react';
import { Set } from 'immutable';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../../language/common-messages';
import Constants from '../../utils/constants';
import SubmitButton from '../submit-button';
import { getCustomTheme } from '../themes/selectors';
import memoize from 'memoize-one';
import { caseInsensitiveCultureInvariantCompare } from '../../utils/string-comparison';
import LoadingContainer from '../loading-section';
import { usePrevious } from '../../utils/common';
import { convertResourceGroupToItem } from './page-header-selectors';
const mapLabPlanToCheckbox = (labPlan, linkedLabPlans, disabled, check, uncheck) => {
    return (<Checkbox key={labPlan.id} label={labPlan.name} disabled={disabled} checked={linkedLabPlans.contains(labPlan.id)} onChange={(_, checked) => {
        checked ? check(labPlan.id) : uncheck(labPlan.id);
    }}/>);
};
const getOptions = memoize((labPlans, linkedLabPlans, isSubmitting, check, uncheck) => {
    return (<>
                {labPlans.map((labPlan) => mapLabPlanToCheckbox(labPlan, linkedLabPlans, isSubmitting, check, uncheck))}
            </>);
});
const getLinkedLabPlans = memoize((labPlans) => {
    return labPlans
        .filter((o) => !!o.linkedLmsInstance)
        .map((o) => o.id)
        .toSet();
});
const getUnlinkedLabPlans = memoize((labPlans) => {
    return labPlans
        .filter((o) => !o.linkedLmsInstance)
        .map((o) => o.id)
        .toSet();
});
const getAllIds = memoize((labPlans) => {
    return labPlans.map((o) => o.id).toSet();
});
const getLabPlansToLink = memoize((labPlans, linkIds) => {
    return labPlans
        .filter((o) => !o.linkedLmsInstance &&
        linkIds.find((id) => caseInsensitiveCultureInvariantCompare(o.id, id) === 0))
        .map((lp) => lp.id);
});
const getLabPlansToUnlink = memoize((labPlans, unlinkedIds) => {
    return labPlans
        .filter((o) => !!o.linkedLmsInstance &&
        unlinkedIds.find((id) => caseInsensitiveCultureInvariantCompare(o.id, id) === 0))
        .map((lp) => lp.id);
});
// Flyout to connect lab plans to LMS by the admin.
export const LmsLabPlanSettingsFlyout = (props) => {
    const { isSubmitting, onDismiss, labPlans, lmsInstance, onSubmit, isLoading, currentLabParentId, selectLabParentItem, } = props;
    const { formatMessage: msg } = useIntl();
    const [linkedLabPlans, setLinkedLabPlans] = React.useState(Set());
    const [unlinkedLabPlans, setUnlinkedLabPlans] = React.useState(Set());
    // close the flyout after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    const labPlansToLink = getLabPlansToLink(labPlans, linkedLabPlans);
    const labPlansToUnlink = getLabPlansToUnlink(labPlans, unlinkedLabPlans);
    const shouldDisable = labPlansToLink.size === 0 && labPlansToUnlink.size === 0;
    React.useEffect(() => {
        if (!isSubmitting && !isLoading) {
            const linkedLabPlans = getLinkedLabPlans(labPlans);
            setLinkedLabPlans(linkedLabPlans);
            const unlinkedLabPlans = getUnlinkedLabPlans(labPlans);
            setUnlinkedLabPlans(unlinkedLabPlans);
            if (previousIsSubmitting && onDismiss) {
                onDismiss();
            }
            if (currentLabParentId && selectLabParentItem) {
                const currentLabParent = convertResourceGroupToItem(currentLabParentId);
                selectLabParentItem(currentLabParent);
            }
        }
    }, [isSubmitting, isLoading]);
    const onClick = React.useCallback(() => {
        onSubmit(labPlansToLink.toArray(), labPlansToUnlink.toArray(), lmsInstance);
    }, [labPlans, linkedLabPlans, unlinkedLabPlans]);
    const check = React.useCallback((id) => {
        setLinkedLabPlans(linkedLabPlans.add(id));
        setUnlinkedLabPlans(unlinkedLabPlans.remove(id));
    }, [linkedLabPlans, unlinkedLabPlans]);
    const uncheck = React.useCallback((id) => {
        setUnlinkedLabPlans(unlinkedLabPlans.add(id));
        setLinkedLabPlans(linkedLabPlans.remove(id));
    }, [linkedLabPlans, unlinkedLabPlans]);
    const allIds = getAllIds(labPlans);
    const checkAll = React.useCallback(() => {
        setLinkedLabPlans(allIds);
        setUnlinkedLabPlans(Set());
    }, [allIds]);
    const uncheckAll = React.useCallback(() => {
        setUnlinkedLabPlans(allIds);
        setLinkedLabPlans(Set());
    }, [allIds]);
    const options = getOptions(labPlans, linkedLabPlans, isSubmitting, check, uncheck);
    const isEmpty = labPlans.size === 0;
    return (<Panel type={PanelType.custom} customWidth={`${Constants.DefaultFlyoutWidth}px`} isOpen={true} isLightDismiss={!isSubmitting} onDismiss={isSubmitting ? undefined : onDismiss} headerText={msg(commonMessages.lmsLabPlanSettings)} closeButtonAriaLabel={msg(commonMessages.cancel)} styles={{ content: { padding: 0 }, ...getCustomTheme().panelStyles }} onRenderFooterContent={() => (<>
                    {!isLoading && !isEmpty && (<Stack horizontal tokens={{ childrenGap: '8px' }}>
                            <Stack.Item>
                                <SubmitButton default={true} buttonText={msg(commonMessages.save)} onClick={onClick} isSubmitting={isSubmitting} disabled={shouldDisable}/>
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton disabled={isSubmitting} onClick={onDismiss}>
                                    {msg(commonMessages.cancel)}
                                </DefaultButton>
                            </Stack.Item>
                        </Stack>)}
                </>)}>
            {isLoading && <LoadingContainer />}
            {!isLoading && (<Stack>
                    <Stack.Item>
                        <FormattedMessage id="LmsLabPlanSettingsDescription" defaultMessage="The lab plans linked to your LMS determine which virtual machine images, sizes, and other configurations are available when creating labs. <Link>Learn more</Link>" description="Description about linking lab plan to LMS" values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlabs-linklms" target="_blank">
                                        {chunks}
                                    </Link>),
    }}/>
                    </Stack.Item>
                    {isEmpty && (<Stack.Item styles={{ root: { paddingTop: '15px', color: SharedColors.red20 } }}>
                            <FormattedMessage id="LmsConnectNoSeletableLabPlans" defaultMessage="No lab plans can be linked to this LMS. Creation of new labs will not be available." description="Message shown whe we cannot find any lab plans that could be linked to this LMS instance."/>
                        </Stack.Item>)}
                    {!isEmpty && (<>
                            <Stack.Item styles={{ root: { paddingTop: '15px' } }}>
                                <FormattedMessage id="LmsLabPlanSettingsSelectedLabPlans" defaultMessage="{numberOfSelectedLabPlans} plans selected." description="Description about number of selected lab plans to connect to LMS." values={{
        numberOfSelectedLabPlans: linkedLabPlans.size,
    }}/>
                            </Stack.Item>

                            <Stack.Item styles={{ root: { paddingTop: '10px' } }}>
                                <Checkbox key="all" label={msg(commonMessages.allFilter)} disabled={isSubmitting} checked={linkedLabPlans.size === labPlans.size} onChange={(_, checked) => {
        checked ? checkAll() : uncheckAll();
    }}/>
                            </Stack.Item>
                            <Stack.Item>
                                <Separator />
                            </Stack.Item>
                            <Stack.Item>
                                <Stack className="vertical-scrollable-content" tokens={{ childrenGap: '10px' }}>
                                    {options}
                                </Stack>
                            </Stack.Item>
                        </>)}
                </Stack>)}
        </Panel>);
};
export default LmsLabPlanSettingsFlyout;
