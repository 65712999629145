import { Dropdown } from '@fluentui/react';
import { defineMessages, useIntl } from 'react-intl';
import * as React from 'react';
import { LabAccessType } from '../data/models/lab';
const messages = defineMessages({
    labAccessLabel: {
        id: 'LabAccessDropdownLabel',
        defaultMessage: 'Show:',
        description: 'Label for dropdown to filter labs based on access'
    },
    myLabs: {
        id: 'MyLabsDropdownOption',
        defaultMessage: 'My labs',
        description: 'Label for dropdown option that will show only labs where the user has write-access'
    },
    allLabs: {
        id: 'AllLabsDropdownOption',
        defaultMessage: 'All labs',
        description: 'Label for dropdown option that will show labs where the user has read-access and write-access'
    },
});
export const LabAccessDropdown = (props) => {
    const intl = useIntl();
    const { selected, filterByLabAccessType } = props;
    const options = [
        {
            key: LabAccessType.writeAccess,
            text: intl.formatMessage(messages.myLabs),
        },
        {
            key: LabAccessType.readAndWriteAccess,
            text: intl.formatMessage(messages.allLabs),
        }
    ];
    const dropdownProps = {
        options,
        label: intl.formatMessage(messages.labAccessLabel),
        selectedKey: selected,
        onChange: (_, value) => {
            const option = value;
            filterByLabAccessType(option.key);
        },
        styles: {
            dropdown: { display: 'inline-block' },
            title: { border: 'none' },
            root: { display: 'flex' },
        },
    };
    return <Dropdown {...dropdownProps}/>;
};
export default LabAccessDropdown;
