import { Record } from 'immutable';
import { AuthenticationState } from '../../data/models/identity';
import { LtiActionType, } from '../actions/lti/lti-actions';
function ltiSignIn(state, action) {
    return state.merge({
        authenticationState: AuthenticationState.Authenticating,
    });
}
function ltiSignInSuccess(state, action) {
    return state.merge({
        authenticationState: AuthenticationState.Authenticated,
    });
}
function ltiSignInError(state, action) {
    const { error: initializeError } = action;
    return state.merge({
        authenticationState: AuthenticationState.AuthenticationError,
        initializeError,
    });
}
function setLtiContext(state, action) {
    const { ltiContext } = action;
    return state.merge({
        ltiContext,
    });
}
function setMembershipUrl(state, action) {
    const { membershipUrl } = action;
    return state.merge({
        membershipUrl,
    });
}
function setLtiRoles(state, action) {
    const { roles } = action;
    return state.merge({
        roles,
    });
}
function ltiRequestWebStorageAccess(state, action) {
    return state.merge({
        requestWebStorageAccessError: undefined,
    });
}
function ltiRequestWebStorageAccessSuccess(state, action) {
    return state.merge({
        hasGrantedWebStorageAccess: true,
    });
}
function ltiRequestWebStorageAccessError(state, action) {
    const { error } = action;
    return state.merge({
        requestWebStorageAccessError: error,
    });
}
function setLmsInstance(state, action) {
    const { lmsInstance } = action;
    return state.merge({
        lmsInstance,
    });
}
const initialState = Record({
    authenticationState: AuthenticationState.NotStarted,
    initializeError: undefined,
    roles: [],
    membershipUrl: '',
    ltiContext: undefined,
    requestWebStorageAccessError: undefined,
    hasGrantedWebStorageAccess: false,
    lmsInstance: '',
})();
export const ltiReducer = (state = initialState, action) => {
    switch (action.type) {
        case LtiActionType.LTI_SIGN_IN:
            return ltiSignIn(state, action);
        case LtiActionType.LTI_SIGN_IN_SUCCESS:
            return ltiSignInSuccess(state, action);
        case LtiActionType.LTI_SIGN_IN_ERROR:
            return ltiSignInError(state, action);
        case LtiActionType.SET_LTI_CONTEXT:
            return setLtiContext(state, action);
        case LtiActionType.SET_LTI_ROLES:
            return setLtiRoles(state, action);
        case LtiActionType.SET_MEMBERSHIP_URL:
            return setMembershipUrl(state, action);
        case LtiActionType.LTI_REQUEST_WEB_STORAGE_ACCESS:
            return ltiRequestWebStorageAccess(state, action);
        case LtiActionType.LTI_REQUEST_WEB_STORAGE_ACCESS_SUCCESS:
            return ltiRequestWebStorageAccessSuccess(state, action);
        case LtiActionType.LTI_REQUEST_WEB_STORAGE_ACCESS_ERROR:
            return ltiRequestWebStorageAccessError(state, action);
        case LtiActionType.SET_LMS_INSTANCE:
            return setLmsInstance(state, action);
        default:
            return state;
    }
};
export default ltiReducer;
