import { takeLatest, put, call } from 'redux-saga/effects';
import { getUserPhotoUrl } from '../../../data/providers/user-photo-provider';
import { IdentityActionType } from '../../actions/identity/identity-actions';
import { loadUserPhotoError, loadUserPhotoFailed, loadUserPhotoSuccess, } from '../../actions/identity/identity-action-creators';
import { getGraphAccessTokenSaga } from './access-tokens';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
export function* loadUserPhoto(action) {
    try {
        // We need to allow redirect here to get graph tokens in browsers with cookie sandboxing (safari, brave)
        // Redirecting here so we don't lose context later when we try to get the group info
        const accessToken = yield call(getGraphAccessTokenSaga, undefined, true);
        const photoUrl = yield call(getUserPhotoUrl, accessToken);
        if (!photoUrl) {
            yield put(loadUserPhotoFailed());
        }
        else {
            yield put(loadUserPhotoSuccess(photoUrl));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.GetUserPhoto, err ? err.code : undefined);
        yield put(loadUserPhotoError(error));
    }
}
export function* loadUserPhotoSaga() {
    yield takeLatest(IdentityActionType.LOAD_USER_PHOTO, loadUserPhoto);
}
