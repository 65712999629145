import { call, put, select, all, takeLatest } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import { ResourceId } from '../../../utils/resource-id';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { LabPlanActionType } from '../../actions/lab-plan/lab-plan-actions';
import { updateLabPlansLmsSettingsError, updateLabPlansLmsSettingsSuccess, } from '../../actions/lab-plan/lab-plan-action-creators';
import LtiProvider from '../../../data/providers/lti-provider';
import { getLtiTokenSaga } from '../lti/lti-token';
import LabPlanProvider from '../../../data/providers/vnext/arm-api/lab-plan-provider';
import { getLabPlans } from '../../selectors/vnext/lab-plan-selectors';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
export async function callLinkLms(labPlan, lmsInstance, accessToken, ltiToken, locale, language, errors, successfulLabPlans) {
    try {
        await LtiProvider.linkLms(labPlan.id, accessToken, locale, language, ltiToken, labPlan.location);
        successfulLabPlans.push({ ...labPlan, linkedLmsInstance: lmsInstance });
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labPlan.id, FailureOperation.LinkLabPlan, e ? e.code : undefined);
        errors[error.id] = error;
    }
}
export async function callUnLinkLms(labPlan, accessToken, locale, language, errors, successfulLabPlans) {
    const resourceId = new ResourceId(labPlan.id, true);
    try {
        const newLabPlan = await LabPlanProvider.updateLabPlan(resourceId, { linkedLmsInstance: null, allowedRegions: labPlan.allowedRegions }, accessToken, locale, language);
        successfulLabPlans.push(newLabPlan);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.UnlinkLabPlan, e ? e.code : undefined);
        errors[error.id] = error;
    }
}
export function* updateLabPlansLmsSettings(action) {
    const { linkIds, unlinkIds, lmsInstance } = action;
    try {
        const successfulLabPlans = [];
        const errors = {};
        const armAccessToken = yield call(getArmAccessTokenSaga);
        const labsAccessToken = yield call(getLabsApiAccessTokenSaga);
        const ltiToken = yield call(getLtiTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const labPlans = yield select(getLabPlans);
        const linkLabPlanCalls = linkIds.map((id) => {
            const labPlan = labPlans.find((o) => caseInsensitiveCultureInvariantCompare(o.id, id) === 0);
            return call(callLinkLms, labPlan, lmsInstance, labsAccessToken, ltiToken, locale, language, errors, successfulLabPlans);
        });
        const unlinkLabPlanCalls = unlinkIds.map((id) => {
            const labPlan = labPlans.find((o) => caseInsensitiveCultureInvariantCompare(o.id, id) === 0);
            return call(callUnLinkLms, labPlan, armAccessToken, locale, language, errors, successfulLabPlans);
        });
        yield all([...linkLabPlanCalls, ...unlinkLabPlanCalls]);
        if (successfulLabPlans.length > 0) {
            yield put(updateLabPlansLmsSettingsSuccess(successfulLabPlans));
        }
        const failedIds = Object.keys(errors);
        if (failedIds.length > 0) {
            yield put(updateLabPlansLmsSettingsError(errors));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, 'all', FailureOperation.UpdateLmsSettings, e ? e.code : undefined);
        const errors = {};
        errors[error.id] = error;
        yield put(updateLabPlansLmsSettingsError(errors));
    }
}
export function* updateLabPlansLmsSettingsSaga() {
    yield takeLatest(LabPlanActionType.UPDATE_LAB_PLANS_LMS_SETTINGS, updateLabPlansLmsSettings);
}
