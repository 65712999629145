import { fork, takeEvery, takeLatest } from 'redux-saga/effects';
import { isValidResourceId } from '../../utils/resource-id';
function environmentIdBasedTaskHelper(vCurrentWorker, vNextWorker) {
    return function* (action) {
        const environmentId = action.environmentId;
        if (!isValidResourceId(environmentId) && !!vNextWorker) {
            yield fork(vNextWorker, action);
        }
        else if (!!vCurrentWorker) {
            yield fork(vCurrentWorker, action);
        }
    };
}
export function environmentIdBasedTakeLatest(pattern, vCurrentWorker, vNextWorker) {
    const helper = environmentIdBasedTaskHelper(vCurrentWorker, vNextWorker);
    return takeLatest(pattern, helper);
}
export function environmentIdBasedTakeEvery(pattern, vCurrentWorker, vNextWorker) {
    const helper = environmentIdBasedTaskHelper(vCurrentWorker, vNextWorker);
    return takeEvery(pattern, helper);
}
