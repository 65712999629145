import { all, fork } from 'redux-saga/effects';
import { getPricingAndAvailabilityDataSaga, getPricesAndAvailabilityOnLabParentSelectSaga, } from './get-pricing-and-availability';
import { getLabPricingAndAvailabilityDataSaga, getLabPricesAndAvailabilityOnLoadTemplateSaga, } from './get-lab-pricing-and-availability';
export function* rootSaga() {
    yield all([
        fork(getPricingAndAvailabilityDataSaga),
        fork(getPricesAndAvailabilityOnLabParentSelectSaga),
        fork(getLabPricingAndAvailabilityDataSaga),
        fork(getLabPricesAndAvailabilityOnLoadTemplateSaga),
    ]);
}
