import { TooltipHost, Icon } from '@fluentui/react';
import * as React from 'react';
import _ from 'lodash';
const defaultIconStyles = {
    root: {
        marginLeft: '7px',
        cursor: 'default',
    },
};
export const InfoTip = (props) => {
    const iconStyles = props.iconStyles ? _.merge({}, defaultIconStyles, props.iconStyles) : defaultIconStyles;
    return (<TooltipHost {...props}>
            <Icon aria-label={props.ariaLabel} role="none" aria-hidden="false" iconName="Info" className="ms-fontSize-16" styles={iconStyles} tabIndex={props.iconTabIndex || 0}/>
        </TooltipHost>);
};
export default InfoTip;
