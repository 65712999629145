import { Checkbox, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreateLabCredentials } from './create-lab-credentials';
import { PasswordSettingsField } from '../../common/password-settings-field';
import { messages } from './create-lab-messages';
import { isUbuntuImage } from './create-lab-selectors';
import { Collapse } from 'react-collapse';
import './create-lab-credentials-page.css';
export const NewLabCredentialsPage = (props) => {
    const { isVNext, image, customizeTemplate, username, password, nonAdminUsername, nonAdminPassword, useSharedPassword, isNonAdminEnabled, isPasswordValid, isNonAdminPasswordValid, onUsernameChange, onPasswordChange, onUseSharedPasswordChange, onUsernameValidationChange, onPasswordValidationChange, onNonAdminUsernameValidationChange, onNonAdminPasswordValidationChange, onNonAdminEnabledChange, onNonAdminUsernameChange, onNonAdminPasswordChange, } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const isUseSharedPasswordEnabled = (!isUbuntuImage(image) && customizeTemplate) || isVNext;
    const imageText = '[' + image.text + ']';
    const headerMessage = image.isSpecialized
        ? messages.newLabCredentialsPageIntroVnextSpecialized
        : messages.newLabCredentialsPageIntroVnextGeneralized;
    const [usernamePlaceholder, setUsernamePlaceholder] = React.useState(msg(messages.usernamePlaceholder));
    const [usernameLabel, setUsernameLabel] = React.useState(msg(messages.usernameLabel));
    const [passwordPlaceholder, setPasswordPlaceholder] = React.useState(msg(messages.passwordPlaceholder));
    const [passwordLabel, setPasswordLabel] = React.useState(msg(messages.passwordLabel));
    const [passwordInfoTipEnabled, setPasswordInfoTipEnabled] = React.useState(false);
    const [useSharedPasswordCheckbox, setUseSharedPasswordCheckbox] = React.useState(true);
    React.useEffect(() => {
        if (isVNext) {
            // state logic is here to maintain dependent states
            if (isNonAdminEnabled) {
                setUsernameLabel(msg(messages.usernameAdminLabel));
                setPasswordLabel(msg(messages.passwordAdminLabel));
                setPasswordInfoTipEnabled(image.isSpecialized);
                if (!image.isSpecialized) {
                    setUsernamePlaceholder(msg(messages.usernamePlaceholder));
                    setPasswordPlaceholder(msg(messages.passwordPlaceholder));
                }
                else {
                    setUsernamePlaceholder(msg(messages.usernameSpecializedAdminPlaceholder) + imageText);
                    setPasswordPlaceholder(msg(messages.passwordSpecializedAdminPlaceholder));
                }
            }
            else {
                //if non-admin is disabled
                setUsernameLabel(msg(messages.usernameLabel));
                setPasswordLabel(msg(messages.passwordLabel));
                setPasswordInfoTipEnabled(false);
                if (!image.isSpecialized) {
                    setUsernamePlaceholder(msg(messages.usernamePlaceholder));
                    setPasswordPlaceholder(msg(messages.passwordPlaceholder));
                }
                else {
                    setUsernamePlaceholder(msg(messages.usernameSpecializedPlaceholder) + imageText);
                    setPasswordPlaceholder(msg(messages.passwordSpecializedPlaceholder));
                }
            }
        }
    }, [isNonAdminEnabled]);
    // We need both the states, one that controls the state the ui checkbox,and the other for API request.
    const onUseSharedPasswordOptionChange = (isUseSharedPasswordEnabled) => {
        setUseSharedPasswordCheckbox(isUseSharedPasswordEnabled);
        onUseSharedPasswordChange(isUseSharedPasswordEnabled);
    };
    const onNonAdminEnabledOptionChange = (isNonAdminOptionEnabled) => {
        onNonAdminEnabledChange(isNonAdminOptionEnabled);
    };
    return (<>
            {!isVNext ? (<Stack tokens={{ childrenGap: 30 }}>
                    <Stack.Item>
                        <CreateLabCredentials username={username} password={password} isPasswordValid={isPasswordValid} usernamePlaceholder={msg(messages.usernamePlaceholder)} passwordPlaceholder={msg(messages.passwordPlaceholder)} usernameLabel={msg(messages.usernameLabel)} passwordLabel={msg(messages.passwordLabel)} passwordInfoTipEnabled={false} headerMessage={messages.newLabCredentialsPageIntro} isWindows={image.isWindows} isVNext onUsernameChange={onUsernameChange} onPasswordChange={onPasswordChange} onUsernameValidationChange={onUsernameValidationChange} onPasswordValidationChange={onPasswordValidationChange}/>
                    </Stack.Item>
                    <Stack.Item>
                        <FormattedMessage {...messages.newLabCredentialsPagePasswordDescription}/>
                    </Stack.Item>
                    <Stack.Item>
                        <PasswordSettingsField showHeader={false} disabled={!isUseSharedPasswordEnabled} disabledReason={customizeTemplate || isVNext
        ? undefined
        : msg(messages.customizeImageNoSharedPasswordDescription)} input={{
        value: isUseSharedPasswordEnabled ? useSharedPassword : false,
        onChange: (value) => onUseSharedPasswordChange(value),
    }}/>
                    </Stack.Item>
                </Stack>) : (<Stack>
                    <Stack.Item styles={{
        root: {
            paddingBottom: '30px',
            borderBottom: '1px solid #EDEBE9',
            marginBottom: '25px',
        },
    }}>
                        <CreateLabCredentials username={username} password={password} isPasswordValid={isPasswordValid} usernamePlaceholder={usernamePlaceholder} passwordPlaceholder={passwordPlaceholder} usernameLabel={usernameLabel} passwordLabel={passwordLabel} passwordInfoTipEnabled={passwordInfoTipEnabled} headerMessage={headerMessage} isWindows={image.isWindows} isVNext onUsernameChange={onUsernameChange} onPasswordChange={onPasswordChange} onUsernameValidationChange={onUsernameValidationChange} onPasswordValidationChange={onPasswordValidationChange}/>
                    </Stack.Item>
                    <Stack.Item>
                        <Checkbox checked={isNonAdminEnabled} label={msg(messages.newLabCredentialsPageNonAdminOption)} onChange={(_event, checked) => onNonAdminEnabledOptionChange(checked === true)}/>
                        <Collapse isOpened={isNonAdminEnabled}>
                            <Stack.Item styles={{
        root: {
            paddingTop: '10px',
            paddingBottom: '16px',
        },
    }}>
                                <CreateLabCredentials username={nonAdminUsername} password={nonAdminPassword} isPasswordValid={isNonAdminPasswordValid} usernamePlaceholder={msg(messages.usernamePlaceholder)} passwordPlaceholder={msg(messages.passwordPlaceholder)} usernameLabel={msg(messages.usernameNonAdminLabel)} passwordLabel={msg(messages.passwordNonAdminLabel)} passwordInfoTipEnabled={false} headerMessage={messages.newLabCredentialsPageNonAdminOptionDescription} isWindows={image.isWindows} isVNext onUsernameChange={onNonAdminUsernameChange} onPasswordChange={onNonAdminPasswordChange} onUsernameValidationChange={onNonAdminUsernameValidationChange} onPasswordValidationChange={onNonAdminPasswordValidationChange}/>
                                <div style={{ paddingTop: '16px' }}>
                                    <FormattedMessage {...messages.newLabCredentialsPageNonAdminOptionBottomDescription}/>
                                </div>
                            </Stack.Item>
                        </Collapse>
                    </Stack.Item>
                    {<Stack.Item styles={{
        root: {
            paddingTop: '23px',
            paddingBottom: '13px',
        },
    }}>
                            <Checkbox label={msg(messages.useSharedPasswordCheckboxLabel)} checked={useSharedPassword} onChange={(_event, checked) => onUseSharedPasswordOptionChange(checked === true)}/>
                            {useSharedPasswordCheckbox && (<Stack.Item styles={{
        root: { paddingTop: '13px' },
    }}>
                                    <FormattedMessage {...messages.useSharedPasswordEnabledMessage}/>
                                </Stack.Item>)}
                        </Stack.Item>}
                    {!useSharedPasswordCheckbox && !isNonAdminEnabled && (<Stack.Item>
                            <FormattedMessage id="NoOptionSelectedMessage" defaultMessage="Each lab user will be prompted for a new password at first logon." description="Message is displayed after the checkboxes when neither the non-admin nor the shared password boxes are checked."/>
                        </Stack.Item>)}
                </Stack>)}
        </>);
};
export default NewLabCredentialsPage;
