import { DefaultButton, Dialog, DialogType, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SubmitButton } from '../common/submit-button';
import { commonMessages } from '../language/common-messages';
import { usePrevious } from '../utils/common';
import { Constants } from '../utils/constants';
const messages = defineMessages({
    deleteLabTitle: {
        id: 'DeleteLabTitle',
        defaultMessage: 'Delete lab',
        description: 'Title for dialog box confirming lab delete',
    },
    deleteLabMessage: {
        id: 'DeleteLabMessage',
        defaultMessage: `The lab ‘{name}’ and its virtual machines will be permanently deleted. Do you want to continue?`,
        description: 'Message for dialog box confirming lab delete',
    },
});
export const DeleteLabDialog = (props) => {
    const { lab, isSubmitting, onDismiss, deleteLab } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    // close the dialog after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    const onSubmit = () => {
        deleteLab(lab.id);
    };
    return (<Dialog hidden={false} onDismiss={isSubmitting ? undefined : onDismiss} maxWidth={Constants.DefaultDialogWidth} dialogContentProps={{
        type: DialogType.normal,
        title: msg(messages.deleteLabTitle),
        subText: msg(messages.deleteLabMessage, { name: lab.name }),
    }}>
            <Stack horizontal tokens={{ childrenGap: '8px' }} styles={{ root: { width: '100%' } }} verticalAlign="end" horizontalAlign="end">
                <Stack.Item>
                    <SubmitButton isSubmitting={isSubmitting} buttonText={msg(commonMessages.delete)} onClick={onSubmit}/>
                </Stack.Item>
                <Stack.Item>
                    <DefaultButton disabled={isSubmitting} onClick={isSubmitting ? undefined : onDismiss} text={msg(commonMessages.cancel)}/>
                </Stack.Item>
            </Stack>
        </Dialog>);
};
export default DeleteLabDialog;
