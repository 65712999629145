import { call, put, select } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import LabProvider from '../../../data/providers/lab-provider';
import { ResourceId } from '../../../utils/resource-id';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { LabActionType } from '../../actions/lab/lab-actions';
import { getLab, syncLabUsersError } from '../../actions/lab/lab-action-creators';
import VNextLabProvider from '../../../data/providers/vnext/arm-api/lab-provider';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { listUsers } from '../../actions/user/user-action-creators';
export function* syncLabUsers(action) {
    const { labId } = action;
    try {
        const resourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(LabProvider.syncUserList, resourceId, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.SyncLabUsers, e ? e.code : undefined);
        yield put(syncLabUsersError(labId, error));
    }
}
export function* syncVNextLabUsers(action) {
    const { labId } = action;
    try {
        const resourceId = new ResourceId(labId, true);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(VNextLabProvider.syncLabUser, resourceId, accessToken, locale, language);
        yield put(getLab(labId, true, true));
        yield put(listUsers(labId));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.SyncLabUsers, e ? e.code : undefined);
        yield put(syncLabUsersError(labId, error));
    }
}
export function* syncLabUsersSaga() {
    yield labParentBasedTakeLatest(LabActionType.SYNC_LAB_USERS, syncLabUsers, syncVNextLabUsers);
}
