import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    showTipsLabel: {
        id: 'LabListIntroShowTips',
        defaultMessage: 'Show tips and suggestions',
        description: 'The label for a checkbox to enable / disable showing tips and suggestions.',
    },
    initialPageTitle: {
        id: 'LabListIntroInitialPageTitle',
        defaultMessage: 'Welcome to Azure Lab Services',
        description: 'The title text displayed on the initial page of the lab list introduction.',
    },
    initialPageContent: {
        id: 'LabListIntroInitialPageContent',
        defaultMessage: 'Azure Lab Services is the easiest way to create and manage virtual computer labs for your classroom. To help you get started, we would like to introduce you to some core concepts.',
        description: 'The text content on the initial page of the lab list introduction.',
    },
    templatePageTitle: {
        id: 'LabListIntroTemplatePageTitle',
        defaultMessage: 'Template virtual machine',
        description: 'The title text displayed on the template page of the lab list introduction.',
    },
    templatePageContent: {
        id: 'LabListIntroTemplatePageContent',
        defaultMessage: 'A template virtual machine is a base image from which all other virtual machines in the lab are created. You can configure the template virtual machine and install the software you want to provide to students.',
        description: 'The text content of the template page of the lab list introduction',
    },
    labPageTitle: {
        id: 'LabListIntroLabPageTitle',
        defaultMessage: 'Creating a new lab',
        description: 'The title text displayed on the lab page of the lab list introduction.',
    },
    labPageContent: {
        id: 'LabListIntroLabPageContent',
        defaultMessage: 'A lab is set up by selecting the virtual machine size, operating system image, and credentials for the lab. A template virtual machine will then be created using those settings. Publishing will replicate its contents to all student virtual machines in the lab.',
        description: 'The text content of the lab page of the lab list introduction.',
    },
    studentPageTitle: {
        id: 'LabListIntroStudentPageTitle',
        defaultMessage: 'Student virtual machines',
        description: 'The title text displayed on the student page of the lab list introduction.',
    },
    studentPageContent: {
        id: 'LabListIntroStudentPageContent',
        defaultMessage: 'Students can register using a link, and virtual machines will be assigned to them in the lab. Once registered, they can start, connect, and stop their virtual machine in order to work with it directly.',
        description: 'The text content of the student page of the lab list introduction.',
    },
});
