import { actionChannel, call, put, select, take } from 'redux-saga/effects';
import { ErrorCode } from '../../../common/error-codes';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { IdentityErrorCode } from '../../../data/models/identity';
import { getAccessToken } from '../../../data/providers/msal-provider';
import { getAccessToken as getAccessTokenTeams } from '../../../data/providers/teams-provider';
import { fatalError } from '../../actions/common/common-action-creators';
import { getAccessTokenError, getAccessTokenFailed, getAccessTokenRedirecting, getAccessTokenSuccess, getArmAccessToken, getGraphAccessToken, getLabsApiAccessToken, } from '../../actions/identity/identity-action-creators';
import { IdentityActionType } from '../../actions/identity/identity-actions';
import { isLmsHost, isTeamsHost } from '../../selectors/common-selectors';
import { getTenantId } from '../../selectors/identity-selector';
import { getReturnUrl } from '../../selectors/route-selector';
import { isLmsStudent } from '../../selectors/lti-selectors';
import { getLtiTokenSaga } from '../lti/lti-token';
export async function tokenPromiseWait(promise) {
    const token = await promise;
    return token;
}
export function createTokenPromise() {
    let resolve;
    let reject;
    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });
    return {
        resolve: resolve,
        reject: reject,
        promise,
    };
}
export function* getArmAccessTokenSaga(canRedirect = false, returnUrl) {
    const tenant = yield select(getTenantId);
    const { resolve, reject, promise } = (yield call(createTokenPromise));
    yield put(getArmAccessToken(resolve, reject, tenant, canRedirect, returnUrl));
    const token = yield call(tokenPromiseWait, promise);
    return token;
}
export function* getLabsApiAccessTokenSaga(canRedirect = false, returnUrl, shouldAlwaysUseLtiTokenWhenInLms) {
    let shouldUseLtiToken = false;
    if (shouldAlwaysUseLtiTokenWhenInLms) {
        // we should always use ltiToken when calling api for student virtual machines both in student view or the professor 'My virutal machines' panel view.
        shouldUseLtiToken = yield select(isLmsHost);
    }
    else {
        // for other labs api call which are for professor only, e.g. coreUsage,  we should still use aad token. so we determine which token to use by the lti role.
        shouldUseLtiToken = yield select(isLmsStudent);
    }
    if (shouldUseLtiToken) {
        const token = yield call(getLtiTokenSaga);
        return token;
    }
    else {
        const tenant = yield select(getTenantId);
        const { resolve, reject, promise } = (yield call(createTokenPromise));
        yield put(getLabsApiAccessToken(resolve, reject, tenant, canRedirect, returnUrl));
        const token = yield call(tokenPromiseWait, promise);
        return token;
    }
}
export function* getGraphAccessTokenSaga(tenantId, canRedirect = false) {
    const tenant = tenantId ? tenantId : yield select(getTenantId);
    const { resolve, reject, promise } = (yield call(createTokenPromise));
    yield put(getGraphAccessToken(resolve, reject, tenant, canRedirect));
    const token = yield call(tokenPromiseWait, promise);
    return token;
}
export function* getAccessTokenRequest(action) {
    let { returnUrl } = action;
    const { scopes, isErrorTerminal, tenantId, canRedirect, resolve, reject } = action;
    try {
        if (!tenantId) {
            const error = new MlClientError(undefined, tenantId, FailureOperation.GetAccessToken, IdentityErrorCode.AccessDenied);
            yield call(reject, error);
            return;
        }
        let result;
        const isTeamsHosted = yield select(isTeamsHost);
        const isLmsHosted = yield select(isLmsHost);
        if (isLmsHosted) {
            result = yield call(getAccessToken, scopes, tenantId, undefined, true);
        }
        else if (canRedirect) {
            if (!returnUrl) {
                returnUrl = yield select(getReturnUrl);
            }
            if (isTeamsHosted) {
                result = yield call(getAccessTokenTeams, scopes, tenantId, returnUrl);
            }
            else {
                result = yield call(getAccessToken, scopes, tenantId, returnUrl);
            }
        }
        else {
            if (isTeamsHosted) {
                result = yield call(getAccessTokenTeams, scopes, tenantId);
            }
            else {
                result = yield call(getAccessToken, scopes, tenantId);
            }
        }
        const { accessToken, isRedirecting, errorCode } = result;
        if (accessToken) {
            yield put(getAccessTokenSuccess());
            yield call(resolve, accessToken);
        }
        else if (isRedirecting) {
            yield put(getAccessTokenRedirecting(scopes, tenantId));
        }
        else {
            const error = new MlClientError(undefined, tenantId, FailureOperation.GetAccessToken, errorCode);
            switch (errorCode) {
                case IdentityErrorCode.Renewal:
                case IdentityErrorCode.InteractionRequired:
                case IdentityErrorCode.UserLogin:
                case IdentityErrorCode.LoginRequired:
                case IdentityErrorCode.EmptyIdToken: {
                    yield put(getAccessTokenFailed(errorCode));
                    if (isErrorTerminal) {
                        yield put(fatalError(ErrorCode.SessionExpired, error));
                    }
                    break;
                }
                case IdentityErrorCode.EndpointsError:
                case IdentityErrorCode.InvalidState: {
                    yield put(getAccessTokenError(errorCode, error));
                    if (isErrorTerminal) {
                        yield put(fatalError(ErrorCode.AuthenticationFailed, error));
                    }
                    break;
                }
                case IdentityErrorCode.PopupWindowError:
                    yield put(getAccessTokenError(errorCode, error));
                    if (isErrorTerminal) {
                        yield put(fatalError(ErrorCode.PopupWindowBlocked, error));
                    }
                    break;
                default: {
                    yield put(getAccessTokenError(errorCode, error));
                    if (isErrorTerminal) {
                        yield put(fatalError(ErrorCode.GenericError, error));
                    }
                    break;
                }
            }
            yield call(reject, error);
        }
    }
    catch (err) {
        const errorCode = IdentityErrorCode.Failed;
        const error = new MlClientError(err ? err.message : undefined, tenantId, FailureOperation.GetAccessToken, err ? err.code : errorCode);
        yield put(getAccessTokenError(errorCode, error));
        if (isErrorTerminal) {
            yield put(fatalError(ErrorCode.GenericError, error));
        }
        yield call(reject, error);
    }
}
export function* watchGetAccessTokenRequests() {
    const requestChannel = yield actionChannel(IdentityActionType.GET_ACCESS_TOKEN);
    while (true) {
        // this allows the action channel to act as a queue and lets us run
        // our access token requests in serial to resolve issues with the MSAL
        // library we use to acquire tokens
        const action = yield take(requestChannel);
        yield call(getAccessTokenRequest, action);
    }
}
