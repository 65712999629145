import { Link } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import InfoTip from '../info-tip';
export const ShutdownOnDisconnectInfoTip = (props) => (<InfoTip ariaLabel={props.ariaLabel} content={<>
                <p>
                    <FormattedMessage id="ShutdownOnDisconnectTooltip1" defaultMessage="Shut down will occur when a Remote Desktop connection is disconnected for Windows machines, or an SSH connection is disconnected for Linux machines (only <Link>specific distrubutions and verisons of Linux</Link> are supported)." description="First line of tooltip for Shutdown on Disconnect setting. <Link> and </Link> should not be localized, but text between them should." values={{
    Link: (chunks) => (<Link href="https://aka.ms/azlabs-linuxidle" target="_blank">
                                    {chunks}
                                </Link>),
}}/>
                </p>
                <p>
                    <FormattedMessage id="ShutdownOnDisconnectTooltip2" defaultMessage="You can specify how long the virtual machines should wait for the user to reconnect before automatically shutting down." description="Second line of tooltip for Shutdown on Disconnect setting."/>
                </p>
            </>}/>);
export const VNextShutdownOnDisconnectInfoTip = (props) => (<InfoTip ariaLabel={props.ariaLabel} content={<>
                <p>
                    <FormattedMessage id="VNextShutdownOnDisconnectTooltip1" defaultMessage="Shut down will occur when a Remote Desktop connection is disconnected for Windows machines, or an SSH connection is disconnected for Linux machines." description="First line of tooltip for Shutdown on Disconnect setting."/>
                </p>
                <p>
                    <FormattedMessage id="VNextShutdownOnDisconnectTooltip2" defaultMessage="You can specify how long the virtual machines should wait for the user to reconnect before automatically shutting down." description="Second line of tooltip for Shutdown on Disconnect setting."/>
                </p>
            </>}/>);
export const DisconnectOnIdleInfoTip = (props) => (<InfoTip ariaLabel={props.ariaLabel} content={<>
                <FormattedMessage id="DisconnectOnIdleTooltip1" defaultMessage="This setting uses <Link>Window OS's definition of idle</Link>, which uses two criteria:" description="First line of tooltip for Disconnect on Idle setting (list of points follows). <Link> and </Link> should not be localized, but text between them should." values={{
    Link: (chunks) => (<Link href="https://aka.ms/azlabs-windowsidle" target="_blank">
                                {chunks}
                            </Link>),
}}/>
                <p>
                    <FormattedMessage id="DisconnectOnIdleTooltip2" defaultMessage="User absence - no keyboard or mouse input." description="Numbered item  under list of reasons why disconnect on idle occurs."/>
                </p>
                <p>
                    <FormattedMessage id="DisconnectOnIdleTooltip3" defaultMessage="Lack of resource consumption - all the processors and all the disks were idle for a certain % of time." description="Numbered item  under list of reasons why disconnect on idle occurs."/>
                </p>
            </>}/>);
export const ShutdownOnIdleInfoTip = (props) => (<InfoTip ariaLabel={props.ariaLabel} content={<>
                <p>
                    <FormattedMessage id="ShutdownOnIdleTooltip1" defaultMessage="Shut down on idle helps manage costs in your labs." description="Description about enabling shutdown on Idle."/>
                </p>
                <p>
                    <FormattedMessage id="ShutdownOnIdleTooltip2" defaultMessage="Idle based on absence is effective in most cases, but absence and resource usage allows machines in your lab to stay connected until long-running compute operations are complete. This is useful in data science classes, for example." description="Description about idle contions for shutdown on Idle."/>
                </p>
            </>}/>);
