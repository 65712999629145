import { CommonActionType } from '../common/common-actions';
export const OperationsResultActionType = {
    ...CommonActionType,
    POLL_OPERATIONS_RESULT: 'POLL_OPERATIONS_RESULT',
    POLL_PUBLISH_OPERATION_RESULT: 'POLL_PUBLISH_OPERATION_RESULT',
    POLL_PUBLISH_OPERATION_RESULT_SUCCESS: 'POLL_PUBLISH_OPERATION_RESULT_SUCCESS',
    POLL_PUBLISH_OPERATION_RESULT_CANCELLED: 'POLL_PUBLISH_OPERATION_RESULT_CANCELLED',
    POLL_OPERATIONS_RESULT_SUCCESS: 'POLL_OPERATIONS_RESULT_SUCCESS',
    POLL_OPERATIONS_RESULT_CANCELLED: 'POLL_OPERATIONS_RESULT_CANCELLED',
    POLL_OPERATIONS_RESULT_ERROR: 'POLL_OPERATIONS_RESULT_ERROR',
    POLL_USER_OPERATIONS_RESULT: 'POLL_USER_OPERATIONS_RESULT',
    POLL_USER_OPERATIONS_RESULT_SUCCESS: 'POLL_USER_OPERATIONS_RESULT_SUCCESS',
    POLL_USER_OPERATIONS_RESULT_CANCELLED: 'POLL_USER_OPERATIONS_RESULT_CANCELLED',
    POLL_USER_OPERATIONS_RESULT_ERROR: 'POLL_USER_OPERATIONS_RESULT_ERROR',
};
