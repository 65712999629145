import * as React from 'react';
import { injectIntl } from 'react-intl';
import { DefaultButton, Stack } from '@fluentui/react';
import _ from 'lodash';
import { daysShortLocaleAware } from './flyout-utilities';
import { getCustomTheme } from '../common/themes/selectors';
import './schedule.css';
const DayPickerInjected = (props) => {
    const { locale, errorMessage, label } = props;
    return (<>
            <div className="schedules__flyout-control--label">{label}</div>
            <div className={errorMessage ? 'schedules__flyout-daypicker-error' : ''}>
                <Stack horizontal horizontalAlign="space-between">
                    {daysShortLocaleAware(locale).map((day) => {
        return (<Stack.Item key={day.longText}>
                                <DefaultButton key={day.longText} ariaLabel={day.longText} checked={isChecked(props.days, day.value)} text={day.text} onClick={() => onSelectDay(props.onChange, props.days, day.value)} styles={getCustomTheme().dayPickerButtonStyles}/>
                            </Stack.Item>);
    })}
                </Stack>
            </div>
            {errorMessage && <span className="schedule__flyout-error">{errorMessage}</span>}
        </>);
};
function onSelectDay(onChange, days, day) {
    const isDayAlreadyChecked = isChecked(days, day);
    if (isDayAlreadyChecked) {
        days = _.without(days, day);
    }
    else {
        days = [...days];
        days.push(day);
    }
    onChange(days);
}
function isChecked(days, day) {
    return days.some((weekday) => weekday === day);
}
const DayPicker = injectIntl(DayPickerInjected);
export default DayPicker;
