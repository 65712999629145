import { ActionButton, Icon, Stack, TooltipHost, getTheme } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { PageHeaderFilteredDropdown } from './dropdown/page-header-filtered-dropdown';
const messages = defineMessages({
    ariaLabel: {
        id: 'LabPickerAriaLabel',
        defaultMessage: 'Select a lab',
        description: 'Aria label for the lab picker control',
    },
    searchLabsText: {
        id: 'LabPickerSearchPlaceholder',
        defaultMessage: 'Search labs',
        description: 'Placeholder for the lab picker search box',
    },
    noLabsFoundFilterText: {
        id: 'LabPickerSearchNoLabsMatchFilterText',
        defaultMessage: 'No labs found',
        description: 'Message to inform the user that no labs were found that match the filter criteria',
    },
});
export const Selected = (props) => {
    const { lab, isVNextLab } = props;
    return (<Stack horizontal={true} verticalAlign="center" horizontalAlign="start" grow styles={{ root: { marginRight: '5px' } }}>
            <Stack.Item>
                <span className="lab-picker__item-container">
                    {getDisplayName(lab, isVNextLab)}
                </span>
            </Stack.Item>
        </Stack>);
};
function getDisplayName(lab, isVNextLab) {
    return isVNextLab ? lab?.title ?? lab.name : lab.name;
}
export const Item = (props) => {
    return (<Stack horizontal={true} verticalAlign="center" tokens={{ padding: '0 0 0 10px' }} horizontalAlign="start" grow>
            <Stack.Item>
                <span className="lab-picker__item-container">
                    {getDisplayName(props.lab, props.isVNextLab)}
                </span>
            </Stack.Item>
        </Stack>);
};
export const Placeholder = () => {
    return (<Stack horizontal={true} verticalAlign="center" horizontalAlign="start" grow>
            <Stack.Item>
                <span className="lab-picker__item-container">
                    <FormattedMessage id="LabPickerPlaceholder" defaultMessage="Select a lab" description="The placeholder message displayed in the lab picker when no lab is selected."/>
                </span>
            </Stack.Item>
        </Stack>);
};
export const Footer = (props) => {
    const { onNewLab, isNewLabCreationDisabled } = props;
    return (<TooltipHost content={isNewLabCreationDisabled ? (<FormattedMessage id="NoCoresForSubscriptionNewLabButtonTooltip" defaultMessage="Your admin needs to request a limit increase in Azure Lab Services for virtual machine cores before you can create a lab. Please contact your Lab Services administator." description="Message displayed on tooltip of disabled new lab buttonnpm  when customer subscription type has no cores available by default."/>) : ('')}>
            <ActionButton onClick={onNewLab} disabled={isNewLabCreationDisabled} className="custom-link-button" styles={{ root: { color: getTheme().palette.themePrimary } }}>
                <Stack horizontal tokens={{ childrenGap: '5px' }} verticalAlign="center">
                    <Stack.Item>
                        <Icon iconName="CirclePlus"/>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { paddingBottom: '2px' } }}>
                        <FormattedMessage id="LabPickerFooter" defaultMessage="New lab" description="Action button text displayed in the lab picker to create a new lab."/>
                    </Stack.Item>
                </Stack>
            </ActionButton>
        </TooltipHost>);
};
const NoResultsInjected = (props) => {
    const message = props.intl.formatMessage(messages.noLabsFoundFilterText);
    return (<Stack horizontal={true} verticalAlign="center" horizontalAlign="start" tokens={{ childrenGap: '4px', padding: '0 0 0 10px' }} grow>
            <Stack.Item>
                <Icon iconName="SearchIssue" title={message}/>
            </Stack.Item>
            <Stack.Item>{message}</Stack.Item>
        </Stack>);
};
export const NoResults = injectIntl(NoResultsInjected);
function convertLabToOption(lab) {
    return { value: lab.id };
}
function mapLabsToOptions(labs, isVNextLabs) {
    return isVNextLabs
        ? labs.map((l) => convertLabToOption(l)).toJS()
        : labs.map((l) => convertLabToOption(l)).toJS();
}
function getLabFromOption(option, labs) {
    return labs.find((l) => l.id === option.value);
}
function filterLabs(filter, labs, isVNextLabs) {
    const lowerCaseFilter = filter.toLowerCase();
    const filteredLabs = isVNextLabs
        ? labs.filter((lab) => getDisplayName(lab, isVNextLabs).toLowerCase().indexOf(lowerCaseFilter) > -1)
        : labs.filter((lab) => getDisplayName(lab, isVNextLabs).toLowerCase().indexOf(lowerCaseFilter) > -1);
    return mapLabsToOptions(filteredLabs, isVNextLabs);
}
const LabPickerInjected = (props) => {
    const { isLoading, isTeamsOrLmsIntegrationEnabled, labs, selected, onChange, isNewLabCreationDisabled, styles, intl, isVNextLabs, } = props;
    const placeholder = intl.formatMessage(messages.searchLabsText);
    const ariaLabel = intl.formatMessage(messages.ariaLabel);
    const options = isLoading ? [] : mapLabsToOptions(labs, isVNextLabs);
    const selectedOption = selected ? convertLabToOption(selected) : undefined;
    return (<PageHeaderFilteredDropdown items={options} styles={styles} ariaLabel={ariaLabel} filterPlaceholder={placeholder} filterAriaLabel={placeholder} selected={selectedOption} isLoading={isLoading} minExpandedWidth={'300px'} onChange={(option) => {
        onChange(getLabFromOption(option, labs));
    }} onRenderSelected={(selected) => (<Selected lab={getLabFromOption(selected, labs)} isVNextLab={isVNextLabs} isTeamsOrLmsIntegrationEnabled={isTeamsOrLmsIntegrationEnabled}/>)} onRenderItem={(item) => (<Item lab={getLabFromOption(item, labs)} isVNextLab={isVNextLabs}/>)} onFilterItems={(filter) => filterLabs(filter, labs, isVNextLabs)} onRenderPlaceholder={() => <Placeholder />} onRenderNoResults={() => <NoResults />} onRenderFooter={() => (<Footer isNewLabCreationDisabled={isNewLabCreationDisabled} onNewLab={props.newLab}/>)}/>);
};
export const LabPicker = injectIntl(LabPickerInjected);
