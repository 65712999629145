import { Map, Record } from 'immutable';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { UsageActionType, } from '../../actions/vnext/usage/usage-actions';
function listUsage(state, action) {
    return state.merge({
        error: undefined,
        loadState: LoadingStoreState.Loading,
    });
}
function listRegionUsageSuccess(state, action) {
    const { data, region } = action;
    let usageByRegion = state.get('usageByRegion');
    usageByRegion = usageByRegion?.set(region, data);
    return state.merge({
        usageByRegion,
    });
}
function listUsageError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: LoadingStoreState.LoadingFailed,
        error,
    });
}
function listUsageSuccess(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loaded,
    });
}
function listUsageCancelled(state, action) {
    return state.merge({
        loadState: LoadingStoreState.NotStarted,
        usageByRegion: Map(),
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    error: undefined,
    usageByRegion: Map(),
})();
export const usageReducer = (state = initialState, action) => {
    switch (action.type) {
        case UsageActionType.SELECT_TENANT:
        case UsageActionType.SELECT_LAB:
        case UsageActionType.SELECT_LAB_PLAN:
        case UsageActionType.SELECT_LAB_PARENT_RESOURCE:
        case UsageActionType.CLEAR_USER_SESSION:
            return state.merge(initialState);
        case UsageActionType.LIST_USAGE_DATA:
            return listUsage(state, action);
        case UsageActionType.LIST_REGION_USAGE_DATA_SUCCESS:
            return listRegionUsageSuccess(state, action);
        case UsageActionType.LIST_USAGE_DATA_ERROR:
            return listUsageError(state, action);
        case UsageActionType.LIST_USAGE_DATA_SUCCESS:
            return listUsageSuccess(state, action);
        case UsageActionType.LIST_USAGE_DATA_CANCELLED:
            return listUsageCancelled(state, action);
        default:
            return state;
    }
};
export default usageReducer;
