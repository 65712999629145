import { Record, List, Map } from 'immutable';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { Context } from '../../action-context';
import { ImageActionType, } from '../../actions/vnext/images/image-actions';
import { addToListMap } from '../../../utils/common';
function listLabPlanImages(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loading,
        errors: Map(),
    });
}
function listLabPlanImagesSuccess(state, action) {
    const { images } = action;
    return state.merge({
        loadState: LoadingStoreState.Loaded,
        images: List(images),
    });
}
function listLabPlanImagesError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addToListMap(errors, error, error.id);
    return state.merge({
        errors,
        loadState: LoadingStoreState.LoadingFailed,
    });
}
function createImageAccepted(state, action) {
    const { image } = action;
    return state.merge({
        images: state.get('images').push(image),
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    images: List(),
    errors: Map(),
})();
export const ImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case ImageActionType.CLEAR_USER_SESSION:
        case ImageActionType.SELECT_LAB_PARENT_RESOURCE:
        case ImageActionType.SELECT_TENANT:
        case ImageActionType.SELECT_LAB_PLAN:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    switch (action.type) {
                        case ImageActionType.LIST_IMAGES:
                            return listLabPlanImages(state, action);
                        case ImageActionType.LIST_IMAGES_SUCCESS:
                            return listLabPlanImagesSuccess(state, action);
                        case ImageActionType.LIST_IMAGES_ERROR:
                            return listLabPlanImagesError(state, action);
                        case ImageActionType.CREATE_IMAGE_ACCEPTED:
                            return createImageAccepted(state, action);
                        default:
                            return state;
                    }
                default:
                    return state;
            }
    }
};
export default ImageReducer;
