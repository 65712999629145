import { CommonLabActionType } from '../lab/lab-actions';
import { CommonActionType } from '../common/common-actions';
import { CommonLabParentResourceActionType } from '../lab-parent-resource/lab-parent-resource-actions';
import { CommonTemplateActionType } from '../template/template-actions';
import { CommonSharedImageActionType } from '../shared-image/shared-image-actions';
export const EnvironmentActionType = {
    ...CommonActionType,
    ...CommonLabActionType,
    ...CommonLabParentResourceActionType,
    ...CommonTemplateActionType,
    ...CommonSharedImageActionType,
    LIST_ENVIRONMENTS: 'LIST_ENVIRONMENTS',
    LIST_ENVIRONMENTS_SUCCESS: 'LIST_ENVIRONMENTS_SUCCESS',
    LIST_ENVIRONMENTS_CANCELLED: 'LIST_ENVIRONMENTS_CANCELLED',
    LIST_ENVIRONMENTS_ERROR: 'LIST_ENVIRONMENTS_ERROR',
    START_ENVIRONMENTS: 'START_ENVIRONMENTS',
    START_ENVIRONMENTS_ACCEPTED: 'START_ENVIRONMENTS_ACCEPTED',
    START_ENVIRONMENTS_ERROR: 'START_ENVIRONMENTS_ERROR',
    STOP_ENVIRONMENTS: 'STOP_ENVIRONMENTS',
    STOP_ENVIRONMENTS_ACCEPTED: 'STOP_ENVIRONMENTS_ACCEPTED',
    STOP_ENVIRONMENTS_ERROR: 'STOP_ENVIRONMENTS_ERROR',
    RESET_ENVIRONMENTS: 'RESET_ENVIRONMENTS',
    RESET_ENVIRONMENTS_ACCEPTED: 'RESET_ENVIRONMENTS_ACCEPTED',
    RESET_ENVIRONMENTS_ERROR: 'RESET_ENVIRONMENTS_ERROR',
    GET_ENVIRONMENT: 'GET_ENVIRONMENT',
    GET_ENVIRONMENT_SUCCESS: 'GET_ENVIRONMENT_SUCCESS',
    GET_ENVIRONMENT_CANCELLED: 'GET_ENVIRONMENT_CANCELLED',
    GET_ENVIRONMENT_ERROR: 'GET_ENVIRONMENT_ERROR',
    CLEAR_ENVIRONMENTS_LOAD_ERROR: 'CLEAR_ENVIRONMENTS_LOAD_ERROR',
    CLEAR_ENVIRONMENTS_START_ERROR: 'CLEAR_ENVIRONMENTS_START_ERROR',
    CLEAR_ENVIRONMENTS_STOP_ERROR: 'CLEAR_ENVIRONMENTS_STOP_ERROR',
    CLEAR_ENVIRONMENTS_RESET_ERROR: 'CLEAR_ENVIRONMENTS_RESET_ERROR',
    POLL_ENVIRONMENTS_START: 'POLL_ENVIRONMENTS_START',
    POLL_ENVIRONMENTS_STOP: 'POLL_ENVIRONMENTS_STOP',
    POLL_ENVIRONMENTS_CANCELLED: 'POLL_ENVIRONMENTS_CANCELLED',
    CLEAR_VIRTUAL_MACHINE_GET_ERROR: 'CLEAR_VIRTUAL_MACHINE_GET_ERROR',
    CLEAR_VIRTUAL_MACHINE_RESET_PASSWORD_ERROR: 'CLEAR_VIRTUAL_MACHINE_RESET_PASSWORD_ERROR',
    POLL_VIRTUAL_MACHINE_START: 'POLL_VIRTUAL_MACHINE_START',
    POLL_VIRTUAL_MACHINE_STOP: 'POLL_VIRTUAL_MACHINE_STOP',
    POLL_VIRTUAL_MACHINE_CANCELLED: 'POLL_VIRTUAL_MACHINE_CANCELLED',
    DISMISS_EXPORT_STARTED_MESSAGE_BAR: 'DISMISS_EXPORT_STARTED_MESSAGE_BAR',
    CLEAR_EXPORT_ERROR: 'CLEAR_EXPORT_ERROR',
    REDEPLOY_VIRTUAL_MACHINE: 'REDEPLOY_VIRTUAL_MACHINE',
    REDEPLOY_VIRTUAL_MACHINE_ERROR: 'REDEPLOY_VIRTUAL_MACHINE_ERROR',
    REDEPLOY_VIRTUAL_MACHINE_ACCEPTED: 'REDEPLOY_VIRTUAL_MACHINE_ACCEPTED',
    CLEAR_VIRTUAL_MACHINE_REDEPLOY_ERROR: 'CLEAR_VIRTUAL_MACHINE_REDEPLOY_ERROR',
};
