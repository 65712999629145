import { Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SafeSpinButton } from '../../common/safe-spin-button';
import { ConnectionTypeDropdown } from '../../common/connection-type/connection-type-dropdown';
import { areConnectionTypesValid } from '../../common/connection-type/validators';
import { isIdleSupported } from '../../common/shutdown-policy/selectors';
import { ShutdownPolicyGroup } from '../../common/shutdown-policy/shutdown-policy-group';
import Constants from '../../utils/constants';
import { messages } from './create-lab-messages';
import PanelTimeEstimate from '../../common/create-lab-time-estimate';
export const NewLabPoliciesPage = (props) => {
    const { isVNext, image, isSubmitting, quota, idleConfig, isBastionEnabled, isBastionSupported, connectionTypes, isConnectionTypesValid, onQuotaChange, onConnectionTypesChange, onIdleConfigChange, onConnectionTypesValidationChange, } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const setConnectionTypes = (value) => {
        const isValid = areConnectionTypesValid(!image.isWindows, value, isVNext);
        onConnectionTypesChange(value);
        onConnectionTypesValidationChange(isValid);
    };
    return (<Stack tokens={{ childrenGap: 30 }} style={{ height: '100%' }}>
            <Stack.Item>
                <FormattedMessage {...messages.newLabPoliciesPageIntro}/>
            </Stack.Item>
            <Stack.Item>
                <SafeSpinButton label={msg(messages.quotaLabel)} autoFocus min={0} max={Constants.MaxLabQuota} disabled={isSubmitting} value={quota} onChange={onQuotaChange}/>
            </Stack.Item>
            {isBastionEnabled && (<Stack.Item>
                    <ConnectionTypeDropdown isVNext={isVNext} isLinux={!image.isWindows} isBastionSupported={isBastionSupported} clientTypesDisabled={false} disabled={isSubmitting} required={true} valid={isConnectionTypesValid} value={connectionTypes} onChange={setConnectionTypes}/>
                </Stack.Item>)}
            {isIdleSupported(idleConfig) && (<Stack.Item>
                    <ShutdownPolicyGroup disabled={isSubmitting} idleConfig={idleConfig} isLinuxSIG={image.isCustomImage && !image.isWindows} onChange={onIdleConfigChange} isVNext={isVNext}/>
                </Stack.Item>)}
            {!isVNext && (<Stack.Item styles={{ root: { height: '100%' } }}>
                    <PanelTimeEstimate timeEstimateMessage={msg(messages.newLabPoliciesPageTimeEstimate)}/>
                </Stack.Item>)}
        </Stack>);
};
export default NewLabPoliciesPage;
