import { Stack, Icon, Link, ProgressIndicator, Dropdown, ResponsiveMode, } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { caseInsensitiveCultureInvariantCompare } from '../utils/string-comparison';
const messages = defineMessages({
    teamsChooseLabAccountMessage: {
        id: 'TeamsChooseLabAccountMessage',
        defaultMessage: 'Which container would you like to use for creating labs in this team?',
        description: 'Message for dialog box to choose container for labs',
    },
});
export const TeamsConfigureLabParent = (props) => {
    const { labParentItems, onSave, isRefreshing, refreshLabParents, defaultSelectedId } = props;
    const hasSingleLabParent = labParentItems.size === 1;
    return (<Stack tokens={{ childrenGap: '25px' }} grow verticalFill>
            <Stack.Item>
                <Icon iconName="teams-choose-lab-account-content"/>
            </Stack.Item>
            <Stack.Item styles={{ root: { paddingRight: '5px', paddingLeft: '5px' } }}>
                <Stack>
                    <Stack.Item styles={{ root: { fontWeight: 600, fontSize: '20px' } }}>
                        <FormattedMessage id="TeamsConfigureLabAccountTitle" defaultMessage="Welcome to Azure Lab Services" description="Title for choose lab account dialog"/>
                    </Stack.Item>
                    {hasSingleLabParent && (<Stack.Item styles={{ root: { paddingTop: '16px' } }}>
                            <FormattedMessage id="TeamsChooseLabAccountGetStartedDescriptionSingleAccount" defaultMessage="All labs in this team will be created within the container below." description="Description for the teams configuration page when a single lab account is found."/>
                        </Stack.Item>)}
                    {!hasSingleLabParent && (<Stack.Item>
                            <TeamsChooseLabParentContent labParentItems={labParentItems} onSave={onSave} defaultSelectedId={defaultSelectedId}/>
                        </Stack.Item>)}
                    {hasSingleLabParent && (<Stack.Item>
                            <TeamsSingleLabParentContent labParentItem={labParentItems.get(0)} isRefreshing={isRefreshing} refreshLabParents={refreshLabParents} onSave={onSave}/>
                        </Stack.Item>)}
                </Stack>
            </Stack.Item>
        </Stack>);
};
const getOption = (labParentItem, selected) => {
    const option = { key: labParentItem.id, text: labParentItem.name, selected };
    return option;
};
const getOptions = (labParentItems) => {
    const options = labParentItems.toArray().map((o) => {
        return getOption(o);
    });
    return options;
};
export const TeamsChooseLabParentContent = (props) => {
    const intl = useIntl();
    const { labParentItems, onSave, defaultSelectedId } = props;
    const defaultSelectedItem = labParentItems.find((o) => caseInsensitiveCultureInvariantCompare(o.id, defaultSelectedId) === 0);
    const [selectedOption, setSelectedOption] = React.useState(getOption(defaultSelectedItem, true));
    const options = getOptions(labParentItems);
    const onChange = (option) => {
        setSelectedOption(option);
        onSave(option.key);
    };
    const dropdownProps = {
        options,
        selectedKey: selectedOption.key,
        onChange: (_, option) => !!option && onChange(option),
        // set responsive mode to let the list rendered in callout, rather than in a new panel
        responsiveMode: ResponsiveMode.large,
        calloutProps: {
            minPagePadding: 0,
            calloutMaxHeight: 110,
        },
    };
    return (<Stack.Item styles={{ root: { paddingTop: '20px', fontWeight: 600 } }}>
            <Dropdown {...dropdownProps} label={intl.formatMessage(messages.teamsChooseLabAccountMessage)}/>
        </Stack.Item>);
};
export const TeamsSingleLabParentContent = (props) => {
    const { labParentItem, isRefreshing, refreshLabParents } = props;
    return (<Stack>
            <Stack.Item>
                <Stack horizontal styles={{ root: { paddingTop: '34px', fontWeight: 600 } }}>
                    <Stack.Item>
                        <Icon iconName="Completed" styles={{ root: { color: '#008373' } }}/>
                    </Stack.Item>
                    <Stack styles={{ root: { paddingLeft: '10px' } }}>
                        <Stack.Item>
                            <FormattedMessage id="TeamsSingleLabAccountMessage" defaultMessage='"<span>{labParentName}</span>" will be used for the labs in this team.' description="Description about the current single account that will be used for this team. The value of {labParentName} contains the name of the lab account that is selected." values={{
        labParentName: labParentItem.name || '',
        span: (chunks) => <span style={{ fontWeight: 600 }}>{chunks}</span>,
    }}/>
                        </Stack.Item>
                        <Stack.Item styles={{ root: { paddingTop: '7px' } }}>
                            <FormattedMessage id="TeamsCheckAdditionalAccounts" defaultMessage="If this isn't the resource group or lab account you want to use, <Link>check for additional options.</Link>" description="Link to refresh resource groups/lab accounts if the current container is not what uses want" values={{
        Link: (chunks) => (<Link onClick={isRefreshing ? undefined : refreshLabParents}>{chunks}</Link>),
    }}/>
                        </Stack.Item>
                    </Stack>
                </Stack>
            </Stack.Item>
            <Stack.Item styles={{ root: { paddingLeft: '10px' } }}>
                {isRefreshing && <ProgressIndicator styles={{ root: { marginTop: '35px' } }}/>}
            </Stack.Item>
        </Stack>);
};
export default TeamsConfigureLabParent;
