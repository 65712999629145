import { hasFatalError, getFatalError, getFatalErrorCode } from '../selectors/common-selectors';
import { trackException, flush, trackEvent } from '../../utils/telemetry/telemetry-channel';
import ErrorCode from '../../common/error-codes';
import { CommonActionType } from '../actions/common/common-actions';
import { logAction } from './action-logger-middleware';
export const fatalErrorMiddleware = ({ getState }) => (next) => (action) => {
    // Evaluated prior to the action being dispatched to the reducer layer so
    // we can get a snapshot before the state change occurs.
    let storeState = getState();
    const alreadyHasFatal = hasFatalError(storeState);
    // If the action is a FatalErrorAction, we log the action itself when
    // there isn't a pre-existing fatal error in our state. If there was
    // a pre-existing one, we log a specific event instead to reduce noise and duplicate logs.
    if (action.type === CommonActionType.FATAL_ERROR) {
        if (!alreadyHasFatal) {
            // FATAL_ERROR is excluded from the action-logger-middleware
            // so we can use more specific logic when evaluating whether the action
            // gets logged or not. Thus it is handled here.
            logAction(action);
        }
        else {
            const { errorCode, error } = action;
            if (errorCode === ErrorCode.GenericError) {
                trackEvent('DuplicateFatalErrorActionDispatched', {
                    errorCode: errorCode,
                    error: !!error ? JSON.stringify(error) : 'N/A',
                });
            }
        }
    }
    // lets the action continue through the middleware pipeline
    const result = next(action);
    // Evaluated after the action has been processed by the reducer layer
    // and represents what is actually in our application state. We are interested
    // in the state and not the actions when logging these events because it is possible
    // for other actions beyond the explicit FATAL_ERROR action to result in a
    // fatal error in our store.
    if (!alreadyHasFatal) {
        storeState = getState();
        if (hasFatalError(storeState)) {
            const errorCode = getFatalErrorCode(storeState);
            const error = getFatalError(storeState);
            trackEvent('FatalErrorOccurred', {
                errorCode: errorCode,
                error: !!error ? JSON.stringify(error) : 'N/A',
            });
            if (errorCode === ErrorCode.GenericError) {
                trackException(undefined, !!error ? error : new Error('No fatal error data specified.'));
            }
            flush();
        }
    }
    return result;
};
export default fatalErrorMiddleware;
