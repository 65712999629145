import { all, fork } from 'redux-saga/effects';
import { listTemplatesSaga } from './list-templates';
import { updateTemplateSaga } from './update-template';
import { resetPasswordSaga } from './reset-password';
import { getTemplateSaga, pollTemplateSaga } from './get-template';
import { publishTemplateSaga } from './publish-template';
import { startTemplateSaga } from './start-template';
import { stopTemplateSaga } from './stop-template';
export function* rootSaga() {
    yield all([
        fork(listTemplatesSaga),
        fork(updateTemplateSaga),
        fork(resetPasswordSaga),
        fork(getTemplateSaga),
        fork(pollTemplateSaga),
        fork(publishTemplateSaga),
        fork(startTemplateSaga),
        fork(stopTemplateSaga),
    ]);
}
