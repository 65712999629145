import { LabAccountActionType, } from './lab-account-actions';
export const listLabAccounts = (selectFirst, selectFirstIfSelectedNotFound) => ({
    type: LabAccountActionType.LIST_LAB_ACCOUNTS,
    selectFirst,
    selectFirstIfSelectedNotFound,
});
export const listLabAccountsSuccess = (labAccounts) => ({
    type: LabAccountActionType.LIST_LAB_ACCOUNTS_SUCCESS,
    labAccounts,
});
export const listLabAccountsError = (error) => ({
    type: LabAccountActionType.LIST_LAB_ACCOUNTS_ERROR,
    error,
});
export const refreshSubscriptionsAndLabAccounts = () => ({
    type: LabAccountActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS,
});
export const refreshSubscriptionsAndLabAccountsSuccess = () => ({
    type: LabAccountActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS_SUCCESS,
});
export const refreshSubscriptionsAndLabAccountsError = (refreshError) => ({
    type: LabAccountActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS_ERROR,
    refreshError,
});
export const loadSubscriptionLabAccountsError = (subscription, error) => ({
    type: LabAccountActionType.LOAD_SUBSCRIPTION_LAB_ACCOUNTS_ERROR,
    subscription,
    error,
});
export const getLabAccount = (id) => ({
    type: LabAccountActionType.GET_LAB_ACCOUNT,
    id,
});
export const getLabAccountSuccess = (labAccount) => ({
    type: LabAccountActionType.GET_LAB_ACCOUNT_SUCCESS,
    labAccount,
});
export const getLabAccountError = (id, error) => ({
    type: LabAccountActionType.GET_LAB_ACCOUNT_ERROR,
    id,
    error,
});
const actions = {
    listLabAccounts,
    listLabAccountsSuccess,
    listLabAccountsError,
    loadSubscriptionLabAccountsError,
    getLabAccount,
    getLabAccountSuccess,
    getLabAccountError,
};
export default actions;
