import * as React from 'react';
import { connect } from 'react-redux';
import { LoadingContainer } from '../common/loading-section';
import { teamsStartMfa } from '../redux/actions/teams/teams-action-creators';
const TeamsStartMfaContainerInjected = (props) => {
    const { teamsStartMfa } = props;
    React.useEffect(() => {
        teamsStartMfa();
    }, []);
    return <LoadingContainer styles={{ root: { backgroundColor: 'inherit' } }}/>;
};
const mapDispatchToProps = {
    teamsStartMfa,
};
export const TeamsStartMfaContainer = connect(null, mapDispatchToProps)(TeamsStartMfaContainerInjected);
export default TeamsStartMfaContainer;
