import moment from 'moment-timezone';
import memoize from 'memoize-one';
import { Constants } from '../../utils/constants';
export const getLastGroupSyncRelativeTime = memoize((lab, locale) => {
    const now = moment(new Date()).locale(locale);
    const lastGroupSyncTime = moment(lab.lastGroupSyncTime).locale(locale);
    return lastGroupSyncTime.from(now);
});
export const getNextGroupSyncTime = memoize((ianaTimeZone, locale) => {
    moment.locale(locale);
    const currentDate = new Date();
    const nowMoment = moment(currentDate).utc();
    const scheduleUtcDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate(), Constants.ScheduledSyncTimeHour, Constants.ScheduledSyncTimeMinute));
    // if the sync job scheduled on today has already happened, use the next day
    if (moment(scheduleUtcDate).diff(nowMoment) <= 0) {
        const adjustedScheduleDate = new Date(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.getUTCDate() + 1, Constants.ScheduledSyncTimeHour, Constants.ScheduledSyncTimeMinute));
        return moment.tz(adjustedScheduleDate, ianaTimeZone).format('LT z');
    }
    return moment.tz(scheduleUtcDate, ianaTimeZone).format('LT z');
});
