import OperationStatus from '../../utils/operation-status';
export const isLatestOperation = (lab, operation) => {
    return (!!lab.latestOperationResult &&
        !!lab.latestOperationResult.requestUri &&
        lab.latestOperationResult.requestUri.includes(`${operation}?`));
};
export const isLabUserSyncFailed = (lab) => {
    return isLatestOperation(lab, 'syncUserList') && lab.latestOperationResult?.status === OperationStatus.Failed;
};
export const isLabUserSyncRunning = (lab) => {
    return isLatestOperation(lab, 'syncUserList') && lab.latestOperationResult?.status === OperationStatus.Running;
};
export const isLabUserSyncSucceeded = (lab) => {
    return isLatestOperation(lab, 'syncUserList') && lab.latestOperationResult?.status === OperationStatus.Succeeded;
};
export const isLabUserSyncing = (lab) => {
    return isLabUserSyncRunning(lab) || !!lab.isSyncPending;
};
