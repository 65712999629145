import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../../data/ml-client-error';
import PriceProvider from '../../../../data/providers/vnext/labs-api/price-provider';
import { PriceActionType } from '../../../actions/vnext/price/price-actions';
import { listPriceDataCancelled, listPriceDataError, listPriceDataSuccess, } from '../../../actions/vnext/price/price-action-creators';
import { getLanguage, getLocale } from '../../../selectors/common-selectors';
import { Map } from 'immutable';
import { getLabsApiAccessTokenSaga } from '../../identity/access-tokens';
export function* listPrice(action) {
    const { subscriptionId, regions } = action;
    try {
        const accessToken = yield call(getLabsApiAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const priceCalls = regions.map((region) => {
            return call(PriceProvider.listPrice, subscriptionId, region, accessToken, locale, language);
        });
        const { results, changed } = yield race({
            results: all(priceCalls),
            changed: race([
                take(PriceActionType.SELECT_LAB_PARENT_RESOURCE),
                take(PriceActionType.SELECT_LAB),
                take(PriceActionType.SELECT_LAB_PLAN),
            ]),
        });
        if (changed) {
            yield put(listPriceDataCancelled());
        }
        else {
            let map = Map();
            for (let index = 0; index < regions.length; index++) {
                map = map.set(regions[index], results[index]);
            }
            yield put(listPriceDataSuccess(map));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, subscriptionId, FailureOperation.ListPriceData, err ? err.code : undefined);
        yield put(listPriceDataError(error));
    }
}
export function* listPriceSaga() {
    yield takeLatest(PriceActionType.LIST_PRICE_DATA, listPrice);
}
