import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import memoize from 'memoize-one';
import { ProvisioningState } from '../utils/provisioning-state';
import Constants from '../utils/constants';
import { InviteStatus, UserStatus } from './user-list-models';
import { isCurrentLabUserSyncing, isLabGroupSynced, isLabConnectedLmsOrTeams, isCurrentLabReadOnly, getCurrentLab, getLabUsageQuota, isLabUserRestricted, isVNextLab, } from '../redux/selectors/lab-selectors';
import { hostNames } from '../utils/environment';
import { getIanaTimezone, getLocale, isTeamsOrLmsMode, storeHasLoadError, storeIsLoading, } from '../redux/selectors/common-selectors';
import { getGroupName, getGroups, isLoadingGroupList, isLoadingGroupName } from '../redux/selectors/group-selectors';
import { getSearch } from '../redux/selectors/route-selector';
import { isCurrentLabParentLabAccount } from '../redux/selectors/lab-parent-resource-selectors';
import { LabServicesModels } from 'lab-services';
import moment from 'moment';
import { getCurrentLabUpdateError, getVNextLabGroupId, getVNextLabGroupName, isLoadingLabMetadata, isVNextLabLmsConnected, shouldDisableCurrentLabUpdate, } from '../redux/selectors/vnext/lab-selectors';
// Transforms a ML user object to UserDetails consumable by the list view
function getUserDetails(user, labQuotaInHours) {
    const { registrationState, familyName, givenName, provisioningState, registrationLinkEmailState, latestRegistrationLinkEmailSent, id, email, totalUsage, additionalUsageQuota, } = user;
    let name, nameSortValue;
    if (registrationState === Ml.RegistrationState.Registered) {
        // HACK: We should get fullName from the API so we don't have to do this
        name = `${givenName || ''} ${familyName || ''}`.trim();
        if (!name) {
            name = Constants.DefaultUserName;
            nameSortValue = '';
        }
        else {
            nameSortValue = name;
        }
    }
    else {
        name = Constants.DefaultUserName;
        nameSortValue = '';
    }
    let canDelete = false;
    let canInvite = false;
    let canInviteAgain = false;
    let canAddQuota = false;
    // Determine user status
    let status;
    switch (provisioningState) {
        case ProvisioningState.Deleting:
            status = UserStatus.Deleting;
            break;
        default:
            canDelete = true;
            canAddQuota = true;
            status =
                registrationState === Ml.RegistrationState.Registered
                    ? UserStatus.Registered
                    : UserStatus.NotRegistered;
            break;
    }
    // Determine Invitation text for filter and list view.
    let inviteStatus;
    switch (registrationLinkEmailState) {
        case Ml.RegistrationLinkEmailState.NotSent:
            inviteStatus = InviteStatus.NotSent;
            canInvite = canDelete;
            break;
        case Ml.RegistrationLinkEmailState.Sending:
            inviteStatus = InviteStatus.Sending;
            break;
        case Ml.RegistrationLinkEmailState.Sent:
            inviteStatus = InviteStatus.Sent;
            canInviteAgain = canDelete;
            break;
        case Ml.RegistrationLinkEmailState.Failed:
            inviteStatus = InviteStatus.SendingFailed;
            canInvite = canDelete;
            break;
        default:
            inviteStatus = InviteStatus.Unknown;
            break;
    }
    const currentUsage = totalUsage ? totalUsage.asHours() : 0;
    const additionalQuota = additionalUsageQuota ? additionalUsageQuota.asHours() : 0;
    const totalQuota = labQuotaInHours + additionalQuota;
    return {
        key: id || '',
        id: id || '',
        name,
        email: email || '',
        nameSortValue,
        status,
        inviteStatus,
        currentUsage,
        totalQuota,
        additionalQuota,
        canDelete,
        canInvite,
        canInviteAgain,
        canAddQuota,
        latestRegistrationLinkEmailSent,
    };
}
function getVNextUserDetails(user, labQuotaInHours) {
    const { registrationState, displayName, invitationSent, id, email, totalUsage, additionalUsageQuota } = user;
    let { provisioningState, invitationState, pendingProvisioningState, isSendingInivatationPending } = user; // eslint-disable-line prefer-const
    provisioningState = !!pendingProvisioningState ? pendingProvisioningState : provisioningState;
    invitationState = !!isSendingInivatationPending ? LabServicesModels.InvitationState.Sending : invitationState;
    let nameSortValue;
    let name = displayName || '';
    if (!name) {
        name = Constants.DefaultUserName;
        nameSortValue = '';
    }
    else {
        nameSortValue = name;
    }
    let canDelete = false;
    let canInvite = false;
    let canInviteAgain = false;
    let canAddQuota = false;
    let canSendInvitation = false;
    // Determine user status
    let status;
    switch (provisioningState) {
        case LabServicesModels.ProvisioningState.Deleting:
            status = UserStatus.Deleting;
            break;
        case LabServicesModels.ProvisioningState.Failed:
            status = UserStatus.Failed;
            canDelete = true;
            break;
        default:
            canDelete = true;
            canAddQuota = true;
            canSendInvitation = true;
            status =
                registrationState === LabServicesModels.RegistrationState.Registered
                    ? UserStatus.Registered
                    : UserStatus.NotRegistered;
            break;
    }
    // Determine Invitation text for filter and list view.
    let inviteStatus;
    switch (invitationState) {
        case LabServicesModels.InvitationState.NotSent:
            inviteStatus = InviteStatus.NotSent;
            canInvite = canSendInvitation;
            break;
        case LabServicesModels.InvitationState.Sending:
            inviteStatus = InviteStatus.Sending;
            break;
        case LabServicesModels.InvitationState.Sent:
            inviteStatus = InviteStatus.Sent;
            canInviteAgain = canSendInvitation;
            break;
        case LabServicesModels.InvitationState.Failed:
            inviteStatus = InviteStatus.SendingFailed;
            canInvite = canSendInvitation;
            break;
        default:
            inviteStatus = InviteStatus.Unknown;
            break;
    }
    const currentUsage = !!totalUsage ? moment.duration(totalUsage).asHours() : 0;
    const additionalQuota = !!additionalUsageQuota ? moment.duration(additionalUsageQuota).asHours() : 0;
    const totalQuota = labQuotaInHours + additionalQuota;
    return {
        key: id || '',
        id: id || '',
        name,
        email: email || '',
        nameSortValue,
        status,
        inviteStatus,
        currentUsage,
        totalQuota,
        additionalQuota,
        canDelete,
        canInvite,
        canInviteAgain,
        canAddQuota,
        latestRegistrationLinkEmailSent: invitationSent,
    };
}
const convertUserDetails = memoize((users, labQuotaInHours, isVNext) => {
    return !isVNext
        ? users.map((user) => getUserDetails(user, labQuotaInHours))
        : users.map((user) => getVNextUserDetails(user, labQuotaInHours));
});
const getRegistrationLink = memoize((lab) => {
    const isVNext = isVNextLab(lab.id);
    const invitationCode = isVNext ? lab.securityProfile?.registrationCode : lab.invitationCode;
    const location = lab.location.toLowerCase();
    const isDev = lab.id.toLowerCase().indexOf(Constants.DevSubscriptionId) >= 0 &&
        (!isVNext
            ? location === Constants.Regions.SoutheastAsia
            : location === Constants.Regions.WestUs2 || location === Constants.Regions.EastUs2);
    if (location === Constants.Regions.CentralUsEuap || location === Constants.Regions.EastUs2Euap) {
        return `https://${hostNames.INSIDERS}/register/${invitationCode}`;
    }
    else if (isDev) {
        return `https://${hostNames.DEV}/register/${invitationCode}`;
    }
    else {
        return `https://${hostNames.PROD}/register/${invitationCode}`;
    }
});
export const getUserListViewModel = memoize((state) => {
    const isVNext = !isCurrentLabParentLabAccount(state);
    const currentLab = getCurrentLab(state);
    const isReadOnly = isCurrentLabReadOnly(state);
    const isLoadingGroups = isLoadingGroupList(state);
    const aadGroupName = getGroupName(state);
    const groups = getGroups(state);
    const isGroupSyncModeEnabled = isLabGroupSynced(currentLab);
    let labQuota = getLabUsageQuota(currentLab);
    labQuota = labQuota ?? Constants.MaxLabQuota;
    const isRestricted = isLabUserRestricted(currentLab);
    const registrationLink = !!currentLab ? getRegistrationLink(currentLab) : '';
    const isSyncing = isCurrentLabUserSyncing(state);
    const isTeamsOrLmsIntegrationEnabled = isTeamsOrLmsMode(state);
    const ianaTimezone = getIanaTimezone(state);
    const locale = getLocale(state);
    if (!isVNext) {
        const labStore = state.get('labStore');
        const userStore = state.get('userStore');
        const lab = currentLab;
        const labUpdateError = labStore.get('updateError');
        const isUpdatingLab = labStore.get('isUpdating');
        const isUpdatingUsers = userStore.get('isUpdating');
        const isLabTeamsOrLmsConnected = isLabConnectedLmsOrTeams(lab);
        const isSyncing = isCurrentLabUserSyncing(state);
        const aadGroupId = lab?.aadGroupId;
        const lastGroupSyncTime = lab?.lastGroupSyncTime;
        const userData = userStore.get('users');
        const addError = userStore.get('addError');
        const deleteErrors = userStore.get('deleteErrors');
        const inviteError = userStore.get('inviteError');
        const updateErrors = userStore.get('updateErrors');
        const isAdding = userStore.get('isAdding');
        const isDeleting = userStore.get('isDeleting');
        const isInviting = userStore.get('isInviting');
        const users = convertUserDetails(userData, labQuota, false);
        const labStoreLoadState = labStore.get('loadState');
        const userStoreLoadState = userStore.get('loadState');
        const isLoading = storeIsLoading(labStoreLoadState) || storeIsLoading(userStoreLoadState) || isLoadingGroupName(state);
        const hasLoadError = storeHasLoadError(labStoreLoadState) || storeHasLoadError(userStoreLoadState);
        return {
            users,
            lab,
            labQuota,
            registrationLink,
            isRestricted,
            isReadOnly,
            isGroupSyncModeEnabled,
            isLabTeamsOrLmsConnected,
            isSyncing,
            groupId: aadGroupId,
            lastGroupSyncTime,
            groupName: aadGroupName,
            groups,
            isLoadingGroups,
            isAdding,
            isDeleting,
            isInviting,
            isUpdatingLab,
            isUpdatingUsers,
            labUpdateError,
            hasAddErrors: !!addError,
            hasDeleteErrors: deleteErrors.size > 0,
            hasInviteErrors: !!inviteError,
            hasUpdateErrors: updateErrors.size > 0,
            isTeamsOrLmsIntegrationEnabled,
            isLoading,
            hasLoadError,
            locale,
            ianaTimezone,
        };
    }
    else {
        const labStore = state.get('vNextLabStore');
        const userStore = state.get('vNextUserStore');
        const lab = currentLab;
        const labUpdateError = getCurrentLabUpdateError(state);
        const isUpdatingLab = labStore.get('isUpdating');
        const isUpdatingUsers = userStore.get('isUpdating');
        const groupId = getVNextLabGroupId(lab);
        const lastGroupSyncTime = !!lab?.lastGroupSyncTime ? new Date(lab.lastGroupSyncTime) : undefined;
        const userData = userStore.get('users');
        const addErrors = userStore.get('addErrors');
        const deleteErrors = userStore.get('deleteErrors');
        const inviteErrors = userStore.get('inviteErrors');
        const updateErrors = userStore.get('updateErrors');
        const isAdding = userStore.get('isAdding');
        const isDeleting = userStore.get('isDeleting');
        const isInviting = userStore.get('isInviting');
        const users = convertUserDetails(userData, labQuota, true);
        const labStoreLoadState = labStore.get('loadState');
        const userStoreLoadState = userStore.get('loadState');
        const isLoading = storeIsLoading(labStoreLoadState) ||
            storeIsLoading(userStoreLoadState) ||
            isLoadingGroupName(state) ||
            isLoadingLabMetadata(state);
        const hasLoadError = storeHasLoadError(labStoreLoadState) || storeHasLoadError(userStoreLoadState);
        const shouldDisableLabUpdate = shouldDisableCurrentLabUpdate(state);
        const isLabTeamsOrLmsConnected = isLabConnectedLmsOrTeams(lab);
        const isLabLmsConnected = isVNextLabLmsConnected(lab);
        const groupName = getVNextLabGroupName(state);
        return {
            users,
            lab,
            labQuota,
            registrationLink,
            isRestricted,
            isReadOnly,
            isGroupSyncModeEnabled,
            isLabTeamsOrLmsConnected,
            isSyncing,
            groupId,
            lastGroupSyncTime,
            groupName,
            groups,
            isLoadingGroups,
            isAdding,
            isDeleting,
            isInviting,
            isUpdatingLab,
            isUpdatingUsers,
            labUpdateError,
            hasAddErrors: addErrors.size > 0,
            hasDeleteErrors: deleteErrors.size > 0,
            hasInviteErrors: inviteErrors.size > 0,
            hasUpdateErrors: updateErrors.size > 0,
            isTeamsOrLmsIntegrationEnabled,
            isLoading,
            hasLoadError,
            locale,
            ianaTimezone,
            shouldDisableLabUpdate,
            isLabLmsConnected,
        };
    }
});
export const getUserListContainerModel = memoize((state) => {
    const search = getSearch(state);
    const userListViewModel = getUserListViewModel(state);
    const isVNext = !isCurrentLabParentLabAccount(state);
    return {
        search,
        userListViewModel,
        isVNext,
    };
});
