import { LabPlanActionType, } from './lab-plan-actions';
export const listLabPlans = (labParentId) => ({
    type: LabPlanActionType.LIST_LAB_PLANS,
    labParentId,
});
export const listLabPlansSuccess = (labPlans) => ({
    type: LabPlanActionType.LIST_LAB_PLANS_SUCCESS,
    labPlans,
});
export const loadSubscriptionLabPlansError = (subscription, error, resourceGroupName) => ({
    type: LabPlanActionType.LOAD_SUBSCRIPTION_LAB_PLANS_ERROR,
    subscription,
    error,
    resourceGroupName,
});
export const selectLabPlan = (labPlanId) => ({
    type: LabPlanActionType.SELECT_LAB_PLAN,
    labPlanId,
});
export const getLabPlan = (labPlanId) => ({
    type: LabPlanActionType.GET_LAB_PLAN,
    labPlanId,
});
export const getLabPlanSuccess = (labPlan) => ({
    type: LabPlanActionType.GET_LAB_PLAN_SUCCESS,
    labPlan,
});
export const getLabPlanError = (error) => ({
    type: LabPlanActionType.GET_LAB_PLAN_ERROR,
    error,
});
export const updateLabPlansLmsSettings = (linkIds, unlinkIds, lmsInstance) => ({
    type: LabPlanActionType.UPDATE_LAB_PLANS_LMS_SETTINGS,
    linkIds,
    unlinkIds,
    lmsInstance,
});
export const updateLabPlansLmsSettingsSuccess = (labPlans) => ({
    type: LabPlanActionType.UPDATE_LAB_PLANS_LMS_SETTINGS_SUCCESS,
    labPlans,
});
export const updateLabPlansLmsSettingsError = (errors) => ({
    type: LabPlanActionType.UPDATE_LAB_PLANS_LMS_SETTINGS_ERROR,
    errors,
});
export const clearUpdateLinkedLmsError = (id) => ({
    type: LabPlanActionType.CLEAR_UPDATE_LMS_ERROR,
    id,
});
const actions = {
    listLabPlans,
    listLabPlansSuccess,
    loadSubscriptionLabPlansError,
    selectLabPlan,
    getLabPlan,
    getLabPlanSuccess,
    getLabPlanError,
    updateLabPlansLmsSettings,
    updateLabPlansLmsSettingsSuccess,
    updateLabPlansLmsSettingsError,
    clearUpdateLinkedLmsError,
};
export default actions;
