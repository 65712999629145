import { CheckboxVisibility, CommandBar, ContextualMenuItemType, DetailsList, DetailsListLayoutMode, DirectionalHint, Icon, IconButton, Link, MarqueeSelection, MessageBar, MessageBarType, ScrollablePane, ScrollbarVisibility, SearchBox, Selection, SelectionMode, Spinner, SpinnerSize, Stack, Sticky, StickyPositionType, Text, TooltipHost, } from '@fluentui/react';
import { createObjectCsvStringifier } from 'csv-writer';
import _ from 'lodash';
import memoize from 'memoize-one';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { getRdpConnectData, getSshCommand, getSshConnectData } from '../common/connect-to-vm/connect-utilities';
import RdpInfoDialog from '../common/connect-to-vm/rdp-info-dialog';
import { ConnectToVmDialog } from '../common/connect-to-vm/ssh-connect-dialog';
import { getNavigationItems } from '../common/lab-nav-items';
import { LoadingContainer } from '../common/loading-section';
import { RequestLimitIncreaseInSentenceLink } from '../common/request-limit-increase-link';
import { getConnectionContextMenuItems } from '../common/selectors/vm-connect';
import { getCustomTheme } from '../common/themes/selectors';
import VmPowerState from '../common/vm/vm-power-state';
import { VmUsageColumn } from '../common/vm/vm-usage-column';
import { ConnectionType, VmAssignmentType, VmState } from '../data/models/environment-common';
import FullPageMessage from '../full-page-message/full-page-message';
import commonMessages from '../language/common-messages';
import { createAndDownloadFile } from '../utils/files';
import LabNavKeys from '../utils/lab-nav-key';
import { objectKeySorter } from '../utils/sorting';
import { trackTrace } from '../utils/telemetry/telemetry-channel';
import CapacityFlyout from './capacity-flyout';
import ResetVmsDialog from './reset-vms-dialog';
import StartVmsDialog from './start-vms-dialog';
import TroubleshootDialog from '../common/troubleshoot-dialog/troubleshoot-dialog';
import { messages as commonVmListMessages } from './vm-list-messages';
import GroupSyncTimeInfo from '../common/group-sync-time-info';
import { getNextGroupSyncTime } from '../common/selectors/group-sync-time-selectors';
import Routes from '../utils/routes';
import LabErrorBanner from '../lab/lab-error-banner';
import { isVNextLab } from '../redux/selectors/lab-selectors';
import templateMessages from '../template/template-messages';
import './vm-list.css';
import { VmAgentError, VmOperationError, VmHeartbeatError } from './vm-operation-error';
import { OperationStatus, OperationType, mapResourceErrorToOperation } from '../data/models/vnext/operation';
import { VmOperationSuccess } from './vm-operation-success';
const messages = defineMessages({
    labCapacityCommandFormat: {
        id: 'VmListLabCapacityCommandFormat',
        defaultMessage: 'Lab capacity: {numberOfMachines} machines',
        description: 'Name of command on virtual machines page command bar to open lab capacity flyout. {numberOfMachines} is an integer - number of machines in the lab.',
    },
    stopAllCommand: {
        id: 'VmListStopAllCommand',
        defaultMessage: 'Stop all',
        description: 'Name of command on virtual machines page command bar to stop all virtual machines in the lab.',
    },
    startAllCommand: {
        id: 'VmListStartAllCommand',
        defaultMessage: 'Start all',
        description: 'Name of the command on the virtual machines page command bar to start all virtual machines in a lab.',
    },
    filterVirtualMachines: {
        id: 'VmListFilterVirtualMachines',
        defaultMessage: 'Filter virtual machines',
        description: 'Tooltip on the filter button above virtual machines list.',
    },
    startCommand: {
        id: 'VmListStartCommand',
        defaultMessage: 'Start',
        description: 'Name of command on virtual machines page command bar to start one or more virtual machines.',
    },
    stopCommand: {
        id: 'VmListStopCommand',
        defaultMessage: 'Stop',
        description: 'Name of command on virtual machines page command bar to stop one or more virtual machines.',
    },
    resetCommand: {
        id: 'VmListResetCommand',
        defaultMessage: 'Reimage',
        description: 'Name of command on virtual machines page command bar to reset one or more virtual machines.',
    },
    connectCommand: {
        id: 'VmListConnectCommand',
        defaultMessage: 'Connect',
        description: 'Name of command on virtual machines page command bar to connect to a virtual machine.',
    },
    stateColumnHeader: {
        id: 'VmListStateColumnHeader',
        defaultMessage: 'State',
        description: 'Header for State column of a table containing user or virtual machine state.',
    },
    osColumnHeader: {
        id: 'VmListOsColumnHeader',
        defaultMessage: 'OS',
        description: 'Header for OS (operating system) column of a table containing user or virtual machine state.',
    },
    lastUsedColumnHeader: {
        id: 'VmListLastUsedColumnHeader',
        defaultMessage: 'Last used',
        description: 'Title for Last Used column of a table containing date/time when a VM was last used.',
    },
    typeFilterMenu: {
        id: 'VmListTypeFilterMenu',
        defaultMessage: 'Type',
        description: 'Label for filter menu to select virtual machine Type (assigned or unassigned).',
    },
    typeFilterMenuSelected: {
        id: 'VmListTypeFilterMenuSelected',
        defaultMessage: 'Type: {selectedTypes}',
        description: 'Label for filter menu to select virtual machine Type (assigned or unassigned) when a filter is selected. {selectedTypes} is a comma separated list of selected types.',
    },
    stateFilterMenuSelected: {
        id: 'VmListStateFilterMenuSelected',
        defaultMessage: 'State: {selectedStates}',
        description: 'Label for filter menu to select virtual machine States (creating, running, stopping, etc.) when a filter is selected. {selectedStates} is a comma separated list of selected states.',
    },
    assignedType: {
        id: 'VmAssignedType',
        defaultMessage: 'Assigned',
        description: 'Text shown for a virtual machine which is assigned to a lab user.',
    },
    unassignedType: {
        id: 'VmUnassignedType',
        defaultMessage: 'Unassigned',
        description: 'Text shown for a virtual machine which is not assigned to a lab user.',
    },
    preparingToConnect: {
        id: 'VmPreparingToConnectAriaLabel',
        defaultMessage: 'Preparing to connect',
        description: 'Aria label for the loading spinner shown when we are requesting the data we need to connect to a VM.',
    },
    privateIpColumnHeader: {
        id: 'VmListPrivateIpColumnHeader',
        defaultMessage: 'Private IP Address',
        description: 'Column header for private IP address of virtual machine.',
    },
    vmListCheckboxAriaLabel: {
        id: 'VMListCheckboxAriaLabel',
        defaultMessage: 'Select a virtual machine',
        description: 'ARIA label for virtual machine list row checkboxes.',
    },
});
// TODO: Re-introduce filtering
class VmListInjected extends React.Component {
    constructor(props) {
        super(props);
        this.onVmsPropsChange = memoize((vms, previousSelectedIds) => {
            const selectedItems = [];
            const selectedIds = [];
            const stopTargets = [];
            const startTargets = [];
            const resetTargets = [];
            const redeployTargets = [];
            vms.forEach((vm) => {
                const { id } = vm;
                if (previousSelectedIds.indexOf(id) > -1) {
                    selectedIds.push(id);
                    selectedItems.push(vm);
                    const { canStop, canStart, canReset, canRedeploy } = vm;
                    if (canStop) {
                        stopTargets.push(id);
                    }
                    if (canStart) {
                        startTargets.push(id);
                    }
                    if (canReset) {
                        resetTargets.push(id);
                    }
                    if (!!canRedeploy) {
                        redeployTargets.push(id);
                    }
                }
            });
            this.setState({
                selectedCount: selectedIds.length,
                selectedIds,
                stopTargets,
                startTargets,
                resetTargets,
                redeployTargets,
            });
            return selectedItems;
        });
        this.onExpandingStateChange = memoize((vms, previousConnectExpandData) => {
            // handles opening the dialogs / downloading the RDP file post expand
            const expandingIds = Object.keys(previousConnectExpandData);
            if (expandingIds.length > 0) {
                const connectExpandData = { ...previousConnectExpandData };
                const finishedExpandingItems = vms.filter((item) => expandingIds.indexOf(item.id) > -1 &&
                    !item.isExpanding &&
                    (!!item.sshAuthority || !!item.rdpAuthority));
                if (finishedExpandingItems.size > 0) {
                    finishedExpandingItems.map((item) => {
                        const connectionType = connectExpandData[item.id];
                        delete connectExpandData[item.id];
                        this._onConnect(item, connectionType);
                    });
                    this.setState({ connectExpandData });
                }
            }
        });
        this.filterAndSortVms = memoize((vms, selectedIds, // used purely for memoization
        filterText, filterStates, filterTypes, sortByColumn, isSortDescending, sorterStateMap) => {
            const filteredItems = vms
                .filter((item) => (!filterText || item.assignedTo.toLowerCase().includes(filterText.toLowerCase())) &&
                (filterStates.length < 1 || filterStates.indexOf(item.vmState) > -1) &&
                (filterTypes.length < 1 || filterTypes.indexOf(item.vmAssignmentType) > -1))
                .toArray();
            const key = sortByColumn;
            const sorter = objectKeySorter(key, isSortDescending, sorterStateMap);
            const sortedItems = filteredItems.sort(sorter);
            return sortedItems;
        });
        // Handles Export to CSV command
        this._onExportClicked = () => {
            const { vmListViewModel, intl } = this.props;
            const { vmDetails: items } = vmListViewModel;
            const msg = intl.formatMessage;
            const vmStateMap = this._buildVmStateMap();
            const writer = createObjectCsvStringifier({
                header: [
                    { id: 'assignedTo', title: msg(commonMessages.nameColumnHeader) },
                    { id: 'vmState', title: msg(messages.stateColumnHeader) },
                    { id: 'currentUsage', title: msg(commonMessages.usageColumnHeader) },
                    { id: 'privateIpAddress', title: msg(messages.privateIpColumnHeader) },
                ],
            });
            const records = items
                .map((o) => ({
                assignedTo: o.vmAssignmentType === VmAssignmentType.Unassigned ? msg(messages.unassignedType) : o.assignedTo,
                vmState: vmStateMap[o.vmState],
                currentUsage: o.currentUsage,
                privateIpAddress: o.privateIpAddress,
            }))
                .toArray();
            const csv = writer.getHeaderString() + writer.stringifyRecords(records);
            createAndDownloadFile(`${msg(commonVmListMessages.pageTitle)}.csv`, csv, true);
        };
        this._buildVmAssignedTypeMap = this._buildVmAssignedTypeMap.bind(this);
        this._buildVmStateMap = this._buildVmStateMap.bind(this);
        this._getFilterText = this._getFilterText.bind(this);
        this._createColumns = this._createColumns.bind(this);
        this._isStopAllEnabled = this._isStopAllEnabled.bind(this);
        this._onCapacityFlyoutSubmit = this._onCapacityFlyoutSubmit.bind(this);
        this._onColumnClick = this._onColumnClick.bind(this);
        this._onRenderNameColumn = this._onRenderNameColumn.bind(this);
        this._onRenderStateColumn = this._onRenderStateColumn.bind(this);
        this._onResetDialogSubmit = this._onResetDialogSubmit.bind(this);
        this._onStartDialogSubmit = this._onStartDialogSubmit.bind(this);
        this._onStopAllClicked = this._onStopAllClicked.bind(this);
        this._onStopClicked = this._onStopClicked.bind(this);
        this._renderFilterBar = this._renderFilterBar.bind(this);
        this._renderDefaultCommandBar = this._renderDefaultCommandBar.bind(this);
        this._renderListHeader = this._renderListHeader.bind(this);
        this._renderSelectionCommandBar = this._renderSelectionCommandBar.bind(this);
        this._onFilterTextChange = this._onFilterTextChange.bind(this);
        this._onFilterTypeChange = this._onFilterTypeChange.bind(this);
        this._onFilterStateChange = this._onFilterStateChange.bind(this);
        this._onClearTypeFilter = this._onClearTypeFilter.bind(this);
        this._onClearStateFilter = this._onClearStateFilter.bind(this);
        this._onFilterClose = this._onFilterClose.bind(this);
        this._onFilterOpen = this._onFilterOpen.bind(this);
        this._isStartAllEnabled = this._isStartAllEnabled.bind(this);
        this._onDismissConnectDialog = this._onDismissConnectDialog.bind(this);
        this._onDismissedConnectDialog = this._onDismissedConnectDialog.bind(this);
        this._onConnect = this._onConnect.bind(this);
        this._onConnectSsh = this._onConnectSsh.bind(this);
        this._onConnectRdp = this._onConnectRdp.bind(this);
        this._onConnectSshInBrowser = this._onConnectSshInBrowser.bind(this);
        this._onConnectRdpInBrowser = this._onConnectRdpInBrowser.bind(this);
        this._onRenderRow = this._onRenderRow.bind(this);
        this._onRedeployClicked = this._onRedeployClicked.bind(this);
        this._selection = new Selection({
            onSelectionChanged: () => {
                const { vmDetails: items } = this.props.vmListViewModel;
                const selectedIds = this._selection.getSelection().map((item) => item.key);
                this.onVmsPropsChange(items, selectedIds);
            },
        });
        this.state = {
            showCapacityFlyout: false,
            showStartDialog: false,
            showResetDialog: false,
            showSshDialog: false,
            sshCommand: undefined,
            showRdpInfoDialog: false,
            showTroubleshootDialog: false,
            rdpInfo: undefined,
            showFilter: false,
            sortByColumn: 'assignedTo',
            wasStartAllInvoked: false,
            isSortDescending: false,
            selectedCount: 0,
            selectedIds: [],
            stopTargets: [],
            startTargets: [],
            resetTargets: [],
            redeployTargets: [],
            filterText: '',
            filterTypes: [],
            filterStates: [],
            connectExpandData: {},
            showInfoBanner: isVNextLab(this.props.vmListViewModel.lab?.id) &&
                this.props.vmListViewModel.isVmActionsEnabled &&
                this.props.vmListViewModel.isReadOnly
                ? true
                : false,
            operations: this.props.vmListViewModel.operations,
        };
    }
    // This method is deprecated, but it is the
    // most efficient way to reset our selections and without it we can't
    // trigger the ssh dialog / rdp file download so we are using
    // it for now. If this method is removed in a subsequent React release
    // we'll need to re-evaluate this behavior
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { vmListViewModel } = nextProps;
        const { vmDetails: items } = vmListViewModel;
        const { selectedIds, connectExpandData } = this.state;
        const itemsArray = items.toJS();
        const selectedItems = itemsArray.filter((o) => selectedIds.indexOf(o.id) > -1);
        // clear our selections and then reselect them
        this._selection.setItems(itemsArray, true);
        if (selectedItems.length === items.count()) {
            this._selection.setAllSelected(true);
        }
        else {
            this._selection.setAllSelected(false);
            selectedItems.forEach((item) => {
                this._selection.setKeySelected(item.key, true, false);
            });
        }
        // onVmsPropsChange re-calculates our state and then returns the items that should be selected
        // and is memoized for perf
        // update state after reselection to make sure the state won't be reset when selecting
        this.onVmsPropsChange(items, selectedIds);
        // onExpandingStateChange re-calculates state based on VM expands and is memoized for perf
        this.onExpandingStateChange(items, connectExpandData);
    }
    render() {
        const { vmListViewModel, intl, clearLabUpdateError, clearLabCapacityExceedsCoresError, clearStartError, clearResetError, clearStopError, navigateRoute, clearRedeployError, } = this.props;
        const { showStartDialog, showResetDialog, showCapacityFlyout, showSshDialog, showFilter, sshCommand, showRdpInfoDialog, showTroubleshootDialog, rdpInfo, selectedCount, selectedIds, sortByColumn, isSortDescending, filterText, filterStates, filterTypes, showInfoBanner, } = this.state;
        const { lab, vmDetails: items, isReadOnly, currentTenantId, groupName, isGroupSyncModeEnabled, hasBeenPublished, capacity, maxCapacity, coreQuotaData, coresPerVm, isGpu, isLabUpdating, labUpdateError, labCapacityExceedsCoresError, startErrors, stopErrors, resetErrors, redeployErrors, isTeamsOrLmsIntegrationEnabled, isLoading, hasLoadError, ianaTimezone, locale, coreQuotaStatus, isLabTeamsOrLmsConnected, isVmActionsEnabled, } = vmListViewModel;
        const msg = intl.formatMessage;
        const { primaryBackgroundColor, pageTitleStyle, primaryCommandBarStyles, messageBarStyles } = getCustomTheme();
        const usersRoute = Routes.Users(lab?.id);
        if (hasLoadError) {
            const navigationItems = isTeamsOrLmsIntegrationEnabled && lab && lab.id
                ? getNavigationItems(intl, lab.id, LabNavKeys.Users, navigateRoute)
                : undefined;
            return (<div id="vm-list-container" style={{ backgroundColor: primaryBackgroundColor }}>
                    <div id="vm-list-content">
                        {navigationItems && (<div id="vm-list-header">
                                <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                            </div>)}
                        <FullPageMessage image="error-general" message={<FormattedMessage id="VmListLoadError" defaultMessage="Cannot load lab virtual machines" description="Error shown on VM list page when it fails to load necessary data from the server."/>} messageDetails={<FormattedMessage id="VmListLoadErrorDetails" defaultMessage="Your lab does not appear to be in a state to view or edit VMs at this time. Please try again later or contact your Lab Services administator for assistance." description="Error shown on VM list page when it fails to load necessary data from the server."/>}/>
                    </div>
                </div>);
        }
        if (isLoading) {
            const navigationItems = isTeamsOrLmsIntegrationEnabled && lab && lab.id
                ? getNavigationItems(intl, lab.id, LabNavKeys.Users, navigateRoute)
                : undefined;
            return (<div id="vm-list-container" style={{ backgroundColor: primaryBackgroundColor }}>
                    <div id="vm-list-content">
                        {navigationItems && (<div id="vm-list-header">
                                <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                            </div>)}
                        <LoadingContainer />
                    </div>
                </div>);
        }
        const isVNext = isVNextLab(lab?.id);
        const vmStateMap = this._buildVmStateMap();
        const vmAssignedTypeMap = this._buildVmAssignedTypeMap();
        const minCapacity = items.count((item) => item.vmAssignmentType == VmAssignmentType.Assigned) || (isVNext ? 0 : 1);
        const columns = this._createColumns(sortByColumn, isSortDescending, locale);
        let sorterStateMap = undefined;
        if (sortByColumn === 'vmState') {
            sorterStateMap = vmStateMap;
        }
        const sortedItems = this.filterAndSortVms(items, selectedIds, filterText, filterStates, filterTypes, sortByColumn, isSortDescending, sorterStateMap);
        const detailsList = (<DetailsList data-is-scrollable="true" items={sortedItems} columns={columns} selectionMode={isReadOnly && !isVmActionsEnabled ? SelectionMode.none : SelectionMode.multiple} getKey={(item) => item.id} setKey="set" layoutMode={DetailsListLayoutMode.justified} isHeaderVisible={true} selection={this._selection} onRenderDetailsHeader={this._renderListHeader} onRenderRow={this._onRenderRow} enterModalSelectionOnTouch={true} ariaLabelForSelectionColumn={msg(commonMessages.toggleSelection)} ariaLabelForSelectAllCheckbox={msg(commonMessages.toggleAllSelection)} checkButtonAriaLabel={msg(messages.vmListCheckboxAriaLabel)} checkboxVisibility={CheckboxVisibility.always} compact={false}/>);
        return (<>
                <div id="vm-list-container" style={{ backgroundColor: primaryBackgroundColor }}>
                    <div id="vm-list-content">
                        <div id="vm-list-header">
                            {labCapacityExceedsCoresError && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearLabCapacityExceedsCoresError()} styles={messageBarStyles}>
                                    {msg(commonMessages.coreUsageExceedsErrorBannerMessage, {
            requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labId={lab.id} tenantId={currentTenantId}/>),
        })}
                                </MessageBar>)}
                            {isReadOnly && isVmActionsEnabled && showInfoBanner && (<MessageBar messageBarType={MessageBarType.info} styles={messageBarStyles} onDismiss={() => this.setState({ showInfoBanner: false })} dismissButtonAriaLabel={msg(commonMessages.close)}>
                                    <FormattedMessage id="LabAssistantRoleInfoMessage" defaultMessage="Your role enables you to start, stop, connect, and reset virtual machines in this lab, but not change lab capacity." description="Message shown on the Virtual machine pool page when the current user is a Lab Assistant"/>
                                </MessageBar>)}
                            <LabErrorBanner updateError={labUpdateError} clearUpdateError={clearLabUpdateError}/>
                            {startErrors.size > 0 && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearStartError()}>
                                    <FormattedMessage id="VmListStartError" defaultMessage="An error occurred while starting your VM(s)." description="Message shown when an error occurs while starting VMs."/>
                                </MessageBar>)}
                            {stopErrors.size > 0 && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearStopError()}>
                                    <FormattedMessage id="VmListStopError" defaultMessage="An error occurred while stopping your VM(s)." description="Message shown when an error occurs while stopping VMs."/>
                                </MessageBar>)}
                            {resetErrors.size > 0 && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearResetError()}>
                                    <FormattedMessage id="VmListResetError" defaultMessage="An error occurred while resetting your VM(s)." description="Message shown when an error occurs while resetting VMs."/>
                                </MessageBar>)}
                            {!!redeployErrors && redeployErrors.size > 0 && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearRedeployError()}>
                                    <FormattedMessage id="VmListRedeployError" defaultMessage="An error occurred while redeploying your VM(s)." description="Message shown when an error occurs while redeployung VMs."/>
                                </MessageBar>)}
                            {selectedCount > 0
            ? this._renderSelectionCommandBar()
            : this._renderDefaultCommandBar(sortedItems)}
                            {showFilter && this._renderFilterBar(vmStateMap, vmAssignedTypeMap)}
                            {items.size > 0 && (<>
                                    <h1 style={pageTitleStyle}>
                                        <FormattedMessage {...commonVmListMessages.pageTitle}/>
                                    </h1>

                                    <Stack style={{
            marginLeft: '32px',
            marginRight: '8px',
            marginTop: '11px',
            marginBottom: '25px',
        }}>
                                        {isGroupSyncModeEnabled && lab && lab.lastGroupSyncTime && (<Stack.Item>
                                                <GroupSyncTimeInfo groupName={groupName} locale={locale} lab={lab} content={<FormattedMessage id="VmListNextUserGroupSyncTime" defaultMessage="The next automatic sync will occur at {nextGroupSyncTime}. To sync this lab's user list now, go to <Link>Users</Link>." description="Info tip shown about next group sync time" values={{
            nextGroupSyncTime: getNextGroupSyncTime(ianaTimezone, locale),
            Link: (chunks) => (<Link onClick={() => navigateRoute(usersRoute)}>
                                                                        {chunks}
                                                                    </Link>),
        }}/>} tooltipProps={{ maxWidth: '178px' }}/>
                                            </Stack.Item>)}
                                        <Stack.Item>
                                            {!isLabTeamsOrLmsConnected && !isGroupSyncModeEnabled && (<FormattedMessage id="VmPoolUnassignedCleanupMessage" defaultMessage="Each registered user in your lab will automatically get one virtual machine in this pool. Any virtual machine that is not assigned to a user within 30 days will be automatically removed." description="A message displayed on the VM pool page to inform customers about VM retention policies."/>)}
                                            {isGroupSyncModeEnabled && !isLabTeamsOrLmsConnected && (<FormattedMessage id="GroupSyncMdoeVmPoolAssignedMessage" defaultMessage="Each user in your lab will automatically be assigned one virtual machine in this pool." description="Message displayed on the VM pool page to inform customers about assigning virtual machine when in group sync mode."/>)}
                                            {isLabTeamsOrLmsConnected && !isVNext && (<FormattedMessage id="TeamsVmPoolAssignedMessage" defaultMessage="Each user in the lab will automatically be assigned one virtual machine upon first sign-in." description="Message displayed on the VM pool page in Teams to inform customers about assigning virtual machine."/>)}
                                            {isLabTeamsOrLmsConnected && isVNext && (<FormattedMessage id="VNextTeamsOrLmsVmPoolAssignedMessage" defaultMessage="When new users are detected in the synced group, they will be automatically registered to the lab and assigned one virtual machine each." description="Message displayed on the VM pool page in Teams or Lms to inform customers about assigning virtual machine in vNext."/>)}
                                        </Stack.Item>
                                    </Stack>
                                </>)}
                        </div>
                        {items.size == 0 ? (<Stack id="vm-list-body" horizontalAlign="center" styles={{ root: { marginTop: '52px' } }} verticalFill>
                                <Icon iconName="empty-vm-list" styles={{ root: { marginBottom: 20 } }}/>
                                <b>
                                    <FormattedMessage id="VmListNoVmsMessage" defaultMessage="No virtual machines have been created." description="Message shown on the VMs page when no VMs have yet been created."/>
                                </b>
                                <div style={{ maxWidth: 610, marginBottom: 25, textAlign: 'center' }}>
                                    {!isGroupSyncModeEnabled && !isVNext && (<FormattedMessage id="VmListNoVmsDescription" defaultMessage="Publish the lab or update lab capacity using the menu above to create virtual machines." description="Message shown on the VMs page when no VMs have yet been created."/>)}
                                    {!isGroupSyncModeEnabled && isVNext && (<FormattedMessage id="VNextVmListNoVmsDescription" defaultMessage="No virtual machines have been created for this lab. Publish the lab or increase lab capacity to create virtual machines. Machines created within unpublished labs can be used to reserve a portion of the capacity available in your subscription." description="Message shown on the VMs page when no VMs have yet been created."/>)}
                                    {isGroupSyncModeEnabled && (<FormattedMessage id="GroupSyncModeVmListNoVmsDescription" defaultMessage="Publish the lab to create virtual machines." description="Message shown on the VMs page when no VMs have yet been created in group sync mode."/>)}
                                </div>
                            </Stack>) : (<ScrollablePane id="vm-list-body" className="vertical-scrollable-content" scrollbarVisibility={ScrollbarVisibility.auto}>
                                <div id="vm-details-list-content">
                                    {isReadOnly && <>{detailsList}</>}
                                    {!isReadOnly && (<MarqueeSelection selection={this._selection}>
                                            {detailsList}
                                        </MarqueeSelection>)}
                                </div>
                            </ScrollablePane>)}
                    </div>
                </div>
                {showStartDialog && (<StartVmsDialog onSubmit={() => this._onStartDialogSubmit(sortedItems)} onDismiss={() => this.setState({ showStartDialog: false, wasStartAllInvoked: false })}/>)}
                {showResetDialog && (<ResetVmsDialog numberOfMachines={this.state.resetTargets.length} onSubmit={() => this._onResetDialogSubmit()} onDismiss={() => this.setState({ showResetDialog: false })}/>)}
                {showCapacityFlyout && (<CapacityFlyout minCapacity={minCapacity} maxCapacity={maxCapacity} hasBeenPublished={hasBeenPublished} capacity={capacity} isGpu={isGpu} labId={lab.id} currentTenantId={currentTenantId} coresPerVm={coresPerVm} coreQuotaData={coreQuotaData} coreQuotaStatus={coreQuotaStatus} isSubmitting={isLabUpdating} onSubmit={(capacity) => this._onCapacityFlyoutSubmit(capacity)} onDismiss={() => this.setState({ showCapacityFlyout: false })}/>)}
                {showSshDialog && sshCommand && (<ConnectToVmDialog sshCommand={sshCommand} onDismiss={this._onDismissConnectDialog} onDismissed={this._onDismissedConnectDialog}/>)}
                {showRdpInfoDialog && rdpInfo && (<RdpInfoDialog rdpInfo={rdpInfo} onDismiss={() => this.setState({ showRdpInfoDialog: false, rdpInfo: undefined })}/>)}
                {showTroubleshootDialog && (<TroubleshootDialog onDismiss={() => this.setTroubleshootDialog(false)} onRedeploy={() => this._onRedeployClicked()}/>)}
            </>);
    }
    _buildVmStateMap() {
        const msg = this.props.intl.formatMessage;
        const creating = msg(commonMessages.creatingState);
        const starting = msg(commonMessages.startingState);
        const stopping = msg(commonMessages.stoppingState);
        const running = msg(commonMessages.runningState);
        const deleting = msg(commonMessages.deletingState);
        const stopped = msg(commonMessages.stoppedState);
        const failed = msg(commonMessages.failedState);
        const resettingPassword = msg(commonMessages.resettingPasswordState);
        const unknown = msg(commonMessages.unknownState);
        const reimaging = msg(commonMessages.reimagingState);
        const redeploying = msg(commonMessages.redeployingState);
        const map = {};
        map[VmState.Creating] = creating;
        map[VmState.Starting] = starting;
        map[VmState.Running] = running;
        map[VmState.Deleting] = deleting;
        map[VmState.Stopping] = stopping;
        map[VmState.Stopped] = stopped;
        map[VmState.Failed] = failed;
        map[VmState.ResettingPassword] = resettingPassword;
        map[VmState.Unknown] = unknown;
        map[VmState.Reimaging] = reimaging;
        map[VmState.Redeploying] = redeploying;
        return map;
    }
    _buildVmAssignedTypeMap() {
        const msg = this.props.intl.formatMessage;
        const assigned = msg(messages.assignedType);
        const unassigned = msg(messages.unassignedType);
        const unknown = msg(commonMessages.unknownState);
        const map = {};
        map[VmAssignmentType.Assigned] = assigned;
        map[VmAssignmentType.Unassigned] = unassigned;
        map[VmAssignmentType.Unknown] = unknown;
        return map;
    }
    _getFilterText(items, stateMap) {
        if (items.length < 1) {
            return '';
        }
        const filterStrings = items.map((item) => stateMap[item]).sort();
        return filterStrings.join(', ');
    }
    _onRenderRow(props, defaultRender) {
        const { detailsRowStyles } = getCustomTheme();
        return (<div className="vm-list__row">
                {defaultRender({
            ...props,
            // Define row style in DetailsList does not work, so applying style when rendering row.
            styles: detailsRowStyles,
        })}
            </div>);
    }
    getVmStateForMailto(item) {
        const result = [];
        const lineBreak = '%0D%0A';
        if (item != null) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].vmState == 'starting' || item[i].vmState == 'stopping') {
                    let tup = '';
                    tup += 'Assigned To:  ' + item[i].assignedTo + lineBreak;
                    tup += 'Id:  ' + item[i].id + lineBreak;
                    tup += 'State: ' + item[i].vmState + lineBreak;
                    tup += lineBreak + '';
                    result.push(tup);
                }
            }
        }
        return result;
    }
    _renderFilterBar(vmStateMap, vmAssignedTypeMap) {
        const { intl, vmListViewModel } = this.props;
        const { vmDetails: items } = vmListViewModel;
        const { filterText, filterStates, filterTypes } = this.state;
        const { secondaryCommandBarStyles, filterBarStyles } = getCustomTheme();
        const msg = intl.formatMessage;
        const availableTypes = items
            .map((item) => item.vmAssignmentType)
            .filter((value, index, self) => self.indexOf(value) === index);
        const availableStates = items
            .map((item) => item.vmState)
            .filter((value, index, self) => self.indexOf(value) === index);
        const availableTypeOptions = availableTypes
            .map((type) => ({
            key: type,
            text: vmAssignedTypeMap[type],
            canCheck: true,
            checked: filterTypes.indexOf(type) > -1,
            className: 'vm-list__filter-button',
            onClick: (ev) => {
                ev && ev.preventDefault();
                this._onFilterTypeChange(type);
            },
        }))
            .sortBy((option) => option.text)
            .toArray();
        const availableStateOptions = availableStates
            .map((state) => ({
            key: state,
            text: vmStateMap[state],
            canCheck: true,
            checked: filterStates.indexOf(state) > -1,
            className: 'vm-list__filter-button',
            onClick: (ev) => {
                ev && ev.preventDefault();
                this._onFilterStateChange(state);
            },
        }))
            .sortBy((option) => option.text)
            .toArray();
        if (availableTypeOptions.length > 0) {
            availableTypeOptions.push({
                key: 'clearDivider',
                itemType: ContextualMenuItemType.Divider,
            });
            availableTypeOptions.push({
                key: 'clear',
                text: msg(commonMessages.clearFilters),
                canCheck: false,
                checked: false,
                className: 'vm-list__filter-clear-button',
                iconProps: {
                    iconName: 'Cancel',
                    styles: {
                        root: {
                            color: 'rgb(50, 49, 48)',
                            fontSize: '14px',
                        },
                    },
                },
                onClick: (ev) => {
                    ev && ev.preventDefault();
                    this._onClearTypeFilter();
                },
            });
        }
        if (availableStateOptions.length > 0) {
            availableStateOptions.push({
                key: 'clearDivider',
                itemType: ContextualMenuItemType.Divider,
            });
            availableStateOptions.push({
                key: 'clear',
                text: msg(commonMessages.clearFilters),
                canCheck: false,
                checked: false,
                className: 'vm-list__filter-clear-button',
                iconProps: {
                    iconName: 'Cancel',
                    styles: {
                        root: {
                            color: 'rgb(50, 49, 48)',
                            fontSize: '14px',
                        },
                    },
                },
                onClick: (ev) => {
                    ev && ev.preventDefault();
                    this._onClearStateFilter();
                },
            });
        }
        return (<CommandBar styles={_.merge({}, secondaryCommandBarStyles, filterBarStyles)} items={[
            {
                key: 'filterByName',
                onRender: () => (<SearchBox ariaLabel={msg(commonMessages.filterByName)} styles={{
                    root: {
                        backgroundColor: 'transparent',
                        border: 'none',
                        marginTop: 8,
                        width: '300px',
                    },
                }} value={filterText} spellCheck={false} placeholder={msg(commonMessages.filterByName)} iconProps={{ iconName: 'Filter' }} onChange={(_, newValue) => this._onFilterTextChange(newValue || '')}/>),
            },
        ]} farItems={[
            {
                key: 'filterByType',
                text: filterTypes.length > 0
                    ? msg(messages.typeFilterMenuSelected, {
                        selectedTypes: this._getFilterText(filterTypes, vmAssignedTypeMap),
                    })
                    : msg(messages.typeFilterMenu),
                subMenuProps: {
                    items: availableTypeOptions,
                },
            },
            {
                key: 'filterByState',
                text: filterStates.length > 0
                    ? msg(messages.stateFilterMenuSelected, {
                        selectedStates: this._getFilterText(filterStates, vmStateMap),
                    })
                    : msg(messages.stateColumnHeader),
                subMenuProps: {
                    items: availableStateOptions,
                },
            },
            {
                key: 'closeFilter',
                text: msg(commonMessages.closeFilterCommand),
                ariaLabel: msg(commonMessages.closeFilterCommand),
                iconProps: { iconName: 'Cancel' },
                iconOnly: true,
                onClick: () => this._onFilterClose(),
            },
        ]}/>);
    }
    _renderListHeader(props, defaultRender) {
        const { detailsHeaderStyles } = getCustomTheme();
        return (<Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender({
            ...props,
            onRenderColumnHeaderTooltip: (tooltipHostProps) => (<TooltipHost {...tooltipHostProps} styles={detailsHeaderStyles}/>),
            styles: detailsHeaderStyles,
        })}
            </Sticky>);
    }
    // Renders default command bar shown when there is no selection
    _renderDefaultCommandBar(vms) {
        const { intl, vmListViewModel, navigateRoute } = this.props;
        const { capacity, isReadOnly, isGroupSyncModeEnabled, lab, isTeamsOrLmsIntegrationEnabled, shouldDisableLabUpdate, isVmActionsEnabled, } = vmListViewModel;
        const { showFilter } = this.state;
        const { formatMessage: msg } = intl;
        const { primaryCommandBarStyles } = getCustomTheme();
        const items = [];
        if (!isGroupSyncModeEnabled) {
            items.push({
                key: 'labCapacity',
                disabled: isReadOnly || !!shouldDisableLabUpdate,
                text: msg(messages.labCapacityCommandFormat, {
                    numberOfMachines: capacity,
                }),
                iconProps: { iconName: 'TVMonitor' },
                onClick: () => this.setState({ showCapacityFlyout: true }),
            });
        }
        if ((!isReadOnly || isVmActionsEnabled) && vms.length > 0) {
            if (this._isStartAllEnabled(vms)) {
                items.push({
                    key: 'startAll',
                    text: msg(messages.startAllCommand),
                    iconProps: { iconName: 'TriangleRight12' },
                    onClick: () => this.setState({ wasStartAllInvoked: true, showStartDialog: true }),
                });
            }
            if (this._isStopAllEnabled(vms)) {
                items.push({
                    key: 'stopAll',
                    text: msg(messages.stopAllCommand),
                    iconProps: { iconName: 'Stop' },
                    onClick: () => this._onStopAllClicked(vms),
                });
            }
        }
        const overflowItems = [];
        let farItems = [];
        const exportItem = {
            key: 'export',
            text: msg(commonMessages.exportToCsv),
            iconProps: { iconName: 'Export' },
            onClick: this._onExportClicked,
        };
        const filterItem = {
            key: 'filter',
            ariaLabel: msg(messages.filterVirtualMachines),
            text: msg(messages.filterVirtualMachines),
            iconProps: { iconName: 'Filter' },
            iconOnly: true,
            'aria-expanded': showFilter ? true : false,
            onClick: () => this._onFilterOpen(),
        };
        if (isReadOnly) {
            items.push(exportItem);
        }
        else {
            overflowItems.push(exportItem);
        }
        const overflowButtonProps = { title: msg(commonMessages.moreActionsMenu) };
        if (isTeamsOrLmsIntegrationEnabled) {
            items.push(filterItem);
            if (isTeamsOrLmsIntegrationEnabled && lab && lab.id) {
                farItems = getNavigationItems(intl, lab.id, LabNavKeys.VirualMachine, navigateRoute);
            }
        }
        else {
            farItems.push(filterItem);
        }
        return (<CommandBar styles={primaryCommandBarStyles} items={items} overflowItems={overflowItems} overflowButtonProps={overflowButtonProps} farItems={farItems}/>);
    }
    // Renders command bar shown when there is non=empty selection
    _renderSelectionCommandBar() {
        const { vmListViewModel, intl } = this.props;
        const { isReadOnly, isVmActionsEnabled } = vmListViewModel;
        const { formatMessage: msg } = intl;
        const { startTargets, stopTargets, resetTargets, redeployTargets } = this.state;
        const { secondaryCommandBarStyles } = getCustomTheme();
        const items = [];
        if (!isReadOnly || isVmActionsEnabled) {
            if (startTargets.length > 0) {
                items.push({
                    key: 'start',
                    text: msg(messages.startCommand),
                    iconProps: { iconName: 'TriangleRight12' },
                    onClick: () => this.setState({ showStartDialog: true }),
                });
            }
            if (stopTargets.length > 0) {
                items.push({
                    key: 'stop',
                    text: msg(messages.stopCommand),
                    iconProps: { iconName: 'Stop' },
                    onClick: () => this._onStopClicked(),
                });
            }
            if (resetTargets.length > 0) {
                items.push({
                    key: 'reset',
                    text: msg(messages.resetCommand),
                    iconProps: { iconName: 'Refresh' },
                    onClick: () => this.setState({ showResetDialog: true }),
                });
            }
            if (redeployTargets.length > 0) {
                items.push({
                    key: 'troubleshoot',
                    text: msg(commonMessages.troubleshoot),
                    iconProps: { iconName: 'Repair' },
                    onClick: () => this.setTroubleshootDialog(true),
                });
            }
        }
        return (<CommandBar styles={secondaryCommandBarStyles} items={items} farItems={[
            {
                key: 'clearSelection',
                text: msg(commonMessages.clearSelectionCommand),
                ariaLabel: msg(commonMessages.clearSelectionCommand),
                iconProps: { iconName: 'Cancel' },
                iconOnly: true,
                onClick: () => this._selection.setAllSelected(false),
            },
            {
                key: 'selectedCount',
                onRender: () => (<Stack verticalFill verticalAlign="center">
                                <Stack.Item>
                                    {msg(commonMessages.selectedFormat, {
                    numberOfObjects: this.state.selectedCount,
                })}
                                </Stack.Item>
                            </Stack>),
            },
        ]}/>);
    }
    // Column definitions for the details list view control
    _createColumns(sortByColumn, isSortDescending, locale) {
        const { intl } = this.props;
        const msg = intl.formatMessage;
        const nameColumnLabel = msg(commonMessages.nameColumnHeader);
        const stateColumnLabel = msg(messages.stateColumnHeader);
        const usageColumnLabel = msg(commonMessages.usageColumnHeader);
        const privateIPColumnLabel = msg(messages.privateIpColumnHeader);
        const result = [
            {
                key: 'assignedTo',
                name: nameColumnLabel,
                minWidth: 210,
                maxWidth: 400,
                isRowHeader: true,
                isResizable: true,
                isSorted: sortByColumn === 'assignedTo',
                isSortedDescending: sortByColumn === 'assignedTo' ? isSortDescending : false,
                sortAscendingAriaLabel: msg(commonMessages.sortedAtoZ),
                sortDescendingAriaLabel: msg(commonMessages.sortedZtoA),
                onColumnClick: (_, column) => this._onColumnClick(column),
                onRender: (item) => this._onRenderNameColumn(item),
                className: 'vm-list__name-column',
            },
            {
                key: 'vmState',
                name: stateColumnLabel,
                minWidth: 100,
                maxWidth: 150,
                isResizable: true,
                isCollapsable: true,
                isSorted: sortByColumn === 'vmState',
                isSortedDescending: sortByColumn === 'vmState' ? isSortDescending : false,
                sortAscendingAriaLabel: msg(commonMessages.sortedAtoZ),
                sortDescendingAriaLabel: msg(commonMessages.sortedZtoA),
                onColumnClick: (_, column) => this._onColumnClick(column),
                onRender: (item) => this._onRenderStateColumn(item),
                className: 'vm-list__state-column',
            },
            {
                key: 'currentUsage',
                name: usageColumnLabel,
                minWidth: 130,
                maxWidth: 130,
                isResizable: true,
                isCollapsible: true,
                isSorted: sortByColumn === 'currentUsage',
                isSortedDescending: sortByColumn === 'currentUsage' ? isSortDescending : false,
                sortAscendingAriaLabel: msg(commonMessages.sortedSmallerToLarger),
                sortDescendingAriaLabel: msg(commonMessages.sortedLargerToSmaller),
                onColumnClick: (_, column) => this._onColumnClick(column),
                onRender: (item) => (<Stack horizontal verticalAlign="center">
                        <Stack.Item align="start" styles={{ root: { paddingRight: '10px' } }}>
                            <VmUsageColumn locale={locale} currentUsage={item.currentUsage} 
                // setting quota to 0 so it doesn't show the quota
                usageQuota={0}/>
                        </Stack.Item>
                    </Stack>),
            },
            {
                key: 'privateIpAddress',
                name: privateIPColumnLabel,
                fieldName: 'privateIpAddress',
                minWidth: 100,
                maxWidth: 100,
                isResizable: true,
                isCollapsible: true,
                isSorted: sortByColumn === 'privateIpAddress',
                isSortedDescending: sortByColumn === 'privateIpAddress' ? isSortDescending : false,
                sortAscendingAriaLabel: msg(commonMessages.sortedSmallerToLarger),
                sortDescendingAriaLabel: msg(commonMessages.sortedLargerToSmaller),
                className: 'ms-fontSize-14 vm-list__user-select',
                onColumnClick: (_, column) => this._onColumnClick(column),
            },
        ];
        return result;
    }
    // Handles changes to column sort order for the list view
    _onColumnClick(column) {
        const sortByColumn = column.key || 'assignedTo';
        const isNewColumn = this.state.sortByColumn !== sortByColumn;
        const isSortDescending = isNewColumn ? false : !this.state.isSortDescending;
        this.setState({ sortByColumn, isSortDescending });
    }
    // Renders Name cell of list view row including icon, name and command bar
    _onRenderNameColumn(item) {
        const { vmListViewModel, intl } = this.props;
        const { useSharedPassword, isReadOnly, isTeamsOrLmsIntegrationEnabled, isBastionEnabled, isLabDraft, isVmActionsEnabled, operations, currentTenantId, vmParentId, } = vmListViewModel;
        const vmParentIdParts = vmParentId?.split('/');
        let subscriptionId = '';
        if (vmParentIdParts?.length > 2) {
            subscriptionId = vmParentIdParts[2];
        }
        const { selectedIds, selectedCount, connectExpandData } = this.state;
        const msg = intl.formatMessage;
        const selected = selectedIds.indexOf(item.id) > -1;
        const vmOperation = operations?.find((o) => o.ResourceId?.id == item.id) || mapResourceErrorToOperation(vmListViewModel.vmDetails.find((vm) => vm.id == item.id)?.resourceOperationError);
        const vmOperationStatus = vmOperation?.status;
        const vmOperationType = vmOperation?.operationType || OperationType.Unknown;
        const isUnassigned = item.vmAssignmentType === VmAssignmentType.Unassigned;
        const assignedTo = isUnassigned ? msg(messages.unassignedType) : item.assignedTo;
        const isSingleSelect = selectedCount < 1 || (selected && selectedCount === 1);
        const canConnect = useSharedPassword && item.canConnect && isSingleSelect;
        const canShowRdpInfo = item.rdpAuthority && item.rdpAuthority.length > 0;
        const canShowRedeployButton = item.canRedeploy;
        const styles = {
            root: {
                backgroundColor: 'inherit',
                borderStyle: 'none',
                height: '100%',
            },
        };
        const connectItemProps = {
            ariaLabel: msg(messages.connectCommand),
            styles,
            iconProps: {
                iconName: 'ConnectVirtualMachine',
                styles: { root: { color: '#605E5C' } },
            },
            menuIconProps: {
                // this is used to hide the chevron
                style: { display: 'none' },
            },
            className: canConnect
                ? 'vm-list__action-selected'
                : 'vm-list__action-selected-hidden',
        };
        const isRdpConnectionInTeams = isTeamsOrLmsIntegrationEnabled && item.connectionTypes.indexOf(ConnectionType.Rdp) > -1;
        const connectionTypes = item.connectionTypes.filter((type) => isBastionEnabled || (type !== ConnectionType.RdpInBrowser && type !== ConnectionType.SshInBrowser));
        if (!!connectExpandData[item.id] || item.isExpanding) {
            connectItemProps.iconProps = undefined;
            connectItemProps.onRenderIcon = () => (<Spinner ariaLabel={msg(messages.preparingToConnect)} size={SpinnerSize.small} styles={{ root: { paddingBottom: '3px', paddingRight: '10px' } }}/>);
        }
        else if (connectionTypes.length > 1 || isRdpConnectionInTeams) {
            const items = getConnectionContextMenuItems(item.canConnect, item, connectionTypes, this._onConnect, intl, isTeamsOrLmsIntegrationEnabled);
            connectItemProps.menuProps = { items, isSubMenu: true };
        }
        else if (connectionTypes.length === 1) {
            connectItemProps.onClick = () => this._onConnect(item, connectionTypes[0]);
        }
        const resetButtonProps = {
            ariaLabel: msg(messages.resetCommand),
            styles,
            iconProps: {
                iconName: 'Refresh',
                styles: { root: { color: '#605E5C' } },
            },
            className: item.canReset ? 'vm-list__action-selected' : 'vm-list__action-selected-hidden',
            onClick: () => this.setState({ showResetDialog: true }),
        };
        let rdpInfoProps;
        if (canShowRdpInfo) {
            rdpInfoProps = {
                ariaLabel: msg(commonMessages.showRdpInfoTitle),
                styles,
                iconProps: {
                    iconName: 'ClipboardList',
                    styles: { root: { color: '#605E5C' } },
                },
                className: 'vm-list__action-selected',
                onClick: () => this.setState({ showRdpInfoDialog: true, rdpInfo: item.rdpAuthority }),
            };
        }
        let redeployButtonProps;
        if (canShowRedeployButton) {
            redeployButtonProps = {
                ariaLabel: msg(commonMessages.troubleshoot),
                styles,
                iconProps: {
                    iconName: 'Repair',
                    styles: { root: { color: '#605E5C' } },
                },
                className: item.canRedeploy ? 'vm-list__action-selected' : 'vm-list__action-selected-hidden',
                onClick: () => this.setState({ showTroubleshootDialog: true }),
            };
        }
        return (<Stack className="vm-list__name-stack" horizontal horizontalAlign="start" verticalAlign="center" grow>
                {vmOperationStatus && vmOperationStatus == OperationStatus.Failed && item.errorCode !== "HeartbeatNotReceived" && (<Stack styles={{ root: { paddingTop: '4px' } }}>
                        {item.errorCode != "AgentFailure" &&
            (<VmOperationError tenantId={currentTenantId} subscriptionId={subscriptionId} operationType={vmOperationType}/>)}
                    </Stack>)}
                {vmOperationStatus && vmOperationStatus == OperationStatus.Succeeded && item.errorCode !== "HeartbeatNotReceived" && (<Stack styles={{ root: { paddingTop: '4px' } }}>
                        <VmOperationSuccess operationType={vmOperationType}/>
                    </Stack>)}
                {item.errorCode == "AgentFailure" && (<Stack styles={{ root: { paddingTop: '4px' } }}>
                        <VmAgentError tenantId={currentTenantId} subscriptionId={subscriptionId}/>
                    </Stack>)}
                {item.errorCode == "HeartbeatNotReceived" && (<Stack styles={{ root: { paddingTop: '4px' } }}>
                        <VmHeartbeatError tenantId={currentTenantId} subscriptionId={subscriptionId}/>
                    </Stack>)}
                <Stack.Item className="vm-list__name-text-stack-item" align="start">
                    <TooltipHost content={assignedTo}>
                        <Stack horizontal styles={{ root: { paddingLeft: '10px' } }} tokens={{ childrenGap: 9 }}>
                            
                            
                            <Text block={true} nowrap={true} styles={{ root: { paddingTop: '11px' } }}>
                                {assignedTo}
                            </Text>
                        </Stack>
                    </TooltipHost>
                </Stack.Item>
                <Stack.Item grow>
                    <div />
                </Stack.Item>
                {(!isReadOnly || isVmActionsEnabled) && !isLabDraft && (<>
                        <Stack.Item className="vm-list__name-action-stack-item" align="end">
                            <TooltipHost directionalHint={DirectionalHint.topRightEdge} content={connectItemProps.ariaLabel}>
                                <IconButton {...connectItemProps}/>
                            </TooltipHost>
                        </Stack.Item>
                        {!!rdpInfoProps && (<Stack.Item className="vm-list__name-action-stack-item" align="end">
                                <TooltipHost directionalHint={DirectionalHint.topRightEdge} content={rdpInfoProps.ariaLabel}>
                                    <IconButton {...rdpInfoProps}/>
                                </TooltipHost>
                            </Stack.Item>)}
                        {!!redeployButtonProps && (<Stack.Item className="vm-list__name-action-stack-item" align="end">
                                <TooltipHost directionalHint={DirectionalHint.topRightEdge} content={redeployButtonProps.ariaLabel}>
                                    <IconButton {...redeployButtonProps}/>
                                </TooltipHost>
                        </Stack.Item>)}
                        <Stack.Item className="vm-list__name-action-stack-item" align="end">
                            <TooltipHost directionalHint={DirectionalHint.topRightEdge} content={resetButtonProps.ariaLabel}>
                                <IconButton {...resetButtonProps}/>
                            </TooltipHost>
                        </Stack.Item>
                    </>)}
            </Stack>);
    }
    // Renders State cell of list view row including context menu
    _onRenderStateColumn(item) {
        const { vmListViewModel, intl } = this.props;
        const { isReadOnly, isLabDraft, isVmActionsEnabled } = vmListViewModel;
        const { vmState } = item;
        const msg = intl.formatMessage;
        const unpublished = msg(templateMessages.templateUnpublishedStatus);
        return (<Stack className="vm-list__state-container" horizontal tokens={{ childrenGap: 8 }} verticalFill verticalAlign="center" horizontalAlign="start" grow>
                <Stack.Item className="vm-list__state-stack-item" grow>
                    {!isLabDraft && (<VmPowerState forceDisable={isReadOnly && !isVmActionsEnabled} vmState={vmState} onStart={() => this.setState({ showStartDialog: true })} onStop={this._onStopClicked}/>)}
                    {!!isLabDraft && (<Text block={true} nowrap={true} styles={{ root: { paddingLeft: '10px', paddingTop: '11px' } }}>
                            {unpublished}
                        </Text>)}
                </Stack.Item>
            </Stack>);
    }
    _onCapacityFlyoutSubmit(capacity) {
        this.props.updateCapacity(capacity);
    }
    _onResetDialogSubmit() {
        const { resetEnvironments } = this.props;
        const { resetTargets } = this.state;
        if (resetTargets.length > 0) {
            resetEnvironments(resetTargets);
        }
        this.setState({ showResetDialog: false });
    }
    _onStartDialogSubmit(vms) {
        const { wasStartAllInvoked } = this.state;
        const { startEnvironments } = this.props;
        if (wasStartAllInvoked) {
            const ids = vms.filter((o) => o.canStart).map((o) => o.id);
            startEnvironments(ids);
            this.setState({ showStartDialog: false, wasStartAllInvoked: false });
        }
        else {
            const { startTargets } = this.state;
            if (startTargets.length > 0) {
                startEnvironments(startTargets);
            }
            this.setState({ showStartDialog: false });
        }
    }
    _onStopClicked() {
        const { stopEnvironments } = this.props;
        const { stopTargets } = this.state;
        if (stopTargets.length > 0) {
            stopEnvironments(stopTargets);
        }
    }
    _onRedeployClicked() {
        const { redeployVirtualMachines } = this.props;
        const { redeployTargets } = this.state;
        if (redeployTargets.length > 0) {
            redeployVirtualMachines(redeployTargets);
        }
    }
    setTroubleshootDialog(openDialog) {
        this.setState({ showTroubleshootDialog: openDialog });
    }
    _isStopAllEnabled(vms) {
        return vms.some((o) => o.canStop);
    }
    _isStartAllEnabled(vms) {
        return vms.some((o) => o.canStart);
    }
    _onStopAllClicked(vms) {
        const ids = vms.filter((o) => o.canStop).map((o) => o.id);
        this.props.stopEnvironments(ids);
    }
    _onFilterTextChange(filterText) {
        this.setState({ filterText });
    }
    _onFilterTypeChange(filterType) {
        const { filterTypes: oldFilterTypes } = this.state;
        const filterTypes = [...oldFilterTypes];
        const index = oldFilterTypes.indexOf(filterType);
        if (index > -1) {
            filterTypes.splice(index, 1);
        }
        else {
            filterTypes.push(filterType);
        }
        this.setState({ filterTypes });
    }
    _onFilterStateChange(filterState) {
        const { filterStates: oldFilterStates } = this.state;
        const filterStates = [...oldFilterStates];
        const index = oldFilterStates.indexOf(filterState);
        if (index > -1) {
            filterStates.splice(index, 1);
        }
        else {
            filterStates.push(filterState);
        }
        this.setState({ filterStates });
    }
    _onClearTypeFilter() {
        this.setState({ filterTypes: [] });
    }
    _onClearStateFilter() {
        this.setState({ filterStates: [] });
    }
    _onFilterClose() {
        this.setState({ showFilter: false, filterText: '', filterStates: [], filterTypes: [] });
    }
    _onFilterOpen() {
        this.setState({ showFilter: true });
    }
    _onDismissConnectDialog() {
        this.setState({
            showSshDialog: false,
        });
    }
    _onDismissedConnectDialog() {
        this.setState({
            sshCommand: undefined,
        });
    }
    _onConnect(vm, connectionType) {
        const hasConnectInfo = !!vm.rdpAuthority || !!vm.rdpInBrowserUrl || !!vm.sshAuthority || !!vm.sshInBrowserUrl;
        if (!hasConnectInfo) {
            const { connectExpandData } = this.state;
            connectExpandData[vm.id] = connectionType;
            this.props.getEnvironmentNetworkData(vm.id);
            this.setState({ connectExpandData });
        }
        else {
            switch (connectionType) {
                case ConnectionType.Rdp:
                    this._onConnectRdp(vm);
                    break;
                case ConnectionType.RdpInBrowser:
                    this._onConnectRdpInBrowser(vm);
                    break;
                case ConnectionType.Ssh:
                    this._onConnectSsh(vm);
                    break;
                case ConnectionType.SshInBrowser:
                    this._onConnectSshInBrowser(vm);
                    break;
                default:
                    trackTrace(`vm-list: Failed to connect to a VM. connectionType: ${connectionType}`);
                    break;
            }
        }
    }
    _onConnectSsh(vm) {
        const { sshAuthority, username } = vm;
        const { port, url } = getSshConnectData(sshAuthority);
        const sshCommand = getSshCommand(port, username, url);
        this.setState({ showSshDialog: true, sshCommand });
    }
    _onConnectRdp(vm) {
        const { isWindows, labTitle } = this.props.vmListViewModel;
        const { rdpAuthority, username } = vm;
        const { fileName, fileContent } = getRdpConnectData(rdpAuthority, isWindows, username, labTitle);
        createAndDownloadFile(fileName, fileContent);
    }
    _onConnectRdpInBrowser(vm) {
        window.open(vm.rdpInBrowserUrl, `rdp-${vm.id}`); // eslint-disable-line security/detect-non-literal-fs-filename
    }
    _onConnectSshInBrowser(vm) {
        window.open(vm.sshInBrowserUrl, `ssh-${vm.id}`); // eslint-disable-line security/detect-non-literal-fs-filename
    }
}
export const VmList = injectIntl(VmListInjected);
export default VmList;
