import { Dropdown, Stack } from '@fluentui/react';
import { LabServicesModels } from 'lab-services';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
const messages = defineMessages({
    idleUserAbsenceOption: {
        id: 'IdleUserAbsenceOption',
        defaultMessage: 'Detect idle based on user absence',
        description: 'Checkbox option for custom idle detection based on user absence.',
    },
    idleUserAbsenceAndUsageOption: {
        id: 'IdleUserAbsenceAndUsageOption',
        defaultMessage: 'Detect idle based on user absence and resource usage',
        description: 'Checkbox option for custom idle detection based on user absence and resource usage.',
    },
    idleCustomizeDropdownAriaLabel: {
        id: 'IdleCustomizeDropdownAriaLabel',
        defaultMessage: 'Custom idle detection dropdown based on either user absence or user absence and resource usage',
        description: 'Aria label for custom idle detection based on user absence only or user absence and resource usage.',
    },
});
export const CustomIdleShutdownDropdown = (props) => {
    const { value, onChange, disabled } = props;
    const msg = useIntl().formatMessage;
    const dropdownOptions = [
        { key: LabServicesModels.ShutdownOnIdleMode.LowUsage, text: msg(messages.idleUserAbsenceAndUsageOption) },
        { key: LabServicesModels.ShutdownOnIdleMode.UserAbsence, text: msg(messages.idleUserAbsenceOption) },
    ];
    const onSelectionChange = (option) => {
        if (option) {
            onChange(option.key === LabServicesModels.ShutdownOnIdleMode.UserAbsence
                ? LabServicesModels.ShutdownOnIdleMode.UserAbsence
                : LabServicesModels.ShutdownOnIdleMode.LowUsage);
        }
    };
    return (<Stack horizontal styles={{
        root: {
            marginTop: '8px',
            width: '100%',
        },
    }}>
            <Stack.Item grow>
                <Dropdown options={dropdownOptions} selectedKey={value} ariaLabel={msg(messages.idleCustomizeDropdownAriaLabel)} onChange={(_event, option, _index) => onSelectionChange(option)} disabled={disabled}/>
            </Stack.Item>
        </Stack>);
};
export default CustomIdleShutdownDropdown;
