import * as React from 'react';
import { TooltipHost, Toggle, getId } from '@fluentui/react';
export class ToggleToolTip extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { content, disabled } = this.props;
        const toolTipHostId = getId('toggleTipHost');
        return (<TooltipHost id={toolTipHostId} content={disabled ? undefined : content}>
                <Toggle {...this.props} aria-describedby={toolTipHostId}/>
            </TooltipHost>);
    }
}
export default ToggleToolTip;
