import * as React from 'react';
import { connect } from 'react-redux';
import { teamsConfigureTab, teamsDisableSaveButton } from '../redux/actions/teams/teams-action-creators';
import TeamsConfig from './teams-config';
import { getTeamsConfigContainerModel } from './teams-config-selectors';
import { refreshSubscriptionsAndLabParentResources } from '../redux/actions/lab-parent-resource/lab-parent-resource-action-creators';
class TeamsConfigContainerInjected extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        const { teamsConfigureTab, teamsConfigViewModel } = this.props;
        const { isStudent, isInvalidChannel, defaultSelectedId } = teamsConfigViewModel;
        if (!isStudent && !isInvalidChannel && defaultSelectedId) {
            teamsConfigureTab(defaultSelectedId);
        }
    }
    componentDidUpdate(prevProps) {
        const { teamsConfigViewModel, teamsConfigureTab, teamsDisableSaveButton } = this.props;
        const { isStudent, isInvalidChannel, labParentItems, isRefreshing, defaultSelectedId } = teamsConfigViewModel;
        if (isStudent || isInvalidChannel) {
            return;
        }
        if (prevProps.teamsConfigViewModel.labParentItems.size !== labParentItems.size) {
            if (prevProps.teamsConfigViewModel.labParentItems.size === 0 && defaultSelectedId) {
                teamsConfigureTab(defaultSelectedId);
            }
            else if (labParentItems.size === 0) {
                teamsDisableSaveButton();
            }
        }
        // if refreshing subscriptions and lab accounts is in process, disable the save button
        // when refreshing is done, configure the save button with the current single lab account.
        if (labParentItems.size === 1 && prevProps.teamsConfigViewModel.labParentItems.size === 1) {
            if (!prevProps.teamsConfigViewModel.isRefreshing && isRefreshing) {
                teamsDisableSaveButton();
            }
            else if (prevProps.teamsConfigViewModel.isRefreshing && !isRefreshing) {
                teamsConfigureTab(labParentItems.get(0).id);
            }
        }
    }
    render() {
        const { teamsConfigViewModel, teamsConfigureTab, refreshSubscriptionsAndLabParentResources } = this.props;
        const { isStudent, isLoading, isRefreshing, isInvalidChannel, labParentItems, hasRefreshed, hasRefreshError, defaultSelectedId, } = teamsConfigViewModel;
        // NOTE: Using #FFFFFF directly here so this change is low impact. When we can properly support a dark / high contrast
        // mode theme in teams this backgroundColor style will be removed
        return (<div style={{ backgroundColor: '#FFFFFF', height: '100%', width: '100%' }}>
                <TeamsConfig isStudent={isStudent} isInvalidChannel={isInvalidChannel} isLoading={isLoading} isRefreshing={isRefreshing} showNoFoundLabAccountsMessage={!isRefreshing && hasRefreshed && !hasRefreshError} labParentItems={labParentItems} defaultSelectedId={defaultSelectedId} onSave={(labParentId) => {
            teamsConfigureTab(labParentId);
        }} refreshLabParents={refreshSubscriptionsAndLabParentResources}/>
            </div>);
    }
}
const mapStateToProps = (state) => {
    return getTeamsConfigContainerModel(state);
};
const mapDispatchToProps = {
    teamsDisableSaveButton,
    teamsConfigureTab,
    refreshSubscriptionsAndLabParentResources,
};
export const TeamsConfigContainer = connect(mapStateToProps, mapDispatchToProps)(TeamsConfigContainerInjected);
export default TeamsConfigContainer;
