import { CommonActionType } from '../common/common-actions';
import { CommonLabActionType } from '../lab/lab-actions';
import { CommonTemplateActionType } from '../template/template-actions';
import { CommonLabParentResourceActionType } from '../lab-parent-resource/lab-parent-resource-actions';
export const PricingAndAvailabilityActionType = {
    ...CommonActionType,
    ...CommonLabActionType,
    ...CommonLabParentResourceActionType,
    ...CommonTemplateActionType,
    GET_PRICING_AND_AVAILABILITY_DATA: 'GET_PRICING_AND_AVAILABILITY_DATA',
    GET_PRICING_AND_AVAILABILITY_DATA_SUCCESS: 'GET_PRICING_AND_AVAILABILITY_DATA_SUCCESS',
    GET_PRICING_AND_AVAILABILITY_DATA_ERROR: 'GET_PRICING_AND_AVAILABILITY_DATA_ERROR',
    CLEAR_PRICING_AND_AVAILABILITY_ERROR: 'CLEAR_PRICING_AND_AVAILABILITY_ERROR',
    GET_LAB_PRICING_AND_AVAILABILITY_DATA: 'GET_LAB_PRICING_AND_AVAILABILITY_DATA',
    GET_LAB_PRICING_AND_AVAILABILITY_DATA_SUCCESS: 'GET_LAB_PRICING_AND_AVAILABILITY_DATA_SUCCESS',
    GET_LAB_PRICING_AND_AVAILABILITY_DATA_ERROR: 'GET_LAB_PRICING_AND_AVAILABILITY_DATA_ERROR',
    CLEAR_LAB_PRICING_AND_AVAILABILITY_ERROR: 'CLEAR_LAB_PRICING_AND_AVAILABILITY_ERROR',
};
