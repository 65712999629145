import { GroupActionType, } from './group-actions';
export const getGroupName = (groupId) => ({
    type: GroupActionType.GET_GROUP_NAME,
    groupId,
});
export const getGroupNameFailed = () => ({
    type: GroupActionType.GET_GROUP_NAME_FAILED,
});
export const getGroupNameSuccess = (groupName) => ({
    type: GroupActionType.GET_GROUP_NAME_SUCCESS,
    groupName,
});
export const getGroupNameError = (error) => ({
    type: GroupActionType.GET_GROUP_NAME_ERROR,
    error,
});
export const setGroupId = (groupId) => ({
    type: GroupActionType.SET_GROUP_ID,
    groupId,
});
export const setGroupName = (groupName) => ({
    type: GroupActionType.SET_GROUP_NAME,
    groupName,
});
export const enableFilterByGroupId = () => ({
    type: GroupActionType.ENABLE_FILTER_BY_GROUP_ID,
});
export const getGroups = (filterText) => ({
    type: GroupActionType.GET_GROUPS,
    filterText: filterText || '',
});
export const getGroupsSuccess = (groups) => ({
    type: GroupActionType.GET_GROUPS_SUCCESS,
    groups,
});
export const getGroupsFailed = () => ({
    type: GroupActionType.GET_GROUPS_FAILED,
});
export const getGroupsError = (error) => ({
    type: GroupActionType.GET_GROUPS_ERROR,
    error,
});
const actions = {
    getGroupName,
    getGroupNameFailed,
    getGroupNameSuccess,
    getGroupNameError,
    setGroupId,
    setGroupName,
    enableFilterByGroupId,
    getGroups,
    getGroupsSuccess,
    getGroupsFailed,
    getGroupsError,
};
export default actions;
