import Constants from '../../utils/constants';
import ArmProvider from './arm-data-provider';
export const ActionPermissions = {
    WriteLab: 'Microsoft.LabServices/labAccounts/labs/write',
};
export const VNextActionPermissions = {
    WriteLab: 'Microsoft.LabServices/labs/write',
    WriteLabPlan: 'Microsoft.LabServices/labPlans/write',
    WriteLabVm: 'Microsoft.LabServices/labs/virtualMachines/start/action',
};
export const VNextDataActionPermission = {
    CreateLabInLabPlan: "Microsoft.LabServices/labPlans/createLab/action"
};
export async function hasAccess(actionToCheck, id, accessToken, locale, language) {
    const resourcePermissionsUrl = `${id}${Constants.Urls.Permissions}`;
    const response = await ArmProvider.get(resourcePermissionsUrl, accessToken, locale, language);
    const permissions = response && response.json ? await response.json() : { value: [] };
    if (actionToCheck === VNextDataActionPermission.CreateLabInLabPlan) {
        return hasDataActionAccess(actionToCheck, permissions);
    }
    return hasActionAccess(actionToCheck, permissions);
}
function hasActionAccess(actionToCheck, resourcePermissions) {
    const allActionsWildCardString = '*';
    const explicitactionPermissions = resourcePermissions.value.filter((values) => values.actions.find((action) => action === actionToCheck));
    const allPermissions = resourcePermissions.value.filter((values) => values.actions.find((action) => action === allActionsWildCardString));
    return explicitactionPermissions.length > 0 || allPermissions.length > 0;
}
function hasDataActionAccess(actionToCheck, resourcePermissions) {
    const explicitDataActionPermissions = resourcePermissions.value.filter((values) => values.dataActions.find((dataActions) => dataActions === actionToCheck));
    return explicitDataActionPermissions.length > 0;
}
const PermissionsProvider = {
    ActionPermissions,
    VNextActionPermissions,
    VNextDataActionPermission,
    hasAccess,
};
export default PermissionsProvider;
