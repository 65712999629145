import { createVNextArmApiClient } from '../../arm-data-provider';
export async function listImages(labPlanId, accessToken, locale, language, listEnabledOnly) {
    const armApiClient = createVNextArmApiClient(labPlanId.subscriptionId, accessToken, locale, language);
    const options = listEnabledOnly
        ? {
            filter: "properties/enabledState eq 'Enabled'",
        }
        : undefined;
    let currentPage = await armApiClient.images.listByLabPlan(labPlanId.resourceGroupName, labPlanId.name, options);
    let images = currentPage;
    while (!!currentPage.nextLink) {
        const nextPage = await armApiClient.images.listByLabPlanNext(currentPage.nextLink, options);
        currentPage = nextPage;
        images = images.concat(nextPage);
    }
    return images;
}
export async function updateImage(imageId, accessToken, locale, language) {
    const client = createVNextArmApiClient(imageId.subscriptionId, accessToken, locale, language);
    await client.images.update(imageId.resourceGroupName, imageId.parent.name, imageId.name);
}
const ImageProvider = {
    listImages,
    updateImage,
};
export default ImageProvider;
