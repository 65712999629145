import { CommonActionType } from '../../common/common-actions';
import { CommonLabParentResourceActionType } from '../../lab-parent-resource/lab-parent-resource-actions';
import { CommonLabPlanActionType } from '../../lab-plan/lab-plan-actions';
import { CommonLabActionType } from '../../lab/lab-actions';
export const PriceActionType = {
    ...CommonActionType,
    ...CommonLabParentResourceActionType,
    ...CommonLabActionType,
    ...CommonLabPlanActionType,
    LIST_PRICE_DATA: 'LIST_PRICE_DATA',
    LIST_PRICE_DATA_SUCCESS: 'LIST_PRICE_DATA_SUCCESS',
    LIST_PRICE_DATA_ERROR: 'LIST_PRICE_DATA_ERROR',
    LIST_PRICE_DATA_CANCELLED: 'LIST_PRICE_DATA_CANCELLED',
};
