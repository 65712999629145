import { Announced } from '@fluentui/react';
import * as React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { VmState } from '../data/models/environment-common';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
const messages = defineMessages({
    studentVmListAnnouncedInfomation: {
        id: 'StudentVmListAnnounceInfomation',
        defaultMessage: 'Virtual machine of {labName} is now {vmState}',
        description: 'Information about the change of virtual machine state that need to be announced to users.',
    },
});
export const StudentVmListAnnounced = (props) => {
    const { vms } = props;
    const intl = useIntl();
    const msg = intl.formatMessage;
    const map = {};
    map[VmState.Creating] = msg(commonMessages.creatingState);
    map[VmState.Starting] = msg(commonMessages.startingState);
    map[VmState.Running] = msg(commonMessages.runningState);
    map[VmState.Deleting] = msg(commonMessages.deletingState);
    map[VmState.Stopping] = msg(commonMessages.stoppingState);
    map[VmState.Stopped] = msg(commonMessages.stoppedState);
    map[VmState.Failed] = msg(commonMessages.failedState);
    map[VmState.ResettingPassword] = msg(commonMessages.resettingPasswordState);
    map[VmState.Unknown] = msg(commonMessages.unknownState);
    const [announced, setAnnounced] = React.useState(null);
    const previousVms = usePrevious(vms);
    React.useEffect(() => {
        if (!previousVms) {
            return;
        }
        const updatedVms = [];
        for (const vm of vms) {
            const vmIndex = previousVms.findIndex((o) => o.id === vm.id);
            if (vmIndex > -1 && vm.vmState !== previousVms[vmIndex].vmState) {
                updatedVms.push(vm);
            }
        }
        if (updatedVms.length > 0) {
            const announcedItems = updatedVms.map((vm) => (<Announced key={vm.id} message={intl.formatMessage(messages.studentVmListAnnouncedInfomation, {
                labName: vm.title,
                vmState: map[vm.vmState],
            })} aria-live="assertive"/>));
            setAnnounced(<>{announcedItems}</>);
        }
    }, [vms]);
    return <>{announced}</>;
};
export default StudentVmListAnnounced;
