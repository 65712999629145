import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { Routes } from '../utils/routes';
export const SignInReturnPage = (props) => {
    const { isAuthenticated, isMfaReturnUrl, search, returnUrl } = props;
    if (isAuthenticated || isMfaReturnUrl) {
        return <Redirect to={returnUrl}/>;
    }
    return <Redirect to={{ pathname: Routes.Home, search }}/>;
};
export default SignInReturnPage;
