import { DefaultButton, Dialog, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SubmitButton } from '../common/submit-button';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import Constants from '../utils/constants';
const messages = defineMessages({
    title: {
        id: 'OpenAccessDialogTitle',
        defaultMessage: 'Switch to open access',
        description: 'Title of the dialog which pops up when user switches a lab to open access mode.',
    },
    dialogMessage: {
        id: 'OpenAccessDialogText',
        defaultMessage: "Anyone with the registration link will be able to join the lab without being part of your lab's user list.",
        description: 'Text on the dialog which pops up when user switches a lab to open access mode.',
    },
});
export const OpenAccessDialog = (props) => {
    const { isSubmitting, onSubmit, onDismiss } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    // close the dialog after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    return (<Dialog hidden={false} onDismiss={isSubmitting ? undefined : onDismiss} dialogContentProps={{ title: msg(messages.title), subText: msg(messages.dialogMessage) }} closeButtonAriaLabel={msg(commonMessages.cancel)} maxWidth={Constants.DefaultDialogWidth}>
            <Stack horizontal tokens={{ childrenGap: '8px' }} styles={{ root: { width: '100%' } }} verticalAlign="end" horizontalAlign="end">
                <Stack.Item>
                    <SubmitButton default={true} isSubmitting={isSubmitting} buttonText={msg(commonMessages.continue)} onClick={onSubmit}/>
                </Stack.Item>
                <Stack.Item>
                    <DefaultButton disabled={isSubmitting} text={msg(commonMessages.cancel)} onClick={onDismiss} autoFocus={true}/>
                </Stack.Item>
            </Stack>
        </Dialog>);
};
export default OpenAccessDialog;
