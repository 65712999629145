import { Position, SpinButton } from '@fluentui/react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import commonMessages from '../language/common-messages';
export const SafeSpinButton = (props) => {
    const msg = useIntl().formatMessage;
    const { styles, autoFocus, label, ariaLabel, value, disabled, min, max, onChange, labelPosition } = props;
    const validate = (value, delta, min, max) => {
        const num = Number(value.trim()) + delta;
        let result;
        if (isNaN(num) || num < min) {
            result = String(min);
        }
        else if (num > max) {
            result = String(max);
        }
        else {
            result = num.toFixed(0);
        }
        onChange(Number(result));
        return result;
    };
    return (<SpinButton styles={styles} inputProps={{ autoFocus }} label={label} labelPosition={labelPosition ?? Position.top} ariaLabel={ariaLabel ?? label} incrementButtonAriaLabel={msg(commonMessages.incrementAriaLabel)} decrementButtonAriaLabel={msg(commonMessages.decrementAriaLabel)} disabled={disabled} min={min} max={max} value={value.toString()} onValidate={(value) => validate(value, 0, min, max)} onIncrement={(value) => validate(value, 1, min, max)} onDecrement={(value) => validate(value, -1, min, max)}/>);
};
export default SafeSpinButton;
