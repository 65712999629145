import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ResourceId } from '../utils/resource-id';
import { Constants } from '../utils/constants';
import { Link } from '@fluentui/react';
import { isVNextLab } from '../redux/selectors/lab-selectors';
export const RequestLimitIncreaseInSentenceLink = (props) => {
    let { tenantId, labParentId, labId } = props; // eslint-disable-line prefer-const
    if (!labParentId && !labId) {
        return <></>;
    }
    if (!labParentId && labId) {
        const isVNext = isVNextLab(labId);
        const labResourceId = new ResourceId(labId, isVNext);
        labParentId = labResourceId.parent.id;
    }
    const id = new ResourceId(labParentId);
    return (<Link target="_blank" style={{ paddingLeft: '0px' }} href={Constants.Urls.SupportRequest(tenantId, id.subscriptionId)}>
            <FormattedMessage id="SupportRequestLink" defaultMessage="request a limit increase" description="Link to submit a support request to increase core quota limits for the subscription."/>
        </Link>);
};
export const RequestLimitIncreaseLink = (props) => {
    let { tenantId, labParentId, labId } = props; // eslint-disable-line prefer-const
    if (!labParentId && !labId) {
        return <></>;
    }
    if (!labParentId && labId) {
        const isVNext = isVNextLab(labId);
        const labResourceId = new ResourceId(labId, isVNext);
        labParentId = labResourceId.parent.id;
    }
    const id = new ResourceId(labParentId);
    return (<Link target="_blank" style={{ paddingLeft: '0px' }} href={Constants.Urls.SupportRequest(tenantId, id.subscriptionId)}>
            <FormattedMessage id="RequestLimitIncreaseLink" defaultMessage="Request a limit increase" description="Link to submit a support request to increase core quota limits for the subscription."/>
        </Link>);
};
