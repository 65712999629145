import * as React from 'react';
import { Helmet } from 'react-helmet';
import IdleTimer from 'react-idle-timer';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AuthenticationState } from './data/models/identity';
import ErrorBoundaryContainer from './error-boundary/error-boundary-container';
import ErrorViewContainer from './error-view/error-view-container';
import { LandingPageContainer } from './landing-page/landing-page-container';
import { ProfessorAppContainer } from './professor-app/professor-app-container';
import { idleTimeout, initialize, processQueryParams, setTimezone, } from './redux/actions/common/common-action-creators';
import { getLocale, isTeamsMode, isTeamsHost } from './redux/selectors/common-selectors';
import { getRoute, getSearch } from './redux/selectors/route-selector';
import { InitializationState } from './redux/state/common-store';
import { SignInRedirectPageContainer } from './sign-in/sign-in-redirect-container';
import { SignInReturnPageContainer } from './sign-in/sign-in-return-container';
import { StudentAppContainer } from './student-app/student-app-container';
import { UserLoggedOutContainer } from './user-logged-out/user-logged-out-container';
import { guessTimezone } from './utils/common';
import { Urls } from './utils/constants';
import { Routes } from './utils/routes';
import { trackPageView } from './utils/telemetry/telemetry-channel';
import { Loading } from './welcome/loading';
import { TeamsStartMfaContainer } from './teams-app/teams-start-mfa-container';
import { TeamsCompleteMfaContainer } from './teams-app/teams-complete-mfa-container';
import { LtiStartOidcContainer } from './lti-app/lti-start-oidc-container';
import { LoadingContainer } from './common/loading-section';
import { getAuthenticationState } from './redux/selectors/identity-selector';
import { isLmsStudent } from './redux/selectors/lti-selectors';
import './app.css';
class AppInjected extends React.Component {
    constructor(props) {
        super(props);
        this._onIdle = (_e) => {
            this.props.idleTimeout();
        };
        // Doing this in the constructor and not in
        // componentDidMount so we derive a locale by
        // the time we render the loading spinner.
        // Otherwise, this is unsafe.
        const timezone = guessTimezone();
        const { search, setTimezone, processQueryParams } = this.props;
        processQueryParams(search);
        setTimezone(timezone);
    }
    // using this as a hook for detecting changes to search, can't see
    // previous values of search within a saga to compare
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { search, processQueryParams } = nextProps;
        if (search !== this.props.search) {
            processQueryParams(search);
        }
    }
    componentDidMount() {
        const { route, initialize } = this.props;
        trackPageView('SPA', route);
        initialize();
    }
    render() {
        const { fatalError, isInitializing, isRedirecting, isAuthenticated, isSigningOut, shouldHideLoadingHeader, isTeamsHosted, search, locale, messages, isPoppingUp, isLmsCourseStudent, } = this.props;
        let appContent;
        if (fatalError) {
            // the error is rendered by the ErrorViewContainer
            appContent = null;
        }
        else if (isInitializing || isRedirecting || isSigningOut || isPoppingUp) {
            appContent = (<Switch>
                    <Route exact={true} path={[Routes.TeamsConfig, Routes.TeamsStartMfa, Routes.TeamsCompleteMfa, Routes.LtiStartOidc]} render={() => <LoadingContainer styles={{ root: { backgroundColor: 'inherit' } }}/>}/>
                    <Route render={() => <Loading hideHeader={shouldHideLoadingHeader}/>}/>
                </Switch>);
        }
        else if (!isAuthenticated) {
            appContent = (<Switch>
                    <Route exact={true} path={Routes.LoggedOut} component={UserLoggedOutContainer}/>
                    <Route exact={true} path={Routes.SignInReturn} component={SignInReturnPageContainer}/>
                    <Route exact={true} path={Routes.TeamsStartMfa} component={TeamsStartMfaContainer}/>
                    <Route exact={true} path={Routes.TeamsCompleteMfa} component={TeamsCompleteMfaContainer}/>
                    <Route exact={true} path={Routes.LtiStartOidc} component={LtiStartOidcContainer}/>
                    <Route exact={true} path={[
                Routes.Welcome,
                Routes.StudentView,
                Routes.TeamsConfig,
                Routes.LabList(),
                Routes.NewLab(),
                Routes.Dashboard(),
                Routes.Template(),
                Routes.Schedule(),
                Routes.Settings(),
                Routes.Users(),
                Routes.Lab(),
                Routes.VirtualMachines(),
                Routes.Register(),
            ]} component={SignInRedirectPageContainer}/>
                    <Route exact={true} path={Routes.Home} component={LandingPageContainer}/>
                    <Route exact={true} path={Routes.Privacy} render={() => {
                window.location.replace(Urls.Privacy);
                return <></>;
            }}/>
                    <Route exact={true} path={Routes.TermsOfUse} render={() => {
                window.location.replace(Urls.TermsOfUse);
                return <></>;
            }}/>
                    <Route render={() => <Redirect to={{ pathname: Routes.Home, search }}/>}/>
                </Switch>);
        }
        else {
            appContent = (<Switch>
                    <Route exact={true} path={Routes.SignInReturn} component={SignInReturnPageContainer}/>
                    <Route exact={true} path={Routes.TeamsStartMfa} component={TeamsStartMfaContainer}/>
                    <Route exact={true} path={Routes.TeamsCompleteMfa} component={TeamsCompleteMfaContainer}/>
                    <Route exact={true} path={Routes.LtiStartOidc} component={LtiStartOidcContainer}/>
                    <Route exact={true} path={[
                Routes.Home,
                Routes.Welcome,
                Routes.TeamsConfig,
                Routes.LabList(),
                Routes.NewLab(),
                Routes.Dashboard(),
                Routes.Template(),
                Routes.VirtualMachines(),
                Routes.Users(),
                Routes.Schedule(),
                Routes.Settings(),
                Routes.Lab(),
                Routes.VNextLab(),
            ]} component={ProfessorAppContainer}/>
                    <Route exact={true} path={[Routes.StudentView, Routes.Register()]} component={StudentAppContainer}/>
                    <Route exact={true} path={Routes.Privacy} render={() => {
                window.location.replace(Urls.Privacy);
                return <></>;
            }}/>
                    <Route exact={true} path={Routes.TermsOfUse} render={() => {
                window.location.replace(Urls.TermsOfUse);
                return <></>;
            }}/>
                    <Route render={() => <Redirect to={{ pathname: Routes.Home, search }}/>}/>
                </Switch>);
        }
        return (<IntlProvider locale={locale} key={locale} messages={messages}>
                {!isTeamsHosted && !isLmsCourseStudent && (<IdleTimer element={document} onIdle={this._onIdle} timeout={30 * 60 * 1000}/>)}
                <Helmet htmlAttributes={{ lang: locale, dir: 'ltr' }}/>
                <ErrorViewContainer />
                <ErrorBoundaryContainer>{appContent}</ErrorBoundaryContainer>
            </IntlProvider>);
    }
}
const mapStateToProps = (state) => {
    const commonStore = state.get('commonStore');
    const locale = getLocale(state);
    const requestedLocale = commonStore.get('requestedLocale');
    const messages = commonStore.get('messages');
    const initializationState = commonStore.get('initializationState');
    const fatalError = commonStore.get('fatalError');
    const shouldHideLoadingHeader = isTeamsMode(state);
    const isTeamsHosted = isTeamsHost(state);
    const isLmsCourseStudent = isLmsStudent(state);
    const identityState = getAuthenticationState(state);
    const isAuthenticated = identityState === AuthenticationState.Authenticated;
    const isRedirecting = identityState === AuthenticationState.Redirecting;
    const isSigningOut = identityState === AuthenticationState.SigningOut;
    const isPoppingUp = identityState === AuthenticationState.PoppingUp;
    const isInitializing = initializationState === InitializationState.NotStarted ||
        initializationState === InitializationState.Initializing;
    const search = getSearch(state);
    const route = getRoute(state);
    return {
        locale,
        requestedLocale,
        messages,
        isInitializing,
        isAuthenticated,
        isRedirecting,
        isSigningOut,
        shouldHideLoadingHeader,
        isTeamsHosted,
        isLmsCourseStudent,
        search,
        route,
        fatalError,
        isPoppingUp,
    };
};
const mapDispatchToProps = {
    initialize,
    setTimezone,
    idleTimeout,
    processQueryParams,
};
export const App = connect(mapStateToProps, mapDispatchToProps)(AppInjected);
export default App;
