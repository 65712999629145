import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    settings: {
        id: 'SettingsPageTitle',
        defaultMessage: 'Settings',
        description: 'Title of the lab settings page',
    },
    linuxDistributionsInfo: {
        id: 'SettingsLinuxDistributionsInfoText',
        defaultMessage: 'Some Linux distributions don\'t support Idle auto-shutdown.  To see which distributions support it, please see <Link>documentation</Link>.',
        description: 'Message at the bottom of new lab flyout dialog for vnext that warns about Linux distrubutions.',
    },
});
export default messages;
