import { Announced, CheckboxVisibility, CommandBar, ContextualMenuItemType, DefaultButton, DetailsListLayoutMode, DirectionalHint, Icon, IconButton, MarqueeSelection, MessageBar, MessageBarType, PersonaCoin, PersonaSize, ScrollablePane, ScrollbarVisibility, SearchBox, Selection, SelectionMode, ShimmeredDetailsList, Stack, Sticky, StickyPositionType, Text, TooltipHost, } from '@fluentui/react';
import { createObjectCsvStringifier } from 'csv-writer';
import _ from 'lodash';
import memoize from 'memoize-one';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import CommandButtonToolTip from '../common/command-button-tooltip';
import { FileUploadButton } from '../common/file-upload-button';
import { getNavigationItems } from '../common/lab-nav-items';
import { LoadingContainer } from '../common/loading-section';
import { getCustomTheme } from '../common/themes/selectors';
import ToggleToolTip from '../common/toggle-tooltip';
import { VmUsageColumn, VmUsageProgressColumn } from '../common/vm/vm-usage-column';
import FullPageMessage from '../full-page-message/full-page-message';
import { LabErrorBanner } from '../lab/lab-error-banner';
import commonMessages from '../language/common-messages';
import { shortenNumber } from '../utils/common';
import Constants from '../utils/constants';
import { isValidEmail } from '../utils/emails';
import { createAndDownloadFile } from '../utils/files';
import LabNavKeys from '../utils/lab-nav-key';
import { objectKeySorter } from '../utils/sorting';
import { AddUsersFlyout as AddUsersFlyoutNew } from './add-users-flyout-new';
import SyncFromGroupFlyout from './sync-from-group-flyout';
import DeleteUsersDialog from './delete-users-dialog';
import InvitationFlyout from './invitation-flyout';
import { InviteAllDialog } from './invite-all-dialog';
import OpenAccessDialog from './open-access-dialog';
import QuotaFlyout from './quota-flyout';
import RegistrationLinkDialog from './registration-link-dialog';
import { InviteStatus, UserStatus } from './user-list-models';
import { messages as userListCommonMessages } from './user-list-messages';
import { UserQuotaFlyout } from './user-quota-flyout';
import SyncUsersDialog from './sync-users-dialog';
import GroupSyncTimeInfo from '../common/group-sync-time-info';
import { getNextGroupSyncTime } from '../common/selectors/group-sync-time-selectors';
import messages from './user-list-messages';
import SyncingSpinner from '../common/syncing-spinner';
import './user-list.css';
class UserListInjected extends React.Component {
    constructor(props) {
        super(props);
        this.onUserPropsChange = memoize((users, previousSelectedIds) => {
            const selectedIds = [];
            const selectedItems = [];
            const deleteSelectTarget = [];
            const inviteSelectTarget = [];
            const quotaSelectTarget = [];
            // logic for updating our selections when the list changes
            users.forEach((user) => {
                const { id } = user;
                if (previousSelectedIds.indexOf(id) > -1) {
                    selectedIds.push(id);
                    selectedItems.push(user);
                    const { canDelete, canInvite, canInviteAgain, canAddQuota } = user;
                    if (canDelete) {
                        deleteSelectTarget.push(id);
                    }
                    if (canInvite || canInviteAgain) {
                        inviteSelectTarget.push(id);
                    }
                    if (canAddQuota) {
                        quotaSelectTarget.push(id);
                    }
                }
            });
            this.setState({
                selectedCount: selectedIds.length,
                selectedIds,
                deleteSelectTarget,
                inviteSelectTarget,
                quotaSelectTarget,
            });
            return selectedItems;
        });
        this.filterAndSortUsers = memoize((users, selectedIds, // used purely for memoization
        filterText, filterUserStatus, filterInviteStatus, sortByColumn, isSortDescending, sorterStateMap) => {
            const filteredItems = users
                .filter((user) => (!filterText ||
                user.name.toLowerCase().includes(filterText.toLowerCase()) ||
                user.email.toLowerCase().includes(filterText.toLowerCase())) &&
                (filterUserStatus.length < 1 || filterUserStatus.indexOf(user.status) > -1) &&
                (filterInviteStatus.length < 1 || filterInviteStatus.indexOf(user.inviteStatus) > -1))
                .toArray();
            const key = sortByColumn;
            const sorter = objectKeySorter(key, isSortDescending, sorterStateMap);
            const sortedItems = filteredItems.sort(sorter);
            return sortedItems;
        });
        // Handles Export to CSV command
        this._onExportClicked = () => {
            const { userListViewModel, intl } = this.props;
            const { users } = userListViewModel;
            const msg = intl.formatMessage;
            const userStatusMap = this._buildUserStatusMap();
            const inviteStatusMap = this._buildInviteStatusMap();
            const writer = createObjectCsvStringifier({
                header: [
                    { id: 'email', title: msg(messages.emailColumnHeader) },
                    { id: 'name', title: msg(commonMessages.nameColumnHeader) },
                    { id: 'status', title: msg(messages.statusColumnHeader) },
                    { id: 'inviteStatus', title: msg(messages.invitationColumnHeader) },
                    { id: 'currentUsage', title: msg(messages.usageCsvColumnName) },
                    { id: 'totalQuota', title: msg(messages.quotaCsvColumnName) },
                ],
            });
            const records = users
                .map((o) => ({
                email: o.email,
                name: o.name,
                status: userStatusMap[o.status],
                inviteStatus: inviteStatusMap[o.inviteStatus],
                currentUsage: o.currentUsage,
                totalQuota: o.totalQuota,
            }))
                .toArray();
            const csv = writer.getHeaderString() + writer.stringifyRecords(records);
            createAndDownloadFile(`${msg(userListCommonMessages.pageTitle)}.csv`, csv, true);
        };
        this.announce = [];
        this._buildInviteStatusMap = this._buildInviteStatusMap.bind(this);
        this._buildUserStatusMap = this._buildUserStatusMap.bind(this);
        this._renderListHeader = this._renderListHeader.bind(this);
        this._renderDefaultCommandBar = this._renderDefaultCommandBar.bind(this);
        this._renderFilterBar = this._renderFilterBar.bind(this);
        this._renderSelectionCommandBar = this._renderSelectionCommandBar.bind(this);
        this._createColumns = this._createColumns.bind(this);
        this._onAccessDialogSubmit = this._onAccessDialogSubmit.bind(this);
        this._onAccessModeToggle = this._onAccessModeToggle.bind(this);
        this._onAddUsersFlyoutSubmit = this._onAddUsersFlyoutSubmit.bind(this);
        this._onClearInviteStatusFilter = this._onClearInviteStatusFilter.bind(this);
        this._onClearUserStatusFilter = this._onClearUserStatusFilter.bind(this);
        this._onCloseFilter = this._onCloseFilter.bind(this);
        this._onColumnClick = this._onColumnClick.bind(this);
        this._onCsvFileUpload = this._onCsvFileUpload.bind(this);
        this._onDeleteDialogSubmit = this._onDeleteDialogSubmit.bind(this);
        this._onFilterInviteStatusChange = this._onFilterInviteStatusChange.bind(this);
        this._onFilterTextChange = this._onFilterTextChange.bind(this);
        this._onFilterUserStatusChange = this._onFilterUserStatusChange.bind(this);
        this._onInvitationFlyoutSubmit = this._onInvitationFlyoutSubmit.bind(this);
        this._onOpenFilter = this._onOpenFilter.bind(this);
        this._onQuotaFlyoutSubmit = this._onQuotaFlyoutSubmit.bind(this);
        this._onRenderNameColumn = this._onRenderNameColumn.bind(this);
        this._onRenderUploadCsv = this._onRenderUploadCsv.bind(this);
        this._getFilterText = this._getFilterText.bind(this);
        this._getQuotaCommandName = this._getQuotaCommandName.bind(this);
        this._onDismissInviteAllDialog = this._onDismissInviteAllDialog.bind(this);
        this._onSubmitInviteAllDialog = this._onSubmitInviteAllDialog.bind(this);
        this._onInviteAllClicked = this._onInviteAllClicked.bind(this);
        this._onInviteClicked = this._onInviteClicked.bind(this);
        this._onLabQuotaClicked = this._onLabQuotaClicked.bind(this);
        this._onUserQuotaClicked = this._onUserQuotaClicked.bind(this);
        this._onDeleteUserClicked = this._onDeleteUserClicked.bind(this);
        this._onRenderRow = this._onRenderRow.bind(this);
        this._onSyncUsersDialogSubmit = this._onSyncUsersDialogSubmit.bind(this);
        this._selection = new Selection({
            onSelectionChanged: () => {
                const selectedIds = this._selection.getSelection().map((item) => item.key);
                this.onUserPropsChange(this.props.userListViewModel.users, selectedIds);
            },
        });
        this.state = {
            showFilter: false,
            showOpenAccessDialog: false,
            showRegistrationLinkDialog: false,
            showDeleteUsersDialog: false,
            showInviteAllDialog: false,
            showSyncUsersDialog: false,
            showAddUsersFlyout: false,
            showSyncFromGroupFlyout: false,
            showInvitationFlyout: false,
            showQuotaFlyout: false,
            showUserQuotaFlyout: false,
            addUserEmails: '',
            selectedCount: 0,
            selectedIds: [],
            hoveredId: '',
            deleteSelectTarget: [],
            inviteSelectTarget: [],
            quotaSelectTarget: [],
            inviteIds: [],
            filterText: '',
            filterUserStatus: [],
            filterInviteStatus: [],
            sortByColumn: 'nameSortValue',
            isSortDescending: false,
            hasEmptyCsvFileError: false,
        };
    }
    // This method is deprecated, but it is the
    // most efficient way to reset our selections
    // If this method is removed in a subsequent React release
    // we'll need to re-evaluate this behavior
    UNSAFE_componentWillReceiveProps(nextProps) {
        const { users } = nextProps.userListViewModel;
        const { selectedIds } = this.state;
        // onUserPropsChange re-calculates our state and then returns the items that should be selected
        // and is memoized for perf
        const selectedItems = this.onUserPropsChange(users, selectedIds);
        // clear our selections and then reselect them
        this._selection.setItems(selectedItems, true);
        if (selectedItems.length === users.count()) {
            this._selection.setAllSelected(true);
        }
        else {
            this._selection.setAllSelected(false);
            selectedItems.forEach((item) => {
                this._selection.setKeySelected(item.key, true, false);
            });
        }
    }
    render() {
        const { userListViewModel, intl, clearLabUpdateError, clearAddUsersError, clearDeleteUsersError, clearInviteUsersError, clearUpdateAdditionalQuotaForUsersError, navigateRoute, } = this.props;
        const { showAddUsersFlyout, showSyncFromGroupFlyout, showDeleteUsersDialog, showFilter, showInvitationFlyout, showOpenAccessDialog, showQuotaFlyout, showUserQuotaFlyout, showRegistrationLinkDialog, showInviteAllDialog, showSyncUsersDialog, selectedCount, selectedIds, deleteSelectTarget, inviteIds, filterText, filterInviteStatus, filterUserStatus, sortByColumn, isSortDescending, } = this.state;
        const { lab, groupId, users, isSyncing, isReadOnly, isGroupSyncModeEnabled, groupName, labQuota, isRestricted, registrationLink, groups, isAdding, isDeleting, isInviting, isUpdatingLab, isUpdatingUsers, labUpdateError, hasAddErrors, hasDeleteErrors, hasInviteErrors, hasUpdateErrors, isLoadingGroups, isTeamsOrLmsIntegrationEnabled, isLoading, hasLoadError, ianaTimezone, locale, isLabLmsConnected, shouldDisableLabUpdate, } = userListViewModel;
        const msg = intl.formatMessage;
        const { primaryBackgroundColor, pageTitleStyle, primaryCommandBarStyles, shimmeredUserListStyles, } = getCustomTheme();
        if (hasLoadError) {
            const navigationItems = isTeamsOrLmsIntegrationEnabled && lab && lab.id
                ? getNavigationItems(intl, lab.id, LabNavKeys.Users, navigateRoute)
                : undefined;
            return (<div id="user-list-container" style={{ backgroundColor: primaryBackgroundColor }}>
                    <div id="user-list-content">
                        {navigationItems && (<div id="user-list-header">
                                <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                            </div>)}
                        <FullPageMessage image="error-general" message={<FormattedMessage id="UserListLoadError" defaultMessage="Cannot load lab users" description="Error shown on the user list page when it fails to load necessary data from the server."/>} messageDetails={<FormattedMessage id="UserListLoadErrorDetails" defaultMessage="Your lab does not appear to be in a state to view or edit users at this time. Please try again later or contact your Lab Services administator for assistance." description="Error shown on the user list page when it fails to load necessary data from the server."/>}/>
                    </div>
                </div>);
        }
        if (isLoading) {
            const navigationItems = isTeamsOrLmsIntegrationEnabled && lab && lab.id
                ? getNavigationItems(intl, lab.id, LabNavKeys.Users, navigateRoute)
                : undefined;
            return (<div id="user-list-container" style={{ backgroundColor: primaryBackgroundColor }}>
                    <div id="user-list-content">
                        {navigationItems && (<div id="user-list-header">
                                <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                            </div>)}
                        <LoadingContainer />
                    </div>
                </div>);
        }
        const userStatusMap = this._buildUserStatusMap();
        const inviteStatusMap = this._buildInviteStatusMap();
        const columns = this._createColumns(sortByColumn, isSortDescending, userStatusMap, inviteStatusMap, locale, isLabLmsConnected);
        let sorterStateMap = undefined;
        if (sortByColumn === 'status') {
            sorterStateMap = userStatusMap;
        }
        else if (sortByColumn === 'inviteStatus') {
            sorterStateMap = inviteStatusMap;
        }
        const sortedItems = this.filterAndSortUsers(users, selectedIds, filterText, filterUserStatus, filterInviteStatus, sortByColumn, isSortDescending, sorterStateMap);
        const detailsList = (<ShimmeredDetailsList data-is-scrollable="true" items={sortedItems} columns={columns} selectionMode={isReadOnly || isSyncing ? SelectionMode.none : SelectionMode.multiple} getKey={isSyncing ? undefined : (item) => item.id} setKey="set" layoutMode={DetailsListLayoutMode.justified} isHeaderVisible={true} selection={this._selection} onRenderDetailsHeader={this._renderListHeader} onRenderRow={this._onRenderRow} enterModalSelectionOnTouch={true} ariaLabelForSelectionColumn={msg(commonMessages.toggleSelection)} ariaLabelForSelectAllCheckbox={msg(commonMessages.toggleAllSelection)} checkButtonAriaLabel={msg(messages.userListCheckboxAriaLabel)} checkboxVisibility={CheckboxVisibility.always} compact={false} enableShimmer={isSyncing} ariaLabelForShimmer={msg(messages.shimmerAriaLabel)} styles={shimmeredUserListStyles} className={'user-list__details-list'}/>);
        return (<>
                {this.announce}
                <div id="user-list-container" style={{ backgroundColor: primaryBackgroundColor }}>
                    <div id="user-list-content">
                        <div id="user-list-header">
                            <LabErrorBanner updateError={labUpdateError} clearUpdateError={clearLabUpdateError}/>
                            {hasAddErrors && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearAddUsersError()}>
                                    <FormattedMessage id="UserListAddUsersError" defaultMessage="An error occurred while adding users to the lab." description="Message shown when an error occurs while adding users to the lab."/>
                                </MessageBar>)}
                            {hasDeleteErrors && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearDeleteUsersError()}>
                                    <FormattedMessage id="UserListDeleteUsersError" defaultMessage="An error occurred while deleting users from the lab." description="Message shown when an error occurs while deleting users from the lab."/>
                                </MessageBar>)}
                            {hasInviteErrors && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearInviteUsersError()}>
                                    <FormattedMessage id="UserListInviteUsersError" defaultMessage="An error occurred while inviting users to the lab." description="Message shown when error an occurs while inviting users to the lab."/>
                                </MessageBar>)}
                            {hasUpdateErrors && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearUpdateAdditionalQuotaForUsersError()}>
                                    <FormattedMessage id="UserListUpdateUsersError" defaultMessage="An error occurred while adding quota to one or more users." description="Message shown when an error occurs while adding additional quota to users."/>
                                </MessageBar>)}
                            {selectedCount > 0 ? this._renderSelectionCommandBar() : this._renderDefaultCommandBar()}
                            {showFilter && this._renderFilterBar(users, userStatusMap, inviteStatusMap)}
                            {(users.size > 0 || (!!groupId && isSyncing)) && (<>
                                    <h1 style={pageTitleStyle}>
                                        <FormattedMessage {...userListCommonMessages.pageTitle}/>
                                    </h1>
                                    <Stack style={{
            marginLeft: '32px',
            marginRight: '8px',
            marginTop: '11px',
            marginBottom: '25px',
        }}>
                                        <Stack.Item>
                                            {isSyncing && (<SyncingSpinner groupName={groupName} styles={{
            root: { justifyContent: 'initial' },
            label: { fontSize: '14px', verticalAlign: 'center' },
        }}/>)}
                                        </Stack.Item>
                                        {isGroupSyncModeEnabled && !isSyncing && lab && lab.lastGroupSyncTime && (<Stack.Item>
                                                <GroupSyncTimeInfo groupName={groupName} locale={locale} lab={lab} content={<FormattedMessage id="UserListListNextUserGroupSyncTime" defaultMessage="The user list will automatically sync from the group every 24 hours. The next sync will occur at {nextGroupSyncTime}." description="Info tip about automatic sync and next sync time" values={{
            nextGroupSyncTime: getNextGroupSyncTime(ianaTimezone, locale),
        }}/>} tooltipProps={{ maxWidth: '237px' }}/>
                                            </Stack.Item>)}
                                        <Stack.Item>
                                            <FormattedMessage id="UserListQuotaDescription" defaultMessage="Quota available for each user includes the lab quota and the user quota. This does not include hours consumed during schedule events." description="Description about quota."/>
                                        </Stack.Item>
                                    </Stack>
                                </>)}
                        </div>
                        {users.size === 0 && !isSyncing ? (!!groupId ? (<Stack id="user-list-body" horizontalAlign="center" styles={{ root: { paddingTop: '27px' } }} verticalFill>
                                    <Icon iconName="users" styles={{ root: { marginBottom: 20 } }}/>
                                    <b>
                                        <FormattedMessage id="UserListNewNoUsersInGroupMessage" defaultMessage="No users have been added to the group." description="Message shown on the users page when no users have yet been added to the group attached to the lab."/>
                                    </b>
                                    <div style={{ maxWidth: 610, marginBottom: 25, textAlign: 'center' }}>
                                        <FormattedMessage id="UserListNewNoUsersInGroupDescription" defaultMessage='No users have been added to the group. This lab has been set to sync from "{groupName}", which contains no users. Once users have been added to the group, they will be added to the lab when it syncs.' description="Explanation shown on the users page when no users have yet been added to the attached group. {groupName} is the name of the users aadGroup being inserted and should not be localized." values={{
            groupName: <span style={{ fontWeight: 600 }}>{groupName}</span>,
        }}/>
                                    </div>
                                    <div style={{ maxWidth: 610, marginBottom: 25, textAlign: 'center' }}>
                                        <FormattedMessage id="UserListEmptyListNextUserGroupSyncTime" defaultMessage="The next automatic sync will occur at {nextGroupSyncTime}, or you can sync manually" description="Info message about automatic sync and next sync time. {nextGroupSyncTime} is the time of the next group sync being inserted and should not be localized." values={{
            nextGroupSyncTime: getNextGroupSyncTime(ianaTimezone, locale),
        }}/>
                                    </div>
                                    <div>
                                        <DefaultButton text={msg(messages.syncNowCommand)} style={{
            marginRight: '4px',
        }} onClick={() => this.setState({ showSyncUsersDialog: true })} disabled={!!shouldDisableLabUpdate}/>
                                    </div>
                                </Stack>) : isRestricted ? (<Stack id="user-list-body" horizontalAlign="center" styles={{ root: { paddingTop: '27px' } }} verticalFill>
                                    <Icon iconName="users" styles={{ root: { marginBottom: 20 } }}/>
                                    <b>
                                        <FormattedMessage id="UserListNewNoUsersMessage" defaultMessage="No users have been added." description="Message shown on the users page when no users have yet been added."/>
                                    </b>
                                    <div style={{ maxWidth: 610, marginBottom: 25, textAlign: 'center' }}>
                                        <FormattedMessage id="UserListNewNoUsersDescription" defaultMessage="The user list for a lab can be managed manually, or set to sync from an existing group within your organization. <b>This choice will determine how users are managed in this lab.</b>" description="Message shown on the users page when no users have yet been added." values={{
            b: (chunks) => <b>{chunks}</b>,
        }}/>
                                    </div>
                                    <div>
                                        <DefaultButton text={msg(messages.addUsersManuallyCommand)} style={{
            marginRight: '4px',
        }} onClick={() => this.setState({
            showAddUsersFlyout: true,
            addUserEmails: '',
            hasEmptyCsvFileError: false,
        })} disabled={!!shouldDisableLabUpdate}/>
                                        <DefaultButton text={msg(messages.syncFromGroupCommand)} style={{
            marginLeft: '4px',
        }} onClick={() => this.setState({
            showSyncFromGroupFlyout: true,
        })} disabled={!!shouldDisableLabUpdate}/>
                                    </div>
                                </Stack>) : (<Stack id="user-list-body" horizontalAlign="center" styles={{ root: { paddingTop: '27px' } }} verticalFill>
                                    <Icon iconName="users" styles={{ root: { marginBottom: 20 } }}/>
                                    <b>
                                        <FormattedMessage id="UserListNewNoUsersUnrestrictedMessage" defaultMessage="No users have joined the lab." description="Message shown on the users page when no users have joined the unrestricted lab."/>
                                    </b>
                                    <div style={{ maxWidth: 610, marginBottom: 25, textAlign: 'center' }}>
                                        <FormattedMessage id="UserListNewNoUsersUnrestrictedDescription" defaultMessage="No users have joined the lab. This lab has been set to unrestricted access, so any user with the registration link can join. You can also add specific users by selecing “<b>add users</b>” above." description="Message shown on the users page when no users have joined the unrestricted lab." values={{
            b: (chunks) => <b>{chunks}</b>,
        }}/>
                                    </div>
                                </Stack>)) : (<ScrollablePane id="user-list-body" className="vertical-scrollable-content" scrollbarVisibility={ScrollbarVisibility.auto}>
                                <div id="user-details-list-container">
                                    {isReadOnly && <>{detailsList}</>}
                                    {!isReadOnly && (<MarqueeSelection selection={this._selection}>
                                            {detailsList}
                                        </MarqueeSelection>)}
                                </div>
                            </ScrollablePane>)}
                    </div>
                </div>
                {showOpenAccessDialog && (<OpenAccessDialog onSubmit={() => this._onAccessDialogSubmit()} isSubmitting={isUpdatingLab} onDismiss={() => this.setState({ showOpenAccessDialog: false })}/>)}
                {showRegistrationLinkDialog && (<RegistrationLinkDialog registrationLink={registrationLink} onDismiss={() => this.setState({ showRegistrationLinkDialog: false })}/>)}
                {showDeleteUsersDialog && (<DeleteUsersDialog numberOfUsers={deleteSelectTarget.length} isSubmitting={isDeleting} onSubmit={() => this._onDeleteDialogSubmit()} onDismiss={() => this.setState({ showDeleteUsersDialog: false })}/>)}
                {showAddUsersFlyout && (<AddUsersFlyoutNew isSubmitting={isAdding} onSubmit={(emails) => this._onAddUsersFlyoutSubmit(emails)} onDismiss={() => this.setState({ showAddUsersFlyout: false })}/>)}
                {showSyncFromGroupFlyout && (<SyncFromGroupFlyout groups={groups} isLoadingGroups={isLoadingGroups} isSubmitting={isUpdatingLab} onSubmit={(group, isDeletionExternallyManaged) => this._onSyncFromGroupFlyoutSubmit(group, isDeletionExternallyManaged)} onDismiss={() => this.setState({ showSyncFromGroupFlyout: false })} searchGroups={(filterText) => this._onSyncFromGroupFlyoutSearchForGroups(filterText)}/>)}
                {showInviteAllDialog && (<InviteAllDialog inviteCount={users.count((u) => u.canInvite || u.canInviteAgain)} invitedCount={users.count((u) => u.canInviteAgain)} onDismiss={this._onDismissInviteAllDialog} onSubmit={this._onSubmitInviteAllDialog}/>)}
                {showInvitationFlyout && (<InvitationFlyout numberOfUsers={inviteIds.length} isSubmitting={isInviting} onSubmit={(extraMessage) => this._onInvitationFlyoutSubmit(extraMessage)} onDismiss={() => this.setState({ showInvitationFlyout: false })}/>)}
                {showSyncUsersDialog && (<SyncUsersDialog groupName={groupName} onSubmit={() => this._onSyncUsersDialogSubmit()} onDismiss={() => this.setState({ showSyncUsersDialog: false })}/>)}
                {showQuotaFlyout && (<QuotaFlyout minQuota={0} maxQuota={Constants.MaxLabQuota} quota={labQuota} isSubmitting={isUpdatingLab} onSubmit={(quota) => this._onQuotaFlyoutSubmit(quota)} onDismiss={() => this.setState({ showQuotaFlyout: false })}/>)}
                {showUserQuotaFlyout && (<UserQuotaFlyout users={this._getSelectedUsers()} labQuota={labQuota} isSubmitting={isUpdatingUsers} onSubmit={(quota) => this._onUserQuotaFlyoutSubmit(quota)} onDismiss={() => this.setState({ showUserQuotaFlyout: false })}/>)}
            </>);
    }
    _buildUserStatusMap() {
        const msg = this.props.intl.formatMessage;
        const deleting = msg(commonMessages.deletingState);
        const notRegistered = msg(messages.notRegistered);
        const registered = msg(messages.registered);
        const unknown = msg(commonMessages.unknownState);
        const failed = msg(commonMessages.failedState);
        const map = {};
        map[UserStatus.Deleting] = deleting;
        map[UserStatus.NotRegistered] = notRegistered;
        map[UserStatus.Registered] = registered;
        map[UserStatus.Unknown] = unknown;
        map[UserStatus.Failed] = failed;
        return map;
    }
    _buildInviteStatusMap() {
        const msg = this.props.intl.formatMessage;
        const notSent = msg(messages.notSent);
        const sending = msg(messages.sending);
        const sendingFailed = msg(messages.sendFailed);
        const sent = msg(messages.sent);
        const unknown = msg(commonMessages.unknownState);
        const map = {};
        map[InviteStatus.NotSent] = notSent;
        map[InviteStatus.Sending] = sending;
        map[InviteStatus.SendingFailed] = sendingFailed;
        map[InviteStatus.Sent] = sent;
        map[InviteStatus.Unknown] = unknown;
        return map;
    }
    _onRenderRow(props, defaultRender) {
        const { detailsRowStyles } = getCustomTheme();
        return (<div className="user-list__row" onMouseEnter={() => this.setState({ hoveredId: props.item.key })} onMouseLeave={() => this.setState({ hoveredId: '' })}>
                {defaultRender({
            ...props,
            // Define row style in DetailsList does not work, so applying style when rendering row.
            styles: detailsRowStyles,
        })}
            </div>);
    }
    _renderListHeader(props, defaultRender) {
        const { detailsHeaderStyles } = getCustomTheme();
        return (<Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
                {defaultRender({
            ...props,
            onRenderColumnHeaderTooltip: (tooltipHostProps) => (<TooltipHost {...tooltipHostProps}/>),
            // Define row style in DetailsList does not work, so applying style when rendering list header.
            styles: detailsHeaderStyles,
        })}
            </Sticky>);
    }
    // Renders default command bar shown when there is no selection
    _renderDefaultCommandBar() {
        const { intl, userListViewModel, navigateRoute } = this.props;
        const { isTeamsOrLmsIntegrationEnabled } = userListViewModel;
        const { isRestricted, isReadOnly, isGroupSyncModeEnabled, isLabTeamsOrLmsConnected, lab, isSyncing, isUpdatingLab, shouldDisableLabUpdate, users, groupId, } = userListViewModel;
        const { showFilter } = this.state;
        const msg = intl.formatMessage;
        const { primaryCommandBarStyles, primaryCommandButtonStyles } = getCustomTheme();
        let items = [];
        let farItems = [];
        const exportItem = {
            key: 'export',
            text: msg(commonMessages.exportToCsv),
            iconProps: { iconName: 'Export' },
            onClick: this._onExportClicked,
        };
        const filterItem = {
            key: 'filter',
            text: msg(messages.filterUsers),
            ariaLabel: msg(messages.filterUsers),
            'aria-expanded': showFilter ? true : false,
            iconProps: { iconName: 'Filter' },
            iconOnly: true,
            onClick: this._onOpenFilter,
        };
        const syncItem = {
            key: 'sync',
            text: msg(commonMessages.sync),
            iconProps: { iconName: 'Refresh' },
            onClick: () => this.setState({ showSyncUsersDialog: true }),
            disabled: isSyncing || !!shouldDisableLabUpdate,
        };
        const inviteAllItem = {
            key: 'inviteAll',
            text: msg(messages.inviteAllCommand),
            iconProps: { iconName: 'Mail' },
            onClick: this._onInviteAllClicked,
            disabled: isSyncing || users.filter((o) => o.canInvite).size === 0,
        };
        const registrationLinkItem = {
            key: 'registrationLink',
            text: msg(messages.registrationLinkCommand),
            iconProps: { iconName: 'ChatInviteFriend' },
            onClick: () => this.setState({ showRegistrationLinkDialog: true }),
        };
        const separatorItem = {
            key: 'separator',
            onRender: () => <div className="command-bar-separator"/>,
        };
        const accessModeItem = {
            key: 'accessMode',
            onRender: () => (<ToggleToolTip label={msg(messages.restrictAccess)} inlineLabel={true} content={msg(messages.restrictAccessTooltip)} styles={{
                root: { marginTop: 7, marginRight: 7, cursor: 'pointer' },
                label: { fontWeight: 'normal', whiteSpace: 'nowrap' },
            }} disabled={isUpdatingLab || !!shouldDisableLabUpdate} checked={isRestricted} onChange={(_, checked) => this._onAccessModeToggle(checked)}/>),
        };
        const addUsersMenuItem = {
            key: 'addUsersMenu',
            text: msg(messages.addUsersMenu),
            iconProps: { iconName: 'PeopleAdd' },
            disabled: !!shouldDisableLabUpdate,
            onClick: () => this.setState({
                showAddUsersFlyout: true,
                addUserEmails: '',
                hasEmptyCsvFileError: false,
            }),
        };
        if (isTeamsOrLmsIntegrationEnabled) {
            if (lab && lab.id) {
                farItems = getNavigationItems(intl, lab.id, LabNavKeys.Users, navigateRoute);
            }
        }
        else if (users.size > 0) {
            farItems.push(filterItem);
        }
        if (isReadOnly) {
            items = [exportItem];
            if (isTeamsOrLmsIntegrationEnabled) {
                items.push(separatorItem);
                items.push(filterItem);
            }
            return <CommandBar styles={primaryCommandBarStyles} items={items} farItems={farItems}/>;
        }
        const overflowButtonProps = { title: msg(commonMessages.moreActionsMenu) };
        const overflowItems = [];
        const quotaItem = {
            key: 'quota',
            onRender: () => (<CommandButtonToolTip content={msg(messages.quotaTooltip)} disabled={isUpdatingLab || !!shouldDisableLabUpdate} labelText={this._getQuotaCommandName()} iconProps={{ iconName: 'Timer' }} onClick={this._onLabQuotaClicked} styles={primaryCommandButtonStyles}/>),
        };
        const isEmptyUsersInGroupSyncedLab = !!groupId && users.size === 0 && !isSyncing;
        if (!isLabTeamsOrLmsConnected) {
            if (!isGroupSyncModeEnabled && !isRestricted) {
                items = [accessModeItem, quotaItem, separatorItem, addUsersMenuItem, registrationLinkItem];
            }
            else if (!isGroupSyncModeEnabled && isRestricted && users.size > 0) {
                items = [
                    accessModeItem,
                    quotaItem,
                    separatorItem,
                    addUsersMenuItem,
                    inviteAllItem,
                    registrationLinkItem,
                ];
            }
            else if (!isGroupSyncModeEnabled && isRestricted && users.size === 0) {
                items = [accessModeItem, quotaItem];
            }
            else {
                items = [quotaItem, separatorItem, syncItem, inviteAllItem, registrationLinkItem];
            }
        }
        else {
            if (!isTeamsOrLmsIntegrationEnabled) {
                items = [quotaItem, syncItem, separatorItem, registrationLinkItem];
            }
            else {
                if (isEmptyUsersInGroupSyncedLab) {
                    items = [];
                }
                else {
                    items = [quotaItem, syncItem, exportItem, separatorItem, filterItem];
                }
            }
        }
        if (!isTeamsOrLmsIntegrationEnabled) {
            if (users.size > 0) {
                overflowItems.push(exportItem);
            }
        }
        // Don't render the command bar when we have an empty group
        const shouldNotRenderCommandBar = !isTeamsOrLmsIntegrationEnabled && isEmptyUsersInGroupSyncedLab;
        return (!shouldNotRenderCommandBar && (<CommandBar styles={primaryCommandBarStyles} overflowButtonProps={overflowButtonProps} items={items} overflowItems={overflowItems} farItems={farItems}/>));
    }
    // Renders command bar shown when there is non=empty selection
    _renderSelectionCommandBar() {
        const { intl: { formatMessage: msg }, userListViewModel, } = this.props;
        const { isReadOnly, isGroupSyncModeEnabled, isLabTeamsOrLmsConnected, isUpdatingLab, shouldDisableLabUpdate, } = userListViewModel;
        const { deleteSelectTarget: deleteTarget, inviteSelectTarget: inviteTarget, quotaSelectTarget: quotaTarget, } = this.state;
        const { secondaryCommandBarStyles } = getCustomTheme();
        const items = [];
        if (!isReadOnly) {
            if (!isGroupSyncModeEnabled) {
                if (deleteTarget.length > 0) {
                    items.push({
                        key: 'delete',
                        disabled: isUpdatingLab || !!shouldDisableLabUpdate,
                        text: msg(commonMessages.delete),
                        iconProps: { iconName: 'Delete' },
                        onClick: this._onDeleteUserClicked,
                    });
                }
            }
            if (!isLabTeamsOrLmsConnected && inviteTarget.length > 0) {
                items.push({
                    key: 'invite',
                    text: msg(messages.inviteCommand),
                    iconProps: { iconName: 'Mail' },
                    onClick: this._onInviteClicked,
                });
            }
            if (quotaTarget.length > 0) {
                items.push({
                    key: 'userQuota',
                    disabled: isUpdatingLab || !!shouldDisableLabUpdate,
                    text: msg(messages.adjustQuota),
                    iconProps: { iconName: 'Timer' },
                    onClick: this._onUserQuotaClicked,
                });
            }
        }
        return (<CommandBar styles={secondaryCommandBarStyles} items={items} farItems={[
            {
                key: 'clearSelection',
                text: msg(commonMessages.clearSelectionCommand),
                ariaLabel: msg(commonMessages.clearSelectionCommand),
                iconProps: { iconName: 'Cancel' },
                iconOnly: true,
                onClick: () => this._selection.setAllSelected(false),
            },
            {
                key: 'selectedCount',
                onRender: () => (<Stack verticalFill verticalAlign="center">
                                <Stack.Item>
                                    {msg(commonMessages.selectedFormat, {
                    numberOfObjects: this.state.selectedCount,
                })}
                                </Stack.Item>
                            </Stack>),
            },
        ]}/>);
    }
    // Renders filter bar with text search box and state filters
    _renderFilterBar(items, userStatusMap, inviteStatusMap) {
        const { filterText, filterUserStatus, filterInviteStatus } = this.state;
        const { intl: { formatMessage: msg }, userListViewModel, } = this.props;
        const { isLabTeamsOrLmsConnected } = userListViewModel;
        const { secondaryCommandBarStyles, filterBarStyles } = getCustomTheme();
        const availableUserStatuses = items
            .map((item) => item.status)
            .filter((value, index, self) => self.indexOf(value) === index);
        const availableInviteStatus = items
            .map((item) => item.inviteStatus)
            .filter((value, index, self) => self.indexOf(value) === index);
        const availableUserStatusOptions = availableUserStatuses
            .map((status) => ({
            key: status,
            text: userStatusMap[status],
            canCheck: true,
            checked: filterUserStatus.indexOf(status) > -1,
            className: 'user-list__filter-button',
            onClick: (ev) => {
                ev && ev.preventDefault();
                this._onFilterUserStatusChange(status);
            },
        }))
            .sortBy((option) => option.text)
            .toArray();
        const availableInviteStatusOptions = availableInviteStatus
            .map((status) => ({
            key: status,
            text: inviteStatusMap[status],
            canCheck: true,
            checked: filterInviteStatus.indexOf(status) > -1,
            className: 'user-list__filter-button',
            onClick: (ev) => {
                ev && ev.preventDefault();
                this._onFilterInviteStatusChange(status);
            },
        }))
            .sortBy((option) => option.text)
            .toArray();
        if (availableUserStatusOptions.length > 0) {
            availableUserStatusOptions.push({
                key: 'clearDivider',
                itemType: ContextualMenuItemType.Divider,
            });
            availableUserStatusOptions.push({
                key: 'clear',
                text: msg(commonMessages.clearFilters),
                canCheck: false,
                checked: false,
                className: 'user-list__filter-clear-button',
                iconProps: {
                    iconName: 'Cancel',
                    styles: {
                        root: {
                            color: 'rgb(50, 49, 48)',
                            fontSize: '14px',
                        },
                    },
                },
                onClick: (ev) => {
                    ev && ev.preventDefault();
                    this._onClearUserStatusFilter();
                },
            });
        }
        if (availableInviteStatusOptions.length > 0) {
            availableInviteStatusOptions.push({
                key: 'clearDivider',
                itemType: ContextualMenuItemType.Divider,
            });
            availableInviteStatusOptions.push({
                key: 'clear',
                text: msg(commonMessages.clearFilters),
                canCheck: false,
                checked: false,
                className: 'user-list__filter-clear-button',
                iconProps: {
                    iconName: 'Cancel',
                    styles: {
                        root: {
                            color: 'rgb(50, 49, 48)',
                            fontSize: '14px',
                        },
                    },
                },
                onClick: (ev) => {
                    ev && ev.preventDefault();
                    this._onClearInviteStatusFilter();
                },
            });
        }
        const farItems = [
            {
                key: 'filterByStatus',
                text: filterUserStatus.length > 0
                    ? msg(messages.statusFilterSelected, {
                        selected: this._getFilterText(filterUserStatus, userStatusMap),
                    })
                    : msg(messages.statusColumnHeader),
                subMenuProps: {
                    items: availableUserStatusOptions,
                },
            },
        ];
        if (!isLabTeamsOrLmsConnected) {
            farItems.push({
                key: 'filterByInvitation',
                text: filterInviteStatus.length > 0
                    ? msg(messages.invitationFilterSelected, {
                        selected: this._getFilterText(filterInviteStatus, inviteStatusMap),
                    })
                    : msg(messages.invitationColumnHeader),
                subMenuProps: {
                    items: availableInviteStatusOptions,
                },
            });
        }
        farItems.push({
            key: 'closeFilter',
            text: msg(commonMessages.closeFilterCommand),
            ariaLabel: msg(commonMessages.closeFilterCommand),
            iconProps: { iconName: 'Cancel' },
            iconOnly: true,
            onClick: () => this._onCloseFilter(),
        });
        return (<CommandBar styles={_.merge({}, secondaryCommandBarStyles, filterBarStyles)} items={[
            {
                key: 'filterByName',
                onRender: () => (<SearchBox ariaLabel={msg(messages.textFilterPlaceholder)} styles={{
                    root: {
                        backgroundColor: 'transparent',
                        border: 'none',
                        marginTop: 8,
                        width: '300px',
                    },
                }} value={filterText} spellCheck={false} placeholder={msg(messages.textFilterPlaceholder)} iconProps={{ iconName: 'Filter' }} onChange={(_, newValue) => this._onFilterTextChange(newValue || '')}/>),
            },
        ]} farItems={farItems}/>);
    }
    _getFilterText(items, stateMap) {
        if (items.length < 1) {
            return '';
        }
        const filterStrings = items.map((item) => stateMap[item]).sort();
        return filterStrings.join(', ');
    }
    // Column definitions for the details list view control
    _createColumns(sortByColumn, isSortDescending, userStatusMap, inviteStatusMap, locale, shouldHideEmailColumn) {
        const { intl, userListViewModel } = this.props;
        const { isLabTeamsOrLmsConnected } = userListViewModel;
        const msg = intl.formatMessage;
        const nameColumnLabel = msg(commonMessages.nameColumnHeader);
        const emailColumnLabel = msg(messages.emailColumnHeader);
        const statusColumnLabel = msg(messages.statusColumnHeader);
        const inviteStatusColumnLabel = msg(messages.invitationColumnHeader);
        const usageColumnLabel = msg(commonMessages.usageColumnHeader);
        const columns = [
            {
                key: 'nameSortValue',
                name: nameColumnLabel,
                minWidth: 210,
                maxWidth: 350,
                isRowHeader: true,
                isResizable: true,
                isSorted: sortByColumn === 'nameSortValue',
                isSortedDescending: sortByColumn === 'nameSortValue' ? isSortDescending : false,
                sortAscendingAriaLabel: msg(commonMessages.sortedAtoZ),
                sortDescendingAriaLabel: msg(commonMessages.sortedZtoA),
                onColumnClick: (_, column) => this._onColumnClick(column),
                onRender: (item) => this._onRenderNameColumn(item),
                className: 'user-list__name-column',
            },
        ];
        const email = {
            key: 'email',
            name: emailColumnLabel,
            minWidth: 150,
            maxWidth: 250,
            isResizable: true,
            isCollapsable: true,
            isSorted: sortByColumn === 'email',
            isSortedDescending: sortByColumn === 'email' ? isSortDescending : false,
            sortAscendingAriaLabel: msg(commonMessages.sortedAtoZ),
            sortDescendingAriaLabel: msg(commonMessages.sortedZtoA),
            onColumnClick: (_, column) => this._onColumnClick(column),
            onRender: (item) => (<Stack verticalFill verticalAlign="center">
                    <Stack.Item>{item.email}</Stack.Item>
                </Stack>),
        };
        if (!shouldHideEmailColumn) {
            columns.push(email);
        }
        const status = {
            key: 'status',
            name: statusColumnLabel,
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: true,
            isSorted: sortByColumn === 'status',
            isSortedDescending: sortByColumn === 'status' ? isSortDescending : false,
            sortAscendingAriaLabel: msg(commonMessages.sortedAtoZ),
            sortDescendingAriaLabel: msg(commonMessages.sortedZtoA),
            onColumnClick: (_, column) => this._onColumnClick(column),
            onRender: (item) => {
                const { status } = item;
                let statusText;
                switch (status) {
                    case UserStatus.Deleting:
                        statusText = msg(commonMessages.deleting);
                        break;
                    default:
                        statusText = userStatusMap[status];
                        break;
                }
                return (<Stack verticalFill verticalAlign="center">
                        <Stack.Item>
                            <span>{statusText}</span>
                        </Stack.Item>
                    </Stack>);
            },
        };
        columns.push(status);
        const inviteStatus = {
            key: 'inviteStatus',
            name: inviteStatusColumnLabel,
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            isCollapsible: true,
            isSorted: sortByColumn === 'inviteStatus',
            isSortedDescending: sortByColumn === 'inviteStatus' ? isSortDescending : false,
            sortAscendingAriaLabel: msg(commonMessages.sortedAtoZ),
            sortDescendingAriaLabel: msg(commonMessages.sortedZtoA),
            onColumnClick: (_, column) => this._onColumnClick(column),
            onRender: (item) => {
                const { inviteStatus, latestRegistrationLinkEmailSent } = item;
                let statusText;
                switch (inviteStatus) {
                    case InviteStatus.Sending:
                        statusText = msg(messages.sendingProgress);
                        break;
                    case InviteStatus.Sent:
                        statusText = msg(messages.sentFormat, {
                            date: intl.formatDate(latestRegistrationLinkEmailSent),
                        });
                        break;
                    case InviteStatus.SendingFailed:
                        statusText = msg(messages.sendFailedFormat, {
                            date: intl.formatDate(latestRegistrationLinkEmailSent),
                        });
                    default:
                        statusText = inviteStatusMap[inviteStatus];
                        break;
                }
                return (<Stack verticalFill verticalAlign="center">
                        <Stack.Item>
                            <span>{statusText}</span>
                        </Stack.Item>
                    </Stack>);
            },
        };
        const currentUsage = {
            key: 'currentUsage',
            name: usageColumnLabel,
            minWidth: 200,
            maxWidth: 250,
            isResizable: true,
            isCollapsible: true,
            isSorted: sortByColumn === 'currentUsage',
            isSortedDescending: sortByColumn === 'currentUsage' ? isSortDescending : false,
            sortAscendingAriaLabel: msg(commonMessages.sortedSmallerToLarger),
            sortDescendingAriaLabel: msg(commonMessages.sortedLargerToSmaller),
            onColumnClick: (_, column) => this._onColumnClick(column),
            onRender: (item) => {
                const isHoveredOrSelected = this.state.hoveredId === item.id || this.state.selectedIds.indexOf(item.id) > -1;
                return (<Stack horizontal verticalAlign="center" tokens={{ childrenGap: '5px' }}>
                        <Stack.Item align="start">
                            <VmUsageProgressColumn currentUsage={item.currentUsage} usageQuota={item.totalQuota} isHoveredOrSelected={isHoveredOrSelected}/>
                        </Stack.Item>
                        <Stack.Item align="end">
                            <VmUsageColumn locale={locale} currentUsage={item.currentUsage} usageQuota={item.totalQuota}/>
                        </Stack.Item>
                    </Stack>);
            },
        };
        if (!isLabTeamsOrLmsConnected) {
            columns.push(inviteStatus);
        }
        columns.push(currentUsage);
        return columns;
    }
    // Renders Upload CSV button in command bar, including file upload control
    _onRenderUploadCsv(dismissMenu) {
        const { intl: { formatMessage: msg }, } = this.props;
        const { primaryCommandButtonStyles } = getCustomTheme();
        return (<FileUploadButton content={msg(messages.uploadCsvTooltip)} directionalHint={DirectionalHint.rightCenter} accept=".csv" labelText={msg(messages.uploadCsvCommand)} styles={primaryCommandButtonStyles} onChange={(event) => {
            this._onCsvFileUpload(event);
            dismissMenu();
        }}/>);
    }
    // Parses CSV file content and opens Add Users Flyout with resulting list of emails
    _onCsvFileUpload(event) {
        const reader = new FileReader();
        reader.onerror = () => {
            // TODO: report error
        };
        reader.onloadend = (e) => {
            const emails = e.target.result
                .split(/\n|\r\n/)
                .map((line) => line
                .split(/\t|,/)
                .map((o) => o.trim())
                .find((o) => isValidEmail(o)))
                .filter((o) => !!o);
            if (emails.length > 0) {
                this.setState({
                    showAddUsersFlyout: true,
                    addUserEmails: emails.join('\n'),
                    hasEmptyCsvFileError: false,
                });
            }
            else {
                this.setState({
                    showAddUsersFlyout: true,
                    hasEmptyCsvFileError: true,
                });
            }
        };
        reader.readAsText(event.target.files[0]);
        event.target.value = '';
    }
    // Returns text for the Quota command bar button
    _getQuotaCommandName(hours) {
        const { intl, userListViewModel } = this.props;
        const { labQuota } = userListViewModel;
        const msg = intl.formatMessage;
        return labQuota === 0
            ? msg(messages.quotaCommandScheduleOnly)
            : msg(messages.quotaCommandHoursFormat, { numberOfHours: hours ?? labQuota });
    }
    _getSelectedUsers() {
        const { users } = this.props.userListViewModel;
        if (this.state.selectedIds.length == 0) {
            return [];
        }
        return users.filter((user) => this.state.selectedIds.indexOf(user.id) != -1).toArray();
    }
    // Handle toggling of 'Restricted access' toggle on command bar
    _onAccessModeToggle(value) {
        if (value) {
            this.props.updateAccessMode(true);
        }
        else {
            this.setState({ showOpenAccessDialog: true });
        }
    }
    // Handles submit operation for the Open Access dialog
    _onAccessDialogSubmit() {
        this.props.updateAccessMode(false);
    }
    // Handles submit operation for the Quota flyout
    _onQuotaFlyoutSubmit(quota) {
        this.props.updateQuota(quota);
        // Announcement for command bar
        const message = this._getQuotaCommandName(quota);
        const userQuotaUpdateAnnounce = <Announced message={message} aria-live="assertive"/>;
        this.announce = userQuotaUpdateAnnounce;
        /* If we wanted to announce each list row item, copy code from below and remove the target = key check */
    }
    // Handles submit operation for the User Quota flyout
    _onUserQuotaFlyoutSubmit(additionalQuota) {
        this.props.updateUserQuota(this.state.quotaSelectTarget, additionalQuota);
        // Announcement for each selected row in the user list table
        const announcedItems = [];
        const { intl, userListViewModel } = this.props;
        const { labQuota, locale, users } = userListViewModel;
        users.map((user) => {
            const { key, currentUsage } = user;
            this.state.quotaSelectTarget.forEach((target) => {
                if (target === key) {
                    const message = intl.formatMessage(commonMessages.vmUsageColumnProgressFormat, {
                        current: shortenNumber(currentUsage, locale, intl),
                        total: shortenNumber(labQuota + additionalQuota, locale, intl),
                    });
                    announcedItems.push(<Announced key={key} message={message} aria-live="assertive"/>);
                }
            });
        });
        this.announce = announcedItems;
    }
    // Handles submit operation for the Add Users flyout
    _onAddUsersFlyoutSubmit(emails) {
        this.props.addUsers(emails);
    }
    // Handles submit operation for the Sync From Group flyout
    _onSyncFromGroupFlyoutSubmit(group, isDeletionExternallyManaged) {
        this.props.syncFromGroup(group, isDeletionExternallyManaged);
    }
    // Handles submit operation for the Sync From Group flyout
    _onSyncFromGroupFlyoutSearchForGroups(filterText) {
        this.props.searchForGroups(filterText);
    }
    // Handles submit operation for the Send Invitation flyout
    _onInvitationFlyoutSubmit(extraMessage) {
        const { inviteUsers } = this.props;
        const { inviteIds } = this.state;
        inviteUsers(inviteIds, extraMessage);
    }
    // Handles submit operation for the Delete Users flyout
    _onDeleteDialogSubmit() {
        const { deleteUsers } = this.props;
        const { deleteSelectTarget } = this.state;
        deleteUsers(deleteSelectTarget);
    }
    _onOpenFilter() {
        this.setState({
            showFilter: true,
        });
    }
    _onCloseFilter() {
        this.setState({
            showFilter: false,
            filterText: '',
            filterInviteStatus: [],
            filterUserStatus: [],
        });
    }
    _onFilterTextChange(filterText) {
        this.setState({
            filterText,
        });
    }
    _onFilterUserStatusChange(userStatus) {
        const filterUserStatus = [...this.state.filterUserStatus];
        const index = filterUserStatus.indexOf(userStatus);
        if (index > -1) {
            filterUserStatus.splice(index, 1);
        }
        else {
            filterUserStatus.push(userStatus);
        }
        this.setState({
            filterUserStatus,
        });
    }
    _onFilterInviteStatusChange(inviteStatus) {
        const filterInviteStatus = [...this.state.filterInviteStatus];
        const index = filterInviteStatus.indexOf(inviteStatus);
        if (index > -1) {
            filterInviteStatus.splice(index, 1);
        }
        else {
            filterInviteStatus.push(inviteStatus);
        }
        this.setState({
            filterInviteStatus,
        });
    }
    _onClearUserStatusFilter() {
        this.setState({ filterUserStatus: [] });
    }
    _onClearInviteStatusFilter() {
        this.setState({ filterInviteStatus: [] });
    }
    _onDismissInviteAllDialog() {
        this.setState({ showInviteAllDialog: false });
    }
    _onSubmitInviteAllDialog(includeAlreadyInvited) {
        const { users } = this.props.userListViewModel;
        const inviteIds = includeAlreadyInvited
            ? users
                .filter((u) => u.canInvite || u.canInviteAgain)
                .map((u) => u.id)
                .toArray()
            : users
                .filter((u) => u.canInvite)
                .map((u) => u.id)
                .toArray();
        this.setState({ showInviteAllDialog: false, showInvitationFlyout: true, inviteIds });
    }
    _onSyncUsersDialogSubmit() {
        this.props.syncUsers();
        this.setState({ showSyncUsersDialog: false });
    }
    _onLabQuotaClicked() {
        this.setState({ showQuotaFlyout: true });
    }
    _onUserQuotaClicked() {
        this.setState({ showUserQuotaFlyout: true });
    }
    _onDeleteUserClicked() {
        this.setState({ showDeleteUsersDialog: true });
    }
    _onInviteClicked() {
        const { inviteSelectTarget: inviteIds } = this.state;
        this.setState({ showInvitationFlyout: true, inviteIds });
    }
    _onInviteAllClicked() {
        const { users } = this.props.userListViewModel;
        const shouldShowDialog = users.some((u) => u.inviteStatus === InviteStatus.Sent);
        if (shouldShowDialog) {
            this.setState({ showInviteAllDialog: true, inviteIds: [] });
        }
        else {
            const inviteIds = users
                .filter((u) => u.canInvite)
                .map((u) => u.id)
                .toArray();
            this.setState({ showInvitationFlyout: true, inviteIds });
        }
    }
    // Handles changes to column sort order for the list view
    _onColumnClick(column) {
        const sortByColumn = column.key || 'nameSortValue';
        const isNewColumn = this.state.sortByColumn !== sortByColumn;
        const isSortDescending = isNewColumn ? false : !this.state.isSortDescending;
        this.setState({ sortByColumn, isSortDescending });
    }
    // Renders Name cell of list view row including person icon, name and command bar
    _onRenderNameColumn(item) {
        const { intl, userListViewModel } = this.props;
        const { isReadOnly, isGroupSyncModeEnabled, isLabTeamsOrLmsConnected, shouldDisableLabUpdate, isUpdatingLab, } = userListViewModel;
        const { selectedIds, selectedCount } = this.state;
        const { formatMessage: msg } = intl;
        const styles = {
            root: {
                backgroundColor: 'inherit',
                borderStyle: 'none',
                height: '100%',
            },
        };
        const { canAddQuota, canDelete, canInvite, canInviteAgain, name, id } = item;
        const itemHasActions = !isReadOnly && (canAddQuota || canDelete || canInvite || canInviteAgain);
        const selected = selectedCount > 0 && selectedIds.indexOf(id) > -1;
        const items = [];
        if (canAddQuota) {
            items.push({
                key: 'userQuota',
                text: msg(messages.adjustQuota),
                iconProps: { iconName: 'Timer' },
                onClick: this._onUserQuotaClicked,
                disabled: isUpdatingLab || !!shouldDisableLabUpdate,
            });
        }
        if (!isGroupSyncModeEnabled && canDelete) {
            items.push({
                key: 'deleteUser',
                text: msg(commonMessages.delete),
                iconProps: { iconName: 'Delete' },
                onClick: this._onDeleteUserClicked,
                disabled: isUpdatingLab || !!shouldDisableLabUpdate,
            });
        }
        return (<Stack className="user-list__name-stack" horizontal horizontalAlign="start" verticalAlign="center">
                <Stack.Item className="user-list__name-persona-stack-item" align="start">
                    <PersonaCoin size={PersonaSize.size28} text={name}/>
                </Stack.Item>
                <Stack.Item className="user-list__name-text-stack-item">
                    <TooltipHost content={name}>
                        <Text block={true} nowrap={true}>
                            {name}
                        </Text>
                    </TooltipHost>
                </Stack.Item>
                <Stack.Item grow>
                    <div />
                </Stack.Item>
                {itemHasActions && (<>
                        {(canInvite || canInviteAgain) && !isLabTeamsOrLmsConnected && (<Stack.Item className="user-list__name-action-stack-item" align="end">
                                <TooltipHost directionalHint={DirectionalHint.topRightEdge} content={msg(messages.inviteCommand)}>
                                    <IconButton iconProps={{ iconName: 'Mail', styles: { root: { color: '#605E5C' } } }} onClick={this._onInviteClicked} styles={styles} ariaLabel={msg(messages.inviteCommand)} className={selected ? 'user-list__action-selected' : 'user-list__action-not-selected'}/>
                                </TooltipHost>
                            </Stack.Item>)}
                        {items.length > 0 && (<Stack.Item className="user-list__name-action-stack-item" align="end">
                                <TooltipHost directionalHint={DirectionalHint.topRightEdge} content={msg(messages.expandUserActionsTooltip)}>
                                    <IconButton iconProps={{ iconName: 'MoreVertical', styles: { root: { color: '#605E5C' } } }} menuIconProps={{
            // this is used to hide the chevron
            style: { display: 'none' },
        }} styles={styles} menuProps={{ items, isSubMenu: true }} ariaLabel={msg(messages.expandUserActionsTooltip)} className={selected ? 'user-list__action-selected' : 'user-list__action-not-selected'}/>
                                </TooltipHost>
                            </Stack.Item>)}
                    </>)}
            </Stack>);
    }
}
export const UserList = injectIntl(UserListInjected);
export default UserList;
