import { Stack, Link } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../../language/common-messages';
import './page-footer.css';
export const PageFooter = () => {
    const intl = useIntl();
    const year = new Date().getFullYear();
    return (<Stack id="page-footer-container" horizontalAlign="end" horizontal={true} role="contentinfo">
            <Stack.Item>
                <div className="page-footer__items">© {year} Microsoft</div>
            </Stack.Item>
            <Stack.Item>
                <div className="page-footer__items">
                    <Link className="page-footer__links" href="https://www.microsoft.com/servicesagreement" target="_blank" styles={{
        root: {
            color: 'inherit',
            textDecoration: 'none',
        },
    }}>
                        {intl.formatMessage(commonMessages.termsOfUseLink)}
                    </Link>
                </div>
            </Stack.Item>
            <Stack.Item>
                <div className="page-footer__items">
                    <Link className="page-footer__links" href="https://go.microsoft.com/fwlink/?LinkId=521839" target="_blank" rel="noopener noreferrer" styles={{
        root: {
            color: 'inherit',
            textDecoration: 'none',
        },
    }}>
                        <FormattedMessage id="PrivacyAndCookiePolicyLink" defaultMessage="Privacy & cookies" description="Link to our privacy and cookie policies"/>
                    </Link>
                </div>
            </Stack.Item>
        </Stack>);
};
export default PageFooter;
