import { ConnectionType } from '../../data/models/environment-common';
import { commonMessages } from '../../language/common-messages';
export const getConnectionTypes = (rdpAuthority, rdpInBrowserUrl, sshAuthority, sshInBrowserUrl, isWindows, isLinuxRdpEnabled) => {
    // TODO: Remove the extra isWindows and isLinuxEnabled checks once connection info is cached
    const connectionTypes = [];
    if (rdpInBrowserUrl) {
        connectionTypes.push(ConnectionType.RdpInBrowser);
    }
    if (sshInBrowserUrl) {
        connectionTypes.push(ConnectionType.SshInBrowser);
    }
    if (rdpAuthority || isWindows === true || isLinuxRdpEnabled === true) {
        connectionTypes.push(ConnectionType.Rdp);
    }
    if (sshAuthority || isWindows === false) {
        connectionTypes.push(ConnectionType.Ssh);
    }
    return connectionTypes;
};
export const getVNextConnectionTypes = (rdpAuthority, rdpInBrowserUrl, sshAuthority, sshInBrowserUrl) => {
    const connectionTypes = [];
    if (rdpInBrowserUrl) {
        connectionTypes.push(ConnectionType.RdpInBrowser);
    }
    if (sshInBrowserUrl) {
        connectionTypes.push(ConnectionType.SshInBrowser);
    }
    if (rdpAuthority) {
        connectionTypes.push(ConnectionType.Rdp);
    }
    if (sshAuthority) {
        connectionTypes.push(ConnectionType.Ssh);
    }
    return connectionTypes;
};
export const getConnectionTypeStringMap = (intl, isTeamsIntegrationEnabled) => {
    const msg = intl.formatMessage;
    const vmConnectSshTitle = msg(commonMessages.vmSshConnectTitle);
    const vmConnectSshBrowserTitle = msg(commonMessages.vmSshBrowserConnectTitle);
    const vmConnectRdpTitle = isTeamsIntegrationEnabled
        ? msg(commonMessages.vmDownloadRdpFileTitle)
        : msg(commonMessages.vmRdpConnectTitle);
    const vmConnectRdpBrowserTitle = msg(commonMessages.vmRdpBrowserConnectTitle);
    const map = {};
    map[ConnectionType.Rdp] = vmConnectRdpTitle;
    map[ConnectionType.RdpInBrowser] = vmConnectRdpBrowserTitle;
    map[ConnectionType.Ssh] = vmConnectSshTitle;
    map[ConnectionType.SshInBrowser] = vmConnectSshBrowserTitle;
    return map;
};
export const getConnectionContextMenuItems = (canConnect, item, connectionTypes, onConnect, intl, isTeamsIntegrationEnabled) => {
    const connectionTypeMap = getConnectionTypeStringMap(intl, isTeamsIntegrationEnabled);
    const items = [];
    connectionTypes.map((type) => {
        items.push({
            key: type,
            text: connectionTypeMap[type],
            disabled: !canConnect,
            onClick: () => {
                onConnect(item, type);
            },
        });
    });
    return items;
};
