import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { duration } from 'moment';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getImageIdleConfig, idleConfigToLabParameters } from '../../common/shutdown-policy/selectors';
import { toEnableState } from '../../data/helpers/enum-helpers';
import { ConnectionType, OperatingSystem } from '../../data/models/environment-common';
import { listGalleryImages } from '../../redux/actions/gallery-image/gallery-image-action-creators';
import { createLab } from '../../redux/actions/lab/lab-action-creators';
import { listSharedImages } from '../../redux/actions/shared-image/shared-image-action-creators';
import { trackEvent } from '../../utils/telemetry/telemetry-channel';
import NewLabFlyout from './create-lab-flyout';
import { getCreateLabContainerModel, getDefaultConnectionTypes, getDefaultImage, getVCurrentDefaultSize, getVCurrentSizeData, isUbuntuImage, isVCurrentGpuSize, getVCurrentGeographyData, getLabAccountDefaultGeoName, getVCurrentDefaultGeography, } from './create-lab-selectors';
class CreateLabFlyoutContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._loadLabAccountImages = () => {
            const { labParentId } = this.props;
            this.props.listGalleryImages(labParentId);
            this.props.listSharedImages(labParentId);
        };
        this._onFormStateChange = (newState) => {
            const { formState: oldState } = this.state;
            const formState = { ...oldState, ...newState };
            this.setState({ formState });
        };
        this._updateFormDataOnImageLoaded = (shouldInitializeName) => {
            const { createLabViewModel, defaultLabName } = this.props;
            const { name } = this.state.formState;
            const { username, password, nonAdminUsername, nonAdminPassword, isNonAdminEnabled } = this.state.formState;
            const { images, labAccount, pricingAndAvailabilityData, coreQuotaData, defaultIdleConfig, isBastionEnabled, isV2BastionEnabled, } = createLabViewModel;
            const image = getDefaultImage(images);
            const idleConfig = image ? getImageIdleConfig(defaultIdleConfig, image) : defaultIdleConfig;
            const sizeData = image && pricingAndAvailabilityData
                ? getVCurrentSizeData(pricingAndAvailabilityData, image.isWindows ? OperatingSystem.Windows : OperatingSystem.Linux)
                : undefined;
            const defaultGeoName = getLabAccountDefaultGeoName(labAccount);
            const size = sizeData ? getVCurrentDefaultSize(sizeData, coreQuotaData) : undefined;
            const geoData = size && sizeData ? getVCurrentGeographyData(sizeData, size) : undefined;
            const geography = geoData ? getVCurrentDefaultGeography(geoData, defaultGeoName) : undefined;
            const connectionTypes = image
                ? getDefaultConnectionTypes(image.isWindows, labAccount, undefined, isBastionEnabled, isV2BastionEnabled)
                : [];
            this._onFormStateChange({
                name: !!shouldInitializeName ? defaultLabName : name,
                image,
                geography,
                size,
                connectionTypes,
                idleConfig,
                useSharedPassword: true,
                isNonAdminEnabled,
                username,
                password,
                nonAdminUsername,
                nonAdminPassword,
                isTemplateVmEnabled: true,
            });
        };
        this._initializeFormData = () => {
            this._updateFormDataOnImageLoaded(true);
            this.setState({ formStateInitialized: true });
        };
        this.state = {
            formStateInitialized: false,
            formState: {
                name: '',
                image: undefined,
                size: undefined,
                geography: undefined,
                isRdpEnabled: false,
                installGpuDriverEnabled: true,
                customizeTemplate: true,
                username: '',
                password: '',
                useSharedPassword: false,
                nonAdminUsername: '',
                nonAdminPassword: '',
                isNonAdminEnabled: false,
                quota: 10,
                connectionTypes: [],
                idleConfig: undefined,
                isTemplateVmEnabled: true,
                labCapacity: 1,
            },
        };
    }
    componentDidMount() {
        trackEvent('CREATE_LAB_STARTED');
        const { createLabViewModel } = this.props;
        const { isLoading, hasLoadError } = createLabViewModel;
        if (this.props.labParentId) {
            this._loadLabAccountImages();
            if (!isLoading && !hasLoadError) {
                this._initializeFormData();
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { isLoading, hasLoadError } = this.props.createLabViewModel;
        if (prevProps.labParentId !== this.props.labParentId) {
            this._loadLabAccountImages();
        }
        if (prevProps.createLabViewModel.isLoading && !isLoading && !hasLoadError) {
            this._initializeFormData();
        }
        if (prevProps.createLabViewModel.isSubmitting != this.props.createLabViewModel.isSubmitting &&
            !this.props.createLabViewModel.isSubmitting) {
            if (this.props.hasLabErrors) {
                this.props.onDismiss();
            }
        }
    }
    render() {
        const { onDismiss, createLabViewModel } = this.props;
        const { isLoading } = createLabViewModel;
        const { formStateInitialized, formState } = this.state;
        return (<NewLabFlyout createLabViewModel={createLabViewModel} isLoading={isLoading || !formStateInitialized} formState={formState} onFormStateChange={this._onFormStateChange} onSubmit={() => this._onSubmit()} onDismiss={onDismiss} onSelectLabPlan={() => {
            return; // no-op, this is only needed in vnext
        }}/>);
    }
    _onSubmit() {
        const { onCreatedRoute, createLabViewModel, aadGroupId } = this.props;
        const { isBastionEnabled } = createLabViewModel;
        const { formState } = this.state;
        const { name, size, geography, image, isRdpEnabled, installGpuDriverEnabled, customizeTemplate, useSharedPassword, username, password, quota, idleConfig, connectionTypes, } = formState;
        const vCurrentSize = size;
        const vCurrentGeography = geography;
        this.props.createLab(this.props.labParentId, {
            name: name,
            labParameters: {
                ...idleConfigToLabParameters(idleConfig),
                vmSize: vCurrentSize.sizeName,
                geography: vCurrentGeography.geographyName,
                galleryImageResourceId: (!image.isCustomImage && image.id) || undefined,
                sharedImageResourceId: (image.isCustomImage && image.id) || undefined,
                templateVmState: customizeTemplate ? Ml.EnableState.Enabled : Ml.EnableState.Disabled,
                linuxRdpState: toEnableState(isRdpEnabled),
                installGpuDriverEnabled: isVCurrentGpuSize(vCurrentSize)
                    ? toEnableState(installGpuDriverEnabled)
                    : undefined,
                sharedPasswordState: isUbuntuImage(image) || !customizeTemplate
                    ? Ml.EnableState.Disabled
                    : toEnableState(useSharedPassword),
                username,
                password,
                userQuota: duration(quota, 'h'),
                connectivityPolicy: isBastionEnabled
                    ? {
                        rdpEnabled: toEnableState(connectionTypes.includes(ConnectionType.Rdp)),
                        rdpInBrowserEnabled: toEnableState(connectionTypes.includes(ConnectionType.RdpInBrowser)),
                        sshEnabled: toEnableState(connectionTypes.includes(ConnectionType.Ssh)),
                        sshInBrowserEnabled: toEnableState(connectionTypes.includes(ConnectionType.SshInBrowser)),
                    }
                    : {
                        rdpEnabled: toEnableState(isRdpEnabled || image.isWindows),
                        sshEnabled: toEnableState(!image.isWindows),
                    },
                aadGroupId,
            },
        }, onCreatedRoute);
    }
}
const mapStateToProps = (state) => {
    return getCreateLabContainerModel(state);
};
const mapDispatchToProps = {
    listGalleryImages,
    listSharedImages,
    createLab,
};
export const CreateLabFlyoutContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateLabFlyoutContainerInjected));
export default CreateLabFlyoutContainer;
