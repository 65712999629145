import * as React from 'react';
import { TextField, Stack, DirectionalHint } from '@fluentui/react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { isPasswordReserved, isPasswordLengthIncorrect, isPasswordComplex } from './password-validator';
import './password-field.css';
import InfoTip from '../info-tip';
const messages = defineMessages({
    passwordShowHideAriaLabel: {
        id: 'PasswordShowHideAriaLabel',
        defaultMessage: 'Show your password',
        description: 'Text for a button that toggles showing a password in a password input field',
    },
    passwordFieldLabel: {
        id: 'PasswordFieldLabel',
        defaultMessage: 'Password',
        description: 'The label displayed for the password field.',
    },
    passwordRequiredError: {
        id: 'PasswordRequiredError',
        defaultMessage: 'Password is required',
        description: 'The error shown to indicate that a password is required.',
    },
    passwordRestrictedError: {
        id: 'PasswordRestrictedError',
        defaultMessage: 'Commonly used passwords are not allowed',
        description: 'The error shown to indicate that a password contains restricted words.',
    },
    passwordLengthInvalidError: {
        id: 'PasswordLengthInvalidError',
        defaultMessage: 'Passwords must be between {minLength} and {maxLength} characters long',
        description: 'The error shown to indicate that a passwords length is invalid with {minLength} being the minimum password length and {maxLength} the maximum password length.',
    },
    passwordComplexityInvalidError: {
        id: 'PasswordComplexityInvalidError',
        defaultMessage: 'Password must include 3 of the following: a number, uppercase character, lowercase character, or a special character',
        description: 'The error shown to indicate that a passwords complexity is invalid.',
    },
});
function getPasswordValidationErrorMessage(password, props) {
    const { minLength, maxLength } = props;
    let errorMessage = '';
    if (!password) {
        errorMessage = props.intl.formatMessage(messages.passwordRequiredError);
    }
    else if (isPasswordReserved(password)) {
        errorMessage = props.intl.formatMessage(messages.passwordRestrictedError);
    }
    else if (isPasswordLengthIncorrect(password, minLength, maxLength)) {
        errorMessage = props.intl.formatMessage(messages.passwordLengthInvalidError, {
            minLength,
            maxLength,
        });
    }
    else if (!isPasswordComplex(password)) {
        errorMessage = props.intl.formatMessage(messages.passwordComplexityInvalidError);
    }
    return errorMessage;
}
const PasswordFieldInjected = (props) => {
    const ariaLabel = props.intl.formatMessage(messages.passwordShowHideAriaLabel);
    const type = 'password';
    const resolvedProps = {
        ...props,
        type,
        canRevealPassword: true,
        revealPasswordAriaLabel: ariaLabel,
        styles: {
            suffix: {
                backgroundColor: 'transparent',
                display: 'none',
            },
            errorMessage: {
                paddingTop: '5px',
            },
            revealButton: {
                color: 'black',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: 'transparent',
                },
            },
            ...props.styles,
        },
    };
    const calloutContent = (<span>
            <FormattedMessage id="PasswordLabelInfoMessage" defaultMessage="This  password can't be too similar to the existing password in the virtual machine image, or lab creation will fail." description="Password label info message callout for specialized images when non-admin is enabled."/>
        </span>);
    // set a default label if one was not provided or if infotip is enabled.
    if (!props.infoTipEnabled) {
        resolvedProps.label = props.customLabel
            ? props.customLabel
            : props.intl.formatMessage(messages.passwordFieldLabel);
    }
    else {
        // this does not mean that the password is not required,
        // it just removes the * from the textfield as it is added to the label with the infotip.
        resolvedProps.required = false;
    }
    // setup our password validation
    resolvedProps.onGetErrorMessage = (value) => {
        const errorMessage = getPasswordValidationErrorMessage(value, props);
        // trigger a validation change
        const isValid = !errorMessage;
        if (props.isValid !== isValid) {
            props.onValidationChange(isValid);
        }
        return errorMessage;
    };
    return (<Stack>
            {props.infoTipEnabled && (<Stack.Item styles={{
        root: { padding: '5px 0' },
    }}>
                    <span style={{ fontWeight: 600 }}>
                        <FormattedMessage id="CustomPasswordLabel" defaultMessage="{passwordLabel}" description="Custom password label for when infotip is enabled." values={{ passwordLabel: props.customLabel }}/>
                    </span>
                    <span>
                        <InfoTip content={calloutContent} calloutProps={{ gapSpace: 10 }} directionalHint={DirectionalHint.rightTopEdge} iconStyles={{
        root: {
            marginLeft: '0px',
            paddingLeft: '4px',
            fontSize: '12px !important',
        },
    }}/>
                    </span>
                    <span style={{ color: 'rgb(164, 38, 44)' }}> *</span>
                </Stack.Item>)}
            <Stack.Item>
                <TextField {...resolvedProps} aria-required={resolvedProps.required || undefined}/>
            </Stack.Item>
        </Stack>);
};
export const PasswordField = injectIntl(PasswordFieldInjected);
export default PasswordField;
