import { DocumentCard, DocumentCardTitle, Dropdown, Link, Panel, PanelType, Stack, } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { commonMessages } from '../../language/common-messages';
import { Constants } from '../../utils/constants';
import Linkify from '../linkify';
import { getCustomTheme } from '../themes/selectors';
import './help-flyout.css';
const messages = defineMessages({
    labDropDownLabel: {
        id: 'HelpFlyoutLabDropDownLabel',
        defaultMessage: 'Lab',
        description: 'Label for the lab drop-down on help flyout.',
    },
    labAccountSupportCardTitle: {
        id: 'LabAccountSupportCardTitle',
        defaultMessage: 'Lab account support',
        description: 'Title for the card of lab account support',
    },
    needHelpCardTitle: {
        id: 'NeedHelpCardTitle',
        defaultMessage: 'Need help?',
        description: 'Title for the card of need help',
    },
    aboutServicesCardTitle: {
        id: 'AboutAzureLabServicesCardTitle',
        defaultMessage: 'About Azure Lab Services',
        description: 'Title for the card of about azure lab services',
    },
    labSupportCardTitle: {
        id: 'LabSupportCardTitle',
        defaultMessage: 'Lab support',
        description: 'Title for the card of lab support',
    },
});
const documentCardStyle = {
    root: {
        fontSize: '14px',
        maxWidth: 500,
        marginBottom: '24px',
        paddingBottom: '25px',
        boxShadow: ' 0px 0.6px 1.8px rgba(0, 0, 0, 0.108), 0px 3.2px 7.2px rgba(0, 0, 0, 0.132)',
    },
};
const documentCardTitleStyle = {
    root: { fontWeight: 600, lineHeight: '20px', height: '20px', padding: '22px', fontSize: '14px' },
};
const stackItemStyle = {
    root: {
        overflowWrap: 'break-word',
        marginLeft: '22px',
        marginBottom: '10px',
        marginRight: '22px',
    },
};
const SupportSection = (props) => {
    const { url, email, phone, instructions, isLabAccount } = props;
    const intl = useIntl();
    const title = !!isLabAccount
        ? intl.formatMessage(messages.labAccountSupportCardTitle)
        : intl.formatMessage(messages.labSupportCardTitle);
    return (<DocumentCard styles={documentCardStyle} aria-label={title}>
            <DocumentCardTitle title={title} styles={documentCardTitleStyle}/>

            <Stack tokens={{ childrenGap: 15 }}>
                {(url || email || phone) && (<Stack.Item>
                        {url && (<div className="help-flyout__card-content">
                                <FormattedMessage id="SupportUrlLink" defaultMessage="<Link>View support website</Link>" description="Link to support web site. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href={url} target="_blank">
                                                {chunks}
                                            </Link>),
    }}/>
                            </div>)}
                        {email && (<div className="help-flyout__card-content">
                                <FormattedMessage id="SupportEmailLink" defaultMessage="<Link>Email support</Link>" description="Link to support email. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => <Link href={`mailto:${email}`}>{chunks}</Link>,
    }}/>
                            </div>)}
                        {phone && (<div className="help-flyout__card-content">
                                <FormattedMessage id="SupportPhoneLabel" defaultMessage="Phone support: {phoneNumber}" description="Text showing support phone number. {phoneNumber} is the phone number." values={{ phoneNumber: phone }}/>
                            </div>)}
                    </Stack.Item>)}
                {instructions && (<Stack.Item styles={stackItemStyle}>
                        <Linkify>{instructions}</Linkify>
                    </Stack.Item>)}
                {!url && !email && !phone && !instructions && (<Stack.Item styles={stackItemStyle}>
                        <FormattedMessage id="ContactAdminMessage" defaultMessage="Please contact your lab administrator if you need help with your virtual machine." description="The generic message shown when support info is not provided"/>
                    </Stack.Item>)}
            </Stack>
        </DocumentCard>);
};
const NeedHelpSection = () => {
    const intl = useIntl();
    return (<DocumentCard styles={documentCardStyle} aria-label={intl.formatMessage(messages.needHelpCardTitle)}>
            <DocumentCardTitle title={intl.formatMessage(messages.needHelpCardTitle)} styles={documentCardTitleStyle}/>
            <div className="help-flyout__card-content">
                <FormattedMessage id="GettingStartedLink" defaultMessage="<Link>Getting started</Link>" description="Link to getting started help information. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlabs-getstarted" target="_blank">
                                {chunks}
                            </Link>),
    }}/>
            </div>
            <div className="help-flyout__card-content">
                <FormattedMessage id="ServiceStatusLink" defaultMessage="<Link>Service status</Link>" description="Link to view Azure service status. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlabs-status" target="_blank">
                                {chunks}
                            </Link>),
    }}/>
            </div>
            <div className="help-flyout__card-content">
                <FormattedMessage id="AskAQuestionLink" defaultMessage="<Link>Ask a question</Link>" description="Link to question and answer platform. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlabs-answers" target="_blank">
                                {chunks}
                            </Link>),
    }}/>
            </div>
            <div className="help-flyout__card-content">
                <FormattedMessage id="ReportAProblemLink" defaultMessage="<Link>Report a problem</Link>" description="Link to report a problem with Azure Lab Services. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlabs-support" target="_blank">
                                {chunks}
                            </Link>),
    }}/>
            </div>
            <div className="help-flyout__card-content">
                <FormattedMessage id="MakeASuggestionLink" defaultMessage="<Link>Make a suggestion</Link>" description="Link to make a suggestion. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlabs-suggest" target="_blank">
                                {chunks}
                            </Link>),
    }}/>
            </div>
        </DocumentCard>);
};
const NewsAndUpdateSection = () => {
    const intl = useIntl();
    return (<DocumentCard styles={documentCardStyle} aria-label={intl.formatMessage(messages.aboutServicesCardTitle)}>
            <DocumentCardTitle title={intl.formatMessage(messages.aboutServicesCardTitle)} styles={documentCardTitleStyle}/>
            <div className="help-flyout__card-content">
                <FormattedMessage id="NewsAndUpdatesLink" defaultMessage="<Link>News and updates</Link>" description="Link to learn news and updates of azure lab services. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlabs-blog" target="_blank">
                                {chunks}
                            </Link>),
    }}/>
            </div>
        </DocumentCard>);
};
const PrivacyAndTerms = (props) => {
    const intl = useIntl();
    const isGermanLocale = props.locale.toLowerCase().startsWith('de');
    const isItalianLocale = props.locale.toLowerCase().startsWith('it');
    return (<span className="help-flyout__privacy-and-terms-of-use">
            <Link href="https://go.microsoft.com/fwlink/?LinkId=521839" target="_blank">
                {intl.formatMessage(commonMessages.privacyLink)}
            </Link>
            &nbsp;|&nbsp;
            <Link href="https://aka.ms/azlabs-termsofuse" target="_blank">
                {intl.formatMessage(commonMessages.termsOfUseLink)}
            </Link>
            {isGermanLocale && (<>
                    &nbsp;|&nbsp;
                    <Link href="https://aka.ms/impressum_de" target="_blank">
                        
                        Impressum
                    </Link>
                </>)}
            {isItalianLocale && (<>
                    &nbsp;|&nbsp;
                    <Link href="https://www.microsoft.com/it-it/accessibility/declarations" target="_blank">
                        
                        Dichiarazione di accessibilità
                    </Link>
                </>)}
        </span>);
};
export const HelpFlyout = (props) => {
    const { sections, defaultSection, onDismiss, isLabAccount, isStudentVmList, locale } = props;
    const intl = useIntl();
    const msg = intl.formatMessage;
    let showDropdown = false;
    let options = undefined;
    if (sections) {
        options = sections
            .map((value, key) => ({
            key,
            id: key,
            text: key,
            data: value,
        }))
            .toList()
            .toArray();
        showDropdown = options.some((o) => !!o.id);
    }
    const [selectedSection, setSelectedSection] = React.useState(defaultSection ?? (options ? options[0].id : undefined));
    const currentSection = !!sections && selectedSection !== undefined ? sections.get(selectedSection) : undefined;
    return (<Panel type={PanelType.custom} customWidth={`${Constants.DefaultFlyoutWidth}px`} isOpen={true} isLightDismiss={true} onDismiss={onDismiss} headerText={msg(commonMessages.help)} closeButtonAriaLabel={msg(commonMessages.close)} styles={{
        main: { backgroundColor: '#FAF9F8' },
        ...getCustomTheme().panelStyles,
    }}>
            {showDropdown && (<Dropdown label={msg(messages.labDropDownLabel)} ariaLabel={msg(messages.labDropDownLabel)} options={options} selectedKey={selectedSection} onChange={(_, option) => setSelectedSection(option.id)} styles={{ root: { paddingBottom: 20 } }}/>)}
            {currentSection && <SupportSection {...currentSection} isLabAccount={isLabAccount}/>}
            {!isStudentVmList && (<div>
                    <NeedHelpSection />
                    <NewsAndUpdateSection />
                </div>)}
            <PrivacyAndTerms locale={locale ?? ''}/>
        </Panel>);
};
export default HelpFlyout;
