import { Icon, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import commonMessages from '../../language/common-messages';
import PageHeaderFilteredDropdown from './dropdown/page-header-filtered-dropdown';
import './lab-parent-picker.css';
const messages = defineMessages({
    ariaLabel: {
        id: 'LabAccountPickerAriaLabel',
        defaultMessage: 'Select an item',
        description: 'Aria label for the combo box control',
    },
    noLabAccountsInFilterText: {
        id: 'LabAccountMenuNoAccountsMatchFilterText',
        defaultMessage: 'No items found',
        description: 'Message to inform user that no items in a listbox have been found matching the filter criteria',
    },
});
export const Selected = (props) => {
    const { item } = props;
    return (<Stack className="lab-parent-picker__item-container" horizontal={true} verticalAlign="center" horizontalAlign="start" tokens={{ childrenGap: '4px' }} grow styles={{ root: { marginRight: '5px' } }}>
            <Stack.Item>
                <Icon iconName="EngineeringGroup" styles={{ root: { margin: '5px 4px 5px 5px' } }}/>
            </Stack.Item>

            <Stack.Item>
                {item.name}
            </Stack.Item>
        </Stack>);
};
export const Item = (props) => {
    const { item } = props;
    return (<Stack className="lab-parent-picker__item-container" horizontal={true} verticalAlign="center" horizontalAlign="start" tokens={{ childrenGap: '4px', padding: '0 0 0 10px' }} grow>
            <Stack.Item>
                <Icon iconName="EngineeringGroup"/>
            </Stack.Item>

            <Stack.Item>
                {item.name}
            </Stack.Item>
        </Stack>);
};
export const Placeholder = () => {
    return (<Stack className="lab-parent-picker__item-container" horizontal={true} verticalAlign="center" horizontalAlign="start" grow styles={{ root: { marginRight: '5px' } }}>
            <Stack.Item>
                <span className="lab-parent-picker__item-container">
                    <FormattedMessage id="LabAccountPickerPlaceholder" defaultMessage="Select an item" description="The placeholder message displayed in the combo box when no item is selected."/>
                </span>
            </Stack.Item>
        </Stack>);
};
const NoResultsInjected = (props) => {
    const message = props.intl.formatMessage(messages.noLabAccountsInFilterText);
    return (<Stack horizontal={true} verticalAlign="center" horizontalAlign="start" tokens={{ childrenGap: '4px', padding: '0 0 0 10px' }} grow>
            <Stack.Item>
                <Icon iconName="SearchIssue" title={message}/>
            </Stack.Item>
            <Stack.Item>{message}</Stack.Item>
        </Stack>);
};
export const NoResults = injectIntl(NoResultsInjected);
function convertItemToOption(item) {
    return {
        value: item.id,
    };
}
function mapItemsToOptions(items) {
    return items.map((a) => convertItemToOption(a)).toJS();
}
function filterDropdownOptions(filter, items) {
    const filteredItems = items.filter((o) => o.name.toLowerCase().indexOf(filter.toLowerCase()) > -1);
    return mapItemsToOptions(filteredItems);
}
function getItemFromOption(option, items) {
    return items.find((a) => a.id === option.value);
}
const LabParentPickerInjected = (props) => {
    const { isLoading, isTeamsOrLmsIntegrationEnabled, labParentItems, selectedItem, onChange, styles, intl } = props;
    const placeholder = intl.formatMessage(commonMessages.searchLabAccountText);
    const ariaLabel = intl.formatMessage(messages.ariaLabel);
    const options = isLoading ? [] : mapItemsToOptions(labParentItems);
    const selectedOption = !!selectedItem ? convertItemToOption(selectedItem) : undefined;
    return (<PageHeaderFilteredDropdown items={options} styles={styles} ariaLabel={ariaLabel} filterPlaceholder={placeholder} filterAriaLabel={placeholder} selected={selectedOption} isLoading={isLoading} minExpandedWidth={'300px'} onChange={(value) => {
        onChange(getItemFromOption(value, labParentItems));
    }} onRenderSelected={(selected) => (<Selected item={getItemFromOption(selected, labParentItems)} isTeamsOrLmsIntegrationEnabled={isTeamsOrLmsIntegrationEnabled}/>)} onRenderItem={(item) => (<Item item={getItemFromOption(item, labParentItems)} isTeamsOrLmsIntegrationEnabled={isTeamsOrLmsIntegrationEnabled}/>)} onFilterItems={(filter) => filterDropdownOptions(filter, labParentItems)} onRenderPlaceholder={() => <Placeholder />} onRenderNoResults={() => <NoResults />}/>);
};
export const LabParentPicker = injectIntl(LabParentPickerInjected);
