import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { FailureOperation } from '../data/ml-client-error';
import { MessageBar, MessageBarType } from '@fluentui/react';
import commonMessages from '../language/common-messages';
import { ResourceId } from '../utils/resource-id';
const messages = defineMessages({
    linkLmsError: {
        id: 'LinkLmsError',
        defaultMessage: 'An error occured when linking {labPlanName} to LMS.',
        description: 'Message to indicate the error when linking a lab plan to LMS.',
    },
    unlinkLmsError: {
        id: 'UnlinkLmsError',
        defaultMessage: 'An error occured when unlinking {labPlanName} from LMS.',
        description: 'Message to indicate the error when unlinking a lab plan from LMS.',
    },
    linkLmsAllError: {
        id: 'LinkLmsAllError',
        defaultMessage: 'An error occured when linking one or more lab plans to LMS.',
        description: 'Message to indicate the error when linking a lab plan to LMS.',
    },
    unlinkLmsAllError: {
        id: 'UnlinkLmsAllError',
        defaultMessage: 'An error occured when unlinking one or more lab plans from LMS.',
        description: 'Message to indicate the error when unlinking a lab plan from LMS.',
    },
});
export const LinkedLmsUpdateErrors = (props) => {
    const { clearError } = props;
    const { formatMessage: msg } = useIntl();
    let { errors } = props;
    errors = errors.filter((error) => error.failureOperation === FailureOperation.LinkLabPlan ||
        error.failureOperation === FailureOperation.UnlinkLabPlan);
    return (<>
            {errors.size > 0 &&
        errors
            .map((error) => {
            let message = '';
            const resourceId = new ResourceId(error.id, true);
            if (!resourceId) {
                message =
                    error.failureOperation === FailureOperation.LinkLabPlan
                        ? msg(messages.linkLmsAllError)
                        : msg(messages.unlinkLmsAllError);
            }
            else {
                const labPlanName = resourceId.name;
                message =
                    error.failureOperation === FailureOperation.LinkLabPlan
                        ? msg(messages.linkLmsError, { labPlanName })
                        : msg(messages.unlinkLmsError, { labPlanName });
            }
            return (<div key={error.id}>
                                <MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearError(error.id)}>
                                    {message}
                                </MessageBar>
                            </div>);
        })
            .toList()}
        </>);
};
export default LinkedLmsUpdateErrors;
