import { trackTrace, trackException } from './telemetry/telemetry-channel';
import { detectInPrivate } from './detect-incognito';
import { MlClientError, FailureOperation } from '../data/ml-client-error';
import { browserName } from './browser';
import ErrorCode from '../common/error-codes';
export const StorageType = {
    localStorage: 'localStorage',
    sessionStorage: 'sessionStorage',
};
const DomExceptionSecurityError = 'SecurityError';
const DomExceptionLegacySecurityError = 'SECURITY_ERR';
let isInitialized = false;
let storageType = StorageType.sessionStorage;
async function deriveStorageType() {
    try {
        const isPrivate = await detectInPrivate();
        // use this switch to tweak specific storage settings by browser as
        // well as whether the user is running in private or not
        switch (browserName) {
            case 'edge':
                storageType = StorageType.localStorage;
                break;
            default:
                storageType = isPrivate ? StorageType.sessionStorage : StorageType.localStorage;
                break;
        }
        trackTrace(`Storage: isPrivate = ${isPrivate}, browserName = ${browserName}, storageType = ${storageType}`);
    }
    catch (err) {
        trackTrace(`Storage: failed to derive a storage type. Falling back to ${storageType}.`);
        trackException(err);
    }
}
export async function init() {
    if (!isInitialized) {
        await deriveStorageType();
    }
    isInitialized = true;
}
export function getStorageType() {
    if (!isInitialized) {
        throw new MlClientError('Storage was used before it was initialized.', '', FailureOperation.StorageInit);
    }
    return storageType;
}
function isDomExceptionSecurityError(errorName) {
    return errorName === DomExceptionSecurityError || errorName === DomExceptionLegacySecurityError;
}
export function setItem(key, value, shouldThrowError) {
    if (!isInitialized) {
        throw new MlClientError('Storage was used before it was initialized.', '', FailureOperation.StorageInit);
    }
    try {
        storageType === StorageType.sessionStorage
            ? sessionStorage.setItem(key, value)
            : localStorage.setItem(key, value);
    }
    catch (err) {
        trackException(err);
        trackTrace(`Storage: setItem - Failed to persist data for key = ${key}, value = ${value}`);
        if (shouldThrowError && err instanceof DOMException) {
            if (isDomExceptionSecurityError(err.name)) {
                throw new MlClientError(err.message, '', FailureOperation.AccessWebStorage, ErrorCode.CookiesAndDataBlocked);
            }
            else {
                throw new MlClientError(err.message, '', FailureOperation.AccessWebStorage, ErrorCode.GenericError);
            }
        }
    }
}
export function getItem(key) {
    if (!isInitialized) {
        throw new MlClientError('Storage was used before it was initialized.', '', FailureOperation.StorageInit);
    }
    try {
        return storageType === StorageType.sessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key);
    }
    catch (err) {
        trackException(err);
        trackTrace(`Storage: getItem - Failed to get data for key = ${key}`);
    }
    return null;
}
export function removeItem(key) {
    if (!isInitialized) {
        throw new MlClientError('Storage was used before it was initialized.', '', FailureOperation.StorageInit);
    }
    try {
        storageType === StorageType.sessionStorage ? sessionStorage.removeItem(key) : localStorage.removeItem(key);
    }
    catch (err) {
        trackException(err);
        trackTrace(`Storage: removeItem - Failed to remove data for key = ${key}`);
    }
}
export function clear() {
    if (!isInitialized) {
        throw new MlClientError('Storage was used before it was initialized.', '', FailureOperation.StorageInit);
    }
    try {
        sessionStorage.clear();
    }
    catch (err) {
        trackException(err);
        trackTrace('Storage: clear - Failed to clear sessionStorage items');
    }
    try {
        localStorage.clear();
    }
    catch (err) {
        trackException(err);
        trackTrace('Storage: clear - Failed to clear localStorage items');
    }
}
export const Storage = {
    init,
    getStorageType,
    setItem,
    getItem,
    removeItem,
    clear,
};
export default Storage;
