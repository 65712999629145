import _ from 'lodash';
import { Icon, Spinner, SpinnerSize, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import InfoTip from '../common/info-tip';
import commonMessages from '../language/common-messages';
import templateMessages from './template-messages';
import { getLastPublishedDate, getLastPublishedTime, getLastUploadedRelativeTime, isUploadStateFailed, } from './template-selectors';
import SyncingSpinner from '../common/syncing-spinner';
import './template.css';
const messages = defineMessages({
    templateExportingImageStatus: {
        id: 'TemplateExportingImageStatus',
        defaultMessage: 'Exporting image...',
        description: 'Message displayed while image export is in progress',
    },
    templateCombinedExportingAndResettingStatus: {
        id: 'TemplateCombinedExportingAndResettingStatus',
        defaultMessage: 'Exporting image, resetting password...',
        description: 'Message displayed while image export and resetting password is simultaneously is in progress',
    },
    templatePublishingStatus: {
        id: 'TemplatePublishingStatus',
        defaultMessage: 'Publishing "{templateName}". This can take up to 1 hour...',
        description: 'Message displayed while publishing template is in progress.  {templateName} is the name of the template being published',
    },
    vNextTemplatePublishingStatus: {
        id: 'VNextTemplatePublishingStatus',
        defaultMessage: 'Publishing "{templateName}". This can take up to 20 minutes...',
        description: 'Message displayed while publishing template is in progress.  {templateName} is the name of the template being published',
    },
    templateScalingStatus: {
        id: 'TemplateScalingStatus',
        defaultMessage: 'Updating lab capacity. This can take up to 1 hour...',
        description: 'Message displayed while updating the lab capacity is in progress.',
    },
    vNextTemplateScalingStatus: {
        id: 'VNextTemplateScalingStatus',
        defaultMessage: 'Updating lab capacity. This can take up to 20 minutes...',
        description: 'Message displayed while updating the lab capacity is in progress.',
    },
});
const StatusSpinnerType = {
    Default: 'Default',
    Primary: 'Primary',
};
const StatusSpinner = (props) => {
    const { label, spinnerType } = props;
    return (<Spinner size={SpinnerSize.xSmall} labelPosition="right" ariaLabel={label} styles={{
        root: {
            paddingLeft: '5px',
        },
        label: {
            fontSize: '12px',
            marginLeft: '7px',
            marginBottom: '1px',
            color: spinnerType === StatusSpinnerType.Primary ? undefined : '#797775',
        },
    }} {...props}/>);
};
export const HasEditsStatus = () => {
    return (<Stack horizontal>
            <Stack.Item>
                <Icon iconName="Warning12" styles={{
        root: {
            fontSize: '12px',
            lineHeight: '12px',
            paddingTop: '5px',
            paddingRight: '5px',
            color: '#D83B01',
        },
    }}/>
            </Stack.Item>
            <Stack.Item>
                <span className="template__publish-status">
                    <FormattedMessage id="TemplateChangedSinceLastPublish" defaultMessage="Changed since last publish" description="Template status indicating the template has been edited since last publish"/>
                </span>
            </Stack.Item>
        </Stack>);
};
export const CombinedStatus = () => {
    const intl = useIntl();
    const combinedStatus = intl.formatMessage(messages.templateCombinedExportingAndResettingStatus);
    return <StatusSpinner label={combinedStatus} spinnerType={StatusSpinnerType.Default}/>;
};
export const PublishedStatus = (props) => {
    const { template, locale } = props;
    const lastPublishedDate = getLastPublishedDate(template, locale);
    const lastPublishedTime = getLastPublishedTime(template, locale);
    return (<span className="template__publish-status">
            <FormattedMessage id="TemplatePublishedStatus" defaultMessage="Published {lastPublishedDate} - {lastPublishedTime}" description="Text to indicate template has been published on date {lastPublishedDate} at time {lastPublishedTime}." values={{ lastPublishedDate, lastPublishedTime }}/>
        </span>);
};
export const UnpublishedStatus = () => {
    const intl = useIntl();
    const unpublished = intl.formatMessage(templateMessages.templateUnpublishedStatus);
    const unpublishedTooltip = intl.formatMessage(templateMessages.templateUnpublishedTooltipText);
    return (<Stack horizontal>
            <Stack.Item>
                <span className="template__publish-status">{unpublished}</span>
            </Stack.Item>
            <Stack.Item>
                <InfoTip content={unpublishedTooltip}/>
            </Stack.Item>
        </Stack>);
};
export const ScalingStatus = (props) => {
    const intl = useIntl();
    const { isVNext } = props.template;
    const { formatMessage: msg } = intl;
    const scaling = isVNext ? msg(messages.vNextTemplateScalingStatus) : msg(messages.templateScalingStatus);
    return (<StatusSpinner label={scaling} spinnerType={StatusSpinnerType.Primary} styles={{
        label: { textAlign: 'right', display: 'inline', verticalAlign: 'text-top' },
        circle: { display: 'inline-block' },
        root: { alignItems: 'flex-start', display: 'inline', textAlign: 'right' },
    }}/>);
};
export const SharedImageError = (props) => {
    const { sharedImage } = props;
    return (<div className="template__image-status template__image-status--failed">
            <Icon iconName="ErrorBadge" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '16px',
            paddingRight: '5px',
            position: 'relative',
            top: '3px',
            color: '#e53935',
        },
    }}/>
            {sharedImage ? (<FormattedMessage id="FailedToUploadImageMessage" defaultMessage="Saving virtual machine image ‘{imageName}’ failed. Please try again." description="Text to indicate saving the image failed.  {imageName} is the display name of the failed image." values={{ imageName: sharedImage.displayName }}/>) : (<FormattedMessage id="FailedToUploadImageMessageNoName" defaultMessage="Saving virtual machine image failed. Please try again." description="Text to indicate saving the image failed."/>)}
        </div>);
};
export const ExportingImageStatus = () => {
    const intl = useIntl();
    const exporting = intl.formatMessage(messages.templateExportingImageStatus);
    return <StatusSpinner label={exporting} spinnerType={StatusSpinnerType.Default}/>;
};
export const ExportedImageStatus = (props) => {
    const { template, locale } = props;
    const exportRelativeTime = getLastUploadedRelativeTime(template, locale);
    return (<span className="template__image-status">
            <FormattedMessage id="TemplateLastUploadedTime" defaultMessage="Image exported {exportRelativeTime}" description="Text to indicate image has been exported {lastExportedTime}, the parameter {lastExportedTime} being a (localized) time relative to the current time (for example one hour ago, one day ago, etc)." values={{ exportRelativeTime }}/>
        </span>);
};
export const ResettingPasswordStatus = () => {
    const intl = useIntl();
    const resetting = intl.formatMessage(commonMessages.resettingPassword);
    return <StatusSpinner label={resetting} spinnerType={StatusSpinnerType.Default}/>;
};
export const PublishingStatus = (props) => {
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const { template } = props;
    const isVNext = template.isVNext;
    const truncatedTitle = template.title.length > 40 ? _.truncate(template.title, { length: 40 }) : template.title; // eslint-disable-line security/detect-non-literal-fs-filename
    const publishing = isVNext
        ? msg(messages.vNextTemplatePublishingStatus, {
            templateName: truncatedTitle,
        })
        : msg(messages.templatePublishingStatus, {
            templateName: truncatedTitle,
        });
    return (<StatusSpinner styles={{
        label: { textAlign: 'right', display: 'inline', verticalAlign: 'text-top' },
        circle: { display: 'inline-block' },
        root: { alignItems: 'flex-start', display: 'inline' },
    }} label={publishing} spinnerType={StatusSpinnerType.Primary}/>);
};
export const PublishOperationStatus = (props) => {
    const { template, locale } = props;
    const { isPublishing, isScaling, isPublished, hasEdits } = template;
    if (isPublishing) {
        return <PublishingStatus template={template}/>;
    }
    if (isScaling) {
        return <ScalingStatus template={template}/>;
    }
    if (!isPublished) {
        return <UnpublishedStatus />;
    }
    if (hasEdits) {
        return <HasEditsStatus />;
    }
    return <PublishedStatus template={template} locale={locale}/>;
};
export const ImageStatus = (props) => {
    const { template } = props;
    const { isUploadingImage, isResettingPassword, sharedImage, lastUploaded } = template;
    if (isUploadingImage && isResettingPassword) {
        return <CombinedStatus />;
    }
    if (isUploadingImage) {
        return <ExportingImageStatus />;
    }
    if (isResettingPassword) {
        return <ResettingPasswordStatus />;
    }
    if (isUploadStateFailed(template)) {
        return <SharedImageError sharedImage={sharedImage}/>;
    }
    if (template && lastUploaded) {
        return <ExportedImageStatus {...props}/>;
    }
    return <></>;
};
export const OperationIndicator = (props) => {
    const { template, groupName } = props;
    return (<Stack>
            <Stack.Item align="end" styles={{ root: { maxWidth: '450px', textAlign: 'right' } }}>
                {!template.isSyncing && <PublishOperationStatus {...props}/>}
                {!!template.isSyncing && (<SyncingSpinner groupName={groupName} styles={{
        label: {
            textAlign: 'right',
            display: 'inline',
            verticalAlign: 'text-top',
            fontSize: '12px',
        },
        circle: { display: 'inline-block' },
        root: { alignItems: 'flex-start', display: 'inline', textAlign: 'right' },
    }}/>)}
            </Stack.Item>
            <Stack.Item align="end">
                <ImageStatus {...props}/>
            </Stack.Item>
        </Stack>);
};
export default OperationIndicator;
