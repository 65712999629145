import { IdentityActionType, } from './identity-actions';
import { IdentityErrorCode, graphScopes, armScopes, labsApiScopes } from '../../../data/models/identity';
import { defaultSettings } from '../../../data/models/user-settings';
export const signIn = (isInteractive = true, persistUrl = true) => ({
    type: IdentityActionType.SIGN_IN,
    isInteractive,
    persistUrl,
});
export const signInSuccess = (account) => ({
    type: IdentityActionType.SIGN_IN_SUCCESS,
    account,
});
export const signInRedirecting = () => ({
    type: IdentityActionType.SIGN_IN_REDIRECTING,
});
export const signInPoppingUp = () => ({
    type: IdentityActionType.SIGN_IN_POPPING_UP,
});
export const signInFailed = (errorCode) => ({
    type: IdentityActionType.SIGN_IN_FAILED,
    errorCode: errorCode || IdentityErrorCode.Unknown,
});
export const signInError = (errorCode, error) => ({
    type: IdentityActionType.SIGN_IN_ERROR,
    errorCode,
    error,
});
export const signOut = () => ({
    type: IdentityActionType.SIGN_OUT,
});
export const signOutError = (error) => ({
    type: IdentityActionType.SIGN_OUT_ERROR,
    errorCode: IdentityErrorCode.Unknown,
    error,
});
export const lmsCompleteSignOut = () => ({
    type: IdentityActionType.LMS_COMPLETE_SIGN_OUT,
});
export const resolveSignInReturnUrl = (returnUrl) => ({
    type: IdentityActionType.RESOLVE_SIGN_IN_RETURN_URL,
    returnUrl,
});
export const loadUserPhoto = () => ({
    type: IdentityActionType.LOAD_USER_PHOTO,
});
export const loadUserPhotoSuccess = (imageUrl) => ({
    type: IdentityActionType.LOAD_USER_PHOTO_SUCCESS,
    imageUrl,
});
export const loadUserPhotoFailed = () => ({
    type: IdentityActionType.LOAD_USER_PHOTO_FAILED,
});
export const loadUserPhotoError = (error) => ({
    type: IdentityActionType.LOAD_USER_PHOTO_ERROR,
    error,
});
export const loadUserSettings = (canRedirect = false) => ({
    type: IdentityActionType.LOAD_USER_SETTINGS,
    canRedirect,
});
export const loadUserSettingsSuccess = (settings) => ({
    type: IdentityActionType.LOAD_USER_SETTINGS_SUCCESS,
    settings,
});
export const loadUserSettingsError = (error, cachedSettings) => ({
    type: IdentityActionType.LOAD_USER_SETTINGS_ERROR,
    error,
    cachedSettings,
});
export const updateUserSettings = (settings) => ({
    type: IdentityActionType.UPDATE_USER_SETTINGS,
    settings,
});
export const toggleDisableHints = (disableHints) => updateUserSettings({ disableHints });
export const dismissLabListFirstRun = () => updateUserSettings({ dismissedLabListFirstRun: true });
export const dismissRetirementAlert = () => updateUserSettings({ showRetirementAlert: false });
export const resetShowRetirementAlert = () => updateUserSettings({ showRetirementAlert: true });
export const dismissCovidBanner = () => updateUserSettings({ hasDismissedCovidBanner: true });
export const dismissOldV1GpuLabBanner = () => updateUserSettings({ hasDismissedOldV1GpuLabBanner: true });
export const resetUserSettings = () => updateUserSettings(defaultSettings);
export const updateUserSettingsSuccess = () => ({
    type: IdentityActionType.UPDATE_USER_SETTINGS_SUCCESS,
});
export const updateUserSettingsError = (error) => ({
    type: IdentityActionType.UPDATE_USER_SETTINGS_ERROR,
    error,
});
export const updateUserSettingsExists = () => ({
    type: IdentityActionType.UPDATE_USER_SETTINGS_EXISTS,
});
export const updateUserSettingsCancelled = () => ({
    type: IdentityActionType.UPDATE_USER_SETTINGS_CANCELLED,
});
export const getAccessToken = (resolve, reject, scopes, tenantId, canRedirect = false, isErrorTerminal = true, returnUrl) => ({
    type: IdentityActionType.GET_ACCESS_TOKEN,
    scopes,
    isErrorTerminal,
    tenantId,
    resolve,
    reject,
    canRedirect,
    returnUrl,
});
export const getArmAccessToken = (resolve, reject, tenantId, canRedirect = false, returnUrl) => ({
    type: IdentityActionType.GET_ACCESS_TOKEN,
    scopes: armScopes,
    isErrorTerminal: true,
    tenantId,
    resolve,
    reject,
    canRedirect,
    returnUrl,
});
export const getGraphAccessToken = (resolve, reject, tenantId, canRedirect = false, returnUrl) => ({
    type: IdentityActionType.GET_ACCESS_TOKEN,
    scopes: graphScopes,
    isErrorTerminal: false,
    tenantId,
    resolve,
    reject,
    canRedirect,
    returnUrl,
});
export const getLabsApiAccessToken = (resolve, reject, tenantId, canRedirect = false, returnUrl) => ({
    type: IdentityActionType.GET_ACCESS_TOKEN,
    scopes: labsApiScopes,
    isErrorTerminal: true,
    tenantId,
    resolve,
    reject,
    canRedirect,
    returnUrl,
});
export const getAccessTokenSuccess = () => ({
    type: IdentityActionType.GET_ACCESS_TOKEN_SUCCESS,
});
export const getAccessTokenRedirecting = (scopes, tenantId) => ({
    type: IdentityActionType.GET_ACCESS_TOKEN_REDIRECTING,
    scopes,
    tenantId,
});
export const getAccessTokenFailed = (errorCode) => ({
    type: IdentityActionType.GET_ACCESS_TOKEN_FAILED,
    errorCode: errorCode || IdentityErrorCode.Unknown,
});
export const getAccessTokenError = (errorCode, error) => ({
    type: IdentityActionType.GET_ACCESS_TOKEN_ERROR,
    errorCode,
    error,
});
export const setTenantId = (tenantId) => ({
    type: IdentityActionType.SET_TENANT_ID,
    tenantId,
});
export const setLoginHint = (loginHint) => ({
    type: IdentityActionType.SET_LOGIN_HINT,
    loginHint,
});
export const requestUserConsent = (scopes) => ({
    type: IdentityActionType.REQUEST_USER_CONSENT,
    scopes,
});
export const requestUserConsentSuccess = (result) => ({
    type: IdentityActionType.REQUEST_USER_CONSENT_SUCCESS,
    result,
});
export const requestUserConsentFailed = () => ({
    type: IdentityActionType.REQUEST_USER_CONSENT_FAILED,
});
export const requestUserConsentError = (error) => ({
    type: IdentityActionType.REQUEST_USER_CONSENT_ERROR,
    error,
});
const actions = {
    signIn,
    signInSuccess,
    signInRedirecting,
    signInFailed,
    signInError,
    signOut,
    signOutError,
    resolveSignInReturnUrl,
    loadUserPhoto,
    loadUserPhotoSuccess,
    loadUserPhotoFailed,
    loadUserPhotoError,
    loadUserSettings,
    loadUserSettingsSuccess,
    loadUserSettingsError,
    updateUserSettings,
    toggleDisableHints,
    dismissCovidBanner,
    dismissLabListFirstRun,
    dismissRetirementAlert,
    resetShowRetirementAlert,
    resetUserSettings,
    updateUserSettingsSuccess,
    updateUserSettingsError,
    getAccessToken,
    getArmAccessToken,
    getGraphAccessToken,
    getAccessTokenSuccess,
    getAccessTokenRedirecting,
    getAccessTokenFailed,
    getAccessTokenError,
    setTenantId,
    setLoginHint,
    requestUserConsent,
    requestUserConsentSuccess,
    requestUserConsentFailed,
    requestUserConsentError,
    signInPoppingUp,
    lmsCompleteSignOut,
    updateUserSettingsCancelled,
};
export default actions;
