import { all, call, put, select } from 'redux-saga/effects';
import { MlClientError, FailureOperation } from '../../../data/ml-client-error';
import EnvironmentProvider from '../../../data/providers/environment-provider';
import { ResourceId } from '../../../utils/resource-id';
import { startEnvironmentsError, startEnvironmentsAccepted, } from '../../actions/environment/environment-action-creators';
import { EnvironmentActionType } from '../../actions/environment/environment-actions';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import VirtualMachineProvider from '../../../data/providers/vnext/arm-api/virtual-machine-provider';
import { labParentBasedTakeEvery } from '../../../redux/effects/lab-parent-based';
import { GetOperationsResult } from '../../actions/operations/operations-result-action-creators';
export async function callStartEnvironment(resourceId, accessToken, locale, language, errors) {
    try {
        await EnvironmentProvider.startEnvironment(resourceId, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.StartEnvironments, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* startEnvironments(action) {
    const { environmentIds } = action;
    try {
        const resourceIds = environmentIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        yield all(resourceIds.map((resourceId) => call(callStartEnvironment, resourceId, accessToken, locale, language, errors)));
        if (Object.keys(errors).length > 0) {
            yield put(startEnvironmentsError(errors));
        }
    }
    catch (e) {
        const errors = {};
        const error = new MlClientError(e ? e.message : undefined, 'all', FailureOperation.StartEnvironments, e ? e.code : undefined);
        errors[error.id] = error;
        yield put(startEnvironmentsError(errors));
    }
}
export async function callStartVNextVirtualMachine(resourceId, accessToken, locale, language, errors) {
    try {
        const startVmResult = await VirtualMachineProvider.startVirtualMachine(resourceId, accessToken, locale, language);
        startVmResult['ResourceId'] = resourceId;
        return startVmResult;
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.StartEnvironments, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* startVNextVirtualMachines(action) {
    const { environmentIds: virtualMachineIds } = action;
    try {
        const resourceIds = virtualMachineIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        const startVmsResult = yield all(resourceIds.map((resourceId) => call(callStartVNextVirtualMachine, resourceId, accessToken, locale, language, errors)));
        if (Object.keys(errors).length > 0) {
            yield put(startEnvironmentsError(errors));
        }
        yield put(startEnvironmentsAccepted(startVmsResult));
        yield put(GetOperationsResult(''));
    }
    catch (e) {
        const errors = {};
        const error = new MlClientError(e ? e.message : undefined, virtualMachineIds.length === 1 ? virtualMachineIds[0] : 'all', FailureOperation.StartEnvironments, e ? e.code : undefined);
        errors[error.id] = error;
        yield put(startEnvironmentsError(errors));
    }
}
export function* startEnvironmentsSaga() {
    yield labParentBasedTakeEvery(EnvironmentActionType.START_ENVIRONMENTS, startEnvironments, startVNextVirtualMachines);
}
