import { List, Map, Record, Set } from 'immutable';
import { FailureOperation } from '../../../data/ml-client-error';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { addToListMap, removeFromMap } from '../../../utils/common';
import { LabActionType, } from '../../actions/lab/lab-actions';
import Context from '../../action-context';
import { ResourceId } from '../../../utils/resource-id';
import { LabServicesModels } from 'lab-services';
import { LabUpdateOperation } from '../../../data/models/lab';
import ErrorCode from '../../../common/error-codes';
import { toEnableState } from '../../../data/helpers/enum-helpers';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { LabAccessType } from '../../../data/models/lab';
import { OperationsResultActionType, } from '../../actions/operations/operations-result-actions';
function addOrUpdateLab(lab, labs) {
    if (!labs) {
        return List([lab]);
    }
    else {
        const index = labs.findIndex((o) => caseInsensitiveCultureInvariantCompare(o.id, lab.id) === 0);
        if (index > -1) {
            const oldLab = labs.get(index);
            // preserve original role info if not returned in the get
            // such as when we are polling on a lab
            if (lab.roles.length < 1) {
                lab.roles = [...oldLab.roles];
            }
            lab.lastGroupSyncTime = oldLab?.lastGroupSyncTime;
            lab.lastPublished = oldLab?.lastPublished;
            return labs.set(index, lab);
        }
        else {
            return labs.push(lab);
        }
    }
}
function getLabResourceGroupId(labId) {
    const resourceId = new ResourceId(labId, true);
    const resourceGroupId = resourceId.getResourceGroupId();
    return resourceGroupId;
}
function listVNextLabs(state, action) {
    const oldLoadState = state.get('loadState');
    return state.merge({
        loadState: oldLoadState === LoadingStoreState.Loaded || oldLoadState === LoadingStoreState.Updated
            ? LoadingStoreState.Updating
            : LoadingStoreState.Loading,
    });
}
function listVNextLabsSuccess(state, action) {
    const { labs, labParentId } = action;
    const oldLoadState = state.get('loadState');
    let resourceGroupLabsMap = Map();
    // get permission propagating labs stored that cannot be fetched from ARM yet, merge into resourceGroupLabsMap when needed to show on labs portal
    let resourceGroupPropagatingLabsMap = state.get('resourceGroupPropagatingLabsMap');
    // lab ids from input, fectched from ARM list labs call
    const labIds = Set(List(labs).map(d => d.id));
    if (!labParentId) {
        for (let index = 0; index < labs.length; index++) {
            const resource = new ResourceId(labs[index].id, true);
            const resourceGroupId = resource.getResourceGroupId();
            resourceGroupLabsMap = addToListMap(resourceGroupLabsMap, labs[index], resourceGroupId);
        }
        const RGIds = Array.from(resourceGroupLabsMap.keys());
        for (let index = 0; index < RGIds.length; index++) {
            const permissionPropagatingLabs = resourceGroupPropagatingLabsMap.get(RGIds[index]);
            if (permissionPropagatingLabs && permissionPropagatingLabs.size > 0) {
                for (let index = 0; index < permissionPropagatingLabs.size; index++) {
                    const propagatingLab = permissionPropagatingLabs.get(index);
                    if (propagatingLab && !labIds.has(propagatingLab.id)) {
                        // if a permission propagating lab does not exist in ARM lab list yet, need to add the lab into resourceGroupLabsMap for the current RG to show the lab
                        resourceGroupLabsMap = addToListMap(resourceGroupLabsMap, propagatingLab, RGIds[index]);
                    }
                    if (propagatingLab && labIds.has(propagatingLab.id)) {
                        // if a lab exists in permission propagating map and it exists in ARM lab list already, need to remove the lab from propagating map
                        resourceGroupPropagatingLabsMap = removeFromMap(resourceGroupPropagatingLabsMap, propagatingLab, RGIds[index]);
                    }
                }
            }
        }
    }
    else {
        resourceGroupLabsMap = state.get('resourceGroupLabsMap');
        resourceGroupLabsMap = resourceGroupLabsMap.set(labParentId, List(labs));
        const permissionPropagatingLabs = resourceGroupPropagatingLabsMap.get(labParentId);
        if (permissionPropagatingLabs && permissionPropagatingLabs.size > 0) {
            for (let index = 0; index < permissionPropagatingLabs.size; index++) {
                const propagatingLab = permissionPropagatingLabs.get(index);
                if (propagatingLab && !labIds.has(propagatingLab.id)) {
                    // if a permission propagating lab does not exist in ARM lab list yet, need to add the lab into resourceGroupLabsMap for the current RG to show the lab
                    resourceGroupLabsMap = addToListMap(resourceGroupLabsMap, propagatingLab, labParentId);
                }
                if (propagatingLab && labIds.has(propagatingLab.id)) {
                    // if a lab exists in permission propagating map and it exists in ARM lab list already, need to remove the lab from propagating map
                    resourceGroupPropagatingLabsMap = removeFromMap(resourceGroupPropagatingLabsMap, propagatingLab, labParentId);
                }
            }
        }
    }
    return state.merge({
        loadState: oldLoadState === LoadingStoreState.Updating ? LoadingStoreState.Updated : LoadingStoreState.Loaded,
        resourceGroupLabsMap,
        resourceGroupPropagatingLabsMap,
    });
}
function filterByLabAccessType(state, action) {
    const { labAccessType } = action;
    return state.merge({
        labAccessType,
    });
}
function loadSubscriptionVNextLabsError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addToListMap(errors, error, error.id);
    return state.merge({
        errors,
    });
}
function createLab(state, action) {
    return state.merge({
        isCreating: true,
    });
}
function createLabSuccess(state, action) {
    const { lab } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    const resourceId = new ResourceId(lab.id, true);
    resourceGroupLabsMap = addToListMap(resourceGroupLabsMap, lab, resourceId.getResourceGroupId());
    return state.merge({
        resourceGroupLabsMap,
        isCreating: false,
    });
}
function createLabError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addToListMap(errors, error, error.id);
    return state.merge({
        errors,
        isCreating: false,
    });
}
function deleteLab(state, action) {
    return state.merge({
        isDeleting: true,
    });
}
function deleteLabSuccess(state, action) {
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    const { labId } = action;
    const resourceGroupId = getLabResourceGroupId(labId);
    let labs = resourceGroupLabsMap.get(resourceGroupId);
    const labIndex = labs?.findIndex((o) => caseInsensitiveCultureInvariantCompare(o.id, labId) === 0);
    if (labIndex !== undefined && labIndex > -1) {
        const oldLab = labs.get(labIndex);
        const newLab = { ...oldLab, provisioningState: LabServicesModels.ProvisioningState.Deleting };
        labs = labs?.set(labIndex, newLab);
        resourceGroupLabsMap = resourceGroupLabsMap.set(resourceGroupId, labs);
    }
    return state.merge({
        resourceGroupLabsMap,
        isDeleting: false,
    });
}
function deleteLabError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addToListMap(errors, error, error.id);
    return state.merge({
        errors,
        isDeleting: false,
    });
}
function updateLab(state, action) {
    const { labId, updateOperation, labUpdate } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    const resourceGroupId = getLabResourceGroupId(labId);
    let labs = resourceGroupLabsMap.get(resourceGroupId);
    if (updateOperation === LabUpdateOperation.Settings && !!labs) {
        const index = labs.findIndex((o) => caseInsensitiveCultureInvariantCompare(o.id, labId) === 0);
        if (index > -1) {
            // Preserve settings while calling update operation on the lab.
            const oldLab = labs.get(index);
            const newLab = {
                ...oldLab,
                connectionProfile: labUpdate.connectionProfile,
                autoShutdownProfile: labUpdate.autoShutdownProfile,
                pendingProvisioningState: LabServicesModels.ProvisioningState.Updating,
            };
            labs = labs.set(index, newLab);
            resourceGroupLabsMap = resourceGroupLabsMap.set(resourceGroupId, labs);
        }
    }
    return state.merge({
        updateError: undefined,
        isUpdating: true,
        resourceGroupLabsMap,
    });
}
function updateLabSuccess(state, action) {
    const { lab } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    const resourceGroupId = getLabResourceGroupId(lab.id);
    let labs = resourceGroupLabsMap.get(resourceGroupId);
    if (!!labs) {
        labs = addOrUpdateLab(lab, labs);
        resourceGroupLabsMap = resourceGroupLabsMap.set(resourceGroupId, labs);
    }
    return state.merge({
        resourceGroupLabsMap,
        isUpdating: false,
    });
}
function updateLabError(state, action) {
    const { error: updateError } = action;
    if (updateError.code === ErrorCode.LabCapacityUpdateExceedsGpuCoresLimit ||
        updateError.code === ErrorCode.LabCapacityUpdateExceedsStandardCoresLimit) {
        return state.merge({
            labCapacityExceedsCoresError: updateError,
            isUpdating: false,
        });
    }
    let errors = state.get('errors');
    errors = addToListMap(errors, updateError, updateError.id);
    return state.merge({
        errors,
        updateError,
        isUpdating: false,
    });
}
function clearError(state, action) {
    const { errorIndex, id } = action;
    const labId = id.toLowerCase();
    let errors = state.get('errors').get(labId);
    if (!errors || errorIndex >= errors.count()) {
        return state;
    }
    errors = errors.remove(errorIndex);
    const errorMap = errors.count() > 0 ? state.get('errors').set(labId, errors) : state.get('errors').remove(labId);
    return state.merge({
        errors: errorMap,
    });
}
function clearUpdateError(state, action) {
    return state.merge({
        updateError: undefined,
    });
}
function clearLabPublishError(state, action) {
    const { id } = action;
    const labId = id.toLowerCase();
    let errors = state.get('errors').get(labId);
    if (!errors || errors.count() < 1) {
        return state;
    }
    let newErrors = errors.filter(function (value, index, arr) {
        return value.failureOperation != FailureOperation.PublishTemplate;
    });
    const errorMap = newErrors.count() > 0 ? state.get('errors').set(labId, newErrors) : state.get('errors').remove(labId);
    return state.merge({
        errors: errorMap,
    });
}
function clearLabCapacityExceedsCoresError(state, action) {
    return state.merge({
        labCapacityExceedsCoresError: undefined,
    });
}
function selectLab(state, action) {
    const { labId: currentLabId } = action;
    return state.merge({
        currentLabId,
        labMetadataLoadState: LoadingStoreState.NotStarted,
    });
}
function clearSelectedLab(state, action) {
    return state.merge({
        currentLabId: '',
        labMetadataLoadState: LoadingStoreState.NotStarted,
    });
}
function getLab(state, action) {
    const { labId } = action;
    let errors = state.get('errors');
    let labErrors = errors.get(labId);
    if (!!labErrors) {
        labErrors = labErrors.filter((o) => o.failureOperation != FailureOperation.GetLab);
        if (!!labErrors && labErrors.size > 0) {
            errors = errors.set(labId, labErrors);
        }
        else {
            errors = errors.delete(labId);
        }
    }
    return state.merge({
        errors,
    });
}
function getLabSuccess(state, action) {
    const { lab } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    const resourceGroupId = getLabResourceGroupId(lab.id);
    let labs = resourceGroupLabsMap.get(resourceGroupId);
    labs = addOrUpdateLab(lab, labs);
    resourceGroupLabsMap = resourceGroupLabsMap.set(resourceGroupId, labs);
    return state.merge({
        resourceGroupLabsMap,
    });
}
function getLabError(state, action) {
    return state.merge({
        errors: addToListMap(state.errors, action.error, action.error.id),
    });
}
function updateLabAccessMode(state, action) {
    const { labId, openAccess } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    const resourceGroupId = getLabResourceGroupId(labId);
    let labs = resourceGroupLabsMap.get(resourceGroupId);
    if (!!labs) {
        const index = labs.findIndex((lab) => caseInsensitiveCultureInvariantCompare(lab.id, labId) === 0);
        if (index > -1) {
            const oldLab = labs.get(index);
            const securityProfile = { ...oldLab.securityProfile, openAccess: toEnableState(openAccess) };
            const newLab = { ...oldLab, securityProfile };
            labs = labs.set(index, newLab);
            resourceGroupLabsMap = resourceGroupLabsMap.set(resourceGroupId, labs);
        }
    }
    return state.merge({
        isUpdating: true,
        resourceGroupLabsMap,
    });
}
function updateLabAccessModeError(state, action) {
    const { labId } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    const resourceGroupId = getLabResourceGroupId(labId);
    let labs = resourceGroupLabsMap.get(resourceGroupId);
    if (!!labs) {
        const index = labs.findIndex((lab) => caseInsensitiveCultureInvariantCompare(lab.id, labId) === 0);
        if (index > -1) {
            const oldLab = labs.get(index);
            const oldOpenAccess = oldLab.securityProfile?.openAccess;
            const securityProfile = {
                ...oldLab.securityProfile,
                openAccess: oldOpenAccess === LabServicesModels.EnableState.Disabled
                    ? LabServicesModels.EnableState.Enabled
                    : LabServicesModels.EnableState.Disabled,
            };
            const newLab = { ...oldLab, securityProfile };
            labs = labs.set(index, newLab);
            resourceGroupLabsMap = resourceGroupLabsMap.set(resourceGroupId, labs);
        }
    }
    return state.merge({
        isUpdating: false,
        resourceGroupLabsMap,
    });
}
function syncLabUsers(state, action) {
    const { labId } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    resourceGroupLabsMap = setPendingLabState(labId, resourceGroupLabsMap, LabServicesModels.LabState.Syncing, LabServicesModels.ProvisioningState.Updating);
    return state.merge({
        resourceGroupLabsMap,
        syncUsersError: undefined,
    });
}
function syncLabUsersError(state, action) {
    const { syncUsersError, labId } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    resourceGroupLabsMap = setPendingLabState(labId, resourceGroupLabsMap, undefined, undefined);
    return state.merge({
        resourceGroupLabsMap,
        syncUsersError,
    });
}
function setPendingLabState(labId, resourceGroupLabsMap, pendingLabState, pendingProvisioningState) {
    const resourceGroupId = getLabResourceGroupId(labId);
    let labs = resourceGroupLabsMap.get(resourceGroupId);
    if (!!labs) {
        const index = labs.findIndex((lab) => caseInsensitiveCultureInvariantCompare(lab.id, labId) === 0);
        if (index > -1) {
            const oldLab = labs.get(index);
            const newLab = { ...oldLab, pendingLabState, pendingProvisioningState };
            labs = labs.set(index, newLab);
            resourceGroupLabsMap = resourceGroupLabsMap.set(resourceGroupId, labs);
        }
    }
    return resourceGroupLabsMap;
}
function publishLab(state, action) {
    const { labId, capacity } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    resourceGroupLabsMap = setPendingLabState(labId, resourceGroupLabsMap, LabServicesModels.LabState.Publishing, LabServicesModels.ProvisioningState.Updating);
    const resourceGroupId = getLabResourceGroupId(labId);
    let labs = resourceGroupLabsMap.get(resourceGroupId);
    if (!!labs) {
        const index = labs.findIndex((lab) => caseInsensitiveCultureInvariantCompare(lab.id, labId) === 0);
        if (index > -1) {
            const oldLab = labs.get(index);
            const newSku = { ...oldLab.virtualMachineProfile.sku, capacity };
            const labUpdate = {
                virtualMachineProfile: { ...oldLab.virtualMachineProfile, sku: newSku },
            };
            const newLab = { ...oldLab, ...labUpdate };
            labs = labs.set(index, newLab);
            resourceGroupLabsMap = resourceGroupLabsMap.set(resourceGroupId, labs);
        }
    }
    return state.merge({
        resourceGroupLabsMap,
    });
}
function publishVNextLabAccepted(state, action) {
    const { operation } = action;
    let operations = state.get('labPublishOperations');
    operations = operations.set(operation.ResourceId?.id, operation);
    return state.merge({
        labPublishOperations: operations,
    });
}
function pollOperationResultSuccess(state, action) {
    const { operation } = action;
    let operations = state.get('labPublishOperations');
    operations = operations.set(operation.ResourceId?.id, operation);
    return state.merge({
        labPublishOperations: operations,
    });
}
function publishLabError(state, action) {
    const { labId, error } = action;
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    let errors = state.get('errors');
    resourceGroupLabsMap = setPendingLabState(labId, resourceGroupLabsMap, undefined, undefined);
    errors = addToListMap(errors, error, error.id);
    return state.merge({
        resourceGroupLabsMap,
        errors,
    });
}
function getLabMetadata(state, action) {
    const oldLoadState = state.get('labMetadataLoadState');
    return state.merge({
        labMetadataLoadState: oldLoadState === LoadingStoreState.NotStarted ? LoadingStoreState.Loading : LoadingStoreState.Updating,
    });
}
function getLabMetadataSuccess(state, action) {
    const { labMetadata, labId } = action;
    const oldLoadState = state.get('labMetadataLoadState');
    let resourceGroupLabsMap = state.get('resourceGroupLabsMap');
    const resourceGroupId = getLabResourceGroupId(labId);
    let labs = resourceGroupLabsMap.get(resourceGroupId);
    if (!!labs) {
        const index = labs.findIndex((o) => caseInsensitiveCultureInvariantCompare(o.id, labId) === 0);
        if (index > -1) {
            const oldLab = labs.get(index);
            const newLab = { ...oldLab, ...labMetadata };
            labs = labs.set(index, newLab);
        }
        resourceGroupLabsMap = resourceGroupLabsMap.set(resourceGroupId, labs);
    }
    return state.merge({
        labMetadataLoadState: oldLoadState === LoadingStoreState.Loading ? LoadingStoreState.Loaded : LoadingStoreState.Updated,
        resourceGroupLabsMap,
    });
}
function getLabMetadataError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addToListMap(errors, error, error.id);
    const oldLoadState = state.get('labMetadataLoadState');
    return state.merge({
        labMetadataLoadState: oldLoadState === LoadingStoreState.Loading
            ? LoadingStoreState.LoadingFailed
            : LoadingStoreState.UpdateFailed,
        errors,
    });
}
function updateVnextCreatelabPermission(state, action) {
    const { createLabPermissionOnLabPlan } = action;
    return state.merge({
        hasCreateLabPermissionOnLabPlan: createLabPermissionOnLabPlan,
    });
}
function updateVnextPermissionPropagation(state, action) {
    const { vNextLabPermissionPropagating } = action;
    return state.merge({
        isVnextLabPermissionPropagating: vNextLabPermissionPropagating,
    });
}
function addVnextPermissionPropagatingLab(state, action) {
    const { lab, labParentId } = action;
    let resourceGroupPropagatingLabsMap = state.get('resourceGroupPropagatingLabsMap');
    if (!labParentId) {
        const resource = new ResourceId(lab.id, true);
        const resourceGroupId = resource.getResourceGroupId();
        resourceGroupPropagatingLabsMap = addToListMap(resourceGroupPropagatingLabsMap, lab, resourceGroupId);
    }
    else {
        resourceGroupPropagatingLabsMap = addToListMap(resourceGroupPropagatingLabsMap, lab, labParentId);
    }
    return state.merge({
        resourceGroupPropagatingLabsMap,
    });
}
function removeVnextPermissionPropagatingLab(state, action) {
    const { lab, labParentId } = action;
    let resourceGroupPropagatingLabsMap = state.get('resourceGroupPropagatingLabsMap');
    if (!labParentId) {
        const resource = new ResourceId(lab.id, true);
        const resourceGroupId = resource.getResourceGroupId();
        resourceGroupPropagatingLabsMap = removeFromMap(resourceGroupPropagatingLabsMap, lab, resourceGroupId);
    }
    else {
        resourceGroupPropagatingLabsMap = removeFromMap(resourceGroupPropagatingLabsMap, lab, labParentId);
    }
    return state.merge({
        resourceGroupPropagatingLabsMap,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    resourceGroupLabsMap: Map(),
    currentLabId: '',
    errors: Map(),
    isDeleting: false,
    isCreating: false,
    isUpdating: false,
    updateError: undefined,
    labCapacityExceedsCoresError: undefined,
    syncUsersError: undefined,
    isUpdatingMetadata: false,
    labMetadataLoadState: LoadingStoreState.NotStarted,
    labAccessType: LabAccessType.writeAccess,
    labPublishOperations: Map(),
    hasCreateLabPermissionOnLabPlan: undefined,
    isVnextLabPermissionPropagating: undefined,
    resourceGroupPropagatingLabsMap: Map(),
})();
export const labReducer = (state = initialState, action) => {
    switch (action.type) {
        case LabActionType.CLEAR_USER_SESSION:
        case LabActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    switch (action.type) {
                        case LabActionType.LIST_LABS:
                            return listVNextLabs(state, action);
                        case LabActionType.LIST_LABS_SUCCESS:
                            return listVNextLabsSuccess(state, action);
                        case LabActionType.FILTER_BY_LAB_ACCESS_TYPE:
                            return filterByLabAccessType(state, action);
                        case LabActionType.LOAD_SUBSCRIPTION_VNEXT_LABS_ERROR:
                            return loadSubscriptionVNextLabsError(state, action);
                        case LabActionType.UPDATE_VNEXT_CREATELAB_PERMISSION:
                            return updateVnextCreatelabPermission(state, action);
                        case LabActionType.UPDATE_VNEXT_PERMISSION_PROPAGATION:
                            return updateVnextPermissionPropagation(state, action);
                        case LabActionType.ADD_VNEXT_PERMISSION_PROPAGATING_LAB:
                            return addVnextPermissionPropagatingLab(state, action);
                        case LabActionType.REMOVE_VNEXT_PERMISSION_PROPAGATING_LAB:
                            return removeVnextPermissionPropagatingLab(state, action);
                        case LabActionType.CREATE_LAB:
                            return createLab(state, action);
                        case LabActionType.CREATE_LAB_SUCCESS:
                            return createLabSuccess(state, action);
                        case LabActionType.CREATE_LAB_ERROR:
                            return createLabError(state, action);
                        case LabActionType.DELETE_LAB:
                            return deleteLab(state, action);
                        case LabActionType.DELETE_LAB_SUCCESS:
                            return deleteLabSuccess(state, action);
                        case LabActionType.DELETE_LAB_ERROR:
                            return deleteLabError(state, action);
                        case LabActionType.UPDATE_LAB:
                            return updateLab(state, action);
                        case LabActionType.UPDATE_LAB_SUCCESS:
                            return updateLabSuccess(state, action);
                        case LabActionType.UPDATE_LAB_ERROR:
                            return updateLabError(state, action);
                        case LabActionType.CLEAR_LAB_ERROR:
                            return clearError(state, action);
                        case LabActionType.CLEAR_LAB_UPDATE_ERROR:
                            return clearUpdateError(state, action);
                        case LabActionType.CLEAR_LAB_CAPACITY_EXCEEDS_CORES_ERROR:
                            return clearLabCapacityExceedsCoresError(state, action);
                        case LabActionType.CLEAR_PUBLISH_LAB_ERROR:
                            return clearLabPublishError(state, action);
                        case LabActionType.SELECT_LAB:
                            return selectLab(state, action);
                        case LabActionType.CLEAR_SELECTED_LAB:
                            return clearSelectedLab(state, action);
                        case LabActionType.SELECT_LAB_PARENT_RESOURCE:
                            return clearSelectedLab(state, action);
                        case LabActionType.GET_LAB:
                            return getLab(state, action);
                        case LabActionType.GET_LAB_SUCCESS:
                            return getLabSuccess(state, action);
                        case LabActionType.GET_LAB_ERROR:
                            return getLabError(state, action);
                        case LabActionType.UPDATE_LAB_ACCESS_MODE:
                            return updateLabAccessMode(state, action);
                        case LabActionType.UPDATE_LAB_ACCESS_MODE_ERROR:
                            return updateLabAccessModeError(state, action);
                        case LabActionType.SYNC_LAB_USERS:
                            return syncLabUsers(state, action);
                        case LabActionType.SYNC_LAB_USERS_ERROR:
                            return syncLabUsersError(state, action);
                        case LabActionType.PUBLISH_LAB:
                            return publishLab(state, action);
                        case LabActionType.PUBLISH_LAB_ERROR:
                            return publishLabError(state, action);
                        case LabActionType.PUBLISH_LAB_ACCEPTED:
                            return publishVNextLabAccepted(state, action);
                        case LabActionType.GET_LAB_METADATA:
                            return getLabMetadata(state, action);
                        case LabActionType.GET_LAB_METADATA_SUCCESS:
                            return getLabMetadataSuccess(state, action);
                        case LabActionType.GET_LAB_METADATA_ERROR:
                            return getLabMetadataError(state, action);
                        case OperationsResultActionType.POLL_PUBLISH_OPERATION_RESULT_SUCCESS:
                            return pollOperationResultSuccess(state, action);
                        default:
                            return state;
                    }
                default:
                    return state;
            }
    }
};
export default labReducer;
