import { call, put, takeLatest, select } from 'redux-saga/effects';
import { TemplateActionType } from '../../actions/template/template-actions';
import TemplateProvider from '../../../data/providers/template-provider';
import { listTemplatesSuccess, listTemplatesError, selectTemplate, createTemplateError, } from '../../actions/template/template-action-creators';
import { ResourceId } from '../../../utils/resource-id';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { hasTemplateCreationFailed } from '../../selectors/template-selectors';
export function* listTemplates(action) {
    const { labId, expand, storeFirst } = action;
    try {
        const resourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const templates = yield call(TemplateProvider.listTemplates, resourceId, accessToken, locale, language, expand);
        if (templates.length > 0 && storeFirst) {
            const template = templates[0];
            yield put(selectTemplate(template));
            if (hasTemplateCreationFailed(template)) {
                const error = new MlClientError(template.latestOperationResult.errorMessage, template.id, FailureOperation.CreateTemplate, template.latestOperationResult.errorCode);
                yield put(createTemplateError(error));
            }
        }
        yield put(listTemplatesSuccess(templates));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.ListTemplates, e ? e.code : undefined);
        yield put(listTemplatesError(error));
    }
}
export function* listTemplatesSaga() {
    yield takeLatest(TemplateActionType.LIST_TEMPLATES, listTemplates);
}
