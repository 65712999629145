const reservedPasswords = [
    'abc@123',
    'p@$$w0rd',
    'p@ssw0rd',
    'p@ssword123',
    'pa$$word',
    'pass@word1',
    'password!',
    'password1',
    'password22',
    'iloveyou!',
    ' www.3hidc.com',
    '!!aaaa11',
    '!$%)3)cw',
    '!@#$%^&*(987654321qq',
    '!@#$%^654321qaz',
    '!@#$%9876weungbtxcn',
    '!@#$qwerasdf',
    '!@#123abc',
    '!@#abc123',
    '!@#admin123',
    '!@#lanqing0902',
    '!@#qwe123',
    '!jingdian.com1982',
    '!qaz2wsx',
    '!qazxsw@@mxy',
    '!qazxsw2',
    '!qazxsw2@',
    '#x4%a68k3l5q0d7x1z9d0z',
    '#x4%a6d7x1z9d0z8k3l5q0',
    '%username%12',
    '%username%123',
    '%username%1234',
    '%username%123445',
    '%username%123456',
    '*woaini1',
    ',,,debia56',
    '@!$fsa_hgf3w343',
    '@!65367016s',
    '@#$%415abc',
    '@123qwe@',
    '@1q2w3e@',
    '@q1w2e3r4@',
    '@song1234@',
    '@yh780202',
    '[ylh510212-8-5]',
    'â¨ã¨â©mâ©â«â¡â¡oo0o00',
    '00gdty@)!!',
    '0168.com',
    '018idc.cn',
    '021hb.com2008',
    '0527idc.com',
    '0575@chinaidc!@#',
    '0769dns.com',
    '0p;/9ol.8ik,7ujm',
    '100asp.com',
    '10idc.com',
    '111qqq!!!',
    '111qqq...',
    '123!@#abc',
    '123.com.com',
    '123.mama/bufu',
    '123.qwert',
    '123.qwerty',
    '123321.com',
    '1234/add',
    '123456.com',
    '1234567890!@#$%^qaz',
    '123654.com',
    '123abc!@#',
    '123qwe!@#',
    '123qwer!',
    '14361256malin.,',
    '163ns.com',
    '167.167kk',
    '17tf123654.com',
    '19810zax',
    '19890607.com',
    '1a2s3d4f5g6h7j8k9l0;',
    '1q@w#e$r',
    '1q@w#e$r%t',
    '1q@w#e$r%t^y',
    '1q@w#e$r%t^y&u',
    '1q@w#e$r%t^y&u*i',
    '1q@w#e$r%t^y&u*i(o',
    '1q@w#e$r%t^y&u*i(o)p',
    '1q@w3e$r',
    '1q2w3e,./? ><',
    '1qaz@wsx',
    '1qaz@wsx#edc',
    '1qaz2wsx!@#',
    '1qazxsw@',
    '1qazxsw2@mxy',
    '200550616@qq.com',
    '2008sxidc@))*',
    '201234.com',
    '2100idc.com',
    '21idc.com',
    '21vnet!@#',
    '21vnet.com',
    '26dgidc!@#',
    '26idc.com',
    '293idc!@#',
    '3322.org',
    '35inter.com',
    '365obsserver!',
    '3h8idc!!#',
    '3h8idc72sanhe000',
    '3inaidc!@#',
    '3s3w.com',
    '3sina.net',
    '4399.net',
    '513tyml.com',
    '523000.com',
    '52marykay.com.c',
    '52marykay.com.cn',
    '52xxx.cn',
    '52yj#1314@',
    '530@163.com',
    '544jtlfdks%#@%(~_&fgvd11',
    '58cto.com',
    '59.cn4563',
    '59.cn666',
    '59.cn999',
    '59.com999',
    '75800.com',
    '7751688mzhjkl1q2w3e',
    '7791/815zxc',
    '789789qaz.com',
    '7ujm,ki8',
    '7ujm8ik,',
    '85bs.com',
    '870226linjin.',
    '88310000.com',
    '8hgame@xuhao888',
    '9000idc.com!@#',
    '95599malin.,',
    '999ah.com',
    '99dvd.net',
    'a#9l@1q3$p4%5&zm',
    'a123.123',
    'a123.321',
    'a123.456',
    'a168ball@!$552',
    'a321.123',
    'a321.321',
    'a6266005*',
    'aaa123!@#',
    'aaaidc.com444284',
    'abc!@#123',
    'abc123!@#',
    'abcd!@#1234',
    'abcd000***',
    'abcd000...',
    'abcd111!!!',
    'abcd123!@#',
    'abcd123!@#12345',
    'abcd1234!@#',
    'abcd1234!@#$',
    'abcd1234!@#$%',
    'abcd12345!@#',
    'abcd12345!@#$%',
    'admin!2#',
    'admin.369',
    'admin@123',
    'admin@1234',
    'admin@12345',
    'admin123.0',
    'admin1522',
    'admin5!@',
    'admin5.com',
    'admin68admin!@#',
    'admin789+',
    'ahshengqiang9935.net',
    'aistar123<>!n',
    'amyaqi!#(!*!)#$^%sh63536719qll',
    'anada325!@#',
    'andariel@123',
    'angel19871002!@#',
    'angel9350!@#',
    'as1234@sfqas.com',
    'asd123!@#',
    'asd123.0',
    'asdf123.',
    'asdf1234%^&*',
    'auto@12345',
    'ba0260!#%#%',
    'ba0260!#%#% ',
    'bao_zj2010',
    'bbs-hz0752-net',
    'beijin2008',
    'biyi0791.com',
    'bl-kj@123',
    'btc-43477557',
    'c123456.',
    'c3net_co.ltd',
    'ccfeng66131421',
    'ceshi100m168',
    'ceshichina35',
    'changed0731@2008',
    'chat6.cn',
    'chenzhidongidc99.cn',
    'chiefun2009*',
    'china!@#2011',
    'china!@#2012',
    'china111idc',
    'china123!@#',
    'china123idc',
    'china123love!@#',
    'china2008@))*',
    'china222idc',
    'china333idc',
    'china35.cn',
    'china35.com',
    'china35.idc',
    'china35.net',
    'china353idc',
    'china488idc',
    'china555idc',
    'china566idc',
    'china653idc',
    'china666idc',
    'china888idc',
    'china999idc',
    'chinaidc!!!123',
    'chinaidc!@#123',
    'chinaidc`12',
    'chinaidc0805',
    'chinaidc123!@#',
    'chinaidc35.com',
    'clyj#520@1314',
    'cqidc!@#2008',
    'cqidc2008!@#',
    'cqrzgs159357!@#',
    'cqscszww8848#',
    'csjhj7^^gdsghwq222199jkjkndwsdw^&%()',
    'cszwwtlxsw8848#',
    'cznut9527!@#$',
    'dell1950=hdsms',
    'dezhou!!)69',
    'dezhou!!)69 ',
    'dg10!#%#%',
    'dg130.$(',
    'dhlxm83840309~',
    'dhlxm83840309~!',
    'djdesaitt1..',
    'dkw0.123',
    'dns65!@#',
    'dns99+588',
    'dong!@#456',
    'ds.dw587~!@dd..',
    'duriel@123',
    'dy8848ert&@#',
    'e963.com',
    'edong123456789.0',
    'edongidc!@#2008',
    'edongidc@123',
    'edongidc123456789.0',
    'edongidc2008!@#',
    'ef126mlkm69136116',
    'erenb.com6688',
    'erenb.net6688',
    'eta_server-101',
    'fg56.com',
    'fqwegqwggwewgwgklqwgg*(@#tghq',
    'gd6eetkg2qoy',
    'geeidc123456**',
    'geiscctv48.209',
    'gmaj123#123',
    'gmaj123#123#123',
    'good7lw.com',
    'goodj0bidc',
    'google576cd6fab584a010.html',
    'guangxi123445!@#$%^',
    'guangxi123456!@#$%^',
    'h@ppyt00#@!',
    'hao123.com',
    'hdsms=dell1950',
    'hdsms=h2d4t6x8',
    'heedann_530',
    'heedann_530@163.com',
    'hengdong2008//',
    'hezu86new',
    'hf#chinaidc@idccenter',
    'hi19810zax',
    'hk@maya0769.com',
    'hlwj0519-1205.jf',
    'hongyanxuwenxingguo03',
    'hsnuc0sdq2',
    'huacheng123!@#',
    'huayun1234!@#',
    'hucgynxz!&#it',
    'hz2654321!@#$%^',
    'idc!@#$1234',
    'idc.10.cn',
    'idc.26idc.com',
    'idc.dns99',
    'idc.dns99.cn',
    'idc^%$#@!2654321',
    'idc_2008',
    'idc_2009',
    'idc0.1230',
    'idc0123.0',
    'idc123!@#',
    'idc123&123',
    'idc123,abc',
    'idc123.0',
    'idc123.com',
    'idc1234%^&*',
    'idc123445!@#$%^',
    'idc123456!@#$%^',
    'idc1654321!@#$%^',
    'idc196.@^',
    'idc2007@))&',
    'idc2008@))*',
    'idc2011!@#',
    'idc2654321!@#$%^',
    'idc2654321^%$#@!',
    'idc5.net',
    'idc517.com',
    'idc78.com',
    'idckk.com198134',
    'idckk.com198171',
    'iis_99yu_1',
    'ip345.com123',
    'jd8idc.com',
    'jh2654321!@#$%^',
    'jianrong#&^^**@&6688',
    'jiezu@520',
    'jifang360.com',
    'jones123',
    'jspower123.0',
    'jxidc0105!@#$%',
    'kiss&129116',
    'kongmima41@#',
    'ky!#%&(er987',
    'lanmang@idc2007',
    'lanmang@idc2008',
    'ldserver123123123',
    'ldserver123445',
    'ldserver1234457',
    'ldserver123456',
    'ldserver1234567',
    'ldserver147258',
    'ldserver456789',
    'least123!@#',
    'leet87362275',
    'leo_zj2010',
    'lg@123456',
    'lgg1234.0',
    'ligang_2007',
    'linduinc3456789(*&^%$#@!',
    'liuhan522!@#',
    'liusc522$#@!',
    'llwl507cn.1314',
    'ln5203344***',
    'luofei520!@#123',
    'lvye135~!@',
    'lzwl0.123',
    'm&g_2008',
    'mailserver@peiying.cnff8',
    'mama.123/bufu',
    'manmancai8.com',
    'maya0769.com',
    'min1985minzkdns.com!(*%',
    'min21minzkdns.com!(*%',
    'min86minzkdns.com!(*%',
    'mir2.com',
    'mlkmef12669136116',
    'mmddkk88pp,./',
    'mrgool_010',
    'n1.zj.cn',
    'ncbiyi0791.com',
    'netlan1234%^&*',
    'ngncn.com2010',
    'niubi99china',
    'oaoidc123',
    'oaoidc123!@#',
    'onlyidc123789+',
    'oo0',
    'p$ssw0rd',
    'p@ss1234',
    'p@ssw0rd!',
    'p@ssw0rd1',
    'p@ssword',
    'pa$$w0rd',
    'pa55word',
    'pass@word',
    'password123',
    'pengzhufuqq..5',
    'pollyo0o!#%&',
    'power0.123',
    'power123.0',
    'q1w2e3$%^&',
    'qaz!@#123...',
    'qaz#@!321',
    'qaz000...',
    'qaz123!@#',
    'qaz47991798',
    'qazwsx!@#123',
    'qazwsxedc!@#123',
    'qeeka^%@@!%@@',
    'qq()#*(*',
    'qq123.com',
    'qq123.com  ',
    'qq123456.com',
    'qq520520--++',
    'qs1234!@#',
    'qsx6059410172.',
    'qwe!@#123',
    'qwe123!@#',
    'qwe123.0',
    'qwe-5158-dsa',
    'qwe-5158-sa',
    'qwe789!@#',
    'qweasd!@#123',
    'qweasd123!@#',
    'qy_0901gd',
    'qy0668-2196688',
    'qy520.cn',
    'qy521.cn',
    's#d885fk9981',
    'sa!@#123',
    'sa123!@#',
    'sa890-=\\',
    'sanhe000~!@#',
    'sanhe000~!@#  ',
    'sauoo.com153',
    'say236.219',
    'sbkjef12669136116',
    'scm2988#shnet',
    'sdf#$d567dsqew',
    'sdfwerqfr42de',
    'sdr%tw0308',
    'sdr%tw564',
    'sever1<jyyy>)*',
    'sh(8&6%4#2!',
    'sha^5$3@1',
    'shaidc!2#4',
    'shaidc!23',
    'shaidc%4#2!',
    'shaidc*90',
    'shaidc^5$3@1',
    'shaidc^5$3@12008',
    'shaidc2008!@#',
    'shaidc2008$3@1',
    'shaidc2008%^&*',
    'shanghai%4#2!',
    'shdadapic@&63536719',
    'sino_webserver',
    'sky_master!1986',
    'soft.v01.cn',
    'spbo3@#$071810',
    'sport#@%5535',
    'star-x/van',
    'stdx2008!!',
    'sujianguocqzc168',
    'sujianguocqzc558',
    'sun198527@',
    'sun99999.com',
    'sxidc!@#456',
    'sys168.com',
    'tangchao20!!',
    'tangke*80231314',
    'taotao_!@#110220',
    'taotao_!@#50715655',
    'tcidcseo20%#',
    'think#$!',
    'ting5201314`',
    'tkggja850518`1',
    'tnhoo15862380404',
    'topset4227232.152..',
    'topset4227232.70..',
    'trista188#**',
    'tt8sports^%$com',
    'ttixe008jnsj#',
    'tztour.com123',
    'un-idc0503',
    'v01.cnidc',
    'vip.51.la',
    'vlan35idc%^&',
    'wangzi123345,./',
    'wdmdjdesaill1..',
    'webadmin!@#$%^123445',
    'webadmin!@#$%^123456',
    'webadmin!@#321',
    'webadmin123!@#',
    'webadmin123445!@#$%^',
    'webadmin123456!@#$%^',
    'webadmin321!@#',
    'webamin!@#123',
    'weidc35.com',
    'wenzhou.o4.com.cn',
    'wh1979522.89',
    'winner!@#00',
    'woool@2008',
    'write_au',
    'wt1223@126',
    'wt1223@126.com',
    'wukai,./88',
    'wukai,./8899',
    'wusheng123!@#',
    'www.0168.com',
    'www.0527idc.com',
    'www.0769dns.com',
    'www.10.cn',
    'www.10idc.com',
    'www.126.com',
    'www.163.com',
    'www.1818gm.cn',
    'www.1818gm.com',
    'www.2100idc.com',
    'www.21vnet.com',
    'www.26idc.com',
    'www.293.net',
    'www.31w.cn',
    'www.3h8.com',
    'www.3hidc.com',
    'www.3s3w.com',
    'www.3sina.net',
    'www.3tvps.net',
    'www.523000.com',
    'www.666idc.com',
    'www.6h.com',
    'www.7x24.cn',
    'www.88310000.com',
    'www.9000idc.com',
    'www.9tao.com',
    'www.admin5.com',
    'www.chat6.cn',
    'www.dns65.com',
    'www.fg56.com',
    'www.fj163.com',
    'www.idc123.com',
    'www.idc5.net',
    'www.idc78.com',
    'www.jifang360.com',
    'www.mir2.com',
    'www.n1.zj.cn',
    'www.peng34654',
    'www.v009.cn',
    'www.v01.cn',
    'www.v01.cn.com',
    'www.v01.cn123456',
    'www.v02.cn',
    'www.v03.cn',
    'www.zj3000.cn',
    'wz2654321!@#$%^',
    'xfx1989_2008',
    'xh772-ap',
    'xiefei5201314.',
    'xijiaoyuan123)(',
    'xs2008@cpgestion',
    'xtzj.com',
    'xubo!@#$1234',
    'xueddiesheng@2008',
    'xyidc_2006',
    'xyz!@#$1234abc',
    'yanhuang2010',
    'yanhuangidc123',
    'yanhuangyanhuang2010',
    'yantaowan_!@#110220',
    'yb888mail/./',
    'yisence#@200512',
    'yz@cnidc@563idc#2618$zf',
    'zaq1@wsx',
    'zguoshengmlp13908462030~!@',
    'zhao520123.',
    'zhengbiao1!@#!@#',
    'zhenzhi123!@#',
    'zhida.160',
    'zhongguo$#@!999@',
    'zj112.($',
    'zj124.70./*-',
    'zjewww6168!qaz',
    'zkdns2008!@#',
    'zlzy.com87',
    'znhyy10599@!',
    'zxc123.0',
    'zxf1984@gmail.cn',
    'zxf1984@gmail.com',
    'zzmlkmef12669136116',
];
export const lowercaseReservedPasswords = reservedPasswords.map((password) => password.toLowerCase());
export default lowercaseReservedPasswords;
