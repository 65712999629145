export function decodeBase64Url(payload) {
    let base64Payload = payload.replace(/-/g, '+').replace(/_/g, '/');
    const padCount = base64Payload.length % 4;
    if (padCount > 0) {
        for (let count = 0; count < 4 - padCount; count++) {
            base64Payload = base64Payload.concat('=');
        }
    }
    return atob(base64Payload);
}
