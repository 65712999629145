import { Record } from 'immutable';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { SkuActionType, } from '../../actions/vnext/sku/sku-actions';
function listSkuData(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loading,
        error: undefined,
    });
}
function listSkuDataSuccess(state, action) {
    const { data } = action;
    return state.merge({
        loadState: LoadingStoreState.Loaded,
        skus: data,
    });
}
function listSkuDataError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: LoadingStoreState.LoadingFailed,
        error,
        skus: undefined,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    error: undefined,
    skus: [],
})();
export const skuReducer = (state = initialState, action) => {
    switch (action.type) {
        case SkuActionType.SELECT_TENANT:
        case SkuActionType.CLEAR_USER_SESSION:
        case SkuActionType.SELECT_LAB_PARENT_RESOURCE:
            return state.merge(initialState);
        case SkuActionType.LIST_SKU_DATA:
            return listSkuData(state, action);
        case SkuActionType.LIST_SKU_DATA_SUCCESS:
            return listSkuDataSuccess(state, action);
        case SkuActionType.LIST_SKU_DATA_ERROR:
            return listSkuDataError(state, action);
        default:
            return state;
    }
};
export default skuReducer;
