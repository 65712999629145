import { call, put, race, select, take } from 'redux-saga/effects';
import { isLabUserSyncFailed, isLabUserSyncRunning, isLabUserSyncSucceeded, } from '../../../common/selectors/lab-latest-operation';
import { listUsers } from '../../actions/user/user-action-creators';
import { FailureOperation, MlClientError, AzurePolicyViolation } from '../../../data/ml-client-error';
import { LabUpdateOperation } from '../../../data/models/lab';
import LabProvider from '../../../data/providers/lab-provider';
import VNextLabProvider from '../../../data/providers/vnext/arm-api/lab-provider';
import { ResourceId } from '../../../utils/resource-id';
import { updateLabError, updateLabSuccess, syncLabUsers, updateVNextLabSuccess, getLab, pollLabUntilTerminalStateStart, } from '../../actions/lab/lab-action-creators';
import { LabActionType } from '../../actions/lab/lab-actions';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { getGroupName } from '../../actions/group/group-action-creators';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { getVNextLab } from '../../selectors/vnext/lab-selectors';
import { TenSecondInterval } from '../../../utils/constants';
export function* updateLab(action) {
    const { lab, updateOperation } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const updatedLab = yield call(LabProvider.updateLab, lab, accessToken, locale, language);
        yield put(updateLabSuccess(updatedLab));
        // The updatelab operation doesn't return the syncoperation from the RP - should be fixed in vnext
        // for now, we manually force a sync operation in cases when we're setting the group id to let the ui render the proper state
        // additionally, we wont have the group name until we reload the lab-app unless we call this here and we want to show it on the page
        if (updateOperation === LabUpdateOperation.SetGroup) {
            yield put(getGroupName(updatedLab.aadGroupId));
            yield put(syncLabUsers(updatedLab.id));
        }
        // dispatch listUsers action to make sure the user list will be updated if the new syncUserList job is finished before next polling of lab
        if ((lab.latestOperationResult?.operationUrl !== updatedLab.latestOperationResult?.operationUrl ||
            isLabUserSyncRunning(lab)) &&
            (isLabUserSyncSucceeded(updatedLab) || isLabUserSyncFailed(updatedLab))) {
            yield put(listUsers(lab.id));
        }
    }
    catch (e) {
        let failureOperation;
        switch (updateOperation) {
            case LabUpdateOperation.AccessMode:
                failureOperation = FailureOperation.UpdateLabAccessMode;
                break;
            case LabUpdateOperation.Capacity:
                failureOperation = FailureOperation.UpdateLabCapacity;
                break;
            case LabUpdateOperation.Quota:
                failureOperation = FailureOperation.UpdateLabQuota;
                break;
            case LabUpdateOperation.Settings:
                failureOperation = FailureOperation.UpdateLabSettings;
                break;
            default:
                failureOperation = FailureOperation.UpdateLab;
                break;
        }
        const error = new MlClientError(e ? e.message : undefined, lab.id, failureOperation, e ? e.code : undefined);
        yield put(updateLabError(error));
    }
}
export function* updateVNextLab(action) {
    const { labId, labUpdate, updateOperation } = action;
    try {
        const resourceId = new ResourceId(labId, true);
        const oldLab = yield select(getVNextLab, labId);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const accessToken = yield call(getArmAccessTokenSaga);
        let updatedLab;
        if (updateOperation === LabUpdateOperation.Capacity ||
            updateOperation === LabUpdateOperation.SetGroup ||
            updateOperation === LabUpdateOperation.Settings) {
            updatedLab = yield call(VNextLabProvider.beginUpdateLab, resourceId, labUpdate, accessToken, locale, language);
        }
        else {
            updatedLab = yield call(VNextLabProvider.updateLab, resourceId, labUpdate, accessToken, locale, language);
        }
        yield put(updateVNextLabSuccess({ ...updatedLab, roles: [...oldLab.roles] }));
        if (updateOperation === LabUpdateOperation.Capacity ||
            updateOperation === LabUpdateOperation.SetGroup ||
            updateOperation === LabUpdateOperation.Settings) {
            yield put(pollLabUntilTerminalStateStart(labId, TenSecondInterval, TenSecondInterval));
        }
    }
    catch (e) {
        let failureOperation;
        switch (updateOperation) {
            case LabUpdateOperation.AccessMode:
                failureOperation = FailureOperation.UpdateLabAccessMode;
                break;
            case LabUpdateOperation.Capacity:
                if (e?.code == FailureOperation.ScalingDownWhenVirtualMachinesAreRunning) {
                    failureOperation = FailureOperation.ScalingDownWhenVirtualMachinesAreRunning;
                }
                else {
                    failureOperation = FailureOperation.UpdateLabCapacity;
                }
                break;
            case LabUpdateOperation.Quota:
                failureOperation = FailureOperation.UpdateLabQuota;
                break;
            case LabUpdateOperation.TitleOrDesciption:
                failureOperation = FailureOperation.UpdateLabTitleOrDescription;
                break;
            case LabUpdateOperation.Settings:
                failureOperation = FailureOperation.UpdateLabSettings;
                yield put(getLab(labId));
                yield race({
                    success: take(LabActionType.GET_LAB_SUCCESS),
                    cancelled: take(LabActionType.GET_LAB_CANCELLED),
                    error: take(LabActionType.GET_LAB_ERROR),
                });
                break;
            default:
                failureOperation = FailureOperation.UpdateLab;
                break;
        }
        let policyViolationDefinitionIds = undefined;
        if (e?.code === AzurePolicyViolation.CODE) {
            policyViolationDefinitionIds = [];
            const errorObject = JSON.parse(e.response.body);
            for (const additionalInfo of errorObject.error.additionalInfo) {
                policyViolationDefinitionIds.push(additionalInfo.info.policyDefinitionId);
            }
        }
        const error = new MlClientError(e ? e.message : undefined, labId, failureOperation, e ? e.code : undefined, policyViolationDefinitionIds ? policyViolationDefinitionIds : undefined);
        yield put(updateLabError(error));
    }
}
export function* updateLabSaga() {
    yield labParentBasedTakeLatest(LabActionType.UPDATE_LAB, updateLab, updateVNextLab);
}
