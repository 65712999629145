import * as React from 'react';
import { connect } from 'react-redux';
import ErrorBoundary from './error-boundary';
import { fatalError } from '../redux/actions/common/common-action-creators';
import { ErrorCode } from '../common/error-codes';
class ErrorBoundaryContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this.setUnhandledError = this.setUnhandledError.bind(this);
    }
    setUnhandledError(error) {
        this.props.fatalError(ErrorCode.GenericError, error);
    }
    render() {
        const { hasError, children } = this.props;
        return (<ErrorBoundary hasError={hasError} setUnhandledError={this.setUnhandledError}>
                {children}
            </ErrorBoundary>);
    }
}
const mapStateToProps = (state) => {
    const commonStore = state.get('commonStore');
    const fatalErrorCode = commonStore.get('fatalErrorCode');
    const hasError = fatalErrorCode !== undefined;
    return {
        hasError,
    };
};
const mapDispatchToProps = {
    fatalError,
};
export const ErrorBoundaryContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorBoundaryContainerInjected);
export default ErrorBoundaryContainer;
