import Routes from '../../utils/routes';
import { isLabParentLabAccount } from '../../redux/selectors/lab-parent-resource-selectors';
export function getLabListRoute(id) {
    if (isLabParentLabAccount(id)) {
        return Routes.LabList(id);
    }
    else {
        return Routes.VNextLabList(id);
    }
}
