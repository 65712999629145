import * as React from 'react';
import { Link } from '@fluentui/react';
export class FileUploadLink extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }
    render() {
        const { labelText, accept, onChange } = this.props;
        return (<Link onClick={() => this.inputRef.current && this.inputRef.current.click()}>
                {labelText}
                <input id="upload" type="file" accept={accept} style={{ display: 'none' }} ref={this.inputRef} onChange={onChange}/>
            </Link>);
    }
}
export default FileUploadLink;
