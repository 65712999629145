import * as React from 'react';
export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidCatch(error, _info) {
        this.props.setUnhandledError(error);
    }
    render() {
        if (this.props.hasError) {
            return <></>;
        }
        else {
            return this.props.children;
        }
    }
}
export default ErrorBoundary;
