import { createVNextArmApiClient } from '../../arm-data-provider';
export async function listUsage(subscriptionId, location, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(subscriptionId, accessToken, locale, language);
    let currentPage = await armApiClient.usages.listByLocation(location);
    let usage = currentPage;
    while (!!currentPage.nextLink) {
        const nextPage = await armApiClient.usages.listByLocationNext(currentPage.nextLink);
        currentPage = nextPage;
        usage = usage.concat(nextPage);
    }
    return { region: location, usage };
}
const UsageProvider = {
    listUsage,
};
export default UsageProvider;
