import { CommonActionType } from '../common/common-actions';
export const IdentityActionType = {
    ...CommonActionType,
    SIGN_IN: 'SIGN_IN',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_REDIRECTING: 'SIGN_IN_REDIRECTING',
    SIGN_IN_FAILED: 'SIGN_IN_FAILED',
    SIGN_IN_ERROR: 'SIGN_IN_ERROR',
    SIGN_OUT: 'SIGN_OUT',
    SIGN_OUT_ERROR: 'SIGN_OUT_ERROR',
    RESOLVE_SIGN_IN_RETURN_URL: 'RESOLVE_SIGN_IN_RETURN_URL',
    LOAD_USER_PHOTO: 'LOAD_USER_PHOTO',
    LOAD_USER_PHOTO_SUCCESS: 'LOAD_USER_PHOTO_SUCCESS',
    LOAD_USER_PHOTO_FAILED: 'LOAD_USER_PHOTO_FAILED',
    LOAD_USER_PHOTO_ERROR: 'LOAD_USER_PHOTO_ERROR',
    LOAD_USER_SETTINGS: 'LOAD_USER_SETTINGS',
    LOAD_USER_SETTINGS_SUCCESS: 'LOAD_USER_SETTINGS_SUCCESS',
    LOAD_USER_SETTINGS_ERROR: 'LOAD_USER_SETTINGS_ERROR',
    UPDATE_USER_SETTINGS: 'UPDATE_USER_SETTINGS',
    UPDATE_USER_SETTINGS_SUCCESS: 'UPDATE_USER_SETTINGS_SUCCESS',
    UPDATE_USER_SETTINGS_ERROR: 'UPDATE_USER_SETTINGS_ERROR',
    UPDATE_USER_SETTINGS_EXISTS: 'UPDATE_USER_SETTINGS_EXISTS',
    UPDATE_USER_SETTINGS_CANCELLED: 'UPDATE_USER_SETTINGS_CANCELLED',
    GET_ACCESS_TOKEN: 'GET_ACCESS_TOKEN',
    GET_ACCESS_TOKEN_SUCCESS: 'GET_ACCESS_TOKEN_SUCCESS',
    GET_ACCESS_TOKEN_REDIRECTING: 'GET_ACCESS_TOKEN_REDIRECTING',
    GET_ACCESS_TOKEN_FAILED: 'GET_ACCESS_TOKEN_FAILED',
    GET_ACCESS_TOKEN_ERROR: 'GET_ACCESS_TOKEN_ERROR',
    SET_TENANT_ID: 'SET_TENANT_ID',
    SET_LOGIN_HINT: 'SET_LOGIN_HINT',
    REQUEST_USER_CONSENT: 'REQUEST_USER_CONSENT',
    REQUEST_USER_CONSENT_SUCCESS: 'REQUEST_USER_CONSENT_SUCCESS',
    REQUEST_USER_CONSENT_FAILED: 'REQUEST_USER_CONSENT_FAILED',
    REQUEST_USER_CONSENT_ERROR: 'REQUEST_USER_CONSENT_ERROR',
    SIGN_IN_POPPING_UP: 'SIGN_IN_POPPING_UP',
    LMS_COMPLETE_SIGN_OUT: 'LMS_COMPLETE_SIGN_OUT',
};
