import * as React from 'react';
import _ from 'lodash';
import { Icon, PrimaryButton, Stack } from '@fluentui/react';
import { Link } from 'react-router-dom';
import { getCustomTheme } from '../common/themes/selectors';
import './full-page-message.css';
export const FullPageMessage = (props) => {
    const { image, message, messageDetails, buttonText, buttonLinkPath, imageSize, styles, buttonOnClick, messageDetailsMaxWidth, } = props;
    const { primaryBackgroundColor } = getCustomTheme();
    const stackStyles = _.merge({}, { root: { backgroundColor: primaryBackgroundColor } }, styles);
    return (<Stack verticalAlign="center" horizontalAlign="center" tokens={{ childrenGap: '40px' }} styles={stackStyles} grow verticalFill>
            <div id="full-page-message-container">
                <Stack.Item>
                    <Icon iconName={image} className="full-page-message__icon" styles={{
        root: {
            selectors: {
                svg: { height: imageSize?.height ?? 300, width: imageSize?.width ?? 300 },
            },
        },
    }}/>
                    <div className="full-page-message__message ms-fontSize-xlPlus ms-fontWeight-semibold">
                        {message}
                    </div>
                </Stack.Item>
                <Stack.Item styles={{ root: { maxWidth: messageDetailsMaxWidth } }}>
                    {messageDetails && <div className="full-page-message__details ms-fontSize-m">{messageDetails}</div>}
                </Stack.Item>
                <Stack.Item>
                    {buttonText && buttonLinkPath && (<Link to={buttonLinkPath} className="full-page-message__link">
                            <PrimaryButton text={buttonText} onClick={buttonOnClick}/>
                        </Link>)}
                </Stack.Item>
                <Stack.Item styles={{ root: { marginTop: '20px' } }}>
                    {buttonText && buttonOnClick && <PrimaryButton text={buttonText} onClick={buttonOnClick}/>}
                </Stack.Item>
            </div>
        </Stack>);
};
export default FullPageMessage;
