import { disableSaveButton } from '../../../data/providers/teams-provider';
import { call, takeLatest, put } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { TeamsActionType } from '../../actions/teams/teams-actions';
import { teamsDisableSaveButtonError, teamsDisableSaveButtonSuccess } from '../../actions/teams/teams-action-creators';
export function* teamsDisableSaveButton(action) {
    try {
        yield call(disableSaveButton);
        yield put(teamsDisableSaveButtonSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.TeamsDisableSaveButton, err ? err.code : undefined);
        yield put(teamsDisableSaveButtonError(error));
    }
}
export function* disableSaveButtonSaga() {
    yield takeLatest(TeamsActionType.TEAMS_DISABLE_SAVE_BUTTON, teamsDisableSaveButton);
}
