import * as React from 'react';
import { List } from 'immutable';
import _ from 'lodash';
import { findWindows } from 'windows-iana';
import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales';
import { trackTrace } from './telemetry/telemetry-channel';
export function flattenListMap(map) {
    let values = [];
    map.forEach((listValue) => {
        values = [...values, ...listValue.toArray()];
    });
    return values;
}
export function addToListMap(map, itemToAdd, id) {
    let itemsForId = map.get(id.toLowerCase()) || List([]);
    if (!itemsForId.some((item) => _.isEqual(item, itemToAdd))) {
        itemsForId = itemsForId.concat(itemToAdd);
    }
    return map.set(id.toLowerCase(), itemsForId);
}
export function removeFromMap(map, itemToRemove, id) {
    const itemsForId = map.get(id.toLowerCase());
    if (!itemsForId) {
        return map;
    }
    const newItemsForId = itemsForId.filter((item) => !_.isEqual(itemToRemove, item));
    return map.set(id.toLowerCase(), newItemsForId);
}
export function compareByName(a, b) {
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
    }
    else if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
    }
    else {
        return 0;
    }
}
export function compareByText(a, b) {
    if (a.text.toLowerCase() < b.text.toLowerCase()) {
        return -1;
    }
    else if (a.text.toLowerCase() > b.text.toLowerCase()) {
        return 1;
    }
    else {
        return 0;
    }
}
export function guessTimezone() {
    const guess = moment.tz.guess();
    const windowsTimeZone = findWindows(guess);
    return windowsTimeZone;
}
/** For use in functional components to compare old value with new value */
export function usePrevious(value) {
    const ref = React.useRef();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}
/**
 * Returns number like 1200 in the form of 1.2k
 * Rounds up
 */
export const shortenNumber = (num, locale, intl) => {
    let shortened = '';
    try {
        // some of the languages codes in numeral.js are wrong :(
        if (locale.startsWith('zh')) {
            numeral.locale('chs');
        }
        else if (locale.startsWith('be')) {
            numeral.locale('nl');
        }
        else {
            numeral.locale(locale);
        }
        shortened =
            num > 999
                ? numeral(num).format('0.0a')
                : intl.formatNumber(num, {
                    maximumFractionDigits: 1,
                });
    }
    catch (e) {
        trackTrace(`common.ts: unable to load locale ${locale} for numeral.js, unable to shorten number`);
        shortened = intl.formatNumber(num, {
            maximumFractionDigits: 1,
        });
    }
    return shortened;
};
const CommonUtilities = {
    flattenListMap,
    addToListMap,
    removeFromMap,
    compareByName,
    guessTimezone,
    usePrevious,
    shortenNumber,
};
export default CommonUtilities;
