import { takeLatest, put, call } from 'redux-saga/effects';
import { getGroupNameById } from '../../../data/providers/group-provider';
import { getGroupNameError, getGroupNameFailed, getGroupNameSuccess } from '../../actions/group/group-action-creators';
import { GroupActionType } from '../../actions/group/group-actions';
import { getGraphAccessTokenSaga } from '../identity/access-tokens';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
export function* getGroupName(action) {
    const { groupId } = action;
    try {
        const accessToken = yield call(getGraphAccessTokenSaga);
        const groupName = yield call(getGroupNameById, accessToken, groupId);
        if (!groupName) {
            yield put(getGroupNameFailed());
        }
        else {
            yield put(getGroupNameSuccess(groupName));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, groupId, FailureOperation.GetGroupName, err ? err.code : undefined);
        yield put(getGroupNameError(error));
    }
}
export function* getGroupNameSaga() {
    yield takeLatest(GroupActionType.GET_GROUP_NAME, getGroupName);
}
