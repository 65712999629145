import { TeamsActionType, } from './teams-actions';
export const teamsInitialize = () => ({
    type: TeamsActionType.TEAMS_INITIALIZE,
});
export const teamsInitializeSuccess = () => ({
    type: TeamsActionType.TEAMS_INITIALIZE_SUCCESS,
});
export const teamsInitializeError = (error) => ({
    type: TeamsActionType.TEAMS_INITIALIZE_ERROR,
    error,
});
export const teamsDisableSaveButton = () => ({
    type: TeamsActionType.TEAMS_DISABLE_SAVE_BUTTON,
});
export const teamsDisableSaveButtonSuccess = () => ({
    type: TeamsActionType.TEAMS_DISABLE_SAVE_BUTTON_SUCCESS,
});
export const teamsDisableSaveButtonError = (disableSaveButtonError) => ({
    type: TeamsActionType.TEAMS_DISABLE_SAVE_BUTTON_ERROR,
    disableSaveButtonError,
});
export const teamsConfigureTab = (labAccountId) => ({
    type: TeamsActionType.TEAMS_CONFIGURE_TAB,
    labAccountId,
});
export const teamsConfigureTabSuccess = () => ({
    type: TeamsActionType.TEAMS_CONFIGURE_TAB_SUCCESS,
});
export const teamsConfigureTabError = (configureTabError) => ({
    type: TeamsActionType.TEAMS_CONFIGURE_TAB_ERROR,
    configureTabError,
});
export const teamsStartMfa = () => ({
    type: TeamsActionType.TEAMS_START_MFA,
});
export const teamsStartMfaError = (error) => ({
    type: TeamsActionType.TEAMS_START_MFA_ERROR,
    error,
});
export const teamsStartMfaRedirecting = () => ({
    type: TeamsActionType.TEAMS_START_MFA_REDIRECTING,
});
export const teamsCompleteMfa = () => ({
    type: TeamsActionType.TEAMS_COMPLETE_MFA,
});
export const teamsCompleteMfaSuccess = () => ({
    type: TeamsActionType.TEAMS_COMPLETE_MFA_SUCCESS,
});
export const teamsCompleteMfaFailed = () => ({
    type: TeamsActionType.TEAMS_COMPLETE_MFA_FAILED,
});
export const teamsCompleteMfaError = (error) => ({
    type: TeamsActionType.TEAMS_COMPLETE_MFA_ERROR,
    error,
});
export const setUserTeamRole = (userTeamRole) => ({
    type: TeamsActionType.SET_USER_TEAM_ROLE,
    userTeamRole,
});
export const setTeamsChannelType = (channelType) => ({
    type: TeamsActionType.SET_TEAMS_CHANNEL_TYPE,
    channelType,
});
export const setTeamsTheme = (theme) => ({
    type: TeamsActionType.SET_TEAMS_THEME,
    theme,
});
const actions = {
    teamsInitialize,
    teamsInitializeSuccess,
    teamsInitializeError,
    teamsDisableSaveButton,
    teamsDisableSaveButtonSuccess,
    teamsDisableSaveButtonError,
    teamsConfigureTab,
    teamsConfigureTabSuccess,
    teamsConfigureTabError,
    teamsStartMfa,
    teamsStartMfaError,
    teamsStartMfaRedirecting,
    teamsCompleteMfa,
    teamsCompleteMfaSuccess,
    teamsCompleteMfaFailed,
    teamsCompleteMfaError,
    setUserTeamRole,
    setTeamsChannelType,
    setTeamsTheme,
};
export default actions;
