import { defineMessages } from 'react-intl';
const messages = defineMessages({
    requiredError: {
        id: 'LabNameValidatorRequiredError',
        defaultMessage: 'Lab name is required.',
        description: 'Error shown for invalid lab name values.',
    },
    specialCharsError: {
        id: 'LabNameValidatorSpecialCharsError',
        defaultMessage: 'Lab name cannot contain special characters.',
        description: 'Error shown for invalid lab name values.',
    },
    duplicateNameError: {
        id: 'LabNameValidatorDuplicateNameError',
        defaultMessage: 'A lab with this name already exists.',
        description: 'Error shown for invalid lab name values.',
    },
});
const labNameRegex = /^[^<>%&:\\?/@#$^*().;+=~`\u0000—\u001F]+$/;
export function validateLabName(name, labNames, intl) {
    if (!name || name.trim().length === 0) {
        return intl.formatMessage(messages.requiredError);
    }
    else if (!labNameRegex.test(name)) {
        return intl.formatMessage(messages.specialCharsError);
    }
    else if (labNames.some((o) => o.toLowerCase() === name.toLowerCase())) {
        return intl.formatMessage(messages.duplicateNameError);
    }
    else {
        return undefined;
    }
}
