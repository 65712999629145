import { call, put, takeLatest } from 'redux-saga/effects';
import { getLtiToken } from '../../../data/providers/lti-provider';
import { ErrorCode } from '../../../common/error-codes';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { IdentityErrorCode } from '../../../data/models/identity';
import { fatalError } from '../../actions/common/common-action-creators';
import { decodeLtiTokenPayload } from '../../selectors/lti-selectors';
import { LtiActionType } from '../../actions/lti/lti-actions';
import { getLtiTokenError, getLtiToken as getLtiTokenActionCreator, getLtiTokenSuccess, } from '../../actions/lti/lti-action-creators';
import { createTokenPromise, tokenPromiseWait } from '../identity/access-tokens';
export const expirationTimeField = 'exp';
export function* getLtiTokenRequest(action) {
    const { resolve, reject } = action;
    try {
        const token = yield call(getLtiToken);
        const decodedPayload = yield call(decodeLtiTokenPayload, token);
        const exp = decodedPayload[expirationTimeField];
        if (!exp || Date.now() >= exp * 1000) {
            const error = new MlClientError('lti token expired', '', FailureOperation.GetLtiToken, ErrorCode.LtiTokenExpired);
            yield put(getLtiTokenError(error));
            yield put(fatalError(ErrorCode.LtiTokenExpired, error));
            yield call(reject, error);
        }
        else {
            yield put(getLtiTokenSuccess());
            yield call(resolve, token);
        }
    }
    catch (err) {
        const errorCode = IdentityErrorCode.Failed;
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.GetLtiToken, err ? err.code : errorCode);
        yield put(getLtiTokenError(error));
        yield put(fatalError(ErrorCode.GenericError, error));
        yield call(reject, error);
    }
}
export function* getLtiTokenSaga() {
    const { resolve, reject, promise } = (yield call(createTokenPromise));
    yield put(getLtiTokenActionCreator(resolve, reject));
    const token = yield call(tokenPromiseWait, promise);
    return token;
}
export function* getLtiTokenRequestSaga() {
    yield takeLatest(LtiActionType.GET_LTI_TOKEN, getLtiTokenRequest);
}
