import { call, put, select, takeLatest } from 'redux-saga/effects';
import { fatalError, setTheme } from '../../actions/common/common-action-creators';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { initialize, oidcAuth } from '../../../data/providers/lti-provider';
import { getQueryParams } from '../../../utils/query-params';
import { ltiStartOidcError, ltiStartOidcRedirecting } from '../../actions/lti/lti-action-creators';
import { LtiActionType } from '../../actions/lti/lti-actions';
import { getSearch } from '../../selectors/route-selector';
import Theme from '../../../utils/themes';
export function* ltiStartOidc(action) {
    try {
        yield put(setTheme(Theme.LmsBasics));
        // Commenting out this step as it's causing bug in Safari in Firefox, leaving in for context/potential refactoring
        /*
        try {
            
            const hasAccess = yield call(hasStorageAccess);
            const isEdge = yield call(isEdgeBrowser);
            if (!hasAccess && !isEdge) {
                const error = new MlClientError(
                    'Lti: Users need to request web storage access manually',
                    '',
                    FailureOperation.LtiStartOidc,
                    ErrorCode.LtiRequestStorageAccessNeeded
                );
                yield put(ltiStartOidcError(error));
                yield put(fatalError(ErrorCode.LtiRequestStorageAccessNeeded, error));
                return;
            }
        } catch (e) {
            // do nothing if storage access api is not supported in the browser and we will catch storage access error when call initialize below
        }
        */
        yield call(initialize);
        const search = yield select(getSearch);
        const queryParams = yield call(getQueryParams, search);
        if (!!queryParams.ltiAuthUrl) {
            yield call(oidcAuth, queryParams.ltiAuthUrl);
            yield put(ltiStartOidcRedirecting());
        }
        else {
            const error = new MlClientError('LtiOidc: The required LTI auth URL query parameter is missing.', '', FailureOperation.LtiStartOidc);
            yield put(ltiStartOidcError(error));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.LtiStartOidc, err ? err.code : undefined);
        yield put(ltiStartOidcError(error));
        yield put(fatalError(err.code, error));
    }
}
export function* ltiStartOidcSaga() {
    yield takeLatest(LtiActionType.LTI_START_OIDC, ltiStartOidc);
}
