import { ResourceId } from '../../../../utils/resource-id';
import { createLabsApiClient } from './labs-api-client-factory';
export async function getCoreUsage(labParentId, accessToken, locale, language) {
    const id = labParentId instanceof ResourceId ? labParentId : new ResourceId(labParentId);
    const client = createLabsApiClient(id.subscriptionId, accessToken, locale, language);
    const result = await client.coreUsage.get();
    return result;
}
const CoreUsageProvider = {
    getCoreUsage,
};
export default CoreUsageProvider;
