import _ from 'lodash';
import { ResourceId } from '../../utils/resource-id';
import { newLabRoles } from '../models/roles';
import { getMlClient } from './arm-data-provider';
export async function listSubscriptionLabAccounts(subscription, accessToken, locale, language) {
    const client = getMlClient(subscription.subscriptionId, accessToken, locale, language);
    const labAccounts = await client.labAccounts.listBySubscription();
    const result = _.uniqBy(labAccounts, (labAccount) => labAccount.id.toLowerCase());
    return result;
}
export async function getCoreRestrictionsAndUsages(labAccountId, accessToken, locale, language) {
    const id = labAccountId instanceof ResourceId ? labAccountId : new ResourceId(labAccountId);
    const client = getMlClient(id.subscriptionId, accessToken, locale, language);
    const result = await client.labAccounts.getRestrictionsAndUsage(id.resourceGroupName, id.name);
    return result;
}
export async function getLabAccount(labAccountId, accessToken, locale, language) {
    const id = labAccountId instanceof ResourceId ? labAccountId : new ResourceId(labAccountId);
    const client = getMlClient(id.subscriptionId, accessToken, locale, language);
    const result = await client.labAccounts.get(id.resourceGroupName, id.name);
    return result;
}
export async function listGalleryImages(labAccountId, accessToken, locale, language) {
    const id = labAccountId instanceof ResourceId ? labAccountId : new ResourceId(labAccountId);
    const client = getMlClient(id.subscriptionId, accessToken, locale, language);
    const result = await client.galleryImages.list(id.resourceGroupName, id.name, {
        filter: `(properties/isEnabled)`,
    });
    return result;
}
export async function createLab(labAccountId, props, accessToken, locale, language) {
    // trimming the lab name so that we don't create labs with trailing spaces
    // even though the RP allows it, due to other RP bugs (can't delete or adjust capacity)
    const labParameters = props.labParameters
        ? {
            ...props.labParameters,
            username: props.labParameters.username ? props.labParameters.username.trim() : undefined,
            password: props.labParameters.password ? props.labParameters.password.trim() : undefined,
        }
        : undefined;
    const createProps = { ...props, name: props.name.trim(), labParameters };
    const id = labAccountId instanceof ResourceId ? labAccountId : new ResourceId(labAccountId);
    const client = getMlClient(id.subscriptionId, accessToken, locale, language);
    const lab = await client.labAccounts.createLab(id.resourceGroupName, id.name, createProps);
    return { ...lab, roles: [...newLabRoles] };
}
export async function getPricingAndAvailability(labAccountId, accessToken, locale, language) {
    const id = labAccountId instanceof ResourceId ? labAccountId : new ResourceId(labAccountId);
    const client = getMlClient(id.subscriptionId, accessToken, locale, language);
    const result = await client.labAccounts.getPricingAndAvailability(id.resourceGroupName, id.name);
    return result.operatingSystems || {};
}
const LabAccountProvider = {
    listSubscriptionLabAccounts,
    getLabAccount,
    listGalleryImages,
    createLab,
    getCoreRestrictionsAndUsages,
};
export default LabAccountProvider;
