import { MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { RequestLimitIncreaseInSentenceLink } from '../common/request-limit-increase-link';
import { CoreQuotaStatus, determineVCurrentOrVNextLabRemainingVms, determineVCurrentQuotaStatus, } from '../redux/selectors/core-limit-selectors';
import { getLabCapacity, isVNextLab } from '../redux/selectors/lab-selectors';
export const QuotaBanner = (props) => {
    const { lab, template, coreQuotaData, currentTenantId, isGroupSyncModeEnabled, styles } = props;
    if (!template || !lab || !coreQuotaData) {
        return <></>;
    }
    if (template.isPublished || template.isPublishing || template.isScaling) {
        return <></>;
    }
    const isVNext = isVNextLab(lab.id);
    const quotaStatus = !isVNext ? determineVCurrentQuotaStatus(coreQuotaData) : undefined;
    if (quotaStatus === CoreQuotaStatus.Exempt) {
        return <></>;
    }
    if (quotaStatus === CoreQuotaStatus.Restricted) {
        return (<MessageBar messageBarType={MessageBarType.blocked}>
                <FormattedMessage id="NoCoresForSubscriptionPublishMessage" defaultMessage="Your admin needs to request a limit increase in Azure Lab Services for virtual machine cores before you can publish this lab. Please contact your Lab Services administator." description="Message displayed on template page when customer subscription type has no cores available by default and cannot publish."/>
            </MessageBar>);
    }
    const usersInLab = getLabCapacity(lab);
    const remainingVmsInSubscription = determineVCurrentOrVNextLabRemainingVms(isVNext, template.isGpu, template.cores, coreQuotaData, getLabCapacity(lab));
    if (quotaStatus === CoreQuotaStatus.QuotaExceeded || remainingVmsInSubscription === 0) {
        return (<MessageBar messageBarType={MessageBarType.blocked} styles={styles}>
                <FormattedMessage id="QuotaExceededForPublishMessage" defaultMessage="Your account has reached its virtual machine cores limit in Azure Lab Services. Before you can publish, you need to {requestLimitIncreaseLink}. You can expect the process to take 1-5 days. Due to high demand, some SKUs may be temporarily unavailable." description="Message displayed on template when customer subscription has exceeded its quota and cannot publish a lab. {requestLimitIncreaseLink} is a link with id 'SupportRequestLinkRequest' with instructions on how to increase quota for the subscription." values={{
            requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labId={lab?.id} tenantId={currentTenantId}/>),
        }}/>
            </MessageBar>);
    }
    else if (isGroupSyncModeEnabled &&
        usersInLab !== undefined &&
        remainingVmsInSubscription !== undefined &&
        usersInLab > remainingVmsInSubscription) {
        return (<MessageBar messageBarType={MessageBarType.blocked} styles={styles}>
                <FormattedMessage id="UsersExceededRemainingVmsForPublishMessage" defaultMessage="Virtual machines are needed for {usersInLab} users but only {remainingVmsInSubscription} machines are currently available in your organization. Before you can publish, you need to {requestLimitIncreaseLink}. You can expect the process to take 1-5 days. Due to high demand, some SKUs may be temporarily unavailable." description="Message displayed on template when number of users in a AAD group synced lab exceeds the number of remaining VMs and cannot publish a lab. {usersInLab} is the number of users in the lab, {remainingVmsInSubscription} is the number of VMs remaining in the subscription that could be published immediately, {requestLimitIncreaseLink} is a link with id 'SupportRequestLinkRequest' with instructions on how to increase quota for the subscription." values={{
            usersInLab,
            remainingVmsInSubscription,
            requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labId={lab?.id} tenantId={currentTenantId}/>),
        }}/>
            </MessageBar>);
    }
    const showGettingCloseBanner = remainingVmsInSubscription === undefined ? false : remainingVmsInSubscription <= 10;
    if (showGettingCloseBanner) {
        return (<MessageBar messageBarType={MessageBarType.warning} styles={styles}>
                <FormattedMessage id="PublishGettingCloseToLimitsBannerMessage" defaultMessage="Your account is getting close to reaching its cores limit in Azure Lab Services. Currently, you can only publish {numberOfVms} virtual machines. To publish more, please {requestLimitIncreaseLink}. You can expect the process to take 1-5 days. Due to high demand, some SKUs may be temporarily unavailable." description="Message displayed on template when customer subscription is getting close to its core limit. {requestLimitIncreaseLink} is a link with id 'SupportRequestLinkRequest' with instructions on how to increase quota for the subscription." values={{
            numberOfVms: remainingVmsInSubscription,
            requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labId={lab?.id} tenantId={currentTenantId}/>),
        }}/>
            </MessageBar>);
    }
    return <></>;
};
export default QuotaBanner;
