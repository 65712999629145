import { defineMessages } from 'react-intl';
export const timezoneMessages = defineMessages({
    datelineStandardTime: {
        id: 'Dateline Standard Time',
        defaultMessage: `International Date Line West`,
        description: `Label for the time zone 'International Date Line West'`,
    },
    uTC11: {
        id: 'UTC-11',
        defaultMessage: `Coordinated Universal Time-11`,
        description: `Label for the time zone 'Coordinated Universal Time-11'`,
    },
    hawaiianStandardTime: {
        id: 'Hawaiian Standard Time',
        defaultMessage: `Hawaii`,
        description: `Label for the time zone 'Hawaii'`,
    },
    alaskanStandardTime: {
        id: 'Alaskan Standard Time',
        defaultMessage: `Alaska`,
        description: `Label for the time zone 'Alaska'`,
    },
    pacificStandardTimeMexico: {
        id: 'Pacific Standard Time (Mexico)',
        defaultMessage: `Baja California`,
        description: `Label for the time zone 'Baja California'`,
    },
    pacificStandardTime: {
        id: 'Pacific Standard Time',
        defaultMessage: `Pacific Time (US, Canada)`,
        description: `Label for the time zone 'Pacific Time (US, Canada)'`,
    },
    uSMountainStandardTime: {
        id: 'US Mountain Standard Time',
        defaultMessage: `Arizona`,
        description: `Label for the time zone 'Arizona'`,
    },
    mountainStandardTimeMexico: {
        id: 'Mountain Standard Time (Mexico)',
        defaultMessage: `Chihuahua, La Paz, Mazatlan`,
        description: `Label for the time zone 'Chihuahua, La Paz, Mazatlan'`,
    },
    mountainStandardTime: {
        id: 'Mountain Standard Time',
        defaultMessage: `Mountain Time (US, Canada)`,
        description: `Label for the time zone 'Mountain Time (US, Canada)'`,
    },
    centralAmericaStandardTime: {
        id: 'Central America Standard Time',
        defaultMessage: `Central America`,
        description: `Label for the time zone 'Central America'`,
    },
    centralStandardTime: {
        id: 'Central Standard Time',
        defaultMessage: `Central Time (US, Canada)`,
        description: `Label for the time zone 'Central Time (US, Canada)'`,
    },
    centralStandardTimeMexico: {
        id: 'Central Standard Time (Mexico)',
        defaultMessage: `Guadalajara, Mexico City, Monterrey`,
        description: `Label for the time zone 'Guadalajara, Mexico City, Monterrey'`,
    },
    canadaCentralStandardTime: {
        id: 'Canada Central Standard Time',
        defaultMessage: `Saskatchewan`,
        description: `Label for the time zone 'Saskatchewan'`,
    },
    sAPacificStandardTime: {
        id: 'SA Pacific Standard Time',
        defaultMessage: `Bogota, Lima, Quito, Rio Branco`,
        description: `Label for the time zone 'Bogota, Lima, Quito, Rio Branco'`,
    },
    easternStandardTimeMexico: {
        id: 'Eastern Standard Time (Mexico)',
        defaultMessage: `Chetumal`,
        description: `Label for the time zone 'Chetumal'`,
    },
    easternStandardTime: {
        id: 'Eastern Standard Time',
        defaultMessage: `Eastern Time (US, Canada)`,
        description: `Label for the time zone 'Eastern Time (US, Canada)'`,
    },
    uSEasternStandardTime: {
        id: 'US Eastern Standard Time',
        defaultMessage: `Indiana (East)`,
        description: `Label for the time zone 'Indiana (East)'`,
    },
    venezuelaStandardTime: {
        id: 'Venezuela Standard Time',
        defaultMessage: `Caracas`,
        description: `Label for the time zone 'Caracas'`,
    },
    paraguayStandardTime: {
        id: 'Paraguay Standard Time',
        defaultMessage: `Asuncion`,
        description: `Label for the time zone 'Asuncion'`,
    },
    atlanticStandardTime: {
        id: 'Atlantic Standard Time',
        defaultMessage: `Atlantic Time (Canada)`,
        description: `Label for the time zone 'Atlantic Time (Canada)'`,
    },
    centralBrazilianStandardTime: {
        id: 'Central Brazilian Standard Time',
        defaultMessage: `Cuiaba`,
        description: `Label for the time zone 'Cuiaba'`,
    },
    sAWesternStandardTime: {
        id: 'SA Western Standard Time',
        defaultMessage: `Georgetown, La Paz, Manaus, San Juan`,
        description: `Label for the time zone 'Georgetown, La Paz, Manaus, San Juan'`,
    },
    pacificSAStandardTime: {
        id: 'Pacific SA Standard Time',
        defaultMessage: `Santiago`,
        description: `Label for the time zone 'Santiago'`,
    },
    newfoundlandStandardTime: {
        id: 'Newfoundland Standard Time',
        defaultMessage: `Newfoundland`,
        description: `Label for the time zone 'Newfoundland'`,
    },
    eSouthAmericaStandardTime: {
        id: 'E. South America Standard Time',
        defaultMessage: `Brasilia`,
        description: `Label for the time zone 'Brasilia'`,
    },
    sAEasternStandardTime: {
        id: 'SA Eastern Standard Time',
        defaultMessage: `Cayenne, Fortaleza`,
        description: `Label for the time zone 'Cayenne, Fortaleza'`,
    },
    argentinaStandardTime: {
        id: 'Argentina Standard Time',
        defaultMessage: `City of Buenos Aires`,
        description: `Label for the time zone 'City of Buenos Aires'`,
    },
    greenlandStandardTime: {
        id: 'Greenland Standard Time',
        defaultMessage: `Greenland`,
        description: `Label for the time zone 'Greenland'`,
    },
    montevideoStandardTime: {
        id: 'Montevideo Standard Time',
        defaultMessage: `Montevideo`,
        description: `Label for the time zone 'Montevideo'`,
    },
    bahiaStandardTime: {
        id: 'Bahia Standard Time',
        defaultMessage: `Salvador`,
        description: `Label for the time zone 'Salvador'`,
    },
    uTC02: {
        id: 'UTC-02',
        defaultMessage: `Coordinated Universal Time-02`,
        description: `Label for the time zone 'Coordinated Universal Time-02'`,
    },
    azoresStandardTime: {
        id: 'Azores Standard Time',
        defaultMessage: `Azores`,
        description: `Label for the time zone 'Azores'`,
    },
    capeVerdeStandardTime: {
        id: 'Cape Verde Standard Time',
        defaultMessage: `Cabo Verde Is.`,
        description: `Label for the time zone 'Cabo Verde Is.'`,
    },
    moroccoStandardTime: {
        id: 'Morocco Standard Time',
        defaultMessage: `Casablanca`,
        description: `Label for the time zone 'Casablanca'`,
    },
    uTC: {
        id: 'UTC',
        defaultMessage: `Coordinated Universal Time`,
        description: `Label for the time zone 'Coordinated Universal Time'`,
    },
    gMTStandardTime: {
        id: 'GMT Standard Time',
        defaultMessage: `Dublin, Edinburgh, Lisbon, London`,
        description: `Label for the time zone 'Dublin, Edinburgh, Lisbon, London'`,
    },
    greenwichStandardTime: {
        id: 'Greenwich Standard Time',
        defaultMessage: `Monrovia, Reykjavik`,
        description: `Label for the time zone 'Monrovia, Reykjavik'`,
    },
    wEuropeStandardTime: {
        id: 'W. Europe Standard Time',
        defaultMessage: `Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna`,
        description: `Label for the time zone 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna'`,
    },
    centralEuropeStandardTime: {
        id: 'Central Europe Standard Time',
        defaultMessage: `Belgrade, Bratislava, Budapest, Ljubljana, Prague`,
        description: `Label for the time zone 'Belgrade, Bratislava, Budapest, Ljubljana, Prague'`,
    },
    romanceStandardTime: {
        id: 'Romance Standard Time',
        defaultMessage: `Brussels, Copenhagen, Madrid, Paris`,
        description: `Label for the time zone 'Brussels, Copenhagen, Madrid, Paris'`,
    },
    centralEuropeanStandardTime: {
        id: 'Central European Standard Time',
        defaultMessage: `Sarajevo, Skopje, Warsaw, Zagreb`,
        description: `Label for the time zone 'Sarajevo, Skopje, Warsaw, Zagreb'`,
    },
    wCentralAfricaStandardTime: {
        id: 'W. Central Africa Standard Time',
        defaultMessage: `West Central Africa`,
        description: `Label for the time zone 'West Central Africa'`,
    },
    namibiaStandardTime: {
        id: 'Namibia Standard Time',
        defaultMessage: `Windhoek`,
        description: `Label for the time zone 'Windhoek'`,
    },
    jordanStandardTime: {
        id: 'Jordan Standard Time',
        defaultMessage: `Amman`,
        description: `Label for the time zone 'Amman'`,
    },
    gTBStandardTime: {
        id: 'GTB Standard Time',
        defaultMessage: `Athens, Bucharest`,
        description: `Label for the time zone 'Athens, Bucharest'`,
    },
    middleEastStandardTime: {
        id: 'Middle East Standard Time',
        defaultMessage: `Beirut`,
        description: `Label for the time zone 'Beirut'`,
    },
    egyptStandardTime: {
        id: 'Egypt Standard Time',
        defaultMessage: `Cairo`,
        description: `Label for the time zone 'Cairo'`,
    },
    syriaStandardTime: {
        id: 'Syria Standard Time',
        defaultMessage: `Damascus`,
        description: `Label for the time zone 'Damascus'`,
    },
    eEuropeStandardTime: {
        id: 'E. Europe Standard Time',
        defaultMessage: `E. Europe`,
        description: `Label for the time zone 'E. Europe'`,
    },
    southAfricaStandardTime: {
        id: 'South Africa Standard Time',
        defaultMessage: `Harare, Pretoria`,
        description: `Label for the time zone 'Harare, Pretoria'`,
    },
    fLEStandardTime: {
        id: 'FLE Standard Time',
        defaultMessage: `Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius`,
        description: `Label for the time zone 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius'`,
    },
    israelStandardTime: {
        id: 'Israel Standard Time',
        defaultMessage: `Jerusalem`,
        description: `Label for the time zone 'Jerusalem'`,
    },
    kaliningradStandardTime: {
        id: 'Kaliningrad Standard Time',
        defaultMessage: `Kaliningrad (RTZ 1)`,
        description: `Label for the time zone 'Kaliningrad (RTZ 1)'`,
    },
    libyaStandardTime: {
        id: 'Libya Standard Time',
        defaultMessage: `Tripoli`,
        description: `Label for the time zone 'Tripoli'`,
    },
    arabicStandardTime: {
        id: 'Arabic Standard Time',
        defaultMessage: `Baghdad`,
        description: `Label for the time zone 'Baghdad'`,
    },
    turkeyStandardTime: {
        id: 'Turkey Standard Time',
        defaultMessage: `Istanbul`,
        description: `Label for the time zone 'Istanbul'`,
    },
    arabStandardTime: {
        id: 'Arab Standard Time',
        defaultMessage: `Kuwait, Riyadh`,
        description: `Label for the time zone 'Kuwait, Riyadh'`,
    },
    belarusStandardTime: {
        id: 'Belarus Standard Time',
        defaultMessage: `Minsk`,
        description: `Label for the time zone 'Minsk'`,
    },
    russianStandardTime: {
        id: 'Russian Standard Time',
        defaultMessage: `Moscow, St. Petersburg, Volgograd (RTZ 2)`,
        description: `Label for the time zone 'Moscow, St. Petersburg, Volgograd (RTZ 2)'`,
    },
    eAfricaStandardTime: {
        id: 'E. Africa Standard Time',
        defaultMessage: `Nairobi`,
        description: `Label for the time zone 'Nairobi'`,
    },
    iranStandardTime: {
        id: 'Iran Standard Time',
        defaultMessage: `Tehran`,
        description: `Label for the time zone 'Tehran'`,
    },
    arabianStandardTime: {
        id: 'Arabian Standard Time',
        defaultMessage: `Abu Dhabi, Muscat`,
        description: `Label for the time zone 'Abu Dhabi, Muscat'`,
    },
    azerbaijanStandardTime: {
        id: 'Azerbaijan Standard Time',
        defaultMessage: `Baku`,
        description: `Label for the time zone 'Baku'`,
    },
    russiaTimeZone3: {
        id: 'Russia Time Zone 3',
        defaultMessage: `Izhevsk, Samara (RTZ 3)`,
        description: `Label for the time zone 'Izhevsk, Samara (RTZ 3)'`,
    },
    mauritiusStandardTime: {
        id: 'Mauritius Standard Time',
        defaultMessage: `Port Louis`,
        description: `Label for the time zone 'Port Louis'`,
    },
    georgianStandardTime: {
        id: 'Georgian Standard Time',
        defaultMessage: `Tbilisi`,
        description: `Label for the time zone 'Tbilisi'`,
    },
    caucasusStandardTime: {
        id: 'Caucasus Standard Time',
        defaultMessage: `Yerevan`,
        description: `Label for the time zone 'Yerevan'`,
    },
    afghanistanStandardTime: {
        id: 'Afghanistan Standard Time',
        defaultMessage: `Kabul`,
        description: `Label for the time zone 'Kabul'`,
    },
    westAsiaStandardTime: {
        id: 'West Asia Standard Time',
        defaultMessage: `Ashgabat, Tashkent`,
        description: `Label for the time zone 'Ashgabat, Tashkent'`,
    },
    ekaterinburgStandardTime: {
        id: 'Ekaterinburg Standard Time',
        defaultMessage: `Ekaterinburg (RTZ 4)`,
        description: `Label for the time zone 'Ekaterinburg (RTZ 4)'`,
    },
    pakistanStandardTime: {
        id: 'Pakistan Standard Time',
        defaultMessage: `Islamabad, Karachi`,
        description: `Label for the time zone 'Islamabad, Karachi'`,
    },
    indiaStandardTime: {
        id: 'India Standard Time',
        defaultMessage: `Chennai, Kolkata, Mumbai, New Delhi`,
        description: `Label for the time zone 'Chennai, Kolkata, Mumbai, New Delhi'`,
    },
    sriLankaStandardTime: {
        id: 'Sri Lanka Standard Time',
        defaultMessage: `Sri Jayawardenepura`,
        description: `Label for the time zone 'Sri Jayawardenepura'`,
    },
    nepalStandardTime: {
        id: 'Nepal Standard Time',
        defaultMessage: `Kathmandu`,
        description: `Label for the time zone 'Kathmandu'`,
    },
    centralAsiaStandardTime: {
        id: 'Central Asia Standard Time',
        defaultMessage: `Astana`,
        description: `Label for the time zone 'Astana'`,
    },
    bangladeshStandardTime: {
        id: 'Bangladesh Standard Time',
        defaultMessage: `Dhaka`,
        description: `Label for the time zone 'Dhaka'`,
    },
    nCentralAsiaStandardTime: {
        id: 'N. Central Asia Standard Time',
        defaultMessage: `Novosibirsk (RTZ 5)`,
        description: `Label for the time zone 'Novosibirsk (RTZ 5)'`,
    },
    myanmarStandardTime: {
        id: 'Myanmar Standard Time',
        defaultMessage: `Yangon (Rangoon)`,
        description: `Label for the time zone 'Yangon (Rangoon)'`,
    },
    sEAsiaStandardTime: {
        id: 'SE Asia Standard Time',
        defaultMessage: `Bangkok, Hanoi, Jakarta`,
        description: `Label for the time zone 'Bangkok, Hanoi, Jakarta'`,
    },
    northAsiaStandardTime: {
        id: 'North Asia Standard Time',
        defaultMessage: `Krasnoyarsk (RTZ 6)`,
        description: `Label for the time zone 'Krasnoyarsk (RTZ 6)'`,
    },
    chinaStandardTime: {
        id: 'China Standard Time',
        defaultMessage: `Beijing, Chongqing, Hong Kong, Urumqi`,
        description: `Label for the time zone 'Beijing, Chongqing, Hong Kong, Urumqi'`,
    },
    northAsiaEastStandardTime: {
        id: 'North Asia East Standard Time',
        defaultMessage: `Irkutsk (RTZ 7)`,
        description: `Label for the time zone 'Irkutsk (RTZ 7)'`,
    },
    singaporeStandardTime: {
        id: 'Singapore Standard Time',
        defaultMessage: `Kuala Lumpur, Singapore`,
        description: `Label for the time zone 'Kuala Lumpur, Singapore'`,
    },
    wAustraliaStandardTime: {
        id: 'W. Australia Standard Time',
        defaultMessage: `Perth`,
        description: `Label for the time zone 'Perth'`,
    },
    taipeiStandardTime: {
        id: 'Taipei Standard Time',
        defaultMessage: `Taipei`,
        description: `Label for the time zone 'Taipei'`,
    },
    ulaanbaatarStandardTime: {
        id: 'Ulaanbaatar Standard Time',
        defaultMessage: `Ulaanbaatar`,
        description: `Label for the time zone 'Ulaanbaatar'`,
    },
    tokyoStandardTime: {
        id: 'Tokyo Standard Time',
        defaultMessage: `Osaka, Sapporo, Tokyo`,
        description: `Label for the time zone 'Osaka, Sapporo, Tokyo'`,
    },
    koreaStandardTime: {
        id: 'Korea Standard Time',
        defaultMessage: `Seoul`,
        description: `Label for the time zone 'Seoul'`,
    },
    yakutskStandardTime: {
        id: 'Yakutsk Standard Time',
        defaultMessage: `Yakutsk (RTZ 8)`,
        description: `Label for the time zone 'Yakutsk (RTZ 8)'`,
    },
    cenAustraliaStandardTime: {
        id: 'Cen. Australia Standard Time',
        defaultMessage: `Adelaide`,
        description: `Label for the time zone 'Adelaide'`,
    },
    aUSCentralStandardTime: {
        id: 'AUS Central Standard Time',
        defaultMessage: `Darwin`,
        description: `Label for the time zone 'Darwin'`,
    },
    eAustraliaStandardTime: {
        id: 'E. Australia Standard Time',
        defaultMessage: `Brisbane`,
        description: `Label for the time zone 'Brisbane'`,
    },
    aUSEasternStandardTime: {
        id: 'AUS Eastern Standard Time',
        defaultMessage: `Canberra, Melbourne, Sydney`,
        description: `Label for the time zone 'Canberra, Melbourne, Sydney'`,
    },
    westPacificStandardTime: {
        id: 'West Pacific Standard Time',
        defaultMessage: `Guam, Port Moresby`,
        description: `Label for the time zone 'Guam, Port Moresby'`,
    },
    tasmaniaStandardTime: {
        id: 'Tasmania Standard Time',
        defaultMessage: `Hobart`,
        description: `Label for the time zone 'Hobart'`,
    },
    magadanStandardTime: {
        id: 'Magadan Standard Time',
        defaultMessage: `Magadan`,
        description: `Label for the time zone 'Magadan'`,
    },
    vladivostokStandardTime: {
        id: 'Vladivostok Standard Time',
        defaultMessage: `Vladivostok, Magadan (RTZ 9)`,
        description: `Label for the time zone 'Vladivostok, Magadan (RTZ 9)'`,
    },
    russiaTimeZone10: {
        id: 'Russia Time Zone 10',
        defaultMessage: `Chokurdakh (RTZ 10)`,
        description: `Label for the time zone 'Chokurdakh (RTZ 10)'`,
    },
    centralPacificStandardTime: {
        id: 'Central Pacific Standard Time',
        defaultMessage: `Solomon Is., New Caledonia`,
        description: `Label for the time zone 'Solomon Is., New Caledonia'`,
    },
    russiaTimeZone11: {
        id: 'Russia Time Zone 11',
        defaultMessage: `Anadyr, Petropavlovsk-Kamchatsky (RTZ 11)`,
        description: `Label for the time zone 'Anadyr, Petropavlovsk-Kamchatsky (RTZ 11)'`,
    },
    newZealandStandardTime: {
        id: 'New Zealand Standard Time',
        defaultMessage: `Auckland, Wellington`,
        description: `Label for the time zone 'Auckland, Wellington'`,
    },
    uTC12: {
        id: 'UTC+12',
        defaultMessage: `Coordinated Universal Time+12`,
        description: `Label for the time zone 'Coordinated Universal Time+12'`,
    },
    fijiStandardTime: {
        id: 'Fiji Standard Time',
        defaultMessage: `Fiji`,
        description: `Label for the time zone 'Fiji'`,
    },
    tongaStandardTime: {
        id: 'Tonga Standard Time',
        defaultMessage: `Nuku'alofa`,
        description: `Label for the time zone 'Nuku'alofa'`,
    },
    samoaStandardTime: {
        id: 'Samoa Standard Time',
        defaultMessage: `Samoa`,
        description: `Label for the time zone 'Samoa'`,
    },
    lineIslandsStandardTime: {
        id: 'Line Islands Standard Time',
        defaultMessage: `Kiritimati Island`,
        description: `Label for the time zone 'Kiritimati Island'`,
    },
});
export default timezoneMessages;
