import IcalExpander from 'ical-expander';
import ical from 'ical-generator';
import moment from 'moment-timezone';
import { findOneIana } from 'windows-iana';
import { ManagedLabsModels } from '@azure-lab-services/ml-ts';
import Constants from '../utils/constants';
import scheduleMessages from './schedule-messages';
function mapWeekday(weekday) {
    switch (weekday) {
        case 'Monday':
            return 'MO';
        case 'Tuesday':
            return 'TU';
        case 'Wednesday':
            return 'WE';
        case 'Thursday':
            return 'TH';
        case 'Friday':
            return 'FR';
        case 'Saturday':
            return 'SA';
        case 'Sunday':
            return 'SU';
        default:
            throw Error('Unexpected weekday encountered');
    }
}
function getWeekdays(schedule) {
    return schedule.recurrencePattern && schedule.recurrencePattern.weekDays
        ? schedule.recurrencePattern.weekDays.map((weekday) => mapWeekday(weekday))
        : [];
}
function getVNextWeekdays(schedule) {
    return schedule.recurrencePattern && schedule.recurrencePattern.weekDays
        ? schedule.recurrencePattern.weekDays.map((weekday) => mapWeekday(weekday))
        : [];
}
function getFrequency(schedule) {
    if (!schedule.recurrencePattern) {
        return 'WEEKLY';
    }
    switch (schedule.recurrencePattern.frequency) {
        case 'Daily':
            return 'DAILY';
        case 'Weekly':
        default:
            return 'WEEKLY';
    }
}
function getInterval(schedule) {
    return schedule.recurrencePattern && schedule.recurrencePattern.interval ? schedule.recurrencePattern.interval : 1;
}
function getScheduleStartDate(schedule) {
    return schedule.startAction.enableState === Constants.EnableState.Enabled
        ? new Date(schedule.start)
        : new Date(schedule.end);
}
function getVNextScheduleStartDate(schedule) {
    return schedule.startAt ?? schedule.stopAt;
}
function getScheduleRecurrenceEndDate(schedule) {
    return schedule.recurrencePattern
        ? schedule.recurrencePattern.until
            ? new Date(schedule.recurrencePattern.until)
            : undefined
        : new Date(schedule.end);
}
function getVNextScheduleRecurrenceEndDate(schedule) {
    return schedule.recurrencePattern
        ? schedule.recurrencePattern.expirationDate
            ? schedule.recurrencePattern.expirationDate
            : undefined
        : schedule.stopAt;
}
function getUTCDate(date) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes()));
}
function getBrowserOffset() {
    const date = new Date();
    const offset = date.getTimezoneOffset() / 60;
    return offset;
}
function getEventOffset(event) {
    const zone = moment.tz.zone(event.timezone);
    const start = new Date(event.startDate);
    const offset = zone.parse(start.valueOf()) / 60;
    return offset;
}
/** The date passed in is the time in that event's local time
 *  Need to do adjustments to make it the time in the browser's time
 *  Also do adjustments to make sure DST does not affect times that are displayed
 */
function getAdjustedDate(date, browserOffset, eventOffset) {
    if (moment(new Date()).isDST() != moment(date).isDST()) {
        if (moment(new Date()).isDST()) {
            date.setHours(date.getHours() + eventOffset - browserOffset - 1);
        }
        else {
            date.setHours(date.getHours() + eventOffset - browserOffset + 1);
        }
    }
    else {
        date.setHours(date.getHours() + eventOffset - browserOffset);
    }
    return date;
}
export function adjustDate(date, timezone) {
    const browserOffset = getBrowserOffset();
    const ianaZone = findOneIana(timezone);
    const zone = moment.tz.zone(ianaZone);
    const offset = zone.parse(date.valueOf()) / 60;
    const adjusted = new Date(date);
    return getAdjustedDate(adjusted, browserOffset, offset);
}
function getStopOnlyEvent(locale, classNames, intl, start, end, id) {
    classNames.push('fc-event--stop-only');
    // adjust times so calendar will display nicely if schedule is at the very bottom of the calendar
    const calendarStart = new Date(start);
    const calendarEnd = new Date(end);
    const endTimeFormatted = formatTime(end, locale);
    const title = intl.formatMessage(scheduleMessages.stopWithTimeLabel, { stopTime: endTimeFormatted });
    // adjust calendar end time so it reflects the actual height in the calendar
    // as it takes up the slot of a 30 minute event.  without this code it'll appear short but actually take up
    // the space of a 2 hour event (as that's our default duration) and it'll push other
    // events out of the way if they're in that time frame
    calendarEnd.setHours(calendarStart.getHours());
    calendarEnd.setMinutes(calendarStart.getMinutes() + 30);
    if (end.getHours() === 23 && end.getMinutes() > 29) {
        calendarStart.setHours(23, 29);
        calendarEnd.setHours(23, 59);
    }
    return {
        start: calendarStart,
        end: calendarEnd,
        title,
        classNames,
        id,
        extendedProps: {
            start,
            end,
        },
    };
}
function getStartOnlyEvent(locale, classNames, intl, start, end, id) {
    classNames.push('fc-event--start-only');
    // adjust times so calendar will display nicely if schedule is at the very bottom of the calendar
    const calendarStart = new Date(start);
    const calendarEnd = new Date(end);
    const startTimeFormatted = formatTime(start, locale);
    const title = intl.formatMessage(scheduleMessages.startWithTimeLabel, { startTime: startTimeFormatted });
    // adjust calendar end time so it reflects the actual height in the calendar
    // as it takes up the slot of a 30 minute event.  without this code it'll appear short but actually take up
    // the space of a 2 hour event (as that's our default duration) and it'll push other
    // events out of the way if they're in that time frame
    calendarEnd.setHours(calendarStart.getHours());
    calendarEnd.setMinutes(calendarStart.getMinutes() + 30);
    if (start.getHours() === 23 && start.getMinutes() > 29) {
        calendarStart.setHours(23, 29);
        calendarEnd.setDate(calendarStart.getDate());
        calendarEnd.setHours(23, 59);
    }
    return {
        start: calendarStart,
        end: calendarEnd,
        title,
        classNames,
        id,
        extendedProps: {
            start,
            end,
        },
    };
}
function getStandardEvent(locale, classNames, adjustedStart, adjustedEnd, id) {
    const startTimeFormatted = formatTime(adjustedStart, locale);
    const endTimeFormatted = formatTime(adjustedEnd, locale);
    const title = `${startTimeFormatted} - ${endTimeFormatted}`;
    return {
        start: adjustedStart,
        end: adjustedEnd,
        title,
        classNames,
        id,
    };
}
function formatTime(date, locale) {
    return moment(date).locale(locale).format('LT');
}
function getAdjustedStart(event, browserOffset) {
    const eventOffset = getEventOffset(event);
    const start = new Date(event.startDate.year, event.startDate.month - 1, event.startDate.day, event.startDate.hour, event.startDate.minute, 0);
    return getAdjustedDate(start, browserOffset, eventOffset);
}
function getAdjustedEnd(event, browserOffset) {
    const eventOffset = getEventOffset(event);
    const end = new Date(event.endDate.year, event.endDate.month - 1, event.endDate.day, event.endDate.hour, event.endDate.minute, 0);
    return getAdjustedDate(end, browserOffset, eventOffset);
}
export function createIcal(schedules) {
    const cal = ical();
    schedules.forEach((schedule) => {
        const event = {
            start: getUTCDate(getScheduleStartDate(schedule)),
            end: getUTCDate(schedule.end),
            summary: schedule.notes,
            description: findOneIana(schedule.timeZoneId),
            repeating: schedule.recurrencePattern
                ? {
                    freq: getFrequency(schedule),
                    interval: getInterval(schedule),
                    until: getScheduleRecurrenceEndDate(schedule),
                    byDay: getWeekdays(schedule),
                }
                : undefined,
            id: schedule.id,
        };
        cal.createEvent(event);
    });
    return cal;
}
export function createIcalVNext(schedules) {
    const cal = ical();
    schedules.forEach((schedule) => {
        const event = {
            start: getUTCDate(getVNextScheduleStartDate(schedule)),
            end: getUTCDate(schedule.stopAt),
            summary: schedule.notes,
            description: findOneIana(schedule.timeZoneId),
            repeating: schedule.recurrencePattern
                ? {
                    freq: getFrequency(schedule),
                    interval: getInterval(schedule),
                    until: getVNextScheduleRecurrenceEndDate(schedule) instanceof Date ? getUTCDate(getVNextScheduleRecurrenceEndDate(schedule)) : getVNextScheduleRecurrenceEndDate(schedule),
                    byDay: getVNextWeekdays(schedule),
                }
                : undefined,
            id: schedule.id,
        };
        cal.createEvent(event);
    });
    return cal;
}
export function expandEvents(cal, startWindow, endWindow) {
    const icalExpander = new IcalExpander({ ics: cal.toString(), maxIterations: 0 });
    // enlarge time window for different time zones
    const earlierStart = new Date(startWindow);
    earlierStart.setDate(earlierStart.getDate() - 2);
    const laterEnd = new Date(endWindow);
    laterEnd.setDate(laterEnd.getDate() + 2);
    const expandedEvents = icalExpander.between(earlierStart, laterEnd);
    const expandedOccurrences = expandedEvents.occurrences.map((o) => ({
        startDate: o.startDate,
        endDate: o.endDate,
        summary: o.item.summary,
        timezone: o.item.description,
        id: o.item.uid,
        isRecurring: true,
    }));
    const onetimeEvents = expandedEvents.events.map((e) => ({
        startDate: e.startDate,
        endDate: e.endDate,
        summary: e.summary,
        timezone: e.description,
        id: e.uid,
        isRecurring: false,
    }));
    const allEvents = expandedOccurrences.concat(onetimeEvents);
    return allEvents;
}
function getCalendarEvents(locale, intl, schedules, occurrences) {
    const browserOffset = getBrowserOffset();
    const calendarEvents = occurrences.map((event) => {
        const start = getAdjustedStart(event, browserOffset);
        const end = getAdjustedEnd(event, browserOffset);
        const id = event.id.replace('@undefined', '');
        const index = schedules.findIndex((schedule) => schedule.id.toLowerCase() === id.toLowerCase());
        const schedule = schedules[index];
        const classNames = [];
        if (event.isRecurring) {
            classNames.push('fc-event--recurring');
        }
        if (schedule.startAction.enableState === ManagedLabsModels.EnableState.Disabled) {
            return getStopOnlyEvent(locale, classNames, intl, start, end, id);
        }
        else if (schedule.endAction.enableState === ManagedLabsModels.EnableState.Disabled) {
            return getStartOnlyEvent(locale, classNames, intl, start, end, id);
        }
        else {
            return getStandardEvent(locale, classNames, start, end, id);
        }
    });
    return calendarEvents;
}
function getCalendarEventsVNext(locale, intl, schedules, occurrences) {
    const browserOffset = getBrowserOffset();
    const calendarEvents = occurrences.map((event) => {
        const start = getAdjustedStart(event, browserOffset);
        const end = getAdjustedEnd(event, browserOffset);
        const id = event.id.replace('@undefined', '');
        const index = schedules.findIndex((schedule) => schedule.id.toLowerCase() === id.toLowerCase());
        const schedule = schedules[index];
        const classNames = [];
        if (event.isRecurring) {
            classNames.push('fc-event--recurring');
        }
        if (!schedule.startAt) {
            return getStopOnlyEvent(locale, classNames, intl, start, end, id);
        }
        else {
            return getStandardEvent(locale, classNames, start, end, id);
        }
    });
    return calendarEvents;
}
export function getEvents(isCurrentLabParentLabAccount, locale, intl, schedules, startWindow, endWindow) {
    if (isCurrentLabParentLabAccount) {
        const cal = createIcal(schedules);
        const eventsForTimeWindow = expandEvents(cal, startWindow, endWindow);
        const calendarEvents = getCalendarEvents(locale, intl, schedules, eventsForTimeWindow);
        return calendarEvents;
    }
    else {
        const cal = createIcalVNext(schedules);
        const eventsForTimeWindow = expandEvents(cal, startWindow, endWindow);
        const calendarEvents = getCalendarEventsVNext(locale, intl, schedules, eventsForTimeWindow);
        return calendarEvents;
    }
}
export function updateDateWindow(currentStartWindow, currentEndWindow, calendarInfo, setStartWindow, setEndWindow) {
    const newStartWindow = new Date(calendarInfo.view.currentStart);
    const newEndWindow = new Date(calendarInfo.view.currentEnd);
    if (newStartWindow.valueOf() !== currentStartWindow.valueOf()) {
        setStartWindow(newStartWindow);
    }
    if (newEndWindow.valueOf() !== currentEndWindow.valueOf()) {
        setEndWindow(newEndWindow);
    }
}
const CalendarUtilities = {
    getEvents,
    updateDateWindow,
    createIcal,
    expandEvents,
    adjustDate,
};
export default CalendarUtilities;
