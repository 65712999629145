import { call, put, select } from 'redux-saga/effects';
import { LabActionType } from '../../actions/lab/lab-actions';
import { beginDeleteLab } from '../../../data/providers/lab-provider';
import { deleteLabError, deleteLabSuccess } from '../../actions/lab/lab-action-creators';
import MlClientError, { FailureOperation, AzurePolicyViolation } from '../../../data/ml-client-error';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { ResourceId } from '../../../utils/resource-id';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import LabProvider from '../../../data/providers/vnext/arm-api/lab-provider';
import { labParentBasedTakeEvery } from '../../effects/lab-parent-based';
export function* deleteLab(action) {
    const { labId } = action;
    try {
        const labResourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(beginDeleteLab, labResourceId, accessToken, locale, language);
        yield put(deleteLabSuccess(labId));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.DeleteLab, e ? e.code : undefined);
        yield put(deleteLabError(error));
    }
}
export function* deleteVNextLab(action) {
    const { labId } = action;
    try {
        const labResourceId = new ResourceId(labId, true);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(LabProvider.deleteLab, labResourceId, accessToken, locale, language);
        yield put(deleteLabSuccess(labId));
    }
    catch (e) {
        let policyViolationDefinitionIds = undefined;
        if (e?.code === AzurePolicyViolation.CODE) {
            policyViolationDefinitionIds = [];
            const errorObject = JSON.parse(e.response.body);
            for (const additionalInfo of errorObject.error.additionalInfo) {
                policyViolationDefinitionIds.push(additionalInfo.info.policyDefinitionId);
            }
        }
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.DeleteLab, e ? e.code : undefined, policyViolationDefinitionIds ? policyViolationDefinitionIds : undefined);
        yield put(deleteLabError(error));
    }
}
export function* deleteLabSaga() {
    yield labParentBasedTakeEvery(LabActionType.DELETE_LAB, deleteLab, deleteVNextLab);
}
