import Constants from '../../../../utils/constants';
import { createLabsApiClient } from './labs-api-client-factory';
import { VmState } from '../../../models/environment-common';
import { OperationType } from '../../../models/vnext/operation';
export async function listVirtualMachines(accessToken, locale, language, aadGroupId) {
    const labsApiClient = createLabsApiClient(Constants.NoSubscriptionId, accessToken, locale, language);
    const options = {
        customHeaders: !!aadGroupId ? { 'x-ml-aad-group-id': aadGroupId } : undefined,
    };
    const response = await labsApiClient.virtualMachine.list(options);
    const userVirtualMachines = [];
    response.map((vm, index) => {
        userVirtualMachines[index] = { ...vm, pendingVmState: VmState.None };
    });
    return userVirtualMachines;
}
export async function start(subscription, region, virtualMachineId, accessToken, locale, language) {
    const labsApiClient = createLabsApiClient(subscription, accessToken, locale, language);
    const startVmResult = await labsApiClient.virtualMachine.start(region, virtualMachineId);
    return { ...startVmResult._response.parsedBody, operationType: OperationType.StartOperation };
}
export async function stop(subscription, region, virtualMachineId, accessToken, locale, language) {
    const labsApiClient = createLabsApiClient(subscription, accessToken, locale, language);
    const stopVmResult = await labsApiClient.virtualMachine.stop(region, virtualMachineId);
    return { ...stopVmResult._response.parsedBody, operationType: OperationType.StopOperation };
}
export async function resetPassword(subscription, region, virtualMachineId, username, password, accessToken, locale, language) {
    const labsApiClient = createLabsApiClient(subscription, accessToken, locale, language);
    const body = {
        username,
        password,
    };
    await labsApiClient.virtualMachine.resetPassword(body, region, virtualMachineId);
}
export async function reimage(subscription, region, virtualMachineId, accessToken, locale, language) {
    const labsApiClient = createLabsApiClient(subscription, accessToken, locale, language);
    const reimageVmResult = await labsApiClient.virtualMachine.reimage(region, virtualMachineId);
    return { ...reimageVmResult._response.parsedBody, operationType: OperationType.ReimageOperation };
}
export async function redeploy(subscription, region, virtualMachineId, accessToken, locale, language) {
    const labsApiClient = createLabsApiClient(subscription, accessToken, locale, language);
    const redeployVmResult = await labsApiClient.virtualMachine.redeploy(region, virtualMachineId);
    return { ...redeployVmResult._response.parsedBody, operationType: OperationType.RedeployOperation };
}
const UserVirtualMachinesProvider = {
    listVirtualMachines,
    start,
    stop,
    resetPassword,
    reimage,
    redeploy,
};
export default UserVirtualMachinesProvider;
