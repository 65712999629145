import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { commonMessages } from '../language/common-messages';
import Constants from '../utils/constants';
import './student-vm-stop-dialog.css';
const messages = defineMessages({
    dialogTitle: {
        id: 'StopVMDialogTitle',
        defaultMessage: 'Stop virtual machine',
        description: 'The title of the stop VM dialog.',
    },
    dialogMessage: {
        id: 'StopVMDialogMessage',
        defaultMessage: 'Hours are not available for this virtual machine. You will not be able to turn it on once it is stopped. Do you want to continue?',
        description: 'The message displayed in the stop VM dialog.',
    },
});
const StopVmDialogInjected = (props) => {
    const { vm, onDismiss, onSubmit } = props;
    const title = props.intl.formatMessage(messages.dialogTitle);
    const subText = props.intl.formatMessage(messages.dialogMessage);
    const confirmText = props.intl.formatMessage(commonMessages.yes);
    const cancelText = props.intl.formatMessage(commonMessages.no);
    return (<Dialog hidden={false} onDismiss={onDismiss} maxWidth={Constants.DefaultDialogWidth} dialogContentProps={{
        type: DialogType.normal,
        title,
        subText,
    }}>
            <DialogFooter>
                <PrimaryButton onClick={() => vm && onSubmit(vm)} text={confirmText}/>
                <DefaultButton autoFocus onClick={onDismiss} text={cancelText}/>
            </DialogFooter>
        </Dialog>);
};
export const StopVmDialog = injectIntl(StopVmDialogInjected);
export default StopVmDialog;
