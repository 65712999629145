import _ from 'lodash';
import { CommandBar, ContextualMenuItemType } from '@fluentui/react';
import * as React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import commonMessages from '../../language/common-messages';
import FeatureMenu from './feature-menu';
import { HomeButton } from './home-button';
import { LabParentPicker } from './lab-parent-picker';
import { LabPicker } from './lab-picker';
import HelpFlyout from './help-flyout';
import { UserMenu } from './user-menu';
import VmListFlyout from './vm-list-flyout';
import { TenantPicker } from './tenant-picker';
import { getCustomTheme, getPageHeaderRootExpandedColor } from '../themes/selectors';
import LmsLabPlanSettingsFlyout from './lms-lab-plan-settings-flyout';
import './page-header.css';
const PageHeaderInjected = (props) => {
    const { homeButtonProps, labParentPickerProps, labPickerProps, userMenuProps, featureMenuProps, tenantPickerProps, helpFlyoutPropsForSupportSection, isVmListEnabled, lmsLabPlanSettingsFlyoutProps, showFeedbackButton, feedbackSurvey, } = props;
    const [showHelpFlyout, setShowHelpFlyout] = React.useState(false);
    const [showVmListFlyout, setShowVmListFlyout] = React.useState(false);
    const [showLmsLabPlanSettingsFlyout, setShowLmsLabPlanSettingsFlyout] = React.useState(false);
    const { pageHeaderStyles, pageHeaderButtonStyles: styles } = getCustomTheme();
    const rootExpandedStyle = getPageHeaderRootExpandedColor();
    const { backgroundColor: rootExpandedBackgroundColor, color: rootExpandedColor } = rootExpandedStyle;
    const msg = useIntl().formatMessage;
    const homeStyles = _.merge({}, styles, { root: { minWidth: '230px' } });
    const items = [
        {
            key: 'Home',
            onRender: () => (<div role="menuitem">
                    <HomeButton {...homeButtonProps} styles={homeStyles}/>
                </div>),
        },
    ];
    if (labParentPickerProps) {
        const pickerStyles = _.merge({}, styles, {
            root: { paddingLeft: 8, paddingRight: 8 },
        });
        items.push({
            key: 'LabAccountPicker',
            onRender: () => (<div role="menuitem">
                    <LabParentPicker {...labParentPickerProps} styles={pickerStyles}/>
                </div>),
        });
        if (labPickerProps) {
            items.push({
                key: 'LabAccountLabSeparator',
                itemType: ContextualMenuItemType.Divider,
                onRender: () => <span className="page-header__separator ms-fontSize-mPlus">/</span>,
            });
            items.push({
                key: 'LabPicker',
                onRender: () => (<div role="menuitem">
                        <LabPicker {...labPickerProps} styles={pickerStyles}/>
                    </div>),
            });
        }
    }
    const farItems = [];
    if (lmsLabPlanSettingsFlyoutProps) {
        const text = msg(commonMessages.lmsLabPlanSettings);
        farItems.push({
            key: 'LmsConnect',
            text,
            label: text,
            iconProps: {
                iconName: 'Settings',
                style: { color: showLmsLabPlanSettingsFlyout ? rootExpandedColor : undefined },
            },
            iconOnly: true,
            buttonStyles: _.merge({}, styles, {
                root: {
                    padding: 16,
                    backgroundColor: showLmsLabPlanSettingsFlyout ? rootExpandedBackgroundColor : undefined,
                },
            }),
            onClick: () => setShowLmsLabPlanSettingsFlyout(true),
        });
    }
    if (isVmListEnabled) {
        const text = msg(commonMessages.myVirtualMachines);
        farItems.push({
            key: 'VirtualMachines',
            text,
            ariaLabel: text,
            iconProps: { iconName: 'TVMonitor', style: { color: showVmListFlyout ? rootExpandedColor : undefined } },
            iconOnly: true,
            buttonStyles: _.merge({}, styles, {
                root: { padding: 16, backgroundColor: showVmListFlyout ? rootExpandedBackgroundColor : undefined },
            }),
            onClick: () => setShowVmListFlyout(true),
        });
    }
    if (showFeedbackButton) {
        const text = msg(commonMessages.feedback);
        farItems.push({
            key: 'Feedback',
            iconOnly: true,
            text,
            ariaLabel: text,
            iconProps: { iconName: 'Emoji2' },
            buttonStyles: _.merge({}, styles, {
                root: { padding: 16 },
            }),
            onClick: () => {
                window.open(feedbackSurvey, '_blank');
            },
        });
    }
    const text = msg(commonMessages.help);
    farItems.push({
        key: 'StatusCircleQuestionMark',
        text,
        ariaLabel: text,
        iconProps: { iconName: 'Help', style: { color: showHelpFlyout ? rootExpandedColor : undefined } },
        iconOnly: true,
        buttonStyles: _.merge({}, styles, {
            root: { padding: 16, backgroundColor: showHelpFlyout ? rootExpandedBackgroundColor : undefined },
        }),
        onClick: () => setShowHelpFlyout(true),
    });
    if (featureMenuProps) {
        farItems.push({
            key: 'FeatureMenu',
            onRender: () => (<div role="menuitem">
                    <FeatureMenu {...featureMenuProps} styles={styles}/>
                </div>),
        });
    }
    if (tenantPickerProps) {
        farItems.push({
            key: 'TenantPicker',
            onRender: () => (<div role="menuitem">
                    <TenantPicker {...tenantPickerProps} styles={styles}/>
                </div>),
        });
    }
    if (userMenuProps) {
        farItems.push({
            key: 'UserMenu',
            onRender: () => (<div role="menuitem">
                    <UserMenu {...userMenuProps} styles={styles}/>
                </div>),
        });
    }
    return (<div id="page-header-container" role="banner">
            <CommandBar items={items} farItems={farItems} overflowButtonProps={{
        styles,
    }} styles={pageHeaderStyles}/>
            {showHelpFlyout && (<HelpFlyout {...helpFlyoutPropsForSupportSection} onDismiss={() => setShowHelpFlyout(false)}/>)}
            {isVmListEnabled && showVmListFlyout && <VmListFlyout onDismiss={() => setShowVmListFlyout(false)}/>}
            {!!lmsLabPlanSettingsFlyoutProps && showLmsLabPlanSettingsFlyout && (<LmsLabPlanSettingsFlyout {...lmsLabPlanSettingsFlyoutProps} onDismiss={() => setShowLmsLabPlanSettingsFlyout(false)}/>)}
        </div>);
};
export const PageHeader = injectIntl(PageHeaderInjected);
export default PageHeader;
