import { List, Record, Map } from 'immutable';
import { UserEnvironmentActionType, } from '../actions/user-environment/user-environment-actions';
import { VmState } from '../../data/models/environment-common';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { Context } from '../action-context';
function setPendingEnvironmentState(environmentId, environments, pendingVmState) {
    environments = environments.update(environments.findIndex((env) => env.id === environmentId), (env) => {
        return { ...env, pendingVmState };
    });
    return environments;
}
function clearPendingEnvironmentState(environmentId, environments) {
    environments = environments.update(environments.findIndex((env) => env.id === environmentId), (env) => {
        return { ...env, pendingVmState: VmState.None };
    });
    return environments;
}
function addEnvironmentFailure(environmentId, error, errorMap) {
    let errors = errorMap.get(environmentId);
    errors = errors ? errors.concat(error) : List([error]);
    errorMap = errorMap.set(environmentId, errors);
    return errorMap;
}
function clearSingleEnvironmentError(environmentId, errorIndex, errorMap) {
    let errors = errorMap.get(environmentId);
    if (!errors || errorIndex >= errors.count()) {
        return errorMap;
    }
    errors = errors.remove(errorIndex);
    errorMap = errors.count() > 0 ? errorMap.set(environmentId, errors) : errorMap.remove(environmentId);
    return errorMap;
}
function clearEnvironmentError(environmentId, errorMap) {
    return errorMap.remove(environmentId);
}
function listGlobalUserEnvironments(state, action) {
    const oldEnvironmentState = state.get('loadState');
    const loadState = oldEnvironmentState === LoadingStoreState.NotStarted || oldEnvironmentState === LoadingStoreState.Loading
        ? LoadingStoreState.Loading
        : LoadingStoreState.Updating;
    return state.merge({
        loadState,
    });
}
function listGlobalUserEnvironmentsCancelled(state, action) {
    const loadState = state.get('loadState') === LoadingStoreState.Loading ? LoadingStoreState.NotStarted : LoadingStoreState.Loaded;
    return state.merge({
        loadState,
    });
}
function listGlobalUserEnvironmentsSuccess(state, action) {
    const { environments: environmentArray } = action;
    const environments = List(environmentArray);
    return state.merge({
        environments,
        listEnvironmentsError: undefined,
    });
}
function listGlobalUserEnvironmentsError(state, action) {
    const { error: listEnvironmentsError } = action;
    const loadState = state.get('loadState') === LoadingStoreState.Loading
        ? LoadingStoreState.LoadingFailed
        : LoadingStoreState.UpdateFailed;
    return state.merge({
        loadState,
        listEnvironmentsError,
    });
}
function clearListUserEnvironmentsError(state, action) {
    return state.merge({
        listEnvironmentsError: undefined,
    });
}
function startEnvironment(state, action) {
    const { environmentId } = action;
    let startErrors = state.get('startErrors');
    startErrors = clearEnvironmentError(environmentId, startErrors);
    let environments = state.get('environments');
    environments = setPendingEnvironmentState(environmentId, environments, VmState.Starting);
    return state.merge({
        environments,
        startErrors,
    });
}
function startUserEnvironmentFailed(state, action) {
    const { error, environmentId } = action;
    let environments = state.get('environments');
    environments = clearPendingEnvironmentState(environmentId, environments);
    let startErrors = state.get('startErrors');
    startErrors = addEnvironmentFailure(environmentId, error, startErrors);
    return state.merge({
        environments,
        startErrors,
    });
}
function clearStartEnvironmentError(state, action) {
    const { environmentId, errorIndex } = action;
    let startErrors = state.get('startErrors');
    startErrors = clearSingleEnvironmentError(environmentId, errorIndex, startErrors);
    return state.merge({
        startErrors,
    });
}
function stopEnvironment(state, action) {
    const { environmentId } = action;
    let stopErrors = state.get('stopErrors');
    stopErrors = clearEnvironmentError(environmentId, stopErrors);
    let environments = state.get('environments');
    environments = setPendingEnvironmentState(environmentId, environments, VmState.Stopping);
    return state.merge({
        environments,
        stopErrors,
    });
}
function stopUserEnvironmentFailed(state, action) {
    const { error, environmentId } = action;
    let environments = state.get('environments');
    environments = clearPendingEnvironmentState(environmentId, environments);
    let stopErrors = state.get('stopErrors');
    stopErrors = addEnvironmentFailure(environmentId, error, stopErrors);
    return state.merge({
        environments,
        stopErrors,
    });
}
function clearStopEnvironmentError(state, action) {
    const { environmentId, errorIndex } = action;
    let stopErrors = state.get('stopErrors');
    stopErrors = clearSingleEnvironmentError(environmentId, errorIndex, stopErrors);
    return state.merge({
        stopErrors,
    });
}
function resetPassword(state, action) {
    const { environmentId } = action;
    let resetPasswordErrors = state.get('resetPasswordErrors');
    resetPasswordErrors = clearEnvironmentError(environmentId, resetPasswordErrors);
    let environments = state.get('environments');
    environments = setPendingEnvironmentState(environmentId, environments, VmState.ResettingPassword);
    return state.merge({
        environments,
        resetPasswordErrors,
    });
}
function resetUserEnvironmentPasswordFailed(state, action) {
    const { error, environmentId } = action;
    let environments = state.get('environments');
    environments = clearPendingEnvironmentState(environmentId, environments);
    let resetPasswordErrors = state.get('resetPasswordErrors');
    resetPasswordErrors = addEnvironmentFailure(environmentId, error, resetPasswordErrors);
    return state.merge({
        environments,
        resetPasswordErrors,
    });
}
function clearResetEnvironmentPasswordError(state, action) {
    const { environmentId, errorIndex } = action;
    let resetPasswordErrors = state.get('resetPasswordErrors');
    resetPasswordErrors = clearSingleEnvironmentError(environmentId, errorIndex, resetPasswordErrors);
    return state.merge({
        resetPasswordErrors,
    });
}
function clearErrors(state, action) {
    return state.merge({
        listEnvironmentsError: undefined,
        startErrors: Map(),
        stopErrors: Map(),
        resetPasswordErrors: Map(),
    });
}
function listAllUserVirtualMachinesSuccess(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loaded,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    environments: List(),
    listEnvironmentsError: undefined,
    startErrors: Map(),
    stopErrors: Map(),
    resetPasswordErrors: Map(),
})();
export const userEnvironmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserEnvironmentActionType.CLEAR_USER_SESSION:
        case UserEnvironmentActionType.SELECT_TENANT:
            return state.merge(initialState);
        case UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_SUCCESS:
            return listGlobalUserEnvironmentsSuccess(state, action);
        default:
            switch (action.context) {
                case Context.VNext:
                    return state;
                default:
                    switch (action.type) {
                        case UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS:
                            return listGlobalUserEnvironments(state, action);
                        case UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED:
                            return listGlobalUserEnvironmentsCancelled(state, action);
                        case UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_ERROR:
                            return listGlobalUserEnvironmentsError(state, action);
                        case UserEnvironmentActionType.CLEAR_LIST_GLOBAL_USER_ENVIRONMENTS_ERROR:
                            return clearListUserEnvironmentsError(state, action);
                        case UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT:
                            return startEnvironment(state, action);
                        case UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT_FAILED:
                            return startUserEnvironmentFailed(state, action);
                        case UserEnvironmentActionType.CLEAR_START_USER_ENVIRONMENT_ERROR:
                            return clearStartEnvironmentError(state, action);
                        case UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT:
                            return stopEnvironment(state, action);
                        case UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT_FAILED:
                            return stopUserEnvironmentFailed(state, action);
                        case UserEnvironmentActionType.CLEAR_STOP_USER_ENVIRONMENT_ERROR:
                            return clearStopEnvironmentError(state, action);
                        case UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD:
                            return resetPassword(state, action);
                        case UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD_FAILED:
                            return resetUserEnvironmentPasswordFailed(state, action);
                        case UserEnvironmentActionType.CLEAR_RESET_USER_ENVIRONMENT_PASSWORD_ERROR:
                            return clearResetEnvironmentPasswordError(state, action);
                        case UserEnvironmentActionType.CLEAR_GLOBAL_USER_ENVIRONMENT_ERRORS:
                            return clearErrors(state, action);
                        case UserEnvironmentActionType.LIST_ALL_USER_VIRTUAL_MACHINES_SUCCESS:
                            return listAllUserVirtualMachinesSuccess(state, action);
                        default:
                            return state;
                    }
            }
    }
};
export default userEnvironmentReducer;
