import * as React from 'react';
import TeamsConfigureLabParent from './teams-configure-lab-parent-content';
import LoadingContainer from '../common/loading-section';
import TeamsGetStartedContent from './teams-get-started-content';
import TeamsMessages from './teams-messages';
import FullPageMessage from '../full-page-message/full-page-message';
import { FormattedMessage } from 'react-intl';
export const TeamsConfig = (props) => {
    const { isStudent, isInvalidChannel, isLoading, isRefreshing, showNoFoundLabAccountsMessage, labParentItems, defaultSelectedId, onSave, refreshLabParents, } = props;
    // add TeamsMessages just for building the resouces.json so that these strings can be translated.
    {
        false && <TeamsMessages />;
    }
    if (isLoading) {
        return <LoadingContainer styles={{ root: { backgroundColor: 'inherit' } }}/>;
    }
    if (isStudent) {
        return (<FullPageMessage image="error-access" message={<FormattedMessage id="ErrorMessageStudentAddTab" defaultMessage="Only owners can create labs for this team" description="The error message displayed when students try to add the tab in Teams."/>} messageDetails={<FormattedMessage id="ErrorMessageStudentAddTabDetails" defaultMessage="Please contact the team owner if you need to set up a lab." description="The error message displayed when students try to add the tab in Teams."/>} imageSize={{ width: 250, height: 250 }} styles={{ root: { backgroundColor: 'inherit' } }}/>);
    }
    if (isInvalidChannel) {
        return (<FullPageMessage image="error-access" message={<FormattedMessage id="ErrorMessageAddInPrivateChannel" defaultMessage="Labs cannot be created in private channels." description="The error message displayed when adding the tab in private channel."/>} messageDetails={<FormattedMessage id="ErrorMessageAddInPrivateChannelDetails" defaultMessage="Please use a public channel to set up your lab." description="The error message displayed when adding the tab in private channel."/>} imageSize={{ width: 250, height: 250 }} styles={{ root: { backgroundColor: 'inherit' } }}/>);
    }
    if (labParentItems.size > 0) {
        return (<TeamsConfigureLabParent labParentItems={labParentItems} onSave={onSave} isRefreshing={isRefreshing} refreshLabParents={refreshLabParents} defaultSelectedId={defaultSelectedId}/>);
    }
    else {
        return (<TeamsGetStartedContent refreshLabParents={refreshLabParents} isRefreshing={isRefreshing} showNoFoundLabAccountsMessage={showNoFoundLabAccountsMessage}/>);
    }
};
export default TeamsConfig;
