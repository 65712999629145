import { LtiActionType, } from './lti-actions';
export const ltiStartOidc = () => ({
    type: LtiActionType.LTI_START_OIDC,
});
export const ltiStartOidcError = (error) => ({
    type: LtiActionType.LTI_START_OIDC_ERROR,
    error,
});
export const ltiStartOidcRedirecting = () => ({
    type: LtiActionType.LTI_START_OIDC_REDIRECTING,
});
export const ltiSignIn = () => ({
    type: LtiActionType.LTI_SIGN_IN,
});
export const ltiSignInSuccess = () => ({
    type: LtiActionType.LTI_SIGN_IN_SUCCESS,
});
export const ltiSignInError = (error) => ({
    type: LtiActionType.LTI_SIGN_IN_ERROR,
    error,
});
export const setLtiContext = (ltiContext) => ({
    type: LtiActionType.SET_LTI_CONTEXT,
    ltiContext,
});
export const setMembershipUrl = (membershipUrl) => ({
    type: LtiActionType.SET_MEMBERSHIP_URL,
    membershipUrl,
});
export const setLtiRoles = (roles) => ({
    type: LtiActionType.SET_LTI_ROLES,
    roles,
});
export const getLtiToken = (resolve, reject) => ({
    type: LtiActionType.GET_LTI_TOKEN,
    resolve,
    reject,
});
export const getLtiTokenError = (error) => ({
    type: LtiActionType.GET_LTI_TOKEN_ERROR,
    error,
});
export const getLtiTokenSuccess = () => ({
    type: LtiActionType.GET_LTI_TOKEN_SUCCESS,
});
export const ltiRequestWebStorageAccess = () => ({
    type: LtiActionType.LTI_REQUEST_WEB_STORAGE_ACCESS,
});
export const ltiRequestWebStorageAccessSuccess = () => ({
    type: LtiActionType.LTI_REQUEST_WEB_STORAGE_ACCESS_SUCCESS,
});
export const ltiRequestWebStorageAccessError = (error) => ({
    type: LtiActionType.LTI_REQUEST_WEB_STORAGE_ACCESS_ERROR,
    error,
});
export const setLmsInstance = (lmsInstance) => ({
    type: LtiActionType.SET_LMS_INSTANCE,
    lmsInstance,
});
const actions = {
    ltiStartOidc,
    ltiStartOidcError,
    ltiStartOidcRedirecting,
    ltiSignIn,
    ltiSignInSuccess,
    ltiSignInError,
    setLtiContext,
    setMembershipUrl,
    setLtiRoles,
    getLtiToken,
    getLtiTokenError,
    ltiRequestWebStorageAccess,
    ltiRequestWebStorageAccessSuccess,
    ltiRequestWebStorageAccessError,
    setLmsInstance,
};
export default actions;
