import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { listSubscriptionsForTenant } from '../../../data/providers/subscription-provider';
import { loadSubscriptionsError, loadSubscriptionsSuccess, } from '../../actions/subscription/subscription-action-creators';
import { SubscriptionActionType } from '../../actions/subscription/subscription-actions';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { allFeatures } from '../../../data/providers/feature-provider';
export function* loadSubscriptions(action) {
    try {
        const accessToken = yield call(getArmAccessTokenSaga, true);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const subscriptions = yield call(listSubscriptionsForTenant, accessToken, locale, language);
        const checkCalls = subscriptions.map((subscription) => {
            return { allFeatures: call(allFeatures, subscription) };
        });
        const foundFeatures = yield all(checkCalls.map((call) => call.allFeatures));
        subscriptions.map(async (subscription, index) => {
            subscriptions[index] = {
                ...subscription,
                features: foundFeatures[index].value === undefined
                    ? []
                    : foundFeatures[index].value
                        .filter((x) => x.properties.state == 'Registered')
                        .map((x) => x.name),
            };
        });
        yield put(loadSubscriptionsSuccess(subscriptions));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.LoadSubscriptions, err ? err.code : undefined);
        yield put(loadSubscriptionsError(error));
    }
}
export function* loadSubscriptionsSaga() {
    yield takeLatest(SubscriptionActionType.LOAD_SUBSCRIPTIONS, loadSubscriptions);
}
