import * as React from 'react';
import { Field } from 'react-final-form';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import 'moment/min/locales';
import { Dropdown, Stack, PrimaryButton, DefaultButton, ActionButton, Dialog, DialogFooter, Callout, Calendar, DialogType, } from '@fluentui/react';
import { getCustomTheme } from '../common/themes/selectors';
import commonMessages from '../language/common-messages';
import DayPicker from './day-picker';
import { firstDayOfWeekForPicker, dayPickerStrings, onFormatDate as formatDate, mapDayToLocaleDay, sortDays, } from './flyout-utilities';
import scheduleMessages from './schedule-messages';
function validateSelectedDays(value, message) {
    return value.length ? undefined : message;
}
const messages = defineMessages({
    everyWeekLabel: {
        id: 'EveryWeekLabel',
        defaultMessage: 'every week',
        description: 'Label for every week',
    },
    neverLabel: {
        id: 'NeverLabel',
        defaultMessage: 'never',
        description: 'Label for never',
    },
    chooseEndDateLabel: {
        id: 'ChooseEndDateLabel',
        defaultMessage: 'Choose end date',
        description: 'Label for choosing end date',
    },
    occurrenceUntilEndDateLabel: {
        id: 'OccurrenceUntilEndDateLabel',
        defaultMessage: 'Occurs every {days} until',
        description: 'Label to indicate the schedule occurs on specified days until a specified end date (the end date is shown on the page as a date picker)',
    },
});
export const RecurrencePicker = (props) => {
    const { input: { value, onChange }, label, options, placeHolder, meta: { error, pristine }, } = props;
    return (<Dropdown defaultSelectedKey={value} options={options} calloutProps={{ calloutMaxHeight: 300 }} onChange={(event, option) => onChange(option.key)} label={label} placeholder={placeHolder} styles={{
        dropdown: { display: 'inline-block' },
        title: { border: 'none', paddingLeft: '5px', lineHeight: 'unset', height: 'unset' },
        root: { display: 'flex', height: 'unset', marginBottom: '9px' },
        callout: { minWidth: '125px', marginTop: '-2px' },
        caretDownWrapper: { lineHeight: 'unset' },
        label: { lineHeight: 'unset', paddingTop: '0px', fontWeight: 'unset' },
    }} errorMessage={!pristine && error}/>);
};
export const ScheduledDayPicker = (props) => {
    const { input: { value, onChange }, label, locale, meta: { error }, } = props;
    return <DayPicker label={label} locale={locale} days={value} onChange={onChange} errorMessage={error}/>;
};
export const EndDatePicker = (props) => {
    const [isHidden, setIsHidden] = React.useState(true);
    const { input: { value, onChange }, intl, locale, minDate, ariaLabel, } = props;
    const { palette } = getCustomTheme();
    return (<>
            <ActionButton ariaLabel={ariaLabel} id="actionButtonDatePicker" onClick={() => setIsHidden(false)} styles={{
        root: {
            fontSize: '12px',
            height: '25px',
            padding: '0px',
            color: palette?.themePrimary,
        },
        rootHovered: {
            color: palette?.themeDark,
        },
        label: { margin: '0px' },
        flexContainer: { flexDirection: 'row-reverse' },
        icon: { fontSize: '12px', marginTop: '2px' },
    }} iconProps={{ iconName: 'ChevronDown' }}>
                {formatDate(locale, value)}
            </ActionButton>
            <Callout gapSpace={0} target={document.getElementById('actionButtonDatePicker')} onDismiss={() => setIsHidden(true)} hidden={isHidden} setInitialFocus>
                <Calendar firstDayOfWeek={firstDayOfWeekForPicker(locale)} strings={dayPickerStrings(locale, intl)} isMonthPickerVisible={false} value={value} showGoToToday={false} onSelectDate={(date) => {
        onChange(date);
        setIsHidden(true);
    }} minDate={minDate}/>
            </Callout>
        </>);
};
const EndDateChooserInjected = (props) => {
    const { isCurrentLabParentLabAccount, locale, intl, form, values, schedule, defaultSchedule } = props;
    const chooseEndDate = intl.formatMessage(messages.chooseEndDateLabel);
    const { palette } = getCustomTheme();
    let recurrenceEndDate = undefined;
    if (isCurrentLabParentLabAccount) {
        recurrenceEndDate =
            schedule && schedule.recurrencePattern?.until
                ? schedule.recurrencePattern?.until
                : defaultSchedule.recurrencePattern?.until;
    }
    else {
        recurrenceEndDate =
            schedule && schedule.recurrencePattern?.expirationDate
                ? schedule.recurrencePattern?.expirationDate
                : defaultSchedule.recurrencePattern?.expirationDate;
    }
    let occurrenceUntilEndDateLabel = '';
    if (values.endDatePicker) {
        const days = values
            .dayPicker.sort((a, b) => sortDays(locale, a, b))
            .map((day) => mapDayToLocaleDay(locale, day))
            .join(', ');
        occurrenceUntilEndDateLabel = intl.formatMessage(messages.occurrenceUntilEndDateLabel, { days });
    }
    return (<>
            {values.endDatePicker && (<Stack styles={{ root: { marginTop: '20px', fontSize: '12px' } }}>
                    <Stack.Item>{occurrenceUntilEndDateLabel}</Stack.Item>
                    <Stack.Item>
                        <Stack horizontal>
                            <Stack.Item>
                                <Field name="endDatePicker" locale={locale} intl={intl} minDate={values.startDatePicker} ariaLabel={occurrenceUntilEndDateLabel} component={EndDatePicker}/>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>)}
            {!values.endDatePicker && (<Stack styles={{ root: { marginTop: '20px', fontSize: '12px' } }}>
                    <Stack.Item>
                        <FormattedMessage id="OccurrenceNoendDateLabel" defaultMessage="Occurs every {days}." description="Label to indicate the schedule occurs on specified days" values={{
        days: values
            .dayPicker.sort((a, b) => sortDays(locale, a, b))
            .map((day) => mapDayToLocaleDay(locale, day))
            .join(', '),
    }}/>
                    </Stack.Item>
                    <Stack.Item>
                        <ActionButton styles={{
        root: {
            color: palette?.themePrimary,
            fontSize: '12px',
            padding: '0px 0px 22px 0px',
        },
        label: {
            margin: '0px',
            marginTop: '6px',
        },
        rootHovered: {
            color: palette?.themeDark,
        },
    }} onClick={() => {
        form.change('endDatePicker', recurrenceEndDate);
    }}>
                            {chooseEndDate}
                        </ActionButton>
                    </Stack.Item>
                </Stack>)}
        </>);
};
const EndDateChooser = injectIntl(EndDateChooserInjected);
const RecurrenceDialogInjected = (props) => {
    const { isCurrentLabParentLabAccount, locale, intl, form, values, schedule, defaultSchedule, recurrenceFormValues, isDialogOpen, onDialogClose, } = props;
    const repeat = intl.formatMessage(scheduleMessages.repeatLabel);
    const everyWeek = intl.formatMessage(messages.everyWeekLabel);
    const never = intl.formatMessage(messages.neverLabel);
    const save = intl.formatMessage(commonMessages.save);
    const cancel = intl.formatMessage(commonMessages.cancel);
    const validationMessage = intl.formatMessage(scheduleMessages.selectedDaysValidationMessage);
    const onDialogCancel = () => {
        onDialogClose();
        form.change('dayPicker', recurrenceFormValues.dayPicker);
        form.change('recurrencePicker', recurrenceFormValues.recurrencePicker);
        const until = recurrenceFormValues.endDatePicker ? new Date(recurrenceFormValues.endDatePicker) : undefined;
        form.change('endDatePicker', until);
    };
    return (<Dialog styles={{ root: { padding: '0px 24px 24px 24px' } }} minWidth="360px" dialogContentProps={{
        type: DialogType.normal,
        title: repeat,
    }} modalProps={{
        className: 'schedules__recurrence-dialog',
        topOffsetFixed: false,
    }} hidden={!isDialogOpen} onDismiss={onDialogCancel}>
            <Stack styles={{ root: { width: '312px', minHeight: '100px' } }}>
                <Stack.Item>
                    <Field label={repeat} name="recurrencePicker" component={RecurrencePicker} options={[
        { key: 'weekly', text: everyWeek },
        { key: 'never', text: never },
    ]}/>
                </Stack.Item>
                <Stack.Item styles={{ root: { width: '300px' } }}>
                    {values.recurrencePicker === 'weekly' && (<Field name="dayPicker" locale={locale} component={ScheduledDayPicker} validate={(value) => validateSelectedDays(value, validationMessage)}/>)}
                </Stack.Item>
                <Stack.Item>
                    {values.recurrencePicker === 'weekly' && (<EndDateChooser isCurrentLabParentLabAccount={isCurrentLabParentLabAccount} locale={locale} form={form} values={values} schedule={schedule} defaultSchedule={defaultSchedule}/>)}
                </Stack.Item>
                <DialogFooter styles={{ actionsRight: { marginTop: '10px' } }}>
                    <PrimaryButton disabled={form.getState().hasValidationErrors ||
        (values.recurrencePicker === 'weekly' && !values.endDatePicker)} onClick={onDialogClose} text={save}/>
                    <DefaultButton onClick={onDialogCancel} text={cancel}/>
                </DialogFooter>
            </Stack>
        </Dialog>);
};
export const RecurrenceDialog = injectIntl(RecurrenceDialogInjected);
const RecurrenceDialogControls = {
    RecurrenceDialog,
};
export default RecurrenceDialogControls;
