import { createVNextArmApiClient, get } from '../../arm-data-provider';
import { VNextActionPermissions, hasAccess } from '../../permissions-provider';
import { defaultLabRoles, newLabRoles, Roles } from '../../../../data/models/roles';
import { trackTrace } from '../../../../utils/telemetry/telemetry-channel';
import { Urls } from '../../../../utils/constants';
async function getLabRoles(lab, accessToken, locale, language) {
    const roles = [...defaultLabRoles];
    try {
        if (await hasAccess(VNextActionPermissions.WriteLab, lab.id, accessToken, locale, language)) {
            roles.push(Roles.Write);
        }
        if (await hasAccess(VNextActionPermissions.WriteLabVm, lab.id, accessToken, locale, language)) {
            roles.push(Roles.LabAssistant);
        }
    }
    catch (e) {
        trackTrace(`Unable to determine write access for lab ${lab.name} due to error ${e.message}, assuming no access.`);
    }
    return roles;
}
export async function listSubscriptionLabs(subscription, armAccessToken, locale, language) {
    const client = createVNextArmApiClient(subscription.subscriptionId, armAccessToken, locale, language);
    let currentPage = await client.labs.listBySubscription();
    let response = currentPage;
    while (!!currentPage.nextLink) {
        const nextPage = await client.labs.listBySubscriptionNext(currentPage.nextLink);
        currentPage = nextPage;
        response = response.concat(nextPage);
    }
    const labs = [];
    await Promise.all(response.map(async (lab, index) => {
        const roles = await getLabRoles(lab, armAccessToken, locale, language);
        labs[index] = { ...lab, roles };
    }));
    return labs;
}
export async function listResourceGroupLabs(resourceId, armAccessToken, locale, language) {
    const client = createVNextArmApiClient(resourceId.subscriptionId, armAccessToken, locale, language);
    let currentPage = await client.labs.listByResourceGroup(resourceId.resourceGroupName);
    let response = currentPage;
    while (!!currentPage.nextLink) {
        const nextPage = await client.labs.listByResourceGroupNext(currentPage.nextLink);
        currentPage = nextPage;
        response = response.concat(nextPage);
    }
    const labs = [];
    await Promise.all(response.map(async (lab, index) => {
        const roles = await getLabRoles(lab, armAccessToken, locale, language);
        labs[index] = { ...lab, roles };
    }));
    return labs;
}
export async function createOrUpdateLab(labParentId, labData, accessToken, locale, language) {
    const client = createVNextArmApiClient(labParentId.subscriptionId, accessToken, locale, language);
    const lroPoller = await client.labs.beginCreateOrUpdate(labData, labParentId.resourceGroupName, labData.name);
    return { ...lroPoller.getInitialResponse().parsedBody, roles: [...newLabRoles] };
}
export async function deleteLab(labId, accessToken, locale, language) {
    const client = createVNextArmApiClient(labId.subscriptionId, accessToken, locale, language);
    await client.labs.beginDeleteMethod(labId.resourceGroupName, labId.name);
}
export async function syncLabUser(labId, accessToken, locale, language) {
    const client = createVNextArmApiClient(labId.subscriptionId, accessToken, locale, language);
    await client.labs.syncGroup(labId.resourceGroupName, labId.name);
}
export async function getLab(labId, armAccessToken, labsApiAccessToken, locale, language, includeRoles) {
    const client = createVNextArmApiClient(labId.subscriptionId, armAccessToken, locale, language);
    const lab = await client.labs.get(labId.resourceGroupName, labId.name);
    if (includeRoles) {
        const roles = await getLabRoles(lab, armAccessToken, locale, language);
        return { ...lab, roles };
    }
    return { ...lab, roles: [] };
}
export async function publishLab(labId, accessToken, locale, language) {
    const client = createVNextArmApiClient(labId.subscriptionId, accessToken, locale, language);
    const publishLabResult = await client.labs.publish(labId.resourceGroupName, labId.name);
    return publishLabResult?._response.parsedBody;
}
export async function updateLab(labId, labUpdateData, accessToken, locale, language) {
    const client = createVNextArmApiClient(labId.subscriptionId, accessToken, locale, language);
    return await client.labs.update(labUpdateData, labId.resourceGroupName, labId.name);
}
export async function beginUpdateLab(labId, labUpdateData, accessToken, locale, language) {
    const client = createVNextArmApiClient(labId.subscriptionId, accessToken, locale, language);
    const lroPoller = await client.labs.beginUpdate(labUpdateData, labId.resourceGroupName, labId.name);
    return lroPoller.getInitialResponse().parsedBody;
}
export async function getOperationResult(operation, accessToken, locale, language) {
    const relativeUrl = operation?.id + Urls.OperationsResult;
    const resultPromise = await get(relativeUrl, accessToken, locale, language);
    const result = await resultPromise.json();
    const operationResult = {
        ...operation,
        status: result.status,
        endTime: result.endTime,
        error: result.error,
    };
    return operationResult;
}
const LabProvider = {
    listSubscriptionLabs,
    listResourceGroupLabs,
    createOrUpdateLab,
    deleteLab,
    syncLabUser,
    getLab,
    publishLab,
    updateLab,
    beginUpdateLab,
    getOperationResult,
};
export default LabProvider;
