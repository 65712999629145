import { CommonActionType } from '../common/common-actions';
import { CommonLabParentResourceActionType } from '../lab-parent-resource/lab-parent-resource-actions';
export const CommonSharedImageActionType = {
    CREATE_SHARED_IMAGE: 'CREATE_SHARED_IMAGE',
    CREATE_SHARED_IMAGE_ERROR: 'CREATE_SHARED_IMAGE_ERROR',
    UPDATE_SHARED_IMAGE: 'UPDATE_SHARED_IMAGE',
    UPDATE_SHARED_IMAGE_ERROR: 'UPDATE_SHARED_IMAGE_ERROR',
};
export const SharedImageActionType = {
    ...CommonActionType,
    ...CommonLabParentResourceActionType,
    ...CommonSharedImageActionType,
    LIST_SHARED_IMAGES: 'LIST_SHARED_IMAGES',
    LIST_SHARED_IMAGES_SUCCESS: 'LIST_SHARED_IMAGES_SUCCESS',
    LIST_SHARED_IMAGES_ERROR: 'LIST_SHARED_IMAGES_ERROR',
    GET_SHARED_GALLERY: 'GET_SHARED_GALLERY',
    GET_SHARED_GALLERY_SUCCESS: 'GET_SHARED_GALLERY_SUCCESS',
    GET_SHARED_GALLERY_ERROR: 'GET_SHARED_GALLERY_ERROR',
    UPDATE_SHARED_IMAGE_SUCCESS: 'UPDATE_SHARED_IMAGE_SUCCESS',
};
