import _ from 'lodash';
import { DefaultButton, getTheme, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { commonMessages } from '../language/common-messages';
import Constants from '../utils/constants';
import { Routes } from '../utils/routes';
import { labAppNavMessages } from './lab-app-nav-messages';
import './lab-app-nav.css';
const theme = getTheme();
const navButtonStyles = {
    root: {
        height: '36px',
        minWidth: '58px',
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        border: 0,
        backgroundColor: theme.palette.neutralLighter,
        textAlign: 'left',
    },
    rootHovered: {
        backgroundColor: theme.palette.neutralLight,
    },
    label: {
        fontWeight: 400,
    },
};
const navButtonSelectedStyles = _.merge({}, navButtonStyles, {
    root: {
        backgroundColor: theme.palette.neutralQuaternaryAlt,
    },
    label: {
        fontWeight: 600,
    },
});
const navButtonIconOnlyStyles = _.merge({}, navButtonStyles, {
    root: {
        justifyContent: 'center',
    },
});
const navButtonSelectedIconOnlyStyles = _.merge({}, navButtonSelectedStyles, {
    root: {
        justifyContent: 'center',
    },
});
const navButtonHeader = _.merge({}, navButtonStyles, {
    root: {
        height: '44px',
        width: '58px',
    },
});
const messages = defineMessages({
    collapseAriaLabel: {
        id: 'LabAppNavCollapseButtonLabel',
        defaultMessage: 'Collapse',
        description: 'Aria label for a button to collapse the navigation menu.',
    },
    expandAriaLabel: {
        id: 'LabAppNavExpandButtonLabel',
        defaultMessage: 'Expand',
        description: 'Aria label for a button to expand the navigation menu.',
    },
    labAppNavAriaLabel: {
        id: 'LabAppNavAriaLabel',
        defaultMessage: 'Lab page navigation',
        description: 'Aria label for the lab navigation element.',
    },
});
const LabAppNavInjected = (props) => {
    const { route, selectedLabId, navigateRoute, expanded, updateExpanded, intl } = props;
    const msg = intl.formatMessage;
    const encodedRoute = encodeURI(route);
    const dashboardRoute = Routes.Dashboard(selectedLabId);
    const settingsRoute = Routes.Settings(selectedLabId);
    const templateRoute = Routes.Template(selectedLabId);
    const virtualMachinesRoute = Routes.VirtualMachines(selectedLabId);
    const usersRoute = Routes.Users(selectedLabId);
    const schedulesRoute = Routes.Schedule(selectedLabId);
    const buttonStyles = expanded ? navButtonStyles : navButtonIconOnlyStyles;
    const buttonSelectedStyles = expanded ? navButtonSelectedStyles : navButtonSelectedIconOnlyStyles;
    const headerAria = expanded ? msg(messages.collapseAriaLabel) : msg(messages.expandAriaLabel);
    const dashboardText = msg(labAppNavMessages.dashboard);
    const settingsText = msg(labAppNavMessages.settings);
    const templateText = msg(labAppNavMessages.template);
    const virtualMachinePoolText = msg(labAppNavMessages.virtualMachinePool);
    const usersText = msg(commonMessages.users);
    const scheduleText = msg(commonMessages.schedule);
    const navAriaLabel = msg(messages.labAppNavAriaLabel);
    return (<nav id="lab-app-nav" role="navigation" aria-label={navAriaLabel}>
            <Stack>
                <ul role="menu" aria-expanded={expanded} style={{ minWidth: expanded ? Constants.NavWidthExpanded : undefined }}>
                    <Stack.Item styles={{ root: { borderBottom: 'solid 1px #EDEBE9' } }}>
                        <li role="none">
                            <DefaultButton role="menuitem" iconProps={{ iconName: expanded ? 'DoubleChevronLeft' : 'DoubleChevronRight' }} onClick={() => updateExpanded(!expanded)} ariaLabel={headerAria} title={headerAria} styles={navButtonHeader}/>
                        </li>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { marginTop: '20px' } }}>
                        <li role="none">
                            <DefaultButton role="menuitem" onClick={() => navigateRoute(dashboardRoute)} iconProps={{ iconName: 'ViewDashboard' }} text={expanded ? dashboardText : undefined} title={expanded ? undefined : dashboardText} ariaLabel={dashboardText} styles={encodedRoute === dashboardRoute ? buttonSelectedStyles : buttonStyles}/>
                        </li>
                    </Stack.Item>
                    <Stack.Item>
                        <li role="none">
                            <DefaultButton role="menuitem" onClick={() => navigateRoute(templateRoute)} iconProps={{ iconName: 'TestBeaker' }} text={expanded ? templateText : undefined} title={expanded ? undefined : templateText} ariaLabel={templateText} styles={encodedRoute === templateRoute ? buttonSelectedStyles : buttonStyles}/>
                        </li>
                    </Stack.Item>
                    <Stack.Item>
                        <li role="none">
                            <DefaultButton role="menuitem" onClick={() => navigateRoute(virtualMachinesRoute)} iconProps={{ iconName: 'TVMonitor' }} text={expanded ? virtualMachinePoolText : undefined} title={expanded ? undefined : virtualMachinePoolText} ariaLabel={virtualMachinePoolText} styles={encodedRoute === virtualMachinesRoute ? buttonSelectedStyles : buttonStyles}/>
                        </li>
                    </Stack.Item>
                    <Stack.Item>
                        <li role="none">
                            <DefaultButton role="menuitem" onClick={() => navigateRoute(usersRoute)} iconProps={{ iconName: 'People' }} text={expanded ? usersText : undefined} title={expanded ? undefined : usersText} ariaLabel={usersText} styles={encodedRoute === usersRoute ? buttonSelectedStyles : buttonStyles}/>
                        </li>
                    </Stack.Item>
                    <Stack.Item>
                        <li role="none">
                            <DefaultButton role="menuitem" onClick={() => navigateRoute(schedulesRoute)} iconProps={{ iconName: 'DateTime' }} text={expanded ? scheduleText : undefined} title={expanded ? undefined : scheduleText} ariaLabel={scheduleText} styles={encodedRoute === schedulesRoute ? buttonSelectedStyles : buttonStyles}/>
                        </li>
                    </Stack.Item>
                    <Stack.Item>
                        <li role="none">
                            <DefaultButton role="menuitem" onClick={() => navigateRoute(settingsRoute)} iconProps={{ iconName: 'Settings' }} text={expanded ? settingsText : undefined} title={expanded ? undefined : settingsText} ariaLabel={settingsText} styles={encodedRoute === settingsRoute ? buttonSelectedStyles : buttonStyles}/>
                        </li>
                    </Stack.Item>
                </ul>
            </Stack>
        </nav>);
};
export const LabAppNav = injectIntl(LabAppNavInjected);
export default LabAppNav;
