import { routerActions } from 'connected-react-router/immutable';
import { Map } from 'immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { clearFatalError, disableFeature, enableFeature, initialize, } from '../../redux/actions/common/common-action-creators';
import { resetUserSettings, signIn, signOut } from '../../redux/actions/identity/identity-action-creators';
import { clearSelectedLab, selectLab } from '../../redux/actions/lab/lab-action-creators';
import { isValidSupportInfo } from '../../redux/selectors/lab-account-selectors';
import { Feature } from '../../utils/features';
import { Routes } from '../../utils/routes';
import ErrorCode from '../error-codes';
import { PageHeader } from './page-header';
import TeamsPageHeader from './teams-page-header';
import { selectTenant } from '../../redux/actions/common/common-action-creators';
import { getPageHeaderContainerModel } from './page-header-selectors';
import { selectLabParentResource } from '../../redux/actions/lab-parent-resource/lab-parent-resource-action-creators';
import { getLabListRoute } from '../../data/helpers/route-helpers';
import { updateLabPlansLmsSettings } from '../../redux/actions/lab-plan/lab-plan-action-creators';
import { Constants } from '../../utils/constants';
const PageHeaderContainerInjected = (props) => {
    const { isAuthenticated, isAuthenticating, isDevModeEnabled, hasFatalError, fatalErrorCode, imageUrl, name, initials, displayId, hideLabParentPicker, hideLabPicker, hideUserMenu, hideVmListButton, hideTenantPicker, hideFeedback, isLoadingLabParents, isLoadingLabs, labs, labPath, currentLab, isLoadingTenants, tenants, selectedTenantId, isNewLabCreationDisabled, push, pathname, search, clearFatalError, signIn, signOut, selectLab, useCurrentPathForHome: useCurrentPath, enableFeature, disableFeature, enabledFeatures, resetUserSettings, selectTenant, supportSections, defaultSupportSection, isTeamsIntegrationEnabled, labParentItems, currentLabParentItem, currentSupportInfo, selectLabParentResource, isCurrentLabParentLabAccount, isStudentVmList, clearSelectedLab, isLmsIntegrationEnabled, initialize, shouldLoginWithPopupWindow, isLtiStartOidcRoute, locale, labPlans, lmsInstance, updateLabPlansLmsSettings, isLoadingLabPlans, isUpdatingLms, isLmsAdmin, } = props;
    let labParentPickerProps = undefined;
    let labPickerProps = undefined;
    let tenantPickerProps = undefined;
    let onLabParentItemChange = undefined;
    if (!hideLabParentPicker) {
        onLabParentItemChange = (labParentItem) => {
            const { id } = labParentItem;
            const switchingLabParent = !currentLabParentItem || currentLabParentItem.id !== id;
            if (switchingLabParent) {
                selectLabParentResource(id);
            }
            push({ pathname: getLabListRoute(id), search });
            if (hasFatalError) {
                switch (fatalErrorCode) {
                    case ErrorCode.LabAccountAuthorizationFailed:
                        if (switchingLabParent) {
                            clearFatalError();
                        }
                        break;
                    case ErrorCode.AuthenticationFailed:
                    case ErrorCode.SessionExpired:
                        break;
                    default:
                        clearFatalError();
                        break;
                }
            }
        };
        labParentPickerProps = {
            isLoading: isLoadingLabParents,
            isTeamsOrLmsIntegrationEnabled: isTeamsIntegrationEnabled || isLmsIntegrationEnabled,
            selectedItem: currentLabParentItem,
            labParentItems,
            onChange: onLabParentItemChange,
        };
        if (!hideLabPicker && !!currentLabParentItem) {
            const onLabChange = (lab) => {
                const { id } = lab;
                const switchingLab = !currentLab || currentLab.id !== id;
                if (switchingLab) {
                    selectLab(id);
                    if (labPath) {
                        push({ pathname: `${id}${labPath}`, search });
                    }
                    else {
                        push({ pathname: Routes.Template(id), search });
                    }
                }
                if (hasFatalError) {
                    switch (fatalErrorCode) {
                        case ErrorCode.LabAuthorizationFailed:
                            if (switchingLab) {
                                clearFatalError();
                            }
                            break;
                        case ErrorCode.AuthenticationFailed:
                        case ErrorCode.SessionExpired:
                            break;
                        default:
                            clearFatalError();
                            break;
                    }
                }
            };
            labPickerProps = {
                isVNextLabs: !isCurrentLabParentLabAccount,
                isLoading: isLoadingLabs,
                isTeamsOrLmsIntegrationEnabled: isTeamsIntegrationEnabled || isLmsIntegrationEnabled,
                labs,
                selected: currentLab,
                onChange: onLabChange,
                isNewLabCreationDisabled,
                newLab: isCurrentLabParentLabAccount
                    ? () => push({ pathname: Routes.NewLab(currentLabParentItem.id), search })
                    : () => {
                        clearSelectedLab();
                        push({ pathname: Routes.NewVNextLab(currentLabParentItem.id), search });
                    },
            };
        }
    }
    if (!hideTenantPicker && tenants.size > 1) {
        const onTenantChange = (tenant) => {
            const { tenantId } = tenant;
            const switchingTenant = selectedTenantId !== tenantId;
            if (switchingTenant) {
                selectTenant(tenantId, !isLmsIntegrationEnabled);
                if (hasFatalError) {
                    switch (fatalErrorCode) {
                        case ErrorCode.AuthenticationFailed:
                        case ErrorCode.SessionExpired:
                            break;
                        default:
                            clearFatalError();
                            break;
                    }
                }
            }
        };
        tenantPickerProps = {
            isLoading: isLoadingTenants,
            tenants,
            selected: tenants.find((a) => a.tenantId === selectedTenantId),
            onChange: onTenantChange,
        };
    }
    let userMenuProps = undefined;
    if (!hideUserMenu) {
        userMenuProps = {
            isAuthenticated,
            isAuthenticating,
            imageUrl,
            name,
            initials,
            displayId,
            isLmsIntegrationEnabled,
            signIn: shouldLoginWithPopupWindow ? () => initialize(true) : () => signIn(true),
            signOut,
        };
    }
    const homeButtonProps = {
        isLmsIntegrationEnabled,
        navigateHome: () => {
            const path = useCurrentPath || isLtiStartOidcRoute ? pathname : Routes.Home;
            if (!isLtiStartOidcRoute) {
                clearSelectedLab();
            }
            push({ pathname: path, search });
            if (hasFatalError && !isLtiStartOidcRoute) {
                switch (fatalErrorCode) {
                    case ErrorCode.LabAccountAuthorizationFailed:
                        if (labParentItems.size > 0) {
                            const firstItem = labParentItems.get(0);
                            selectLabParentResource(firstItem.id);
                            clearFatalError();
                        }
                        break;
                    case ErrorCode.LabAuthorizationFailed:
                        selectLab('');
                        clearFatalError();
                        break;
                    case ErrorCode.AuthenticationFailed:
                    case ErrorCode.SessionExpired:
                        break;
                    default:
                        clearFatalError();
                        break;
                }
            }
        },
    };
    let featureMenuProps;
    if (isDevModeEnabled) {
        const allFeatures = Object.getOwnPropertyNames(Feature)
            .sort()
            .map((o) => o);
        featureMenuProps = {
            allFeatures,
            enabledFeatures,
            enableFeature,
            disableFeature,
            resetUserSettings,
        };
    }
    let helpFlyoutPropsForSupportSection = { isStudentVmList, locale };
    if (supportSections && supportSections.count() > 0) {
        helpFlyoutPropsForSupportSection = {
            sections: supportSections,
            defaultSection: defaultSupportSection,
            isLabAccount: isCurrentLabParentLabAccount,
            isStudentVmList,
            locale,
        };
    }
    else if (isValidSupportInfo(currentSupportInfo)) {
        helpFlyoutPropsForSupportSection = {
            sections: Map([['', currentSupportInfo]]),
            isLabAccount: isCurrentLabParentLabAccount,
            isStudentVmList,
            locale,
        };
    }
    let lmsLabPlanSettingsFlyoutProps = undefined;
    if (isLmsAdmin && isAuthenticated) {
        lmsLabPlanSettingsFlyoutProps = {
            labPlans,
            lmsInstance,
            isSubmitting: isUpdatingLms,
            currentLabParentId: currentLabParentItem?.id,
            onSubmit: updateLabPlansLmsSettings,
            isLoading: isLoadingLabPlans,
            selectLabParentItem: onLabParentItemChange,
        };
    }
    if (isTeamsIntegrationEnabled) {
        return (<TeamsPageHeader isLoading={!!hideLabParentPicker ? undefined : isLoadingLabParents} currentLabParentItem={currentLabParentItem} onClick={onLabParentItemChange} labPickerProps={!!hideLabPicker ? undefined : labPickerProps} featureMenuProps={featureMenuProps} helpFlyoutPropsForSupportSection={helpFlyoutPropsForSupportSection} isVmListEnabled={!hideVmListButton && isAuthenticated}/>);
    }
    else {
        return (<PageHeader homeButtonProps={homeButtonProps} labParentPickerProps={labParentPickerProps} labPickerProps={labPickerProps} tenantPickerProps={tenantPickerProps} featureMenuProps={featureMenuProps} userMenuProps={userMenuProps} helpFlyoutPropsForSupportSection={helpFlyoutPropsForSupportSection} isVmListEnabled={!hideVmListButton && isAuthenticated} lmsLabPlanSettingsFlyoutProps={lmsLabPlanSettingsFlyoutProps} showFeedbackButton={isAuthenticated && !isLmsIntegrationEnabled && !hideFeedback} feedbackSurvey={isStudentVmList ? Constants.Urls.StudentFeedback : Constants.Urls.ProfessorFeedback}/>);
    }
};
const mapStateToProps = (state) => {
    return getPageHeaderContainerModel(state);
};
const mapDispatchToProps = {
    clearFatalError,
    signIn,
    signOut,
    selectLab,
    enableFeature,
    disableFeature,
    resetUserSettings,
    selectTenant,
    selectLabParentResource,
    clearSelectedLab,
    push: routerActions.push,
    initialize,
    updateLabPlansLmsSettings,
};
export const PageHeaderContainer = connect(mapStateToProps, mapDispatchToProps)(PageHeaderContainerInjected);
export default PageHeaderContainer;
