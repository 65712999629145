import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { LabParentResourceActionType, } from '../../actions/lab-parent-resource/lab-parent-resource-actions';
import { listLabAccountsSuccess } from '../../actions/lab-account/lab-account-action-creators';
import { listLabParentResourcesError, listLabParentResourcesSuccess, selectLabParentResource, } from '../../actions/lab-parent-resource/lab-parent-resource-action-creators';
import { getSubscriptions } from '../../selectors/subscription-selector';
import { isLmsHost, isVNextFeatureEnabled } from '../../selectors/common-selectors';
import { loadSubscriptionLabAccounts } from '../lab-account/list-lab-accounts';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { listVNextLabsSuccess } from '../../actions/lab/lab-action-creators';
import { loadSubscriptionVNextLabs } from '../lab/list-labs';
import { loadSubscriptionLabPlans } from '../lab-plan/list-lab-plan';
import { getCurrentLabParentId } from '../../selectors/lab-parent-resource-selectors';
import { listLabPlansSuccess } from '../../actions/lab-plan/lab-plan-action-creators';
import { ResourceId } from '../../../utils/resource-id';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { getCurrentLmsInstance, getLtiContextId, isLmsAdministrator } from '../../selectors/lti-selectors';
import { compareByName } from '../../../utils/common';
import { isHealthyState } from '../../../utils/provisioning-state';
import { isLinkedLmsInstanceValid, isValidLabPlan } from '../../../redux/selectors/vnext/lab-plan-selectors';
function getVNextLabParentItem(id) {
    const resourceId = new ResourceId(id, true);
    return {
        id: resourceId.parent?.id ?? '',
        name: resourceId.parent?.name ?? '',
    };
}
export function getFirstValidLabParentId(labAccounts, labs, labPlans, lmsInstance, isLms) {
    let labParentId;
    if (labAccounts.length > 0) {
        const validLabAccounts = labAccounts.filter((o) => isHealthyState(o)).sort(compareByName);
        labParentId = validLabAccounts.length > 0 ? validLabAccounts[0].id : undefined;
    }
    if (!labParentId && labs.length > 0) {
        const validLabs = labs.filter((o) => isHealthyState(o));
        const resourceGroups = validLabs.map((o) => getVNextLabParentItem(o.id)).sort(compareByName);
        labParentId = resourceGroups.length > 0 ? resourceGroups[0].id : undefined;
    }
    if (!labParentId && labPlans.length > 0) {
        const validLabPlans = labPlans.filter((o) => isValidLabPlan(o, lmsInstance, isLms));
        const resourceGroups = validLabPlans.map((o) => getVNextLabParentItem(o.id)).sort(compareByName);
        labParentId = resourceGroups.length > 0 ? resourceGroups[0].id : undefined;
    }
    return labParentId;
}
export function* listLabParentResources(action) {
    const { selectFirst, selectFirstIfSelectedNotFound } = action;
    try {
        const subscriptions = yield select(getSubscriptions);
        const isVNext = yield select(isVNextFeatureEnabled);
        const isLms = yield select(isLmsHost);
        const loadLabAccountsCalls = subscriptions.toArray().map((subscription) => {
            return call(loadSubscriptionLabAccounts, subscription);
        });
        let labAccounts = [];
        let labs = [];
        const labPlans = [];
        if (isVNext || isLms) {
            const loadLabsCalls = subscriptions.toArray().map((subscription) => {
                return call(loadSubscriptionVNextLabs, subscription);
            });
            const isLmsAdmin = yield select(isLmsAdministrator);
            const loadLabPlansCalls = subscriptions.toArray().map((subscription) => {
                return call(loadSubscriptionLabPlans, subscription, isLmsAdmin);
            });
            if (!isLms) {
                const results = yield all([
                    all(loadLabAccountsCalls),
                    all(loadLabsCalls),
                    all(loadLabPlansCalls),
                ]);
                results[0]?.forEach((value) => {
                    labAccounts.push(...value);
                });
                results[1]?.forEach((value) => {
                    labs.push(...value);
                });
                results[2]?.forEach((value) => {
                    labPlans.push(...value);
                });
            }
            else {
                const results = yield all([
                    all(loadLabsCalls),
                    all(loadLabPlansCalls),
                ]);
                results[0]?.forEach((value) => {
                    labs.push(...value);
                });
                if (isLms) {
                    const ltiContextId = yield select(getLtiContextId);
                    labs = labs.filter((o) => !!o.rosterProfile?.ltiContextId &&
                        caseInsensitiveCultureInvariantCompare(o.rosterProfile?.ltiContextId, ltiContextId) === 0);
                }
                results[1]?.forEach((value) => {
                    labPlans.push(...value);
                });
            }
        }
        else {
            const labAccountsResults = yield all(loadLabAccountsCalls);
            labAccountsResults?.forEach((value) => {
                labAccounts = [...labAccounts, ...value];
            });
        }
        let lmsInstance = '';
        if (isLms) {
            lmsInstance = yield select(getCurrentLmsInstance);
        }
        // select a lab account if required (must happen before success otherwise there is a race condition)
        if (selectFirst) {
            const firstLabParentId = yield call(getFirstValidLabParentId, labAccounts, labs, labPlans, lmsInstance, isLms);
            if (firstLabParentId) {
                yield put(selectLabParentResource(firstLabParentId));
            }
        }
        else if (selectFirstIfSelectedNotFound) {
            // This is used to fallback to the first lab account if the one restored from
            // user settings is no longer being returned. Otherwise, the user would see a resource
            // not found page.
            const selectedLabParentId = yield select(getCurrentLabParentId);
            const foundInLabAccounts = labAccounts.findIndex((la) => caseInsensitiveCultureInvariantCompare(la.id, selectedLabParentId) === 0) > -1;
            const foundInLabPlans = labPlans.findIndex((lp) => {
                const id = new ResourceId(lp.id, true);
                return (caseInsensitiveCultureInvariantCompare(id.parent.id, selectedLabParentId) === 0 &&
                    isLinkedLmsInstanceValid(lp, lmsInstance, isLms));
            }) > -1;
            const foundInLabs = labs.findIndex((lab) => {
                const id = new ResourceId(lab.id, true);
                return caseInsensitiveCultureInvariantCompare(id.parent.id, selectedLabParentId) === 0;
            }) > -1;
            if (!foundInLabAccounts && !foundInLabPlans && !foundInLabs) {
                const firstLabParentId = yield call(getFirstValidLabParentId, labAccounts, labs, labPlans, lmsInstance, isLms);
                if (firstLabParentId) {
                    yield put(selectLabParentResource(firstLabParentId));
                }
            }
        }
        yield put(listLabAccountsSuccess(labAccounts));
        if (isVNext || isLms) {
            yield put(listVNextLabsSuccess(labs));
            yield put(listLabPlansSuccess(labPlans));
        }
        yield put(listLabParentResourcesSuccess());
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, 'all-subscriptions', FailureOperation.ListLabParentResources, e ? e.code : undefined);
        yield put(listLabParentResourcesError(error));
    }
}
export function* listLabParentResourcesSaga() {
    yield takeLatest(LabParentResourceActionType.LIST_LAB_PARENT_RESOURCES, listLabParentResources);
}
