import { GalleryImageActionType, } from './gallery-image-actions';
export const listGalleryImages = (labAccountId) => ({
    type: GalleryImageActionType.LIST_GALLERY_IMAGES,
    labAccountId,
});
export const listGalleryImagesSuccess = (images) => ({
    type: GalleryImageActionType.LIST_GALLERY_IMAGES_SUCCESS,
    images,
});
export const listGalleryImagesError = (error) => ({
    type: GalleryImageActionType.LIST_GALLERY_IMAGES_ERROR,
    error,
});
const actions = {
    listGalleryImages,
    listGalleryImagesSuccess,
    listGalleryImagesError,
};
export default actions;
