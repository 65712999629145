import FetchMock from 'fetch-mock';
import QueryString from 'query-string';
import { Modes } from './modes';
import MockScenarios from './mock-scenarios';
export function setMocks(_scenario) {
    // no mocks right now
}
export function init() {
    // enable passthrough so we can run partial mocks
    FetchMock.config.fallbackToNetwork = true;
    const queryParams = QueryString.parse(window.location.search);
    if (queryParams.mode && queryParams.mode.toLowerCase() === Modes.Mock) {
        const scenario = queryParams.scenario ? queryParams.scenario.toLowerCase() : MockScenarios.Default;
        setMocks(scenario);
    }
}
const Mocks = {
    setMocks,
    init,
};
export default Mocks;
