import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    pageTitle: {
        id: 'UserListPageTitle',
        defaultMessage: 'Users',
        description: 'Users page title.',
    },
    expandUserActionsTooltip: {
        id: 'ExpandUserActionsTooltip',
        defaultMessage: 'Additional actions',
        description: 'Tooltip for menu to expand more user actions',
    },
    restrictAccess: {
        id: 'UserListRestrictAccess',
        defaultMessage: 'Restrict access',
        description: 'Label of restrict access toggle on the user page command bar.',
    },
    restrictAccessTooltip: {
        id: 'UserListAccessModeTooltip',
        defaultMessage: 'When Restrict Access is On, users will be unable to join the lab unless they are part of this user list.\nWhen Restrict Access is Off, any user with the registration link will be able to join the lab, even if they are not part of your user list.',
        description: "Tooltip on the 'Restricted access' toggle.",
    },
    quotaCommandScheduleOnly: {
        id: 'UserListQuotaCommandScheduleOnly',
        defaultMessage: 'Quota per user: schedule only',
        description: 'Name of command on the users page command bar that opens Quota configuration flyout in the state when lab usage is allowed only during scheduled hours.',
    },
    quotaCommandHoursFormat: {
        id: 'UserListQuotaCommandHoursFormat',
        defaultMessage: 'Quota per user: {numberOfHours} hour(s)',
        description: 'Name of command on the users page command bar that opens Quota configuration flyout. {numberOfHours} is number of hours the quota is currently set to.',
    },
    quotaTooltip: {
        id: 'UserListQuotaTooltip',
        defaultMessage: 'Configure a quota to limit the maximum number of virtual machine usage hours allocated to each user.\nWhen a user reaches this limit, their machine will be stopped and they will be unable to start it again until the quota is increased.\nScheduled hours are not counted against user quota and machines will still start if scheduled to.',
        description: 'Tooltip on the quota configuration button.',
    },
    addUsersMenu: {
        id: 'UserListAddUsersMenu',
        defaultMessage: 'Add users',
        description: 'Name of Add Users drop-down menu on the users page command bar.',
    },
    addByEmailCommand: {
        id: 'UserListAddByEmailCommand',
        defaultMessage: 'Add by email address',
        description: 'Name of command on users page command bar to invite user(s) using their email address.',
    },
    uploadCsvCommand: {
        id: 'UserListUploadCsvCommand',
        defaultMessage: 'Upload CSV',
        description: 'Name of command on users page command bar to load list of users from a CSV file.',
    },
    uploadCsvTooltip: {
        id: 'UserListUploadCsvTooltip',
        defaultMessage: 'Add multiple users using a CSV file. You can export a CSV file from Excel or create one in a text editor.\nThe first email address on each line will be imported. You will be able to review and edit the imported list before adding them.\nFor more information on the CSV file format see https://aka.ms/azlab-csv.',
        description: 'Tooltip on the Upload CSV command',
    },
    inviteAllCommand: {
        id: 'UserListInviteAllCommand',
        defaultMessage: 'Invite all',
        description: 'Name of command on users page command bar to invite all users to the lab.',
    },
    registrationLinkCommand: {
        id: 'UserListRegistrationLinkCommand',
        defaultMessage: 'Registration link',
        description: 'Name of command on users page command bar to display lab registration link.',
    },
    filterUsers: {
        id: 'UserListFilterUsers',
        defaultMessage: 'Filter users',
        description: 'Tooltip on the filter button above user list.',
    },
    inviteCommand: {
        id: 'UserListInviteCommand',
        defaultMessage: 'Invite',
        description: 'Name of command on users page command bar to invite selected users to the lab.',
    },
    emailColumnHeader: {
        id: 'UserListEmailColumnHeader',
        defaultMessage: 'Email',
        description: 'Title for Email column of a table containing user email addresses.',
    },
    statusColumnHeader: {
        id: 'UserListStatusColumnHeader',
        defaultMessage: 'Status',
        description: 'Title for Status column of a table containing user registration statuses.',
    },
    statusFilterSelected: {
        id: 'UserListStatusFilterSelected',
        defaultMessage: 'Status: {selected}',
        description: 'Text for the user status filter when filters are selected. {selected} is a comma separated list of selected user statuses.',
    },
    invitationColumnHeader: {
        id: 'UserListInvitationColumnHeader',
        defaultMessage: 'Invitation',
        description: 'Title for Invitation column of a table containing status of user invite emails.',
    },
    invitationFilterSelected: {
        id: 'UserListInvitationFilterSelected',
        defaultMessage: 'Invitation: {selected}',
        description: 'Text for the invitation status filter when filters are selected. {selected} is a comma separated list of selected invitation statuses.',
    },
    registered: {
        id: 'UserDetailsRegistered',
        defaultMessage: 'Registered',
        description: 'Text for registered user status.',
    },
    notRegistered: {
        id: 'UserDetailsNotRegistered',
        defaultMessage: 'Not registered',
        description: 'Text for "not registered" user status.',
    },
    sent: {
        id: 'UserDetailsSent',
        defaultMessage: 'Sent',
        description: 'Text for status of user invitation email that was sent.',
    },
    sentFormat: {
        id: 'UserDetailsSentFormat',
        defaultMessage: 'Sent on {date}',
        description: 'Text for status of user invitation email. {date] is the date when invitation was sent.',
    },
    sending: {
        id: 'UserDetailsSending',
        defaultMessage: 'Sending',
        description: 'Text for status of user invitation email while it is being sent.',
    },
    sendingProgress: {
        id: 'UserDetailsSendingProgress',
        defaultMessage: 'Sending...',
        description: 'Text for indicating sending is in progress.',
    },
    notSent: {
        id: 'UserDetailsNotSent',
        defaultMessage: 'Not sent',
        description: 'Text for status of user invitation email when it has not been sent.',
    },
    sendFailed: {
        id: 'UserDetailsSendFailed',
        defaultMessage: 'Failed',
        description: 'Text for status of user invitation email when it failed to be sent.',
    },
    sendFailedFormat: {
        id: 'UserDetailsSendFailedFormat',
        defaultMessage: 'Failed on {date}',
        description: "Text for the status of a user's invitation email when it failed to be sent. {date] is the date when send attempt was made.",
    },
    adjustQuota: {
        id: 'UserDetailsAdjustQuota',
        defaultMessage: 'Adjust quota',
        description: 'Name of the command on the users page command bar for adjusting the additional quota for specific students.',
    },
    textFilterPlaceholder: {
        id: 'UserDetailsTextFilterPlaceholder',
        defaultMessage: 'Filter by name or email',
        description: 'Placeholder text for our text filter that allows filtering of names or emails.',
    },
    userListCheckboxAriaLabel: {
        id: 'UserListCheckboxAriaLabel',
        defaultMessage: 'Select a user',
        description: 'ARIA label for user list row checkboxes.',
    },
    usageCsvColumnName: {
        id: 'UserListUsageCsvColumnName',
        defaultMessage: 'Usage (hours)',
        description: 'Name of usage column in exported CSV file.',
    },
    quotaCsvColumnName: {
        id: 'UserListQuotaCsvColumnName',
        defaultMessage: 'Quota (hours)',
        description: 'Name of quota column in exported CSV file.',
    },
    shimmerAriaLabel: {
        id: 'ShimmerAriaLabel',
        defaultMessage: 'User list is syncing',
        description: 'Aria label when user list is syncing.',
    },
    addUsersManuallyCommand: {
        id: 'UserListAddUsersManuallyCommand',
        defaultMessage: 'Add users manually',
        description: 'Button text to manually add users to the lab.',
    },
    syncFromGroupCommand: {
        id: 'UserListSyncFromGroupCommand',
        defaultMessage: 'Sync from group',
        description: 'Button text to sync lab users from a group.',
    },
    syncNowCommand: {
        id: 'UserListSyncNowCommand',
        defaultMessage: 'Sync now',
        description: 'Button text to sync lab users from a group now.',
    },
});
export default messages;
