import { all, fork } from 'redux-saga/effects';
import { listUsersSaga, pollUsersSaga } from './list-users';
import { addUsersSaga } from './add-users';
import { deleteUsersSaga } from './delete-users';
import { inviteUsersSaga } from './invite-users';
import { updateAdditionalQuotaSaga } from './update-additional-quota';
export function* rootSaga() {
    yield all([
        fork(listUsersSaga),
        fork(pollUsersSaga),
        fork(addUsersSaga),
        fork(deleteUsersSaga),
        fork(inviteUsersSaga),
        fork(updateAdditionalQuotaSaga),
    ]);
}
