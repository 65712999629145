import { call, put, takeLatest } from 'redux-saga/effects';
import { clearFatalError, fatalError } from '../../actions/common/common-action-creators';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { requestStorageAccess } from '../../../data/providers/lti-provider';
import { LtiActionType } from '../../actions/lti/lti-actions';
import { ltiRequestWebStorageAccessError, ltiRequestWebStorageAccessSuccess, } from '../../actions/lti/lti-action-creators';
import ErrorCode from '../../../common/error-codes';
export function* ltiRequestStorageAccess(action) {
    try {
        yield call(requestStorageAccess);
        yield put(ltiRequestWebStorageAccessSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.LtiRequestBrowserStorageAccess, ErrorCode.CookiesAndDataBlocked);
        yield put(ltiRequestWebStorageAccessError(error));
        yield put(clearFatalError());
        yield put(fatalError(ErrorCode.CookiesAndDataBlocked, error));
    }
}
export function* ltiRequestStorageAccessSaga() {
    yield takeLatest(LtiActionType.LTI_REQUEST_WEB_STORAGE_ACCESS, ltiRequestStorageAccess);
}
