import { findWindows } from 'windows-iana';
import { trackTrace } from '../../../../utils/telemetry/telemetry-channel';
import { getDateInLocalTime } from '../../../helpers/date-helpers';
import { createVNextArmApiClient } from '../../arm-data-provider';
function adjustScheduleDatesIfNeeded(schedules) {
    schedules.forEach((schedule) => adjustScheduleDateIfNeeded(schedule));
}
function adjustScheduleDateIfNeeded(schedule) {
    try {
        if (schedule.startAt) {
            const newStart = getDateInLocalTime(schedule.startAt.toISOString());
            schedule.startAt = newStart;
        }
        const newStop = getDateInLocalTime(schedule.stopAt.toISOString());
        schedule.stopAt = newStop;
        if (schedule.recurrencePattern?.expirationDate) {
            const newExpirationDate = getDateInLocalTime(schedule.recurrencePattern.expirationDate.toISOString());
            schedule.recurrencePattern.expirationDate = newExpirationDate;
        }
        schedule.timeZoneId = findWindows(schedule.timeZoneId);
    }
    catch (e) {
        trackTrace(`schedule-provider.ts: Encountered error adjusting dates for schedule ${JSON.stringify(schedule)}`);
    }
}
export async function listSchedules(id, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(id.subscriptionId, accessToken, locale, language);
    let currentPage = await armApiClient.schedules.listByLab(id.resourceGroupName, id.name);
    let schedules = currentPage;
    while (!!currentPage.nextLink) {
        const nextPage = await armApiClient.schedules.listByLabNext(currentPage.nextLink);
        currentPage = nextPage;
        schedules = schedules.concat(nextPage);
    }
    adjustScheduleDatesIfNeeded(schedules);
    return schedules;
}
export async function deleteSchedule(id, accessToken, locale, language) {
    if (!id.subscriptionId) {
        throw new Error('Subscription id not provided');
    }
    if (!id.resourceGroupName) {
        throw new Error('Resource group name not provided');
    }
    if (!id.name) {
        throw new Error('Schedule name not provided');
    }
    if (!id.parent || !id.parent.name) {
        throw new Error('Lab name not provided');
    }
    const armApiClient = createVNextArmApiClient(id.subscriptionId, accessToken, locale, language);
    await armApiClient.schedules.beginDeleteMethod(id.resourceGroupName, id.parent.name, id.name);
}
export async function createSchedule(id, accessToken, locale, language, schedule) {
    if (!id.subscriptionId) {
        throw new Error('Subscription id not provided');
    }
    if (!id.resourceGroupName) {
        throw new Error('Resource group name not provided');
    }
    if (!schedule.name) {
        throw new Error('Schedule name not provided');
    }
    if (!id.name) {
        throw new Error('Lab name not provided');
    }
    const armApiClient = createVNextArmApiClient(id.subscriptionId, accessToken, locale, language);
    const adjustedSchedule = await armApiClient.schedules.createOrUpdate(schedule, id.resourceGroupName, id.name, schedule.name);
    adjustScheduleDateIfNeeded(adjustedSchedule);
    return adjustedSchedule;
}
export async function updateSchedule(id, accessToken, locale, language, schedule) {
    if (!id.subscriptionId) {
        throw new Error('Subscription id not provided');
    }
    if (!id.resourceGroupName) {
        throw new Error('Resource group name not provided');
    }
    if (!schedule.name) {
        throw new Error('Schedule name not provided');
    }
    if (!id.name) {
        throw new Error('Lab name not provided');
    }
    const armApiClient = createVNextArmApiClient(id.subscriptionId, accessToken, locale, language);
    // We use PUT rather than PATCH here as the startAt field will be ignored in SDK if we set it to null when switching the schedule type from standard to stopOnly.
    const updatedSchedule = await armApiClient.schedules.createOrUpdate(schedule, id.resourceGroupName, id.name, schedule.name);
    adjustScheduleDateIfNeeded(updatedSchedule);
    return updatedSchedule;
}
const ScheduleProvider = {
    listSchedules,
    deleteSchedule,
    createSchedule,
    updateSchedule,
};
export default ScheduleProvider;
