import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CoreQuotaStatus, determineAdditionalVms } from '../redux/selectors/core-limit-selectors';
import { RequestLimitIncreaseLink } from '../common/request-limit-increase-link';
import { isVNextLab } from '../redux/selectors/lab-selectors';
export const CoresRemainingMessage = (props) => {
    const { quotaStatus, currentTenantId, labId, capacity: initialCapacity, hasBeenPublished, remainingVmsInLab, maxCapacity, formCapacity, } = props;
    const additionalVms = !isVNextLab(labId)
        ? determineAdditionalVms(initialCapacity, hasBeenPublished, remainingVmsInLab)
        : remainingVmsInLab !== undefined
            ? remainingVmsInLab - initialCapacity
            : undefined;
    if (quotaStatus === CoreQuotaStatus.Exempt) {
        return <></>;
    }
    if (remainingVmsInLab === undefined) {
        return <></>;
    }
    if (formCapacity === undefined && remainingVmsInLab > 10) {
        return <></>;
    }
    if (formCapacity !== undefined && formCapacity < maxCapacity && remainingVmsInLab > 10) {
        return <></>;
    }
    return (<div style={{ fontSize: '12px' }}>
            <FormattedMessage id="AdditionalMachinesAvailableCapacityFlyout" defaultMessage="{numberOfMachines} additional machines are available due to virtual machine limits on your account. You need to {requestLimitIncreaseLink}. You can expect the process to take 1-5 days. Due to high demand, some SKUs may be temporarily unavailable." description="Message explaining how many additional machines are available for the user." values={{
        numberOfMachines: additionalVms,
        requestLimitIncreaseLink: <RequestLimitIncreaseLink labId={labId} tenantId={currentTenantId}/>,
    }}/>
        </div>);
};
export default CoresRemainingMessage;
