import * as React from 'react';
import { FocusZone } from '@fluentui/react';
import { PageTitle } from './page-title';
import { PageHeaderContainer } from '../page-header/page-header-container';
import { PageFooter } from './page-footer';
import './page.css';
export class Page extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { headerProps, showFooter, isScrollable, title, hideHeader } = this.props;
        return (<>
                <PageTitle subtitle={title}/>
                <div id="page-container" className={isScrollable ? 'page-container-scrollable' : 'page-container-fixed'}>
                    {!hideHeader && (<FocusZone>
                            <PageHeaderContainer {...(headerProps || {})}/>
                        </FocusZone>)}
                    <div role="main" id="page-content" className={isScrollable ? 'page-content-scrollable' : 'page-content-fixed'}>
                        
                        {this.props.children}
                    </div>
                    {showFooter && (<div id="page-footer">
                            <FocusZone>
                                <PageFooter />
                            </FocusZone>
                        </div>)}
                </div>
            </>);
    }
}
export default Page;
