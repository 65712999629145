import { put, call, takeLatest, all } from 'redux-saga/effects';
import { requestUserConsentOrMfa } from '../../../data/providers/teams-provider';
import { IdentityActionType } from '../../actions/identity/identity-actions';
import { requestUserConsentSuccess, requestUserConsentFailed, requestUserConsentError, } from '../../actions/identity/identity-action-creators';
import { fatalError, clearFatalError, initialize } from '../../actions/common/common-action-creators';
import ErrorCode from '../../../common/error-codes';
import { MlClientError, FailureOperation } from '../../../data/ml-client-error';
export function* requestUserConsent(action) {
    try {
        const result = yield call(requestUserConsentOrMfa);
        yield all([put(clearFatalError()), put(requestUserConsentSuccess(result)), put(initialize())]);
    }
    catch (err) {
        const message = !!err ? err.message : undefined;
        const code = !!err ? err.code || err.message : undefined;
        const error = new MlClientError(message, '', FailureOperation.RequestUserConsent, code);
        let fatalErrorCode;
        switch (code) {
            case 'FailedToOpenWindow':
            case 'CancelledByUser':
                fatalErrorCode =
                    code === 'FailedToOpenWindow' ? ErrorCode.InvalidGrantPopupBlocked : ErrorCode.InvalidGrant;
                yield put(requestUserConsentFailed());
                break;
            default:
                fatalErrorCode = ErrorCode.GenericError;
                yield put(requestUserConsentError(error));
                break;
        }
        yield put(clearFatalError());
        yield put(fatalError(fatalErrorCode, error));
    }
}
export function* requestUserConsentSaga() {
    yield takeLatest(IdentityActionType.REQUEST_USER_CONSENT, requestUserConsent);
}
