import Constants from '../../../../utils/constants';
import { createLabsApiClient } from './labs-api-client-factory';
export async function register(registrationCode, accessToken, locale, language) {
    const labsApiClient = createLabsApiClient(Constants.NoSubscriptionId, accessToken, locale, language);
    await labsApiClient.register.global(registrationCode.trim());
}
const RegisterProvider = {
    register,
};
export default RegisterProvider;
