import { DocumentCard, Icon, MessageBar, MessageBarType, getTheme } from '@fluentui/react';
import { NeutralColors } from '@fluentui/theme';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { AzurePolicyViolation, getAzurePolicyViolationErrorMessage } from '../data/ml-client-error';
import { commonMessages } from '../language/common-messages';
import { isLabBlocked, isLabMoving, isWriteableLab, isLabDeleting, isLabDeletionFailed, } from '../redux/selectors/lab-selectors';
import { LabCardFooter } from './lab-card-footer';
import { getErrorMessage } from './lab-list-error-handling';
import { LabServicesModels } from 'lab-services';
const messages = defineMessages({
    readOnlyTitle: {
        id: 'LabCardReadOnlyTitle',
        defaultMessage: '{labName} (read-only)',
        description: 'Title formatting to indicate a lab is read-only. {labName} is replaced with the user defined lab name.',
    },
});
export const LabErrorBanner = (props) => {
    const { error, clearError } = props;
    const intl = useIntl();
    if (!error) {
        return null;
    }
    if (error.code == AzurePolicyViolation.CODE) {
        return (<>
                <MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)} onDismiss={() => clearError(error)}>
                    {getAzurePolicyViolationErrorMessage(error).map((errorMessage, index) => (<div key={index}>{intl.formatMessage(errorMessage)}</div>))}
                </MessageBar>
            </>);
    }
    else {
        return (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={intl.formatMessage(commonMessages.close)} onDismiss={() => clearError(error)}>
                {getErrorMessage(intl, error)}
            </MessageBar>);
    }
};
export const LabCard = (props) => {
    const { lab, isVNextLab, maxUsers, quota, osType, isCustomizable, onCardClicked, onScheduleClicked, onUsersClicked, onVmsClicked, onDeleteClicked, } = props;
    const isDeletionFailed = isLabDeletionFailed(lab);
    const isBlocked = isLabBlocked(lab);
    const isWriteable = isWriteableLab(lab);
    const isMoving = isLabMoving(lab);
    const isDeleting = isLabDeleting(lab);
    const nonClickable = isDeleting || isDeletionFailed;
    const hasError = isBlocked || isDeletionFailed;
    const displayName = isVNextLab ? lab?.title ?? lab.name : lab.name;
    return (<DocumentCard className="lab-list__card" onClick={nonClickable ? undefined : () => onCardClicked(lab.id)} styles={nonClickable || hasError ? { root: { backgroundColor: '#FAF9F8' } } : undefined}>
            <LabCardBody name={displayName} isVNextLab={isVNextLab} isDeletionFailed={isDeletionFailed} isBlocked={isBlocked} isWriteable={isWriteable} isMoving={isMoving} isDeleting={isDeleting} maxUsers={maxUsers} quota={quota} osType={osType} isCustomizable={isCustomizable}/>
            <div className="lab-list__card-divider ms-bgColor-neutralLight"/>
            <LabCardFooter isDeletionFailed={isDeletionFailed} isWriteable={isWriteable} isMoving={isMoving} isDeleting={isDeleting} onScheduleClicked={() => onScheduleClicked(lab.id)} onUsersClicked={() => onUsersClicked(lab.id)} onVmsClicked={() => onVmsClicked(lab.id)} onDeleteClicked={() => onDeleteClicked(lab)}/>
        </DocumentCard>);
};
export const LabCardBody = (props) => {
    const { name, isVNextLab, isDeletionFailed, isBlocked, isWriteable, isMoving, isDeleting, maxUsers, quota, osType, isCustomizable, } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const hasError = isDeletionFailed || isBlocked;
    let bodyInfo;
    if (isDeletionFailed) {
        bodyInfo = (<FormattedMessage id="LabCardDeletionFailedLabMessage" defaultMessage="Lab deletion failed. Please try again, or contact your lab administrator to correct the problem." description="Text shown on a lab card when lab deletion is failed."/>);
    }
    else if (isBlocked) {
        bodyInfo = (<FormattedMessage id="LabCardOutageBlockedLabMessage" defaultMessage="Unfortunately, this lab was affected by the recent Azure Lab Services outage and is not recoverable. Please delete it and create a new lab." description="Text shown on a lab card when the lab is in a blocked state due to an outage."/>);
    }
    else if (isMoving) {
        bodyInfo = (<FormattedMessage id="LabCardMovingLabMessage" defaultMessage="This lab is being moved to another Azure region and will be read-only until the move is finished." description="Text shown on a lab card when the lab is being moved between Azure regions."/>);
    }
    else if (isDeleting) {
        bodyInfo = (<FormattedMessage id="LabCardDeletingLabMessage" defaultMessage="This lab is being deleted. It will be removed when the operation is complete." description="Text shown on a lab card when the lab is being deleted."/>);
    }
    else {
        // no changes to vCurrent Lab
        if (!isVNextLab) {
            bodyInfo = (<FormattedMessage id="QuotaPerUserText" defaultMessage="Quota per user: {quotaSpan}" description="Text indicating quota per user for the lab where the parameter {quotaSpan} contains quota with formatting applied." values={{
                quotaSpan: (<span className="lab-list__card-body-info--emphasis">
                                {quota ? (<FormattedMessage id="QuotaPerUserValue" defaultMessage="{quota} hours" description="The amount of quota (indicated by parameter {quota}) each user gets for the lab." values={{ quota: quota }}/>) : (<FormattedMessage id="ScheduleOnly" defaultMessage="Schedule only" description="Indicates students have no quota and can only use the lab as scheduled"/>)}
                            </span>),
            }}/>);
        }
        else {
            // vnext
            bodyInfo = (<>
                    <div className="lab-list__card-body-info-vnext-quota">
                        <FormattedMessage id="VNextQuotaPerUserText" defaultMessage="Quota: {quotaTemplate}" description="Text indicating quota per user for the lab where the parameter {quotaTemplate} contains quota with formatting applied." values={{
                quotaTemplate: (<span className="lab-list__card-body-info--emphasis">
                                        {quota ? (<FormattedMessage id="VNextQuotaPerUserValue" defaultMessage="{quotaValue} hours per user" description="The amount of quota (indicated by parameter {quotaValue}) each user gets for the lab." values={{ quotaValue: quota }}/>) : (<FormattedMessage id="VNextScheduleOnly" defaultMessage="Schedule only" description="Indicates students have no quota and can only use the lab as scheduled"/>)}
                                    </span>),
            }}/>
                    </div>
                    <div className="lab-list__card-body-info-vnext-lab-capacity">
                        <FormattedMessage id="LabVirtualMachinesText" defaultMessage="Lab capacity: <b>{maxUsers} virtual machines</b>" description="Text indicating the number of virtual machines in the {maxUsersTemplate} with formatting applied." values={{
                b: (chunks) => <span style={{ fontWeight: 600 }}>{chunks}</span>,
                maxUsers: maxUsers,
            }}/>
                    </div>
                </>);
        }
    }
    const iconStyle = { backgroundColor: isDeleting || isMoving ? '#797775' : getTheme().palette.themePrimary };
    const titleStyle = { color: isDeletionFailed ? NeutralColors.gray110 : NeutralColors.gray160 };
    const labTitle = !isWriteable || isMoving ? msg(messages.readOnlyTitle, { labName: name }) : name;
    // vCurrent Lab no changes
    if (!isVNextLab) {
        return (<div className="lab-list__card-body">
                <div className="lab-list__card-body-title-section">
                    <div className="lab-list__card-body-icon-section">
                        <Icon iconName="ThisPC" className="lab-list__card-body-icon" styles={{
            root: { color: isDeletionFailed ? NeutralColors.gray70 : NeutralColors.gray100 },
        }}/>
                        {!hasError &&
            (isDeleting ? (<div className="lab-list__card-body-circle" style={iconStyle}>
                                    <Icon iconName="Delete"/>
                                </div>) : (<div className="lab-list__card-body-circle" style={iconStyle}>
                                    {maxUsers}
                                </div>))}
                        {hasError && (<div className="lab-list__card-body-circle lab-list__card-body-failed-circle">
                                <Icon iconName={isDeletionFailed ? 'Cancel' : 'Blocked2'}/>
                            </div>)}
                    </div>
                    <div className="lab-list__card-title" title={labTitle}>
                        <div className="lab-list__card-title-overflow" style={titleStyle}>
                            {labTitle}
                        </div>
                    </div>
                </div>
                <div className="lab-list__card-body-info">{bodyInfo}</div>
            </div>);
    }
    else {
        //vnext
        return (<div className="lab-list__card-body-vnext">
                <div className="lab-list__card-body-title-section-vnext">
                    <div className="lab-list__card-body-icon-section-vnext">
                        {!hasError && isDeleting && (<div className="padding-right">
                                <div className="lab-list__card-body-circle-vnext" style={iconStyle}>
                                    <Icon iconName="Delete"/>
                                </div>
                            </div>)}
                        {hasError && (<div className="padding-right">
                                <div className="lab-list__card-body-circle-vnext lab-list__card-body-failed-circle-vnext">
                                    <Icon iconName={isDeletionFailed ? 'Cancel' : 'Blocked2'}/>
                                </div>
                            </div>)}
                    </div>
                    <div className="lab-list__card-title-vnext" title={labTitle}>
                        <div className="lab-list__card-title-subtitle-container">
                            <div className="lab-list__card-title-overflow-vnext" style={titleStyle}>
                                {labTitle}
                            </div>
                            <div className="lab-list__card-subtitle-vnext">
                                {isCustomizable && (<FormattedMessage id="CustomizableLabMessage" defaultMessage="Customizable lab" description="Text indicating the lab is customizable"/>)}
                                {!isCustomizable && (<FormattedMessage id="NonCustomizableLabMessage" defaultMessage="Non-customizable lab" description="Text indicating whether the lab is non-customizable"/>)}
                            </div>
                        </div>

                        {isVNextLab && (<div className="lab-list__card-title-bubble-container-vnext">
                                {!!osType && (<Icon id="template-page-os-type-icon" iconName={osType === LabServicesModels.OsType.Windows ? 'WindowsLogo' : 'LinuxLogo32'} styles={{
            root: {
                fontSize: '28px',
                lineHeight: '16px',
                color: osType == LabServicesModels.OsType.Windows ? '#0066CC' : '#605E5C',
                marginTop: '6px',
                paddingLeft: osType == LabServicesModels.OsType.Windows ? '7px' : '2px',
            },
        }}/>)}
                            </div>)}
                    </div>
                </div>
                <div className="lab-list__card-body-info-vnext">{bodyInfo}</div>
            </div>);
    }
};
