import { ContextualMenuItemType, DocumentCardActions, ProgressIndicator, Stack, } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { labAppNavMessages } from '../lab-app/lab-app-nav-messages';
import commonMessages from '../language/common-messages';
import './lab-list.css';
const messages = defineMessages({
    labMenuAria: {
        id: 'LabMenuAria',
        defaultMessage: 'Lab menu',
        description: 'Aria label for a button that indicates the lab menu.',
    },
});
export const LabCardFooter = (props) => {
    const { isDeletionFailed, isWriteable, isMoving, isDeleting, onScheduleClicked, onUsersClicked, onVmsClicked, onDeleteClicked, } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const virtualMachinesText = msg(labAppNavMessages.virtualMachinePool);
    const scheduleText = msg(commonMessages.schedule);
    const usersText = msg(commonMessages.users);
    const deleteText = msg(commonMessages.delete);
    const ariaLabel = msg(messages.labMenuAria);
    const items = [];
    if (!isDeletionFailed) {
        items.push({
            key: 'virtualMachines',
            text: virtualMachinesText,
            ariaLabel: virtualMachinesText,
            iconProps: {
                iconName: 'TVMonitor',
            },
            className: 'lab-list__card-footer-menu-items',
            onClick: onVmsClicked,
        }, {
            key: 'schedule',
            text: scheduleText,
            ariaLabel: scheduleText,
            iconProps: {
                iconName: 'DateTime',
            },
            className: 'lab-list__card-footer-menu-items',
            onClick: onScheduleClicked,
        }, {
            key: 'users',
            text: usersText,
            ariaLabel: usersText,
            iconProps: {
                iconName: 'People',
            },
            className: 'lab-list__card-footer-menu-items',
            onClick: onUsersClicked,
        });
    }
    if (isWriteable && !isMoving) {
        items.push({
            key: 'divider',
            itemType: ContextualMenuItemType.Divider,
        }, {
            key: 'delete',
            text: deleteText,
            ariaLabel: deleteText,
            iconProps: {
                iconName: 'Delete',
            },
            className: 'lab-list__card-footer-menu-items',
            onClick: onDeleteClicked,
        });
    }
    return (<div className="lab-list__card-footer">
            <Stack horizontal>
                <Stack.Item>
                    {(isDeleting || isMoving) && (<ProgressIndicator barHeight={4} styles={{
        root: { padding: '7px 15px', width: isDeleting ? 300 : 250 },
        progressTrack: { backgroundColor: '#faf9f8' },
    }}/>)}
                </Stack.Item>
                <Stack.Item>
                    {!isDeleting && (<DocumentCardActions className="lab-list__card-footer-menu-button" actions={[
        {
            ariaLabel,
            ariaDescription: ariaLabel,
            styles: {
                menuIcon: {
                    color: '#605E5C',
                    fontSize: '16px',
                },
            },
            menuIconProps: {
                iconName: 'MoreVertical',
                title: ariaLabel,
                ariaLabel,
            },
            menuProps: {
                items,
                calloutProps: {
                    className: 'lab-list__card-footer-menu',
                },
            },
        },
    ]}/>)}
                </Stack.Item>
            </Stack>
        </div>);
};
export default LabCardFooter;
