import * as React from 'react';
import moment from 'moment';
import 'moment/min/locales';
import { Dropdown, TextField, ComboBox, DatePicker, TooltipHost, DirectionalHint, Stack, } from '@fluentui/react';
import { defineMessages, injectIntl } from 'react-intl';
import { Field } from 'react-final-form';
import { firstDayOfWeekForPicker, dayPickerStrings, onFormatDate } from './flyout-utilities';
import { ScheduleType } from './flyout';
import { validateDate } from '../utils/validators';
const messages = defineMessages({
    standardLabel: {
        id: 'StandardLabel',
        defaultMessage: 'Standard',
        description: `Label indicating a standard schedule (not start-only or stop-only)`,
    },
    startOnlyLabel: {
        id: 'StartOnlyLabel',
        defaultMessage: 'Start only',
        description: `Label indicating a start only schedule`,
    },
    stopOnlyLabel: {
        id: 'StopOnlyLabel',
        defaultMessage: 'Stop only',
        description: 'Label indicating a stop only schedule',
    },
    eventTypeLabel: {
        id: 'EventTypeLabel',
        defaultMessage: 'Event type',
        description: 'Label for dropdown indicating which type of schedule to create',
    },
    stopOnlyScheduleTypeMessage: {
        id: 'StopOnlyScheduleTypeMessage',
        defaultMessage: 'Stop only schedule events can be used to shut down all virtual machines in a lab at the specified time.',
        description: 'Text explaining what stop only events do',
    },
    startOnlyScheduleTypeMessage: {
        id: 'StartOnlyScheduleTypeMessage',
        defaultMessage: 'Start only schedule events can be used to start up all virtual machines in a lab at the specified time.',
        description: 'Text explaining what start only events do',
    },
    standardScheduleTypeMessage: {
        id: 'StandardScheduleTypeMessage',
        defaultMessage: 'Standard schedule events have a designated start and end time where virtual machines are running in the lab.',
        description: 'Text explaining what standard events do',
    },
});
export const TimePicker = (props) => {
    const { input: { value, onChange }, label, options, isSaving, meta: { error }, } = props;
    const [timePickerOptions, setTimePickerOptions] = React.useState(options);
    const formats = [
        'YYYY-MM-DD LT',
        'YYYY-MM-DD H:m',
        'YYYY-MM-DD H:mm',
        'YYYY-MM-DD HH:m',
        'YYYY-MM-DD HH:mm',
        'YYYY-MM-DD hh:m A',
        'YYYY-MM-DD hh:mA',
        'YYYY-MM-DD hh:mm A',
        'YYYY-MM-DD hh:mmA',
        'YYYY-MM-DD h:m A',
        'YYYY-MM-DD h:mA',
        'YYYY-MM-DD h:mm A',
        'YYYY-MM-DD h:mmA',
    ];
    initTimeOptions(timePickerOptions, setTimePickerOptions, value);
    return (<ComboBox dropdownWidth={347} options={timePickerOptions} disabled={isSaving} scrollSelectedToTop={true} allowFreeform calloutProps={{ calloutMaxHeight: 300 }} onChange={(_, option, __, value) => {
        const testValue = `2000-01-01 ${value}`;
        const testValueAsMoment = moment(testValue, formats, true);
        if (value && testValueAsMoment.isValid()) {
            const key = testValueAsMoment.format('HHmm');
            timePickerOptions.push({ key, text: value });
            timePickerOptions.sort(compareByKey);
            setTimePickerOptions(timePickerOptions);
            onChange(key);
        }
        if (option && option.key) {
            onChange(option.key);
        }
    }} text={getDropdownValue(timePickerOptions, value)} label={label} errorMessage={error}/>);
};
const compareByKey = (a, b) => {
    if (+a.key < +b.key) {
        return -1;
    }
    else if (+a.key > +b.key) {
        return 1;
    }
    else {
        return 0;
    }
};
/**
 * If editing a schedule with non-standard time (not one of the default times in the dropdown),
 * make sure it is added to the options so it is seen in the dropdown.
 */
const initTimeOptions = (options, setOptions, value) => {
    const index = options.findIndex((option) => option.key === value);
    if (index < 0) {
        const timeString = `2000-01-01 ${value[0]}${value[1]}:${value[2]}${value[3]}`;
        const text = moment(timeString).format('LT');
        options.push({ key: value, text });
        options.sort(compareByKey);
        setOptions(options);
    }
};
const getDropdownValue = (options, value) => {
    const index = options.findIndex((option) => option.key === value);
    return index > -1 ? options[index].text : '';
};
export const StartDatePickerField = (props) => {
    const { name, label, intl, locale, maxDate, isSaving, required } = props;
    const pickerStrings = dayPickerStrings(locale, intl);
    return (<Field name={name} validate={(value) => validateDate(maxDate, value, pickerStrings.invalidInputErrorMessage)}>
            {(fieldProps) => (<DatePicker calloutProps={{ styles: { root: { marginRight: '8px' } } }} firstDayOfWeek={firstDayOfWeekForPicker(locale)} strings={pickerStrings} value={fieldProps.input.value} disabled={isSaving} formatDate={(date) => {
        return date ? onFormatDate(locale, date) : '';
    }} onSelectDate={(date) => fieldProps.input.onChange(date)} allowTextInput={true} maxDate={maxDate} label={label} isRequired={required}/>)}
        </Field>);
};
export const TimezonePicker = (props) => {
    const { input: { value, onChange }, label, options, placeHolder, isSaving, meta: { error, pristine }, } = props;
    return (<Dropdown defaultSelectedKey={value} disabled={isSaving} options={options} calloutProps={{ calloutMaxHeight: 300 }} onChange={(_, option) => onChange(option.key)} label={label} placeholder={placeHolder} errorMessage={!pristine && error}/>);
};
export const Notes = (props) => {
    const { input: { value, onChange }, label, isSaving, meta: { error }, } = props;
    return (<TextField styles={{ field: { maxHeight: '250px' } }} multiline autoAdjustHeight rows={3} disabled={isSaving} value={value} onChange={onChange} errorMessage={error} label={label}/>);
};
export function validateLength(value, message) {
    return !value || value.length <= 500 ? undefined : message;
}
function onScheduleTypeChange(item, setScheduleType) {
    const key = item.key;
    setScheduleType(key);
}
function renderOption(option) {
    return (<TooltipHost content={option.description} directionalHint={DirectionalHint.leftCenter} styles={{ root: { width: '100%' } }}>
            <Stack styles={{ root: { width: '100%' } }}>
                <Stack.Item>{option.label}</Stack.Item>
            </Stack>
        </TooltipHost>);
}
const ScheduleTypeDropdownInjected = (props) => {
    const { currentScheduleType, setScheduleType, isSaving, isCurrentLabParentLabAccount } = props;
    const standard = props.intl.formatMessage(messages.standardLabel);
    const startOnly = props.intl.formatMessage(messages.startOnlyLabel);
    const stopOnly = props.intl.formatMessage(messages.stopOnlyLabel);
    const eventType = props.intl.formatMessage(messages.eventTypeLabel);
    const standardDescription = props.intl.formatMessage(messages.standardScheduleTypeMessage);
    const startOnlyDescription = props.intl.formatMessage(messages.startOnlyScheduleTypeMessage);
    const stopOnlyDescription = props.intl.formatMessage(messages.stopOnlyScheduleTypeMessage);
    const onRenderTitle = () => {
        const index = eventTypeDropdownOptions.findIndex((option) => option.key === currentScheduleType);
        const label = eventTypeDropdownOptions[index].label;
        return <div>{label}</div>;
    };
    const eventTypeDropdownOptions = [
        {
            key: ScheduleType.standard,
            text: '',
            label: standard,
            description: standardDescription,
        },
        {
            key: ScheduleType.stopOnly,
            text: '',
            label: stopOnly,
            description: stopOnlyDescription,
        },
    ];
    // start only schedules are not allowed in vnext
    if (isCurrentLabParentLabAccount) {
        eventTypeDropdownOptions.push({
            key: ScheduleType.startOnly,
            text: '',
            label: startOnly,
            description: startOnlyDescription,
        });
    }
    const dropdownItemStyle = {
        selectors: {
            '.ms-Button-flexContainer': {
                width: '100%',
            },
        },
    };
    return (<div className="schedules__flyout-control">
            <Dropdown styles={{
        dropdownItem: dropdownItemStyle,
        dropdownItemSelected: dropdownItemStyle,
    }} disabled={isSaving} label={eventType} defaultSelectedKey={currentScheduleType} options={eventTypeDropdownOptions} onChange={(_, item) => onScheduleTypeChange(item, setScheduleType)} onRenderOption={(option) => renderOption(option)} onRenderTitle={onRenderTitle}/>
        </div>);
};
export const ScheduleTypeDropdown = injectIntl(ScheduleTypeDropdownInjected);
const FlyoutSharedControls = {
    StartDatePickerField,
    TimePicker,
    TimezonePicker,
    Notes,
    validateLength,
    ScheduleTypeDropdown,
};
export default FlyoutSharedControls;
