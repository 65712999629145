import { put, call, cancelled, select } from 'redux-saga/effects';
import { MlClientError, FailureOperation } from '../../../data/ml-client-error';
import { CommonActionType } from '../../actions/common/common-actions';
import { studentInitializeSuccess, studentInitializeError, studentInitializeCancelled, } from '../../actions/common/common-action-creators';
import { cancelableTakeLeading } from '../../effects/cancelable';
import { isLmsHost } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
export function* studentInitialize(action) {
    try {
        const shouldSkipGetAccessToken = yield select(isLmsHost);
        // if lms, we got the lti token during initial handshake and stored it.
        // so skip getting access token here when in lms.
        if (!shouldSkipGetAccessToken) {
            // get access tokens, allow a full frame redirect if required
            yield call(getLabsApiAccessTokenSaga, true); // vNext
            yield call(getArmAccessTokenSaga, true); // vCurrent
        }
        yield put(studentInitializeSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.StudentInitialize, err ? err.code : undefined);
        yield put(studentInitializeError(error));
    }
    finally {
        if (yield cancelled()) {
            yield put(studentInitializeCancelled());
        }
    }
}
export function* studentInitializeSaga() {
    yield cancelableTakeLeading(CommonActionType.STUDENT_INITIALIZE, studentInitialize, CommonActionType.STUDENT_INITIALIZE_SUCCESS, CommonActionType.STUDENT_INITIALIZE_ERROR);
}
