import { call, put, takeLatest, select } from 'redux-saga/effects';
import { TemplateActionType } from '../../actions/template/template-actions';
import TemplateProvider from '../../../data/providers/template-provider';
import { stopTemplateError } from '../../actions/template/template-action-creators';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
export function* stopTemplate(action) {
    try {
        const { templateId } = action;
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(TemplateProvider.stopTemplate, templateId, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, action.templateId, FailureOperation.StopTemplate, e ? e.code : undefined);
        yield put(stopTemplateError(error));
    }
}
export function* stopTemplateSaga() {
    yield takeLatest(TemplateActionType.STOP_TEMPLATE, stopTemplate);
}
