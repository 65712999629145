import { List, Record } from 'immutable';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { GroupActionType, } from '../actions/group/group-actions';
function getGroupName(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loading,
    });
}
function getGroupNameSuccess(state, action) {
    const { groupName } = action;
    return state.merge({
        loadState: LoadingStoreState.Loaded,
        groupName,
    });
}
function getGroupNameFailed(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loaded,
    });
}
function getGroupNameError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: LoadingStoreState.LoadingFailed,
        error,
    });
}
function setGroupId(state, action) {
    const { groupId } = action;
    return state.merge({
        groupId,
        groupName: '',
    });
}
function setGroupName(state, action) {
    const { groupName } = action;
    return state.merge({
        groupName,
    });
}
function enableFilterByGroupId(state, action) {
    return state.merge({
        shouldFilterByGroupId: true,
    });
}
function getGroups(state, action) {
    return state.merge({
        groupsLoadState: LoadingStoreState.Loading,
    });
}
function getGroupsSuccess(state, action) {
    const { groups } = action;
    return state.merge({
        groupsLoadState: LoadingStoreState.Loaded,
        groups: List(groups),
    });
}
function getGroupsFailed(state, action) {
    return state.merge({
        groupsLoadState: LoadingStoreState.Loaded,
    });
}
function getGroupsError(state, action) {
    const { error } = action;
    return state.merge({
        groupsLoadState: LoadingStoreState.LoadingFailed,
        error,
    });
}
const initialState = Record({
    groupId: '',
    groupName: '',
    error: undefined,
    shouldFilterByGroupId: false,
    loadState: LoadingStoreState.NotStarted,
    groupsLoadState: LoadingStoreState.NotStarted,
    groups: List(),
})();
export const groupReducer = (state = initialState, action) => {
    switch (action.type) {
        case GroupActionType.GET_GROUP_NAME:
            return getGroupName(state, action);
        case GroupActionType.GET_GROUP_NAME_SUCCESS:
            return getGroupNameSuccess(state, action);
        case GroupActionType.GET_GROUP_NAME_ERROR:
            return getGroupNameError(state, action);
        case GroupActionType.GET_GROUP_NAME_FAILED:
            return getGroupNameFailed(state, action);
        case GroupActionType.SET_GROUP_ID:
            return setGroupId(state, action);
        case GroupActionType.SET_GROUP_NAME:
            return setGroupName(state, action);
        case GroupActionType.ENABLE_FILTER_BY_GROUP_ID:
            return enableFilterByGroupId(state, action);
        case GroupActionType.GET_GROUPS:
            return getGroups(state, action);
        case GroupActionType.GET_GROUPS_SUCCESS:
            return getGroupsSuccess(state, action);
        case GroupActionType.GET_GROUPS_ERROR:
            return getGroupsError(state, action);
        case GroupActionType.GET_GROUPS_FAILED:
            return getGroupsFailed(state, action);
        default:
            return state;
    }
};
export default groupReducer;
