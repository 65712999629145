import { Record, List, Map } from 'immutable';
import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import _ from 'lodash';
import { FailureOperation } from '../../data/ml-client-error';
import { TemplateActionType, } from '../actions/template/template-actions';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import PowerState from '../../utils/power-state';
import ErrorCode from '../../common/error-codes';
import { clearErrorsByOperation } from '../../utils/errors';
import { getRdpAuthority, getSshAuthority } from '../selectors/template-selectors';
import { Context } from '../action-context';
function listTemplates(state, action) {
    return state.merge({
        errors: Map(),
        loadState: LoadingStoreState.Loading,
    });
}
function listTemplatesSuccess(state, action) {
    return state.merge({
        templates: List(action.templates),
        loadState: LoadingStoreState.Loaded,
    });
}
function listTemplatesError(state, action) {
    return state.merge({
        loadState: LoadingStoreState.LoadingFailed,
        loadError: action.error,
        templates: List([]),
    });
}
function getTemplate(state, action) {
    return state.merge({
        loadState: state.loadState === LoadingStoreState.NotStarted ? LoadingStoreState.Loading : LoadingStoreState.Updating,
    });
}
function ensureExpandedInfoNotLost(expandedTemplate, newTemplate) {
    const rdpAuthority = getRdpAuthority(expandedTemplate);
    const sshAuthority = getSshAuthority(expandedTemplate);
    if (rdpAuthority && sshAuthority) {
        _.merge(newTemplate.resourceSettings.referenceVm.vmStateDetails, {
            rdpAuthority,
            sshAuthority,
        });
    }
    else if (rdpAuthority) {
        _.merge(newTemplate.resourceSettings.referenceVm.vmStateDetails, {
            rdpAuthority,
        });
    }
    else if (sshAuthority) {
        _.merge(newTemplate.resourceSettings.referenceVm.vmStateDetails, {
            sshAuthority,
        });
    }
    return newTemplate;
}
/**
 * On expands, we overwrite the entire template.  There is a certain case where we immediately set the pending state so the
 * UI is updated quickly, and we must call expand template immediately after.  We don't want to overwrite the pending state in that case.
 * Expands on template is called only once, to get network information, so this isn't a common occurrence.
 */
function ensurePendingStateNotLost(oldTemplate, newTemplate) {
    if (oldTemplate?.resourceSettings?.referenceVm?.vmStateDetails) {
        _.merge(newTemplate.resourceSettings.referenceVm.vmStateDetails, {
            lastKnownPowerState: oldTemplate.resourceSettings.referenceVm.vmStateDetails.lastKnownPowerState,
        });
    }
    if (oldTemplate?.latestOperationResult?.status === PowerState.Running &&
        oldTemplate?.latestOperationResult?.requestUri === 'resetPassword') {
        _.merge(newTemplate.latestOperationResult, oldTemplate.latestOperationResult);
    }
    return newTemplate;
}
function getTemplateSuccess(state, action) {
    const { expand } = action;
    let templates = state.get('templates');
    const templateIndex = templates.findIndex((t) => t.id === action.template.id);
    if (templateIndex > -1) {
        const template = templates.get(templateIndex);
        action.template = expand
            ? ensurePendingStateNotLost(template, action.template)
            : ensureExpandedInfoNotLost(template, action.template);
        templates = templates.set(templateIndex, action.template);
    }
    else {
        templates = templates.push(action.template);
    }
    return state.merge({
        templates,
        loadState: LoadingStoreState.Loaded,
    });
}
function getTemplateError(state, action) {
    return state.merge({
        loadState: LoadingStoreState.LoadingFailed,
        loadError: action.error,
        isUpdating: false,
    });
}
function updateTemplate(state, action) {
    return state.merge({
        isUpdating: true,
    });
}
function updateTemplateSuccess(state, action) {
    let templates = state.get('templates');
    const templateIndex = templates.findIndex((t) => t.id === action.template.id);
    if (templateIndex > -1) {
        const template = templates.get(templateIndex);
        action.template = ensurePendingStateNotLost(template, action.template);
        action.template = ensureExpandedInfoNotLost(template, action.template);
        templates = templates.set(templateIndex, action.template);
    }
    else {
        templates = templates.push(action.template);
    }
    return state.merge({
        templates,
        isUpdating: false,
    });
}
function updateTemplateError(state, action) {
    return state.merge({
        errors: addError(state.errors, action.error, action.error.id),
        isUpdating: false,
    });
}
function selectTemplate(state, action) {
    return state.merge({
        currentTemplate: action.template.id,
    });
}
function startTemplate(state, action) {
    const { templateId } = action;
    let { templates, template, templateIndex } = findTemplate(state, templateId); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    const startingTemplate = { ...template };
    _.merge(startingTemplate.resourceSettings.referenceVm.vmStateDetails, {
        lastKnownPowerState: PowerState.Starting,
    });
    templates = templates.set(templateIndex, startingTemplate);
    return state.merge({
        templates,
    });
}
function startTemplateError(state, action) {
    const { error } = action;
    let { templates, template, templateIndex } = findTemplate(state, error.id); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    const errorTemplate = { ...template };
    _.merge(errorTemplate.resourceSettings.referenceVm.vmStateDetails, {
        lastKnownPowerState: PowerState.Stopped,
    });
    templates = templates.set(templateIndex, errorTemplate);
    return state.merge({
        templates,
        errors: addError(state.errors, action.error, action.error.id),
    });
}
function stopTemplate(state, action) {
    const { templateId } = action;
    let { templates, template, templateIndex } = findTemplate(state, templateId); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    const stoppingTemplate = { ...template };
    _.merge(stoppingTemplate.resourceSettings.referenceVm.vmStateDetails, {
        lastKnownPowerState: PowerState.Stopping,
    });
    templates = templates.set(templateIndex, stoppingTemplate);
    return state.merge({
        templates,
    });
}
function stopTemplateError(state, action) {
    const { error } = action;
    let { templates, template, templateIndex } = findTemplate(state, error.id); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    const errorTemplate = { ...template };
    _.merge(errorTemplate.resourceSettings.referenceVm.vmStateDetails, {
        lastKnownPowerState: PowerState.Running,
    });
    templates = templates.set(templateIndex, errorTemplate);
    return state.merge({
        templates,
        errors: addError(state.errors, action.error, action.error.id),
    });
}
function createTemplateError(state, action) {
    const { error: createTemplateError } = action;
    return state.merge({
        createTemplateError,
    });
}
function resetPassword(state, action) {
    const { templateId } = action;
    const errorStore = state.get('errors');
    let { templates, template, templateIndex } = findTemplate(state, templateId); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    const resettingTemplate = { ...template };
    _.merge(resettingTemplate.latestOperationResult, {
        status: PowerState.Running,
        requestUri: 'resetPassword',
    });
    templates = templates.set(templateIndex, resettingTemplate);
    return state.merge({
        templates,
        errors: clearErrorsByOperation(errorStore, FailureOperation.ResetPassword),
    });
}
function resetPasswordError(state, action) {
    const { error } = action;
    let { templates, template, templateIndex } = findTemplate(state, error.id); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    const errorTemplate = { ...template };
    _.merge(errorTemplate.latestOperationResult, {
        status: PowerState.None,
        requestUri: '',
    });
    templates = templates.set(templateIndex, errorTemplate);
    return state.merge({
        templates,
        errors: addError(state.errors, action.error, action.error.id),
    });
}
function publishTemplate(state, action) {
    const { templateId } = action;
    let templates = state.get('templates');
    const index = templates.findIndex((t) => t.id === templateId);
    if (index > -1) {
        const oldTemplate = templates.get(index);
        const newTemplate = { ...oldTemplate, publishingState: Ml.PublishingState.Publishing };
        templates = templates.set(index, newTemplate);
    }
    return state.merge({
        templates,
    });
}
function publishTemplateCancelled(state, action) {
    const { templateId } = action;
    let templates = state.get('templates');
    const index = templates.findIndex((t) => t.id === templateId);
    if (index > -1) {
        const oldTemplate = templates.get(index);
        const newTemplate = { ...oldTemplate, publishingState: Ml.PublishingState.PublishFailed };
        templates = templates.set(index, newTemplate);
    }
    return state.merge({
        templates,
    });
}
function publishTemplateError(state, action) {
    const { error, templateId } = action;
    let templates = state.get('templates');
    const index = templates.findIndex((t) => t.id === templateId);
    if (index > -1) {
        const oldTemplate = templates.get(index);
        const newTemplate = { ...oldTemplate, publishingState: Ml.PublishingState.PublishFailed };
        templates = templates.set(index, newTemplate);
    }
    if (error.code === ErrorCode.PublishExceedsGpuCoresLimit ||
        error.code === ErrorCode.PublishExceedsStandardCoresLimit) {
        return state.merge({
            templates,
            publishExceedsCoresError: error,
        });
    }
    let errors = state.get('errors');
    errors = addError(errors, error, error.id);
    return state.merge({
        templates,
        errors,
    });
}
function createSharedImage(state, action) {
    const { templateId } = action;
    let { templates, template, templateIndex } = findTemplate(state, templateId); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    template = { ...template, uploadState: Ml.UploadState.InProgress };
    templates = templates.set(templateIndex, template);
    return state.merge({
        templates,
    });
}
function createSharedImageError(state, action) {
    const { templateId } = action;
    let { templates, template, templateIndex } = findTemplate(state, templateId); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    template = { ...template, uploadState: Ml.UploadState.Failed };
    templates = templates.set(templateIndex, template);
    return state.merge({
        templates,
        errors: addError(state.errors, action.error, action.error.id),
    });
}
function updateSharedImage(state, action) {
    const { templateId } = action;
    let { templates, template, templateIndex } = findTemplate(state, templateId); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    template = { ...template, uploadState: Ml.UploadState.InProgress };
    templates = templates.set(templateIndex, template);
    return state.merge({
        templates,
    });
}
function updateSharedImageError(state, action) {
    const { templateId } = action;
    let { templates, template, templateIndex } = findTemplate(state, templateId); // eslint-disable-line prefer-const
    if (!template) {
        return state;
    }
    template = { ...template, uploadState: Ml.UploadState.Failed };
    templates = templates.set(templateIndex, template);
    return state.merge({
        templates,
        errors: addError(state.errors, action.error, action.error.id),
    });
}
function findTemplate(state, id) {
    const templates = state.get('templates');
    const templateIndex = templates.findIndex((t) => t.id === id);
    if (templateIndex < 0) {
        return { templates, template: undefined, templateIndex };
    }
    const template = templates.get(templateIndex);
    return { templates, template, templateIndex };
}
function clearError(state, action) {
    let errors = state.errors.get(action.id);
    if (!errors || action.errorIndex >= errors.count()) {
        return state;
    }
    errors = errors.remove(action.errorIndex);
    const errorMap = errors.count() > 0 ? state.errors.set(action.id, errors) : state.errors.remove(action.id);
    return state.merge({
        errors: errorMap,
    });
}
function clearLoadError(state, action) {
    return state.merge({
        loadError: undefined,
    });
}
function clearPublishExceedsCoresError(state, action) {
    return state.merge({
        publishExceedsCoresError: undefined,
    });
}
function addError(errorMap, error, id) {
    const errors = errorMap.get(id);
    if (errors) {
        const existingError = errors.some((_error) => _.isEqual(_error, error));
        if (!existingError) {
            return errorMap.set(id, errors.concat(error));
        }
    }
    else {
        return errorMap.set(id, List([error]));
    }
    return errorMap;
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    loadError: undefined,
    publishExceedsCoresError: undefined,
    isUpdating: false,
    currentTemplate: '',
    templates: List(),
    errors: Map(),
    createTemplateError: undefined,
})();
export const templateReducer = (state = initialState, action) => {
    switch (action.type) {
        case TemplateActionType.CLEAR_USER_SESSION:
        case TemplateActionType.SELECT_LAB_PARENT_RESOURCE:
        case TemplateActionType.SELECT_LAB:
        case TemplateActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    return state;
                default:
                    switch (action.type) {
                        case TemplateActionType.LIST_TEMPLATES:
                            return listTemplates(state, action);
                        case TemplateActionType.LIST_TEMPLATES_SUCCESS:
                            return listTemplatesSuccess(state, action);
                        case TemplateActionType.LIST_TEMPLATES_ERROR:
                            return listTemplatesError(state, action);
                        case TemplateActionType.GET_TEMPLATE:
                            return getTemplate(state, action);
                        case TemplateActionType.GET_TEMPLATE_SUCCESS:
                            return getTemplateSuccess(state, action);
                        case TemplateActionType.GET_TEMPLATE_ERROR:
                            return getTemplateError(state, action);
                        case TemplateActionType.UPDATE_TEMPLATE:
                            return updateTemplate(state, action);
                        case TemplateActionType.UPDATE_TEMPLATE_SUCCESS:
                            return updateTemplateSuccess(state, action);
                        case TemplateActionType.UPDATE_TEMPLATE_ERROR:
                            return updateTemplateError(state, action);
                        case TemplateActionType.START_TEMPLATE:
                            return startTemplate(state, action);
                        case TemplateActionType.START_TEMPLATE_ERROR:
                            return startTemplateError(state, action);
                        case TemplateActionType.STOP_TEMPLATE:
                            return stopTemplate(state, action);
                        case TemplateActionType.STOP_TEMPLATE_ERROR:
                            return stopTemplateError(state, action);
                        case TemplateActionType.CREATE_TEMPLATE_ERROR:
                            return createTemplateError(state, action);
                        case TemplateActionType.RESET_PASSWORD:
                            return resetPassword(state, action);
                        case TemplateActionType.RESET_PASSWORD_ERROR:
                            return resetPasswordError(state, action);
                        case TemplateActionType.PUBLISH_TEMPLATE:
                            return publishTemplate(state, action);
                        case TemplateActionType.PUBLISH_TEMPLATE_CANCELLED:
                            return publishTemplateCancelled(state, action);
                        case TemplateActionType.PUBLISH_TEMPLATE_ERROR:
                            return publishTemplateError(state, action);
                        case TemplateActionType.SELECT_TEMPLATE:
                            return selectTemplate(state, action);
                        case TemplateActionType.CLEAR_TEMPLATE_ERROR:
                            return clearError(state, action);
                        case TemplateActionType.CLEAR_LOAD_TEMPLATE_ERROR:
                            return clearLoadError(state, action);
                        case TemplateActionType.CLEAR_PUBLISH_EXCEEDS_CORES_ERROR:
                            return clearPublishExceedsCoresError(state, action);
                        case TemplateActionType.CREATE_SHARED_IMAGE:
                            return createSharedImage(state, action);
                        case TemplateActionType.CREATE_SHARED_IMAGE_ERROR:
                            return createSharedImageError(state, action);
                        case TemplateActionType.UPDATE_SHARED_IMAGE:
                            return updateSharedImage(state, action);
                        case TemplateActionType.UPDATE_SHARED_IMAGE_ERROR:
                            return updateSharedImageError(state, action);
                        default:
                            return state;
                    }
            }
    }
};
export default templateReducer;
