import { Stack } from '@fluentui/react';
import * as React from 'react';
import { ClockIcon } from './clock-icon';
import { FormattedMessage } from 'react-intl';
export const CreateLabTimeEstimate = (props) => {
    const { timeEstimateMessage } = props;
    return (<Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end" verticalFill style={{ marginBottom: 20, verticalAlign: 'bottom' }}>
            <Stack.Item>
                <ClockIcon />
            </Stack.Item>
            <Stack.Item styles={{ root: { paddingBottom: '2px' } }}>
                <FormattedMessage id="TemplateVmSettingsPageTimeMessage" defaultMessage="Lab creation will take up to <b>{timeEstimateMessage}</b>." description="Message at the top of new lab flyout dialog. {timeEstimateMessage} is the bolded text '20 minutes'." values={{
        b: (chunks) => <b>{chunks}</b>,
        timeEstimateMessage,
    }}/>
            </Stack.Item>
        </Stack>);
};
export default CreateLabTimeEstimate;
