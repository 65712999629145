import { trackTrace } from '../../utils/telemetry/telemetry-channel';
import { getDateInLocalTime } from '../helpers/date-helpers';
import { getMlClient } from './arm-data-provider';
function adjustScheduleDatesIfNeeded(schedules, scheduleJson) {
    const adjustedSchedules = [...schedules];
    for (let i = 0; i < scheduleJson.length; i++) {
        const id = scheduleJson[i].id;
        const index = schedules.findIndex((schedule) => schedule.id.toLowerCase() === id.toLowerCase());
        const newStart = getDateInLocalTime(scheduleJson[i].properties.start);
        const newEnd = getDateInLocalTime(scheduleJson[i].properties.end);
        adjustedSchedules[index].start = newStart;
        adjustedSchedules[index].end = newEnd;
    }
    return adjustedSchedules;
}
function adjustScheduleDateIfNeeded(schedule, scheduleJson) {
    try {
        const newStart = getDateInLocalTime(scheduleJson.properties.start);
        const newEnd = getDateInLocalTime(scheduleJson.properties.end);
        schedule.start = newStart;
        schedule.end = newEnd;
    }
    catch (e) {
        trackTrace(`schedule-provider.ts: Encountered error parsing dates for schedule ${scheduleJson}`);
    }
    return schedule;
}
export async function listSchedules(id, accessToken, locale, language) {
    const mlClient = getMlClient(id.subscriptionId, accessToken, locale, language);
    const schedules = await mlClient.schedules.list(id.resourceGroupName, id.parent.parent.name, id.parent.name, id.name);
    const scheduleHttpResponse = await mlClient.schedules.listWithHttpOperationResponse(id.resourceGroupName, id.parent.parent.name, id.parent.name, id.name);
    const scheduleJsonResponse = JSON.parse(scheduleHttpResponse.bodyAsText).value;
    const adjustedSchedules = adjustScheduleDatesIfNeeded(schedules, scheduleJsonResponse);
    return adjustedSchedules;
}
export async function deleteSchedule(id, accessToken, locale, language) {
    if (!id.subscriptionId) {
        throw new Error('Subscription id not provided');
    }
    if (!id.resourceGroupName) {
        throw new Error('Resource group name not provided');
    }
    if (!id.name) {
        throw new Error('Schedule name not provided');
    }
    if (!id.parent.name) {
        throw new Error('Template name not provided');
    }
    if (!id.parent.parent.name) {
        throw new Error('Lab name not provided');
    }
    if (!id.parent.parent.parent.name) {
        throw new Error('Lab account name not provided');
    }
    const mlClient = getMlClient(id.subscriptionId, accessToken, locale, language);
    await mlClient.schedules.deleteMethod(id.resourceGroupName, id.parent.parent.parent.name, id.parent.parent.name, id.parent.name, id.name);
}
export async function createOrUpdateSchedule(id, accessToken, locale, language, schedule) {
    if (!id.subscriptionId) {
        throw new Error('Subscription id not provided');
    }
    if (!id.resourceGroupName) {
        throw new Error('Resource group name not provided');
    }
    if (!schedule.name) {
        throw new Error('Schedule name not provided');
    }
    if (!id.name) {
        throw new Error('Template name not provided');
    }
    if (!id.parent.name) {
        throw new Error('Lab name not provided');
    }
    if (!id.parent.parent.name) {
        throw new Error('Lab account name not provided');
    }
    const mlClient = getMlClient(id.subscriptionId, accessToken, locale, language);
    const createdScheduleHttpResponse = await mlClient.schedules.createOrUpdateWithHttpOperationResponse(id.resourceGroupName, id.parent.parent.name, id.parent.name, id.name, schedule.name, schedule);
    const deserializedSchedule = createdScheduleHttpResponse.bodyAsJson;
    const scheduleJsonWithoutDeserializedDates = JSON.parse(createdScheduleHttpResponse.bodyAsText);
    const adjustedSchedule = adjustScheduleDateIfNeeded(deserializedSchedule, scheduleJsonWithoutDeserializedDates);
    return adjustedSchedule;
}
const ScheduleProvider = {
    listSchedules,
    deleteSchedule,
    createOrUpdateSchedule,
};
export default ScheduleProvider;
