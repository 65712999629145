import { Dropdown } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import memoize from 'memoize-one';
const messages = defineMessages({
    labPlanLabel: {
        id: 'NewLabConfigPageLabPlanLabel',
        defaultMessage: 'Which plan will be used for this lab?',
        description: 'Label for lab plan picker on new lab flyout.',
    },
});
const getOptions = memoize((labPlans) => {
    const options = [];
    labPlans.map((o) => {
        const option = {
            key: o.id,
            text: o.name,
            data: o,
        };
        options.push(option);
    });
    return options;
});
export const LabPlanPicker = (props) => {
    const { labPlans, selected, required, onChange } = props;
    const intl = useIntl();
    const options = getOptions(labPlans);
    const dropdownProps = {
        options,
        label: intl.formatMessage(messages.labPlanLabel),
        selectedKey: !selected ? undefined : selected.id,
        onChange: (_, option) => !!option && !!option.data && onChange(option.data.id),
        required,
    };
    return <Dropdown {...dropdownProps}/>;
};
export default LabPlanPicker;
