import { SubscriptionActionType, } from './subscription-actions';
export const loadSubscriptions = () => ({
    type: SubscriptionActionType.LOAD_SUBSCRIPTIONS,
});
export const loadSubscriptionsSuccess = (subscriptions) => ({
    type: SubscriptionActionType.LOAD_SUBSCRIPTIONS_SUCCESS,
    subscriptions,
});
export const loadSubscriptionsError = (error) => ({
    type: SubscriptionActionType.LOAD_SUBSCRIPTIONS_ERROR,
    error,
});
export const checkFeatureError = (error) => ({
    type: SubscriptionActionType.CHECK_FEATURE_ERROR,
    error,
});
