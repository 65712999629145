export const CommonActionType = {
    INITIALIZE: 'INITIALIZE',
    INITIALIZE_CANCELLED: 'INITIALIZE_CANCELLED',
    INITIALIZE_SUCCESS: 'INITIALIZE_SUCCESS',
    INITIALIZE_ERROR: 'INITIALIZE_ERROR',
    PROFESSOR_INITIALIZE: 'PROFESSOR_INITIALIZE',
    PROFESSOR_INITIALIZE_CANCELLED: 'PROFESSOR_INITIALIZE_CANCELLED',
    PROFESSOR_INITIALIZE_SUCCESS: 'PROFESSOR_INITIALIZE_SUCCESS',
    PROFESSOR_INITIALIZE_ERROR: 'PROFESSOR_INITIALIZE_ERROR',
    STUDENT_INITIALIZE: 'STUDENT_INITIALIZE',
    STUDENT_INITIALIZE_CANCELLED: 'STUDENT_INITIALIZE_CANCELLED',
    STUDENT_INITIALIZE_SUCCESS: 'STUDENT_INITIALIZE_SUCCESS',
    STUDENT_INITIALIZE_ERROR: 'STUDENT_INITIALIZE_ERROR',
    LAB_APP_INITIALIZE: 'LAB_APP_INITIALIZE',
    LAB_APP_INITIALIZE_CANCELLED: 'LAB_APP_INITIALIZE_CANCELLED',
    LAB_APP_INITIALIZE_SUCCESS: 'LAB_APP_INITIALIZE_SUCCESS',
    LAB_APP_INITIALIZE_ERROR: 'LAB_APP_INITIALIZE_ERROR',
    SET_HOST: 'SET_HOST',
    SET_LOCALE: 'SET_LOCALE',
    SET_TIMEZONE: 'SET_TIMEZONE',
    SET_THEME: 'SET_THEME',
    SET_REFERRER: 'SET_REFERRER',
    FATAL_ERROR: 'FATAL_ERROR',
    PROCESS_URL_FEATURES: 'PROCESS_URL_FEATURES',
    PROCESS_URL_FEATURES_SUCCESS: 'PROCESS_URL_FEATURES_SUCCESS',
    PROCESS_URL_FEATURES_ERROR: 'PROCESS_URL_FEATURES_ERROR',
    ENABLE_FEATURE: 'ENABLE_FEATURE',
    DISABLE_FEATURE: 'DISABLE_FEATURE',
    CLEAR_FATAL_ERROR: 'CLEAR_FATAL_ERROR',
    IDLE_TIMEOUT: 'IDLE_TIMEOUT',
    PROCESS_QUERY_PARAMS: 'PROCESS_QUERY_PARAMS',
    CLEAR_USER_SESSION: 'CLEAR_USER_SESSION',
    ENABLE_FEATURE_SUCCESS: 'ENABLE_FEATURE_SUCCESS',
    ENABLE_FEATURE_ERROR: 'ENABLE_FEATURE_ERROR',
    SELECT_TENANT: 'SELECT_TENANT',
};
