import * as React from 'react';
import { DefaultButton, } from '@fluentui/react';
import { LoadingItem } from './loading-item';
import _ from 'lodash';
import DropdownItem from './dropdown-item';
const PageHeaderDropdownExpanded = (props) => {
    const { menuListProps, defaultRender } = props;
    return <div style={{ verticalAlign: 'middle' }}>{defaultRender(menuListProps)}</div>;
};
export class PageHeaderDropdown extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { items, selected, isLoading, minExpandedWidth, onChange, onRenderSelected, onRenderItem, onRenderPlaceholder, styles, ariaLabel, directionalHint, } = this.props;
        const defaultStyles = {
            root: {
                verticalAlign: 'middle',
                minWidth: '10px',
            },
        };
        const buttonStyles = _.merge({}, defaultStyles, styles || undefined);
        if (isLoading) {
            return <LoadingItem styles={buttonStyles}/>;
        }
        if (items.length < 1) {
            return null;
        }
        let selectedItem = undefined;
        const menuItems = items.map((item) => ({
            key: item.value,
            onRender: (menuItem, dismissMenu) => {
                return (<DropdownItem item={item} onRenderItem={onRenderItem} onClick={() => {
                    onChange(item);
                    dismissMenu();
                }} styles={{
                    root: {
                        padding: '8px 4px',
                        width: '100%',
                        backgroundColor: 'inherit',
                        border: 0,
                        height: '55px',
                    },
                }}/>);
            },
        }));
        selectedItem = selected ? onRenderSelected(selected) : onRenderPlaceholder();
        // NOTE: Setting ariaLabel as ariaDescription so that the screen reader
        // still reads out the selected value of the dropdown
        return (<DefaultButton styles={buttonStyles} ariaDescription={ariaLabel} menuIconProps={{
            styles: {
                root: {
                    marginLeft: '8px',
                    backgroundColor: 'inherit',
                    color: 'inherit',
                },
            },
        }} menuProps={{
            items: menuItems,
            styles: {
                list: {
                    minWidth: minExpandedWidth,
                    maxHeight: '300px',
                    overflowY: 'auto',
                },
            },
            directionalHint,
            onRenderMenuList: (menuListProps, defaultRender) => (<PageHeaderDropdownExpanded menuListProps={menuListProps} defaultRender={defaultRender}/>),
        }}>
                {selectedItem}
            </DefaultButton>);
    }
}
export default PageHeaderDropdown;
