import { Constants } from '../../utils/constants';
import { getItem, setItem } from '../../utils/storage';
import { trackException, trackTrace } from '../../utils/telemetry/telemetry-channel';
import { defaultSettings, settingsCacheKey } from '../models/user-settings';
import { getMlClient } from './arm-data-provider';
export function getCacheKey(displayId) {
    return `${settingsCacheKey}-${displayId}`;
}
export function updateSettingsCache(displayId, settings) {
    const key = getCacheKey(displayId);
    const cache = getUserSettingsFromCache(displayId);
    if (cache) {
        if (settings.disableHints !== undefined) {
            cache.disableHints = settings.disableHints;
        }
        if (settings.dismissedLabListFirstRun !== undefined) {
            cache.dismissedLabListFirstRun = settings.dismissedLabListFirstRun;
        }
        if (settings.showRetirementAlert !== undefined) {
            cache.showRetirementAlert = settings.showRetirementAlert;
        }
        if (settings.hasDismissedCovidBanner !== undefined) {
            cache.hasDismissedCovidBanner = settings.hasDismissedCovidBanner;
        }
        if (settings.hasDismissedOldV1GpuLabBanner !== undefined) {
            cache.hasDismissedOldV1GpuLabBanner = settings.hasDismissedOldV1GpuLabBanner;
        }
        if (settings.lastUsedParentResource !== undefined) {
            cache.lastUsedParentResource = settings.lastUsedParentResource;
        }
        setItem(key, JSON.stringify(cache));
    }
    else {
        setItem(key, JSON.stringify(settings));
    }
}
export function getUserSettingsFromCache(displayId) {
    const key = getCacheKey(displayId);
    const settingsJson = getItem(key);
    if (settingsJson) {
        try {
            const settings = JSON.parse(settingsJson);
            // recast from vcurrent format to vnext
            if (settings.lastUsedLabAccount) {
                settings.lastUsedParentResource = settings.lastUsedLabAccount;
                delete settings.lastUsedLabAccount;
            }
            return settings;
        }
        catch (err) {
            trackException(err);
            trackTrace('UserSettings: failed to parse cached settings.');
        }
    }
    return undefined;
}
export async function getUserSettings(displayId, accessToken, locale, language) {
    const mlClient = getMlClient(Constants.NoSubscriptionId, accessToken, locale, language);
    const response = await mlClient.globalUsers.userSettings(Constants.NoUsername, { settingsJson: undefined });
    if (!response) {
        throw new Error('Failed to get user settings from server');
    }
    if (!!response.settingsJson) {
        const settings = JSON.parse(response.settingsJson);
        // recast from vcurrent format to vnext
        if (settings.lastUsedLabAccount) {
            settings.lastUsedParentResource = settings.lastUsedLabAccount;
            delete settings.lastUsedLabAccount;
        }
        updateSettingsCache(displayId, settings);
        return settings;
    }
    return defaultSettings;
}
export async function updateUserSettings(settings, displayId, accessToken, locale, language) {
    updateSettingsCache(displayId, settings);
    const settingsJson = JSON.stringify(settings);
    const payload = {
        settingsJson,
    };
    const mlClient = getMlClient(Constants.NoSubscriptionId, accessToken, locale, language);
    await mlClient.globalUsers.userSettings(Constants.NoUsername, payload);
}
const UserSettingsProvider = {
    getUserSettingsFromCache,
    getUserSettings,
    updateUserSettings,
};
export default UserSettingsProvider;
