import { checkFeatureError } from './../../redux/actions/subscription/subscription-action-creators';
import { MlClientError, FailureOperation } from './../ml-client-error';
import { call, put, select } from 'redux-saga/effects';
import ArmProvider from './arm-data-provider';
import Constants from '../../utils/constants';
import { getArmAccessTokenSaga } from './../../redux/sagas/identity/access-tokens';
import { getLanguage, getLocale } from './../../redux/selectors/common-selectors';
async function getAllFeatureFlags(subscriptionId, accessToken, locale, language) {
    const allFeatureFlagsUrl = `${subscriptionId}${Constants.Urls.AllFeatureFlags}`;
    const response = await ArmProvider.get(allFeatureFlagsUrl, accessToken, locale, language);
    const allFeaturesResult = response?.json ? await response.json() : [];
    return allFeaturesResult;
}
export function* allFeatures(subscription) {
    let allFeatures = [];
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        allFeatures = yield call(getAllFeatureFlags, subscription.id, accessToken, locale, language);
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, subscription.subscriptionId, FailureOperation.CheckFeatureFlags, err ? err.code : undefined);
        yield put(checkFeatureError(error));
        return allFeatures;
    }
    return allFeatures;
}
