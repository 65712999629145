import { Checkbox, Dropdown, Separator, Stack, SearchBox, } from '@fluentui/react';
import * as React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import memoize from 'memoize-one';
import { DefaultCurrencyCode } from '../../utils/constants';
import { caseInsensitiveLocaleCompare } from '../../utils/string-comparison';
import { getSkuMinMaxCostFromImage, isSkuAvailable } from './create-lab-selectors';
import { messages as vmSizePickerSharedMessages } from './vm-size-picker-shared';
import { getLocalizedSkuDisplayName, isLegacySku, SkuConstants } from '../../common/selectors/sku-selectors';
import commonMessages from '../../language/common-messages';
const messages = defineMessages({
    vNextVmSizePickerTitleMinAndMaxPrice: {
        id: 'VNext_VmSizePickerTitleMinAndMaxPrice',
        defaultMessage: '{size} - {minPrice}-{maxPrice}/hr',
        description: 'The name of a size and its min and max price per hour. {size} is the name of the size, {minPrice} is the minimum price, {maxPrice} is the maximum price.',
    },
    vNextVmSizePickerTitle: {
        id: 'VNext_VmSizePickerTitle',
        defaultMessage: '{size} - {price}/hr',
        description: 'The name of a size and its price per hour. {size} is the name of the size and {price} is the price.',
    },
    vmSizePickerFormatVNext: {
        id: 'VmSizePickerFormatVNext',
        defaultMessage: '{numberOfCores} vCPUs, {memorySize}GB RAM, {storageSize}GB, {storageType}',
        description: 'Virtual machine size specification. {numberOfCores} is the number of CPU cores, {memorySize} is the number of GB of memory, {storageSize} is the disk size in GB, {storageType} is the disk type',
    },
});
const Option = (option) => {
    const { text, subTitle } = option;
    return (<Stack styles={{ root: { width: '100%' } }}>
            <Stack.Item>{text}</Stack.Item>
            <Stack.Item styles={{ root: { color: !option.disabled ? '#555555' : undefined, fontSize: 12 } }}>
                {subTitle}
            </Stack.Item>
        </Stack>);
};
const getText = memoize((sku, vmImageInfo, intl, disabled) => {
    const { formatMessage: msg, formatNumber } = intl;
    const skuDisplayName = getLocalizedSkuDisplayName(sku, intl);
    if (disabled) {
        return skuDisplayName;
    }
    const { min: minPrice, max: maxPrice } = getMinPriceMaxPriceAndCurrency(sku, vmImageInfo);
    return minPrice !== maxPrice
        ? msg(messages.vNextVmSizePickerTitleMinAndMaxPrice, {
            size: skuDisplayName,
            minPrice: formatNumber(minPrice, { style: 'currency', currency: DefaultCurrencyCode }),
            maxPrice: formatNumber(maxPrice, { style: 'currency', currency: DefaultCurrencyCode }),
        })
        : msg(messages.vNextVmSizePickerTitle, {
            size: skuDisplayName,
            price: formatNumber(minPrice, { style: 'currency', currency: DefaultCurrencyCode }),
        });
});
const getSubtitle = memoize((sku, intl) => {
    const { formatMessage: msg } = intl;
    const storageType = caseInsensitiveLocaleCompare(sku.size.storageType, SkuConstants.StandardSSD) === 0
        ? msg(commonMessages.standardSSD)
        : msg(commonMessages.premiumSSD);
    return msg(messages.vmSizePickerFormatVNext, {
        numberOfCores: sku.size.cores,
        memorySize: sku.size.memoryGB,
        storageSize: sku.size.storageGB,
        storageType,
    });
});
const getMinPriceMaxPriceAndCurrency = (sku, vmImageInfo) => {
    const { min, max } = getSkuMinMaxCostFromImage(sku.locations, vmImageInfo);
    return {
        min,
        max,
    };
};
const getOptions = memoize((skus, vmImageInfo, filterStr, intl) => {
    let options = [];
    skus.forEach((sku) => {
        // It's probably not worth storing a generation discriminator in data to make this cleaner because we shouldn't 
        // continue to deal with legacy skus. These should only be for the purpose of migration.
        const disabled = !isSkuAvailable(sku, vmImageInfo) || (isLegacySku(sku.family.toUpperCase()) && vmImageInfo.text.endsWith('(Gen2)'));
        const option = {
            disabled,
            key: sku.name,
            title: '',
            text: getText(sku, vmImageInfo, intl, disabled),
            sku,
            subTitle: getSubtitle(sku, intl),
        };
        options.push(option);
    });
    options = options.filter((o) => o.subTitle.toLocaleLowerCase().indexOf(filterStr) >= 0 ||
        o.text.toLocaleLowerCase().indexOf(filterStr) >= 0);
    return options;
});
const itemStyle = {
    paddingTop: 4,
    paddingBottom: 4,
    height: 'fit-content',
    selectors: {
        '.ms-Button-flexContainer': {
            width: '100%',
        },
    },
};
export const VNextVmSizePicker = (props) => {
    const { skus, selected, vmImageInfo, onChange } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const [showUnavailable, setShowUnavailable] = React.useState(false);
    const [filter, setFilter] = React.useState('');
    const options = getOptions(skus, vmImageInfo, filter, intl);
    const filteredOptions = !showUnavailable ? options.filter((o) => !o.disabled) : options;
    const hasDisabledOptions = options.some((o) => o.disabled);
    const onSearchChange = (_, value) => {
        const filterValue = value?.toLowerCase() || '';
        setFilter(filterValue);
    };
    const dropdownProps = {
        label: msg(vmSizePickerSharedMessages.simplifiedSizeLabel),
        options: filteredOptions,
        placeholder: msg(vmSizePickerSharedMessages.placeholder),
        selectedKey: selected ? [selected.key] : [],
        styles: {
            title: {
                lineHeight: 20,
                height: 50,
                paddingTop: 5,
            },
            dropdownItem: itemStyle,
            dropdownItemSelected: itemStyle,
            dropdownItemDisabled: itemStyle,
            dropdownItemSelectedAndDisabled: itemStyle,
            root: {
                paddingBottom: '8px',
            },
        },
        onDismiss: () => setFilter(''),
        onChange: (_, value) => {
            const option = value;
            if (!option.disabled) {
                onChange(option.sku);
            }
        },
        onRenderOption: (option) => (option ? <Option {...option}/> : null),
        onRenderTitle: (options) => (options ? <Option {...options[0]}/> : null),
        onRenderList: (renderProps, defaultRender) => {
            return (<>
                    <SearchBox placeholder={props.searchPlaceholder} styles={{ root: { margin: 10 } }} onChange={onSearchChange}/>
                    <div style={{ maxHeight: '200px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {defaultRender && defaultRender(renderProps)}
                    </div>
                    {hasDisabledOptions && (<>
                            <Separator styles={{ root: { padding: '0', margin: '0', height: '1px' } }}/>
                            <Checkbox styles={{ root: { padding: '15px 5px 15px 15px' } }} checked={showUnavailable} onChange={(_, value) => setShowUnavailable(value || false)} label={msg(vmSizePickerSharedMessages.showUnavailable)}/>
                        </>)}
                </>);
        },
    };
    return <Dropdown {...dropdownProps} aria-required={true}/>;
};
export default VNextVmSizePicker;
