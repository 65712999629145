import { environmentType, EnvironmentType } from '../utils/environment';
import localSettings from './settings-local.json';
import devSettings from './settings-dev.json';
import prodSettings from './settings-prod.json';
let settings;
switch (environmentType) {
    case EnvironmentType.LOCAL:
        settings = localSettings;
        break;
    case EnvironmentType.DEV:
        settings = { ...localSettings, ...devSettings };
        break;
    case EnvironmentType.INSIDERS:
    case EnvironmentType.PROD:
    default:
        settings = { ...localSettings, ...prodSettings };
        break;
}
export default settings;
