import { Record } from 'immutable';
import { Map } from 'immutable';
import { LabParentResourceActionType, } from '../actions/lab-parent-resource/lab-parent-resource-actions';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { addToListMap } from '../../utils/common';
import { InitializationState } from '../state/common-store';
function selectLabParentResource(state, action) {
    const { resourceId } = action;
    return state.merge({
        currentLabParentId: resourceId,
    });
}
function clearSelectedLabParentResource(state, action) {
    return state.merge({
        currentLabParentId: '',
    });
}
function listLabParentResources(state, action) {
    const oldLoadState = state.get('loadState');
    return state.merge({
        errors: Map(),
        loadState: oldLoadState === LoadingStoreState.Loaded || oldLoadState === LoadingStoreState.Updated
            ? LoadingStoreState.Updating
            : LoadingStoreState.Loading,
    });
}
function listLabParentResourcesSuccess(state, action) {
    const oldLoadState = state.get('loadState');
    return state.merge({
        loadState: oldLoadState === LoadingStoreState.Updating ? LoadingStoreState.Updated : LoadingStoreState.Loaded,
    });
}
function listLabParentResourcesError(state, action) {
    const oldLoadState = state.get('loadState');
    let errors = state.get('errors');
    errors = addToListMap(state.errors, action.error, action.error.id);
    return state.merge({
        errors,
        loadState: oldLoadState === LoadingStoreState.Updating
            ? LoadingStoreState.UpdateFailed
            : LoadingStoreState.LoadingFailed,
    });
}
function refreshSubscriptionsAndLabParentResources(state, action) {
    return state.merge({
        refreshInitializationState: InitializationState.Initializing,
        refreshError: undefined,
        errors: Map(),
    });
}
function refreshSubscriptionsAndLabParentResourcesSuccess(state, action) {
    return state.merge({
        refreshInitializationState: InitializationState.Success,
    });
}
function refreshSubscriptionsAndLabParentResourcesError(state, action) {
    const { refreshError } = action;
    return state.merge({
        refreshInitializationState: InitializationState.Failed,
        refreshError,
    });
}
const initialState = Record({
    currentLabParentId: '',
    loadState: LoadingStoreState.NotStarted,
    errors: Map(),
    refreshError: undefined,
    refreshInitializationState: InitializationState.NotStarted,
})();
export const LabParentResourceReducer = (state = initialState, action) => {
    switch (action.type) {
        case LabParentResourceActionType.CLEAR_USER_SESSION:
        case LabParentResourceActionType.SELECT_TENANT:
            return state.merge(initialState);
        case LabParentResourceActionType.SELECT_LAB_PARENT_RESOURCE:
            return selectLabParentResource(state, action);
        case LabParentResourceActionType.CLEAR_SELECTED_LAB_PARENT_RESOURCE:
            return clearSelectedLabParentResource(state, action);
        case LabParentResourceActionType.LIST_LAB_PARENT_RESOURCES:
            return listLabParentResources(state, action);
        case LabParentResourceActionType.LIST_LAB_PARENT_RESOURCES_SUCCESS:
            return listLabParentResourcesSuccess(state, action);
        case LabParentResourceActionType.LIST_LAB_PARENT_RESOURCES_ERROR:
            return listLabParentResourcesError(state, action);
        case LabParentResourceActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES:
            return refreshSubscriptionsAndLabParentResources(state, action);
        case LabParentResourceActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES_SUCCESS:
            return refreshSubscriptionsAndLabParentResourcesSuccess(state, action);
        case LabParentResourceActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES_ERROR:
            return refreshSubscriptionsAndLabParentResourcesError(state, action);
        default:
            return state;
    }
};
export default LabParentResourceReducer;
