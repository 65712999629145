export const RegistrationState = {
    None: '',
    Registering: 'registering',
    Registered: 'registered',
    Failed: 'failed',
};
export const RegistrationErrorCode = {
    RestrictedLabAccessDenied: 'RestrictedLabAccessDenied',
    InsufficientEnvironmentsToClaim: 'InsufficientEnvironmentsToClaim',
    InvalidRegistrationCode: 'InvalidRegistrationCode',
    CannotRegisterToUnpublishedLab: 'CannotRegisterToUnpublishedLab',
    ResourceOperationNotSupported: 'ResourceOperationNotSupported',
};
