import { UsageActionType, } from './usage-actions';
export const listUsageData = (subscriptionId, regions) => ({
    type: UsageActionType.LIST_USAGE_DATA,
    subscriptionId,
    regions,
});
export const listRegionUsageDataSuccess = (region, data) => ({
    type: UsageActionType.LIST_REGION_USAGE_DATA_SUCCESS,
    region,
    data,
});
export const listUsageDataError = (regions, error) => ({
    type: UsageActionType.LIST_USAGE_DATA_ERROR,
    regions,
    error,
});
export const listUsageDataSuccess = () => ({
    type: UsageActionType.LIST_USAGE_DATA_SUCCESS,
});
export const listUsageDataCancelled = () => ({
    type: UsageActionType.LIST_USAGE_DATA_CANCELLED,
});
const actions = {
    listUsageData,
    listRegionUsageDataSuccess,
    listUsageDataSuccess,
    listUsageDataError,
    listUsageDataCancelled,
};
export default actions;
