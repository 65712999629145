import { CommonActionType } from '../common/common-actions';
export const UserEnvironmentActionType = {
    ...CommonActionType,
    POLL_LIST_GLOBAL_USER_ENVIRONMENTS_START: 'POLL_LIST_GLOBAL_USER_ENVIRONMENTS_START',
    POLL_LIST_GLOBAL_USER_ENVIRONMENTS_STOP: 'POLL_LIST_GLOBAL_USER_ENVIRONMENTS_STOP',
    POLL_LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED: 'POLL_LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED',
    LIST_GLOBAL_USER_ENVIRONMENTS: 'LIST_GLOBAL_USER_ENVIRONMENTS',
    LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED: 'LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED',
    LIST_GLOBAL_USER_ENVIRONMENTS_SUCCESS: 'LIST_GLOBAL_USER_ENVIRONMENTS_SUCCESS',
    LIST_GLOBAL_USER_ENVIRONMENTS_ERROR: 'LIST_GLOBAL_USER_ENVIRONMENTS_ERROR',
    CLEAR_LIST_GLOBAL_USER_ENVIRONMENTS_ERROR: 'CLEAR_LIST_GLOBAL_USER_ENVIRONMENTS_ERROR',
    START_GLOBAL_USER_ENVIRONMENT: 'START_GLOBAL_USER_ENVIRONMENT',
    START_GLOBAL_USER_ENVIRONMENT_FAILED: 'START_GLOBAL_USER_ENVIRONMENT_FAILED',
    START_GLOBAL_USER_ENVIRONMENT_ACCEPTED: 'START_GLOBAL_USER_ENVIRONMENT_ACCEPTED_ACTION',
    CLEAR_START_USER_ENVIRONMENT_ERROR: 'CLEAR_START_USER_ENVIRONMENT_ERROR',
    STOP_GLOBAL_USER_ENVIRONMENT: 'STOP_GLOBAL_USER_ENVIRONMENT',
    STOP_GLOBAL_USER_ENVIRONMENT_FAILED: 'STOP_GLOBAL_USER_ENVIRONMENT_FAILED',
    STOP_GLOBAL_USER_ENVIRONMENT_ACCEPTED: 'STOP_GLOBAL_USER_ENVIRONMENT_ACCEPTED_ACTION',
    CLEAR_STOP_USER_ENVIRONMENT_ERROR: 'CLEAR_STOP_USER_ENVIRONMENT_ERROR',
    RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD: 'RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD',
    RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD_FAILED: 'RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD_FAILED',
    CLEAR_RESET_USER_ENVIRONMENT_PASSWORD_ERROR: 'CLEAR_RESET_USER_ENVIRONMENT_PASSWORD_ERROR',
    CLEAR_GLOBAL_USER_ENVIRONMENT_ERRORS: 'CLEAR_GLOBAL_USER_ENVIRONMENT_ERRORS',
    REIMAGE_USER_VIRTUAL_MACHINE: 'REIMAGE_USER_VIRTUAL_MACHINE',
    REIMAGE_USER_VIRTUAL_MACHINE_ERROR: 'REIMAGE_USER_VIRTUAL_MACHINE_ERROR',
    REIMAGE_USER_VIRTUAL_MACHINE_ACCEPTED: 'REIMAGE_USER_VIRTUAL_MACHINE_ACCEPTED',
    REDEPLOY_USER_VIRTUAL_MACHINE: 'REDEPLOY_USER_VIRTUAL_MACHINE',
    REDEPLOY_USER_VIRTUAL_MACHINE_ERROR: 'REDEPLOY_USER_VIRTUAL_MACHINE_ERROR',
    REDEPLOY_USER_VIRTUAL_MACHINE_ACCEPTED: 'REDEPLOY_USER_VIRTUAL_MACHINE_ACCEPTED',
    CLEAR_REIMAGE_USER_VIRTUAL_MACHINE_ERROR: 'CLEAR_REIMAGE_USER_VIRTUAL_MACHINE_ERROR',
    CLEAR_REDEPLOY_USER_VIRTUAL_MACHINE_ERROR: 'CLEAR_REDEPLOY_USER_VIRTUAL_MACHINE_ERROR',
    LIST_VNEXT_USER_VIRTUAL_MACHINES: 'LIST_VNEXT_USER_VIRTUAL_MACHINES',
    LIST_VNEXT_USER_VIRTUAL_MACHINES_SUCCESS: 'LIST_VNEXT_USER_VIRTUAL_MACHINES_SUCCESS',
    LIST_VNEXT_USER_VIRTUAL_MACHINES_FAILURE: 'LIST_VNEXT_USER_VIRTUAL_MACHINES_FAILURE',
    LIST_VCURRENT_USER_VIRTUAL_MACHINES: 'LIST_VCURRENT_USER_VIRTUAL_MACHINES',
    LIST_VCURRENT_USER_VIRTUAL_MACHINES_FAILURE: 'LIST_VCURRENT_USER_VIRTUAL_MACHINES_FAILURE',
    LIST_ALL_USER_VIRTUAL_MACHINES_SUCCESS: 'LIST_ALL_USER_VIRTUAL_MACHINES_SUCCESS',
};
