import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../language/common-messages';
const messages = defineMessages({
    title: {
        id: 'StartVmsDialogTitle',
        defaultMessage: 'Start virtual machines',
        description: 'Title of confirmation dialog when starting virtual machines.',
    },
});
const StartVmsDialog = (props) => {
    const intl = useIntl();
    const msg = intl.formatMessage;
    return (<Dialog hidden={false} onDismiss={props.onDismiss} dialogContentProps={{ title: msg(messages.title) }} closeButtonAriaLabel={msg(commonMessages.cancel)} styles={{ main: { maxWidth: '358px' } }}>
            <div style={{ paddingTop: '15px' }}>
                <FormattedMessage id="StartVmsDialogTextFormat" defaultMessage="<b>When you start a user's virtual machine, it will start accruing cost. Your usage will not affect the user's quota</b>. Please make sure to shut it down when you are done using it." description="Text of confirmation dialog when starting virtual machines. <b> and </b> should not be localized, but the text between them should." values={{
        b: (chunks) => <b>{chunks}</b>,
    }}/>
            </div>
            <DialogFooter styles={{ actions: { marginTop: '27px' } }}>
                <PrimaryButton text={msg(commonMessages.start)} onClick={props.onSubmit}/>
                <DefaultButton autoFocus text={msg(commonMessages.cancel)} onClick={props.onDismiss}/>
            </DialogFooter>
        </Dialog>);
};
export default StartVmsDialog;
