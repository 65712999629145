import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { ActionButton, DocumentCard, DocumentCardLogo, DocumentCardTitle, DocumentCardType, Icon, Separator, Shimmer, ShimmerElementsGroup, ShimmerElementType, Stack, getTheme, } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedDate, injectIntl } from 'react-intl';
import InfoTip from '../common/info-tip';
import templateMessages from '../template/template-messages';
import { trackEvent } from '../utils/telemetry/telemetry-channel';
import { compareByStartDate, compareVNextScheduleByDate, getReadableSchedule, pickRelevantSchedules, } from './dashboard-selectors';
import { LabServicesModels } from 'lab-services';
import { isVNextLab } from '../redux/selectors/lab-selectors';
import './dashboard.css';
const messages = defineMessages({
    lastPublishedTemplateDashboard: {
        id: 'LastPublishedTemplateDashboard',
        defaultMessage: 'Last published',
        description: 'Label for last published date of template',
    },
    createdTemplateDashboard: {
        id: 'CreatedTemplateDashboard',
        defaultMessage: 'Created',
        description: 'Label for created date of template',
    },
    changedSinceLastPublishTemplateDashboard: {
        id: 'ChangedSinceLastPublishTemplateDashboard',
        defaultMessage: 'Changed since last publish',
        description: 'Message on template tile that the template has been changed since the last publish',
    },
    manageTemplateDashboardButton: {
        id: 'ManageTemplateDashboardButton',
        defaultMessage: 'Manage template',
        description: 'Label for manage template button on template section of dashboard page',
    },
    viewTemplateDashboardButton: {
        id: 'ViewTemplateDashboardButton',
        defaultMessage: 'View template',
        description: 'Label for the view template button for read-only labs on the template section of the dashboard page',
    },
    assignedVmsDashboard: {
        id: 'AssignedVmsDashboard',
        defaultMessage: 'Assigned virtual machines',
        description: 'Label for assigned virtual machines',
    },
    unassignedVmsDashboard: {
        id: 'UnassignedVmsDashboard',
        defaultMessage: 'Unassigned virtual machines',
        description: 'Label for unassigned virtual machines',
    },
    manageVirtualMachinesDashboardButton: {
        id: 'ManageVirtualMachinesDashboardButton',
        defaultMessage: 'Manage virtual machines',
        description: 'Label for manage virtual machines button on virtual machines section of dashboard page',
    },
    viewVirtualMachinesDashboardButton: {
        id: 'ViewVirtualMachinesDashboardButton',
        defaultMessage: 'View virtual machines',
        description: 'Label for the view virtual machines button for read-only labs on virtual machines section of the dashboard page',
    },
    virtualMachinePoolDashboardSectionTitle: {
        id: 'VirtualMachinePoolDashboardSectionTitle',
        defaultMessage: 'Virtual machine pool',
        description: 'Label for Virtual Machine pool section on dashboard page',
    },
    registeredUsersDashboard: {
        id: 'RegisteredUsersDashboard',
        defaultMessage: 'Registered users',
        description: 'Label for registered users',
    },
    unregisteredUsersDashboard: {
        id: 'UnregisteredUsersDashboard',
        defaultMessage: 'Unregistered users',
        description: 'Label for unregistered users',
    },
    manageUsersDashboardButton: {
        id: 'ManageUsersDashboardButton',
        defaultMessage: 'Invite and manage users',
        description: 'Label for manage users button on users section of dashboard page',
    },
    teamsManageUsersDashboardButton: {
        id: 'TeamsManageUsersDashboardButton',
        defaultMessage: 'Manage users',
        description: 'Label for manage users button on users section of dashboard page when in Teams mode',
    },
    viewUsersDashboardButton: {
        id: 'ViewUsersDashboardButton',
        defaultMessage: 'View users',
        description: 'Label for the view users button for read-only labs on users section of the dashboard page',
    },
    usersDashboardSectionTitle: {
        id: 'UsersDashboardSectionTitle',
        defaultMessage: 'Users',
        description: 'Label for Users section on dashboard page',
    },
    setLabSchedulesButton: {
        id: 'SetLabSchedulesButton',
        defaultMessage: 'Set lab schedules',
        description: 'Label for set lab schedules button on schedules section of dashboard page',
    },
    viewLabSchedulesButton: {
        id: 'ViewLabSchedulesButton',
        defaultMessage: 'View lab schedules',
        description: 'Label for the view lab schedules button for read-only labs on the schedules section of the dashboard page',
    },
    schedulesDashboardSectionTitle: {
        id: 'SchedulesDashboardSectionTitle',
        defaultMessage: 'Schedules',
        description: 'Label for Schedules section on dashboard page',
    },
    scheduleTileNoScheduledEvents: {
        id: 'ScheduleTileNoScheduledEvents',
        defaultMessage: 'No scheduled events',
        description: 'Message on schedule tile on dashboard when there are no scheduled events',
    },
    scheduleTileAdditionalScheduledEvents: {
        id: 'ScheduleTileAdditionalScheduledEvents',
        defaultMessage: '+{numEvents} more scheduled event(s)',
        description: 'Message on schedule tile on dashboard when there are {numEvents} more scheduled events not listed in the tile.',
    },
});
const ScheduleOverviewCardInjected = (props) => {
    const { schedules, intl, isLoading, isReadOnly, locale, navigateRoute, labId } = props;
    const msg = intl.formatMessage;
    const info = [];
    const isLabParentLabAccount = !isVNextLab(labId);
    const sortedSchedules = isLabParentLabAccount
        ? schedules.filter((s) => !!s).sort(compareByStartDate)
        : schedules.filter((s) => !!s).sort(compareVNextScheduleByDate);
    if (sortedSchedules.count() !== schedules.count()) {
        let scheduleString = '';
        try {
            scheduleString = JSON.stringify(schedules);
        }
        catch (e) {
            // do nothing
        }
        trackEvent('NULL_SCHEDULE', { schedules: scheduleString });
    }
    if (sortedSchedules.count() === 0) {
        info.push({ value: msg(messages.scheduleTileNoScheduledEvents) });
    }
    else if (sortedSchedules.count() === 1) {
        const summary = getReadableSchedule(isLabParentLabAccount, sortedSchedules.get(0), intl, locale);
        info.push({ value: summary, tooltip: summary });
    }
    else if (sortedSchedules.count() === 2) {
        const summary1 = getReadableSchedule(isLabParentLabAccount, sortedSchedules.get(0), intl, locale);
        const summary2 = getReadableSchedule(isLabParentLabAccount, sortedSchedules.get(1), intl, locale);
        info.push({ value: summary1, tooltip: summary1 });
        info.push({ value: summary2, tooltip: summary2 });
    }
    else {
        const pickedSchedules = pickRelevantSchedules(isLabParentLabAccount, new Date(), sortedSchedules);
        const summary1 = getReadableSchedule(isLabParentLabAccount, pickedSchedules.get(0), intl, locale);
        const summary2 = getReadableSchedule(isLabParentLabAccount, pickedSchedules.get(1), intl, locale);
        info.push({ value: summary1, tooltip: summary1 });
        info.push({ value: summary2, tooltip: summary2 });
        info.push({
            value: msg(messages.scheduleTileAdditionalScheduledEvents, { numEvents: sortedSchedules.count() - 2 }),
        });
    }
    return (<OverviewCard labId={labId} infoSingleColumn={info} title={msg(messages.schedulesDashboardSectionTitle)} iconName="DateTime" buttonText={isReadOnly ? msg(messages.viewLabSchedulesButton) : msg(messages.setLabSchedulesButton)} isLoading={isLoading} navigateRoute={navigateRoute}/>);
};
export const ScheduleOverviewCard = injectIntl(ScheduleOverviewCardInjected);
const UsersOverviewCardInjected = (props) => {
    const { isVNext, users, intl, isLoading, isReadOnly, isGroupSyncModeEnabled, navigateRoute, labId } = props;
    const title = intl.formatMessage(messages.usersDashboardSectionTitle);
    const buttonText = isReadOnly
        ? intl.formatMessage(messages.viewUsersDashboardButton)
        : isGroupSyncModeEnabled
            ? intl.formatMessage(messages.teamsManageUsersDashboardButton)
            : intl.formatMessage(messages.manageUsersDashboardButton);
    const registered = intl.formatMessage(messages.registeredUsersDashboard);
    const unregistered = intl.formatMessage(messages.unregisteredUsersDashboard);
    const isRegistered = (user) => {
        return user.registrationState === Ml.RegistrationState.Registered;
    };
    const isVNextUserRegistered = (user) => {
        return user.registrationState === LabServicesModels.RegistrationState.Registered;
    };
    const unregisteredCount = !isVNext
        ? users.filter((user) => !isRegistered(user)).count()
        : users.filter((user) => !isVNextUserRegistered(user)).count();
    const registeredCount = !isVNext
        ? users.filter((user) => isRegistered(user)).count()
        : users.filter((user) => isVNextUserRegistered(user)).count();
    const info = [
        { label: registered, value: registeredCount },
        { label: unregistered, value: unregisteredCount },
    ];
    return (<OverviewCard labId={labId} info={info} title={title} iconName="People" buttonText={buttonText} isLoading={isLoading} navigateRoute={navigateRoute}/>);
};
export const UsersOverviewCard = injectIntl(UsersOverviewCardInjected);
const VmPoolOverviewCardInjected = (props) => {
    const { isVNext, environments, intl, isLoading, isReadOnly, navigateRoute, labId } = props;
    const title = intl.formatMessage(messages.virtualMachinePoolDashboardSectionTitle);
    const buttonText = isReadOnly
        ? intl.formatMessage(messages.viewVirtualMachinesDashboardButton)
        : intl.formatMessage(messages.manageVirtualMachinesDashboardButton);
    const assigned = intl.formatMessage(messages.assignedVmsDashboard);
    const unassigned = intl.formatMessage(messages.unassignedVmsDashboard);
    const isClaimed = (env) => !!env.claimedByUserPrincipalId;
    const isVNextVmClaimed = (vm) => !!vm.claimedByUserId;
    const unassignedVmCount = !isVNext
        ? environments.filter((env) => !isClaimed(env)).count()
        : environments.filter((vm) => !isVNextVmClaimed(vm)).count();
    const assignedVmCount = !isVNext
        ? environments.filter((env) => isClaimed(env)).count()
        : environments.filter((vm) => isVNextVmClaimed(vm)).count();
    const info = [
        { label: assigned, value: assignedVmCount },
        { label: unassigned, value: unassignedVmCount },
    ];
    return (<OverviewCard labId={labId} info={info} title={title} iconName="TVMonitor" buttonText={buttonText} isLoading={isLoading} navigateRoute={navigateRoute}/>);
};
export const VmPoolOverviewCard = injectIntl(VmPoolOverviewCardInjected);
const TemplateOverviewCardInjected = (props) => {
    const { template, isLoading, isReadOnly, intl, navigateRoute, labId } = props;
    const title = intl.formatMessage(templateMessages.templatePageTitle);
    const buttonText = isReadOnly
        ? intl.formatMessage(messages.viewTemplateDashboardButton)
        : intl.formatMessage(messages.manageTemplateDashboardButton);
    const lastPublished = intl.formatMessage(messages.lastPublishedTemplateDashboard);
    const unpublished = intl.formatMessage(templateMessages.templateUnpublishedStatus);
    const changed = intl.formatMessage(messages.changedSinceLastPublishTemplateDashboard);
    const created = intl.formatMessage(messages.createdTemplateDashboard);
    const tooltip = intl.formatMessage(templateMessages.templateUnpublishedTooltipText);
    const isPublished = template && template.isPublished;
    const lastPublishedDate = template && template.lastPublished && <FormattedDate value={template.lastPublished}/>;
    const createdDate = template && template.createdDate && <FormattedDate value={template.createdDate}/>;
    const warningMessage = template && template.hasEdits ? changed : '';
    const UnpublishedTemplate = () => {
        return (<Stack horizontal>
                <Stack.Item styles={{ root: { fontSize: '14px', fontWeight: '600' } }}>{unpublished}</Stack.Item>
                <Stack.Item>
                    <InfoTip content={tooltip}/>
                </Stack.Item>
            </Stack>);
    };
    const info = [
        { label: created, value: createdDate || '--' },
        { label: lastPublished, value: isPublished ? lastPublishedDate || '--' : <UnpublishedTemplate /> },
    ];
    return (<OverviewCard labId={labId} info={info} title={title} iconName="TestBeaker" buttonText={buttonText} isLoading={isLoading} warningMessage={warningMessage} navigateRoute={navigateRoute}/>);
};
export const TemplateOverviewCard = injectIntl(TemplateOverviewCardInjected);
const HeaderShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 200, height: 20 }]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25 },
    { type: ShimmerElementType.circle, height: 30 },
    { type: ShimmerElementType.gap, width: 15 },
    { type: ShimmerElementType.line, width: 95, height: 16 },
    { type: ShimmerElementType.gap, width: 100 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 200, height: 10 }]}/>
    </>);
const BodyShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25 },
    { type: ShimmerElementType.line, width: 130, height: 9 },
    { type: ShimmerElementType.gap, width: 20 },
    { type: ShimmerElementType.line, width: 72, height: 9 },
    { type: ShimmerElementType.gap, width: 100 },
]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25 },
    { type: ShimmerElementType.line, width: 130, height: 9 },
    { type: ShimmerElementType.gap, width: 20 },
    { type: ShimmerElementType.line, width: 72, height: 9 },
    { type: ShimmerElementType.gap, width: 100 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 217, height: 40 }]}/>
    </>);
const WarningMessageShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25 },
    { type: ShimmerElementType.line, width: 150, height: 9 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 175, height: 20 }]}/>
    </>);
const FooterShimmer = (<>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 250, height: 15 }]}/>
        <ShimmerElementsGroup shimmerElements={[
    { type: ShimmerElementType.gap, width: 25 },
    { type: ShimmerElementType.line, width: 150, height: 9 },
    { type: ShimmerElementType.gap, width: 150 },
]}/>
        <ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.gap, width: 250, height: 15 }]}/>
    </>);
export const WarningMessage = (props) => {
    const { message } = props;
    return (<Stack horizontal styles={{ root: { marginLeft: '25px', marginTop: '16px' } }}>
            <Stack.Item>
                <Icon iconName="Warning" styles={{ root: { fontSize: '12px', color: '#D83B01', paddingRight: '5px' } }}/>
            </Stack.Item>
            <Stack.Item styles={{ root: { fontSize: '12px' } }}>{message}</Stack.Item>
        </Stack>);
};
const OverviewCardInjected = (props) => {
    const { labId, title, buttonText, iconName, info, infoSingleColumn, isLoading, navigateRoute, warningMessage, } = props;
    const InfoSection = () => {
        return (<>
                {infoSingleColumn && (<div className="dashboard__overview-card--info-container">
                        {infoSingleColumn.map((row) => (<div key={row.value} className="dashboard__overview-card--info-row">
                                <div className="dashboard__overview-card--info-column-one">{row.value}</div>
                            </div>))}
                    </div>)}
                {info && (<div className="dashboard__overview-card--info-container">
                        {info.map((row) => (<div key={`${row.label}-${row.value}`} className="dashboard__overview-card--info-row">
                                <div className="dashboard__overview-card--info-column-one">{row.label}</div>
                                <div className="dashboard__overview-card--info-column-two">{row.value}</div>
                            </div>))}
                    </div>)}
            </>);
    };
    const HeaderSection = () => {
        return (<Stack verticalAlign="center" horizontal styles={{ root: { marginLeft: '10px' } }}>
                <Stack.Item>
                    <DocumentCardLogo logoIcon={iconName} styles={{
            root: { fontSize: '30px', lineHeight: '30px', height: '40px' },
        }}/>
                </Stack.Item>
                <Stack.Item>
                    <DocumentCardTitle title={title} styles={{
            root: { fontWeight: 600, lineHeight: '60px', height: '60px', padding: '0px' },
        }}/>
                </Stack.Item>
            </Stack>);
    };
    const ButtonSection = () => {
        if (!labId) {
            return <div />;
        }
        return (<div style={{ marginLeft: '18px', height: '46px' }}>
                <ActionButton styles={{
            flexContainer: { flexDirection: 'row-reverse' },
            root: { color: getTheme().palette.themePrimary, marginTop: '2px' },
        }} iconProps={{
            iconName: 'ChevronRight',
            styles: { root: { fontSize: '14px', lineHeight: '16px', height: '13px' } },
        }}>
                    {buttonText}
                </ActionButton>
            </div>);
    };
    return (<DocumentCard type={DocumentCardType.normal} onClick={isLoading ? undefined : () => navigateRoute(labId)} styles={{
        root: {
            marginRight: '18px',
            marginTop: '22px',
            width: '330px',
            maxWidth: '330px',
            height: '207px',
            maxHeight: '207px',
            display: 'inline-flex',
            flex: '0 0',
            flexDirection: 'column',
            border: '1px solid transparent',
            boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.108), 0px 6.4px 14.4px rgba(0, 0, 0, 0.132)',
            borderRadius: '2px',
        },
    }}>
            <Stack grow verticalAlign="space-between">
                <Stack.Item styles={{ root: { marginTop: '8px' } }}>
                    <Shimmer isDataLoaded={!isLoading} width={200} customElementsGroup={HeaderShimmer}>
                        <HeaderSection />
                    </Shimmer>
                    <Shimmer isDataLoaded={!isLoading} styles={{ root: { marginTop: '5px' } }} width={217} customElementsGroup={BodyShimmer}>
                        <InfoSection />
                    </Shimmer>
                    {warningMessage && (<Shimmer isDataLoaded={!isLoading} width={175} customElementsGroup={WarningMessageShimmer}>
                            <WarningMessage message={warningMessage}/>
                        </Shimmer>)}
                </Stack.Item>
                <Stack.Item>
                    <Separator styles={{ root: { color: '#EDEBE9', lineHeight: '1px', padding: '0px' } }}/>
                    <Shimmer isDataLoaded={!isLoading} width={250} customElementsGroup={FooterShimmer}>
                        <ButtonSection />
                    </Shimmer>
                </Stack.Item>
            </Stack>
        </DocumentCard>);
};
export const OverviewCard = injectIntl(OverviewCardInjected);
