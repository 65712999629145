import { SkuActionType } from './sku-actions';
export const listSkuData = (labParentId) => ({
    type: SkuActionType.LIST_SKU_DATA,
    labParentId,
});
export const listSkuDataSuccess = (data) => ({
    type: SkuActionType.LIST_SKU_DATA_SUCCESS,
    data,
});
export const listSkuDataError = (error) => ({
    type: SkuActionType.LIST_SKU_DATA_ERROR,
    error,
});
const actions = {
    listSkuData,
    listSkuDataSuccess,
    listSkuDataError,
};
export default actions;
