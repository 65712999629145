import * as React from 'react';
import { DefaultButton } from '@fluentui/react';
import _ from 'lodash';
export const DropdownItem = (props) => {
    const { item, onRenderItem, onClick, styles } = props;
    const defaultStyles = {
        root: {
            padding: '0 4px 0 4px',
            width: '100%',
            backgroundColor: 'inherit',
            border: 0,
            height: '36px',
        },
    };
    const buttonStyles = _.merge({}, defaultStyles, styles);
    return (<DefaultButton onClick={onClick} role="menuitem" styles={buttonStyles}>
            {onRenderItem(item)}
        </DefaultButton>);
};
export default DropdownItem;
