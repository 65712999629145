import memoize from 'memoize-one';
import { AuthenticationState } from '../../data/models/identity';
import { getLocale, isDevMode, isLmsHost, isLmsMode, isTeamsMode, storeIsLoading, } from '../../redux/selectors/common-selectors';
import { getCurrentLabId, getLabStoreLoadState, getSelectableLabs } from '../../redux/selectors/lab-selectors';
import { getLabRoute, getRoute, getSearch, isLtiOidcRoute } from '../../redux/selectors/route-selector';
import { compareByName } from '../../utils/common';
import { isHealthyState } from '../../utils/provisioning-state';
import { getTenants } from '../../redux/selectors/tenant-selectors';
import { getAuthenticationState } from '../../redux/selectors/identity-selector';
import { getCurrentResourceGroup, getResourceGroups, isCurrentLabParentLabAccount, isLoadingLabParents, shouldDisableNewLabCreation, } from '../../redux/selectors/lab-parent-resource-selectors';
import { getCurrentLabAccount } from '../../redux/selectors/lab-account-selectors';
import { ResourceId } from '../../utils/resource-id';
import { getCurrentSupportInfo } from '../../redux/selectors/vnext/support-info-selectors';
import { getLinkableLabPlans } from '../../redux/selectors/vnext/lab-plan-selectors';
import { getCurrentLmsInstance, isLmsAdministrator } from '../../redux/selectors/lti-selectors';
function getInitials(name, displayId) {
    if (name) {
        const nameParts = name.trim().split(' ');
        if (nameParts.length < 2) {
            return nameParts[0][0].toUpperCase();
        }
        return `${nameParts[0][0]}${nameParts[nameParts.length - 1][0]}`.toUpperCase();
    }
    if (displayId) {
        const idParts = displayId.trim().split(/\.|@/);
        if (idParts.length < 2 || idParts[1].length < 1) {
            return idParts[0][0].toUpperCase();
        }
        const secondPart = (idParts[1].length === 1 && idParts.length > 2 ? idParts[2] : idParts[1])[0];
        return `${idParts[0][0]}${secondPart}`.toUpperCase();
    }
    return '?';
}
export function convertResourceGroupToItem(resourceGroupId) {
    const resourceGroup = new ResourceId(resourceGroupId);
    return {
        name: resourceGroup.resourceGroupName,
        id: resourceGroupId,
    };
}
function mapLabAccountsToItems(accounts) {
    return accounts.map((a) => convertLabAccountToItem(a));
}
function convertLabAccountToItem(account) {
    return {
        name: account.name,
        id: account.id,
    };
}
function mapResourceGroupsToItems(resourceGroups) {
    return resourceGroups.map((a) => convertResourceGroupToItem(a));
}
export function mapLabParentResourcesToItems(accounts, resourceGroups) {
    let items = mapLabAccountsToItems(accounts);
    items = items.concat(mapResourceGroupsToItems(resourceGroups));
    return items;
}
export const getPageHeaderContainerModel = memoize((state) => {
    const commonStore = state.get('commonStore');
    const labAccountStore = state.get('labAccountStore');
    const identityStore = state.get('identityStore');
    const labPlanStore = state.get('labPlanStore');
    const identityState = getAuthenticationState(state);
    const displayId = identityStore.get('displayId');
    const name = identityStore.get('name');
    const imageUrl = identityStore.get('imageUrl');
    const isAuthenticating = identityState === AuthenticationState.NotStarted ||
        identityState === AuthenticationState.Authenticating ||
        identityState === AuthenticationState.Redirecting;
    const isAuthenticated = identityState === AuthenticationState.Authenticated;
    const initials = getInitials(name, displayId);
    const selectedTenantId = identityStore.get('tenantId');
    const labAccounts = labAccountStore.get('labAccounts').filter((o) => isHealthyState(o));
    const currentLabAccount = getCurrentLabAccount(state);
    const isLoadingLabParentResources = isLoadingLabParents(state);
    const pathname = getRoute(state);
    const search = getSearch(state);
    const labPath = getLabRoute(state);
    const labLoadState = getLabStoreLoadState(state);
    const isLabAccount = isCurrentLabParentLabAccount(state);
    const labs = getSelectableLabs(state);
    const currentLabId = getCurrentLabId(state);
    const isLoadingLabs = storeIsLoading(labLoadState);
    const currentLab = labs.find((lab) => lab.id?.toUpperCase() === currentLabId.toUpperCase());
    const tenants = getTenants(state);
    const tenantStore = state.get('tenantStore');
    const tenantLoadingState = tenantStore.get('loadingState');
    const isLoadingTenants = storeIsLoading(tenantLoadingState);
    const fatalErrorCode = commonStore.get('fatalErrorCode');
    const hasFatalError = !!fatalErrorCode;
    const featureStore = state.get('commonStore');
    const isDevModeEnabled = isDevMode(state);
    const enabledFeatures = featureStore.get('features');
    const isNewLabCreationDisabled = shouldDisableNewLabCreation(state);
    const isTeamsIntegrationEnabled = isTeamsMode(state);
    const isLmsIntegrationEnabled = isLmsMode(state);
    const resourceGroups = getResourceGroups(state);
    const currentResourceGroup = getCurrentResourceGroup(state);
    const labParentItems = mapLabParentResourcesToItems(labAccounts, resourceGroups).sort(compareByName);
    const currentLabParentItem = !!currentLabAccount
        ? convertLabAccountToItem(currentLabAccount)
        : !!currentResourceGroup
            ? convertResourceGroupToItem(currentResourceGroup)
            : undefined;
    const currentSupportInfo = getCurrentSupportInfo(state);
    const shouldLoginWithPopupWindow = isLmsHost(state);
    const isLtiStartOidcRoute = isLtiOidcRoute(state);
    const locale = getLocale(state);
    let labPlans = getLinkableLabPlans(state);
    labPlans = labPlans.sort(compareByName);
    const lmsInstance = getCurrentLmsInstance(state);
    const isLmsAdmin = isLmsAdministrator(state);
    const labPlanLoadState = labPlanStore.get('loadState');
    const isLoadingLabPlans = storeIsLoading(labPlanLoadState);
    const isUpdatingLms = labPlanStore.get('isUpdatingLms');
    return {
        isAuthenticating,
        isAuthenticated,
        isDevModeEnabled,
        hasFatalError,
        fatalErrorCode,
        initials,
        displayId,
        name,
        imageUrl,
        isLoadingLabParents: isLoadingLabParentResources,
        pathname,
        search,
        labPath,
        isLoadingLabs,
        labs,
        currentLab,
        isLoadingTenants,
        tenants,
        selectedTenantId,
        isNewLabCreationDisabled,
        enabledFeatures,
        isTeamsIntegrationEnabled,
        labParentItems,
        currentLabParentItem,
        currentSupportInfo,
        isCurrentLabParentLabAccount: isLabAccount,
        isLmsIntegrationEnabled,
        shouldLoginWithPopupWindow,
        isLtiStartOidcRoute,
        locale,
        labPlans,
        lmsInstance,
        isUpdatingLms,
        isLoadingLabPlans,
        isLmsAdmin,
    };
});
