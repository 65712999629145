import memoize from 'memoize-one';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { getCurrentLabId } from '../lab-selectors';
import { getVNextLab } from './lab-selectors';
import { LabServicesModels } from 'lab-services';
import { getCurrentLabSizeData } from './pricing-and-availability-selectors';
import { getVNextPrice } from './price-selectors';
import { SkuFamilyName, convertSkuCapabilityToVmSzie, getSkuCostTargetUnit, getSkuFriendlyName, isClassicSku, } from '../../../common/selectors/sku-selectors';
import { subscriptionHasFeature } from '../subscription-selector';
export const getVNextSkuData = memoize((store) => {
    let skus = store.get('vNextSkuStore').get('skus');
    if (!skus) {
        return;
    }
    // Eventually we'll open the flood gates to all skus, we need to stop adding booleans here 
    // when the business model changes.
    const isPrivatePreview = subscriptionHasFeature(store, 'Microsoft.LabServices/PrivatePreview');
    const isAllowedLargeDisks = subscriptionHasFeature(store, 'Microsoft.LabServices/AllowLargeDisks');
    const isAllowedLargeDSv4 = subscriptionHasFeature(store, 'Microsoft.LabServices/AllowLargeDSv4');
    const isAllowedStandard_NV4as_v4 = subscriptionHasFeature(store, 'Microsoft.LabServices/AllowStandard_NV4as_v4');
    const isAllowedLegacySkus = subscriptionHasFeature(store, 'Microsoft.LabServices/AllowLegacySkus');
    const hasSkuFeature = isPrivatePreview ||
        isAllowedLargeDisks ||
        isAllowedStandard_NV4as_v4 ||
        isAllowedLargeDSv4 ||
        isAllowedLegacySkus;
    // Need to remove these skus from the list as they are legacy skus and will break in the backend.
    skus = skus.filter((o) => o.name !== "Basic" &&
        o.name !== "Standard" &&
        o.name !== "Large" &&
        o.name !== "Virtualization" &&
        o.name !== "Performance" &&
        o.name !== "SmallGPUCompute" &&
        o.name !== "MediumGPUVisualization" &&
        o.name !== "SmallGPUVisualization");
    // TODO: smartly return the SKUs based on the feature
    if (hasSkuFeature) {
        if (!isAllowedLargeDisks && !isAllowedLargeDSv4) {
            skus = skus.filter((o) => isClassicSku(o.name) ||
                (!isClassicSku(o.name) &&
                    new Number(o.capabilities?.find((x) => x.name === 'StorageGB')?.value) <= 512));
        }
        if (!isAllowedLegacySkus) {
            skus = skus.filter((o) => isClassicSku(o.name) ||
                (!isClassicSku(o.name) &&
                    o.family !== SkuFamilyName.Dsv3 &&
                    o.family !== SkuFamilyName.Av2 &&
                    o.family !== SkuFamilyName.NC &&
                    o.family !== SkuFamilyName.NV));
        }
        if (!isAllowedStandard_NV4as_v4) {
            skus = skus.filter((o) => isClassicSku(o.name) || (!isClassicSku(o.name) && !o.name?.startsWith('Standard_NVv4_4_')));
        }
        return skus;
    }
    else {
        return skus.filter((o) => isClassicSku(o.name) ||
            (!isClassicSku(o.name) &&
                (o.family === SkuFamilyName.NCasT4v3 ||
                    o.family === SkuFamilyName.NVadsA10v5)));
    }
});
export const getVNextSkuLoadState = memoize((store) => store.get('vNextSkuStore').get('loadState'));
export const getVNextSkuLoadError = memoize((store) => store.get('vNextSkuStore').get('error'));
export const getCurrentLabSkuInfo = memoize((store) => {
    const currentLabId = getCurrentLabId(store);
    const currentVNextLab = getVNextLab(store, currentLabId);
    const labSkuName = currentVNextLab?.virtualMachineProfile?.sku?.name;
    const labLocation = currentVNextLab?.location;
    if (!labSkuName || !labLocation) {
        return undefined;
    }
    //if old sku
    const oldSizeData = getCurrentLabSizeData(store);
    if (!!oldSizeData) {
        return {
            skuName: labSkuName,
            cores: oldSizeData.cores,
            isGpu: oldSizeData.discreteGpu === LabServicesModels.EnableState.Enabled,
            memoryGB: oldSizeData.memoryInGB,
            price: oldSizeData.locations[labLocation]?.price,
            currency: oldSizeData.locations[labLocation]?.currencyCode,
            storageGB: undefined,
            storageType: '',
            skuFamilyName: undefined,
            skuFriendlyName: oldSizeData.displayName,
        };
    }
    // if new sku
    let skuData = getVNextSkuData(store);
    if (!skuData) {
        return undefined;
    }
    skuData = skuData.filter((o) => caseInsensitiveCultureInvariantCompare(o.name, labSkuName) === 0);
    const sku = skuData.find((o) => !!o.locations &&
        o.locations?.findIndex((o) => caseInsensitiveCultureInvariantCompare(o, labLocation) === 0) >= 0);
    if (!sku) {
        return undefined;
    }
    const size = convertSkuCapabilityToVmSzie(sku.capabilities, sku.size);
    const imageType = currentVNextLab?.virtualMachineProfile?.osType;
    const targetPriceUnit = getSkuCostTargetUnit(imageType === LabServicesModels.OsType.Windows);
    const priceData = getVNextPrice(store);
    const currentLabSkuPrice = priceData
        .get(labLocation)
        ?.find((o) => caseInsensitiveCultureInvariantCompare(o.skuName, labSkuName) === 0 &&
        caseInsensitiveCultureInvariantCompare(o.unit, targetPriceUnit) === 0);
    const vmSku = {
        key: sku.name,
        name: sku.name,
        family: sku.family,
        size,
        isClassic: isClassicSku(sku.name),
        locations: [
            {
                key: labLocation,
                name: labLocation,
                costs: !currentLabSkuPrice ? [] : [currentLabSkuPrice],
                usage: undefined,
            },
        ],
    };
    return {
        ...size,
        skuName: labSkuName,
        skuFriendlyName: getSkuFriendlyName(vmSku),
        skuFamilyName: sku.family,
        price: currentLabSkuPrice?.value,
        currency: currentLabSkuPrice?.currencyCode,
    };
});
export const isCurrentVNextLabOldSku = memoize((store) => {
    const oldSizeData = getCurrentLabSizeData(store);
    return !!oldSizeData;
});
