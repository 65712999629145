import * as React from 'react';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { getRdpConnectData } from '../common/connect-to-vm/connect-utilities';
import { SubmitButton } from '../common/submit-button';
import { createAndDownloadFile } from '../utils/files';
import './template.css';
const messages = defineMessages({
    connectToTemplateTitle: {
        id: 'ConnectToTemplateTitle',
        defaultMessage: 'Connect to template',
        description: 'Title for customize template dialog',
    },
    connectTemplateButtonLabel: {
        id: 'ConnectTemplateButtonLabel',
        defaultMessage: 'Connect',
        description: 'Label on button to connect to the template',
    },
});
const CustomizeMessage = () => {
    return (<FormattedMessage id="CustomizeTemplateForWindowsMessage" defaultMessage="Connecting to a template will download an RDP file. Open this file and enter your credentials to connect to the virtual machine." description="Text to explain how to connect to the template in the customize template dialog"/>);
};
export const areNetworkDetailsLoaded = (template) => template.rdpAuthority || template.rdpInBrowserUrl || template.sshAuthority || template.sshInBrowserUrl;
export const constructAndDownloadRdpFile = (template) => {
    const { rdpAuthority, isWindows, username, title } = template;
    const { fileName, fileContent } = getRdpConnectData(rdpAuthority, isWindows, username, title);
    createAndDownloadFile(fileName, fileContent);
};
const CustomizeTemplateDialogInjected = (props) => {
    const { template, getTemplateWithExpand, onDismiss } = props;
    const [isLoadingNetworkDetails, setIsLoadingNetworkDetails] = React.useState(false);
    const connectToTemplateTitle = props.intl.formatMessage(messages.connectToTemplateTitle);
    const connectButtonLabel = props.intl.formatMessage(messages.connectTemplateButtonLabel);
    const loadNetworkDetails = () => {
        if (areNetworkDetailsLoaded(template)) {
            constructAndDownloadRdpFile(template);
            onDismiss();
        }
        else {
            getTemplateWithExpand(template.id, true);
            setIsLoadingNetworkDetails(true);
        }
    };
    React.useEffect(() => {
        if (isLoadingNetworkDetails && areNetworkDetailsLoaded(template)) {
            setIsLoadingNetworkDetails(false);
            constructAndDownloadRdpFile(template);
            onDismiss();
        }
    }, [template]);
    return (<Dialog onDismiss={onDismiss} hidden={false} dialogContentProps={{
        type: DialogType.normal,
        title: connectToTemplateTitle,
        styles: { title: { fontWeight: 600, textAlign: 'center', paddingTop: '58px' } },
    }} modalProps={{
        onDismissed: onDismiss,
        isBlocking: false,
        styles: {
            main: {
                boxShadow: '0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)',
                borderRadius: '2px',
                textAlign: 'center',
                fontSize: '20px',
                paddingRight: '30px',
                paddingLeft: '30px',
            },
        },
    }} minWidth={730}>
            <CustomizeMessage />
            <DialogFooter styles={{ actions: { marginTop: '58px' } }}>
                <SubmitButton styles={{ root: { width: '160px', height: '32px' } }} buttonText={connectButtonLabel} ariaLabel={connectButtonLabel} isSubmitting={isLoadingNetworkDetails} onClick={() => {
        loadNetworkDetails();
    }}/>
            </DialogFooter>
        </Dialog>);
};
export const CustomizeTemplateDialog = injectIntl(CustomizeTemplateDialogInjected);
export default CustomizeTemplateDialog;
