import * as React from 'react';
import { getRTL, Icon } from '@fluentui/react';
import classNames from 'classnames';
import './carousel-pagination.css';
// NOTE: This is a quick port of an outlook control that removes their dependencies
export class CarouselPagination extends React.Component {
    constructor(props) {
        super(props);
        this.handleKeyPress = (e) => {
            const { currentPage, pages, onPageClicked } = this.props;
            const previousKeyCode = getRTL() ? 39 /* right */ : 37; /* left */
            const nextKeyCode = getRTL() ? 37 /* left */ : 39; /* right */
            if (e.keyCode === previousKeyCode && currentPage > 0) {
                onPageClicked(currentPage - 1);
            }
            else if (e.keyCode === nextKeyCode && currentPage < pages - 1) {
                onPageClicked(currentPage + 1);
            }
        };
        this.renderDots = () => {
            const { currentPage, pages, onPageClicked } = this.props;
            const dots = [];
            for (let i = 0; i < pages; ++i) {
                const isActive = i === currentPage;
                const className = classNames({
                    dot: true,
                    activeDot: isActive,
                });
                const dot = (<Icon iconName={'CircleFill'} className={className} key={`carousel-dot-${i}`} onClick={() => onPageClicked(i)}/>);
                dots.push(dot);
            }
            return dots;
        };
        this.render = () => {
            const { pages, currentPage, containerClassName } = this.props;
            const dots = this.renderDots();
            const className = containerClassName ? `carouselPagination ${containerClassName}` : 'carouselPagination ';
            return (<div className={className} tabIndex={0} role="slider" aria-valuemin={1} aria-valuemax={pages} aria-valuenow={currentPage + 1} onKeyDown={this.handleKeyPress}>
                {dots}
            </div>);
        };
    }
}
export default CarouselPagination;
