import { LabUpdateOperation } from '../../../data/models/lab';
import { LabActionType, } from './lab-actions';
import Context from '../../action-context';
export const selectLab = (labId) => ({
    type: LabActionType.SELECT_LAB,
    labId,
});
export const filterByLabAccessType = (labAccessType) => ({
    type: LabActionType.FILTER_BY_LAB_ACCESS_TYPE,
    labAccessType,
});
export const listLabs = (labParentId, filterWriteAccess = true) => ({
    type: LabActionType.LIST_LABS,
    labParentId,
    filterWriteAccess,
});
export const listLabsSuccess = (labs) => ({
    type: LabActionType.LIST_LABS_SUCCESS,
    labs,
});
export const listVNextLabsSuccess = (labs, labParentId) => ({
    type: LabActionType.LIST_LABS_SUCCESS,
    // when the lab parent id specified in the url is a lab account, the vnext-middleware won't add context for the action since the current lab parent id is a lab account.
    // add the action context here so that it will use vNext reducer to update vNext store.
    context: Context.VNext,
    labs,
    labParentId,
});
export const listLabsError = (error) => ({
    type: LabActionType.LIST_LABS_ERROR,
    error,
});
export const listLabsCancelled = () => ({
    type: LabActionType.LIST_LABS_CANCELLED,
});
export const getLab = (labId, includeRoles = true, includeMetadata = false) => ({
    type: LabActionType.GET_LAB,
    labId,
    includeRoles,
    includeMetadata,
});
export const getLabCancelled = () => ({
    type: LabActionType.GET_LAB_CANCELLED,
});
export const getLabSuccess = (lab) => ({
    type: LabActionType.GET_LAB_SUCCESS,
    lab,
});
export const getVNextLabSuccess = (lab) => ({
    type: LabActionType.GET_LAB_SUCCESS,
    lab,
});
export const getLabError = (error) => ({
    type: LabActionType.GET_LAB_ERROR,
    error,
});
export const deleteLab = (labId) => ({
    type: LabActionType.DELETE_LAB,
    labId,
});
export const deleteLabSuccess = (labId) => ({
    type: LabActionType.DELETE_LAB_SUCCESS,
    labId,
});
export const deleteLabError = (error) => ({
    type: LabActionType.DELETE_LAB_ERROR,
    error,
});
export const createLab = (labParentId, props, getCompleteRoute) => ({
    type: LabActionType.CREATE_LAB,
    labParentId,
    props,
    getCompleteRoute,
});
export const checkVnextCreatelabPermission = (labPlanId) => ({
    type: LabActionType.CHECK_VNEXT_CREATELAB_PERMISSION,
    labPlanId,
});
export const updateVnextCreatelabPermission = (createLabPermissionOnLabPlan) => ({
    type: LabActionType.UPDATE_VNEXT_CREATELAB_PERMISSION,
    createLabPermissionOnLabPlan,
});
export const updateVnextPermissionPropagation = (vNextLabPermissionPropagating) => ({
    type: LabActionType.UPDATE_VNEXT_PERMISSION_PROPAGATION,
    vNextLabPermissionPropagating,
});
export const addVnextPermissionPropagatingLab = (lab, labParentId) => ({
    type: LabActionType.ADD_VNEXT_PERMISSION_PROPAGATING_LAB,
    // when the lab parent id specified in the url is a lab account, the vnext-middleware won't add context for the action since the current lab parent id is a lab account.
    // add the action context here so that it will use vNext reducer to update vNext store.
    context: Context.VNext,
    lab,
    labParentId,
});
export const removeVnextPermissionPropagatingLab = (lab, labParentId) => ({
    type: LabActionType.REMOVE_VNEXT_PERMISSION_PROPAGATING_LAB,
    // when the lab parent id specified in the url is a lab account, the vnext-middleware won't add context for the action since the current lab parent id is a lab account.
    // add the action context here so that it will use vNext reducer to update vNext store.
    context: Context.VNext,
    lab,
    labParentId,
});
export const createVNextLab = (labParentId, props, getCompleteRoute) => ({
    type: LabActionType.CREATE_LAB,
    labParentId,
    props,
    getCompleteRoute,
});
export const createLabSuccess = (lab) => ({
    type: LabActionType.CREATE_LAB_SUCCESS,
    lab,
});
export const createVNextLabSuccess = (lab) => ({
    type: LabActionType.CREATE_LAB_SUCCESS,
    lab,
});
export const createLabError = (error) => ({
    type: LabActionType.CREATE_LAB_ERROR,
    error,
});
export const updateLab = (lab, updateOperation = LabUpdateOperation.Update) => ({
    type: LabActionType.UPDATE_LAB,
    lab,
    updateOperation,
});
export const updateLabSuccess = (lab) => ({
    type: LabActionType.UPDATE_LAB_SUCCESS,
    lab,
});
export const updateLabError = (error) => ({
    type: LabActionType.UPDATE_LAB_ERROR,
    error,
});
export const updateLabAccessMode = (labId, userAccessMode) => ({
    type: LabActionType.UPDATE_LAB_ACCESS_MODE,
    labId,
    userAccessMode,
});
export const updateVNextLabAccessMode = (labId, openAccess) => ({
    type: LabActionType.UPDATE_LAB_ACCESS_MODE,
    labId,
    openAccess,
});
export const updateLabAccessModeSuccess = () => ({
    type: LabActionType.UPDATE_LAB_ACCESS_MODE_SUCCESS,
});
export const updateLabAccessModeError = (labId) => ({
    type: LabActionType.UPDATE_LAB_ACCESS_MODE_ERROR,
    labId,
});
export const clearLabsError = (id, errorIndex) => ({
    type: LabActionType.CLEAR_LAB_ERROR,
    id,
    errorIndex,
});
export const clearPublishLabError = (id) => ({
    type: LabActionType.CLEAR_PUBLISH_LAB_ERROR,
    id,
});
export const clearLabUpdateError = () => ({
    type: LabActionType.CLEAR_LAB_UPDATE_ERROR,
});
export const clearLabCapacityExceedsCoresError = () => ({
    type: LabActionType.CLEAR_LAB_CAPACITY_EXCEEDS_CORES_ERROR,
});
export const pollLabStart = (id, intervalMs, initialDelayMs) => ({
    type: LabActionType.POLL_LAB_START,
    id,
    intervalMs,
    initialDelayMs,
});
export const pollLabUntilTerminalStateStart = (id, intervalMs, initialDelayMs, shouldStopWhenTerminalState = true) => ({
    type: LabActionType.POLL_LAB_START,
    id,
    intervalMs,
    initialDelayMs,
    shouldStopWhenTerminalState,
});
export const pollLabStop = () => ({
    type: LabActionType.POLL_LAB_STOP,
});
export const pollLabCancelled = () => ({
    type: LabActionType.POLL_LAB_CANCELLED,
});
export const syncLabUsers = (labId) => ({
    type: LabActionType.SYNC_LAB_USERS,
    labId,
});
export const syncLabUsersError = (labId, syncUsersError) => ({
    type: LabActionType.SYNC_LAB_USERS_ERROR,
    labId,
    syncUsersError,
});
export const pollLabListStart = (labParentId, intervalMs, initialDelayMs) => ({
    type: LabActionType.POLL_LAB_LIST_START,
    labParentId,
    intervalMs,
    initialDelayMs,
});
export const pollLabListCancelled = () => ({
    type: LabActionType.POLL_LAB_LIST_CANCELLED,
});
export const pollLabListStop = () => ({
    type: LabActionType.POLL_LAB_LIST_STOP,
});
export const loadSubscriptionVNextLabsError = (subscription, error, resourceGroupName) => ({
    type: LabActionType.LOAD_SUBSCRIPTION_VNEXT_LABS_ERROR,
    context: Context.VNext,
    subscription,
    error,
    resourceGroupName,
});
export const updateVNextLab = (labId, labUpdate, updateOperation = LabUpdateOperation.Update) => ({
    type: LabActionType.UPDATE_LAB,
    labId,
    labUpdate,
    updateOperation,
});
export const updateVNextLabSuccess = (lab) => ({
    type: LabActionType.UPDATE_LAB_SUCCESS,
    lab,
});
export const clearSelectedLab = () => ({
    type: LabActionType.CLEAR_SELECTED_LAB,
});
export const publishVNextLab = (labId, capacity, shouldUpdateCapacity) => ({
    type: LabActionType.PUBLISH_LAB,
    labId,
    capacity,
    shouldUpdateCapacity,
});
export const publishVNextLabAccepted = (operation) => ({
    type: LabActionType.PUBLISH_LAB_ACCEPTED,
    operation,
});
export const publishVNextLabError = (labId, error) => ({
    type: LabActionType.PUBLISH_LAB_ERROR,
    labId,
    error,
});
export const publishVNextLabCancelled = (labId) => ({
    type: LabActionType.PUBLISH_LAB_CANCELLED,
    labId,
});
export const getLabMetadata = (labId) => ({
    type: LabActionType.GET_LAB_METADATA,
    labId,
});
export const getLabMetadataSuccess = (labId, labMetadata) => ({
    type: LabActionType.GET_LAB_METADATA_SUCCESS,
    labId,
    labMetadata,
});
export const getLabMetadataError = (error) => ({
    type: LabActionType.GET_LAB_METADATA_ERROR,
    error,
});
const actions = {
    selectLab,
    filterByLabAccessType,
    listLabs,
    listLabsSuccess,
    listLabsError,
    listLabsCancelled,
    getLab,
    getLabCancelled,
    getLabSuccess,
    getLabError,
    deleteLab,
    deleteLabSuccess,
    deleteLabError,
    createLab,
    createLabSuccess,
    createLabError,
    updateLab,
    updateLabSuccess,
    updateLabError,
    updateLabAccessMode,
    updateLabAccessModeSuccess,
    updateLabAccessModeError,
    clearLabsError,
    clearPublishLabError,
    clearLabUpdateError,
    clearLabCapacityExceedsCoresError,
    pollLabStart,
    pollLabStop,
    pollLabCancelled,
    syncLabUsers,
    syncLabUsersError,
    pollLabListStart,
    pollLabListCancelled,
    pollLabListStop,
    listVNextLabsSuccess,
    loadSubscriptionVNextLabsError,
    checkVnextCreatelabPermission,
    updateVnextCreatelabPermission,
    updateVnextPermissionPropagation,
    addVnextPermissionPropagatingLab,
    removeVnextPermissionPropagatingLab,
    createVNextLab,
    createVNextLabSuccess,
    updateVNextLab,
    updateVNextLabSuccess,
    clearSelectedLab,
    getVNextLabSuccess,
    updateVNextLabAccessMode,
    publishVNextLab,
    publishVNextLabCancelled,
    publishVNextLabError,
    pollLabUntilTerminalStateStart,
    getLabMetadataError,
    getLabMetadata,
    getLabMetadataSuccess,
};
export default actions;
