import { CommonActionType } from '../common/common-actions';
import { CommonLabActionType } from '../lab/lab-actions';
import { SharedImageActionType } from '../shared-image/shared-image-actions';
import { CommonLabParentResourceActionType } from '../lab-parent-resource/lab-parent-resource-actions';
export const CommonTemplateActionType = {
    LIST_TEMPLATES_SUCCESS: 'LIST_TEMPLATES_SUCCESS',
    GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
    PUBLISH_TEMPLATE: 'PUBLISH_TEMPLATE',
    RESET_PASSWORD: 'RESET_PASSWORD',
    RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
};
export const TemplateActionType = {
    ...CommonActionType,
    ...CommonLabActionType,
    ...CommonLabParentResourceActionType,
    ...SharedImageActionType,
    ...CommonTemplateActionType,
    LIST_TEMPLATES: 'LIST_TEMPLATES',
    LIST_TEMPLATES_ERROR: 'LIST_TEMPLATES_ERROR',
    GET_TEMPLATE: 'GET_TEMPLATE',
    GET_TEMPLATE_CANCELLED: 'GET_TEMPLATE_CANCELLED',
    GET_TEMPLATE_ERROR: 'GET_TEMPLATE_ERROR',
    START_TEMPLATE: 'START_TEMPLATE',
    START_TEMPLATE_ERROR: 'START_TEMPLATE_ERROR',
    STOP_TEMPLATE: 'STOP_TEMPLATE',
    STOP_TEMPLATE_ERROR: 'STOP_TEMPLATE_ERROR',
    CREATE_TEMPLATE_ERROR: 'CREATE_TEMPLATE_ERROR',
    UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
    UPDATE_TEMPLATE_SUCCESS: 'UPDATE_TEMPLATE_SUCCESS',
    UPDATE_TEMPLATE_ERROR: 'UPDATE_TEMPLATE_ERROR',
    PUBLISH_TEMPLATE_CANCELLED: 'PUBLISH_TEMPLATE_CANCELLED',
    PUBLISH_TEMPLATE_ERROR: 'PUBLISH_TEMPLATE_ERROR',
    PUBLISH_EXCEEDS_CORES_ERROR: 'PUBLISH_EXCEEDS_CORES_ERROR',
    SELECT_TEMPLATE: 'SELECT_TEMPLATE',
    CLEAR_TEMPLATE_ERROR: 'CLEAR_TEMPLATE_ERROR',
    CLEAR_LOAD_TEMPLATE_ERROR: 'CLEAR_LOAD_TEMPLATE_ERROR',
    CLEAR_PUBLISH_EXCEEDS_CORES_ERROR: 'CLEAR_PUBLISH_EXCEEDS_CORES_ERROR',
    POLL_TEMPLATE_START: 'POLL_TEMPLATE_START',
    POLL_TEMPLATE_STOP: 'POLL_TEMPLATE_STOP',
    POLL_TEMPLATE_CANCELLED: 'POLL_TEMPLATE_CANCELLED',
};
