import { put, select, takeLatest } from 'redux-saga/effects';
import ErrorCode from '../../../common/error-codes';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import { trackException } from '../../../utils/telemetry/telemetry-channel';
import { fatalError } from '../../actions/common/common-action-creators';
import { CommonActionType } from '../../actions/common/common-actions';
import { isUserAuthenticated } from '../../selectors/identity-selector';
export function* idleTimeout(action) {
    try {
        const isAuthenticated = yield select(isUserAuthenticated);
        if (isAuthenticated) {
            const error = new MlClientError('Session expired because the user was idle for too long.', '', FailureOperation.IdleTimeout);
            yield put(fatalError(ErrorCode.IdleSessionExpired, error));
        }
    }
    catch (err) {
        trackException(err);
    }
}
export function* idleTimeoutSaga() {
    yield takeLatest(CommonActionType.IDLE_TIMEOUT, idleTimeout);
}
