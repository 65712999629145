import { call, put, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import ScheduleProvider from '../../../data/providers/schedule-provider';
import { default as VNextScheduleProvider } from '../../../data/providers/vnext/arm-api/schedule-provider';
import { ResourceId } from '../../../utils/resource-id';
import { ScheduleActionType, } from '../../actions/schedule/schedule-actions';
import { updateScheduleSuccess, updateVNextScheduleSuccess, updateScheduleError, } from '../../actions/schedule/schedule-action-creators';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
export function* updateSchedule(action) {
    const { schedule, templateId } = action;
    try {
        const resourceId = new ResourceId(templateId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const updatedSchedule = yield call(ScheduleProvider.createOrUpdateSchedule, resourceId, accessToken, locale, language, schedule);
        yield put(updateScheduleSuccess(updatedSchedule));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, schedule.id, FailureOperation.UpdateSchedule, e ? e.code : undefined);
        yield put(updateScheduleError(error));
    }
}
export function* updateVNextSchedule(action) {
    const { schedule, labId } = action;
    try {
        const resourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const updatedSchedule = yield call(VNextScheduleProvider.updateSchedule, resourceId, accessToken, locale, language, schedule);
        yield put(updateVNextScheduleSuccess(updatedSchedule));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, schedule.id, FailureOperation.UpdateSchedule, e ? e.code : undefined);
        yield put(updateScheduleError(error));
    }
}
export function* updateScheduleSaga() {
    yield labParentBasedTakeLatest(ScheduleActionType.UPDATE_SCHEDULE, updateSchedule, updateVNextSchedule);
}
