import memoize from 'memoize-one';
import { decodeBase64Url } from '../../utils/decode-base64url';
import { LtiInstructorRole, LtiAdministratorRoles, LtiContentDeveloperRole } from '../../utils/constants';
import { isLmsHost } from './common-selectors';
export const isLmsCourseInstructor = memoize((store) => {
    const roles = store.get('ltiStore').get('roles');
    return roles.indexOf(LtiInstructorRole) >= 0;
});
export const isLmsCourseContentDeveloper = memoize((store) => {
    const roles = store.get('ltiStore').get('roles');
    return roles.indexOf(LtiContentDeveloperRole) >= 0;
});
export const isLmsAdministrator = memoize((store) => {
    const roles = store.get('ltiStore').get('roles');
    return roles.some((r) => LtiAdministratorRoles.indexOf(r) >= 0);
});
export const isLmsProfessor = memoize((store) => {
    return isLmsCourseInstructor(store) || isLmsCourseContentDeveloper(store) || isLmsAdministrator(store);
});
export const shouldSignInAad = memoize((store) => {
    return !isLmsHost(store) || isLmsProfessor(store);
});
export const isLmsStudent = memoize((store) => {
    return isLmsHost(store) && !isLmsProfessor(store);
});
export function getValidatedLtiToken(hash, state, nonce) {
    if (!hash || !state || !nonce) {
        return undefined;
    }
    const params = hash.split('&');
    if (!params || params.length !== 2) {
        return undefined;
    }
    const stateParam = params[1].split('=');
    if (!stateParam || stateParam.length !== 2 || stateParam[0] !== 'state' || stateParam[1] !== state) {
        return undefined;
    }
    const ltiTokenParam = params[0].split('=');
    if (!ltiTokenParam || ltiTokenParam.length !== 2 || ltiTokenParam[0] !== '#id_token' || !ltiTokenParam[1]) {
        return undefined;
    }
    const decodedToken = decodeLtiTokenPayload(ltiTokenParam[1]);
    if (decodedToken['nonce'] !== nonce) {
        return undefined;
    }
    return ltiTokenParam[1];
}
export const decodeLtiTokenPayload = memoize((token) => {
    const payload = token.split('.')[1];
    let res;
    if (!!payload) {
        const decodedPayload = decodeBase64Url(payload);
        res = JSON.parse(decodedPayload);
    }
    return res;
});
export const getLtiContextTitle = memoize((store) => {
    const ltiContext = store.get('ltiStore').get('ltiContext');
    return ltiContext?.title;
});
export const getLtiContextId = memoize((store) => {
    const ltiContext = store.get('ltiStore').get('ltiContext');
    return ltiContext?.id;
});
export const hasGrantedWebStorageAccess = memoize((store) => {
    const ltiStore = store.get('ltiStore');
    const hasWebStorageAccessGranted = ltiStore.get('hasGrantedWebStorageAccess');
    return hasWebStorageAccessGranted;
});
export const getCurrentLmsInstance = memoize((store) => {
    return store.get('ltiStore').get('lmsInstance');
});
