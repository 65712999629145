import * as React from 'react';
import { Modal, IconButton, getRTL } from '@fluentui/react';
import classNames from 'classnames';
import { injectIntl } from 'react-intl';
import { CarouselCards } from './carousel-cards';
import { CarouselPagination } from './carousel-pagination';
import commonMessages from '../../language/common-messages';
import './carousel.css';
// NOTE: This is a quick port of an outlook control that removes their dependencies
class CarouselInjected extends React.Component {
    constructor(props) {
        super(props);
        this.onCardChange = (currentPage) => {
            const { onCardChange } = this.props;
            this.setState({
                currentPage,
            });
            if (onCardChange) {
                onCardChange(currentPage);
            }
        };
        this.render = () => {
            const { isOpen, cardContent, onClose, intl } = this.props;
            const { currentPage } = this.state;
            const { formatMessage: msg } = intl;
            const numCards = cardContent.length;
            const previousClassName = classNames({
                previousCard: true,
                hideButton: currentPage === 0,
            });
            const nextClassName = classNames({
                nextCard: true,
                hideButton: currentPage === numCards - 1,
            });
            return (<Modal isOpen={isOpen} containerClassName="carouselContainer" onDismiss={onClose} isBlocking={false} styles={{ root: { minHeight: '730px' } }}>
                <div className="carouselScroll">
                    <div className="gutter">
                        <IconButton className={previousClassName} iconProps={{ iconName: getRTL() ? 'ChevronRight' : 'ChevronLeft' }} onClick={() => this.onCardChange(currentPage - 1)} ariaLabel={msg(commonMessages.previous)}/>
                    </div>
                    <div className="mainContent">
                        <CarouselCards cards={cardContent} currentCard={currentPage}/>
                        <div className="closeButtonContainer">
                            <button onClick={onClose} className="closeButton"/>
                        </div>
                        <div className="paginationContainer">
                            <CarouselPagination pages={numCards} currentPage={currentPage} onPageClicked={(page) => this.onCardChange(page)} containerClassName="cardPagination"/>
                        </div>
                    </div>
                    <div className="gutter">
                        <IconButton className={nextClassName} iconProps={{ iconName: getRTL() ? 'ChevronLeft' : 'ChevronRight' }} onClick={() => this.onCardChange(currentPage + 1)} ariaLabel={msg(commonMessages.next)}/>
                    </div>
                </div>
            </Modal>);
        };
        this.state = {
            currentPage: 0,
        };
    }
}
export const Carousel = injectIntl(CarouselInjected);
export default Carousel;
