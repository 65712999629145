import { routerActions } from 'connected-react-router/immutable';
import * as React from 'react';
import { connect } from 'react-redux';
import { listImages } from '../redux/actions/vnext/images/image-action-creators';
import ErrorCode from '../common/error-codes';
import { Page } from '../common/page/page';
import { fatalError, labAppInitialize } from '../redux/actions/common/common-action-creators';
import { dismissCovidBanner } from '../redux/actions/identity/identity-action-creators';
import { LabApp } from './lab-app';
import { getLabAppContainerModel } from './lab-app-selectors';
import { clearUpdateLinkedLmsError } from '../redux/actions/lab-plan/lab-plan-action-creators';
class LabAppContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._navigateRoute = this._navigateRoute.bind(this);
        this._onDismissedCovid19Banner = this._onDismissedCovid19Banner.bind(this);
    }
    _navigateRoute(pathname) {
        const { push, labAppViewModel } = this.props;
        const { search } = labAppViewModel;
        push({ pathname, search });
    }
    _onDismissedCovid19Banner() {
        this.props.dismissCovidBanner();
    }
    componentDidMount() {
        const { currentLabId, labAppInitialize, labAppViewModel, listImages, currentLab } = this.props;
        const { isVNextLabCreating } = labAppViewModel;
        if (!!currentLabId) {
            labAppInitialize(currentLabId);
        }
        if (isVNextLabCreating && currentLab) {
            const vNextLab = currentLab;
            if (vNextLab.labPlanId) {
                listImages(vNextLab.labPlanId);
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { isLoadingLabs, isCreatingLab, currentLabId, labAppInitialize, currentLabExists, fatalError, labAppViewModel, currentLab, listImages, } = this.props;
        const { isVNextLabCreating, isVnextLabPermissionPropagating } = labAppViewModel;
        const { currentLabId: previousCurrentLabId, labAppViewModel: previousLabAppViewModel } = prevProps;
        if (!!currentLabId &&
            (!previousCurrentLabId || previousCurrentLabId.toLowerCase() !== currentLabId.toLowerCase())) {
            labAppInitialize(currentLabId);
        }
        // HACK: This hack is needed because the API does not return resource not found for labs that do not exist
        // when loading templates, users or other lab resources
        // Note: this handles cases where the lab does not actually exist
        if (!isLoadingLabs && !isCreatingLab && !currentLabExists && !isVnextLabPermissionPropagating) {
            fatalError(ErrorCode.ResourceNotFound, new Error('Lab app failed to find the current lab.'));
        }
        if (!previousLabAppViewModel.isVNextLabCreating && isVNextLabCreating && currentLab) {
            const vNextLab = currentLab;
            if (vNextLab.labPlanId) {
                listImages(vNextLab.labPlanId);
            }
        }
    }
    render() {
        const { labAppViewModel, clearUpdateLinkedLmsError } = this.props;
        return (<Page>
                <LabApp labAppViewModel={labAppViewModel} navigateRoute={this._navigateRoute} onDismissedCovidBanner={this._onDismissedCovid19Banner} clearUpdateLinkedLmsError={clearUpdateLinkedLmsError}/>
            </Page>);
    }
}
const mapStateToProps = (state) => {
    return getLabAppContainerModel(state);
};
const mapDispatchToProps = {
    push: routerActions.push,
    labAppInitialize,
    dismissCovidBanner,
    fatalError,
    listImages,
    clearUpdateLinkedLmsError,
};
export const LabAppContainer = connect(mapStateToProps, mapDispatchToProps)(LabAppContainerInjected);
export default LabAppContainer;
