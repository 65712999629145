import { DefaultButton, Dialog, DialogType, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SubmitButton } from '../common/submit-button';
import { commonMessages } from '../language/common-messages';
import { usePrevious } from '../utils/common';
const messages = defineMessages({
    deleteScheduleTitle: {
        id: 'DeleteScheduleTitle',
        defaultMessage: 'Delete scheduled event',
        description: 'Title for delete schedule dialog',
    },
    deleteRecurringScheduleConfirmationMessage: {
        id: 'DeleteRecurringScheduleConfirmationMessage',
        defaultMessage: 'This will delete all occurrences of the scheduled event. Do you want to continue?',
        description: 'Confirmation message for deleting a recurring schedule',
    },
    deleteOnetimeScheduleConfirmationMessage: {
        id: 'DeleteOnetimeScheduleConfirmationMessage',
        defaultMessage: 'This will delete the scheduled event. Do you want to continue?',
        description: 'Confirmation message for deleting a onetime schedule',
    },
});
export const ScheduleDeleteDialog = (props) => {
    const { isSaving, schedule, onDismiss, onSubmit } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const previousIsSaving = usePrevious(isSaving);
    React.useEffect(() => {
        if (previousIsSaving && !isSaving) {
            onDismiss();
        }
    }, [isSaving]);
    const cancel = msg(commonMessages.cancel);
    const yes = msg(commonMessages.yes);
    const deleteRecurringMessage = msg(messages.deleteRecurringScheduleConfirmationMessage);
    const deleteOnetimeMessage = msg(messages.deleteOnetimeScheduleConfirmationMessage);
    const deleteScheduleTitle = msg(messages.deleteScheduleTitle);
    return (<Dialog hidden={false} onDismiss={isSaving ? undefined : onDismiss} dialogContentProps={{
        type: DialogType.normal,
        title: deleteScheduleTitle,
        subText: schedule && schedule.recurrencePattern ? deleteRecurringMessage : deleteOnetimeMessage,
    }} modalProps={{
        styles: { main: { maxWidth: 450 } },
    }}>
            <Stack horizontal tokens={{ childrenGap: '8px' }} styles={{ root: { width: '100%' } }} verticalAlign="end" horizontalAlign="end">
                <Stack.Item>
                    <SubmitButton onClick={() => onSubmit(schedule)} buttonText={yes} isSubmitting={isSaving}/>
                </Stack.Item>
                <Stack.Item>
                    <DefaultButton autoFocus disabled={isSaving} onClick={isSaving ? undefined : onDismiss} text={cancel}/>
                </Stack.Item>
            </Stack>
        </Dialog>);
};
export default ScheduleDeleteDialog;
