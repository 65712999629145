import memoize from 'memoize-one';
import { isLmsStudent } from '../redux/selectors/lti-selectors';
import { isVNextFeatureEnabledOrLms } from '../redux/selectors/common-selectors';
import { getSearch } from '../redux/selectors/route-selector';
export const getRegisterViewModel = memoize((state) => {
    const isVNext = isVNextFeatureEnabledOrLms(state);
    const search = getSearch(state);
    const shouldHideUserMenu = isLmsStudent(state);
    let registerStore;
    if (isVNext) {
        registerStore = state.get('vNextRegisterStore');
    }
    else {
        registerStore = state.get('registerStore');
    }
    const registrationState = registerStore.get('registrationState');
    const error = registerStore.get('error');
    return {
        registrationState,
        error,
        search,
        shouldHideUserMenu,
    };
});
export const getRegisterContainerModel = memoize((state) => {
    const registerViewModel = getRegisterViewModel(state);
    return {
        registerViewModel,
    };
});
