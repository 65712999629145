import * as React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DefaultButton, Stack, ChoiceGroup } from '@fluentui/react';
import { injectIntl, FormattedMessage, defineMessages } from 'react-intl';
import Constants from '../utils/constants';
import { commonMessages } from '../language/common-messages';
const messages = defineMessages({
    title: {
        id: 'InviteAllDialogTitle',
        defaultMessage: 'Send invitations',
        description: 'Title for the invite all dialog.',
    },
    sendOnlyUninvited: {
        id: 'InviteAllSendOnlyUninvitedOption',
        defaultMessage: 'Send only to users who have not been invited',
        description: 'Option to send an invite to only uninvited users.',
    },
    sendAll: {
        id: 'InviteAllSendAllOption',
        defaultMessage: 'Send to all users',
        description: 'Option to send an invite to all users, even those who have already been invited.',
    },
});
const InviteAllDialogInjected = (props) => {
    const { inviteCount, invitedCount, onSubmit, onDismiss, intl } = props;
    const { formatMessage: msg } = intl;
    const isEveryoneAlreadyInvited = inviteCount === invitedCount;
    const [includeAlreadyInvited, setIncludeAlreadyInvited] = React.useState(isEveryoneAlreadyInvited);
    let dialogContent;
    if (isEveryoneAlreadyInvited) {
        const resendInviteBoldSpan = (<b>
                <FormattedMessage id="InviteAllDialogResendInviteSpan" defaultMessage="Do you want to resend invitations to all users?" description="Span displayed as content in the invite all dialog when all the users have already been invited. Composed into InviteAllDialogAllInvitedContent."/>
            </b>);
        dialogContent = (<FormattedMessage id="InviteAllDialogAllInvitedContent" defaultMessage="All users in this lab have already been invited. {resendInviteBoldSpan}" description="Message displayed in the invite all dialog when all users have been invited. {resendInviteBoldSpan} is the resolved value of InviteAllDialogResendInviteSpan." values={{ resendInviteBoldSpan }}/>);
    }
    else {
        dialogContent = (<Stack tokens={{ childrenGap: '18px' }}>
                <Stack.Item styles={{ root: { fontSize: '14px', paddingTop: '25px' } }}>
                    <FormattedMessage id="InviteAllDialogSomeInvitedMessage" defaultMessage="This lab includes users who have already been invited." description="Message displayed in the invite all dialog when some but not all of the users have already been invited."/>
                </Stack.Item>
                <Stack.Item>
                    <ChoiceGroup autoFocus defaultSelectedKey="sendUninvited" options={[
            {
                key: 'sendUninvited',
                text: msg(messages.sendOnlyUninvited),
                styles: { root: { paddingBottom: '10px' } },
            },
            { key: 'sendAll', text: msg(messages.sendAll) },
        ]} onChange={(_, value) => value.key === 'sendUninvited'
            ? setIncludeAlreadyInvited(false)
            : setIncludeAlreadyInvited(true)}/>
                </Stack.Item>
            </Stack>);
    }
    return (<Dialog hidden={false} onDismiss={onDismiss} dialogContentProps={{ title: msg(messages.title) }} closeButtonAriaLabel={msg(commonMessages.cancel)} minWidth={isEveryoneAlreadyInvited ? '386px' : '422px'} maxWidth={Constants.DefaultDialogWidth}>
            {dialogContent}
            <DialogFooter styles={{ actions: { paddingTop: '50px' } }}>
                <PrimaryButton text={msg(commonMessages.continue)} onClick={() => onSubmit(includeAlreadyInvited)}/>
                <DefaultButton text={msg(commonMessages.cancel)} onClick={onDismiss}/>
            </DialogFooter>
        </Dialog>);
};
export const InviteAllDialog = injectIntl(InviteAllDialogInjected);
export default InviteAllDialog;
