import { CommonActionType } from '../common/common-actions';
import { CommonLabParentResourceActionType } from '../lab-parent-resource/lab-parent-resource-actions';
export const CommonLabActionType = {
    SELECT_LAB: 'SELECT_LAB',
    CREATE_LAB_SUCCESS: 'CREATE_LAB_SUCCESS',
    UPDATE_LAB_SUCCESS: 'UPDATE_LAB_SUCCESS',
    CLEAR_SELECTED_LAB: 'CLEAR_SELECTED_LAB',
    PUBLISH_LAB: 'PUBLISH_LAB',
};
export const LabActionType = {
    ...CommonActionType,
    ...CommonLabActionType,
    ...CommonLabParentResourceActionType,
    LIST_LABS: 'LIST_LABS',
    LIST_LABS_SUCCESS: 'LIST_LABS_SUCCESS',
    LIST_LABS_ERROR: 'LIST_LABS_ERROR',
    LIST_LABS_CANCELLED: 'LIST_LABS_CANCELLED',
    GET_LAB: 'GET_LAB',
    GET_LAB_SUCCESS: 'GET_LAB_SUCCESS',
    GET_LAB_CANCELLED: 'GET_LAB_CANCELLED',
    GET_LAB_ERROR: 'GET_LAB_ERROR',
    FILTER_BY_LAB_ACCESS_TYPE: 'FILTER_BY_LAB_ACCESS_TYPE',
    DELETE_LAB: 'DELETE_LAB',
    DELETE_LAB_SUCCESS: 'DELETE_LAB_SUCCESS',
    DELETE_LAB_ERROR: 'DELETE_LAB_ERROR',
    CHECK_VNEXT_CREATELAB_PERMISSION: 'CHECK_VNEXT_CREATELAB_PERMISSION',
    UPDATE_VNEXT_CREATELAB_PERMISSION: 'UPDATE_VNEXT_CREATELAB_PERMISSION',
    UPDATE_VNEXT_PERMISSION_PROPAGATION: 'UPDATE_VNEXT_PERMISSION_PROPAGATION',
    ADD_VNEXT_PERMISSION_PROPAGATING_LAB: 'ADD_VNEXT_PERMISSION_PROPAGATING_LAB',
    REMOVE_VNEXT_PERMISSION_PROPAGATING_LAB: 'REMOVE_VNEXT_PERMISSION_PROPAGATING_LAB',
    CREATE_LAB: 'CREATE_LAB',
    CREATE_LAB_ERROR: 'CREATE_LAB_ERROR',
    UPDATE_LAB: 'UPDATE_LAB',
    UPDATE_LAB_ERROR: 'UPDATE_LAB_ERROR',
    UPDATE_LAB_ACCESS_MODE: 'UPDATE_LAB_ACCESS_MODE',
    UPDATE_LAB_ACCESS_MODE_SUCCESS: 'UPDATE_LAB_ACCESS_MODE_SUCCESS',
    UPDATE_LAB_ACCESS_MODE_ERROR: 'UPDATE_LAB_ACCESS_MODE_ERROR',
    CLEAR_LAB_ERROR: 'CLEAR_LAB_ERROR',
    CLEAR_LAB_UPDATE_ERROR: 'CLEAR_LAB_UPDATE_ERROR',
    CLEAR_LAB_CAPACITY_EXCEEDS_CORES_ERROR: 'CLEAR_LAB_CAPACITY_EXCEEDS_CORES_ERROR',
    CLEAR_PUBLISH_LAB_ERROR: 'CLEAR_PUBLISH_LAB_ERROR',
    POLL_LAB_START: 'POLL_LAB_START',
    POLL_LAB_STOP: 'POLL_LAB_STOP',
    POLL_LAB_CANCELLED: 'POLL_LAB_CANCELLED',
    SYNC_LAB_USERS: 'SYNC_LAB_USERS',
    SYNC_LAB_USERS_ERROR: 'SYNC_LAB_USERS_ERROR',
    POLL_LAB_LIST_START: 'POLL_LAB_LIST_START',
    POLL_LAB_LIST_STOP: 'POLL_LAB_LIST_STOP',
    POLL_LAB_LIST_CANCELLED: 'POLL_LAB_LIST_CANCELLED',
    LOAD_SUBSCRIPTION_VNEXT_LABS_ERROR: 'LOAD_SUBSCRIPTION_VNEXT_LABS_ERROR',
    PUBLISH_LAB_ACCEPTED: 'PUBLISH_LAB_ACCEPTED',
    PUBLISH_LAB_ERROR: 'PUBLISH_LAB_ERROR',
    PUBLISH_LAB_CANCELLED: 'PUBLISH_LAB_CANCELLED',
    UPDATE_LAB_METADATA: 'UPDATE_LAB_METADATA',
    UPDATE_LAB_METADATA_SUCCESS: 'UPDATE_LAB_METADATA_SUCCESS',
    UPDATE_LAB_METADATA_ERROR: 'UPDATE_LAB_METADATA_ERROR',
    GET_LAB_METADATA: 'GET_LAB_METADATA',
    GET_LAB_METADATA_SUCCESS: 'GET_LAB_METADATA_SUCCESS',
    GET_LAB_METADATA_ERROR: 'GET_LAB_METADATA_ERROR',
};
