import { call, put, delay, race, take, cancelled, cancel, fork, select } from 'redux-saga/effects';
import { UserActionType } from '../../actions/user/user-actions';
import UserProvider from '../../../data/providers/user-provider';
import { listUsers as listUsersActionCreator, listUsersSuccess, listUsersError, pollUsersCancelled, listUsersCancelled, listVNextUsersSuccess, } from '../../actions/user/user-action-creators';
import { ResourceId } from '../../../utils/resource-id';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import VNextUserProvider from '../../../data/providers/vnext/arm-api/user-provider';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
export function* listUsers(action) {
    const labId = action.labId;
    try {
        const resourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { users, changed } = yield race({
            users: call(UserProvider.listUsers, resourceId, accessToken, locale, language),
            // TODO: Remaining cancel actions
            changed: race([
                take(UserActionType.SELECT_LAB_PARENT_RESOURCE),
                take(UserActionType.SELECT_LAB),
                take(UserActionType.POLL_USERS_STOP),
                take(UserActionType.POLL_USERS_CANCELLED),
                take(UserActionType.UPDATE_ADDITIONAL_QUOTA),
            ]),
        });
        if (changed) {
            yield put(listUsersCancelled());
        }
        else {
            yield put(listUsersSuccess(users));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.LoadUsers, e ? e.code : undefined);
        yield put(listUsersError(error));
    }
}
export function* listVNextUsers(action) {
    const { labId } = action;
    try {
        const resourceId = new ResourceId(labId, true);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { users, changed } = yield race({
            users: call(VNextUserProvider.listUsers, resourceId, accessToken, locale, language),
            changed: race([
                take(UserActionType.SELECT_LAB_PARENT_RESOURCE),
                take(UserActionType.SELECT_LAB),
                take(UserActionType.POLL_USERS_STOP),
                take(UserActionType.POLL_USERS_CANCELLED),
                take(UserActionType.UPDATE_ADDITIONAL_QUOTA),
            ]),
        });
        if (changed) {
            yield put(listUsersCancelled());
        }
        else {
            yield put(listVNextUsersSuccess(users));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.LoadUsers, e ? e.code : undefined);
        yield put(listUsersError(error));
    }
}
function* pollUsers(action) {
    try {
        // allowing for an initial delay before we kick off polling
        // to mitigate a state flashing bug due to issues in the RP
        const { initialDelayMs, labId } = action;
        if (!!initialDelayMs) {
            yield delay(initialDelayMs);
        }
        // this looks like an infinite loop, but the finally block will get triggered when
        // the task that is running this is canceled, which happens when the POLL_USERS_STOP
        // action is dispatched
        while (true) {
            yield put(listUsersActionCreator(labId));
            yield race({
                success: take(UserActionType.LIST_USERS_SUCCESS),
                cancelled: take(UserActionType.LIST_USERS_CANCELLED),
                error: take(UserActionType.LIST_USERS_ERROR),
            });
            const { intervalMs } = action;
            yield delay(intervalMs);
        }
    }
    finally {
        if (yield cancelled()) {
            yield put(pollUsersCancelled());
        }
    }
}
export function* pollUsersSaga() {
    // while this looks like an infinite loop, yield take(POLL_USERS_START)
    // will pause this code until polling is started
    while (true) {
        const action = yield take(UserActionType.POLL_USERS_START);
        // forking so the polling task runs without blocking
        const pollUsersTask = yield fork(pollUsers, action);
        // waits until the stop is triggered so we can cancel the polling task
        yield take(UserActionType.POLL_USERS_STOP);
        yield cancel(pollUsersTask);
    }
}
export function* listUsersSaga() {
    yield labParentBasedTakeLatest(UserActionType.LIST_USERS, listUsers, listVNextUsers);
}
