import { call, put, takeLatest } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { armScopes } from '../../../data/models/identity';
import { getAccessToken } from '../../../data/providers/msal-provider';
import { initialize, getContext, notifyMfaSuccess, notifyMfaFailure } from '../../../data/providers/teams-provider';
import { teamsCompleteMfaError, teamsCompleteMfaFailed, teamsCompleteMfaSuccess, } from '../../actions/teams/teams-action-creators';
import { TeamsActionType } from '../../actions/teams/teams-actions';
export function* teamsCompleteMfa(action) {
    try {
        yield call(initialize);
        const context = yield call(getContext);
        const { tid } = context;
        const result = yield call(getAccessToken, armScopes, tid);
        if (!!result.accessToken) {
            yield put(teamsCompleteMfaSuccess());
            yield call(notifyMfaSuccess);
        }
        else {
            yield put(teamsCompleteMfaFailed());
            yield call(notifyMfaFailure);
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.TeamsCompleteMfa, err ? err.code : undefined);
        yield put(teamsCompleteMfaError(error));
    }
}
export function* teamsCompleteMfaSaga() {
    yield takeLatest(TeamsActionType.TEAMS_COMPLETE_MFA, teamsCompleteMfa);
}
