import { Checkbox, Link, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import InfoSection from '../info-section';
import GracePeriodInput from './grace-period-input';
import { MaxGracePeriod, MinIdleGracePeriod, MinNoConnectGracePeriod } from './idle-config';
import messages from './messages';
import { isShutdownOnIdleEnabled } from './selectors';
import { DisconnectOnIdleInfoTip, ShutdownOnDisconnectInfoTip, ShutdownOnIdleInfoTip, VNextShutdownOnDisconnectInfoTip, } from './tooltips';
import { LabServicesModels } from 'lab-services';
import { CustomIdleShutdownDropdown } from './customize-shutdown-dropdown';
export const ShutdownPolicyGroup = (props) => {
    const msg = useIntl().formatMessage;
    const { idleConfig, disabled, onChange, isVNext, isLinuxSIG } = props;
    const shutdownOnDisconnectInfoTipAriaLabel = isVNext
        ? msg(messages.vNextShutdownOnDisconnectInfoTipAriaLabel)
        : msg(messages.shutdownOnDisconnectInfoTipAriaLabel);
    const disconnectOnIdleInfoTipAriaLabel = msg(messages.disconnectOnIdleInfoTipAriaLabel);
    const shutdownOnIdleInfoTipAriaLabel = msg(messages.shutdownOnIdleInfoTipAriaLabel);
    return (<Stack tokens={{ childrenGap: 15 }}>
            <div className="ms-fontWeight-semibold">
                <FormattedMessage {...messages.title}/>
            </div>
            <Stack tokens={{ childrenGap: 26 }}>
                {!isVNext && !!idleConfig.disconnectOnIdleSupported && (<Stack tokens={{ childrenGap: 8 }}>
                        <Stack horizontal>
                            <Checkbox label={msg(messages.disconnectOnIdleLabel)} checked={idleConfig.disconnectOnIdleEnabled} onChange={(_, value) => onChange({ ...idleConfig, disconnectOnIdleEnabled: value || false })} disabled={disabled}/>
                            <DisconnectOnIdleInfoTip ariaLabel={disconnectOnIdleInfoTipAriaLabel}/>
                        </Stack>
                        {!!idleConfig.disconnectOnIdleEnabled && (<GracePeriodInput label={msg(messages.disconnectOnIdleInputLabel)} value={idleConfig.disconnectOnIdleGracePeriod} onChange={(value) => onChange({ ...idleConfig, disconnectOnIdleGracePeriod: value })} disabled={disabled} min={MinIdleGracePeriod} max={MaxGracePeriod}/>)}
                    </Stack>)}
                {isVNext && (<Stack tokens={{ childrenGap: 8 }}>
                        <Stack horizontal>
                            <Checkbox label={msg(messages.shutdownOnIdleLabel)} checked={isShutdownOnIdleEnabled(idleConfig)} onChange={(_, value) => onChange({
        ...idleConfig,
        shutdownOnIdle: value
            ? LabServicesModels.ShutdownOnIdleMode.LowUsage
            : LabServicesModels.ShutdownOnIdleMode.None,
    })} disabled={disabled}/>
                            <ShutdownOnIdleInfoTip ariaLabel={shutdownOnIdleInfoTipAriaLabel}/>
                        </Stack>
                        {isShutdownOnIdleEnabled(idleConfig) && (<Stack.Item>
                                <GracePeriodInput label={msg(messages.disconnectOnIdleInputLabel)} value={idleConfig.shutdownOnIdleGracePeriod} onChange={(value) => onChange({ ...idleConfig, shutdownOnIdleGracePeriod: value })} disabled={disabled} min={MinIdleGracePeriod} max={MaxGracePeriod}/>
                                <CustomIdleShutdownDropdown value={idleConfig.shutdownOnIdle} onChange={(value) => {
        onChange({ ...idleConfig, shutdownOnIdle: value });
    }} disabled={disabled}/>
                            </Stack.Item>)}
                    </Stack>)}
                {idleConfig.shutdownOnDisconnectSupported && (<Stack tokens={{ childrenGap: 8 }}>
                        <Stack horizontal>
                            <Checkbox label={msg(messages.shutdownOnDisconnectLabel)} checked={idleConfig.shutdownOnDisconnect} onChange={(_, value) => onChange({ ...idleConfig, shutdownOnDisconnect: value || false })} disabled={disabled}/>
                            {!isVNext && (<ShutdownOnDisconnectInfoTip ariaLabel={shutdownOnDisconnectInfoTipAriaLabel}/>)}
                            {isVNext && (<VNextShutdownOnDisconnectInfoTip ariaLabel={shutdownOnDisconnectInfoTipAriaLabel}/>)}
                        </Stack>
                        {idleConfig.shutdownOnDisconnect && (<GracePeriodInput label={msg(messages.shutdownOnDisconnectInputLabel)} value={idleConfig.shutdownOnDisconnectGracePeriod} onChange={(value) => onChange({ ...idleConfig, shutdownOnDisconnectGracePeriod: value })} disabled={disabled} min={0} max={MaxGracePeriod}/>)}
                    </Stack>)}
                {idleConfig.shutdownOnNoConnectSupported && (<Stack tokens={{ childrenGap: 8 }}>
                        <Checkbox label={msg(messages.shutdownOnNoConnectLabel)} checked={idleConfig.shutdownWhenNotConnected} onChange={(_, value) => onChange({ ...idleConfig, shutdownWhenNotConnected: value || false })} disabled={disabled}/>
                        {idleConfig.shutdownWhenNotConnected && (<GracePeriodInput label={msg(messages.shutdownOnNoConnectInputLabel)} value={idleConfig.shutdownOnNoConnectGracePeriod} onChange={(value) => onChange({ ...idleConfig, shutdownOnNoConnectGracePeriod: value })} disabled={disabled} min={MinNoConnectGracePeriod} max={MaxGracePeriod}/>)}
                    </Stack>)}
                {isLinuxSIG && !isVNext && (<InfoSection>
                        <FormattedMessage id="SupportedLinuxDistributions" defaultMessage="This lab uses a Linux image from the Azure Compute Gallery. Please confirm that this image's Linux <Link>version is supported</Link> before enabling disconnect and shutdown settings for this lab. Configuring these settings on an unsupported image will fail and may cause issues when publishing." description="<Link> and </Link> should not be localized, but the other text should be." values={{
        Link: (text) => (<Link href="https://docs.microsoft.com/en-us/azure/virtual-machines/extensions/diagnostics-linux#supported-linux-distributions" target="_blank">
                                        {text}
                                    </Link>),
    }}/>
                    </InfoSection>)}
            </Stack>
        </Stack>);
};
export default ShutdownPolicyGroup;
