import { CommonActionType } from '../common/common-actions';
export const CommonLabParentResourceActionType = {
    SELECT_LAB_PARENT_RESOURCE: 'SELECT_LAB_PARENT_RESOURCE',
    CLEAR_SELECTED_LAB_PARENT_RESOURCE: 'CLEAR_SELECTED_LAB_PARENT_RESOURCE',
};
export const LabParentResourceActionType = {
    ...CommonActionType,
    ...CommonLabParentResourceActionType,
    LIST_LAB_PARENT_RESOURCES: 'LIST_LAB_PARENT_RESOURCES',
    LIST_LAB_PARENT_RESOURCES_SUCCESS: 'LIST_LAB_PARENT_RESOURCES_SUCCESS',
    LIST_LAB_PARENT_RESOURCES_ERROR: 'LIST_LAB_PARENT_RESOURCES_ERROR',
    REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES: 'REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES',
    REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES_SUCCESS: 'REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES_SUCCESS',
    REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES_ERROR: 'REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES_ERROR',
};
