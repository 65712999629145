import { CommonActionType, } from './common-actions';
import { ErrorCode } from '../../../common/error-codes';
export const fatalError = (errorCode = ErrorCode.GenericError, error) => ({
    type: CommonActionType.FATAL_ERROR,
    errorCode,
    error,
});
export const clearFatalError = () => ({
    type: CommonActionType.CLEAR_FATAL_ERROR,
});
export const initialize = (shouldSignInInteractively = false) => ({
    type: CommonActionType.INITIALIZE,
    shouldSignInInteractively,
});
export const initializeCancelled = () => ({
    type: CommonActionType.INITIALIZE_CANCELLED,
});
export const initializeSuccess = () => ({
    type: CommonActionType.INITIALIZE_SUCCESS,
});
export const initializeError = (error) => ({
    type: CommonActionType.INITIALIZE_ERROR,
    error,
});
export const professorInitialize = () => ({
    type: CommonActionType.PROFESSOR_INITIALIZE,
});
export const professorInitializeCancelled = () => ({
    type: CommonActionType.PROFESSOR_INITIALIZE_CANCELLED,
});
export const professorInitializeSuccess = () => ({
    type: CommonActionType.PROFESSOR_INITIALIZE_SUCCESS,
});
export const professorInitializeError = (error) => ({
    type: CommonActionType.PROFESSOR_INITIALIZE_ERROR,
    error,
});
export const studentInitialize = () => ({
    type: CommonActionType.STUDENT_INITIALIZE,
});
export const studentInitializeCancelled = () => ({
    type: CommonActionType.STUDENT_INITIALIZE_CANCELLED,
});
export const studentInitializeSuccess = () => ({
    type: CommonActionType.STUDENT_INITIALIZE_SUCCESS,
});
export const studentInitializeError = (error) => ({
    type: CommonActionType.STUDENT_INITIALIZE_ERROR,
    error,
});
export const labAppInitialize = (labId) => ({
    type: CommonActionType.LAB_APP_INITIALIZE,
    labId,
});
export const labAppInitializeCancelled = () => ({
    type: CommonActionType.LAB_APP_INITIALIZE_CANCELLED,
});
export const labAppInitializeSuccess = () => ({
    type: CommonActionType.LAB_APP_INITIALIZE_SUCCESS,
});
export const labAppInitializeError = (error) => ({
    type: CommonActionType.LAB_APP_INITIALIZE_ERROR,
    error,
});
export const setHost = (host) => ({
    type: CommonActionType.SET_HOST,
    host,
});
export const setLocale = (locale) => ({
    type: CommonActionType.SET_LOCALE,
    locale,
});
export const setTimezone = (timezone) => ({
    type: CommonActionType.SET_TIMEZONE,
    timezone,
});
export const setTheme = (theme) => ({
    type: CommonActionType.SET_THEME,
    theme,
});
export const setReferrer = (referrer) => ({
    type: CommonActionType.SET_REFERRER,
    referrer,
});
export const processUrlFeatures = () => ({
    type: CommonActionType.PROCESS_URL_FEATURES,
});
export const processUrlFeaturesSuccess = () => ({
    type: CommonActionType.PROCESS_URL_FEATURES_SUCCESS,
});
export const processUrlFeaturesError = () => ({
    type: CommonActionType.PROCESS_URL_FEATURES_ERROR,
});
export const enableFeature = (feature) => ({
    type: CommonActionType.ENABLE_FEATURE,
    feature,
});
export const enableFeatureSuccess = () => ({
    type: CommonActionType.ENABLE_FEATURE_SUCCESS,
});
export const enableFeatureError = () => ({
    type: CommonActionType.ENABLE_FEATURE_ERROR,
});
export const disableFeature = (feature) => ({
    type: CommonActionType.DISABLE_FEATURE,
    feature,
});
export const idleTimeout = () => ({
    type: CommonActionType.IDLE_TIMEOUT,
});
export const processQueryParams = (search) => ({
    type: CommonActionType.PROCESS_QUERY_PARAMS,
    search,
});
export const clearUserSession = () => ({
    type: CommonActionType.CLEAR_USER_SESSION,
});
export const selectTenant = (tenantId, isRedirect) => ({
    type: CommonActionType.SELECT_TENANT,
    tenantId,
    isRedirect,
});
const actions = {
    fatalError,
    clearFatalError,
    initialize,
    initializeCancelled,
    initializeSuccess,
    initializeError,
    professorInitialize,
    professorInitializeCancelled,
    professorInitializeSuccess,
    professorInitializeError,
    studentInitialize,
    studentInitializeCancelled,
    studentInitializeSuccess,
    studentInitializeError,
    labAppInitialize,
    labAppInitializeCancelled,
    labAppInitializeSuccess,
    labAppInitializeError,
    setHost,
    setLocale,
    setTimezone,
    setTheme,
    setReferrer,
    processUrlFeatures,
    processUrlFeaturesSuccess,
    processUrlFeaturesError,
    enableFeature,
    disableFeature,
    idleTimeout,
    processQueryParams,
    clearUserSession,
    enableFeatureSuccess,
    enableFeatureError,
    selectTenant,
};
export default actions;
