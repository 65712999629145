import memoize from 'memoize-one';
import { trackTrace } from '../../utils/telemetry/telemetry-channel';
import { ResourceId } from '../../utils/resource-id';
import { InitializationState } from '../state/common-store';
import { Feature } from '../../utils/features';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { environmentType, EnvironmentType } from '../../utils/environment';
import { Host } from '../../utils/hosts';
import { findOneIana } from 'windows-iana/dist';
import { getCurrentLabParentId, isCurrentLabParentLabAccount, isCurrentLabParentResourceGroup, } from './lab-parent-resource-selectors';
import { LabServicesModels } from 'lab-services';
import { isLtiOidcRoute, isLtiReturnOidc } from './route-selector';
import { getCurrentLabId } from './lab-selectors';
export const isInitialized = (state) => state === InitializationState.Success || state === InitializationState.Failed;
export const isAppInitialized = memoize((state) => {
    const commonStore = state.get('commonStore');
    const initializationState = commonStore.get('initializationState');
    const initialized = isInitialized(initializationState);
    return initialized;
});
export const isProfessorAppInitialized = memoize((state) => {
    const commonStore = state.get('commonStore');
    const professorInitializationState = commonStore.get('professorInitializationState');
    const initialized = isInitialized(professorInitializationState);
    return initialized;
});
export const isStudentAppInitialized = memoize((state) => {
    const commonStore = state.get('commonStore');
    const studentInitializeState = commonStore.get('studentInitializeState');
    const initialized = isInitialized(studentInitializeState);
    return initialized;
});
export const isLabAppInitialized = memoize((state) => {
    const commonStore = state.get('commonStore');
    const labAppInitializeState = commonStore.get('labAppInitializeState');
    const initialized = isInitialized(labAppInitializeState);
    return initialized;
});
export const hasFatalError = memoize((state) => state.get('commonStore').get('fatalErrorCode') !== undefined);
export const getFatalError = memoize((state) => state.get('commonStore').get('fatalError'));
export const getFatalErrorCode = memoize((state) => state.get('commonStore').get('fatalErrorCode'));
export const getResourceProperties = memoize((state) => {
    let subscriptionId = '';
    let resourceGroup = '';
    let labAccount = '';
    let lab = '';
    let template = '';
    const isVCurrent = isCurrentLabParentLabAccount(state);
    const currentLabId = getCurrentLabId(state);
    const currentLabParentId = getCurrentLabParentId(state);
    if (currentLabParentId) {
        try {
            const resourceId = new ResourceId(currentLabParentId);
            subscriptionId = resourceId.subscriptionId || subscriptionId;
            resourceGroup = resourceId.resourceGroupName || resourceGroup;
            labAccount = isVCurrent ? resourceId.name || labAccount : '';
        }
        catch (e) {
            trackTrace(`common-selectors: Unable to select resource id for ${currentLabParentId} due to error ${e.message}.`);
        }
    }
    if (currentLabId) {
        try {
            const resourceId = new ResourceId(currentLabId, !isVCurrent);
            lab = resourceId.name || lab;
        }
        catch (e) {
            trackTrace(`common-selectors: Unable to select resource id for ${currentLabId} due to error ${e.message}.`);
        }
    }
    const templateStore = state.get('templateStore');
    const currentTemplateId = templateStore.get('currentTemplate');
    if (currentTemplateId) {
        try {
            const resourceId = new ResourceId(currentTemplateId);
            template = resourceId.name || template;
        }
        catch (e) {
            trackTrace(`common-selectors: Unable to select resource id for ${currentTemplateId} due to error ${e.message}.`);
        }
    }
    return { subscriptionId, resourceGroup, labAccount, lab, template };
});
export const getRequestedLocale = memoize((state) => state.get('commonStore').get('requestedLocale'));
export const getHost = memoize((state) => state.get('commonStore').get('host'));
export const getLocale = memoize((state) => state.get('commonStore').get('locale'));
export const getLanguage = memoize((state) => state.get('commonStore').get('language'));
export const getMarket = memoize((state) => state.get('commonStore').get('market'));
export const getTimezone = memoize((state) => state.get('commonStore').get('timezone'));
export const getIanaTimezone = memoize((state) => {
    const windowsTimeZone = state.get('commonStore').get('timezone');
    const ianaTimeZone = findOneIana(windowsTimeZone);
    return ianaTimeZone;
});
export const getReferrer = memoize((state) => state.get('commonStore').get('referrer'));
export const isFeatureEnabled = memoize((state, feature) => state
    .get('commonStore')
    .get('features')
    .has(feature.trim().toLowerCase()));
export const isDevMode = memoize((state) => environmentType !== EnvironmentType.PROD || isFeatureEnabled(state, Feature.Dev));
export const isTeamsHost = memoize((state) => {
    const host = getHost(state);
    return host === Host.Teams;
});
export const isTeamsMode = memoize((state) => {
    return isTeamsHost(state) || isFeatureEnabled(state, Feature.TeamsIntegration);
});
export const getFeatures = memoize((state) => {
    const features = state.get('commonStore').get('features');
    return features.toArray();
});
export const storeIsLoading = (loadState) => {
    return loadState === LoadingStoreState.Loading || loadState === LoadingStoreState.NotStarted;
};
export const storeIsUpdating = (loadState) => {
    return loadState === LoadingStoreState.Updating;
};
export const storeHasLoadError = (loadState) => {
    return loadState === LoadingStoreState.LoadingFailed;
};
export const storeHasRefreshError = (loadState) => {
    return loadState === LoadingStoreState.LoadingFailed;
};
export const isVNextFeatureEnabled = memoize((_state) => {
    // return isFeatureEnabled(state, Feature.VNext);
    return true;
});
export const isTerminalState = (provisioningState) => {
    if (!provisioningState) {
        return false;
    }
    return (provisioningState === LabServicesModels.ProvisioningState.Succeeded ||
        provisioningState === LabServicesModels.ProvisioningState.Failed);
};
export const isLmsHost = memoize((store) => {
    const host = store.get('commonStore').get('host');
    return host === Host.Canvas;
});
export const isVNextFeatureEnabledOrLms = memoize((state) => {
    return isVNextFeatureEnabled(state) || isLmsHost(state);
});
export const isLmsMode = memoize((state) => {
    return (isLmsHost(state) ||
        isFeatureEnabled(state, Feature.CanvasIntegration) ||
        isLtiOidcRoute(state) ||
        isLtiReturnOidc(state));
});
export const isTeamsOrLmsMode = memoize((state) => {
    return isLmsMode(state) || isTeamsMode(state);
});
export const isBastionFeatureEnabled = memoize((state) => {
    return isFeatureEnabled(state, Feature.Bastion) || isCurrentLabParentResourceGroup(state) || isLmsHost(state);
});
