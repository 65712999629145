export const UserStatus = {
    Failed: 'Failed',
    Deleting: 'Deleting',
    Registered: 'Registered',
    NotRegistered: 'NotRegistered',
    Unknown: '',
};
export const InviteStatus = {
    NotSent: 'NotSent',
    Sending: 'Sending',
    Sent: 'Sent',
    SendingFailed: 'SendingFailed',
    Unknown: '',
};
