import * as msTeams from '@microsoft/teams-js';
import { formatFeatureParam } from '../../redux/sagas/common/features';
import Feature from '../../utils/features';
import { Host } from '../../utils/hosts';
import Routes from '../../utils/routes';
import { getLabListRoute } from '../helpers/route-helpers';
import ArmProvider from './arm-data-provider';
import { getAccessToken as getAccessTokenMsal, loginSilent as loginSilentMsal, clearCacheForScope as clearCacheForScopeMsal, } from './msal-provider';
let useMsal = false;
export function notifyAppLoaded() {
    msTeams.appInitialization.notifyAppLoaded();
}
export function notifySuccess() {
    msTeams.appInitialization.notifySuccess();
}
// TODO: Actually wire this up
export function notifyAuthFailure() {
    const request = {
        reason: 'AuthFailed',
    };
    msTeams.appInitialization.notifyFailure(request);
}
export async function disableSaveButton() {
    msTeams.settings.setValidityState(false);
}
export async function configureTab(labParentId, shouldAddVNextFeatureWQueryParam) {
    const { groupId, tid } = await getContext();
    const labParentRoute = getLabListRoute(labParentId);
    const baseUrl = `${window.location.origin}${labParentRoute}`;
    let contentUrl = `${baseUrl}?host=${encodeURIComponent(Host.Teams)}`;
    let websiteUrl = `${baseUrl}?referrer=${encodeURIComponent(Host.Teams)}&tenantId=${tid}&groupId=${encodeURIComponent(groupId || '')}`;
    if (shouldAddVNextFeatureWQueryParam) {
        const featureParam = formatFeatureParam(Feature.VNext);
        contentUrl = `${contentUrl}&${featureParam}=true`;
        websiteUrl = `${websiteUrl}&${featureParam}=true`;
    }
    msTeams.settings.registerOnSaveHandler((saveEvent) => {
        msTeams.settings.setSettings({
            // set the url that iframe loads
            contentUrl,
            // display the web icon on the top app bar and set the url for the external link
            websiteUrl,
            entityId: 'AzureLabs',
        });
        saveEvent.notifySuccess();
    });
    msTeams.settings.setValidityState(true);
}
export function clearCacheForScope(accessToken) {
    if (useMsal) {
        clearCacheForScopeMsal(accessToken);
    }
    // no cache clear for teams SSO tokens unfortunately, so we don't do anything
}
export async function getAccessToken(scopes, tenantId, returnUrl) {
    if (useMsal) {
        const result = await getAccessTokenMsal(scopes, tenantId);
        return result;
    }
    const promise = new Promise((resolve, reject) => {
        const request = {
            successCallback: (token) => {
                const result = {
                    isRedirecting: false,
                    accessToken: token,
                };
                resolve(result);
            },
            failureCallback: (reason) => reject(new Error(reason)),
            silent: !returnUrl,
        };
        msTeams.authentication.getAuthToken(request);
    });
    const result = await promise;
    return result;
}
export async function loginSilent(tenantId) {
    if (useMsal) {
        const result = await loginSilentMsal(tenantId);
        return result;
    }
    const context = await getContext();
    const account = {
        accountIdentifier: '',
        homeAccountIdentifier: '',
        idToken: {},
        sid: '',
        environment: '',
        userName: context.loginHint || '',
        name: '',
        idTokenClaims: {
            tid: context.tid || '',
        },
    };
    const result = {
        isAuthenticated: true,
        account,
    };
    return result;
}
export async function initialize() {
    const promise = new Promise((resolve, reject) => {
        try {
            if (!msTeams.ParentAppWindow) {
                reject(new Error('Teams initialization failed. Not running in a Teams host.'));
            }
            else {
                // NOTE: This has no failure callback, should we fail after a timeout?
                // Teams will do that for us since we won't notifySuccess, but good to
                // keep this in mind.
                msTeams.initialize(async () => {
                    try {
                        await configureArmAndAuth();
                        resolve();
                    }
                    catch (err) {
                        reject(err);
                    }
                });
            }
        }
        catch (err) {
            reject(err);
        }
    });
    return promise;
}
export async function configureArmAndAuth() {
    const msalSignInResult = await loginSilentMsal();
    useMsal = msalSignInResult.isAuthenticated;
    if (!useMsal) {
        ArmProvider.enableProxy();
    }
    else {
        ArmProvider.disableProxy();
    }
}
export async function getContext() {
    const promise = new Promise((resolve, reject) => {
        try {
            msTeams.getContext((context) => {
                resolve(context);
            });
        }
        catch (err) {
            reject(err);
        }
    });
    const context = await promise;
    return context;
}
export function requestUserConsentOrMfa() {
    const promise = new Promise((resolve, reject) => {
        const url = `https://${window.location.host}${Routes.TeamsStartMfa}?host=${encodeURIComponent(Host.Teams)}`;
        const request = {
            url,
            successCallback: (result) => {
                useMsal = true;
                ArmProvider.disableProxy();
                resolve(result);
            },
            failureCallback: (reason) => {
                reject(new Error(reason || ''));
            },
        };
        msTeams.authentication.authenticate(request);
    });
    return promise;
}
export function notifyMfaSuccess() {
    msTeams.authentication.notifySuccess();
}
export function notifyMfaFailure() {
    msTeams.authentication.notifyFailure();
}
const TeamsProvider = {
    initialize,
    getContext,
    disableSaveButton,
    configureTab,
    getAccessToken,
    loginSilent,
    requestUserConsentOrMfa,
    notifyMfaSuccess,
    notifyMfaFailure,
};
export default TeamsProvider;
