import * as React from 'react';
import { Icon } from '@fluentui/react';
import { getCustomTheme } from './themes/selectors';
export const ClockIcon = () => {
    return (<Icon iconName="Clock" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '16px',
            paddingTop: '2px',
            color: getCustomTheme().palette?.themePrimary,
        },
    }}/>);
};
