import * as React from 'react';
import { DefaultButton } from '@fluentui/react';
export class FileUploadButton extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }
    render() {
        const { labelText, accept, onChange } = this.props;
        return (<DefaultButton text={labelText} style={{
            paddingLeft: '8px',
            paddingRight: '8px',
            width: 'fit-content',
        }} onClick={() => this.inputRef.current && this.inputRef.current.click()}>
                <input id="upload" type="file" accept={accept} style={{ display: 'none' }} ref={this.inputRef} onChange={onChange}/>
            </DefaultButton>);
    }
}
export default FileUploadButton;
