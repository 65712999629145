import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import RegisterUserContainer from '../register-user/register-user-container';
import StudentVmListContainer from '../student-vm-list/student-vm-list-container';
import { Routes } from '../utils/routes';
import { Loading } from '../welcome/loading';
export const StudentApp = (props) => {
    if (props.isInitializing) {
        return <Loading />;
    }
    return (<Switch>
            <Route path={Routes.StudentView} component={StudentVmListContainer}/>
            <Route path={Routes.Register()} render={(routeProps) => (<RegisterUserContainer registrationCode={routeProps.match.params.registrationCode}/>)}/>
        </Switch>);
};
export default StudentApp;
