import { createLabsApiClient } from './labs-api-client-factory';
export async function getLabMetadata(region, resourceId, accessToken, locale, language) {
    const labsApiClient = createLabsApiClient(resourceId.subscriptionId, accessToken, locale, language);
    const response = (await labsApiClient.lab.getMetadata(region, resourceId.resourceGroupName, resourceId.name));
    return response;
}
const ResourceMetaDataProvider = {
    getLabMetadata,
};
export default ResourceMetaDataProvider;
