import { Map, Record } from 'immutable';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { CoreLimitActionType, } from '../actions/core-limit/core-limit-actions';
import { Context } from '../action-context';
function getCoreRestrictionsAndUsages(state, action) {
    return state.merge({
        loadState: state.get('loadState') === LoadingStoreState.Loaded
            ? LoadingStoreState.Updating
            : LoadingStoreState.Loading,
    });
}
function getCoreRestrictionsAndUsagesSuccess(state, action) {
    const { coreRestrictionsAndUsages } = action;
    if (!coreRestrictionsAndUsages) {
        return state;
    }
    const { restrictions, usages, isExempt } = coreRestrictionsAndUsages;
    let coreQuotaData = state.get('coreQuotaData');
    if (!coreQuotaData) {
        coreQuotaData = {};
    }
    const newUsages = usages ? Map(usages.map((x) => [x.coreFamily, x.numberOfCores])) : undefined;
    const newRestrictions = restrictions ? Map(restrictions.map((x) => [x.coreFamily, x.numberOfCores])) : undefined;
    coreQuotaData = { ...coreQuotaData, isExempt, usages: newUsages, restrictions: newRestrictions };
    return state.merge({
        loadState: LoadingStoreState.Loaded,
        coreQuotaData,
    });
}
function getCoreRestrictionsAndUsagesError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: state.get('loadState') === LoadingStoreState.Updating
            ? LoadingStoreState.UpdateFailed
            : LoadingStoreState.LoadingFailed,
        error,
    });
}
function getCoreRestrictionsAndUsagesCancelled(state, action) {
    return state.merge({
        loadState: state.get('loadState') === LoadingStoreState.Loading
            ? LoadingStoreState.NotStarted
            : LoadingStoreState.Loaded,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    coreQuotaData: {
        isExempt: true,
        usages: Map(),
        restrictions: Map(),
    },
    error: undefined,
})();
export const coreLimitReducer = (state = initialState, action) => {
    switch (action.type) {
        case CoreLimitActionType.CLEAR_USER_SESSION:
        case CoreLimitActionType.SELECT_TENANT:
        case CoreLimitActionType.SELECT_LAB_PARENT_RESOURCE:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    return state;
                default:
                    switch (action.type) {
                        case CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE:
                            return getCoreRestrictionsAndUsages(state, action);
                        case CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_SUCCESS:
                            return getCoreRestrictionsAndUsagesSuccess(state, action);
                        case CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_ERROR:
                            return getCoreRestrictionsAndUsagesError(state, action);
                        case CoreLimitActionType.GET_CORE_RESTRICTIONS_AND_USAGE_CANCELLED:
                            return getCoreRestrictionsAndUsagesCancelled(state, action);
                        default:
                            return state;
                    }
            }
    }
};
export default coreLimitReducer;
