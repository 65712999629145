import memoize from 'memoize-one';
import { getCurrentLabParentId } from './lab-parent-resource-selectors';
import { ResourceId } from '../../utils/resource-id';
import { caseInsensitiveCultureInvariantCompare } from '../../utils/string-comparison';
export const getSubscriptions = memoize((store) => {
    const subscriptionStore = store.get('subscriptionStore');
    const subscriptions = subscriptionStore.get('subscriptions');
    return subscriptions;
});
export const subscriptionHasFeature = memoize((store, feature) => {
    const currentLabParent = getCurrentLabParentId(store);
    const resourceId = new ResourceId(currentLabParent);
    const subscriptionId = resourceId.subscriptionId;
    if (!subscriptionId) {
        return false;
    }
    const subscriptions = getSubscriptions(store);
    const currentSubscription = subscriptions.find((o) => caseInsensitiveCultureInvariantCompare(o.subscriptionId, subscriptionId) === 0);
    if (!currentSubscription) {
        return false;
    }
    return currentSubscription.features.some((x) => x === feature);
});
