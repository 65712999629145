import { connectRouter } from 'connected-react-router/immutable';
import { combineReducers } from 'redux-immutable';
import { registerReducer } from './register-reducer';
import { userEnvironmentReducer } from './user-environment-reducer';
import { commonReducer } from './common-reducer';
import { labReducer } from './lab-reducer';
import { labAccountReducer } from './lab-account-reducer.';
import { identityReducer } from './identity-reducer';
import { scheduleReducer } from './schedule-reducer';
import { templateReducer } from './template-reducer';
import { subscriptionReducer } from './subscription-reducer';
import { galleryImageReducer } from './gallery-image-reducer';
import { sharedImageReducer } from './shared-image-reducer';
import { userReducer } from './user-reducer';
import { environmentReducer } from './environment-reducer';
import { pricingAndAvailabilityReducer } from './pricing-and-availability-reducer';
import { coreLimitReducer } from './core-limit-reducer';
import { groupReducer } from './group-reducer';
import { teamsReducer } from './teams-reducers';
import { TenantReducer } from './tenant-reducer';
import { pricingAndAvailabilityReducer as vNextpricingAndAvailabilityReducer } from './vnext/pricing-and-availability-reducer';
import { userVirtualMachineReducer as vNextUserVirtualMachineReducer } from './vnext/user-virtual-machine-reducer';
import { registerReducer as vNextRegisterReducer } from './vnext/register-reducer';
import { labReducer as vNextLabReducer } from './vnext/lab-reducer';
import { scheduleReducer as vNextSchedulerReducer } from './vnext/schedule-reducer';
import LabParentResourceReducer from './lab-parent-resource-reducer';
import coreUsageReducer from './vnext/core-usage-reducer';
import labPlanReducer from './vnext/lab-plan-reducer';
import imageReducer from './vnext/image-reducer';
import vNextVirtualMachineReducer from './vnext/virtual-machine-reducer';
import vNextUserReducer from './vnext/user-reducer';
import ltiReducer from './lti-reducer';
import skuReducer from './vnext/sku-reducer';
import usageReducer from './vnext/usage-reducer';
import priceReducer from './vnext/price-reducer';
// NOTE: TypeScript does not handle these types well
export const combinedReducer = (history) => combineReducers({
    registerStore: registerReducer,
    identityStore: identityReducer,
    userEnvironmentStore: userEnvironmentReducer,
    commonStore: commonReducer,
    galleryImageStore: galleryImageReducer,
    sharedImageStore: sharedImageReducer,
    labStore: labReducer,
    labAccountStore: labAccountReducer,
    scheduleStore: scheduleReducer,
    templateStore: templateReducer,
    subscriptionStore: subscriptionReducer,
    coreLimitStore: coreLimitReducer,
    userStore: userReducer,
    environmentStore: environmentReducer,
    pricingAndAvailabilityStore: pricingAndAvailabilityReducer,
    groupStore: groupReducer,
    teamsStore: teamsReducer,
    tenantStore: TenantReducer,
    router: connectRouter(history),
    ltiStore: ltiReducer,
    //vNext reducers
    vNextPricingAndAvailabilityStore: vNextpricingAndAvailabilityReducer,
    vNextUserVirtualMachineStore: vNextUserVirtualMachineReducer,
    vNextRegisterStore: vNextRegisterReducer,
    vNextLabStore: vNextLabReducer,
    vNextScheduleStore: vNextSchedulerReducer,
    labParentResourceStore: LabParentResourceReducer,
    labPlanStore: labPlanReducer,
    vNextCoreUsageStore: coreUsageReducer,
    vNextImageStore: imageReducer,
    vNextVirtualMachineStore: vNextVirtualMachineReducer,
    vNextUserStore: vNextUserReducer,
    vNextSkuStore: skuReducer,
    vNextUsageStore: usageReducer,
    vNextPriceStore: priceReducer,
});
export default combinedReducer;
