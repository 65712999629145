import _ from 'lodash';
export function validateDate(maxDate, value, message) {
    return _.isDate(value) && (!_.isDate(maxDate) || value <= maxDate) ? undefined : message;
}
export function validateRequired(value, message) {
    return value ? undefined : message;
}
export function validateLength(value, maxLength, message) {
    return value.length <= maxLength ? undefined : message;
}
const CommonValidators = {
    validateLength,
    validateRequired,
};
export default CommonValidators;
