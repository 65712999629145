import { DefaultButton, Dialog, DialogFooter, DialogType, Icon, Link, Panel, PanelType, Persona, PersonaSize, PrimaryButton, Stack, } from '@fluentui/react';
import _ from 'lodash';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { SubmitButton } from '../common/submit-button';
import { getCustomTheme } from '../common/themes/selectors';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import Constants from '../utils/constants';
import { FilteredDropdown } from '../common/filtered-dropdown';
import './add-users-flyout.css';
const messages = defineMessages({
    title: {
        id: 'UserListSyncFromGroupFlyoutTitle',
        defaultMessage: 'Sync from group',
        description: 'Title of the flyout dialog to sync a lab from a group.',
    },
    label: {
        id: 'UserListSyncFromGroupFlyoutLabel',
        defaultMessage: 'Group',
        description: 'Label above group searchbox box on flyout dialog to sync a lab from a group.',
    },
    placeholder: {
        id: 'UserListSyncFromGroupFlyoutPlaceholder',
        defaultMessage: 'Search for groups',
        description: 'Placeholder text inside searchbox on a dialog to sync a lab from a group.',
    },
    disableSync: {
        id: 'UserListSyncFromGroupFlyoutDisableSync',
        defaultMessage: 'Disable group sync and retain lab',
        description: 'Choice message for retaining lab on group deletion',
    },
    deleteLab: {
        id: 'UserListSyncFromGroupFlyoutDeleteLab',
        defaultMessage: 'Delete lab (including all users and virtual machines)',
        description: 'Choice message for deleting lab on group deletion',
    },
    confirmDialogTitle: {
        id: 'UserListSyncFromGroupFlyoutConfirmDialogTitle',
        defaultMessage: 'Confirm sync',
        description: 'Title of confirmation dialog presented when user attempts to sync lab to group.',
    },
    dropdownLabel: {
        id: 'UserListSyncFromGroupFlyoutDropdownLabel',
        defaultMessage: 'Group',
        description: 'Label above the search for group dropdown',
    },
});
export const SyncFromGroupFlyout = (props) => {
    const { groups, isSubmitting, onDismiss, onSubmit, searchGroups, isLoadingGroups } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const [selectedGroup, setSelectedGroup] = React.useState();
    const [isValid, setIsValid] = React.useState(false);
    const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
    const groupSelected = (selectedItem) => {
        setSelectedGroup(selectedItem);
        setIsValid(!!selectedItem);
    };
    // close the flyout after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    const onDialogSubmit = () => {
        setShowConfirmDialog(false);
        onSubmit(selectedGroup?.id || '', false);
    };
    return (<Panel type={PanelType.custom} customWidth={`${Constants.DefaultFlyoutWidth}px`} isOpen={true} isLightDismiss={!isSubmitting} onDismiss={isSubmitting ? undefined : onDismiss} headerText={msg(messages.title)} closeButtonAriaLabel={msg(commonMessages.cancel)} styles={getCustomTheme().panelStyles} onRenderFooterContent={() => (<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '8px' }}>
                    <Stack.Item>
                        <SubmitButton default={true} disabled={!isValid} buttonText={msg(commonMessages.add)} onClick={() => setShowConfirmDialog(true)} isSubmitting={isSubmitting}/>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton disabled={isSubmitting} onClick={onDismiss}>
                            {msg(commonMessages.cancel)}
                        </DefaultButton>
                    </Stack.Item>
                </Stack>)}>
            <GroupDropdown groups={groups} isLoadingGroups={isLoadingGroups} searchGroups={searchGroups} groupSelected={groupSelected} disabled={isSubmitting}/>
            <FormattedMessage id="UserListSyncFromGroupFlyoutGroupNotFoundLink" defaultMessage="<Link>What if I don’t see the group I need for my lab?</Link>" description="Information for users who cannot find their group. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlabs-groupnotfound" target="_blank" styles={{ root: { paddingTop: '8px' } }}>
                            {chunks}
                        </Link>),
    }}/>
            <Stack horizontal tokens={{ childrenGap: '8px' }} styles={{ root: { paddingTop: '16px', paddingBottom: '32px' } }}>
                <Stack.Item>
                    <Icon iconName="Warning" styles={{ root: { verticalAlign: 'baseline', paddingTop: '4px' } }}/>
                </Stack.Item>
                <Stack.Item>
                    <Stack tokens={{ childrenGap: '16px' }}>
                        <Stack.Item className="ms-fontWeight-semibold">
                            <FormattedMessage id="UserListSyncFromGroupFlyoutWarningMessage" defaultMessage="Once a lab is synced from a group, a user's virtual machine will be automatically deleted when they are removed from the group." description="Warning message shown to user that virtual machines will be deleted when users are removed from group."/>
                        </Stack.Item>
                        <Stack.Item className="ms-fontWeight-semibold">
                            <FormattedMessage id="UserListSyncFromGroupFlyoutDeletedMessage" defaultMessage="If a group is deleted, existing lab users and virtual machines will be retained until the lab itself is deleted." description="Explanation for what happens when a group attached to the lab is deleted."/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
            {showConfirmDialog && (<Dialog hidden={false} onDismiss={() => setShowConfirmDialog(false)} dialogContentProps={{
        type: DialogType.normal,
        title: msg(messages.confirmDialogTitle),
    }}>
                    <FormattedMessage id="UserListSyncFromGroupFlyoutConfirmDialogMessage" defaultMessage='This lab will be permanently synced from the group "{name}". <b>Once synced, you will not be able to manually add or remove users and machines in this lab at any time.</b>' description="Confirmation dialog message presented when user attempts to sync lab to group. {name} is the name of the group being added, and should not be localised. <b> and </b> are bolding indicators, and should not be localized, but the text between should." values={{
        name: selectedGroup?.name || selectedGroup?.id,
        b: (chunks) => <span className="ms-fontWeight-semibold">{chunks}</span>,
    }}/>
                    <DialogFooter styles={{ actions: { marginTop: 30 } }}>
                        <PrimaryButton text={msg(commonMessages.continue)} onClick={onDialogSubmit}/>
                        <DefaultButton autoFocus text={msg(commonMessages.cancel)} onClick={() => setShowConfirmDialog(false)}/>
                    </DialogFooter>
                </Dialog>)}
        </Panel>);
};
const onRenderItem = (item) => {
    return (<Persona size={PersonaSize.size40} text={item.name} secondaryText={item.description} tertiaryText={item.id}/>);
};
const onRenderSelected = (item) => {
    return (<Persona size={PersonaSize.size24} text={item.name} secondaryText={item.description} tertiaryText={item.id}/>);
};
const onGetKey = (item) => {
    return item.id;
};
const onGetText = (item) => {
    return item.name || '';
};
const doesTextStartWith = (text, filterText) => {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
};
const onFilter = (items, filterText) => {
    const filter = !!filterText ? filterText : '';
    return items.filter((item) => doesTextStartWith(item.name, filter)).slice(0, 10);
};
export const GroupDropdown = (props) => {
    const { groups, searchGroups, groupSelected, disabled } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const debouncedSearch = _.debounce(searchGroups, 800);
    const onFilterChanged = (filterText) => {
        debouncedSearch(!!filterText ? filterText : '');
        groupSelected();
    };
    return (<>
            <FilteredDropdown items={groups} onGetKey={onGetKey} onGetText={onGetText} onSelected={groupSelected} onRenderItem={onRenderItem} onRenderSelected={onRenderSelected} onFilterChanged={onFilterChanged} onFilter={onFilter} maxWidth={375} label={msg(messages.dropdownLabel)} disabled={disabled}/>
        </>);
};
export default SyncFromGroupFlyout;
