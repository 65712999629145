import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    nameLabel: {
        id: 'NewLabConfigPageNameLabel',
        defaultMessage: 'Name your lab',
        description: 'Label for lab name text box on new lab flyout dialog.',
    },
    simplifiedNameLabel: {
        id: 'NewLabConfigPageSimplifiedNameLabel',
        defaultMessage: 'Name',
        description: 'Label for lab name text box on new lab flyout dialog based on simplified designs.',
    },
    namePlaceholder: {
        id: 'NewLabConfigPageNamePlaceholder',
        defaultMessage: 'Lab name (visible to students)',
        description: 'Placeholder text for lab name text box on new lab flyout dialog.',
    },
    sizeLabel: {
        id: 'NewLabConfigPageSizeLabel',
        defaultMessage: 'Which virtual machine size do you need?',
        description: 'Label for size drop-down on new lab flyout dialog.',
    },
    imageLabel: {
        id: 'NewLabConfigPageImageLabel',
        defaultMessage: 'Which virtual machine image do you want to use?',
        description: 'Label for VM image drop-down  on new lab flyout dialog.',
    },
    simplifiedImageLabel: {
        id: 'NewLabConfigPageSimplifiedImageLabel',
        defaultMessage: 'Virtual machine image',
        description: 'Label for VM image drop-down  on new lab flyout dialog based on simplified designs.',
    },
    searchImages: {
        id: 'NewLabConfigPagePickerSearchImages',
        defaultMessage: 'Search images',
        description: 'Placeholder for search text box inside VM image drop-down.',
    },
    searchImagesResult: {
        id: 'NewLabConfigPagePickerSearchImagesResult',
        defaultMessage: 'Filtered to show {numResults} images',
        description: 'Text for screen reader to announce number of search results in VM image picker.',
    },
    locationLabel: {
        id: 'NewLabConfigPageLocationLabel',
        defaultMessage: 'Which location will host your lab?',
        description: 'Label for the location dropdown on the new lab flyout dialog.',
    },
    rdpLabel: {
        id: 'NewLabConfigPageRdpLabel',
        defaultMessage: 'Enable Remote Desktop Connection',
        description: 'Label for RDP toggle on new lab flyout dialog.',
    },
    gpuLabel: {
        id: 'NewLabConfigPageGpuLabel',
        defaultMessage: 'Install GPU drivers',
        description: 'Label for GPU driver toggle on new lab flyout dialog.',
    },
    gpuDriverTooltipText: {
        id: 'GpuDriverTooltipText',
        defaultMessage: 'You have selected a GPU machine size, which requires GPU driver installation. We can automatically install drivers for the selected virtual machine image.',
        description: 'Text on tooltip for info icon next to GPU driver toggle',
    },
    customizeImageLabel: {
        id: 'NewLabConfigPageCustomizeImageLabel',
        defaultMessage: 'Customize the virtual machine image',
        description: 'Label for Customize the image toggle on new lab flyout dialog.',
    },
    customizeImageDescription: {
        id: 'NewLabConfigPageCustomizeImageDescription',
        defaultMessage: 'Enabling this setting will create a template virtual machine from your chosen image. You can use it to install additional software and tools. It will add up to 20 minutes to the lab creation process. If the image you chose already includes everything you need, disable this setting to speed up the lab creation process.',
        description: 'Text on tooltip for info icon next to Customize the image toggle.',
    },
    customizeImageNoSharedPasswordDescription: {
        id: 'NewLabCredentialsPageCustomizeImageDescription',
        defaultMessage: 'This option is not available when you choose not to customize the virtual machine image.',
        description: 'Text on tooltip for info icon next to Use shared password toggle.',
    },
    usernameLabel: {
        id: 'NewLabCredentialsPageUsernamelabel',
        defaultMessage: 'Username',
        description: 'Label for username text box on new lab flyout dialog.',
    },
    usernameAdminLabel: {
        id: 'NewLabCredentialsPageUsernameAdminlabel',
        defaultMessage: 'Admin username',
        description: 'Label for username text box on new lab flyout dialog if non-admin is enabled.',
    },
    usernameNonAdminLabel: {
        id: 'NewLabCredentialsPageUsernameNonAdminlabel',
        defaultMessage: 'Username (non-admin)',
        description: 'Label for non-admin username text box on new lab flyout dialog.',
    },
    usernamePlaceholder: {
        id: 'NewLabCredentialsPageUsernamePlaceholder',
        defaultMessage: 'Default username',
        description: 'Placeholder for username text box on new lab flyout dialog.',
    },
    usernameSpecializedPlaceholder: {
        id: 'NewLabCredentialsPageUsernameSpecializedPlaceholder',
        defaultMessage: 'Username ',
        description: 'Placeholder for username text box on new lab flyout dialog when using a specialized image.',
    },
    usernameSpecializedAdminPlaceholder: {
        id: 'UsernameSpecializedAdminPlaceholder',
        defaultMessage: 'Admin username ',
        description: 'Placeholder for username text box on new lab flyout dialog when using a specialized image and non-admin is enabled.',
    },
    passwordLabel: {
        id: 'NewLabCredentialsPagePasswordLabel',
        defaultMessage: 'Password',
        description: 'Label for password text box on new lab flyout dialog.',
    },
    passwordAdminLabel: {
        id: 'NewLabCredentialsPagePasswordAdminLabel',
        defaultMessage: 'Admin password',
        description: 'Label for password text box on new lab flyout dialog, if non-admin is enabled.',
    },
    passwordNonAdminLabel: {
        id: 'NewLabCredentialsPagePasswordNonAdminLabel',
        defaultMessage: 'Password (non-admin)',
        description: 'Label for non-admin password text box on new lab flyout dialog.',
    },
    passwordPlaceholder: {
        id: 'NewLabCredentialsPagePasswordPlaceholder',
        defaultMessage: 'Default password',
        description: 'Placeholder for password text box on new lab flyout dialog.',
    },
    passwordSpecializedPlaceholder: {
        id: 'NewLabCredentialsPagePasswordSpecializedPlaceholder',
        defaultMessage: 'New password',
        description: 'Placeholder for password text box on new lab flyout dialog when using a specialized image.',
    },
    passwordSpecializedAdminPlaceholder: {
        id: 'NewLabCredentialsPagePasswordSpecializedAdminPlaceholder',
        defaultMessage: 'New admin password',
        description: 'Placeholder for password text box on new lab flyout dialog for a specialized image when a non-admin is enabled.',
    },
    useSharedPasswordEnabledMessage: {
        id: 'UseSharedPasswordEnabledMessage',
        defaultMessage: 'If this setting is disabled, each student will be prompted for a new password at first logon.',
        description: 'Text for use shared password enabled check box.',
    },
    useSharedPasswordCheckboxLabel: {
        id: 'useSharedPasswordCheckboxLabel',
        defaultMessage: 'Use same password for all virtual machines',
        description: 'Text the checkbox option for use shared password.',
    },
    title1: {
        id: 'NewLabFlyoutTitle1',
        defaultMessage: 'New lab',
        description: 'Title of the first page of flyout for creating a new lab.',
    },
    title2: {
        id: 'NewLabFlyoutTitle2',
        defaultMessage: 'Virtual machine credentials',
        description: 'Title of second page of flyout for creating a new lab.',
    },
    title3: {
        id: 'NewLabFlyoutTitle3',
        defaultMessage: 'Lab policies',
        description: 'Title of third page of flyout for creating a new lab.',
    },
    title4: {
        id: 'NewLabFlyoutTitle4',
        defaultMessage: 'Template virtual machine settings',
        description: 'Title of fourth page of flyout for creating a new lab.',
    },
    next: {
        id: 'NewLabFlyoutNextButton',
        defaultMessage: 'Next',
        description: 'Text of a button to switch to next flyout page.',
    },
    back: {
        id: 'NewLabFlyoutBackButton',
        defaultMessage: 'Back',
        description: 'Text of a button to switch to previous flyout page.',
    },
    finish: {
        id: 'NewLabFlyoutFinishButton',
        defaultMessage: 'Finish',
        description: 'Text of a button to finalize new lab creation.',
    },
    quotaLabel: {
        id: 'NewLabPoliciesPageQuotaLabel',
        defaultMessage: 'Lab hours available to each user outside of scheduled events',
        description: 'Label for quota text box on new lab flyout dialog.',
    },
    newLabConfigPageIntro: {
        id: 'NewLabConfigPageIntro',
        defaultMessage: 'A template virtual machine will be created for the lab from the choices you make here. Once the template is published, each user will get a virtual machine that is a copy of the template.',
        description: 'Message at the top of new lab flyout dialog.',
    },
    newLabConfigPageModifiedIntro: {
        id: 'NewLabConfigPageModifiedIntro',
        defaultMessage: 'The settings here will be used for all virtual machines in this lab. <Link>Learn more</Link>',
        description: 'Message at the top of new lab flyout dialog for vnext.',
    },
    newLabConfigPageGpuOptOutText: {
        id: 'NewLabConfigPageGpuOptOutText',
        defaultMessage: 'When this setting is disabled, GPU drivers will need to be manually installed in order to use GPU compute functionality.',
        description: 'Message when GPU toggle is off.',
    },
    newLabConfigPageTotalPriceFormat: {
        id: 'NewLabConfigPageTotalPriceFormat',
        defaultMessage: 'Total price: {price} per hour',
        description: 'Text on new lab flyout dialog. {price} is amount of US dollars',
    },
    newLabConfigPagePricePerVmFormat: {
        id: 'NewLabConfigPagePricePerVmFormat',
        defaultMessage: 'Price per virtual machine: {price}/hour',
        description: 'Text on new lab flyout dialog for VM-level price. {price} is the cost with unit',
    },
    newLabCredentialsPageIntro: {
        id: 'NewLabCredentialsPageIntro',
        defaultMessage: 'Set login credentials for the template virtual machine.',
        description: 'Message at the top of new lab flyout dialog.',
    },
    newLabCredentialsPageIntroVnextGeneralized: {
        id: 'newLabCredentialsPageIntroVnextGeneralized',
        defaultMessage: 'Set the default login credentials for all virtual machines in the lab. Passwords must include 3 of the following: a number, uppercase character, lowercase character, and a special character.',
        description: 'Message at the top of new lab flyout dialog for vnext when a generalized image is selected.',
    },
    newLabCredentialsPageIntroVnextSpecialized: {
        id: 'newLabCredentialsPageIntroVnextSpecialized',
        defaultMessage: 'Enter the username for the virtual machine image and a new password. Passwords must include 3 of the following: a number, uppercase character, lowercase character, and a special character.',
        description: 'Message at the top of new lab flyout dialog for vnext when a specialized image is selected.',
    },
    newLabCredentialsPagePasswordDescription: {
        id: 'NewLabCredentialsPagePasswordDescription',
        defaultMessage: 'Passwords must include 3 of the following: a number, uppercase character, lowercase character, or a special character.',
        description: 'Text on the new lab flyout dialog.',
    },
    newLabCredentialsPageNonAdminOption: {
        id: 'newLabCredentialsPageNonAdminOption',
        defaultMessage: 'Give lab users a non-admin account on their virtual machines',
        description: 'Option header for the non-admin users in the new lab flyout dialog.',
    },
    newLabCredentialsPageNonAdminOptionDescription: {
        id: 'newLabCredentialsPageNonAdminOptionDescription',
        defaultMessage: 'Non-administrator accounts prevent the user from installing software or changing the configuration.',
        description: 'Option description for the non-admin users in the new lab flyout dialog.',
    },
    newLabCredentialsPageNonAdminOptionBottomDescription: {
        id: 'newLabCredentialsPageNonAdminOptionBottomDescription',
        defaultMessage: 'Each lab user will use the non-admin password.',
        description: 'Option description for the non-admin users in the new lab flyout dialog at the bottom after the password field.',
    },
    newLabFlyoutProgressFormat: {
        id: 'NewLabFlyoutProgressFormat',
        defaultMessage: 'Step {stepNumber} of {maxStepNumber}',
        description: 'Indicator of progress within new lab flyout. {stepNumber} can be between 1 and {maxStepNumber}.',
    },
    newLabFlyoutLoadError: {
        id: 'NewLabFlyoutLoadError',
        defaultMessage: 'Cannot create new lab',
        description: 'Error shown on new lab flyout when it fails to load necessary data from the server.',
    },
    newLabFlyoutLoadErrorDetails: {
        id: 'NewLabFlyoutLoadErrorDetails',
        defaultMessage: 'Your container does not appear to be configured to create new labs at this time. Please try again later or contact your Lab Services administator for support.',
        description: 'Error shown on new lab flyout when it fails to load necessary data from the server.',
    },
    newLabFlyoutImagesError: {
        id: 'NewLabFlyoutImagesError',
        defaultMessage: 'No images available',
        description: 'Error shown on new lab flyout when it fails to find any base VM images to create a new lab.',
    },
    newLabFlyoutImagesErrorDetails: {
        id: 'NewLabFlyoutImagesErrorDetails',
        defaultMessage: 'There are no VM images enabled. Please use Azure portal to enable some images or contact your Lab Services administator for support.',
        description: 'Error shown on new lab flyout when it fails to find any base VM images to create a new lab.',
    },
    newLabFlyoutCapacityErrorDetails: {
        id: 'NewLabFlyoutCapacityErrorDetails',
        defaultMessage: 'Lab creation is currently constrained. Please use the Azure Portal to adjust settings, or contact your Lab Services administator for support.',
        description: 'Error shown on new lab flyout when it fails to find any VM capacity to create a new lab.',
    },
    newLabFlyoutDiscardTitle: {
        id: 'NewLabFlyoutDiscardTitle',
        defaultMessage: 'Discard lab',
        description: 'Title of the dialog shown when user dismisses New Lab flyout without saving changes.',
    },
    newLabFlyoutDiscardMessage: {
        id: 'NewLabFlyoutDiscardMessage',
        defaultMessage: 'Are you sure you want to discard this lab?',
        description: 'Message on the dialog shown when user dismisses New Lab flyout without saving changes.',
    },
    newLabPoliciesPageTimeEstimate: {
        id: 'NewLabPoliciesPageTimeEstimate',
        defaultMessage: '20 minutes',
        description: 'Bolded text that will be composed into NewLabPoliciesPageTimeMessage',
    },
    newLabPoliciesPageIntro: {
        id: 'NewLabPoliciesPageIntro',
        defaultMessage: 'Policy settings can always be changed after the lab is created.',
        description: 'Message at the top of new lab flyout dialog.',
    },
    newLabPoliciesPageTimeMessage: {
        id: 'NewLabPoliciesPageTimeMessage',
        defaultMessage: 'Lab creation will take up to {fillInboldedMessage}.',
        description: "Message at the top of new lab flyout dialog. {fillInboldedMessage} is the bolded text '20 minutes'.",
    },
    vmSizePickerFormat: {
        id: 'VmSizePickerFormat',
        defaultMessage: '{numberOfCores} cores, {memorySize}GB RAM',
        description: 'Virtual machine size specification. {numberOfCores} is the number of CPU cores, {memorySize} is the number of GB of memory.',
    },
    templateVmSettingsPageMainMessage: {
        id: 'TemplateVmSettingsPageMainMessage',
        defaultMessage: 'Creating a template virtual machine enables you to customize the content of the virtual machines in the lab (by installing additional software, adjusting settings, or adding files). This will add 5-15 minutes to the lab creation time.',
        description: 'Message at the top of new lab flyout dialog.',
    },
    createTemplateVmOption: {
        id: 'CreateTemplateVmOption',
        defaultMessage: 'Create a template virtual machine',
        description: 'The create template VM option in the VM customization page.',
    },
    useVmImageWithoutCustomization: {
        id: 'UseVmImageWithoutCustomization',
        defaultMessage: 'Use virtual machine image without customization',
        description: 'The use VM image without customization option in the VM customization page.',
    },
    templateVmSettingsMaxVmTitle: {
        id: 'TemplateVmSettingsMaxVmTitle',
        defaultMessage: 'Maximum number of virtual machines in the lab',
        description: 'Title for the max number of virtual machines in the lab.',
    },
    customTemplateVmPageTimeEstimate: {
        id: 'CustomTemplateVmPageTimeEstimate',
        defaultMessage: '15 minutes',
        description: 'Bolded text that will be composed into TemplateVmSettingsPageTimeMessage',
    },
    noTemplateVmPageTimeEstimate: {
        id: 'NoTemplateVmPageTimeEstimate',
        defaultMessage: '5 minutes',
        description: 'Bolded text that will be composed into TemplateVmSettingsPageTimeMessage',
    },
    sizesAndGeoNotAvailableCreateLabLinkText: {
        id: 'SizesAndGeoNotAvailableCreateLabLinkText',
        defaultMessage: 'Why are some sizes and/or regions not available?',
        description: 'Text for a link to a document that explains why sizes or regions may be unavailable.',
    },
    searchSkus: {
        id: 'NewLabConfigPagePickerSearchSkus',
        defaultMessage: 'Search sizes',
        description: 'Placeholder for search text box inside VM image drop-down.',
    },
    newLabFlyoutLabPlanRegions: {
        id: 'NewLabFlyoutLabPlanRegionsLinkText',
        defaultMessage: 'Learn more',
        description: 'Text for a link to a document that explains how to enable regions for a lab plan.'
    },
    newLabFlyoutTechnicalSupportRequest: {
        id: 'NewLabFlyoutTechnicalSupportRequestLinkText',
        defaultMessage: 'file a support request',
        description: 'Link to submit a general technical support request for the subscription.'
    },
    showUnavailableSimplified: {
        id: 'VmImagePickerSimplifiedShowUnavailable',
        defaultMessage: 'Show unavailable images',
        description: 'Label for a checkbox to show / hide unavailable images.',
    },
    linuxDistributionsInfo: {
        id: 'LinuxDistributionsInfoText',
        defaultMessage: 'Some Linux distributions don\'t support Idle auto-shutdown.  To see which distributions support it, please see <Link>documentation</Link>.',
        description: 'Message at the bottom of new lab flyout dialog for vnext that warns about Linux distrubutions.',
    },
});
export default messages;
