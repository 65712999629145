import * as React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl, defineMessages } from 'react-intl';
import { commonMessages } from '../../language/common-messages';
const messages = defineMessages({
    pageTitleWithSubtitle: {
        id: 'PageTitleWithSubtitle',
        defaultMessage: '{subtitle} - Azure Lab Services',
        description: 'The page title to display when we have a subtitle. The value of {subtitle} is the subtitle.',
    },
});
class PageTitleInjected extends React.Component {
    render() {
        const { subtitle } = this.props;
        const titleMessage = subtitle
            ? this.props.intl.formatMessage(messages.pageTitleWithSubtitle, {
                subtitle,
            })
            : this.props.intl.formatMessage(commonMessages.azureLabServices);
        return (<Helmet>
                <title>{titleMessage}</title>
            </Helmet>);
    }
}
export const PageTitle = injectIntl(PageTitleInjected);
export default PageTitle;
