import { ChoiceGroup, DefaultButton, Link, Panel, PanelType, Stack, TextField, Icon, Spinner, SpinnerSize, Text, } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FileUploadButton } from '../common/file-upload-button-flyout';
import { FileUploadLink } from '../common/file-upload-link';
import { SubmitButton } from '../common/submit-button';
import { getCustomTheme } from '../common/themes/selectors';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import Constants from '../utils/constants';
import { isValidEmail, splitEmailList, validateEmailList } from '../utils/emails';
import { messages } from './add-users-flyout-messages';
import './add-users-flyout.css';
export const AddUsersFlyout = (props) => {
    const { isSubmitting, onDismiss, onSubmit } = props;
    const intl = useIntl();
    const [emails, setEmails] = React.useState([]);
    const [isValid, setIsValid] = React.useState(false);
    const [selectedChoice, setSelectedChoice] = React.useState('email');
    const [emailAddresses, setEmailAddresses] = React.useState();
    const [hasEmptyCsvFileError, setHasEmptyCsvFileError] = React.useState(false);
    const [csvFileName, setCsvFileName] = React.useState();
    const [csvFileUploaded, setCsvFileUploaded] = React.useState(false);
    const [csvFileUploading, setCsvFileUploading] = React.useState(false);
    const _onCsvFileUpload = (event) => {
        const reader = new FileReader();
        reader.onerror = () => {
            // TODO: report error
            setCsvFileUploading(false);
        };
        reader.onloadend = (e) => {
            const emails = e.target.result
                .split(/\n|\r\n/)
                .map((line) => line
                .split(/\t|,/)
                .map((o) => o.trim())
                .find((o) => isValidEmail(o)))
                .filter((o) => !!o);
            if (emails.length > 0) {
                setEmailAddresses(emails.join('\n'));
                setEmails(splitEmailList(emails.join('\n')));
                setHasEmptyCsvFileError(false);
                setCsvFileUploaded(true);
            }
            else {
                setHasEmptyCsvFileError(true);
                setCsvFileUploaded(false);
            }
            setCsvFileUploading(false);
        };
        setCsvFileName(!!event.target.files ? event.target.files[0].name : '');
        setCsvFileUploading(true);
        reader.readAsText(event.target.files[0]);
        event.target.value = '';
    };
    // close the flyout after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    const msg = intl.formatMessage;
    const addTypes = [
        { key: 'email', text: msg(messages.addByEmailCommand) },
        { key: 'csv', text: msg(messages.uploadCsvCommand) },
    ];
    return (<Panel type={PanelType.custom} customWidth={`${Constants.DefaultFlyoutWidth}px`} isOpen={true} isLightDismiss={!isSubmitting} onDismiss={isSubmitting ? undefined : onDismiss} headerText={msg(messages.title)} closeButtonAriaLabel={msg(commonMessages.cancel)} styles={getCustomTheme().panelStyles} onRenderFooterContent={() => (<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '8px' }}>
                    <Stack.Item>
                        <SubmitButton default={true} disabled={!isValid || hasEmptyCsvFileError} buttonText={msg(commonMessages.add)} onClick={() => onSubmit(emails)} isSubmitting={isSubmitting}/>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton disabled={isSubmitting} onClick={onDismiss}>
                            {msg(commonMessages.cancel)}
                        </DefaultButton>
                    </Stack.Item>
                </Stack>)}>
            {!hasEmptyCsvFileError && (<ChoiceGroup selectedKey={selectedChoice} options={addTypes} onChange={(event, option) => setSelectedChoice(option.key)}/>)}
            <br />
            {!hasEmptyCsvFileError && selectedChoice == 'email' && (<Stack tokens={{ childrenGap: '16px' }}>
                    <Stack.Item>
                        <TextField label={msg(messages.label)} autoFocus={true} multiline={true} spellCheck={false} disabled={isSubmitting} resizable={false} rows={Constants.LargeTextFieldRows} maxLength={10000} value={emailAddresses} onChange={(event, newValue) => setEmailAddresses(newValue)} validateOnLoad={!!emailAddresses} onGetErrorMessage={(value) => {
        const result = validateEmailList(value, msg(messages.validationError));
        setIsValid(!result);
        setEmails(splitEmailList(value));
        return result || '';
    }}/>
                    </Stack.Item>
                    <Stack.Item>
                        <FormattedMessage id="UserListAddUsersFlyoutNewAddUsersManuallyWarning" defaultMessage="<b>Once you have added users manually, you will not be able to sync this lab from a group unless all users are deleted first.</b>" description="Explanation that users cannot choose the sync from group option unless users are all removed from the lab. <b> and </b> should not be localised, but the text within should." values={{
        b: (chunks) => <b>{chunks}</b>,
    }}/>
                    </Stack.Item>
                </Stack>)}
            {!hasEmptyCsvFileError && selectedChoice == 'csv' && (<Stack tokens={{ childrenGap: '16px' }}>
                    <Stack.Item>
                        <FormattedMessage id="UserListAddUsersFlyoutNewUploadCsvMessage" defaultMessage="Upload a CSV file with the email address of each user to add to your lab. <b>Once you have added users manually, you will not be able to sync this lab from a group unless all users are deleted first.</b>" description="Explanation that users cannot choose the sync from group option unless users are all removed from the lab. <b> and </b> should not be localised, but the text within should." values={{
        b: (chunks) => <b>{chunks}</b>,
    }}/>
                    </Stack.Item>
                    <Stack.Item>
                        <FormattedMessage id="UserListAddUsersFlyoutNewLearnMoreCsv" defaultMessage="The first email address on each line will be imported, and you will be able to review the list before saving. CSV files can be exported from Excel or created in a text editor. <Link>Click here</Link> for more information." description="Information for the users to learn more when uploaded CSV file is empty. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlab-csv" target="_blank">
                                        {chunks}
                                    </Link>),
    }}/>
                    </Stack.Item>
                    <Stack.Item styles={{ root: { paddingTop: '16px' } }}>
                        <FileUploadButton labelText={msg(messages.chooseFileCommand)} accept=".csv" onChange={_onCsvFileUpload} styles={{ root: { marginTop: '16px', marginBottom: '16px' } }}/>
                    </Stack.Item>
                    {csvFileUploading && (<Stack.Item>
                            <FormattedMessage id="UserListAddUsersFlyoutNewUploadCsvInProgress" defaultMessage="{spinner} Uploading {filename}..." description="Message shown on during file upload. {filename} is the uploaded filename and {spinner} is the progress spinner, and neither of these should be localised." values={{
        spinner: <Spinner size={SpinnerSize.small}/>,
        filename: csvFileName,
    }}/>
                        </Stack.Item>)}
                    {csvFileUploaded && (<Stack.Item>
                            <FormattedMessage id="UserListAddUsersFlyoutNewUploadCsvSuccess" defaultMessage="{checkmark} {filename} uploaded successfully, {count} user(s) imported." description="Message shown on file upload success. {checkmark} is the checkmark icon, {filename} is the uploaded filename and {count} is the number of users, and none of these should be localised." values={{
        checkmark: (<Icon iconName="CheckMark" styles={{ root: { color: SharedColors.green10 } }}/>),
        filename: (<Text block nowrap>
                                            {csvFileName}
                                        </Text>),
        count: emails.length,
    }}/>
                        </Stack.Item>)}
                    {csvFileUploaded && (<Stack.Item>
                            <TextField autoFocus={true} multiline={true} spellCheck={false} disabled={isSubmitting} resizable={false} rows={Constants.LargeTextFieldRows} maxLength={10000} value={emailAddresses} readOnly={true} onGetErrorMessage={(value) => {
        const result = validateEmailList(value, msg(messages.validationError));
        setIsValid(!result);
        setEmails(splitEmailList(value));
        return result || '';
    }}/>
                        </Stack.Item>)}
                </Stack>)}
            {hasEmptyCsvFileError && (<>
                    <div className="add-user__empty-file-error">
                        <FormattedMessage id="UserListAddUsersFlyoutNewEmptyCsvFileError" defaultMessage="The file you selected does not contain any valid email addresses. Please ensure the file is formatted correctly, or <FileUploadLink>choose another file</FileUploadLink>." description="Error shown to the user when uploaded CSV file is empty. <FileUploadLink> and </FileUploadLink> should not be localized, but text between them should." values={{
        FileUploadLink: (chunks) => (<FileUploadLink labelText={chunks} accept=".csv" onChange={_onCsvFileUpload}/>),
    }}/>
                    </div>
                    <div>
                        <FormattedMessage id="UserListAddUsersFlyoutNewLearnMoreCsvInformation" defaultMessage="The first email address on each line will be imported. <Link>Click here</Link> for more information on CSV files." description="Information for the users to learn more when uploaded CSV file is empty. <Link> and </Link> should not be localized, but text between them should." values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlab-csv" target="_blank">
                                        {chunks}
                                    </Link>),
    }}/>
                    </div>
                </>)}
        </Panel>);
};
export default AddUsersFlyout;
