import { PriceActionType, } from './price-actions';
export const listPriceData = (subscriptionId, regions) => ({
    type: PriceActionType.LIST_PRICE_DATA,
    subscriptionId,
    regions,
});
export const listPriceDataSuccess = (data) => ({
    type: PriceActionType.LIST_PRICE_DATA_SUCCESS,
    data,
});
export const listPriceDataError = (error) => ({
    type: PriceActionType.LIST_PRICE_DATA_ERROR,
    error,
});
export const listPriceDataCancelled = () => ({
    type: PriceActionType.LIST_PRICE_DATA_CANCELLED,
});
const actions = {
    listPriceData,
    listPriceDataSuccess,
    listPriceDataError,
    listPriceDataCancelled,
};
export default actions;
