import * as React from 'react';
import { CommandButton, TooltipHost } from '@fluentui/react';
export class CommandButtonToolTip extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { disabled, content, directionalHint, labelText } = this.props;
        return (<TooltipHost content={disabled ? undefined : content} directionalHint={directionalHint}>
                <CommandButton {...this.props}>{!this.props.children ? labelText : this.props.children}</CommandButton>
            </TooltipHost>);
    }
}
export default CommandButtonToolTip;
