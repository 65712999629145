import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    showUnavailable: {
        id: 'VmGeographyPickerShowUnavailable',
        defaultMessage: 'Show unavailable locations for this size',
        description: 'Label for a checkbox to show / hide unavailable geographies for the selected size.',
    },
    showUnavailableSimplified: {
        id: 'VmGeographyPickerSimplifiedShowUnavailable',
        defaultMessage: 'Show unavailable locations',
        description: 'Label for a checkbox to show / hide unavailable geographies for the selected size and image.',
    },
    placeholder: {
        id: 'VmGeographyPickerPlaceholderText',
        defaultMessage: 'Please select a location',
        description: 'Placeholder text for the location picker when nothing is selected.',
    },
    simplifiedLocationLabel: {
        id: 'NewLabConfigPageSimplifiedLocationLabel',
        defaultMessage: 'Location',
        description: 'Label for the location dropdown on the new lab flyout dialog based on simplified designs.',
    },
});
