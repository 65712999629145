import { VmState } from '../../../models/environment-common';
import { createVNextArmApiClient } from '../../arm-data-provider';
import { OperationType } from '../../../models/vnext/operation';
import { Urls } from '../../../../utils/constants';
import Settings from '../../../../settings/settings';
import { getAcceptLanguage } from '../../../../language/languages';
import { get as armGet } from '../../arm-data-provider';
import { RestError } from 'ms-rest-js';
export async function listVirtualMachines(labId, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(labId.subscriptionId, accessToken, locale, language);
    let currentPage = await armApiClient.virtualMachines.listByLab(labId.resourceGroupName, labId.name);
    let response = currentPage;
    while (!!currentPage.nextLink) {
        const nextPage = await armApiClient.virtualMachines.listByLabNext(currentPage.nextLink);
        currentPage = nextPage;
        response = response.concat(nextPage);
    }
    const virtualMachines = [];
    response.map((vm, index) => {
        virtualMachines[index] = { ...vm, pendingVmState: VmState.None };
    });
    return virtualMachines;
}
export async function reimageVirtualMachine(virtualMachineId, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(virtualMachineId.subscriptionId, accessToken, locale, language);
    const reimageVmResult = await armApiClient.virtualMachines.beginReimage(virtualMachineId.resourceGroupName, virtualMachineId.parent.name, virtualMachineId.name);
    const asyncOperationUrl = reimageVmResult?.getInitialResponse().headers?.get('azure-asyncoperation');
    return {
        ...reimageVmResult?.getInitialResponse().parsedBody,
        operationType: OperationType.ReimageOperation,
        asyncPollOperationUrl: asyncOperationUrl,
    };
}
export async function startVirtualMachine(virtualMachineId, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(virtualMachineId.subscriptionId, accessToken, locale, language);
    const startVmResult = await armApiClient.virtualMachines.beginStart(virtualMachineId.resourceGroupName, virtualMachineId.parent.name, virtualMachineId.name);
    const asyncOperationUrl = startVmResult?.getInitialResponse().headers?.get('azure-asyncoperation');
    return {
        ...startVmResult?.getInitialResponse().parsedBody,
        operationType: OperationType.StartOperation,
        asyncPollOperationUrl: asyncOperationUrl,
    };
}
export async function stopVirtualMachine(virtualMachineId, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(virtualMachineId.subscriptionId, accessToken, locale, language);
    const stopVmResult = await armApiClient.virtualMachines.beginStop(virtualMachineId.resourceGroupName, virtualMachineId.parent.name, virtualMachineId.name);
    const asyncOperationUrl = stopVmResult?.getInitialResponse().headers?.get('azure-asyncoperation');
    return {
        ...stopVmResult?.getInitialResponse().parsedBody,
        operationType: OperationType.StopOperation,
        asyncPollOperationUrl: asyncOperationUrl,
    };
}
export async function getVirtualMachine(virtualMachineId, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(virtualMachineId.subscriptionId, accessToken, locale, language);
    const response = await armApiClient.virtualMachines.get(virtualMachineId.resourceGroupName, virtualMachineId.parent.name, virtualMachineId.name);
    const virtualMachine = { ...response, pendingVmState: VmState.None };
    return virtualMachine;
}
export async function resetPassword(virtualMachineId, accessToken, locale, language, username, password) {
    const armApiClient = createVNextArmApiClient(virtualMachineId.subscriptionId, accessToken, locale, language);
    const body = {
        username,
        password,
    };
    await armApiClient.virtualMachines.beginResetPassword(body, virtualMachineId.resourceGroupName, virtualMachineId.parent.name, virtualMachineId.name);
}
export async function redeployVirtualMachine(virtualMachineId, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(virtualMachineId.subscriptionId, accessToken, locale, language);
    const redeployVmResult = await armApiClient.virtualMachines.beginRedeploy(virtualMachineId.resourceGroupName, virtualMachineId.parent.name, virtualMachineId.name);
    const asyncOperationUrl = redeployVmResult?.getInitialResponse().headers?.get('azure-asyncoperation');
    return {
        ...redeployVmResult?.getInitialResponse().parsedBody,
        operationType: OperationType.RedeployOperation,
        asyncPollOperationUrl: asyncOperationUrl,
    };
}
export const get = async (baseUri, relativeUrl, accessToken, locale, language) => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    headers.append('Accept-Language', getAcceptLanguage(locale, language));
    const url = `${baseUri}${relativeUrl}`;
    console.log(url);
    const response = await fetch(url, {
        headers: headers,
    });
    if (response.status >= 400) {
        const errorResponse = (await response.json());
        const { Error } = errorResponse;
        throw new RestError(Error?.Message || 'A request error occurred.', Error?.Code || response.statusText);
    }
    return response;
};
export async function getOperationResultARM(operation, accessToken, locale, language) {
    const url = new URL(operation.asyncPollOperationUrl);
    const relativeUrl = url.pathname + url.search;
    const resultPromise = await armGet(relativeUrl, accessToken, locale, language);
    const result = await resultPromise.json();
    const operationResult = {
        ...operation,
        status: result.status,
        endTime: result.endTime,
    };
    return operationResult;
}
export async function getOperationResultLabsApi(operation, accessToken, locale, language) {
    const relativeUrl = operation?.id + Urls.OperationsResultLabsApi;
    const regex = /\/locations\/(\w+)\//;
    const match = operation?.id.match(regex);
    let operationRegion = '';
    if (match && match.length > 0) {
        operationRegion = match[1];
    }
    const baseUri = Settings.LabsApiUrl + '/' + operationRegion + '/api';
    const resultPromise = await get(baseUri, relativeUrl, accessToken, locale, language);
    const result = await resultPromise.json();
    const operationResult = {
        ...operation,
        status: result.status,
        endTime: result.endTime,
    };
    return operationResult;
}
const VirtualMachineProvider = {
    listVirtualMachines,
    reimageVirtualMachine,
    startVirtualMachine,
    stopVirtualMachine,
    getVirtualMachine,
    resetPassword,
    redeployVirtualMachine,
    getOperationResultLabsApi,
    getOperationResultARM,
};
export default VirtualMachineProvider;
