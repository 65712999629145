import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { MlClientError, FailureOperation } from '../../../data/ml-client-error';
import { ResourceId } from '../../../utils/resource-id';
import { redeployVirtualMachinesAccepted, redeployVirtualMachinesError, } from '../../actions/environment/environment-action-creators';
import { EnvironmentActionType } from '../../actions/environment/environment-actions';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import VirtualMachineProvider from '../../../data/providers/vnext/arm-api/virtual-machine-provider';
import { GetOperationsResult } from '../../actions/operations/operations-result-action-creators';
export async function callRedeployVNextVirtualMachine(resourceId, accessToken, locale, language, errors) {
    try {
        const redeployVmResult = await VirtualMachineProvider.redeployVirtualMachine(resourceId, accessToken, locale, language);
        redeployVmResult['ResourceId'] = resourceId;
        return redeployVmResult;
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.RedeployVirtualMachine, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* redeployVNextVirtualMachines(action) {
    const { environmentIds: virtualMachineIds } = action;
    try {
        const resourceIds = virtualMachineIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        const redeployVmsResult = yield all(resourceIds.map((resourceId) => call(callRedeployVNextVirtualMachine, resourceId, accessToken, locale, language, errors)));
        if (Object.keys(errors).length > 0) {
            yield put(redeployVirtualMachinesError(errors));
        }
        yield put(redeployVirtualMachinesAccepted(redeployVmsResult));
        yield put(GetOperationsResult(''));
    }
    catch (e) {
        const errors = {};
        const error = new MlClientError(e ? e.message : undefined, virtualMachineIds.length === 1 ? virtualMachineIds[0] : 'all', FailureOperation.RedeployVirtualMachine, e ? e.code : undefined);
        errors[error.id] = error;
        yield put(redeployVirtualMachinesError(errors));
    }
}
export function* redeployVirtualMachinesSaga() {
    yield takeEvery(EnvironmentActionType.REDEPLOY_VIRTUAL_MACHINE, redeployVNextVirtualMachines);
}
