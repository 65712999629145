import { Record } from 'immutable';
import { RegistrationState } from '../../../data/models/register-user';
import { RegisterActionType, } from '../../actions/register/register-actions';
import { Context } from '../../action-context';
function registerUser(state, action) {
    return state.merge({
        registrationState: RegistrationState.Registering,
    });
}
function registerUserSuccess(state, action) {
    return state.merge({
        registrationState: RegistrationState.Registered,
    });
}
function registerUserFailed(state, action) {
    const { error } = action;
    return state.merge({
        registrationState: RegistrationState.Failed,
        error,
    });
}
function clearRegisterErrors(state, action) {
    return state.merge({
        registrationState: RegistrationState.None,
        error: undefined,
    });
}
function clearRegisterState(state, action) {
    return state.merge({
        registrationState: RegistrationState.None,
    });
}
const initialState = Record({
    registrationState: RegistrationState.None,
    error: undefined,
})();
export const registerReducer = (state = initialState, action) => {
    switch (action.type) {
        case RegisterActionType.CLEAR_USER_SESSION:
        case RegisterActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    switch (action.type) {
                        case RegisterActionType.REGISTER_USER:
                            return registerUser(state, action);
                        case RegisterActionType.REGISTER_USER_SUCCESS:
                            return registerUserSuccess(state, action);
                        case RegisterActionType.REGISTER_USER_FAILED:
                            return registerUserFailed(state, action);
                        case RegisterActionType.CLEAR_REGISTER_ERRORS:
                            return clearRegisterErrors(state, action);
                        case RegisterActionType.CLEAR_REGISTER_STATE:
                            return clearRegisterState(state, action);
                        default:
                            return state;
                    }
                default:
                    return state;
            }
    }
};
export default registerReducer;
