import { createVNextArmApiClient } from '../../arm-data-provider';
export async function listSkus(id, accessToken, locale, language) {
    const armApiClient = createVNextArmApiClient(id.subscriptionId, accessToken, locale, language);
    let currentPage = await armApiClient.skus.list();
    let skus = currentPage;
    while (!!currentPage.nextLink) {
        const nextPage = await armApiClient.skus.listNext(currentPage.nextLink);
        currentPage = nextPage;
        skus = skus.concat(nextPage);
    }
    return skus;
}
const SkuProvider = {
    listSkus,
};
export default SkuProvider;
