import * as React from 'react';
import CommandButtonToolTip from './command-button-tooltip';
export class FileUploadButton extends React.Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }
    render() {
        const { content, labelText, directionalHint, accept, styles, onChange } = this.props;
        const labelTextInputFile = (<>
                {labelText}
                <input id="upload" type="file" accept={accept} style={{ display: 'none' }} ref={this.inputRef} onChange={onChange}/>
            </>);
        return (<CommandButtonToolTip content={content} labelText={labelTextInputFile} directionalHint={directionalHint} iconProps={{
            iconName: 'Upload',
            styles: {
                root: {
                    width: '16px',
                    padding: '0 4px 0 5px',
                },
            },
        }} styles={styles} onClick={() => this.inputRef.current && this.inputRef.current.click()}/>);
    }
}
export default FileUploadButton;
