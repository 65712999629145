import { routerActions } from 'connected-react-router';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { idleConfigToAutoShutdownProfile, idleConfigToLab } from '../common/shutdown-policy/selectors';
import PageTitle from '../common/page/page-title';
import { toConnectionType, toEnableState } from '../data/helpers/enum-helpers';
import { ConnectionType } from '../data/models/environment-common';
import { LabUpdateOperation } from '../data/models/lab';
import { clearLabUpdateError, pollLabStart, pollLabStop, updateLab, updateVNextLab, } from '../redux/actions/lab/lab-action-creators';
import SettingsPage from './settings-page';
import { messages } from './settings-page-messages';
import { getSettingsPageContainerModel } from './settings-page-selectors';
import { isVNextLab } from '../redux/selectors/lab-selectors';
class SettingsPageContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._getPollingInterval = this._getPollingInterval.bind(this);
        this._startPollingLab = this._startPollingLab.bind(this);
        this._updateLab = this._updateLab.bind(this);
        this._navigateRoute = this._navigateRoute.bind(this);
    }
    componentDidMount() {
        const { isVNext } = this.props;
        if (!isVNext) {
            this._startPollingLab();
        }
    }
    componentWillUnmount() {
        const { isVNext, pollLabStop } = this.props;
        if (!isVNext) {
            pollLabStop();
        }
    }
    componentDidUpdate(prevProps) {
        const { settingsPageViewModel, isVNext } = this.props;
        const { lab } = settingsPageViewModel;
        // Reset polling if either lab or polling interval changes.
        if (!isVNext &&
            (lab?.id !== prevProps.settingsPageViewModel.lab?.id ||
                this._pollingInterval !== this._getPollingInterval())) {
            this.props.pollLabStop();
            this._startPollingLab();
        }
    }
    _getPollingInterval() {
        const { settingsPageViewModel } = this.props;
        const { isApplyingSettings, isUpdating, isCreating, isPublishing, isScaling } = settingsPageViewModel;
        if (isUpdating) {
            // Avoid polling while we're in update call.
            return undefined;
        }
        else if (isApplyingSettings) {
            // Settings update is usually fast - poll every 10 secs.
            return 10 * 1000;
        }
        else if (isCreating || isPublishing || isScaling) {
            // Publishing and scaling take long time - poll every minute.
            return 60 * 1000;
        }
        else {
            // Don't poll otherwise.
            return undefined;
        }
    }
    _startPollingLab() {
        const { settingsPageViewModel, pollLabStart } = this.props;
        const { lab } = settingsPageViewModel;
        if (lab) {
            this._pollingInterval = this._getPollingInterval();
            if (this._pollingInterval) {
                pollLabStart(lab.id, this._pollingInterval);
            }
        }
    }
    _updateLab(connectionTypes, idleConfig) {
        const { settingsPageViewModel, updateLab, updateVNextLab } = this.props;
        const { lab } = settingsPageViewModel;
        if (lab) {
            const connectivityPolicy = {
                rdpEnabled: toEnableState(connectionTypes.includes(ConnectionType.Rdp)),
                rdpInBrowserEnabled: toEnableState(connectionTypes.includes(ConnectionType.RdpInBrowser)),
                sshEnabled: toEnableState(connectionTypes.includes(ConnectionType.Ssh)),
                sshInBrowserEnabled: toEnableState(connectionTypes.includes(ConnectionType.SshInBrowser)),
            };
            if (!isVNextLab(lab.id)) {
                const idleConfigPartial = idleConfigToLab(idleConfig);
                const labUpdate = {
                    ...lab,
                    ...idleConfigPartial,
                    connectivityPolicy,
                };
                updateLab(labUpdate, LabUpdateOperation.Settings);
            }
            else {
                const autoShutdownProfile = idleConfigToAutoShutdownProfile(idleConfig);
                const connectionProfile = {
                    webSshAccess: toConnectionType(connectionTypes.includes(ConnectionType.SshInBrowser)),
                    webRdpAccess: toConnectionType(connectionTypes.includes(ConnectionType.RdpInBrowser)),
                    clientSshAccess: toConnectionType(connectionTypes.includes(ConnectionType.Ssh)),
                    clientRdpAccess: toConnectionType(connectionTypes.includes(ConnectionType.Rdp)),
                };
                const labUpdate = {
                    autoShutdownProfile,
                    connectionProfile,
                };
                updateVNextLab(lab.id, labUpdate, LabUpdateOperation.Settings);
            }
        }
    }
    _navigateRoute(pathname) {
        const { push, search } = this.props;
        push({ pathname, search });
    }
    render() {
        const { intl, clearLabUpdateError } = this.props;
        const title = intl.formatMessage(messages.settings);
        return (<>
                <PageTitle subtitle={title}/>
                <SettingsPage {...this.props} onSubmit={this._updateLab} clearUpdateError={clearLabUpdateError} navigateRoute={this._navigateRoute}/>
            </>);
    }
}
const mapDispatchToProps = {
    updateLab,
    clearLabUpdateError,
    pollLabStart,
    pollLabStop,
    push: routerActions.push,
    updateVNextLab,
};
const mapStateToProps = (state) => {
    return getSettingsPageContainerModel(state);
};
export const SettingsPageContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SettingsPageContainerInjected));
export default SettingsPageContainer;
