import { takeLatest, put, call } from 'redux-saga/effects';
import { getGroups as getGroupsFromGraph } from '../../../data/providers/group-provider';
import { getGroupsFailed, getGroupsSuccess, getGroupsError } from '../../actions/group/group-action-creators';
import { GroupActionType } from '../../actions/group/group-actions';
import { getGraphAccessTokenSaga } from '../identity/access-tokens';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
export function* getGroups(action) {
    const { filterText } = action;
    try {
        const accessToken = yield call(getGraphAccessTokenSaga);
        const groups = yield call(getGroupsFromGraph, accessToken, filterText);
        if (!groups) {
            yield put(getGroupsFailed());
        }
        else {
            yield put(getGroupsSuccess(groups));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, filterText, FailureOperation.GetGroups, err ? err.code : undefined);
        yield put(getGroupsError(error));
    }
}
export function* getGroupsSaga() {
    yield takeLatest(GroupActionType.GET_GROUPS, getGroups);
}
