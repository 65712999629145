import { Stack, TextField, Toggle, Link, Shimmer, Icon, StackItem } from '@fluentui/react';
import { SharedColors } from '@fluentui/theme';
import * as React from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import LinuxRdpDialog from '../../common/connection-type/linux-rdp-dialog';
import InfoSection from '../../common/info-section';
import InfoTip from '../../common/info-tip';
import { DefaultCurrencyCode, QuotaRequestNeededNumberOfVMs } from '../../utils/constants';
import { caseInsensitiveCultureInvariantCompare } from '../../utils/string-comparison';
import { LabConfigShimmer } from './create-lab-config-page-shared';
import { messages } from './create-lab-messages';
import { getDefaultConnectionTypes, getLocationMinCostFromImage, getSkuAndLocationMinCostFromImage, getSkuCostFromImageOS, getSkuMinCostFromImage, isSkuAvailable, isSkuLocationAvailable, } from './create-lab-selectors';
import { validateVnextLabName } from './lab-name-validator-vnext';
import { LabPlanPicker } from './lab-plan-picker';
import { getLocalizedLocationName } from './location-helpers';
import VNextVmLocationPicker from './vm-location-picker-vnext';
import { VmImagePicker } from './vm-image-picker';
import VNextVmSizePicker from './vm-size-picker-vnext';
import { getNumberOfRemainingVms, getSkuCostTargetUnit, getLocalizedSkuDisplayName, getNumberOfAvailableCores, } from '../../common/selectors/sku-selectors';
import { RequestLimitIncreaseInSentenceLink } from '../../common/request-limit-increase-link';
import { areConnectionTypesValid } from '../../common/connection-type/validators';
export const VNextNewLabConfigPage = (props) => {
    const { name, image, sku, location, skus, images, isRdpEnabled, installGpuDriverEnabled, customizeTemplate, addImagesUrl, isLoadingOnSelectLabPlan, labPlans, selectedLabPlan, onSizeChange, onRdpEnabledChange, onLabPlanChange, onImageChange, onConnectionTypesValidation, hasSelectLabPlanLoadError, currentTenantId, labParentId, isV2BastionEnabled, } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const [linuxRdpDialogAcknowledged, setLinuxRdpDialogAcknowledged] = React.useState(false);
    const [showLinuxRdpDialog, setShowLinuxRdpDialog] = React.useState(false);
    const [locationBeforeSizeChange, setLocationBeforeSizeChange] = React.useState('');
    const [sizeBeforeLocationChange, setSizeBeforeLocationChange] = React.useState('');
    const availableCores = !location ? 0 : getNumberOfAvailableCores(location);
    const numberOfVms = !sku || !location ? 0 : getNumberOfRemainingVms(sku, location);
    const resetSizeAndLocationChange = () => {
        setLocationBeforeSizeChange('');
        setSizeBeforeLocationChange('');
    };
    const handleSizeChange = (value) => {
        if (!!sku) {
            if (caseInsensitiveCultureInvariantCompare(value.name, sku.name) === 0) {
                return;
            }
        }
        // Reset value
        resetSizeAndLocationChange();
        // Check if currently selected location is available on newly selected SKU
        // If not, select cheapest available location and mark flag to show that we changed location
        if (!!location?.name && isSkuLocationAvailable(value, location.name, image)) {
            const skuLocation = value.locations.find((l) => caseInsensitiveCultureInvariantCompare(l.name, location?.name) === 0);
            onSizeChange(value, skuLocation);
        }
        else {
            const minLocation = getSkuMinCostFromImage(value, image);
            const locationName = getLocalizedLocationName(location?.name ?? '', intl);
            setLocationBeforeSizeChange(locationName);
            onSizeChange(value, minLocation);
        }
    };
    const handleImageChange = (value) => {
        if (caseInsensitiveCultureInvariantCompare(value.id, image.id) === 0) {
            return;
        }
        if (!skus) {
            return;
        }
        resetSizeAndLocationChange();
        const { isWindows } = value;
        const connectionTypes = getDefaultConnectionTypes(isWindows, undefined, selectedLabPlan, false, isV2BastionEnabled);
        const isValid = areConnectionTypesValid(!value.isWindows, connectionTypes, true);
        onConnectionTypesValidation(isValid);
        // Check if currently selected location is available on newly selected image
        if (!!sku && !!location?.name && isSkuLocationAvailable(sku, location.name, value)) {
            onImageChange(value, connectionTypes, sku, location);
            return;
        }
        let newSku, newLocation;
        const previousLocationName = !!location?.name ? getLocalizedLocationName(location?.name, intl) : '';
        // Check if other locations of the current sku is available
        if (!!sku && isSkuAvailable(sku, value)) {
            // Get location
            newLocation = getSkuMinCostFromImage(sku, value);
            if (!!location?.name && newLocation) {
                setLocationBeforeSizeChange(previousLocationName);
                onImageChange(value, connectionTypes, sku, newLocation);
                return;
            }
        }
        // Check if other skus of the current location is available
        const previousSkuName = !!sku ? getLocalizedSkuDisplayName(sku, intl) : '';
        const minSkuSameLoction = !!location ? getLocationMinCostFromImage(skus, value, location.name) : undefined;
        if (!!minSkuSameLoction) {
            newSku = minSkuSameLoction;
            newLocation = newSku.locations.find((o) => caseInsensitiveCultureInvariantCompare(o.name, location.name) === 0);
            setSizeBeforeLocationChange(previousSkuName);
        }
        else {
            // Find sku and location of min cost
            const minSkuAndLocation = getSkuAndLocationMinCostFromImage(skus, value);
            newSku = minSkuAndLocation?.sku;
            newLocation = minSkuAndLocation?.location;
            if (!!newSku && !!newLocation) {
                setSizeBeforeLocationChange(previousSkuName);
                setLocationBeforeSizeChange(previousLocationName);
            }
        }
        onImageChange(value, connectionTypes, newSku, newLocation);
    };
    const handleLabPlanChange = (labPlanId) => {
        resetSizeAndLocationChange();
        onLabPlanChange(labPlanId);
    };
    const pricePerVm = getSkuCostFromImageOS(location, getSkuCostTargetUnit(image.isWindows));
    return (<>
            <Stack tokens={{ childrenGap: 30 }}>
                <Stack.Item>
                    <FormattedMessage {...messages.newLabConfigPageModifiedIntro} values={{
        Link: (chunks) => (<Link href="https://aka.ms/azlabs-setup-lab" target="_blank">
                                    {chunks}
                                </Link>),
    }}/>
                </Stack.Item>
                <Stack.Item>
                    <TextField label={msg(messages.simplifiedNameLabel)} placeholder={msg(messages.namePlaceholder)} autoFocus={true} spellCheck={false} maxLength={100} value={name} validateOnLoad={props.validateNameOnLoad} onChange={(_, value) => props.onNameChange(value || '')} aria-required={true} onGetErrorMessage={(value) => {
        // trimming for validation name so we compare correctly against the list of lab
        // names that already exist
        const result = validateVnextLabName(value.trim(), props.labNames, intl);
        props.onNameValidation(!result);
        return result || '';
    }}/>
                </Stack.Item>
                {!!labPlans && labPlans.size > 1 && (<Stack.Item>
                        <LabPlanPicker labPlans={labPlans} selected={selectedLabPlan} onChange={handleLabPlanChange}/>
                    </Stack.Item>)}
                {props.hasSelectLabPlanLoadError && !!selectedLabPlan && (<Stack horizontal tokens={{ childrenGap: 10 }}>
                        <StackItem>
                            <Icon iconName="Warning" styles={{ root: { color: SharedColors.red20 } }}></Icon>
                        </StackItem>
                        <StackItem styles={{ root: { color: SharedColors.red20 } }}>
                            {!!labPlans && labPlans.size > 1 && (<FormattedMessage id="NewLabConfigPageImageSizeLocationLoadErrorMultipleLabPlans" defaultMessage="We failed to load the virtual machine images, sizes, or location information for the selected lab plan. Please <Link>try again</Link>, or select another plan." description="Message when loading images, sizes, or location information for the current lab plan failed and users have more than one lab plan." values={{
        Link: (chunks) => (
        // eslint-disable-next-line react/prop-types
        <Link onClick={() => handleLabPlanChange(selectedLabPlan.id ?? '')}>
                                                {chunks}
                                            </Link>),
    }}></FormattedMessage>)}
                            {!!labPlans && labPlans.size === 1 && (<FormattedMessage id="NewLabConfigPageImageSizeLocationLoadErrorSingleLabPlan" defaultMessage="We failed to load the virtual machine images, sizes, or location information for your lab plan. Please <Link>try again</Link>, or contact your IT administrator if the problem persists." description="Message when loading images, sizes, or location information for the current lab plan failed and users have only one available lab plan." values={{
        Link: (chunks) => (
        // eslint-disable-next-line react/prop-types
        <Link onClick={() => handleLabPlanChange(selectedLabPlan.id)}>
                                                {chunks}
                                            </Link>),
    }}></FormattedMessage>)}
                        </StackItem>
                    </Stack>)}
                <Shimmer isDataLoaded={!isLoadingOnSelectLabPlan} customElementsGroup={LabConfigShimmer} width="100%">
                    {!hasSelectLabPlanLoadError && (<Stack tokens={{ childrenGap: 30 }}>
                            <Stack.Item>
                                <VmImagePicker label={msg(messages.simplifiedImageLabel)} searchPlaceholder={msg(messages.searchImages)} options={images} skus={skus} selectedKey={image.key} addImagesUrl={addImagesUrl} onChange={handleImageChange}/>
                            </Stack.Item>
                            {props.isSkipTemplateEnabled && (<Stack.Item>
                                    <Stack horizontal styles={{ root: { marginTop: '15px !important' } }}>
                                        <Stack.Item>
                                            <Toggle label={msg(messages.customizeImageLabel)} ariaLabel={msg(messages.customizeImageLabel)} inlineLabel={true} checked={customizeTemplate} onChange={(_, value) => props.onCustomizeTemplateChange(value || false)}/>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <InfoTip content={msg(messages.customizeImageDescription)} styles={{ root: { paddingTop: '7px' } }}/>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>)}
                            <Stack.Item>
                                <Stack>
                                    {!!skus && (<Stack.Item>
                                            <VNextVmSizePicker skus={skus} selected={sku} searchPlaceholder={msg(messages.searchSkus)} vmImageInfo={image} onChange={handleSizeChange}/>
                                        </Stack.Item>)}
                                    {sku && sku.size.isGpu && (<Stack.Item>
                                            <Stack horizontal styles={{ root: { paddingTop: '15px' } }}>
                                                <Stack.Item>
                                                    <Toggle label={msg(messages.gpuLabel)} inlineLabel={true} checked={installGpuDriverEnabled} onChange={(_, value) => props.onInstallGpuDriverEnabledChange(value || false)}/>
                                                </Stack.Item>
                                                <Stack.Item styles={{ root: { paddingTop: '7px' } }}>
                                                    <InfoTip content={msg(messages.gpuDriverTooltipText)} styles={{ root: { paddingTop: '7px' } }}/>
                                                </Stack.Item>
                                            </Stack>
                                            {!installGpuDriverEnabled && (<Stack.Item>
                                                    <FormattedMessage {...messages.newLabConfigPageGpuOptOutText}/>
                                                </Stack.Item>)}
                                        </Stack.Item>)}
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack>
                                    {props.canSelectGeography && !!sku && (<Stack.Item>
                                            <VNextVmLocationPicker sku={sku} selected={location} onChange={props.onLocationChange} onChangeReset={resetSizeAndLocationChange} vmImageInfo={image}/>
                                        </Stack.Item>)}
                                    {sizeBeforeLocationChange && (<InfoSection>
                                            <FormattedMessage id="SizeChangedFromImageChange" defaultMessage="Your previously selected size ({previousSize}) is unavailable for this location, so we selected the lowest cost size available. " description="Text describing when the lab creation size has been changed to another that has availability based on the selected image." values={{ previousSize: sizeBeforeLocationChange }}/>
                                        </InfoSection>)}
                                    {locationBeforeSizeChange && (<InfoSection>
                                            <FormattedMessage id="LocationChangedFromSizeChange" defaultMessage="Your previously selected location ({previousLocation}) is unavailable for this size, so we selected the lowest cost location available. " description="Text describing when the lab creation location has been changed to a location that has availability based on the selected SKU size." values={{ previousLocation: locationBeforeSizeChange }}/>
                                        </InfoSection>)}
                                </Stack>
                            </Stack.Item>
                            {!image.isWindows && !props.isBastionEnabled && (<Stack.Item>
                                    <Toggle label={msg(messages.rdpLabel)} inlineLabel={true} checked={isRdpEnabled} onChange={(_, value) => !linuxRdpDialogAcknowledged && !isRdpEnabled
        ? setShowLinuxRdpDialog(true)
        : onRdpEnabledChange(value || false)}/>
                                </Stack.Item>)}
                            <Stack.Item>
                                <span style={{ backgroundColor: '#F3F2F1', fontWeight: 600, padding: 8 }}>
                                    <FormattedMessage {...messages.newLabConfigPagePricePerVmFormat} values={{
        price: (<FormattedNumber value={pricePerVm} style="currency" currency={DefaultCurrencyCode}/>),
    }}/>
                                </span>
                            </Stack.Item>
                            {!!sku && !!location?.usage && (<Stack.Item>
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <Stack.Item styles={{ root: { paddingTop: '2px' } }}>
                                            {numberOfVms <= 0 && (<Icon iconName="Warning" styles={{ root: { color: SharedColors.red20 } }}/>)}
                                            {numberOfVms > 0 && <InfoTip />}
                                        </Stack.Item>
                                        <Stack.Item styles={{
        root: { color: numberOfVms <= 0 ? SharedColors.red20 : undefined },
    }}>
                                            <FormattedMessage id="NewLabConfigPageAvailableVms" defaultMessage="{available} of {limit} {skuFamily} vCPUs are available in {location}. You can create {numOfVms} VM(s) of the selected size." description="Text describing how many cores are available for a given SKU family and region in a lab plan. Indicates the number of VMs that could be created based on number of cores available and size of selected VM." values={{
        available: availableCores,
        limit: location.usage.limit,
        skuFamily: sku.family,
        location: !!location?.name
            ? getLocalizedLocationName(location.name, intl)
            : '',
        numOfVms: numberOfVms,
    }}/>
                                            {numberOfVms <= 0 && (<FormattedMessage id="VNextLimitExceededMessage" defaultMessage=" Before you can create a new lab, you need to {requestLimitIncreaseLink}. You can expect the process to take 1-5 days. Due to high demand, some SKUs may be temporarily unavailable." description="Message displayed on lab creation page when remaining cores are not enough for lab creation." values={{
        requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labParentId={labParentId} tenantId={currentTenantId}/>),
    }}/>)}
                                            {numberOfVms > 0 && numberOfVms < QuotaRequestNeededNumberOfVMs && (<FormattedMessage id="VNextLimitNotEnoughMessage" defaultMessage=" If more VMs will be created in this lab, you need to {requestLimitIncreaseLink}. You can expect the process to take 1-5 days. Due to high demand, some SKUs may be temporarily unavailable." description="Message displayed on lab creation page when less than 30 VMs can be created due to quota limit." values={{
        requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labParentId={labParentId} tenantId={currentTenantId}/>),
    }}/>)}
                                        </Stack.Item>
                                    </Stack>
                                    <Stack horizontal tokens={{ childrenGap: 5 }}>
                                        <Stack.Item styles={{ root: { paddingTop: '2px' } }}>
                                            <InfoTip />
                                        </Stack.Item>
                                        <Stack.Item styles={{
        root: { color: undefined },
    }}>
                                            <FormattedMessage {...messages.linuxDistributionsInfo} values={{
        Link: (chunks) => (<Link href="https://learn.microsoft.com/en-us/azure/lab-services/how-to-configure-auto-shutdown-lab-plans#supported-linux-distributions-for-automatic-shutdown" target="_blank">
                                                            {chunks}
                                                        </Link>),
    }}/>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>)}
                        </Stack>)}
                </Shimmer>
            </Stack>
            {showLinuxRdpDialog && (<LinuxRdpDialog onSubmit={() => {
        setLinuxRdpDialogAcknowledged(true);
        onRdpEnabledChange(true);
        setShowLinuxRdpDialog(false);
    }} onDismiss={() => setShowLinuxRdpDialog(false)}/>)}
        </>);
};
export default VNextNewLabConfigPage;
