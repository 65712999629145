import { Announced, DirectionalHint, Dropdown, Icon, Link, SearchBox, Separator, Stack, TooltipHost, getTheme, Checkbox, } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from './create-lab-messages';
import { isSkuAvailable } from './create-lab-selectors';
const Footer = (props) => (<Link href={props.addImagesUrl} target="_blank" className="custom-link-button" styles={{ root: { color: getTheme().palette.themePrimary } }}>
        <Stack horizontal tokens={{ childrenGap: '5' }} verticalAlign="center" styles={{ root: { padding: 15 } }}>
            <Stack.Item>
                <Icon iconName="CirclePlus"/>
            </Stack.Item>
            <Stack.Item styles={{ root: { paddingBottom: '2px' } }}>
                <FormattedMessage id="VmImagePickerAdddImagesLink" defaultMessage="Enable more image options" description="Link text displayed in the VM image picker to add more images."/>
            </Stack.Item>
        </Stack>
    </Link>);
export const VmImagePicker = (props) => {
    const { onChange, skus, searchPlaceholder, addImagesUrl, required } = props;
    const [filter, setFilter] = React.useState('');
    const [showUnavailable, setShowUnavailable] = React.useState(false);
    const [announce, setAnnounce] = React.useState(null);
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const itemStyle = {
        paddingTop: 4,
        paddingBottom: 4,
        height: 'fit-content',
        selectors: {
            '.ms-Button-flexContainer': {
                width: '100%',
            },
        },
    };
    const getOptions = (filterStr) => {
        return props.options.filter((o) => o.text.toLowerCase().indexOf(filterStr) >= 0 ||
            (o.subTitle && o.subTitle.toLowerCase().indexOf(filterStr) >= 0)).map((o) => ({
            ...o,
            disabled: !!skus && skus.every(s => !isSkuAvailable(s, o))
        }));
    };
    const onSearchChange = (_, value) => {
        const filterValue = value?.toLowerCase() || '';
        setFilter(filterValue);
        const options = getOptions(filterValue);
        const searchResultAnnounce = (<Announced message={msg(messages.searchImagesResult, { numResults: options.length })} aria-live="assertive"/>);
        setAnnounce(searchResultAnnounce);
    };
    const options = getOptions(filter);
    const filteredOptions = !showUnavailable ? options.filter((o) => !o.disabled) : options;
    const hasDisabledOptions = options.some((o) => o.disabled);
    const resolvedProps = {
        ...props,
        options: filteredOptions,
        onDismiss: () => setFilter(''),
        onChange: (_, value) => {
            const option = value;
            if (!option.disabled) {
                onChange(option);
            }
        },
        onRenderOption: (option) => (option ? renderOption(option) : null),
        onRenderTitle: (options) => (options ? renderOption(options[0]) : null),
        onRenderList: (renderProps, defaultRender) => {
            return (<>
                    {announce}
                    <SearchBox placeholder={searchPlaceholder} styles={{ root: { margin: 10 } }} onChange={onSearchChange}/>
                    <div style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
                        {defaultRender && defaultRender(renderProps)}
                    </div>
                    {hasDisabledOptions && (<>
                                <Separator styles={{ root: { padding: '0', margin: '0', height: '1px' } }}/>
                                <Checkbox styles={{ root: { padding: '15px' } }} checked={showUnavailable} onChange={(_, value) => setShowUnavailable(value || false)} label={msg(messages.showUnavailableSimplified)}/>
                            </>)}
                    {addImagesUrl && (<>
                            <Separator styles={{ root: { padding: '0', margin: '0', height: '1px' } }}/>
                            <Footer addImagesUrl={addImagesUrl}/>
                        </>)}
                </>);
        },
        styles: {
            title: {
                lineHeight: 20,
                height: 50,
                paddingTop: 5,
            },
            dropdownItem: itemStyle,
            dropdownItemSelected: itemStyle,
            dropdownItemDisabled: itemStyle,
            dropdownItemSelectedAndDisabled: itemStyle
        },
    };
    return <Dropdown {...resolvedProps} aria-required={required || undefined}/>;
};
const renderOption = (option) => {
    const item = (<Stack horizontal verticalAlign="center" styles={{ root: { overflow: 'hidden', width: '100%' } }}>
            <Stack.Item>
                <div style={{ marginRight: 7 }}>
                    {option.iconUrl ? (<img width={35} aria-hidden src={option.iconUrl}/>) : (<Icon iconName={option.isWindows ? 'WindowsLogo' : 'LinuxLogo32'} styles={{
        root: {
            color: option.isWindows ? '#00BCF2' : '#484644',
            fontSize: 30,
            marginTop: 10,
            marginRight: 7,
        },
    }}/>)}
                </div>
            </Stack.Item>
            <Stack.Item styles={{ root: { overflow: 'hidden' } }}>
                <div style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    }}>
                    {option.text}
                </div>
                <div style={{
        color: !option.disabled ? '#555555' : undefined,
        fontSize: 12,
    }}>
                    {option.subTitle}
                </div>
            </Stack.Item>
        </Stack>);
    return !option.description ? (item) : (<TooltipHost content={<div dangerouslySetInnerHTML={{ __html: option.description || '' }}/>} directionalHint={DirectionalHint.leftCenter} styles={{ root: { width: '100%' } }}>
            {item}
        </TooltipHost>);
};
