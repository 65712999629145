import _ from 'lodash';
import { CommandButton, DefaultButton, PersonaCoin, PersonaSize, ActivityItem, Link, } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { commonMessages } from '../../language/common-messages';
import './user-menu.css';
const messages = defineMessages({
    userMenu: {
        id: 'UserMenu',
        defaultMessage: 'User menu',
        description: 'Aria label for user menu button',
    },
});
export const UserMenu = (props) => {
    const { isAuthenticating, isAuthenticated, imageUrl, name, initials, displayId, signIn, signOut, styles, isLmsIntegrationEnabled, } = props;
    const [showNameTitle, setShowNameTitle] = React.useState(false);
    const [showDisplayIdTitle, setShowDisplayIdTitle] = React.useState(false);
    const intl = useIntl();
    const divNameRef = React.useCallback((node) => {
        if (!showNameTitle && node && node.scrollWidth > node.offsetWidth) {
            setShowNameTitle(true);
        }
    }, []);
    const divDisplayIdRef = React.useCallback((node) => {
        if (!showDisplayIdTitle && node && node.scrollWidth > node.offsetWidth) {
            setShowDisplayIdTitle(true);
        }
    }, []);
    if (isAuthenticating) {
        return null;
    }
    if (!isAuthenticated) {
        const defaultStyles = {
            textContainer: {
                fontWeight: 'lighter',
            },
        };
        const buttonStyles = _.merge({}, defaultStyles, styles || {});
        return (<DefaultButton onClick={signIn} styles={buttonStyles}>
                <span className="ms-fontSize-mPlus">
                    <FormattedMessage {...commonMessages.signInButtonText}/>
                </span>
            </DefaultButton>);
    }
    if (isLmsIntegrationEnabled) {
        return (<DefaultButton onClick={signOut} styles={styles}>
                <FormattedMessage {...commonMessages.signOutButtonText}/>
            </DefaultButton>);
    }
    const userMenu = intl.formatMessage(messages.userMenu);
    const contactCardItem = {
        activityDescription: [
            <div className="user-menu__activity-item-name" key="Name" ref={divNameRef} title={showNameTitle ? name : undefined}>
                {name}
            </div>,
            <div className="user-menu__activity-item-display-id" key="DisplayId" ref={divDisplayIdRef} title={showDisplayIdTitle ? displayId : undefined}>
                {displayId}
            </div>,
            <div className="user-menu__activity-item-link" key="MyProfile">
                <FormattedMessage id="MyProfileLink" defaultMessage="<Link>My profile</Link>" description="Link to the profile of the user. <Link> and </Link> should not be localized, but text between them should." values={{
                Link: (chunks) => (<Link href="https://aka.ms/azlabs-profile" target="_blank">
                                {chunks}
                            </Link>),
            }}/>
            </div>,
            <div className="user-menu__activity-item-link" key="SignOut">
                <FormattedMessage id="SignOutLink" defaultMessage="<Link>Sign out</Link>" description="Link to sign out Azure Lab Services. <Link> and </Link> should not be localized, but text between them should." values={{
                Link: (chunks) => <Link onClick={() => signOut()}>{chunks}</Link>,
            }}/>
            </div>,
        ],
        activityPersonas: [{ imageUrl: imageUrl, imageInitials: initials, coinSize: 80 }],
    };
    const items = [
        {
            key: 'FullIdentity',
            onRender: () => (<ActivityItem {...contactCardItem} styles={{
                root: { width: '340px', height: '160px' },
                activityPersona: { padding: '30px 0px 35px 35px' },
            }}/>),
        },
    ];
    const defaultStyles = {
        root: {
            verticalAlign: 'center',
        },
    };
    const buttonStyles = _.merge({}, defaultStyles, styles || {});
    return (<CommandButton styles={buttonStyles} ariaLabel={userMenu} menuProps={{ items, calloutProps: { minPagePadding: 0 } }} menuIconProps={{
        style: { display: 'none ' },
    }}>
            <PersonaCoin imageUrl={imageUrl} imageInitials={initials} size={PersonaSize.size32} styles={{
        initials: { fontWeight: 'normal' },
        coin: { padding: '0 10px 0 10px' },
    }}/>
        </CommandButton>);
};
export default UserMenu;
