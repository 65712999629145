import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { ltiSignInError, ltiSignInSuccess, setLmsInstance, setLtiContext, setLtiRoles, setMembershipUrl, } from '../../actions/lti/lti-action-creators';
import { LtiActionType } from '../../actions/lti/lti-actions';
import { getHash } from '../../selectors/route-selector';
import { LtiClaims } from '../../../utils/constants';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getNonce, getState, setLtiToken } from '../../../data/providers/lti-provider';
import { decodeLtiTokenPayload, getValidatedLtiToken, isLmsProfessor } from '../../selectors/lti-selectors';
import Routes from '../../../utils/routes';
import { routerActions } from 'connected-react-router/immutable';
import { setHost, setTheme } from '../../actions/common/common-action-creators';
import Host from '../../../utils/hosts';
import Theme from '../../../utils/themes';
import { URLBuilder } from '@azure/ms-rest-js';
const LtilmsInstance = 'lmsinstance';
export function* ltiSignIn(action) {
    try {
        const hash = yield select(getHash);
        const state = yield call(getState);
        const nonce = yield call(getNonce);
        const ltiToken = yield call(getValidatedLtiToken, hash, state, nonce);
        if (!ltiToken) {
            const error = new MlClientError('LtiSignIn: invalid state or nonce', '', FailureOperation.LtiSignIn);
            yield put(ltiSignInError(error));
            return;
        }
        yield call(setLtiToken, ltiToken);
        const decodedPayload = yield call(decodeLtiTokenPayload, ltiToken);
        const roles = decodedPayload[LtiClaims.roles];
        const context = decodedPayload[LtiClaims.context];
        const toolPlatform = decodedPayload[LtiClaims.toolPlatform];
        let lms = Host.Unknown;
        let theme = Theme.Default;
        let lmsInstance = '';
        if (!!toolPlatform) {
            const id = toolPlatform['guid'];
            const pfc = toolPlatform['product_family_code'];
            if ((!!id && id.includes('canvas')) || (!!pfc && pfc.includes('canvas'))) {
                lms = Host.Canvas;
                theme = Theme.Canvas;
            }
        }
        const namesRoleServices = decodedPayload[LtiClaims.namesroleservice];
        const membershipUrl = namesRoleServices[LtiClaims.contextMembershipUrl];
        const customClaims = decodedPayload[LtiClaims.custom];
        if (!!customClaims) {
            const url = new URLBuilder();
            url.setHost(customClaims[LtilmsInstance] + '/');
            url.setScheme('https');
            lmsInstance = url.toString();
        }
        yield all([
            put(setHost(lms)),
            put(setLtiRoles(roles || [])),
            put(setMembershipUrl(membershipUrl || '')),
            put(setLtiContext(context)),
            put(setTheme(theme)),
            put(setLmsInstance(lmsInstance)),
        ]);
        const isProfessor = yield select(isLmsProfessor);
        if (!isProfessor) {
            yield put(routerActions.replace({ pathname: Routes.StudentView }));
        }
        else {
            yield put(routerActions.replace({ pathname: Routes.Home }));
        }
        yield put(ltiSignInSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.LtiSignIn, err ? err.code : undefined);
        yield put(ltiSignInError(error));
    }
}
export function* ltiSignInSaga() {
    yield takeLatest(LtiActionType.LTI_SIGN_IN, ltiSignIn);
}
