import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { List, Map, Record } from 'immutable';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { UserActionType, } from '../actions/user/user-actions';
import { ProvisioningState } from '../../utils/provisioning-state';
import { Context } from '../action-context';
function listUsers(state, action) {
    return state.merge({
        loadError: undefined,
        loadState: state.loadState === LoadingStoreState.Loaded ? LoadingStoreState.Updating : LoadingStoreState.Loading,
    });
}
function listUsersCancelled(state, action) {
    return state.merge({
        loadState: state.loadState === LoadingStoreState.Updating ? LoadingStoreState.Loaded : LoadingStoreState.NotStarted,
    });
}
function listUsersSuccess(state, action) {
    return state.merge({
        users: List(action.users),
        loadState: LoadingStoreState.Loaded,
    });
}
function listUsersError(state, action) {
    const { error: loadError } = action;
    return state.merge({
        loadError,
        loadState: state.loadState === LoadingStoreState.Updating
            ? LoadingStoreState.UpdateFailed
            : LoadingStoreState.LoadingFailed,
    });
}
function deleteUsers(state, action) {
    return state.merge({
        isDeleting: true,
        deleteErrors: Map(),
    });
}
function deleteUsersSuccess(state, action) {
    const { userIds } = action;
    let users = state.get('users');
    userIds.map((id) => {
        users = updateUser(id, users, { provisioningState: ProvisioningState.Deleting });
    });
    return state.merge({
        isDeleting: false,
        users,
    });
}
function deleteUsersError(state, action) {
    const { errors } = action;
    const userIds = Object.keys(errors);
    let deleteErrors = state.get('deleteErrors');
    userIds.map((id) => {
        deleteErrors = deleteErrors.set(id, errors[id]);
    });
    return state.merge({
        deleteErrors,
        isDeleting: false,
    });
}
function addUsers(state, action) {
    return state.merge({
        isAdding: true,
        addError: undefined,
    });
}
function addUsersSuccess(state, action) {
    const { users: newUsers } = action;
    let users = state.get('users');
    if (newUsers && newUsers.length > 0) {
        // typescript nonsense because we can't import
        // enums for the ml-ts package unless we import
        // the entire models object and TS doesn't understand
        // that Ml.User is the same as User imported directly
        users = users.push(newUsers);
    }
    return state.merge({
        isAdding: false,
        users,
    });
}
function addUsersError(state, action) {
    const { error: addError } = action;
    return state.merge({
        addError,
        isAdding: false,
    });
}
function inviteUsers(state, action) {
    return state.merge({
        isInviting: true,
        inviteError: undefined,
    });
}
function inviteUsersSuccess(state, action) {
    const { userIds } = action;
    let users = state.get('users');
    userIds.map((id) => {
        users = updateUser(id, users, { registrationLinkEmailState: Ml.RegistrationLinkEmailState.Sending });
    });
    return state.merge({
        isInviting: false,
        users,
    });
}
function inviteUsersError(state, action) {
    const { error: inviteError } = action;
    return state.merge({
        inviteError,
        isInviting: false,
    });
}
function clearLoadError(state, action) {
    return state.merge({
        loadError: undefined,
    });
}
function clearAddUsersError(state, action) {
    return state.merge({
        addError: undefined,
    });
}
function clearDeleteUsersError(state, action) {
    const { id } = action;
    let deleteErrors = state.get('deleteErrors');
    if (id) {
        deleteErrors = deleteErrors.remove(id);
    }
    else {
        deleteErrors = Map();
    }
    return state.merge({
        deleteErrors,
    });
}
function clearInviteUsersError(state, action) {
    return state.merge({
        inviteError: undefined,
    });
}
function updateAdditionalQuota(state, action) {
    return state.merge({
        isUpdating: true,
        updateErrors: Map(),
    });
}
function updateAdditionalQuotaSuccess(state, action) {
    return state.merge({
        isUpdating: false,
        users: updateUsers(state, action.users),
    });
}
function updateAdditionalQuotaError(state, action) {
    const { errors } = action;
    let updateErrors = state.get('updateErrors');
    for (const [userId, error] of Object.entries(errors)) {
        updateErrors = updateErrors.set(userId, error);
    }
    return state.merge({
        updateErrors,
        isUpdating: false,
    });
}
function clearUpdateAdditionalQuotaError(state, action) {
    const { id } = action;
    let updateErrors = state.get('updateErrors');
    if (id) {
        updateErrors = updateErrors.remove(id);
    }
    else {
        updateErrors = Map();
    }
    return state.merge({
        updateErrors,
    });
}
/**
 * Merges the update values into an existing user with the given ID.
 * @param id The resource ID of the user to update.
 * @param users The users in the current state.
 * @param update The updated values for the user.
 */
function updateUser(id, users, update) {
    const index = users.findIndex((user) => user.id === id);
    if (index < 0) {
        return users;
    }
    let user = users.get(index);
    user = { ...user, ...update };
    users = users.set(index, user);
    return users;
}
/**
 * Adds or replaces the passed in users in the stores current list of users. Does not remove other users. Does not merge.
 * @param state The current store state.
 * @param updatedUsers The updated state of the users.
 */
function updateUsers(state, updatedUsers) {
    let currentUsers = state.get('users');
    updatedUsers.map((user) => {
        const currentUserIndex = currentUsers.findIndex((current) => current.id === user.id);
        if (currentUserIndex === -1) {
            currentUsers = currentUsers.push(user);
        }
        else {
            currentUsers = currentUsers.set(currentUserIndex, user);
        }
    });
    return currentUsers;
}
const initialState = Record({
    users: List(),
    loadState: LoadingStoreState.NotStarted,
    loadError: undefined,
    isAdding: false,
    isDeleting: false,
    isInviting: false,
    isUpdating: false,
    addError: undefined,
    deleteErrors: Map(),
    inviteError: undefined,
    updateErrors: Map(),
})();
export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActionType.CLEAR_USER_SESSION:
        case UserActionType.SELECT_LAB_PARENT_RESOURCE:
        case UserActionType.SELECT_LAB:
        case UserActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    return state;
                default:
                    switch (action.type) {
                        case UserActionType.LIST_USERS:
                            return listUsers(state, action);
                        case UserActionType.LIST_USERS_CANCELLED:
                            return listUsersCancelled(state, action);
                        case UserActionType.LIST_USERS_SUCCESS:
                            return listUsersSuccess(state, action);
                        case UserActionType.LIST_USERS_ERROR:
                            return listUsersError(state, action);
                        case UserActionType.DELETE_USERS:
                            return deleteUsers(state, action);
                        case UserActionType.DELETE_USERS_SUCCESS:
                            return deleteUsersSuccess(state, action);
                        case UserActionType.DELETE_USERS_ERROR:
                            return deleteUsersError(state, action);
                        case UserActionType.ADD_USERS:
                            return addUsers(state, action);
                        case UserActionType.ADD_USERS_SUCCESS:
                            return addUsersSuccess(state, action);
                        case UserActionType.ADD_USERS_ERROR:
                            return addUsersError(state, action);
                        case UserActionType.INVITE_USERS:
                            return inviteUsers(state, action);
                        case UserActionType.INVITE_USERS_SUCCESS:
                            return inviteUsersSuccess(state, action);
                        case UserActionType.INVITE_USERS_ERROR:
                            return inviteUsersError(state, action);
                        case UserActionType.CLEAR_LOAD_USERS_ERROR:
                            return clearLoadError(state, action);
                        case UserActionType.CLEAR_ADD_USERS_ERROR:
                            return clearAddUsersError(state, action);
                        case UserActionType.CLEAR_DELETE_USERS_ERROR:
                            return clearDeleteUsersError(state, action);
                        case UserActionType.CLEAR_INVITE_USERS_ERROR:
                            return clearInviteUsersError(state, action);
                        case UserActionType.UPDATE_ADDITIONAL_QUOTA:
                            return updateAdditionalQuota(state, action);
                        case UserActionType.UPDATE_ADDITIONAL_QUOTA_SUCCESS:
                            return updateAdditionalQuotaSuccess(state, action);
                        case UserActionType.UPDATE_ADDITIONAL_QUOTA_ERROR:
                            return updateAdditionalQuotaError(state, action);
                        case UserActionType.CLEAR_UPDATE_ADDITIONAL_QUOTA_ERROR:
                            return clearUpdateAdditionalQuotaError(state, action);
                        default:
                            return state;
                    }
            }
    }
};
export default userReducer;
