import { put, takeLatest, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router/immutable';
import { getLabIdFromRoute, getLabParentIdFromRoute } from '../../selectors/route-selector';
import { getCurrentLabId } from '../../selectors/lab-selectors';
import { selectLab } from '../../actions/lab/lab-action-creators';
import { isProfessorAppInitialized } from '../../selectors/common-selectors';
import { trackException } from '../../../utils/telemetry/telemetry-channel';
import { getCurrentLabParentId } from '../../selectors/lab-parent-resource-selectors';
import { selectLabParentResource } from '../../actions/lab-parent-resource/lab-parent-resource-action-creators';
export function* routeChange() {
    try {
        const professorInitialized = yield select(isProfessorAppInitialized);
        // we need to wait for professor initialization so that our subscription / tenant
        // map has been created, otherwise the listLabs caused from selectLabParentResource
        // will fail
        if (professorInitialized) {
            const routeLabParentId = yield select(getLabParentIdFromRoute);
            // process lab and lab account changes that result from history (go back / go forward)
            // changes
            if (routeLabParentId) {
                const currentLabParentId = yield select(getCurrentLabParentId);
                if (currentLabParentId !== routeLabParentId) {
                    yield put(selectLabParentResource(routeLabParentId));
                }
                const routeLabId = yield select(getLabIdFromRoute);
                if (routeLabId) {
                    const currentLabId = yield select(getCurrentLabId);
                    if (currentLabId !== routeLabId) {
                        yield put(selectLab(routeLabId));
                    }
                }
            }
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* routeChangeSaga() {
    yield takeLatest(LOCATION_CHANGE, routeChange);
}
