import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import commonMessages from '../language/common-messages';
import Constants from '../utils/constants';
const messages = defineMessages({
    title: {
        id: 'ResetVmsDialogTitle',
        defaultMessage: 'Reimage virtual machine(s)',
        description: 'Title of confirmation dialog when reimaging one or more virtual machines.',
    },
    studentVMTitle: {
        id: 'ResetStudentVMDialogTitle',
        defaultMessage: 'Reimage virtual machine',
        description: 'Title of confirmation dialog when reimaging one student virtual machines',
    },
});
const ResetVmsDialogInjected = (props) => {
    const { numberOfMachines, isStudentVM, onDismiss, onSubmit } = props;
    const msg = props.intl.formatMessage;
    const cantBeUndoneSpan = (<b>
            <FormattedMessage id="ResetVmsDialogCantBeUndoneMessage" defaultMessage="This operation cannot be undone." description="This message will be displayed as part of DeleteUsersDialogTextFormat"/>
        </b>);
    return (<Dialog hidden={false} onDismiss={onDismiss} dialogContentProps={{ title: !isStudentVM ? msg(messages.title) : msg(messages.studentVMTitle) }} closeButtonAriaLabel={msg(commonMessages.cancel)} maxWidth={Constants.DefaultDialogWidth}>
            {!!numberOfMachines && !isStudentVM && (<FormattedMessage id="ResetVmsDialogTextFormat" defaultMessage="{numberOfMachines} virtual machine(s) will be reset to the original state(s) and all of your data on the disk(s) will be lost. {cantBeUndoneSpan}" description="Text of confirmation dialog when resetting one or more virtual machines. {numberOfMachines} is an integer indicating number of machines (greater or equal to 1). {cantBeUndoneSpan} is the bolded resolved value of ResetVmsDialogCantBeUndoneMessage." values={{ numberOfMachines, cantBeUndoneSpan }}/>)}
            {!!isStudentVM && (<FormattedMessage id="ResetStudentVMDialogTextFormat" defaultMessage="Your virtual machine will be reset to the original state and all of your data on the disk will be lost. {cantBeUndoneSpan}" description="Text of confirmation dialog when resetting student virtual machine. {cantBeUndoneSpan} is the bolded resolved value of ResetVmsDialogCantBeUndoneMessage." values={{ cantBeUndoneSpan }}/>)}
            <DialogFooter>
                <PrimaryButton text={msg(commonMessages.reset)} onClick={onSubmit}/>
                <DefaultButton autoFocus text={msg(commonMessages.cancel)} onClick={onDismiss}/>
            </DialogFooter>
        </Dialog>);
};
export const ResetVmsDialog = injectIntl(ResetVmsDialogInjected);
export default ResetVmsDialog;
