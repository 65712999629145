let currentLocale;
export function setCurrentLocale(locale) {
    currentLocale = locale;
}
export function caseInsensitiveLocaleCompare(a, b) {
    return a.localeCompare(b, currentLocale, { sensitivity: 'accent' });
}
export function caseInsensitiveCultureInvariantCompare(a, b) {
    return a.localeCompare(b, 'en-001', { sensitivity: 'base' });
}
// the value of currentLocale here is same with what stored in the common store. set locale again here for string comparison with culture
export function getCurrentLocale() {
    return currentLocale;
}
