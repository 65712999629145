import { CommonLabActionType } from '../lab/lab-actions';
import { CommonActionType } from '../common/common-actions';
import { CommonLabParentResourceActionType } from '../lab-parent-resource/lab-parent-resource-actions';
export const UserActionType = {
    ...CommonActionType,
    ...CommonLabActionType,
    ...CommonLabParentResourceActionType,
    LIST_USERS: 'LIST_USERS',
    LIST_USERS_CANCELLED: 'LIST_USERS_CANCELLED',
    LIST_USERS_SUCCESS: 'LIST_USERS_SUCCESS',
    LIST_USERS_ERROR: 'LIST_USERS_ERROR',
    DELETE_USERS: 'DELETE_USERS',
    DELETE_USERS_SUCCESS: 'DELETE_USERS_SUCCESS',
    DELETE_USERS_ERROR: 'DELETE_USERS_ERROR',
    ADD_USERS: 'ADD_USERS',
    ADD_USERS_SUCCESS: 'ADD_USERS_SUCCESS',
    ADD_USERS_ERROR: 'ADD_USERS_ERROR',
    INVITE_USERS: 'INVITE_USERS',
    INVITE_USERS_SUCCESS: 'INVITE_USERS_SUCCESS',
    INVITE_USERS_ERROR: 'INVITE_USERS_ERROR',
    CLEAR_LOAD_USERS_ERROR: 'CLEAR_LOAD_USERS_ERROR',
    CLEAR_ADD_USERS_ERROR: 'CLEAR_ADD_USERS_ERROR',
    CLEAR_DELETE_USERS_ERROR: 'CLEAR_DELETE_USERS_ERROR',
    CLEAR_INVITE_USERS_ERROR: 'CLEAR_INVITE_USERS_ERROR',
    POLL_USERS_START: 'POLL_USERS_START',
    POLL_USERS_STOP: 'POLL_USERS_STOP',
    POLL_USERS_CANCELLED: 'POLL_USERS_CANCELLED',
    UPDATE_ADDITIONAL_QUOTA: 'UPDATE_ADDITIONAL_QUOTA',
    UPDATE_ADDITIONAL_QUOTA_SUCCESS: 'UPDATE_ADDITIONAL_QUOTA_SUCCESS',
    UPDATE_ADDITIONAL_QUOTA_ERROR: 'UPDATE_ADDITIONAL_QUOTA_ERROR',
    CLEAR_UPDATE_ADDITIONAL_QUOTA_ERROR: 'CLEAR_UPDATE_ADDITIONAL_QUOTA_ERROR',
};
