import { call, put, takeLatest, select } from 'redux-saga/effects';
import { TemplateActionType } from '../../actions/template/template-actions';
import TemplateProvider from '../../../data/providers/template-provider';
import { updateTemplateSuccess, updateTemplateError } from '../../actions/template/template-action-creators';
import { getTemplate as getTemplateSelector } from '../../../redux/selectors/template-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
export function* updateTemplate(action) {
    const { templateId, title, description } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const oldTemplate = yield select(getTemplateSelector, templateId);
        const template = { ...oldTemplate, title, description };
        const updatedTemplate = yield call(TemplateProvider.updateTemplate, template, accessToken, locale, language);
        yield put(updateTemplateSuccess(updatedTemplate));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, templateId, FailureOperation.UpdateTemplate, e ? e.code : undefined);
        yield put(updateTemplateError(error));
    }
}
export function* updateTemplateSaga() {
    yield takeLatest(TemplateActionType.UPDATE_TEMPLATE, updateTemplate);
}
