import { fork, takeLatest } from 'redux-saga/effects';
import { isVNextRegistrationCode } from '../../common/selectors/registration-code';
function registrationCodeLengthBasedBasedTaskHelper(vCurrentWorker, vNextWorker) {
    return function* (action) {
        const registrationCode = action.registrationCode;
        if (isVNextRegistrationCode(registrationCode) && !!vNextWorker) {
            yield fork(vNextWorker, action);
        }
        else if (!!vCurrentWorker) {
            yield fork(vCurrentWorker, action);
        }
    };
}
export function registrationCodeLengthBasedTakeLatest(pattern, vCurrentWorker, vNextWorker) {
    const helper = registrationCodeLengthBasedBasedTaskHelper(vCurrentWorker, vNextWorker);
    return takeLatest(pattern, helper);
}
