import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    PolicyEnforceAutoShutdown: {
        id: 'ALSPolicyEnforceAutoShutdownError',
        defaultMessage: 'The lab could not be created or updated, because policy requires that all auto shutdown options must be enabled. ',
        description: 'This error message is displayed when policy violation occurs for enforce auto shutdown policy',
    },
    PolicyAllowedVirtualMachineSKUs: {
        id: 'ALSPolicyAllowedVirtualMachineSKUsError',
        defaultMessage: 'The lab could not be created or updated, because the selected virtual machine size is not allowed by policy. ',
        description: 'This error message is displayed when policy violation occurs for allowed virtual machine SKUs policy',
    },
    PolicyRequireNonAdminUser: {
        id: 'ALSPolicyRequireNonAdminUserError',
        defaultMessage: 'The lab could not be created or updated, because policy requires that all non-admin accounts must be enabled. ',
        description: 'This error message is displayed when policy violation occurs for require non admin user policy',
    },
    PolicyTemplateLabsNotAllowed: {
        id: 'ALSPolicyTemplateLabsNotAllowedError',
        defaultMessage: 'The lab could not be created or updated, because template virtual machine is not allowed by policy. ',
        description: 'This error message is displayed when policy violation occurs for template labs not allowed policy',
    },
    UnknownPolicyViolationError: {
        id: 'UnknownPolicyViolationError',
        defaultMessage: 'Azure built-in policy error or customer policy violation. ',
        description: 'This error message denotes azure built-in policy error or customer policy violation occurred.',
    },
});
export const FailureOperation = {
    Unknown: '',
    SaveSharedImage: 'SaveSharedImage',
    GetLabAccount: 'GetLabAccount',
    GetEnvironment: 'GetEnvironment',
    GetEnvironmentWithNetwork: 'GetEnvironmentWithNetwork',
    DeleteLab: 'DeleteLab',
    CreateLab: 'CreateLab',
    UpdateLab: 'UpdateLab',
    UpdateLabCapacity: 'UpdateLabCapacity',
    UpdateLabAccessMode: 'UpdateLabAccessMode',
    ScalingDownWhenVirtualMachinesAreRunning: 'ScalingDownWhenVirtualMachinesAreRunning',
    UpdateLabQuota: 'UpdateLabQuota',
    LoadLabs: 'LoadLabs',
    LoadSubscriptions: 'LoadSubscriptions',
    LoadTenantSubscriptions: 'LoadTenantSubscriptions',
    LoadSchedules: 'LoadSchedules',
    UpdateSchedule: 'UpdateSchedule',
    DeleteSchedule: 'DeleteSchedule',
    CreateSchedule: 'CreateSchedule',
    LoadUsers: 'LoadUsers',
    AddUsers: 'AddUsers',
    InviteUsers: 'InviteUsers',
    DeleteUsers: 'DeleteUsers',
    LoadEnvironments: 'LoadEnvironments',
    StartEnvironments: 'StartEnvironments',
    StopEnvironments: 'StopEnvironments',
    ResetEnvironments: 'ResetEnvironments',
    GetSharedGallery: 'GetSharedGallery',
    StopTemplate: 'StopTemplate',
    StartTemplate: 'StartTemplate',
    CreateTemplate: 'CreateTemplate',
    ResetPassword: 'ResetPassword',
    PublishTemplate: 'PublishTemplate',
    GetTemplate: 'GetTemplate',
    UpdateTemplate: 'UpdateTemplate',
    ListTemplates: 'ListTemplates',
    UpdateAdditionalQuotaForUsers: 'UpdateAdditionalQuotaForUsers',
    Initialize: 'Initialize',
    ProfessorInitialize: 'ProfessorInitialize',
    StudentInitialize: 'StudentInitialize',
    LabAppInitialize: 'LabAppInitialize',
    GetAccessToken: 'GetAccessToken',
    GetUserPhoto: 'GetUserPhoto',
    GetGroupName: 'GetGroupName',
    GetGroups: 'GetGroups',
    ListLabAccounts: 'ListLabAccounts',
    RefreshSubscriptionsAndLabAccounts: 'RefreshSubscriptionsAndLabAccounts',
    ListLabAccountsForSubscription: 'ListLabAccountsForSubscription',
    ListGalleryImages: 'ListGalleryImages',
    ListSharedImages: 'ListSharedImages',
    SignIn: 'SignIn',
    SignOut: 'SignOut',
    RegisterUser: 'RegisterUser',
    ListUserEnvironments: 'ListUserEnvironments',
    ListUserEnvironmentsPartial: 'ListUserEnvironmentsPartial',
    StartUserEnvironment: 'StartUserEnvironment',
    StopUserEnvironment: 'StopUserEnvironment',
    ResetUserEnvironmentPassword: 'ResetUserEnvironmentPassword',
    ReduxSagas: 'ReduxSagas',
    MsalProviderInit: 'MsalProviderInit',
    ReactUnhandled: 'ReactUnhandled',
    IdleTimeout: 'IdleTimeout',
    GetUserSettings: 'GetUserSettings',
    UpdateUserSettings: 'UpdateUserSettings',
    StorageInit: 'StorageInit',
    GetPricingAndAvailabilityData: 'GetPricingAndAvailabilityData',
    GetLabPricingAndAvailabilityData: 'GetLabPricingAndAvailabilityData',
    GetCoreRestrictionsAndUsages: 'GetCoreRestrictionsAndUsages',
    GetLab: 'GetLab',
    TeamsInitialize: 'TeamsInitialize',
    TeamsDisableSaveButton: 'TeamsDisableSaveButton',
    TeamsConfigureTab: 'TeamsConfigureTab',
    TeamsStartMfa: 'TeamsStartMfa',
    TeamsCompleteMfa: 'TeamsCompleteMfa',
    SyncLabUsers: 'SyncLabUsers',
    ListTenants: 'ListTenants',
    SelectTenant: 'SelectTenant',
    RequestUserConsent: 'RequestUserConsent',
    ListVNextLabsForSubscription: 'ListVNextLabsForSubscription',
    ListLabPlansForSubscription: 'ListLabPlansForSubscription',
    ListLabParentResources: 'ListLabParentResources',
    ListLabPlanImages: 'ListLabPlanImages',
    ListSkus: 'ListSkus',
    LtiStartOidc: 'LtiStartOidc',
    RefreshSubscriptionsAndLabParentResources: 'RefreshSubscriptionsAndLabParentResources',
    UpdateLabTitleOrDescription: 'UpdateLabTitleOrDescription',
    GetLabMetadata: 'GetLabMetadata',
    UpdateLabSettings: 'UpdateLabSettings',
    RedeployVirtualMachine: 'RedeployVirtualMachine',
    ReimageUserVirtualMachine: 'ReimageUserVirtualMachine',
    RedeployUserVirtualMachine: 'RedeployUserVirtualMachine',
    RedeployVirtualMachineError: 'RedeployVirtualMachineError',
    LtiSignIn: 'LtiSignIn',
    GetLtiToken: 'GetLtiToken',
    AccessWebStorage: 'AccessWebStorage',
    LtiRequestBrowserStorageAccess: 'LtiRequestBrowserStorageAccess',
    ListSkuData: 'GetSkuData',
    ListUsageData: 'GetUsageData',
    ListPriceData: 'ListPriceData',
    CheckFeatureFlags: 'CheckFeatureFlags',
    GetLabPlan: 'GetLabPlan',
    LinkLabPlan: 'LinkLabPlan',
    UnlinkLabPlan: 'UnlinkLabPlan',
    UpdateLmsSettings: 'UpdateLmsSettings',
    GetOperationsResultFailure: 'GetOperationsResultFailure',
    GetUserOperationsResultFailure: 'GetUserOperationsResultFailure',
};
export class MlClientError {
    constructor(message, id, failureOperation, code, policyViolationDefinitionIds) {
        this.message = message;
        this.id = id;
        this.failureOperation = failureOperation;
        this.code = code;
        this.policyViolationDefinitionIds = policyViolationDefinitionIds;
    }
}
//export const REQUEST_DISALLOWED_BY_POLICY = 'RequestDisallowedByPolicy';
export const AzurePolicyViolation = {
    CODE: 'RequestDisallowedByPolicy',
    AUTOSHUTDOWN_POLICY: '/providers/Microsoft.Authorization/policyDefinitions/a6e9cf2d-7d76-440e-b795-8da246bd3aab',
    REQUIRE_NONADMIN_USER_POLICY: '/providers/Microsoft.Authorization/policyDefinitions/0fd9915e-cab3-4f24-b200-6e20e1aa276a',
    TEMPLATE_LABS_POLICY: '/providers/Microsoft.Authorization/policyDefinitions/e8a5a3eb-1ab6-4657-a701-7ae432cf14e1',
    ALLOWED_VIRTUAL_MACHINES_POLICY: '/providers/Microsoft.Authorization/policyDefinitions/3e13d504-9083-4912-b935-39a085db2249',
};
export const getAzurePolicyViolationErrorMessage = (error) => {
    const errorMessages = [];
    switch (error.code) {
        case AzurePolicyViolation.CODE:
            const errorCount = error.policyViolationDefinitionIds.length;
            for (const policyViolationDefinitionId of error.policyViolationDefinitionIds) {
                switch (policyViolationDefinitionId) {
                    case AzurePolicyViolation.AUTOSHUTDOWN_POLICY:
                        errorMessages.push(messages.PolicyEnforceAutoShutdown);
                        break;
                    case AzurePolicyViolation.REQUIRE_NONADMIN_USER_POLICY:
                        errorMessages.push(messages.PolicyRequireNonAdminUser);
                        break;
                    case AzurePolicyViolation.TEMPLATE_LABS_POLICY:
                        errorMessages.push(messages.PolicyTemplateLabsNotAllowed);
                        break;
                    case AzurePolicyViolation.ALLOWED_VIRTUAL_MACHINES_POLICY:
                        errorMessages.push(messages.PolicyAllowedVirtualMachineSKUs);
                        break;
                    default:
                        break;
                }
            }
            // unknown policy violation occurred
            if (errorMessages.length != errorCount) {
                errorMessages.push(messages.UnknownPolicyViolationError);
            }
    }
    return errorMessages;
};
export default MlClientError;
