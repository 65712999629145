import { Dialog, DialogFooter, PrimaryButton, TextField } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import CopyToClipboardButton from '../common/copy-to-clipboard-button/copy-to-clipboard-button';
import commonMessages from '../language/common-messages';
import Constants from '../utils/constants';
const messages = defineMessages({
    title: {
        id: 'RegistrationLinkDialogTitle',
        defaultMessage: 'User registration',
        description: 'Title of the dialog showing lab registration link.',
    },
    label: {
        id: 'RegistrationLinkDialogLabel',
        defaultMessage: 'Registration link',
        description: 'Label of the registration link text box.',
    },
});
const RegistrationLinkDialogInjected = (props) => {
    const msg = props.intl.formatMessage;
    return (<Dialog hidden={false} onDismiss={props.onDismiss} dialogContentProps={{ title: msg(messages.title) }} closeButtonAriaLabel={msg(commonMessages.done)} maxWidth={Constants.DefaultDialogWidth}>
            <FormattedMessage id="RegistrationLinkDialogText" defaultMessage="Users are added to this lab by registering via the following link and registration code:" description="Text on the dialog showing lab registration link."/>
            <TextField name={msg(messages.label)} ariaLabel={msg(messages.label)} readOnly={true} value={props.registrationLink} styles={{ root: { marginTop: 16, maxWidth: '402px' } }}/>
            <CopyToClipboardButton text={props.registrationLink}/>
            <DialogFooter>
                <PrimaryButton text={msg(commonMessages.done)} onClick={props.onDismiss}/>
            </DialogFooter>
        </Dialog>);
};
export const RegistrationLinkDialog = injectIntl(RegistrationLinkDialogInjected);
export default RegistrationLinkDialog;
