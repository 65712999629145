import * as React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { PrimaryButton, DialogFooter, Dialog, DefaultButton, Link } from '@fluentui/react';
const LinuxRdpDialogInjected = (props) => {
    const doesNotInstallSpan = (<b>
            <FormattedMessage id="LinuxRdpDialogDoesNotInstallSpan" defaultMessage="does not install RDP or GUI packages" description="Bolded text that will be composed into LinuxRdpDialogText"/>
        </b>);
    return (<Dialog hidden={false} onDismiss={props.onDismiss} dialogContentProps={{
        title: (<FormattedMessage id="LinuxRdpDialogTitle" defaultMessage="Enable Remote Desktop Connection" description="Title of the dialog which pops up when user enables RDP connection for a new linux lab."/>),
    }} minWidth={510}>
            <FormattedMessage id="LinuxRdpDialogText" defaultMessage="This only opens the Remote Desktop port. It {doesNotInstallSpan} on lab virtual machines. You must install these packages on the virtual machines to use a Remote Desktop Connection." description="Text on the dialog which pops up when user enables RDP connection for a new Linux lab." values={{ doesNotInstallSpan }}/>
            <div style={{ marginTop: 10 }}>
                <Link href="https://aka.ms/azlabs-linuxrdp" target="_blank">
                    <FormattedMessage id="LinuxRdpDialogLearnMore" defaultMessage="Learn more" description="Text of a link navigating to a page with more information."/>
                </Link>
            </div>
            <DialogFooter>
                <PrimaryButton onClick={props.onSubmit}>
                    <FormattedMessage id="LinuxRdpContinueButtonText" defaultMessage="Continue with Remote Desktop" description="Text of a button a user clicks to continue with enabling RDP on a new Linux lab."/>
                </PrimaryButton>
                <DefaultButton autoFocus onClick={props.onDismiss}>
                    <FormattedMessage id="LinuxRdpBackButtonText" defaultMessage="Back" description="Text of a button a user clicks to cancel enabling RDP on a new Linux lab."/>
                </DefaultButton>
            </DialogFooter>
        </Dialog>);
};
export const LinuxRdpDialog = injectIntl(LinuxRdpDialogInjected);
export default LinuxRdpDialog;
