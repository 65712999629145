import * as React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl } from 'react-intl';
import { registerUser } from '../redux/actions/register/register-action-creators';
import { RegisterUser } from './register-user';
import { Page } from '../common/page/page';
import { getRegisterContainerModel } from './register-selectors';
const messages = defineMessages({
    pageTitle: {
        id: 'RegisterUserPageTitle',
        defaultMessage: 'Registering',
        description: 'The page title for the register user page',
    },
});
class RegisterUserContainer extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        this.props.registerUser(this.props.registrationCode);
    }
    render() {
        const pageTitle = this.props.intl.formatMessage(messages.pageTitle);
        const { registerViewModel } = this.props;
        const { shouldHideUserMenu } = registerViewModel;
        return (<Page headerProps={{
            hideLabParentPicker: true,
            hideLabPicker: true,
            hideUserMenu: shouldHideUserMenu,
        }} title={pageTitle} isScrollable={true}>
                <RegisterUser registerViewModel={registerViewModel}/>
            </Page>);
    }
}
const mapStateToProps = (state) => {
    return getRegisterContainerModel(state);
};
const mapDispatchToProps = {
    registerUser,
};
const container = connect(mapStateToProps, mapDispatchToProps)(injectIntl(RegisterUserContainer));
export default container;
