import * as React from 'react';
import { ComboBox } from '@fluentui/react';
export class FilteredDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.getOptions = () => {
            const { items, onGetKey, onGetText, onFilter } = this.props;
            const { filter } = this.state;
            const filteredItems = onFilter(items, filter);
            const options = filteredItems
                .map((item) => {
                const option = {
                    key: onGetKey(item),
                    data: item,
                    text: onGetText(item),
                };
                return option;
            })
                .toArray();
            return options;
        };
        this.onChange = (_event, option, _index, _value) => {
            const { onSelected, onGetText } = this.props;
            if (!!option) {
                onSelected(option.data);
                this.setState({ filter: onGetText(option.data) });
            }
        };
        this.onInputValueChange = (newValue, _composing) => {
            const { onFilterChanged } = this.props;
            this.setState({
                filter: newValue || '',
            });
            onFilterChanged(newValue);
        };
        this.state = {
            filter: '',
        };
    }
    render() {
        const { label, selected, onGetKey, onRenderItem, maxWidth, disabled } = this.props;
        const { filter } = this.state;
        const options = this.getOptions();
        const autofillProps = {
            onInputValueChange: this.onInputValueChange,
        };
        return (<ComboBox allowFreeform={true} autoComplete={'on'} options={options} selectedKey={!!selected ? onGetKey(selected) : undefined} openOnKeyboardFocus={true} autofill={autofillProps} onChange={this.onChange} onRenderOption={(option) => !!option ? onRenderItem(option.data) : null} dropdownMaxWidth={maxWidth} dropdownWidth={maxWidth} text={filter} label={label} disabled={disabled}/>);
    }
}
