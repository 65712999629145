import * as React from 'react';
import { Link } from '@fluentui/react';
import { FormattedMessage } from 'react-intl';
import { Constants } from "../utils/constants";
export const LabPlanSettingsInSentenceLink = (props) => {
    let { tenantId, labPlanId } = props;
    if (!labPlanId) {
        return <></>;
    }
    return (<Link target="_blank" href={Constants.Urls.LabPlanSettings(tenantId, labPlanId)}>
            <FormattedMessage id="LabPlanSettingsLink" defaultMessage="Azure portal" description="Link to the lab plan settings"/>
        </Link>);
};
