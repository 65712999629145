import { call, takeLatest } from 'redux-saga/effects';
import { CommonActionType } from '../../actions/common/common-actions';
import { setCurrentLocale } from '../../../utils/string-comparison';
export function* setLocale(action) {
    const { locale } = action;
    yield call(setCurrentLocale, locale);
}
export function* setLocaleSaga() {
    yield takeLatest(CommonActionType.SET_LOCALE, setLocale);
}
