import { routerActions } from 'connected-react-router/immutable';
import { call, put, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation, AzurePolicyViolation } from '../../../data/ml-client-error';
import LabAccountProvider from '../../../data/providers/lab-account-provider';
import { clearCacheForScope } from '../../../data/providers/msal-provider';
import { clearCacheForScope as clearCacheForScopeTeams } from '../../../data/providers/teams-provider';
import { ResourceId } from '../../../utils/resource-id';
import { createLabError, createLabSuccess, createVNextLabSuccess, selectLab, updateVnextPermissionPropagation, addVnextPermissionPropagatingLab, } from '../../actions/lab/lab-action-creators';
import { LabActionType } from '../../actions/lab/lab-actions';
import { getLanguage, getLocale, isLmsHost, isTeamsHost } from '../../selectors/common-selectors';
import { getSearch } from '../../selectors/route-selector';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import LabProvider from '../../../data/providers/vnext/labs-api/lab-provider';
import { getLtiTokenSaga } from '../lti/lti-token';
import { getVnextLabPermissionPropagating, getCreateLabPermissionOnLabPlan } from '../../selectors/vnext/lab-selectors';
export function* createLab(action) {
    const { labParentId, props, getCompleteRoute } = action;
    try {
        const resourceId = new ResourceId(labParentId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const newLab = yield call(LabAccountProvider.createLab, resourceId, props, accessToken, locale, language);
        const isTeamsHosted = yield select(isTeamsHost);
        if (isTeamsHosted) {
            yield call(clearCacheForScopeTeams, accessToken);
        }
        else {
            yield call(clearCacheForScope, accessToken);
        }
        // refresh access token and allow redirects for browsers
        // such as Safari or Brave
        const labId = `${labParentId}/labs/${newLab.name}`;
        const returnUrl = yield call(getCompleteRoute, labId);
        yield call(getArmAccessTokenSaga, true, returnUrl);
        // TODO: Create a saga that always injects search so we can stop doing this
        // all over the codebase
        const search = yield select(getSearch);
        yield put(selectLab(labId));
        yield put(routerActions.push({ pathname: returnUrl, search }));
        yield put(createLabSuccess(newLab));
    }
    catch (e) {
        const labId = `${labParentId}/labs/${props.name}`;
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.CreateLab, e ? e.code : undefined);
        yield put(createLabError(error));
    }
}
// create vNext lab saga
export function* createVNextLab(action) {
    const { labParentId, props, getCompleteRoute } = action;
    try {
        const resourceId = new ResourceId(labParentId);
        const accessToken = yield call(getLabsApiAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const isLms = yield select(isLmsHost);
        let ltiToken;
        if (isLms) {
            ltiToken = yield call(getLtiTokenSaga);
        }
        const newLab = yield call(LabProvider.createLab, resourceId, props, accessToken, locale, language, isLms ? ltiToken : undefined);
        const isTeamsHosted = yield select(isTeamsHost);
        if (isTeamsHosted) {
            yield call(clearCacheForScopeTeams, accessToken);
        }
        else {
            yield call(clearCacheForScope, accessToken);
        }
        const search = yield select(getSearch);
        // refresh access token and allow redirects for browsers
        // such as Safari or Brave
        const labId = `${labParentId}/providers/microsoft.labservices/labs/${newLab.name}`;
        const returnUrl = yield call(getCompleteRoute, labId);
        yield call(getArmAccessTokenSaga, true, `${returnUrl}${search}`);
        yield call(getLabsApiAccessTokenSaga, true, `${returnUrl}${search}`);
        yield put(selectLab(labId));
        yield put(routerActions.push({ pathname: returnUrl, search }));
        yield put(createVNextLabSuccess(newLab));
        const hasCreateLabPermissionOnLabPlan = yield select(getCreateLabPermissionOnLabPlan);
        if (hasCreateLabPermissionOnLabPlan) {
            yield put(addVnextPermissionPropagatingLab(newLab));
        }
    }
    catch (e) {
        const labId = `${labParentId}/providers/microsoft.labservices/labs/${props.name}`;
        const policyViolationDefinitionIds = [];
        if (e?.code === AzurePolicyViolation.CODE) {
            const errorObject = JSON.parse(e.response.body);
            for (const additionalInfo of errorObject.error.additionalInfo) {
                policyViolationDefinitionIds.push(additionalInfo.info.policyDefinitionId);
            }
        }
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.CreateLab, e ? e.code : undefined, policyViolationDefinitionIds ? policyViolationDefinitionIds : []);
        yield put(createLabError(error));
        // check permission propagating flag in vnext lab store
        const vNextLabPermissionPropagating = yield select(getVnextLabPermissionPropagating);
        // need to clear the propagating flag when create lab failed to not show the permission propagating banner
        if (vNextLabPermissionPropagating) {
            // update isVnextLabPermissionPropagating to false for the current lab that failed lab creation
            yield put(updateVnextPermissionPropagation(false));
        }
    }
}
export function* createLabSaga() {
    yield labParentBasedTakeLatest(LabActionType.CREATE_LAB, createLab, createVNextLab);
}
