import { RegisterActionType, } from './register-actions';
export const registerUser = (registrationCode) => ({
    type: RegisterActionType.REGISTER_USER,
    registrationCode,
});
export const clearRegistrationErrors = () => ({
    type: RegisterActionType.CLEAR_REGISTER_ERRORS,
});
export const clearRegistrationState = () => ({
    type: RegisterActionType.CLEAR_REGISTER_STATE,
});
export const registerUserSuccess = () => ({
    type: RegisterActionType.REGISTER_USER_SUCCESS,
});
export const registerUserFailed = (error) => ({
    type: RegisterActionType.REGISTER_USER_FAILED,
    error,
});
const actions = {
    registerUser,
    clearRegistrationErrors,
    clearRegistrationState,
    registerUserSuccess,
    registerUserFailed,
};
export default actions;
