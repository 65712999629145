import { Record, List, Map } from 'immutable';
import _ from 'lodash';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { Context } from '../../action-context';
import { ScheduleActionType, } from '../../actions/schedule/schedule-actions';
function listSchedules(state, action) {
    return state.merge({
        errors: Map(),
        loadState: LoadingStoreState.Loading,
    });
}
function listSchedulesSuccess(state, action) {
    const { schedules } = action;
    return state.merge({
        schedules: List(schedules),
        loadState: LoadingStoreState.Loaded,
    });
}
function listSchedulesError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: LoadingStoreState.LoadingFailed,
        loadError: error,
        schedules: List([]),
    });
}
function deleteSchedule(state, action) {
    return state.merge({
        isDeleting: true,
    });
}
function deleteScheduleSuccess(state, action) {
    const { scheduleId } = action;
    let schedules = state.get('schedules');
    const removeAtIndex = schedules.findIndex((schedule) => caseInsensitiveCultureInvariantCompare(schedule.id, scheduleId) === 0);
    if (removeAtIndex > -1) {
        schedules = schedules.remove(removeAtIndex);
    }
    return state.merge({
        schedules,
        isDeleting: false,
    });
}
function deleteScheduleError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addError(errors, error, error.id);
    return state.merge({
        errors,
        isDeleting: false,
    });
}
function createSchedule(state, action) {
    return state.merge({
        isCreating: true,
    });
}
function createScheduleSuccess(state, action) {
    const { schedule } = action;
    let schedules = state.get('schedules');
    schedules = schedules.push(schedule);
    return state.merge({
        schedules,
        isCreating: false,
    });
}
function createScheduleError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addError(errors, error, error.id);
    return state.merge({
        errors,
        isCreating: false,
    });
}
function updateSchedule(state, action) {
    return state.merge({
        isUpdating: true,
    });
}
function updateScheduleSuccess(state, action) {
    const { schedule } = action;
    let schedules = state.get('schedules');
    const index = schedules.findIndex((sch) => caseInsensitiveCultureInvariantCompare(sch.id, schedule.id) === 0);
    // ignoring an update if it is no longer in the store, should happen, but might happen
    // with a race condition when a user navigates to another lab
    if (index > -1) {
        schedules = schedules.set(index, schedule);
    }
    return state.merge({
        schedules,
        isUpdating: false,
    });
}
function updateScheduleError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addError(errors, error, error.id);
    return state.merge({
        errors,
        isUpdating: false,
    });
}
function clearLoadError(state, action) {
    return state.merge({
        loadError: undefined,
    });
}
function clearError(state, action) {
    const { id, errorIndex } = action;
    let errors = state.get('errors');
    let errorList = errors.get(id);
    if (!errorList || errorIndex >= errorList.size) {
        return state;
    }
    errorList = errorList.remove(errorIndex);
    errors = errorList.size > 0 ? errors.set(id, errorList) : errors.remove(id);
    return state.merge({
        errors,
    });
}
function addError(errorMap, error, id) {
    const errors = errorMap.get(id);
    if (errors) {
        const existingError = errors.some((_error) => _.isEqual(_error, error));
        if (!existingError) {
            return errorMap.set(id, errors.push(error));
        }
    }
    else {
        return errorMap.set(id, List([error]));
    }
    return errorMap;
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    schedules: List(),
    errors: Map(),
    loadError: undefined,
    isDeleting: false,
    isCreating: false,
    isUpdating: false,
})();
export const scheduleReducer = (state = initialState, action) => {
    switch (action.type) {
        case ScheduleActionType.CLEAR_USER_SESSION:
        case ScheduleActionType.SELECT_LAB_PARENT_RESOURCE:
        case ScheduleActionType.SELECT_LAB:
        case ScheduleActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    switch (action.type) {
                        case ScheduleActionType.LIST_SCHEDULES:
                            return listSchedules(state, action);
                        case ScheduleActionType.LIST_SCHEDULES_SUCCESS:
                            return listSchedulesSuccess(state, action);
                        case ScheduleActionType.LIST_SCHEDULES_ERROR:
                            return listSchedulesError(state, action);
                        case ScheduleActionType.DELETE_SCHEDULE:
                            return deleteSchedule(state, action);
                        case ScheduleActionType.DELETE_SCHEDULE_SUCCESS:
                            return deleteScheduleSuccess(state, action);
                        case ScheduleActionType.DELETE_SCHEDULE_ERROR:
                            return deleteScheduleError(state, action);
                        case ScheduleActionType.CREATE_SCHEDULE:
                            return createSchedule(state, action);
                        case ScheduleActionType.CREATE_SCHEDULE_SUCCESS:
                            return createScheduleSuccess(state, action);
                        case ScheduleActionType.CREATE_SCHEDULE_ERROR:
                            return createScheduleError(state, action);
                        case ScheduleActionType.UPDATE_SCHEDULE:
                            return updateSchedule(state, action);
                        case ScheduleActionType.UPDATE_SCHEDULE_SUCCESS:
                            return updateScheduleSuccess(state, action);
                        case ScheduleActionType.UPDATE_SCHEDULE_ERROR:
                            return updateScheduleError(state, action);
                        case ScheduleActionType.CLEAR_LOAD_SCHEDULE_ERROR:
                            return clearLoadError(state, action);
                        case ScheduleActionType.CLEAR_SCHEDULE_ERROR:
                            return clearError(state, action);
                        default:
                            return state;
                    }
                default:
                    return state;
            }
    }
};
export default scheduleReducer;
