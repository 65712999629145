import { ActionButton, IconButton, Stack } from '@fluentui/react';
import moment from 'moment';
import 'moment/min/locales';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { Field } from 'react-final-form';
import commonMessages from '../language/common-messages';
import { validateRequired } from '../utils/validators';
import { ScheduleType, RecurrenceType } from './flyout';
import { TimePicker, TimezonePicker, Notes, validateLength, StartDatePickerField } from './flyout-shared-controls';
import { dropdownTimes, dropdownTimezones, mapDayToLocaleDay, sortDays } from './flyout-utilities';
import scheduleMessages from './schedule-messages';
import './schedule.css';
const FlyoutFormInjected = (props) => {
    const { locale, intl, isSaving, deleteRecurrence, scheduleType, values, onDialogOpen, setRecurValues } = props;
    const dateLabel = intl.formatMessage(scheduleMessages.dateLabel);
    const startTimeLabel = intl.formatMessage(scheduleMessages.startTimeLabel);
    const stopTimeLabel = intl.formatMessage(scheduleMessages.stopTimeLabel);
    const timeZoneLabel = intl.formatMessage(scheduleMessages.timeZoneLabel);
    const selectTimezonePlaceholder = intl.formatMessage(scheduleMessages.selectTimezonePlaceholder);
    const notesLabel = intl.formatMessage(scheduleMessages.notesLabel);
    const repeat = intl.formatMessage(scheduleMessages.repeatLabel);
    const none = intl.formatMessage(commonMessages.none);
    const deleteRecurrenceLabel = intl.formatMessage(scheduleMessages.deleteRecurrenceLabel);
    const repeatButtonLabel = values.endDatePicker
        ? intl.formatMessage(scheduleMessages.occurenceWithEndDateLabel, {
            days: values
                .dayPicker.sort((a, b) => sortDays(locale, a, b))
                .map((day) => mapDayToLocaleDay(locale, day))
                .join(', '),
            date: moment(values.endDatePicker).locale(locale).format('ll'),
        })
        : intl.formatMessage(scheduleMessages.occurenceNoEndDateLabel, { days: values.dayPicker.join(', ') });
    const validationMessages = {
        selectedDaysMessage: intl.formatMessage(scheduleMessages.selectedDaysValidationMessage),
        startTimeMessage: intl.formatMessage(scheduleMessages.startTimeValidationMessage),
        stopTimeMessage: intl.formatMessage(scheduleMessages.stopTimeValidationMessage),
        minimumDurationValidationMessage: intl.formatMessage(scheduleMessages.minimumDurationValidationMessage),
        timezoneMessage: intl.formatMessage(scheduleMessages.timezoneValidationMessage),
        notesMessage: intl.formatMessage(scheduleMessages.notesValidationMessage),
    };
    return (<div>
            <div className="schedules__flyout-control">
                <StartDatePickerField name="startDatePicker" label={dateLabel} locale={locale} intl={intl} maxDate={values.endDatePicker} isSaving={isSaving} required={true}/>
            </div>

            {scheduleType !== ScheduleType.stopOnly && (<div className="schedules__flyout-control">
                    <Field name="startTimePicker" label={startTimeLabel} component={TimePicker} isSaving={isSaving} options={dropdownTimes(locale)}/>
                </div>)}

            {scheduleType !== ScheduleType.startOnly && (<div className="schedules__flyout-control">
                    <Field name="stopTimePicker" label={stopTimeLabel} component={TimePicker} isSaving={isSaving} options={dropdownTimes(locale)}/>
                </div>)}

            <div className="schedules__flyout-control">
                <Field name="timezonePicker" label={timeZoneLabel} placeHolder={selectTimezonePlaceholder} component={TimezonePicker} options={dropdownTimezones(intl)} isSaving={isSaving} validate={(value) => validateRequired(value, validationMessages.timezoneMessage)}/>
            </div>

            <div className="schedules__flyout-control">
                <div className="schedules__flyout-control--label">
                    <label className="ms-Label" htmlFor="repeatButton">
                        {repeat}
                    </label>
                </div>
                <div className="schedules__flyout--repeat">
                    <Stack horizontal>
                        <Stack.Item>
                            <ActionButton id="repeatButton" disabled={isSaving} ariaLabel={`${repeat}. ${repeatButtonLabel}`} styles={{
        root: { paddingLeft: '0px', marginLeft: '0px', textAlign: 'left', height: 'unset' },
        label: { lineHeight: '19px' },
        flexContainer: { alignItems: 'flex-start' },
    }} onClick={() => {
        const recurValues = {
            recurrencePicker: values.recurrencePicker,
            dayPicker: values.dayPicker,
            endDatePicker: values.endDatePicker,
        };
        setRecurValues(recurValues);
        onDialogOpen();
    }} iconProps={{
        iconName: 'Edit',
        styles: {
            root: {
                paddingRight: '5px',
                paddingTop: '4px',
                color: isSaving ? '#A19F9D' : undefined,
                marginLeft: '0px',
            },
        },
    }}>
                                {values.recurrencePicker === RecurrenceType.weekly ? repeatButtonLabel : none}
                            </ActionButton>
                        </Stack.Item>
                        <Stack.Item align="start">
                            {values.recurrencePicker === RecurrenceType.weekly && (<IconButton className="schedules__flyout--repeat-delete" disabled={isSaving} onClick={deleteRecurrence} styles={{
        root: {
            height: '25px',
            paddingTop: '6px',
            paddingBottom: '6px',
        },
    }} iconProps={{ iconName: 'Cancel' }} title={deleteRecurrenceLabel} ariaLabel={deleteRecurrenceLabel}/>)}
                        </Stack.Item>
                    </Stack>
                </div>
            </div>

            <div className="schedules__flyout-control">
                <Field name="notes" isSaving={isSaving} label={notesLabel} component={Notes} validate={(value) => validateLength(value, validationMessages.notesMessage)}/>
            </div>
        </div>);
};
export const FlyoutForm = injectIntl(FlyoutFormInjected);
export default FlyoutForm;
