import * as React from 'react';
import { connect } from 'react-redux';
import ErrorView from './error-view';
import { clearFatalError, initialize } from '../redux/actions/common/common-action-creators';
import { signIn, requestUserConsent } from '../redux/actions/identity/identity-action-creators';
import { getSearch, isLtiOidcRoute, isRouteTeamsConfig } from '../redux/selectors/route-selector';
import { Page } from '../common/page/page';
import { getTenants } from '../redux/selectors/tenant-selectors';
import { isLmsHost } from '../redux/selectors/common-selectors';
import { hasGrantedWebStorageAccess, isLmsStudent } from '../redux/selectors/lti-selectors';
import { ltiRequestWebStorageAccess } from '../redux/actions/lti/lti-action-creators';
const ErrorViewContainerInjected = (props) => {
    const { hasError, search, fatalErrorCode: errorCode, clearFatalError: clearError, signIn, requestUserConsent, hideHeader, hasMultipleTenants, initialize, isLmsHosted, isRouteLtiOidc, isLmsStudent, ltiRequestWebStorageAccess, hasWebStorageAccessGranted, } = props;
    if (!hasError) {
        return null;
    }
    return (<Page hideHeader={hideHeader} headerProps={isRouteLtiOidc || isLmsStudent
        ? {
            hideLabParentPicker: true,
            hideLabPicker: true,
            hideVmListButton: true,
            hideUserMenu: true,
            hideFeedback: true,
        }
        : { hideFeedback: true }}>
            <ErrorView search={search} errorCode={errorCode} clearError={clearError} signIn={isLmsHosted ? () => initialize(true) : () => signIn} requestUserConsent={requestUserConsent} hasMultipleTenants={hasMultipleTenants} isLmsHosted={isLmsHosted} ltiRequestWebStorageAccess={ltiRequestWebStorageAccess} hasWebStorageAccessGranted={hasWebStorageAccessGranted}/>
        </Page>);
};
const mapStateToProps = (state) => {
    const commonStore = state.get('commonStore');
    const fatalErrorCode = commonStore.get('fatalErrorCode');
    const hasError = fatalErrorCode !== undefined;
    const search = getSearch(state);
    const hideHeader = isRouteTeamsConfig(state);
    const tenants = getTenants(state);
    const hasMultipleTenants = tenants.size > 1;
    const isRouteLtiOidc = isLtiOidcRoute(state);
    const isLmsHosted = isLmsHost(state) || isRouteLtiOidc;
    const isCourseStudent = isLmsStudent(state);
    const hasWebStorageAccessGranted = hasGrantedWebStorageAccess(state);
    return {
        hasError,
        search,
        fatalErrorCode,
        hideHeader,
        hasMultipleTenants,
        isLmsHosted,
        isRouteLtiOidc,
        isLmsStudent: isCourseStudent,
        hasWebStorageAccessGranted,
    };
};
const mapDispatchToProps = {
    clearFatalError,
    signIn,
    requestUserConsent,
    initialize,
    ltiRequestWebStorageAccess,
};
export const ErrorViewContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorViewContainerInjected);
export default ErrorViewContainer;
