import * as React from 'react';
import { connect } from 'react-redux';
import { signIn } from '../redux/actions/identity/identity-action-creators';
import { UserLoggedOut } from './user-logged-out';
import { Page } from '../common/page/page';
import { initialize } from '../redux/actions/common/common-action-creators';
import { isLmsHost } from '../redux/selectors/common-selectors';
const UserLoggedOutContainerInjected = (props) => {
    const { signIn, initialize, shouldOpenPopupWindow } = props;
    return (<Page headerProps={{ hideLabParentPicker: true }}>
            
            <UserLoggedOut signIn={shouldOpenPopupWindow ? () => initialize(true) : () => signIn(true, false)}/>
        </Page>);
};
const mapStateToProps = (state) => {
    const isLms = isLmsHost(state);
    return {
        shouldOpenPopupWindow: isLms,
    };
};
const mapDispatchToProps = {
    signIn,
    initialize,
};
export const UserLoggedOutContainer = connect(mapStateToProps, mapDispatchToProps)(UserLoggedOutContainerInjected);
export default UserLoggedOutContainer;
