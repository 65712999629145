import memoize from 'memoize-one';
import { MlCoreFamily } from '../../data/models/core-quota-data';
import { determineVNextLabSkuRemainingVms } from '../../common/selectors/usage-selectors';
export const CoreQuotaStatus = {
    Exempt: 'Exempt',
    Restricted: 'Restricted',
    QuotaExceeded: 'QuotaExceeded',
    QuotaNotExceeded: 'QuotaNotExceeded',
    Unknown: 'Unknown',
};
export const convertToRestModel = (uiModel) => {
    const restModel = {
        isExempt: uiModel.isExempt,
        restrictions: [],
        usages: [],
    };
    uiModel.restrictions.forEach((value, key) => {
        restModel.restrictions.push({ coreFamily: key, numberOfCores: value });
    });
    uiModel.usages.forEach((value, key) => {
        restModel.usages.push({ coreFamily: key, numberOfCores: value });
    });
    return restModel;
};
export const getCoreLimits = memoize((state) => {
    const coreLimitStore = state.get('coreLimitStore');
    return coreLimitStore.get('coreQuotaData');
});
export const determineVCurrentQuotaStatus = memoize((coreQuotaData) => {
    if (!coreQuotaData) {
        return CoreQuotaStatus.Unknown;
    }
    if (coreQuotaData.isExempt === undefined) {
        return CoreQuotaStatus.Unknown;
    }
    if (coreQuotaData.isExempt) {
        return CoreQuotaStatus.Exempt;
    }
    const { restrictions, usages } = coreQuotaData;
    if (!restrictions || !usages) {
        return CoreQuotaStatus.Unknown;
    }
    const gpuLimit = restrictions.get(MlCoreFamily.GPU);
    const standardLimit = restrictions.get(MlCoreFamily.Standard);
    if (gpuLimit === 0 && standardLimit === 0) {
        return CoreQuotaStatus.Restricted;
    }
    const gpuUsage = usages.get(MlCoreFamily.GPU);
    const standardUsage = usages.get(MlCoreFamily.Standard);
    if (gpuLimit === undefined ||
        standardLimit === undefined ||
        gpuUsage === undefined ||
        standardUsage === undefined) {
        return CoreQuotaStatus.Unknown;
    }
    if (gpuUsage >= gpuLimit && standardUsage >= standardLimit) {
        return CoreQuotaStatus.QuotaExceeded;
    }
    return CoreQuotaStatus.QuotaNotExceeded;
});
/**
 * Determines additional number of VMs a lab can have
 * If lab is published, then their cores are already accounted for and we return the remaining number of VMs in the subscription
 * If lab is not published, then the max users in lab is not accounted for in our recorded usage and we have to include that
 */
export const determineAdditionalVms = memoize((numUsersInLab, hasBeenPublished, remainingVmsInLab) => {
    if (remainingVmsInLab === undefined) {
        return;
    }
    if (hasBeenPublished) {
        return remainingVmsInLab;
    }
    if (numUsersInLab === undefined) {
        return remainingVmsInLab;
    }
    if (remainingVmsInLab - numUsersInLab < 0) {
        return 0;
    }
    return remainingVmsInLab - numUsersInLab;
});
/**
 * Determines remaining number of VMs the subscription can provision given the size of a VM and its core family
 */
export const determineVCurrentRemainingVms = memoize((isGpu, coresPerVm, coreQuotaData) => {
    if (!coreQuotaData) {
        return;
    }
    if (coresPerVm === undefined) {
        return;
    }
    if (coreQuotaData.isExempt === undefined) {
        return;
    }
    if (coreQuotaData.isExempt) {
        return;
    }
    const { restrictions, usages } = coreQuotaData;
    if (!restrictions || !usages) {
        return;
    }
    const gpuLimit = restrictions.get(MlCoreFamily.GPU);
    const standardLimit = restrictions.get(MlCoreFamily.Standard);
    if (gpuLimit === 0 && standardLimit === 0) {
        return 0;
    }
    const gpuUsage = usages.get(MlCoreFamily.GPU);
    const standardUsage = usages.get(MlCoreFamily.Standard);
    const remainingCores = isGpu ? gpuLimit - gpuUsage : standardLimit - standardUsage;
    const remainingVms = Math.floor(remainingCores / coresPerVm);
    return remainingVms;
});
export const safeGetRestrictions = memoize((coreFamily, coreQuotaData) => {
    if (!coreQuotaData) {
        return;
    }
    if (coreQuotaData.isExempt === undefined) {
        return;
    }
    if (coreQuotaData.isExempt) {
        return;
    }
    const { restrictions } = coreQuotaData;
    if (!restrictions) {
        return;
    }
    return restrictions.get(coreFamily);
});
export const safeGetUsages = memoize((coreFamily, coreQuotaData) => {
    if (!coreQuotaData) {
        return;
    }
    if (coreQuotaData.isExempt === undefined) {
        return;
    }
    if (coreQuotaData.isExempt) {
        return;
    }
    const { usages } = coreQuotaData;
    if (!usages) {
        return;
    }
    return usages.get(coreFamily);
});
export const determineVCurrentOrVNextLabRemainingVms = memoize((isVNext, isGpu, coresPerVm, coreQuotaData, currentLabCapacity) => {
    if (!coreQuotaData) {
        return undefined;
    }
    return !isVNext
        ? determineVCurrentRemainingVms(isGpu, coresPerVm, coreQuotaData)
        : determineVNextLabSkuRemainingVms(coreQuotaData, currentLabCapacity, coresPerVm);
});
const CoreLimitSelectors = {
    determineAdditionalVms,
    safeGetRestrictions,
    safeGetUsages,
    convertToRestModel,
    determineVCurrentOrVNextLabRemainingVms,
};
export default CoreLimitSelectors;
