import { DefaultPollingDelayInterval, FifteenSecondInterval } from '../../utils/constants';
import { VmState } from '../../data/models/environment-common';
export function determinePollingInitialDelay(vms, isVNext, isTemplateVM) {
    let isStarting, isStopping;
    if (isTemplateVM) {
        isStarting = vms.isStarting;
        isStopping = vms.isStopping;
    }
    else {
        const vmDetails = vms;
        isStarting = vmDetails.some((vm) => vm.vmState === VmState.Starting);
        isStopping = vmDetails.some((vm) => vm.vmState === VmState.Stopping);
    }
    if (isVNext && (isStarting || isStopping)) {
        return FifteenSecondInterval;
    }
    return DefaultPollingDelayInterval;
}
