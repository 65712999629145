import { DefaultButton, Dialog, DialogFooter, PrimaryButton, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../language/common-messages';
import { ClockIcon } from '../common/clock-icon';
import Constants from '../utils/constants';
const messages = defineMessages({
    title: {
        id: 'SyncUsersDialogTitle',
        defaultMessage: 'Sync from group',
        description: 'Title of confirmation dialog when syncing users',
    },
});
const SyncUsersDialog = (props) => {
    const intl = useIntl();
    const msg = intl.formatMessage;
    const { groupName, onSubmit, onDismiss } = props;
    return (<Dialog hidden={false} onDismiss={onDismiss} dialogContentProps={{ title: msg(messages.title) }} closeButtonAriaLabel={msg(commonMessages.cancel)} minWidth={Constants.DefaultDialogWideWidth}>
            <Stack tokens={{ childrenGap: '30px' }}>
                <Stack.Item styles={{ root: { paddingTop: '15px' } }}>
                    {!!groupName && (<FormattedMessage id="SyncUsersDialogContent" defaultMessage='This lab automatically syncs from the group "<aadGroupName></aadGroupName>" every 24 hours. If you would like to sync now, it will make the list of users unavailable while the operation is taking place.' description="Content of confirmation dialog when syncing users." values={{
        aadGroupName: () => <span style={{ fontWeight: 600 }}>{groupName}</span>,
    }}/>)}
                    {!groupName && (<FormattedMessage id="SyncUsersDialogContentNoGroupName" defaultMessage="This lab automatically syncs from a group every 24 hours. If you would like to sync now, it will make the list of users unavailable while the operation is taking place." description="Content of confirmation dialog when syncing users."/>)}
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal tokens={{ childrenGap: 10 }} verticalAlign="end" verticalFill style={{ marginBottom: 20 }}>
                        <Stack.Item>
                            <ClockIcon />
                        </Stack.Item>
                        <Stack.Item styles={{ root: { paddingBottom: '2px' } }}>
                            <FormattedMessage id="SyncUsersTimeMessage" defaultMessage="This process can take up to <tenMinutes>10 minutes</tenMinutes>" values={{
        tenMinutes: (chunks) => <span style={{ fontWeight: 600 }}>{chunks}</span>,
    }}/>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </Stack>
            <DialogFooter styles={{ actions: { marginTop: '27px' } }}>
                <PrimaryButton text={msg(commonMessages.sync)} onClick={onSubmit}/>
                <DefaultButton autoFocus text={msg(commonMessages.cancel)} onClick={onDismiss}/>
            </DialogFooter>
        </Dialog>);
};
export default SyncUsersDialog;
