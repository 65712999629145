import { all, call, put, select } from 'redux-saga/effects';
import { MlClientError, FailureOperation } from '../../../data/ml-client-error';
import EnvironmentProvider from '../../../data/providers/environment-provider';
import { ResourceId } from '../../../utils/resource-id';
import { resetEnvironmentsError, resetEnvironmentAccepted, } from '../../actions/environment/environment-action-creators';
import { EnvironmentActionType } from '../../actions/environment/environment-actions';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import VirtualMachineProvider from '../../../data/providers/vnext/arm-api/virtual-machine-provider';
import { labParentBasedTakeEvery } from '../../effects/lab-parent-based';
import { GetOperationsResult } from '../../actions/operations/operations-result-action-creators';
export async function callResetEnvironment(resourceId, accessToken, locale, language, errors) {
    try {
        await EnvironmentProvider.deleteEnvironment(resourceId, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.ResetEnvironments, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* resetEnvironments(action) {
    const { environmentIds } = action;
    try {
        const resourceIds = environmentIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        yield all(resourceIds.map((resourceId) => call(callResetEnvironment, resourceId, accessToken, locale, language, errors)));
        if (Object.keys(errors).length > 0) {
            yield put(resetEnvironmentsError(errors));
        }
    }
    catch (e) {
        const errors = {};
        const error = new MlClientError(e ? e.message : undefined, 'all', FailureOperation.ResetEnvironments, e ? e.code : undefined);
        errors[error.id] = error;
        yield put(resetEnvironmentsError(errors));
    }
}
export async function callResetVNextVirtualMachine(resourceId, accessToken, locale, language, errors) {
    try {
        const reimageVmResult = await VirtualMachineProvider.reimageVirtualMachine(resourceId, accessToken, locale, language);
        reimageVmResult['ResourceId'] = resourceId;
        return reimageVmResult;
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.ResetEnvironments, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* resetVNextVirtualMachines(action) {
    const { environmentIds: virtualMachineIds } = action;
    try {
        const resourceIds = virtualMachineIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        const resetVmsResult = yield all(resourceIds.map((resourceId) => call(callResetVNextVirtualMachine, resourceId, accessToken, locale, language, errors)));
        if (Object.keys(errors).length > 0) {
            yield put(resetEnvironmentsError(errors));
        }
        yield put(resetEnvironmentAccepted(resetVmsResult));
        yield put(GetOperationsResult(''));
    }
    catch (e) {
        const errors = {};
        const error = new MlClientError(e ? e.message : undefined, 'all', FailureOperation.ResetEnvironments, e ? e.code : undefined);
        errors[error.id] = error;
        yield put(resetEnvironmentsError(errors));
    }
}
export function* resetEnvironmentsSaga() {
    yield labParentBasedTakeEvery(EnvironmentActionType.RESET_ENVIRONMENTS, resetEnvironments, resetVNextVirtualMachines);
}
