import { CommandBar, ContextualMenuItemType, DefaultButton, } from '@fluentui/react';
import _ from 'lodash';
import * as React from 'react';
import { useIntl } from 'react-intl';
import commonMessages from '../../language/common-messages';
import FeatureMenu from './feature-menu';
import HelpFlyout from './help-flyout';
import { LabPicker } from './lab-picker';
import VmListFlyout from './vm-list-flyout';
import { LoadingItem } from './dropdown/loading-item';
import { Selected } from './lab-parent-picker';
import './page-header.css';
const styles = {
    root: {
        height: '52px',
        width: '100%',
        backgroundColor: '#F3F2F1',
        color: '#484644',
        border: '0px',
        borderRadius: '0px',
    },
    rootHovered: {
        backgroundColor: '#EDEBE9',
    },
    rootPressed: {
        backgroundColor: '#E1DFDD',
    },
    icon: {
        color: '#252424',
    },
};
const TeamsPageHeader = (props) => {
    const { isLoading, currentLabParentItem, onClick, labPickerProps, featureMenuProps, isVmListEnabled, helpFlyoutPropsForSupportSection, } = props;
    const [showHelpFlyout, setShowHelpFlyout] = React.useState(false);
    const [showVmListFlyout, setShowVmListFlyout] = React.useState(false);
    const intl = useIntl();
    const items = [];
    const buttonStyles = _.merge({}, styles, {
        root: { paddingLeft: 8, paddingRight: 8 },
    });
    if (!!currentLabParentItem) {
        items.push({
            key: 'CurrentLabAccount',
            onRender: () => (<div role="menuitem">
                    {!!labPickerProps && (<DefaultButton onClick={() => onClick && onClick(currentLabParentItem)} role="menuitem" styles={buttonStyles}>
                            <Selected item={currentLabParentItem} isTeamsOrLmsIntegrationEnabled={true}/>
                        </DefaultButton>)}
                    {!labPickerProps && <Selected item={currentLabParentItem} isTeamsOrLmsIntegrationEnabled={true}/>}
                </div>),
        });
    }
    else if (!!isLoading) {
        items.push({
            key: 'Loading',
            onRender: () => (<div role="menuitem">
                    <LoadingItem styles={buttonStyles}/>
                </div>),
        });
    }
    if (labPickerProps) {
        items.push({
            key: 'LabAccountLabSeparator',
            itemType: ContextualMenuItemType.Divider,
            onRender: () => <span className="page-header__separator ms-fontSize-mPlus">/</span>,
        });
        items.push({
            key: 'LabPicker',
            onRender: () => (<div role="menuitem">
                    <LabPicker {...labPickerProps} styles={buttonStyles}/>
                </div>),
        });
    }
    const farItems = [];
    if (isVmListEnabled) {
        const text = intl.formatMessage(commonMessages.myVirtualMachines);
        farItems.push({
            key: 'VirtualMachines',
            text,
            ariaLabel: text,
            iconProps: { iconName: 'TVMonitor' },
            iconOnly: true,
            buttonStyles: styles,
            onClick: () => setShowVmListFlyout(true),
        });
    }
    const text = intl.formatMessage(commonMessages.help);
    farItems.push({
        key: 'StatusCircleQuestionMark',
        text,
        ariaLabel: text,
        iconProps: { iconName: 'Help' },
        iconOnly: true,
        buttonStyles: styles,
        onClick: () => setShowHelpFlyout(true),
    });
    if (featureMenuProps) {
        farItems.push({
            key: 'FeatureMenu',
            onRender: () => (<div role="menuitem">
                    <FeatureMenu {...featureMenuProps} styles={styles}/>
                </div>),
        });
    }
    return (<div id="teams-page-header-container" role="banner">
            <CommandBar items={items} farItems={farItems} overflowButtonProps={{
        styles,
    }} styles={{
        root: {
            height: '100%',
            width: '100%',
            backgroundColor: '#F3F2F1',
            color: '#484644',
            margin: '0 0 0 0',
            padding: '0 0 0 0',
        },
    }}/>
            {showHelpFlyout && (<HelpFlyout {...helpFlyoutPropsForSupportSection} onDismiss={() => setShowHelpFlyout(false)}/>)}
            {isVmListEnabled && showVmListFlyout && <VmListFlyout onDismiss={() => setShowVmListFlyout(false)}/>}
        </div>);
};
export default TeamsPageHeader;
