import { LabParentResourceActionType, } from './lab-parent-resource-actions';
export const selectLabParentResource = (resourceId) => ({
    type: LabParentResourceActionType.SELECT_LAB_PARENT_RESOURCE,
    resourceId,
});
export const clearSelectedLabParentResource = () => ({
    type: LabParentResourceActionType.CLEAR_SELECTED_LAB_PARENT_RESOURCE,
});
export const listLabParentResources = (selectFirst, selectFirstIfSelectedNotFound) => ({
    type: LabParentResourceActionType.LIST_LAB_PARENT_RESOURCES,
    selectFirst,
    selectFirstIfSelectedNotFound,
});
export const listLabParentResourcesSuccess = () => ({
    type: LabParentResourceActionType.LIST_LAB_PARENT_RESOURCES_SUCCESS,
});
export const listLabParentResourcesError = (error) => ({
    type: LabParentResourceActionType.LIST_LAB_PARENT_RESOURCES_ERROR,
    error,
});
export const refreshSubscriptionsAndLabParentResources = () => ({
    type: LabParentResourceActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES,
});
export const refreshSubscriptionsAndLabParentResourcesSuccess = () => ({
    type: LabParentResourceActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES_SUCCESS,
});
export const refreshSubscriptionsAndLabParentResourcesError = (refreshError) => ({
    type: LabParentResourceActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES_ERROR,
    refreshError,
});
const actions = {
    selectLabParentResource,
    listLabParentResources,
    listLabParentResourcesError,
    listLabParentResourcesSuccess,
    refreshSubscriptionsAndLabParentResources,
    refreshSubscriptionsAndLabParentResourcesSuccess,
    refreshSubscriptionsAndLabParentResourcesError,
    clearSelectedLabParentResource,
};
export default actions;
