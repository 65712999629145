import { ChoiceGroup, DefaultButton, Dialog, DialogFooter, DialogType, Link, PrimaryButton, Spinner, SpinnerSize, TextField, } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import commonMessages from '../language/common-messages';
import Constants from '../utils/constants';
import { ClockInfoProcessEstimate } from './clock-info-process-estimate';
import { validateSharedImageName } from './image-name-validator';
import templateMessages from './template-messages';
import './export-sig-dialog.css';
const messages = defineMessages({
    sigNameLabel: {
        id: 'SigNameLabel',
        defaultMessage: 'Virtual machine image name',
        description: 'Label for the shared image name text field, where user can input the name of their new shared image',
    },
    createNewImage: {
        id: 'CreateNewImage',
        defaultMessage: 'Create new image',
        description: 'Label on radio button indicating this is the create new image option',
    },
    updateExistingImage: {
        id: 'UpdateExistingImage',
        defaultMessage: 'Update existing image',
        description: 'Label on radio button indicating this is the update existing image option',
    },
});
export const ImageOperation = {
    create: 'create',
    update: 'update',
};
const Summary = (props) => {
    const sharedImageGalleryLink = (<Link target="_blank" href="https://www.aka.ms/azlabs-sig">
            <FormattedMessage id="SharedImageGalleryLinkText" defaultMessage="Azure Compute Gallery" description="Text to indicate Azure Compute Gallery.  This will be contained in a link to redirect to Azure Compute Gallery documentation.  This will be used in the ExportSigInfoAndLink message as the sharedImageGalleryLink parameter."/>
        </Link>);
    return (<>
            <FormattedMessage id="ExportSigInfoAndLink" defaultMessage="Exporting an image to the {sharedImageGalleryLink} allows it to be used when a new template is created in other labs." description="Informational text explaining that exporting an image to the Azure Compute Gallery lets it be used as a template when creating other labs. The parameter {sharedImageGalleryLink} contains a localized link to more documentation for Azure Compute Gallery.  The link text comes from SharedGalleryLinkText." values={{ sharedImageGalleryLink }}/>
            {!props.sharedImage && (<>
                    <span className="export-sig-dialog__re-export_heavy-info">
                        <FormattedMessage id="ExportSpecializedSigUserDataInfo" defaultMessage="User-level data and settings will be included in the exported image." description="Message that user data will be lost during export."/>
                    </span>{' '}
                    <Link target="_blank" href="https://www.aka.ms/azlabs-sigexport">
                        <FormattedMessage id="SharedImageGalleryExportLinkText" defaultMessage="Learn more about the data that stays on the image." description="This will be contained in a link to redirect to exporting Azure Compute Gallery documentation."/>
                    </Link>
                </>)}
            {!!props.sharedImage && (<span className="export-sig-dialog__re-export_heavy-info">
                    <FormattedMessage id="ReExportSigRevertInfo" defaultMessage="An image cannot be reverted once it is updated." description="Message that images cannot be reverted once they are updated."/>
                </span>)}
        </>);
};
const SharedImageTextField = (props) => {
    const intl = useIntl();
    const sigNameLabel = intl.formatMessage(messages.sigNameLabel);
    const onRenderSuffix = () => (props.isLoading ? <Spinner size={SpinnerSize.xSmall}/> : <></>);
    return (<TextField styles={{
        suffix: { backgroundColor: 'transparent' },
    }} autoFocus deferredValidationTime={500} disabled={props.isLoading} onRenderSuffix={onRenderSuffix} label={sigNameLabel} ariaLabel={sigNameLabel} errorMessage={props.errorMessage} onChange={props.onChange}/>);
};
const SharedImageForm = (props) => {
    const { isLoading, sharedImage, imageOperation, onChange, errorMessage, onUpdateOperation } = props;
    const intl = useIntl();
    const createImage = intl.formatMessage(messages.createNewImage);
    const updateImage = intl.formatMessage(messages.updateExistingImage);
    return (<>
            {!!sharedImage ? (<ChoiceGroup selectedKey={imageOperation} onChange={(_, option) => onUpdateOperation(option.key)} options={[
        {
            key: ImageOperation.create,
            text: createImage,
            ariaLabel: createImage,
            styles: { choiceFieldWrapper: { width: '100%' } },
            onRenderField: (props, render) => {
                return (<>
                                        <div className="export-sig-dialog__re-export_create--text">
                                            {render(props)}
                                        </div>
                                        <div className="export-sig-dialog__re-export_create--name-field">
                                            <SharedImageTextField isLoading={isLoading} onChange={onChange} errorMessage={errorMessage}/>
                                        </div>
                                    </>);
            },
        },
        {
            key: ImageOperation.update,
            text: updateImage,
            ariaLabel: updateImage,
            onRenderField: (props, render) => {
                return (<>
                                        {render(props)}
                                        <span className="export-sig-dialog__re-export_update--existing-name">{`(${sharedImage.displayName})`}</span>
                                    </>);
            },
        },
    ]}/>) : (<div className="export-sig-dialog__export_create--text">
                    <SharedImageTextField isLoading={isLoading} onChange={onChange} errorMessage={errorMessage}/>
                </div>)}
        </>);
};
export const ExportSigDialog = (props) => {
    const [sigName, updateSigName] = React.useState('');
    const [imageOperation, updateImageOperation] = React.useState(ImageOperation.create);
    const [errorMessage, updateErrorMessage] = React.useState('');
    const { sharedImages, sharedImage, templateId, isLoading, onDismiss, onCreate, onUpdate } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const cancelButtonLabel = msg(commonMessages.cancel);
    const exportLabel = msg(commonMessages.export);
    const exportToSigTitle = msg(templateMessages.exportToSharedImageGallery);
    const onChange = (_, newValue) => {
        updateSigName(newValue || '');
        validate(newValue);
    };
    const onDismissed = () => {
        updateSigName('');
        onDismiss();
    };
    const validate = (name) => {
        const errorMessage = validateSharedImageName(name, sharedImages, intl);
        updateErrorMessage(errorMessage);
    };
    return (<Dialog onDismiss={onDismissed} hidden={false} dialogContentProps={{
        type: DialogType.normal,
        title: exportToSigTitle,
    }} modalProps={{
        onDismissed,
    }} minWidth={Constants.DefaultDialogWidth}>
            <>
                <div className="export-sig-dialog--summary">
                    <Summary sharedImage={sharedImage}/>
                </div>
                <ClockInfoProcessEstimate timeEstimateMessage={msg(templateMessages.oneHourFormatted)}/>
                <SharedImageForm isLoading={isLoading} onChange={onChange} errorMessage={errorMessage} imageOperation={imageOperation} onUpdateOperation={(op) => updateImageOperation(op)} sharedImage={sharedImage}/>
            </>
            <DialogFooter styles={{ actions: { marginTop: '32px' } }}>
                <PrimaryButton onClick={() => {
        if (imageOperation === ImageOperation.create && templateId) {
            onCreate(templateId, sigName);
        }
        else if (imageOperation === ImageOperation.update &&
            templateId &&
            sharedImage &&
            sharedImage.id) {
            onUpdate(sharedImage.id, templateId);
        }
        onDismissed();
    }} text={exportLabel} ariaLabel={exportLabel} disabled={imageOperation === ImageOperation.create && (!!errorMessage || !sigName)}/>
                <DefaultButton onClick={onDismissed} ariaLabel={cancelButtonLabel} text={cancelButtonLabel}/>
            </DialogFooter>
        </Dialog>);
};
export default ExportSigDialog;
