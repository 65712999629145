import { UserEnvironmentActionType, } from './user-environment-actions';
export const pollListUserEnvironmentsStart = (intervalMs, aadGroupId, initialDelayMs) => ({
    type: UserEnvironmentActionType.POLL_LIST_GLOBAL_USER_ENVIRONMENTS_START,
    intervalMs,
    initialDelayMs,
    aadGroupId,
});
export const pollListUserEnvironmentsStop = () => ({
    type: UserEnvironmentActionType.POLL_LIST_GLOBAL_USER_ENVIRONMENTS_STOP,
});
export const pollListUserEnvironmentsCancelled = () => ({
    type: UserEnvironmentActionType.POLL_LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED,
});
export const listUserEnvironments = (aadGroupId) => ({
    type: UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS,
    aadGroupId,
});
export const listUserEnvironmentsCancelled = () => ({
    type: UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED,
});
export const listUserEnvironmentsSuccess = (environments) => ({
    type: UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_SUCCESS,
    environments,
});
export const listUserEnvironmentsError = (error) => ({
    type: UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_ERROR,
    error,
});
export const clearListUserEnvironmentError = () => ({
    type: UserEnvironmentActionType.CLEAR_LIST_GLOBAL_USER_ENVIRONMENTS_ERROR,
});
export const startEnvironment = (environmentId) => ({
    type: UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT,
    environmentId,
});
export const startUserEnvironmentFailed = (environmentId, error) => ({
    type: UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT_FAILED,
    environmentId,
    error,
});
export const startUserEnvironmentAccepted = (environmentId, operationIds) => ({
    type: UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT_ACCEPTED,
    environmentId,
    operationIds,
});
export const stopUserEnvironmentAccepted = (environmentId, operationIds) => ({
    type: UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT_ACCEPTED,
    environmentId,
    operationIds,
});
export const redeployUserEnvironmentAccepted = (environmentId, operationIds) => ({
    type: UserEnvironmentActionType.REDEPLOY_USER_VIRTUAL_MACHINE_ACCEPTED,
    environmentId,
    operationIds,
});
export const reimageUserEnvironmentAccepted = (environmentId, operationIds) => ({
    type: UserEnvironmentActionType.REIMAGE_USER_VIRTUAL_MACHINE_ACCEPTED,
    environmentId,
    operationIds,
});
export const clearStartUserEnvironmentError = (environmentId, errorIndex) => ({
    type: UserEnvironmentActionType.CLEAR_START_USER_ENVIRONMENT_ERROR,
    environmentId,
    errorIndex,
});
export const stopEnvironment = (environmentId) => ({
    type: UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT,
    environmentId,
});
export const stopUserEnvironmentFailed = (environmentId, error) => ({
    type: UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT_FAILED,
    environmentId,
    error,
});
export const clearStopUserEnvironmentError = (environmentId, errorIndex) => ({
    type: UserEnvironmentActionType.CLEAR_STOP_USER_ENVIRONMENT_ERROR,
    environmentId,
    errorIndex,
});
export const resetEnvironmentPassword = (environmentId, username, password) => ({
    type: UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD,
    environmentId,
    username,
    password,
});
export const resetUserEnvironmentPasswordFailed = (environmentId, error) => ({
    type: UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD_FAILED,
    environmentId,
    error,
});
export const clearResetUserEnvironmentPasswordError = (environmentId, errorIndex) => ({
    type: UserEnvironmentActionType.CLEAR_RESET_USER_ENVIRONMENT_PASSWORD_ERROR,
    environmentId,
    errorIndex,
});
export const clearUserEnvironmentErrors = () => ({
    type: UserEnvironmentActionType.CLEAR_GLOBAL_USER_ENVIRONMENT_ERRORS,
});
export const reimageUserVirtualMachine = (environmentId) => ({
    type: UserEnvironmentActionType.REIMAGE_USER_VIRTUAL_MACHINE,
    environmentId,
});
export const reimageUserVirtualMachineError = (environmentId, error) => ({
    type: UserEnvironmentActionType.REIMAGE_USER_VIRTUAL_MACHINE_ERROR,
    environmentId,
    error,
});
export const redeployUserVirtualMachine = (environmentId) => ({
    type: UserEnvironmentActionType.REDEPLOY_USER_VIRTUAL_MACHINE,
    environmentId,
});
export const redeployUserVirtualMachineError = (environmentId, error) => ({
    type: UserEnvironmentActionType.REDEPLOY_USER_VIRTUAL_MACHINE_ERROR,
    environmentId,
    error,
});
export const clearReimageUserVirtualMachineError = (environmentId, errorIndex) => ({
    type: UserEnvironmentActionType.CLEAR_REIMAGE_USER_VIRTUAL_MACHINE_ERROR,
    environmentId,
    errorIndex,
});
export const clearRedeployUserVirtualMachineError = (environmentId, errorIndex) => ({
    type: UserEnvironmentActionType.CLEAR_REDEPLOY_USER_VIRTUAL_MACHINE_ERROR,
    environmentId,
    errorIndex,
});
export const listAllUserVirtualMachinesSuccess = () => ({
    type: UserEnvironmentActionType.LIST_ALL_USER_VIRTUAL_MACHINES_SUCCESS,
});
export const listVNextUserVirtualMachines = (aadGroupId) => ({
    type: UserEnvironmentActionType.LIST_VNEXT_USER_VIRTUAL_MACHINES,
    aadGroupId,
});
export const listVNextUserVirtualMachinesSuccess = (virtualMachines) => ({
    type: UserEnvironmentActionType.LIST_VNEXT_USER_VIRTUAL_MACHINES_SUCCESS,
    virtualMachines,
});
export const listVNextUserVirtualMachinesFailure = () => ({
    type: UserEnvironmentActionType.LIST_VNEXT_USER_VIRTUAL_MACHINES_FAILURE,
});
export const listVCurrentUserVirtualMachines = (aadGroupId) => ({
    type: UserEnvironmentActionType.LIST_VCURRENT_USER_VIRTUAL_MACHINES,
    aadGroupId,
});
export const listVCurrentUserVirtualMachinesFailures = () => ({
    type: UserEnvironmentActionType.LIST_VCURRENT_USER_VIRTUAL_MACHINES_FAILURE,
});
export const actions = {
    pollListUserEnvironmentsStart,
    pollListUserEnvironmentsStop,
    pollListUserEnvironmentsCancelled,
    listUserEnvironments,
    listUserEnvironmentsCancelled,
    listUserEnvironmentsSuccess,
    listUserEnvironmentsError,
    clearListUserEnvironmentError,
    startEnvironment,
    startUserEnvironmentFailed,
    clearStartUserEnvironmentError,
    stopEnvironment,
    stopUserEnvironmentFailed,
    clearStopUserEnvironmentError,
    resetEnvironmentPassword,
    resetUserEnvironmentPasswordFailed,
    clearResetUserEnvironmentPasswordError,
    clearUserEnvironmentErrors,
    reimageUserVirtualMachine,
    reimageUserVirtualMachineError,
    redeployUserVirtualMachine,
    redeployUserVirtualMachineError,
    redeployUserEnvironmentAccepted,
    reimageUserEnvironmentAccepted,
    clearReimageUserVirtualMachineError,
    clearRedeployUserVirtualMachineError,
    listAllUserVirtualMachinesSuccess,
    listVNextUserVirtualMachines,
    listVNextUserVirtualMachinesSuccess,
    listVNextUserVirtualMachinesFailure,
    listVCurrentUserVirtualMachinesFailures,
    listVCurrentUserVirtualMachines,
};
export default actions;
