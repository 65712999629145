import { put, race, select, take, delay, cancelled } from 'redux-saga/effects';
import { labAppInitialize } from '../../actions/common/common-action-creators';
import { isTerminalState } from '../../selectors/common-selectors';
import { getLab, getLabMetadata, pollLabCancelled, pollLabStop, updateVnextPermissionPropagation, } from '../../actions/lab/lab-action-creators';
import { getVNextLab, getVnextLabPermissionPropagating } from '../../selectors/vnext/lab-selectors';
import { LabActionType } from '../../actions/lab/lab-actions';
import ErrorCode from '../../../common/error-codes';
export function* pollLab(action) {
    let shouldPoll = true;
    const { id, intervalMs, initialDelayMs, shouldStopWhenTerminalState } = action;
    const isVnextLabPermissionPropagating = yield select(getVnextLabPermissionPropagating);
    if (isVnextLabPermissionPropagating) {
        // poll get lab for VNext lab permission propagation scenario until it succeeds
        try {
            if (!!initialDelayMs) {
                yield delay(initialDelayMs);
            }
            while (shouldPoll) {
                yield put(getLab(id, false));
                const { success: labSuccess, error: labError } = yield race({
                    success: take(LabActionType.GET_LAB_SUCCESS),
                    error: take(LabActionType.GET_LAB_ERROR),
                    cancelled: take(LabActionType.GET_LAB_CANCELLED),
                });
                if (shouldStopWhenTerminalState) {
                    if (!!labError) {
                        shouldPoll = labError.error.code === ErrorCode.AuthorizationFailed;
                    }
                    else if (!!labSuccess) {
                        shouldPoll = false;
                    }
                    else {
                        shouldPoll = true;
                    }
                }
                if (shouldPoll) {
                    yield delay(intervalMs);
                }
                else {
                    // update isVnextLabPermissionPropagating to false
                    yield put(updateVnextPermissionPropagation(false));
                    if (shouldStopWhenTerminalState) {
                        yield put(getLabMetadata(id));
                    }
                    yield put(pollLabStop());
                    // permission propagation finished, retry vNextLabAppInitialize for the same lab id
                    yield put(labAppInitialize(id));
                }
            }
        }
        finally {
            if (yield cancelled()) {
                yield put(pollLabCancelled());
            }
        }
    }
    else {
        // old logic for other scenarios
        try {
            if (!!initialDelayMs) {
                yield delay(initialDelayMs);
            }
            while (shouldPoll) {
                yield put(getLab(id));
                yield race({
                    success: take(LabActionType.GET_LAB_SUCCESS),
                    error: take(LabActionType.GET_LAB_ERROR),
                    cancelled: take(LabActionType.GET_LAB_CANCELLED),
                });
                const currentLab = yield select(getVNextLab, id);
                if (shouldStopWhenTerminalState) {
                    shouldPoll = !isTerminalState(currentLab.provisioningState);
                }
                if (shouldPoll) {
                    yield delay(intervalMs);
                }
                else {
                    if (shouldStopWhenTerminalState) {
                        yield put(getLabMetadata(id));
                    }
                    yield put(pollLabStop());
                }
            }
        }
        finally {
            if (yield cancelled()) {
                yield put(pollLabCancelled());
            }
        }
    }
}
