import * as React from 'react';
import { connect } from 'react-redux';
import { LoadingContainer } from '../common/loading-section';
import { teamsCompleteMfa } from '../redux/actions/teams/teams-action-creators';
const TeamsCompleteMfaContainerInjected = (props) => {
    const { teamsCompleteMfa } = props;
    React.useEffect(() => {
        teamsCompleteMfa();
    }, []);
    return <LoadingContainer styles={{ root: { backgroundColor: 'inherit' } }}/>;
};
const mapDispatchToProps = {
    teamsCompleteMfa,
};
export const TeamsCompleteMfaContainer = connect(null, mapDispatchToProps)(TeamsCompleteMfaContainerInjected);
export default TeamsCompleteMfaContainer;
