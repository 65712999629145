import { routerActions } from 'connected-react-router/immutable';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import ErrorCode from '../common/error-codes';
import { Page } from '../common/page/page';
import { fatalError } from '../redux/actions/common/common-action-creators';
import { dismissCovidBanner, dismissLabListFirstRun, dismissRetirementAlert, dismissOldV1GpuLabBanner, toggleDisableHints, } from '../redux/actions/identity/identity-action-creators';
import { clearLabsError, deleteLab, selectLab, pollLabListStart, pollLabListStop, filterByLabAccessType, } from '../redux/actions/lab/lab-action-creators';
import { Routes } from '../utils/routes';
import { LabList } from './lab-list';
import { messages } from './lab-list-messages';
import { ProvisioningState } from '../utils/provisioning-state';
import { Constants } from '../utils/constants';
import { getLabListContainerModel } from './lab-list-selectors';
import { isLabParentLabAccount } from '../redux/selectors/lab-parent-resource-selectors';
import { clearUpdateLinkedLmsError } from '../redux/actions/lab-plan/lab-plan-action-creators';
function isDeletingLabInList(labs) {
    const index = labs.findIndex((lab) => lab.provisioningState === ProvisioningState.Deleting);
    return index > -1;
}
class LabListContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._returnToLabList = this._returnToLabList.bind(this);
        this._loadDashboard = this._loadDashboard.bind(this);
        this._loadSchedule = this._loadSchedule.bind(this);
        this._loadUsers = this._loadUsers.bind(this);
        this._loadVirtualMachines = this._loadVirtualMachines.bind(this);
        this._deleteLab = this._deleteLab.bind(this);
        this._toggleShowTips = this._toggleShowTips.bind(this);
        this._onDismissedIntro = this._onDismissedIntro.bind(this);
        this._onDismissedRetirementAlert = this._onDismissedRetirementAlert.bind(this);
        this._onDismissedCovid19Banner = this._onDismissedCovid19Banner.bind(this);
        this._onDismissedOldV1GpuLabBanner = this._onDismissedOldV1GpuLabBanner.bind(this);
    }
    componentDidMount() {
        const { labListViewModel, pollLabListStart } = this.props;
        const { labs, currentLabParentId } = labListViewModel;
        if (isDeletingLabInList(labs)) {
            pollLabListStart(currentLabParentId, Constants.DefaultPollingInterval);
        }
    }
    componentDidUpdate(prevProps) {
        // Note: this handles cases where the lab account does not actually exist
        const { isLoadingLabParentResources, fatalError, labListViewModel, pollLabListStart, pollLabListStop, currentLabParentExist, } = this.props;
        const { currentLabParentId, labs } = labListViewModel;
        if (!isLoadingLabParentResources && !currentLabParentExist) {
            fatalError(ErrorCode.ResourceNotFound, new Error('Lab list failed to find the current lab account.'));
        }
        if (isDeletingLabInList(labs) !== isDeletingLabInList(prevProps.labListViewModel.labs)) {
            if (isDeletingLabInList(labs)) {
                pollLabListStart(currentLabParentId, Constants.DefaultPollingInterval, Constants.OneMinuteInterval);
            }
            else {
                pollLabListStop();
            }
        }
    }
    componentWillUnmount() {
        const { pollLabListStop } = this.props;
        pollLabListStop();
    }
    _returnToLabList() {
        const { currentLabParentId } = this.props.labListViewModel;
        this.props.replace({
            pathname: isLabParentLabAccount(currentLabParentId)
                ? Routes.LabList(currentLabParentId)
                : Routes.VNextLabList(currentLabParentId),
            search: this.props.search,
        });
    }
    _loadDashboard(labId) {
        const { selectLab, push, search } = this.props;
        selectLab(labId);
        push({ pathname: Routes.Dashboard(labId), search });
    }
    _loadSchedule(labId) {
        const { selectLab, push, search } = this.props;
        selectLab(labId);
        push({ pathname: Routes.Schedule(labId), search });
    }
    _loadUsers(labId) {
        const { selectLab, push, search } = this.props;
        selectLab(labId);
        push({ pathname: Routes.Users(labId), search });
    }
    _loadVirtualMachines(labId) {
        const { selectLab, push, search } = this.props;
        selectLab(labId);
        push({ pathname: Routes.VirtualMachines(labId), search });
    }
    _deleteLab(labId) {
        this.props.deleteLab(labId);
    }
    _toggleShowTips(enabled) {
        this.props.toggleDisableHints(!enabled);
    }
    _onDismissedIntro() {
        this.props.dismissLabListFirstRun();
    }
    _onDismissedRetirementAlert() {
        this.props.dismissRetirementAlert();
    }
    _onDismissedCovid19Banner() {
        this.props.dismissCovidBanner();
    }
    _onDismissedOldV1GpuLabBanner() {
        this.props.dismissOldV1GpuLabBanner();
    }
    render() {
        const { labListViewModel, showNewLabFlyout, intl, labAccessType, clearError, clearUpdateLinkedLmsError, filterByLabAccessType, } = this.props;
        const listProps = {
            labListViewModel,
            showNewLabFlyout,
            labAccessType,
            returnToLabList: this._returnToLabList,
            loadDashboard: this._loadDashboard,
            loadSchedule: this._loadSchedule,
            loadUsers: this._loadUsers,
            loadVirtualMachines: this._loadVirtualMachines,
            deleteLab: this._deleteLab,
            toggleShowTips: this._toggleShowTips,
            onDismissedIntro: this._onDismissedIntro,
            onDismissedRetirementAlert: this._onDismissedRetirementAlert,
            onDismissedCovidBanner: this._onDismissedCovid19Banner,
            onDismissedOldV1GpuLabBanner: this._onDismissedOldV1GpuLabBanner,
            clearError,
            clearUpdateLinkedLmsError,
            filterByLabAccessType,
        };
        const pageTitle = intl.formatMessage(messages.myLabsTitle);
        return (<Page title={pageTitle} headerProps={{ hideLabPicker: true }}>
                <LabList {...listProps}/>
            </Page>);
    }
}
const mapStateToProps = (state) => {
    return getLabListContainerModel(state);
};
const mapDispatchToProps = {
    selectLab,
    deleteLab,
    clearError: clearLabsError,
    dismissLabListFirstRun,
    dismissRetirementAlert,
    dismissCovidBanner,
    dismissOldV1GpuLabBanner,
    toggleDisableHints,
    push: routerActions.push,
    replace: routerActions.replace,
    fatalError,
    pollLabListStart,
    pollLabListStop,
    clearUpdateLinkedLmsError,
    filterByLabAccessType,
};
export const LabListContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(LabListContainerInjected));
export default LabListContainer;
