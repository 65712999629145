import { Position, Stack } from '@fluentui/react';
import * as React from 'react';
import SafeSpinButton from '../safe-spin-button';
const GracePeriodInput = (props) => {
    return (<Stack horizontal>
            <Stack.Item>
                <SafeSpinButton {...props} labelPosition={Position.end} styles={{ spinButtonWrapper: { maxWidth: 75 }, label: { fontWeight: 400 } }}/>
            </Stack.Item>
        </Stack>);
};
export default GracePeriodInput;
