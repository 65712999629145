import { CommonLabActionType } from '../lab/lab-actions';
import { CommonActionType } from '../common/common-actions';
import { CommonLabParentResourceActionType } from '../lab-parent-resource/lab-parent-resource-actions';
export const ScheduleActionType = {
    ...CommonActionType,
    ...CommonLabActionType,
    ...CommonLabParentResourceActionType,
    LIST_SCHEDULES: 'LIST_SCHEDULES',
    LIST_SCHEDULES_SUCCESS: 'LIST_SCHEDULES_SUCCESS',
    LIST_SCHEDULES_ERROR: 'LIST_SCHEDULES_ERROR',
    LOAD_FIRST_LAB_ACCOUNT: 'LOAD_FIRST_LAB_ACCOUNT',
    DELETE_SCHEDULE: 'DELETE_SCHEDULE',
    DELETE_SCHEDULE_SUCCESS: 'DELETE_SCHEDULE_SUCCESS',
    DELETE_SCHEDULE_ERROR: 'DELETE_SCHEDULE_ERROR',
    CREATE_SCHEDULE: 'CREATE_SCHEDULE',
    CREATE_SCHEDULE_SUCCESS: 'CREATE_SCHEDULE_SUCCESS',
    CREATE_SCHEDULE_ERROR: 'CREATE_SCHEDULE_ERROR',
    UPDATE_SCHEDULE: 'UPDATE_SCHEDULE',
    UPDATE_SCHEDULE_SUCCESS: 'UPDATE_SCHEDULE_SUCCESS',
    UPDATE_SCHEDULE_ERROR: 'UPDATE_SCHEDULE_ERROR',
    CLEAR_LOAD_SCHEDULE_ERROR: 'CLEAR_LOAD_SCHEDULE_ERROR',
    CLEAR_SCHEDULE_ERROR: 'CLEAR_SCHEDULE_ERROR',
};
