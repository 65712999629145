import { Dropdown, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { ConnectionType } from '../../data/models/environment-common';
import LinuxRdpDialog from './linux-rdp-dialog';
import InfoTip from '../info-tip';
import './connection-type-dropdown.css';
const messages = defineMessages({
    label: {
        id: 'ConnectionTypeFieldLabel',
        defaultMessage: 'Enabled connection types',
        description: 'Label for connection type drop-down.',
    },
    sshInBrowser: {
        id: 'ConnectionTypeFieldSshInBrowser',
        defaultMessage: 'Web access (SSH in browser)',
        description: 'Connection type drop-down entry for accessing a VM through SSH protocol from a web browser.',
    },
    rdpInBrowser: {
        id: 'ConnectionTypeFieldRdpInBrowser',
        defaultMessage: 'Web access (RDP in browser)',
        description: 'Connection type drop-down entry for accessing a VM through RDP protocol from a web browser.',
    },
    ssh: {
        id: 'ConnectionTypeFieldSsh',
        defaultMessage: 'Client connection (SSH)',
        description: 'Connection type drop-down entry for accessing a VM through SSH protocol from a client.',
    },
    rdp: {
        id: 'ConnectionTypeFieldRdp',
        defaultMessage: 'Client connection (RDP)',
        description: 'Connection type drop-down entry for accessing a VM through RDP protocol from a client.',
    },
    connectionTypeError: {
        id: 'CommonConnectionTypeError',
        defaultMessage: 'Select at least one connection type.',
        description: 'Error message shown when no VM connection types are selected in a drop-down.',
    },
});
const getConnectionTypeLabelMap = (intl) => {
    const { formatMessage: msg } = intl;
    const map = {};
    map[ConnectionType.Rdp] = msg(messages.rdp);
    map[ConnectionType.Ssh] = msg(messages.ssh);
    map[ConnectionType.RdpInBrowser] = msg(messages.rdpInBrowser);
    map[ConnectionType.SshInBrowser] = msg(messages.sshInBrowser);
    return map;
};
const getOption = (connectionType, disabled, labelMap) => {
    return {
        key: connectionType,
        text: labelMap[connectionType],
        disabled,
    };
};
const getOptions = (isLinux, isBastionSupported, clientTypesDisabled, labelMap, isVNext) => {
    const result = [];
    if (isLinux) {
        result.push(getOption(ConnectionType.Ssh, !isVNext, labelMap));
        result.push(getOption(ConnectionType.Rdp, clientTypesDisabled, labelMap));
        if (isBastionSupported) {
            result.push(getOption(ConnectionType.SshInBrowser, !isVNext, labelMap));
        }
    }
    else {
        result.push(getOption(ConnectionType.Rdp, !isVNext, labelMap));
        if (isBastionSupported) {
            result.push(getOption(ConnectionType.RdpInBrowser, !isVNext, labelMap));
        }
    }
    return result;
};
const ConnectionTypeDropdownInjected = (props) => {
    const { intl, clientTypesDisabled, isLinux, isBastionSupported, disabled, required, value, onChange, pristine, valid, isVNext, } = props;
    const { formatMessage: msg } = intl;
    const [showLinuxRdpDialog, setShowLinuxRdpDialog] = React.useState(false);
    const toggleOption = (type) => {
        const newValue = [...value];
        const index = newValue.indexOf(type);
        if (index > -1) {
            newValue.splice(index, 1);
        }
        else {
            newValue.push(type);
        }
        onChange(newValue);
    };
    const labelMap = getConnectionTypeLabelMap(intl);
    const options = getOptions(isLinux, isBastionSupported, clientTypesDisabled, labelMap, isVNext);
    const errorMessage = !pristine && !valid ? msg(messages.connectionTypeError) : undefined;
    return (<>
            {options.length > 1 && (<Dropdown label={msg(messages.label)} disabled={disabled} multiSelect={true} options={options} selectedKeys={value} required={required} aria-required={required || undefined} errorMessage={errorMessage} onChange={(_, option) => {
        if (!option) {
            return;
        }
        const { key, selected } = option;
        if (isLinux && key == ConnectionType.Rdp && selected) {
            setShowLinuxRdpDialog(true);
        }
        else {
            toggleOption(key);
        }
    }} onRenderLabel={(props, defaultRender) => (<Stack horizontal verticalAlign="center">
                            <Stack.Item>{defaultRender(props)}</Stack.Item>
                            {!isVNext && (<Stack.Item>
                                    <InfoTip content={<FormattedMessage id="ConnectionTypeTooltip" defaultMessage="The option to connect to virtual machines in a web browser is now available in preview. It can support up to 25 concurrent users, so labs with less than 25 users are best suited for this preview." description="Tooltip describing connection types setting."/>}/>
                                </Stack.Item>)}
                        </Stack>)} onRenderOption={(props, defaultRender) => (<Stack horizontal verticalAlign="center">
                            <Stack.Item>{defaultRender(props)}</Stack.Item>
                            {(props?.key === ConnectionType.RdpInBrowser ||
        props?.key === ConnectionType.SshInBrowser) &&
        !isVNext && (<Stack.Item>
                                        <span className="connection-type-dropdown-preview-tag">
                                            <FormattedMessage id="ConnectionTypePreview" defaultMessage="preview" description="Label next to connectype type drop-down item for connection types that are currently in preview state."/>
                                        </span>
                                    </Stack.Item>)}
                        </Stack>)}/>)}
            {showLinuxRdpDialog && (<LinuxRdpDialog onSubmit={() => {
        toggleOption(ConnectionType.Rdp);
        setShowLinuxRdpDialog(false);
    }} onDismiss={() => {
        setShowLinuxRdpDialog(false);
    }}/>)}
        </>);
};
export const ConnectionTypeDropdown = injectIntl(ConnectionTypeDropdownInjected);
export default ConnectionTypeDropdown;
