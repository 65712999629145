import { call, put, select, takeLatest } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import LabAccountProvider from '../../../data/providers/lab-account-provider';
import { ResourceId } from '../../../utils/resource-id';
import { getLabAccountError, getLabAccountSuccess } from '../../actions/lab-account/lab-account-action-creators';
import { LabAccountActionType } from '../../actions/lab-account/lab-account-actions';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
export function* getLabAccount(action) {
    const { id } = action;
    try {
        const resourceId = new ResourceId(id);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const labAccount = yield call(LabAccountProvider.getLabAccount, resourceId, accessToken, locale, language);
        yield put(getLabAccountSuccess(labAccount));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, id, FailureOperation.GetLabAccount, err ? err.code : undefined);
        yield put(getLabAccountError(id, error));
    }
}
export function* getLabAccountSaga() {
    yield takeLatest(LabAccountActionType.GET_LAB_ACCOUNT, getLabAccount);
}
