import { Icon, Stack } from '@fluentui/react';
import * as React from 'react';
const InfoIcon = () => {
    return (<Icon iconName="Info" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '16px',
            paddingTop: '2px',
        },
    }}/>);
};
export const InfoSection = ({ children }) => {
    return (<Stack horizontal tokens={{ childrenGap: 8 }} verticalFill style={{ marginTop: 20, marginBottom: 20 }}>
            <Stack.Item>
                <InfoIcon />
            </Stack.Item>
            <Stack.Item styles={{ root: { paddingBottom: '2px', fontSize: '12px' } }}>{children}</Stack.Item>
        </Stack>);
};
export default InfoSection;
