import { call, put, select } from 'redux-saga/effects';
import { TemplateActionType } from '../../actions/template/template-actions';
import TemplateProvider from '../../../data/providers/template-provider';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { resetPasswordError } from '../../actions/template/template-action-creators';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { ResourceId } from '../../../utils/resource-id';
import VirtualMachineProvider from '../../../data/providers/vnext/arm-api/virtual-machine-provider';
export function* resetPassword(action) {
    try {
        const { templateId, username, password } = action;
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(TemplateProvider.resetPassword, templateId, username, password, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, action.templateId, FailureOperation.ResetPassword, e ? e.code : undefined);
        yield put(resetPasswordError(error));
    }
}
export function* resetVNextTemplatePassword(action) {
    try {
        const { templateId, username, password } = action;
        const resourceId = new ResourceId(templateId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(VirtualMachineProvider.resetPassword, resourceId, accessToken, locale, language, username, password);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, action.templateId, FailureOperation.ResetPassword, e ? e.code : undefined);
        yield put(resetPasswordError(error));
    }
}
export function* resetPasswordSaga() {
    yield labParentBasedTakeLatest(TemplateActionType.RESET_PASSWORD, resetPassword, resetVNextTemplatePassword);
}
