import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { VmState } from '../../data/models/environment-common';
import commonMessages from '../../language/common-messages';
import ToggleToolTip from '../toggle-tooltip';
const messages = defineMessages({
    startTooltip: {
        id: 'VmPowerStateStartTooltip',
        defaultMessage: 'Click to start virtual machine.',
        description: 'Tooltip on toggle to start virtual machine',
    },
    stopTooltip: {
        id: 'VmPowerStateStopTooltip',
        defaultMessage: 'Click to stop virtual machine.',
        description: 'Tooltip on toggle to stop virtual machine',
    },
});
export const VmPowerStateInjected = (props) => {
    const { intl, vmState, forceDisable, onStart, onStop } = props;
    const msg = intl.formatMessage;
    let stateText = '';
    let title = undefined;
    switch (vmState) {
        case VmState.Creating:
            stateText = msg(commonMessages.creating);
            break;
        case VmState.Deleting:
            stateText = msg(commonMessages.deleting);
            break;
        case VmState.ResettingPassword:
            stateText = msg(commonMessages.resettingPassword);
            break;
        case VmState.Running:
            stateText = msg(commonMessages.runningState);
            title = msg(messages.stopTooltip);
            break;
        case VmState.Starting:
            stateText = msg(commonMessages.starting);
            break;
        case VmState.Stopped:
            stateText = msg(commonMessages.stoppedState);
            title = msg(messages.startTooltip);
            break;
        case VmState.Stopping:
            stateText = msg(commonMessages.stopping);
            break;
        case VmState.Redeploying:
            stateText = msg(commonMessages.redeployingState);
            break;
        case VmState.Reimaging:
            stateText = msg(commonMessages.reimagingState);
            break;
    }
    const showToggle = vmState !== VmState.Creating && vmState !== VmState.Deleting;
    const showSpinner = vmState !== VmState.Stopped && vmState !== VmState.Running;
    const isDisabled = !!forceDisable || showSpinner;
    return (<Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }} style={{ height: '100%', paddingLeft: 5 }}>
            {showToggle && (<Stack.Item>
                    <ToggleToolTip content={title} ariaLabel={stateText} inlineLabel={true} styles={{ root: { marginBottom: 0 } }} disabled={isDisabled} checked={vmState === VmState.Starting || vmState === VmState.Running} onChange={(_, value) => (value ? onStart() : onStop())}/>
                </Stack.Item>)}
            {showSpinner && (<Stack.Item>
                    <Spinner size={showToggle ? SpinnerSize.xSmall : SpinnerSize.small}/>
                </Stack.Item>)}
            <Stack.Item>
                <span className="ms-fontSize-14">{stateText}</span>
            </Stack.Item>
        </Stack>);
};
export const VmPowerState = injectIntl(VmPowerStateInjected);
export default VmPowerState;
