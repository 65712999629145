import { all, fork } from 'redux-saga/effects';
import { listLabsSaga, listLabsOnLabParentChangeSaga, pollLabListSaga } from './list-labs';
import { checkVNextCreateLabPermissionOnLabPlanSaga } from './check-permission';
import { getLabSaga, pollLabSaga, pollLabOperationSaga } from './get-lab';
import { deleteLabSaga } from './delete-lab';
import { updateLabSaga } from './update-lab';
import { createLabSaga } from './create-lab';
import { updateLabAccessModeSaga } from './update-lab-access-mode';
import { syncLabUsersSaga } from './sync-lab-users';
import { publishVNextLabSaga } from './publish-lab';
import { getLabMetadataSaga } from './get-lab-metadata';
export function* rootSaga() {
    yield all([
        fork(listLabsSaga),
        fork(getLabSaga),
        fork(deleteLabSaga),
        fork(checkVNextCreateLabPermissionOnLabPlanSaga),
        fork(createLabSaga),
        fork(updateLabSaga),
        fork(listLabsOnLabParentChangeSaga),
        fork(updateLabAccessModeSaga),
        fork(pollLabSaga),
        fork(syncLabUsersSaga),
        fork(pollLabListSaga),
        fork(publishVNextLabSaga),
        fork(getLabMetadataSaga),
        fork(pollLabOperationSaga),
    ]);
}
