import { CommonActionType } from '../../common/common-actions';
import { CommonLabParentResourceActionType } from '../../lab-parent-resource/lab-parent-resource-actions';
import { CommonLabPlanActionType } from '../../lab-plan/lab-plan-actions';
export const ImageActionType = {
    ...CommonActionType,
    ...CommonLabParentResourceActionType,
    ...CommonLabPlanActionType,
    LIST_IMAGES: 'LIST_IMAGES',
    LIST_IMAGES_SUCCESS: 'LIST_IMAGES_SUCCESS',
    LIST_IMAGES_ERROR: 'LIST_IMAGES_ERROR',
    CREATE_IMAGE_ACCEPTED: 'CREATE_IMAGE_ACCEPTED',
};
