import memoize from 'memoize-one';
import labAppNavMessages from '../lab-app/lab-app-nav-messages';
import { commonMessages } from '../language/common-messages';
import LabNavKeys from '../utils/lab-nav-key';
import { Routes } from '../utils/routes';
export const getNavigationItems = memoize((intl, selectedLabId, selectedItem, navigateRoute) => {
    const msg = intl.formatMessage;
    const dashboardText = msg(labAppNavMessages.dashboard);
    const settingsText = msg(labAppNavMessages.settings);
    const templateText = msg(labAppNavMessages.template);
    const virtualMachinePoolText = msg(labAppNavMessages.virtualMachinePool);
    const usersText = msg(commonMessages.users);
    const scheduleText = msg(commonMessages.schedule);
    const dashboardRoute = Routes.Dashboard(selectedLabId);
    const settingsRoute = Routes.Settings(selectedLabId);
    const templateRoute = Routes.Template(selectedLabId);
    const virtualMachinesRoute = Routes.VirtualMachines(selectedLabId);
    const usersRoute = Routes.Users(selectedLabId);
    const schedulesRoute = Routes.Schedule(selectedLabId);
    const navigationItems = [
        {
            key: LabNavKeys.Dashboard,
            onClick: () => navigateRoute(dashboardRoute),
            text: dashboardText,
            ariaLabel: dashboardText,
            className: selectedItem === LabNavKeys.Dashboard ? 'selected' : undefined,
        },
        {
            key: LabNavKeys.Template,
            onClick: () => navigateRoute(templateRoute),
            text: templateText,
            ariaLabel: templateText,
            className: selectedItem === LabNavKeys.Template ? 'selected' : undefined,
        },
        {
            key: LabNavKeys.VirualMachine,
            onClick: () => navigateRoute(virtualMachinesRoute),
            text: virtualMachinePoolText,
            ariaLabel: virtualMachinePoolText,
            className: selectedItem === LabNavKeys.VirualMachine ? 'selected' : undefined,
        },
        {
            key: LabNavKeys.Users,
            onClick: () => navigateRoute(usersRoute),
            text: usersText,
            ariaLabel: usersText,
            className: selectedItem === LabNavKeys.Users ? 'selected' : undefined,
        },
        {
            key: LabNavKeys.Schedule,
            onClick: () => navigateRoute(schedulesRoute),
            text: scheduleText,
            ariaLabel: scheduleText,
            className: selectedItem === LabNavKeys.Schedule ? 'selected' : undefined,
        },
        {
            key: LabNavKeys.Settings,
            onClick: () => navigateRoute(settingsRoute),
            text: settingsText,
            ariaLabel: settingsText,
            className: selectedItem === LabNavKeys.Settings ? 'selected' : undefined,
        },
    ];
    return navigationItems;
});
