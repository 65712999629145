import { List } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { StudentVmCard } from './student-vm-card';
import './student-vm-list.css';
import StudentVmListAnnounced from './student-vm-list-announced';
import { ErrorBanners, ListErrorBanner } from './student-vm-list-banners';
import { messages } from './student-vm-list-messages';
import { mustResetPasswordBeforeConnect, vmCanStartAgain } from './student-vm-list-selectors';
import { NoVmsMessage } from './student-vm-no-vms-message';
import { StopVmDialog } from './student-vm-stop-dialog';
import { getRdpConnectData, getSshCommand, getSshConnectData } from '../common/connect-to-vm/connect-utilities';
import RdpInfoDialog from '../common/connect-to-vm/rdp-info-dialog';
import { ConnectToVmDialog } from '../common/connect-to-vm/ssh-connect-dialog';
import LoadingContainer from '../common/loading-section';
import { ResetPasswordDialog } from '../common/reset-password-dialog/reset-password-dialog';
import { getCustomTheme } from '../common/themes/selectors';
import { ConnectionType } from '../data/models/environment-common';
import { createAndDownloadFile } from '../utils/files';
import { caseInsensitiveLocaleCompare } from '../utils/string-comparison';
import ResetVmsDialog from '../vm-list/reset-vms-dialog';
import TroubleshootDialog from '../common/troubleshoot-dialog/troubleshoot-dialog';
class StudentVmList extends React.Component {
    constructor(props) {
        super(props);
        // initialize state
        this.state = {
            showResetPasswordDialog: false,
            resetPasswordDialogVM: undefined,
            showRdpInfoDialog: false,
            rdpInfoDialogVm: undefined,
            showConnectDialog: false,
            connectDialogSshCommand: undefined,
            showStopVMDialog: false,
            stopDialogVM: undefined,
            showReimageDialog: false,
            reimageDialogVM: undefined,
            showTroubleshootDialog: false,
            troubleshootDialogVm: undefined,
        };
        // bind events to this class
        this._renderVm = this._renderVm.bind(this);
        this._renderErrorBanners = this._renderErrorBanners.bind(this);
        this._vmStartClicked = this._vmStartClicked.bind(this);
        this._vmStopClicked = this._vmStopClicked.bind(this);
        this._vmStopConfirmedClicked = this._vmStopConfirmedClicked.bind(this);
        this._vmConnectClicked = this._vmConnectClicked.bind(this);
        this._setRdpConnectData = this._setRdpConnectData.bind(this);
        this._setSshConnectData = this._setSshConnectData.bind(this);
        this._rdpInBrowser = this._rdpInBrowser.bind(this);
        this._sshInBrowser = this._sshInBrowser.bind(this);
        this._setConnectData = this._setConnectData.bind(this);
        this._resetPasswordClicked = this._resetPasswordClicked.bind(this);
        this._showRdpInfoClicked = this._showRdpInfoClicked.bind(this);
        this._changePasswordClicked = this._changePasswordClicked.bind(this);
        this._dismissResetPasswordDialog = this._dismissResetPasswordDialog.bind(this);
        this._dismissRdpInfoDialog = this._dismissRdpInfoDialog.bind(this);
        this._dismissedResetPasswordDialog = this._dismissedResetPasswordDialog.bind(this);
        this._dismissConnectDialog = this._dismissConnectDialog.bind(this);
        this._dismissedConnectDialog = this._dismissedConnectDialog.bind(this);
        this._dismissStopDialog = this._dismissStopDialog.bind(this);
        this._vmReimageClicked = this._vmReimageClicked.bind(this);
        this._vmRedeployClicked = this._vmRedeployClicked.bind(this);
        this._dismissReimageDialog = this._dismissReimageDialog.bind(this);
        this._reimageSubmitted = this._reimageSubmitted.bind(this);
        this._showTroubleshootDialogClicked = this._showTroubleshootDialogClicked.bind(this);
        this._dismissTroubleshootDialog = this._dismissTroubleshootDialog.bind(this);
    }
    render() {
        const { studentVmListViewModel, isPanelView } = this.props;
        const { vms, isLoading, isTeamsIntegrationEnabled, isLmsIntegrationEnabled } = studentVmListViewModel;
        const { resetPasswordDialogVM, showResetPasswordDialog, showRdpInfoDialog, rdpInfoDialogVm, showConnectDialog, connectDialogSshCommand, showStopVMDialog, stopDialogVM, showReimageDialog, showTroubleshootDialog, troubleshootDialogVm, } = this.state;
        const { secondaryBackgroundColor, transitionBackgroundColor } = getCustomTheme();
        if (isLoading) {
            return <LoadingContainer />;
        }
        if (!isLoading && vms.length < 1) {
            return (<div id="student-vm-list" style={isPanelView ? undefined : { backgroundColor: transitionBackgroundColor }}>
                    {this._renderListErrorBanner()}
                    <NoVmsMessage isPanelView={isPanelView} isTeamsIntegrationEnabled={isTeamsIntegrationEnabled} isLmsIntegrationEnabled={isLmsIntegrationEnabled}/>
                </div>);
        }
        const sortedVms = vms.sort((a, b) => {
            if (a.title && b.title) {
                return caseInsensitiveLocaleCompare(a.title, b.title);
            }
            else {
                return 0;
            }
        });
        const mustSetPassword = resetPasswordDialogVM && mustResetPasswordBeforeConnect(resetPasswordDialogVM);
        return (<>
                <div id="student-vm-list" style={isPanelView ? undefined : { backgroundColor: secondaryBackgroundColor }}>
                    {!isPanelView && (<>
                            {this._renderErrorBanners()}
                            <div id="student-vm-list-page-title">
                                <FormattedMessage {...messages.virtualMachinesPageHeader}/>
                            </div>
                        </>)}

                    <>
                        <div id="student-vm-list-content">
                            <StudentVmListAnnounced vms={vms}/>
                            <List items={sortedVms} onRenderCell={this._renderVm} getItemCountForPage={() => sortedVms.length}/>
                        </div>
                    </>
                </div>

                {showResetPasswordDialog && (<ResetPasswordDialog isResetting={!mustSetPassword} isWindows={resetPasswordDialogVM && resetPasswordDialogVM.isWindows} username={resetPasswordDialogVM && resetPasswordDialogVM.username} id={resetPasswordDialogVM && resetPasswordDialogVM.id} onDismiss={this._dismissResetPasswordDialog} onDismissed={this._dismissedResetPasswordDialog} onSubmit={this._changePasswordClicked}/>)}
                {showConnectDialog && (<ConnectToVmDialog sshCommand={connectDialogSshCommand || ''} onDismiss={this._dismissConnectDialog} onDismissed={this._dismissedConnectDialog}/>)}
                {showStopVMDialog && (<StopVmDialog vm={stopDialogVM} onDismiss={this._dismissStopDialog} onSubmit={this._vmStopConfirmedClicked}/>)}
                {showRdpInfoDialog && (<RdpInfoDialog rdpInfo={(rdpInfoDialogVm && rdpInfoDialogVm.rdpAuthority) || ''} onDismiss={this._dismissRdpInfoDialog}/>)}
                {showReimageDialog && (<ResetVmsDialog isStudentVM={true} onSubmit={this._reimageSubmitted} onDismiss={this._dismissReimageDialog}/>)}
                {showTroubleshootDialog && (<TroubleshootDialog onDismiss={() => this._dismissTroubleshootDialog()} onRedeploy={() => this._vmRedeployClicked(troubleshootDialogVm)}/>)}
            </>);
    }
    _renderVm(item, index) {
        if (!item) {
            return null;
        }
        const { isBastionEnabled, isTeamsIntegrationEnabled } = this.props.studentVmListViewModel;
        return (<StudentVmCard item={item} index={index} isBastionEnabled={isBastionEnabled} isTeamsIntegrationEnabled={isTeamsIntegrationEnabled} onStart={this._vmStartClicked} onStop={this._vmStopClicked} onConnect={this._vmConnectClicked} onResetPassword={this._resetPasswordClicked} onShowRdpInfo={this._showRdpInfoClicked} onRedeploy={this._vmRedeployClicked} onReimage={this._vmReimageClicked} onShowTroubleshootDialog={this._showTroubleshootDialogClicked}/>);
    }
    _renderListErrorBanner() {
        const { studentVmListViewModel, onClearListError } = this.props;
        const { listEnvironmentsError } = studentVmListViewModel;
        return (<ListErrorBanner listEnvironmentsError={listEnvironmentsError} dismissListEnvironmentsError={onClearListError}/>);
    }
    _renderErrorBanners() {
        const { studentVmListViewModel, onClearListError, onClearStartError, onClearStopError, onClearResetPasswordError, onClearRedeployError, onClearReimageError, } = this.props;
        const { vms, listEnvironmentsError, vCurrentStartErrors, vCurrentStopErrors, vCurrentResetPasswordErrors, vNextStartErrors, vNextStopErrors, vNextResetPasswordErrors, reimageErrors, redeployErrors, } = studentVmListViewModel;
        return (<ErrorBanners vms={vms} listEnvironmentsError={listEnvironmentsError} vCurrentStartErrors={vCurrentStartErrors} vCurrentStopErrors={vCurrentStopErrors} vCurrentResetPasswordErrors={vCurrentResetPasswordErrors} dismissListEnvironmentsError={onClearListError} dismissStartEnvironmentError={onClearStartError} dismissStopEnvironmentError={onClearStopError} dismissResetEnvironmentPasswordError={onClearResetPasswordError} vNextStartErrors={vNextStartErrors} vNextStopErrors={vNextStopErrors} vNextResetPasswordErrors={vNextResetPasswordErrors} reimageErrors={reimageErrors} redeployErrors={redeployErrors} dismissRedeployError={onClearRedeployError} dismissReimageError={onClearReimageError}/>);
    }
    _resetPasswordClicked(vm) {
        this.setState({
            showResetPasswordDialog: true,
            resetPasswordDialogVM: vm,
        });
    }
    _showRdpInfoClicked(vm) {
        this.setState({
            showRdpInfoDialog: true,
            rdpInfoDialogVm: vm,
        });
    }
    _showTroubleshootDialogClicked(vm) {
        this.setState({
            showTroubleshootDialog: true,
            troubleshootDialogVm: vm,
        });
    }
    _vmStartClicked(vm) {
        this.props.onStart(vm.id);
    }
    _vmStopClicked(vm) {
        // we want to tell a user that they won't be able to re-start their
        // VMs after they stop them
        if (vmCanStartAgain(vm)) {
            this.props.onStop(vm.id);
        }
        else {
            this.setState({
                showStopVMDialog: true,
                stopDialogVM: vm,
            });
        }
    }
    _vmReimageClicked(vm) {
        this.setState({ showReimageDialog: true, reimageDialogVM: vm });
    }
    _reimageSubmitted() {
        const { reimageDialogVM } = this.state;
        if (!!reimageDialogVM) {
            this.props.onReimage(reimageDialogVM.id);
        }
        this._dismissReimageDialog();
    }
    _dismissReimageDialog() {
        this.setState({
            showReimageDialog: false,
            reimageDialogVM: undefined,
        });
    }
    _vmRedeployClicked(vm) {
        this.props.onRedeploy(vm.id);
    }
    _vmStopConfirmedClicked(vm) {
        this.props.onStop(vm.id);
        this._dismissStopDialog();
    }
    _vmConnectClicked(vm, type) {
        if (mustResetPasswordBeforeConnect(vm)) {
            this.setState({
                showResetPasswordDialog: true,
                resetPasswordDialogVM: vm,
            });
        }
        else {
            this._setConnectData(vm, type);
        }
    }
    _setSshConnectData(vm) {
        const { port, url } = getSshConnectData(vm.sshAuthority);
        this.setState({
            showConnectDialog: true,
            // note: this is not localizable because it's a command
            connectDialogSshCommand: getSshCommand(port, vm.username, url),
        });
    }
    _setRdpConnectData(vm) {
        const { fileName, fileContent } = getRdpConnectData(vm.rdpAuthority, vm.isWindows, vm.username, vm.title);
        createAndDownloadFile(fileName, fileContent);
    }
    _rdpInBrowser(vm) {
        window.open(vm.rdpBrowserUrl, `rdp-${vm.title}`); // eslint-disable-line security/detect-non-literal-fs-filename
    }
    _sshInBrowser(vm) {
        window.open(vm.sshBrowserUrl, `ssh-${vm.title}`); // eslint-disable-line security/detect-non-literal-fs-filename
    }
    _setConnectData(vm, type) {
        switch (type) {
            case ConnectionType.Rdp:
                this._setRdpConnectData(vm);
                break;
            case ConnectionType.Ssh:
                this._setSshConnectData(vm);
                break;
            case ConnectionType.RdpInBrowser:
                this._rdpInBrowser(vm);
                break;
            case ConnectionType.SshInBrowser:
                this._sshInBrowser(vm);
                break;
            default:
                throw new Error(`Unsupported connection type: ${type}`);
        }
    }
    _changePasswordClicked(id, username, password) {
        this.props.onPasswordReset(id, username, password);
        this._dismissResetPasswordDialog();
    }
    _dismissResetPasswordDialog() {
        this.setState({
            showResetPasswordDialog: false,
        });
    }
    _dismissRdpInfoDialog() {
        this.setState({
            showRdpInfoDialog: false,
            rdpInfoDialogVm: undefined,
        });
    }
    _dismissTroubleshootDialog() {
        this.setState({
            showTroubleshootDialog: false,
            troubleshootDialogVm: undefined,
        });
    }
    _dismissedResetPasswordDialog() {
        this.setState({
            resetPasswordDialogVM: undefined,
        });
    }
    _dismissConnectDialog() {
        this.setState({
            showConnectDialog: false,
        });
    }
    _dismissedConnectDialog() {
        this.setState({
            connectDialogSshCommand: undefined,
        });
    }
    _dismissStopDialog() {
        this.setState({
            showStopVMDialog: false,
            stopDialogVM: undefined,
        });
    }
}
export default injectIntl(StudentVmList);
