import { call, put, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { ResourceId } from '../../../utils/resource-id';
import { SharedImageActionType } from '../../actions/shared-image/shared-image-actions';
import { createSharedImageError } from '../../actions/shared-image/shared-image-action-creators';
import SharedImageProvider from '../../../data/providers/shared-image-provider';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import LabPlanProvider from '../../../data/providers/vnext/arm-api/lab-plan-provider';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { getCurrentLab } from '../../selectors/lab-selectors';
import { LabServicesModels } from 'lab-services';
import { createSharedImageAccepted } from '../../../redux/actions/vnext/images/image-action-creators';
export function* createSharedImage(action) {
    const { templateId, displayName } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(SharedImageProvider.createSharedImage, templateId, displayName, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, templateId, FailureOperation.SaveSharedImage, e ? e.code : undefined);
        yield put(createSharedImageError(templateId, error));
    }
}
export function* createVNextSharedImage(action) {
    const { displayName, templateId } = action;
    try {
        const currentLab = yield select(getCurrentLab);
        // we disable 'Export to Azure Compute Gallery' when the lab is not created by a lab plan with sharedGalleryId
        const labPlanResourceId = new ResourceId(currentLab.labPlanId, true);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(LabPlanProvider.saveImage, labPlanResourceId, accessToken, locale, language, displayName, templateId);
        const image = {
            id: labPlanResourceId.id + '/images/' + displayName,
            sharedGalleryId: labPlanResourceId.id + '/images/' + displayName,
            type: 'Microsoft.LabServices/labPlans/images',
            name: displayName,
            displayName: displayName,
            enabledState: LabServicesModels.EnableState.Enabled,
            provisioningState: LabServicesModels.ProvisioningState.Updating,
        };
        yield put(createSharedImageAccepted(currentLab.labPlanId, image));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, templateId, FailureOperation.SaveSharedImage, e ? e.code : undefined);
        yield put(createSharedImageError(templateId, error));
    }
}
export function* createSharedImageSaga() {
    yield labParentBasedTakeLatest(SharedImageActionType.CREATE_SHARED_IMAGE, createSharedImage, createVNextSharedImage);
}
