import { Stack, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import Lottie from 'react-lottie';
import retirementAnimation from '../animations/professor-fre/retirement.json';
import { Carousel } from '../common/carousel/carousel';
import { messages } from './retirement-notification-messages';
import './introduction.css';
const RetirementNotificationCarouselItem = (props) => {
    const { animationData, headingText, contentText, extraContent, isVisible } = props;
    return (<Stack className="lab-list-intro__carousel-item" tokens={{ childrenGap: 36 }}>
            <Stack.Item className="lab-list-intro__carousel-item__animation">
                <Lottie options={{ loop: false, autoplay: true, animationData }} isStopped={!isVisible}/>
            </Stack.Item>
            <Stack.Item className="lab-list-intro__carousel-item__content" align="center" styles={{ root: { width: '640px' } }} grow>
                <Stack className="lab-list-intro__content-container" tokens={{ childrenGap: 15 }} horizontalAlign="center" grow>
                    <Stack.Item className="lab-list-intro__content-header">{headingText}</Stack.Item>
                    <Stack.Item className="lab-list-intro__content-text">{contentText}</Stack.Item>
                    <Stack.Item className="lab-list-intro__link" grow align="center">
                        <div>
                            <PrimaryButton text={extraContent} onClick={() => {
        window.open('https://aka.ms/azlabs-retirementguide', '_blank');
    }}/>
                        </div>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>);
};
export const RetirementNotification = (props) => {
    const { onDismiss } = props;
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const cardContent = [
        {
            content: () => (<RetirementNotificationCarouselItem animationData={retirementAnimation} isVisible={currentSlide === 0} headingText={<FormattedMessage {...messages.RetirementNotificationPageTitle}/>} contentText={<FormattedMessage {...messages.RetirementNotificationPageContent}/>} extraContent={<FormattedMessage {...messages.RetirementNotificationPageExtraContent}/>}/>),
        },
    ];
    return (<Carousel isOpen={true} cardContent={cardContent} onClose={onDismiss} onCardChange={(page) => setCurrentSlide(page)}/>);
};
export default RetirementNotification;
