import * as React from 'react';
import { DefaultButton, Icon } from '@fluentui/react';
import { injectIntl } from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard';
import commonMessages from '../../language/common-messages';
import './copy-to-clipboard-button.css';
const CopyToClipboardButtonInjected = (props) => {
    const { intl } = props;
    const { formatMessage: msg } = intl;
    const [copied, updateCopied] = React.useState(false);
    const label = copied ? msg(commonMessages.copied) : msg(commonMessages.copy);
    return (<CopyToClipboard text={props.text} onCopy={() => updateCopied(true)}>
            <DefaultButton ariaLabel={label} styles={{
        root: {
            backgroundColor: 'transparent',
            paddingLeft: '0',
            paddingRight: '0',
            marginTop: '15px',
            minWidth: 'auto',
            height: 'auto',
            border: 0,
        },
        rootHovered: {
            backgroundColor: 'transparent',
        },
        rootPressed: {
            backgroundColor: 'transparent',
        },
    }}>
                <div className="copy-to-clipboard-button-icon-container">
                    <Icon iconName="ClipboardList" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '19px',
            color: '#323130',
        },
    }}/>
                </div>
                <span className="copy-to-clipboard-button-text-container">{label}</span>
            </DefaultButton>
        </CopyToClipboard>);
};
export const CopyToClipboardButton = injectIntl(CopyToClipboardButtonInjected);
export default CopyToClipboardButton;
