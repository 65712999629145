import { Checkbox, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Lottie from 'react-lottie';
import createLabAnimation from '../animations/professor-fre/create-lab.json';
import studentVirtualMachineAnimation from '../animations/professor-fre/student-virtual-machine.json';
import virtualMachineAnimation from '../animations/professor-fre/virtual-machine.json';
import welcomeAnimation from '../animations/professor-fre/welcome.json';
import { Carousel } from '../common/carousel/carousel';
import { messages } from './introduction-messages';
import './introduction.css';
const LabListIntroCarouselItem = (props) => {
    const { animationData, headingText, contentText, extraContent, isVisible } = props;
    return (<Stack className="lab-list-intro__carousel-item" tokens={{ childrenGap: 38 }}>
            <Stack.Item className="lab-list-intro__carousel-item__animation">
                <Lottie options={{ loop: false, autoplay: true, animationData }} isStopped={!isVisible}/>
            </Stack.Item>
            <Stack.Item className="lab-list-intro__carousel-item__content" align="center" grow>
                <Stack className="lab-list-intro__content-container" horizontalAlign="center" grow>
                    <Stack.Item className="lab-list-intro__content-header">{headingText}</Stack.Item>
                    <Stack.Item className="lab-list-intro__content-text">{contentText}</Stack.Item>
                    <Stack.Item className="lab-list-intro__extra-content" grow align="center">
                        {extraContent}
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>);
};
export const LabListIntroduction = (props) => {
    const { onDismiss, toggleShowTips, hintsEnabled, checkboxEnabled } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const cardContent = [
        {
            content: () => (<LabListIntroCarouselItem animationData={welcomeAnimation} isVisible={currentSlide === 0} headingText={<FormattedMessage {...messages.initialPageTitle}/>} contentText={<FormattedMessage {...messages.initialPageContent}/>} extraContent={checkboxEnabled ? (<Checkbox checked={hintsEnabled} label={msg(messages.showTipsLabel)} onChange={(ev, checked) => toggleShowTips(!!checked)} styles={{ root: { paddingTop: '30px', fontSize: '14px', lineHeight: '20px' } }}/>) : undefined}/>),
        },
        {
            content: () => (<LabListIntroCarouselItem animationData={virtualMachineAnimation} isVisible={currentSlide === 1} headingText={<FormattedMessage {...messages.templatePageTitle}/>} contentText={<FormattedMessage {...messages.templatePageContent}/>}/>),
        },
        {
            content: () => (<LabListIntroCarouselItem animationData={createLabAnimation} isVisible={currentSlide === 2} headingText={<FormattedMessage {...messages.labPageTitle}/>} contentText={<FormattedMessage {...messages.labPageContent}/>}/>),
        },
        {
            content: () => (<LabListIntroCarouselItem animationData={studentVirtualMachineAnimation} isVisible={currentSlide === 3} headingText={<FormattedMessage {...messages.studentPageTitle}/>} contentText={<FormattedMessage {...messages.studentPageContent}/>}/>),
        },
    ];
    return (<Carousel isOpen={true} cardContent={cardContent} onClose={onDismiss} onCardChange={(page) => setCurrentSlide(page)}/>);
};
export default LabListIntroduction;
