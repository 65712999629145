import { OneDSChannel } from './1ds-channel';
import { ApplicationInisightsChannel } from './ai-channel';
export const TelemetryType = {
    OneDS: 'ONEDS',
    ApplicationInsights: 'AI',
    All: 'ALL',
};
export function getTelemetryChannelsFromType(telemetryType) {
    switch (telemetryType) {
        case TelemetryType.ApplicationInsights:
            return [new ApplicationInisightsChannel()];
        case TelemetryType.OneDS:
            return [new OneDSChannel()];
        default:
            return [new ApplicationInisightsChannel(), new OneDSChannel()];
    }
}
