import { Stack } from '@fluentui/react';
import React from 'react';
import { useIntl } from 'react-intl';
import { LabSizeInfo } from './lab-size-info';
import { getLocalizedLabSkuDisplayName } from './selectors/sku-selectors';
export const ImageAndSizeInfo = (props) => {
    const { imageName, labSkuInfo } = props;
    const intl = useIntl();
    const skuDisplayName = !labSkuInfo ? '' : getLocalizedLabSkuDisplayName(labSkuInfo, intl);
    return (<Stack className="ms-fontColor-neutralSecondary" horizontalAlign="start" styles={{ root: { marginTop: '10px' } }}>
            {!!imageName && (<Stack.Item styles={{ root: { fontWeight: 600, fontSize: '12px' } }}>{imageName}</Stack.Item>)}
            {!!skuDisplayName && <Stack.Item styles={{ root: { fontSize: '12px' } }}>{skuDisplayName}</Stack.Item>}
            <Stack.Item styles={{ root: { fontSize: '12px' } }}>
                <LabSizeInfo {...props}/>
            </Stack.Item>
        </Stack>);
};
