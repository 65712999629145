import { routerActions } from 'connected-react-router/immutable';
import queryString from 'query-string';
import { call, cancelled, put, race, select, take } from 'redux-saga/effects';
import { ltiSignIn } from '../../actions/lti/lti-action-creators';
import { LtiActionType } from '../../actions/lti/lti-actions';
import { ErrorCode } from '../../../common/error-codes';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import { getReturnUrl, init as initMsal } from '../../../data/providers/msal-provider';
import { getQueryParams } from '../../../utils/query-params';
import { fatalError, initializeCancelled, initializeError, initializeSuccess, processUrlFeatures, } from '../../actions/common/common-action-creators';
import { CommonActionType } from '../../actions/common/common-actions';
import { loadUserSettings, resolveSignInReturnUrl, signIn } from '../../actions/identity/identity-action-creators';
import { IdentityActionType, } from '../../actions/identity/identity-actions';
import { teamsInitialize } from '../../actions/teams/teams-action-creators';
import { TeamsActionType } from '../../actions/teams/teams-actions';
import { cancelableTakeLeading } from '../../effects/cancelable';
import { isTeamsHost } from '../../selectors/common-selectors';
import { getRoute, getSearch, isLtiOidcRoute, isLtiReturnOidc, isMfaRouteOrRedirect, } from '../../selectors/route-selector';
import { shouldSignInAad } from '../../selectors/lti-selectors';
export function* initialize(action) {
    try {
        const { shouldSignInInteractively } = action;
        // LTI oidc redirect, short-circuit
        const isLtiStart = yield select(isLtiOidcRoute);
        if (isLtiStart) {
            yield put(initializeSuccess());
            return;
        }
        const isLtiReturn = yield select(isLtiReturnOidc);
        if (isLtiReturn) {
            yield put(ltiSignIn());
            const { error: ltiError } = yield race({
                success: take(LtiActionType.LTI_SIGN_IN_SUCCESS),
                error: take(LtiActionType.LTI_SIGN_IN_ERROR),
            });
            if (ltiError) {
                const { error } = ltiError;
                yield put(initializeError(error));
                yield put(fatalError(ErrorCode.GenericError, error));
                return;
            }
        }
        const shouldAadSignIn = yield select(shouldSignInAad);
        if (shouldAadSignIn) {
            yield call(initMsal);
        }
        else {
            yield put(initializeSuccess());
            return;
        }
        const isTeamsHosted = yield select(isTeamsHost);
        if (isTeamsHosted) {
            yield put(teamsInitialize());
            const { error: teamsError } = yield race({
                success: take(TeamsActionType.TEAMS_INITIALIZE_SUCCESS),
                error: take(TeamsActionType.TEAMS_INITIALIZE_ERROR),
            });
            if (teamsError) {
                const { error } = teamsError;
                yield put(initializeError(error));
                yield put(fatalError(ErrorCode.GenericError, error));
                return;
            }
        }
        else {
            // set the return URL based on our MSAL stored state if
            // applicable
            const returnUrl = yield call(getReturnUrl);
            if (!!returnUrl) {
                yield put(resolveSignInReturnUrl(returnUrl));
            }
        }
        yield put(processUrlFeatures());
        yield race({
            success: take(CommonActionType.PROCESS_URL_FEATURES_SUCCESS),
            error: take(CommonActionType.PROCESS_URL_FEATURES_ERROR),
        });
        // if this is an MFA flow, we can stop now
        const isMfa = yield select(isMfaRouteOrRedirect);
        if (isMfa) {
            yield put(initializeSuccess());
            return;
        }
        yield put(signIn(shouldSignInInteractively));
        // we race all of the possible outcomes of sign in so that
        // initialization does not proceed until sign in completes
        const { error: errorAction, failure } = yield race({
            success: take(IdentityActionType.SIGN_IN_SUCCESS),
            failure: take(IdentityActionType.SIGN_IN_FAILED),
            error: take(IdentityActionType.SIGN_IN_ERROR),
        });
        if (errorAction) {
            // if we hit an auth error then something unexpected happened so we
            // need to fail initialization
            const { error } = errorAction;
            yield put(initializeError(error));
            yield put(fatalError(ErrorCode.AuthenticationFailed, error));
            return;
        }
        if (!failure) {
            yield put(loadUserSettings(true));
            const { error: settingsErrorAction } = yield race({
                success: take(IdentityActionType.LOAD_USER_SETTINGS_SUCCESS),
                error: take(IdentityActionType.LOAD_USER_SETTINGS_ERROR),
            });
            if (!!settingsErrorAction) {
                const { error: settingsError } = settingsErrorAction;
                if (settingsError?.code === ErrorCode.InvalidGrant) {
                    yield put(initializeError(settingsError));
                    yield put(fatalError(ErrorCode.InvalidGrant, settingsError));
                    return;
                }
            }
        }
        // remove the tenant id in query string since we don't show the current tenant id in url when switching tenant
        // and we will select the first valid tenant in list if the tenant id specified in url is invalid.
        let search = yield select(getSearch);
        const queryParams = yield call(getQueryParams, search);
        if (!!queryParams.tenantId) {
            delete queryParams.tenantId;
            const pathname = yield select(getRoute);
            search = yield call(queryString.stringify, queryParams);
            yield put(routerActions.replace({ pathname, search }));
        }
        // if auth failed (isInteractive = false only), then we are done
        // initializing and will show the route
        yield put(initializeSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.Initialize, err ? err.code : undefined);
        yield put(initializeError(error));
        yield put(fatalError(ErrorCode.GenericError, error));
    }
    finally {
        if (yield cancelled()) {
            yield put(initializeCancelled());
        }
    }
}
export function* initializeSaga() {
    yield cancelableTakeLeading(CommonActionType.INITIALIZE, initialize, CommonActionType.INITIALIZE_SUCCESS, CommonActionType.INITIALIZE_ERROR);
}
