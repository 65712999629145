import { call, put, select, takeLatest } from 'redux-saga/effects';
import UserVirtualMachinesProvider from '../../../data/providers/vnext/labs-api/user-virtual-machine-provider';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { UserEnvironmentActionType, } from '../../actions/user-environment/user-environment-actions';
import { getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { redeployUserEnvironmentAccepted, redeployUserVirtualMachineError, } from '../../actions/user-environment/user-environment-action-creators';
import { getUserVirtualMachines } from '../../selectors/vnext/user-virtual-machine-selectors';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { ResourceId } from '../../../utils/resource-id';
export function* redeployVirtualMachine(action) {
    const { environmentId } = action;
    try {
        const accessToken = yield call(getLabsApiAccessTokenSaga, false, undefined, true);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const userVirtualMachines = yield select(getUserVirtualMachines);
        const userVirtualMachine = userVirtualMachines.find((o) => caseInsensitiveCultureInvariantCompare(o.id, environmentId) === 0);
        const redeployVmsResult = [];
        const redeployVmResult = yield call(UserVirtualMachinesProvider.redeploy, userVirtualMachine.subscriptionId, userVirtualMachine.location, environmentId, accessToken, locale, language);
        redeployVmResult['ResourceId'] = new ResourceId(environmentId, true);
        ;
        redeployVmsResult.push(redeployVmResult);
        yield put(redeployUserEnvironmentAccepted(environmentId, redeployVmsResult));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, environmentId, FailureOperation.RedeployUserVirtualMachine, err ? err.code : undefined);
        yield put(redeployUserVirtualMachineError(environmentId, error));
    }
}
export function* redeployVirtualMachineSaga() {
    yield takeLatest(UserEnvironmentActionType.REDEPLOY_USER_VIRTUAL_MACHINE, redeployVirtualMachine);
}
