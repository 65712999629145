import { call, put, takeLatest, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { LabActionType } from '../../actions/lab/lab-actions';
import ResourceMetaDataProvider from '../../../data/providers/vnext/labs-api/resource-metadata-provider';
import { getLabMetadataError, getLabMetadataSuccess } from '../../actions/lab/lab-action-creators';
import { ResourceId } from '../../../utils/resource-id';
import { getVNextLab } from '../../selectors/vnext/lab-selectors';
export function* getLabMetadata(action) {
    const { labId } = action;
    try {
        const accessToken = yield call(getLabsApiAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const resourceId = new ResourceId(labId, true);
        const lab = yield select(getVNextLab, labId);
        const metadata = yield call(ResourceMetaDataProvider.getLabMetadata, lab.location, resourceId, accessToken, locale, language);
        yield put(getLabMetadataSuccess(labId, metadata));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.GetLabMetadata, e ? e.code : undefined);
        yield put(getLabMetadataError(error));
    }
}
export function* getLabMetadataSaga() {
    yield takeLatest(LabActionType.GET_LAB_METADATA, getLabMetadata);
}
