import { CommandBar, DefaultButton, Layer, MessageBar, MessageBarType, Overlay, Separator, Spinner, SpinnerSize, Stack, Link } from '@fluentui/react';
import * as React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingContainer from '../common/loading-section';
import { ConnectionTypeDropdownField } from '../common/connection-type/connection-type-dropdown-field';
import { getNavigationItems } from '../common/lab-nav-items';
import { isIdleSupported } from '../common/shutdown-policy/selectors';
import ShutdownPolicyFieldGroup from '../common/shutdown-policy/shutdown-policy-field-group';
import SubmitButton from '../common/submit-button';
import { getCustomTheme } from '../common/themes/selectors';
import UnsavedChangesDialog from '../common/unsaved-changes-dialog';
import FullPageMessage from '../full-page-message/full-page-message';
import commonMessages from '../language/common-messages';
import LabNavKeys from '../utils/lab-nav-key';
import sharedMessages from './settings-page-messages';
import LabErrorBanner from '../lab/lab-error-banner';
import { isVNextLab } from '../redux/selectors/lab-selectors';
import InfoTip from '../common/info-tip';
import { messages } from './settings-page-messages';
import './settings-page.css';
const SettingsPageInjected = (props) => {
    const { settingsPageViewModel, onSubmit, clearUpdateError, navigateRoute } = props;
    const { showLoadError, isRunning, isCreating, isPublishing, isScaling, isApplyingSettings, idleUpdateFailed, isUpdating, updateError, isWindows, connectionTypes, idleConfig, isBastionEnabled, isVnetAttached, isReadOnly, isTeamsOrLmsIntegrationEnabled, lab, isLoading, shouldDisableLabUpdate, } = settingsPageViewModel;
    const msg = props.intl.formatMessage;
    const { smallPageTitleStyle, secondaryBackgroundColor, primaryCommandBarStyles } = getCustomTheme();
    let navigationItems = undefined;
    if (isTeamsOrLmsIntegrationEnabled && lab && lab.id) {
        navigationItems = getNavigationItems(props.intl, lab.id, LabNavKeys.Settings, navigateRoute);
    }
    const isDisabled = shouldDisableLabUpdate || showLoadError || isReadOnly;
    const [showDiscardDialog, setShowDiscardDialog] = React.useState(false);
    const initialValues = {
        connectionTypes,
        idleConfig,
    };
    const isVNext = isVNextLab(lab?.id);
    if (!!isLoading) {
        return (<div id="settings-page-container" style={{ backgroundColor: secondaryBackgroundColor }}>
                <div id="settings-page-content">
                    {navigationItems && (<div id="settings-list-header">
                            <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                        </div>)}
                    <LoadingContainer />
                </div>
            </div>);
    }
    return (<Form initialValues={initialValues} onSubmit={(values) => {
        const { connectionTypes, idleConfig } = values;
        onSubmit(connectionTypes, idleConfig);
    }}>
            {(formProps) => (<>
                    {!formProps.pristine && (<Layer styles={{ root: { zIndex: 9999 } }}>
                            <Overlay style={{ backgroundColor: 'transparent' }} onClick={() => setShowDiscardDialog(true)}/>
                        </Layer>)}
                    <div id="settings-page-container" style={{ backgroundColor: secondaryBackgroundColor }}>
                        <div id="settings-page-content">
                            <div id="settings-page-header">
                                {navigationItems && (<CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>)}
                                {updateError && !isUpdating && !showLoadError && (<LabErrorBanner updateError={updateError} clearUpdateError={clearUpdateError}/>)}
                                {!!idleUpdateFailed && !isUpdating && !showLoadError && (<MessageBar messageBarType={MessageBarType.error}>
                                        <FormattedMessage id="SettingsPageIdleUpdateError" defaultMessage="An error occurred while updating shutdown policy settings. Please refresh the page and try again." description="Message shown when an error occurs while updating lab settings."/>
                                    </MessageBar>)}
                                {!showLoadError && (<Stack.Item>
                                        <h1 style={smallPageTitleStyle}>
                                            <FormattedMessage {...sharedMessages.settings}/>
                                        </h1>
                                    </Stack.Item>)}
                            </div>
                            {showLoadError && (<FullPageMessage image="error-general" message={<FormattedMessage id="SettingsPageLoadError" defaultMessage="Cannot load lab settings" description="Error shown on lab settings page when it fails to load necessary data from the server."/>} messageDetails={<FormattedMessage id="SettingsPageLoadErrorDetails" defaultMessage="Your lab does not appear to be in a state to view or edit settings at this time. Please try again later or contact your Lab Services administator for assistance." description="Error shown on lab settings page when it fails to load necessary data from the server."/>}/>)}
                            {!showLoadError && (<>
                                    <Stack id="settings-page-body" className="vertical-scrollable-content" role="form" verticalFill grow tokens={{ childrenGap: '10px' }} styles={{ root: { zIndex: 10000 } }}>
                                        {isBastionEnabled && (<Stack.Item styles={{ root: { marginLeft: 32, maxWidth: 450 } }}>
                                                <ConnectionTypeDropdownField name="connectionTypes" disabled={true} isLinux={!isWindows} clientTypesDisabled={!isVNext} isBastionSupported={!isVnetAttached && !isVNext} isVNext={isVNext}/>
                                            </Stack.Item>)}
                                        {isIdleSupported(idleConfig) && (<Stack.Item styles={{
        root: {
            marginLeft: 32,
            maxWidth: 450,
            paddingTop: isBastionEnabled ? 20 : undefined,
        },
    }}>
                                                <ShutdownPolicyFieldGroup idleConfigFieldName="idleConfig" disabled={isDisabled} isRunning={isRunning} isVNext={isVNext}/>
                                            </Stack.Item>)}
                                        <Stack.Item verticalFill grow styles={{ root: { minHeight: '20px' } }}>
                                            <div />
                                        </Stack.Item>
                                        <Stack horizontal tokens={{ childrenGap: 5 }}>
                                            <Stack.Item styles={{ root: { paddingTop: '2px' } }}>
                                                <InfoTip />
                                            </Stack.Item>
                                            <Stack.Item styles={{
        root: { color: undefined },
    }}>
                                                    <FormattedMessage {...messages.linuxDistributionsInfo} values={{
        Link: (chunks) => (<Link href="https://learn.microsoft.com/en-us/azure/lab-services/how-to-configure-auto-shutdown-lab-plans#supported-linux-distributions-for-automatic-shutdown" target="_blank">
                                                                    {chunks}
                                                                </Link>),
    }}/>
                                            </Stack.Item>
                                        </Stack>
                                        <Stack.Item>
                                            <Separator />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack horizontal verticalAlign="center" tokens={{ childrenGap: 8 }} style={{ marginLeft: 32, marginBottom: 23, marginTop: 13 }}>
                                                <Stack.Item>
                                                    <SubmitButton styles={{ root: { minWidth: 80 } }} buttonText={msg(commonMessages.apply)} disabled={isDisabled || formProps.pristine || !formProps.valid} isSubmitting={isUpdating} onClick={() => formProps.handleSubmit()}/>
                                                </Stack.Item>
                                                <Stack.Item>
                                                    <DefaultButton styles={{ root: { minWidth: 80 } }} disabled={isDisabled || formProps.pristine} onClick={() => formProps.form.reset()}>
                                                        {msg(commonMessages.discard)}
                                                    </DefaultButton>
                                                </Stack.Item>
                                                {(isCreating || isPublishing || isScaling || isApplyingSettings) && (<Stack.Item>
                                                        <Spinner size={SpinnerSize.medium} style={{ marginLeft: 20 }}/>
                                                    </Stack.Item>)}
                                                <Stack.Item>
                                                    <div>
                                                        {isCreating && (<FormattedMessage id="SettingsPageCreatingStatus" defaultMessage="Creating the lab..." description="Progress message on the lab settings page."/>)}
                                                        {isPublishing && (<FormattedMessage id="SettingsPagePublishingStatus" defaultMessage="Publishing the lab..." description="Progress message on the lab settings page."/>)}
                                                        {isScaling && (<FormattedMessage id="SettingsPageScalingStatus" defaultMessage="Updating lab capacity..." description="Progress message on the lab settings page."/>)}
                                                        {isApplyingSettings && (<FormattedMessage id="SettingsPageUpdatingStatus" defaultMessage="Applying settings..." description="Progress message on the lab settings page."/>)}
                                                    </div>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                    {showDiscardDialog && (<UnsavedChangesDialog title={<FormattedMessage id="SettingsPageDiscardTitle" defaultMessage="Discard changes" description="Title of the dialog shown when user navigates away from settings page without saving changes."/>} message={<FormattedMessage id="SettingsPageDiscardMessage" defaultMessage="Are you sure you want to discard changes made to lab settings?" description="Message on the dialog shown when user navigates away from settings page without saving changes."/>} onOk={() => {
        formProps.form.reset();
        setShowDiscardDialog(false);
    }} onCancel={() => setShowDiscardDialog(false)}/>)}
                                </>)}
                        </div>
                    </div>
                </>)}
        </Form>);
};
const SettingsPage = injectIntl(SettingsPageInjected);
export default SettingsPage;
