import * as React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Icon, Stack, Link } from '@fluentui/react';
import './student-vm-no-vms-message.css';
const messages = defineMessages({
    teamsStudentNoVmsHeading: {
        id: 'TeamsStudentNoVmsHeading',
        defaultMessage: "This lab isn't available yet.",
        description: 'Heading for a page shown to students in Teams when they have no VMs.',
    },
});
const PanelViewContent = (props) => {
    const { isTeamsOrLmsIntegrationEnabled } = props;
    return (<Stack horizontalAlign="center" style={{ textAlign: 'center' }}>
            <div id="no-vms-message-icon-container" style={{ paddingTop: '30px' }}>
                <Icon iconName="empty-state"/>
            </div>
            <div className="ms-fontWeight-semibold">
                {!isTeamsOrLmsIntegrationEnabled && (<FormattedMessage id="StudentNoVmsPanelTitle" defaultMessage="No virtual machines are assigned to you" description="The text on 'my virtual machines' flyout"/>)}
                {isTeamsOrLmsIntegrationEnabled && <FormattedMessage {...messages.teamsStudentNoVmsHeading}/>}
            </div>
            <div className="ms-fontSize-semibold" style={{ maxWidth: '272px' }}>
                {!isTeamsOrLmsIntegrationEnabled && (<FormattedMessage id="StudentNoVmsPanelDescription" defaultMessage="Register yourself on the Users screen within a lab to access your machines here." description="The text on 'my virtual machines' flyout"/>)}
                {isTeamsOrLmsIntegrationEnabled && (<FormattedMessage id="TeamsStudentNoVmsPanelDescription" defaultMessage="Your virtual machine will appear here when the lab is published." description="The text on 'my virtual machines' flyout"/>)}
            </div>
        </Stack>);
};
export const NoVmsMessage = (props) => {
    const { isPanelView, isTeamsIntegrationEnabled, isLmsIntegrationEnabled } = props;
    if (isPanelView) {
        return (<PanelViewContent isTeamsOrLmsIntegrationEnabled={isTeamsIntegrationEnabled || isLmsIntegrationEnabled}/>);
    }
    if (isTeamsIntegrationEnabled || isLmsIntegrationEnabled) {
        return (<Stack horizontalAlign="center" verticalAlign="center" grow verticalFill>
                <div id="no-vms-message-container">
                    <Stack.Item>
                        <div id="no-vms-message-header-container">
                            <div className="ms-fontSize-24 ms-fontWeight-semibold">
                                <FormattedMessage {...messages.teamsStudentNoVmsHeading}/>
                            </div>
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontal verticalAlign="center" horizontalAlign="center">
                            <Stack.Item>
                                <div id="no-vms-message-icon-container">
                                    <Icon iconName="teams-student-empty-state"/>
                                </div>
                            </Stack.Item>
                            {isTeamsIntegrationEnabled && (<Stack.Item styles={{ root: { maxWidth: '330px' } }}>
                                    <FormattedMessage id="TeamsStudentNoVmsDescription" defaultMessage="The lab associated with this team hasn't been published by the lab owner, or you have not yet been added. If you need immediate access to this lab, please contact the team owner." description="Description about the availability to the virtual machines for students in Teams mode."/>
                                </Stack.Item>)}
                            {isLmsIntegrationEnabled && (<Stack.Item styles={{ root: { maxWidth: '330px' } }}>
                                    <FormattedMessage id="LmsStudentNoVmsDescription" defaultMessage="Please try again later. If you need immediate access to this lab, please contact the lab owner." description="Description about the availability to the virtual machines for students in Lms mode."/>
                                </Stack.Item>)}
                        </Stack>
                    </Stack.Item>
                </div>
            </Stack>);
    }
    const createLabLink = (<Link href="https://aka.ms/azlabs-getstarted">
            <FormattedMessage id="StudentNoVmsCreateLabLinkText" defaultMessage="steps to get started" description="The text for the link to instruct users how to create labs. This link tag, which includes this string, will be used by string 'StudentNoVmsCreateALabAccountInstructions' as parameter {link}."/>
        </Link>);
    const registrationLinkExample = <strong>https://labs.azure.com/register/abcdefg</strong>;
    return (<Stack horizontalAlign="center" verticalAlign="center" grow verticalFill>
            <div id="no-vms-message-container">
                <Stack.Item>
                    <div id="no-vms-message-header-container">
                        <div id="no-vms-message-header" className="ms-fontSize-24 ms-fontWeight-semibold">
                            <FormattedMessage id="StudentNoVmsHeading" defaultMessage="We can't find anything that belongs to you." description="Heading for a page shown to students when they have no VMs."/>
                        </div>
                    </div>
                </Stack.Item>
                <Stack.Item>
                    <Stack horizontal={true} verticalAlign="center" horizontalAlign="center" wrap>
                        <Stack.Item>
                            <div id="no-vms-message-icon-container">
                                <Icon iconName="empty-state" styles={{
        root: {
            selectors: {
                svg: { width: 300, height: 200 },
            },
        },
    }}/>
                            </div>
                        </Stack.Item>
                        <Stack.Item>
                            <Stack>
                                <div id="no-vms-message-instructions-container">
                                    <Stack.Item>
                                        <div id="no-vms-message-create-a-lab-section">
                                            <div id="no-vms-message-create-a-lab-section-title" className="ms-fontSize-16 ms-fontWeight-semibold">
                                                <FormattedMessage id="StudentNoVmsCreateALabHeading" defaultMessage="Did you want to create a lab?" description="Heading for a section of the page which instructs users how to create labs."/>
                                            </div>
                                            <div>
                                                <FormattedMessage id="StudentNoVmsCreateALabAccountInstructions" defaultMessage="You first need to have access to a lab plan or lab account to be able to create labs. If your work or school organization already has Azure Lab Services enabled, contact your administrator to be added. If you want to create your own, follow the {link}." description="Instructions for creating a lab account. The parameter {link} contains a link to further instructions for creating lab accounts. The text for the link is localized as string 'StudentNoVmsCreateLabLinkText'" values={{ link: createLabLink }}/>
                                            </div>
                                        </div>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <div id="no-vms-message-registration-section">
                                            <div id="no-vms-message-registration-section-title" className="ms-fontSize-16 ms-fontWeight-semibold">
                                                <FormattedMessage id="StudentNoVmsLabRegistrationHeading" defaultMessage="Were you invited to a lab?" description="Heading for a section of the page which helps users with lab registration."/>
                                            </div>
                                            <div>
                                                <FormattedMessage id="StudentNoVmsLabRegistrationInstructions" defaultMessage="If you were, you should have received an access link. Go to the link to access the lab. The access link should look like this: {link}" description="Instructions for lab registration. The parameter {link} contains an example registration link that is formatted in bold. It will look like https://labs.azure.com/register/abcdefg" values={{ link: registrationLinkExample }}/>
                                            </div>
                                        </div>
                                    </Stack.Item>
                                </div>
                            </Stack>
                        </Stack.Item>
                    </Stack>
                </Stack.Item>
            </div>
        </Stack>);
};
export default NoVmsMessage;
