import { Record, Set } from 'immutable';
import { InitializationState } from '../state/common-store';
import { CommonActionType, } from '../actions/common/common-actions';
import { getLocaleData } from '../../language/languages';
import { MlClientError, FailureOperation } from '../../data/ml-client-error';
import { Host } from '../../utils/hosts';
import { Theme } from '../../utils/themes';
function initialize(state, action) {
    return state.merge({
        initializationState: InitializationState.Initializing,
    });
}
function initializeSuccess(state, action) {
    return state.merge({
        initializationState: InitializationState.Success,
    });
}
function initializeFailed(state, action) {
    return state.merge({
        initializationState: InitializationState.Failed,
    });
}
function initializeCancelled(state, action) {
    return state.merge({
        initializationState: InitializationState.NotStarted,
    });
}
function professorInitialize(state, action) {
    return state.merge({
        professorInitializationState: InitializationState.Initializing,
    });
}
function professorInitializeSuccess(state, action) {
    return state.merge({
        professorInitializationState: InitializationState.Success,
    });
}
function professorInitializeFailed(state, action) {
    return state.merge({
        professorInitializationState: InitializationState.Failed,
    });
}
function professorInitializeCancelled(state, action) {
    return state.merge({
        professorInitializationState: InitializationState.NotStarted,
    });
}
function studentInitialize(state, action) {
    return state.merge({
        studentInitializeState: InitializationState.Initializing,
    });
}
function studentInitializeSuccess(state, action) {
    return state.merge({
        studentInitializeState: InitializationState.Success,
    });
}
function studentInitializeFailed(state, action) {
    return state.merge({
        studentInitializeState: InitializationState.Failed,
    });
}
function studentInitializeCancelled(state, action) {
    return state.merge({
        studentInitializeState: InitializationState.NotStarted,
    });
}
function labAppInitialize(state, action) {
    return state.merge({
        labAppInitializeState: InitializationState.Initializing,
    });
}
function labAppInitializeSuccess(state, action) {
    return state.merge({
        labAppInitializeState: InitializationState.Success,
    });
}
function labAppInitializeFailed(state, action) {
    return state.merge({
        labAppInitializeState: InitializationState.Failed,
    });
}
function labAppInitializeCancelled(state, action) {
    return state.merge({
        labAppInitializeState: InitializationState.NotStarted,
    });
}
function setHost(state, action) {
    const { host } = action;
    return state.merge({
        host,
    });
}
function setLocale(state, action) {
    const { locale: requestedLocale } = action;
    const { locale, market, language, messages } = getLocaleData(requestedLocale);
    return state.merge({
        requestedLocale,
        locale,
        market,
        language,
        messages,
    });
}
function setTimezone(state, action) {
    const { timezone } = action;
    return state.merge({ timezone });
}
function setTheme(state, action) {
    const { theme } = action;
    return state.merge({ theme });
}
function setReferrer(state, action) {
    const { referrer } = action;
    return state.merge({ referrer });
}
function enableFeature(state, action) {
    const { feature } = action;
    let features = state.get('features');
    features = features.add(feature);
    return state.merge({ features });
}
function disableFeature(state, action) {
    const { feature } = action;
    let features = state.get('features');
    features = features.remove(feature);
    return state.merge({ features });
}
function fatalError(state, action) {
    const { error: fatalError, errorCode: fatalErrorCode } = action;
    // make sure we preserve the original fatal error when multiple errors occur in succession
    if (!!state.get('fatalErrorCode')) {
        return state;
    }
    return state.merge({
        fatalErrorCode,
        fatalError: fatalError || new MlClientError('The application encountered a fatal error.', '', FailureOperation.Unknown),
    });
}
function clearFatalError(state, action) {
    return state.merge({
        fatalErrorCode: undefined,
        fatalError: undefined,
    });
}
function clearUserSession(state, action) {
    return state.merge({
        professorInitializationState: InitializationState.NotStarted,
        studentInitializeState: InitializationState.NotStarted,
        labAppInitializeState: InitializationState.NotStarted,
    });
}
const initialState = Record({
    initializationState: InitializationState.NotStarted,
    professorInitializationState: InitializationState.NotStarted,
    studentInitializeState: InitializationState.NotStarted,
    labAppInitializeState: InitializationState.NotStarted,
    host: Host.Web,
    referrer: '',
    requestedLocale: '',
    locale: '',
    market: '',
    language: '',
    messages: {},
    timezone: '',
    features: Set([]),
    theme: Theme.Default,
    fatalErrorCode: undefined,
    fatalError: undefined,
})();
export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case CommonActionType.INITIALIZE:
            return initialize(state, action);
        case CommonActionType.INITIALIZE_SUCCESS:
            return initializeSuccess(state, action);
        case CommonActionType.INITIALIZE_ERROR:
            return initializeFailed(state, action);
        case CommonActionType.INITIALIZE_CANCELLED:
            return initializeCancelled(state, action);
        case CommonActionType.PROFESSOR_INITIALIZE:
            return professorInitialize(state, action);
        case CommonActionType.PROFESSOR_INITIALIZE_SUCCESS:
            return professorInitializeSuccess(state, action);
        case CommonActionType.PROFESSOR_INITIALIZE_ERROR:
            return professorInitializeFailed(state, action);
        case CommonActionType.PROFESSOR_INITIALIZE_CANCELLED:
            return professorInitializeCancelled(state, action);
        case CommonActionType.STUDENT_INITIALIZE:
            return studentInitialize(state, action);
        case CommonActionType.STUDENT_INITIALIZE_SUCCESS:
            return studentInitializeSuccess(state, action);
        case CommonActionType.STUDENT_INITIALIZE_ERROR:
            return studentInitializeFailed(state, action);
        case CommonActionType.STUDENT_INITIALIZE_CANCELLED:
            return studentInitializeCancelled(state, action);
        case CommonActionType.LAB_APP_INITIALIZE:
            return labAppInitialize(state, action);
        case CommonActionType.LAB_APP_INITIALIZE_SUCCESS:
            return labAppInitializeSuccess(state, action);
        case CommonActionType.LAB_APP_INITIALIZE_ERROR:
            return labAppInitializeFailed(state, action);
        case CommonActionType.LAB_APP_INITIALIZE_CANCELLED:
            return labAppInitializeCancelled(state, action);
        case CommonActionType.SET_HOST:
            return setHost(state, action);
        case CommonActionType.SET_LOCALE:
            return setLocale(state, action);
        case CommonActionType.SET_TIMEZONE:
            return setTimezone(state, action);
        case CommonActionType.SET_THEME:
            return setTheme(state, action);
        case CommonActionType.SET_REFERRER:
            return setReferrer(state, action);
        case CommonActionType.ENABLE_FEATURE:
            return enableFeature(state, action);
        case CommonActionType.DISABLE_FEATURE:
            return disableFeature(state, action);
        case CommonActionType.FATAL_ERROR:
            return fatalError(state, action);
        case CommonActionType.CLEAR_FATAL_ERROR:
            return clearFatalError(state, action);
        case CommonActionType.CLEAR_USER_SESSION:
        case CommonActionType.SELECT_TENANT:
            return clearUserSession(state, action);
        default:
            return state;
    }
};
