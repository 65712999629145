import * as React from 'react';
import { connect } from 'react-redux';
import { professorInitialize } from '../redux/actions/common/common-action-creators';
import { InitializationState } from '../redux/state/common-store';
import { ProfessorApp } from './professor-app';
import { isTeamsMode } from '../redux/selectors/common-selectors';
class ProfessorAppContainerInjected extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (this.props.professorInitializationState === InitializationState.NotStarted) {
            this.props.professorInitialize();
        }
    }
    render() {
        const { isInitializing, isTeamsModeEnabled } = this.props;
        return <ProfessorApp isInitializing={isInitializing} isTeamsModeEnabled={isTeamsModeEnabled}/>;
    }
}
const mapStateToProps = (state) => {
    const commonStore = state.get('commonStore');
    const professorInitializationState = commonStore.get('professorInitializationState');
    const isInitializing = professorInitializationState === InitializationState.NotStarted ||
        professorInitializationState === InitializationState.Initializing;
    const isTeamsModeEnabled = isTeamsMode(state);
    return {
        professorInitializationState,
        isInitializing,
        isTeamsModeEnabled,
    };
};
const mapDispatchToProps = {
    professorInitialize,
};
export const ProfessorAppContainer = connect(mapStateToProps, mapDispatchToProps)(ProfessorAppContainerInjected);
export default ProfessorAppContainer;
