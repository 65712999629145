export const ProvisioningState = {
    Unknown: '',
    None: 'none',
    Succeeded: 'succeeded',
    Failed: 'failed',
    Creating: 'creating',
    Deleting: 'deleting',
    Moving: 'moving',
};
export const OperationResultStatus = {
    Running: 'Running',
    Succeeded: 'Succeeded',
    Failed: 'Failed',
};
