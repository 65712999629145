import { call, put, race, select, take, delay, all } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import { ProvisioningState } from '../../../data/models/common';
import LabProvider from '../../../data/providers/lab-provider';
import { ResourceId } from '../../../utils/resource-id';
import { addUsersError, addUsersSuccess, addVNextUsersError, addVNextUsersSuccess, listUsers, } from '../../actions/user/user-action-creators';
import { UserActionType } from '../../actions/user/user-actions';
import { getUsers } from '../../selectors/user-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { getVNextUsers } from '../../selectors/vnext/user-selectors';
import UserProvider from '../../../data/providers/vnext/arm-api/user-provider';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
export function* addUsers(action) {
    const { labId, emailAddresses } = action;
    try {
        // compare against our current list of users to make
        // sure we have new users to add
        const currentUsers = yield select(getUsers);
        const newEmails = emailAddresses.filter((email) => currentUsers.findIndex((user) => user.email === email) < 0);
        // the emails are all duplicates, don't need to do anything
        if (newEmails.length < 1) {
            yield delay(500); // HACK: Doing this since the state change happens too fast for flyouts to auto-close
            yield put(addUsersSuccess([]));
        }
        // time to actually add the users
        const resourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(LabProvider.addUsers, resourceId, newEmails, accessToken, locale, language);
        // refresh the user list
        yield put(listUsers(labId));
        const { success } = yield race({
            success: take(UserActionType.LIST_USERS_SUCCESS),
            cancelled: take(UserActionType.LIST_USERS_CANCELLED),
            error: take(UserActionType.LIST_USERS_ERROR),
        });
        if (success) {
            // get the updated list of users
            const users = yield select(getUsers);
            // determine if any emails didn't get added for some reason
            const failedEmails = newEmails.filter((email) => users.findIndex((user) => user.email === email) < 0);
            if (failedEmails.length > 0) {
                // we have some errors so dispatch an error
                const error = new MlClientError(undefined, labId, FailureOperation.AddUsers);
                yield put(addUsersError(error, failedEmails));
            }
            if (failedEmails.length < newEmails.length) {
                // we have at least some successes so dispatch success
                yield put(addUsersSuccess([]));
            }
        }
        else {
            // add placeholder users since the list call failed so we can move on
            const newUsers = newEmails.map((email) => {
                const user = {
                    id: email,
                    email,
                    provisioningState: ProvisioningState.Unknown,
                };
                return user;
            });
            yield put(addUsersSuccess(newUsers));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.AddUsers, e ? e.code : undefined);
        yield put(addUsersError(error));
    }
}
export async function callAddUser(resourceId, accessToken, locale, language, errors, emailAddress, successfulUsers) {
    try {
        const newUser = await UserProvider.addUser(resourceId, accessToken, locale, language, emailAddress);
        successfulUsers.push(newUser);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, emailAddress, FailureOperation.AddUsers, e ? e.code : undefined);
        errors[error.id] = error;
    }
}
export function* addVNextUsers(action) {
    const { labId, emailAddresses } = action;
    try {
        // compare against our current list of users to make
        // sure we have new users to add
        const currentUsers = yield select(getVNextUsers);
        const newEmails = emailAddresses.filter((email) => currentUsers.findIndex((user) => caseInsensitiveCultureInvariantCompare(user.email, email) === 0) < 0);
        // the emails are all duplicates, don't need to do anything
        if (newEmails.length < 1) {
            yield delay(500); // HACK: Doing this since the state change happens too fast for flyouts to auto-close
            yield put(addVNextUsersSuccess([]));
        }
        const successfulUsers = [];
        const errors = {};
        const resourceId = new ResourceId(labId, true);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield all(newEmails.map((emailAddress) => call(callAddUser, resourceId, accessToken, locale, language, errors, emailAddress, successfulUsers)));
        if (successfulUsers.length > 0) {
            yield put(addVNextUsersSuccess(successfulUsers));
        }
        const failedIds = Object.keys(errors);
        if (failedIds.length > 0) {
            yield put(addVNextUsersError(errors));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, 'all', FailureOperation.AddUsers, e ? e.code : undefined);
        const errors = {};
        errors[error.id] = error;
        yield put(addVNextUsersError(errors));
    }
}
export function* addUsersSaga() {
    yield labParentBasedTakeLatest(UserActionType.ADD_USERS, addUsers, addVNextUsers);
}
