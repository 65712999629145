import { put, call, select } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import UserSettingsProvider from '../../../data/providers/user-settings-provider';
import { default as VNextUserSettingsProvider } from '../../../data/providers/vnext/labs-api/user-settings-provider';
import { IdentityActionType } from '../../actions/identity/identity-actions';
import { loadUserSettingsSuccess, loadUserSettingsError, updateUserSettingsExists, } from '../../actions/identity/identity-action-creators';
import { featureBasedTakeLatest } from '../../effects/feature-based';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { getDisplayId } from '../../selectors/identity-selector';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from './access-tokens';
export function* loadUserSettings(action) {
    try {
        const { canRedirect } = action;
        const accessToken = yield call(getArmAccessTokenSaga, canRedirect);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const displayId = yield select(getDisplayId);
        const settings = yield call(UserSettingsProvider.getUserSettings, displayId, accessToken, locale, language);
        yield put(loadUserSettingsSuccess(settings));
    }
    catch (err) {
        let message = undefined;
        let code = undefined;
        const errorString = !!err ? `${err}` : '';
        if (!!errorString && errorString.startsWith('Error: ')) {
            const errorPayloadString = errorString.replace('Error: ', '');
            try {
                const errorResult = JSON.parse(errorPayloadString);
                if (!!errorResult.Error) {
                    message = errorResult.Error.Message;
                    code = errorResult.Error.Code;
                }
            }
            catch {
                // do nothing
            }
        }
        if (!message) {
            message = !!err ? err.message : undefined;
        }
        if (!code) {
            code = !!err ? err.code : undefined;
        }
        const error = new MlClientError(message, '', FailureOperation.GetUserSettings, code);
        let cachedSettings = undefined;
        try {
            const displayId = yield select(getDisplayId);
            cachedSettings = yield call(UserSettingsProvider.getUserSettingsFromCache, displayId);
        }
        catch {
            // do nothing
        }
        yield put(loadUserSettingsError(error, cachedSettings));
    }
}
export function* loadVNextUserSettings(action) {
    try {
        const { canRedirect } = action;
        const accessToken = yield call(getLabsApiAccessTokenSaga, canRedirect);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const displayId = yield select(getDisplayId);
        const settings = yield call(VNextUserSettingsProvider.getUserSettings, displayId, accessToken, locale, language);
        yield put(loadUserSettingsSuccess(settings));
        yield put(updateUserSettingsExists());
    }
    catch (err) {
        let message = undefined;
        let code = undefined;
        const errorString = !!err ? `${err}` : '';
        if (!!errorString && errorString.startsWith('Error: ')) {
            const errorPayloadString = errorString.replace('Error: ', '');
            try {
                const errorResult = JSON.parse(errorPayloadString);
                if (!!errorResult.Error) {
                    message = errorResult.Error.Message;
                    code = errorResult.Error.Code;
                }
            }
            catch {
                // do nothing
            }
        }
        if (!message) {
            message = !!err ? err.message : undefined;
        }
        if (!code) {
            code = !!err ? err.code : undefined;
        }
        const error = new MlClientError(message, '', FailureOperation.GetUserSettings, code);
        let cachedSettings = undefined;
        try {
            const displayId = yield select(getDisplayId);
            cachedSettings = yield call(UserSettingsProvider.getUserSettingsFromCache, displayId);
        }
        catch {
            // do nothing
        }
        yield put(loadUserSettingsError(error, cachedSettings));
    }
}
export function* loadUserSettingsSaga() {
    yield featureBasedTakeLatest(IdentityActionType.LOAD_USER_SETTINGS, loadUserSettings, loadVNextUserSettings);
}
