import { SharedImageActionType, } from './shared-image-actions';
export const listSharedImages = (labAccountId) => ({
    type: SharedImageActionType.LIST_SHARED_IMAGES,
    labAccountId,
});
export const listSharedImagesSuccess = (images) => ({
    type: SharedImageActionType.LIST_SHARED_IMAGES_SUCCESS,
    images,
});
export const listSharedImagesError = (error) => ({
    type: SharedImageActionType.LIST_SHARED_IMAGES_ERROR,
    error,
});
export const getSharedGallery = (labAccountId) => ({
    type: SharedImageActionType.GET_SHARED_GALLERY,
    labAccountId,
});
export const getSharedGallerySuccess = (sharedGalleries) => ({
    type: SharedImageActionType.GET_SHARED_GALLERY_SUCCESS,
    sharedGalleries,
});
export const getSharedGalleryError = (error) => ({
    type: SharedImageActionType.GET_SHARED_GALLERY_ERROR,
    error,
});
export const createSharedImage = (templateId, displayName) => ({
    type: SharedImageActionType.CREATE_SHARED_IMAGE,
    templateId,
    displayName,
});
export const createSharedImageError = (templateId, error) => ({
    type: SharedImageActionType.CREATE_SHARED_IMAGE_ERROR,
    templateId,
    error,
});
export const updateSharedImage = (sharedImageId, templateId) => ({
    type: SharedImageActionType.UPDATE_SHARED_IMAGE,
    sharedImageId,
    templateId,
});
export const updateSharedImageSuccess = () => ({
    type: SharedImageActionType.UPDATE_SHARED_IMAGE_SUCCESS,
});
export const updateSharedImageError = (templateId, error) => ({
    type: SharedImageActionType.UPDATE_SHARED_IMAGE_ERROR,
    templateId,
    error,
});
const actions = {
    getSharedGallery,
    getSharedGallerySuccess,
    getSharedGalleryError,
    listSharedImages,
    listSharedImagesSuccess,
    listSharedImagesError,
    createSharedImage,
    createSharedImageError,
    updateSharedImageError,
};
export default actions;
