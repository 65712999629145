// Your use of the content in the files referenced here is subject to the terms of the license at https://aka.ms/fabric-assets-license
import { registerIcons } from '@uifabric/styling';
import { svgIcons } from '../svgs/svg-icons';
import '../css/fabric-icons.css';
// NOTES: This file was generated and then modified. Modifications include a mechanism for resolving our fonts without pre-loading a CDN,
// as well as the  ability to call registerIcons once while including our SVG icons.
export function initializeIcons(_baseUrl = '', options) {
    const subset = {
        style: {
            MozOsxFontSmoothing: 'grayscale',
            WebkitFontSmoothing: 'antialiased',
            fontStyle: 'normal',
            fontWeight: 'normal',
            speak: 'none',
        },
        fontFace: {
            fontFamily: `"FabricMDL2Icons"`,
            src: `url('./static/media/fabric-icons.9ed0c2b7.woff') format('woff')`,
        },
        icons: {
            Edit: '\uE70F',
            Add: '\uE710',
            More: '\uE712',
            Settings: '\uE713',
            Mail: '\uE715',
            People: '\uE716',
            Stop: '\uE71A',
            Link: '\uE71B',
            Filter: '\uE71C',
            Search: '\uE721',
            Refresh: '\uE72C',
            Delete: '\uE74D',
            Play: '\uE768',
            WindowsLogo: '\uE782',
            Warning: '\uE7BA',
            TVMonitor: '\uE7F4',
            View: '\uE890',
            Upload: '\uE898',
            GotoToday: '\uE8D1',
            Permissions: '\uE8D7',
            Clock: '\uE917',
            Timer: '\uE91E',
            Info: '\uE946',
            PeopleAdd: '\uEA15',
            Ringer: '\uEA8F',
            CirclePlus: '\uEAEE',
            Money: '\uEAFD',
            EditMirrored: '\uEB7E',
            ThisPC: '\uEC4E',
            DeveloperTools: '\uEC7A',
            DateTime: '\uEC92',
            Export: '\uEDE1',
            DateTimeMirrored: '\uEE93',
            ConnectVirtualMachine: '\uEE9D',
            ClipboardList: '\uF0E3',
            ClipboardListMirrored: '\uF0E4',
            StatusCircleQuestionMark: '\uF142',
            ViewDashboard: '\uF246',
            MoreVertical: '\uF2BC',
            EngineeringGroup: '\uF362',
            TestBeaker: '\uF3A5',
            TestBeakerSolid: '\uF3A6',
            LinuxLogo32: '\uF5D3',
            Warning12: '\uF62F',
            DisconnectVirtualMachine: '\uF873',
            ...svgIcons,
        },
    };
    registerIcons(subset, options);
}
