import { PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import * as React from 'react';
import _ from 'lodash';
export const SubmitButton = (props) => {
    const { isSubmitting, buttonText, disabled, spinnerStyles: providedSpinnerStyles, styles: providedStyles } = props;
    const spinnerStyles = _.merge({}, { root: { marginRight: 8 } }, providedSpinnerStyles);
    const styles = _.merge({}, { root: { fontWeight: 600, width: 'fit-content' } }, providedStyles);
    const mergedProps = { ...props, ...{ disabled: disabled || isSubmitting, text: undefined, styles } };
    return (<PrimaryButton {...mergedProps}>
            {isSubmitting && <Spinner size={SpinnerSize.small} styles={spinnerStyles}/>}
            {buttonText}
        </PrimaryButton>);
};
export default SubmitButton;
