import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
const messages = defineMessages({
    simpleUserExperience: {
        id: 'SimpleUserExperience',
        defaultMessage: 'Simple user experience',
        description: 'Introduction to student user experience in adding tab dialog in Teams',
    },
    simpleUserExperienceDescription: {
        id: 'SimpleUserExperienceDescription',
        defaultMessage: 'Provide immediate access to VMs for invited users',
        description: 'Details about simple user experience',
    },
    flexibilityInCreatingLab: {
        id: 'FlexibilityInCreatingLab',
        defaultMessage: 'Flexibility in creating customized lab environments',
        description: 'Introduction to professor user experience in adding tab dialog in Teams',
    },
    flexibilityInCreatingLabDescription: {
        id: 'FlexibilityInCreatingLabDescription',
        defaultMessage: 'Use Azure Marketplace images or bring in your custom images',
        description: 'Details about flexibility in creating lab environments',
    },
    costOptimization: {
        id: 'CostOptimization',
        defaultMessage: 'Cost optimization',
        description: 'Introduction to schedule in adding tab dialog in Teams',
    },
    costOptimizationDescription: {
        id: 'CostOptimizationDescription',
        defaultMessage: 'Schedule designated usage times or limit usage by setting up quotas',
        description: 'Details about cost optimization',
    },
    payForWhatYouNeed: {
        id: 'PayForWhatYouNeed',
        defaultMessage: 'Pay for just what you need',
        description: 'Introduction to dashboard in adding tab dialog in Teams',
    },
    payForWhatYouNeedDescription: {
        id: 'PayForWhatYouNeedDescription',
        defaultMessage: 'Only pay for active usage in your labs',
        description: 'Details about just pay for what you need',
    },
    computerLabsInCloud: {
        id: 'ComputerLabsInCloud',
        defaultMessage: 'Computer labs in the cloud',
        description: 'Title of introduction about Azure Lab Services',
    },
    aboutAzureLabServicesContentPartOne: {
        id: 'AboutAzureLabServicesContentPartOne',
        defaultMessage: 'Azure Lab Services is a managed cloud service that enables educators and IT to easily roll out cost-efficient labs with customizable virtual machines, without having to master the complexities of setting up cloud infrastructure.',
        description: 'First part for about Azure Lab Services',
    },
    aboutAzureLabServicesContentPartTwo: {
        id: 'AboutAzureLabServicesContentPartTwo',
        defaultMessage: 'Azure Lab Services helps to quickly create virtual lab environments and enables to easily run a class, set up a training lab, or host a hackathon in the cloud so that users can access lab resources from anywhere, anytime.',
        description: 'Second part for about Azure Lab Services',
    },
    aboutAzureLabServicesKeyCapabilities: {
        id: 'AboutAzureLabServicesKeyCapabilities',
        defaultMessage: 'Azure Lab Services provides the following key capabilities –',
        description: 'Key capabilities of Azure Lab Services',
    },
    simpleUserExperienceCapability: {
        id: 'SimpleUserExperienceCapability',
        defaultMessage: '· Simple User Experience – Provide immediate access to VMs for invited users. With one click users can connect and start working - no Azure subscription needed.',
        description: 'Details about simple user experience in about Azure Lab Services content',
    },
    flexibilityCapability: {
        id: 'FlexibilityDetails',
        defaultMessage: '· Flexibility – Use thousands of Azure Marketplace images or bring in your custom images to quickly provision lab VMs and use repeatedly across labs.',
        description: 'Details about flexibility in about Azure Lab Services content',
    },
    costOptimizationCapability: {
        id: 'CostOptimizationDetails',
        defaultMessage: '· Cost Optimization and Tracking – Manage your lab budget with usage control features. Schedule designated usage times or set up recurring auto-shutdowns and start times. Track individuals’ hourly usage or limit usage by setting up quotas.',
        description: 'Details about cost optimization and Tracking',
    },
    automaticManagementAndScalingCapability: {
        id: 'AutomaticManagementAndScalingCapability',
        defaultMessage: '· Automatic Management and Scaling - Provisioning and scaling to hundreds of VMs with a single click, with the service managing all underlying infrastructure.',
        description: 'Details about automatic management and scaling',
    },
    syncWithTeams: {
        id: 'SyncWithTeams',
        defaultMessage: "When a lab is created within Teams, Azure Lab Services will sync with your Team's member list and automatically provision or decommission your lab's virtual machines based on membership:",
        description: 'Sync with Teams introduction in About content',
    },
    addUsersInTeams: {
        id: 'AddUsersInTeams',
        defaultMessage: 'When new users are added to your Team, new virtual machines will be created and assigned to them.',
        description: 'Details about adding users when in Teams',
    },
    deleteUsersInTeams: {
        id: 'DeleteUsersInTeams',
        defaultMessage: 'When users are deleted from your Team, virtual machines that were assigned to them will be deleted.',
        description: 'Details about deleting users when in Teams',
    },
});
export const TeamsMessages = () => {
    return (<>
            <FormattedMessage {...messages.simpleUserExperience}/>
            <FormattedMessage {...messages.simpleUserExperienceDescription}/>
            <FormattedMessage {...messages.flexibilityInCreatingLab}/>
            <FormattedMessage {...messages.flexibilityInCreatingLabDescription}/>
            <FormattedMessage {...messages.costOptimization}/>
            <FormattedMessage {...messages.costOptimizationDescription}/>
            <FormattedMessage {...messages.payForWhatYouNeed}/>
            <FormattedMessage {...messages.payForWhatYouNeedDescription}/>
            <FormattedMessage {...messages.computerLabsInCloud}/>
            <FormattedMessage {...messages.aboutAzureLabServicesContentPartOne}/>
            <FormattedMessage {...messages.aboutAzureLabServicesContentPartTwo}/>
            <FormattedMessage {...messages.aboutAzureLabServicesKeyCapabilities}/>
            <FormattedMessage {...messages.simpleUserExperienceCapability}/>
            <FormattedMessage {...messages.flexibilityCapability}/>
            <FormattedMessage {...messages.costOptimizationCapability}/>
            <FormattedMessage {...messages.automaticManagementAndScalingCapability}/>
            <FormattedMessage {...messages.addUsersInTeams}/>
            <FormattedMessage {...messages.deleteUsersInTeams}/>
        </>);
};
export default TeamsMessages;
