import Constants from '../../../../utils/constants';
import { defaultSettings } from '../../../models/user-settings';
import { updateSettingsCache } from '../../user-settings-provider';
import { createLabsApiClient } from './labs-api-client-factory';
// need to convert string types to boolean types
function convertResponse(body) {
    if (body.disableHints) {
        body.disableHints = body.disableHints === 'true' ? true : false;
    }
    if (body.dismissedLabListFirstRun) {
        body.dismissedLabListFirstRun = body.dismissedLabListFirstRun === 'true' ? true : false;
    }
    if (body.showRetirementAlert) {
        body.showRetirementAlert = body.showRetirementAlert === 'true' ? true : false;
    }
    if (body.hasDismissedCovidBanner) {
        body.hasDismissedCovidBanner = body.hasDismissedCovidBanner === 'true' ? true : false;
    }
    if (body.hasDismissedOldV1GpuLabBanner) {
        body.hasDismissedOldV1GpuLabBanner = body.hasDismissedOldV1GpuLabBanner === 'true' ? true : false;
    }
    return body;
}
export async function getUserSettings(displayId, accessToken, locale, language) {
    const labsApiClient = createLabsApiClient(Constants.NoSubscriptionId, accessToken, locale, language);
    const response = await labsApiClient.userSettings.get();
    if (!response) {
        throw new Error('Failed to get user settings from server');
    }
    if (!!response) {
        const settings = convertResponse(response);
        updateSettingsCache(displayId, settings);
        return settings;
    }
    return defaultSettings;
}
export async function createUserSettings(settings, displayId, accessToken, locale, language) {
    updateSettingsCache(displayId, settings);
    const labsApiClient = createLabsApiClient(Constants.NoSubscriptionId, accessToken, locale, language);
    await labsApiClient.userSettings.createOrUpdate(settings);
}
export async function updateUserSettings(settings, displayId, accessToken, locale, language) {
    updateSettingsCache(displayId, settings);
    const labsApiClient = createLabsApiClient(Constants.NoSubscriptionId, accessToken, locale, language);
    await labsApiClient.userSettings.update(settings);
}
const UserSettingsProvider = {
    getUserSettings,
    createUserSettings,
    updateUserSettings,
};
export default UserSettingsProvider;
