// Regular expression below is taken from https://github.com/Microsoft/referencesource/blob/master/System.ComponentModel.DataAnnotations/DataAnnotations/EmailAddressAttribute.cs
// With trailing period (\.?) removed.
// eslint-disable-next-line security/detect-unsafe-regex
const emailRegex = /^((([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-zA-Z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-zA-Z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-zA-Z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/;
// Splits string of separated emails into individual entries.
export function splitEmailList(emailList) {
    return emailList
        .replace(/[^,;]*.?</g, '') // remove name before <email@microsoft.com>
        .replace(/>/g, '') // remove > at the end of <email@microsoft.com>
        .replace(/[,;\r ]{1,}/g, '\n') // replace other separators with newlines
        .replace(/[\n]{2,}/g, '\n') // remove duplicate newlines
        .split('\n') // split newlines
        .map((o) => o.trim()) // remove leading/trailing whitespace
        .filter((o) => o.length > 0) // remove empty entries
        .map((o) => o.toLowerCase()) // lowercase all entries
        .filter((value, index, array) => array.indexOf(value) === index) // remove duplicates
        .sort((a, b) => a.localeCompare(b)); // sort alphabetically
}
// Returns true if and only if given string is a valid email address.
export function isValidEmail(email) {
    return !!email && emailRegex.test(email);
}
// Runs email list validation.
export function validateEmailList(emailList, message) {
    const emails = splitEmailList(emailList);
    const isValid = emails.length > 0 && emails.every((o) => isValidEmail(o));
    return isValid ? undefined : message;
}
