import * as React from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import FullPageMessage from '../full-page-message/full-page-message';
import { commonMessages } from '../language/common-messages';
const messages = defineMessages({
    signedOutMessage: {
        id: 'SignedOutMessage',
        defaultMessage: 'You are signed out.',
        description: 'The message displayed to a user when they sign out.',
    },
});
const UserLoggedOutInjected = (props) => {
    const signedOutMessage = props.intl.formatMessage(messages.signedOutMessage);
    const signInButtonText = props.intl.formatMessage(commonMessages.signInButtonText);
    return (<FullPageMessage image="session-expired" message={signedOutMessage} buttonText={signInButtonText} buttonOnClick={props.signIn}/>);
};
export const UserLoggedOut = injectIntl(UserLoggedOutInjected);
export default UserLoggedOut;
