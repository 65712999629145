import { all, fork } from 'redux-saga/effects';
import { getCoreLimitsSaga, getCoreLimitsOnSelectLabParentResourceSaga, getCoreLimitsOnSelectLabSaga, getCoreLimitsOnPublishTemplateSaga, getCoreLimitsOnCreateLabSuccessSaga, getCoreLimitsOnUpdateLabSuccessSaga, getCoreUsageOnPublishVNextLabSaga, } from './get-core-limits';
export function* rootSaga() {
    yield all([
        fork(getCoreLimitsSaga),
        fork(getCoreLimitsOnSelectLabParentResourceSaga),
        fork(getCoreLimitsOnSelectLabSaga),
        fork(getCoreLimitsOnPublishTemplateSaga),
        fork(getCoreLimitsOnCreateLabSuccessSaga),
        fork(getCoreLimitsOnUpdateLabSuccessSaga),
        fork(getCoreUsageOnPublishVNextLabSaga),
    ]);
}
