import * as React from 'react';
import { connect } from 'react-redux';
import { studentInitialize } from '../redux/actions/common/common-action-creators';
import { InitializationState } from '../redux/state/common-store';
import { StudentApp } from './student-app';
class StudentAppContainerInjected extends React.Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (this.props.studentInitializeState === InitializationState.NotStarted) {
            this.props.studentInitialize();
        }
    }
    render() {
        const { isInitializing } = this.props;
        return <StudentApp isInitializing={isInitializing}/>;
    }
}
const mapStateToProps = (state) => {
    const commonStore = state.get('commonStore');
    const studentInitializeState = commonStore.get('studentInitializeState');
    const isInitializing = studentInitializeState === InitializationState.NotStarted ||
        studentInitializeState === InitializationState.Initializing;
    return {
        studentInitializeState,
        isInitializing,
    };
};
const mapDispatchToProps = {
    studentInitialize,
};
export const StudentAppContainer = connect(mapStateToProps, mapDispatchToProps)(StudentAppContainerInjected);
export default StudentAppContainer;
