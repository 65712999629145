import { Record, List, Map } from 'immutable';
import _ from 'lodash';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { GalleryImageActionType, } from '../actions/gallery-image/gallery-image-actions';
import { Context } from '../action-context';
function listGalleryImages(state, action) {
    return state.merge({
        errors: Map(),
        loadState: LoadingStoreState.Loading,
    });
}
function listGalleryImagesSuccess(state, action) {
    return state.merge({
        images: List(action.images),
        loadState: LoadingStoreState.Loaded,
    });
}
function listGalleryImagesError(state, action) {
    return state.merge({
        errors: addError(state.errors, action.error, action.error.id),
        loadState: LoadingStoreState.LoadingFailed,
        images: List([]),
    });
}
function addError(errorMap, error, id) {
    const errors = errorMap.get(id);
    if (errors) {
        const existingError = errors.some((_error) => _.isEqual(_error, error));
        if (!existingError) {
            return errorMap.set(id, errors.concat(error));
        }
    }
    else {
        return errorMap.set(id, List([error]));
    }
    return errorMap;
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    images: List(),
    errors: Map(),
})();
export const galleryImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case GalleryImageActionType.CLEAR_USER_SESSION:
        case GalleryImageActionType.SELECT_LAB_PARENT_RESOURCE:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    return state;
                default:
                    switch (action.type) {
                        case GalleryImageActionType.LIST_GALLERY_IMAGES:
                            return listGalleryImages(state, action);
                        case GalleryImageActionType.LIST_GALLERY_IMAGES_SUCCESS:
                            return listGalleryImagesSuccess(state, action);
                        case GalleryImageActionType.LIST_GALLERY_IMAGES_ERROR:
                            return listGalleryImagesError(state, action);
                        default:
                            return state;
                    }
            }
    }
};
export default galleryImageReducer;
