import { all, fork } from 'redux-saga/effects';
import { configureTabSaga } from './teams-configure-tab';
import { disableSaveButtonSaga } from './teams-disable-save-button';
import { teamsInitializeSaga } from './teams-initialize';
import { teamsStartMfaSaga } from './teams-start-mfa';
import { teamsCompleteMfaSaga } from './teams-complete-mfa';
export function* rootSaga() {
    yield all([
        fork(teamsInitializeSaga),
        fork(disableSaveButtonSaga),
        fork(configureTabSaga),
        fork(teamsStartMfaSaga),
        fork(teamsCompleteMfaSaga),
    ]);
}
