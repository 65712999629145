import { MessageBar, MessageBarType, Link } from '@fluentui/react';
import { injectIntl, FormattedMessage } from 'react-intl';
import * as React from 'react';
import { ErrorCode } from '../common/error-codes';
import { RequestLimitIncreaseInSentenceLink } from '../common/request-limit-increase-link';
import { getErrorMessage, messages } from './template-error-handling';
import commonMessages from '../language/common-messages';
import './template.css';
const TemplateErrorBannerInjected = (props) => {
    return (<>
            {props.error && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={props.intl.formatMessage(commonMessages.close)} onDismiss={() => props.clearError(props.error)}>
                    {props.error.code != ErrorCode.TotalRegionalCoreLimit
        ? getErrorMessage(props.intl, props.error)
        : props.intl.formatMessage(messages.publishTemplateTotalRegionalCoreLimitError, {
            requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labId={props.labId} tenantId={props.currentTenantId}/>),
        })}
                </MessageBar>)}
        </>);
};
export const TemplateErrorBanner = injectIntl(TemplateErrorBannerInjected);
const TemplateErrorBannersInjected = (props) => {
    const { labId, errors, clearError, loadError, publishExceedsCoresError, currentTenantId, clearLoadError, clearPublishExceedsCoresError, intl, } = props;
    const msg = intl.formatMessage;
    return (<>
            {errors
        .map((errors) => {
        return errors.map((error, errorIndex) => (<TemplateErrorBanner labId={labId} currentTenantId={currentTenantId} key={`${error.id}-${error.failureOperation}`} error={error} clearError={() => clearError(error.id, errorIndex)}/>));
    })
        .toList()}
            {loadError && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearLoadError()}>
                    {getErrorMessage(intl, loadError)}
                </MessageBar>)}
            {publishExceedsCoresError && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearPublishExceedsCoresError()}>
                    {msg(commonMessages.coreUsageExceedsErrorBannerMessage, {
        requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labId={labId} tenantId={currentTenantId}/>),
    })}
                </MessageBar>)}
        </>);
};
export const TemplateErrorBanners = injectIntl(TemplateErrorBannersInjected);
export const PublishSuccessBanner = (props) => {
    const { onDismiss } = props;
    return (<MessageBar onDismiss={onDismiss} messageBarType={MessageBarType.success}>
            <FormattedMessage id="TemplateSuccessfulPublish" defaultMessage="The template was successfully published! It is now available to users." description="Text in banner to indicate the publish was successful."/>
        </MessageBar>);
};
const GpuDriverBannerInjected = (props) => {
    const { isWindows, message, linkText } = props;
    const url = isWindows ? 'https://aka.ms/azlabs-gpu-windows' : 'https://aka.ms/azlabs-gpu-linux';
    return (<MessageBar {...props}>
            {props.intl.formatMessage(message)}
            <Link href={url} target="_blank">
                {props.intl.formatMessage(linkText)}
            </Link>
        </MessageBar>);
};
export const GpuDriverBanner = injectIntl(GpuDriverBannerInjected);
