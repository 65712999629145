import { defineMessages } from 'react-intl';
export const commonMessages = defineMessages({
    signInButtonText: {
        id: 'SignInButtonText',
        defaultMessage: 'Sign in',
        description: 'Text for sign in buttons across the site',
    },
    numberOne: {
        id: 'CommonNumberOne',
        defaultMessage: '1',
        description: 'Text for the number one',
    },
    numberTwo: {
        id: 'CommonNumberTwo',
        defaultMessage: '2',
        description: 'Text for the number two',
    },
    numberThree: {
        id: 'CommonNumberThree',
        defaultMessage: '3',
        description: 'Text for the number three',
    },
    loadingText: {
        id: 'LoadingStatusMessage',
        defaultMessage: 'Loading...',
        description: 'Text to indicate loading is in progress',
    },
    copy: {
        id: 'CommonWordCopy',
        defaultMessage: 'Copy',
        description: 'The word copy.',
    },
    copied: {
        id: 'CommonWordCopied',
        defaultMessage: 'Copied',
        description: 'The word copied.',
    },
    done: {
        id: 'CommonWordDone',
        defaultMessage: 'Done',
        description: 'The word done.',
    },
    cancel: {
        id: 'CommonWordCancel',
        defaultMessage: 'Cancel',
        description: 'The word cancel.',
    },
    edit: {
        id: 'CommonWordEdit',
        defaultMessage: 'Edit',
        description: 'The word edit.',
    },
    change: {
        id: 'CommonWordChange',
        defaultMessage: 'Change',
        description: 'The word change.',
    },
    virtualMachines: {
        id: 'CommonPhraseVirtualMachines',
        defaultMessage: 'Virtual machines',
        description: 'Text for virtual machines',
    },
    schedule: {
        id: 'CommonWordSchedule',
        defaultMessage: 'Schedule',
        description: 'Word for schedule',
    },
    users: {
        id: 'CommonWordUsers',
        defaultMessage: 'Users',
        description: 'Word for users',
    },
    delete: {
        id: 'CommonWordDelete',
        defaultMessage: 'Delete',
        description: 'Word for delete',
    },
    close: {
        id: 'CommonWordClose',
        defaultMessage: 'Close',
        description: 'Word for close',
    },
    continue: {
        id: 'CommonWordContinue',
        defaultMessage: 'Continue',
        description: 'Word for continue',
    },
    ok: {
        id: 'CommonWordOk',
        defaultMessage: 'OK',
        description: 'Word for ok',
    },
    yes: {
        id: 'CommonWordYes',
        defaultMessage: 'Yes',
        description: 'Word for yes',
    },
    no: {
        id: 'CommonWordNo',
        defaultMessage: 'No',
        description: 'Word for no',
    },
    saving: {
        id: 'CommonWordSaving',
        defaultMessage: 'Saving...',
        description: 'Word for saving with three dots to indicate progress in operation',
    },
    save: {
        id: 'CommonWordSave',
        defaultMessage: 'Save',
        description: 'Word for save',
    },
    add: {
        id: 'CommonWordAdd',
        defaultMessage: 'Add',
        description: 'Word for add',
    },
    send: {
        id: 'CommonWordSend',
        defaultMessage: 'Send',
        description: 'Word for send',
    },
    discard: {
        id: 'CommonWordDiscard',
        defaultMessage: 'Discard',
        description: 'Word for discard',
    },
    reset: {
        id: 'CommonWordReset',
        defaultMessage: 'Reimage',
        description: 'Word for Reimage virtual machine',
    },
    start: {
        id: 'CommonWordStart',
        defaultMessage: 'Start',
        description: 'Word for start',
    },
    stop: {
        id: 'CommonWordStop',
        defaultMessage: 'Stop',
        description: 'Word for stop',
    },
    redeploy: {
        id: 'CommonWordRedeploy',
        defaultMessage: 'Redeploy',
        description: 'Word for redeploy',
    },
    reimage: {
        id: 'CommonWordReimage',
        defaultMessage: 'Reimage',
        description: 'Word for reimage',
    },
    setContact: {
        id: 'CommonWordContact',
        defaultMessage: 'Set contact',
        description: 'Common label for setting a contact.',
    },
    none: {
        id: 'CommonWordNone',
        defaultMessage: 'None',
        description: 'Word for none',
    },
    azure: {
        id: 'CommonWordAzure',
        defaultMessage: 'Azure',
        description: 'Word for the Azure product.',
    },
    export: {
        id: 'CommonWordExport',
        defaultMessage: 'Export',
        description: 'Word for export.',
    },
    labServices: {
        id: 'CommonPhraseLabServices',
        defaultMessage: 'Lab Services',
        description: 'Common label that describes the product, which is called Lab Services.',
    },
    azureLabServices: {
        id: 'CommonPhraseAzureLabServices',
        defaultMessage: 'Azure Lab Services',
        description: 'Common label that is the title of the product, Azure Lab Services.',
    },
    filterByName: {
        id: 'CommonFilterByName',
        defaultMessage: 'Filter by name',
        description: 'Placeholder text for filter text box.',
    },
    selectedFormat: {
        id: 'CommonSelectedFormat',
        defaultMessage: '{numberOfObjects} selected',
        description: 'Message indicating how many objects is selected on a page. {numberOfObjects} is an integer greater or equal to 1.',
    },
    clearSelectionCommand: {
        id: 'CommonClearSelectionCommand',
        defaultMessage: 'Clear selection',
        description: 'Name of command bar command that clears current selection in a table.',
    },
    closeFilterCommand: {
        id: 'CommonCloseFilterCommand',
        defaultMessage: 'Close filter',
        description: 'Name of command bar command that closes filter bar.',
    },
    clearFilters: {
        id: 'CommonClearFilter',
        defaultMessage: 'Clear',
        description: 'Displayed in a filter menu as the option to clear what is being filtered.',
    },
    allFilter: {
        id: 'CommonAllFilter',
        defaultMessage: 'All',
        description: 'Filter value indicating that all objects should be included.',
    },
    nameColumnHeader: {
        id: 'CommonNameColumnHeader',
        defaultMessage: 'Name',
        description: 'Header for Name column of a table containing user or virtual machine name.',
    },
    usageColumnHeader: {
        id: 'CommonUsageColumnHeader',
        defaultMessage: 'Quota hours used',
        description: 'Title for Usage column of a table containing number of hours a VM was used.',
    },
    usageDisclaimer: {
        id: 'VmUsageColumnTooltip',
        defaultMessage: 'Quota hours used and cost to date include only hours consumed by the user. This does not include hours consumed if the lab creator started the machine, manually or via a scheduled event.',
        description: 'Tooltip show on VM/users pages.',
    },
    vmUsageColumnProgressFormat: {
        id: 'VmUsageColumnProgressFormat',
        defaultMessage: '{current}/{total} user hours',
        description: 'Text showing usage of time quota by a user on VM. {current} is used number of hours from {total} number of hours.',
    },
    vmUsageColumnUsageFormat: {
        id: 'VmUsageColumnUsageFormat',
        defaultMessage: '{current} user hours',
        description: 'Text showing current usage of a virtual machine. {current} is the number of used hours.',
    },
    usageProgressColumnHeader: {
        id: 'CommonUsageProgressColumnHeader',
        defaultMessage: 'Usage indicator',
        description: 'Title for Usage indicator column of a table containing number of hours a VM was used.',
    },
    creatingState: {
        id: 'CommonCreatingState',
        defaultMessage: 'Creating',
        description: 'State of a virtual machine when it is being created.',
    },
    deletingState: {
        id: 'CommonDeletingState',
        defaultMessage: 'Deleting',
        description: 'State of a virtual machine or a user when it is being deleted from the lab.',
    },
    stoppedState: {
        id: 'CommonStoppedState',
        defaultMessage: 'Stopped',
        description: 'State of a virtual machine when it is stopped.',
    },
    startingState: {
        id: 'CommonStartingState',
        defaultMessage: 'Starting',
        description: 'State of a virtual machine when it is starting.',
    },
    runningState: {
        id: 'CommonRunningState',
        defaultMessage: 'Running',
        description: 'State of a virtual machine when it is running.',
    },
    stoppingState: {
        id: 'CommonStoppingState',
        defaultMessage: 'Stopping',
        description: 'State of a virtual machine when it is stopping.',
    },
    failedState: {
        id: 'CommonFailedState',
        defaultMessage: 'Failed',
        description: 'State of a virtual machine when it has failed.',
    },
    unknownState: {
        id: 'CommonUnknownState',
        defaultMessage: 'Unknown',
        description: 'Displayed when the state of a virtual machine is unknown.',
    },
    resettingPasswordState: {
        id: 'CommonResettingPasswordState',
        defaultMessage: 'Resetting password',
        description: 'State of a virtual machine when it is resetting password.',
    },
    reimagingState: {
        id: 'CommonReimagingState',
        defaultMessage: 'Reimaging',
        description: 'State of a virtual machine when it is reimaging.',
    },
    redeployingState: {
        id: 'CommonRedeployingState',
        defaultMessage: 'Redeploying',
        description: 'State of a virtual machine when it is redeploying.',
    },
    sortedAtoZ: {
        id: 'CommonSortedAtoZ',
        defaultMessage: 'Sorted in alphabetical order',
        description: 'Description of a table column that is sorted in alphabetical order.',
    },
    sortedZtoA: {
        id: 'CommonSortedZtoA',
        defaultMessage: 'Sorted in reverse alphabetical order',
        description: 'Description of a table column that is sorted in reverse alphabetical order.',
    },
    sortedSmallerToLarger: {
        id: 'CommonSortedSmallerToLarger',
        defaultMessage: 'Sorted smaller to larger',
        description: 'Description of a table column that is sorted from smaller to larger value.',
    },
    sortedLargerToSmaller: {
        id: 'CommonSortedLargerToSmaller',
        defaultMessage: 'Sorted larger to smaller',
        description: 'Description of a table column that is sorted from larger to smaller value.',
    },
    toggleSelection: {
        id: 'CommonToggleSelection',
        defaultMessage: 'Toggle selection',
        description: 'Description of a button to toggle selection on a list view item.',
    },
    toggleAllSelection: {
        id: 'CommonToggleAllSelection',
        defaultMessage: 'Toggle selection for all items',
        description: 'Description of a button to toggle selection on all items in a list view.',
    },
    genericErrorMessage: {
        id: 'GenericErrorMessage',
        defaultMessage: `An error has occurred.`,
        description: 'Message to indicate an error happened when we do not know the cause of the error.',
    },
    creating: {
        id: 'VmCreatingMessage',
        defaultMessage: 'Creating...',
        description: 'Message displayed while creating a VM.',
    },
    starting: {
        id: 'VmStartingMessage',
        defaultMessage: 'Starting...',
        description: 'Message displayed while starting a VM.',
    },
    deleting: {
        id: 'VmDeletingMessage',
        defaultMessage: 'Deleting...',
        description: 'Message displayed while deleting a VM.',
    },
    stopping: {
        id: 'VmStoppingMessage',
        defaultMessage: 'Stopping...',
        description: 'Message displayed while stopping a VM.',
    },
    resettingPassword: {
        id: 'VmResettingPasswordMessage',
        defaultMessage: 'Resetting password...',
        description: 'Message displayed while resetting the password of a VM.',
    },
    settingPassword: {
        id: 'VmSettingPasswordMessage',
        defaultMessage: 'Setting password...',
        description: 'Message displayed while setting a password for the first time.',
    },
    vmDownloadRdpFileTitle: {
        id: 'VmDownloadRdpFileTitle',
        defaultMessage: 'Download RDP file',
        description: 'Title for the connect via RDP when in Teams',
    },
    vmSshConnectTitle: {
        id: 'VmSshConnectTitle',
        defaultMessage: 'Connect via SSH',
        description: 'Title for the connect via SSH button.',
    },
    vmSshBrowserConnectTitle: {
        id: 'VmSshBrowserConnectTitle',
        defaultMessage: 'Connect via SSH in browser',
        description: 'Title for the connect via SSH in browser button.',
    },
    vmRdpConnectTitle: {
        id: 'VmRdpConnectTitle',
        defaultMessage: 'Connect via RDP',
        description: 'Title for the connect via RDP button.',
    },
    vmRdpBrowserConnectTitle: {
        id: 'VmRdpBrowserConnectTitle',
        defaultMessage: 'Connect via RDP in browser',
        description: 'Title for the connect via RDP in browser button.',
    },
    apply: {
        id: 'CommonWordApply',
        defaultMessage: 'Apply',
        description: 'Text for a button to apply changes.',
    },
    resetPassword: {
        id: 'ResetPasswordDialogTitle',
        defaultMessage: 'Reset password',
        description: 'The title for the password dialog when the user has set a password before. This text is also used for the confirmation button, and command bar button as well.',
    },
    help: {
        id: 'Help',
        defaultMessage: 'Help',
        description: 'Label for the title of flyout displaying help information.',
    },
    previous: {
        id: 'CommonWordPrevious',
        defaultMessage: 'Previous',
        description: 'The word previous. Used for ARIA labels on buttons that go back.',
    },
    next: {
        id: 'CommonWordNext',
        defaultMessage: 'Next',
        description: 'The word next. Used for ARIA labels on buttons that go forward.',
    },
    coreUsageExceedsErrorBannerMessage: {
        id: 'CoreUsageExceedsErrorBannerMessage',
        defaultMessage: 'Your account has reached its virtual machine cores limit in Azure Lab Services.  To proceed, you need to {requestLimitIncreaseLink}.  You can expect the process to take 1-5 days. Due to high demand, some SKUs may be temporarily unavailable.',
        description: 'The message displayed in the error banners whenever an action has exceeded the subscription core limits, along with instructions on how to increase quota.',
    },
    exportToCsv: {
        id: 'ExportCommand',
        defaultMessage: 'Export CSV',
        description: 'The title for the Export CSV command menu item.',
    },
    myVirtualMachines: {
        id: 'CommonPhraseMyVirtualMachines',
        defaultMessage: 'My virtual machines',
        description: 'Text for my virtual machines',
    },
    showRdpInfoTitle: {
        id: 'ShowRdpInfoTitle',
        defaultMessage: 'Show RDP information',
        description: 'Title for the Show RDP info button.',
    },
    covidWarning: {
        id: 'Covid19UnassignedVmsWarning',
        defaultMessage: 'To enable remote learning experiences for more organizations, we will remove any virtual machines that are not assigned to a user within 30 days.',
        description: 'A warning shown to users about new VM cleanup procedures.',
    },
    covidPublishWarning: {
        id: 'Covid19UnassignedVmsPublishWarning',
        defaultMessage: 'Any virtual machine that is not assigned to a user within 30 days will be automatically removed from the lab.',
        description: 'A warning shown to users after publishing a lab about new VM cleanup procedures.',
    },
    oldV1GpuLabsGenericWarning: {
        id: 'OldV1GpuLabsGenericWarning',
        defaultMessage: 'One or more of your labs was created before February 21, 2024 and possibly uses a virtual machine size which is not going to be available in Azure past July 15, 2024 . If you plan to use these lab(s) beyond July 15, 2024, please recreate these labs to avoid an outage for them beyond this date. {gpuOutage}',
        description: "A warning shown to users that have old labs showing them that they may not work in the future. {gpuOutage} is a link to the documentation regarding this warning with message id 'OldV1GpuLabsWarningLink'.",
    },
    oldV1GpuLabWarning: {
        id: 'OldV1GpuLabWarning',
        defaultMessage: 'This lab may not be accessible after July 15, 2024. If you plan to use this lab beyond this date, please recreate the lab. {gpuOutage}',
        description: "A warning shown to users that have an old lab showing them that it may not work in the future. {gpuOutage} is a link to the documentation regarding this warning with message id 'OldV1GpuLabWarningLink'."
    },
    permissionPropagationText: {
        id: 'PermissionPropagationText',
        defaultMessage: 'Please do not refresh the page. We are propagating the permissions while we create the lab. If you refresh the page, you may experience a delay in accessing the newly created lab.',
        description: 'A information shown to users about delay in showing new lab after refresh when permission needs propagation.',
    },
    moreActionsMenu: {
        id: 'MoreActionsMenuAriaLabel',
        defaultMessage: 'Access additional features',
        description: 'AriaLabel text for ellipses button on command bar.',
    },
    incrementAriaLabel: {
        id: 'IncrementAriaLabel',
        defaultMessage: 'Increment the value by one.',
        description: 'AriaLabel text for increment button on a spin button control.',
    },
    decrementAriaLabel: {
        id: 'DecrementAriaLabel',
        defaultMessage: 'Decrement the value by one.',
        description: 'AriaLabel text for decrement button on a spin button control.',
    },
    stepGetAnAzureSubscription: {
        id: 'LandingPageGetAzureSubscriptionHeader',
        defaultMessage: '{numberSpan} Get an Azure subscription',
        description: 'Instructions for setting up an Azure subscription where numberSpan contains the step number with markup.',
    },
    stepAddLabAccount: {
        id: 'LandingPageAddALabAccountHeader',
        defaultMessage: '{numberSpan} Create a lab plan (preview) or lab account',
        description: 'Instructions for setting up a lab account where numberSpan contains the step number with markup.',
    },
    stepAddLabPlan: {
        id: 'LandingPageAddALabPlanHeader',
        defaultMessage: '{numberSpan} Create a lab plan',
        description: 'Instructions for setting up a lab plan where numberSpan contains the step number with markup.',
    },
    sync: {
        id: 'UserListSync',
        defaultMessage: 'Sync',
        description: 'Name of the command on the users page command bar for syncing the user list with aadGroupId',
    },
    lastUserListGroupSyncTime: {
        id: 'lastUserListGroupSyncTime',
        defaultMessage: 'Last synced from "<aadGroupName></aadGroupName>" {lastGroupSyncRelativeTime}',
        description: 'Description about last user list group sync time',
    },
    searchLabAccountText: {
        id: 'LabAccountMenuSearchLabAccountPlaceholder',
        defaultMessage: 'Search items',
        description: 'Placeholder for resource group or lab account picker search box',
    },
    signOutButtonText: {
        id: 'SignOutButtonText',
        defaultMessage: 'Sign out',
        description: 'Text for sign out buttons across the site',
    },
    retry: {
        id: 'RetryButtonText',
        defaultMessage: 'Retry',
        description: 'Text for retry buttons',
    },
    troubleshoot: {
        id: 'CommonWordTroubleshoot',
        defaultMessage: 'Redeploy',
        description: 'Word for Troubleshoot, changing it to Redeploy',
    },
    grantAccess: {
        id: 'grantAccessButtonText',
        defaultMessage: 'Grant access',
        description: 'Text for grant web storage access',
    },
    standardSSD: {
        id: 'StandardSSD',
        defaultMessage: 'Standard SSD',
        description: 'Text for the Standard SSD (solid state drive) storage type',
    },
    premiumSSD: {
        id: 'PremiumSSD',
        defaultMessage: 'Premium SSD',
        description: 'Text for the Premium SSD (solid state drive) storage type',
    },
    privacyLink: {
        id: 'PrivacyLink',
        defaultMessage: 'Privacy',
        description: 'Link to our privacy policy',
    },
    termsOfUseLink: {
        id: 'TermsOfUseLink',
        defaultMessage: 'Terms of use',
        description: 'Link to our terms of use',
    },
    lmsLabPlanSettings: {
        id: 'LmsLabPlanSettings',
        defaultMessage: 'Lab plans',
        description: 'Title of the LMS connect flyout.',
    },
    feedback: {
        id: 'Feedback',
        defaultMessage: 'Feedback',
        description: 'Text for the feedback link.',
    },
});
export default commonMessages;
