import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import moment from 'moment';
import memoize from 'memoize-one';
import { VmState, VmAssignmentType } from '../data/models/environment-common';
import { getConnectionTypes, getVNextConnectionTypes } from '../common/selectors/vm-connect';
import { getCurrentLab, isLabGroupSynced, isCurrentLabReadOnly, isLabConnectedLmsOrTeams, isLabAssistantRole, } from '../redux/selectors/lab-selectors';
import { getCores, getCurrentTemplate, getIsGpu, getTemplates } from '../redux/selectors/template-selectors';
import { getTenantId } from '../redux/selectors/identity-selector';
import { getGroupName, isLoadingGroupName } from '../redux/selectors/group-selectors';
import { getIanaTimezone, getLocale, isBastionFeatureEnabled, isTeamsOrLmsMode, storeHasLoadError, storeIsLoading, } from '../redux/selectors/common-selectors';
import { getSearch } from '../redux/selectors/route-selector';
import { DefaultMaxCapacity } from '../utils/constants';
import { determineVCurrentQuotaStatus, getCoreLimits } from '../redux/selectors/core-limit-selectors';
import { LabServicesModels } from 'lab-services';
import { getUserVMRedeployErrors, getUserVMs, getUserVMStartErrors, getUserVMStopErrors, } from '../redux/selectors/vnext/virtual-machine-selectors';
import { caseInsensitiveCultureInvariantCompare } from '../utils/string-comparison';
import { isCurrentLabParentLabAccount } from '../redux/selectors/lab-parent-resource-selectors';
import { getCurrentLabUpdateError, getVNextLabGroupName, isLoadingLabMetadata, isVNextLabDraft, shouldDisableCurrentLabUpdate, } from '../redux/selectors/vnext/lab-selectors';
import { getCurrentLabCoreUsageInfo, getVNextUsageLoadState } from '../redux/selectors/vnext/usage-selectors';
import { getCurrentLabSkuInfo, getVNextSkuLoadState } from '../redux/selectors/vnext/sku-selectors';
export function getVmDetails(env, gettingEnvironments, users, template) {
    const { id, name, vmState: currentVmState, pendingVmState, networkInterface } = env;
    const vmState = pendingVmState !== VmState.None ? pendingVmState : currentVmState;
    const vmAssignmentType = !name ? VmAssignmentType.Unassigned : VmAssignmentType.Assigned;
    const isExpanding = gettingEnvironments.contains(env.id);
    const userForEnvironment = vmAssignmentType === VmAssignmentType.Assigned
        ? users.find((user) => !!user.name &&
            !!env.claimedByUserPrincipalId &&
            user.name.toLowerCase() === env.claimedByUserPrincipalId.toLowerCase())
        : undefined;
    const currentUsage = !!userForEnvironment && !!userForEnvironment.totalUsage ? userForEnvironment.totalUsage.asHours() : 0;
    let canStart = false;
    let canStop = false;
    let canReset = false;
    let canConnect = false;
    switch (vmState) {
        case VmState.Starting:
        case VmState.Stopping:
            canReset = true;
            break;
        case VmState.Running:
            canStop = true;
            canReset = true;
            canConnect = true;
            break;
        case VmState.Stopped:
            canStart = true;
            canReset = true;
            break;
        default:
            break;
    }
    // TODO: Remove the extra isWindows and isLinuxEnabled checks once connection info is cached
    const { rdpAuthority, rdpInBrowserUrl, sshAuthority, sshInBrowserUrl } = networkInterface || {};
    const isWindows = template ? template.resourceSettings.osType === Ml.OsType.Windows : undefined;
    const isLinuxRdpEnabled = template
        ? template.resourceSettings.linuxRdpEnabled === Ml.EnableState.Enabled
        : undefined;
    const connectionTypes = getConnectionTypes(rdpAuthority, rdpInBrowserUrl, sshAuthority, sshInBrowserUrl, isWindows, isLinuxRdpEnabled);
    const privateIpAddress = env.networkInterface && env.networkInterface.privateIpAddress;
    const details = {
        key: id,
        id,
        vmState,
        vmAssignmentType,
        assignedTo: name || '',
        currentUsage,
        canStart,
        canStop,
        canReset,
        canConnect,
        isExpanding,
        sshAuthority,
        sshInBrowserUrl,
        rdpAuthority,
        rdpInBrowserUrl,
        connectionTypes,
        username: networkInterface ? networkInterface.username : undefined,
        privateIpAddress,
        errorCode: networkInterface ? networkInterface.errorCode : undefined,
    };
    return details;
}
export const convertVmDetails = memoize((environments, gettingEnvironments, users, template) => {
    return environments.map((env) => getVmDetails(env, gettingEnvironments, users, template));
});
export function convertVNextVirtualMachineStateToVmState(virutalMachineState, provisioningState) {
    switch (virutalMachineState) {
        case LabServicesModels.VirtualMachineState.ResettingPassword:
            return VmState.ResettingPassword;
        case LabServicesModels.VirtualMachineState.Running:
            return VmState.Running;
        case LabServicesModels.VirtualMachineState.Starting:
            return VmState.Starting;
        case LabServicesModels.VirtualMachineState.Stopping:
            return VmState.Stopping;
        case LabServicesModels.VirtualMachineState.Reimaging:
            return VmState.Reimaging;
        case LabServicesModels.VirtualMachineState.Redeploying:
            return VmState.Redeploying;
        default: {
            switch (provisioningState) {
                case LabServicesModels.ProvisioningState.Creating:
                    return VmState.Creating;
                case LabServicesModels.ProvisioningState.Deleting:
                    return VmState.Deleting;
                case LabServicesModels.ProvisioningState.Failed:
                    return VmState.Failed;
                default:
                    return !virutalMachineState ? VmState.Unknown : VmState.Stopped;
            }
        }
    }
}
export function getVNextVmDetails(virtualMachine, gettingVirtualMachines, users) {
    const { id, state: currentVmState, pendingVmState, connectionProfile, claimedByUserId, provisioningState, isUpdatePending, resourceOperationError, } = virtualMachine;
    const vmProvisioningState = isUpdatePending ? LabServicesModels.ProvisioningState.Updating : provisioningState;
    const vmState = pendingVmState !== VmState.None
        ? pendingVmState
        : convertVNextVirtualMachineStateToVmState(currentVmState, provisioningState);
    const vmAssignmentType = !claimedByUserId ? VmAssignmentType.Unassigned : VmAssignmentType.Assigned;
    const isExpanding = gettingVirtualMachines.contains(virtualMachine.id);
    const user = vmAssignmentType === VmAssignmentType.Assigned
        ? users.find((o) => !!o.id && caseInsensitiveCultureInvariantCompare(claimedByUserId, o.id) === 0)
        : undefined;
    const currentUsage = !!user && !!user.totalUsage ? moment.duration(user.totalUsage).asHours() : 0;
    let canStart = false;
    let canStop = false;
    let canReset = false;
    let canConnect = false;
    let canRedeploy = false;
    switch (vmProvisioningState) {
        case LabServicesModels.ProvisioningState.Updating:
            break;
        default:
            switch (vmState) {
                case VmState.Running:
                    canStop = true;
                    canReset = true;
                    canConnect = true;
                    canRedeploy = true;
                    break;
                case VmState.Stopped:
                    canStart = true;
                    canReset = true;
                    canRedeploy = true;
                    break;
                default:
                    break;
            }
    }
    const { rdpAuthority, rdpInBrowserUrl, sshAuthority, sshInBrowserUrl } = connectionProfile || {};
    const connectionTypes = getVNextConnectionTypes(rdpAuthority, rdpInBrowserUrl, sshAuthority, sshInBrowserUrl);
    const privateIpAddress = connectionProfile?.privateIpAddress ?? '--';
    const errorCode = resourceOperationError?.code ?? ' ';
    const details = {
        key: id,
        id: id,
        vmState,
        vmAssignmentType,
        assignedTo: user?.displayName || '',
        currentUsage,
        canStart,
        canStop,
        canReset,
        canConnect,
        canRedeploy,
        isExpanding,
        sshAuthority,
        sshInBrowserUrl,
        rdpAuthority,
        rdpInBrowserUrl,
        connectionTypes,
        username: connectionProfile?.adminUsername,
        privateIpAddress,
        errorCode,
        resourceOperationError,
    };
    return details;
}
export const convertVNextVmDetails = memoize((virtualMachines, gettingVirtualMachines, users) => {
    return virtualMachines.map((vm) => getVNextVmDetails(vm, gettingVirtualMachines, users));
});
export const getVmListViewModel = memoize((state) => {
    const isVNext = !isCurrentLabParentLabAccount(state);
    const currentTenantId = getTenantId(state);
    const ianaTimezone = getIanaTimezone(state);
    const locale = getLocale(state);
    const aadGroupName = getGroupName(state);
    const isBastionEnabled = isBastionFeatureEnabled(state);
    const isTeamsOrLmsIntegrationEnabled = isTeamsOrLmsMode(state);
    const currentLab = getCurrentLab(state);
    const isGroupSyncModeEnabled = isLabGroupSynced(currentLab);
    const isReadOnly = isCurrentLabReadOnly(state);
    const isVmActionsEnabled = isVNext ? isLabAssistantRole(currentLab) : false;
    if (!isVNext) {
        const labStore = state.get('labStore');
        const templateStore = state.get('templateStore');
        const envStore = state.get('environmentStore');
        const userStore = state.get('userStore');
        const pricingAndAvailabilityStore = state.get('pricingAndAvailabilityStore');
        const coreUsageStore = state.get('coreLimitStore');
        const labStoreState = labStore.get('loadState');
        const templateStoreState = templateStore.get('loadState');
        const coreQuotaLoadState = coreUsageStore.get('loadState');
        const environmentsLoadState = envStore.get('loadState');
        const userStoreLoadState = userStore.get('loadState');
        const isLoading = storeIsLoading(labStoreState) ||
            storeIsLoading(templateStoreState) ||
            storeIsLoading(environmentsLoadState) ||
            storeIsLoading(userStoreLoadState) ||
            storeIsLoading(coreQuotaLoadState) ||
            isLoadingGroupName(state);
        const templates = getTemplates(state);
        const hasLoadError = storeHasLoadError(labStoreState) ||
            storeHasLoadError(templateStoreState) ||
            (!storeIsLoading(templateStoreState) && templates.size < 1) ||
            storeHasLoadError(environmentsLoadState) ||
            storeHasLoadError(userStoreLoadState);
        const coreQuotaData = getCoreLimits(state);
        const coreQuotaStatus = determineVCurrentQuotaStatus(coreQuotaData);
        const labUpdateError = labStore.get('updateError');
        const labCapacityExceedsCoresError = labStore.get('labCapacityExceedsCoresError');
        const isLabUpdating = labStore.get('isUpdating');
        const template = getCurrentTemplate(state);
        const sizeData = pricingAndAvailabilityStore.get('labData');
        const environments = envStore.get('environments');
        const startErrors = envStore.get('startErrors');
        const stopErrors = envStore.get('stopErrors');
        const resetErrors = envStore.get('resetErrors');
        const gettingEnvironments = envStore.get('gettingEnvironments');
        const users = userStore.get('users');
        const vmDetails = convertVmDetails(environments, gettingEnvironments, users, template);
        const lab = currentLab;
        const isGpu = getIsGpu(sizeData);
        const coresPerVm = getCores(sizeData);
        const capacity = lab && lab.maxUsersInLab ? lab.maxUsersInLab : 0;
        const maxCapacity = lab?.userQuota ?? DefaultMaxCapacity;
        const labTitle = template ? template.title : undefined;
        const isWindows = template ? template.resourceSettings.osType === Ml.OsType.Windows : false;
        const useSharedPassword = lab ? lab.sharedPasswordEnabled === Ml.EnableState.Enabled : false;
        const hasBeenPublished = template ? template.publishingState !== Ml.PublishingState.Draft : false;
        const isLabTeamsOrLmsConnected = isLabConnectedLmsOrTeams(lab);
        return {
            vmDetails,
            coreQuotaData,
            lab,
            vmParentId: template?.id,
            isGroupSyncModeEnabled,
            isGpu,
            coresPerVm,
            isReadOnly,
            groupName: aadGroupName,
            currentTenantId,
            capacity,
            maxCapacity,
            labTitle,
            isWindows,
            useSharedPassword,
            hasBeenPublished,
            isLabUpdating,
            startErrors,
            stopErrors,
            resetErrors,
            labUpdateError,
            labCapacityExceedsCoresError,
            isBastionEnabled,
            isTeamsOrLmsIntegrationEnabled,
            isLoading,
            hasLoadError,
            ianaTimezone,
            locale,
            coreQuotaStatus,
            isLabTeamsOrLmsConnected,
            isVmActionsEnabled,
        };
    }
    else {
        const labStore = state.get('vNextLabStore');
        const virtualMachineStore = state.get('vNextVirtualMachineStore');
        const userStore = state.get('vNextUserStore');
        const labStoreState = labStore.get('loadState');
        const virtualMachinesLoadState = virtualMachineStore.get('loadState');
        const operations = virtualMachineStore.get('operations');
        const userStoreLoadState = userStore.get('loadState');
        const usageLoadState = getVNextUsageLoadState(state);
        const skuLoadState = getVNextSkuLoadState(state);
        const lab = currentLab;
        const isLoading = storeIsLoading(labStoreState) ||
            storeIsLoading(virtualMachinesLoadState) ||
            storeIsLoading(userStoreLoadState) ||
            isLoadingGroupName(state) ||
            isLoadingLabMetadata(state) ||
            storeIsLoading(usageLoadState) ||
            storeIsLoading(skuLoadState);
        const hasLoadError = storeHasLoadError(labStoreState) ||
            storeHasLoadError(virtualMachinesLoadState) ||
            storeHasLoadError(userStoreLoadState);
        const coreUsageData = getCurrentLabCoreUsageInfo(state);
        const labUpdateError = getCurrentLabUpdateError(state);
        const labCapacityExceedsCoresError = labStore.get('labCapacityExceedsCoresError');
        const isLabUpdating = labStore.get('isUpdating');
        const labTitle = lab?.title;
        const isWindows = lab?.virtualMachineProfile?.osType === LabServicesModels.OsType.Windows;
        const useSharedPassword = lab?.virtualMachineProfile?.useSharedPassword === LabServicesModels.EnableState.Enabled;
        const hasBeenPublished = lab?.state === LabServicesModels.LabState.Published;
        const virtualMachines = getUserVMs(state);
        const startErrors = getUserVMStartErrors(state);
        const stopErrors = getUserVMStopErrors(state);
        const resetErrors = virtualMachineStore.get('resetErrors');
        const labSku = getCurrentLabSkuInfo(state);
        const isGpu = labSku?.isGpu ?? false;
        const coresPerVm = labSku?.cores;
        const capacity = !lab?.virtualMachineProfile?.sku?.capacity ? 0 : lab.virtualMachineProfile.sku.capacity;
        const maxCapacity = DefaultMaxCapacity;
        const gettingVirtualMachines = virtualMachineStore.get('gettingVirtualMachines');
        const users = userStore.get('users');
        const vmDetails = convertVNextVmDetails(virtualMachines, gettingVirtualMachines, users);
        const shouldDisableLabUpdate = shouldDisableCurrentLabUpdate(state);
        const redeployErrors = getUserVMRedeployErrors(state);
        const groupName = getVNextLabGroupName(state);
        const isLabTeamsOrLmsConnected = isLabConnectedLmsOrTeams(lab);
        const isDraft = !!lab && isVNextLabDraft(lab);
        return {
            vmDetails,
            vmParentId: lab?.id,
            coreQuotaData: coreUsageData,
            lab,
            isGroupSyncModeEnabled,
            isGpu,
            coresPerVm,
            isReadOnly,
            groupName,
            currentTenantId,
            capacity,
            maxCapacity,
            labTitle,
            isWindows,
            useSharedPassword,
            hasBeenPublished,
            isLabUpdating,
            startErrors,
            stopErrors,
            resetErrors,
            labUpdateError,
            labCapacityExceedsCoresError,
            isBastionEnabled,
            isTeamsOrLmsIntegrationEnabled,
            isLoading,
            hasLoadError,
            ianaTimezone,
            locale,
            shouldDisableLabUpdate,
            redeployErrors,
            isLabTeamsOrLmsConnected,
            isLabDraft: isDraft,
            isVmActionsEnabled,
            operations: operations,
        };
    }
});
export const getVmListContainerModel = memoize((state) => {
    const search = getSearch(state);
    const vmListViewModel = getVmListViewModel(state);
    const isVNext = !isCurrentLabParentLabAccount(state);
    return {
        vmListViewModel,
        search,
        isVNext,
    };
});
