import enUS from '../l10n/resources.json';
import csCZ from '@generated/l10n/resources.cs.json';
import deDE from '@generated/l10n/resources.de.json';
import esES from '@generated/l10n/resources.es.json';
import frFR from '@generated/l10n/resources.fr.json';
import huHU from '@generated/l10n/resources.hu.json';
import idID from '@generated/l10n/resources.id.json';
import itIT from '@generated/l10n/resources.it.json';
import jaJP from '@generated/l10n/resources.ja.json';
import koKR from '@generated/l10n/resources.ko.json';
import nlNL from '@generated/l10n/resources.nl.json';
import plPL from '@generated/l10n/resources.pl.json';
import ptBR from '@generated/l10n/resources.pt-BR.json';
import ptPT from '@generated/l10n/resources.pt-PT.json';
import ruRU from '@generated/l10n/resources.ru.json';
import svSE from '@generated/l10n/resources.sv.json';
import trTR from '@generated/l10n/resources.tr.json';
import zhCN from '@generated/l10n/resources.zh-Hans.json';
import zhTW from '@generated/l10n/resources.zh-Hant.json';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-relativetimeformat/dist/locale-data/cs';
import '@formatjs/intl-pluralrules/dist/locale-data/cs';
import '@formatjs/intl-relativetimeformat/dist/locale-data/de';
import '@formatjs/intl-pluralrules/dist/locale-data/de';
import '@formatjs/intl-relativetimeformat/dist/locale-data/en';
import '@formatjs/intl-pluralrules/dist/locale-data/en';
import '@formatjs/intl-relativetimeformat/dist/locale-data/es';
import '@formatjs/intl-pluralrules/dist/locale-data/es';
import '@formatjs/intl-relativetimeformat/dist/locale-data/fr';
import '@formatjs/intl-pluralrules/dist/locale-data/fr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/hu';
import '@formatjs/intl-pluralrules/dist/locale-data/hu';
import '@formatjs/intl-relativetimeformat/dist/locale-data/id';
import '@formatjs/intl-pluralrules/dist/locale-data/id';
import '@formatjs/intl-relativetimeformat/dist/locale-data/it';
import '@formatjs/intl-pluralrules/dist/locale-data/it';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ja';
import '@formatjs/intl-pluralrules/dist/locale-data/ja';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ko';
import '@formatjs/intl-pluralrules/dist/locale-data/ko';
import '@formatjs/intl-relativetimeformat/dist/locale-data/nl';
import '@formatjs/intl-pluralrules/dist/locale-data/nl';
import '@formatjs/intl-relativetimeformat/dist/locale-data/pl';
import '@formatjs/intl-pluralrules/dist/locale-data/pl';
import '@formatjs/intl-relativetimeformat/dist/locale-data/pt';
import '@formatjs/intl-pluralrules/dist/locale-data/pt';
import '@formatjs/intl-relativetimeformat/dist/locale-data/ru';
import '@formatjs/intl-pluralrules/dist/locale-data/ru';
import '@formatjs/intl-relativetimeformat/dist/locale-data/sv';
import '@formatjs/intl-pluralrules/dist/locale-data/sv';
import '@formatjs/intl-relativetimeformat/dist/locale-data/tr';
import '@formatjs/intl-pluralrules/dist/locale-data/tr';
import '@formatjs/intl-relativetimeformat/dist/locale-data/zh';
import '@formatjs/intl-pluralrules/dist/locale-data/zh';
export const defaultMarket = 'US';
export const defaultLanguage = 'en';
export const defaultLocale = `${defaultLanguage}-${defaultMarket}`;
export const defaultMessages = enUS;
const localizedMessages = {};
let isInitialized = false;
export function initLocaleData() {
    if (isInitialized) {
        return localizedMessages;
    }
    localizedMessages['cs'] = csCZ;
    localizedMessages['cs-CZ'] = csCZ;
    localizedMessages['de'] = deDE;
    localizedMessages['de-DE'] = deDE;
    localizedMessages[defaultLanguage] = enUS;
    localizedMessages[defaultLocale] = enUS;
    localizedMessages['es'] = esES;
    localizedMessages['es-ES'] = esES;
    localizedMessages['fr'] = frFR;
    localizedMessages['fr-FR'] = frFR;
    localizedMessages['hu'] = huHU;
    localizedMessages['hu-HU'] = huHU;
    localizedMessages['id'] = idID;
    localizedMessages['id-ID'] = idID;
    localizedMessages['it'] = itIT;
    localizedMessages['it-IT'] = itIT;
    localizedMessages['ja'] = jaJP;
    localizedMessages['ja-JP'] = jaJP;
    localizedMessages['ko'] = koKR;
    localizedMessages['ko-KR'] = koKR;
    localizedMessages['nl'] = nlNL;
    localizedMessages['nl-NL'] = nlNL;
    localizedMessages['pl'] = plPL;
    localizedMessages['pl-PL'] = plPL;
    localizedMessages['pt'] = ptPT;
    localizedMessages['pt-PT'] = ptPT;
    localizedMessages['pt-BR'] = ptBR;
    localizedMessages['ru'] = ruRU;
    localizedMessages['ru-RU'] = ruRU;
    localizedMessages['sv'] = svSE;
    localizedMessages['sv-SE'] = svSE;
    localizedMessages['tr'] = trTR;
    localizedMessages['tr-TR'] = trTR;
    localizedMessages['zh'] = zhCN; // using zh-CN for all Chinese (simplified) locales and the default (no market)
    localizedMessages['zh-CN'] = zhCN;
    localizedMessages['zh-SG'] = zhCN;
    localizedMessages['zh-TW'] = zhTW; // using zh-TW for all Chinese (traditional) locales
    localizedMessages['zh-HK'] = zhTW;
    localizedMessages['zh-MO'] = zhTW;
    // only include pseudo-loc for development
    if (process.env.NODE_ENV === 'development') {
        // Disables annoying errors when developing from react-intl
        const consoleError = console.error.bind(console);
        console.error = (message, ...args) => {
            if (typeof message === 'string' &&
                (message.indexOf('React Intl') > -1 || message.indexOf('require("history")') > -1)) {
                return;
            }
            if (message && message.message && message.message.indexOf('React Intl') > -1) {
                return;
            }
            consoleError(message, ...args);
        };
        // Disables annoying warnings when when developing from react-intl
        const consoleWarn = console.warn.bind(console);
        console.warn = (message, ...args) => {
            if (typeof message === 'string' && message.indexOf('The icon') > -1) {
                return;
            }
            consoleWarn(message, ...args);
        };
        try {
            // remove the default language so we can ignore the JSON file when in
            // development
            localizedMessages[defaultLanguage] = {};
            localizedMessages[defaultLocale] = {};
            const qpsPloc = require('../l10n/resources.qps-ploc.json'); // eslint-disable-line @typescript-eslint/no-var-requires
            localizedMessages['qps'] = qpsPloc;
            localizedMessages['qps-ploc'] = qpsPloc;
        }
        catch (e) {
            // these does not need to go to AI, it is purely for development
            console.warn('The pseudo loc file was not found. Please run "npm run build" from /ClientApp to create it.');
        }
    }
    isInitialized = true;
    return localizedMessages;
}
export function getAcceptLanguage(locale, lang) {
    const language = lang ? lang : getLocaleLanguage(locale);
    const acceptLanguage = `${locale}, ${language};q=0.5`;
    return acceptLanguage;
}
export function resolveSearchLocale(queryParams) {
    const locale = queryParams.locale || queryParams.lang || navigator.language;
    return locale;
}
export function getLocaleData(requestedLocale) {
    if (!isInitialized) {
        initLocaleData();
    }
    const market = getLocaleMarket(requestedLocale);
    let language = getLocaleLanguage(requestedLocale);
    const supportedLocales = Object.keys(localizedMessages);
    const isLanguageSupported = supportedLocales.some((lang) => lang === language);
    if (!isLanguageSupported) {
        language = defaultLanguage;
    }
    // NOTE: This logic doesn't support resolving locales like ca-ES-valencia, but
    // we don't support anything like that at this time
    const locale = `${language}-${market}`;
    const messages = localizedMessages[locale] || localizedMessages[language] || defaultMessages;
    return { locale, market, language, messages };
}
function getLocaleMarket(locale) {
    if (!locale) {
        return defaultMarket;
    }
    // special case specifically for zh and zh-ZH which are not supported in momentjs
    if (locale === 'zh' || locale.toLowerCase() === 'zh-zh') {
        return 'CN';
    }
    // logic to handle locales such as "ca-ES-valencia"
    const tokens = locale.trim().split('-');
    for (let i = tokens.length - 1; i > -1; --i) {
        const token = tokens[i];
        if (token.length === 2) {
            return token.toUpperCase();
        }
    }
    return defaultMarket;
}
function getLocaleLanguage(locale) {
    if (!locale) {
        return defaultLanguage;
    }
    // Gets only the first token of the locale. Example: if the parameter is 'en-US' this would return 'en'
    const tokens = locale.trim().split('-');
    if (tokens.length > 0) {
        return tokens[0].toLowerCase();
    }
    return defaultLanguage;
}
const languages = {
    initLocaleData,
    getLocaleData,
};
export default languages;
