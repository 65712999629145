import { OperationStatus } from '../../../data/models/vnext/operation';
import memoize from 'memoize-one';
export const getOperations = memoize((store) => {
    return store.get('vNextVirtualMachineStore').get('operations');
});
export const getOperationsInProgress = memoize((store) => {
    let operations = store.get('vNextVirtualMachineStore').get('operations');
    let filteredOperations = operations.filter((op) => op.status === OperationStatus.InProgress);
    return filteredOperations.toArray();
});
export const getUserOperationsInProgress = memoize((store) => {
    let operations = store.get('vNextUserVirtualMachineStore').get('operations');
    let filteredOperations = operations?.filter((op) => op.status === OperationStatus.InProgress);
    return filteredOperations ? filteredOperations.toArray() : [];
});
export const getLabOperations = memoize((store) => {
    return store.get('vNextLabStore').get('labPublishOperations');
});
