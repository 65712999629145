import * as React from 'react';
import { connect } from 'react-redux';
import { LoadingContainer } from '../common/loading-section';
import { ltiStartOidc } from '../redux/actions/lti/lti-action-creators';
const LtiStartOidcContainerInjected = (props) => {
    const { ltiStartOidc } = props;
    React.useEffect(() => {
        ltiStartOidc();
    }, []);
    return <LoadingContainer styles={{ root: { backgroundColor: 'inherit' } }}/>;
};
const mapDispatchToProps = {
    ltiStartOidc,
};
export const LtiStartOidcContainer = connect(null, mapDispatchToProps)(LtiStartOidcContainerInjected);
export default LtiStartOidcContainer;
