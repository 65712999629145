import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { DefaultButton, Icon } from '@fluentui/react';
import _ from 'lodash';
import { commonMessages } from '../../language/common-messages';
import './home-button.css';
export const HomeButton = (props) => {
    const { navigateHome, styles, isLmsIntegrationEnabled } = props;
    const azureSpan = (<span className="home-button__logo-brand ms-fontWeight-semibold">
            <FormattedMessage {...commonMessages.azure}/>
        </span>);
    const defaultStyles = {
        root: {
            padding: '0 15px 0 15px',
            margin: 0,
            height: '100%',
        },
        flexContainer: {
            flex: '1 1 0',
            padding: 0,
            margin: 0,
            justifyContent: 'flex-start',
        },
    };
    const buttonStyles = _.merge({}, defaultStyles, styles || {});
    return (<DefaultButton onClick={navigateHome} styles={buttonStyles}>
            {!isLmsIntegrationEnabled && <Icon iconName="logo" className="home-button__logo-icon"/>}
            {isLmsIntegrationEnabled && <Icon iconName="lms-logo" className="home-button__logo-icon"/>}
            <span className="ms-fontWeight-semibold">
                <FormattedMessage id="HeaderFormattedAzureLabServicesLabel" defaultMessage="{azureSpan} Lab Services" description="Used to format the product name, Azure Lab Services, with special formatting around the word Azure. The parameter {azureSpan} will contain this formatted version of the word Azure." values={{ azureSpan }}/>
            </span>
        </DefaultButton>);
};
