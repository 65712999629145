import * as React from 'react';
import { connect } from 'react-redux';
import { signIn } from '../redux/actions/identity/identity-action-creators';
import { LandingPage } from './landing-page';
import { Page } from '../common/page/page';
import { isLmsHost } from '../redux/selectors/common-selectors';
import { initialize } from '../redux/actions/common/common-action-creators';
class LandingPageContainerInjected extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { signIn, initialize, shouldLoginWithPopupWindow } = this.props;
        return (<Page headerProps={{ hideLabParentPicker: true, hideLabPicker: true }} isScrollable={true} showFooter={true}>
                
                <LandingPage signIn={shouldLoginWithPopupWindow ? () => initialize(true) : () => signIn(true)}/>
            </Page>);
    }
}
const mapDispatchToProps = {
    signIn,
    initialize,
};
const mapStateToProps = (state) => {
    const shouldLoginWithPopupWindow = isLmsHost(state);
    return {
        shouldLoginWithPopupWindow,
    };
};
export const LandingPageContainer = connect(mapStateToProps, mapDispatchToProps)(LandingPageContainerInjected);
export default LandingPageContainer;
