import { race, put, take, takeLatest } from 'redux-saga/effects';
import { loadSubscriptions } from '../../actions/subscription/subscription-action-creators';
import { SubscriptionActionType } from '../../actions/subscription/subscription-actions';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { LabParentResourceActionType, } from '../../actions/lab-parent-resource/lab-parent-resource-actions';
import { listLabParentResources, refreshSubscriptionsAndLabParentResourcesError, refreshSubscriptionsAndLabParentResourcesSuccess, } from '../../actions/lab-parent-resource/lab-parent-resource-action-creators';
export function* refreshSubscriptionsAndLabParentResources(action) {
    try {
        yield put(loadSubscriptions());
        const { error: loadSubscriptionErrorAction } = yield race({
            success: take(SubscriptionActionType.LOAD_SUBSCRIPTIONS_SUCCESS),
            error: take(SubscriptionActionType.LOAD_SUBSCRIPTIONS_ERROR),
        });
        if (loadSubscriptionErrorAction) {
            const { error } = loadSubscriptionErrorAction;
            yield put(refreshSubscriptionsAndLabParentResourcesError(error));
        }
        else {
            yield put(listLabParentResources(false, false));
            const { error: listLabParentResourcesErrorAction } = yield race({
                success: take(LabParentResourceActionType.LIST_LAB_PARENT_RESOURCES_SUCCESS),
                error: take(LabParentResourceActionType.LIST_LAB_PARENT_RESOURCES_ERROR),
            });
            if (listLabParentResourcesErrorAction) {
                const { error } = listLabParentResourcesErrorAction;
                yield put(refreshSubscriptionsAndLabParentResourcesError(error));
            }
            else {
                yield put(refreshSubscriptionsAndLabParentResourcesSuccess());
            }
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.RefreshSubscriptionsAndLabParentResources, err ? err.code : undefined);
        yield put(refreshSubscriptionsAndLabParentResourcesError(error));
    }
}
export function* refreshSubscriptionsAndLabParentResourcesSaga() {
    yield takeLatest(LabParentResourceActionType.REFRESH_SUBSCRIPTIONS_AND_LAB_PARENT_RESOURCES, refreshSubscriptionsAndLabParentResources);
}
