import { call, put, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { ResourceId } from '../../../utils/resource-id';
import { SharedImageActionType } from '../../actions/shared-image/shared-image-actions';
import { updateSharedImageSuccess, updateSharedImageError, } from '../../actions/shared-image/shared-image-action-creators';
import SharedImageProvider from '../../../data/providers/shared-image-provider';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import LabPlanProvider from '../../../data/providers/vnext/arm-api/lab-plan-provider';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { getCurrentLab } from '../../selectors/lab-selectors';
export function* updateSharedImage(action) {
    const { sharedImageId, templateId } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(SharedImageProvider.updateSharedImage, sharedImageId, templateId, accessToken, locale, language);
        yield put(updateSharedImageSuccess());
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, sharedImageId, FailureOperation.SaveSharedImage, e ? e.code : undefined);
        yield put(updateSharedImageError(templateId, error));
    }
}
export function* updateVNextSharedImage(action) {
    const { templateId, sharedImageId } = action;
    try {
        const currentLab = yield select(getCurrentLab);
        // we disable 'Export to Azure Compute Gallery' when the lab is not created by a lab plan with sharedGalleryId
        const labPlanResourceId = new ResourceId(currentLab.labPlanId, true);
        const sharedImageResourceId = new ResourceId(sharedImageId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(LabPlanProvider.saveImage, labPlanResourceId, accessToken, locale, language, sharedImageResourceId.name, templateId);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, templateId, FailureOperation.SaveSharedImage, e ? e.code : undefined);
        yield put(updateSharedImageError(templateId, error));
    }
}
export function* updateSharedImageSaga() {
    yield labParentBasedTakeLatest(SharedImageActionType.UPDATE_SHARED_IMAGE, updateSharedImage, updateVNextSharedImage);
}
