import _ from 'lodash';
import ip from 'ip';
import { caseInsensitiveLocaleCompare } from './string-comparison';
export function objectKeySorter(key, isSortDescending, stateToLabelMap) {
    const objectComparer = (a, b) => {
        const aValue = a[key];
        const bValue = b[key];
        // HACK: Need a better way to specify to do IP address sorting
        if (key === 'privateIpAddress') {
            if (!aValue && !bValue) {
                return 0;
            }
            else if (!aValue) {
                return 1;
            }
            else if (!bValue) {
                return -1;
            }
            const aLong = ip.toLong(aValue);
            const bLong = ip.toLong(bValue);
            const numberComparer = numberSorter(isSortDescending);
            return numberComparer(aLong, bLong);
        }
        else if (_.isString(aValue) || _.isString(bValue)) {
            const stringComparer = stringSorter(isSortDescending, stateToLabelMap);
            return stringComparer(aValue, bValue);
        }
        else if (_.isNumber(aValue) || _.isNumber(bValue)) {
            const numberComparer = numberSorter(isSortDescending);
            return numberComparer(aValue, bValue);
        }
        else {
            if (aValue < bValue) {
                return isSortDescending ? 1 : -1;
            }
            else if (aValue < bValue) {
                return isSortDescending ? -1 : 1;
            }
            return 0;
        }
    };
    return objectComparer;
}
export const stringSorter = (isSortDescending, stateToLabelMap) => {
    const stringComparer = (a, b) => {
        if (!a && !b) {
            return 0;
        }
        else if (!a) {
            return 1;
        }
        else if (!b) {
            return -1;
        }
        if (stateToLabelMap) {
            a = stateToLabelMap[a];
            b = stateToLabelMap[b];
        }
        if (caseInsensitiveLocaleCompare(a, b) < 0) {
            return isSortDescending ? 1 : -1;
        }
        else if (caseInsensitiveLocaleCompare(a, b) > 0) {
            return isSortDescending ? -1 : 1;
        }
        else {
            return 0;
        }
    };
    return stringComparer;
};
export const numberSorter = (isSortDescending) => {
    const numberComparer = (a, b) => {
        if (a < b) {
            return isSortDescending ? 1 : -1;
        }
        else if (a > b) {
            return isSortDescending ? -1 : 1;
        }
        return 0;
    };
    return numberComparer;
};
export function objectMultiKeySorter(keys, isSortDescending, stateToLabelMaps) {
    const objectMultiKeyComparer = (a, b) => {
        for (let index = 0; index < keys.length; index++) {
            const key = keys[index];
            if (a[key] !== b[key] || index == keys.length - 1) {
                const stateToLabelMap = stateToLabelMaps ? stateToLabelMaps[index] : undefined;
                const objectKeyComparer = objectKeySorter(key, isSortDescending, stateToLabelMap);
                return objectKeyComparer(a, b);
            }
        }
        return 0;
    };
    return objectMultiKeyComparer;
}
