import { DirectionalHint, Icon, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import PageHeaderDropdown from './dropdown/page-header-dropdown';
import { NeutralColors } from '@fluentui/theme';
const messages = defineMessages({
    ariaLabel: {
        id: 'TenantPickerAriaLabel',
        defaultMessage: 'Select a tenant',
        description: 'Aria label for the tenant picker control',
    },
});
export const Selected = (props) => {
    const { tenant } = props;
    return (<Stack horizontal={true} verticalAlign="center" horizontalAlign="start" grow styles={{ root: { marginRight: '5px' } }}>
            <Stack.Item>{tenant.displayName}</Stack.Item>
        </Stack>);
};
export const TenantPickerPlaceholder = () => {
    return (<Stack horizontal={true} verticalAlign="center" horizontalAlign="start" grow styles={{ root: { marginRight: '5px' } }}>
            <Stack.Item>
                <FormattedMessage id="TenantPickerPlaceholder" defaultMessage="Select a tenant" description="The placeholder message displayed in the tenant picker when no tenant is selected."/>
            </Stack.Item>
        </Stack>);
};
export const Item = (props) => {
    const { tenant, isSelected } = props;
    return (<Stack horizontal={true} verticalAlign="center" horizontalAlign="start" grow>
            {isSelected && (<Stack.Item>
                    <Icon iconName="Accept" styles={{ root: { paddingLeft: '8px' } }}/>
                </Stack.Item>)}
            <Stack styles={{ root: { paddingLeft: isSelected ? '8px' : '30px', paddingRight: '12px' } }}>
                <Stack.Item align="start">{tenant.displayName}</Stack.Item>
                <Stack.Item align="start" styles={{ root: { color: NeutralColors.gray130 } }}>
                    {tenant.defaultDomain}
                </Stack.Item>
            </Stack>
        </Stack>);
};
function convertTenantToOption(tenant) {
    return { value: tenant.tenantId };
}
function mapTenantsToOptions(tenants) {
    return tenants.map((a) => convertTenantToOption(a)).toJS();
}
function getTenantFromOption(option, tenants) {
    return tenants.find((a) => a.tenantId === option.value);
}
export const TenantPicker = (props) => {
    const { isLoading, tenants, selected, onChange, styles } = props;
    const intl = useIntl();
    const { formatMessage } = intl;
    const ariaLabel = formatMessage(messages.ariaLabel);
    const options = isLoading ? [] : mapTenantsToOptions(tenants);
    const selectedOption = selected ? convertTenantToOption(selected) : undefined;
    return (<PageHeaderDropdown items={options} styles={styles} ariaLabel={ariaLabel} selected={selectedOption} isLoading={isLoading} minExpandedWidth={'50px'} onChange={(value) => {
        onChange(getTenantFromOption(value, tenants));
    }} onRenderSelected={(selected) => (<Selected tenant={getTenantFromOption(selected, tenants)}/>)} onRenderItem={(item) => (<Item tenant={getTenantFromOption(item, tenants)} isSelected={item.value === selected?.tenantId}/>)} onRenderPlaceholder={() => <TenantPickerPlaceholder />} directionalHint={DirectionalHint.bottomRightEdge}/>);
};
export default TenantPicker;
