import { put, call, select, delay, race, take } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import { settingsUpdateDebounceInMs } from '../../../data/models/user-settings';
import UserSettingsProvider from '../../../data/providers/user-settings-provider';
import { default as VNextUserSettingsProvider } from '../../../data/providers/vnext/labs-api/user-settings-provider';
import { IdentityActionType } from '../../actions/identity/identity-actions';
import { updateUserSettingsSuccess, updateUserSettingsError, updateUserSettingsExists, updateUserSettingsCancelled, } from '../../actions/identity/identity-action-creators';
import { featureBasedTakeLatest } from '../../effects/feature-based';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { getUserSettings, getDisplayId, doesSettingsExist } from '../../selectors/identity-selector';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from './access-tokens';
import { CommonActionType } from '../../actions/common/common-actions';
export function* updateUserSettings(action) {
    try {
        // debounce so we can group updates together
        yield delay(settingsUpdateDebounceInMs);
        const accessToken = yield call(getArmAccessTokenSaga, false);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const displayId = yield select(getDisplayId);
        // the merge of the settings update with the existing settings happens in our reducer
        // which happens as soon as the action is dispatched and before this saga is started
        const settings = yield select(getUserSettings);
        yield call(UserSettingsProvider.updateUserSettings, settings.toJS(), displayId, accessToken, locale, language);
        yield put(updateUserSettingsSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.UpdateUserSettings, err ? err.code : undefined);
        yield put(updateUserSettingsError(error));
    }
}
export function* updateVNextUserSettings(action) {
    try {
        // debounce so we can group updates together
        const { changed } = yield race({
            delayed: delay(settingsUpdateDebounceInMs),
            changed: take(CommonActionType.SELECT_TENANT),
        });
        // when selecting tenant in canvas, we are signing in with popup window rather than redirecting. if not cancelling user settings updating,
        // it will try to get access token for the previously signed in tenant, which would requires login interactively again while we are signing
        // in to the newly selected tenant.
        if (changed) {
            yield put(updateUserSettingsCancelled());
        }
        else {
            const accessToken = yield call(getLabsApiAccessTokenSaga, false);
            const locale = yield select(getLocale);
            const language = yield select(getLanguage);
            const displayId = yield select(getDisplayId);
            // the merge of the settings update with the existing settings happens in our reducer
            // which happens as soon as the action is dispatched and before this saga is started
            const settingsExist = yield select(doesSettingsExist);
            if (settingsExist) {
                yield call(VNextUserSettingsProvider.updateUserSettings, action.settings, // TODO: figure out the type error
                displayId, accessToken, locale, language);
            }
            else {
                const settings = yield select(getUserSettings);
                yield call(VNextUserSettingsProvider.createUserSettings, settings.toJS(), displayId, accessToken, locale, language);
                yield put(updateUserSettingsExists());
            }
            yield put(updateUserSettingsSuccess());
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.UpdateUserSettings, err ? err.code : undefined);
        yield put(updateUserSettingsError(error));
    }
}
export function* updateUserSettingsSaga() {
    yield featureBasedTakeLatest(IdentityActionType.UPDATE_USER_SETTINGS, updateUserSettings, updateVNextUserSettings);
}
