import React from 'react';
import { FormattedMessage } from 'react-intl';
import commonMessages from '../language/common-messages';
import { caseInsensitiveLocaleCompare } from '../utils/string-comparison';
import { SkuConstants } from './selectors/sku-selectors';
export const LabSizeInfo = (props) => {
    const { cores, memorySize, storageSize, storageType } = props;
    return (<>
            {!!cores && !!memorySize && (<>
                    <FormattedMessage id="CreatingViewDetails" defaultMessage="{numberOfCores} cores | {memorySize}GB RAM " description="Virtual machine cores and memory specification. {numberOfCores} is number of VM cores. {memorySize} is memory size in GBs." values={{
        numberOfCores: cores,
        memorySize,
    }}/>
                    {!!storageSize && !!storageType && (<>
                            <FormattedMessage id="TemplateLabSize" defaultMessage="| {storageSize}GB " description="Virtual machine disk specification. {storageSize} is the number of GB of storage." values={{
        storageSize,
    }}/>
                            {caseInsensitiveLocaleCompare(storageType, SkuConstants.PremiumSSD) === 0 ? (<FormattedMessage {...commonMessages.premiumSSD}/>) : (<FormattedMessage {...commonMessages.standardSSD}/>)}
                        </>)}
                </>)}
        </>);
};
