import memoize from 'memoize-one';
import { getCurrentLabAccount } from '../lab-account-selectors';
import { getCurrentLab, isVNextLab } from '../lab-selectors';
import { getLabPlan, getLabPlans } from './lab-plan-selectors';
export const getCurrentSupportInfo = memoize((state) => {
    const currentLabAccount = getCurrentLabAccount(state);
    let currentSupportInfo = !!currentLabAccount ? currentLabAccount.supportInfo : undefined;
    const labPlans = getLabPlans(state);
    const currentLab = getCurrentLab(state);
    if (!!currentLab && isVNextLab(currentLab.id) && !!currentLab.labPlanId) {
        const labPlan = getLabPlan(labPlans, currentLab.labPlanId);
        currentSupportInfo = labPlan?.supportInfo;
    }
    return currentSupportInfo;
});
