import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { useIntl } from 'react-intl';
import { defineMessages } from 'react-intl';
const messages = defineMessages({
    syncInProgressSpinnerLabel: {
        id: 'UserListSyncInProgressSpinnerLabel',
        defaultMessage: 'Sync in progress from "{aadGroupName}"...',
        description: 'Message shown when sync is in progress',
    },
    syncInProgressSpinnerLabelNoGroupName: {
        id: 'UserListSyncInProgressSpinnerLabelNoGroupName',
        defaultMessage: 'Sync in progress from a group...',
        description: 'Message shown when sync is in progress',
    },
});
export const SyncingSpinner = (props) => {
    const { groupName, styles } = props;
    const intl = useIntl();
    return (<Spinner size={SpinnerSize.xSmall} styles={styles} label={!groupName
        ? intl.formatMessage(messages.syncInProgressSpinnerLabelNoGroupName)
        : intl.formatMessage(messages.syncInProgressSpinnerLabel, {
            aadGroupName: groupName,
        })} labelPosition="right"/>);
};
export default SyncingSpinner;
