import memoize from 'memoize-one';
import { getTenantId, getUserSettings } from '../redux/selectors/identity-selector';
import { getSupportInfoEmail } from '../redux/selectors/lab-account-selectors';
import { getFilteredLabs, getLabAccessType, hasBlockedLabs, hasOldV1Labs } from '../redux/selectors/lab-selectors';
import { getSearch } from '../redux/selectors/route-selector';
import { isFeatureEnabled, isLmsMode, isTeamsMode, storeIsLoading } from '../redux/selectors/common-selectors';
import Feature from '../utils/features';
import { getCurrentLabParentId, isCurrentLabParentLabAccount, shouldDisableNewLabCreation, } from '../redux/selectors/lab-parent-resource-selectors';
import { doesCurrentLabParentExist, isLoadingLabParents } from '../redux/selectors/lab-parent-resource-selectors';
import { isLoadingVNextLabs } from '../redux/selectors/vnext/lab-selectors';
import { determineVCurrentQuotaStatus, getCoreLimits } from '../redux/selectors/core-limit-selectors';
import { ResourceId, vNextLabOrLabPlanIdLength } from '../utils/resource-id';
import { caseInsensitiveCultureInvariantCompare } from '../utils/string-comparison';
import { getUpdateLmsErrors } from '../redux/selectors/vnext/lab-plan-selectors';
import { getVnextLabPermissionPropagating, getCreateLabPermissionOnLabPlan, } from '../redux/selectors/vnext/lab-selectors';
export function isLabInCurrentResourceGroup(id, currentLabParent) {
    const parts = id.split('/').filter((n) => n !== '');
    if (parts.length < vNextLabOrLabPlanIdLength) {
        return false;
    }
    const resourceId = new ResourceId(id, true);
    return caseInsensitiveCultureInvariantCompare(resourceId.getResourceGroupId(), currentLabParent) === 0;
}
export const getLabListViewModel = memoize((state) => {
    const isLoadingLabParentResources = isLoadingLabParents(state);
    const currentLabParentExist = doesCurrentLabParentExist(state);
    const isVNextLabs = !isCurrentLabParentLabAccount(state);
    const isVnextLabPermissionPropagating = getVnextLabPermissionPropagating(state);
    const isVnextAuthErrorPermissionPropagationRelated = getCreateLabPermissionOnLabPlan(state);
    const labs = getFilteredLabs(state);
    const currentLabParentId = getCurrentLabParentId(state);
    const isIntroCheckboxEnabled = isFeatureEnabled(state, Feature.LabListIntroCheckbox);
    const currentTenantId = getTenantId(state);
    const showBlockedLabsBanner = hasBlockedLabs(state);
    const isNewLabCreationDisabled = shouldDisableNewLabCreation(state);
    const settings = getUserSettings(state);
    const showOldGpuLabsGenericBanner = !isVNextLabs && !settings.get('hasDismissedOldV1GpuLabBanner') && hasOldV1Labs(state);
    const hintsEnabled = !settings.get('disableHints');
    const showIntro = !settings.get('dismissedLabListFirstRun');
    const showRetirementAlert = settings.get('showRetirementAlert');
    const showCovidBanner = !settings.get('hasDismissedCovidBanner');
    const isTeamsIntegrationEnabled = isTeamsMode(state);
    const isLmsIntegrationEnabled = isLmsMode(state);
    const updateLinkedLmsErrors = getUpdateLmsErrors(state);
    if (!isVNextLabs) {
        const labStore = state.get('labStore');
        const coreLimitStore = state.get('coreLimitStore');
        const labStoreLoadState = labStore.get('loadState');
        const isLoadingLabs = storeIsLoading(labStoreLoadState);
        const coreQuotaLoadState = coreLimitStore.get('loadState');
        const isLoadingCoreQuota = storeIsLoading(coreQuotaLoadState);
        const isInitialized = !isLoadingLabs &&
            !isLoadingCoreQuota &&
            (labs.count() > 0 || (!isLoadingLabParentResources && currentLabParentExist));
        const isLoading = !isInitialized;
        const isDeleting = labStore.get('isDeleting');
        const administratorEmailAddress = getSupportInfoEmail(state);
        const errors = labStore.get('errors');
        const coreQuotaData = getCoreLimits(state);
        const quotaStatus = determineVCurrentQuotaStatus(coreQuotaData);
        return {
            isLoading,
            labs,
            isDeleting,
            currentTenantId,
            currentLabParentId,
            administratorEmailAddress,
            quotaStatus,
            hintsEnabled,
            showIntro,
            showRetirementAlert,
            showCovidBanner,
            showBlockedLabsBanner,
            showOldGpuLabsGenericBanner,
            errors,
            isIntroCheckboxEnabled,
            isVNextLabs,
            isVnextLabPermissionPropagating,
            isVnextAuthErrorPermissionPropagationRelated,
            isNewLabCreationDisabled,
            isTeamsIntegrationEnabled,
            isLmsIntegrationEnabled,
            updateLinkedLmsErrors,
        };
    }
    else {
        const vNextLabStore = state.get('vNextLabStore');
        const vNextSkuStore = state.get('vNextSkuStore');
        const isLoadingLabs = isLoadingVNextLabs(state);
        const isDeleting = vNextLabStore.get('isDeleting');
        // we only show banners for errors of labs in the current resource group
        const errors = vNextLabStore
            .get('errors')
            .filter((value, key) => isLabInCurrentResourceGroup(key, currentLabParentId));
        const skuLoadState = vNextSkuStore.get('loadState');
        const isLoadingSku = storeIsLoading(skuLoadState);
        const isInitialized = !isLoadingLabs &&
            !isLoadingSku &&
            (labs.count() > 0 || (!isLoadingLabParentResources && currentLabParentExist));
        const isLoading = !isInitialized;
        return {
            isLoading,
            labs,
            isDeleting,
            currentTenantId,
            currentLabParentId,
            hintsEnabled,
            showIntro,
            showRetirementAlert,
            showCovidBanner,
            showBlockedLabsBanner,
            showOldGpuLabsGenericBanner,
            errors,
            isIntroCheckboxEnabled,
            isVNextLabs,
            isVnextLabPermissionPropagating,
            isVnextAuthErrorPermissionPropagationRelated,
            isNewLabCreationDisabled,
            isTeamsIntegrationEnabled,
            isLmsIntegrationEnabled,
            updateLinkedLmsErrors,
        };
    }
});
export const getLabListContainerModel = memoize((state) => {
    const labStore = state.get('labStore');
    const labStoreLoadState = labStore.get('loadState');
    const isLoadingLabs = storeIsLoading(labStoreLoadState);
    const isLoadingLabParentResources = isLoadingLabParents(state);
    const currentLabParentExist = doesCurrentLabParentExist(state);
    const search = getSearch(state);
    const labListViewModel = getLabListViewModel(state);
    const labAccessType = getLabAccessType(state);
    return {
        isLoadingLabs,
        isLoadingLabParentResources,
        search,
        currentLabParentExist,
        labListViewModel,
        labAccessType,
    };
});
