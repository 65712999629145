import { all, call, put, select } from 'redux-saga/effects';
import { MlClientError, FailureOperation } from '../../../data/ml-client-error';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { UserActionType } from '../../actions/user/user-actions';
import { getUsers } from '../../selectors/user-selectors';
import { duration } from 'moment';
import UserProvider from '../../../data/providers/user-provider';
import { updateAdditionalQuotaForUsersError, updateAdditionalQuotaForUsersSuccess, updateVNextAdditionalQuotaForUsersSuccess, } from '../../actions/user/user-action-creators';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import VNextUserProvider from '../../../data/providers/vnext/arm-api/user-provider';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { getVNextUsers } from '../../selectors/vnext/user-selectors';
async function callUpdateUser(user, accessToken, locale, language, errors, successfulUsers) {
    let updatedUser;
    try {
        updatedUser = await UserProvider.updateUser(user, accessToken, locale, language);
        successfulUsers.push(updatedUser);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, user.id, FailureOperation.UpdateAdditionalQuotaForUsers, e ? e.code : undefined);
        errors[user.id] = error;
    }
}
export function* updateAdditionalQuota(action) {
    const { userIds, additionalQuota } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        //get users to update
        const users = yield select(getUsers);
        const selectedUsersWithQuota = users
            .filter((user) => userIds.indexOf(user.id) > -1)
            .map((user) => {
            // override existing user quota
            const updatedUser = { ...user, additionalUsageQuota: duration(additionalQuota, 'hours') };
            return updatedUser;
        });
        const errors = {};
        const successfulUsers = [];
        yield all(selectedUsersWithQuota
            .map((user) => call(callUpdateUser, user, accessToken, locale, language, errors, successfulUsers))
            .toArray());
        if (Object.keys(errors).length > 0) {
            yield put(updateAdditionalQuotaForUsersError(errors));
        }
        if (successfulUsers.length > 0) {
            yield put(updateAdditionalQuotaForUsersSuccess(successfulUsers));
        }
    }
    catch (e) {
        const errors = {};
        const error = new MlClientError(e ? e.message : '', 'all', FailureOperation.UpdateAdditionalQuotaForUsers, e ? e.code : undefined);
        errors[error.id] = error;
        yield put(updateAdditionalQuotaForUsersError(errors));
    }
}
async function callUpdateVNextUser(user, additionalQuota, accessToken, locale, language, errors, successfulUsers) {
    try {
        const updatedUser = await VNextUserProvider.updateUser(user, accessToken, locale, language, additionalQuota);
        successfulUsers.push(updatedUser);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, user.id, FailureOperation.UpdateAdditionalQuotaForUsers, e ? e.code : undefined);
        errors[user.id] = error;
    }
}
export function* updateVNextAdditionalQuota(action) {
    const { userIds, additionalQuota } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const users = yield select(getVNextUsers);
        const selectedUsersWithQuota = users.filter((user) => userIds.indexOf(user.id) > -1);
        const additionalUsageQuota = duration(additionalQuota, 'hours').toISOString();
        const successfulUsers = [];
        const errors = {};
        yield all(selectedUsersWithQuota
            .map((user) => call(callUpdateVNextUser, user, additionalUsageQuota, accessToken, locale, language, errors, successfulUsers))
            .toArray());
        if (Object.keys(errors).length > 0) {
            yield put(updateAdditionalQuotaForUsersError(errors));
        }
        if (successfulUsers.length > 0) {
            yield put(updateVNextAdditionalQuotaForUsersSuccess(successfulUsers));
        }
    }
    catch (e) {
        const errors = {};
        const error = new MlClientError(e ? e.message : '', 'all', FailureOperation.UpdateAdditionalQuotaForUsers, e ? e.code : undefined);
        errors[error.id] = error;
        yield put(updateAdditionalQuotaForUsersError(errors));
    }
}
export function* updateAdditionalQuotaSaga() {
    yield labParentBasedTakeLatest(UserActionType.UPDATE_ADDITIONAL_QUOTA, updateAdditionalQuota, updateVNextAdditionalQuota);
}
