import { loadTheme } from '@fluentui/react';
import { call, put, takeEvery, takeLatest, all } from 'redux-saga/effects';
import { CanvasCustomTheme, DefaultCustomTheme, LmsBasicsCustomTheme, TeamsCustomTheme, } from '../../../common/themes/custom-themes';
import { loadCustomTheme } from '../../../common/themes/selectors';
import { CanvasTheme, DefaultTheme, TeamsTheme } from '../../../common/themes/themes';
import Feature from '../../../utils/features';
import { trackException } from '../../../utils/telemetry/telemetry-channel';
import { CommonActionType, } from '../../actions/common/common-actions';
import { setTheme as setThemeAction } from '../../actions/common/common-action-creators';
import { Theme } from '../../../utils/themes';
export function* loadTeamsOrDefaultTheme(action) {
    try {
        const { feature } = action;
        if (feature === Feature.TeamsIntegration || feature === Feature.CanvasIntegration) {
            if (action.type === CommonActionType.ENABLE_FEATURE) {
                switch (feature) {
                    case Feature.TeamsIntegration:
                        yield put(setThemeAction(Theme.Teams));
                        break;
                    case Feature.CanvasIntegration:
                        yield put(setThemeAction(Theme.Canvas));
                        break;
                    default:
                        break;
                }
            }
            else {
                yield put(setThemeAction(Theme.Default));
            }
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* loadTeamsOrDefaultThemeSaga() {
    yield takeEvery([CommonActionType.ENABLE_FEATURE, CommonActionType.DISABLE_FEATURE], loadTeamsOrDefaultTheme);
}
export function* setTheme(action) {
    try {
        const { theme } = action;
        switch (theme) {
            case Theme.Teams:
                yield all([call(loadTheme, TeamsTheme), call(loadCustomTheme, TeamsCustomTheme)]);
                break;
            case Theme.LmsBasics:
                yield all([call(loadTheme, DefaultTheme), call(loadCustomTheme, LmsBasicsCustomTheme)]);
                break;
            case Theme.Canvas:
                yield all([call(loadTheme, CanvasTheme), call(loadCustomTheme, CanvasCustomTheme)]);
                break;
            case Theme.Default:
            default:
                yield all([call(loadTheme, DefaultTheme), call(loadCustomTheme, DefaultCustomTheme)]);
                break;
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* setThemeSaga() {
    yield takeLatest(CommonActionType.SET_THEME, setTheme);
}
