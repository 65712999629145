export const LoadingStoreState = {
    NotStarted: 'NotStarted',
    Updating: 'Updating',
    Loading: 'Loading',
    Loaded: 'Loaded',
    LoadingFailed: 'LoadingFailed',
    UpdateFailed: 'UpdateFailed',
    Updated: 'Updated',
};
export function getCombinedState(...states) {
    let hasLoading = false;
    let hasNotLoaded = false;
    for (let i = 0; i < states.length; i++) {
        switch (states[i]) {
            case LoadingStoreState.LoadingFailed:
                return LoadingStoreState.LoadingFailed;
            case LoadingStoreState.Loading:
                hasLoading = true;
                hasNotLoaded = true;
                break;
            case LoadingStoreState.NotStarted:
                hasNotLoaded = true;
                break;
        }
    }
    return hasNotLoaded
        ? hasLoading
            ? LoadingStoreState.Loading
            : LoadingStoreState.NotStarted
        : LoadingStoreState.Loaded;
}
