import { call, put, race, select, take } from 'redux-saga/effects';
import { register } from '../../../data/providers/register-provider';
import { registerUserSuccess, registerUserFailed } from '../../actions/register/register-action-creators';
import { RegisterActionType } from '../../actions/register/register-actions';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { register as registerVNext } from '../../../data/providers/vnext/labs-api/register-provider';
import { registrationCodeLengthBasedTakeLatest } from '../../effects/registration-code-length-based';
import { enableFeature } from '../../actions/common/common-action-creators';
import Feature from '../../../utils/features';
import { CommonActionType } from '../../actions/common/common-actions';
import { isRegisterRoute } from '../../selectors/route-selector';
export function* registerUser(action) {
    const { registrationCode } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(register, registrationCode, accessToken, locale, language);
        yield put(registerUserSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, registrationCode, FailureOperation.RegisterUser, err ? err.code : undefined);
        yield put(registerUserFailed(error));
    }
}
//vNext saga
export function* registerVNextUser(action) {
    const { registrationCode } = action;
    try {
        const accessToken = yield call(getLabsApiAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(registerVNext, registrationCode, accessToken, locale, language);
        const isRegisterUrl = yield select(isRegisterRoute);
        // a workaround for now to enable vNext feature if registering using the link from email inivation. so we could test vNext student scenarios more smoothly.
        // we only enable vnext feature if users are registering with the registration link.
        if (isRegisterUrl) {
            yield put(enableFeature(Feature.VNext));
            yield race({
                success: take(CommonActionType.ENABLE_FEATURE_SUCCESS),
                error: take(CommonActionType.ENABLE_FEATURE_ERROR),
            });
        }
        yield put(registerUserSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, registrationCode, FailureOperation.RegisterUser, err ? err.code : undefined);
        yield put(registerUserFailed(error));
    }
}
export function* registerUserSaga() {
    yield registrationCodeLengthBasedTakeLatest(RegisterActionType.REGISTER_USER, registerUser, registerVNextUser);
}
