import { ProgressIndicator, Stack } from '@fluentui/react';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import commonMessages from '../../language/common-messages';
import { shortenNumber } from '../../utils/common';
import { getCustomTheme } from '../themes/selectors';
const VmUsageColumnInjected = (props) => {
    const msg = props.intl.formatMessage;
    const usageAsProgressMessage = msg(commonMessages.vmUsageColumnProgressFormat, {
        current: shortenNumber(props.currentUsage, props.locale, props.intl),
        total: shortenNumber(props.usageQuota, props.locale, props.intl),
    });
    const usageMessage = msg(commonMessages.vmUsageColumnUsageFormat, {
        current: props.intl.formatNumber(props.currentUsage, {
            maximumFractionDigits: 1,
        }),
    });
    const message = props.usageQuota > 0 ? (<div className={'vm-usage-column'} style={{ width: 'fit-content', fontSize: '12px' }}>
                {usageAsProgressMessage}
            </div>) : (<div className={'vm-usage-column'}>{usageMessage}</div>);
    return (<Stack verticalFill verticalAlign="center">
            <Stack.Item>{message}</Stack.Item>
        </Stack>);
};
export const VmUsageColumn = injectIntl(VmUsageColumnInjected);
export const VmUsageProgressColumn = (props) => {
    if (props.usageQuota < 1) {
        return null;
    }
    const { usageProgressTrackStyles: trackStyles } = getCustomTheme();
    const trackStyle = props.isHoveredOrSelected ? trackStyles?.active : trackStyles?.normal;
    return (<Stack horizontal verticalFill verticalAlign="center">
            <Stack.Item>
                <div aria-hidden="true">
                    <ProgressIndicator percentComplete={props.currentUsage / props.usageQuota} barHeight={4} styles={{
        root: { width: '77px' },
        progressBar: {
            backgroundColor: (props.currentUsage >= props.usageQuota && '#DA3b01') || undefined,
        },
        progressTrack: trackStyle,
    }}/>
                </div>
            </Stack.Item>
        </Stack>);
};
