import * as React from 'react';
import { Dialog, DialogFooter, PrimaryButton, DialogType, TextField } from '@fluentui/react';
import { defineMessages, injectIntl } from 'react-intl';
import commonMessages from '../../language/common-messages';
import CopyToClipboardButton from '../copy-to-clipboard-button/copy-to-clipboard-button';
import Constants from '../../utils/constants';
import './ssh-connect-dialog.css';
const messages = defineMessages({
    connectDialogTitle: {
        id: 'VmConnectDialogTitle',
        defaultMessage: 'Connect to your virtual machine',
        description: 'The title of the VM connect dialog.',
    },
    connectDialogMessage: {
        id: 'VmConnectDialogMessage',
        defaultMessage: 'To connect to your Linux virtual machine using SSH, use the following command:',
        description: 'The message displayed in the VM connect dialog.',
    },
    inputLabel: {
        id: 'VmConnectDialogSshConnectionInputLabel',
        defaultMessage: 'SSH connection command',
        description: 'Label for the input that contains SSH connection info.',
    },
});
const ConnectToVmDialogInjected = (props) => {
    const { sshCommand, onDismiss, onDismissed, intl } = props;
    const { formatMessage: msg } = intl;
    const connectDialogTitle = msg(messages.connectDialogTitle);
    const connectDialogMessage = msg(messages.connectDialogMessage);
    const doneText = msg(commonMessages.done);
    const inputLabel = msg(messages.inputLabel);
    return (<Dialog hidden={false} onDismiss={onDismiss} maxWidth={Constants.DefaultDialogWidth} dialogContentProps={{
        type: DialogType.normal,
        title: connectDialogTitle,
        subText: connectDialogMessage,
    }} modalProps={{
        onDismissed: onDismissed,
        containerClassName: 'ssh-connect-dialog',
    }}>
            <TextField name="sshKey" multiline disabled={false} autoAdjustHeight resizable={false} readOnly={true} value={sshCommand} ariaLabel={inputLabel} styles={{
        root: {
            maxWidth: '402px',
        },
    }}/>
            <CopyToClipboardButton text={sshCommand}/>
            <DialogFooter>
                <PrimaryButton onClick={onDismiss} text={doneText} styles={{
        root: {
            minWidth: '80px',
            height: '32px',
        },
    }}/>
            </DialogFooter>
        </Dialog>);
};
export const ConnectToVmDialog = injectIntl(ConnectToVmDialogInjected);
export default ConnectToVmDialog;
