import * as React from 'react';
import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import { injectIntl, defineMessages, FormattedMessage } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { RegistrationState, RegistrationErrorCode } from '../data/models/register-user';
import FullPageMessage from '../full-page-message/full-page-message';
import { Routes } from '../utils/routes';
import { MlClientError, FailureOperation } from '../data/ml-client-error';
import './register-user.css';
const messages = defineMessages({
    registeringLoadingLabel: {
        id: 'RegisteringLoadingLabel',
        defaultMessage: 'Registering...',
        description: 'Message displayed below a loading spinner when lab registration is in progress.',
    },
    registerHeaderLabel: {
        id: 'RegisterUserPageHeading',
        defaultMessage: 'Registering for your lab',
        description: 'Heading displayed on the register user page while registration is in progress.',
    },
});
const RegisterUserInjected = (props) => {
    const { registerViewModel, intl } = props;
    const { search, registrationState, error } = registerViewModel;
    const loadingStatus = intl.formatMessage(messages.registeringLoadingLabel);
    const registeringHeader = intl.formatMessage(messages.registerHeaderLabel);
    const errorCode = error ? error.code : undefined;
    const registrationFailed = registrationState === RegistrationState.Failed;
    const registrationSuccess = registrationState === RegistrationState.Registered;
    const registrationInProgress = registrationState === RegistrationState.Registering || registrationState === RegistrationState.None;
    if (registrationFailed) {
        let image = 'error-general';
        let errorMessage;
        switch (errorCode) {
            case RegistrationErrorCode.InsufficientEnvironmentsToClaim:
                image = 'error-access';
                errorMessage = (<FormattedMessage id="RegisterUserInsufficientEnvironmentsToClaimMessage" defaultMessage="All virtual machines have been claimed. Please contact the lab owner to increase the capacity of the lab." description="The message displayed to the user when a lab has insufficient capacity to register."/>);
                break;
            case RegistrationErrorCode.RestrictedLabAccessDenied:
                image = 'error-access';
                errorMessage = (<FormattedMessage id="RegisterUserRestrictedLabAccessDeniedMessage" defaultMessage="You do not have permission to access this lab. Please contact the lab owner for access." description="The message displayed to the user when they do not have access to register for a lab."/>);
                break;
            case RegistrationErrorCode.InvalidRegistrationCode:
                image = 'error-access';
                errorMessage = (<FormattedMessage id="RegisterUserInvalidRegistrationCodeMessage" defaultMessage="The registration code provided is invalid or expired." description="The message displayed to the user when lab registration files due to an invalid or expired code."/>);
                break;
            case RegistrationErrorCode.CannotRegisterToUnpublishedLab:
                image = 'error-access';
                errorMessage = (<FormattedMessage id="RegisterUserCannotRegisterToUnpublishedLabMessage" defaultMessage="You cannot register to a lab that has not been published." description="The message displayed to the user when lab registration fails due to an unpublished lab."/>);
                break;
            case RegistrationErrorCode.ResourceOperationNotSupported:
                errorMessage = (<FormattedMessage id="RegisterUserResourceOperationNotSupportedbMessage" defaultMessage="We are unable to register for the lab at this time. Please try again in a few minutes." description="The message displayed to the user when lab registration fails because their user object is being deleted."/>);
                break;
            default:
                throw (error ||
                    new MlClientError('An unknown registration error was encountered.', '', FailureOperation.RegisterUser));
        }
        return <FullPageMessage image={image} message={registeringHeader} messageDetails={errorMessage}/>;
    }
    return (<Stack id="register-user-content" verticalAlign="center" horizontalAlign="center" grow verticalFill>
            {registrationSuccess ? (<Redirect to={{ pathname: Routes.StudentView, search }}/>) : (<Stack.Item>
                    <h2 className="ms-fontSize-xxl ms-fontWeight-semibold">{registeringHeader}</h2>
                    {registrationInProgress && (<Spinner size={SpinnerSize.large} label={loadingStatus} ariaLive="assertive"/>)}
                </Stack.Item>)}
        </Stack>);
};
export const RegisterUser = injectIntl(RegisterUserInjected);
export default RegisterUser;
