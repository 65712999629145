import { defineMessages } from 'react-intl';
const messages = defineMessages({
    requiredError: {
        id: 'UsernameValidatorRequiredError',
        defaultMessage: 'Username is required.',
        description: 'Error shown for invalid username values.',
    },
    reservedError: {
        id: 'UsernameValidatorReservedError',
        defaultMessage: 'Usernames cannot match one of the reserved words.',
        description: 'Error shown for invalid username values.',
    },
    endsWithPeriodError: {
        id: 'UsernameValidatorEndsWithPeriodError',
        defaultMessage: 'Usernames cannot end with a period (.)',
        description: 'Error shown for invalid username values.',
    },
    windowsSpecialCharsError: {
        id: 'UsernameValidatorWindowsSpecialCharsError',
        defaultMessage: 'Usernames cannot contain the following characters: {invalidChars}',
        description: 'Error shown for invalid username values. {invalidChars} is a list of invalid characters.',
    },
    startsWithNumberError: {
        id: 'UsernameValidatorStartsWithNumberError',
        defaultMessage: "Usernames cannot start with a number, '$', '~', or '-'",
        description: 'Error shown for invalid username values.',
    },
    linuxSpecialCharsError: {
        id: 'UsernameValidatorLinuxSpecialCharsError',
        defaultMessage: 'Usernames cannot contain uppercase letters or the following characters: {invalidChars}',
        description: 'Error shown for invalid username values. {invalidChars} is a list of invalid characters.',
    },
    unicodeCharsError: {
        id: 'UsernameValidatorUnicodeCharsError',
        defaultMessage: 'Usernames cannot contain Unicode characters with codes below 0x20 or above 0x7E.',
        description: 'Error shown for invalid username values.',
    },
});
// The reserved user name list is copied from the managed lab service code
// `EnvironmentSetting.Business.Validators.ReservedUsernames`
const reservedUsernames = [
    'administrator',
    'admin',
    'user',
    'user1',
    'user2',
    'user3',
    'user4',
    'user5',
    'admin1',
    'admin2',
    '1',
    '123',
    'a',
    'actuser',
    'adm',
    'aspnet',
    'backup',
    'console',
    'david',
    'guest',
    'john',
    'owner',
    'root',
    'server',
    'sql',
    'support',
    'support_388945a0',
    'sys',
    'test',
    'test1',
    'test2',
    'test3',
];
// Allowed username characters.
const allowedCharactersRegex = /^[\u0020-\u007E]*$/;
// Windows user name cannot end with a period (.) or contain the following characters
// \/"[]:|<>+=;,?*@
const windowsInvalidCharactersRegex = /[\\/"\[\]:|<>+=;,?*@]/;
const windowsInvalidChars = '\\/"[]:|<>+=;,?*@';
const windowsEndsWithPeriodRegex = /\.$/;
// Linux username cannot start with '$', '-' or contain the following characters
// A-Z \/""[]:|<>+=;,?*@#()!
const linuxInvalidCharactersRegex = /[A-Z \\/"\[\]:|<>+=;,?*@#()!]/;
const linuxInvalidChars = ' \\/"[]:|<>+=;,?*@#()!';
const linuxInvalidStartChars = /^[$~\-]/;
const linuxStartsWithNumberRegex = /^[0-9]/;
export function validateUsername(name, isWindows, intl) {
    if (!name || name.trim().length === 0) {
        return intl.formatMessage(messages.requiredError);
    }
    else if (reservedUsernames.indexOf(name.toLowerCase()) >= 0) {
        return intl.formatMessage(messages.reservedError);
    }
    else if (isWindows && windowsEndsWithPeriodRegex.test(name)) {
        return intl.formatMessage(messages.endsWithPeriodError);
    }
    else if (isWindows && windowsInvalidCharactersRegex.test(name)) {
        return intl.formatMessage(messages.windowsSpecialCharsError, { invalidChars: windowsInvalidChars });
    }
    else if (!isWindows && (linuxStartsWithNumberRegex.test(name) || linuxInvalidStartChars.test(name))) {
        return intl.formatMessage(messages.startsWithNumberError);
    }
    else if (!isWindows && linuxInvalidCharactersRegex.test(name)) {
        return intl.formatMessage(messages.linuxSpecialCharsError, { invalidChars: linuxInvalidChars });
    }
    else if (!allowedCharactersRegex.test(name)) {
        return intl.formatMessage(messages.unicodeCharsError);
    }
    else {
        return undefined;
    }
}
