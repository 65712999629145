import { all, call, put, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import LabProvider from '../../../data/providers/lab-provider';
import { ResourceId } from '../../../utils/resource-id';
import { inviteUsersError, inviteUsersSuccess, inviteVNextUsersError } from '../../actions/user/user-action-creators';
import { UserActionType } from '../../actions/user/user-actions';
import { getUsers } from '../../selectors/user-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import VNextUserProvider from '../../../data/providers/vnext/arm-api/user-provider';
export function* inviteUsers(action) {
    const { labId, userIds, extraMessage } = action;
    try {
        // resolve emails form the user Ids
        const users = yield select(getUsers);
        const emails = users
            .filter((user) => userIds.indexOf(user.id) > -1)
            .map((user) => user.email)
            .toArray();
        const resourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(LabProvider.sendEmail, resourceId, emails, extraMessage, accessToken, locale, language);
        yield put(inviteUsersSuccess(userIds));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.InviteUsers, e ? e.code : undefined);
        yield put(inviteUsersError(error));
    }
}
export async function callInviteUser(resourceId, accessToken, locale, language, errors, text) {
    try {
        await VNextUserProvider.inviteUser(resourceId, accessToken, locale, language, text);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.InviteUsers, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* inviteVNextUsers(action) {
    const { userIds, extraMessage } = action;
    try {
        const resourceIds = userIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        yield all(resourceIds.map((resourceId) => call(callInviteUser, resourceId, accessToken, locale, language, errors, extraMessage)));
        const failedIds = Object.keys(errors);
        if (failedIds.length < userIds.length) {
            const successIds = userIds.filter((id) => failedIds.indexOf(id) < 0);
            yield put(inviteUsersSuccess(successIds));
        }
        if (failedIds.length > 0) {
            yield put(inviteVNextUsersError(errors));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, 'all', FailureOperation.InviteUsers, e ? e.code : undefined);
        const errors = {};
        errors[error.id] = error;
        yield put(inviteVNextUsersError(errors));
    }
}
export function* inviteUsersSaga() {
    yield labParentBasedTakeLatest(UserActionType.INVITE_USERS, inviteUsers, inviteVNextUsers);
}
