export const TeamsActionType = {
    TEAMS_INITIALIZE: 'TEAMS_INITIALIZE',
    TEAMS_INITIALIZE_SUCCESS: 'TEAMS_INITIALIZE_SUCCESS',
    TEAMS_INITIALIZE_ERROR: 'TEAMS_INITIALIZE_ERROR',
    TEAMS_DISABLE_SAVE_BUTTON: 'TEAMS_DISABLE_SAVE_BUTTON',
    TEAMS_DISABLE_SAVE_BUTTON_SUCCESS: 'TEAMS_DISABLE_SAVE_BUTTON_SUCCESS',
    TEAMS_DISABLE_SAVE_BUTTON_ERROR: 'TEAMS_DISABLE_SAVE_BUTTON_ERROR',
    TEAMS_CONFIGURE_TAB: 'TEAMS_CONFIGURE_TAB',
    TEAMS_CONFIGURE_TAB_SUCCESS: 'TEAMS_CONFIGURE_TAB_SUCCESS',
    TEAMS_CONFIGURE_TAB_ERROR: 'TEAMS_CONFIGURE_TAB_ERROR',
    TEAMS_START_MFA: 'TEAMS_START_MFA',
    TEAMS_START_MFA_ERROR: 'TEAMS_START_MFA_ERROR',
    TEAMS_START_MFA_REDIRECTING: 'TEAMS_START_MFA_REDIRECTING',
    TEAMS_COMPLETE_MFA: 'TEAMS_COMPLETE_MFA',
    TEAMS_COMPLETE_MFA_SUCCESS: 'TEAMS_COMPLETE_MFA_SUCCESS',
    TEAMS_COMPLETE_MFA_FAILED: 'TEAMS_COMPLETE_MFA_FAILED',
    TEAMS_COMPLETE_MFA_ERROR: 'TEAMS_COMPLETE_MFA_ERROR',
    SET_USER_TEAM_ROLE: 'SET_USER_TEAM_ROLE',
    SET_TEAMS_CHANNEL_TYPE: 'SET_TEAMS_CHANNEL_TYPE',
    SET_TEAMS_THEME: 'SET_TEAMS_THEME',
};
