import memoize from 'memoize-one';
import { UserTeamRole, ChannelType } from '../../data/models/teams';
export const isTeamsAdmin = memoize((state) => {
    const teamsRole = state.get('teamsStore').get('userTeamRole');
    return teamsRole === UserTeamRole.Admin;
});
export const isRegularChannel = memoize((state) => {
    const channelType = state.get('teamsStore').get('channelType');
    return channelType === ChannelType.Regular;
});
