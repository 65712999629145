import { Record, List } from 'immutable';
import { SubscriptionActionType, } from '../actions/subscription/subscription-actions';
import { LoadingStoreState } from '../../data/models/loading-store-state';
function loadSubscriptions(state, action) {
    return state.merge({
        loadingState: state.loadingState === LoadingStoreState.Loaded || state.loadingState === LoadingStoreState.Updated
            ? LoadingStoreState.Updating
            : LoadingStoreState.Loading,
    });
}
function loadSubscriptionsSuccess(state, action) {
    const { subscriptions } = action;
    return state.merge({
        loadingState: state.loadingState === LoadingStoreState.Updating ? LoadingStoreState.Updated : LoadingStoreState.Loaded,
        subscriptions: List(subscriptions),
    });
}
function loadSubscriptionsError(state, action) {
    return state.merge({
        loadingState: LoadingStoreState.LoadingFailed,
    });
}
const initialState = Record({
    loadingState: LoadingStoreState.NotStarted,
    subscriptions: List(),
    error: undefined,
})();
export const subscriptionReducer = (state = initialState, action) => {
    switch (action.type) {
        case SubscriptionActionType.LOAD_SUBSCRIPTIONS:
            return loadSubscriptions(state, action);
        case SubscriptionActionType.LOAD_SUBSCRIPTIONS_SUCCESS:
            return loadSubscriptionsSuccess(state, action);
        case SubscriptionActionType.LOAD_SUBSCRIPTIONS_ERROR:
            return loadSubscriptionsError(state, action);
        case SubscriptionActionType.CLEAR_USER_SESSION:
        case SubscriptionActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            return state;
    }
};
