import { DefaultButton, Panel, PanelType, Stack, TextField } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { SubmitButton } from '../common/submit-button';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import Constants from '../utils/constants';
import { getCustomTheme } from '../common/themes/selectors';
const messages = defineMessages({
    title: {
        id: 'UserListInvitationFlyoutTitle',
        defaultMessage: 'Send invitation by email',
        description: 'Title of the flyout dialog to send invitation email to users.',
    },
    label: {
        id: 'UserListInvitationFlyoutMessageLabel',
        defaultMessage: 'Add a message (optional)',
        description: 'Label above optional message text box on a dialog to send invitation email to users.',
    },
});
const InvitationFlyoutInjected = (props) => {
    const { numberOfUsers, isSubmitting, onSubmit, onDismiss, intl } = props;
    const [extraMessage, setExtraMessage] = React.useState('');
    // close the flyout after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    const msg = intl.formatMessage;
    const numberOfUsersSpan = (<b>
            <FormattedMessage id="UserListInvitationFlyoutNumberOfUsersFormat" defaultMessage="{numberOfUsers} user(s) will be invited." description="Formatted text string that will be composed into UserListInvitationFlyoutTextFormat. {numberOfUsers} is the number of users who will be invited which is greater or equal to 1." values={{ numberOfUsers }}/>
        </b>);
    return (<Panel type={PanelType.custom} customWidth={`${Constants.DefaultFlyoutWidth}px`} isOpen={true} isLightDismiss={!isSubmitting} onDismiss={isSubmitting ? undefined : onDismiss} headerText={msg(messages.title)} closeButtonAriaLabel={msg(commonMessages.cancel)} styles={getCustomTheme().panelStyles} onRenderFooterContent={() => (<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '8px' }}>
                    <Stack.Item>
                        <SubmitButton isSubmitting={isSubmitting} buttonText={msg(commonMessages.send)} onClick={() => onSubmit(extraMessage)}/>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton disabled={isSubmitting} onClick={onDismiss}>
                            {msg(commonMessages.cancel)}
                        </DefaultButton>
                    </Stack.Item>
                </Stack>)}>
            <div style={{ marginBottom: 30 }}>
                <FormattedMessage id="UserListInvitationFlyoutTextFormat" defaultMessage="{numberOfUsersSpan} This email will include a registration link for the lab." description="Text at the top of flyout dialog to send invitation email to users. {numberOfUsersSpan} is the bolded resolved value of UserListInvitationFlyoutNumberOfUsersFormat." values={{ numberOfUsersSpan }}/>
            </div>
            <TextField disabled={isSubmitting} label={msg(messages.label)} autoFocus={true} resizable={false} multiline={true} rows={Constants.LargeTextFieldRows} maxLength={10000} onChange={(_, value) => setExtraMessage(value || '')}/>
        </Panel>);
};
export const InvitationFlyout = injectIntl(InvitationFlyoutInjected);
export default InvitationFlyout;
