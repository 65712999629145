import Settings from '../../settings/settings';
import { environmentType, EnvironmentType } from '../environment';
import { getResourceProperties, getLocale, getMarket, getLanguage, getRequestedLocale, getTimezone, getFeatures, getHost, getReferrer, } from '../../redux/selectors/common-selectors';
import MlClientError from '../../data/ml-client-error';
import { getRouteName } from '../../redux/selectors/route-selector';
import { getGroupId } from '../../redux/selectors/group-selectors';
import { getTelemetryChannelsFromType } from './telemetry-type';
const consolePrefix = 'TelemetryChannel';
const version = process.env.REACT_APP_VERSION;
let logToConsole = environmentType !== EnvironmentType.PROD;
export function enableConsoleLog() {
    if (environmentType === EnvironmentType.PROD) {
        logToConsole = true;
    }
}
export function disableConsoleLog() {
    if (environmentType === EnvironmentType.PROD) {
        logToConsole = false;
    }
}
let store = undefined;
export function setStore(newStore) {
    store = newStore;
}
const initializer = (item) => {
    try {
        if (!item.data) {
            item.data = {};
        }
        // process our telemetry to extract the client request ID and remove any auth tokens
        if (item.baseData && item.baseData.properties && item.baseData.properties.requestHeaders) {
            delete item.baseData.properties.requestHeaders['authorization'];
            delete item.baseData.properties.requestHeaders['Authorization'];
            if (item.baseData.properties.requestHeaders['x-ms-client-request-id']) {
                item.data['ClientRequestId'] =
                    item.baseData.properties.requestHeaders['x-ms-client-request-id'];
            }
        }
        // if URI in the data, scrub it
        if (item.data?.uri) {
            item.data['uri'] = scrubUri(item.data.uri);
        }
        // get some common info from our store
        if (store) {
            const state = store.getState();
            const routeName = getRouteName(state);
            const { subscriptionId, resourceGroup, labAccount, lab, template } = getResourceProperties(state);
            const requestedLocale = getRequestedLocale(state);
            const locale = getLocale(state);
            const market = getMarket(state);
            const language = getLanguage(state);
            const timezone = getTimezone(state);
            const features = getFeatures(state);
            const host = getHost(state);
            const referrer = getReferrer(state);
            const groupId = getGroupId(state);
            item.data['subscriptionId'] = subscriptionId;
            item.data['resourceGroup'] = resourceGroup;
            item.data['labAccount'] = labAccount;
            item.data['lab'] = lab;
            item.data['template'] = template;
            item.data['requestedLocale'] = requestedLocale;
            item.data['locale'] = locale;
            item.data['market'] = market;
            item.data['language'] = language;
            item.data['timezone'] = timezone;
            item.data['features'] = features && features.length > 0 ? JSON.stringify(features) : '';
            item.data['routeName'] = routeName;
            item.data['host'] = host;
            item.data['referrer'] = referrer;
            item.data['groupId'] = groupId;
        }
        item.data['version'] = version;
        item.data['environmentType'] = environmentType;
        item.data['clientType'] = 'React';
        item.data['Service'] = 'mlclient'; // back compat with old client
    }
    catch (err) {
        // Not logging to AI here since we don't want this to cause a logging loop.
        // Only adding the try - catch to prevent the client from crashing due
        // to telemetry bugs.
        if (logToConsole) {
            console.error(`TelemetryChannel: initializer failed: ${err ? JSON.stringify(err) : ''}`);
        }
    }
};
const telemetryChannels = getTelemetryChannelsFromType(Settings.TelemetryChannelsEnabled);
function init() {
    try {
        telemetryChannels.forEach((channel) => {
            channel.initialize(initializer);
        });
    }
    catch (err) {
        console.error(`TelemetryChannel: One or more channels failed to initialize: ${err ? JSON.stringify(err) : ''}`);
    }
}
function handleAppInsightsException(func) {
    try {
        func();
    }
    catch (e) {
        console.error(`TelemetryChannel error: ${e || {}}`);
    }
}
function addMetadata(properties) {
    // TODO: Setup whatever metadata properties we want to make available on all events
    if (properties && properties['payload']) {
        const payload = JSON.parse(properties['payload'], truncateProp);
        properties = { ...properties, payload };
    }
    return properties || {};
}
const truncate = (props) => {
    // Truncate properties longer than 1024 characters
    const truncatedProps = [];
    Object.keys(props).forEach((key) => {
        const prop = props[key];
        if (prop && prop.length > 1024) {
            props[key] = prop.substring(0, 1024);
            truncatedProps.push(key);
        }
    });
    props['truncatedProps'] = truncatedProps.toString();
    return props;
};
const truncateProp = (_, value) => {
    if (Array.isArray(value) && JSON.stringify(value).length > 1024) {
        // this shortens the length but still gets a nice array view in AI
        while (JSON.stringify(value).length > 1024) {
            value.pop();
        }
        return value;
    }
    if (typeof value === 'string' && value.length > 1024) {
        return value.substring(0, 1024);
    }
    return value;
};
export function trackPageView(name, uri, properties, measurements) {
    handleAppInsightsException(() => {
        if (logToConsole) {
            console.info(`${consolePrefix}.trackPageView: name = "${name || ''}", uri = "${uri || ''}"`);
        }
        properties = truncate(addMetadata(properties));
        telemetryChannels.forEach((channel) => {
            channel.trackPageView(name, uri, properties, measurements);
        });
    });
}
export function trackEvent(name, properties, measurements) {
    handleAppInsightsException(() => {
        if (logToConsole) {
            if (properties) {
                console.groupCollapsed(`${consolePrefix}.trackEvent: name = "${name} - ${properties['type']}"`);
                console.info(`properties ="${JSON.stringify(properties)}"`);
                console.groupEnd();
            }
            else {
                console.info(`${consolePrefix}.trackEvent: name = "${name}`);
            }
        }
        // todo: look into moving the logic in addMetadata to truncate
        properties = addMetadata(properties);
        telemetryChannels.forEach((channel) => {
            channel.trackEvent(name, properties, measurements);
        });
    });
}
export function scrubUri(url) {
    // Split the URL into the base and the hash (fragment)
    // eslint-disable-next-line prefer-const
    let [baseUrl, hash] = url.split('#');
    if (hash) {
        const params = new URLSearchParams(hash);
        params.delete('id_token');
        hash = params.toString();
        if (hash) {
            return `${baseUrl}#${hash}`;
        }
        else {
            return baseUrl;
        }
    }
    return url;
}
export function scrubData(data) {
    try {
        const scrubbedData = JSON.parse(data);
        if (scrubbedData && scrubbedData.uri) {
            scrubbedData.uri = scrubUri(scrubbedData.uri);
        }
        return JSON.stringify(scrubbedData);
    }
    catch (e) {
        return data;
    }
}
export function trackDependency(id, responseCode, name, duration, success, correlationContext, type, data, properties, measurements) {
    handleAppInsightsException(() => {
        if (logToConsole) {
            console.info(`${consolePrefix}.trackDependency: id = "${id}", responseCode = "${responseCode}", name = "${name}", duration = "${duration}", success = "${success}", correlationContext = "${correlationContext}", type = "${type}", data = "${data}"`);
        }
        properties = truncate(addMetadata(properties));
        if (properties && 'uri' in properties) {
            properties['uri'] = scrubUri(properties['uri']);
        }
        telemetryChannels.forEach((channel) => {
            channel.trackDependencyData(id, responseCode, name, duration, success, correlationContext, type, scrubData(data), properties, measurements);
        });
    });
}
export function trackException(id, exception, severityLevel, properties, measurements) {
    handleAppInsightsException(() => {
        if (logToConsole) {
            console.error(`${consolePrefix}.trackException: id = "${id}", exception = "${exception || {}}"`);
        }
        properties = properties || {};
        if (exception && exception instanceof MlClientError) {
            const mlError = exception;
            properties['isMlError'] = 'true';
            properties['errorCode'] = mlError.code || '';
            properties['id'] = mlError.id;
            properties['failureOperation'] = mlError.failureOperation;
        }
        else {
            properties['isMlError'] = 'false';
            properties['errorCode'] = '';
            properties['id'] = '';
            properties['failureOperation'] = '';
        }
        properties = truncate(addMetadata(properties));
        telemetryChannels.forEach((channel) => {
            channel.trackException(id, exception, severityLevel, properties, measurements);
        });
    });
}
export function trackMetric(name, average, sampleCount, min, max) {
    handleAppInsightsException(() => {
        if (logToConsole) {
            console.info(`${consolePrefix}.trackMetric: name = "${name}", average = "${average}", sampleCount = "${sampleCount || ''}", min = "${min || ''}", max = "${max || ''}"`);
        }
        telemetryChannels.forEach((channel) => {
            channel.trackMetric(name, average, sampleCount, min, max);
        });
    });
}
export function trackTrace(message, properties, severityLevel) {
    handleAppInsightsException(() => {
        if (logToConsole) {
            console.info(`${consolePrefix}.trackTrace: message = "${message}"`);
        }
        properties = truncate(addMetadata(properties));
        telemetryChannels.forEach((channel) => {
            channel.trackTrace(message, properties, severityLevel);
        });
    });
}
export function flush() {
    handleAppInsightsException(() => {
        if (logToConsole) {
            console.info(`${consolePrefix}.flush`);
        }
        telemetryChannels.forEach((channel) => {
            channel.flush();
        });
    });
}
export function setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie) {
    handleAppInsightsException(() => {
        if (logToConsole) {
            console.info(`${consolePrefix}.setAuthenticatedUserContext: authenticatedUserId = "${authenticatedUserId}", accountId = "${accountId || ''}", storeInCookie = "${storeInCookie || false}"`);
        }
        telemetryChannels.forEach((channel) => {
            channel.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
        });
    });
}
export function clearAuthenticatedUserContext() {
    handleAppInsightsException(() => {
        if (logToConsole) {
            console.info(`${consolePrefix}.clearAuthenticatedUserContext`);
        }
        telemetryChannels.forEach((channel) => {
            channel.clearAuthenticatedUserContext();
        });
    });
}
const TelemetryChannel = {
    trackPageView,
    trackEvent,
    trackDependency,
    trackException,
    trackMetric,
    trackTrace,
    flush,
    setAuthenticatedUserContext,
    clearAuthenticatedUserContext,
};
try {
    init();
}
catch (error) {
    console.error(`Telemetry failed to initialize and will not be available. Error: ${JSON.stringify(error || {})}`);
}
export default TelemetryChannel;
