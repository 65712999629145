import { DistributedTracingModes } from '@microsoft/applicationinsights-common';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import Settings from '../../settings/settings';
export class ApplicationInisightsChannel {
    constructor() {
        this._isInitialized = false;
    }
    initialize(telemetryInitializer) {
        if (this._isInitialized) {
            return;
        }
        const config = {
            instrumentationKey: Settings.ApplicationInsights.InstrumentationKey,
            emitLineDelimitedJson: true,
            enableDebug: false,
            isBrowserLinkTrackingEnabled: true,
            disableExceptionTracking: false,
            disableAjaxTracking: false,
            disableFetchTracking: false,
            isCookieUseDisabled: true,
            enableSessionStorageBuffer: true,
            isRetryDisabled: false,
            isStorageUseDisabled: false,
            isBeaconApiDisabled: true,
            enableCorsCorrelation: true,
            enableAutoRouteTracking: false,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
            distributedTracingMode: DistributedTracingModes.W3C,
        };
        this._appInsights = new ApplicationInsights({ config });
        this._appInsights.loadAppInsights();
        this._appInsights.addTelemetryInitializer(telemetryInitializer);
        // Force appInsights to generate a new trace ID on piece of telemetry. We will look into correlating
        // telemetry later, but right now we don't even use the W3CTraceContext for the client. So this is to
        // benefit backend telemetry.
        this._appInsights.context.telemetryTrace.traceID = '';
        this._isInitialized = true;
    }
    trackPageView(name, uri, properties, measurements) {
        this._appInsights.trackPageView({ name, uri, properties, measurements });
    }
    trackEvent(name, properties, measurements) {
        this._appInsights.trackEvent({ name, properties, measurements });
    }
    trackDependencyData(id, responseCode, name, duration, success, correlationContext, type, data, properties, measurements) {
        this._appInsights.trackDependencyData({
            id,
            responseCode,
            name,
            duration,
            success,
            correlationContext,
            type,
            data,
            properties,
            measurements,
        });
    }
    trackException(id, exception, severityLevel, properties, measurements) {
        this._appInsights.trackException({ id, exception, severityLevel, properties, measurements });
    }
    trackTrace(message, properties, severityLevel) {
        this._appInsights.trackTrace({ message, properties, severityLevel });
    }
    trackMetric(name, average, sampleCount, min, max) {
        this._appInsights.trackMetric({ name, average, sampleCount, min, max });
    }
    setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie) {
        this._appInsights.setAuthenticatedUserContext(authenticatedUserId, accountId, storeInCookie);
    }
    clearAuthenticatedUserContext() {
        this._appInsights.clearAuthenticatedUserContext();
    }
    flush() {
        this._appInsights.flush();
    }
}
