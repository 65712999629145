import { getMlClient } from './arm-data-provider';
import Constants from '../../utils/constants';
export async function register(registrationCode, accessToken, locale, language) {
    const mlClient = getMlClient(Constants.NoSubscriptionId, accessToken, locale, language);
    await mlClient.globalUsers.register(Constants.NoUsername, {
        registrationCode: registrationCode.trim(),
    });
}
const RegisterProvider = {
    register,
};
export default RegisterProvider;
