import * as React from 'react';
import { Icon, TooltipHost } from '@fluentui/react';
import { getCustomTheme } from '../common/themes/selectors';
import { useIntl } from 'react-intl';
import { OperationType } from '../data/models/vnext/operation';
import { messages } from '../vm-list/vm-list-messages';
export const VmOperationSuccess = (props) => {
    const intl = useIntl();
    const vmOperationType = props.operationType;
    return (<TooltipHost content={intl.formatMessage(messages.operationResultGenericSuccess, {
        Operation: vmOperationType === OperationType.StartOperation
            ? intl.formatMessage(messages.StartOperation)
            : vmOperationType === OperationType.StopOperation
                ? intl.formatMessage(messages.StopOperation)
                : '',
    })}>
            <Icon iconName="SkypeCheck" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '16px',
            color: getCustomTheme().palette?.green,
        },
    }}/>
        </TooltipHost>);
};
