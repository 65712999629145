import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Page } from '../common/page/page';
import { getCustomTheme } from '../common/themes/selectors';
import { commonMessages } from '../language/common-messages';
export const Loading = (props) => {
    const { hideHeader } = props;
    const loadingMessage = useIntl().formatMessage(commonMessages.loadingText);
    const { transitionBackgroundColor } = getCustomTheme();
    return (<Page hideHeader={hideHeader} headerProps={{ hideLabParentPicker: true, hideUserMenu: true, hideTenantPicker: true, hideFeedback: true }}>
            <Stack horizontalAlign="center" verticalAlign="center" grow verticalFill styles={{ root: { backgroundColor: transitionBackgroundColor } }}>
                <Stack.Item>
                    <h2 className="ms-fontSize-xxl ms-fontWeight-semibold">
                        <FormattedMessage id="InitialLoadingSpinnerHeader" defaultMessage="Welcome to Azure Lab Services" description="Heading displayed above a loading spinner on the initial page load while we are initializing / loading."/>
                    </h2>
                </Stack.Item>
                <Stack.Item>
                    <Spinner size={SpinnerSize.large} label={loadingMessage} ariaLive="assertive"/>
                </Stack.Item>
            </Stack>
        </Page>);
};
export default Loading;
