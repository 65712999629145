import { Panel, PanelType } from '@fluentui/react';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { getCustomTheme } from '../../common/themes/selectors';
import { commonMessages } from '../../language/common-messages';
import StudentVmListContainer from '../../student-vm-list/student-vm-list-container';
import { Constants } from '../../utils/constants';
const VmListFlyoutInjected = (props) => {
    const { onDismiss, intl } = props;
    const { formatMessage: msg } = intl;
    return (<Panel type={PanelType.custom} customWidth={`${Constants.DefaultFlyoutWidth}px`} isOpen={true} isLightDismiss={true} onDismiss={onDismiss} headerText={msg(commonMessages.myVirtualMachines)} closeButtonAriaLabel={msg(commonMessages.close)} styles={{ content: { padding: 0 }, ...getCustomTheme().panelStyles }}>
            <StudentVmListContainer isPanelView={true}/>
        </Panel>);
};
export const VmListFlyout = injectIntl(VmListFlyoutInjected);
export default VmListFlyout;
