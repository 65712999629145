import { call, put, select, takeLatest } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { armScopes } from '../../../data/models/identity';
import { getAccessToken, loginRedirect, loginSilent } from '../../../data/providers/msal-provider';
import { initialize, getContext } from '../../../data/providers/teams-provider';
import { Routes } from '../../../utils/routes';
import { teamsCompleteMfa, teamsStartMfaError, teamsStartMfaRedirecting, } from '../../actions/teams/teams-action-creators';
import { TeamsActionType } from '../../actions/teams/teams-actions';
import { getSearch } from '../../selectors/route-selector';
export function* teamsStartMfa(action) {
    try {
        yield call(initialize);
        const search = yield select(getSearch);
        const context = yield call(getContext);
        const { tid, loginHint } = context;
        const result = yield call(loginSilent, tid);
        if (!result.isAuthenticated) {
            yield put(teamsStartMfaRedirecting());
            const signInReturnUrl = `${Routes.TeamsStartMfa}${search}`;
            yield call(loginRedirect, signInReturnUrl, tid, loginHint);
        }
        else {
            const accessTokenReturnUrl = `${Routes.TeamsCompleteMfa}${search}`;
            const result = yield call(getAccessToken, armScopes, tid, accessTokenReturnUrl);
            if (result.isRedirecting) {
                yield put(teamsStartMfaRedirecting());
            }
            else {
                yield put(teamsCompleteMfa());
            }
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.TeamsStartMfa, err ? err.code : undefined);
        yield put(teamsStartMfaError(error));
    }
}
export function* teamsStartMfaSaga() {
    yield takeLatest(TeamsActionType.TEAMS_START_MFA, teamsStartMfa);
}
