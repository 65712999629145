import { List, Map, Record } from 'immutable';
import { LabServicesModels } from 'lab-services';
import Context from '../../action-context';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { UserActionType, } from '../../actions/user/user-actions';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
function setPendingProvisioningState(userIds, users, pendingProvisioningState) {
    userIds.map((id) => {
        const index = users.findIndex((user) => caseInsensitiveCultureInvariantCompare(user.id, id) === 0);
        if (index > -1) {
            users = users.update(index, (user) => {
                return { ...user, pendingProvisioningState };
            });
        }
    });
    return users;
}
function listUsers(state, action) {
    const { loadState: oldLoadState } = state;
    return state.merge({
        loadError: undefined,
        loadState: oldLoadState === LoadingStoreState.Loaded ? LoadingStoreState.Updating : LoadingStoreState.Loading,
    });
}
function listUsersCancelled(state, action) {
    const { loadState: oldLoadState } = state;
    return state.merge({
        loadState: oldLoadState === LoadingStoreState.Updating ? LoadingStoreState.Loaded : LoadingStoreState.NotStarted,
    });
}
function listUsersSuccess(state, action) {
    const { users } = action;
    return state.merge({
        users: List(users),
        loadState: LoadingStoreState.Loaded,
    });
}
function listUsersError(state, action) {
    const { error: loadError } = action;
    const { loadState: oldLoadState } = state;
    return state.merge({
        loadError,
        loadState: oldLoadState === LoadingStoreState.Updating
            ? LoadingStoreState.UpdateFailed
            : LoadingStoreState.LoadingFailed,
    });
}
function deleteUsers(state, action) {
    return state.merge({
        isDeleting: true,
        deleteErrors: Map(),
    });
}
function deleteUsersSuccess(state, action) {
    const { userIds } = action;
    let users = state.get('users');
    userIds.map((_id) => {
        // TODO: why is this called inside a loop?
        users = setPendingProvisioningState(userIds, users, LabServicesModels.ProvisioningState.Deleting);
    });
    return state.merge({
        isDeleting: false,
        users,
    });
}
function deleteUsersError(state, action) {
    const { errors } = action;
    const userIds = Object.keys(errors);
    let deleteErrors = state.get('deleteErrors');
    userIds.map((id) => {
        deleteErrors = deleteErrors.set(id, errors[id]);
    });
    return state.merge({
        deleteErrors,
        isDeleting: false,
    });
}
function addUsers(state, action) {
    return state.merge({
        isAdding: true,
        addErrors: Map(),
    });
}
function addUsersSuccess(state, action) {
    const { users: newUsers } = action;
    let users = state.get('users');
    if (!!newUsers && newUsers.length > 0) {
        newUsers.forEach((user) => {
            if (users.findIndex((o) => caseInsensitiveCultureInvariantCompare(o.id, user.id) === 0) < 0) {
                users = users.push(user);
            }
        });
    }
    return state.merge({
        isAdding: false,
        users,
    });
}
function addUsersError(state, action) {
    const { errors } = action;
    const userIds = Object.keys(errors);
    let addErrors = state.get('addErrors');
    userIds.map((id) => {
        addErrors = addErrors.set(id, errors[id]);
    });
    return state.merge({
        addErrors,
        isAdding: false,
    });
}
function inviteUsers(state, action) {
    return state.merge({
        isInviting: true,
        inviteErrors: Map(),
    });
}
function inviteUsersSuccess(state, action) {
    const { userIds } = action;
    let users = state.get('users');
    userIds.map((id) => {
        const index = users.findIndex((user) => caseInsensitiveCultureInvariantCompare(user.id, id) === 0);
        if (index > -1) {
            users = users.update(index, (user) => {
                return { ...user, isSendingInivatationPending: true };
            });
        }
    });
    return state.merge({
        isInviting: false,
        users,
    });
}
function inviteUsersError(state, action) {
    const { errors } = action;
    const userIds = Object.keys(errors);
    let inviteErrors = state.get('inviteErrors');
    userIds.map((id) => {
        inviteErrors = inviteErrors.set(id, errors[id]);
    });
    return state.merge({
        inviteErrors,
        isInviting: false,
    });
}
function clearLoadError(state, action) {
    return state.merge({
        loadError: undefined,
    });
}
function clearUserErrors(errors, id) {
    if (!!id) {
        errors = errors.remove(id);
    }
    else {
        errors = Map();
    }
    return errors;
}
function clearAddUsersError(state, action) {
    const { id } = action;
    let addErrors = state.get('addErrors');
    addErrors = clearUserErrors(addErrors, id);
    return state.merge({
        addErrors,
    });
}
function clearDeleteUsersError(state, action) {
    const { id } = action;
    let deleteErrors = state.get('deleteErrors');
    deleteErrors = clearUserErrors(deleteErrors, id);
    return state.merge({
        deleteErrors,
    });
}
function clearInviteUsersError(state, action) {
    const { id } = action;
    let inviteErrors = state.get('inviteErrors');
    inviteErrors = clearUserErrors(inviteErrors, id);
    return state.merge({
        inviteErrors,
    });
}
function updateAdditionalQuota(state, action) {
    return state.merge({
        isUpdating: true,
        updateErrors: Map(),
    });
}
function updateAdditionalQuotaSuccess(state, action) {
    let users = state.get('users');
    const { users: updatedUsers } = action;
    updatedUsers.map((user) => {
        const index = users.findIndex((o) => caseInsensitiveCultureInvariantCompare(o.id, user.id) === 0);
        if (index === -1) {
            users = users.push(user);
        }
        else {
            users = users.set(index, user);
        }
    });
    return state.merge({
        isUpdating: false,
        users,
    });
}
function updateAdditionalQuotaError(state, action) {
    const { errors } = action;
    let updateErrors = state.get('updateErrors');
    for (const [userId, error] of Object.entries(errors)) {
        updateErrors = updateErrors.set(userId, error);
    }
    return state.merge({
        updateErrors,
        isUpdating: false,
    });
}
function clearUpdateAdditionalQuotaError(state, action) {
    const { id } = action;
    let updateErrors = state.get('updateErrors');
    if (id) {
        updateErrors = updateErrors.remove(id);
    }
    else {
        updateErrors = Map();
    }
    return state.merge({
        updateErrors,
    });
}
const initialState = Record({
    users: List(),
    loadState: LoadingStoreState.NotStarted,
    loadError: undefined,
    isAdding: false,
    isDeleting: false,
    isInviting: false,
    isUpdating: false,
    addErrors: Map(),
    deleteErrors: Map(),
    inviteErrors: Map(),
    updateErrors: Map(),
})();
export const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case UserActionType.CLEAR_USER_SESSION:
        case UserActionType.SELECT_LAB_PARENT_RESOURCE:
        case UserActionType.SELECT_LAB:
        case UserActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    switch (action.type) {
                        case UserActionType.LIST_USERS:
                            return listUsers(state, action);
                        case UserActionType.LIST_USERS_CANCELLED:
                            return listUsersCancelled(state, action);
                        case UserActionType.LIST_USERS_SUCCESS:
                            return listUsersSuccess(state, action);
                        case UserActionType.LIST_USERS_ERROR:
                            return listUsersError(state, action);
                        case UserActionType.DELETE_USERS:
                            return deleteUsers(state, action);
                        case UserActionType.DELETE_USERS_SUCCESS:
                            return deleteUsersSuccess(state, action);
                        case UserActionType.DELETE_USERS_ERROR:
                            return deleteUsersError(state, action);
                        case UserActionType.ADD_USERS:
                            return addUsers(state, action);
                        case UserActionType.ADD_USERS_SUCCESS:
                            return addUsersSuccess(state, action);
                        case UserActionType.ADD_USERS_ERROR:
                            return addUsersError(state, action);
                        case UserActionType.INVITE_USERS:
                            return inviteUsers(state, action);
                        case UserActionType.INVITE_USERS_SUCCESS:
                            return inviteUsersSuccess(state, action);
                        case UserActionType.INVITE_USERS_ERROR:
                            return inviteUsersError(state, action);
                        case UserActionType.CLEAR_LOAD_USERS_ERROR:
                            return clearLoadError(state, action);
                        case UserActionType.CLEAR_ADD_USERS_ERROR:
                            return clearAddUsersError(state, action);
                        case UserActionType.CLEAR_DELETE_USERS_ERROR:
                            return clearDeleteUsersError(state, action);
                        case UserActionType.CLEAR_INVITE_USERS_ERROR:
                            return clearInviteUsersError(state, action);
                        case UserActionType.UPDATE_ADDITIONAL_QUOTA:
                            return updateAdditionalQuota(state, action);
                        case UserActionType.UPDATE_ADDITIONAL_QUOTA_SUCCESS:
                            return updateAdditionalQuotaSuccess(state, action);
                        case UserActionType.UPDATE_ADDITIONAL_QUOTA_ERROR:
                            return updateAdditionalQuotaError(state, action);
                        case UserActionType.CLEAR_UPDATE_ADDITIONAL_QUOTA_ERROR:
                            return clearUpdateAdditionalQuotaError(state, action);
                        default:
                            return state;
                    }
                default:
                    return state;
            }
    }
};
export default userReducer;
