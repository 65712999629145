import memoize from 'memoize-one';
import { InitializationState } from '../state/common-store';
import { getCurrentLabAccountId } from './lab-parent-resource-selectors';
import { isHealthyState } from '../../utils/provisioning-state';
import { caseInsensitiveCultureInvariantCompare } from '../../utils/string-comparison';
export const getLabAccounts = memoize((state) => {
    const labAccountStore = state.get('labAccountStore');
    const labAccounts = labAccountStore.get('labAccounts');
    return labAccounts;
});
export const getHealthyLabAccounts = memoize((state) => {
    const labAccounts = getLabAccounts(state);
    return labAccounts.filter((o) => isHealthyState(o));
});
export const getLabAccount = memoize((store, labAccountId) => {
    if (!labAccountId) {
        return undefined;
    }
    const labAccounts = getHealthyLabAccounts(store);
    const labAccount = labAccounts.find((l) => caseInsensitiveCultureInvariantCompare(l.id, labAccountId) === 0);
    return labAccount;
});
export const getCurrentLabAccount = memoize((store) => {
    const currentLabAccountId = getCurrentLabAccountId(store);
    if (!currentLabAccountId) {
        return undefined;
    }
    return getLabAccount(store, currentLabAccountId);
});
export const doesCurrentLabAccountExist = memoize((store) => {
    return !!getCurrentLabAccount(store);
});
export const isValidSupportInfo = (info) => {
    return !!info && (!!info.url || !!info.email || !!info.phone || !!info.instructions);
};
export const getSupportInfoEmail = memoize((state) => {
    const labAccount = getCurrentLabAccount(state);
    return labAccount?.supportInfo?.email;
});
export const isRefreshingLabAccounts = memoize((state) => {
    const labAccountStore = state.get('labAccountStore');
    const refreshInitializationState = labAccountStore.get('refreshInitializationState');
    return refreshInitializationState === InitializationState.Initializing;
});
export const hasRefreshLabAccountsError = memoize((state) => {
    const subscriptionStore = state.get('subscriptionStore');
    const loadSubscriptionError = subscriptionStore.get('error');
    const labAccountStore = state.get('labAccountStore');
    const loadLabAccountLoadError = labAccountStore.get('errors');
    const refreshError = labAccountStore.get('refreshError');
    return !!loadSubscriptionError || loadLabAccountLoadError.size > 0 || !!refreshError;
});
export const hasRefreshedLabAccounts = memoize((state) => {
    const labAccountStore = state.get('labAccountStore');
    const refreshInitializationState = labAccountStore.get('refreshInitializationState');
    return refreshInitializationState === InitializationState.Success;
});
