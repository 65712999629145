import LowerCaseReservedPasswords from './reserved-passwords';
const lowerCaseTest = new RegExp('^(?=.*[a-z])');
const upperCaseTest = new RegExp('^(?=.*[A-Z])');
const numericTest = new RegExp('^(?=.*[0-9])');
const specialCharsTest = new RegExp('^(?=.*[!@#$%^&*])');
export function isPasswordComplex(password, requiredComplexity = 3) {
    let complexity = 0;
    const trimmedPassword = password ? password.trim() : '';
    if (lowerCaseTest.test(trimmedPassword)) {
        complexity++;
    }
    if (upperCaseTest.test(trimmedPassword)) {
        complexity++;
    }
    if (numericTest.test(trimmedPassword)) {
        complexity++;
    }
    if (specialCharsTest.test(trimmedPassword)) {
        complexity++;
    }
    return complexity >= requiredComplexity;
}
export function isPasswordReserved(password) {
    const lowerCasePassword = password ? password.trim().toLowerCase() : '';
    return LowerCaseReservedPasswords.indexOf(lowerCasePassword) > -1;
}
export function isPasswordLengthIncorrect(password, minLength, maxLength) {
    const trimmedPassword = password ? password.trim() : '';
    return trimmedPassword.length < minLength || trimmedPassword.length > maxLength;
}
