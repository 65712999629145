import { MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { FailureOperation, AzurePolicyViolation, getAzurePolicyViolationErrorMessage, } from '../data/ml-client-error';
import { commonMessages } from '../language/common-messages';
const LabErrorBannerInjected = (props) => {
    const { updateError, clearUpdateError, intl } = props;
    if (!updateError) {
        return null;
    }
    const msg = intl.formatMessage;
    /* there could be multiple policy violations, showing them in single error banner*/
    if (updateError.code == AzurePolicyViolation.CODE) {
        return (<>
                <MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearUpdateError()}>
                    {getAzurePolicyViolationErrorMessage(updateError).map((errorMessage, index) => (<div key={index}>{msg(errorMessage)}</div>))}
                </MessageBar>
            </>);
    }
    switch (updateError.failureOperation) {
        case FailureOperation.UpdateLabAccessMode:
            return (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearUpdateError()}>
                    <FormattedMessage id="LabUpdateErrorFailedToUpdateAccessMode" defaultMessage="An error occurred while updating the lab access mode." description="Message shown when an error occurs while updating the lab access mode."/>
                </MessageBar>);
        case FailureOperation.ScalingDownWhenVirtualMachinesAreRunning:
            return (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearUpdateError()}>
                    <FormattedMessage id="ScalingDownWhenVirtualMachinesAreRunning" defaultMessage="An error occurred while updating the lab capacity. You cannot decrease a lab's capacity when there are virtual machines running in the lab. Please shut down all virtual machines in the lab before attempting to reduce the lab's capacity." description="Message shown when an error occurs while updating the lab capacity when virtual machines are in running status."/>
                </MessageBar>);
        case FailureOperation.UpdateLabCapacity:
            return (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearUpdateError()}>
                    <FormattedMessage id="LabUpdateErrorFailedToUpdateCapacity" defaultMessage="An error occurred while updating the lab capacity." description="Message shown when an error occurs while updating the lab capacity."/>
                </MessageBar>);
        case FailureOperation.UpdateLabQuota:
            return (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearUpdateError()}>
                    <FormattedMessage id="LabUpdateErrorFailedToUpdateQuota" defaultMessage="An error occurred while updating the lab quota." description="Message shown when an error occurs while updating the lab quota."/>
                </MessageBar>);
        case FailureOperation.UpdateLabSettings:
            return (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearUpdateError()}>
                    <FormattedMessage id="SettingsPageUpdateError" defaultMessage="An error occurred while updating lab settings. Please refresh the page and try again." description="Message shown when an error occurs while updating lab settings."/>
                </MessageBar>);
        case FailureOperation.UpdateLab:
        default:
            return (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={msg(commonMessages.close)} onDismiss={() => clearUpdateError()}>
                    <FormattedMessage id="LabUpdateErrorFailed" defaultMessage="An error occurred while updating the lab." description="Generic error message shown when an error occurs while updating the lab."/>
                </MessageBar>);
    }
};
export const LabErrorBanner = injectIntl(LabErrorBannerInjected);
export default LabErrorBanner;
