import { Icon, ProgressIndicator, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ImageAndSizeInfo } from '../common/image-and-size-info';
import { getCustomTheme } from '../common/themes/selectors';
const PermissionPropagationView = (props) => {
    const { secondaryBackgroundColor } = getCustomTheme();
    return (<Stack horizontalAlign="center" grow verticalFill styles={{ root: { backgroundColor: secondaryBackgroundColor } }}>
            <Stack.Item>
                <Icon iconName="creating-template" styles={{ root: { marginTop: 140, marginBottom: 20 } }}/>
            </Stack.Item>
            <Stack.Item>
                <Stack horizontalAlign="start">
                    <Stack.Item>
                        <div className="ms-fontSize-m ms-fontWeight-semibold">
                            <FormattedMessage id="PermissionPropagationViewMessage" defaultMessage="Please do not refresh the page. We are propagating the permissions while we create the lab." description="Message shown when the permission is propagating."/>
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <ProgressIndicator barHeight={4} description={<>
                                    <FormattedMessage id="PermissionPropagationViewDescription" defaultMessage="If you refresh the page, you may experience a delay in accessing the newly created lab." description="Message shown below the progress bar when the permission is propagating."/>
                                    <br />
                                    <ImageAndSizeInfo {...props}/>
                                </>} styles={{
        root: { minWidth: 250, width: '100%', marginBottom: 15 },
    }}/>
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        </Stack>);
};
export default PermissionPropagationView;
