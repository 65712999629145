import { Record, List, Map } from 'immutable';
import _ from 'lodash';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { SharedImageActionType, } from '../actions/shared-image/shared-image-actions';
import { Context } from '../action-context';
function listSharedImages(state, action) {
    return state.merge({
        errors: Map(),
        loadState: LoadingStoreState.Loading,
    });
}
function listSharedImagesSuccess(state, action) {
    return state.merge({
        images: List(action.images),
        loadState: LoadingStoreState.Loaded,
    });
}
function listSharedImagesError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addError(errors, error, error.id);
    return state.merge({
        errors,
        loadState: LoadingStoreState.LoadingFailed,
        images: List([]),
    });
}
function getSharedGallerySuccess(state, action) {
    return state.merge({
        sharedGalleries: List(action.sharedGalleries),
    });
}
function createSharedImageError(state, action) {
    const { error } = action;
    let errors = state.get('errors');
    errors = addError(errors, error, error.id);
    return state.merge({
        errors,
    });
}
function addError(errorMap, error, id) {
    const errors = errorMap.get(id);
    if (errors) {
        const existingError = errors.some((_error) => _.isEqual(_error, error));
        if (!existingError) {
            return errorMap.set(id, errors.concat(error));
        }
    }
    else {
        return errorMap.set(id, List([error]));
    }
    return errorMap;
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    sharedGalleries: List(),
    images: List(),
    errors: Map(),
})();
export const sharedImageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SharedImageActionType.CLEAR_USER_SESSION:
        case SharedImageActionType.SELECT_LAB_PARENT_RESOURCE:
        case SharedImageActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    return state;
                default:
                    switch (action.type) {
                        case SharedImageActionType.LIST_SHARED_IMAGES:
                            return listSharedImages(state, action);
                        case SharedImageActionType.LIST_SHARED_IMAGES_SUCCESS:
                            return listSharedImagesSuccess(state, action);
                        case SharedImageActionType.LIST_SHARED_IMAGES_ERROR:
                            return listSharedImagesError(state, action);
                        case SharedImageActionType.GET_SHARED_GALLERY_SUCCESS:
                            return getSharedGallerySuccess(state, action);
                        case SharedImageActionType.CREATE_SHARED_IMAGE_ERROR:
                            return createSharedImageError(state, action);
                        default:
                            return state;
                    }
            }
    }
};
export default sharedImageReducer;
