import { call, put, select, takeLatest } from 'redux-saga/effects';
import LabProvider from '../../../data/providers/vnext/arm-api/lab-provider';
import { getVNextLab } from '../../selectors/vnext/lab-selectors';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { ResourceId } from '../../../utils/resource-id';
import { getLab, pollLabUntilTerminalStateStart, publishVNextLabError, clearPublishLabError, } from '../../actions/lab/lab-action-creators';
import { LabActionType } from '../../actions/lab/lab-actions';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { LabServicesModels } from 'lab-services';
import { OneMinuteInterval } from '../../../utils/constants';
import LabsApiLabProvider from '../../../data/providers/vnext/labs-api/lab-provider';
export function* publishVNextLab(action) {
    const { labId, capacity, shouldUpdateCapacity } = action;
    try {
        yield put(clearPublishLabError(labId));
        const resourceId = new ResourceId(labId, true);
        const lab = yield select(getVNextLab, labId);
        if (!lab) {
            yield put(publishVNextLabError(labId, new MlClientError('Lab was not found', labId, FailureOperation.PublishTemplate)));
            return;
        }
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        if (lab.state === LabServicesModels.LabState.Draft && shouldUpdateCapacity) {
            const accessToken = yield call(getLabsApiAccessTokenSaga);
            yield call(LabsApiLabProvider.publishLab, lab.location, resourceId, accessToken, locale, language, capacity);
            yield put(pollLabUntilTerminalStateStart(labId, OneMinuteInterval, OneMinuteInterval));
        }
        else {
            const accessToken = yield call(getArmAccessTokenSaga);
            yield call(LabProvider.publishLab, resourceId, accessToken, locale, language);
            // get lab to clear the pending state and get the latest lab state
            yield put(getLab(labId, true, true));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.PublishTemplate, e ? e.code : undefined);
        yield put(publishVNextLabError(labId, error));
    }
}
export function* publishVNextLabSaga() {
    yield takeLatest(LabActionType.PUBLISH_LAB, publishVNextLab);
}
