import { CommonActionType } from '../common/common-actions';
import { CommonLabParentResourceActionType } from '../lab-parent-resource/lab-parent-resource-actions';
export const LabAccountActionType = {
    ...CommonActionType,
    ...CommonLabParentResourceActionType,
    LIST_LAB_ACCOUNTS: 'LIST_LAB_ACCOUNTS',
    LIST_LAB_ACCOUNTS_SUCCESS: 'LIST_LAB_ACCOUNTS_SUCCESS',
    LIST_LAB_ACCOUNTS_ERROR: 'LIST_LAB_ACCOUNTS_ERROR',
    REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS: 'REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS',
    REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS_SUCCESS: 'REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS_SUCCESS',
    REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS_ERROR: 'REFRESH_SUBSCRIPTIONS_AND_LAB_ACCOUNTS_ERROR',
    GET_LAB_ACCOUNT: 'GET_LAB_ACCOUNT',
    GET_LAB_ACCOUNT_SUCCESS: 'GET_LAB_ACCOUNT_SUCCESS',
    GET_LAB_ACCOUNT_ERROR: 'GET_LAB_ACCOUNT_ERROR',
    LOAD_SUBSCRIPTION_LAB_ACCOUNTS_ERROR: 'LOAD_SUBSCRIPTION_LAB_ACCOUNTS_ERROR',
};
