import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { duration } from 'moment';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PageTitle } from '../common/page/page-title';
import { LabUpdateOperation } from '../data/models/lab';
import { clearLabUpdateError, updateLab, updateLabAccessMode, pollLabStart, pollLabStop, syncLabUsers, updateVNextLab, updateVNextLabAccessMode, } from '../redux/actions/lab/lab-action-creators';
import { getGroups as searchForGroups } from '../redux/actions/group/group-action-creators';
import { addUsers, clearAddUsersError, clearDeleteUsersError, clearInviteUsersError, clearLoadUsersError, clearUpdateAdditionalQuotaForUsersError, deleteUsers, inviteUsers, listUsers, pollUsersStart, pollUsersStop, updateAdditionalQuotaForUsers, } from '../redux/actions/user/user-action-creators';
import Constants from '../utils/constants';
import { UserList } from './user-list';
import { messages } from './user-list-messages';
import { routerActions } from 'connected-react-router';
import { InviteStatus, UserStatus } from './user-list-models';
import { getUserListContainerModel } from './user-list-selectors';
import { isVNextLab } from '../redux/selectors/lab-selectors';
function determinePollingIntervalLength(users) {
    if (users.some((user) => user.inviteStatus === InviteStatus.Sending)) {
        return Constants.FiveSecondInterval;
    }
    if (users.some((user) => user.status === UserStatus.Deleting)) {
        return Constants.TenSecondInterval;
    }
    return Constants.DefaultPollingInterval;
}
class UserListContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._setPollingInterval = (labId, pollingIntervalMs, initialDelayMs) => {
            this._pollingIntervalMs = pollingIntervalMs;
            this.props.pollUsersStart(labId, pollingIntervalMs, initialDelayMs);
        };
        this._updateLabAccessMode = (isRestricted) => {
            const { userListViewModel, updateLabAccessMode, updateVNextLabAccessMode } = this.props;
            const { lab } = userListViewModel;
            if (!lab) {
                return;
            }
            const isVNext = isVNextLab(lab.id);
            if (!isVNext) {
                const userAccessMode = isRestricted ? Ml.LabUserAccessMode.Restricted : Ml.LabUserAccessMode.Open;
                updateLabAccessMode(lab.id, userAccessMode);
            }
            else {
                updateVNextLabAccessMode(lab.id, !isRestricted);
            }
        };
        this._updateLabQuota = (quota) => {
            const { userListViewModel, updateLab, updateVNextLab } = this.props;
            const { lab } = userListViewModel;
            if (!lab) {
                return;
            }
            const isVNext = isVNextLab(lab.id);
            if (!isVNext) {
                const currentLab = lab;
                const usageQuota = duration(quota, 'hours');
                const labUpdate = { ...currentLab, usageQuota };
                updateLab(labUpdate, LabUpdateOperation.Quota);
            }
            else {
                const currentLab = lab;
                const virtualMachineProfile = {
                    ...currentLab.virtualMachineProfile,
                    usageQuota: duration(quota, 'hours').toISOString(),
                };
                updateVNextLab(currentLab.id, { virtualMachineProfile }, LabUpdateOperation.Quota);
            }
        };
        this._updateUserQuota = (userIds, quota) => {
            const { updateAdditionalQuotaForUsers } = this.props;
            updateAdditionalQuotaForUsers(userIds, quota);
        };
        this._addUsers = (emailAddresses) => {
            const { labId, addUsers } = this.props;
            addUsers(labId, emailAddresses);
        };
        this._syncFromGroup = (groupId, isDeletionExternallyManaged) => {
            const { userListViewModel, updateLab, updateVNextLab } = this.props;
            const { lab } = userListViewModel;
            if (!lab) {
                return;
            }
            const isVNext = isVNextLab(lab.id);
            if (!isVNext) {
                const currentLab = lab;
                const aadGroupId = groupId;
                const updateGroupId = { ...currentLab, aadGroupId, isDeletionExternallyManaged };
                updateLab(updateGroupId, LabUpdateOperation.SetGroup);
            }
            else {
                const currentLab = lab;
                const rosterProfile = { ...currentLab.rosterProfile, activeDirectoryGroupId: groupId };
                updateVNextLab(currentLab.id, { rosterProfile }, LabUpdateOperation.SetGroup);
            }
        };
        this._searchForGroups = (filterText) => {
            const { searchForGroups } = this.props;
            searchForGroups(filterText);
        };
        this._inviteUsers = (emailAddresses, extraMessage) => {
            const { labId, inviteUsers } = this.props;
            inviteUsers(labId, emailAddresses, extraMessage);
        };
        this._deleteUsers = (userIds) => {
            const { deleteUsers } = this.props;
            deleteUsers(userIds);
        };
        this._syncUsers = () => {
            const { labId, syncLabUsers } = this.props;
            syncLabUsers(labId);
        };
        this._navigateRoute = this._navigateRoute.bind(this);
    }
    componentDidMount() {
        const { isVNext, userListViewModel } = this.props;
        const { lab, users, isGroupSyncModeEnabled, isSyncing } = userListViewModel;
        if (lab) {
            const nextPollingInterval = determinePollingIntervalLength(users);
            this._setPollingInterval(lab.id, nextPollingInterval);
            if (!isVNext && isGroupSyncModeEnabled && isSyncing) {
                this.props.pollLabStart(lab.id, Constants.OneMinuteInterval, Constants.DefaultPollingDelayInterval);
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { isVNext, userListViewModel } = this.props;
        const { lab, users, isGroupSyncModeEnabled, isSyncing } = userListViewModel;
        if (lab) {
            const nextPollingIntervalMs = determinePollingIntervalLength(users);
            if (!prevProps.userListViewModel.lab || prevProps.userListViewModel.lab.id !== lab.id) {
                this.props.pollUsersStop();
                this._setPollingInterval(lab.id, nextPollingIntervalMs);
            }
            else if (this._pollingIntervalMs !== nextPollingIntervalMs) {
                this.props.pollUsersStop();
                this._setPollingInterval(lab.id, nextPollingIntervalMs, Constants.DefaultPollingDelayInterval);
            }
            if (isGroupSyncModeEnabled && !isVNext) {
                if (!prevProps.userListViewModel.isSyncing && isSyncing) {
                    this.props.pollLabStart(lab.id, Constants.OneMinuteInterval, Constants.DefaultPollingDelayInterval);
                }
                else if (prevProps.userListViewModel.isSyncing && !isSyncing) {
                    this.props.pollLabStop();
                }
            }
        }
    }
    componentWillUnmount() {
        const { isVNext, pollUsersStop, pollLabStop } = this.props;
        pollUsersStop();
        if (!isVNext) {
            pollLabStop();
        }
    }
    _navigateRoute(pathname) {
        const { push, search } = this.props;
        push({ pathname, search });
    }
    render() {
        const { userListViewModel, clearLabUpdateError, clearLoadUsersError, clearAddUsersError, clearDeleteUsersError, clearInviteUsersError, clearUpdateAdditionalQuotaForUsersError, intl, } = this.props;
        const pageTitle = intl.formatMessage(messages.pageTitle);
        return (<>
                <PageTitle subtitle={pageTitle}/>
                <UserList userListViewModel={userListViewModel} clearAddUsersError={clearAddUsersError} clearDeleteUsersError={clearDeleteUsersError} clearInviteUsersError={clearInviteUsersError} clearLabUpdateError={clearLabUpdateError} clearLoadUsersError={clearLoadUsersError} clearUpdateAdditionalQuotaForUsersError={clearUpdateAdditionalQuotaForUsersError} updateAccessMode={this._updateLabAccessMode} updateQuota={this._updateLabQuota} updateUserQuota={this._updateUserQuota} addUsers={this._addUsers} syncFromGroup={this._syncFromGroup} searchForGroups={this._searchForGroups} inviteUsers={this._inviteUsers} deleteUsers={this._deleteUsers} navigateRoute={this._navigateRoute} syncUsers={this._syncUsers}/>
            </>);
    }
}
const mapStateToProps = (state) => {
    return getUserListContainerModel(state);
};
const mapDispatchToProps = {
    updateLab,
    updateVNextLab,
    updateLabAccessMode,
    updateVNextLabAccessMode,
    clearLabUpdateError,
    listUsers,
    addUsers,
    inviteUsers,
    deleteUsers,
    clearLoadUsersError,
    clearAddUsersError,
    clearDeleteUsersError,
    clearInviteUsersError,
    pollUsersStart,
    pollUsersStop,
    updateAdditionalQuotaForUsers,
    clearUpdateAdditionalQuotaForUsersError,
    push: routerActions.push,
    syncLabUsers,
    searchForGroups,
    pollLabStart,
    pollLabStop,
};
export const UserListContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(UserListContainerInjected));
export default UserListContainer;
