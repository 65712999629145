export const vNextLabOrLabPlanIdLength = 8;
const vCurrentLabIdLength = 10;
export class ResourceId {
    constructor(resourceId, isVNextLabOrLabPlan) {
        if (!resourceId) {
            return;
        }
        this.id = resourceId;
        const parts = this.id.split('/').filter((n) => n !== '');
        if (parts.length > 2 && parts[2].toLowerCase() === 'resourcegroups') {
            this.subscriptionId = parts[1];
            this.resourceGroupName = parts[3];
            this.providerNamespace = parts[5];
            this.type = parts[parts.length - 2];
            this.name = parts[parts.length - 1];
        }
        // Set parent.
        if (!isVNextLabOrLabPlan && parts.length >= vCurrentLabIdLength) {
            this.parent = new ResourceId('/' + parts.slice(0, parts.length - 2).join('/'));
        }
        else if (isVNextLabOrLabPlan && parts.length >= vNextLabOrLabPlanIdLength) {
            this.parent = new ResourceId('/' + parts.slice(0, parts.length - 4).join('/'));
        }
        // Set ancestors.
        this.ancestors = [];
        let current = this.parent;
        while (!!current) {
            this.ancestors.push(current);
            current = current.parent;
        }
        this.ancestors = this.ancestors.reverse();
        // Set full resource type.
        this.fullResourceType = this.providerNamespace;
        for (let i = 0; i < this.ancestors.length; i++) {
            this.fullResourceType += '/' + this.ancestors[i].type;
        }
        this.fullResourceType += '/' + this.type;
    }
    static buildLabId(subscriptionId, resourceGroupName, labAccountName, labName) {
        return (`/subscriptions/${subscriptionId}` +
            `/resourcegroups/${resourceGroupName}` +
            `/providers/microsoft.labservices` +
            `/labaccounts/${labAccountName}` +
            `/labs/${labName}`);
    }
    static buildTemplateId(labId, name) {
        return `${labId}/environmentsettings/${name}`;
    }
    getResourceGroupId() {
        if (!this.subscriptionId || !this.resourceGroupName) {
            return undefined;
        }
        return `/subscriptions/${this.subscriptionId}` + `/resourcegroups/${this.resourceGroupName}`;
    }
    isValidResourceId() {
        return !!this.subscriptionId && !!this.resourceGroupName;
    }
}
export function isValidResourceId(id) {
    const resourceId = new ResourceId(id);
    return resourceId.isValidResourceId();
}
