import { call, put, takeLatest, select, all } from 'redux-saga/effects';
import { LabAccountActionType } from '../../actions/lab-account/lab-account-actions';
import { listSubscriptionLabAccounts } from '../../../data/providers/lab-account-provider';
import { loadSubscriptionLabAccountsError, listLabAccountsSuccess, listLabAccountsError, } from '../../actions/lab-account/lab-account-action-creators';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getSubscriptions } from '../../selectors/subscription-selector';
import { isHealthyState } from '../../../utils/provisioning-state';
import { compareByName } from '../../../utils/common';
import { getCurrentLabAccountId } from '../../selectors/lab-parent-resource-selectors';
import { selectLabParentResource } from '../../actions/lab-parent-resource/lab-parent-resource-action-creators';
export function getFirstValidLabAccount(labAccounts) {
    const validLabAccounts = labAccounts.filter((o) => isHealthyState(o)).sort(compareByName);
    const labAccount = validLabAccounts.length > 0 ? validLabAccounts[0] : undefined;
    return labAccount;
}
export function* loadSubscriptionLabAccounts(subscription) {
    let labAccounts = [];
    const accessToken = yield call(getArmAccessTokenSaga);
    const locale = yield select(getLocale);
    const language = yield select(getLanguage);
    try {
        labAccounts = yield call(listSubscriptionLabAccounts, subscription, accessToken, locale, language);
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, subscription.subscriptionId, FailureOperation.ListLabAccountsForSubscription, err ? err.code : undefined);
        yield put(loadSubscriptionLabAccountsError(subscription, error));
    }
    return labAccounts;
}
export function* listLabAccounts(action) {
    try {
        const { selectFirst, selectFirstIfSelectedNotFound } = action;
        const subscriptions = yield select(getSubscriptions);
        const loadLabAccountsCalls = subscriptions.toArray().map((subscription) => {
            return call(loadSubscriptionLabAccounts, subscription);
        });
        const labAccountsResults = yield all(loadLabAccountsCalls);
        let labAccounts = [];
        labAccountsResults?.forEach((value) => {
            labAccounts = [...labAccounts, ...value];
        });
        // select a lab account if required (must happen before success otherwise there is a race condition)
        if (selectFirst) {
            const firstLabAccount = yield call(getFirstValidLabAccount, labAccounts);
            if (firstLabAccount && firstLabAccount.id) {
                yield put(selectLabParentResource(firstLabAccount.id));
            }
        }
        else if (selectFirstIfSelectedNotFound) {
            // This is used to fallback to the first lab account if the one restored from
            // user settings is no longer being returned. Otherwise, the user would see a resource
            // not found page.
            const selectedLabAccountId = yield select(getCurrentLabAccountId);
            if (labAccounts.findIndex((la) => la.id === selectedLabAccountId) < 0) {
                const firstLabAccount = yield call(getFirstValidLabAccount, labAccounts);
                if (firstLabAccount && firstLabAccount.id) {
                    yield put(selectLabParentResource(firstLabAccount.id));
                }
            }
        }
        yield put(listLabAccountsSuccess(labAccounts));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, 'all-subscriptions', FailureOperation.ListLabAccounts, err ? err.code : undefined);
        yield put(listLabAccountsError(error));
    }
}
export function* listLabAccountsSaga() {
    yield takeLatest(LabAccountActionType.LIST_LAB_ACCOUNTS, listLabAccounts);
}
