import { DefaultButton, Stack } from '@fluentui/react';
import moment from 'moment';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { findOneIana } from 'windows-iana';
import { ManagedLabsModels } from '@azure-lab-services/ml-ts';
import { LabServicesModels } from 'lab-services';
import SubmitButton from '../common/submit-button';
import commonMessages from '../language/common-messages';
import { ScheduleType, RecurrenceType } from './flyout';
import { adjustDatesForSchedule, adjustDatesForVNextSchedule, getDefaultWeeklySchedule, getDefaultVNextWeeklySchedule, estimatedClassLength, getBrowserOffset, } from './flyout-utilities';
import { FlyoutMode } from './schedule';
import './schedule.css';
const FlyoutFooterInjected = (props) => {
    const save = props.intl.formatMessage(commonMessages.save);
    const discard = props.intl.formatMessage(commonMessages.discard);
    const { form, formValues, scheduleType, mode, schedule, isCurrentLabParentLabAccount, createSchedule, updateSchedule, isSaving, onClose, } = props;
    const onClick = () => onSave(formValues, scheduleType, mode, schedule, isCurrentLabParentLabAccount, createSchedule, updateSchedule);
    return (<>
            <Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '8px' }}>
                <Stack.Item>
                    <SubmitButton buttonText={save} isSubmitting={isSaving} disabled={hasErrors(form) || isSaving} onClick={onClick}/>
                </Stack.Item>
                <Stack.Item>
                    <DefaultButton disabled={isSaving} onClick={isSaving ? undefined : onClose}>
                        {discard}
                    </DefaultButton>
                </Stack.Item>
            </Stack>
        </>);
};
export const FlyoutFooter = injectIntl(FlyoutFooterInjected);
function hasErrors(form) {
    return form.getState().hasValidationErrors;
}
function hydrateCommonPropertiesForSchedule(schedule, startDate, startHoursKey, stopHoursKey, isRecurring, endDate, days, timeZoneId, notes) {
    schedule.start = startDate;
    schedule.end = startDate;
    const startHours = String(startHoursKey).substring(0, 2);
    const startMinutes = String(startHoursKey).substring(2, 4);
    const stopHours = String(stopHoursKey).substring(0, 2);
    const stopMinutes = String(stopHoursKey).substring(2, 4);
    schedule.start = new Date(schedule.start);
    schedule.end = new Date(schedule.end);
    schedule.start.setHours(+startHours, +startMinutes, 0, 0);
    schedule.end.setHours(+stopHours, +stopMinutes, 0, 0);
    const isTodayDST = moment(new Date()).isDST();
    const isScheduleDST = moment(schedule.start).isDST();
    adjustScheduleForDST(isTodayDST, isScheduleDST, schedule.start, schedule.end);
    schedule.notes = notes;
    schedule.timeZoneId = timeZoneId;
    schedule.startAction.enableState = ManagedLabsModels.EnableState.Enabled;
    schedule.endAction.enableState = ManagedLabsModels.EnableState.Enabled;
    if (isRecurring) {
        if (!schedule.recurrencePattern) {
            schedule.recurrencePattern = {
                frequency: ManagedLabsModels.RecurrenceFrequency.Weekly,
            };
        }
        if (endDate) {
            schedule.recurrencePattern.until = endDate;
            schedule.recurrencePattern.until.setHours(23, 59, 59);
        }
        else {
            schedule.recurrencePattern.until = undefined;
        }
        schedule.recurrencePattern.weekDays = days;
    }
    else {
        if (schedule.recurrencePattern) {
            schedule.recurrencePattern = undefined;
        }
    }
    return schedule;
}
function hydrateCommonPropertiesForVNextSchedule(schedule, startDate, startHoursKey, stopHoursKey, isRecurring, endDate, days, timeZoneId, notes) {
    schedule.startAt = startDate;
    schedule.stopAt = startDate;
    const startHours = String(startHoursKey).substring(0, 2);
    const startMinutes = String(startHoursKey).substring(2, 4);
    const stopHours = String(stopHoursKey).substring(0, 2);
    const stopMinutes = String(stopHoursKey).substring(2, 4);
    schedule.startAt = new Date(schedule.startAt);
    schedule.stopAt = new Date(schedule.stopAt);
    schedule.startAt.setHours(+startHours, +startMinutes, 0, 0);
    schedule.stopAt.setHours(+stopHours, +stopMinutes, 0, 0);
    const isTodayDST = moment(new Date()).isDST();
    const isScheduleDST = moment(schedule.startAt).isDST();
    adjustScheduleForDST(isTodayDST, isScheduleDST, schedule.startAt, schedule.stopAt);
    schedule.notes = notes;
    schedule.timeZoneId = convertWindowsTimezoneToIana(timeZoneId);
    if (isRecurring) {
        endDate.setHours(23, 59, 59);
        if (!schedule.recurrencePattern) {
            schedule.recurrencePattern = {
                frequency: LabServicesModels.RecurrenceFrequency.Weekly,
                expirationDate: endDate,
            };
        }
        else {
            schedule.recurrencePattern.expirationDate = endDate;
        }
        schedule.recurrencePattern.weekDays = days;
    }
    else {
        if (schedule.recurrencePattern) {
            schedule.recurrencePattern = null;
        }
    }
    return schedule;
}
function onSave(values, scheduleType, mode, schedule, isCurrentLabParentLabAccount, createSchedule, updateSchedule) {
    if (isCurrentLabParentLabAccount) {
        saveSchedule(values, scheduleType, mode, schedule, createSchedule, updateSchedule);
    }
    else {
        saveVNextSchedule(values, scheduleType, mode, schedule, createSchedule, updateSchedule);
    }
}
function saveSchedule(values, scheduleType, mode, schedule, createSchedule, updateSchedule) {
    schedule = mode === FlyoutMode.add ? getDefaultWeeklySchedule(values.timezonePicker) : schedule;
    schedule = hydrateCommonPropertiesForSchedule(schedule, values.startDatePicker, values.startTimePicker, values.stopTimePicker, values.recurrencePicker === RecurrenceType.weekly ? true : false, values.endDatePicker, values.dayPicker, values.timezonePicker, values.notes);
    if (scheduleType === ScheduleType.startOnly) {
        schedule.endAction.enableState = ManagedLabsModels.EnableState.Disabled;
        adjustTimesForStartOnlySchedule(schedule);
    }
    else if (scheduleType === ScheduleType.stopOnly) {
        schedule.startAction.enableState = ManagedLabsModels.EnableState.Disabled;
        adjustTimesForStopOnlySchedule(schedule);
    }
    const offset = getBrowserOffset();
    const adjustedSchedule = adjustDatesForSchedule(offset, schedule);
    if (mode === FlyoutMode.edit) {
        updateSchedule(adjustedSchedule);
    }
    else {
        createSchedule(adjustedSchedule);
    }
}
function saveVNextSchedule(values, scheduleType, mode, schedule, createSchedule, updateSchedule) {
    schedule = mode === FlyoutMode.add ? getDefaultVNextWeeklySchedule(values.timezonePicker) : schedule;
    schedule = hydrateCommonPropertiesForVNextSchedule(schedule, values.startDatePicker, values.startTimePicker, values.stopTimePicker, values.recurrencePicker === RecurrenceType.weekly ? true : false, values.endDatePicker, values.dayPicker, values.timezonePicker, values.notes);
    if (scheduleType === ScheduleType.stopOnly) {
        schedule.startAt = null;
    }
    const offset = getBrowserOffset();
    const adjustedSchedule = adjustDatesForVNextSchedule(offset, schedule);
    if (mode === FlyoutMode.edit) {
        updateSchedule(adjustedSchedule);
    }
    else {
        createSchedule(adjustedSchedule);
    }
}
function convertWindowsTimezoneToIana(id) {
    return findOneIana(id);
}
function adjustTimesForStopOnlySchedule(schedule) {
    if (schedule.end.getHours() == 0 && schedule.end.getMinutes() == 0) {
        schedule.start.setHours(0, 0, 0);
        schedule.start.setDate(schedule.start.getDate() - 1);
    }
    else if (schedule.end.getHours() < estimatedClassLength) {
        schedule.start.setHours(0, 0, 0);
    }
    else {
        schedule.start.setHours(schedule.end.getHours() - estimatedClassLength, schedule.end.getMinutes());
    }
}
function adjustTimesForStartOnlySchedule(schedule) {
    if (schedule.start.getHours() == 23 && schedule.start.getMinutes() == 59) {
        schedule.end.setHours(23, 59);
        schedule.end.setDate(schedule.end.getDate() + 1);
    }
    else if (schedule.start.getHours() >= 22) {
        schedule.end.setHours(23, 59);
    }
    else {
        schedule.end.setHours(schedule.start.getHours() + estimatedClassLength, schedule.start.getMinutes());
    }
}
export function adjustScheduleForDST(isTodayDST, isScheduleDST, start, end) {
    if (isTodayDST && !isScheduleDST) {
        start.setHours(start.getHours() - 1);
        end.setHours(end.getHours() - 1);
    }
    else if (!isTodayDST && isScheduleDST) {
        start.setHours(start.getHours() + 1);
        end.setHours(end.getHours() + 1);
    }
}
export default FlyoutFooter;
