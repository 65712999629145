import { ChoiceGroup, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from './create-lab-messages';
import { CreateLabTimeEstimate } from '../../common/create-lab-time-estimate';
export const VmTemplateSettingsPage = (props) => {
    const { isSubmitting, onIsTemplateVmEnabledChange } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const VmImageWithCustomization = 'createTemplateVm';
    const VmImageWithoutCustomization = 'useVmImageWithoutCustomization';
    const [selectedChoice, setSelectedChoice] = React.useState('createTemplateVm');
    const [timeEstimateMessage, setTimeEstimateMessage] = React.useState(msg(messages.customTemplateVmPageTimeEstimate));
    const optionsToCustomizeVmCreation = [
        { key: VmImageWithCustomization, text: msg(messages.createTemplateVmOption) },
        { key: VmImageWithoutCustomization, text: msg(messages.useVmImageWithoutCustomization) },
    ];
    const onSelectedChoiceChange = (selectedChoice) => {
        setSelectedChoice(selectedChoice);
        onIsTemplateVmEnabledChange(selectedChoice == VmImageWithCustomization);
        if (selectedChoice == VmImageWithoutCustomization) {
            setTimeEstimateMessage(msg(messages.noTemplateVmPageTimeEstimate));
        }
        else {
            setTimeEstimateMessage(msg(messages.customTemplateVmPageTimeEstimate));
        }
    };
    return (<Stack tokens={{ childrenGap: 20 }} style={{ height: '100%' }}>
            <Stack.Item>
                <FormattedMessage {...messages.templateVmSettingsPageMainMessage}/>
            </Stack.Item>
            <Stack.Item style={{ marginBottom: 10 }}>
                <ChoiceGroup selectedKey={selectedChoice} options={optionsToCustomizeVmCreation} onChange={(_event, option) => onSelectedChoiceChange(option.key)} disabled={isSubmitting}/>
            </Stack.Item>
            <Stack.Item styles={{ root: { height: '100%' } }}>
                <CreateLabTimeEstimate timeEstimateMessage={timeEstimateMessage}/>
            </Stack.Item>
        </Stack>);
};
export default VmTemplateSettingsPage;
