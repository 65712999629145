export const UserTeamRole = {
    Unknown: '',
    Admin: 'Admin',
    User: 'User',
    Guest: 'Guest',
};
// We can't use UserTeamRole from teams directly because this type of enum
// is not usable beyond being a type when using isolated modules. Instead,
// we have to convert it to one of our constant types.
export function convertUserTeamRole(role) {
    switch (role) {
        case 0:
            return UserTeamRole.Admin;
        case 1:
            return UserTeamRole.User;
        case 2:
            return UserTeamRole.Guest;
        default:
            return UserTeamRole.Unknown;
    }
}
export const ChannelType = {
    Unknown: '',
    Regular: 'Regular',
    Private: 'Private',
};
export function convertChannelType(channelType) {
    switch (channelType) {
        case ChannelType.Regular:
            return ChannelType.Regular;
        case ChannelType.Private:
            return ChannelType.Private;
        default:
            return ChannelType.Unknown;
    }
}
export const TeamsTheme = {
    Unknown: '',
    Default: 'default',
    Dark: 'dark',
    Contrast: 'contrast',
};
export function convertTeamsTheme(teamsTheme) {
    switch (teamsTheme) {
        case TeamsTheme.Dark:
            return TeamsTheme.Dark;
        case TeamsTheme.Contrast:
            return TeamsTheme.Contrast;
        case TeamsTheme.Default:
            return TeamsTheme.Default;
        default:
            return TeamsTheme.Unknown;
    }
}
