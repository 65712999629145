import { DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { Form } from 'react-final-form';
import { defineMessages, useIntl } from 'react-intl';
import commonMessages from '../language/common-messages';
import Constants from '../utils/constants';
import templateMessages from './template-messages';
import { PublishDialogContent } from './publish-template-dialog-content';
import { determineVCurrentOrVNextLabRemainingVms } from '../redux/selectors/core-limit-selectors';
import { getLabCapacity, isVNextLab } from '../redux/selectors/lab-selectors';
import './publish-template-dialog.css';
const messages = defineMessages({
    publishTemplateTitle: {
        id: 'PublishTemplateTitle',
        defaultMessage: 'Publish template',
        description: 'Title for publish template dialog',
    },
});
export const PublishDialogFooter = (props) => {
    const intl = useIntl();
    const { isDisabled, onSubmit, onDismiss } = props;
    const publish = intl.formatMessage(templateMessages.publishTemplate);
    const cancel = intl.formatMessage(commonMessages.cancel);
    return (<DialogFooter styles={{ actions: { marginTop: '32px' } }}>
            <PrimaryButton disabled={isDisabled} text={publish} ariaLabel={publish} onClick={onSubmit}/>
            <DefaultButton onClick={onDismiss} ariaLabel={cancel} text={cancel}/>
        </DialogFooter>);
};
export const PublishTemplateDialog = (props) => {
    const intl = useIntl();
    const { lab, isGpu, coresPerVm, coreQuotaData, templateId, onDismiss, hasBeenPublished, publishTemplate, currentTenantId, isGroupSyncModeEnabled, groupName, } = props;
    const msg = intl.formatMessage;
    const initialLabCapacity = getLabCapacity(lab) ?? 1;
    const isVNext = isVNextLab(lab.id);
    const remainingVmsInSubscription = determineVCurrentOrVNextLabRemainingVms(isVNext, isGpu, coresPerVm, coreQuotaData, getLabCapacity(lab));
    const labMaxCapacity = !isVNext ? lab.userQuota : undefined;
    const onDismissed = () => {
        onDismiss();
    };
    return (<Dialog onDismiss={onDismissed} hidden={false} dialogContentProps={{
        type: DialogType.normal,
        title: msg(messages.publishTemplateTitle),
    }} modalProps={{
        onDismissed: onDismissed,
    }} minWidth={Constants.DefaultDialogWideWidth}>
            <Form initialValues={{ capacity: initialLabCapacity }} onSubmit={(values) => {
        publishTemplate(templateId, values.capacity);
        onDismiss();
    }}>
                {(formProps) => (<>
                        <PublishDialogContent remainingVmsInSubscription={remainingVmsInSubscription} hasBeenPublished={hasBeenPublished} labCapacity={formProps.values.capacity} labMaxCapacity={labMaxCapacity} labId={lab.id} hasTemplate={!!templateId} currentTenantId={currentTenantId} isGroupSyncModeEnabled={isGroupSyncModeEnabled} groupName={groupName}/>
                        <PublishDialogFooter isDisabled={!formProps.valid} onDismiss={onDismissed} onSubmit={() => formProps.handleSubmit()}/>
                    </>)}
            </Form>
        </Dialog>);
};
export default PublishTemplateDialog;
