export const LabUpdateOperation = {
    Capacity: 'Capacity',
    Quota: 'Quota',
    AccessMode: 'AccessMode',
    ContactEmail: 'ContactEmail',
    DismissGpuWarning: 'DismissGpuWarning',
    Settings: 'Settings',
    Update: 'Update',
    SetGroup: 'SetGroup',
    TitleOrDesciption: 'TitleOrDesciption',
};
export const LabAccessType = {
    writeAccess: 'writeAccess',
    readAndWriteAccess: 'readAndWriteAccess',
};
