import { put, race, select, take } from 'redux-saga/effects';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { getVNextLab } from '../../selectors/vnext/lab-selectors';
import { LabUpdateOperation } from '../../../data/models/lab';
import { updateLab, updateLabAccessModeError, updateLabAccessModeSuccess, updateVNextLab, } from '../../actions/lab/lab-action-creators';
import { LabActionType, } from '../../actions/lab/lab-actions';
import { getVCurrentLab } from '../../selectors/lab-selectors';
import { toEnableState } from '../../../data/helpers/enum-helpers';
export function* updateLabAccessMode(action) {
    const { labId, userAccessMode } = action;
    try {
        const lab = yield select(getVCurrentLab, labId);
        if (!lab) {
            yield put(updateLabAccessModeError(labId));
        }
        else {
            const labUpdate = { ...lab, userAccessMode };
            yield put(updateLab(labUpdate, LabUpdateOperation.AccessMode));
            const { success } = yield race({
                success: take(LabActionType.UPDATE_LAB_SUCCESS),
                error: take(LabActionType.UPDATE_LAB_ERROR),
            });
            if (success) {
                yield put(updateLabAccessModeSuccess());
            }
            else {
                yield put(updateLabAccessModeError(labId));
            }
        }
    }
    catch (error) {
        yield put(updateLabAccessModeError(labId));
    }
}
export function* updateVNextLabAccessMode(action) {
    const { labId, openAccess } = action;
    try {
        const lab = yield select(getVNextLab, labId);
        if (!lab) {
            yield put(updateLabAccessModeError(labId));
        }
        else {
            const securityProfile = { ...lab.securityProfile, openAccess: toEnableState(openAccess) };
            const labUpdateData = { securityProfile };
            yield put(updateVNextLab(labId, labUpdateData, LabUpdateOperation.AccessMode));
            const { success } = yield race({
                success: take(LabActionType.UPDATE_LAB_SUCCESS),
                error: take(LabActionType.UPDATE_LAB_ERROR),
            });
            if (success) {
                yield put(updateLabAccessModeSuccess());
            }
            else {
                yield put(updateLabAccessModeError(labId));
            }
        }
    }
    catch (error) {
        yield put(updateLabAccessModeError(labId));
    }
}
export function* updateLabAccessModeSaga() {
    yield labParentBasedTakeLatest(LabActionType.UPDATE_LAB_ACCESS_MODE, updateLabAccessMode, updateVNextLabAccessMode);
}
