import memoize from 'memoize-one';
import { matchPath } from 'react-router';
import { ResourceId } from '../../utils/resource-id';
import { AllRoutes, LabParentRoutes, LabIdRoutePrefix, LabRoutes, MfaRoutes, RoutePathToRouteNameMap, Routes, VNextLabParentRoutes, } from '../../utils/routes';
import { isVNextFeatureEnabledOrLms } from './common-selectors';
import { getCurrentReturnUrl } from './identity-selector';
import { isVNextLab } from './lab-selectors';
export const getLocation = memoize((state) => {
    const router = state.get('router');
    const location = router.get('location');
    return location;
});
export const getRoute = memoize((state) => {
    const location = getLocation(state);
    const pathname = location.get('pathname');
    const registrationRoute = matchPath(pathname, {
        path: Routes.Register(),
        exact: true,
        strict: false,
    });
    if (registrationRoute && registrationRoute.params.registrationCode) {
        return `/register/${registrationRoute.params.registrationCode}`;
    }
    else {
        return pathname ? pathname.toLowerCase() : '';
    }
});
export const getSearch = memoize((state) => {
    const location = getLocation(state);
    const search = location.get('search');
    return search;
});
export const getHash = memoize((state) => {
    const location = getLocation(state);
    const hash = location.get('hash');
    return hash;
});
export const getReturnUrl = memoize((state) => {
    const returnUrlPath = getRoute(state);
    const returnUrlSearch = getSearch(state);
    let path = Routes.Home;
    switch (returnUrlPath) {
        case Routes.SignInReturn:
            path = getCurrentReturnUrl(state);
            if (returnUrlSearch.length > 0 &&
                path.length >= returnUrlSearch.length &&
                path.indexOf(returnUrlSearch.substring(1)) > -1) {
                // if the query string was saved in storage as a part of returnUrl in the previous signInRedirecting, we skip adding search again.
                return path;
            }
            else {
                break;
            }
        case Routes.LoggedOut:
        case '/':
        case '':
        case null:
        case undefined:
            break;
        default:
            path = returnUrlPath;
            break;
    }
    const returnUrl = `${path}${returnUrlSearch}`;
    return returnUrl;
});
export const getLabParentIdFromRoute = memoize((state) => {
    const route = getRoute(state);
    const isVNext = isVNextFeatureEnabledOrLms(state);
    const vNextLabParentMatch = matchPath(route, {
        path: VNextLabParentRoutes,
        exact: true,
        strict: false,
    });
    const labParentMatch = matchPath(route, {
        path: LabParentRoutes,
        exact: true,
        strict: false,
    });
    if (isVNext && vNextLabParentMatch && vNextLabParentMatch.params.labParentId) {
        const parentId = `/${vNextLabParentMatch.params.labParentId}`;
        return parentId;
    }
    else if (labParentMatch && labParentMatch.params.labParentId) {
        const parentId = `/${labParentMatch.params.labParentId}`;
        return parentId;
    }
    const labId = getLabIdFromRoute(state);
    if (labId) {
        const isVNextLabId = isVNextLab(labId);
        const labResource = new ResourceId(labId, isVNextLabId);
        const parentId = labResource && labResource.parent ? labResource.parent.id : undefined;
        return !parentId ? undefined : parentId;
    }
    return undefined;
});
export const getLabIdFromRoute = memoize((state) => {
    const route = getRoute(state);
    const labIdMatch = matchPath(route, {
        path: LabRoutes,
        exact: true,
        strict: false,
    });
    if (labIdMatch && labIdMatch.params.labId) {
        return `/${labIdMatch.params.labId}`;
    }
    // this handles the case where a user navigates to a lab directly but not to a particular page
    let labMatch = matchPath(route, {
        path: Routes.Lab(),
        exact: true,
        strict: false,
    });
    if (isVNextFeatureEnabledOrLms(state) && !labMatch) {
        labMatch = matchPath(route, { path: Routes.VNextLab(), exact: true, strict: false });
    }
    if (labMatch && labMatch.url) {
        return labMatch.url;
    }
    return undefined;
});
export const getLabRoute = memoize((state) => {
    const route = getRoute(state);
    const labIdMatch = matchPath(route, {
        path: LabRoutes,
        exact: true,
        strict: false,
    });
    if (labIdMatch && labIdMatch.path) {
        return labIdMatch.path.replace(LabIdRoutePrefix, '');
    }
    return undefined;
});
const UnknownRouteName = 'Unknown';
export const getRouteName = memoize((state) => {
    const route = getRoute(state);
    const routeMatch = matchPath(route, {
        path: AllRoutes,
    });
    if (routeMatch && routeMatch.path) {
        const routeName = RoutePathToRouteNameMap[routeMatch.path];
        return routeName || UnknownRouteName;
    }
    return UnknownRouteName;
});
export const isRouteTeamsConfig = memoize((state) => {
    return getRoute(state) === Routes.TeamsConfig;
});
const isMfaRouteMatch = (route) => {
    const mfaMatch = matchPath(route, {
        path: MfaRoutes,
    });
    return !!mfaMatch && mfaMatch.isExact;
};
export const isMfaReturn = memoize((state) => {
    const returnUrl = getCurrentReturnUrl(state);
    const route = !!returnUrl ? returnUrl.split('?')[0] : '';
    return isMfaRouteMatch(route);
});
export const isMfaRouteOrRedirect = memoize((state) => {
    const route = getRoute(state);
    if (route === Routes.SignInReturn) {
        return isMfaReturn(state);
    }
    return isMfaRouteMatch(route);
});
export const isLtiOidcRoute = memoize((state) => {
    return getRoute(state) === Routes.LtiStartOidc;
});
export const isLtiReturnOidc = memoize((state) => {
    return getRoute(state) === Routes.LtiReturnOidc;
});
export const isRegisterRoute = memoize((state) => {
    const route = getRoute(state);
    const registerRoute = matchPath(route, {
        path: Routes.Register(),
    });
    return !!registerRoute && registerRoute.isExact;
});
