import { Link } from '@fluentui/react';
import { toASCII } from 'punycode';
import * as React from 'react';
import ReactLinkify from 'react-linkify';
export class Linkify extends React.Component {
    constructor(props) {
        super(props);
        this._componentDecorator = this._componentDecorator.bind(this);
    }
    _componentDecorator(href, text, _key) {
        const { target } = this.props;
        return (<Link target={target ?? '_blank'} href={href} rel="noopener">
                {toASCII(text)}
            </Link>);
    }
    render() {
        return <ReactLinkify componentDecorator={this._componentDecorator}>{this.props.children}</ReactLinkify>;
    }
}
export default Linkify;
