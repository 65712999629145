import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { PowerState, EnvironmentState, VmState, OperatingSystem } from '../models/environment-common';
import { OperationResultStatus, ProvisioningState } from '../models/common';
export function processPowerState(lastKnownPowerState) {
    if (!lastKnownPowerState) {
        return PowerState.Unknown;
    }
    const processedPowerState = lastKnownPowerState
        .trim()
        .toLowerCase()
        .replace('powerstate/', '')
        .replace('deallocating', PowerState.Stopping)
        .replace('deallocated', PowerState.Stopped);
    return processedPowerState;
}
export function processEnvironmentState(environmentState) {
    if (!environmentState) {
        return EnvironmentState.Unknown;
    }
    return environmentState.trim().toLowerCase();
}
export function processVmState(powerState, state, latestOperationResult) {
    if (state === EnvironmentState.Creating || state === ProvisioningState.Creating) {
        return VmState.Creating;
    }
    else if (state === EnvironmentState.Failed || state === ProvisioningState.Failed) {
        return VmState.Failed;
    }
    else if (state === ProvisioningState.Deleting) {
        return VmState.Deleting;
    }
    if (!latestOperationResult) {
        return VmState.Unknown;
    }
    const { status, requestUri } = latestOperationResult;
    const activeRequestUri = status === OperationResultStatus.Running && requestUri ? requestUri.trim().toLowerCase() : '';
    if (activeRequestUri.indexOf('/start') > -1) {
        return VmState.Starting;
    }
    else if (activeRequestUri.indexOf('/stop') > -1) {
        return VmState.Stopping;
    }
    else if (activeRequestUri.indexOf('/resetpassword') > -1) {
        return VmState.ResettingPassword;
    }
    else if (powerState === PowerState.Stopping) {
        return VmState.Stopping;
    }
    else if (powerState === PowerState.Starting) {
        return VmState.Starting;
    }
    else if (powerState === PowerState.Stopped) {
        return VmState.Stopped;
    }
    else if (powerState === PowerState.Running) {
        return VmState.Running;
    }
    // defaulting to stopped, could also return unknown or none
    return VmState.Stopped;
}
export function processProvisioningState(provisioningState) {
    if (!provisioningState) {
        return ProvisioningState.None;
    }
    return provisioningState.trim().toLowerCase();
}
export function processOperationSystem(osType) {
    return osType === Ml.OsType.Linux ? OperatingSystem.Linux : OperatingSystem.Windows;
}
