import { Checkbox, Stack, Icon } from '@fluentui/react';
import * as React from 'react';
import { Field, useField } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { LabServicesModels } from 'lab-services';
import Condition, { ConditionNot } from '../condition';
import GracePeriodInput from './grace-period-input';
import { MaxGracePeriod, MinIdleGracePeriod, MinNoConnectGracePeriod } from './idle-config';
import { messages } from './messages';
import { DisconnectOnIdleInfoTip, ShutdownOnDisconnectInfoTip, ShutdownOnIdleInfoTip, VNextShutdownOnDisconnectInfoTip, } from './tooltips';
import CustomIdleShutdownDropdown from './customize-shutdown-dropdown';
export const DisconnectOnIdleWarning = (props) => {
    const { isRunning } = props;
    const msg = useIntl().formatMessage;
    const isEnabledDirty = useField('idleConfig.disconnectOnIdleEnabled').meta?.dirty;
    const isGracePeriodDirty = useField('idleConfig.disconnectOnIdleGracePeriod').meta?.dirty;
    if (isRunning === undefined || isEnabledDirty === undefined || isGracePeriodDirty === undefined) {
        return <></>;
    }
    if (!isRunning) {
        return <></>;
    }
    if (!isEnabledDirty && !isGracePeriodDirty) {
        return <></>;
    }
    return (<div>
            <Icon iconName="Warning" styles={{ root: { fontSize: '16px', marginRight: '11px', marginTop: '5px', verticalAlign: 'bottom' } }}/>
            {msg(messages.disconnectOnIdleWarningText)}
        </div>);
};
export const ShutdownPolicyFieldGroup = (props) => {
    const msg = useIntl().formatMessage;
    const { idleConfigFieldName, disabled, isRunning, isVNext } = props;
    const shutdownOnIdleFieldName = `${idleConfigFieldName}.shutdownOnIdle`;
    const shutdownOnIdleField = useField(shutdownOnIdleFieldName);
    const shutdownOnDisconnectInfoTipAriaLabel = isVNext
        ? msg(messages.vNextShutdownOnDisconnectInfoTipAriaLabel)
        : msg(messages.shutdownOnDisconnectInfoTipAriaLabel);
    const disconnectOnIdleInfoTipAriaLabel = msg(messages.disconnectOnIdleInfoTipAriaLabel);
    const shutdownOnIdleInfoTipAriaLabel = msg(messages.shutdownOnIdleInfoTipAriaLabel);
    return (<Stack tokens={{ childrenGap: 15 }}>
            <div className="ms-fontWeight-semibold">
                <FormattedMessage {...messages.title}/>
            </div>
            <Stack tokens={{ childrenGap: 26 }}>
                {!isVNext && (<Condition when={`${idleConfigFieldName}.disconnectOnIdleSupported`}>
                        <Stack tokens={{ childrenGap: 8 }}>
                            <Stack horizontal>
                                <Field name={`${idleConfigFieldName}.disconnectOnIdleEnabled`}>
                                    {(fieldProps) => (<Checkbox label={msg(messages.disconnectOnIdleLabel)} checked={fieldProps.input.value} onChange={fieldProps.input.onChange} disabled={disabled}/>)}
                                </Field>
                                <DisconnectOnIdleInfoTip ariaLabel={disconnectOnIdleInfoTipAriaLabel}/>
                            </Stack>
                            <Condition when={`${idleConfigFieldName}.disconnectOnIdleEnabled`}>
                                <Field name={`${idleConfigFieldName}.disconnectOnIdleGracePeriod`}>
                                    {(fieldProps) => (<GracePeriodInput label={msg(messages.disconnectOnIdleInputLabel)} value={fieldProps.input.value} onChange={fieldProps.input.onChange} disabled={disabled} min={MinIdleGracePeriod} max={MaxGracePeriod}/>)}
                                </Field>
                            </Condition>
                            <DisconnectOnIdleWarning isRunning={isRunning}/>
                        </Stack>
                    </Condition>)}
                {isVNext && (<Stack tokens={{ childrenGap: 8 }}>
                        <Stack horizontal>
                            <Field name={shutdownOnIdleFieldName}>
                                {(fieldProps) => (<Checkbox label={msg(messages.shutdownOnIdleLabel)} checked={fieldProps.input.value !== LabServicesModels.ShutdownOnIdleMode.None} onChange={(_, checked) => {
        checked
            ? fieldProps.input.onChange(LabServicesModels.ShutdownOnIdleMode.LowUsage)
            : fieldProps.input.onChange(LabServicesModels.ShutdownOnIdleMode.None);
    }} disabled={disabled}/>)}
                            </Field>
                            <ShutdownOnIdleInfoTip ariaLabel={shutdownOnIdleInfoTipAriaLabel}/>
                        </Stack>
                        <ConditionNot when={shutdownOnIdleFieldName} isNot={LabServicesModels.ShutdownOnIdleMode.None}>
                            <Stack>
                                <Stack.Item>
                                    <Field name={`${idleConfigFieldName}.shutdownOnIdleGracePeriod`}>
                                        {(fieldProps) => (<GracePeriodInput label={msg(messages.disconnectOnIdleInputLabel)} value={fieldProps.input.value} onChange={fieldProps.input.onChange} disabled={disabled} min={MinIdleGracePeriod} max={MaxGracePeriod}/>)}
                                    </Field>
                                </Stack.Item>
                                <Stack.Item>
                                    <CustomIdleShutdownDropdown value={shutdownOnIdleField.input.value} onChange={(value) => {
        value
            ? shutdownOnIdleField.input.onChange(value)
            : shutdownOnIdleField.input.onChange(LabServicesModels.ShutdownOnIdleMode.LowUsage);
    }} disabled={disabled}/>
                                </Stack.Item>
                            </Stack>
                        </ConditionNot>
                    </Stack>)}
                <Condition when={`${idleConfigFieldName}.shutdownOnDisconnectSupported`}>
                    <Stack tokens={{ childrenGap: 8 }}>
                        <Stack horizontal>
                            <Field name={`${idleConfigFieldName}.shutdownOnDisconnect`}>
                                {(fieldProps) => (<Checkbox label={msg(messages.shutdownOnDisconnectLabel)} checked={fieldProps.input.value} onChange={fieldProps.input.onChange} disabled={disabled}/>)}
                            </Field>
                            {!isVNext && (<ShutdownOnDisconnectInfoTip ariaLabel={shutdownOnDisconnectInfoTipAriaLabel}/>)}
                            {isVNext && (<VNextShutdownOnDisconnectInfoTip ariaLabel={shutdownOnDisconnectInfoTipAriaLabel}/>)}
                        </Stack>
                        <Condition when={`${idleConfigFieldName}.shutdownOnDisconnect`}>
                            <Field name={`${idleConfigFieldName}.shutdownOnDisconnectGracePeriod`}>
                                {(fieldProps) => (<GracePeriodInput label={msg(messages.shutdownOnDisconnectInputLabel)} value={fieldProps.input.value} onChange={fieldProps.input.onChange} disabled={disabled} min={0} max={MaxGracePeriod}/>)}
                            </Field>
                        </Condition>
                    </Stack>
                </Condition>
                <Condition when={`${idleConfigFieldName}.shutdownOnNoConnectSupported`}>
                    <Stack tokens={{ childrenGap: 8 }}>
                        <Field name={`${idleConfigFieldName}.shutdownWhenNotConnected`}>
                            {(fieldProps) => (<Checkbox label={msg(messages.shutdownOnNoConnectLabel)} checked={fieldProps.input.value} onChange={fieldProps.input.onChange} disabled={disabled}/>)}
                        </Field>
                        <Condition when={`${idleConfigFieldName}.shutdownWhenNotConnected`}>
                            <Field name={`${idleConfigFieldName}.shutdownOnNoConnectGracePeriod`}>
                                {(fieldProps) => (<GracePeriodInput label={msg(messages.shutdownOnNoConnectInputLabel)} value={fieldProps.input.value} onChange={fieldProps.input.onChange} disabled={disabled} min={MinNoConnectGracePeriod} max={MaxGracePeriod}/>)}
                            </Field>
                        </Condition>
                    </Stack>
                </Condition>
            </Stack>
        </Stack>);
};
export default ShutdownPolicyFieldGroup;
