import { all, call, put, select } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import UserProvider from '../../../data/providers/user-provider';
import { ResourceId } from '../../../utils/resource-id';
import { deleteUsersError, deleteUsersSuccess } from '../../actions/user/user-action-creators';
import { UserActionType } from '../../actions/user/user-actions';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import VNextUserProvider from '../../../data/providers/vnext/arm-api/user-provider';
export async function callDeleteUser(resourceId, accessToken, locale, language, errors) {
    try {
        await UserProvider.deleteUser(resourceId, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.DeleteUsers, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* deleteUsers(action) {
    const { userIds } = action;
    try {
        const resourceIds = userIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        yield all(resourceIds.map((resourceId) => call(callDeleteUser, resourceId, accessToken, locale, language, errors)));
        const failedIds = Object.keys(errors);
        if (failedIds.length < userIds.length) {
            // we had at least partial success
            const successIds = userIds.filter((id) => failedIds.indexOf(id) < 0);
            yield put(deleteUsersSuccess(successIds));
        }
        if (failedIds.length > 0) {
            // log the failures we had
            yield put(deleteUsersError(errors));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, 'all', FailureOperation.DeleteUsers, e ? e.code : undefined);
        const errors = {};
        errors[error.id] = error;
        yield put(deleteUsersError(errors));
    }
}
export async function callDeleteVNextUser(resourceId, accessToken, locale, language, errors) {
    try {
        await VNextUserProvider.deleteUser(resourceId, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.DeleteUsers, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* deleteVNextUsers(action) {
    const { userIds } = action;
    try {
        const resourceIds = userIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        yield all(resourceIds.map((resourceId) => call(callDeleteVNextUser, resourceId, accessToken, locale, language, errors)));
        const failedIds = Object.keys(errors);
        if (failedIds.length < userIds.length) {
            const successIds = userIds.filter((id) => failedIds.indexOf(id) < 0);
            yield put(deleteUsersSuccess(successIds));
        }
        if (failedIds.length > 0) {
            yield put(deleteUsersError(errors));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, 'all', FailureOperation.DeleteUsers, e ? e.code : undefined);
        const errors = {};
        errors[error.id] = error;
        yield put(deleteUsersError(errors));
    }
}
export function* deleteUsersSaga() {
    yield labParentBasedTakeLatest(UserActionType.DELETE_USERS, deleteUsers, deleteVNextUsers);
}
