import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { List, Map, Record } from 'immutable';
import _ from 'lodash';
import ErrorCode from '../../common/error-codes';
import { LabUpdateOperation } from '../../data/models/lab';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { LabActionType, } from '../actions/lab/lab-actions';
import { ProvisioningState } from '../../utils/provisioning-state';
import { Context } from '../action-context';
function addOrUpdateLab(lab, labs) {
    const index = labs.findIndex((o) => o.id === lab.id);
    if (index > -1) {
        // preserve original role info if not returned in the get
        // such as when we are polling on a lab
        if (lab.roles.length < 1) {
            const oldLab = labs.get(index);
            lab.roles = [...oldLab.roles];
        }
        return labs.set(index, lab);
    }
    else {
        return labs.push(lab);
    }
}
function selectLab(state, action) {
    const { labId: currentLabId } = action;
    return state.merge({
        currentLabId: currentLabId ? currentLabId.toLowerCase() : undefined,
    });
}
function listLabs(state, action) {
    return state.merge({
        errors: Map(),
        loadState: state.loadState === LoadingStoreState.Loaded || state.loadState === LoadingStoreState.Updated
            ? LoadingStoreState.Updating
            : LoadingStoreState.Loading,
    });
}
function listLabsSuccess(state, action) {
    const { labs } = action;
    return state.merge({
        labs: List(labs),
        loadState: state.loadState === LoadingStoreState.Updating ? LoadingStoreState.Updated : LoadingStoreState.Loaded,
    });
}
function listLabsError(state, action) {
    return state.merge({
        errors: addError(state.errors, action.error, action.error.id),
        loadState: state.loadState === LoadingStoreState.Updating
            ? LoadingStoreState.UpdateFailed
            : LoadingStoreState.LoadingFailed,
        labs: List([]),
    });
}
function getLab(state, action) {
    return state.merge({
        errors: state.errors.clear(),
    });
}
function getLabSuccess(state, action) {
    const { lab } = action;
    let labs = state.get('labs');
    labs = addOrUpdateLab(lab, labs);
    return state.merge({
        labs,
    });
}
function getLabError(state, action) {
    return state.merge({
        errors: addError(state.errors, action.error, action.error.id),
    });
}
function deleteLab(state, action) {
    return state.merge({
        isDeleting: true,
    });
}
function deleteLabSuccess(state, action) {
    let labs = state.get('labs');
    const labsIndex = labs.findIndex((o) => o.id.toLowerCase() === action.labId.toLowerCase());
    if (labsIndex > -1) {
        const oldLab = labs.get(labsIndex);
        const newLab = { ...oldLab, provisioningState: ProvisioningState.Deleting };
        labs = labs.set(labsIndex, newLab);
    }
    return state.merge({
        labs,
        isDeleting: false,
    });
}
function deleteLabError(state, action) {
    return state.merge({
        errors: addError(state.errors, action.error, action.error.id),
        isDeleting: false,
    });
}
function createLab(state, action) {
    return state.merge({
        errors: state.errors.clear(),
        isCreating: true,
    });
}
function createLabSuccess(state, action) {
    const { lab } = action;
    let labs = state.get('labs');
    labs = labs.push(lab);
    return state.merge({
        labs,
        isCreating: false,
    });
}
function createLabError(state, action) {
    return state.merge({
        errors: addError(state.errors, action.error, action.error.id),
        isCreating: false,
    });
}
function updateLab(state, action) {
    const { lab, updateOperation } = action;
    let labs = state.get('labs');
    if (updateOperation === LabUpdateOperation.Settings) {
        const index = labs.findIndex((o) => o.id === lab.id);
        if (index > -1) {
            // Preserve settings while calling update operation on the lab.
            const oldLab = labs.get(index);
            const newLab = {
                ...oldLab,
                connectivityPolicy: lab.connectivityPolicy,
                enableDisconnectOnIdle: lab.enableDisconnectOnIdle,
                enableNoConnectShutdown: lab.enableNoConnectShutdown,
                idleGracePeriod: lab.idleGracePeriod,
                idleNoConnectGracePeriod: lab.idleNoConnectGracePeriod,
                idleOsGracePeriod: lab.idleOsGracePeriod,
                idleShutdownMode: lab.idleShutdownMode,
            };
            labs = labs.set(index, newLab);
        }
    }
    return state.merge({
        updateError: undefined,
        isUpdating: true,
        labs,
    });
}
function updateLabSuccess(state, action) {
    const { lab } = action;
    let labs = state.get('labs');
    labs = addOrUpdateLab(lab, labs);
    return state.merge({
        labs,
        isUpdating: false,
    });
}
function updateLabError(state, action) {
    const { error: updateError } = action;
    if (updateError.code === ErrorCode.LabCapacityUpdateExceedsGpuCoresLimit ||
        updateError.code === ErrorCode.LabCapacityUpdateExceedsStandardCoresLimit) {
        return state.merge({
            labCapacityExceedsCoresError: updateError,
            isUpdating: false,
        });
    }
    let errors = state.get('errors');
    errors = addError(errors, updateError, updateError.id);
    return state.merge({
        errors,
        updateError,
        isUpdating: false,
    });
}
function updateLabAccessMode(state, action) {
    const { labId, userAccessMode } = action;
    let labs = state.get('labs');
    const index = labs.findIndex((lab) => lab.id === labId);
    if (index > -1) {
        const oldLab = labs.get(index);
        const newLab = { ...oldLab, userAccessMode };
        labs = labs.set(index, newLab);
    }
    return state.merge({
        isUpdating: true,
        labs,
    });
}
function updateLabAccessModeError(state, action) {
    const { labId } = action;
    let labs = state.get('labs');
    const index = labs.findIndex((lab) => lab.id === labId);
    if (index > -1) {
        const oldLab = labs.get(index);
        const userAccessMode = oldLab.userAccessMode === Ml.LabUserAccessMode.Open
            ? Ml.LabUserAccessMode.Restricted
            : Ml.LabUserAccessMode.Open;
        const newLab = { ...oldLab, userAccessMode };
        labs = labs.set(index, newLab);
    }
    return state.merge({
        isUpdating: false,
        labs,
    });
}
function clearError(state, action) {
    let errors = state.errors.get(action.id);
    if (!errors || action.errorIndex >= errors.count()) {
        return state;
    }
    errors = errors.remove(action.errorIndex);
    const errorMap = errors.count() > 0 ? state.errors.set(action.id, errors) : state.errors.remove(action.id);
    return state.merge({
        errors: errorMap,
    });
}
function clearUpdateError(state, action) {
    return state.merge({
        updateError: undefined,
    });
}
function clearLabCapacityExceedsCoresError(state, action) {
    return state.merge({
        labCapacityExceedsCoresError: undefined,
    });
}
function addError(errorMap, error, id) {
    const errors = errorMap.get(id);
    if (errors) {
        const existingError = errors.some((_error) => _.isEqual(_error, error));
        if (!existingError) {
            return errorMap.set(id, errors.concat(error));
        }
    }
    else {
        return errorMap.set(id, List([error]));
    }
    return errorMap;
}
function syncLabUsers(state, action) {
    const { labId } = action;
    let labs = state.get('labs');
    const index = labs.findIndex((lab) => lab.id === labId);
    if (index > -1) {
        const oldLab = labs.get(index);
        const newLab = { ...oldLab, isSyncPending: true };
        labs = labs.set(index, newLab);
    }
    return state.merge({
        labs,
        syncUsersError: undefined,
    });
}
function syncLabUsersError(state, action) {
    const { syncUsersError, labId } = action;
    let labs = state.get('labs');
    const index = labs.findIndex((lab) => lab.id === labId);
    if (index > -1) {
        const oldLab = labs.get(index);
        const newLab = { ...oldLab, isSyncPending: false };
        labs = labs.set(index, newLab);
    }
    return state.merge({
        labs,
        syncUsersError,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    labs: List(),
    currentLabId: '',
    errors: Map(),
    isDeleting: false,
    isCreating: false,
    isUpdating: false,
    updateError: undefined,
    labCapacityExceedsCoresError: undefined,
    syncUsersError: undefined,
})();
export const labReducer = (state = initialState, action) => {
    switch (action.type) {
        case LabActionType.CLEAR_USER_SESSION:
        case LabActionType.SELECT_LAB_PARENT_RESOURCE:
        case LabActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    return state;
                default:
                    switch (action.type) {
                        case LabActionType.SELECT_LAB:
                            return selectLab(state, action);
                        case LabActionType.LIST_LABS:
                            return listLabs(state, action);
                        case LabActionType.LIST_LABS_SUCCESS:
                            return listLabsSuccess(state, action);
                        case LabActionType.LIST_LABS_ERROR:
                            return listLabsError(state, action);
                        case LabActionType.GET_LAB:
                            return getLab(state, action);
                        case LabActionType.GET_LAB_SUCCESS:
                            return getLabSuccess(state, action);
                        case LabActionType.GET_LAB_ERROR:
                            return getLabError(state, action);
                        case LabActionType.DELETE_LAB:
                            return deleteLab(state, action);
                        case LabActionType.DELETE_LAB_SUCCESS:
                            return deleteLabSuccess(state, action);
                        case LabActionType.DELETE_LAB_ERROR:
                            return deleteLabError(state, action);
                        case LabActionType.CREATE_LAB:
                            return createLab(state, action);
                        case LabActionType.CREATE_LAB_SUCCESS:
                            return createLabSuccess(state, action);
                        case LabActionType.CREATE_LAB_ERROR:
                            return createLabError(state, action);
                        case LabActionType.UPDATE_LAB:
                            return updateLab(state, action);
                        case LabActionType.UPDATE_LAB_SUCCESS:
                            return updateLabSuccess(state, action);
                        case LabActionType.UPDATE_LAB_ERROR:
                            return updateLabError(state, action);
                        case LabActionType.UPDATE_LAB_ACCESS_MODE:
                            return updateLabAccessMode(state, action);
                        case LabActionType.UPDATE_LAB_ACCESS_MODE_ERROR:
                            return updateLabAccessModeError(state, action);
                        case LabActionType.CLEAR_LAB_ERROR:
                            return clearError(state, action);
                        case LabActionType.CLEAR_LAB_UPDATE_ERROR:
                            return clearUpdateError(state, action);
                        case LabActionType.CLEAR_LAB_CAPACITY_EXCEEDS_CORES_ERROR:
                            return clearLabCapacityExceedsCoresError(state, action);
                        case LabActionType.SYNC_LAB_USERS:
                            return syncLabUsers(state, action);
                        case LabActionType.SYNC_LAB_USERS_ERROR:
                            return syncLabUsersError(state, action);
                        default:
                            return state;
                    }
            }
    }
};
export default labReducer;
