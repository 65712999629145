import { call, put, select, takeLatest } from 'redux-saga/effects';
import { isLmsProfessor } from '../../selectors/lti-selectors';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { IdentityErrorCode } from '../../../data/models/identity';
import { getRedirectAuthError, loginPopup, loginRedirect, loginSilent, } from '../../../data/providers/msal-provider';
import { loginSilent as loginSilentTeams } from '../../../data/providers/teams-provider';
import { setAuthenticatedUserContext } from '../../../utils/telemetry/telemetry-channel';
import { loadUserPhoto, setLoginHint, signInError, signInFailed, signInRedirecting, signInSuccess, signInPoppingUp, } from '../../actions/identity/identity-action-creators';
import { IdentityActionType } from '../../actions/identity/identity-actions';
import { isTeamsHost } from '../../selectors/common-selectors';
import { getCurrentReturnUrl, getTenantId, getDisplayId, getLoginHint } from '../../selectors/identity-selector';
import { getReturnUrl } from '../../selectors/route-selector';
export function* signIn(action) {
    try {
        const { isInteractive, persistUrl } = action;
        // fetch tenantId and we will sign in user's account to this specified tenantId
        const tenantId = yield select(getTenantId);
        const loginHint = yield select(getLoginHint);
        const shouldPopupWindow = yield select(isLmsProfessor);
        // determine if we have any auth errors from a previous
        // sign in attempt
        const authError = yield call(getRedirectAuthError);
        if (authError) {
            const { errorCode } = authError;
            switch (errorCode) {
                case IdentityErrorCode.LoginRequired:
                    if (shouldPopupWindow) {
                        yield put(signInPoppingUp());
                    }
                    else {
                        yield put(signInRedirecting());
                    }
                    let returnUrl = yield select(getCurrentReturnUrl);
                    if (!returnUrl) {
                        returnUrl = yield select(getReturnUrl);
                    }
                    if (shouldPopupWindow) {
                        const result = yield call(loginPopup, tenantId, loginHint);
                        const { account } = result;
                        yield put(signInSuccess(account));
                    }
                    else {
                        yield call(loginRedirect, returnUrl, tenantId, loginHint);
                    }
                    break;
                case IdentityErrorCode.AccessDenied:
                case IdentityErrorCode.UserCanceled:
                    yield put(signInFailed(errorCode));
                    break;
                default:
                    const error = new MlClientError(undefined, '', FailureOperation.SignIn, errorCode);
                    yield put(signInError(errorCode, error));
                    break;
            }
            return;
        }
        let result;
        const isTeamsHosted = yield select(isTeamsHost);
        if (isTeamsHosted) {
            result = yield call(loginSilentTeams, tenantId);
        }
        else {
            result = yield call(loginSilent, tenantId);
        }
        const { isAuthenticated, account, errorCode } = result;
        if (isAuthenticated) {
            yield put(signInSuccess(account));
            if (!isTeamsHosted) {
                const loginHint = account.idTokenClaims['preferred_username'];
                yield put(setLoginHint(loginHint));
                yield put(loadUserPhoto());
            }
        }
        else if (isInteractive) {
            if (shouldPopupWindow) {
                yield put(signInPoppingUp());
                result = yield call(loginPopup, tenantId, loginHint);
                const { account } = result;
                yield put(signInSuccess(account));
            }
            else {
                yield put(signInRedirecting());
                if (persistUrl) {
                    const returnUrl = yield select(getReturnUrl);
                    yield call(loginRedirect, returnUrl, tenantId, loginHint);
                }
                else {
                    yield call(loginRedirect, undefined, tenantId, loginHint);
                }
            }
        }
        else {
            yield put(signInFailed(errorCode));
        }
    }
    catch (err) {
        const errorCode = IdentityErrorCode.Unknown;
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.SignIn, err ? err.code : errorCode);
        yield put(signInError(errorCode, error));
    }
}
export function* updateTelemetryUserId(action) {
    const displayId = yield select(getDisplayId);
    yield call(setAuthenticatedUserContext, displayId);
}
export function* signInSaga() {
    yield takeLatest(IdentityActionType.SIGN_IN, signIn);
}
export function* updateTelemetryUserIdSaga() {
    yield takeLatest(IdentityActionType.SIGN_IN_SUCCESS, updateTelemetryUserId);
}
