import { Map } from 'immutable';
import memoize from 'memoize-one';
export const getVNextPrice = memoize((store) => store.get('vNextPriceStore').get('prices'));
export const getVNextPriceLoadState = memoize((store) => store.get('vNextPriceStore').get('loadState'));
export const getVNextPriceLoadError = memoize((store) => store.get('vNextPriceStore').get('error'));
// Get a map (key by region) of maps (key by SKU name) of prices
// e.g. { westus: { Standard_Fsv2_2_4GB_64_S_SSD: {...}, Standard_Fsv2_2_4GB_128_S_SSD: {...} } }
export const getVNextProcessedPrice = memoize((store) => {
    const priceByRegion = getVNextPrice(store);
    let processedPrice = Map();
    for (const [region, prices] of priceByRegion) {
        let skuFamilyPrice = Map();
        prices.forEach((price) => {
            if (!skuFamilyPrice.get(price.skuName)) {
                skuFamilyPrice = skuFamilyPrice.set(price.skuName, []);
            }
            skuFamilyPrice.get(price.skuName)?.push(price);
        });
        processedPrice = processedPrice.set(region, skuFamilyPrice);
    }
    return processedPrice;
});
