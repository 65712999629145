import { List, Record } from 'immutable';
import { TenantActionType, } from '../actions/tenant/tenant-actions';
import { LoadingStoreState } from '../../data/models/loading-store-state';
function listTenants(state, action) {
    return state.merge({
        loadingState: LoadingStoreState.Loading,
    });
}
function listTenantsSuccess(state, action) {
    const { tenants } = action;
    return state.merge({
        loadingState: LoadingStoreState.Loaded,
        tenants: List(tenants),
    });
}
function listTenantsError(state, action) {
    const { error } = action;
    return state.merge({
        loadingState: LoadingStoreState.LoadingFailed,
        error,
    });
}
const initialState = Record({
    loadingState: LoadingStoreState.NotStarted,
    tenants: List(),
    error: undefined,
})();
export const TenantReducer = (state = initialState, action) => {
    switch (action.type) {
        case TenantActionType.LIST_TENANTS:
            return listTenants(state, action);
        case TenantActionType.LIST_TENANTS_SUCCESS:
            return listTenantsSuccess(state, action);
        case TenantActionType.LIST_TENANTS_ERROR:
            return listTenantsError(state, action);
        case TenantActionType.CLEAR_USER_SESSION:
            return state.merge(initialState);
        default:
            return state;
    }
};
