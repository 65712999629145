import { TemplateActionType, } from './template-actions';
export const listTemplates = (labId, storeFirst = true, expand = false) => ({
    type: TemplateActionType.LIST_TEMPLATES,
    labId,
    storeFirst,
    expand,
});
export const listTemplatesSuccess = (templates) => ({
    type: TemplateActionType.LIST_TEMPLATES_SUCCESS,
    templates,
});
export const listTemplatesError = (error) => ({
    type: TemplateActionType.LIST_TEMPLATES_ERROR,
    error,
});
export const getTemplate = (templateId, expand = false) => ({
    type: TemplateActionType.GET_TEMPLATE,
    templateId,
    expand,
});
export const getTemplateCancelled = () => ({
    type: TemplateActionType.GET_TEMPLATE_CANCELLED,
});
export const getTemplateSuccess = (template, expand = false) => ({
    type: TemplateActionType.GET_TEMPLATE_SUCCESS,
    template,
    expand,
});
export const getTemplateError = (error) => ({
    type: TemplateActionType.GET_TEMPLATE_ERROR,
    error,
});
export const startTemplate = (templateId) => ({
    type: TemplateActionType.START_TEMPLATE,
    templateId,
});
export const startTemplateError = (error) => ({
    type: TemplateActionType.START_TEMPLATE_ERROR,
    error,
});
export const stopTemplate = (templateId) => ({
    type: TemplateActionType.STOP_TEMPLATE,
    templateId,
});
export const stopTemplateError = (error) => ({
    type: TemplateActionType.STOP_TEMPLATE_ERROR,
    error,
});
export const createTemplateError = (error) => ({
    type: TemplateActionType.CREATE_TEMPLATE_ERROR,
    error,
});
export const updateTemplate = (templateId, title, description) => ({
    type: TemplateActionType.UPDATE_TEMPLATE,
    templateId,
    title,
    description,
});
export const updateTemplateSuccess = (template) => ({
    type: TemplateActionType.UPDATE_TEMPLATE_SUCCESS,
    template,
});
export const updateTemplateError = (error) => ({
    type: TemplateActionType.UPDATE_TEMPLATE_ERROR,
    error,
});
export const publishTemplate = (templateId, maxUsersInLab, useExistingImage = false) => ({
    type: TemplateActionType.PUBLISH_TEMPLATE,
    templateId,
    maxUsersInLab,
    useExistingImage,
});
export const publishTemplateCancelled = (templateId) => ({
    type: TemplateActionType.PUBLISH_TEMPLATE_CANCELLED,
    templateId,
});
export const publishTemplateError = (templateId, error) => ({
    type: TemplateActionType.PUBLISH_TEMPLATE_ERROR,
    templateId,
    error,
});
export const resetPassword = (templateId, username, password) => ({
    type: TemplateActionType.RESET_PASSWORD,
    templateId,
    username,
    password,
});
export const resetPasswordError = (error) => ({
    type: TemplateActionType.RESET_PASSWORD_ERROR,
    error,
});
export const selectTemplate = (template) => ({
    type: TemplateActionType.SELECT_TEMPLATE,
    template,
});
export const clearError = (id, errorIndex) => ({
    type: TemplateActionType.CLEAR_TEMPLATE_ERROR,
    id,
    errorIndex,
});
export const clearLoadTemplateError = () => ({
    type: TemplateActionType.CLEAR_LOAD_TEMPLATE_ERROR,
});
export const clearPublishExceedsCoresError = () => ({
    type: TemplateActionType.CLEAR_PUBLISH_EXCEEDS_CORES_ERROR,
});
export const pollTemplateStart = (id, intervalMs, initialDelayMs) => ({
    type: TemplateActionType.POLL_TEMPLATE_START,
    id,
    intervalMs,
    initialDelayMs,
});
export const pollTemplateStop = () => ({
    type: TemplateActionType.POLL_TEMPLATE_STOP,
});
export const pollTemplateCancelled = () => ({
    type: TemplateActionType.POLL_TEMPLATE_CANCELLED,
});
const actions = {
    listTemplates,
    listTemplatesSuccess,
    listTemplatesError,
    getTemplate,
    getTemplateCancelled,
    getTemplateSuccess,
    getTemplateError,
    startTemplate,
    startTemplateError,
    stopTemplate,
    stopTemplateError,
    createTemplateError,
    updateTemplate,
    updateTemplateSuccess,
    updateTemplateError,
    publishTemplate,
    publishTemplateCancelled,
    publishTemplateError,
    resetPassword,
    clearLoadTemplateError,
    clearError,
    clearPublishExceedsCoresError,
    pollTemplateStart,
    pollTemplateStop,
    pollTemplateCancelled,
};
export default actions;
