import { CommonActionType } from '../common/common-actions';
export const GroupActionType = {
    ...CommonActionType,
    GET_GROUP_NAME: 'GET_GROUP_NAME',
    GET_GROUP_NAME_FAILED: 'GET_GROUP_NAME_FAILED',
    GET_GROUP_NAME_SUCCESS: 'GET_GROUP_NAME_SUCCESS',
    GET_GROUP_NAME_ERROR: 'GET_GROUP_NAME_ERROR',
    SET_GROUP_ID: 'SET_GROUP_ID',
    SET_GROUP_NAME: 'SET_GROUP_NAME',
    ENABLE_FILTER_BY_GROUP_ID: 'ENABLE_FILTER_BY_GROUP_ID',
    GET_GROUPS: 'GET_GROUPS',
    GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
    GET_GROUPS_FAILED: 'GET_GROUPS_FAILED',
    GET_GROUPS_ERROR: 'GET_GROUPS_ERROR',
};
