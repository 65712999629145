import * as React from 'react';
import { DefaultButton, SearchBox, } from '@fluentui/react';
import _ from 'lodash';
import { LoadingItem } from './loading-item';
import { DropdownItem } from './dropdown-item';
const PageHeaderFilteredDropdownExpanded = (props) => {
    const { placeholder, ariaLabel, filter, menuListProps, onChange, defaultRender } = props;
    return (<>
            <div style={{ borderBottom: '1px solid #d6d6d6' }}>
                <SearchBox ariaLabel={ariaLabel} placeholder={placeholder} value={filter} onChange={(_, value) => onChange(value || '')} styles={{
        root: {
            margin: '10px',
        },
    }}/>
            </div>
            <div style={{ verticalAlign: 'middle' }}>{defaultRender(menuListProps)}</div>
            {!!props.onRenderFooter && (<div style={{
        borderTop: '1px solid #d6d6d6',
        paddingLeft: '8px',
        paddingTop: '4px',
        paddingBottom: '4px',
    }}>
                    {props.onRenderFooter()}
                </div>)}
        </>);
};
export class PageHeaderFilteredDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filter: '',
        };
        this._updateFilter = this._updateFilter.bind(this);
    }
    _updateFilter(filter) {
        this.setState({
            filter,
        });
    }
    render() {
        const { items, filterPlaceholder, filterAriaLabel, selected, isLoading, minExpandedWidth, onChange, onRenderSelected, onRenderItem, onFilterItems, onRenderPlaceholder, onRenderNoResults, onRenderFooter, styles, ariaLabel, } = this.props;
        const { filter } = this.state;
        const defaultStyles = {
            root: {
                verticalAlign: 'middle',
                minWidth: '10px',
            },
        };
        const buttonStyles = _.merge({}, defaultStyles, styles || undefined);
        if (isLoading) {
            return <LoadingItem styles={buttonStyles}/>;
        }
        if (items.length < 1) {
            return null;
        }
        const filteredItems = onFilterItems(filter, items);
        const menuItems = filteredItems.length > 0
            ? filteredItems.map((item) => ({
                key: item.value,
                onRender: (menuItem, dismissMenu) => {
                    return (<DropdownItem item={item} onRenderItem={onRenderItem} onClick={() => {
                        onChange(item);
                        dismissMenu();
                    }}/>);
                },
            }))
            : [
                {
                    key: 'noResults',
                    onRender: () => {
                        return (<DefaultButton styles={{
                            root: {
                                padding: '0 4px 0 4px',
                                width: '100%',
                                backgroundColor: 'inherit',
                                border: 0,
                            },
                        }}>
                                      {onRenderNoResults()}
                                  </DefaultButton>);
                    },
                },
            ];
        const selectedItem = selected ? onRenderSelected(selected) : onRenderPlaceholder();
        // NOTE: Setting ariaLabel as ariaDescription so that the screen reader
        // still reads out the selected value of the dropdown
        return (<DefaultButton styles={buttonStyles} ariaDescription={ariaLabel} menuIconProps={{
            styles: {
                root: {
                    marginLeft: '8px',
                    backgroundColor: 'inherit',
                    color: 'inherit',
                },
            },
        }} menuProps={{
            items: menuItems,
            styles: {
                list: {
                    minWidth: minExpandedWidth,
                    maxHeight: '300px',
                    overflowY: 'auto',
                },
            },
            onMenuDismissed: () => this._updateFilter(''),
            onRenderMenuList: (menuListProps, defaultRender) => (<PageHeaderFilteredDropdownExpanded placeholder={filterPlaceholder} ariaLabel={filterAriaLabel} filter={filter} menuListProps={menuListProps} onChange={this._updateFilter} defaultRender={defaultRender} onRenderFooter={onRenderFooter}/>),
        }}>
                {selectedItem}
            </DefaultButton>);
    }
}
export default PageHeaderFilteredDropdown;
