import { Icon, SharedColors, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Field } from 'react-final-form';
import commonMessages from '../language/common-messages';
import Constants from '../utils/constants';
import { RequestLimitIncreaseInSentenceLink } from '../common/request-limit-increase-link';
import { SafeSpinButton } from '../common/safe-spin-button';
import { isVNextLab } from '../redux/selectors/lab-selectors';
import { ClockInfoProcessEstimate } from './clock-info-process-estimate';
import { templateMessages } from './template-messages';
const messages = defineMessages({
    setMaxNumberOfMachinesLabel: {
        id: 'SetMaxNumberOfMachinesLabel',
        defaultMessage: 'Set the maximum number of machines in the lab',
        description: 'Label for field to set maximum number of machines in the lab',
    },
});
export const PublishDialogContent = (props) => {
    const intl = useIntl();
    const { remainingVmsInSubscription, hasBeenPublished, labCapacity, labMaxCapacity, labId, currentTenantId, isGroupSyncModeEnabled, groupName, hasTemplate, } = props;
    const { formatMessage: msg } = intl;
    const setMaxNumber = msg(messages.setMaxNumberOfMachinesLabel);
    let maxCapacity = labMaxCapacity ?? Constants.DefaultMaxCapacity;
    if (remainingVmsInSubscription !== undefined) {
        maxCapacity = Math.min(maxCapacity, remainingVmsInSubscription);
    }
    const isVNext = isVNextLab(labId);
    const longProcessWarningSpan = (<span style={{ fontWeight: 600 }}>
            {isVNext ? (<FormattedMessage id="VNextRepublishDialogSubWarning" defaultMessage="This process can take up to 20 minutes and cannot be undone." description="Text in re-publish dialog to warn user the re publish action can take a while and cannot be undone"/>) : (<FormattedMessage id="RepublishDialogSubWarning" defaultMessage="This process can take up to an hour and cannot be undone." description="Text in re-publish dialog to warn user the re publish action can take a while and cannot be undone"/>)}
        </span>);
    let isLimitedCoreView;
    if (isGroupSyncModeEnabled) {
        isLimitedCoreView =
            labCapacity !== undefined &&
                remainingVmsInSubscription !== undefined &&
                (remainingVmsInSubscription - labCapacity <= 10 || labCapacity >= maxCapacity);
    }
    else {
        isLimitedCoreView =
            remainingVmsInSubscription !== undefined &&
                (remainingVmsInSubscription <= 10 || (labCapacity !== undefined && labCapacity >= maxCapacity));
    }
    return (<>
            {hasBeenPublished && (<Stack horizontal tokens={{ childrenGap: '15px' }} styles={{ root: { marginTop: '10px' } }}>
                    <Stack.Item>
                        <Icon iconName="Warning" styles={{
        root: {
            fontSize: '54px',
            lineHeight: '54px',
            color: '#D83B01',
        },
    }}/>
                    </Stack.Item>
                    <Stack.Item align="start">
                        {hasTemplate && (<FormattedMessage id="RepublishDialogWarning" defaultMessage="Publishing will immediately delete every user’s virtual machine and replace it with a new one that includes your latest changes. {longProcessWarningSpan}" description="Text in re-publish dialog to warn user that publishing will delete all virtual machines and replace it with a new one.  {longProcessWarningSpan} is a formatted span with message by id RepublishDialogSubWarning, warning the user the process is long and cannot be undone." values={{ longProcessWarningSpan }}/>)}
                        {!hasTemplate && (<FormattedMessage id="NoTemplateRepublishDialogWarning" defaultMessage="Publishing will immediately delete every user’s virtual machine and replace it with a copy of the template image. {longProcessWarningSpan}" description="Text in re-publish dialog to warn user that publishing will delete all virtual machines and replace it with a copy of the template.  {longProcessWarningSpan} is a formatted span with message by id RepublishDialogSubWarning, warning the user the process is long and cannot be undone." values={{ longProcessWarningSpan }}/>)}
                        <div style={{ marginTop: '20px' }}>
                            <FormattedMessage id="RepublishDialogContinueConfirmation" defaultMessage="Do you want to continue?" description="Text in re-publish dialog to ask user if they would like to continue with the re-publish"/>
                        </div>
                    </Stack.Item>
                </Stack>)}
            {!hasBeenPublished && (<div className="publish-template-dialog__publish">
                    {!isGroupSyncModeEnabled && (<>
                            <Field name="capacity">
                                {(fieldProps) => (<SafeSpinButton label={setMaxNumber} autoFocus value={fieldProps.input.value} min={isVNext ? 0 : 1} max={maxCapacity} onChange={fieldProps.input.onChange}/>)}
                            </Field>
                            {!isLimitedCoreView && (<div className="publish-template-dialog__number-can-be-changed-text">
                                    <FormattedMessage id="ChangingNumberOfMachinesInLabInfo" defaultMessage="The number of machines can be increased or decreased at any time." description="Informational text that the number of machines can be changed at any time."/>
                                    <div className="ms-fontWeight-semibold">
                                        <FormattedMessage {...commonMessages.covidPublishWarning}/>
                                    </div>
                                </div>)}
                        </>)}
                    {isGroupSyncModeEnabled && (<>
                            {!!groupName && (<FormattedMessage id="SyncFromGroupFirstPublishMessage" defaultMessage='This lab is synced from the group "<aadGroupName></aadGroupName>". It will be published with {labCapacity} virtual machines.' description="Text in publish dialog to inform users that this lab is synced from aad group and the number of virtual machines is fixed." values={{
        aadGroupName: () => <span style={{ fontWeight: 600 }}>{groupName}</span>,
        labCapacity,
    }}/>)}
                            {!groupName && (<FormattedMessage id="SyncFromGroupFirstPublishNoGroupNameMessage" defaultMessage="This lab is synced from a group. It will be published with {labCapacity} virtual machines." description="Text in publish dialog to inform users that this lab is synced from aad group and the number of virtual machines is fixed." values={{
        labCapacity,
    }}/>)}
                        </>)}
                    {isLimitedCoreView && remainingVmsInSubscription !== undefined && (<Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { marginTop: '16px' } }}>
                            <Stack.Item>
                                <Icon iconName="Warning" styles={{ root: { color: SharedColors.red20 } }}/>
                            </Stack.Item>
                            <Stack.Item styles={{ root: { fontSize: '12px' } }}>
                                <FormattedMessage id="VmsAvailableForPublish" defaultMessage="Your account is close to reaching its virtual machine cores limit in Azure Lab Services. Currently, you can only publish {remainingVmsInSubscription} virtual machines. To publish more, please {requestLimitIncreaseLink}. You can expect the process to take 1-5 days. Due to high demand, some SKUs may be temporarily unavailable." description="Text in publish dialog to inform user the number of virtual machines they have available. {remainingVmsInSubscription} is the number of virtual machines available for the lab." values={{
        remainingVmsInSubscription,
        requestLimitIncreaseLink: (<RequestLimitIncreaseInSentenceLink labId={labId} tenantId={currentTenantId}/>),
    }}/>
                            </Stack.Item>
                        </Stack>)}
                    <div className="publish-template-dialog__this-takes-a-long-time-text">
                        <ClockInfoProcessEstimate timeEstimateMessage={isVNext
        ? msg(templateMessages.twentyMinutesFormatted)
        : msg(templateMessages.oneHourFormatted)}/>
                    </div>
                </div>)}
        </>);
};
