import { UserAgentApplication } from 'msal';
import { v4 as uuid } from 'uuid';
import { IdentityErrorCode, preAuthorizedScopes } from '../models/identity';
import Settings from '../../settings/settings';
import Routes from '../../utils/routes';
import { trackTrace, trackDependency } from '../../utils/telemetry/telemetry-channel';
import { Storage } from '../../utils/storage';
import { MlClientError, FailureOperation } from '../ml-client-error';
import { browserName } from '../../utils/browser';
import { getLtiToken, getNonce as getLtiNonce, getState as getLtiState, setLtiItems } from './lti-provider';
const userCanceledErrorMessage = "Cannot read property 'indexOf' of undefined";
const authorityBase = Settings.AadAuthority;
const ambiguousTenantId = 'organizations';
const crossTenantAuthority = `${authorityBase}/${ambiguousTenantId}/`;
const returnUrlStorageKey = 'return_url';
const loginRequiredAttemptKey = 'login_required_attempt';
let isInitialized = false;
let storageType;
let context;
let authRedirectError;
export function init() {
    if (!isInitialized) {
        storageType = Storage.getStorageType();
        trackTrace(`MsalProvider: storageType = ${storageType}`);
        const configuration = {
            auth: {
                clientId: Settings.AadAppClientId,
                authority: crossTenantAuthority,
                validateAuthority: true,
                navigateToLoginRequestUrl: false,
                redirectUri: `https://${window.location.host}${Routes.SignInReturn}`,
                postLogoutRedirectUri: `https://${window.location.host}${Routes.LoggedOut}`,
            },
            cache: {
                cacheLocation: storageType,
                storeAuthStateInCookie: browserName === 'edge' || browserName === 'ie' ? true : false,
            },
        };
        context = new UserAgentApplication(configuration);
        context.handleRedirectCallback((authError, _response) => {
            const wasLoginRequiredAttempt = hasLoginRequiredAttemptState();
            if (!!authError) {
                authRedirectError = authError;
                trackTrace(`MsalProvider: handleRedirectCallback - authRedirectError = ${JSON.stringify(authRedirectError)}, wasLoginRequiredAttempt = ${wasLoginRequiredAttempt}`);
            }
            else {
                // clearing login required attempt state because we received MSAL hash params without errors (we got a token back)
                clearLoginRequiredAttemptState();
                trackTrace(`MsalProvider: handleRedirectCallback - no error received in hash params, wasLoginRequiredAttempt = ${wasLoginRequiredAttempt}`);
            }
        });
        isInitialized = true;
    }
}
function resolveErrorCode(authError) {
    let errorCode = authError && authError.errorCode
        ? authError.errorCode.toLowerCase()
        : IdentityErrorCode.Unknown;
    // HACK: MSA does not set any hash params when the user hits back from the login page
    // which results in the error message below being returned from MSAL
    if (errorCode === IdentityErrorCode.Unknown &&
        authError &&
        authError.message &&
        authError.message.trim().toLowerCase() === userCanceledErrorMessage.toLowerCase()) {
        errorCode = IdentityErrorCode.UserCanceled;
    }
    return errorCode;
}
export function clearUserSession() {
    // HACK: This lets us call a private method to clear the MSAL cache
    if (isInitialized && context.clearCache) {
        context.clearCache();
    }
    // HACK: MSAL keeps this id token in memory so we need to clear this property
    // otherwise loginSilent will succeed after this method is called
    if (!!context && context.account) {
        context.account = null;
    }
    const ltiState = getLtiState();
    const ltiNonce = getLtiNonce();
    const ltiToken = getLtiToken();
    Storage.clear();
    // we should not clear lti items when signing out aad account
    if (!!ltiState && !!ltiNonce && !!ltiToken) {
        setLtiItems(ltiState, ltiNonce, ltiToken);
    }
}
export function clearCacheForScope(accessToken) {
    if (isInitialized && context.clearCacheForScope) {
        context.clearCacheForScope(accessToken);
    }
}
function setReturnUrl(returnUrl) {
    Storage.setItem(returnUrlStorageKey, returnUrl);
}
function setLoginRequiredAttemptState() {
    Storage.setItem(loginRequiredAttemptKey, 'true');
}
function clearLoginRequiredAttemptState() {
    Storage.removeItem(loginRequiredAttemptKey);
}
function hasLoginRequiredAttemptState() {
    const state = Storage.getItem(loginRequiredAttemptKey);
    return !!state;
}
export function getRedirectAuthError() {
    // resetting this state since it is no longer useful after this error check runs
    const wasLoginRequiredAttempt = hasLoginRequiredAttemptState();
    const authError = authRedirectError;
    authRedirectError = undefined;
    clearLoginRequiredAttemptState();
    if (!!authError) {
        const errorCode = resolveErrorCode(authError);
        const message = authError.errorMessage || authError.message || 'An auth error occurred on redirect.';
        return {
            errorCode,
            message,
        };
    }
    if (wasLoginRequiredAttempt) {
        return {
            errorCode: IdentityErrorCode.LoginRequired,
            message: 'Failed to acquire an access token after a login required error and no error was returned from MSAL.',
        };
    }
    return undefined;
}
export function getReturnUrl() {
    if (!isInitialized) {
        throw new MlClientError('MsalProvider: The provider must be initialized before use.', '', FailureOperation.MsalProviderInit);
    }
    const returnUrl = Storage.getItem(returnUrlStorageKey);
    return returnUrl;
}
export async function loginSilent(tenantId) {
    if (!isInitialized) {
        throw new MlClientError('MsalProvider: The provider must be initialized before use.', '', FailureOperation.MsalProviderInit);
    }
    const account = context.getAccount();
    if (!account) {
        return {
            isAuthenticated: false,
            errorCode: IdentityErrorCode.LoginRequired,
        };
    }
    if (!!tenantId) {
        const tid = account.idTokenClaims['tid'];
        if (tid !== tenantId) {
            clearUserSession();
            return {
                isAuthenticated: false,
                errorCode: IdentityErrorCode.LoginRequired,
            };
        }
    }
    return {
        isAuthenticated: true,
        account,
    };
}
export function loginRedirect(returnUrl, tenantId, loginHint, showPrompt = true) {
    if (!isInitialized) {
        throw new MlClientError('MsalProvider: The provider must be initialized before use.', '', FailureOperation.MsalProviderInit);
    }
    clearUserSession();
    if (returnUrl) {
        setReturnUrl(returnUrl);
    }
    const authority = !!tenantId ? `${authorityBase}/${tenantId}/` : crossTenantAuthority;
    const request = {
        scopes: [...preAuthorizedScopes],
        prompt: showPrompt ? 'select_account' : undefined,
        authority,
        loginHint,
    };
    context.loginRedirect(request);
}
export function loginPopup(tenantId, loginHint, showPrompt = true) {
    if (!isInitialized) {
        throw new MlClientError('MsalProvider: The provider must be initialized before use.', '', FailureOperation.MsalProviderInit);
    }
    clearUserSession();
    const authority = !!tenantId ? `${authorityBase}/${tenantId}/` : crossTenantAuthority;
    const request = {
        scopes: [...preAuthorizedScopes],
        prompt: showPrompt ? 'select_account' : undefined,
        authority,
        loginHint,
    };
    const result = context.loginPopup(request);
    return result;
}
export function logOut() {
    if (!isInitialized) {
        throw new MlClientError('MsalProvider: The provider must be initialized before use.', '', FailureOperation.MsalProviderInit);
    }
    clearUserSession();
    context.logout();
}
export async function getAccessToken(scopes, tenantId, returnUrl, shouldOpenPopupWindow = false) {
    if (!isInitialized) {
        throw new MlClientError('MsalProvider: The provider must be initialized before use.', '', FailureOperation.MsalProviderInit);
    }
    const correlationId = uuid();
    const authority = `${authorityBase}/${tenantId}/`;
    const account = context.getAccount();
    const request = {
        scopes: [...scopes],
        authority,
        account,
        correlationId,
    };
    // for dependency tracking
    const startTime = new Date().getTime();
    try {
        const result = await context.acquireTokenSilent(request);
        const endTime = new Date().getTime();
        const duration = endTime - startTime;
        // this shouldn't happen, but if it does we want to detect the MSAL bug
        if (!result || !result.accessToken) {
            trackDependency(correlationId, 500, 'MsalProvider-getAccessToken', duration, false);
            return {
                isRedirecting: false,
                errorCode: IdentityErrorCode.Unknown,
            };
        }
        // only recording the dependency when it isn't a cached result because this gets called
        // a lot
        const { accessToken, fromCache } = result;
        if (!fromCache) {
            trackDependency(correlationId, 200, 'MsalProvider-getAccessToken', duration, true);
        }
        return {
            accessToken,
            isRedirecting: false,
        };
    }
    catch (error) {
        const endTime = new Date().getTime();
        const duration = endTime - startTime;
        const authError = error;
        const errorCode = resolveErrorCode(authError);
        // some error codes are expected, so we can consider those successful dependencies
        switch (errorCode) {
            case IdentityErrorCode.LoginRequired:
            case IdentityErrorCode.Renewal:
            case IdentityErrorCode.InteractionRequired:
            case IdentityErrorCode.UserLogin:
                trackDependency(correlationId, 300, 'MsalProvider-getAccessToken', duration, true);
                break;
            case IdentityErrorCode.InvalidState:
                trackDependency(correlationId, 400, 'MsalProvider-getAccessToken', duration, true);
                break;
            default:
                trackDependency(correlationId, 500, 'MsalProvider-getAccessToken', duration, false);
                break;
        }
        // if we can redirect or open popup, lets kick it off for the error codes that require it
        if (returnUrl || shouldOpenPopupWindow) {
            const startTime = new Date().getTime();
            switch (errorCode) {
                case IdentityErrorCode.LoginRequired:
                    // setting state so we can record that we are attempting a full
                    // frame redirect for an access token when hitting a login
                    // required error
                    if (!shouldOpenPopupWindow) {
                        setLoginRequiredAttemptState();
                    }
                case IdentityErrorCode.Renewal:
                case IdentityErrorCode.InteractionRequired:
                    if (returnUrl) {
                        setReturnUrl(returnUrl);
                        context.acquireTokenRedirect(request);
                        return {
                            isRedirecting: true,
                        };
                    }
                    else {
                        try {
                            const result = await context.acquireTokenPopup(request);
                            const endTime = new Date().getTime();
                            const duration = endTime - startTime;
                            const { accessToken } = result;
                            trackDependency(correlationId, 200, 'MsalProvider-getAccessTokenPopup', duration, true);
                            return {
                                accessToken,
                                isRedirecting: false,
                            };
                        }
                        catch (error) {
                            const authError = error;
                            const errorCode = resolveErrorCode(authError);
                            return {
                                isRedirecting: false,
                                errorCode,
                            };
                        }
                    }
                case IdentityErrorCode.UserLogin:
                    if (returnUrl) {
                        loginRedirect(returnUrl);
                        return {
                            isRedirecting: true,
                        };
                    }
                    else {
                        try {
                            const result = await loginPopup();
                            const endTime = new Date().getTime();
                            const duration = endTime - startTime;
                            const { accessToken } = result;
                            trackDependency(correlationId, 200, 'MsalProvider-getAccessTokenPopup', duration, true);
                            return {
                                accessToken,
                                isRedirecting: false,
                            };
                        }
                        catch (error) {
                            const authError = error;
                            const errorCode = resolveErrorCode(authError);
                            return {
                                isRedirecting: false,
                                errorCode,
                            };
                        }
                    }
                default:
                    break;
            }
        }
        return {
            isRedirecting: false,
            errorCode,
        };
    }
}
export function isMsalIframe() {
    // this code was adapted from the MSAl WindowUtils to replicate
    // functionality that is no longer intended to be exported as of
    // MSAL 1.2.0, see: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/src/utils/WindowUtils.ts
    if (window.parent !== window) {
        try {
            return window.parent.location.hostname === window.location.hostname;
        }
        catch (err) {
            // blocked from accessing cross origin iframe info so we know the origin is not the same as our own
            return false;
        }
    }
    return false;
}
export function isMsalPopup() {
    // this code was adapted from the MSAl WindowUtils to replicate
    // functionality that is no longer intended to be exported as of
    // MSAL 1.2.0, see: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-core/src/utils/WindowUtils.ts
    if (!!(window.opener && window.opener !== window)) {
        try {
            // if the opener origin is the same as our own origin and it is not for Teams MFA flow
            if (!!window.opener.location.origin && window.opener.location.search === '?host=Teams') {
                return false;
            }
            else {
                return true;
            }
        }
        catch (err) {
            // blocked from accessing cross origin info so we know the origin is not the same as our own
            return false;
        }
    }
    return false;
}
const MsalProvider = {
    init,
    getReturnUrl,
    loginSilent,
    loginRedirect,
    loginPopup,
    logOut,
    getAccessToken,
    isMsalIframe,
    clearCacheForScope,
    clearUserSession,
    isMsalPopup,
};
export default MsalProvider;
