import { Map } from 'immutable';
import memoize from 'memoize-one';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { getCurrentLabId } from '../lab-selectors';
import { getCoreUsage } from './core-usage-selectors';
import { getVNextLab } from './lab-selectors';
import { getCurrentLabSkuInfo, isCurrentVNextLabOldSku } from './sku-selectors';
export const getVNextUsageByRegion = memoize((store) => store.get('vNextUsageStore').get('usageByRegion'));
export const getVNextUsageLoadState = memoize((store) => store.get('vNextUsageStore').get('loadState'));
export const getVNextUsageLoadError = memoize((store) => store.get('vNextUsageStore').get('error'));
// Get a map (key by region) of maps (key by SKU family) of usage
// e.g. { westus: { dsv4: {...}, esv4: {...} }, eastus: { dsv4: {...}, esv4: {...} } }
export const getVNextProcessedUsage = memoize((store) => {
    const usageByRegion = getVNextUsageByRegion(store);
    let processedUsage = Map();
    for (const [region, usages] of usageByRegion) {
        let skuFamilyUsage = Map();
        usages.forEach((usage) => {
            skuFamilyUsage = skuFamilyUsage.set(usage.name?.value ?? '', usage);
        });
        processedUsage = processedUsage.set(region, skuFamilyUsage);
    }
    return processedUsage;
});
export const getCurrentLabCoreUsageInfo = memoize((store) => {
    const currentLabId = getCurrentLabId(store);
    const currentVNextLab = getVNextLab(store, currentLabId);
    if (!currentVNextLab) {
        return undefined;
    }
    const skuInfo = getCurrentLabSkuInfo(store);
    if (!skuInfo) {
        return undefined;
    }
    const isGpu = skuInfo.isGpu;
    const skuFamily = skuInfo.skuFamilyName;
    if (!skuFamily) {
        return undefined;
    }
    const isOldSku = isCurrentVNextLabOldSku(store);
    // if new sku
    if (!isOldSku) {
        const labLocation = currentVNextLab.location;
        const usageByRegion = getVNextUsageByRegion(store);
        const usage = usageByRegion?.get(labLocation);
        const skuUsage = usage?.find((o) => caseInsensitiveCultureInvariantCompare(o.name?.value ?? '', skuFamily) === 0);
        if (!!skuUsage && skuUsage.limit !== undefined && skuUsage.currentValue !== undefined) {
            return {
                limit: skuUsage.limit,
                currentValue: skuUsage.currentValue,
            };
        }
    }
    else {
        // if old sku
        const coreUsage = getCoreUsage(store);
        return {
            limit: isGpu ? coreUsage.alloted.gpu : coreUsage.alloted.standard,
            currentValue: isGpu ? coreUsage.used.gpu : coreUsage.used.standard,
        };
    }
});
