import { MessageBar, MessageBarType } from '@fluentui/react/lib/MessageBar';
import * as React from 'react';
import { injectIntl, FormattedMessage, useIntl } from 'react-intl';
import { ErrorCode } from '../common/error-codes';
import { FailureOperation } from '../data/ml-client-error';
import { commonMessages } from '../language/common-messages';
const ErrorMessage = ({ error, labName }) => {
    switch (error.failureOperation) {
        case FailureOperation.StartUserEnvironment:
            return (<FormattedMessage id="StartUserEnvironmentFailedErrorMessage" defaultMessage="Failed to start the virtual machine ‘{labName}’." description="The error message displayed to users when we fail to start a virtual machine. {labName} is the name of the lab that failed." values={{ labName }}/>);
        case FailureOperation.StopUserEnvironment:
            return (<FormattedMessage id="StopUserEnvironmentFailedErrorMessage" defaultMessage="Failed to stop the virtual machine ‘{labName}’." description="The error message displayed to users when we fail to stop a virtual machine. {labName} is the name of the lab that failed." values={{ labName }}/>);
        case FailureOperation.ResetUserEnvironmentPassword: {
            switch (error.code) {
                case ErrorCode.PasswordNotValid:
                    return (<FormattedMessage id="ResetUserEnvironmentPasswordInvalidErrorMessage" defaultMessage="Unable to reset the password for the virtual machine ‘{labName}’ because it doesn't meet password policies." description="Message to indicate that the provided password wasn't complex enough, long enough, or reused an older password. {labName} is the name of the lab that failed." values={{ labName }}/>);
                default:
                    return (<FormattedMessage id="ResetUserEnvironmentPasswordFailedErrorMessage" defaultMessage="Failed to reset the password for the virtual machine ‘{labName}’." description="The error message displayed to users when we fail to reset the password for a virtual machine. {labName} is the name of the lab that failed." values={{ labName }}/>);
            }
        }
        case FailureOperation.ReimageUserVirtualMachine:
            return (<FormattedMessage id="ReimageUserVirtualMachineFailedErrorMessage" defaultMessage="Failed to reimage the virtual machine ‘{labName}’." description="The error message displayed to users when we fail to reimage a virtual machine. {labName} is the name of the lab that failed." values={{ labName }}/>);
        default:
            return (<FormattedMessage id="RedeployUserVirtualMachineFailedErrorMessage" defaultMessage="Failed to redeploy the virtual machine ‘{labName}’." description="The error message displayed to users when we fail to redeploy a virtual machine. {labName} is the name of the lab that failed." values={{ labName }}/>);
    }
};
const ErrorMapMessageBars = (props) => {
    const intl = useIntl();
    const { errors, vms, dismissError } = props;
    const messageBarCloseAria = intl.formatMessage(commonMessages.close);
    return (<>
            {errors.count() > 0 &&
        errors
            .map((errors, environmentId) => {
            const vm = vms.find((vm) => vm.id === environmentId);
            const labName = vm ? vm.title : '';
            return errors.map((error, errorIndex) => {
                return (<MessageBar key={errorIndex} messageBarType={MessageBarType.error} dismissButtonAriaLabel={messageBarCloseAria} onDismiss={() => dismissError(environmentId, errorIndex)}>
                                    <ErrorMessage error={error} labName={labName}/>
                                </MessageBar>);
            });
        })
            .toList()}
        </>);
};
const ErrorBannersInjected = (props) => {
    const { vms, listEnvironmentsError, vCurrentStartErrors, vCurrentStopErrors, vCurrentResetPasswordErrors, vNextResetPasswordErrors, vNextStopErrors, vNextStartErrors, redeployErrors, reimageErrors, dismissListEnvironmentsError, dismissStartEnvironmentError, dismissStopEnvironmentError, dismissResetEnvironmentPasswordError, dismissReimageError, dismissRedeployError, } = props;
    return (<>
            <ListErrorBanner listEnvironmentsError={listEnvironmentsError} dismissListEnvironmentsError={dismissListEnvironmentsError}/>
            {vCurrentStartErrors.count() > 0 && (<ErrorMapMessageBars errors={vCurrentStartErrors} vms={vms} dismissError={dismissStartEnvironmentError}/>)}
            {!!vNextStartErrors && vNextStartErrors.count() > 0 && (<ErrorMapMessageBars errors={vNextStartErrors} vms={vms} dismissError={dismissStartEnvironmentError}/>)}
            {vCurrentStopErrors.count() > 0 && (<ErrorMapMessageBars errors={vCurrentStopErrors} vms={vms} dismissError={dismissStopEnvironmentError}/>)}
            {!!vNextStopErrors && vNextStopErrors.count() > 0 && (<ErrorMapMessageBars errors={vNextStopErrors} vms={vms} dismissError={dismissStopEnvironmentError}/>)}
            {vCurrentResetPasswordErrors.count() > 0 && (<ErrorMapMessageBars errors={vCurrentResetPasswordErrors} vms={vms} dismissError={dismissResetEnvironmentPasswordError}/>)}
            {!!vNextResetPasswordErrors && vNextResetPasswordErrors.count() > 0 && (<ErrorMapMessageBars errors={vNextResetPasswordErrors} vms={vms} dismissError={dismissResetEnvironmentPasswordError}/>)}
            {!!reimageErrors && reimageErrors.count() > 0 && (<ErrorMapMessageBars errors={reimageErrors} vms={vms} dismissError={dismissReimageError}/>)}
            {!!redeployErrors && redeployErrors.count() > 0 && (<ErrorMapMessageBars errors={redeployErrors} vms={vms} dismissError={dismissRedeployError}/>)}
        </>);
};
export const ErrorBanners = injectIntl(ErrorBannersInjected);
const ListErrorBannerInjected = (props) => {
    const { listEnvironmentsError } = props;
    const isPartial = listEnvironmentsError?.failureOperation === FailureOperation.ListUserEnvironmentsPartial;
    const intl = useIntl();
    const messageBarCloseAria = intl.formatMessage(commonMessages.close);
    return (<>
            {listEnvironmentsError && (<MessageBar messageBarType={isPartial ? MessageBarType.warning : MessageBarType.error} dismissButtonAriaLabel={messageBarCloseAria} onDismiss={props.dismissListEnvironmentsError}>
                    {isPartial ? (<FormattedMessage id="ListUserEnvironmentsPartiallyFailedErrorMessage" defaultMessage="This list may be incomplete - an error occurred while trying to retrieve or update the list of virtual machines." description="The error message displayed to users when we partially fail to retrieve or refresh the list of virtual machines."/>) : (<FormattedMessage id="ListUserEnvironmentsFailedErrorMessage" defaultMessage="An error occurred while trying to retrieve or update the list of virtual machines." description="The error message displayed to users when we fail to retrieve or refresh the list of virtual machines."/>)}
                </MessageBar>)}
        </>);
};
export const ListErrorBanner = injectIntl(ListErrorBannerInjected);
