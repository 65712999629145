import { fork, all } from 'redux-saga/effects';
import { signInSaga, updateTelemetryUserIdSaga } from './sign-in';
import { signOutSaga } from './sign-out';
import { watchGetAccessTokenRequests } from './access-tokens';
import { loadUserPhotoSaga } from './load-user-photo';
import { clearUserSessionSaga } from './clear-user-session';
import { loadUserSettingsSaga } from './load-user-settings';
import { updateUserSettingsSaga } from './update-user-settings';
import { requestUserConsentSaga } from './request-user-consent';
export function* rootSaga() {
    yield all([
        fork(signInSaga),
        fork(updateTelemetryUserIdSaga),
        fork(signOutSaga),
        fork(watchGetAccessTokenRequests),
        fork(loadUserPhotoSaga),
        fork(clearUserSessionSaga),
        fork(loadUserSettingsSaga),
        fork(updateUserSettingsSaga),
        fork(requestUserConsentSaga),
    ]);
}
