import { CommandBar, Icon, ProgressIndicator, Stack } from '@fluentui/react';
import * as React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { ImageAndSizeInfo } from '../common/image-and-size-info';
import { getCustomTheme } from '../common/themes/selectors';
import './template.css';
const CreatingViewInjected = (props) => {
    const { hasTemplateVm, navigationItems } = props;
    const { primaryCommandBarStyles, secondaryBackgroundColor } = getCustomTheme();
    return (<div id="template-container">
            <div id="template-content">
                {navigationItems && (<div id="template-header">
                        <CommandBar items={[]} farItems={navigationItems} styles={primaryCommandBarStyles}/>
                    </div>)}
                <Stack horizontalAlign="center" grow verticalFill styles={{ root: { backgroundColor: secondaryBackgroundColor } }}>
                    <Stack.Item>
                        <Icon iconName="creating-template" styles={{ root: { marginTop: 140, marginBottom: 20 } }}/>
                    </Stack.Item>
                    <Stack.Item>
                        <Stack horizontalAlign="start">
                            <Stack.Item>
                                <div className="ms-fontSize-m ms-fontWeight-semibold">
                                    {hasTemplateVm && (<FormattedMessage id="CreatingViewMessage" defaultMessage="Creating template virtual machine" description="Message shown on the template page while the VM is being created."/>)}
                                    {!hasTemplateVm && (<FormattedMessage id="CreatingViewMessageNoTemplate" defaultMessage="Creating the lab" description="Message shown on the template page while the lab is being created."/>)}
                                </div>
                            </Stack.Item>
                            <Stack.Item>
                                <ProgressIndicator barHeight={4} description={hasTemplateVm ? (<>
                                                <FormattedMessage id="CreatingViewDescription" defaultMessage="This can take up to 20 minutes." description="Message shown on the template page while the VM is being created."/>
                                                <ImageAndSizeInfo {...props}/>
                                            </>) : (<>
                                                <FormattedMessage id="CreatingViewDescriptionNoTemplate" defaultMessage="This can take a few minutes." description="Message shown on the template page while the lab is being created."/>
                                                <ImageAndSizeInfo {...props}/>
                                            </>)} styles={{
        root: { minWidth: 250, width: '100%', marginBottom: 15 },
    }}/>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div>
        </div>);
};
export const CreatingView = injectIntl(CreatingViewInjected);
export default CreatingView;
