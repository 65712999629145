import { routerActions } from 'connected-react-router';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { PageTitle } from '../common/page/page-title';
import { commonMessages } from '../language/common-messages';
import { clearError, createSchedule, createVNextSchedule, deleteSchedule, updateSchedule, updateVNextSchedule, } from '../redux/actions/schedule/schedule-action-creators';
import Schedules from './schedule';
import { getScheduleContainerModel } from './schedule-selectors';
class SchedulesContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._createSchedule = this._createSchedule.bind(this);
        this._updateSchedule = this._updateSchedule.bind(this);
        this._deleteSchedule = this._deleteSchedule.bind(this);
        this._navigateRoute = this._navigateRoute.bind(this);
    }
    render() {
        const { scheduleViewModel, intl, clearError } = this.props;
        const props = {
            scheduleViewModel,
            createSchedule: this._createSchedule,
            updateSchedule: this._updateSchedule,
            deleteSchedule: this._deleteSchedule,
            clearError,
            navigateRoute: this._navigateRoute,
        };
        const pageTitle = intl.formatMessage(commonMessages.schedule);
        return (<>
                <PageTitle subtitle={pageTitle}/>
                <Schedules {...props}/>
            </>);
    }
    _createSchedule(schedule) {
        if (this.props.scheduleViewModel.isCurrentLabParentLabAccount) {
            this.props.createSchedule(this.props.templateId, schedule);
        }
        else {
            this.props.createVNextSchedule(this.props.scheduleViewModel.labId, schedule);
        }
    }
    _updateSchedule(schedule) {
        if (this.props.scheduleViewModel.isCurrentLabParentLabAccount) {
            this.props.updateSchedule(this.props.templateId, schedule);
        }
        else {
            this.props.updateVNextSchedule(this.props.scheduleViewModel.labId, schedule);
        }
    }
    _deleteSchedule(schedule) {
        this.props.deleteSchedule(schedule.id);
    }
    _navigateRoute(pathname) {
        const { push, search } = this.props;
        push({ pathname, search });
    }
}
const mapStateToProps = (state) => {
    return getScheduleContainerModel(state);
};
const mapDispatchToProps = {
    createSchedule,
    createVNextSchedule,
    updateSchedule,
    updateVNextSchedule,
    deleteSchedule,
    clearError,
    push: routerActions.push,
};
export const SchedulesContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(SchedulesContainerInjected));
export default SchedulesContainer;
