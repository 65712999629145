import memoize from 'memoize-one';
import { getLocale, isTeamsOrLmsMode, storeIsLoading } from '../redux/selectors/common-selectors';
import { getUserSettings } from '../redux/selectors/identity-selector';
import { getTemplateCreateError } from '../redux/selectors/template-selectors';
import { doesCurrentLabExist, getCurrentLabId, isCurrentLabBlocked, isCurrentLabWriteable, isCurrentLabMoving, getLabStoreLoadState, isCreatingLab, isVNextLab, getCurrentLab, isLabAssistantRole, isCurrentLabOldV1Lab, } from '../redux/selectors/lab-selectors';
import { getRoute, getSearch } from '../redux/selectors/route-selector';
import { InitializationState } from '../redux/state/common-store';
import { getCurrentLabImage } from '../redux/selectors/vnext/image-selectors';
import { getCurrentLabSkuInfo } from '../redux/selectors/vnext/sku-selectors';
import { getUpdateLmsErrors } from '../redux/selectors/vnext/lab-plan-selectors';
import { isVNextLabCreating as isLabCreating, getVnextLabPermissionPropagating } from '../redux/selectors/vnext/lab-selectors';
export const getLabAppViewModel = memoize((state) => {
    const commonStore = state.get('commonStore');
    const labAppInitializeState = commonStore.get('labAppInitializeState');
    const isAppInitializing = labAppInitializeState === InitializationState.NotStarted ||
        labAppInitializeState === InitializationState.Initializing;
    const labStoreLoadState = getLabStoreLoadState(state);
    const isLoadingLabs = storeIsLoading(labStoreLoadState);
    const currentLabId = getCurrentLabId(state);
    const currentLabExists = doesCurrentLabExist(state);
    const isCreating = isCreatingLab(state);
    const isBlockedLab = isCurrentLabBlocked(state);
    const isWriteable = isCurrentLabWriteable(state);
    const isMoving = isCurrentLabMoving(state);
    const search = getSearch(state);
    const route = getRoute(state);
    const locale = getLocale(state);
    const isInitialized = !isAppInitializing && !isLoadingLabs && !isCreating && currentLabExists;
    const settings = getUserSettings(state);
    const showCovidBanner = !settings.get('hasDismissedCovidBanner');
    const showOldV1GpuLabBanner = isCurrentLabOldV1Lab(state);
    // vCurrent template creation error
    const templateCreateError = getTemplateCreateError(state);
    const isTeamsOrLmsIntegrationEnabled = isTeamsOrLmsMode(state);
    const currentLab = getCurrentLab(state);
    const isVNextLabCreating = isVNextLab(currentLab?.id) && isLabCreating(currentLab);
    const isVnextLabPermissionPropagating = getVnextLabPermissionPropagating(state);
    const isVmActionsEnabled = isVNextLab(currentLab?.id) ? isLabAssistantRole(currentLab) : false;
    let cores, memoryInGB, imageName, storageSize, storageType, labSkuInfo;
    const updateLinkedLmsErrors = getUpdateLmsErrors(state);
    if (isVNextLabCreating) {
        const image = getCurrentLabImage(state);
        labSkuInfo = getCurrentLabSkuInfo(state);
        cores = labSkuInfo?.cores;
        memoryInGB = labSkuInfo?.memoryGB;
        imageName = image?.displayName;
        storageSize = labSkuInfo?.storageGB;
        storageType = labSkuInfo?.storageType;
    }
    return {
        locale,
        isInitializing: !isInitialized,
        isBlockedLab,
        isWriteable,
        isMoving,
        showCovidBanner,
        showOldV1GpuLabBanner,
        search,
        route,
        templateCreateError,
        isTeamsOrLmsIntegrationEnabled,
        selectedLabId: currentLabId,
        isVNextLabCreating,
        isVnextLabPermissionPropagating,
        cores,
        memoryInGB,
        imageName,
        storageSize,
        storageType,
        labSkuInfo,
        updateLinkedLmsErrors,
        isVmActionsEnabled,
    };
});
export const getLabAppContainerModel = memoize((state) => {
    const isCreating = isCreatingLab(state);
    const labStoreLoadState = getLabStoreLoadState(state);
    const isLoadingLabs = storeIsLoading(labStoreLoadState);
    const currentLabExists = doesCurrentLabExist(state);
    const currentLabId = getCurrentLabId(state);
    const labAppViewModel = getLabAppViewModel(state);
    const currentLab = getCurrentLab(state);
    return {
        labAppViewModel,
        isCreatingLab: isCreating,
        isLoadingLabs,
        currentLabExists,
        currentLabId,
        currentLab,
    };
});
