import { NeutralColors } from '@fluentui/theme';
import { merge } from 'lodash';
const commandBarSeparatorStyles = {
    backgroundColor: '#b3b0ad',
    width: '1px',
    height: '25px',
    margin: '10px 10px 0px 10px',
};
export const DefaultCustomTheme = {
    secondaryBackgroundColor: '#faf9f8',
    pageTitleStyle: {
        fontSize: '24px',
        lineHeight: '28px',
        fontWeight: 600,
        height: '32px',
        color: '#000000',
        margin: '30px 0px 0px 30px',
    },
    accessDropdownStyle: {
        margin: '30px 0px 0px 30px',
    },
    smallPageTitleStyle: {
        fontSize: '20px',
        lineHeight: '27px',
        fontWeight: 600,
        margin: '44px 0px 0px 30px',
        color: '#323130',
    },
    panelStyles: {
        main: {
            marginTop: 54,
            '.ms-TextField-fieldGroup:hover': { borderColor: '#0078d4' },
        },
        header: {
            marginTop: 0,
        },
        scrollableContent: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            flexShrink: 1,
            flexGrow: 1,
            overflowY: 'hidden',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            flexShrink: 1,
            flexGrow: 1,
            overflowY: 'auto',
            paddingTop: 20,
        },
        footer: {
            borderTop: '1px solid #edebe9',
        },
        footerInner: {
            paddingLeft: 23,
        },
    },
    primaryCommandButtonStyles: {
        root: {
            width: '100%',
            padding: '0',
            margin: '0',
            border: '0',
        },
        rootHovered: {
            color: '#323130',
            backgroundColor: '#f3f2f1',
        },
        rootPressed: {
            backgroundColor: 'rgb(237, 235, 233)',
        },
        iconPressed: {
            color: 'undefined',
        },
        label: {
            whiteSpace: 'nowrap',
        },
    },
    primaryCommandBarStyles: {
        root: {
            borderBottom: '1px solid #f3f2f1',
            height: 44,
            '.ms-Button--command': { height: 44 },
            '.command-bar-separator': {
                ...commandBarSeparatorStyles,
                backgroundColor: '#b3b0ad',
            },
        },
    },
    secondaryCommandBarStyles: {
        root: {
            backgroundColor: '#f3f2f1',
            borderBottom: '1px solid #f3f2f1',
            height: 44,
            button: { backgroundColor: '#f3f2f1' },
            'button:hover': { backgroundColor: '#edebe9' },
            'button:active': { backgroundColor: '#e1dfdd' },
            '.command-bar-separator': {
                ...commandBarSeparatorStyles,
                backgroundColor: '#b3b0ad',
            },
        },
    },
    filterBarStyles: {
        root: {
            height: 47,
            marginTop: 12,
            marginLeft: 25,
            marginRight: 25,
            '.ms-Icon': { color: '#3b3a39' },
            '.ms-SearchBox-icon': { color: '#3b3a39' },
        },
    },
    usageProgressTrackStyles: {
        active: {
            backgroundColor: '#d2d0ce',
        },
    },
    dayPickerButtonStyles: {
        root: {
            minWidth: '32px',
            maxWidth: '32px',
            minHeight: '32px',
            maxHeight: '32px',
            borderRadius: '50%',
            margin: '0px',
            padding: '0px',
            backgroundColor: '#edebe9',
            border: 'none',
        },
        rootHovered: {
            backgroundColor: 'rgb(225, 223, 221)',
        },
        label: {
            fontWeight: 400,
        },
        rootChecked: {
            backgroundColor: '#0078d4',
            color: 'white',
        },
        rootCheckedHovered: {
            backgroundColor: 'rgb(0, 108, 191)',
            color: 'white',
        },
    },
    shimmeredUserListStyles: {
        root: {
            '.ms-ShimmerLine-root': { height: '18px' },
            '.ms-ShimmerGap-root': { height: '54px' },
            '::after': {
                backgroundImage: 'none',
            },
        },
    },
    messageBarStyles: {
        root: {
            '.ms-Link': { color: '#005a9e' },
        },
    },
    pageHeaderStyles: {
        root: {
            height: '100%',
            width: '100%',
            backgroundColor: '#000000',
            color: '#ffffff',
            margin: '0 0 0 0',
            padding: '0 0 0 0',
        },
    },
    pageHeaderButtonStyles: {
        root: {
            height: '54px',
            width: '100%',
            backgroundColor: '#000000',
            color: '#ffffff',
            border: '0px',
            borderRadius: '0px',
        },
        icon: {
            color: '#ffffff',
        },
        iconHovered: {
            color: '#ffffff',
        },
        iconPressed: {
            color: '#ffffff',
        },
        rootHovered: {
            backgroundColor: '#005A9E',
            color: '#ffffff',
        },
        rootPressed: {
            backgroundColor: '#004578',
            color: '#ffffff',
        },
        rootExpanded: {
            backgroundColor: '#F3F2F1',
            color: '#000000',
        },
    },
};
export const TeamsCustomTheme = merge({}, DefaultCustomTheme, {
    transitionBackgroundColor: '#f3f2f1',
    primaryBackgroundColor: '#faf9f8',
    secondaryBackgroundColor: '#faf9f8',
    pageTitleStyle: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: '20px 0px 0px 30px',
    },
    smallPageTitleStyle: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: '20px 0px 0px 30px',
    },
    panelStyles: {
        main: {
            marginTop: 0,
            '.ms-TextField-fieldGroup:hover': { borderColor: '#6264a7' },
        },
    },
    primaryCommandBarStyles: {
        root: {
            backgroundColor: '#faf9f8',
            borderBottom: '1px solid #e1dfdd',
            height: 54,
            '.ms-Button--command': { height: 54 },
        },
        primarySet: {
            button: { backgroundColor: '#faf9f8' },
            'button:hover': { backgroundColor: '#f3f2f1' },
            'button:active': { backgroundColor: '#edebe9' },
            '.command-bar-separator': {
                backgroundColor: '#e1dfdd',
                margin: '15px 10px 0px 10px',
            },
        },
        secondarySet: {
            button: {
                backgroundColor: '#faf9f8',
                borderBottom: '3px solid transparent',
            },
            'button:hover': {
                backgroundColor: '#faf9f8',
                borderBottom: '3px solid rgba(98, 100, 167, 0.5)',
            },
            'button.selected': {
                borderBottom: '3px solid #6264A7',
                '.ms-Button-label': {
                    color: '#6264A7',
                    fontWeight: 600,
                },
            },
        },
    },
    secondaryCommandBarStyles: {
        root: {
            backgroundColor: '#edebe9',
            borderBottom: '1px solid #e1dfdd',
            height: 54,
            '.ms-Button': { backgroundColor: '#edebe9' },
            '.ms-Button:hover': { backgroundColor: '#e1dfdd' },
            '.ms-Button:active': { backgroundColor: '#d2d0ce' },
            '.ms-Icon': { color: '#6264a7' },
            '.command-bar-separator': {
                backgroundColor: '#e1dfdd',
                margin: '15px 10px 0px 10px',
            },
        },
    },
    detailsHeaderStyles: {
        root: {
            backgroundColor: '#faf9f8',
            '.ms-DetailsHeader-cell:hover': { backgroundColor: '#edebe9' },
        },
    },
    detailsRowStyles: {
        root: {
            backgroundColor: '#faf9f8',
            '&:hover': { backgroundColor: '#edebe9' },
        },
    },
    usageProgressTrackStyles: {
        normal: {
            backgroundColor: '#e1dfdd',
        },
        active: {
            backgroundColor: '#ffffff',
        },
    },
    dayPickerButtonStyles: {
        rootChecked: {
            backgroundColor: '#6264a7',
        },
        rootCheckedHovered: {
            backgroundColor: '#464775',
        },
    },
    shimmeredUserListStyles: {
        root: {
            '.ms-ShimmerLine-root': { height: '14px', borderColor: '#faf9f8' },
            '.ms-ShimmerGap-root': { height: '50px', backgroundColor: '#faf9f8' },
            '.ms-Shimmer-container': {
                borderBottom: '1px solid #edebe9',
            },
        },
    },
    messageBarStyles: {
        root: {
            '.ms-Link': { color: '#6264a7' },
            '.ms-Link:hover': { color: '#464775' },
        },
    },
    labListCommandBarStyles: {
        root: {
            backgroundColor: '#faf9f8',
            borderBottom: '1px solid #e1dfdd',
            height: 54,
        },
        primarySet: {
            button: { backgroundColor: '#faf9f8' },
            'button:hover': { backgroundColor: '#f3f2f1' },
            'button:active': { backgroundColor: '#edebe9' },
        },
    },
});
export const LmsBasicsCustomTheme = merge({}, DefaultCustomTheme, {
    pageHeaderStyles: {
        root: {
            height: '48px',
            backgroundColor: NeutralColors.gray20,
            color: '#292827',
        },
    },
    pageHeaderButtonStyles: {
        root: {
            height: '48px',
            backgroundColor: NeutralColors.gray20,
            color: '#292827',
        },
        icon: {
            color: '#292827',
        },
        iconHovered: {
            color: '#292827',
        },
        iconPressed: {
            color: '#292827',
        },
        rootHovered: {
            backgroundColor: NeutralColors.gray30,
            color: '#292827',
        },
        rootPressed: {
            backgroundColor: NeutralColors.gray40,
            color: '#292827',
        },
        rootExpanded: {
            backgroundColor: NeutralColors.gray40,
            color: '#292827',
        },
    },
});
export const CanvasCustomTheme = merge({}, LmsBasicsCustomTheme, {
    primaryBackgroundColor: '#ffffff',
    secondaryBackgroundColor: '#ffffff',
    pageTitleStyle: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: '20px 0px 0px 30px',
    },
    smallPageTitleStyle: {
        fontSize: '18px',
        lineHeight: '24px',
        margin: '20px 0px 0px 30px',
    },
    primaryCommandBarStyles: {
        root: {
            borderBottom: '1px solid #e1dfdd',
            height: 51,
            '.ms-Button--command': { height: 51 },
        },
        primarySet: {
            '.command-bar-separator': {
                backgroundColor: '#e1dfdd',
                margin: '15px 10px 0px 10px',
            },
        },
        secondarySet: {
            button: {
                borderBottom: '3px solid transparent',
            },
            'button:hover': {
                borderBottom: '3px solid rgba(72, 118, 192, 0.5)',
            },
            'button.selected': {
                borderBottom: '3px solid #2275d3',
                '.ms-Button-label': {
                    color: '#2275d3',
                    fontWeight: 600,
                },
            },
        },
    },
    secondaryCommandBarStyles: {
        root: {
            height: 51,
        },
    },
    dayPickerButtonStyles: {
        rootChecked: {
            backgroundColor: '#2275d3',
        },
        rootCheckedHovered: {
            backgroundColor: '#1a59a1',
        },
    },
    messageBarStyles: {
        root: {
            '.ms-Link': { color: '#2275d3' },
            '.ms-Link:hover': { color: '#1a59a1' },
        },
    },
    panelStyles: {
        main: {
            marginTop: 48,
            '.ms-TextField-fieldGroup:hover': { borderColor: '#2275d3' },
        },
    },
    labListCommandBarStyles: {
        root: {
            borderBottom: '1px solid #e1dfdd',
            height: 51,
        },
    },
});
