import { all, fork } from 'redux-saga/effects';
import { listEnvironmentsSaga, listVCurrentVirtualMachinesSaga, listVNextVirtualMachinesSaga, pollUserEnvironmentsSaga, pollUserOperationsSaga, } from './list-environments';
import { startEnvironmentSaga, stopEnvironmentSaga, resetEnvironmentPasswordSaga } from './modify-environment';
import { reimageVirtualMachineSaga } from './reimage-virtual-machine';
import { redeployVirtualMachineSaga } from './redeploy-virtual-machine';
export function* rootSaga() {
    yield all([
        fork(listVCurrentVirtualMachinesSaga),
        fork(listVNextVirtualMachinesSaga),
        fork(listEnvironmentsSaga),
        fork(pollUserEnvironmentsSaga),
        fork(startEnvironmentSaga),
        fork(stopEnvironmentSaga),
        fork(resetEnvironmentPasswordSaga),
        fork(reimageVirtualMachineSaga),
        fork(redeployVirtualMachineSaga),
        fork(pollUserOperationsSaga),
    ]);
}
