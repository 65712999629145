import { call, put, select, takeLatest } from 'redux-saga/effects';
import { isTemplateCreating } from '../../selectors/template-selectors';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import { LoadingStoreState } from '../../../data/models/loading-store-state';
import { getPricingAndAvailability } from '../../../data/providers/lab-provider';
import { ResourceId } from '../../../utils/resource-id';
import { trackException } from '../../../utils/telemetry/telemetry-channel';
import { getLabPricingAndAvailabilityData as getLabPricingAndAvailabilityDataActionCreator, getLabPricingAndAvailabilityDataError, getLabPricingAndAvailabilityDataSuccess, } from '../../actions/pricing-and-availability/pricing-and-availability-action-creators';
import { PricingAndAvailabilityActionType, } from '../../actions/pricing-and-availability/pricing-and-availability-actions';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { getCurrentLabId } from '../../selectors/lab-selectors';
import { getLabPricingAndAvailabilityLoadState } from '../../selectors/pricing-and-availability-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
export function* getLabPricingAndAvailabilityData(action) {
    const { labId } = action;
    try {
        const resourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const data = yield call(getPricingAndAvailability, resourceId, accessToken, locale, language);
        yield put(getLabPricingAndAvailabilityDataSuccess(data));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, labId, FailureOperation.GetLabPricingAndAvailabilityData, err ? err.code : undefined);
        yield put(getLabPricingAndAvailabilityDataError(error));
    }
}
export function* getLabPricesAndAvailabilityOnLoadTemplate(action) {
    try {
        const labLoadState = yield select(getLabPricingAndAvailabilityLoadState);
        if (labLoadState === LoadingStoreState.NotStarted) {
            const currentTemplate = action.type === PricingAndAvailabilityActionType.GET_TEMPLATE_SUCCESS
                ? action.template
                : action.templates[0];
            if (currentTemplate && !isTemplateCreating(currentTemplate)) {
                const labId = yield select(getCurrentLabId);
                yield put(getLabPricingAndAvailabilityDataActionCreator(labId));
            }
        }
    }
    catch (err) {
        trackException(undefined, err);
    }
}
export function* getLabPricesAndAvailabilityOnLoadTemplateSaga() {
    yield takeLatest([
        PricingAndAvailabilityActionType.LIST_TEMPLATES_SUCCESS,
        PricingAndAvailabilityActionType.GET_TEMPLATE_SUCCESS,
    ], getLabPricesAndAvailabilityOnLoadTemplate);
}
export function* getLabPricingAndAvailabilityDataSaga() {
    yield takeLatest(PricingAndAvailabilityActionType.GET_LAB_PRICING_AND_AVAILABILITY_DATA, getLabPricingAndAvailabilityData);
}
