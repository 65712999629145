import { call, takeLatest, put, select } from 'redux-saga/effects';
import { logOut } from '../../../data/providers/msal-provider';
import { IdentityActionType } from '../../actions/identity/identity-actions';
import { clearAuthenticatedUserContext } from '../../../utils/telemetry/telemetry-channel';
import { lmsCompleteSignOut, signOutError } from '../../actions/identity/identity-action-creators';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { clearFatalError, clearUserSession, fatalError } from '../../actions/common/common-action-creators';
import ErrorCode from '../../../common/error-codes';
import { routerActions } from 'connected-react-router';
import Routes from '../../../utils/routes';
import { isLmsHost } from '../../selectors/common-selectors';
export function* signOut(action) {
    try {
        const isLms = yield select(isLmsHost);
        yield put(clearUserSession());
        yield call(clearAuthenticatedUserContext);
        if (isLms) {
            yield put(clearFatalError());
            yield put(routerActions.push({ pathname: Routes.LoggedOut }));
            yield put(lmsCompleteSignOut());
        }
        else {
            yield call(logOut);
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.SignOut, err ? err.code : undefined);
        yield put(signOutError(error));
        yield put(fatalError(ErrorCode.GenericError, error));
    }
}
export function* signOutSaga() {
    yield takeLatest(IdentityActionType.SIGN_OUT, signOut);
}
