import { List, Map, Record, Set } from 'immutable';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { EnvironmentActionType, } from '../actions/environment/environment-actions';
import { VmState } from '../../data/models/environment-common';
import { Context } from '../action-context';
function listEnvironments(state, action) {
    return state.merge({
        loadError: undefined,
        loadState: state.loadState === LoadingStoreState.Loaded ? LoadingStoreState.Updating : LoadingStoreState.Loading,
    });
}
function listEnvironmentsCancelled(state, action) {
    return state.merge({
        loadState: state.loadState === LoadingStoreState.Updating ? LoadingStoreState.Loaded : LoadingStoreState.NotStarted,
    });
}
export function listEnvironmentsSuccess(state, action) {
    const { environments: newEnvironments } = action;
    let environments = state.get('environments');
    environments = mergeEnvironments(newEnvironments, environments);
    return state.merge({
        environments,
        loadState: LoadingStoreState.Loaded,
    });
}
function listEnvironmentsError(state, action) {
    const { error: loadError } = action;
    return state.merge({
        loadState: state.loadState === LoadingStoreState.Updating
            ? LoadingStoreState.UpdateFailed
            : LoadingStoreState.LoadingFailed,
        loadError,
    });
}
function startEnvironments(state, action) {
    const { environmentIds } = action;
    let environments = state.get('environments');
    environments = setPendingState(environmentIds, environments, VmState.Starting);
    return state.merge({
        environments,
        startErrors: Map(),
    });
}
function startEnvironmentsError(state, action) {
    const { errors } = action;
    const environmentIds = Object.keys(errors);
    let environments = state.get('environments');
    environments = setPendingState(environmentIds, environments, VmState.None);
    let startErrors = state.get('startErrors');
    startErrors = addErrors(environmentIds, startErrors, errors);
    return state.merge({
        environments,
        startErrors,
    });
}
function stopEnvironments(state, action) {
    const { environmentIds } = action;
    let environments = state.get('environments');
    environments = setPendingState(environmentIds, environments, VmState.Stopping);
    return state.merge({
        environments,
        stopErrors: Map(),
    });
}
function stopEnvironmentsError(state, action) {
    const { errors } = action;
    const environmentIds = Object.keys(errors);
    let environments = state.get('environments');
    environments = setPendingState(environmentIds, environments, VmState.None);
    let stopErrors = state.get('stopErrors');
    stopErrors = addErrors(environmentIds, stopErrors, errors);
    return state.merge({
        environments,
        stopErrors,
    });
}
function resetEnvironments(state, action) {
    const { environmentIds } = action;
    let environments = state.get('environments');
    environments = setPendingState(environmentIds, environments, VmState.Deleting);
    return state.merge({
        environments,
        resetErrors: Map(),
    });
}
function resetEnvironmentsError(state, action) {
    const { errors } = action;
    const environmentIds = Object.keys(errors);
    let environments = state.get('environments');
    environments = setPendingState(environmentIds, environments, VmState.None);
    let resetErrors = state.get('resetErrors');
    resetErrors = addErrors(environmentIds, resetErrors, errors);
    return state.merge({
        environments,
        resetErrors,
    });
}
function getEnvironment(state, action) {
    const { id } = action;
    let gettingEnvironments = state.get('gettingEnvironments');
    gettingEnvironments = gettingEnvironments.add(id);
    return state.merge({
        gettingEnvironments,
    });
}
function getEnvironmentSuccess(state, action) {
    const { environment } = action;
    let environments = state.get('environments');
    let gettingEnvironments = state.get('gettingEnvironments');
    gettingEnvironments = gettingEnvironments.remove(environment.id);
    environments = mergeEnvironment(environment, environments);
    return state.merge({
        environments,
        gettingEnvironments,
    });
}
function getEnvironmentCancelled(state, action) {
    const { id } = action;
    let gettingEnvironments = state.get('gettingEnvironments');
    gettingEnvironments = gettingEnvironments.remove(id);
    return state.merge({
        gettingEnvironments,
    });
}
function getEnvironmentError(state, action) {
    const { id, error } = action;
    let getErrors = state.get('getErrors');
    let gettingEnvironments = state.get('gettingEnvironments');
    gettingEnvironments = gettingEnvironments.remove(id);
    getErrors = getErrors.set(id, error);
    return state.merge({
        getErrors,
        gettingEnvironments,
    });
}
function clearLoadError(state, action) {
    return state.merge({
        loadError: undefined,
    });
}
function clearStartError(state, action) {
    const { id } = action;
    let startErrors = state.get('startErrors');
    startErrors = clearError(startErrors, id);
    return state.merge({
        startErrors,
    });
}
function clearStopError(state, action) {
    const { id } = action;
    let stopErrors = state.get('stopErrors');
    stopErrors = clearError(stopErrors, id);
    return state.merge({
        stopErrors,
    });
}
function clearResetError(state, action) {
    const { id } = action;
    let resetErrors = state.get('resetErrors');
    resetErrors = clearError(resetErrors, id);
    return state.merge({
        resetErrors,
    });
}
function addErrors(ids, errorMap, errors) {
    ids.map((id) => (errorMap = errorMap.set(id, errors[id])));
    return errorMap;
}
function clearError(errorMap, id) {
    if (id) {
        return errorMap.remove(id);
    }
    return Map();
}
function setPendingState(environmentIds, environments, pendingVmState) {
    environmentIds.map((id) => {
        const index = environments.findIndex((env) => env.id === id);
        if (index < 0) {
            return;
        }
        environments = environments.update(index, (templateEnvironment) => {
            return { ...templateEnvironment, pendingVmState };
        });
    });
    return environments;
}
function mergeEnvironments(newEnvironments, oldEnvironments) {
    let environments = List();
    newEnvironments.map((newEnvironment) => {
        const index = oldEnvironments.findIndex((e) => e.id === newEnvironment.id);
        if (index > -1) {
            const oldEnvironment = oldEnvironments.get(index);
            const environment = preserveNetworkInterface(oldEnvironment, newEnvironment);
            environments = environments.push(environment);
        }
        else {
            environments = environments.push(newEnvironment);
        }
    });
    return environments;
}
function mergeEnvironment(newEnvironment, environments) {
    const index = environments.findIndex((e) => e.id === newEnvironment.id);
    if (index > -1) {
        const oldEnvironment = environments.get(index);
        const environment = preserveNetworkInterface(oldEnvironment, newEnvironment);
        environments = environments.set(index, environment);
    }
    else {
        environments = environments.push(newEnvironment);
    }
    return environments;
}
function preserveNetworkInterface(oldEnvironment, newEnvironment) {
    const { networkInterface: oldNetworkInterface } = oldEnvironment;
    const { networkInterface: newNetworkInterface } = newEnvironment;
    let environment;
    if (!!newNetworkInterface) {
        environment = newEnvironment;
    }
    else if (!!oldNetworkInterface) {
        environment = { ...newEnvironment, networkInterface: oldNetworkInterface };
    }
    else {
        environment = newEnvironment;
    }
    return environment;
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    environments: List(),
    loadError: undefined,
    gettingEnvironments: Set(),
    getErrors: Map(),
    startErrors: Map(),
    stopErrors: Map(),
    resetErrors: Map(),
})();
export const environmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case EnvironmentActionType.CLEAR_USER_SESSION:
        case EnvironmentActionType.SELECT_LAB_PARENT_RESOURCE:
        case EnvironmentActionType.SELECT_LAB:
        case EnvironmentActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    return state;
                default:
                    switch (action.type) {
                        case EnvironmentActionType.LIST_ENVIRONMENTS:
                            return listEnvironments(state, action);
                        case EnvironmentActionType.LIST_ENVIRONMENTS_CANCELLED:
                            return listEnvironmentsCancelled(state, action);
                        case EnvironmentActionType.LIST_ENVIRONMENTS_SUCCESS:
                            return listEnvironmentsSuccess(state, action);
                        case EnvironmentActionType.LIST_ENVIRONMENTS_ERROR:
                            return listEnvironmentsError(state, action);
                        case EnvironmentActionType.START_ENVIRONMENTS:
                            return startEnvironments(state, action);
                        case EnvironmentActionType.START_ENVIRONMENTS_ERROR:
                            return startEnvironmentsError(state, action);
                        case EnvironmentActionType.STOP_ENVIRONMENTS:
                            return stopEnvironments(state, action);
                        case EnvironmentActionType.STOP_ENVIRONMENTS_ERROR:
                            return stopEnvironmentsError(state, action);
                        case EnvironmentActionType.RESET_ENVIRONMENTS:
                            return resetEnvironments(state, action);
                        case EnvironmentActionType.RESET_ENVIRONMENTS_ERROR:
                            return resetEnvironmentsError(state, action);
                        case EnvironmentActionType.GET_ENVIRONMENT:
                            return getEnvironment(state, action);
                        case EnvironmentActionType.GET_ENVIRONMENT_SUCCESS:
                            return getEnvironmentSuccess(state, action);
                        case EnvironmentActionType.GET_ENVIRONMENT_CANCELLED:
                            return getEnvironmentCancelled(state, action);
                        case EnvironmentActionType.GET_ENVIRONMENT_ERROR:
                            return getEnvironmentError(state, action);
                        case EnvironmentActionType.CLEAR_ENVIRONMENTS_LOAD_ERROR:
                            return clearLoadError(state, action);
                        case EnvironmentActionType.CLEAR_ENVIRONMENTS_START_ERROR:
                            return clearStartError(state, action);
                        case EnvironmentActionType.CLEAR_ENVIRONMENTS_STOP_ERROR:
                            return clearStopError(state, action);
                        case EnvironmentActionType.CLEAR_ENVIRONMENTS_RESET_ERROR:
                            return clearResetError(state, action);
                        default:
                            return state;
                    }
            }
    }
};
export default environmentReducer;
