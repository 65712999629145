import { Dialog, DialogFooter, PrimaryButton, TextField } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import CopyToClipboardButton from '../../common/copy-to-clipboard-button/copy-to-clipboard-button';
import commonMessages from '../../language/common-messages';
import Constants from '../../utils/constants';
const messages = defineMessages({
    title: {
        id: 'RdpInfoDialogTitle',
        defaultMessage: 'Remote desktop information',
        description: 'Title of the dialog showing VM RDP info.',
    },
    label: {
        id: 'RdpInfoDialogLabel',
        defaultMessage: 'Remote computer address',
        description: 'Label of the RDP info text box.',
    },
});
const RdpInfoDialogInjected = (props) => {
    const msg = props.intl.formatMessage;
    return (<Dialog hidden={false} onDismiss={props.onDismiss} dialogContentProps={{ title: msg(messages.title) }} closeButtonAriaLabel={msg(commonMessages.close)} maxWidth={Constants.DefaultDialogWidth}>
            <FormattedMessage id="RdpInfoDialogText" defaultMessage="Use the following host and port number to connect to the virtual machine via RDP:" description="Text on the dialog showing VM RDP info."/>
            <TextField name={msg(messages.label)} ariaLabel={msg(messages.label)} readOnly={true} value={props.rdpInfo} multiline={true} resizable={false} autoAdjustHeight={true} styles={{ root: { marginTop: 16, maxWidth: '402px' } }}/>
            <CopyToClipboardButton text={props.rdpInfo}/>
            <DialogFooter>
                <PrimaryButton text={msg(commonMessages.done)} onClick={props.onDismiss}/>
            </DialogFooter>
        </Dialog>);
};
export const RdpInfoDialog = injectIntl(RdpInfoDialogInjected);
export default RdpInfoDialog;
