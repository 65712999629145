import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import _ from 'lodash';
import memoize from 'memoize-one';
import { duration } from 'moment';
import { isCurrentLabParentLabAccount } from '../../redux/selectors/lab-parent-resource-selectors';
import { getCurrentLabPlan } from '../../redux/selectors/vnext/lab-plan-selectors';
import { isEnabled, toEnableState } from '../../data/helpers/enum-helpers';
import { getCurrentLabAccount } from '../../redux/selectors/lab-account-selectors';
import { DefaultGracePeriod } from './idle-config';
import { LabServicesModels } from 'lab-services';
import moment from 'moment';
import { caseInsensitiveCultureInvariantCompare } from '../../utils/string-comparison';
const supportedLinuxImages = [
    { offer: 'centos', publisher: 'openlogic', sku: '6.10' },
    { offer: 'centos-hpc', publisher: 'openlogic', sku: '6.5' },
    { offer: 'centos-hpc', publisher: 'openlogic', sku: '6.8' },
    { offer: 'centos-hpc', publisher: 'openlogic', sku: '7.1' },
    { offer: 'centos-hpc', publisher: 'openlogic', sku: '7.3' },
    { offer: 'centos-hpc', publisher: 'openlogic', sku: '7.4' },
    { offer: 'centos-hpc', publisher: 'openlogic', sku: '7.6' },
    { offer: 'centos-hpc', publisher: 'openlogic', sku: '7.7' },
    { offer: 'centos-lvm', publisher: 'openlogic', sku: '7-lvm' },
    { offer: 'centos', publisher: 'openlogic', sku: '7.5' },
    { offer: 'centos', publisher: 'openlogic', sku: '7.6' },
    { offer: 'centos', publisher: 'openlogic', sku: '7.7' },
    { offer: 'centos', publisher: 'openlogic', sku: '7_4' },
    { offer: 'centos', publisher: 'openlogic', sku: '7_8' },
    { offer: 'centos', publisher: 'openlogic', sku: '7.5' },
    { offer: 'debian', publisher: 'credativ', sku: '9' },
    { offer: 'debian', publisher: 'credativ', sku: '9-backports' },
    { offer: 'sql2019-rhel7', publisher: 'microsoftsqlserver', sku: 'sqldev' },
    { offer: 'sql2019-ubuntu1604', publisher: 'microsoftsqlserver', sku: 'sqldev' },
    { offer: 'sql2019-rhel7', publisher: 'microsoftsqlserver', sku: 'sqldev' },
    { offer: 'sql2019-ubuntu1604', publisher: 'microsoftsqlserver', sku: 'sqldev' },
    { offer: 'sql2017-rhel7', publisher: 'microsoftsqlserver', sku: 'sqldev' },
    { offer: 'sql2017-sles12sp2', publisher: 'microsoftsqlserver', sku: 'sqldev' },
    { offer: 'sql2017-ubuntu1604', publisher: 'microsoftsqlserver', sku: 'sqldev' },
    { offer: 'sql2017-rhel7', publisher: 'microsoftsqlserver', sku: 'express' },
    { offer: 'sql2017-sles12sp2', publisher: 'microsoftsqlserver', sku: 'express' },
    { offer: 'sql2017-ubuntu1604', publisher: 'microsoftsqlserver', sku: 'express' },
    { offer: 'iot_edge_vm_ubuntu', publisher: 'microsoft_iot_edge', sku: 'ubuntu_1604_edgeruntimeonly' },
    { offer: 'linux-data-science-vm', publisher: 'microsoft-ads', sku: 'linuxdsvm' },
    { offer: 'opensuse-leap', publisher: 'suse', sku: '15-1' },
    { offer: 'opensuse-leap', publisher: 'suse', sku: '15-2' },
    { offer: 'oracle-database-19-3', publisher: 'oracle', sku: 'oracle-db-19300' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '77' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '77-ci' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '78' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '6.10' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '6.8' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '6.9' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '7.3' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '7.4' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '7.5' },
    { offer: 'oracle-linux', publisher: 'oracle', sku: '7.6' },
    { offer: 'rhel-sap', publisher: 'redhat', sku: '76-ci' },
    { offer: 'sles-12-sp5-basic', publisher: 'suse', sku: 'gen1' },
    { offer: 'sles-12-sp5-byos', publisher: 'suse', sku: 'gen1' },
    { offer: 'sles-12-sp5-hpc-byos', publisher: 'suse', sku: 'gen1' },
    { offer: 'sles-12-sp5-hpc', publisher: 'suse', sku: 'gen1' },
    { offer: 'sles-12-sp5', publisher: 'suse', sku: 'gen1' },
    { offer: 'sles-byos', publisher: 'suse', sku: '12-sp2' },
    { offer: 'sles-byos', publisher: 'suse', sku: '12-sp4' },
    { offer: 'sles-sap-12-sp5-byos', publisher: 'suse', sku: 'gen1' },
    { offer: 'sles-sap-12-sp5', publisher: 'suse', sku: 'gen1' },
    { offer: 'sql2019-rhel7', publisher: 'microsoftsqlserver', sku: 'enterprise' },
    { offer: 'sql2019-rhel7', publisher: 'microsoftsqlserver', sku: 'standard' },
    { offer: 'sql2019-sles12sp5', publisher: 'microsoftsqlserver', sku: 'enterprise' },
    { offer: 'sql2019-sles12sp5', publisher: 'microsoftsqlserver', sku: 'standard' },
    { offer: 'sql2019-ubuntu1604', publisher: 'microsoftsqlserver', sku: 'enterprise' },
    { offer: 'sql2019-ubuntu1604', publisher: 'microsoftsqlserver', sku: 'standard' },
    { offer: 'sql2019-ubuntu1804', publisher: 'microsoftsqlserver', sku: 'enterprise' },
    { offer: 'sql2019-ubuntu1804', publisher: 'microsoftsqlserver', sku: 'sqldev' },
    { offer: 'sql2019-ubuntu1804', publisher: 'microsoftsqlserver', sku: 'standard' },
    { offer: 'sql2019-ubuntu1804', publisher: 'microsoftsqlserver', sku: 'web' },
    { offer: 'sql2017-rhel7', publisher: 'microsoftsqlserver', sku: 'enterprise' },
    { offer: 'sql2017-sles12sp2', publisher: 'microsoftsqlserver', sku: 'enterprise' },
    { offer: 'sql2017-ubuntu1604', publisher: 'microsoftsqlserver', sku: 'enterprise' },
    { offer: 'sql2017-rhel7', publisher: 'microsoftsqlserver', sku: 'standard' },
    { offer: 'sql2017-sles12sp2', publisher: 'microsoftsqlserver', sku: 'standard' },
    { offer: 'sql2017-ubuntu1604', publisher: 'microsoftsqlserver', sku: 'standard' },
    { offer: 'sql2017-rhel7', publisher: 'microsoftsqlserver', sku: 'web' },
    { offer: 'sql2017-sles12sp2', publisher: 'microsoftsqlserver', sku: 'web' },
    { offer: 'sql2017-ubuntu1604', publisher: 'microsoftsqlserver', sku: 'web' },
    { offer: 'sles-byos', publisher: 'suse', sku: '12-sp3' },
    { offer: 'sles-hpc-priority', publisher: 'suse', sku: '12-sp3' },
    { offer: 'sles-sap-byos', publisher: 'suse', sku: '12-sp2' },
    { offer: 'sles-sap', publisher: 'suse', sku: '12-sp2' },
    { offer: 'sles-sap-byos', publisher: 'suse', sku: '12-sp3' },
    { offer: 'sles-sap', publisher: 'suse', sku: '12-sp3' },
    { offer: 'sles-byos', publisher: 'suse', sku: '12-sp4' },
    { offer: 'sles-sap', publisher: 'suse', sku: '12-sp4' },
    { offer: 'sles-sap-byos', publisher: 'suse', sku: '12-sp4' },
    { offer: 'ubuntuserver', publisher: 'canonical', sku: '16.04-lts' },
    { offer: 'ubuntuserver', publisher: 'canonical', sku: '14.04.5-lts' },
    { offer: 'ubuntuserver', publisher: 'canonical', sku: '16.04-lts' },
    { offer: 'ubuntuserver', publisher: 'canonical', sku: '18.04-lts' },
];
const unsupportedWindowsImages = [
    { offer: 'windows-7', publisher: 'microsoftwindowsdesktop', sku: 'win7-enterprise' },
    { offer: 'windowsserver', publisher: 'microsoftwindowsserver', sku: '2008-r2-sp1-smalldisk' },
];
const isSameImage = (left, right) => {
    return (!!left.offer &&
        !!right.offer &&
        caseInsensitiveCultureInvariantCompare(left.offer, right.offer) === 0 &&
        !!left.publisher &&
        !!right.publisher &&
        caseInsensitiveCultureInvariantCompare(left.publisher, right.publisher) === 0 &&
        !!left.sku &&
        !!right.sku &&
        caseInsensitiveCultureInvariantCompare(left.sku, right.sku) === 0);
};
// Returns whether idle is supported for a given image.
export const isIdleExtensionSupported = memoize((image) => {
    const { isWindows, isCustomImage, imageReference: imageRef } = image;
    if (isWindows) {
        if (imageRef) {
            return !unsupportedWindowsImages.some((o) => isSameImage(o, imageRef));
        }
        return true;
    }
    else if (imageRef) {
        // When image reference is provided check against list of supported images.
        return supportedLinuxImages.some((o) => isSameImage(o, imageRef));
    }
    else {
        // Otherwise return false except for custom images (which we can't inspect).
        return isCustomImage;
    }
});
// Returns IdleConfig based on current lab account settings.
export const getDefaultIdleConfig = memoize((state) => {
    const isLabAccount = isCurrentLabParentLabAccount(state);
    if (isLabAccount) {
        const labAccount = getCurrentLabAccount(state);
        return {
            shutdownOnDisconnectSupported: true,
            shutdownOnDisconnect: labAccount?.idleShutdownMode === Ml.IdleShutdownMode.OnDisconnect,
            shutdownOnDisconnectGracePeriod: labAccount?.idleGracePeriod?.asMinutes() ?? DefaultGracePeriod,
            shutdownOnNoConnectSupported: true,
            shutdownWhenNotConnected: isEnabled(labAccount?.enableNoConnectShutdown),
            shutdownOnNoConnectGracePeriod: labAccount?.idleNoConnectGracePeriod?.asMinutes() ?? DefaultGracePeriod,
            disconnectOnIdleSupported: true,
            disconnectOnIdleEnabled: isEnabled(labAccount?.enableDisconnectOnIdle),
            disconnectOnIdleGracePeriod: labAccount?.idleOsGracePeriod?.asMinutes() ?? DefaultGracePeriod,
        };
    }
    else {
        const labPlan = getCurrentLabPlan(state);
        return {
            shutdownOnDisconnectSupported: true,
            shutdownOnDisconnect: labPlan?.defaultAutoShutdownProfile?.shutdownOnDisconnect === LabServicesModels.EnableState.Enabled,
            shutdownOnDisconnectGracePeriod: !!labPlan?.defaultAutoShutdownProfile?.disconnectDelay
                ? moment.duration(labPlan.defaultAutoShutdownProfile.disconnectDelay).asMinutes()
                : DefaultGracePeriod,
            shutdownOnNoConnectSupported: true,
            shutdownWhenNotConnected: labPlan?.defaultAutoShutdownProfile?.shutdownWhenNotConnected ===
                LabServicesModels.EnableState.Enabled,
            shutdownOnNoConnectGracePeriod: !!labPlan?.defaultAutoShutdownProfile?.noConnectDelay
                ? moment.duration(labPlan.defaultAutoShutdownProfile.noConnectDelay).asMinutes()
                : DefaultGracePeriod,
            shutdownOnIdle: labPlan?.defaultAutoShutdownProfile?.shutdownOnIdle ?? LabServicesModels.ShutdownOnIdleMode.None,
            shutdownOnIdleGracePeriod: !!labPlan?.defaultAutoShutdownProfile?.idleDelay
                ? moment.duration(labPlan.defaultAutoShutdownProfile.idleDelay).asMinutes()
                : DefaultGracePeriod,
        };
    }
});
// Creates IdleConfig for a given VM image.
export function getImageIdleConfig(defaultConfig, image) {
    const isIdleSupported = isIdleExtensionSupported(image);
    const isWindows = image.isWindows;
    const isLinuxSIG = image.isCustomImage && !image.isWindows;
    return _.merge({}, defaultConfig, {
        shutdownOnDisconnectSupported: defaultConfig.shutdownOnDisconnectSupported && isIdleSupported,
        shutdownOnDisconnect: isLinuxSIG ? false : defaultConfig.shutdownOnDisconnect && isIdleSupported,
        shutdownOnNoConnectSupported: defaultConfig.shutdownOnNoConnectSupported && isIdleSupported,
        shutdownWhenNotConnected: isLinuxSIG ? false : defaultConfig.shutdownWhenNotConnected && isIdleSupported,
        disconnectOnIdleSupported: defaultConfig.disconnectOnIdleSupported && isWindows && isIdleSupported,
        disconnectOnIdleEnabled: isLinuxSIG
            ? false
            : defaultConfig.disconnectOnIdleEnabled && isWindows && isIdleSupported,
    });
}
// Creates partial LabParameters from IdleConfig.
export function idleConfigToLabParameters(config) {
    return {
        idleShutdownMode: config.shutdownOnDisconnectSupported
            ? config.shutdownOnDisconnect
                ? Ml.IdleShutdownMode.OnDisconnect
                : Ml.IdleShutdownMode.None
            : Ml.IdleShutdownMode.NotSupported,
        idleGracePeriod: duration(config.shutdownOnDisconnectGracePeriod, 'm'),
        enableNoConnectShutdown: toEnableState(config.shutdownOnNoConnectSupported && config.shutdownWhenNotConnected),
        idleNoConnectGracePeriod: duration(config.shutdownOnNoConnectGracePeriod, 'm'),
        enableDisconnectOnIdle: toEnableState(!!config.disconnectOnIdleSupported && !!config.disconnectOnIdleEnabled),
        idleOsGracePeriod: duration(config.disconnectOnIdleGracePeriod, 'm'),
    };
}
export function idleConfigToAutoShutdownProfile(config) {
    return {
        shutdownOnDisconnect: toEnableState(config.shutdownOnDisconnectSupported && config.shutdownOnDisconnect),
        shutdownWhenNotConnected: toEnableState(config.shutdownOnNoConnectSupported && config.shutdownWhenNotConnected),
        shutdownOnIdle: config.shutdownOnIdle,
        disconnectDelay: duration(config.shutdownOnDisconnectGracePeriod, 'm').toISOString(),
        noConnectDelay: duration(config.shutdownOnNoConnectGracePeriod, 'm').toISOString(),
        idleDelay: duration(config.shutdownOnIdleGracePeriod, 'm').toISOString(),
    };
}
// Creates IdleConfig for a given Lab.
export function getLabIdleConfig(defaultConfig, lab, isWindows) {
    const isIdleSupported = lab.idleShutdownMode !== Ml.IdleShutdownMode.NotSupported;
    return {
        shutdownOnDisconnectSupported: isIdleSupported && defaultConfig.shutdownOnDisconnectSupported,
        shutdownOnDisconnect: lab.idleShutdownMode === Ml.IdleShutdownMode.OnDisconnect,
        shutdownOnDisconnectGracePeriod: lab.idleGracePeriod?.asMinutes() ?? defaultConfig.shutdownOnDisconnectGracePeriod,
        shutdownOnNoConnectSupported: isIdleSupported && defaultConfig.shutdownOnNoConnectSupported,
        shutdownWhenNotConnected: isEnabled(lab.enableNoConnectShutdown),
        shutdownOnNoConnectGracePeriod: lab.idleNoConnectGracePeriod?.asMinutes() ?? defaultConfig.shutdownOnNoConnectGracePeriod,
        disconnectOnIdleSupported: isWindows && isIdleSupported && defaultConfig.disconnectOnIdleSupported,
        disconnectOnIdleEnabled: isEnabled(lab.enableDisconnectOnIdle),
        disconnectOnIdleGracePeriod: lab.idleOsGracePeriod?.asMinutes() ?? defaultConfig.disconnectOnIdleGracePeriod,
    };
}
export function getVNextLabIdleConfig(lab) {
    const isIdleSupported = true;
    return {
        shutdownOnDisconnectSupported: isIdleSupported,
        shutdownOnDisconnect: lab?.autoShutdownProfile?.shutdownOnDisconnect === LabServicesModels.EnableState.Enabled,
        shutdownOnDisconnectGracePeriod: moment.duration(lab?.autoShutdownProfile?.disconnectDelay).asMinutes(),
        shutdownOnNoConnectSupported: isIdleSupported,
        shutdownWhenNotConnected: lab?.autoShutdownProfile?.shutdownWhenNotConnected === LabServicesModels.EnableState.Enabled,
        shutdownOnNoConnectGracePeriod: moment.duration(lab?.autoShutdownProfile?.noConnectDelay).asMinutes(),
        shutdownOnIdle: lab?.autoShutdownProfile?.shutdownOnIdle ?? LabServicesModels.ShutdownOnIdleMode.None,
        shutdownOnIdleGracePeriod: moment.duration(lab?.autoShutdownProfile?.idleDelay).asMinutes(),
    };
}
// Creates partial Lab from IdleConfig.
export function idleConfigToLab(config) {
    return {
        idleShutdownMode: config.shutdownOnDisconnectSupported
            ? config.shutdownOnDisconnect
                ? Ml.IdleShutdownMode.OnDisconnect
                : Ml.IdleShutdownMode.None
            : Ml.IdleShutdownMode.NotSupported,
        idleGracePeriod: duration(config.shutdownOnDisconnectGracePeriod, 'm'),
        enableNoConnectShutdown: toEnableState(config.shutdownOnNoConnectSupported && config.shutdownWhenNotConnected),
        idleNoConnectGracePeriod: duration(config.shutdownOnNoConnectGracePeriod, 'm'),
        enableDisconnectOnIdle: toEnableState(!!config.disconnectOnIdleSupported && !!config.disconnectOnIdleEnabled),
        idleOsGracePeriod: duration(config.disconnectOnIdleGracePeriod, 'm'),
    };
}
// Returns whether at least one idle option is supported.
export function isIdleSupported(config) {
    return (config.disconnectOnIdleSupported || config.shutdownOnNoConnectSupported || config.shutdownOnDisconnectSupported);
}
export function isShutdownOnIdleEnabled(config) {
    return !!config.shutdownOnIdle && config.shutdownOnIdle !== LabServicesModels.ShutdownOnIdleMode.None;
}
