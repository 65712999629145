import { fork, all } from 'redux-saga/effects';
import { initializeSaga } from './startup';
import { professorInitializeSaga } from './professor-startup';
import { studentInitializeSaga } from './student-startup';
import { labAppStartupSaga } from './lab-app-startup';
import { routeChangeSaga } from './route-change';
import { processUrlFeaturesSaga, enableFeatureSaga, disableFeatureSaga } from './features';
import { fatalErrorSaga } from './fatal-error';
import { idleTimeoutSaga } from './idle-timeout';
import { loadTeamsOrDefaultThemeSaga, setThemeSaga } from './themes';
import { processQueryParamsSaga } from './process-query-params';
import { setLocaleSaga } from './locale';
export function* rootSaga() {
    yield all([
        fork(initializeSaga),
        fork(professorInitializeSaga),
        fork(studentInitializeSaga),
        fork(labAppStartupSaga),
        fork(routeChangeSaga),
        fork(processUrlFeaturesSaga),
        fork(enableFeatureSaga),
        fork(disableFeatureSaga),
        fork(fatalErrorSaga),
        fork(idleTimeoutSaga),
        fork(loadTeamsOrDefaultThemeSaga),
        fork(setThemeSaga),
        fork(processQueryParamsSaga),
        fork(setLocaleSaga),
    ]);
}
