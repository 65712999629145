import { DayOfWeek } from '@fluentui/react';
import _ from 'lodash';
import moment from 'moment';
import 'moment/min/locales';
import { defineMessages } from 'react-intl';
import { ManagedLabsModels } from '@azure-lab-services/ml-ts';
import { LabServicesModels } from 'lab-services';
import { timezoneMessages } from './timezone-messages';
const messages = defineMessages({
    goToTodayCalendarLabel: {
        id: 'GoToTodayCalendarLabel',
        defaultMessage: 'Go to today',
        description: 'Text for a button in the date picker that brings user back to todays date',
    },
    goToPreviousMonthCalendarLabel: {
        id: 'GoToPreviousMonthCalendarLabel',
        defaultMessage: 'Go to previous month',
        description: 'Text for a button in the date picker that selects previous month.',
    },
    goToNextMonthCalendarLabel: {
        id: 'GoToNextMonthCalendarLabel',
        defaultMessage: 'Go to next month',
        description: 'Text for a button in the date picker that selects next month.',
    },
    goToPreviousYearCalendarLabel: {
        id: 'GoToPreviousYearCalendarLabel',
        defaultMessage: 'Go to previous year',
        description: 'Text for a button in the date picker that selects previous year.',
    },
    goToNextYearCalendarLabel: {
        id: 'GoToNextYearCalendarLabel',
        defaultMessage: 'Go to next year',
        description: 'Text for a button in the date picker that selects next year.',
    },
    closeDatePickerLabel: {
        id: 'CloseDatePickerLabel',
        defaultMessage: 'Close date picker',
        description: 'Text for a button in the date picker that closes the control.',
    },
    dateOutOfBoundsMessage: {
        id: 'DateOutOfBoundsMessage',
        defaultMessage: 'The selected date is invalid. Start dates must not be after end dates.',
        description: 'Error message for when date is not within a valid range.',
    },
    dateRequiredMessage: {
        id: 'DateRequiredMessage',
        defaultMessage: 'Please select a date.',
        description: 'Validation message for the date picker indicating it is required.',
    },
    dateInvalidMessage: {
        id: 'DateInvalidMessage',
        defaultMessage: 'Please enter a valid date.',
        description: 'Validation message for when a string entered into the data picker control is not a valid date.',
    },
});
function getTimeDropdownOption(locale, timestamp) {
    const time = moment(timestamp).locale(locale).format('LT');
    const key = moment(timestamp).format('HHmm');
    return { key, text: time };
}
function getTimes(locale) {
    const times = [];
    let timestamp = new Date().setHours(0, 0, 0, 0);
    const dropdownOption = getTimeDropdownOption(locale, timestamp);
    times.push(dropdownOption);
    const thirtyMinutes = 30 * 60 * 1000;
    for (let i = 0; i < 47; i++) {
        timestamp += thirtyMinutes;
        const dropdownOption = getTimeDropdownOption(locale, timestamp);
        times.push(dropdownOption);
    }
    return times;
}
function getMonths(locale, format) {
    const months = [];
    for (let i = 0; i < 12; i++) {
        const month = moment().locale(locale).month(i).format(format);
        months.push(month);
    }
    return months;
}
function getDays(locale, format) {
    const days = [];
    for (let i = 0; i < 7; i++) {
        const day = moment().locale(locale).day(i).format(format);
        days.push(day);
    }
    return days;
}
function getFirstDayOfWeek(locale) {
    return moment.localeData(locale).firstDayOfWeek();
}
function getFirstDayForPicker(locale) {
    const firstDay = getFirstDayOfWeek(locale);
    switch (firstDay) {
        case 0:
            return DayOfWeek.Sunday;
        case 1:
            return DayOfWeek.Monday;
        case 2:
            return DayOfWeek.Tuesday;
        case 3:
            return DayOfWeek.Wednesday;
        case 4:
            return DayOfWeek.Thursday;
        case 5:
            return DayOfWeek.Friday;
        case 6:
            return DayOfWeek.Saturday;
        default:
            return DayOfWeek.Sunday;
    }
}
export function getBrowserOffset() {
    const date = new Date();
    const offset = date.getTimezoneOffset() / 60;
    return offset;
}
function getRandomScheduleName() {
    const randomSubstring = Math.random().toString(36).substr(2, 5);
    return `default_${randomSubstring}`;
}
function getDropdownTimezones(intl) {
    return Object.keys(timezoneMessages).map((key) => {
        const timezoneMessage = timezoneMessages[key];
        return {
            key: timezoneMessage.id,
            text: intl.formatMessage(timezoneMessage),
        };
    });
}
/**
 *  List of Windows timezones for dropdown.  The key is the english version of the
 *  Windows timezone that will be sent to the server and the text is the localized version of the timezone.
 */
export const dropdownTimezones = (intl) => getDropdownTimezones(intl);
/**
 *  List of times from 12:00am to 11:45pm in 15 minute increments, for time picker dropdown.
 *  The keys are military time en, the values are locale aware.
 */
export const dropdownTimes = (locale) => getTimes(locale);
/**
 *  List of days formatted like ['Su', 'Mo', etc.]
 *  Locale aware but first day starts at Sunday
 *  [todo] Extract locale from redux store
 */
export const daysShort = (locale) => getDays(locale, 'dd');
/**
 *  List of days formatted like ['S', 'M', etc.]
 *  Locale aware and first day starts at locale's first day.
 *  [todo] Extract locale from redux store
 */
export const daysShortLocaleAware = (locale) => {
    const days = [];
    const firstDay = getFirstDayOfWeek(locale);
    for (let i = 0; i < 7; i++) {
        const i_locale = (firstDay + i) % 7;
        const text = moment().locale(locale).day(i_locale).format('dd');
        const longText = moment().locale(locale).day(i_locale).format('dddd');
        const value = moment().day(i_locale).format('dddd');
        days.push({ text, longText, value });
    }
    return days;
};
/**
 *  Localized strings for the day picker
 */
export const dayPickerStrings = (locale, intl) => {
    return {
        months: getMonths(locale, 'MMMM'),
        shortMonths: getMonths(locale, 'MMM'),
        days: getDays(locale, 'dddd'),
        shortDays: daysShort(locale),
        goToToday: intl.formatMessage(messages.goToTodayCalendarLabel),
        prevMonthAriaLabel: intl.formatMessage(messages.goToPreviousMonthCalendarLabel),
        nextMonthAriaLabel: intl.formatMessage(messages.goToNextMonthCalendarLabel),
        prevYearAriaLabel: intl.formatMessage(messages.goToPreviousYearCalendarLabel),
        nextYearAriaLabel: intl.formatMessage(messages.goToNextYearCalendarLabel),
        closeButtonAriaLabel: intl.formatMessage(messages.closeDatePickerLabel),
        isRequiredErrorMessage: intl.formatMessage(messages.dateRequiredMessage),
        invalidInputErrorMessage: intl.formatMessage(messages.dateInvalidMessage),
        isOutOfBoundsErrorMessage: intl.formatMessage(messages.dateOutOfBoundsMessage),
    };
};
export function sortDays(locale, a, b) {
    const firstDay = getFirstDayOfWeek(locale);
    const _a = (moment().day(a).isoWeekday() - firstDay + 7) % 7;
    const _b = (moment().day(b).isoWeekday() - firstDay + 7) % 7;
    return _a - _b;
}
export function mapDayToLocaleDay(locale, day) {
    const translate = (dayNumber) => moment().locale(locale).day(dayNumber).format('dddd');
    switch (day.toLowerCase()) {
        case 'sunday':
            return translate(0);
        case 'monday':
            return translate(1);
        case 'tuesday':
            return translate(2);
        case 'wednesday':
            return translate(3);
        case 'thursday':
            return translate(4);
        case 'friday':
            return translate(5);
        case 'saturday':
            return translate(6);
        default:
            return translate(0);
    }
}
export function mapDayToLocaleDayShort(locale, day) {
    const translate = (dayNumber) => moment().locale(locale).day(dayNumber).format('ddd');
    switch (day.toLowerCase()) {
        case 'sunday':
            return translate(0);
        case 'monday':
            return translate(1);
        case 'tuesday':
            return translate(2);
        case 'wednesday':
            return translate(3);
        case 'thursday':
            return translate(4);
        case 'friday':
            return translate(5);
        case 'saturday':
            return translate(6);
        default:
            return translate(0);
    }
}
/**
 *  Format date function for date picker.
 *  Locale aware
 */
export function onFormatDate(locale, date) {
    return moment(date).locale(locale).format('ll');
}
/**
 *  Get first day of week.
 *  Locale aware
 *  [todo] Extract locale from redux store
 */
export const firstDayOfWeek = (locale) => getFirstDayOfWeek(locale);
/**
 *  Get first day of week for date picker.
 *  Locale aware
 *  [todo] Extract locale from redux store
 */
export const firstDayOfWeekForPicker = (locale) => getFirstDayForPicker(locale);
export function getDropdownKeyFromDate(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const _hours = _.padStart(String(hours), 2, '0');
    const _minutes = _.padStart(String(minutes), 2, '0');
    return `${_hours}${_minutes}`;
}
export const estimatedClassLength = 2;
export function getDefaultSchedule(timezone) {
    const defaultStartHour = 8;
    const start = new Date();
    start.setHours(defaultStartHour, 0, 0, 0);
    const end = new Date(start);
    end.setHours(defaultStartHour + estimatedClassLength, 0);
    const defaultSchedule = {
        start,
        end,
        startAction: {
            enableState: ManagedLabsModels.EnableState.Enabled,
            actionType: ManagedLabsModels.ScheduleActionType.Start,
        },
        endAction: {
            enableState: ManagedLabsModels.EnableState.Enabled,
            actionType: ManagedLabsModels.ScheduleActionType.Stop,
        },
        timeZoneId: timezone,
        name: getRandomScheduleName(),
    };
    return defaultSchedule;
}
export function getDefaultVNextSchedule(timezone) {
    const defaultStartHour = 8;
    const startAt = new Date();
    startAt.setHours(defaultStartHour, 0, 0, 0);
    const stopAt = new Date(startAt);
    stopAt.setHours(defaultStartHour + estimatedClassLength, 0);
    const defaultSchedule = {
        startAt,
        stopAt,
        timeZoneId: timezone,
        name: getRandomScheduleName(),
    };
    return defaultSchedule;
}
export function getDefaultWeeklySchedule(timezone) {
    const schedule = getDefaultSchedule(timezone);
    const day = moment().day(new Date().getDay()).format('dddd');
    const semesterLengthGuess = 4;
    const until = new Date(schedule.end);
    until.setMonth(schedule.start.getMonth() + semesterLengthGuess);
    schedule.recurrencePattern = {
        frequency: ManagedLabsModels.RecurrenceFrequency.Weekly,
        weekDays: [day],
        interval: 1,
        until,
    };
    return schedule;
}
export function getDefaultVNextWeeklySchedule(timezone) {
    const schedule = getDefaultVNextSchedule(timezone);
    const day = moment().day(new Date().getDay()).format('dddd');
    const semesterLengthGuess = 4;
    const expirationDate = new Date(schedule.stopAt);
    expirationDate.setMonth(schedule.startAt.getMonth() + semesterLengthGuess);
    schedule.recurrencePattern = {
        frequency: LabServicesModels.RecurrenceFrequency.Weekly,
        weekDays: [day],
        interval: 1,
        expirationDate,
    };
    return schedule;
}
/**
 *  Adjust schedule dates to be sent to server as server stores times in local time, without browser offset.
 */
export function adjustDatesForSchedule(browserOffset, schedule) {
    const adjustedSchedule = _.cloneDeep(schedule);
    // get just the hours portion of the offset, throwing away the decimal and preserving negative sign
    const hoursOffset = Math.trunc(browserOffset);
    // get the minutes portion of the offset => .5 = 30, preserving the negative sign
    const minutesOffset = 60 * (browserOffset % 1);
    // adjust local start time based on browser offset so the server will save in local time correctly
    const adjustedStartHours = schedule.start.getHours() - hoursOffset;
    const adjustedStartMinutes = schedule.start.getMinutes() - minutesOffset;
    adjustedSchedule.start.setHours(adjustedStartHours, adjustedStartMinutes);
    // adjust local end time based on browser offset so the server will save in local time correctly
    const adjustedEndHours = schedule.end.getHours() - hoursOffset;
    const adjustedEndMinutes = schedule.end.getMinutes() - minutesOffset;
    adjustedSchedule.end.setHours(adjustedEndHours, adjustedEndMinutes);
    if (adjustedSchedule.recurrencePattern && adjustedSchedule.recurrencePattern.until) {
        // adjust local recurrence end time based on browser offset so the server will save in local time correctly
        const adjustedUntilHours = adjustedSchedule.recurrencePattern.until.getHours() - hoursOffset;
        const adjustedUntilMinutes = adjustedSchedule.recurrencePattern.until.getMinutes() - minutesOffset;
        adjustedSchedule.recurrencePattern.until.setHours(adjustedUntilHours, adjustedUntilMinutes);
    }
    return adjustedSchedule;
}
/**
 *  Adjust schedule dates to be sent to server as server stores times in local time, without browser offset.
 */
export function adjustDatesForVNextSchedule(browserOffset, schedule) {
    const adjustedSchedule = _.cloneDeep(schedule);
    // get just the hours portion of the offset, throwing away the decimal and preserving negative sign
    const hoursOffset = Math.trunc(browserOffset);
    // get the minutes portion of the offset => .5 = 30, preserving the negative sign
    const minutesOffset = 60 * (browserOffset % 1);
    // adjust local start time based on browser offset so the server will save in local time correctly
    if (schedule.startAt) {
        const adjustedStartHours = schedule.startAt.getHours() - hoursOffset;
        const adjustedStartMinutes = schedule.startAt.getMinutes() - minutesOffset;
        adjustedSchedule.startAt.setHours(adjustedStartHours, adjustedStartMinutes);
    }
    // adjust local end time based on browser offset so the server will save in local time correctly
    const adjustedEndHours = schedule.stopAt.getHours() - hoursOffset;
    const adjustedEndMinutes = schedule.stopAt.getMinutes() - minutesOffset;
    adjustedSchedule.stopAt.setHours(adjustedEndHours, adjustedEndMinutes);
    if (adjustedSchedule.recurrencePattern && adjustedSchedule.recurrencePattern.expirationDate) {
        // adjust local recurrence end time based on browser offset so the server will save in local time correctly
        const adjustedUntilHours = adjustedSchedule.recurrencePattern.expirationDate.getHours() - hoursOffset;
        const adjustedUntilMinutes = adjustedSchedule.recurrencePattern.expirationDate.getMinutes() - minutesOffset;
        adjustedSchedule.recurrencePattern.expirationDate.setHours(adjustedUntilHours, adjustedUntilMinutes);
    }
    return adjustedSchedule;
}
const FlyoutUtilities = {
    estimatedClassLength,
    dropdownTimezones,
    dropdownTimes,
    daysShort,
    daysShortLocaleAware,
    dayPickerStrings,
    onFormatDate,
    firstDayOfWeek,
    firstDayOfWeekForPicker,
    getDefaultSchedule,
    getDefaultVNextSchedule,
    getDefaultWeeklySchedule,
    getDefaultVNextWeeklySchedule,
    adjustDatesForSchedule,
    adjustDatesForVNextSchedule,
    sortDays,
    mapDayToLocaleDay,
    mapDayToLocaleDayShort,
    getBrowserOffset,
};
export default FlyoutUtilities;
