import { DefaultButton, Icon, Panel, PanelType, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import SafeSpinButton from '../common/safe-spin-button';
import { SubmitButton } from '../common/submit-button';
import { getCustomTheme } from '../common/themes/selectors';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import Constants from '../utils/constants';
import './user-quota-flyout.css';
const messages = defineMessages({
    titleSingular: {
        id: 'UserListSingleUserQuotaFlyoutTitle',
        defaultMessage: 'Adjust quota for {name}',
        description: 'Title of the flyout dialog to add additional quota for a single user. {name} is the name of the user.',
    },
    titlePlural: {
        id: 'UserListMultipleUserQuotaFlyoutTitle',
        defaultMessage: 'Adjust quota for {userCount} users',
        description: 'Title of the flyout dialog to add addtional quota for multiple users. {userCount} is the number of users, always greater than 1.',
    },
    inputLabel: {
        id: 'UserListUserQuotaInputLabel',
        defaultMessage: 'Additional hours',
        description: 'Text above the input box for the quota.',
    },
});
const UserQuotaFlyoutInjected = (props) => {
    const { users, labQuota, isSubmitting, onDismiss, onSubmit, intl } = props;
    // calculate default quota to show when opening flyout - highest value set, or the system default if 0
    const additionalQuotaForUsers = users.map((user) => user.additionalQuota);
    const maxAdditionalQuota = Math.max(...additionalQuotaForUsers);
    const defaultAdditionalQuota = maxAdditionalQuota === 0 ? Constants.DefaultAdditionalUserQuota : maxAdditionalQuota;
    const [additionalQuota, setAdditionalQuota] = React.useState(defaultAdditionalQuota);
    const msg = intl.formatMessage;
    // close the flyout after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    const totalQuotaToDisplay = labQuota + additionalQuota;
    let title, description, currentUsageDescription, additionalQuotaLabel, totalQuotaLabel, isOverQuota, warningMessage;
    if (users.length < 1) {
        return null;
    }
    else if (users.length > 1) {
        const userUsage = users.map((user) => user.currentUsage);
        const currentMaxUsage = Math.max(...userUsage);
        isOverQuota = totalQuotaToDisplay <= currentMaxUsage;
        title = msg(messages.titlePlural, {
            userCount: users.length,
        });
        description = (<FormattedMessage id="UserListMultipleUserQuotaDescription" defaultMessage="This will set the number of lab hours granted to the selected users (in addition to the quota set for the lab)." description="Text at the top of flyout dialog to add user-level quota for multiple users."/>);
        const formattedMaxUsage = props.intl.formatNumber(currentMaxUsage, {
            maximumFractionDigits: 1,
        });
        if (formattedMaxUsage === '1') {
            currentUsageDescription = (<FormattedMessage id="UserListMultipleUserQuotaSingularUsageDescription" defaultMessage="The highest usage among the selected users is {maxCurrentUsage} lab hour." description="Text at the top of flyout dialog to describe the current user usage for 1 hour." values={{
                maxCurrentUsage: formattedMaxUsage,
            }}/>);
        }
        else {
            currentUsageDescription = (<FormattedMessage id="UserListMultipleUserQuotaPluralUsageDescription" defaultMessage="The highest usage among the selected users is {maxCurrentUsage} lab hours." description="Text at the top of flyout dialog to describe the current user usage for 0 or multiple hours." values={{
                maxCurrentUsage: formattedMaxUsage,
            }}/>);
        }
        additionalQuotaLabel = (<FormattedMessage id="UserListUserQuotaFlyoutAdditionalQuotaPluralLabel" defaultMessage="Additional quota for selected users:" description="The label for the new value the user is configuring additional quota to for multiple users."/>);
        totalQuotaLabel = (<FormattedMessage id="UserListUserQuotaFlyoutTotalQuotaPluralLabel" defaultMessage="Total quota for selected users:" description="The label for the new total quota that the users will have if the user applies this configuration."/>);
        warningMessage = (<FormattedMessage id="UserListUserQuotaFlyoutWarningPluralLabel" defaultMessage="1 or more users have reached the total quota set above. They won’t be able to access their virtual machine(s) unless you provide additional hours." description="The warning message for when multiple users are selected and have more usage than total quota."/>);
    }
    else {
        const user = users[0];
        const displayName = user.name.length > 0 && user.name !== Constants.DefaultUserName ? user.name : user.email;
        isOverQuota = totalQuotaToDisplay <= user.currentUsage;
        title = msg(messages.titleSingular, {
            name: displayName,
        });
        description = (<FormattedMessage id="UserListSingularUserQuotaDescription" defaultMessage="This will set the number of lab hours granted to this user (in addition to the quota set for the lab)." description="Text at the top of flyout dialog to add user-level quota for a single user."/>);
        const formattedCurrentUsage = props.intl.formatNumber(user.currentUsage, {
            maximumFractionDigits: 1,
        });
        if (formattedCurrentUsage === '1') {
            currentUsageDescription = (<FormattedMessage id="UserListSingularUserQuotaSingularUsageDescription" defaultMessage="This user has currently consumed {currentUsage} lab hour." description="Text at the top of flyout dialog to add user-level quota for a single user for 1 hour." values={{
                currentUsage: formattedCurrentUsage,
            }}/>);
        }
        else {
            currentUsageDescription = (<FormattedMessage id="UserListSingularUserQuotaPluralsUsageDescription" defaultMessage="This user has currently consumed {currentUsage} lab hours." description="Text at the top of flyout dialog to add user-level quota for a single user for 0 or multiple hour." values={{
                currentUsage: formattedCurrentUsage,
            }}/>);
        }
        additionalQuotaLabel = (<FormattedMessage id="UserListUserQuotaFlyoutAdditionalQuotaSingularLabel" defaultMessage="Additional quota for this user:" description="The label for the new value the user is configuring additional quota to for a single user."/>);
        totalQuotaLabel = (<FormattedMessage id="UserListUserQuotaFlyoutTotalQuotaSingularsLabel" defaultMessage="Total quota for this user:" description="The label for the new total quota that the users will have if the user applies this configuration."/>);
        warningMessage = (<FormattedMessage id="UserListUserQuotaFlyoutWarningSingularLabel" defaultMessage="This user has reached the total quota set above. They won’t be able to access their virtual machine unless you provide additional hours." description="The warning message for when a single user is selected and has more usage than total quota."/>);
    }
    const labQuotaValueMessage = labQuota === 1 ? (<FormattedMessage id="UserListUserQuotaFlyoutLabQuotaSingularValue" defaultMessage="{labQuota} hour" description="The current value (1) of the lab quota." values={{
        labQuota: props.intl.formatNumber(labQuota, {
            maximumFractionDigits: 0,
        }),
    }}/>) : (<FormattedMessage id="UserListUserQuotaFlyoutLabQuotaPluralValue" defaultMessage="{labQuota} hours" description="The current value (0 or more than 1) of the lab quota." values={{
        labQuota: props.intl.formatNumber(labQuota, {
            maximumFractionDigits: 0,
        }),
    }}/>);
    const additionalQuotaValueMessage = additionalQuota === 1 ? (<FormattedMessage id="UserListUserQuotaFlyoutAdditionalQuotaSingularValue" defaultMessage="{additionalQuota} hour" description="The new value (1) the user is configuring additional quota to." values={{
        additionalQuota: props.intl.formatNumber(additionalQuota, {
            maximumFractionDigits: 0,
        }),
    }}/>) : (<FormattedMessage id="UserListUserQuotaFlyoutAdditionalQuotaMultipleValue" defaultMessage="{additionalQuota} hours" description="The new value (0 or more than 1) the user is configuring additional quota to." values={{
        additionalQuota: props.intl.formatNumber(additionalQuota, {
            maximumFractionDigits: 0,
        }),
    }}/>);
    const totalQuotaValueMessage = totalQuotaToDisplay === 1 ? (<FormattedMessage id="UserListUserQuotaFlyoutTotalQuotaSingularValue" defaultMessage="{totalQuota} hour" description="The new total quota (1) that the users will have if the user applies this configuration." values={{
        totalQuota: props.intl.formatNumber(labQuota + additionalQuota, {
            maximumFractionDigits: 0,
        }),
    }}/>) : (<FormattedMessage id="UserListUserQuotaFlyoutTotalQuotaPluralValue" defaultMessage="{totalQuota} hours" description="The new total quota (0 or more than 1) that the users will have if the user applies this configuration." values={{
        totalQuota: props.intl.formatNumber(labQuota + additionalQuota, {
            maximumFractionDigits: 0,
        }),
    }}/>);
    return (<Panel type={PanelType.custom} customWidth={`${Constants.DefaultFlyoutWidth}px`} isOpen={true} isLightDismiss={!isSubmitting} onDismiss={isSubmitting ? undefined : onDismiss} headerText={title} closeButtonAriaLabel={msg(commonMessages.cancel)} styles={getCustomTheme().panelStyles} onRenderFooterContent={() => (<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '8px' }}>
                    <Stack.Item>
                        <SubmitButton default={true} isSubmitting={isSubmitting} buttonText={msg(commonMessages.apply)} onClick={() => onSubmit(additionalQuota)}/>
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton disabled={isSubmitting} onClick={onDismiss}>
                            {msg(commonMessages.cancel)}
                        </DefaultButton>
                    </Stack.Item>
                </Stack>)}>
            <div className="user-quota__description">{description}</div>
            <div className="user-quota__description">{currentUsageDescription}</div>
            <SafeSpinButton label={msg(messages.inputLabel)} disabled={isSubmitting} autoFocus value={additionalQuota} onChange={setAdditionalQuota} min={Constants.MinAdditionalUserQuota} max={Constants.MaxAdditionalUserQuota}/>
            <div className="user-quota__calculation">
                <span className="user-quota__quota-calc-label">
                    <FormattedMessage id="UserListUserQuotaFlyoutLabQuotaLabel" defaultMessage="Lab quota:" description="The label for the current value of the lab quota."/>
                </span>
                <span>{labQuotaValueMessage}</span>
            </div>
            <div>
                <span className="user-quota__quota-calc-label">{additionalQuotaLabel}</span>
                <span>{additionalQuotaValueMessage}</span>
            </div>
            <div>
                <span className="user-quota__quota-calc-label">{totalQuotaLabel}</span>
                <span className="user-quota__total">{totalQuotaValueMessage}</span>
            </div>
            {isOverQuota && (<div className="user-quota__warning">
                    <Icon iconName="Warning" className="user-quota__warning-icon" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '19px',
            color: '#D83B01',
        },
    }}/>
                    <span className="user-quota__warning-message">{warningMessage}</span>
                </div>)}
        </Panel>);
};
export const UserQuotaFlyout = injectIntl(UserQuotaFlyoutInjected);
export default UserQuotaFlyout;
