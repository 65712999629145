import { UserActionType, } from './user-actions';
export const listUsers = (labId) => ({
    type: UserActionType.LIST_USERS,
    labId,
});
export const listUsersCancelled = () => ({
    type: UserActionType.LIST_USERS_CANCELLED,
});
export const listUsersSuccess = (users) => ({
    type: UserActionType.LIST_USERS_SUCCESS,
    users,
});
export const listVNextUsersSuccess = (users) => ({
    type: UserActionType.LIST_USERS_SUCCESS,
    users,
});
export const listUsersError = (error) => ({
    type: UserActionType.LIST_USERS_ERROR,
    error,
});
export const deleteUsers = (userIds) => ({
    type: UserActionType.DELETE_USERS,
    userIds,
});
export const deleteUsersSuccess = (userIds) => ({
    type: UserActionType.DELETE_USERS_SUCCESS,
    userIds,
});
export const deleteUsersError = (errors) => ({
    type: UserActionType.DELETE_USERS_ERROR,
    errors,
});
export const addUsers = (labId, emailAddresses) => ({
    type: UserActionType.ADD_USERS,
    labId,
    emailAddresses,
});
export const addUsersSuccess = (users) => ({
    type: UserActionType.ADD_USERS_SUCCESS,
    users,
});
export const addVNextUsersSuccess = (users) => ({
    type: UserActionType.ADD_USERS_SUCCESS,
    users,
});
export const addVNextUsersError = (errors) => ({
    type: UserActionType.ADD_USERS_ERROR,
    errors,
});
export const addUsersError = (error, failedEmails) => ({
    type: UserActionType.ADD_USERS_ERROR,
    error,
    failedEmails,
});
export const inviteUsers = (labId, userIds, extraMessage) => ({
    type: UserActionType.INVITE_USERS,
    labId,
    userIds,
    extraMessage,
});
export const inviteUsersSuccess = (userIds) => ({
    type: UserActionType.INVITE_USERS_SUCCESS,
    userIds,
});
export const inviteUsersError = (error) => ({
    type: UserActionType.INVITE_USERS_ERROR,
    error,
});
export const inviteVNextUsersError = (errors) => ({
    type: UserActionType.INVITE_USERS_ERROR,
    errors,
});
export const clearLoadUsersError = () => ({
    type: UserActionType.CLEAR_LOAD_USERS_ERROR,
});
export const clearAddUsersError = (id) => ({
    type: UserActionType.CLEAR_ADD_USERS_ERROR,
    id,
});
export const clearDeleteUsersError = (id) => ({
    type: UserActionType.CLEAR_DELETE_USERS_ERROR,
    id,
});
export const clearInviteUsersError = (id) => ({
    type: UserActionType.CLEAR_INVITE_USERS_ERROR,
    id,
});
export const pollUsersStart = (labId, intervalMs, initialDelayMs) => ({
    type: UserActionType.POLL_USERS_START,
    labId,
    intervalMs,
    initialDelayMs,
});
export const pollUsersStop = () => ({
    type: UserActionType.POLL_USERS_STOP,
});
export const pollUsersCancelled = () => ({
    type: UserActionType.POLL_USERS_CANCELLED,
});
export const updateAdditionalQuotaForUsers = (userIds, additionalQuota) => ({
    type: UserActionType.UPDATE_ADDITIONAL_QUOTA,
    userIds,
    additionalQuota,
});
export const updateAdditionalQuotaForUsersSuccess = (users) => ({
    type: UserActionType.UPDATE_ADDITIONAL_QUOTA_SUCCESS,
    users,
});
export const updateVNextAdditionalQuotaForUsersSuccess = (users) => ({
    type: UserActionType.UPDATE_ADDITIONAL_QUOTA_SUCCESS,
    users,
});
export const updateAdditionalQuotaForUsersError = (errors) => ({
    type: UserActionType.UPDATE_ADDITIONAL_QUOTA_ERROR,
    errors,
});
export const clearUpdateAdditionalQuotaForUsersError = (userId) => ({
    type: UserActionType.CLEAR_UPDATE_ADDITIONAL_QUOTA_ERROR,
    id: userId,
});
const actions = {
    listUsers,
    listUsersCancelled,
    listUsersSuccess,
    listUsersError,
    deleteUsers,
    deleteUsersSuccess,
    deleteUsersError,
    addUsers,
    addUsersSuccess,
    addUsersError,
    clearLoadUsersError,
    clearAddUsersError,
    clearDeleteUsersError,
    clearInviteUsersError,
    pollUsersStart,
    pollUsersStop,
    pollUsersCancelled,
    listVNextUsersSuccess,
    addVNextUsersSuccess,
    updateVNextAdditionalQuotaForUsersSuccess,
    addVNextUsersError,
    inviteVNextUsersError,
};
export default actions;
