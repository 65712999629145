import { Record } from 'immutable';
import { AuthenticationState, UserType } from '../../data/models/identity';
import { defaultSettings } from '../../data/models/user-settings';
import { IdentityActionType, } from '../actions/identity/identity-actions';
function signIn(state, action) {
    return state.merge({
        state: AuthenticationState.Authenticating,
    });
}
function signInSuccess(state, action) {
    const { account } = action;
    const { userName: displayId, name, idTokenClaims } = account;
    const tenantId = idTokenClaims['tid'];
    return state.merge({
        state: AuthenticationState.Authenticated,
        displayId,
        name,
        tenantId,
    });
}
function signInRedirecting(state, action) {
    return state.merge({
        state: AuthenticationState.Redirecting,
    });
}
function signInPoppingUp(state, action) {
    return state.merge({
        state: AuthenticationState.PoppingUp,
    });
}
function signInFailed(state, action) {
    return state.merge({
        state: AuthenticationState.AuthenticationFailed,
    });
}
function signInError(state, action) {
    return state.merge({
        state: AuthenticationState.AuthenticationError,
    });
}
function signOut(state, action) {
    return state.merge({
        state: AuthenticationState.SigningOut,
    });
}
function resolveSignInReturnUrl(state, action) {
    const { returnUrl } = action;
    if (returnUrl) {
        return state.merge({
            returnUrl,
        });
    }
    return state;
}
function loadUserPhotoSuccess(state, action) {
    const { imageUrl } = action;
    return state.merge({
        imageUrl,
    });
}
function updateUserSettings(state, action) {
    const { settings: settingsUpdate } = action;
    let settings = state.get('settings');
    settings = settings.merge(settingsUpdate);
    const settingsExist = state.get('settingsExist');
    if (settingsExist) {
        return state.merge({
            settings,
            settingsExist,
        });
    }
    else {
        return state.merge({
            settings,
        });
    }
}
function loadUserSettingsError(state, action) {
    const { cachedSettings } = action;
    if (!!cachedSettings) {
        let settings = state.get('settings');
        settings = settings.merge(cachedSettings);
        return state.merge({
            settings,
        });
    }
    return state;
}
function updateUserSettingsExists(state, action) {
    return state.merge({
        settingsExist: true,
    });
}
function getAccessTokenRedirecting(state, action) {
    return state.merge({
        state: AuthenticationState.Redirecting,
    });
}
function setTenantId(state, action) {
    const { tenantId } = action;
    return state.merge({
        tenantId,
    });
}
function setLoginHint(state, action) {
    const { loginHint } = action;
    return state.merge({
        loginHint,
    });
}
function clearUserSession(state, action) {
    const authState = state.get('state');
    return initialState.merge({
        state: authState === AuthenticationState.SigningOut ? authState : AuthenticationState.AuthenticationFailed,
    });
}
function selectTenant(state, action) {
    const { isRedirect } = action;
    const loginHint = state.get('loginHint');
    return initialState.merge({
        state: isRedirect ? AuthenticationState.Redirecting : AuthenticationState.PoppingUp,
        loginHint,
    });
}
function requestUserConsent(state, action) {
    return state.merge({
        state: AuthenticationState.Authenticating,
    });
}
function requestUserConsentSuccess(state, action) {
    return state.merge({
        state: AuthenticationState.Authenticated,
    });
}
function requestUserConsentFailed(state, action) {
    return state.merge({
        state: AuthenticationState.AuthenticationFailed,
    });
}
function requestUserConsentError(state, action) {
    return state.merge({
        state: AuthenticationState.AuthenticationError,
    });
}
function lmsCompleteSignOut(state, action) {
    return state.merge({
        state: AuthenticationState.NotStarted,
    });
}
const initialState = Record({
    state: AuthenticationState.NotStarted,
    returnUrl: '',
    type: UserType.Unknown,
    displayId: '',
    name: '',
    imageUrl: '',
    tenantId: '',
    loginHint: '',
    settingsExist: false,
    settings: Record(defaultSettings)(),
})();
export const identityReducer = (state = initialState, action) => {
    switch (action.type) {
        case IdentityActionType.SIGN_IN:
            return signIn(state, action);
        case IdentityActionType.SIGN_IN_SUCCESS:
            return signInSuccess(state, action);
        case IdentityActionType.SIGN_IN_REDIRECTING:
            return signInRedirecting(state, action);
        case IdentityActionType.SIGN_IN_FAILED:
            return signInFailed(state, action);
        case IdentityActionType.SIGN_IN_ERROR:
            return signInError(state, action);
        case IdentityActionType.SIGN_OUT:
            return signOut(state, action);
        case IdentityActionType.RESOLVE_SIGN_IN_RETURN_URL:
            return resolveSignInReturnUrl(state, action);
        case IdentityActionType.LOAD_USER_PHOTO_SUCCESS:
            return loadUserPhotoSuccess(state, action);
        case IdentityActionType.LOAD_USER_SETTINGS_SUCCESS:
            return updateUserSettings(state, action);
        case IdentityActionType.LOAD_USER_SETTINGS_ERROR:
            return loadUserSettingsError(state, action);
        case IdentityActionType.UPDATE_USER_SETTINGS:
            return updateUserSettings(state, action);
        case IdentityActionType.UPDATE_USER_SETTINGS_EXISTS:
            return updateUserSettingsExists(state, action);
        case IdentityActionType.GET_ACCESS_TOKEN_REDIRECTING:
            return getAccessTokenRedirecting(state, action);
        case IdentityActionType.SET_TENANT_ID:
            return setTenantId(state, action);
        case IdentityActionType.SET_LOGIN_HINT:
            return setLoginHint(state, action);
        case IdentityActionType.CLEAR_USER_SESSION:
            return clearUserSession(state, action);
        case IdentityActionType.SELECT_TENANT:
            return selectTenant(state, action);
        case IdentityActionType.REQUEST_USER_CONSENT:
            return requestUserConsent(state, action);
        case IdentityActionType.REQUEST_USER_CONSENT_SUCCESS:
            return requestUserConsentSuccess(state, action);
        case IdentityActionType.REQUEST_USER_CONSENT_FAILED:
            return requestUserConsentFailed(state, action);
        case IdentityActionType.REQUEST_USER_CONSENT_ERROR:
            return requestUserConsentError(state, action);
        case IdentityActionType.SIGN_IN_POPPING_UP:
            return signInPoppingUp(state, action);
        case IdentityActionType.LMS_COMPLETE_SIGN_OUT:
            return lmsCompleteSignOut(state, action);
        default:
            return state;
    }
};
export default identityReducer;
