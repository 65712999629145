import memoize from 'memoize-one';
import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { getDefaultIdleConfig, getLabIdleConfig, getVNextLabIdleConfig } from '../common/shutdown-policy/selectors';
import { ConnectionType } from '../data/models/environment-common';
import { isBastionFeatureEnabled, isTeamsOrLmsMode, storeHasLoadError, storeIsLoading, } from '../redux/selectors/common-selectors';
import { getCurrentLab, isCurrentLabReadOnly } from '../redux/selectors/lab-selectors';
import { getSearch } from '../redux/selectors/route-selector';
import PowerState from '../utils/power-state';
import { isHealthyState, ProvisioningState } from '../utils/provisioning-state';
import { getPowerState } from '../template/template-selectors';
import { isCurrentLabParentLabAccount } from '../redux/selectors/lab-parent-resource-selectors';
import { LabServicesModels } from 'lab-services';
import { getCurrentLabUpdateError, shouldDisableCurrentLabUpdate } from '../redux/selectors/vnext/lab-selectors';
function getVNextConnectionTypes(connectionProfile) {
    const connectionTypes = [];
    if (!connectionProfile) {
        return connectionTypes;
    }
    if (connectionProfile.clientRdpAccess === LabServicesModels.ConnectionType.Public) {
        connectionTypes.push(ConnectionType.Rdp);
    }
    if (connectionProfile.clientSshAccess === LabServicesModels.ConnectionType.Public) {
        connectionTypes.push(ConnectionType.Ssh);
    }
    if (connectionProfile.webRdpAccess === LabServicesModels.ConnectionType.Public) {
        connectionTypes.push(ConnectionType.RdpInBrowser);
    }
    if (connectionProfile.webSshAccess === LabServicesModels.ConnectionType.Public) {
        connectionTypes.push(ConnectionType.SshInBrowser);
    }
    return connectionTypes;
}
export const getSettingsPageViewModel = memoize((state) => {
    const isVNext = !isCurrentLabParentLabAccount(state);
    const currentLab = getCurrentLab(state);
    const isTeamsOrLmsIntegrationEnabled = isTeamsOrLmsMode(state);
    const isBastionEnabled = isBastionFeatureEnabled(state);
    const isReadOnly = isCurrentLabReadOnly(state);
    if (!isVNext) {
        const lab = currentLab;
        const labStore = state.get('labStore');
        const updateError = labStore.get('updateError');
        const loadState = labStore.get('loadState');
        const isUpdating = labStore.get('isUpdating');
        const templateStore = state.get('templateStore');
        const currentTemplate = templateStore.get('currentTemplate');
        const templates = templateStore.get('templates');
        const template = templates.find((o) => o.id === currentTemplate);
        const showLoadError = !storeIsLoading(loadState) &&
            (!lab ||
                !isHealthyState(lab.provisioningState) ||
                (!!template && !isHealthyState(template.provisioningState)));
        const isCreating = lab?.provisioningState === ProvisioningState.Creating ||
            template?.provisioningState === ProvisioningState.Creating;
        const isApplyingSettings = lab?.idleConfigState === ProvisioningState.Updating;
        const idleUpdateFailed = lab?.idleConfigState === ProvisioningState.Failed;
        const isVnetAttached = !!lab?.virtualNetworkResourceId;
        const powerState = getPowerState(template);
        const isRunning = powerState === PowerState.Running;
        const isPublishing = template?.publishingState === Ml.PublishingState.Publishing;
        const isScaling = template?.publishingState === Ml.PublishingState.Scaling;
        const isWindows = template?.resourceSettings?.osType === Ml.OsType.Windows;
        const connectionTypes = [];
        if (!!lab && !showLoadError) {
            const policy = lab.connectivityPolicy;
            if (policy) {
                if (policy.rdpEnabled === Ml.EnableState.Enabled) {
                    connectionTypes.push(ConnectionType.Rdp);
                }
                if (policy.rdpInBrowserEnabled === Ml.EnableState.Enabled) {
                    connectionTypes.push(ConnectionType.RdpInBrowser);
                }
                if (policy.sshEnabled === Ml.EnableState.Enabled) {
                    connectionTypes.push(ConnectionType.Ssh);
                }
                if (policy.sshInBrowserEnabled === Ml.EnableState.Enabled) {
                    connectionTypes.push(ConnectionType.SshInBrowser);
                }
            }
            if (!policy || connectionTypes.length === 0) {
                if (isWindows) {
                    connectionTypes.push(ConnectionType.Rdp);
                }
                else {
                    connectionTypes.push(ConnectionType.Ssh);
                    if (template?.resourceSettings?.linuxRdpEnabled === Ml.EnableState.Enabled) {
                        connectionTypes.push(ConnectionType.Rdp);
                    }
                }
            }
        }
        const defaultIdleConfig = getDefaultIdleConfig(state);
        const idleConfig = lab ? getLabIdleConfig(defaultIdleConfig, lab, isWindows) : defaultIdleConfig;
        const shouldDisableLabUpdate = isCreating || isPublishing || isScaling || isApplyingSettings || isUpdating;
        return {
            showLoadError,
            isRunning,
            isCreating,
            isPublishing,
            isScaling,
            isApplyingSettings,
            idleUpdateFailed,
            isUpdating,
            updateError,
            isWindows,
            connectionTypes,
            idleConfig,
            isBastionEnabled,
            isVnetAttached,
            isReadOnly,
            isTeamsOrLmsIntegrationEnabled,
            lab,
            shouldDisableLabUpdate,
        };
    }
    else {
        const lab = currentLab;
        const labStore = state.get('vNextLabStore');
        const updateError = getCurrentLabUpdateError(state);
        const labLoadState = labStore.get('loadState');
        const isUpdating = labStore.get('isUpdating');
        const isLoading = storeIsLoading(labLoadState);
        const showLoadError = storeHasLoadError(labLoadState) ||
            (!storeIsLoading(labLoadState) && !isHealthyState(lab?.provisioningState));
        const isCreating = lab?.provisioningState === LabServicesModels.ProvisioningState.Creating;
        const isApplyingSettings = lab?.provisioningState === ProvisioningState.Updating &&
            (lab.state === LabServicesModels.LabState.Draft || lab.state === LabServicesModels.LabState.Published);
        const isVnetAttached = !!lab?.networkProfile?.subnetId;
        const labState = lab?.pendingLabState ?? lab?.state;
        const isPublishing = labState === LabServicesModels.LabState.Publishing;
        const isScaling = labState === LabServicesModels.LabState.Scaling;
        const isWindows = lab?.virtualMachineProfile?.osType === LabServicesModels.OsType.Windows;
        let connectionTypes = [];
        if (!!lab && !showLoadError) {
            connectionTypes = getVNextConnectionTypes(lab.connectionProfile);
        }
        const idleConfig = getVNextLabIdleConfig(lab);
        const shouldDisableLabUpdate = shouldDisableCurrentLabUpdate(state);
        return {
            isLoading,
            showLoadError,
            isCreating,
            isPublishing,
            isScaling,
            isApplyingSettings,
            isUpdating,
            updateError,
            isWindows,
            connectionTypes,
            idleConfig,
            isBastionEnabled,
            isVnetAttached,
            isReadOnly,
            isTeamsOrLmsIntegrationEnabled,
            lab,
            shouldDisableLabUpdate,
        };
    }
});
export const getSettingsPageContainerModel = memoize((state) => {
    const search = getSearch(state);
    const settingsPageViewModel = getSettingsPageViewModel(state);
    const isVNext = !isCurrentLabParentLabAccount(state);
    return {
        search,
        settingsPageViewModel,
        isVNext,
    };
});
