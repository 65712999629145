import { call, put, select } from 'redux-saga/effects';
import { loadSubscriptionLabPlansError } from '../../actions/lab-plan/lab-plan-action-creators';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { listSubscriptionLabPlans } from '../../../data/providers/vnext/arm-api/lab-plan-provider';
export function* loadSubscriptionLabPlans(subscription, includeRoles) {
    let labPlans = [];
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        labPlans = yield call(listSubscriptionLabPlans, subscription, accessToken, locale, language, includeRoles);
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, subscription.subscriptionId, FailureOperation.ListLabPlansForSubscription, err ? err.code : undefined);
        yield put(loadSubscriptionLabPlansError(subscription, error));
    }
    return labPlans;
}
