import { all, fork } from 'redux-saga/effects';
import { getLabAccountSaga } from './get-lab-account';
import { listLabAccountsSaga } from './list-lab-accounts';
import { rememberLabAccountSaga } from './remember-lab-account';
import { refreshSubscriptionsAndLabAccountsSaga } from './refresh-subscriptions-and-lab-accounts';
export function* rootSaga() {
    yield all([
        fork(listLabAccountsSaga),
        fork(getLabAccountSaga),
        fork(rememberLabAccountSaga),
        fork(refreshSubscriptionsAndLabAccountsSaga),
    ]);
}
