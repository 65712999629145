import memoize from 'memoize-one';
import { AuthenticationState } from '../../data/models/identity';
import { isLmsStudent } from './lti-selectors';
export const getTenantId = memoize((state) => state.get('identityStore').get('tenantId'));
export const getDisplayId = memoize((state) => state.get('identityStore').get('displayId'));
export const getLoginHint = memoize((state) => state.get('identityStore').get('loginHint'));
export const getCurrentReturnUrl = memoize((state) => state.get('identityStore').get('returnUrl'));
export const isUserAuthenticated = memoize((state) => state.get('identityStore').get('state') === AuthenticationState.Authenticated);
export const doesSettingsExist = memoize((state) => state.get('identityStore').get('settingsExist'));
export const getUserSettings = memoize((state) => state.get('identityStore').get('settings'));
export const getAuthenticationState = memoize((state) => {
    const isLtiSignInOnly = isLmsStudent(state);
    if (isLtiSignInOnly) {
        const ltiSignInState = state.get('ltiStore').get('authenticationState');
        return ltiSignInState;
    }
    else {
        const aadSignInState = state.get('identityStore').get('state');
        return aadSignInState;
    }
});
