import { DefaultButton, Panel, PanelType, Stack } from '@fluentui/react';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { defineMessages, injectIntl } from 'react-intl';
import { SafeSpinButton } from '../common/safe-spin-button';
import { SubmitButton } from '../common/submit-button';
import { getCustomTheme } from '../common/themes/selectors';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import Constants from '../utils/constants';
const messages = defineMessages({
    title: {
        id: 'UserListQuotaFlyoutTitle',
        defaultMessage: 'Quota per user',
        description: 'Title of the flyout dialog to set user specific quota.',
    },
    label: {
        id: 'UserListQuotaLabel',
        defaultMessage: 'Set the number of lab hours available to each user outside of scheduled class time.',
        description: 'Text at the top of flyout dialog to set user specific quota.',
    },
});
const QuotaFlyoutInjected = (props) => {
    const { isSubmitting, onDismiss, onSubmit, quota: initialQuota, minQuota, maxQuota, intl } = props;
    const msg = intl.formatMessage;
    // close the flyout after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    return (<Form initialValues={{ quota: initialQuota }} onSubmit={(values) => onSubmit(+values.quota)}>
            {(formProps) => (<Panel type={PanelType.custom} customWidth={`${Constants.DefaultFlyoutWidth}px`} isOpen={true} isLightDismiss={!isSubmitting} onDismiss={isSubmitting ? undefined : onDismiss} headerText={msg(messages.title)} closeButtonAriaLabel={msg(commonMessages.cancel)} styles={getCustomTheme().panelStyles} onRenderFooterContent={() => (<Stack horizontal horizontalAlign="end" tokens={{ childrenGap: '8px' }}>
                            <Stack.Item>
                                <SubmitButton default={true} disabled={formProps.pristine || !formProps.valid} isSubmitting={isSubmitting} buttonText={msg(commonMessages.save)} onClick={() => formProps.handleSubmit()}/>
                            </Stack.Item>
                            <Stack.Item>
                                <DefaultButton disabled={isSubmitting} onClick={onDismiss}>
                                    {msg(commonMessages.cancel)}
                                </DefaultButton>
                            </Stack.Item>
                        </Stack>)}>
                    <Field name="quota">
                        {(fieldProps) => (<SafeSpinButton label={msg(messages.label)} disabled={isSubmitting} autoFocus min={minQuota} max={maxQuota} value={fieldProps.input.value} onChange={fieldProps.input.onChange}/>)}
                    </Field>
                </Panel>)}
        </Form>);
};
export const QuotaFlyout = injectIntl(QuotaFlyoutInjected);
export default QuotaFlyout;
