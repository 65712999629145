import { all, call, cancel, cancelled, delay, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { VmState } from '../../../data/models/environment-common';
import { OperationResultStatus } from '../../../data/models/common';
import UserEnvironmentProvider from '../../../data/providers/user-environment-provider';
import { listUserEnvironments as listEnvironmentsActionCreator, listUserEnvironmentsCancelled, listUserEnvironmentsError, listUserEnvironmentsSuccess, pollListUserEnvironmentsCancelled, resetUserEnvironmentPasswordFailed, stopUserEnvironmentFailed, startUserEnvironmentFailed, listVCurrentUserVirtualMachines, listVCurrentUserVirtualMachinesFailures, listVNextUserVirtualMachines, listVNextUserVirtualMachinesSuccess, listVNextUserVirtualMachinesFailure, listAllUserVirtualMachinesSuccess, } from '../../actions/user-environment/user-environment-action-creators';
import { UserEnvironmentActionType, } from '../../actions/user-environment/user-environment-actions';
import { getLanguage, getLocale, isLmsHost } from '../../selectors/common-selectors';
import { getUserEnvironment as getUserEnvironmentSelector } from '../../selectors/user-environment-selectors';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { listVirtualMachines } from '../../../data/providers/vnext/labs-api/user-virtual-machine-provider';
import { featureBasedTakeLatest } from '../../effects/feature-based';
import { OperationsResultActionType, } from '../../../redux/actions/operations/operations-result-actions';
import { FiveSecondInterval } from '../../../utils/constants';
import { GetUserOperationsResult, userOperationsResultCancelled, userOperationsResultError, userOperationsResultSuccess, } from '../../../redux/actions/operations/operations-result-action-creators';
import { getUserOperationsInProgress } from '../../../redux/selectors/vnext/operations-selector';
import { callGetOperationResult } from '../../../redux/sagas/environment/list-environments';
function getErrorForOperation(env, failureOp) {
    switch (failureOp) {
        case FailureOperation.StartUserEnvironment:
        case FailureOperation.StopUserEnvironment:
        case FailureOperation.ResetPassword:
            return new MlClientError(env.latestOperationResult.errorMessage, env.id, failureOp, env.latestOperationResult.errorCode);
        default:
            return new MlClientError('', env.id, failureOp);
    }
}
function hasOperationFailed(previousEnvironment, currentEnvironment) {
    return previousEnvironment &&
        previousEnvironment.latestOperationResult &&
        previousEnvironment.latestOperationResult.status &&
        previousEnvironment.latestOperationResult.status !== OperationResultStatus.Failed &&
        currentEnvironment &&
        currentEnvironment.latestOperationResult &&
        currentEnvironment.latestOperationResult.status === OperationResultStatus.Failed
        ? true
        : false;
}
export function* listUserEnvironments(action) {
    try {
        const { aadGroupId } = action;
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { environments, changed } = yield race({
            environments: call(UserEnvironmentProvider.listEnvironments, accessToken, locale, language, aadGroupId),
            changed: race([
                take(UserEnvironmentActionType.POLL_LIST_GLOBAL_USER_ENVIRONMENTS_STOP),
                take(UserEnvironmentActionType.POLL_LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED),
                take(UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD),
                take(UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT),
                take(UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT),
            ]),
        });
        if (changed) {
            yield put(listUserEnvironmentsCancelled());
        }
        else {
            // get the previous environments we fetched so we can compare it to the most recently fetched template.
            // we compare the two states to see if an ongoing operation has failed, and if so we'll send the
            // appropriate error action so the UI can be updated with the correct error message.
            for (const environment of environments) {
                const previousEnvironment = yield select(getUserEnvironmentSelector, environment.id);
                if (previousEnvironment) {
                    if (hasOperationFailed(previousEnvironment, environment)) {
                        if (previousEnvironment.vmState === VmState.ResettingPassword) {
                            const error = getErrorForOperation(environment, FailureOperation.ResetPassword);
                            yield put(resetUserEnvironmentPasswordFailed(environment.id, error));
                        }
                        else if (previousEnvironment.vmState === VmState.Starting) {
                            const error = getErrorForOperation(environment, FailureOperation.StartUserEnvironment);
                            yield put(startUserEnvironmentFailed(environment.id, error));
                        }
                        else if (previousEnvironment.vmState === VmState.Stopping) {
                            const error = getErrorForOperation(environment, FailureOperation.StopUserEnvironment);
                            yield put(stopUserEnvironmentFailed(environment.id, error));
                        }
                    }
                }
            }
            yield put(listUserEnvironmentsSuccess(environments));
            yield put(listAllUserVirtualMachinesSuccess());
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, 'all', FailureOperation.ListUserEnvironments, err ? err.code : undefined);
        yield put(listUserEnvironmentsError(error));
    }
}
function* pollForEnvironments(action) {
    const { aadGroupId } = action;
    try {
        // allowing for an initial delay before we kick off polling
        // to mitigate a state flashing bug due to issues in the RP
        const { initialDelayMs } = action;
        if (!!initialDelayMs) {
            yield delay(initialDelayMs);
        }
        // this looks like an infinite loop, but the finally block will get triggered when
        // the task that is running this is canceled, which happens when the POLL_LIST_GLOBAL_USER_ENVIRONMENTS_STOP
        // action is dispatched
        while (true) {
            yield put(listEnvironmentsActionCreator(aadGroupId));
            yield race({
                success: take(UserEnvironmentActionType.LIST_ALL_USER_VIRTUAL_MACHINES_SUCCESS),
                cancelled: take(UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED),
                error: take(UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_ERROR),
            });
            const { intervalMs } = action;
            yield delay(intervalMs);
        }
    }
    finally {
        if (yield cancelled()) {
            yield put(pollListUserEnvironmentsCancelled());
        }
    }
}
export function* pollUserEnvironmentsSaga() {
    // while this looks like an infinite loop, yield take(POLL_LIST_GLOBAL_USER_ENVIRONMENTS_START)
    // will pause this code until polling is started
    while (true) {
        const action = yield take(UserEnvironmentActionType.POLL_LIST_GLOBAL_USER_ENVIRONMENTS_START);
        // forking so the polling task runs without blocking
        const pollUserEnvironmentsTask = yield fork(pollForEnvironments, action);
        // waits until the stop is triggered so we can cancel the polling task
        yield take(UserEnvironmentActionType.POLL_LIST_GLOBAL_USER_ENVIRONMENTS_STOP);
        yield cancel(pollUserEnvironmentsTask);
    }
}
export function* pollUserOperations(action) {
    const labsApiAccessToken = yield call(getLabsApiAccessTokenSaga);
    const locale = yield select(getLocale);
    const language = yield select(getLanguage);
    let pollingInterval = FiveSecondInterval;
    try {
        while (true) {
            yield delay(pollingInterval);
            let operations = yield select(getUserOperationsInProgress);
            if (operations.length <= 0) {
                yield put(userOperationsResultCancelled());
            }
            const { userOperationsResult, cancel } = yield race({
                userOperationsResult: yield all(operations.map((operation) => call(callGetOperationResult, operation, "", labsApiAccessToken, locale, language, true))),
                cancel: take(OperationsResultActionType.POLL_USER_OPERATIONS_RESULT_CANCELLED),
            });
            if (userOperationsResult) {
                yield put(userOperationsResultSuccess(userOperationsResult));
            }
            if (cancel) {
                yield put(userOperationsResultCancelled());
            }
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, '', FailureOperation.GetUserOperationsResultFailure, e ? e.code : undefined);
        yield put(userOperationsResultError(error));
    }
    finally {
        if (yield cancelled()) {
            yield put(userOperationsResultCancelled());
        }
    }
}
export function* pollUserOperationsSaga() {
    while (true) {
        const action = yield take(OperationsResultActionType.POLL_USER_OPERATIONS_RESULT);
        const pollUserOperationsTask = yield fork(pollUserOperations, action);
        yield take(OperationsResultActionType.POLL_USER_OPERATIONS_RESULT_CANCELLED);
        yield cancel(pollUserOperationsTask);
    }
}
//vNext saga
export function* listAllUserVirtualMachines(action) {
    try {
        const { aadGroupId } = action;
        const shouldListVNextVirtualMachinesOnly = yield select(isLmsHost);
        let apiCallRace;
        if (shouldListVNextVirtualMachinesOnly) {
            yield put(listVNextUserVirtualMachines(aadGroupId));
            apiCallRace = all({
                vNext: race({
                    success: take(UserEnvironmentActionType.LIST_VNEXT_USER_VIRTUAL_MACHINES_SUCCESS),
                    failure: take(UserEnvironmentActionType.LIST_VNEXT_USER_VIRTUAL_MACHINES_FAILURE),
                }),
            });
        }
        else {
            yield put(listVNextUserVirtualMachines(aadGroupId));
            yield put(listVCurrentUserVirtualMachines(aadGroupId));
            apiCallRace = all({
                vNext: race({
                    success: take(UserEnvironmentActionType.LIST_VNEXT_USER_VIRTUAL_MACHINES_SUCCESS),
                    failure: take(UserEnvironmentActionType.LIST_VNEXT_USER_VIRTUAL_MACHINES_FAILURE),
                }),
                vCurrent: race({
                    success: take(UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS_SUCCESS),
                    failure: take(UserEnvironmentActionType.LIST_VCURRENT_USER_VIRTUAL_MACHINES_FAILURE),
                }),
            });
        }
        const { apiCallResult, changed } = yield race({
            apiCallResult: apiCallRace,
            changed: race([
                take(UserEnvironmentActionType.POLL_LIST_GLOBAL_USER_ENVIRONMENTS_STOP),
                take(UserEnvironmentActionType.POLL_LIST_GLOBAL_USER_ENVIRONMENTS_CANCELLED),
                take(UserEnvironmentActionType.RESET_GLOBAL_USER_ENVIRONMENT_PASSWORD),
                take(UserEnvironmentActionType.START_GLOBAL_USER_ENVIRONMENT),
                take(UserEnvironmentActionType.STOP_GLOBAL_USER_ENVIRONMENT),
                take(UserEnvironmentActionType.REIMAGE_USER_VIRTUAL_MACHINE),
                take(UserEnvironmentActionType.REDEPLOY_USER_VIRTUAL_MACHINE),
            ]),
        });
        if (changed) {
            yield put(listUserEnvironmentsCancelled());
        }
        else {
            if ((shouldListVNextVirtualMachinesOnly && apiCallResult?.vNext?.failure) ||
                (!shouldListVNextVirtualMachinesOnly &&
                    apiCallResult?.vNext?.failure &&
                    apiCallResult?.vCurrent?.failure)) {
                // All API calls failed
                const error = new MlClientError(undefined, 'all', FailureOperation.ListUserEnvironments, undefined);
                yield put(listUserEnvironmentsError(error));
                return;
            }
            else if (apiCallResult?.vNext?.failure || apiCallResult?.vCurrent?.failure) {
                // One of two API calls failed
                const error = new MlClientError(undefined, 'all', FailureOperation.ListUserEnvironmentsPartial, undefined);
                yield put(listUserEnvironmentsError(error));
            }
            yield put(listAllUserVirtualMachinesSuccess());
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, 'all', FailureOperation.ListUserEnvironments, err ? err.code : undefined);
        yield put(listUserEnvironmentsError(error));
    }
}
export function* listVCurrentVirtualMachines(action) {
    try {
        const { aadGroupId } = action;
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const armAccessToken = yield call(getArmAccessTokenSaga);
        const response = yield call(UserEnvironmentProvider.listEnvironments, armAccessToken, locale, language, aadGroupId);
        yield put(listUserEnvironmentsSuccess(response));
    }
    catch (err) {
        yield put(listVCurrentUserVirtualMachinesFailures());
    }
}
export function* listVNextVirtualMachines(action) {
    try {
        const { aadGroupId } = action;
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const labsApiAccessToken = yield call(getLabsApiAccessTokenSaga, false, undefined, true);
        const response = yield call(listVirtualMachines, labsApiAccessToken, locale, language, aadGroupId);
        yield put(listVNextUserVirtualMachinesSuccess(response));
        yield put(GetUserOperationsResult(''));
    }
    catch (err) {
        yield put(listVNextUserVirtualMachinesFailure());
    }
}
export function* listEnvironmentsSaga() {
    yield featureBasedTakeLatest(UserEnvironmentActionType.LIST_GLOBAL_USER_ENVIRONMENTS, listUserEnvironments, listAllUserVirtualMachines);
}
export function* listVCurrentVirtualMachinesSaga() {
    yield takeLatest(UserEnvironmentActionType.LIST_VCURRENT_USER_VIRTUAL_MACHINES, listVCurrentVirtualMachines);
}
export function* listVNextVirtualMachinesSaga() {
    yield takeLatest(UserEnvironmentActionType.LIST_VNEXT_USER_VIRTUAL_MACHINES, listVNextVirtualMachines);
}
