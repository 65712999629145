import { call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { LabUpdateOperation } from '../../../data/models/lab';
import TemplateProvider from '../../../data/providers/template-provider';
import { ResourceId } from '../../../utils/resource-id';
import { updateLab } from '../../actions/lab/lab-action-creators';
import { LabActionType } from '../../actions/lab/lab-actions';
import { publishTemplateCancelled, publishTemplateError } from '../../actions/template/template-action-creators';
import { TemplateActionType } from '../../actions/template/template-actions';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { getVCurrentLab } from '../../selectors/lab-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
export function* publishTemplate(action) {
    const { templateId, maxUsersInLab, useExistingImage } = action;
    try {
        const resourceId = new ResourceId(templateId);
        // if the publish requires a lab capacity update, we take care of it first
        if (!isNaN(maxUsersInLab)) {
            const labId = resourceId.parent.id;
            const lab = yield select(getVCurrentLab, labId);
            if (!lab) {
                yield put(publishTemplateError(templateId, new MlClientError('Lab was not found', templateId, FailureOperation.PublishTemplate)));
                return;
            }
            if (lab.maxUsersInLab !== maxUsersInLab) {
                yield put(updateLab({ ...lab, maxUsersInLab }, LabUpdateOperation.Capacity));
                const { error } = yield race({
                    success: take(LabActionType.UPDATE_LAB_SUCCESS),
                    error: take(LabActionType.UPDATE_LAB_ERROR),
                });
                if (error) {
                    yield put(publishTemplateCancelled(templateId));
                    return;
                }
            }
        }
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        yield call(TemplateProvider.publishTemplate, templateId, useExistingImage, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, templateId, FailureOperation.PublishTemplate, e ? e.code : undefined);
        yield put(publishTemplateError(templateId, error));
    }
}
export function* publishTemplateSaga() {
    yield takeLatest(TemplateActionType.PUBLISH_TEMPLATE, publishTemplate);
}
