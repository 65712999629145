import { call, takeLatest } from 'redux-saga/effects';
import { IdentityActionType } from '../../actions/identity/identity-actions';
import { clearUserSession as clearMsalUserSession } from '../../../data/providers/msal-provider';
import { trackException } from '../../../utils/telemetry/telemetry-channel';
export function* clearUserSession(action) {
    try {
        yield call(clearMsalUserSession);
    }
    catch (err) {
        trackException(err);
    }
}
export function* clearUserSessionSaga() {
    yield takeLatest(IdentityActionType.CLEAR_USER_SESSION, clearUserSession);
}
