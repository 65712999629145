import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { TenantActionType } from '../../actions/tenant/tenant-actions';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { listTenantsError, listTenantsSuccess } from '../../actions/tenant/tenant-action-creators';
import { listTenants } from '../../../data/providers/tenant-provider';
export function* listUserTenants(action) {
    try {
        const accessToken = yield call(getArmAccessTokenSaga, true);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const tenants = yield call(listTenants, accessToken, locale, language);
        yield put(listTenantsSuccess(tenants));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, 'listTenants', FailureOperation.ListTenants, e ? e.code : undefined);
        yield put(listTenantsError(error));
    }
}
export function* listTenantsSaga() {
    yield takeLatest(TenantActionType.LIST_TENANTS, listUserTenants);
}
