import { call, cancelled, put, race, select, take } from 'redux-saga/effects';
import { getSubscriptions } from '../../selectors/subscription-selector';
import ErrorCode from '../../../common/error-codes';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { ResourceId } from '../../../utils/resource-id';
import { fatalError, professorInitializeCancelled, professorInitializeError, professorInitializeSuccess, } from '../../actions/common/common-action-creators';
import { CommonActionType } from '../../actions/common/common-actions';
import { getGroupName } from '../../actions/group/group-action-creators';
import { setTenantId } from '../../actions/identity/identity-action-creators';
import { selectLab } from '../../actions/lab/lab-action-creators';
import { loadSubscriptions } from '../../actions/subscription/subscription-action-creators';
import { SubscriptionActionType } from '../../actions/subscription/subscription-actions';
import { listTenants } from '../../actions/tenant/tenant-action-creators';
import { TenantActionType } from '../../actions/tenant/tenant-actions';
import { cancelableTakeLeading } from '../../effects/cancelable';
import { isTeamsHost, isVNextFeatureEnabled } from '../../selectors/common-selectors';
import { getGroupId, getGroupName as getGroupNameFromStore } from '../../selectors/group-selectors';
import { getTenantId, getUserSettings } from '../../selectors/identity-selector';
import { getLabIdFromRoute, getLabParentIdFromRoute } from '../../selectors/route-selector';
import { getTenants } from '../../selectors/tenant-selectors';
import { listLabParentResources, selectLabParentResource, } from '../../actions/lab-parent-resource/lab-parent-resource-action-creators';
import { getLabsApiAccessTokenSaga } from '../identity/access-tokens';
export function* professorInitialize(action) {
    try {
        let tenantError = undefined;
        let signedInTenantId = yield select(getTenantId);
        const isTeamsHosted = yield select(isTeamsHost);
        if (!isTeamsHosted) {
            yield put(listTenants());
            const { error: tenantErrorAction } = yield race({
                success: take(TenantActionType.LIST_TENANTS_SUCCESS),
                error: take(TenantActionType.LIST_TENANTS_ERROR),
            });
            if (tenantErrorAction) {
                tenantError = tenantErrorAction.error;
            }
        }
        if (!!tenantError) {
            yield put(professorInitializeError(tenantError));
            yield put(fatalError(ErrorCode.GenericError, tenantError));
            return;
        }
        const tenants = yield select(getTenants);
        const signedInTenant = tenants.find((a) => a.tenantId == signedInTenantId);
        // if the signed in tenant is not in the list of tenants returned from arm, we will select the first tenant
        if (!signedInTenant && tenants.size > 0) {
            signedInTenantId = tenants.get(0).tenantId;
            yield put(setTenantId(signedInTenantId));
        }
        yield put(loadSubscriptions());
        const { error: errorAction } = yield race({
            success: take(SubscriptionActionType.LOAD_SUBSCRIPTIONS_SUCCESS),
            error: take(SubscriptionActionType.LOAD_SUBSCRIPTIONS_ERROR),
        });
        if (errorAction) {
            const { error } = errorAction;
            yield put(professorInitializeError(error));
            yield put(fatalError(ErrorCode.GenericError, error));
        }
        else {
            const isVNext = yield select(isVNextFeatureEnabled);
            if (isVNext) {
                yield call(getLabsApiAccessTokenSaga, true);
            }
            // we process the lab and lab parent IDs out of their routes so we can set up
            // our initial application state
            let selectFirstIfSelectedNotFound = false;
            let labParentId = yield select(getLabParentIdFromRoute);
            const labId = yield select(getLabIdFromRoute);
            // if a lab parent isn't specified in our route,
            // lets see if we have a previously used one in our user settings
            if (!labParentId) {
                const settings = yield select(getUserSettings);
                const lastUsedParentResource = settings.get('lastUsedParentResource');
                // we select last used lab parent if its subscription still exists
                // if the subscription no longer exists, we will select a default
                // otherwise, users would encounter fatal error
                if (lastUsedParentResource) {
                    const resourceId = new ResourceId(lastUsedParentResource);
                    const lastUsedSubscriptionId = resourceId.subscriptionId;
                    const subscriptions = yield select(getSubscriptions);
                    if (subscriptions.find((o) => o.subscriptionId == lastUsedSubscriptionId)) {
                        labParentId = lastUsedParentResource;
                        selectFirstIfSelectedNotFound = true;
                    }
                }
            }
            // kick off the lab parent load, but let pages decide if they want to wait on them all
            // to load before displaying anything
            if (labParentId) {
                yield put(selectLabParentResource(labParentId));
                yield put(listLabParentResources(false, selectFirstIfSelectedNotFound));
                // if we have a specific lab, select it
                if (labId) {
                    yield put(selectLab(labId));
                }
            }
            else {
                yield put(listLabParentResources(true));
            }
            // fetch the group name from graph if we have an id
            const groupId = yield select(getGroupId);
            const groupName = yield select(getGroupNameFromStore);
            if (!!groupId && !groupName) {
                yield put(getGroupName(groupId));
            }
            yield put(professorInitializeSuccess());
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, '', FailureOperation.ProfessorInitialize, err ? err.code : undefined);
        yield put(professorInitializeError(error));
        yield put(fatalError(ErrorCode.GenericError, error));
    }
    finally {
        if (yield cancelled()) {
            yield put(professorInitializeCancelled());
        }
    }
}
export function* professorInitializeSaga() {
    yield cancelableTakeLeading(CommonActionType.PROFESSOR_INITIALIZE, professorInitialize, CommonActionType.PROFESSOR_INITIALIZE_SUCCESS, CommonActionType.PROFESSOR_INITIALIZE_ERROR);
}
