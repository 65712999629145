import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { Map } from 'immutable';
import memoize from 'memoize-one';
import { isEnabled } from '../../data/helpers/enum-helpers';
import { MlCoreFamily } from '../../data/models/core-quota-data';
import { ConnectionType } from '../../data/models/environment-common';
import { safeGetRestrictions, safeGetUsages } from '../../redux/selectors/core-limit-selectors';
import { Constants } from '../../utils/constants';
import { getGalleryImageInfo, getSharedImageInfo } from './vm-image-info';
import { getDefaultIdleConfig } from '../../common/shutdown-policy/selectors';
import { getLocale, isBastionFeatureEnabled, isFeatureEnabled, isLmsHost, storeHasLoadError, storeIsLoading, } from '../../redux/selectors/common-selectors';
import Feature from '../../utils/features';
import { getPricingAndAvailabilityData } from '../../redux/selectors/pricing-and-availability-selectors';
import { getCurrentLabAccount } from '../..//redux/selectors/lab-account-selectors';
import { getGroupId, getGroupName, shouldFilterLabsByGroupId } from '../../redux/selectors/group-selectors';
import { getTenantId } from '../../redux/selectors/identity-selector';
import { getCurrentLabParentId, isCurrentLabParentLabAccount, } from '../../redux/selectors/lab-parent-resource-selectors';
import { getLabNames } from '../../redux/selectors/lab-selectors';
import { getVNextImages } from '../../redux/selectors/vnext/image-selectors';
import { LabServicesModels } from 'lab-services';
import { getCurrentLabPlan, getCurrentResourceGroupLabPlans } from '../../redux/selectors/vnext/lab-plan-selectors';
import { compareByName, compareByText } from '../../utils/common';
import { caseInsensitiveCultureInvariantCompare, caseInsensitiveLocaleCompare } from '../../utils/string-comparison';
import { isLabInCurrentResourceGroup } from '../lab-list-selectors';
import { getLtiContextTitle } from '../../redux/selectors/lti-selectors';
import { getVNextSkuData } from '../../redux/selectors/vnext/sku-selectors';
import { getVNextProcessedUsage } from '../../redux/selectors/vnext/usage-selectors';
import { getVNextProcessedPrice } from '../../redux/selectors/vnext/price-selectors';
import { convertSkuCapabilityToVmSzie, getSkuCostTargetUnit, isClassicSku } from '../../common/selectors/sku-selectors';
import { filterImagesByLabplanRegions } from '../../common/selectors/image-selectors';
import { vmSkuSorter } from './vm-size-picker-sorter';
import { subscriptionHasFeature } from '../../redux/selectors/subscription-selector';
export const getVCurrentSizeData = (data, osType) => {
    const osData = data[osType];
    return osData ? osData.sizes : {};
};
export const getVCurrentGeographyData = (data, size) => {
    const sizeInfo = data[size.sizeName];
    return sizeInfo ? sizeInfo.geographies : {};
};
export const hasVCurrentAvailableSize = memoize((data) => {
    // make sure we have at least one size that is available
    const keys = Object.keys(data);
    if (keys.length < 1) {
        return false;
    }
    for (let i = 0; i < keys.length; ++i) {
        const { availability, sizes } = data[keys[i]];
        if (availability !== Ml.Availability.Available) {
            continue;
        }
        const sizeKeys = Object.keys(sizes);
        if (sizeKeys.length < 1) {
            continue;
        }
        for (let j = 0; j < sizeKeys.length; ++j) {
            const { availability, geographies } = sizes[sizeKeys[j]];
            if (availability !== Ml.Availability.Available) {
                continue;
            }
            const geoKeys = Object.keys(geographies);
            if (geoKeys.length < 1) {
                continue;
            }
            for (let k = 0; k < geoKeys.length; ++k) {
                const { availability } = geographies[geoKeys[k]];
                if (availability === Ml.Availability.Available) {
                    return true;
                }
            }
        }
    }
    return false;
});
/**
 * Determine if we have the availability and core capacity to create a VM with the given size.
 */
export const isVCurrentSizeDisabled = (size, coreQuotaData) => {
    if (size.availability !== Ml.Availability.Available) {
        return true;
    }
    if (isSizeDisabledForCores(size, coreQuotaData)) {
        return true;
    }
    return false;
};
/**
 * Only returns true if all VM sizes are available for lab creation.
 */
export const areAllVCurrentSizesAvailable = memoize((data, coreQuotaData) => {
    return Object.keys(data).every((key) => !isVCurrentSizeDisabled(data[key], coreQuotaData));
});
/**
 * Determine if we have the core capacity to create a VM with the given size.
 */
const isSizeDisabledForCores = (size, coreQuotaData) => {
    if (!coreQuotaData) {
        return false;
    }
    if (coreQuotaData.isExempt) {
        return false;
    }
    const coreFamily = size.gpu === Ml.GpuType.Gpu ? MlCoreFamily.GPU : MlCoreFamily.Standard;
    const restriction = safeGetRestrictions(coreFamily, coreQuotaData);
    const usage = safeGetUsages(coreFamily, coreQuotaData);
    if (restriction === undefined) {
        return false;
    }
    if (usage === undefined) {
        return false;
    }
    return usage + size.coresCount > restriction;
};
export const getVCurrentRemainingCores = (coreQuotaData) => {
    if (coreQuotaData.isExempt) {
        return { remainingGpuCores: undefined, remainingStandardCores: undefined };
    }
    const gpuRestriction = safeGetRestrictions(MlCoreFamily.GPU, coreQuotaData);
    const standardRestriction = safeGetRestrictions(MlCoreFamily.Standard, coreQuotaData);
    const gpuUsage = safeGetUsages(MlCoreFamily.GPU, coreQuotaData);
    const standardUsage = safeGetUsages(MlCoreFamily.Standard, coreQuotaData);
    if (gpuRestriction === undefined ||
        standardRestriction === undefined ||
        gpuUsage === undefined ||
        standardUsage === undefined) {
        return { remainingGpuCores: undefined, remainingStandardCores: undefined };
    }
    return {
        remainingGpuCores: gpuRestriction - gpuUsage,
        remainingStandardCores: standardRestriction - standardUsage,
    };
};
export const getVCurrentMinMaxVmCores = memoize((gpuType, data) => {
    const sizes = Object.keys(data).filter((key) => data[key].gpu === gpuType);
    let min;
    let max;
    sizes.forEach((size) => {
        const cores = data[size].coresCount;
        min = !min || cores < min ? cores : min;
        max = !max || cores > max ? cores : max;
    });
    return { minVmCores: min, maxVmCores: max };
});
/**
 * Only returns true if all geographics are available for lab creation.
 */
export const areAllGeosAvailable = (data) => {
    return Object.keys(data).every((key) => isGeographyAvailable(data[key]));
};
export const isGeographyAvailable = (geo) => {
    return geo.availability === Ml.Availability.Available;
};
export const isUbuntuImage = (image) => {
    return !!image && !!image.text && image.text.toLowerCase().indexOf('ubuntu') > -1;
};
export const getDefaultImage = (images) => {
    if (images.length < 1) {
        return undefined;
    }
    const image = images.find((o) => caseInsensitiveCultureInvariantCompare(o.text, Constants.DefaultImageName) === 0);
    return image ?? images[0];
};
export const getVCurrentDefaultSize = (data, coreQuotaData) => {
    let defaultSize = undefined;
    Object.keys(data).map((key) => {
        const size = data[key];
        const disabled = isVCurrentSizeDisabled(size, coreQuotaData);
        if (!disabled && (!defaultSize || defaultSize.ordinal > size.ordinal)) {
            defaultSize = size;
        }
    });
    return defaultSize;
};
export const getVCurrentDefaultGeography = (data, defaultGeoName) => {
    // first we try to pick the geo that matches the lab account default (defaultGeoName)
    const geoNames = Object.keys(data);
    if (!!defaultGeoName && geoNames.indexOf(defaultGeoName) > -1) {
        const geo = data[defaultGeoName];
        if (!!geo && isGeographyAvailable(geo)) {
            return geo;
        }
    }
    // if no match, then we try to pick the geo with the lowest price, and if there are conflicts, then
    // fallback to alphabetical
    let defaultGeo = undefined;
    Object.keys(data).map((key) => {
        const geo = data[key];
        if (isGeographyAvailable(geo) &&
            (!defaultGeo ||
                defaultGeo.price > geo.price ||
                caseInsensitiveLocaleCompare(defaultGeo.localizedDisplayName, geo.localizedDisplayName) > 0)) {
            defaultGeo = geo;
        }
    });
    return defaultGeo;
};
export const isGeographySelectable = (labAccount) => {
    return labAccount.enabledRegionSelection !== false;
};
export const getLabAccountDefaultGeoName = (labAccount) => {
    return labAccount?.geo;
};
export const isRegionSelectable = (labPlan) => {
    return !!labPlan.allowedRegions && labPlan.allowedRegions.length > 0;
};
export const getIsBastionSupported = (labAccount, isBastionEnabled, labPlan, isV2BastionEnabled) => {
    if (isBastionEnabled) {
        if (!!labAccount) {
            return !labAccount.virtualNetworkAddressRange && !labAccount.virtualNetworkResourceId;
        }
    }
    // for V2 labs, we support bastion if the subscription has the feature flag
    return isV2BastionEnabled;
};
export const getDefaultConnectionTypes = (isWindows, labAccount, labPlan, isBastionEnabled, isV2BastionEnabled) => {
    const result = [isWindows ? ConnectionType.Rdp : ConnectionType.Ssh];
    if (getIsBastionSupported(labAccount, isBastionEnabled, labPlan, isV2BastionEnabled)) {
        if (!!labAccount && isEnabled(labAccount.enableBastion)) {
            result.push(isWindows ? ConnectionType.RdpInBrowser : ConnectionType.SshInBrowser);
        }
    }
    return result;
};
export const combineImages = (galleryImages, sharedImages, locale) => {
    const images = [
        ...galleryImages.map((o) => getGalleryImageInfo(o)),
        ...sharedImages.filter((o) => isEnabled(o.enableState)).map((o) => getSharedImageInfo(o)),
    ];
    return images.sort((a, b) => a.text.localeCompare(b.text, locale));
};
export const isVCurrentGpuSize = (size) => {
    return size.gpu === Ml.GpuType.Gpu;
};
export function convertVNextImageToVmImageInfo(image) {
    return {
        id: image.id,
        isWindows: image.osType === LabServicesModels.OsType.Windows,
        key: image.id,
        text: image.displayName,
        description: image.description,
        isCustomImage: !!image.sharedGalleryId,
        iconUrl: image.iconUrl,
        imageReference: {
            id: image.sharedGalleryId,
            offer: image.offer,
            publisher: image.publisher,
            sku: image.sku,
            version: image.version,
        },
        availableRegions: image.availableRegions ?? [],
        isSpecialized: image.osState === LabServicesModels.OsState.Specialized,
    };
}
export const getSkuCostFromImageOS = (location, targetUnit) => {
    const targetCost = location?.costs?.find((c) => caseInsensitiveCultureInvariantCompare(c.unit, targetUnit) === 0);
    return targetCost?.value ?? 0;
};
export const getSkuCostsFromImage = (locations, image) => {
    let skuCosts = Map();
    const targetUnit = getSkuCostTargetUnit(image.isWindows);
    locations.forEach((location) => {
        if (isLocationAvailable(location, targetUnit, image)) {
            skuCosts = skuCosts.set(location.name, getSkuCostFromImageOS(location, targetUnit));
        }
    });
    return skuCosts;
};
export const getSkuMinMaxCostFromImage = (locations, image) => {
    let min = Number.MAX_VALUE, max = Number.MIN_VALUE;
    const targetUnit = getSkuCostTargetUnit(image.isWindows);
    let hasFoundAtLeastOneValidCost = false;
    locations.forEach((location) => {
        const isValid = isLocationAvailable(location, targetUnit, image);
        if (isValid) {
            hasFoundAtLeastOneValidCost = true;
            const cost = getSkuCostFromImageOS(location, targetUnit);
            if (cost < min) {
                min = cost;
            }
            if (cost > max) {
                max = cost;
            }
        }
    });
    return {
        min: hasFoundAtLeastOneValidCost ? min : 0,
        max: hasFoundAtLeastOneValidCost ? max : 0,
    };
};
export const getSkuMinCostFromImage = (sku, image) => {
    const locations = sku.locations;
    if (locations.length === 0) {
        return undefined;
    }
    let min = Number.MAX_VALUE;
    let minLocation;
    const targetUnit = getSkuCostTargetUnit(image.isWindows);
    locations.forEach((location) => {
        const cost = getSkuCostFromImageOS(location, targetUnit);
        if (isLocationAvailable(location, targetUnit, image) && cost < min) {
            min = cost;
            minLocation = location;
        }
    });
    return minLocation;
};
export const getLocationMinCostFromImage = memoize((skus, image, location) => {
    let min = Number.MAX_VALUE;
    let minSku;
    const targetUnit = getSkuCostTargetUnit(image.isWindows);
    skus.forEach((sku) => {
        const isValid = isSkuLocationAvailable(sku, location, image);
        if (isValid) {
            const vmSkuLocation = sku.locations.find((o) => caseInsensitiveCultureInvariantCompare(o.name, location) === 0);
            const cost = getSkuCostFromImageOS(vmSkuLocation, targetUnit);
            if (cost < min) {
                min = cost;
                minSku = sku;
            }
        }
    });
    return minSku;
});
export const getSkuAndLocationMinCostFromImage = memoize((skus, image) => {
    let min = Number.MAX_VALUE;
    let minSku;
    let minLocation;
    const targetUnit = getSkuCostTargetUnit(image.isWindows);
    skus.forEach((sku) => {
        const minSkuLocation = getSkuMinCostFromImage(sku, image);
        if (!!minSkuLocation) {
            const cost = getSkuCostFromImageOS(minSkuLocation, targetUnit);
            if (cost < min) {
                min = cost;
                minSku = sku;
                minLocation = minSkuLocation;
            }
        }
    });
    return {
        sku: minSku,
        location: minLocation,
    };
});
export const getSkusFromData = memoize((skuSettings, usageData, priceData) => {
    if (!usageData || !priceData) {
        return [];
    }
    const skus = [];
    let skuMap = Map();
    skuSettings.forEach((sku) => {
        const size = convertSkuCapabilityToVmSzie(sku.capabilities, sku.size);
        const locations = [];
        sku.locations?.forEach((skuLocation) => {
            const usageByLocation = usageData.get(skuLocation);
            const priceByLocation = priceData.get(skuLocation);
            if (usageByLocation && priceByLocation) {
                const usageByLocationSkuFamily = usageByLocation.get(sku.family);
                const priceByLocationSkuName = priceByLocation.get(sku.name);
                let usage = undefined;
                if (usageByLocationSkuFamily) {
                    usage = {
                        unit: usageByLocationSkuFamily.unit,
                        currentValue: usageByLocationSkuFamily.currentValue,
                        limit: usageByLocationSkuFamily.limit,
                    };
                }
                const location = {
                    key: skuLocation,
                    name: skuLocation,
                    costs: priceByLocationSkuName ?? [],
                    usage,
                };
                locations.push(location);
            }
        });
        const target = skuMap.get(sku.name);
        if (target != undefined) {
            locations.forEach((loc) => {
                if (!target.locations.find((tl) => caseInsensitiveCultureInvariantCompare(tl.name, loc.name) === 0)) {
                    target.locations.push(loc);
                    skuMap = skuMap.set(target.name, target);
                }
                else {
                    // TODO: this is unexpected. log?
                }
            });
        }
        else {
            const newSku = {
                key: sku.name,
                name: sku.name,
                family: sku.family,
                size,
                locations,
                isClassic: isClassicSku(sku.name),
            };
            skus.push(newSku);
            skuMap = skuMap.set(newSku.name, newSku);
        }
    });
    const sorter = vmSkuSorter();
    return skus.sort((a, b) => sorter(a, b));
});
export const getDefaultSku = (skus, vmImageInfo) => {
    if (skus.length === 0) {
        return undefined;
    }
    for (let index = 0; index < skus.length; index++) {
        if (!!getDefaultLocationFromSku(skus[index], vmImageInfo)) {
            return skus[index];
        }
    }
    return undefined;
};
export const isSkuLocationAvailable = memoize((sku, location, vmImageInfo) => {
    const targetPriceUnit = getSkuCostTargetUnit(vmImageInfo.isWindows);
    for (let index = 0; index < sku.locations.length; index++) {
        const skuLocation = sku.locations[index];
        if (caseInsensitiveCultureInvariantCompare(skuLocation.name, location) === 0 &&
            isLocationAvailable(skuLocation, targetPriceUnit, vmImageInfo)) {
            return true;
        }
    }
    return false;
});
const isLocationAvailable = (location, targetPriceUnit, vmImageInfo) => {
    const locationIndex = location.costs.findIndex((o) => !!o.unit && caseInsensitiveCultureInvariantCompare(o.unit, targetPriceUnit) === 0);
    const canFindPriceAndUsage = !!location.usage &&
        !!location.costs &&
        locationIndex >= 0 &&
        location.costs[locationIndex].availability === 'Available';
    return canFindPriceAndUsage && isLocationAllowedForImage(vmImageInfo, location.name);
};
export const isLocationAllowedForImage = (vmImageInfo, location) => {
    return vmImageInfo.isCustomImage
        ? vmImageInfo.availableRegions.findIndex((o) => caseInsensitiveCultureInvariantCompare(o, location) === 0) >= 0
        : true;
};
export const isSkuAvailable = memoize((sku, vmImageInfo) => {
    const targetPriceUnit = getSkuCostTargetUnit(vmImageInfo.isWindows);
    for (let index = 0; index < sku.locations.length; index++) {
        const location = sku.locations[index];
        if (isLocationAvailable(location, targetPriceUnit, vmImageInfo)) {
            return true;
        }
    }
    return false;
});
export const getDefaultLocationFromSku = (sku, vmImageInfo) => {
    for (let index = 0; index < sku.locations.length; index++) {
        if (isSkuLocationAvailable(sku, sku.locations[index].name, vmImageInfo)) {
            return sku.locations[index];
        }
    }
};
export const getCreateLabViewModel = memoize((state) => {
    const currentLabParentId = getCurrentLabParentId(state);
    const isLabAccount = isCurrentLabParentLabAccount(state);
    const isBastionEnabled = isBastionFeatureEnabled(state);
    const isV2BastionEnabled = subscriptionHasFeature(state, Constants.V2BastionFeature);
    const isSkipTemplateEnabled = isFeatureEnabled(state, Feature.SkipTemplate) && isLabAccount;
    const labNames = getLabNames(state);
    const isSetGroupIdInQueryString = shouldFilterLabsByGroupId(state);
    const aadGroupName = isSetGroupIdInQueryString ? getGroupName(state) : '';
    let validateNameOnLoad = !!aadGroupName;
    const currentTenantId = getTenantId(state);
    const defaultIdleConfig = getDefaultIdleConfig(state);
    if (isLabAccount) {
        const labStore = state.get('labStore');
        const labAccountStore = state.get('labAccountStore');
        const galleryImageStore = state.get('galleryImageStore');
        const sharedImageStore = state.get('sharedImageStore');
        const pricingAndAvailabilityStore = state.get('pricingAndAvailabilityStore');
        const coreLimitStore = state.get('coreLimitStore');
        const labStoreLoadState = labStore.get('loadState');
        const labAccountLoadState = labAccountStore.get('loadState');
        const galleryImageLoadState = galleryImageStore.get('loadState');
        const sharedImageLoadState = sharedImageStore.get('loadState');
        const pricingAndAvailabilityLoadState = pricingAndAvailabilityStore.get('loadState');
        const coreLimitLoadState = coreLimitStore.get('loadState');
        const isLoading = storeIsLoading(labStoreLoadState) ||
            storeIsLoading(labAccountLoadState) ||
            storeIsLoading(galleryImageLoadState) ||
            storeIsLoading(sharedImageLoadState) ||
            storeIsLoading(pricingAndAvailabilityLoadState) ||
            storeIsLoading(coreLimitLoadState);
        const hasLoadError = storeHasLoadError(labStoreLoadState) ||
            storeHasLoadError(labAccountLoadState) ||
            storeHasLoadError(galleryImageLoadState) ||
            storeHasLoadError(sharedImageLoadState) ||
            storeHasLoadError(pricingAndAvailabilityLoadState) ||
            storeHasLoadError(coreLimitLoadState);
        const labAccount = getCurrentLabAccount(state);
        const isCreating = labStore.get('isCreating');
        const coreQuotaData = coreLimitStore.get('coreQuotaData');
        const pricingAndAvailabilityData = getPricingAndAvailabilityData(state);
        const hasCapacityError = !isLoading && !hasVCurrentAvailableSize(pricingAndAvailabilityData);
        const galleryImages = galleryImageStore.get('images');
        const sharedImages = sharedImageStore.get('images');
        const locale = getLocale(state);
        const images = combineImages(galleryImages, sharedImages, locale);
        const hasImageError = !isLoading && images.length < 1;
        const addImagesUrl = labAccount ? Constants.Urls.AddImages(currentTenantId, labAccount.id) : undefined;
        return {
            isSubmitting: isCreating,
            labNames,
            defaultIdleConfig,
            isBastionEnabled,
            isV2BastionEnabled,
            isSkipTemplateEnabled,
            coreQuotaData,
            images,
            labAccount,
            currentTenantId,
            addImagesUrl,
            pricingAndAvailabilityData,
            hasCapacityError,
            hasImageError,
            validateNameOnLoad,
            isLoading,
            hasLoadError,
            isCurrentLabParentLabAccount: isLabAccount,
            currentLabParentId,
        };
    }
    else {
        const labStore = state.get('vNextLabStore');
        const imageStore = state.get('vNextImageStore');
        const labPlanStore = state.get('labPlanStore');
        const labParentResourceStore = state.get('labParentResourceStore');
        const skuStore = state.get('vNextSkuStore');
        const usageStore = state.get('vNextUsageStore');
        const priceStore = state.get('vNextPriceStore');
        const labLoadState = labStore.get('loadState');
        const labParentLoadState = labParentResourceStore.get('loadState');
        const imageLoadState = imageStore.get('loadState');
        const labPlanLoadState = labPlanStore.get('loadState');
        const skuLoadState = skuStore.get('loadState');
        const usageLoadState = usageStore.get('loadState');
        const priceLoadState = priceStore.get('loadState');
        const isLoading = storeIsLoading(labLoadState) ||
            storeIsLoading(labParentLoadState) ||
            storeIsLoading(skuLoadState) ||
            storeIsLoading(labPlanLoadState);
        const hasLoadError = storeHasLoadError(labLoadState) ||
            storeHasLoadError(labParentLoadState) ||
            storeHasLoadError(skuLoadState) ||
            storeHasLoadError(labPlanLoadState);
        const isGettingLabPlan = labPlanStore.get('isGettingLabPlan');
        const isLoadingOnSelectLabPlan = storeIsLoading(imageLoadState) ||
            storeIsLoading(skuLoadState) ||
            storeIsLoading(usageLoadState) ||
            storeIsLoading(priceLoadState) ||
            isGettingLabPlan;
        const hasSelectLabPlanLoadError = storeHasLoadError(imageLoadState) ||
            storeHasLoadError(usageLoadState) ||
            storeHasLoadError(priceLoadState);
        const isCreating = labStore.get('isCreating');
        const rawSkuData = getVNextSkuData(state);
        const usageData = getVNextProcessedUsage(state);
        const priceData = getVNextProcessedPrice(state);
        const skuData = getSkusFromData(rawSkuData, usageData, priceData);
        const vNextImages = getVNextImages(state);
        const filteredImages = vNextImages.filter((o) => o.enabledState === LabServicesModels.EnableState.Enabled);
        const hasCapacityError = !isLoading && !rawSkuData;
        const labPlans = getCurrentResourceGroupLabPlans(state).sort(compareByName);
        const selectedLabPlan = getCurrentLabPlan(state);
        const addImagesUrl = selectedLabPlan
            ? Constants.Urls.AddImages(currentTenantId, selectedLabPlan.id)
            : undefined;
        let images = filteredImages.map((o) => convertVNextImageToVmImageInfo(o)).toArray();
        images = filterImagesByLabplanRegions(images, selectedLabPlan);
        const hasImageError = !isLoading && images.length < 1;
        const ltiContextTitle = getLtiContextTitle(state);
        validateNameOnLoad = !!aadGroupName || !!ltiContextTitle;
        return {
            isSubmitting: isCreating,
            labNames,
            defaultIdleConfig,
            isBastionEnabled,
            isV2BastionEnabled,
            isSkipTemplateEnabled,
            images: images.sort(compareByText),
            hasImageError,
            currentTenantId,
            addImagesUrl,
            hasCapacityError,
            validateNameOnLoad,
            isLoading,
            hasLoadError,
            selectedLabPlan,
            labPlans,
            isCurrentLabParentLabAccount: isLabAccount,
            currentLabParentId,
            isLoadingOnSelectLabPlan,
            hasSelectLabPlanLoadError,
            skuData,
        };
    }
});
export const getCreateLabContainerModel = memoize((state) => {
    const currentLabParentId = getCurrentLabParentId(state);
    const isLabAccount = isCurrentLabParentLabAccount(state);
    let hasLabErrors;
    if (isLabAccount) {
        const labStore = state.get('labStore');
        hasLabErrors = !labStore.get('errors').isEmpty();
    }
    else {
        const labStore = state.get('vNextLabStore');
        const errors = labStore
            .get('errors')
            .filter((value, key) => isLabInCurrentResourceGroup(key, currentLabParentId));
        hasLabErrors = !errors.isEmpty();
    }
    const isSetGroupIdInQueryString = shouldFilterLabsByGroupId(state);
    const aadGroupId = isSetGroupIdInQueryString ? getGroupId(state) : '';
    const aadGroupName = isSetGroupIdInQueryString ? getGroupName(state) : '';
    const labPlanStore = state.get('labPlanStore');
    const labPlanLoadState = labPlanStore.get('loadState');
    const isLoadingLabPlans = storeIsLoading(labPlanLoadState);
    const hasLabPlanLoadError = storeHasLoadError(labPlanLoadState);
    const createLabViewModel = getCreateLabViewModel(state);
    const ltiContextTitle = getLtiContextTitle(state);
    const isLms = isLmsHost(state);
    let defaultLabName = '';
    if (isLms) {
        defaultLabName = ltiContextTitle ?? '';
    }
    else if (aadGroupName) {
        defaultLabName = aadGroupName;
    }
    return {
        hasLabErrors,
        aadGroupId,
        createLabViewModel,
        defaultLabName,
        isLoadingLabPlans,
        hasLabPlanLoadError,
    };
});
