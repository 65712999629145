import { all, call, put, race, select, take, takeLatest } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../../data/ml-client-error';
import UsageProvider from '../../../../data/providers/vnext/arm-api/usage-provider';
import { UsageActionType } from '../../../actions/vnext/usage/usage-actions';
import { listRegionUsageDataSuccess, listUsageDataCancelled, listUsageDataError, listUsageDataSuccess, } from '../../../actions/vnext/usage/usage-action-creators';
import { getLanguage, getLocale } from '../../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../../identity/access-tokens';
export function* listUsage(action) {
    const { subscriptionId, regions } = action;
    try {
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const usageCalls = regions.map((region) => {
            return call(UsageProvider.listUsage, subscriptionId, region, accessToken, locale, language);
        });
        if (usageCalls) {
            // TODO: this will fail if any of the calls fail.
            // If we want to protect against a single failure, try to follow pattern in:
            // https://devdiv.visualstudio.com/OnlineServices/_git/azure-lab-services/pullrequest/358391
            const { results, changed } = yield race({
                results: all(usageCalls),
                changed: race([
                    take(UsageActionType.SELECT_LAB_PARENT_RESOURCE),
                    take(UsageActionType.SELECT_LAB),
                    take(UsageActionType.SELECT_LAB_PLAN),
                ]),
            });
            if (changed) {
                yield put(listUsageDataCancelled());
            }
            else {
                for (const result of results) {
                    yield put(listRegionUsageDataSuccess(result.region, result.usage));
                }
            }
        }
        yield put(listUsageDataSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, subscriptionId, FailureOperation.ListUsageData, err ? err.code : undefined);
        yield put(listUsageDataError(regions, error));
    }
}
export function* listUsageSaga() {
    yield takeLatest(UsageActionType.LIST_USAGE_DATA, listUsage);
}
