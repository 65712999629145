import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    title: {
        id: 'ShutdownPolicyTitle',
        defaultMessage: 'Automatic shutdown & disconnect',
        description: 'Header for shutdown policy group of settings.',
    },
    disconnectOnIdleLabel: {
        id: 'DisconnectOnIdleLabel',
        defaultMessage: 'Disconnect users when virtual machines are idle',
        description: 'Label for a checkbox inside shutdown policy group of settings.',
    },
    disconnectOnIdleInputLabel: {
        id: 'DisconnectOnIdleInputLabel',
        defaultMessage: 'minutes after idle state is detected',
        description: 'Label which is shown after the input that specifies number of minutes before a shutdown policy setting is activated.',
    },
    disconnectOnIdleWarningText: {
        id: 'DisconnectOnIdleWarningText',
        defaultMessage: 'The changes here will not take effect until you restart the machine.',
        description: 'Warning for disconnect on idle field (only shown when VM is running and field has been changed) that the changes will not take effect until the machine has been restarted.',
    },
    disconnectOnIdleInfoTipAriaLabel: {
        id: 'DisconnectOnIdleInfoTipAriaLabel',
        defaultMessage: 'This setting uses Window OS definition of idle, which uses two criteria: user absence, no keyboard or mouse input. And lack of resource consumption, all the processors and all the disks were idle for a certain percentage of time.',
        description: 'Aria label for disconnect on idle info tip',
    },
    shutdownOnDisconnectLabel: {
        id: 'ShutdownOnDisconnectLabel',
        defaultMessage: 'Shut down virtual machines when users disconnect',
        description: 'Label for a checkbox inside shutdown policy group of settings.',
    },
    shutdownOnDisconnectInputLabel: {
        id: 'ShutdownOnDisconnectInputLabel',
        defaultMessage: 'minutes after user disconnects',
        description: 'Label which is shown after the input that specifies number of minutes before a shutdown policy setting is activated.',
    },
    shutdownOnDisconnectInfoTipAriaLabel: {
        id: 'ShutdownOnDisconnectInfoTipAriaLabel',
        defaultMessage: 'Shut down will occur when a Remote Desktop connection is disconnected for Windows machines, or an SSH connection is disconnected for Linux machines (only specific distrubutions and verisons of Linux are supported). You can specify how long the virtual machines should wait for the user to reconnect before automatically shutting down.',
        description: 'Aria label for shutdown on disconnect info tip',
    },
    vNextShutdownOnDisconnectInfoTipAriaLabel: {
        id: 'VNextShutdownOnDisconnectInfoTipAriaLabel',
        defaultMessage: 'Shut down will occur when a Remote Desktop connection is disconnected for Windows machines, or an SSH connection is disconnected for Linux machines. You can specify how long the virtual machines should wait for the user to reconnect before automatically shutting down.',
        description: 'Aria label for shutdown on disconnect info tip',
    },
    shutdownOnNoConnectLabel: {
        id: 'ShutdownOnNoConnectLabel',
        defaultMessage: 'Shut down virtual machines when users do not connect',
        description: 'Label for a checkbox inside shutdown policy group of settings.',
    },
    shutdownOnNoConnectInputLabel: {
        id: 'ShutdownOnNoConnectInputLabel',
        defaultMessage: 'minutes after machine is started',
        description: 'Label which is shown after the input that specifies number of minutes before a shutdown policy setting is activated.',
    },
    shutdownOnIdleLabel: {
        id: 'ShutdownOnIdleLabel',
        defaultMessage: 'Shut down idle virtual machines',
        description: 'Label for a checkbox inside shutdown policy group of settings.',
    },
    shutdownOnIdleInfoTipAriaLabel: {
        id: 'ShutdownOnIdleInfoTipAriaLabel',
        defaultMessage: 'Shut down on idle helps manage costs in your labs. Idle based on absence is effective in most cases, but absence and resource usage allows machines in your lab to stay connected until long-running compute operations are complete. This is useful in data science classes, for example.',
        description: 'Aria label for shutdown on idle info tip',
    },
});
export default messages;
