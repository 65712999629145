import { all, call, put, select } from 'redux-saga/effects';
import { FailureOperation, MlClientError } from '../../../data/ml-client-error';
import EnvironmentProvider from '../../../data/providers/environment-provider';
import { ResourceId } from '../../../utils/resource-id';
import { stopEnvironmentsError, stopEnvironmentsAccepted } from '../../actions/environment/environment-action-creators';
import { EnvironmentActionType } from '../../actions/environment/environment-actions';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import VirtualMachineProvider from '../../../data/providers/vnext/arm-api/virtual-machine-provider';
import { labParentBasedTakeEvery } from '../../effects/lab-parent-based';
import { GetOperationsResult } from '../../actions/operations/operations-result-action-creators';
export async function callStopEnvironment(resourceId, accessToken, locale, language, errors) {
    try {
        await EnvironmentProvider.stopEnvironment(resourceId, accessToken, locale, language);
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.StopEnvironments, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* stopEnvironments(action) {
    const { environmentIds: virtualMachineIds } = action;
    try {
        const resourceIds = virtualMachineIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        yield all(resourceIds.map((resourceId) => call(callStopEnvironment, resourceId, accessToken, locale, language, errors)));
        if (Object.keys(errors).length > 0) {
            yield put(stopEnvironmentsError(errors));
        }
    }
    catch (e) {
        const errors = {};
        const error = new MlClientError(e ? e.message : undefined, 'all', FailureOperation.StopEnvironments, e ? e.code : undefined);
        errors[error.id] = error;
        yield put(stopEnvironmentsError(errors));
    }
}
export async function callStopVNextVirtualMachine(resourceId, accessToken, locale, language, errors) {
    try {
        const stopVmResult = await VirtualMachineProvider.stopVirtualMachine(resourceId, accessToken, locale, language);
        stopVmResult['ResourceId'] = resourceId;
        return stopVmResult;
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, resourceId.id, FailureOperation.StopEnvironments, e ? e.code : undefined);
        errors[resourceId.id] = error;
    }
}
export function* stopVNextVirtualMachines(action) {
    const { environmentIds: virtualMachineIds } = action;
    try {
        const resourceIds = virtualMachineIds.map((o) => new ResourceId(o));
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const errors = {};
        const stopVmsResult = yield all(resourceIds.map((resourceId) => call(callStopVNextVirtualMachine, resourceId, accessToken, locale, language, errors)));
        if (Object.keys(errors).length > 0) {
            yield put(stopEnvironmentsError(errors));
        }
        yield put(stopEnvironmentsAccepted(stopVmsResult));
        yield put(GetOperationsResult(''));
    }
    catch (e) {
        const errors = {};
        const error = new MlClientError(e ? e.message : undefined, virtualMachineIds.length === 1 ? virtualMachineIds[0] : 'all', FailureOperation.StopEnvironments, e ? e.code : undefined);
        errors[error.id] = error;
        yield put(stopEnvironmentsError(errors));
    }
}
export function* stopEnvironmentsSaga() {
    yield labParentBasedTakeEvery(EnvironmentActionType.STOP_ENVIRONMENTS, stopEnvironments, stopVNextVirtualMachines);
}
