import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router/immutable';
import { composeWithDevTools } from 'redux-devtools-extension';
import { combinedReducer } from './reducers/combined-reducer';
import { rootSaga } from './sagas/root-saga';
import { fatalErrorMiddleware } from './middleware/fatal-error-middleware';
import { actionLoggerMiddleware } from './middleware/action-logger-middleware';
import { vNextMiddleware } from './middleware/vnext-middleware';
export const configureStore = (history) => {
    const sagaMiddleware = createSagaMiddleware();
    const routerMiddleware = createRouterMiddleware(history);
    const middleware = [
        sagaMiddleware,
        routerMiddleware,
        fatalErrorMiddleware,
        vNextMiddleware,
        actionLoggerMiddleware,
    ];
    const composeEnhancers = composeWithDevTools({ maxAge: 150 });
    const enhancer = composeEnhancers(applyMiddleware(...middleware));
    // Redux does not play well with Typescript sometimes...
    const store = createStore(combinedReducer(history), undefined, enhancer);
    sagaMiddleware.run(rootSaga);
    return store;
};
export default configureStore;
