import { put, call, take, race, select, delay, cancelled, fork, cancel } from 'redux-saga/effects';
import { EnvironmentActionType, } from '../../actions/environment/environment-actions';
import { getEnvironmentSuccess, getEnvironmentError, getEnvironmentCancelled, getVNextVirtualMachineSuccess, getEnvironment as getEnvironmentActionCreator, pollVirtualMachineCancelled, } from '../../actions/environment/environment-action-creators';
import { ResourceId } from '../../../utils/resource-id';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import EnvironmentProvider from '../../../data/providers/environment-provider';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
import { labParentBasedTakeEvery } from '../../effects/lab-parent-based';
import VirtualMachineProvider from '../../../data/providers/vnext/arm-api/virtual-machine-provider';
export function* getEnvironment(action) {
    const { id, includeNetwork } = action;
    try {
        const resourceId = new ResourceId(id);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { environment, changed } = yield race({
            environment: call(EnvironmentProvider.getEnvironment, resourceId, accessToken, locale, language, includeNetwork),
            changed: race([
                take(EnvironmentActionType.SELECT_LAB),
                take(EnvironmentActionType.SELECT_LAB_PARENT_RESOURCE),
            ]),
        });
        if (changed) {
            yield put(getEnvironmentCancelled(id));
        }
        else {
            yield put(getEnvironmentSuccess(environment));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, id, includeNetwork ? FailureOperation.GetEnvironmentWithNetwork : FailureOperation.GetEnvironment, err ? err.code : undefined);
        yield put(getEnvironmentError(id, error));
    }
}
export function* getVNextVirtualMachine(action) {
    const { id } = action;
    try {
        const resourceId = new ResourceId(id);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { virtualMachine, changed } = yield race({
            virtualMachine: call(VirtualMachineProvider.getVirtualMachine, resourceId, accessToken, locale, language),
            changed: race([
                take(EnvironmentActionType.SELECT_LAB),
                take(EnvironmentActionType.SELECT_LAB_PARENT_RESOURCE),
                take(EnvironmentActionType.GET_ENVIRONMENT_CANCELLED),
                take(EnvironmentActionType.POLL_VIRTUAL_MACHINE_STOP),
                take(EnvironmentActionType.POLL_VIRTUAL_MACHINE_CANCELLED),
            ]),
        });
        if (changed) {
            yield put(getEnvironmentCancelled(id));
        }
        else {
            yield put(getVNextVirtualMachineSuccess(virtualMachine));
        }
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, id, FailureOperation.GetEnvironment, err ? err.code : undefined);
        yield put(getEnvironmentError(id, error));
    }
}
export function* getEnvironmentSaga() {
    yield labParentBasedTakeEvery(EnvironmentActionType.GET_ENVIRONMENT, getEnvironment, getVNextVirtualMachine);
}
function* pollVirutalMachine(action) {
    try {
        // allowing for an initial delay before we kick off polling
        // to mitigate a state flashing bug due to issues in the RP
        const { initialDelayMs, id } = action;
        if (!!initialDelayMs) {
            yield delay(initialDelayMs);
        }
        // this looks like an infinite loop, but the finally block will get triggered when
        // the task that is running this is canceled, which happens when the POLL_TEMPLATE_STOP
        // action is dispatched
        while (true) {
            yield put(getEnvironmentActionCreator(id));
            yield race({
                success: take(EnvironmentActionType.GET_ENVIRONMENT_SUCCESS),
                cancelled: take(EnvironmentActionType.GET_ENVIRONMENT_CANCELLED),
                error: take(EnvironmentActionType.GET_ENVIRONMENT_ERROR),
            });
            const { intervalMs } = action;
            yield delay(intervalMs);
        }
    }
    finally {
        if (yield cancelled()) {
            yield put(pollVirtualMachineCancelled());
        }
    }
}
export function* pollVirtualMachineSaga() {
    while (true) {
        const action = yield take(EnvironmentActionType.POLL_VIRTUAL_MACHINE_START);
        // forking so the polling task runs without blocking
        const pollTask = yield fork(pollVirutalMachine, action);
        // waits until the stop is triggered so we can cancel the polling task
        yield take(EnvironmentActionType.POLL_VIRTUAL_MACHINE_STOP);
        yield cancel(pollTask);
    }
}
