import { defineMessages } from 'react-intl';
import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
const messages = defineMessages({
    customLinuxImage: {
        id: 'VmImageInfoCustomLinuxImage',
        defaultMessage: 'Custom Linux image',
        description: 'Description for a custom Linux VM image.',
    },
    customWindowsImage: {
        id: 'VmImageInfoCustomWindowsImage',
        defaultMessage: 'Custom Windows image',
        description: 'Description for a custom Windows VM image.',
    },
});
export const getGalleryImageInfo = (image) => {
    return {
        id: image.id,
        isWindows: image.imageReference.osType !== 'Linux',
        key: image.id,
        text: image.name,
        subTitle: image.author,
        description: image.description,
        iconUrl: image.icon,
        isCustomImage: false,
        imageReference: {
            offer: image.imageReference?.offer ?? '',
            publisher: image.imageReference?.publisher ?? '',
            sku: image.imageReference?.sku ?? '',
        },
        availableRegions: [],
        isSpecialized: false,
    };
};
export const getSharedImageInfo = (image, intl) => {
    const isWindows = image.osType === Ml.OsType.Windows;
    return {
        id: image.id,
        isWindows: isWindows,
        key: image.id,
        text: image.displayName,
        subTitle: intl
            ? intl.formatMessage(isWindows ? messages.customWindowsImage : messages.customLinuxImage)
            : undefined,
        isCustomImage: true,
        imageReference: undefined,
        availableRegions: [],
        isSpecialized: false,
    };
};
