export const DefaultDialogSmallWidth = 408;
export const DefaultDialogWidth = 450;
export const DefaultDialogWideWidth = 495;
export const DefaultFlyoutWidth = 423;
export const NoSubscriptionId = 'NoSubscription';
export const NoUsername = 'NoUsername';
export const AzureLabServicesPesId = '06bfd9d3-516b-d5c6-5802-169c800dec89';
export const QuotaSupportTopicId = '4c6012da-5dcf-7a3a-43b4-99ec73125c83';
export const V2BastionFeature = 'Microsoft.LabServices/AllowV2Bastion';
export const Urls = {
    OperationsResult: '?api-version=2023-06-07',
    OperationsResultLabsApi: '?api-version=2023-02-01',
    Subscriptions: '/subscriptions?api-version=2016-06-01',
    Tenants: '/tenants?api-version=2020-01-01',
    Permissions: '/providers/Microsoft.Authorization/permissions?api-version=2018-07-01',
    SupportRequestForVmIssues: (tenantId, subscriptionId) => `https://portal.azure.com/#@${tenantId}/create/Microsoft.Support/Parameters/{"pesId":"technical","subId":"${subscriptionId}","caller":"azurelabservices"}`,
    SupportRequest: (tenantId, subscriptionId) => `https://portal.azure.com/#@${tenantId}/create/Microsoft.Support/Parameters/{"pesId":"${AzureLabServicesPesId}","subId":"${subscriptionId}","supportTopicId":"${QuotaSupportTopicId}","caller":"azurelabservices"}`,
    CreateLabAccount: (tenantId) => `https://portal.azure.com/#@${tenantId}/create/Microsoft.ManagedLab`,
    AddImages: (tenantId, labAccountId) => `https://portal.azure.com/#@${tenantId}/resource${labAccountId}/marketplaceImages`,
    ExportedImageLink: (tenantId, labPlanComputeGalleryId) => `https://portal.azure.com/#@${tenantId}/resource${labPlanComputeGalleryId}/images`,
    DisabledSizesGeo: 'https://aka.ms/azlabs-sizegeo-availability',
    Privacy: 'https://go.microsoft.com/fwlink/?LinkId=521839',
    TermsOfUse: 'https://aka.ms/azlabs-termsofuse',
    PopupHelp: 'https://support.microsoft.com/en-us/office/disable-pop-up-blockers-for-lync-web-app-8d8ad778-925d-44fc-8268-fc0a6b5471c0',
    AllFeatureFlags: '/providers/Microsoft.Features/providers/Microsoft.LabServices/features?api-version=2021-07-01',
    LinkLabPlan: '/api/lti/v1p3/provisioning/linkLabPlan',
    ProfessorFeedback: 'https://aka.ms/azlabs-ownersurvey',
    StudentFeedback: 'https://aka.ms/LabServicesSurvey',
    LabPlanSettings: (tenantId, labPlanId) => `https://portal.azure.com/#@${tenantId}/resource${labPlanId}/labSettings`,
    LabPlanRegions: 'https://aka.ms/azlabsregions',
    TechnicalSupportRequest: (tenantId) => `https://portal.azure.com/#@${tenantId}/create/Microsoft.Support`,
};
export const ApiVersions = {
    labsCurrentPreview: '2021-10-01-preview',
    managementCurrentPreview: '2021-11-15-preview',
};
export const EnableState = {
    Enabled: 'Enabled',
    Disabled: 'Disabled',
};
export const DefaultImageName = 'Windows 11 Pro';
export const DefaultWindows10ImageName = /Windows 10 Pro, version [0-9]+/;
export const DefaultQuota = 10;
export const DefaultCapacity = 10;
export const DefaultMaxCapacity = 400;
export const MaxLabQuota = 9999;
export const DefaultNoTemplateMinCapacity = 1;
export const MinAdditionalUserQuota = 0;
export const MaxAdditionalUserQuota = 9999;
export const DefaultAdditionalUserQuota = 1;
export const DefaultTemplateName = 'default';
export const DefaultUserName = '--';
export const LargeTextFieldRows = 30;
export const NavWidthExpanded = 230;
export const NavWidthCollapsed = 58;
export const TemplateTitleMaxLength = 255;
export const TemplateDescriptionMaxLength = 500;
// These match the values used in the ML RP, see MLabResourceConstants.EnvironmentSetting
export const WindowsPasswordLength = {
    minLength: 8,
    maxLength: 123,
};
export const LinuxPasswordLength = {
    minLength: 6,
    maxLength: 72,
};
export const VCurrentRegistrationCodeLength = 8;
export const DefaultPollingDelayInterval = 30 * 1000;
export const DefaultPollingInterval = 5 * 60 * 1000;
export const FiveSecondInterval = 5 * 1000;
export const TenSecondInterval = 10 * 1000;
export const FifteenSecondInterval = 15 * 1000;
export const ThirtySecondInterval = 30 * 1000;
export const OneMinuteInterval = 60 * 1000;
export const Regions = {
    SoutheastAsia: 'southeastasia',
    CentralUsEuap: 'centraluseuap',
    EastUs2Euap: 'eastus2euap',
    WestUs2: 'westus2',
    WestCentralUs: 'westcentralus',
    EastUs2: 'eastus2',
};
export const DevSubscriptionId = '2d5eedc9-8509-41fe-aac8-f16d54583ac6';
export const DefaultCurrencyCode = 'USD';
export const ScheduledSyncTimeHour = 1;
export const ScheduledSyncTimeMinute = 0;
export const LtiClaims = {
    roles: 'https://purl.imsglobal.org/spec/lti/claim/roles',
    toolPlatform: 'https://purl.imsglobal.org/spec/lti/claim/tool_platform',
    context: 'https://purl.imsglobal.org/spec/lti/claim/context',
    namesroleservice: 'https://purl.imsglobal.org/spec/lti-nrps/claim/namesroleservice',
    contextMembershipUrl: 'context_memberships_url',
    custom: 'https://purl.imsglobal.org/spec/lti/claim/custom',
};
export const LtiInstructorRole = 'http://purl.imsglobal.org/vocab/lis/v2/membership#Instructor';
export const LtiContentDeveloperRole = 'http://purl.imsglobal.org/vocab/lis/v2/membership#ContentDeveloper';
export const LtiAdministratorRoles = [
    'http://purl.imsglobal.org/vocab/lis/v2/membership#Administrator',
    'http://purl.imsglobal.org/vocab/lis/v2/system/person#Administrator',
    'http://purl.imsglobal.org/vocab/lis/v2/institution/person#Administrator',
];
export const QuotaRequestNeededNumberOfVMs = 30;
export const Constants = {
    DefaultDialogSmallWidth,
    DefaultDialogWidth,
    DefaultDialogWideWidth,
    DefaultFlyoutWidth,
    NoSubscriptionId,
    NoUsername,
    Urls,
    EnableState,
    DefaultImageName,
    DefaultWindows10ImageName,
    DefaultQuota,
    DefaultCapacity,
    DefaultMaxCapacity,
    LargeTextFieldRows,
    MaxLabQuota,
    DefaultNoTemplateMinCapacity,
    MinAdditionalUserQuota,
    MaxAdditionalUserQuota,
    DefaultAdditionalUserQuota,
    DefaultTemplateName,
    TemplateTitleMaxLength,
    TemplateDescriptionMaxLength,
    WindowsPasswordLength,
    LinuxPasswordLength,
    DefaultUserName,
    DefaultPollingDelayInterval,
    DefaultPollingInterval,
    FiveSecondInterval,
    TenSecondInterval,
    ThirtySecondInterval,
    OneMinuteInterval,
    Regions,
    DevSubscriptionId,
    NavWidthExpanded,
    NavWidthCollapsed,
    DefaultCurrencyCode,
    ScheduledSyncTimeHour,
    ScheduledSyncTimeMinute,
    VCurrentRegistrationCodeLength,
    V2BastionFeature,
};
export default Constants;
