import * as React from 'react';
import { Icon, TooltipHost } from '@fluentui/react';
import { getCustomTheme } from '../common/themes/selectors';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from '@fluentui/react';
import { Constants } from '../utils/constants';
import { OperationType } from '../data/models/vnext/operation';
import { messages } from '../vm-list/vm-list-messages';
export const VmOperationError = (props) => {
    const intl = useIntl();
    const tenantId = props.tenantId;
    const subscriptionId = props.subscriptionId;
    const vmOperationType = props.operationType;
    return (<TooltipHost content={tenantId && subscriptionId ? (<FormattedMessage id="OperationResultGenericError" defaultMessage="Virtual machine {Operation} operation failed. Try redeploying the virtual machine. If the issue persists, consider reimaging it. For further assistance submit a support request." description="The error message displayed on tooltip error icon for virtual machines when start or stop operation failure." values={{
        Link: () => (<Link target="_blank" style={{ paddingLeft: '0px' }} href={Constants.Urls.SupportRequestForVmIssues(tenantId, subscriptionId)}>
                                    {intl.formatMessage(messages.SubmitSupportRequest)}
                                </Link>),
        Operation: vmOperationType === OperationType.StartOperation
            ? intl.formatMessage(messages.StartOperation)
            : vmOperationType === OperationType.StopOperation
                ? intl.formatMessage(messages.StopOperation)
                : vmOperationType === OperationType.ReimageOperation
                    ? intl.formatMessage(messages.ReimageOperation)
                    : vmOperationType === OperationType.RedeployOperation
                        ? intl.formatMessage(messages.RedeployOperation)
                        : '',
    }}/>) : (<FormattedMessage id="OperationResultStudentViewError" defaultMessage="Virtual machine {Operation} operation failed. Try redeploying the virtual machine. If the issue persists, consider reimaging it. For further assistance, request your IT administrator to submit a support request." description="Error message shown when start or stop operation failed in student view" values={{
        Operation: vmOperationType === OperationType.StartOperation
            ? intl.formatMessage(messages.StartOperation)
            : vmOperationType === OperationType.StopOperation
                ? intl.formatMessage(messages.StopOperation)
                : '',
    }}/>)}>
            <Icon iconName="StatusErrorFull" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '16px',
            color: getCustomTheme().palette?.redDark,
        },
    }}/>
        </TooltipHost>);
};
export const VmAgentError = (props) => {
    const tenantId = props.tenantId;
    const subscriptionId = props.subscriptionId;
    return (<TooltipHost content={tenantId && subscriptionId ? (<FormattedMessage id="VmOperationResultGenericError" defaultMessage="The lab service agent failed to install on your virtual machine. Refer to the {TroubleshootingGuide} to understand how to reimage the virtual machine to resolve the issue." description="The error message displayed on tooltip error icon for virtual machines when Als agent to provision the VM." values={{
        TroubleshootingGuide: (<Link target="_blank" style={{ paddingLeft: '0px' }} href={"https://learn.microsoft.com/en-us/azure/lab-services/troubleshoot-access-lab-vm"}>
                                        <FormattedMessage id="agentTroubleshootingGuideLink" defaultMessage="troubleshooting guidelines" description="a link to the vm issues troubleshooting guidelines page."/>
                                </Link>)
    }}/>) : (<FormattedMessage id="VmOperationResultStudentViewError" defaultMessage="The lab service agent failed to install on your virtual machine. Refer to the {TroubleshootingGuide} to understand how to reimage the virtual machine to resolve the issue." description="Error message shown when Als agent failed to provision the VM." values={{
        TroubleshootingGuide: (<Link target="_blank" style={{ paddingLeft: '0px' }} href={"https://learn.microsoft.com/en-us/azure/lab-services/troubleshoot-access-lab-vm"}>
                                        <FormattedMessage id="agentStudentViewTroubleshootingGuideLink" defaultMessage="troubleshooting guidelines" description="a link to the vm issues troubleshooting guidelines page."/>
                                </Link>)
    }}/>)}>
            <Icon iconName="StatusErrorFull" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '16px',
            color: getCustomTheme().palette?.redDark,
        },
    }}/>
        </TooltipHost>);
};
export const VmHeartbeatError = (props) => {
    const tenantId = props.tenantId;
    const subscriptionId = props.subscriptionId;
    return (<TooltipHost content={tenantId && subscriptionId ? (<FormattedMessage id="VmHeartbeatError" defaultMessage="Your cloud virtual machine has become non-responsive and will be stopped automatically to avoid overbilling. You may want to refer to the {TroubleshootingGuide} to resolve the issue." description="The error message displayed on tooltip error icon for virtual machines when VM heartbeat was not received." values={{
        TroubleshootingGuide: (<Link target="_blank" style={{ paddingLeft: '0px' }} href={"https://learn.microsoft.com/en-us/azure/lab-services/troubleshoot-access-lab-vm"}>
                                        <FormattedMessage id="adminTroubleshootingGuideLink" defaultMessage="troubleshooting guidelines" description="a link to the vm issues troubleshooting guidelines page."/>
                                </Link>)
    }}/>) : (<FormattedMessage id="VmHeartbeatStudentViewError" defaultMessage="Your cloud virtual machine has become non-responsive and will be stopped automatically to avoid overbilling. You may want to refer to the {TroubleshootingGuide} to resolve the issue." description="Error message shown when VM heartbeat was not received." values={{
        TroubleshootingGuide: (<Link target="_blank" style={{ paddingLeft: '0px' }} href="https://learn.microsoft.com/en-us/azure/lab-services/troubleshoot-access-lab-vm">
                                        <FormattedMessage id="studentTroubleshootingGuideLink" defaultMessage="troubleshooting guidelines" description="a link to the vm issues troubleshooting guidelines page."/>
                                </Link>)
    }}/>)}>
            <Icon iconName="WarningSolid" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '16px',
            color: getCustomTheme().palette?.yellowDark,
        },
    }}/>
        </TooltipHost>);
};
