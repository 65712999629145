import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { LabServicesModels } from 'lab-services';
/**
 * For now, we are going to set 'Public' as the connection state if the state is true, else "None"
 * @param value
 * @returns
 */
export function toConnectionType(value) {
    return value ? LabServicesModels.ConnectionType.Public : LabServicesModels.ConnectionType.None;
}
export function toEnableState(value) {
    return value ? Ml.EnableState.Enabled : Ml.EnableState.Disabled;
}
export function isEnabled(value) {
    return value === Ml.EnableState.Enabled;
}
export function isGeneralized(value) {
    return value === Ml.ImageType.Generalized;
}
