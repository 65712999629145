import { ResourceId } from '../../utils/resource-id';
import { trackTrace } from '../../utils/telemetry/telemetry-channel';
import { defaultLabRoles, Roles } from '../models/roles';
import { getMlClient } from './arm-data-provider';
import { ActionPermissions, hasAccess } from './permissions-provider';
async function getLabRoles(lab, accessToken, locale, language) {
    const roles = [...defaultLabRoles];
    try {
        if (await hasAccess(ActionPermissions.WriteLab, lab.id, accessToken, locale, language)) {
            roles.push(Roles.Write);
        }
    }
    catch (e) {
        trackTrace(`Unable to determine write access for lab ${lab.name} due to error ${e.message}, assuming no access.`);
    }
    return roles;
}
export async function getLab(labId, accessToken, locale, language, includeRoles) {
    const id = labId instanceof ResourceId ? labId : new ResourceId(labId);
    const client = getMlClient(id.subscriptionId, accessToken, locale, language);
    const lab = await client.labs.get(id.resourceGroupName, id.parent.name, id.name);
    if (includeRoles) {
        const roles = await getLabRoles(lab, accessToken, locale, language);
        return { ...lab, roles };
    }
    return { ...lab, roles: [] };
}
export async function listLabs(labAccountId, accessToken, locale, language) {
    const mlClient = getMlClient(labAccountId.subscriptionId, accessToken, locale, language);
    const mlLabs = await mlClient.labs.list(labAccountId.resourceGroupName, labAccountId.name);
    const labs = [];
    await Promise.all(mlLabs.map(async (lab, index) => {
        const roles = await getLabRoles(lab, accessToken, locale, language);
        labs[index] = { ...lab, roles };
    }));
    return labs;
}
export async function beginDeleteLab(labResourceId, accessToken, locale, language) {
    const mlClient = getMlClient(labResourceId.subscriptionId, accessToken, locale, language);
    await mlClient.labs.beginDeleteMethod(labResourceId.resourceGroupName, labResourceId.parent.name, labResourceId.name);
}
export async function updateLab(lab, accessToken, locale, language) {
    const { roles, id } = lab;
    const resourceId = new ResourceId(id);
    const mlClient = getMlClient(resourceId.subscriptionId, accessToken, locale, language);
    const updatedLab = await mlClient.labs.createOrUpdate(resourceId.resourceGroupName, resourceId.parent.name, resourceId.name, lab);
    return { ...updatedLab, roles: [...roles] };
}
export async function addUsers(labId, emailAddresses, accessToken, locale, language) {
    const mlClient = getMlClient(labId.subscriptionId, accessToken, locale, language);
    await mlClient.labs.addUsers(labId.resourceGroupName, labId.parent.name, labId.name, {
        emailAddresses: emailAddresses,
    });
}
export async function sendEmail(labId, emailAddresses, extraMessage, accessToken, locale, language) {
    const mlClient = getMlClient(labId.subscriptionId, accessToken, locale, language);
    await mlClient.labs.sendEmail(labId.resourceGroupName, labId.parent.name, labId.name, {
        emailAddresses: emailAddresses,
        extraMessage: extraMessage,
    });
}
export async function getPricingAndAvailability(labId, accessToken, locale, language) {
    const mlClient = getMlClient(labId.subscriptionId, accessToken, locale, language);
    const result = await mlClient.labs.getLabPricingAndAvailability(labId.resourceGroupName, labId.parent.name, labId.name);
    return result;
}
export async function syncUserList(labId, accessToken, locale, language) {
    const mlClient = getMlClient(labId.subscriptionId, accessToken, locale, language);
    await mlClient.labs.beginSyncUserList(labId.resourceGroupName, labId.parent.name, labId.name);
}
const LabProvider = {
    getLab,
    listLabs,
    beginDeleteLab,
    updateLab,
    addUsers,
    sendEmail,
    syncUserList,
};
export default LabProvider;
