import { defineMessages } from 'react-intl';
export const getLocalizedSkuFriendlyName = (name, intl) => {
    switch (name) {
        case SkuFriendlyName.BasicSmall:
            return intl.formatMessage(skuMessages.basicSmall);
        case SkuFriendlyName.StandardSmall:
            return intl.formatMessage(skuMessages.standardSmall);
        case SkuFriendlyName.StandardMedium:
            return intl.formatMessage(skuMessages.standardMedium);
        case SkuFriendlyName.StandardLarge:
            return intl.formatMessage(skuMessages.standardLarge);
        case SkuFriendlyName.ComputeGpuSmall:
            return intl.formatMessage(skuMessages.computeGpuSmall);
        case SkuFriendlyName.ComputeGpuMedium:
            return intl.formatMessage(skuMessages.computeGpuMedium);
        case SkuFriendlyName.GraphicsGpuSmall:
            return intl.formatMessage(skuMessages.graphicsGpuSmall);
        case SkuFriendlyName.GraphicsGpuMedium:
            return intl.formatMessage(skuMessages.graphicsGpuMedium);
        case SkuFriendlyName.AltComputeGpuSmall:
            return intl.formatMessage(skuMessages.altComputeGpuSmall);
        case SkuFriendlyName.AltComputeGpuMedium:
            return intl.formatMessage(skuMessages.altComputeGpuMedium);
        case SkuFriendlyName.AltGraphicsGpuSmall:
            return intl.formatMessage(skuMessages.altGraphicsGpuSmall);
        case SkuFriendlyName.AltGraphicsGpuMedium:
            return intl.formatMessage(skuMessages.altGraphicsGpuMedium);
        case SkuFriendlyName.MemoryOptimizedSmall:
            return intl.formatMessage(skuMessages.memoryOptimizedSmall);
        case SkuFriendlyName.MemoryOptimizedMedium:
            return intl.formatMessage(skuMessages.memoryOptimizedMedium);
        case ClassicSkuFriendlyName.Small:
            return intl.formatMessage(skuMessages.classicSmall);
        case ClassicSkuFriendlyName.Medium:
            return intl.formatMessage(skuMessages.classicMedium);
        case ClassicSkuFriendlyName.MediumNestedVirtualization:
            return intl.formatMessage(skuMessages.classicMediumNestedVirtualization);
        case ClassicSkuFriendlyName.Large:
            return intl.formatMessage(skuMessages.classicLarge);
        case ClassicSkuFriendlyName.LargeNestedVirtualization:
            return intl.formatMessage(skuMessages.classicLargeNestedVirtualization);
        case ClassicSkuFriendlyName.SmallGpuCompute:
            return intl.formatMessage(skuMessages.classicSmallGpuCompute);
        case ClassicSkuFriendlyName.SmallGpuVisualization:
            return intl.formatMessage(skuMessages.classicSmallGpuVisualization);
        case ClassicSkuFriendlyName.MediumGpuVisualization:
            return intl.formatMessage(skuMessages.classicMediumGpuVisualization);
        default:
            return name;
    }
};
export const ClassicSkuFriendlyName = {
    Small: 'Small',
    Medium: 'Medium',
    Large: 'Large',
    MediumNestedVirtualization: 'Medium (Nested virtualization)',
    LargeNestedVirtualization: 'Large (Nested virtualization)',
    SmallGpuCompute: 'Alternative Small GPU (Compute)',
    SmallGpuVisualization: 'Small GPU (Visualization)',
    MediumGpuVisualization: 'Medium GPU (Visualization)',
};
export const SkuFriendlyName = {
    BasicSmall: 'Basic small',
    StandardSmall: 'Standard small',
    StandardMedium: 'Standard medium',
    StandardLarge: 'Standard large',
    ComputeGpuSmall: 'GPU Compute small',
    ComputeGpuMedium: 'GPU Compute medium',
    GraphicsGpuSmall: 'GPU Visualization small',
    GraphicsGpuMedium: 'GPU Visualization medium',
    AltComputeGpuSmall: 'Alternative GPU Compute small',
    AltComputeGpuMedium: 'Alternative GPU Compute medium',
    AltGraphicsGpuSmall: 'Alternative GPU Visualization small',
    AltGraphicsGpuMedium: 'Alternative GPU Visualization medium',
    MemoryOptimizedSmall: 'Memory-optimized small',
    MemoryOptimizedMedium: 'Memory-optimized medium',
};
export const skuMessages = defineMessages({
    basicSmall: {
        id: 'BasicSmall',
        defaultMessage: 'Basic small',
        description: 'Text for basic small sku.',
    },
    standardSmall: {
        id: 'StandardSmall',
        defaultMessage: 'Standard small',
        description: 'Text for standard small sku.',
    },
    standardMedium: {
        id: 'StandardMedium',
        defaultMessage: 'Standard medium',
        description: 'Text for standard medium sku.',
    },
    standardLarge: {
        id: 'StandardLarge',
        defaultMessage: 'Standard large',
        description: 'Text for standard large sku.',
    },
    computeGpuSmall: {
        id: 'ComputeGpuSmall',
        defaultMessage: 'GPU Compute small',
        description: 'Text for compute GPU small sku.',
    },
    computeGpuMedium: {
        id: 'ComputeGpuMedium',
        defaultMessage: 'GPU Compute medium',
        description: 'Text for compute GPU medium sku.',
    },
    graphicsGpuSmall: {
        id: 'GraphicsGpuSmall',
        defaultMessage: 'GPU Visualization small',
        description: 'Text for graphics GPU small sku.',
    },
    graphicsGpuMedium: {
        id: 'GraphicsGpuMedium',
        defaultMessage: 'GPU Visualization medium',
        description: 'Text for graphics GPU medium sku.',
    },
    altComputeGpuSmall: {
        id: 'AltComputeGpuSmall',
        defaultMessage: 'Alternative GPU Compute small',
        description: 'Text for alternative compute GPU small sku.',
    },
    altComputeGpuMedium: {
        id: 'AltComputeGpuMedium',
        defaultMessage: 'Alternative GPU Compute medium',
        description: 'Text for alternative compute GPU medium sku.',
    },
    altGraphicsGpuSmall: {
        id: 'AltGraphicsGpuSmall',
        defaultMessage: 'Alternative GPU Visualization small',
        description: 'Text for alternative graphics GPU small sku.',
    },
    altGraphicsGpuMedium: {
        id: 'AltGraphicsGpuMedium',
        defaultMessage: 'Alternative GPU Visualization medium',
        description: 'Text for alternative graphics GPU medium sku.',
    },
    memoryOptimizedSmall: {
        id: 'MemoryOptimizedSmall',
        defaultMessage: 'Memory-optimized small',
        description: 'Text for memory-optimized small sku.',
    },
    memoryOptimizedMedium: {
        id: 'MemoryOptimizedMedium',
        defaultMessage: 'Memory-optimized medium',
        description: 'Text for memory-optimized medium sku.',
    },
    classicSmall: {
        id: 'ClassicSmall',
        defaultMessage: 'Small',
        description: 'Text for classic small sku.',
    },
    classicMedium: {
        id: 'ClassicMedium',
        defaultMessage: 'Medium',
        description: 'Text for classic medium sku.',
    },
    classicLarge: {
        id: 'ClassicLarge',
        defaultMessage: 'Large',
        description: 'Text for classic large sku.',
    },
    classicMediumNestedVirtualization: {
        id: 'ClassicMediumNestedVirtualization',
        defaultMessage: 'Medium (Nested virtualization)',
        description: 'Text for medium (nested virtualization) sku.',
    },
    classicLargeNestedVirtualization: {
        id: 'ClassicLargeNestedVirtualization',
        defaultMessage: 'Large (Nested virtualization)',
        description: 'Text for large (nested virtualization) sku.',
    },
    classicSmallGpuCompute: {
        id: 'ClassicSmallGpuCompute',
        defaultMessage: 'Alternative Small GPU (Compute)',
        description: 'Text for alternative small GPU (Compute) sku.',
    },
    classicSmallGpuVisualization: {
        id: 'ClassicSmallGpuVisualization',
        defaultMessage: 'Small GPU (Visualization)',
        description: 'Text for small GPU (Visualization) sku.',
    },
    classicMediumGpuVisualization: {
        id: 'ClassicMediumGpuVisualization',
        defaultMessage: 'Medium GPU (Visualization)',
        description: 'Text for medium GPU (Visualization) sku.',
    },
});
