import { Record } from 'immutable';
import { TeamsActionType, } from '../actions/teams/teams-actions';
import { InitializationState } from '../state/common-store';
import { UserTeamRole, ChannelType, TeamsTheme } from '../../data/models/teams';
function teamsInitialize(state, action) {
    return state.merge({
        initializationState: InitializationState.Initializing,
    });
}
function teamsInitializeSuccess(state, action) {
    return state.merge({
        initializationState: InitializationState.Success,
    });
}
function teamsInitializeError(state, action) {
    const { error: initializeError } = action;
    return state.merge({
        initializationState: InitializationState.Failed,
        initializeError,
    });
}
function teamsDisableSaveButtonError(state, action) {
    const { disableSaveButtonError } = action;
    return state.merge({
        disableSaveButtonError,
    });
}
function teamsConfigureTabError(state, action) {
    const { configureTabError } = action;
    return state.merge({
        configureTabError,
    });
}
function setUserTeamRole(state, action) {
    const { userTeamRole } = action;
    return state.merge({
        userTeamRole,
    });
}
function setTeamsChannelType(state, action) {
    const { channelType } = action;
    return state.merge({
        channelType,
    });
}
function setTeamsTheme(state, action) {
    const { theme } = action;
    return state.merge({
        theme,
    });
}
const initialState = Record({
    initializationState: InitializationState.NotStarted,
    disableSaveButtonError: undefined,
    configureTabError: undefined,
    userTeamRole: UserTeamRole.Unknown,
    channelType: ChannelType.Unknown,
    theme: TeamsTheme.Unknown,
})();
export const teamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case TeamsActionType.TEAMS_INITIALIZE:
            return teamsInitialize(state, action);
        case TeamsActionType.TEAMS_INITIALIZE_SUCCESS:
            return teamsInitializeSuccess(state, action);
        case TeamsActionType.TEAMS_INITIALIZE_ERROR:
            return teamsInitializeError(state, action);
        case TeamsActionType.TEAMS_DISABLE_SAVE_BUTTON_ERROR:
            return teamsDisableSaveButtonError(state, action);
        case TeamsActionType.TEAMS_CONFIGURE_TAB_ERROR:
            return teamsConfigureTabError(state, action);
        case TeamsActionType.SET_USER_TEAM_ROLE:
            return setUserTeamRole(state, action);
        case TeamsActionType.SET_TEAMS_CHANNEL_TYPE:
            return setTeamsChannelType(state, action);
        case TeamsActionType.SET_TEAMS_THEME:
            return setTeamsTheme(state, action);
        default:
            return state;
    }
};
export default teamsReducer;
