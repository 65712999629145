import { Panel, PanelType } from '@fluentui/react';
import 'moment/min/locales';
import * as React from 'react';
import { Form } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { getCustomTheme } from '../common/themes/selectors';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import FlyoutFooter from './flyout-footer';
import FlyoutForm from './flyout-form';
import { RecurrenceDialog } from './flyout-recurrence-dialog';
import { ScheduleTypeDropdown } from './flyout-shared-controls';
import { dropdownTimezones, estimatedClassLength, getDefaultVNextWeeklySchedule, getDefaultWeeklySchedule, getDropdownKeyFromDate, } from './flyout-utilities';
import { FlyoutMode, isEndTimeSameDay } from './schedule';
import scheduleMessages from './schedule-messages';
import './schedule.css';
export const ScheduleType = {
    standard: 'standard',
    startOnly: 'startOnly',
    stopOnly: 'stopOnly',
};
export const RecurrenceType = {
    weekly: 'weekly',
    never: 'never',
};
const FlyoutInjected = (props) => {
    const { intl, isCurrentLabParentLabAccount, locale, timezone, isPanelOpen, isSaving, createSchedule, updateSchedule, onClose, mode, schedule, scheduleType, } = props;
    const addEvent = intl.formatMessage(scheduleMessages.addEvent);
    const editEvent = intl.formatMessage(scheduleMessages.editEvent);
    const close = intl.formatMessage(commonMessages.close);
    const startTimeValidationMessage = intl.formatMessage(scheduleMessages.startTimeValidationMessage);
    const stopTimeValidationMessage = intl.formatMessage(scheduleMessages.stopTimeValidationMessage);
    const minimumDurationValidationMessage = intl.formatMessage(scheduleMessages.minimumDurationValidationMessage);
    const [currentScheduleType, setCurrentScheduleType] = React.useState(scheduleType);
    const [isRecurrenceDialogOpen, setIsRecurrenceDialogOpen] = React.useState(false);
    let defaultSchedule = undefined;
    let startDate = undefined;
    let endDate = undefined;
    let endTime = undefined;
    if (isCurrentLabParentLabAccount) {
        defaultSchedule = modifyScheduleForAddEvent(getDefaultWeeklySchedule(timezone), new Date(), isCurrentLabParentLabAccount);
        const sched = schedule ?? defaultSchedule;
        startDate = sched.start;
        endDate = sched.recurrencePattern?.until;
        endTime = sched.end;
    }
    else {
        defaultSchedule = modifyScheduleForAddEvent(getDefaultVNextWeeklySchedule(timezone), new Date(), isCurrentLabParentLabAccount);
        const sched = schedule ?? defaultSchedule;
        startDate = sched.startAt;
        endDate = sched.recurrencePattern?.expirationDate;
        endTime = sched.stopAt;
    }
    const initialValues = {
        dayPicker: schedule && schedule.recurrencePattern
            ? schedule.recurrencePattern.weekDays
            : defaultSchedule.recurrencePattern.weekDays,
        startDatePicker: startDate ?? endTime,
        endDatePicker: endDate,
        startTimePicker: getDropdownKeyFromDate(startDate ?? endTime),
        stopTimePicker: getDropdownKeyFromDate(endTime),
        timezonePicker: schedule ? schedule.timeZoneId : getTimeZone(intl, defaultSchedule.timeZoneId),
        recurrencePicker: schedule
            ? schedule.recurrencePattern
                ? RecurrenceType.weekly
                : RecurrenceType.never
            : RecurrenceType.weekly,
        notes: schedule ? schedule.notes : '',
    };
    const [formValues, setFormValues] = React.useState(initialValues);
    const [recurrenceFormValues, setRecurrenceFormValues] = React.useState({
        dayPicker: initialValues.dayPicker,
        endDatePicker: initialValues.endDatePicker ? new Date(initialValues.endDatePicker) : undefined,
        recurrencePicker: initialValues.recurrencePicker,
    });
    React.useEffect(() => {
        setCurrentScheduleType(scheduleType);
    }, [scheduleType, schedule]);
    React.useEffect(() => {
        setFormValues(initialValues);
    }, [isPanelOpen]);
    const deleteRecurrence = (form) => {
        // Change recurrence frequency but do not remove end date.
        // Keep the end date value in case recurrence is added back.
        form.change('recurrencePicker', 'never');
    };
    const previousIsSaving = usePrevious(isSaving);
    React.useEffect(() => {
        if (previousIsSaving && !isSaving) {
            onClose();
        }
    }, [isSaving]);
    return (<Form onSubmit={() => {
        return; // no-op
    }} validate={(values) => {
        if (currentScheduleType === ScheduleType.standard) {
            const start = new Date();
            const stop = new Date();
            // picker values are like "0945" or "1645"
            start.setHours(parseInt(values.startTimePicker.substr(0, 2)), parseInt(values.startTimePicker.substr(2, 2)), 0, 0);
            stop.setHours(parseInt(values.stopTimePicker.substr(0, 2)), parseInt(values.stopTimePicker.substr(2, 2)), 0, 0);
            if (start >= stop) {
                return {
                    startTimePicker: startTimeValidationMessage,
                    stopTimePicker: stopTimeValidationMessage,
                };
            }
            else if (!isCurrentLabParentLabAccount) {
                // vnext schedules must be at least 15 minutes long
                start.setMinutes(start.getMinutes() + 15);
                if (start > stop) {
                    return {
                        stopTimePicker: minimumDurationValidationMessage,
                    };
                }
            }
        }
        return undefined;
    }} initialValues={formValues}>
            {(formProps) => (<form>
                    <Panel className="schedules__flyout" isOpen={isPanelOpen} type={PanelType.custom} customWidth="385px" isLightDismiss={isSaving ? false : true} onDismiss={isSaving ? undefined : onClose} headerText={mode === FlyoutMode.add ? addEvent : editEvent} closeButtonAriaLabel={close} styles={getCustomTheme().panelStyles} onRenderFooterContent={() => (<FlyoutFooter form={formProps.form} formValues={formProps.values} scheduleType={currentScheduleType} mode={mode} schedule={schedule} isCurrentLabParentLabAccount={isCurrentLabParentLabAccount} isSaving={isSaving} createSchedule={createSchedule} updateSchedule={updateSchedule} onClose={onClose}/>)}>
                        <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                            <FormattedMessage id="ScheduleFlyoutMessage" defaultMessage="Events can be used to start up and/or shut down all virtual machines in the lab at a specified time.  These events will not count against the user quota." description="Informational message displayed to user upon opening the flyout, explaining the schedule feature and that schedules do not count against quota."/>
                        </div>
                        <RecurrenceDialog isCurrentLabParentLabAccount={isCurrentLabParentLabAccount} isDialogOpen={isRecurrenceDialogOpen} onDialogClose={() => setIsRecurrenceDialogOpen(false)} locale={locale} form={formProps.form} recurrenceFormValues={recurrenceFormValues} values={formProps.values} schedule={schedule} defaultSchedule={defaultSchedule}/>
                        <ScheduleTypeDropdown isCurrentLabParentLabAccount={isCurrentLabParentLabAccount} isSaving={isSaving} currentScheduleType={currentScheduleType} setScheduleType={setCurrentScheduleType}/>
                        <FlyoutForm isSaving={isSaving} deleteRecurrence={() => deleteRecurrence(formProps.form)} locale={locale} values={formProps.values} scheduleType={currentScheduleType} onDialogOpen={() => setIsRecurrenceDialogOpen(true)} setRecurValues={(values) => setRecurrenceFormValues(values)}/>
                    </Panel>
                </form>)}
        </Form>);
};
function getTimeZone(intl, defaultTimeZone) {
    return !isTimezoneAvailable(intl, defaultTimeZone) ? '' : defaultTimeZone;
}
const isTimezoneAvailable = (intl, timeZoneId) => {
    const timezones = dropdownTimezones(intl);
    return timezones.some((timezone) => timezone.key === timeZoneId);
};
const getAdjustedMinutes = (currentMinutes) => {
    if (currentMinutes <= 15) {
        return 30;
    }
    else if (currentMinutes > 15 && currentMinutes < 30) {
        return 45;
    }
    else {
        return 60;
    }
};
const adjustScheduleEndDate = (schedule, now, minutes) => {
    const isSameDay = isEndTimeSameDay(schedule.start);
    if (!isSameDay) {
        schedule.end.setHours(23, 59);
    }
    else {
        schedule.end.setHours(now.getHours() + estimatedClassLength);
        schedule.end.setMinutes(minutes);
    }
    return schedule;
};
// OK to assume schedule.startAt is populated here because this is only used on a default (standard) schedule
const adjustVNextScheduleEndDate = (schedule, now, minutes) => {
    const isSameDay = isEndTimeSameDay(schedule.startAt);
    if (!isSameDay) {
        schedule.stopAt.setHours(23, 59);
    }
    else {
        schedule.stopAt.setHours(now.getHours() + estimatedClassLength);
        schedule.stopAt.setMinutes(minutes);
    }
    return schedule;
};
const adjustScheduleStartDate = (schedule, now, minutes) => {
    schedule.start.setHours(now.getHours());
    schedule.start.setMinutes(minutes);
    return schedule;
};
// OK to assume schedule.startAt is populated here because this is only used on a default (standard) schedule
const adjustVNextScheduleStartDate = (schedule, now, minutes) => {
    schedule.startAt.setHours(now.getHours());
    schedule.startAt.setMinutes(minutes);
    return schedule;
};
const adjustScheduleDatesForLateSameDay = (schedule) => {
    schedule.start.setDate(schedule.start.getDate() + 1);
    schedule.start.setHours(0, 0);
    schedule.end.setHours(estimatedClassLength, 0);
    return schedule;
};
// OK to assume schedule.startAt is populated here because this is only used on a default (standard) schedule
const adjustVNextScheduleDatesForLateSameDay = (schedule) => {
    schedule.startAt.setDate(schedule.startAt.getDate() + 1);
    schedule.startAt.setHours(0, 0);
    schedule.stopAt.setHours(estimatedClassLength, 0);
    return schedule;
};
const adjustScheduleDatesForLateNextDay = (schedule) => {
    schedule.start.setDate(schedule.start.getDate() + 1);
    schedule.start.setHours(0, 30);
    schedule.end.setHours(estimatedClassLength, 30);
    return schedule;
};
// OK to assume schedule.startAt is populated here because this is only used on a default (standard) schedule
const adjustVNextScheduleDatesForLateNextDay = (schedule) => {
    schedule.startAt.setDate(schedule.startAt.getDate() + 1);
    schedule.startAt.setHours(0, 30);
    schedule.stopAt.setHours(estimatedClassLength, 30);
    return schedule;
};
/**
 * To prevent users from adding a schedule in the past, we take the current time and
 * make adjustments.  We set the default to ~15-30 minutes after the current time as schedules
 * need about a 15 minute head up from the RP.
 *
 * If the current time is close to midnight, to fulfill the 15 minute heads up we adjust the schedule
 * to be set in the next day.
 *
 * This is applicable only for schedules created through the add button - clicking on the calendar still
 * respects the date/time chosen.
 */
export const modifyScheduleForAddEvent = (schedule, now, isCurrentLabParentLabAccount) => {
    const currentMinutes = now.getMinutes();
    const currentHour = now.getHours();
    if (isCurrentLabParentLabAccount) {
        let sched = schedule;
        if (currentHour == 23) {
            if (currentMinutes >= 30 && currentMinutes <= 45) {
                return adjustScheduleDatesForLateSameDay(sched);
            }
            else if (currentMinutes > 45) {
                return adjustScheduleDatesForLateNextDay(sched);
            }
        }
        const adjustedMinutes = getAdjustedMinutes(currentMinutes);
        sched = adjustScheduleStartDate(sched, now, adjustedMinutes);
        sched = adjustScheduleEndDate(sched, now, adjustedMinutes);
        return sched;
    }
    else {
        let sched = schedule;
        if (currentHour == 23) {
            if (currentMinutes >= 30 && currentMinutes <= 45) {
                return adjustVNextScheduleDatesForLateSameDay(sched);
            }
            else if (currentMinutes > 45) {
                return adjustVNextScheduleDatesForLateNextDay(sched);
            }
        }
        const adjustedMinutes = getAdjustedMinutes(currentMinutes);
        sched = adjustVNextScheduleStartDate(sched, now, adjustedMinutes);
        sched = adjustVNextScheduleEndDate(sched, now, adjustedMinutes);
        return sched;
    }
};
export const Flyout = injectIntl(FlyoutInjected);
export default Flyout;
