import { put, select } from 'redux-saga/effects';
import { updateUserSettings } from '../../actions/identity/identity-action-creators';
import { LabAccountActionType } from '../../actions/lab-account/lab-account-actions';
import { featureBasedTakeLatest } from '../../effects/feature-based';
import { getUserSettings } from '../../selectors/identity-selector';
export function* rememberLabAccount(action) {
    const { resourceId: lastUsedLabParent } = action;
    // only update our user settings if this value changes
    const currentSettings = yield select(getUserSettings);
    if (currentSettings.get('lastUsedParentResource') !== lastUsedLabParent) {
        const settingsUpdate = {
            lastUsedParentResource: lastUsedLabParent,
        };
        yield put(updateUserSettings(settingsUpdate));
    }
}
export function* rememberVNextLabParent(action) {
    const { resourceId: lastUsedLabParent } = action;
    // only update our user settings if this value changes
    const currentSettings = yield select(getUserSettings);
    if (currentSettings.get('lastUsedParentResource') !== lastUsedLabParent) {
        const settingsUpdate = {
            lastUsedParentResource: lastUsedLabParent,
        };
        yield put(updateUserSettings(settingsUpdate));
    }
}
export function* rememberLabAccountSaga() {
    yield featureBasedTakeLatest(LabAccountActionType.SELECT_LAB_PARENT_RESOURCE, rememberLabAccount, rememberVNextLabParent);
}
