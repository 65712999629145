import Settings from '../../settings/settings';
import { ApplicationInsights, PostChannel, } from '@microsoft/1ds-analytics-web-js';
import { PropertiesPlugin } from '@microsoft/1ds-properties-js';
import { QosPlugin } from '@microsoft/1ds-qos-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
export class OneDSChannel {
    constructor() {
        this._isInitialized = false;
        this._analytics = new ApplicationInsights();
        this._propertiesPlugin = new PropertiesPlugin();
        this._collectorChannelPlugin = new PostChannel();
        this._qosPlugin = new QosPlugin();
    }
    initialize(telemetryInitializer) {
        if (this._isInitialized) {
            return;
        }
        const webAnalyticsConfig = {
            autoCapture: {
                scroll: false,
                pageView: false,
                onLoad: false,
                onUnload: false,
                click: false,
                resize: false,
                jsError: true,
            },
            shareAuthStatus: true,
            autoPopulateParentIdAndParentName: true,
        };
        const propertiesPluginConfig = {
            populateBrowserInfo: true,
            populateOperatingSystemInfo: true,
            enableApplicationInsightsTrace: true,
            dropIdentifiers: true,
        };
        const qosPluginConfig = {
            enableCorsCorrelation: true,
            disableAjaxTracking: false,
            disableFetchTracking: false,
            enableRequestHeaderTracking: true,
            enableResponseHeaderTracking: true,
        };
        const config = {
            instrumentationKey: Settings.OneDS.InstrumentationKey,
            endpointUrl: Settings.OneDS.CollectorEndpoint,
            extensions: [this._qosPlugin, this._propertiesPlugin],
            channels: [[this._collectorChannelPlugin]],
            extensionConfig: { qosPluginIdentifier: qosPluginConfig },
            webAnalyticsConfiguration: webAnalyticsConfig,
            propertyConfiguration: propertiesPluginConfig,
            disableCookiesUsage: true,
            enableDebugExceptions: false,
        };
        //Initialize SDK
        this._analytics.initialize(config, []);
        this._analytics.addTelemetryInitializer(telemetryInitializer);
        this._isInitialized = true;
    }
    trackPageView(name, uri, properties) {
        this._analytics.trackPageView({ name, uri }, properties);
    }
    trackEvent(name, properties) {
        this._analytics.trackEvent({ name }, properties);
    }
    trackDependencyData(id, responseCode, name, duration, success, correlationContext, type, data, properties) {
        this._qosPlugin.trackDependencyData({ id, responseCode, name, duration, success, correlationContext, type, data }, properties);
    }
    trackException(id, exception, severityLevel, properties) {
        this._analytics.trackException({ id, exception, severityLevel }, properties);
    }
    trackTrace(message, properties, severityLevel) {
        const traceProperties = {
            message: message,
            severityLevel: severityLevel ? severityLevel.toString() : SeverityLevel.Verbose.toString(),
        };
        if (properties) {
            properties['message'] = message;
            properties['severityLevel'] = severityLevel ? severityLevel.toString() : SeverityLevel.Verbose.toString();
        }
        this._analytics.trackEvent({ name: 'TraceTelemetry' }, properties ? properties : traceProperties);
    }
    trackMetric(name, average, sampleCount, min, max) {
        this._analytics.trackEvent({ name: 'MetricTelemetry' }, { metricName: name, average, sampleCount, min, max });
    }
    setAuthenticatedUserContext(authenticatedUserId, accountId, _storeInCookie) {
        this._propertiesPlugin.setProperty('authenticatedUserId', authenticatedUserId);
        if (accountId) {
            this._propertiesPlugin.setProperty('accountId', accountId);
        }
    }
    clearAuthenticatedUserContext() {
        this._propertiesPlugin.setProperty('authenticatedUserId', '');
    }
    flush() {
        this._collectorChannelPlugin.flush();
    }
}
