import { PricingAndAvailabilityActionType, } from './pricing-and-availability-actions';
export const getPricingAndAvailabilityData = (labAccountId) => ({
    type: PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA,
    labAccountId,
});
export const getPricingAndAvailabilityDataSuccess = (data) => ({
    type: PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA_SUCCESS,
    data,
});
export const getVNextPricingAndAvailabilityDataSuccess = (data) => ({
    type: PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA_SUCCESS,
    data,
});
export const getPricingAndAvailabilityDataError = (error) => ({
    type: PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA_ERROR,
    error,
});
export const getLabPricingAndAvailabilityData = (labId) => ({
    type: PricingAndAvailabilityActionType.GET_LAB_PRICING_AND_AVAILABILITY_DATA,
    labId,
});
export const getLabPricingAndAvailabilityDataSuccess = (data) => ({
    type: PricingAndAvailabilityActionType.GET_LAB_PRICING_AND_AVAILABILITY_DATA_SUCCESS,
    data,
});
export const getLabPricingAndAvailabilityDataError = (error) => ({
    type: PricingAndAvailabilityActionType.GET_LAB_PRICING_AND_AVAILABILITY_DATA_ERROR,
    error,
});
export const clearLabPricingAndAvailabilityError = () => ({
    type: PricingAndAvailabilityActionType.CLEAR_LAB_PRICING_AND_AVAILABILITY_ERROR,
});
const actions = {
    getPricingAndAvailabilityData,
    getPricingAndAvailabilityDataSuccess,
    getPricingAndAvailabilityDataError,
    getLabPricingAndAvailabilityData,
    getLabPricingAndAvailabilityDataSuccess,
    getLabPricingAndAvailabilityDataError,
    clearLabPricingAndAvailabilityError,
    getVNextPricingAndAvailabilityDataSuccess,
};
export default actions;
