import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    showUnavailable: {
        id: 'VmSizePickerShowUnavailable',
        defaultMessage: 'Show unavailable sizes',
        description: 'Label for a checkbox to show / hide unavailable sizes.',
    },
    placeholder: {
        id: 'VmSizePickerPlaceholderText',
        defaultMessage: 'Please select a size',
        description: 'Placeholder text for the size picker when nothing is selected.',
    },
    simplifiedSizeLabel: {
        id: 'NewLabConfigPageSimplifiedSizeLabel',
        defaultMessage: 'Virtual machine size',
        description: 'Label for size drop-down on new lab flyout dialog based on simplified designs.',
    },
});
