import { LabServicesModels } from 'lab-services';
import memoize from 'memoize-one';
import { getCurrentLabId } from '../lab-selectors';
import { getVNextLab } from './lab-selectors';
export const getVNextPricingAndAvailabilityData = memoize((store) => store.get('vNextPricingAndAvailabilityStore').get('data'));
export const getCurrentLabSizeData = memoize((store) => {
    const currentLabId = getCurrentLabId(store);
    const currentVNextLab = getVNextLab(store, currentLabId);
    const imageType = currentVNextLab?.virtualMachineProfile?.osType;
    const sku = currentVNextLab?.virtualMachineProfile?.sku;
    const pricingAndAvailabilityData = getVNextPricingAndAvailabilityData(store);
    if (!imageType || !sku || !pricingAndAvailabilityData) {
        return undefined;
    }
    const sizeData = imageType === LabServicesModels.OsType.Linux
        ? pricingAndAvailabilityData.linux
        : pricingAndAvailabilityData.windows;
    return sizeData.sizes[sku.name];
});
