import { defineMessages } from 'react-intl';
const messages = defineMessages({
    requiredError: {
        id: 'LabNameVnextValidatorRequiredError',
        defaultMessage: 'Lab name is required.',
        description: 'Error shown for invalid lab name values.',
    },
    specialCharsError: {
        id: 'LabNameVnextValidatorSpecialCharsError',
        defaultMessage: 'Lab name can only contain alphanumeric characters, periods, underscores, and hyphens.',
        description: 'Error shown for invalid lab name values.',
    },
    duplicateNameError: {
        id: 'LabNameVnextValidatorDuplicateNameError',
        defaultMessage: 'A lab with this name already exists.',
        description: 'Error shown for invalid lab name values.',
    },
    nameTooLongError: {
        id: 'LabNameVnextValidatorNameTooLongError',
        defaultMessage: 'The lab name should be less than 100 characters in length.',
        description: 'Error shown for lab name values that are too long.',
    },
});
const labNameRegex = /^[0-9A-Za-z-._~]+$/;
export function validateVnextLabName(name, labNames, intl) {
    if (!name || name.trim().length === 0) {
        return intl.formatMessage(messages.requiredError);
    }
    else if (!labNameRegex.test(name)) {
        return intl.formatMessage(messages.specialCharsError);
    }
    else if (labNames.some((o) => o.toLowerCase() === name.toLowerCase())) {
        return intl.formatMessage(messages.duplicateNameError);
    }
    else if (name.trim().length > 100) {
        return intl.formatMessage(messages.nameTooLongError);
    }
    else {
        return undefined;
    }
}
