import { ButtonType, DirectionalHint, DocumentCard, DocumentCardActions, DocumentCardLogo, DocumentCardTitle, DocumentCardType, Icon, ProgressIndicator, Spinner, SpinnerSize, TooltipHost, Stack, MessageBar, MessageBarType, Link, } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import './student-vm-card.css';
import { getConnectionContextMenuItems } from '../common/selectors/vm-connect';
import { getCustomTheme } from '../common/themes/selectors';
import { VmPowerState } from '../common/vm/vm-power-state';
import { ConnectionType } from '../data/models/environment-common';
import { commonMessages } from '../language/common-messages';
import { isValidResourceId } from '../utils/resource-id';
import { VmOperationError, VmHeartbeatError } from '../vm-list/vm-operation-error';
import OperationStatus from '../utils/operation-status';
import { VmOperationSuccess } from '../vm-list/vm-operation-success';
import { OperationType, mapResourceErrorToOperation } from '../data/models/vnext/operation';
import { messages as VmListMessages } from '../vm-list/vm-list-messages';
const messages = defineMessages({
    vmConnectAria: {
        id: 'VmConnectAria',
        defaultMessage: 'Connect to the virtual machine',
        description: 'Aria label for a button that connects to a VM.',
    },
    vmResetPasswordTitle: {
        id: 'VmResetPasswordTitle',
        defaultMessage: 'Reset password',
        description: 'Title for the reset password button.',
    },
    vmQuotaProgressMessage: {
        id: 'VmQuotaProgressMessage',
        defaultMessage: '{hoursUsed} / {quotaHours} hour(s) used',
        description: 'Message to display the amount of quota hours that have been consumed.',
    },
    moreVmActionsAria: {
        id: 'MoreVmActionsAria',
        defaultMessage: 'More actions menu',
        description: 'Aria label for a button that contains additional VM actions.',
    },
    vmScheduleInProgressTooltip: {
        id: 'VmScheduleInProgressTooltip',
        defaultMessage: 'Usage is not tracked during scheduled class time.',
        description: 'Message displayed in a callout / tooltip when hovering over the hour quota bar when a class is in progress.',
    },
    vmUnavailableMessage: {
        id: 'VMUnavailableMessage',
        defaultMessage: 'You have registered for this lab, but the virtual machine is not yet available.',
        description: 'Message to display when the virtual machine is not available.',
    },
});
const VmScheduleAndPasswordResetStatusInjected = (props) => {
    let message;
    const { palette } = getCustomTheme();
    if (props.isResettingPassword) {
        const resettingPasswordMessage = props.intl.formatMessage(commonMessages.resettingPassword);
        const settingPasswordMessage = props.intl.formatMessage(commonMessages.settingPassword);
        return (<div className="student-vm-card__change-password-status-container ms-fontSize-12">
                <Spinner size={SpinnerSize.medium} labelPosition="right" label={(!props.lastPasswordReset && settingPasswordMessage) || resettingPasswordMessage} styles={{
            root: {
                alignContent: 'flex-start',
                justifyContent: 'flex-start',
            },
        }}/>
                <div className="student-vm-card__change-password-status-message" style={{ color: palette?.themePrimary, marginLeft: '30px' }}>
                    <FormattedMessage id="VmPasswordChangeMessage" defaultMessage="This may take several minutes..." description="Message shown below the loading spinner while password changes are in progress."/>
                </div>
            </div>);
    }
    else if (props.isScheduleRunning) {
        message = (<FormattedMessage id="VmScheduleInProgressMessage" defaultMessage="Class in progress" description="Message displayed on the VM card when the lab scheduled hours are in progress."/>);
    }
    else if (props.isScheduleOnly) {
        message = (<FormattedMessage id="VmScheduleOnlyMessage" defaultMessage="Available during classes only" description="Message displayed on the VM card when the VM is only available during scheduled hours."/>);
    }
    else if (props.hasUnlimitedQuota) {
        message = (<FormattedMessage id="VmUnlimitedHoursMessage" defaultMessage="Unlimited hours" description="Message displayed on the VM card when the user has unlimited hours to use."/>);
    }
    else {
        return null;
    }
    return (<div className="student-vm-card__content-hours-note">
            <Icon iconName="Clock" styles={{
        root: {
            fontSize: '16px',
            lineHeight: '16px',
            marginRight: '6px',
            color: palette?.themePrimary,
        },
    }}/>
            <div className="ms-fontWeight-semibold">{message}</div>
        </div>);
};
const VmScheduleAndPasswordResetStatus = injectIntl(VmScheduleAndPasswordResetStatusInjected);
const VmQuotaProgressInjected = (props) => {
    const { isScheduleRunning, hoursUsed, quotaHours, intl } = props;
    return (<ProgressIndicator description={!isScheduleRunning
        ? props.intl.formatMessage(messages.vmQuotaProgressMessage, {
            hoursUsed: intl.formatNumber(hoursUsed, {
                maximumFractionDigits: 1,
            }),
            quotaHours: intl.formatNumber(quotaHours, {
                maximumFractionDigits: 0,
            }),
        })
        : ''} percentComplete={hoursUsed / quotaHours} barHeight={4} styles={{
        root: {
            flexGrow: 1,
        },
        itemProgress: {
            paddingTop: 6,
            paddingBottom: 6,
        },
        progressBar: {
            // todo: get the color from the theme
            background: (isScheduleRunning && '#979593') || (hoursUsed >= quotaHours && '#DA3B01') || undefined,
        },
        progressTrack: {
            background: '#EDEBE9',
        },
    }}/>);
};
export const VmQuotaProgress = injectIntl(VmQuotaProgressInjected);
const StudentVmCardInjected = (props) => {
    const { item, intl, isBastionEnabled, isTeamsIntegrationEnabled } = props;
    const { formatMessage: msg } = intl;
    const vmConnectAria = msg(messages.vmConnectAria);
    const vmScheduleInProgressTooltip = msg(messages.vmScheduleInProgressTooltip);
    const isVCurrentVM = isValidResourceId(item.id);
    const connectionTypes = isVCurrentVM
        ? item.connectionTypes.filter((type) => isBastionEnabled || (type !== ConnectionType.RdpInBrowser && type !== ConnectionType.SshInBrowser))
        : item.connectionTypes;
    const isRdpConnectionInTeams = isTeamsIntegrationEnabled && item.connectionTypes.indexOf(ConnectionType.Rdp) > -1;
    const connectAction = {
        ariaLabel: vmConnectAria,
        disabled: !item.canConnect,
        iconProps: {
            iconName: 'ConnectVirtualMachine',
            styles: {
                root: {
                    fontSize: '16px',
                    lineHeight: '16px',
                },
            },
        },
        title: vmConnectAria,
        buttonType: ButtonType.icon,
        menuIconProps: {
            // this is used to hide the chevron
            style: { display: 'none' },
        },
        onClick: connectionTypes.length === 1 && !isRdpConnectionInTeams
            ? () => props.onConnect(item, connectionTypes[0])
            : undefined,
    };
    if (connectionTypes.length > 1 || isRdpConnectionInTeams) {
        const items = getConnectionContextMenuItems(item.canConnect, item, connectionTypes, props.onConnect, intl, isTeamsIntegrationEnabled);
        connectAction.menuProps = { items, isSubMenu: true };
    }
    const moreActions = [connectAction];
    const menuItems = [];
    const vmOperation = item.operation || mapResourceErrorToOperation(item.resourceOperationError);
    const vmOperationError = item.resourceOperationError;
    if (!item.useSharedPassword && (isVCurrentVM || item.isRunning)) {
        menuItems.push({
            key: 'ResetPassword',
            text: msg(messages.vmResetPasswordTitle),
            disabled: !item.canResetPassword,
            iconProps: {
                iconName: 'Permissions',
                styles: {
                    root: {
                        fontSize: '16px',
                        lineHeight: '16px',
                    },
                },
            },
            onClick: () => {
                props.onResetPassword(item);
            },
        });
    }
    if (!isValidResourceId(item.id)) {
        if (item.canRedeploy) {
            menuItems.push({
                key: 'troubleshoot',
                text: msg(commonMessages.troubleshoot),
                iconProps: { iconName: 'Repair' },
                onClick: () => {
                    props.onShowTroubleshootDialog(item);
                },
            });
        }
        menuItems.push({
            key: 'reimage',
            iconProps: { iconName: 'Refresh' },
            text: msg(commonMessages.reset),
            onClick: () => {
                props.onReimage(item);
            },
            disabled: !item.canReimage,
        });
    }
    if (item.connectionTypes.includes(ConnectionType.Rdp) && !!item.rdpAuthority) {
        menuItems.push({
            key: 'ShowRdpInfo',
            text: msg(commonMessages.showRdpInfoTitle),
            iconProps: {
                iconName: 'ClipboardList',
                styles: {
                    root: {
                        fontSize: '16px',
                        lineHeight: '16px',
                    },
                },
            },
            onClick: () => {
                props.onShowRdpInfo(item);
            },
        });
    }
    if (menuItems.length > 0) {
        const moreVmActionsAria = msg(messages.moreVmActionsAria);
        moreActions.push({
            ariaLabel: moreVmActionsAria,
            menuIconProps: {
                iconName: 'MoreVertical',
                ariaLabel: moreVmActionsAria,
                title: moreVmActionsAria,
                styles: {
                    root: {
                        fontSize: '16px',
                        lineHeight: '16px',
                    },
                },
            },
            menuProps: {
                items: menuItems,
            },
            disabled: menuItems.findIndex((item) => !item.disabled) < 0,
        });
    }
    return (<DocumentCard type={DocumentCardType.normal} styles={{
        root: {
            margin: '10px',
            width: '330px',
            maxWidth: '330px',
            height: '300px',
            maxHeight: '300px',
            display: 'flex',
            flex: '0 0',
            flexDirection: 'column',
            boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.108), 0px 6.4px 14.4px rgba(0, 0, 0, 0.132)',
            borderRadius: '2px',
            backgroundColor: item.isMoving || item.isUnavailable ? '#FAF9F8' : undefined,
        },
    }}>
            <div className="student-vm-card__header">
                <div className="student-vm-card__header-title-container">
                    {!isVCurrentVM && (vmOperation?.status == OperationStatus.Failed) && vmOperationError?.code !== "HeartbeatNotReceived" && (<Stack styles={{ root: { paddingTop: '13px', paddingLeft: '10px' } }}>
                            <VmOperationError operationType={vmOperation?.operationType}/>
                        </Stack>)}
                    {!isVCurrentVM && vmOperation?.status == OperationStatus.Succeeded && vmOperationError?.code !== "HeartbeatNotReceived" && (<Stack styles={{ root: { paddingTop: '13px', paddingLeft: '10px' } }}>
                            <VmOperationSuccess operationType={vmOperation?.operationType}/>
                        </Stack>)}
                    {!isVCurrentVM && vmOperationError?.code == "HeartbeatNotReceived" && (<Stack styles={{ root: { paddingTop: '13px', paddingLeft: '10px' } }}>
                            <VmHeartbeatError />
                        </Stack>)}

                    <DocumentCardTitle title={item.title || ''} styles={{
        root: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontWeight: 600,
            height: '38px',
            marginTop: '25px',
            paddingTop: '0px',
            paddingBottom: '0px',
            paddingRight: '0px',
            paddingLeft: '11px',
        },
    }}/>
                </div>
                <div className="student-vm-card__header-logo-container">
                    <span className={(!item.isWindows && 'student-vm-card__linux-logo') || ''}>
                        <DocumentCardLogo logoIcon={item.isWindows ? 'WindowsLogo' : 'LinuxLogo32'} styles={{
        root: {
            fontSize: '28px',
            lineHeight: '16px',
            color: '#8A8886',
        },
    }}/>
                    </span>
                </div>
            </div>

            {!!item.isUnavailable && (<div className="student-vm-card__content">
                    <div className="student-vm-card__content-description">
                        <DocumentCardTitle title={msg(messages.vmUnavailableMessage)} shouldTruncate showAsSecondaryTitle styles={{
        root: {
            height: '100%',
        },
    }}/>
                    </div>
                </div>)}
            {!item.isUnavailable && (<>
                    <div className="student-vm-card__content">
                        
                        {!isVCurrentVM && (vmOperation?.status == OperationStatus.Failed) && <MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={props.intl.formatMessage(commonMessages.close)}>
                            <FormattedMessage id="VmOperationErrorBannerGenericMessage" defaultMessage="Virtual machine {Operation} operation failed. Try {redeploying} the virtual machine. If the issue persists, consider {reimaging} it. For further assistance, request your IT administrator to submit a support request." description="Message displayed on a student VM card when the VM is under maintenance and can't be accessed." values={{
        redeploying: (<Link onClick={() => props.onShowTroubleshootDialog(item)}>
                                            <FormattedMessage id="VmOperationErrorBannerRedeploy" defaultMessage="redeploying" description="clickable action for redeploying the VM in the error message."/>
                                        </Link>),
        reimaging: (<Link onClick={() => props.onReimage(item)}>
                                            <FormattedMessage id="VmOperationErrorBannerReimage" defaultMessage='reimaging' description="clickable action for reimaging the VM in the error message."/>
                                        </Link>),
        Operation: vmOperation?.operationType === OperationType.StartOperation
            ? intl.formatMessage(VmListMessages.StartOperation)
            : (vmOperation?.operationType === OperationType.StopOperation)
                ? intl.formatMessage(VmListMessages.StopOperation)
                : '',
    }}/>
                        </MessageBar>}
                        <div className="student-vm-card__content-description">
                            <DocumentCardTitle shouldTruncate={true} title={item.description || ''} showAsSecondaryTitle={true} styles={{
        root: {
            height: '100%',
        },
    }}/>                   
                        </div>                  
                        <div className="student-vm-card__content-hours-used">
                            <div className="student-vm-card__progress-bar-container">
                                {!item.isMoving &&
        !item.isResettingPassword &&
        !item.hasUnlimitedQuota &&
        !item.isScheduleOnly && (<TooltipHost content={vmScheduleInProgressTooltip} directionalHint={DirectionalHint.topLeftEdge} hidden={!item.isScheduleRunning} calloutProps={{
        calloutMaxWidth: 244,
        directionalHint: DirectionalHint.topLeftEdge,
        className: 'student-vm-card__progress-bar-tooltip-container',
    }}>                                           
                                            <VmQuotaProgress {...item}/>
                                        </TooltipHost>)}
                            </div>
                            <VmScheduleAndPasswordResetStatus {...item}/>
                        </div>
                    </div>
                    <div className="student-vm-card__divider ms-bgColor-neutralLight"/>
                    <div className="student-vm-card__footer">
                        {item.isMoving && (<div className="student-vm-card__footer-message">
                                <FormattedMessage id="StudentVmCardMaintenanceMessage" defaultMessage="This virtual machine is part of a lab that is under maintenance. You can't access it at this time." description="Message displayed on a student VM card when the VM is under maintenance and can't be accessed."/>
                            </div>)}
                        {!item.isMoving && (<>
                                {!item.isResettingPassword && (<div style={{ marginLeft: 8 }}>
                                        <VmPowerState vmState={item.vmState} forceDisable={item.isResettingPassword || (!item.canStart && !item.canStop)} onStart={() => props.onStart(item)} onStop={() => props.onStop(item)}/>
                                    </div>)}
                                <div className="student-vm-card__footer-more-menu">
                                    {moreActions.length > 0 ? (<DocumentCardActions actions={moreActions} styles={{
        root: {
            paddingLeft: '0px',
            paddingRight: '0px',
            selectors: {
                ':hover': getCustomTheme().palette?.themePrimary,
            },
        },
    }}/>) : (<div className="student-vm-card__footer-more-menu--placeholder"/>)}
                                </div>
                            </>)}
                    </div>
                </>)}
        </DocumentCard>);
};
export const StudentVmCard = injectIntl(StudentVmCardInjected);
export default StudentVmCard;
