import { LabServicesModels } from 'lab-services';
import { LabsApiModels } from 'lab-services-internal';
import { duration } from 'moment';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { idleConfigToAutoShutdownProfile } from '../../common/shutdown-policy/selectors';
import { toEnableState, toConnectionType } from '../../data/helpers/enum-helpers';
import { ConnectionType } from '../../data/models/environment-common';
import { createVNextLab, checkVnextCreatelabPermission } from '../../redux/actions/lab/lab-action-creators';
import { selectLabPlan } from '../../redux/actions/lab-plan/lab-plan-action-creators';
import { trackEvent } from '../../utils/telemetry/telemetry-channel';
import { getCreateLabContainerModel, getDefaultConnectionTypes, getDefaultImage, getDefaultLocationFromSku, getDefaultSku, } from './create-lab-selectors';
import VNext_NewLabFlyout from './create-lab-flyout-vnext';
class VNext_CreateLabFlyoutContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._selectDefaultLabPlan = () => {
            const { labPlans } = this.props.createLabViewModel;
            if (!!labPlans && labPlans.size > 0) {
                this.props.selectLabPlan(labPlans.get(0).id);
            }
        };
        this._onFormStateChange = (newState) => {
            const { formState: oldState } = this.state;
            const formState = { ...oldState, ...newState };
            this.setState({ formState });
        };
        this._updateFormDataOnImageLoaded = (shouldInitializeName) => {
            const { createLabViewModel, defaultLabName } = this.props;
            const { name } = this.state.formState;
            const { username, password, nonAdminUsername, nonAdminPassword, isNonAdminEnabled } = this.state.formState;
            const { images, labAccount, skuData, defaultIdleConfig, isBastionEnabled, selectedLabPlan, isV2BastionEnabled, } = createLabViewModel;
            const image = getDefaultImage(images);
            const sku = !image || !skuData ? undefined : getDefaultSku(skuData, image);
            const location = !image || !sku ? undefined : getDefaultLocationFromSku(sku, image);
            const connectionTypes = image
                ? getDefaultConnectionTypes(image.isWindows, labAccount, selectedLabPlan, isBastionEnabled, isV2BastionEnabled)
                : [];
            this._onFormStateChange({
                name: !!shouldInitializeName ? defaultLabName : name,
                image,
                location,
                sku,
                connectionTypes,
                idleConfig: defaultIdleConfig,
                labPlanId: selectedLabPlan?.id,
                useSharedPassword: true,
                isNonAdminEnabled,
                username,
                password,
                nonAdminUsername,
                nonAdminPassword,
                isTemplateVmEnabled: true,
            });
        };
        this._initializeFormData = () => {
            this._updateFormDataOnImageLoaded(true);
            this.setState({ formStateInitialized: true });
        };
        this.state = {
            formStateInitialized: false,
            formState: {
                name: '',
                image: undefined,
                sku: undefined,
                location: undefined,
                isRdpEnabled: false,
                installGpuDriverEnabled: true,
                customizeTemplate: true,
                username: '',
                password: '',
                useSharedPassword: false,
                nonAdminUsername: '',
                nonAdminPassword: '',
                isNonAdminEnabled: false,
                quota: 10,
                connectionTypes: [],
                idleConfig: undefined,
                isTemplateVmEnabled: true,
                labCapacity: 0,
            },
        };
    }
    componentDidMount() {
        trackEvent('CREATE_LAB_STARTED');
        if (this.props.labParentId) {
            if (!this.props.isLoadingLabPlans && !this.props.hasLabPlanLoadError) {
                this._selectDefaultLabPlan();
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { isLoading, hasLoadError, isLoadingOnSelectLabPlan } = this.props.createLabViewModel;
        const { isLoadingLabPlans, hasLabPlanLoadError } = this.props;
        if (prevProps.isLoadingLabPlans && !isLoadingLabPlans && !hasLabPlanLoadError) {
            this._selectDefaultLabPlan();
        }
        // initialize form when finishing loading stores. listing lab plan images is completed before all required stores are loaded.
        if (prevProps.createLabViewModel.isLoading && !isLoading && !hasLoadError && !isLoadingOnSelectLabPlan) {
            this._initializeFormData();
        }
        if (prevProps.createLabViewModel.isSubmitting != this.props.createLabViewModel.isSubmitting &&
            !this.props.createLabViewModel.isSubmitting) {
            if (this.props.hasLabErrors) {
                this.props.onDismiss();
            }
        }
        if (!!prevProps.createLabViewModel.isLoadingOnSelectLabPlan &&
            !isLoadingOnSelectLabPlan &&
            !isLoading &&
            !hasLoadError) {
            // initialize form when finishing listing the images of default selected lab plan
            if (!prevState.formStateInitialized) {
                this._initializeFormData();
            }
            else {
                // update form when switching lab plan
                this._updateFormDataOnImageLoaded();
            }
        }
    }
    render() {
        const { onDismiss, createLabViewModel, selectLabPlan } = this.props;
        const { isLoading } = createLabViewModel;
        const { formStateInitialized, formState } = this.state;
        return (<VNext_NewLabFlyout createLabViewModel={createLabViewModel} isLoading={isLoading || !formStateInitialized} formState={formState} onFormStateChange={this._onFormStateChange} onSubmit={() => this._onSubmit()} onDismiss={onDismiss} onSelectLabPlan={(labPlanId) => {
            selectLabPlan(labPlanId);
        }}/>);
    }
    _onSubmit() {
        const { onCreatedRoute, createLabViewModel, aadGroupId } = this.props;
        const { isBastionEnabled, selectedLabPlan } = createLabViewModel;
        const { formState } = this.state;
        const { name, sku, location, image, installGpuDriverEnabled, useSharedPassword, username, password, nonAdminUsername, nonAdminPassword, isNonAdminEnabled, quota, idleConfig, connectionTypes, isTemplateVmEnabled, labCapacity, } = formState;
        const isSharedPasswordDisabled = !useSharedPassword;
        const connectionProfile = {
            webSshAccess: toConnectionType(isBastionEnabled && connectionTypes.includes(ConnectionType.SshInBrowser)),
            webRdpAccess: toConnectionType(isBastionEnabled && connectionTypes.includes(ConnectionType.RdpInBrowser)),
            clientSshAccess: toConnectionType(isBastionEnabled && connectionTypes.includes(ConnectionType.Ssh)),
            clientRdpAccess: toConnectionType(isBastionEnabled && connectionTypes.includes(ConnectionType.Rdp)),
        };
        const nonAdminUser = {
            username: nonAdminUsername.trim(),
            password: nonAdminPassword.trim(),
        };
        const virtualMachineProfile = {
            createOption: isTemplateVmEnabled
                ? LabsApiModels.CreateOption.TemplateVM
                : LabsApiModels.CreateOption.Image,
            usageQuota: duration(quota, 'h').toISOString(),
            imageReference: !image?.imageReference ? {} : image.imageReference,
            osType: !image?.isWindows ? LabServicesModels.OsType.Linux : LabServicesModels.OsType.Windows,
            sku: {
                name: sku.name,
                capacity: !isTemplateVmEnabled ? labCapacity : 0,
            },
            useSharedPassword: toEnableState(!isSharedPasswordDisabled),
            adminUser: {
                username: username.trim(),
                password: password.trim(),
            },
            nonAdminUser: isNonAdminEnabled ? nonAdminUser : undefined,
            additionalCapabilities: {
                installGpuDrivers: sku?.size.isGpu ? toEnableState(installGpuDriverEnabled) : toEnableState(false),
            },
        };
        const createLabProps = {
            name: name.trim(),
            title: name.trim(),
            location: location?.key ?? '',
            labPlanId: selectedLabPlan?.id,
            securityProfile: {
                openAccess: toEnableState(false),
            },
            rosterProfile: {
                activeDirectoryGroupId: !aadGroupId ? undefined : aadGroupId,
            },
            autoShutdownProfile: idleConfigToAutoShutdownProfile(idleConfig),
            connectionProfile,
            virtualMachineProfile,
            networkProfile: !!selectedLabPlan?.defaultNetworkProfile ? selectedLabPlan.defaultNetworkProfile : {},
        };
        if (createLabProps.labPlanId !== null) {
            this.props.checkVnextCreatelabPermission(createLabProps.labPlanId);
        }
        this.props.createVNextLab(this.props.labParentId, createLabProps, onCreatedRoute);
    }
}
const mapStateToProps = (state) => {
    return getCreateLabContainerModel(state);
};
const mapDispatchToProps = {
    selectLabPlan,
    checkVnextCreatelabPermission,
    createVNextLab,
};
export const VNext_CreateLabFlyoutContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(VNext_CreateLabFlyoutContainerInjected));
export default VNext_CreateLabFlyoutContainer;
