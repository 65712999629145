import { call, put, select, takeLatest, race, take } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import LabPlanProvider from '../../../data/providers/vnext/arm-api/lab-plan-provider';
import { ResourceId } from '../../../utils/resource-id';
import { getLabPlan as getLabPlanAction, getLabPlanError, getLabPlanSuccess, } from '../../actions/lab-plan/lab-plan-action-creators';
import { LabPlanActionType } from '../../actions/lab-plan/lab-plan-actions';
import { getLanguage, getLocale } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { listImages } from '../../actions/vnext/images/image-action-creators';
import { getLabPlans } from '../../selectors/vnext/lab-plan-selectors';
import { listUsageData } from '../../actions/vnext/usage/usage-action-creators';
import { listPriceData } from '../../actions/vnext/price/price-action-creators';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
export function* getLabPlan(action) {
    const { labPlanId } = action;
    try {
        const resourceId = new ResourceId(labPlanId, true);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const labPlan = yield call(LabPlanProvider.getLabPlan, resourceId, accessToken, locale, language);
        yield put(getLabPlanSuccess(labPlan));
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, labPlanId, FailureOperation.GetLabPlan, err ? err.code : undefined);
        yield put(getLabPlanError(error));
    }
}
export function* selectLabPlan(action) {
    const { labPlanId } = action;
    yield put(getLabPlanAction(labPlanId));
    const { error: getlabPlanError } = yield race({
        success: take(LabPlanActionType.GET_LAB_PLAN_SUCCESS),
        error: take(LabPlanActionType.GET_LAB_PLAN_ERROR),
    });
    if (!getlabPlanError) {
        const labPlans = yield select(getLabPlans);
        const labPlan = labPlans.find((o) => caseInsensitiveCultureInvariantCompare(o.id, labPlanId) === 0);
        const resourceId = new ResourceId(labPlanId, true);
        yield put(listImages(labPlanId));
        if (!!labPlan?.allowedRegions) {
            yield put(listUsageData(resourceId.subscriptionId, labPlan?.allowedRegions));
            yield put(listPriceData(resourceId.subscriptionId, labPlan?.allowedRegions));
        }
    }
}
export function* getLabPlanSaga() {
    yield takeLatest(LabPlanActionType.GET_LAB_PLAN, getLabPlan);
}
export function* selectLabPlanSaga() {
    yield takeLatest(LabPlanActionType.SELECT_LAB_PLAN, selectLabPlan);
}
