import { call, cancel, fork, put, race, select, take, delay, cancelled } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import LabProvider from '../../../data/providers/lab-provider';
import VNextLabProvider from '../../../data/providers/vnext/arm-api/lab-provider';
import { getLanguage, getLocale } from '../../../redux/selectors/common-selectors';
import { ResourceId } from '../../../utils/resource-id';
import { getLabCancelled, getLabError, getLabMetadata, getLabSuccess, getVNextLabSuccess, publishVNextLabError, syncLabUsersError, } from '../../actions/lab/lab-action-creators';
import { LabActionType } from '../../actions/lab/lab-actions';
import { getArmAccessTokenSaga, getLabsApiAccessTokenSaga } from '../identity/access-tokens';
import { listUsers } from '../../actions/user/user-action-creators';
import { isLabUserSyncFailed, isLabUserSyncSucceeded, isLabUserSyncing, } from '../../../common/selectors/lab-latest-operation';
import { getCurrentLab } from '../../selectors/lab-selectors';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { pollLab } from './poll-non-terminal-state-lab';
import { LabServicesModels } from 'lab-services';
import { listEnvironments } from '../../actions/environment/environment-action-creators';
import { OperationsResultActionType, } from '../../../redux/actions/operations/operations-result-actions';
import { getLabOperations } from '../../../redux/selectors/vnext/operations-selector';
import { FiveSecondInterval } from '../../../utils/constants';
import { OperationStatus } from '../../../data/models/vnext/operation';
import { publishOperationResultSuccess, publishOperationResultCancelled, operationsResultError, } from '../../actions/operations/operations-result-action-creators';
export function* getLab(action) {
    const { labId, includeRoles } = action;
    try {
        const oldLab = yield select(getCurrentLab);
        const resourceId = new ResourceId(labId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { lab, changed } = yield race({
            lab: call(LabProvider.getLab, resourceId, accessToken, locale, language, includeRoles),
            // TODO: Add other actions that should cancel update here
            changed: race([
                take(LabActionType.SELECT_LAB_PARENT_RESOURCE),
                take(LabActionType.SELECT_LAB),
                take(LabActionType.POLL_LAB_STOP),
                take(LabActionType.POLL_LAB_CANCELLED),
                take(LabActionType.UPDATE_LAB),
                take(LabActionType.UPDATE_LAB_SUCCESS),
                take(LabActionType.LIST_LABS),
                take(LabActionType.LIST_LABS_SUCCESS),
            ]),
        });
        if (changed) {
            yield put(getLabCancelled());
        }
        else {
            yield put(getLabSuccess(lab));
            const newLab = lab;
            if (!!oldLab && isLabUserSyncing(oldLab)) {
                if (isLabUserSyncFailed(newLab)) {
                    const syncUsersError = new MlClientError(newLab.latestOperationResult?.errorMessage, labId, FailureOperation.SyncLabUsers, newLab.latestOperationResult?.errorCode);
                    yield put(syncLabUsersError(labId, syncUsersError));
                }
                else if (isLabUserSyncSucceeded(newLab)) {
                    yield put(listUsers(labId));
                }
            }
        }
    }
    catch (e) {
        const error = new MlClientError(e?.message, labId, FailureOperation.GetLab, e?.code);
        yield put(getLabError(error));
    }
}
export function* getVNextLab(action) {
    const { labId, includeRoles, includeMetadata } = action;
    try {
        const resourceId = new ResourceId(labId, true);
        const oldLab = yield select(getCurrentLab);
        const armAccessToken = yield call(getArmAccessTokenSaga);
        const labsApiAccessToken = yield call(getLabsApiAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { lab, changed } = yield race({
            lab: call(VNextLabProvider.getLab, resourceId, armAccessToken, labsApiAccessToken, locale, language, includeRoles),
            changed: race([
                take(LabActionType.SELECT_LAB_PARENT_RESOURCE),
                take(LabActionType.SELECT_LAB),
                take(LabActionType.POLL_LAB_STOP),
                take(LabActionType.POLL_LAB_CANCELLED),
                take(LabActionType.UPDATE_LAB),
                take(LabActionType.UPDATE_LAB_SUCCESS),
                take(LabActionType.LIST_LABS),
                take(LabActionType.LIST_LABS_SUCCESS),
            ]),
        });
        if (changed) {
            yield put(getLabCancelled());
        }
        else {
            yield put(getVNextLabSuccess(lab));
            const newLab = lab;
            if (oldLab?.state === LabServicesModels.LabState.Syncing &&
                newLab.state !== LabServicesModels.LabState.Syncing) {
                yield put(listUsers(labId));
            }
            if (oldLab?.provisioningState === LabServicesModels.ProvisioningState.Creating &&
                newLab.provisioningState === LabServicesModels.ProvisioningState.Succeeded) {
                yield put(listEnvironments(labId));
            }
        }
        if (includeMetadata) {
            yield put(getLabMetadata(labId));
        }
    }
    catch (e) {
        const error = new MlClientError(e?.message, labId, FailureOperation.GetLab, e?.code);
        yield put(getLabError(error));
    }
}
export function* pollLabSaga() {
    // while this looks like an infinite loop, yield take(POLL_LAB_START)
    // will pause this code until polling is started
    while (true) {
        const action = yield take(LabActionType.POLL_LAB_START);
        // forking so the polling task runs without blocking
        const pollTask = yield fork(pollLab, action);
        // waits until the stop is triggered so we can cancel the polling task
        yield race([
            take(LabActionType.SELECT_LAB_PARENT_RESOURCE),
            take(LabActionType.SELECT_LAB),
            take(LabActionType.POLL_LAB_STOP),
            take(LabActionType.SELECT_TENANT),
        ]);
        yield cancel(pollTask);
    }
}
export function* getLabSaga() {
    yield labParentBasedTakeLatest(LabActionType.GET_LAB, getLab, getVNextLab);
}
export async function callGetOperationResult(operation, accessToken, locale, language) {
    try {
        const operationResult = await VNextLabProvider.getOperationResult(operation, accessToken, locale, language);
        return operationResult;
    }
    catch (e) {
        throw e;
    }
}
export function* getOperationResult(action) {
    const { labId } = action;
    const accessToken = yield call(getArmAccessTokenSaga);
    const locale = yield select(getLocale);
    const language = yield select(getLanguage);
    const pollingInterval = FiveSecondInterval;
    try {
        while (true) {
            yield delay(pollingInterval);
            const operations = yield select(getLabOperations);
            const operation = operations.get(labId);
            if (operation.status == OperationStatus.InProgress) {
                const { operationResult, cancel } = yield race({
                    operationResult: call(callGetOperationResult, operation, accessToken, locale, language),
                    cancel: take(OperationsResultActionType.POLL_PUBLISH_OPERATION_RESULT_CANCELLED),
                });
                if (operationResult) {
                    yield put(publishOperationResultSuccess(operationResult));
                    if (operationResult.status == OperationStatus.Failed) {
                        const error = new MlClientError(undefined, labId, FailureOperation.PublishTemplate, operationResult.error ? operationResult.error.code : undefined);
                        yield put(publishVNextLabError(labId, error));
                    }
                }
                if (cancel) {
                    yield put(publishOperationResultCancelled());
                }
            }
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labId, FailureOperation.GetOperationsResultFailure, e ? e.code : undefined);
        yield put(operationsResultError(error));
    }
    finally {
        if (yield cancelled()) {
            yield put(publishOperationResultCancelled());
        }
    }
}
export function* pollLabOperationSaga() {
    while (true) {
        const action = yield take(OperationsResultActionType.POLL_PUBLISH_OPERATION_RESULT);
        const pollOperationsTask = yield fork(getOperationResult, action);
        yield take(OperationsResultActionType.POLL_PUBLISH_OPERATION_RESULT_CANCELLED);
        yield cancel(pollOperationsTask);
    }
}
