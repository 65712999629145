import { Spinner, SpinnerSize, Stack } from '@fluentui/react';
import * as React from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import commonMessages from '../language/common-messages';
import { getCustomTheme } from './themes/selectors';
export const LoadingContainer = (props) => {
    const { styles } = props;
    const { formatMessage: msg } = useIntl();
    const { transitionBackgroundColor } = getCustomTheme();
    const stackStyles = _.merge({}, { root: { backgroundColor: transitionBackgroundColor } }, styles);
    return (<Stack grow verticalFill verticalAlign="center" styles={stackStyles}>
            <Stack.Item>
                <Spinner size={SpinnerSize.large} label={msg(commonMessages.loadingText)}/>
            </Stack.Item>
        </Stack>);
};
export default LoadingContainer;
