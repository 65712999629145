import { all, fork } from 'redux-saga/effects';
import { listEnvironmentsSaga, pollEnvironmentsSaga, pollOperationsSaga } from './list-environments';
import { resetEnvironmentsSaga } from './reset-environments';
import { startEnvironmentsSaga } from './start-environments';
import { stopEnvironmentsSaga } from './stop-environments';
import { getEnvironmentSaga, pollVirtualMachineSaga } from './get-environment';
import { redeployVirtualMachinesSaga } from './redeploy-virtual-machines';
export function* rootSaga() {
    yield all([
        fork(listEnvironmentsSaga),
        fork(pollEnvironmentsSaga),
        fork(startEnvironmentsSaga),
        fork(stopEnvironmentsSaga),
        fork(resetEnvironmentsSaga),
        fork(getEnvironmentSaga),
        fork(pollVirtualMachineSaga),
        fork(redeployVirtualMachinesSaga),
        fork(pollOperationsSaga),
    ]);
}
