import { CryptoUtils } from 'msal/lib-es6/utils/CryptoUtils';
import { WindowUtils } from 'msal/lib-es6/utils/WindowUtils';
import { AuthorityFactory } from 'msal/lib-es6/authority/AuthorityFactory';
import { Base64 } from 'js-base64';
import Settings from '../../settings/settings';
import { trackTrace } from '../../utils/telemetry/telemetry-channel';
import { isMsalPopup } from './msal-provider';
const base64Encode = (input) => {
    const result = Base64.encode(input);
    return result;
};
const base64Decode = (input) => {
    const result = Base64.decode(input);
    return result;
};
// Shim the decode and encode. Their implementations are buggy.
CryptoUtils.base64Encode = base64Encode;
CryptoUtils.base64Decode = base64Decode;
export const CryptoUtilsShim = {
    base64Encode,
    base64Decode,
};
// HACK: This fixes a bug where MSAL is detecting that we are in a popup
// when we are opened as a link from the Azure Portal or Teams. Also we will
// allow rendering our app in Teams MFA flow.
WindowUtils.isInPopup = isMsalPopup;
// The MSAL library makes a lot of calls to get OIDC data that we can assume is structured as below
// for our application and these calls fail more often than we'd like. Shimming these defaults to
// work around the issue for now when it fails.
// NOTE: We'll need to keep an eye on this as we update MSAL
export const saveMetadataFromNetwork = async (authorityInstance, telemetryManager, correlationId) => {
    let metadata;
    try {
        metadata = await authorityInstance.resolveEndpointsAsync(telemetryManager, correlationId);
    }
    catch (err) {
        metadata = {
            AuthorizationEndpoint: `${authorityInstance.CanonicalAuthority}oauth2/v2.0/authorize`,
            EndSessionEndpoint: `${authorityInstance.CanonicalAuthority}oauth2/v2.0/logout`,
            Issuer: authorityInstance.CanonicalAuthority.indexOf('/organizations/') > -1
                ? `${Settings.AadAuthority}/{tenantId}/v2.0`
                : `${authorityInstance.CanonicalAuthority}v2.0`,
        };
        trackTrace(`MsalShim: Failed to get OIDC metadata. Constructing metadata: ${JSON.stringify(metadata)}`);
    }
    AuthorityFactory.metadataMap.set(authorityInstance.CanonicalAuthority, metadata);
    authorityInstance.tenantDiscoveryResponse = metadata;
    return metadata;
};
AuthorityFactory.saveMetadataFromNetwork = saveMetadataFromNetwork;
export const AuthorityFactoryShim = {
    saveMetadataFromNetwork,
};
