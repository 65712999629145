import * as React from 'react';
import { connect } from 'react-redux';
import { Welcome } from './welcome';
import { getWelcomeContainerModel } from './welcome-selectors';
import { clearSelectedLabParentResource } from '../redux/actions/lab-parent-resource/lab-parent-resource-action-creators';
class WelcomeContainerInjected extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { welcomeViewModel, clearSelectedLabParentResource } = this.props;
        return <Welcome welcomeViewModel={welcomeViewModel} clearSelectedLabParent={clearSelectedLabParentResource}/>;
    }
}
const mapStateToProps = (state) => {
    return getWelcomeContainerModel(state);
};
const mapDispatchToProps = {
    clearSelectedLabParentResource,
};
export const WelcomeContainer = connect(mapStateToProps, mapDispatchToProps)(WelcomeContainerInjected);
export default WelcomeContainer;
