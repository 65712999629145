import { call, put, takeLatest, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { ResourceId } from '../../../utils/resource-id';
import { SharedImageActionType } from '../../actions/shared-image/shared-image-actions';
import { getSharedGallerySuccess, getSharedGalleryError, } from '../../actions/shared-image/shared-image-action-creators';
import SharedImageProvider from '../../../data/providers/shared-image-provider';
import { getLocale, getLanguage } from '../../selectors/common-selectors';
export function* getSharedGallery(action) {
    const { labAccountId } = action;
    try {
        const resourceId = new ResourceId(labAccountId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const sharedGallery = yield call(SharedImageProvider.getSharedGallery, resourceId, accessToken, locale, language);
        yield put(getSharedGallerySuccess(sharedGallery));
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labAccountId, FailureOperation.GetSharedGallery, e ? e.code : undefined);
        yield put(getSharedGalleryError(error));
    }
}
export function* getSharedGallerySaga() {
    yield takeLatest(SharedImageActionType.GET_SHARED_GALLERY, getSharedGallery);
}
