import * as React from 'react';
import { Dialog, Label, Text, DialogFooter, PrimaryButton, DefaultButton, DialogType } from '@fluentui/react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import PasswordField from '../password-field/password-field';
import commonMessages from '../../language/common-messages';
import Constants from '../../utils/constants';
import './reset-password-dialog.css';
const messages = defineMessages({
    setPasswordDialogTitleAndButtonText: {
        id: 'SetPasswordDialogTitle',
        defaultMessage: 'Set password',
        description: 'The title for the password dialog when the user has never set a password before. This text is also used for the confirmation button as well.',
    },
    setPasswordDialogContent: {
        id: 'SetPasswordDialogContent',
        defaultMessage: 'Enter a new password to be used when logging in. Setting the password may take several minutes.',
        description: 'The content of the password dialog when a user has never set a password before.',
    },
    resetPasswordDialogContent: {
        id: 'ResetPasswordDialogContent',
        defaultMessage: 'Enter a new password to be used when logging in. Resetting the password may take several minutes.',
        description: 'The content of the password dialog when a user has set a password before.',
    },
});
const ResetPasswordDialogInjected = (props) => {
    const [password, updatePassword] = React.useState('');
    const [isValidPassword, updateIsValidPassword] = React.useState(false);
    const { canShowNonLocalizedMessages, isWindows, isResetting, id, username, onDismiss, onDismissed, onSubmit, } = props;
    const setPasswordDialogTitleAndButtonText = props.intl.formatMessage(messages.setPasswordDialogTitleAndButtonText);
    const setPasswordDialogContent = props.intl.formatMessage(messages.setPasswordDialogContent);
    const resetPasswordDialogTitleAndButtonText = props.intl.formatMessage(commonMessages.resetPassword);
    const resetPasswordDialogContent = props.intl.formatMessage(messages.resetPasswordDialogContent);
    const cancelButtonLabel = props.intl.formatMessage(commonMessages.cancel);
    const title = isResetting ? resetPasswordDialogTitleAndButtonText : setPasswordDialogTitleAndButtonText;
    const subText = isResetting ? resetPasswordDialogContent : setPasswordDialogContent;
    const onChange = (_event, newValue) => {
        updatePassword(newValue || '');
    };
    const dismiss = () => {
        onDismiss();
    };
    const dismissed = () => {
        updatePassword('');
        onDismissed();
    };
    return (<Dialog hidden={false} onDismiss={dismiss} dialogContentProps={{
        type: DialogType.normal,
        title,
        subText,
        styles: {
            subText: { fontSize: '14px', fontWeight: 'normal', lineHeight: '20px' },
        },
    }} modalProps={{
        onDismissed: dismissed,
        containerClassName: 'reset-password-dialog',
    }} minWidth={Constants.DefaultDialogSmallWidth}>
            <div>
                <Label htmlFor="reset-password-username-text" styles={{
        root: {
            fontSize: '14px',
            lineHeight: '19px',
            paddingBottom: '6px',
        },
    }}>
                    <FormattedMessage id="UsernameFieldLabel" defaultMessage="Username" description="The label for the username field."/>
                </Label>
                <Text id="reset-password-username-text">{username}</Text>
            </div>
            <div className="reset-password-dialog-password-field-container">
                <PasswordField name="password" styles={{
        suffix: { marginTop: '1px', marginBottom: '1px', backgroundColor: 'transparent' },
        errorMessage: { paddingTop: '0px' },
    }} {...(isWindows ? Constants.WindowsPasswordLength : Constants.LinuxPasswordLength)} onChange={onChange} onValidationChange={updateIsValidPassword} isValid={isValidPassword} validateOnLoad={false} autoFocus={true} value={password}/>
            </div>
            {canShowNonLocalizedMessages && (<div className="reset-password-dialog-password-complexity-requirements">
                    <FormattedMessage id="VirtualMachinePasswordComplexityRequirements" defaultMessage="Password must include 3 of the following: a number, uppercase character, lowercase character, and a special character." description="Guidelines on the required complexity of a password"/>
                </div>)}
            <DialogFooter styles={{ actions: { marginTop: '58px' } }}>
                <PrimaryButton onClick={() => {
        id && username && onSubmit(id, username, password);
        onDismiss();
    }} text={title} ariaLabel={title} disabled={!isValidPassword}/>
                <DefaultButton onClick={dismiss} ariaLabel={cancelButtonLabel} text={cancelButtonLabel}/>
            </DialogFooter>
        </Dialog>);
};
export const ResetPasswordDialog = injectIntl(ResetPasswordDialogInjected);
export default ResetPasswordDialog;
