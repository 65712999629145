import { Map } from 'immutable';
export const clearErrorsByOperation = (errorMap, op) => {
    let newErrorMap = Map();
    for (const [envId, errors] of errorMap) {
        const newErrors = errors.filter((error) => {
            return error.failureOperation != op;
        });
        if (!newErrors.isEmpty()) {
            newErrorMap = newErrorMap.set(envId, newErrors);
        }
    }
    return newErrorMap;
};
