import { configureTab } from '../../../data/providers/teams-provider';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { TeamsActionType } from '../../actions/teams/teams-actions';
import { teamsConfigureTabSuccess, teamsConfigureTabError } from '../../actions/teams/teams-action-creators';
import { isVNextFeatureEnabled } from '../../selectors/common-selectors';
export function* teamsConfigureTab(action) {
    const { labAccountId } = action;
    const shouldAddVNextFeatureWQueryParam = yield select(isVNextFeatureEnabled);
    try {
        yield call(configureTab, labAccountId, shouldAddVNextFeatureWQueryParam);
        yield put(teamsConfigureTabSuccess());
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, labAccountId, FailureOperation.TeamsConfigureTab, err ? err.code : undefined);
        yield put(teamsConfigureTabError(error));
    }
}
export function* configureTabSaga() {
    yield takeLatest(TeamsActionType.TEAMS_CONFIGURE_TAB, teamsConfigureTab);
}
