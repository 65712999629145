import { getMlClient } from './arm-data-provider';
import { ResourceId } from '../../utils/resource-id';
import { compareByName } from '../../utils/common';
export async function listUsers(labId, accessToken, locale, language) {
    const mlClient = getMlClient(labId.subscriptionId, accessToken, locale, language);
    const users = await mlClient.users.list(labId.resourceGroupName, labId.parent.name, labId.name);
    return users.sort(compareByName);
}
export async function deleteUser(userId, accessToken, locale, language) {
    const mlClient = getMlClient(userId.subscriptionId, accessToken, locale, language);
    await mlClient.users.beginDeleteMethod(userId.resourceGroupName, userId.parent.parent.name, userId.parent.name, userId.name);
}
export async function updateUser(user, accessToken, locale, language) {
    const userResourceId = new ResourceId(user.id);
    const labResourceId = userResourceId.parent;
    const labAccountResourceId = labResourceId.parent;
    const mlClient = getMlClient(userResourceId.subscriptionId, accessToken, locale, language);
    const updatedUser = await mlClient.users.createOrUpdate(userResourceId.resourceGroupName, labAccountResourceId.name, labResourceId.name, userResourceId.name, user);
    return updatedUser;
}
const UserProvider = {
    listUsers,
    deleteUser,
    updateUser,
};
export default UserProvider;
