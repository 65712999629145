import { cancel, fork, race, take, takeEvery, takeLatest, takeLeading, cancelled, select, } from 'redux-saga/effects';
import { CommonActionType } from '../actions/common/common-actions';
import { trackException } from '../../utils/telemetry/telemetry-channel';
import { isCurrentLabParentLabAccount } from '../selectors/lab-parent-resource-selectors';
export function cancelableHelper(labAccountWorker, successPattern, errorPattern, cancelPattern, resourceGroupWorker) {
    return function* (action) {
        const isLabAccount = yield select(isCurrentLabParentLabAccount);
        let worker = labAccountWorker;
        if (!isLabAccount && !!resourceGroupWorker) {
            worker = resourceGroupWorker;
        }
        let task;
        try {
            task = yield fork(worker, action);
            const { cancelledAction } = yield race({
                success: take(successPattern),
                error: take(errorPattern),
                cancelledAction: take(cancelPattern),
            });
            if (cancelledAction) {
                yield cancel(task);
                task = null;
            }
        }
        catch (err) {
            trackException(err);
            if (task) {
                yield cancel(task);
                task = null;
            }
        }
        finally {
            if (yield cancelled()) {
                if (task) {
                    yield cancel(task);
                    task = null;
                }
            }
        }
    };
}
export function cancelableTakeLatest(pattern, worker, successPattern, errorPattern, cancelPattern = CommonActionType.FATAL_ERROR) {
    const helper = cancelableHelper(worker, successPattern, errorPattern, cancelPattern);
    return takeLatest(pattern, helper);
}
export function cancelableTakeLeading(pattern, worker, successPattern, errorPattern, cancelPattern = CommonActionType.FATAL_ERROR) {
    const helper = cancelableHelper(worker, successPattern, errorPattern, cancelPattern);
    return takeLeading(pattern, helper);
}
export function cancelableTakeEvery(pattern, worker, successPattern, errorPattern, cancelPattern = CommonActionType.FATAL_ERROR) {
    const helper = cancelableHelper(worker, successPattern, errorPattern, cancelPattern);
    return takeEvery(pattern, helper);
}
export function labParentBasedCancelableTakeLeading(pattern, labAccountWorker, resourceGroupWorker, successPattern, errorPattern, cancelPattern = CommonActionType.FATAL_ERROR) {
    const helper = cancelableHelper(labAccountWorker, successPattern, errorPattern, cancelPattern, resourceGroupWorker);
    return takeLeading(pattern, helper);
}
