export const Feature = {
    Dev: 'dev',
    Bastion: 'bastion',
    LabListIntroCheckbox: 'lablistintrocheckbox',
    SkipTemplate: 'skiptemplate',
    ReadOnly: 'readonly',
    TeamsIntegration: 'teamsintegration',
    VNext: 'vnext',
    CanvasIntegration: 'canvasintegration',
};
export default Feature;
