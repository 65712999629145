import { call, put, race, select, take, delay, cancelled, fork, cancel } from 'redux-saga/effects';
import ErrorCode from '../../../common/error-codes';
import MlClientError, { FailureOperation } from '../../../data/ml-client-error';
import { listLabs } from '../../../data/providers/lab-provider';
import { ResourceId } from '../../../utils/resource-id';
import { fatalError } from '../../actions/common/common-action-creators';
import { listLabs as listLabsActionCreator, listLabsError, listLabsSuccess, pollLabListCancelled, listLabsCancelled, loadSubscriptionVNextLabsError, listVNextLabsSuccess, } from '../../actions/lab/lab-action-creators';
import { LabActionType, } from '../../actions/lab/lab-actions';
import { getLanguage, getLocale, isLmsHost } from '../../selectors/common-selectors';
import { getArmAccessTokenSaga } from '../identity/access-tokens';
import { IdentityErrorCode } from '../../../data/models/identity';
import { isRouteTeamsConfig } from '../../selectors/route-selector';
import { labParentBasedTakeLatest } from '../../effects/lab-parent-based';
import { LabParentResourceActionType } from '../../actions/lab-parent-resource/lab-parent-resource-actions';
import LabProvider from '../../../data/providers/vnext/arm-api/lab-provider';
import { caseInsensitiveCultureInvariantCompare } from '../../../utils/string-comparison';
import { getLtiContextId } from '../../selectors/lti-selectors';
export function* listLabsForLabAccount(action) {
    const { labParentId } = action;
    try {
        const resourceId = new ResourceId(labParentId);
        const accessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const { labs, changed } = yield race({
            labs: call(listLabs, resourceId, accessToken, locale, language),
            changed: race([
                take(LabActionType.SELECT_LAB_PARENT_RESOURCE),
                take(LabActionType.POLL_LAB_LIST_STOP),
                take(LabActionType.POLL_LAB_LIST_CANCELLED),
            ]),
        });
        if (changed) {
            yield put(listLabsCancelled());
        }
        else {
            yield put(listLabsSuccess(labs));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labParentId, FailureOperation.LoadLabs, e ? e.code : undefined);
        yield put(listLabsError(error));
    }
}
export function* listLabsOnLabParentChange(action) {
    const { resourceId } = action;
    const isTeamsConfigPage = yield select(isRouteTeamsConfig);
    if (!isTeamsConfigPage) {
        yield put(listLabsActionCreator(resourceId));
        const { error: errorAction } = yield race({
            success: take(LabActionType.LIST_LABS_SUCCESS),
            error: take(LabActionType.LIST_LABS_ERROR),
        });
        if (errorAction) {
            const { error } = errorAction;
            switch (error.code) {
                case IdentityErrorCode.AccessDenied:
                case ErrorCode.AuthorizationFailed:
                    yield put(fatalError(ErrorCode.LabAccountAuthorizationFailed, error));
                    break;
                default:
                    break;
            }
        }
    }
}
function* pollLabList(action) {
    try {
        const { initialDelayMs, labParentId } = action;
        if (!!initialDelayMs) {
            yield delay(initialDelayMs);
        }
        while (true) {
            yield put(listLabsActionCreator(labParentId));
            yield race({
                success: take(LabActionType.LIST_LABS_SUCCESS),
                error: take(LabActionType.LIST_LABS_ERROR),
                cancelled: take(LabActionType.LIST_LABS_CANCELLED),
            });
            const { intervalMs } = action;
            yield delay(intervalMs);
        }
    }
    finally {
        if (yield cancelled()) {
            yield put(pollLabListCancelled());
        }
    }
}
export function* pollLabListSaga() {
    while (true) {
        const action = yield take(LabActionType.POLL_LAB_LIST_START);
        const pollLabListTask = yield fork(pollLabList, action);
        yield take(LabActionType.POLL_LAB_LIST_STOP);
        yield cancel(pollLabListTask);
    }
}
export function* loadSubscriptionVNextLabs(subscription) {
    let labs = [];
    const armAccessToken = yield call(getArmAccessTokenSaga);
    const locale = yield select(getLocale);
    const language = yield select(getLanguage);
    try {
        labs = yield call(LabProvider.listSubscriptionLabs, subscription, armAccessToken, locale, language);
    }
    catch (err) {
        const error = new MlClientError(err ? err.message : undefined, subscription.subscriptionId, FailureOperation.ListVNextLabsForSubscription, err ? err.code : undefined);
        yield put(loadSubscriptionVNextLabsError(subscription, error));
    }
    return labs;
}
export function* listResourceGroupLabs(action) {
    const { labParentId } = action;
    try {
        const resourceId = new ResourceId(labParentId);
        const armAccessToken = yield call(getArmAccessTokenSaga);
        const locale = yield select(getLocale);
        const language = yield select(getLanguage);
        const isLms = yield select(isLmsHost);
        // eslint-disable-next-line prefer-const
        let { labs, changed } = yield race({
            labs: call(LabProvider.listResourceGroupLabs, resourceId, armAccessToken, locale, language),
            changed: race([
                take(LabActionType.SELECT_LAB_PARENT_RESOURCE),
                take(LabActionType.POLL_LAB_LIST_STOP),
                take(LabActionType.POLL_LAB_LIST_CANCELLED),
            ]),
        });
        if (isLms) {
            const ltiContextId = yield select(getLtiContextId);
            labs = labs.filter((o) => !!o.rosterProfile?.ltiContextId &&
                caseInsensitiveCultureInvariantCompare(o.rosterProfile?.ltiContextId, ltiContextId) === 0);
        }
        if (changed) {
            yield put(listLabsCancelled());
        }
        else {
            yield put(listVNextLabsSuccess(labs, labParentId));
        }
    }
    catch (e) {
        const error = new MlClientError(e ? e.message : undefined, labParentId, FailureOperation.LoadLabs, e ? e.code : undefined);
        yield put(listLabsError(error));
    }
}
export function* listLabsSaga() {
    yield labParentBasedTakeLatest(LabActionType.LIST_LABS, listLabsForLabAccount, listResourceGroupLabs);
}
export function* listLabsOnLabParentChangeSaga() {
    yield labParentBasedTakeLatest(LabParentResourceActionType.SELECT_LAB_PARENT_RESOURCE, listLabsOnLabParentChange, undefined);
}
