import { OperationsResultActionType, } from './operations-result-actions';
export const GetOperationsResult = (labId) => ({
    type: OperationsResultActionType.POLL_OPERATIONS_RESULT,
    labId,
});
export const GetPublishOperationResult = (labId) => ({
    type: OperationsResultActionType.POLL_PUBLISH_OPERATION_RESULT,
    labId,
});
export const operationsResultSuccess = (operationIds) => ({
    type: OperationsResultActionType.POLL_OPERATIONS_RESULT_SUCCESS,
    operationIds,
});
export const publishOperationResultSuccess = (operation) => ({
    type: OperationsResultActionType.POLL_PUBLISH_OPERATION_RESULT_SUCCESS,
    operation,
});
export const operationsResultCancelled = () => ({
    type: OperationsResultActionType.POLL_OPERATIONS_RESULT_CANCELLED,
});
export const publishOperationResultCancelled = () => ({
    type: OperationsResultActionType.POLL_PUBLISH_OPERATION_RESULT_CANCELLED,
});
export const operationsResultError = (error) => ({
    type: OperationsResultActionType.POLL_OPERATIONS_RESULT_ERROR,
    error,
});
export const GetUserOperationsResult = (labId) => ({
    type: OperationsResultActionType.POLL_USER_OPERATIONS_RESULT,
    labId,
});
export const userOperationsResultSuccess = (operationIds) => ({
    type: OperationsResultActionType.POLL_USER_OPERATIONS_RESULT_SUCCESS,
    operationIds,
});
export const userOperationsResultCancelled = () => ({
    type: OperationsResultActionType.POLL_USER_OPERATIONS_RESULT_CANCELLED,
});
export const userOperationsResultError = (error) => ({
    type: OperationsResultActionType.POLL_USER_OPERATIONS_RESULT_ERROR,
    error,
});
