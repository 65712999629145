import memoize from 'memoize-one';
import { Roles } from '../../data/models/roles';
import { isFeatureEnabled, storeIsLoading, storeIsUpdating } from '../../redux/selectors/common-selectors';
import { getCurrentTemplate, getTemplateCreateError } from '../../redux/selectors/template-selectors';
import { compareByName } from '../../utils/common';
import Feature from '../../utils/features';
import { ProvisioningState } from '../../utils/provisioning-state';
import { isLabUserSyncing } from '../../common/selectors/lab-latest-operation';
import { getGroupId, shouldFilterLabsByGroupId } from './group-selectors';
import { ConnectedLms } from '../../utils/connected-lms';
import { isCurrentLabParentLabAccount } from './lab-parent-resource-selectors';
import { doesVNextLabGroupIdMatch, getResourceGroupLabs, getVNextCurrentLabId, getVNextLab, getVNextLabAccessType, isVNextLabBlocked, isVNextLabLmsConnected, isVNextLabSyncing, } from './vnext/lab-selectors';
import { ManagedLabsModels as Ml } from '@azure-lab-services/ml-ts';
import { LabServicesModels } from 'lab-services';
import moment from 'moment';
import { LabAccessType } from '../../data/models/lab';
export const getVCurrentLabs = memoize((store) => {
    const labStore = store.get('labStore');
    const labs = labStore.get('labs');
    return labs;
});
function doesVCurrentLabGroupIdMatch(lab, aadGroupId) {
    return lab.aadGroupId === aadGroupId;
}
export const getVCurrentLab = memoize((store, labId) => {
    if (!labId) {
        return undefined;
    }
    const labs = getVCurrentLabs(store);
    const lab = labs.find((lab) => lab.id === labId);
    return lab;
});
// keep the original getCurrentLab from vCurrent Lab Store for now
export const getVCurrentCurrentLab = memoize((store) => {
    const currentLabId = getCurrentLabId(store);
    if (!currentLabId) {
        return undefined;
    }
    return getVCurrentLab(store, currentLabId);
});
const isVCurrentLabBlocked = (lab) => {
    return lab && lab.createdByObjectId && lab.createdByObjectId.endsWith('-blocked') ? true : false;
};
export const hasBlockedLabs = memoize((store) => {
    const labs = getLabs(store);
    return labs.findIndex((lab) => isLabBlocked(lab)) > -1;
});
const isV1GpuTemplate = (template) => {
    const size = template?.resourceSettings.vmSize;
    return size === "SmallGPUCompute" ||
        size === "MediumGPUVisualization" ||
        size === "SmallGPUVisualization";
};
export const hasOldV1Labs = memoize((store) => {
    const labs = getLabs(store);
    return labs.findIndex((lab) => isOldV1Lab(lab)) > -1;
});
export const isCurrentLabBlocked = memoize((store) => {
    const lab = getCurrentLab(store);
    return isLabBlocked(lab);
});
export const isCurrentLabOldV1Lab = memoize((store) => {
    const lab = getCurrentLab(store);
    if (isVNextLab(lab?.id)) {
        return false;
    }
    const template = getCurrentTemplate(store);
    return isOldV1GpuLab(lab, template);
});
export const isCurrentLabWriteable = memoize((store) => {
    const lab = getCurrentLab(store);
    return isWriteableLab(lab);
});
export const isCurrentLabMoving = memoize((store) => {
    const lab = getCurrentLab(store);
    return isLabMoving(lab);
});
export const isCurrentLabReadOnly = memoize((store) => {
    return (isCurrentLabBlocked(store) ||
        !isCurrentLabWriteable(store) ||
        isCurrentLabMoving(store) ||
        !!getTemplateCreateError(store));
});
export const isCurrentLabUserSyncing = memoize((store) => {
    const lab = getCurrentLab(store);
    if (!isVNextLab(lab?.id ?? '')) {
        return !!lab && isLabUserSyncing(lab);
    }
    else {
        return isVNextLabSyncing(lab);
    }
});
export const isLabGroupSynced = memoize((lab) => {
    if (!lab) {
        return false;
    }
    if (!isVNextLab(lab.id)) {
        const vCurrentLab = lab;
        return !!vCurrentLab.aadGroupId;
    }
    else {
        const vNextLab = lab;
        return !!vNextLab.rosterProfile?.activeDirectoryGroupId || !!vNextLab.rosterProfile?.ltiContextId;
    }
});
export const isLabConnectedLmsOrTeams = memoize((lab) => {
    if (!lab) {
        return false;
    }
    if (!isVNextLab(lab.id)) {
        const vCurrentLab = lab;
        return vCurrentLab.connectedLMS === ConnectedLms.Teams;
    }
    else {
        // todo: check whether vNext lab is connected to Teams
        const vNextLab = lab;
        return isVNextLabLmsConnected(vNextLab);
    }
});
export const labStoreIsLoadingOrUpdating = memoize((store) => {
    const isLabParentLabAccount = isCurrentLabParentLabAccount(store);
    let loadState;
    if (isLabParentLabAccount) {
        loadState = store.get('labStore').get('loadState');
    }
    else {
        loadState = store.get('vNextLabStore').get('loadState');
    }
    return storeIsLoading(loadState) || storeIsUpdating(loadState);
});
export function isVNextLab(id) {
    if (!id) {
        return false;
    }
    const parts = id.split('/').filter((n) => n !== '');
    return parts.length >= 8 && parts[6].toLowerCase() === 'labs';
}
export const isCurrentLabVNext = memoize((store) => {
    const currentLabId = getCurrentLabId(store);
    return isVNextLab(currentLabId);
});
export const getLabs = memoize((store) => {
    const isLabParentLabAccount = isCurrentLabParentLabAccount(store);
    if (isLabParentLabAccount) {
        return getVCurrentLabs(store);
    }
    else {
        return getResourceGroupLabs(store);
    }
});
export const getLabAccessType = memoize((store) => {
    const isLabParentLabAccount = isCurrentLabParentLabAccount(store);
    if (isLabParentLabAccount) {
        return LabAccessType.writeAccess;
    }
    else {
        return getVNextLabAccessType(store);
    }
});
export const getFilteredLabs = memoize((store) => {
    let resultLabs = getLabs(store).sort(compareByName);
    const labAccessType = getLabAccessType(store);
    const isLabParentLabAccount = isCurrentLabParentLabAccount(store);
    const isReadOnlyEnabled = isFeatureEnabled(store, Feature.ReadOnly);
    const shouldFilterByGroupId = shouldFilterLabsByGroupId(store);
    if (!isReadOnlyEnabled && labAccessType === LabAccessType.writeAccess) {
        resultLabs = isLabParentLabAccount
            ? resultLabs.filter((lab) => isWriteableLab(lab))
            : resultLabs.filter((lab) => isWriteableLab(lab));
    }
    if (shouldFilterByGroupId) {
        const aadGroupId = getGroupId(store);
        return isLabParentLabAccount
            ? resultLabs.filter((lab) => doesVCurrentLabGroupIdMatch(lab, aadGroupId))
            : resultLabs.filter((lab) => doesVNextLabGroupIdMatch(lab, aadGroupId));
    }
    else {
        return resultLabs;
    }
});
export const isWriteableLab = (lab) => {
    return lab && lab.roles.indexOf(Roles.Write) > -1 ? true : false;
};
export const isLabAssistantRole = (lab) => {
    return lab && lab.roles.indexOf(Roles.LabAssistant) > -1 ? true : false;
};
export const isLabBlocked = (lab) => {
    return !!lab && (isVNextLab(lab.id) ? isVNextLabBlocked(lab) : isVCurrentLabBlocked(lab));
};
export const isOldV1Lab = (lab) => {
    // Old here means that it was created before NC/NV deprecation.
    if (!!!lab?.createdDate) {
        return false;
    }
    return lab && lab.createdDate < new Date('2024-02-22');
};
export const isOldV1GpuLab = (lab, template) => {
    return !!lab && isOldV1Lab(lab) && !!template && isV1GpuTemplate(template);
};
export const isLabMoving = (lab) => {
    return lab && lab.provisioningState === ProvisioningState.Moving ? true : false;
};
export const isLabDeleting = (lab) => {
    return lab && lab.provisioningState === ProvisioningState.Deleting ? true : false;
};
export const isLabDeletionFailed = (lab) => {
    if (!!lab && !isVNextLab(lab.id)) {
        const vCurrentlab = lab;
        return (vCurrentlab.provisioningState === ProvisioningState.Failed &&
            vCurrentlab.latestOperationResult?.httpMethod === 'DELETE');
    }
    else {
        // missing lastest operation result in VNext now. so we cannot know whether the failed operation is DELETE.
        return false;
    }
};
export const getCurrentLabId = memoize((store) => {
    const isLabParentLabAccount = isCurrentLabParentLabAccount(store);
    if (isLabParentLabAccount) {
        const labStore = store.get('labStore');
        const currentLabId = labStore.get('currentLabId');
        return currentLabId;
    }
    else {
        return getVNextCurrentLabId(store);
    }
});
export const getCurrentLab = memoize((store) => {
    const currentLabId = getCurrentLabId(store);
    if (!currentLabId) {
        return undefined;
    }
    return isVNextLab(currentLabId) ? getVNextLab(store, currentLabId) : getVCurrentLab(store, currentLabId);
});
export const doesCurrentLabExist = memoize((store) => {
    return !!getCurrentLab(store);
});
export const getLabNames = memoize((store) => {
    const labs = getLabs(store);
    const isLabParentLabAccount = isCurrentLabParentLabAccount(store);
    return isLabParentLabAccount
        ? labs.map((o) => o.name)
        : labs.map((o) => o.name);
});
export const getLabStoreLoadState = memoize((store) => {
    const isLabParentLabAccount = isCurrentLabParentLabAccount(store);
    return isLabParentLabAccount
        ? store.get('labStore').get('loadState')
        : store.get('vNextLabStore').get('loadState');
});
export const isCreatingLab = memoize((store) => {
    const isLabParentLabAccount = isCurrentLabParentLabAccount(store);
    return isLabParentLabAccount
        ? store.get('labStore').get('isCreating')
        : store.get('vNextLabStore').get('isCreating');
});
export const getSelectableLabs = memoize((store) => {
    const labs = getFilteredLabs(store);
    const isLabParentLabAccount = isCurrentLabParentLabAccount(store);
    if (isLabParentLabAccount) {
        return labs.filter((lab) => lab.provisioningState !== ProvisioningState.Deleting);
    }
    else {
        return labs.filter((lab) => lab.provisioningState !== ProvisioningState.Deleting);
    }
});
export const getLabUsageQuota = memoize((lab) => {
    if (!lab) {
        return undefined;
    }
    if (isVNextLab(lab.id)) {
        const currentLab = lab;
        return !!currentLab.virtualMachineProfile?.usageQuota
            ? moment.duration(currentLab.virtualMachineProfile.usageQuota).asHours()
            : undefined;
    }
    else {
        return lab.usageQuota.asHours();
    }
});
export const isLabUserRestricted = memoize((lab) => {
    if (!lab) {
        return true;
    }
    if (isVNextLab(lab.id)) {
        const currentLab = lab;
        return (!currentLab.securityProfile ||
            currentLab.securityProfile.openAccess === LabServicesModels.EnableState.Disabled);
    }
    else {
        return lab.userAccessMode === Ml.LabUserAccessMode.Restricted;
    }
});
export const getLabCapacity = memoize((lab) => {
    if (!!lab) {
        const isVNext = isVNextLab(lab.id);
        return isVNext ? lab.virtualMachineProfile?.sku?.capacity : lab.maxUsersInLab;
    }
});
