import * as React from 'react';
import { FormattedMessage, injectIntl, defineMessages } from 'react-intl';
import { Icon, PrimaryButton, Link, Stack } from '@fluentui/react';
import { commonMessages } from '../language/common-messages';
import './landing-page.css';
const messages = defineMessages({
    createFreeAccountButtonText: {
        id: 'LandingPageCreateFreeAccountButtonText',
        defaultMessage: 'Create a free Azure account',
        description: 'Text for a button to create a free azure account',
    },
    setupLabPlanButtonText: {
        id: 'LandingPageSetupLabPlanButtonText',
        defaultMessage: 'Set up a lab plan',
        description: 'Text for a button to setup a lab plan',
    },
    goToMyLabsButtonText: {
        id: 'LandingPageGoToMyLabsButtonText',
        defaultMessage: 'Sign in to create a lab',
        description: 'Text for a button to sign in to create a lab',
    },
});
const SectionNumber = (numberMessage) => {
    return (<span className="ms-fontSize-xxl ms-fontWeight-bold landing-page__getting-started-section-number">
            {numberMessage}
        </span>);
};
class LandingPageInjected extends React.Component {
    render() {
        const { intl } = this.props;
        const numberOne = SectionNumber(<FormattedMessage {...commonMessages.numberOne}/>);
        const numberTwo = SectionNumber(<FormattedMessage {...commonMessages.numberTwo}/>);
        const numberThree = SectionNumber(<FormattedMessage {...commonMessages.numberThree}/>);
        const createFreeAccountButtonText = intl.formatMessage(messages.createFreeAccountButtonText);
        const setupLabPlanButtonText = intl.formatMessage(messages.setupLabPlanButtonText);
        const goToMyLabsButtonText = intl.formatMessage(messages.goToMyLabsButtonText);
        return (<div id="landing-page-container">
                <Stack id="landing-page-header-section" horizontalAlign="center">
                    <Stack.Item>
                        <h1 id="landing-page-header-title" className="ms-fontSize-su ms-fontWeight-bold">
                            <FormattedMessage id="LandingPageTitle" defaultMessage="Welcome to Azure Lab Services" description="Title displayed on the landing page"/>
                        </h1>
                    </Stack.Item>
                    <Stack.Item>
                        <div id="landing-page-header-text">
                            <FormattedMessage id="LandingPageMessage" defaultMessage="Azure Lab Services enable you to easily set up a class, run a training lab, host a hackathon, experiment, and test your proof-of-concept ideas in the cloud." description="The message displayed below the landing page title"/>
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <div id="landing-page-header-existing-user">
                            <FormattedMessage id="LandingPageExistingUserMessage" defaultMessage="Already a user?" description="Messaging asking if the user is an existing user of the product"/>
                            <Link onClick={this.props.signIn} styles={{
            root: {
                paddingLeft: '5px',
            },
        }}>
                                <FormattedMessage {...commonMessages.signInButtonText}/>
                            </Link>
                        </div>
                    </Stack.Item>
                    <Stack.Item>
                        <Icon id="landing-page-header-icon" iconName="hero-illustration"/>
                    </Stack.Item>
                </Stack>
                <Stack id="landing-page-instructions-container" grow>
                    <Stack.Item>
                        <Stack id="landing-page-instructions-section" horizontalAlign="center">
                            <Stack.Item>
                                <Stack id="landing-page-instructions-heading-section" horizontalAlign="center" tokens={{ childrenGap: '3px' }}>
                                    <Stack.Item>
                                        <div className="ms-fontSize-xl ms-fontWeight-semibold">
                                            <FormattedMessage id="LandingPageNewToLabServicesMessage" defaultMessage="Are you new to Azure Lab Services?" description="Message asking users if they are new to lab services"/>
                                        </div>
                                    </Stack.Item>
                                    <Stack.Item>
                                        <div className="ms-fontWeight-semilight ms-fontSize-l">
                                            <FormattedMessage id="LandingPageFollowTheseSteps" defaultMessage="Follow the steps below to get started." description="Text on the landing page telling users to follow steps to create a lab account"/>
                                        </div>
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item grow>
                                <Stack id="landing-page-instructions-content-section" horizontal={true} horizontalAlign="center" wrap={true}>
                                    <Stack id="landing-page-instructions-get-subscription-section" className="landing-page__instructions-item" horizontalAlign="center">
                                        <Stack.Item>
                                            <div className="ms-fontSize-l ms-fontWeight-bold landing-page__getting-started-heading-format">
                                                <FormattedMessage {...commonMessages.stepGetAnAzureSubscription} values={{ numberSpan: numberOne }}/>
                                            </div>
                                        </Stack.Item>
                                        <Stack.Item className="landing-page__instructions-icon-container">
                                            <Icon iconName="illustration-1"/>
                                        </Stack.Item>
                                        <Stack.Item className="landing-page__getting-started-summary-format" grow>
                                            <FormattedMessage id="LandingPageGetAzureSubscriptionSummary" defaultMessage="Use your personal Microsoft account (MSA) or a Work or school account to create an Azure subscription." description="Summary for the get azure subscription step"/>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <PrimaryButton text={createFreeAccountButtonText} onClick={() => {
            window.open('https://aka.ms/azlabs-getazure', '_blank');
        }}/>
                                        </Stack.Item>
                                    </Stack>
                                    <Stack id="landing-page-instructions-add-lab-plan-section" className="landing-page__instructions-item" horizontalAlign="center">
                                        <Stack.Item>
                                            <div className="ms-fontSize-l ms-fontWeight-bold landing-page__getting-started-heading-format">
                                                <FormattedMessage {...commonMessages.stepAddLabPlan} values={{ numberSpan: numberTwo }}/>
                                            </div>
                                        </Stack.Item>
                                        <Stack.Item className="landing-page__instructions-icon-container">
                                            <Icon iconName="illustration-2"/>
                                        </Stack.Item>
                                        <Stack.Item className="landing-page__getting-started-summary-format" grow>
                                            <FormattedMessage id="LandingPageAddALabPlanSummary" defaultMessage="In the Azure portal, use your Azure subscription to create a lab plan for configuring and managing your labs." description="Summary for the add lab plan step"/>
                                        </Stack.Item>
                                        <Stack.Item className="landing-page__getting-started-action-statement">
                                            <Link href="https://docs.microsoft.com/azure/lab-services/tutorial-setup-lab-plan" target="_blank">
                                                <FormattedMessage id="LandingPageAddLabPlanTutorialLinkText" defaultMessage="See a detailed tutorial here." description="A link to see a detailed tutorial for creating a lab plan."/>
                                            </Link>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <PrimaryButton text={setupLabPlanButtonText} onClick={() => {
            window.open('https://aka.ms/azlabs/new-labplan', '_blank');
        }}/>
                                        </Stack.Item>
                                    </Stack>
                                    <Stack id="landing-page-instructions-create-lab-section" className="landing-page__instructions-item" horizontalAlign="center">
                                        <Stack.Item>
                                            <div className="ms-fontSize-l ms-fontWeight-bold landing-page__getting-started-heading-format">
                                                <FormattedMessage id="LandingPageCreateYourFirstLabStepHeader" defaultMessage="{numberSpan} Create your first lab" description="Instructions for setting up a lab account where numberSpan contains the step number with markup." values={{ numberSpan: numberThree }}/>
                                            </div>
                                        </Stack.Item>
                                        <Stack.Item className="landing-page__instructions-icon-container">
                                            <Icon iconName="illustration-3"/>
                                        </Stack.Item>
                                        <Stack.Item className="landing-page__getting-started-summary-format" grow>
                                            <FormattedMessage id="LandingPageCreateYourFirstLabSummary" defaultMessage="Create a lab for your class, training, hackathon, and more!" description="Summary for the create your first lab step"/>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <PrimaryButton text={goToMyLabsButtonText} onClick={this.props.signIn}/>
                                        </Stack.Item>
                                    </Stack>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div>);
    }
}
export const LandingPage = injectIntl(LandingPageInjected);
export default LandingPage;
