import * as React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import StudentVmList from './student-vm-list';
import { messages } from './student-vm-list-messages';
import { getStudentVmListContainerModel } from './student-vm-list-selectors';
import { Page } from '../common/page/page';
import { RegistrationState } from '../data/models/register-user';
import { registerUser } from '../redux/actions/register/register-action-creators';
import { clearListUserEnvironmentError, clearRedeployUserVirtualMachineError, clearReimageUserVirtualMachineError, clearResetUserEnvironmentPasswordError, clearStartUserEnvironmentError, clearStopUserEnvironmentError, pollListUserEnvironmentsStart, pollListUserEnvironmentsStop, redeployUserVirtualMachine, reimageUserVirtualMachine, resetEnvironmentPassword, startEnvironment, stopEnvironment, } from '../redux/actions/user-environment/user-environment-action-creators';
import { FiveSecondInterval, OneMinuteInterval, ThirtySecondInterval } from '../utils/constants';
import { determinePollingInitialDelay } from '../common/vm/vm-polling-initial-delay';
function determinePollingIntervalLength(vms) {
    if (vms.findIndex((vm) => vm.isStarting || vm.isStopping) > -1) {
        return FiveSecondInterval;
    }
    else if (vms.findIndex((vm) => vm.isCreating || vm.isResettingPassword || !!vm.isRedeploying || !!vm.isReimaging) > -1) {
        return ThirtySecondInterval;
    }
    return OneMinuteInterval;
}
class StudentVmListContainerInjected extends React.Component {
    constructor(props) {
        super(props);
        this._setPollingInterval = this._setPollingInterval.bind(this);
    }
    componentDidMount() {
        const { studentVmListViewModel, groupId, registerUser, isPanelView, shouldFilterByGroupId } = this.props;
        const { vms, registrationState } = studentVmListViewModel;
        const shouldSkipRegisterationInPanel = isPanelView && !shouldFilterByGroupId;
        if (!!groupId && !shouldSkipRegisterationInPanel) {
            // When in AAD group mode (i.e. running inside Teams), run registration first.
            if (registrationState !== RegistrationState.Registering) {
                registerUser(groupId);
                return;
            }
        }
        const initialPollingIntervalMs = determinePollingIntervalLength(vms);
        this._setPollingInterval(initialPollingIntervalMs);
    }
    componentDidUpdate() {
        const { studentVmListViewModel, groupId, pollListUserEnvironmentsStop } = this.props;
        const { vms, registrationState, isVNextFeaureEnabled } = studentVmListViewModel;
        // In AAD group mode (i.e. running inside teams) we run registration first.
        if (!!groupId) {
            // Wait for registration to complete before polling.
            if (registrationState === RegistrationState.Registering) {
                return;
            }
            // Initialize polling after registration is complete.
            else if (!this._pollingIntervalMs) {
                const initialPollingIntervalMs = determinePollingIntervalLength(vms);
                this._setPollingInterval(initialPollingIntervalMs);
                return;
            }
        }
        const nextPollingIntervalMs = determinePollingIntervalLength(vms);
        if (this._pollingIntervalMs !== nextPollingIntervalMs) {
            pollListUserEnvironmentsStop();
            const initialDelayMs = determinePollingInitialDelay(vms, isVNextFeaureEnabled);
            this._setPollingInterval(nextPollingIntervalMs, initialDelayMs);
        }
    }
    componentWillUnmount() {
        this.props.pollListUserEnvironmentsStop();
    }
    render() {
        const { studentVmListViewModel, isPanelView, clearListUserEnvironmentError, startEnvironment, clearStartUserEnvironmentError, stopEnvironment, clearStopUserEnvironmentError, resetEnvironmentPassword, clearResetUserEnvironmentPasswordError, reimageUserVirtualMachine, redeployUserVirtualMachine, clearRedeployUserVirtualMachineError, clearReimageUserVirtualMachineError, } = this.props;
        const { vms, supportSections, isTeamsIntegrationEnabled, shouldHideUserMenu, isLoading, } = studentVmListViewModel;
        const props = {
            studentVmListViewModel,
            isPanelView: isPanelView ?? false,
            onClearListError: clearListUserEnvironmentError,
            onStart: startEnvironment,
            onClearStartError: clearStartUserEnvironmentError,
            onStop: stopEnvironment,
            onClearStopError: clearStopUserEnvironmentError,
            onPasswordReset: resetEnvironmentPassword,
            onClearResetPasswordError: clearResetUserEnvironmentPasswordError,
            onReimage: reimageUserVirtualMachine,
            onRedeploy: redeployUserVirtualMachine,
            onClearRedeployError: clearRedeployUserVirtualMachineError,
            onClearReimageError: clearReimageUserVirtualMachineError,
        };
        const pageTitle = this.props.intl.formatMessage(messages.virtualMachinesPageHeader);
        return isPanelView ? (<StudentVmList {...props}/>) : (<Page title={pageTitle} isScrollable={true} hideHeader={isTeamsIntegrationEnabled && vms.length === 0} headerProps={{
            hideLabParentPicker: true,
            hideLabPicker: true,
            useCurrentPathForHome: true,
            hideVmListButton: true,
            supportSections,
            isStudentVmList: true,
            hideUserMenu: shouldHideUserMenu,
            hideFeedback: isLoading,
        }}>
                <StudentVmList {...props}/>
            </Page>);
    }
    _setPollingInterval(pollingIntervalMs, initialDelayMs) {
        const { groupId, isPanelView, shouldFilterByGroupId } = this.props;
        const shouldListAllVMs = isPanelView && !shouldFilterByGroupId;
        this._pollingIntervalMs = pollingIntervalMs;
        this.props.pollListUserEnvironmentsStart(pollingIntervalMs, shouldListAllVMs ? undefined : groupId, initialDelayMs);
    }
}
const mapStateToProps = (state) => {
    return getStudentVmListContainerModel(state);
};
const mapDispatchToProps = {
    pollListUserEnvironmentsStart,
    pollListUserEnvironmentsStop,
    clearListUserEnvironmentError,
    startEnvironment,
    clearStartUserEnvironmentError,
    stopEnvironment,
    clearStopUserEnvironmentError,
    resetEnvironmentPassword,
    clearResetUserEnvironmentPasswordError,
    registerUser,
    reimageUserVirtualMachine,
    redeployUserVirtualMachine,
    clearReimageUserVirtualMachineError,
    clearRedeployUserVirtualMachineError,
};
export const StudentVmListContainer = connect(mapStateToProps, mapDispatchToProps)(injectIntl(StudentVmListContainerInjected));
export default StudentVmListContainer;
