import { Record } from 'immutable';
import { LoadingStoreState } from '../../data/models/loading-store-state';
import { PricingAndAvailabilityActionType, } from '../actions/pricing-and-availability/pricing-and-availability-actions';
import { Context } from '../action-context';
function getPricingAndAvailabilityData(state, action) {
    return state.merge({
        loadState: LoadingStoreState.Loading,
        error: undefined,
    });
}
function getPricingAndAvailabilityDataSuccess(state, action) {
    const { data } = action;
    return state.merge({
        loadState: LoadingStoreState.Loaded,
        data,
    });
}
function getPricingAndAvailabilityDataError(state, action) {
    const { error } = action;
    return state.merge({
        loadState: LoadingStoreState.LoadingFailed,
        error,
        data: {},
    });
}
function getLabPricingAndAvailabilityData(state, action) {
    return state.merge({
        labLoadState: LoadingStoreState.Loading,
        labError: undefined,
    });
}
function getLabPricingAndAvailabilityDataSuccess(state, action) {
    const { data: labData } = action;
    return state.merge({
        labLoadState: LoadingStoreState.Loaded,
        labData,
    });
}
function getLabPricingAndAvailabilityDataError(state, action) {
    const { error: labError } = action;
    return state.merge({
        labLoadState: LoadingStoreState.LoadingFailed,
        labError,
        labData: undefined,
    });
}
function clearLabData(state) {
    return state.merge({
        labLoadState: LoadingStoreState.NotStarted,
        labError: undefined,
        labData: undefined,
    });
}
const initialState = Record({
    loadState: LoadingStoreState.NotStarted,
    error: undefined,
    data: {},
    labLoadState: LoadingStoreState.NotStarted,
    labError: undefined,
    labData: undefined,
})();
export const pricingAndAvailabilityReducer = (state = initialState, action) => {
    switch (action.type) {
        case PricingAndAvailabilityActionType.CLEAR_USER_SESSION:
        case PricingAndAvailabilityActionType.SELECT_LAB_PARENT_RESOURCE:
        case PricingAndAvailabilityActionType.SELECT_TENANT:
            return state.merge(initialState);
        default:
            switch (action.context) {
                case Context.VNext:
                    return state;
                default:
                    switch (action.type) {
                        case PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA:
                            return getPricingAndAvailabilityData(state, action);
                        case PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA_SUCCESS:
                            return getPricingAndAvailabilityDataSuccess(state, action);
                        case PricingAndAvailabilityActionType.GET_PRICING_AND_AVAILABILITY_DATA_ERROR:
                            return getPricingAndAvailabilityDataError(state, action);
                        case PricingAndAvailabilityActionType.GET_LAB_PRICING_AND_AVAILABILITY_DATA:
                            return getLabPricingAndAvailabilityData(state, action);
                        case PricingAndAvailabilityActionType.GET_LAB_PRICING_AND_AVAILABILITY_DATA_SUCCESS:
                            return getLabPricingAndAvailabilityDataSuccess(state, action);
                        case PricingAndAvailabilityActionType.GET_LAB_PRICING_AND_AVAILABILITY_DATA_ERROR:
                            return getLabPricingAndAvailabilityDataError(state, action);
                        case PricingAndAvailabilityActionType.CLEAR_LAB_PRICING_AND_AVAILABILITY_ERROR:
                        case PricingAndAvailabilityActionType.SELECT_LAB:
                            return clearLabData(state);
                        default:
                            return state;
                    }
            }
    }
};
export default pricingAndAvailabilityReducer;
