import { DefaultButton, Dialog, DialogType, Stack } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { SubmitButton } from '../common/submit-button';
import commonMessages from '../language/common-messages';
import { usePrevious } from '../utils/common';
import Constants from '../utils/constants';
const messages = defineMessages({
    dismissGpuBannerDialogTitle: {
        id: 'DismissGpuBannerDialogTitle',
        defaultMessage: 'Acknowledge GPU driver is missing',
        description: 'The title of the dismiss GPU banner dialog.',
    },
    dismissGpuBannerDialogMessage: {
        id: 'DismissGpuBannerDialogMessage',
        defaultMessage: 'When you click Continue, you are acknowledging the GPU driver must be installed manually and this banner will not be shown again.',
        description: 'The message displayed in the dismiss GPU banner dialog.',
    },
});
export const DismissGpuBannerDialog = (props) => {
    const { isSubmitting, onDismiss, onSubmit } = props;
    const intl = useIntl();
    const { formatMessage: msg } = intl;
    const dialogTitle = msg(messages.dismissGpuBannerDialogTitle);
    const dialogMessage = msg(messages.dismissGpuBannerDialogMessage);
    const continueText = msg(commonMessages.continue);
    const cancel = msg(commonMessages.cancel);
    // close the dialog after we are done submitting
    const previousIsSubmitting = usePrevious(isSubmitting);
    React.useEffect(() => {
        if (previousIsSubmitting && !isSubmitting) {
            onDismiss();
        }
    }, [isSubmitting]);
    return (<Dialog hidden={false} onDismiss={isSubmitting ? undefined : onDismiss} maxWidth={Constants.DefaultDialogWidth} closeButtonAriaLabel={cancel} dialogContentProps={{
        type: DialogType.normal,
        title: dialogTitle,
        subText: dialogMessage,
    }}>
            <Stack horizontal tokens={{ childrenGap: '8px' }} styles={{ root: { width: '100%' } }} verticalAlign="end" horizontalAlign="end">
                <Stack.Item>
                    <SubmitButton isSubmitting={isSubmitting} buttonText={continueText} ariaLabel={continueText} onClick={onSubmit}/>
                </Stack.Item>
                <Stack.Item>
                    <DefaultButton autoFocus onClick={onDismiss} text={cancel} disabled={isSubmitting}/>
                </Stack.Item>
            </Stack>
        </Dialog>);
};
export default DismissGpuBannerDialog;
