import { defineMessages } from 'react-intl';
export const messages = defineMessages({
    title: {
        id: 'UserListAddUsersFlyoutTitle',
        defaultMessage: 'Add users',
        description: 'Title of the flyout dialog to add users to the lab.',
    },
    label: {
        id: 'UserListAddUsersFlyoutLabel',
        defaultMessage: 'Enter the email addresses of users who can register for the lab.',
        description: 'Label above email addresses text box on flyout dialog to add users to the lab.',
    },
    placeholder: {
        id: 'UserListAddUsersFlyoutPlaceholder',
        defaultMessage: 'Enter email addresses, one per line or separated by semicolons',
        description: 'Placeholder text inside textbox on a dialog to add users to the lab.',
    },
    validationError: {
        id: 'UserListAddUsersFlyoutValidationError',
        defaultMessage: 'The list must consist of valid email addresses, one per line or separated by semicolons.',
        description: 'Error shown to the user when list of emails has invalid format/entries.',
    },
    chooseFileCommand: {
        id: 'UserListAddUsersFlyoutChooseFileCommand',
        defaultMessage: 'Choose File',
        description: 'Name of command on add users flyout to load list of users from a CSV file.',
    },
    uploadCsvTooltip: {
        id: 'UserListAddUsersFlyoutUploadCsvTooltip',
        defaultMessage: 'Add multiple users using a CSV file. You can export a CSV file from Excel or create one in a text editor.\nThe first email address on each line will be imported. You will be able to review and edit the imported list before adding them.\nFor more information on the CSV file format see https://aka.ms/azlab-csv.',
        description: 'Tooltip on the Upload CSV command',
    },
    uploadCsvCommand: {
        id: 'UserListAddUsersFlyoutUploadCsvCommand',
        defaultMessage: 'Upload CSV',
        description: 'Name of command on users page command bar to load list of users from a CSV file.',
    },
    addByEmailCommand: {
        id: 'UserListAddUsersFlyoutAddByEmailCommand',
        defaultMessage: 'Add by email address',
        description: 'Name of command on users page command bar to invite user(s) using their email address.',
    },
    confirmDialogTitle: {
        id: 'UserListAddUsersFlyoutConfirmDialogTitle',
        defaultMessage: 'Manage lab manually',
        description: 'Title of confirmation dialog presented when user attempts to manually add user emails to the lab.',
    },
    confirmDialogMessage: {
        id: 'UserListAddUsersFlyoutConfirmDialogMessage',
        defaultMessage: `‘{count}’ users will be added to this lab. You will not be able to sync this lab from a group unless all users are deleted.`,
        description: 'Confirmation dialog message presented when user attempts to manually add user emails to the lab. {count} is inserting the number of emails added to the lab and should not be localized.',
    },
});
export default messages;
