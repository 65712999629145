import { injectIntl, FormattedMessage } from 'react-intl';
import { MessageBar, MessageBarType } from '@fluentui/react';
import * as React from 'react';
import commonMessages from '../language/common-messages';
import './dashboard.css';
const DashboardErrorBannerInjected = (props) => {
    const { templateLoadError, scheduleLoadError, usersLoadError, environmentsLoadError, clearTemplateError, clearScheduleError, clearUsersError, clearEnvironmentError, } = props;
    const closeLabel = props.intl.formatMessage(commonMessages.close);
    return (<>
            {templateLoadError && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={closeLabel} onDismiss={() => clearTemplateError()}>
                    <FormattedMessage id="DashboardTemplateLoadError" defaultMessage="There was an error loading template information, so cost estimates displayed below may not be accurate." description="Message on error banner indicating there was a problem loading the template"/>
                </MessageBar>)}
            {scheduleLoadError && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={closeLabel} onDismiss={() => clearScheduleError()}>
                    <FormattedMessage id="DashboardScheduleLoadError" defaultMessage="There was an error loading schedule information, so cost estimates displayed below may not be accurate." description="Message on error banner indicating there was a problem loading schedules"/>
                </MessageBar>)}
            {usersLoadError && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={closeLabel} onDismiss={() => clearUsersError()}>
                    <FormattedMessage id="DashboardUsersLoadError" defaultMessage="There was an error loading user information, so cost estimates displayed below may not be accurate." description="Message on error banner indicating there was a problem loading users"/>
                </MessageBar>)}
            {environmentsLoadError && (<MessageBar messageBarType={MessageBarType.error} dismissButtonAriaLabel={closeLabel} onDismiss={() => clearEnvironmentError()}>
                    <FormattedMessage id="DashboardEnvironmentsLoadError" defaultMessage="There was an error loading environment information, so cost estimates displayed below may not be accurate." description="Message on error banner indicating there was a problem loading environments"/>
                </MessageBar>)}
        </>);
};
export const DashboardErrorBanner = injectIntl(DashboardErrorBannerInjected);
