import 'js-detect-incognito-private-browsing';
import { trackTrace } from './telemetry/telemetry-channel';
let isPrivate = undefined;
const detector = new window.BrowsingModeDetector();
export async function detectInPrivate() {
    if (isPrivate === undefined) {
        const promise = new Promise((resolve, _reject) => {
            detector.do((browsingInIncognitoMode) => {
                trackTrace(`detectInPrivate: ${browsingInIncognitoMode}`);
                resolve(browsingInIncognitoMode);
            });
        });
        isPrivate = await promise;
    }
    return isPrivate;
}
