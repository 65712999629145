import { CommandButton, DefaultButton, Dropdown, Icon } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
const messages = defineMessages({
    featureMenu: {
        id: 'FeatureMenu',
        defaultMessage: 'Preview features',
        description: 'Title of a preview feature menu.',
    },
});
const FeatureMenuInjected = (props) => {
    const { intl, styles, allFeatures, enabledFeatures, enableFeature, disableFeature, resetUserSettings } = props;
    const title = intl.formatMessage(messages.featureMenu);
    const options = allFeatures.map((feature) => ({
        key: feature.toLowerCase(),
        text: feature,
    }));
    const toggleFeature = (feature) => {
        if (enabledFeatures.includes(feature)) {
            disableFeature(feature);
        }
        else {
            enableFeature(feature);
        }
    };
    const button = (<CommandButton styles={styles} title={title} ariaLabel={title}>
            <Icon iconName="GiftboxOpen" styles={{ root: { fontSize: 16, padding: 16 } }}/>
        </CommandButton>);
    // fabric components do not support immutables
    const selectedKeys = enabledFeatures.toArray();
    return (<Dropdown styles={{
        title: { padding: 0, border: 0, height: 'unset' },
        caretDown: { display: 'none' },
    }} dropdownWidth={200} multiSelect={true} options={options} selectedKeys={selectedKeys} onChange={(_, option) => toggleFeature(option.key)} onRenderList={(renderProps, defaultRender) => {
        return (<>
                        {defaultRender && defaultRender(renderProps)}
                        
                        <DefaultButton text="Reset User Settings" onClick={resetUserSettings}/>
                    </>);
    }} onRenderTitle={() => button} onRenderPlaceholder={() => button}/>);
};
export const FeatureMenu = injectIntl(FeatureMenuInjected);
export default FeatureMenu;
