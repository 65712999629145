import { getMlClient } from './arm-data-provider';
import { ResourceId } from '../../utils/resource-id';
export async function listTemplates(id, accessToken, locale, language, expand = false) {
    const mlClient = getMlClient(id.subscriptionId, accessToken, locale, language);
    const expandParams = {
        expand: 'properties($expand=resourceSettings($expand=referenceVm($expand=vmStateDetails)))',
    };
    return expand
        ? await mlClient.environmentSettings.list(id.resourceGroupName, id.parent.name, id.name, expandParams)
        : await mlClient.environmentSettings.list(id.resourceGroupName, id.parent.name, id.name);
}
export async function getTemplate(id, accessToken, locale, language, expand = false) {
    const mlClient = getMlClient(id.subscriptionId, accessToken, locale, language);
    const expandParams = {
        expand: 'properties($expand=resourceSettings($expand=referenceVm($expand=vmStateDetails)))',
    };
    return expand
        ? await mlClient.environmentSettings.get(id.resourceGroupName, id.parent.parent.name, id.parent.name, id.name, expandParams)
        : await mlClient.environmentSettings.get(id.resourceGroupName, id.parent.parent.name, id.parent.name, id.name);
}
export async function beginCreateTemplate(template, accessToken, locale, language) {
    const templateId = new ResourceId(template.id);
    const mlClient = getMlClient(templateId.subscriptionId, accessToken, locale, language);
    await mlClient.environmentSettings.beginCreateOrUpdate(templateId.resourceGroupName, templateId.parent.parent.name, templateId.parent.name, templateId.name, template);
}
export async function updateTemplate(template, accessToken, locale, language) {
    const templateId = new ResourceId(template.id);
    const mlClient = getMlClient(templateId.subscriptionId, accessToken, locale, language);
    return await mlClient.environmentSettings.createOrUpdate(templateId.resourceGroupName, templateId.parent.parent.name, templateId.parent.name, templateId.name, template);
}
export async function resetPassword(id, username, password, accessToken, locale, language) {
    const templateId = new ResourceId(id);
    const mlClient = getMlClient(templateId.subscriptionId, accessToken, locale, language);
    await mlClient.environmentSettings.beginResetPassword(templateId.resourceGroupName, templateId.parent.parent.name, templateId.parent.name, templateId.name, { username: username.trim(), password: password.trim() });
}
export async function publishTemplate(id, useExistingImage, accessToken, locale, language) {
    const templateId = new ResourceId(id);
    const mlClient = getMlClient(templateId.subscriptionId, accessToken, locale, language);
    await mlClient.environmentSettings.publish(templateId.resourceGroupName, templateId.parent.parent.name, templateId.parent.name, templateId.name, { useExistingImage });
}
export async function startTemplate(id, accessToken, locale, language) {
    const templateId = new ResourceId(id);
    const mlClient = getMlClient(templateId.subscriptionId, accessToken, locale, language);
    await mlClient.environmentSettings.beginStart(templateId.resourceGroupName, templateId.parent.parent.name, templateId.parent.name, templateId.name);
}
export async function stopTemplate(id, accessToken, locale, language) {
    const templateId = new ResourceId(id);
    const mlClient = getMlClient(templateId.subscriptionId, accessToken, locale, language);
    await mlClient.environmentSettings.beginStop(templateId.resourceGroupName, templateId.parent.parent.name, templateId.parent.name, templateId.name);
}
const TemplateProvider = {
    startTemplate,
    stopTemplate,
    getTemplate,
    beginCreateTemplate,
    updateTemplate,
    listTemplates,
    resetPassword,
    publishTemplate,
};
export default TemplateProvider;
