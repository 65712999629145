export const EnvironmentState = {
    Unknown: '',
    Creating: 'creating',
    Created: 'created',
    Failed: 'failed',
};
export const PowerState = {
    Unknown: '',
    None: 'none',
    Running: 'running',
    Starting: 'starting',
    Stopping: 'stopping',
    Stopped: 'stopped',
};
export const VmState = {
    Unknown: '',
    None: 'none',
    Creating: 'creating',
    Failed: 'failed',
    Starting: 'starting',
    Stopping: 'stopping',
    Stopped: 'stopped',
    ResettingPassword: 'resetting-password',
    Running: 'running',
    Deleting: 'deleting',
    Updating: 'updating',
    Moving: 'moving',
    Reimaging: 'reimaging',
    Redeploying: 'redeploying',
};
export const OperatingSystem = {
    Unknown: '',
    Windows: 'Windows',
    Linux: 'Linux',
};
export const VmAssignmentType = {
    Assigned: 'assigned',
    Unassigned: 'unassigned',
    Unknown: '',
};
export const ConnectionType = {
    Unknown: '',
    Rdp: 'RDP',
    Ssh: 'SSH',
    RdpInBrowser: 'RdpInBrowser',
    SshInBrowser: 'SshInBrowser',
};
