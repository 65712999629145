export const ProvisioningState = {
    None: 'None',
    Succeeded: 'Succeeded',
    Updating: 'Updating',
    Failed: 'Failed',
    Creating: 'Creating',
    Deleting: 'Deleting',
    Moving: 'Moving',
};
function getStateValue(state) {
    return state && (state.provisioningState || state);
}
export function isTerminalState(state) {
    switch (getStateValue(state)) {
        case ProvisioningState.Succeeded:
        case ProvisioningState.Failed:
            return true;
        default:
            return false;
    }
}
export function isHealthyState(state) {
    switch (getStateValue(state)) {
        case ProvisioningState.Creating:
        case ProvisioningState.Succeeded:
        case ProvisioningState.Updating:
            return true;
        default:
            return false;
    }
}
