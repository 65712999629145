import { ImageActionType } from './image-actions';
export const listImages = (labPlanId, listEnabledOnly = true) => ({
    type: ImageActionType.LIST_IMAGES,
    labPlanId,
    listEnabledOnly,
});
export const listImagesSuccess = (images) => ({
    type: ImageActionType.LIST_IMAGES_SUCCESS,
    images,
});
export const listImagesError = (error) => ({
    type: ImageActionType.LIST_IMAGES_ERROR,
    error,
});
export const createSharedImageAccepted = (labPlanId, image) => ({
    type: ImageActionType.CREATE_IMAGE_ACCEPTED,
    labPlanId,
    image,
});
const actions = {
    listImages,
    listImagesSuccess,
    listImagesError,
    createSharedImageAccepted,
};
export default actions;
