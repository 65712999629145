export const EnvironmentType = {
    LOCAL: 'LOCAL',
    DEV: 'DEV',
    INSIDERS: 'INSIDERS',
    PROD: 'PROD',
};
export const hostNames = {
    LOCAL: 'localhost',
    LOCAL_TLS: 'local.dev.labs.azure.com',
    DEV: 'dev.labs.azure.com',
    DEV2: 'dev2.labs.azure.com',
    INSIDERS: 'insiders.labs.azure.com',
    INSIDERS2: 'insiders2.labs.azure.com',
    PROD: 'labs.azure.com',
    PROD_REACT: 'r.labs.azure.com',
};
function getEnvironmentType() {
    const hostname = window.location.hostname.toLowerCase();
    switch (hostname) {
        case hostNames.LOCAL:
        case hostNames.LOCAL_TLS:
            return EnvironmentType.LOCAL;
        case hostNames.DEV:
        case hostNames.DEV2:
            return EnvironmentType.DEV;
        case hostNames.INSIDERS:
        case hostNames.INSIDERS2:
            return EnvironmentType.INSIDERS;
        case hostNames.PROD:
        case hostNames.PROD_REACT:
        default:
            return EnvironmentType.PROD;
    }
}
export const environmentType = getEnvironmentType();
