import { EnvironmentActionType, } from './environment-actions';
export const listEnvironments = (id) => ({
    type: EnvironmentActionType.LIST_ENVIRONMENTS,
    // if it is vCurrent, the id indicates the template id. if it is a vNext lab, the id is the current lab id.
    id,
});
export const listEnvironmentsCancelled = () => ({
    type: EnvironmentActionType.LIST_ENVIRONMENTS_CANCELLED,
});
export const listEnvironmentsSuccess = (environments) => ({
    type: EnvironmentActionType.LIST_ENVIRONMENTS_SUCCESS,
    environments,
});
export const listEnvironmentsError = (error) => ({
    type: EnvironmentActionType.LIST_ENVIRONMENTS_ERROR,
    error,
});
export const startEnvironments = (environmentIds) => ({
    type: EnvironmentActionType.START_ENVIRONMENTS,
    environmentIds,
});
export const startEnvironmentsAccepted = (operationIds) => ({
    type: EnvironmentActionType.START_ENVIRONMENTS_ACCEPTED,
    operationIds,
});
export const startEnvironmentsError = (errors) => ({
    type: EnvironmentActionType.START_ENVIRONMENTS_ERROR,
    errors,
});
export const stopEnvironments = (environmentIds) => ({
    type: EnvironmentActionType.STOP_ENVIRONMENTS,
    environmentIds,
});
export const stopEnvironmentsAccepted = (operationIds) => ({
    type: EnvironmentActionType.STOP_ENVIRONMENTS_ACCEPTED,
    operationIds,
});
export const stopEnvironmentsError = (errors) => ({
    type: EnvironmentActionType.STOP_ENVIRONMENTS_ERROR,
    errors,
});
export const resetEnvironments = (environmentIds) => ({
    type: EnvironmentActionType.RESET_ENVIRONMENTS,
    environmentIds,
});
export const resetEnvironmentsError = (errors) => ({
    type: EnvironmentActionType.RESET_ENVIRONMENTS_ERROR,
    errors,
});
export const resetEnvironmentAccepted = (operationIds) => ({
    type: EnvironmentActionType.RESET_ENVIRONMENTS_ACCEPTED,
    operationIds,
});
export const clearGetError = (id) => ({
    type: EnvironmentActionType.CLEAR_VIRTUAL_MACHINE_GET_ERROR,
    id,
});
export const getEnvironment = (id, includeNetwork = false) => ({
    type: EnvironmentActionType.GET_ENVIRONMENT,
    id,
    includeNetwork,
});
export const getEnvironmentSuccess = (environment) => ({
    type: EnvironmentActionType.GET_ENVIRONMENT_SUCCESS,
    environment,
});
export const getEnvironmentCancelled = (id) => ({
    type: EnvironmentActionType.GET_ENVIRONMENT_CANCELLED,
    id,
});
export const getEnvironmentError = (id, error) => ({
    type: EnvironmentActionType.GET_ENVIRONMENT_ERROR,
    id,
    error,
});
export const clearLoadError = () => ({
    type: EnvironmentActionType.CLEAR_ENVIRONMENTS_LOAD_ERROR,
});
export const clearStartError = (id) => ({
    type: EnvironmentActionType.CLEAR_ENVIRONMENTS_START_ERROR,
    id,
});
export const clearStopError = (id) => ({
    type: EnvironmentActionType.CLEAR_ENVIRONMENTS_STOP_ERROR,
    id,
});
export const clearResetError = (id) => ({
    type: EnvironmentActionType.CLEAR_ENVIRONMENTS_RESET_ERROR,
    id,
});
export const pollEnvironmentsStart = (vmParentId, // vCurrent: templateId, vNext: labId
intervalMs, initialDelayMs) => ({
    type: EnvironmentActionType.POLL_ENVIRONMENTS_START,
    vmParentId,
    intervalMs,
    initialDelayMs,
});
export const pollEnvironmentsStop = () => ({
    type: EnvironmentActionType.POLL_ENVIRONMENTS_STOP,
});
export const pollEnvironmentsCancelled = () => ({
    type: EnvironmentActionType.POLL_ENVIRONMENTS_CANCELLED,
});
export const listVNextVirtualMachinesSuccess = (virtualMachines) => ({
    type: EnvironmentActionType.LIST_ENVIRONMENTS_SUCCESS,
    virtualMachines,
});
export const getVNextVirtualMachineSuccess = (virtualMachine) => ({
    type: EnvironmentActionType.GET_ENVIRONMENT_SUCCESS,
    virtualMachine,
});
export const clearStartErrors = (ids) => ({
    type: EnvironmentActionType.CLEAR_ENVIRONMENTS_START_ERROR,
    ids,
});
export const clearStopErrors = (ids) => ({
    type: EnvironmentActionType.CLEAR_ENVIRONMENTS_STOP_ERROR,
    ids,
});
export const pollVirtualMachineStart = (id, intervalMs, initialDelayMs) => ({
    type: EnvironmentActionType.POLL_VIRTUAL_MACHINE_START,
    id,
    intervalMs,
    initialDelayMs,
});
export const pollVirtualMachineStop = () => ({
    type: EnvironmentActionType.POLL_VIRTUAL_MACHINE_STOP,
});
export const pollVirtualMachineCancelled = () => ({
    type: EnvironmentActionType.POLL_VIRTUAL_MACHINE_CANCELLED,
});
export const clearResetPasswordError = () => ({
    type: EnvironmentActionType.CLEAR_VIRTUAL_MACHINE_RESET_PASSWORD_ERROR,
});
export const dismissExportStartedMessageBar = () => ({
    type: EnvironmentActionType.DISMISS_EXPORT_STARTED_MESSAGE_BAR,
});
export const clearExportError = () => ({
    type: EnvironmentActionType.CLEAR_EXPORT_ERROR,
});
export const redeployVirtualMachines = (ids) => ({
    type: EnvironmentActionType.REDEPLOY_VIRTUAL_MACHINE,
    environmentIds: ids,
});
export const redeployVirtualMachinesError = (errors) => ({
    type: EnvironmentActionType.REDEPLOY_VIRTUAL_MACHINE_ERROR,
    errors,
});
export const redeployVirtualMachinesAccepted = (operationIds) => ({
    type: EnvironmentActionType.REDEPLOY_VIRTUAL_MACHINE_ACCEPTED,
    operationIds,
});
export const clearRedeployErrors = (ids) => ({
    type: EnvironmentActionType.CLEAR_VIRTUAL_MACHINE_REDEPLOY_ERROR,
    ids,
});
const actions = {
    listEnvironments,
    listEnvironmentsCancelled,
    listEnvironmentsSuccess,
    listEnvironmentsError,
    startEnvironments,
    startEnvironmentsError,
    stopEnvironments,
    stopEnvironmentsError,
    resetEnvironments,
    resetEnvironmentsError,
    getEnvironment,
    getEnvironmentCancelled,
    getEnvironmentError,
    getEnvironmentSuccess,
    clearLoadError,
    clearStartError,
    clearStopError,
    clearResetError,
    pollEnvironmentsStart,
    pollEnvironmentsStop,
    pollEnvironmentsCancelled,
    listVNextVirtualMachinesSuccess,
    getVNextVirtualMachineSuccess,
    clearGetError,
    clearStartErrors,
    clearStopErrors,
    pollVirtualMachineStart,
    pollVirtualMachineStop,
    pollVirtualMachineCancelled,
    clearResetPasswordError,
    dismissExportStartedMessageBar,
    clearExportError,
    redeployVirtualMachines,
    redeployVirtualMachinesError,
    clearRedeployErrors,
};
export default actions;
