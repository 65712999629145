import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import commonMessages from '../language/common-messages';
import { getLastGroupSyncRelativeTime } from './selectors/group-sync-time-selectors';
import InfoTip from './info-tip';
export const GroupSyncTimeInfo = (props) => {
    const { groupName, lab, locale } = props;
    return (<>
            {!!groupName && (<FormattedMessage {...commonMessages.lastUserListGroupSyncTime} values={{
        aadGroupName: () => <span style={{ fontWeight: 600 }}>{groupName}</span>,
        lastGroupSyncRelativeTime: getLastGroupSyncRelativeTime(lab, locale),
    }}/>)}
            {!groupName && (<FormattedMessage id="lastUserListGroupSyncTimeNoGroupName" defaultMessage="Last synced from a group {lastGroupSyncRelativeTime}" description="Description about last user list group sync time" values={{
        lastGroupSyncRelativeTime: getLastGroupSyncRelativeTime(lab, locale),
    }}/>)}

            <InfoTip {...props}/>
        </>);
};
export default GroupSyncTimeInfo;
