import { LabsApiClient } from 'lab-services-internal';
import { TokenCredentials } from '@azure/ms-rest-js';
import Settings from '../../../../settings/settings';
import { getAcceptLanguage } from '../../../../language/languages';
const baseUri = Settings.LabsApiUrl;
export const createLabsApiClient = (subscriptionId, accessToken, locale, language) => {
    const azureToken = new TokenCredentials(accessToken);
    const client = new LabsApiClient(azureToken, subscriptionId, {
        baseUri,
        acceptLanguage: getAcceptLanguage(locale, language),
    });
    return client;
};
