import { defineMessages } from 'react-intl';
import { FailureOperation } from '../data/ml-client-error';
import { ErrorCode } from '../common/error-codes';
import commonMessages from '../language/common-messages';
import { trackTrace } from '../utils/telemetry/telemetry-channel';
const messages = defineMessages({
    updateErrorStopMustBeAfterStart: {
        id: 'UpdateErrorErrorMustBeAfterStart',
        defaultMessage: 'Unable to update schedule, as stop time must be after start time',
        description: 'Message to indicate that update of schedule failed because end time must be after start time.',
    },
    updateScheduleGenericError: {
        id: 'UpdateScheduleGenericError',
        defaultMessage: 'Failed to update schedule',
        description: 'Generic error message displayed when we failed to update a schedule and do not have a specific error code mapped.',
    },
    deleteErrorScheduleNotFound: {
        id: 'DeleteErrorScheduleNotFound',
        defaultMessage: 'Unable to delete schedule, as it could not be found',
        description: 'Message to indicate that delete of schedule failed because it could not be found.',
    },
    deleteScheduleGenericError: {
        id: 'DeleteScheduleGenericError',
        defaultMessage: 'Failed to delete schedule',
        description: 'Generic error message displayed when we failed to delete a schedule and do not have a specific error code mapped.',
    },
    loadSchedulesGenericError: {
        id: 'LoadSchedulesGenericError',
        defaultMessage: 'Failed to load schedules',
        description: 'Generic error message displayed when we fail to load schedules and do not have a specific error code mapped.',
    },
});
export const getErrorMessage = (intl, error) => {
    switch (error.failureOperation) {
        case FailureOperation.DeleteSchedule:
            return getDeleteErrorMessage(intl, error);
        case FailureOperation.LoadSchedules:
            return getLoadErrorMessage(intl, error);
        case FailureOperation.UpdateSchedule:
            return getUpdateErrorMessage(intl, error);
        default:
            return getFallbackErrorMessage(intl, error);
    }
};
const getDeleteErrorMessage = (intl, error) => {
    switch (error.code) {
        case ErrorCode.NotFound:
        case ErrorCode.ResourceNotFound:
            return intl.formatMessage(messages.deleteErrorScheduleNotFound);
        default:
            const errorMessage = intl.formatMessage(messages.deleteScheduleGenericError);
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getLoadErrorMessage = (intl, error) => {
    const errorMessage = intl.formatMessage(messages.loadSchedulesGenericError);
    return getFallbackErrorMessage(intl, error, errorMessage);
};
const getUpdateErrorMessage = (intl, error) => {
    switch (error.code) {
        case ErrorCode.MustBeGreaterThanProperty:
            return intl.formatMessage(messages.updateErrorStopMustBeAfterStart);
        default:
            const errorMessage = intl.formatMessage(messages.updateScheduleGenericError);
            return getFallbackErrorMessage(intl, error, errorMessage);
    }
};
const getFallbackErrorMessage = (intl, error, genericMessageForOperation) => {
    // for cases where we haven't recorded a code and corresponding message in this client code yet
    trackTrace(`schedule-error-handling: getFallbackErrorMessage - Unmapped error code: ${error ? error.code : 'Unknown'}`);
    // NOTE: Not showing the raw error for now. Perhaps we should let the banner expand and enable the raw error as
    // a way of viewing details?
    return genericMessageForOperation || intl.formatMessage(commonMessages.genericErrorMessage);
};
const ScheduleErrorHandling = {
    getErrorMessage,
};
export default ScheduleErrorHandling;
