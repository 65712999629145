import { CommandBar, CommandBarButton, DirectionalHint, Icon, Stack, TooltipHost, } from '@fluentui/react';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { getLabCapacity, isVNextLab } from '../redux/selectors/lab-selectors';
import { getConnectionContextMenuItems } from '../common/selectors/vm-connect';
import SubmitButton from '../common/submit-button';
import { getCustomTheme } from '../common/themes/selectors';
import { ConnectionType } from '../data/models/environment-common';
import commonMessages from '../language/common-messages';
import { CoreQuotaStatus, determineVCurrentOrVNextLabRemainingVms, determineVCurrentQuotaStatus, } from '../redux/selectors/core-limit-selectors';
import { areNetworkDetailsLoaded, constructAndDownloadRdpFile } from './customize-dialog';
import templateMessages from './template-messages';
import { isSharedGalleryValid } from './template-selectors';
import './template.css';
const messages = defineMessages({
    stopTemplate: {
        id: 'StopTemplate',
        defaultMessage: 'Stop template',
        description: 'Text on command bar button to stop the template.',
    },
    startTemplate: {
        id: 'StartTemplate',
        defaultMessage: 'Start template',
        description: 'Text on command bar button to start the template.',
    },
    connectToTemplate: {
        id: 'ConnectToTemplate',
        defaultMessage: 'Connect to template',
        description: 'Text on command bar button to connect to the template.',
    },
    exportImageDisabledMessage: {
        id: 'ExportImageDisabledMessage',
        defaultMessage: 'There is no valid shared gallery attached to the lab account or lab plan. Contact your Lab Services administrator to enable saving images.',
        description: 'Text on disabled command bar button for exporting image explaining why it is disabled.',
    },
    reimageTemplate: {
        id: 'ReimageTemplate',
        defaultMessage: 'Reimage template',
        description: 'Text on command bar button to reimage the template.',
    }
});
const showConnectionData = (showSshConnectDialog, template, connectionType) => {
    switch (connectionType) {
        case ConnectionType.Rdp:
            constructAndDownloadRdpFile(template);
            break;
        case ConnectionType.RdpInBrowser:
            window.open(template.rdpInBrowserUrl, `rdp-${template.title}`); // eslint-disable-line security/detect-non-literal-fs-filename
            break;
        case ConnectionType.Ssh:
            showSshConnectDialog();
            break;
        case ConnectionType.SshInBrowser:
            window.open(template.sshInBrowserUrl, `ssh-${template.title}`); // eslint-disable-line security/detect-non-literal-fs-filename
            break;
        default:
            break;
    }
};
export const TemplateCommandBarInjected = (props) => {
    const { template, sharedGallery, coreQuotaData, lab, shouldDisableLabUpdate, labPlanSharedGalleryId, onStop, onStart, onOpenResetPasswordDialog, onOpenCustomizeWarningDialog, onOpenPublishDialog, onOpenExportSigDialog, onShowRdpInfoDialog, showSshConnectDialog, getTemplateWithExpand, onOpenTroubleshootDialog, onReimageTemplateDialog, isBastionEnabled, isReadOnly, isGroupSyncModeEnabled, intl, navigationItems, } = props;
    const { primaryCommandBarStyles, secondaryCommandBarStyles } = getCustomTheme();
    if (isReadOnly) {
        return (<CommandBar styles={navigationItems ? primaryCommandBarStyles : secondaryCommandBarStyles} items={[]} farItems={navigationItems}/>);
    }
    const { isSaving, isCreating, isStarting, isStopping, isResettingPassword, isScaling, isPublishing, isRunning, isUploadingImage, isRedeploying, isReimaging, isSyncing, } = template;
    const { formatMessage: msg } = intl;
    const stop = msg(messages.stopTemplate);
    const customize = msg(messages.startTemplate);
    const connect = msg(messages.connectToTemplate);
    const publish = msg(templateMessages.publishTemplate);
    const resetPassword = msg(commonMessages.resetPassword);
    const exportToSig = msg(templateMessages.exportToSharedImageGallery);
    const exportDisabledMessage = msg(messages.exportImageDisabledMessage);
    const troubleshoot = msg(commonMessages.troubleshoot);
    const reimage = msg(messages.reimageTemplate);
    const disabled = isCreating || isStarting || isStopping || isResettingPassword || isScaling || isPublishing || !!isRedeploying || isReimaging;
    const maxUsersInLab = getLabCapacity(lab);
    const isVNext = isVNextLab(lab?.id);
    const quotaStatus = !isVNext ? determineVCurrentQuotaStatus(coreQuotaData) : undefined;
    const remainingVmsInSubscription = determineVCurrentOrVNextLabRemainingVms(isVNext, template.isGpu, template.cores, coreQuotaData, getLabCapacity(lab));
    const isDisabledDueToCoreQuota = quotaStatus === CoreQuotaStatus.Restricted ||
        (!template.isPublished && quotaStatus === CoreQuotaStatus.QuotaExceeded) ||
        (!template.isPublished && remainingVmsInSubscription === 0);
    const isDisabledDueToUsersExceededRemainingVms = isGroupSyncModeEnabled &&
        maxUsersInLab !== undefined &&
        remainingVmsInSubscription !== undefined &&
        maxUsersInLab > remainingVmsInSubscription;
    const [isLoadingNetworkDetails, setIsLoadingNetworkDetails] = React.useState(false);
    const [connectionType, setConnectionType] = React.useState(ConnectionType.Rdp);
    const isGalleryValid = !isVNext ? isSharedGalleryValid(sharedGallery) : !!labPlanSharedGalleryId;
    const isTeamsIntegrationEnabled = !!navigationItems;
    React.useEffect(() => {
        if (isLoadingNetworkDetails && areNetworkDetailsLoaded(template)) {
            setIsLoadingNetworkDetails(false);
            showConnectionData(showSshConnectDialog, template, connectionType);
        }
    }, [template]);
    const handleLoadingAndShowingConnectionInfo = (connectionType) => {
        if (!isVNext && !areNetworkDetailsLoaded(template)) {
            setConnectionType(connectionType);
            setIsLoadingNetworkDetails(true);
            getTemplateWithExpand();
        }
        else {
            showConnectionData(showSshConnectDialog, template, connectionType);
        }
    };
    const startMenuItem = {
        key: 'start',
        ariaLabel: customize,
        text: customize,
        disabled,
        iconProps: { iconName: 'Play' },
        onClick: onStart,
    };
    const reimageTemplateMenuItem = {
        key: 'reimage',
        ariaLabel: reimage,
        text: reimage,
        disabled,
        iconProps: { iconName: 'Refresh' },
        onClick: onReimageTemplateDialog,
    };
    const editMenuItem = {
        key: 'customize',
        ariaLabel: customize,
        text: customize,
        disabled,
        iconProps: { iconName: 'Play' },
        onClick: onOpenCustomizeWarningDialog,
    };
    let connectMenuItem;
    if (isLoadingNetworkDetails) {
        connectMenuItem = {
            key: 'connecting',
            commandBarButtonAs: () => (<SubmitButton spinnerStyles={{ root: { marginLeft: 4, marginRight: 8 } }} styles={{ root: { height: 'inherit', paddingLeft: '4px', paddingRight: '8px' } }} isSubmitting={true} buttonText={connect}/>),
        };
    }
    else {
        const connectionTypes = template.connectionTypes.filter((type) => isBastionEnabled || (type !== ConnectionType.RdpInBrowser && type !== ConnectionType.SshInBrowser));
        const canConnect = !(isScaling || isPublishing || isReimaging);
        const isRdpConnectionInTeams = isTeamsIntegrationEnabled && template.connectionTypes.indexOf(ConnectionType.Rdp) > -1;
        connectMenuItem = {
            key: 'connect',
            ariaLabel: connect,
            text: connect,
            disabled: !canConnect,
            iconProps: { iconName: 'ThisPC' },
            onRenderMenuIcon: () => <></>,
            onClick: connectionTypes.length === 1 && !isRdpConnectionInTeams
                ? () => handleLoadingAndShowingConnectionInfo(connectionTypes[0])
                : undefined,
        };
        if (connectionTypes.length > 1 || isRdpConnectionInTeams) {
            const items = getConnectionContextMenuItems(canConnect, template, connectionTypes, (_, type) => handleLoadingAndShowingConnectionInfo(type), intl, isTeamsIntegrationEnabled);
            connectMenuItem.subMenuProps = { items, isSubMenu: true };
        }
    }
    const stopMenuItem = {
        key: 'stop',
        text: stop,
        ariaLabel: stop,
        disabled,
        iconProps: { iconName: 'Stop' },
        onClick: onStop,
    };
    const passwordMenuItem = {
        key: 'password',
        iconProps: { iconName: 'Permissions' },
        text: resetPassword,
        disabled,
        ariaLabel: resetPassword,
        onClick: onOpenResetPasswordDialog,
    };
    const separator = {
        key: 'separator',
        onRender: () => <div className="command-bar-separator"/>,
    };
    const publishMenuItem = {
        key: 'publish',
        ariaLabel: publish,
        text: publish,
        disabled: disabled ||
            isDisabledDueToCoreQuota ||
            isDisabledDueToUsersExceededRemainingVms ||
            !!shouldDisableLabUpdate,
        iconProps: { iconName: 'Upload' },
        onClick: onOpenPublishDialog,
    };
    const isExportMenuItemDisabled = isCreating ||
        isStarting ||
        isStopping ||
        isResettingPassword ||
        isScaling ||
        isReimaging ||
        isUploadingImage ||
        !isGalleryValid;
    const exportMenuItem = {
        key: 'export',
        disabled: isExportMenuItemDisabled,
        onRender: () => (<TooltipHost content={!isGalleryValid ? exportDisabledMessage : ''} directionalHint={DirectionalHint.bottomCenter} styles={{ root: { display: 'inherit' } }}>
                <CommandBarButton iconProps={{ iconName: 'Export' }} styles={{ root: { minHeight: '36px' }, label: { whiteSpace: 'nowrap' } }} text={exportToSig} disabled={isExportMenuItemDisabled} ariaLabel={exportToSig} onClick={onOpenExportSigDialog}/>
            </TooltipHost>),
    };
    const noTemplateItem = {
        key: 'noTemplate',
        onRender: () => (<Stack horizontal verticalFill verticalAlign="center" styles={{ root: { minHeight: '36px', minWidth: '250px' } }}>
                <Stack.Item>
                    <Icon iconName="Info" styles={{ root: { marginRight: 5, paddingTop: 3 } }}/>
                </Stack.Item>
                <Stack.Item>
                    <FormattedMessage id="NoTemplateMessage" defaultMessage="Template customization has been disabled for this lab." description="Message shown on various pages when template customization has been disabled for the current lab."/>
                </Stack.Item>
            </Stack>),
    };
    const items = !template.hasTemplateVm
        ? [publishMenuItem, separator, noTemplateItem]
        : isRunning
            ? [connectMenuItem, stopMenuItem, passwordMenuItem, separator, publishMenuItem, exportMenuItem]
            : isVNext
                ? [template.isPublished ? editMenuItem : startMenuItem, reimageTemplateMenuItem, separator, publishMenuItem, exportMenuItem]
                : [
                    template.isPublished ? editMenuItem : startMenuItem,
                    passwordMenuItem,
                    separator,
                    publishMenuItem,
                    exportMenuItem,
                ];
    const troubleshootMenuItem = {
        key: 'troubleshoot',
        iconProps: { iconName: 'Repair' },
        text: troubleshoot,
        disabled: !!shouldDisableLabUpdate,
        ariaLabel: troubleshoot,
        onClick: onOpenTroubleshootDialog,
    };
    const isRedeployDisabled = isSaving ||
        isCreating ||
        isStarting ||
        isStopping ||
        isResettingPassword ||
        isScaling ||
        isReimaging ||
        isUploadingImage ||
        isRedeploying ||
        isSyncing;
    if (isVNextLab(lab?.id) && !isRedeployDisabled && template.hasTemplateVm && !template.isPublishing) {
        items.push(troubleshootMenuItem);
    }
    const overflowItems = [];
    if (template.rdpAuthority) {
        overflowItems.push({
            key: 'rdpInfo',
            text: msg(commonMessages.showRdpInfoTitle),
            iconProps: { iconName: 'ClipboardList' },
            onClick: onShowRdpInfoDialog,
        });
    }
    const overflowButtonProps = {
        title: msg(commonMessages.moreActionsMenu),
    };
    return (<CommandBar styles={navigationItems ? primaryCommandBarStyles : secondaryCommandBarStyles} items={items} overflowItems={overflowItems} overflowButtonProps={overflowButtonProps} farItems={navigationItems}/>);
};
export const TemplateCommandBar = injectIntl(TemplateCommandBarInjected);
export default TemplateCommandBar;
