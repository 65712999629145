import { TenantActionType, } from './tenant-actions';
export const listTenants = () => ({
    type: TenantActionType.LIST_TENANTS,
});
export const listTenantsSuccess = (tenants) => ({
    type: TenantActionType.LIST_TENANTS_SUCCESS,
    tenants,
});
export const listTenantsError = (error) => ({
    type: TenantActionType.LIST_TENANTS_ERROR,
    error,
});
export const selectTenantError = (error) => ({
    type: TenantActionType.SELECT_TENANT_ERROR,
    error,
});
const actions = {
    listTenants,
    listTenantsSuccess,
    listTenantsError,
    selectTenantError,
};
export default actions;
