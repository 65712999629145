import { ManagedLabsClient } from '@azure-lab-services/ml-ts';
import { TokenCredentials, RestError } from 'ms-rest-js';
import Constants from '../../utils/constants';
import Settings from '../../settings/settings';
import { environmentType, EnvironmentType } from '../../utils/environment';
import { getAcceptLanguage } from '../../language/languages';
import { LabServicesClient } from 'lab-services';
import { TokenCredentials as AzureTokenCredentials } from '@azure/ms-rest-js';
let baseUri = Settings.ArmUrl;
let baseUriVNext = Settings.ArmUrlVNext;
/**
 * Performs a get against ARM
 * in most scenarios, you should use the ML Client instead.
 * @param relativeUrl relative url to the management endpoint.
 * @param tenantId tenant the token should be valid for.
 */
export const get = async (relativeUrl, accessToken, locale, language) => {
    const headers = new Headers();
    headers.append('Authorization', 'Bearer ' + accessToken);
    headers.append('Accept-Language', getAcceptLanguage(locale, language));
    const url = `${baseUri}${relativeUrl}`;
    const response = await fetch(url, {
        headers: headers,
    });
    if (response.status >= 400) {
        const errorResponse = (await response.json());
        const { Error } = errorResponse;
        throw new RestError(Error?.Message || 'A request error occurred.', Error?.Code || response.statusText);
    }
    return response;
};
/**
 * Performs a post operation against ARM.
 * in most scenarios, you should use the ML Client instead.
 * @param relativeUrl relative url to the management endpoint.
 * @param body body of the post request.
 * @param tenantId tenant the token should be valid for.
 */
export const post = async (relativeUrl, accessToken, locale, language, body) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Accept-Language', getAcceptLanguage(locale, language));
    const url = `${baseUri}${relativeUrl}`;
    const response = await fetch(url, {
        body: body,
        headers: headers,
        method: 'POST',
    });
    if (response.status >= 400) {
        const errorResponse = (await response.json());
        const { Error } = errorResponse;
        throw new RestError(Error?.Message || 'A request error occurred.', Error?.Code || response.statusText);
    }
    return response;
};
/**
 * Get a client for Managed Labs to perform operations.
 * @param subscriptionId Subscription id targeted for the operation.
 */
export const getMlClient = (subscriptionId, accessToken, locale, language) => {
    const azureToken = new TokenCredentials(accessToken);
    const client = new ManagedLabsClient(azureToken, subscriptionId, baseUri, {
        acceptLanguage: getAcceptLanguage(locale, language),
    });
    return adjustForEnvironment(client);
};
export const enableProxy = () => {
    baseUri = Settings.ArmProxyUrl;
    baseUriVNext = Settings.ArmProxyUrl;
};
export const disableProxy = () => {
    baseUri = Settings.ArmUrl;
    baseUriVNext = Settings.ArmUrlVNext;
};
const adjustForEnvironment = (client) => {
    if (client.subscriptionId === Constants.NoSubscriptionId) {
        switch (environmentType) {
            case EnvironmentType.INSIDERS: {
                client.apiVersion = '2017-12-01-beta';
                break;
            }
            case EnvironmentType.DEV: // i.e. local development
            case EnvironmentType.LOCAL: {
                client.apiVersion = '2017-12-01-preview';
                break;
            }
            default: {
                // Do nothing, default to production endpoint.
                break;
            }
        }
    }
    return client;
};
export const createVNextArmApiClient = (subscriptionId, accessToken, locale, language) => {
    const azureToken = new AzureTokenCredentials(accessToken);
    const client = new LabServicesClient(azureToken, subscriptionId, {
        baseUri: baseUriVNext,
        acceptLanguage: getAcceptLanguage(locale, language),
    });
    return client;
};
const ArmProvider = {
    get,
    post,
    getMlClient,
    enableProxy,
    disableProxy,
    createVNextArmApiClient,
};
export default ArmProvider;
