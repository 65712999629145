import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import { injectIntl } from 'react-intl';
import commonMessages from '../language/common-messages';
import Constants from '../utils/constants';
const UnsavedChangesDialogInjected = (props) => {
    const { title, message, onOk, onCancel, intl } = props;
    const msg = intl.formatMessage;
    return (<Dialog hidden={false} dialogContentProps={{ title }} maxWidth={Constants.DefaultDialogSmallWidth} onDismiss={onCancel} closeButtonAriaLabel={msg(commonMessages.cancel)}>
            {message}
            <DialogFooter styles={{ actions: { marginTop: 30 } }}>
                <PrimaryButton text={msg(commonMessages.ok)} onClick={onOk}/>
                <DefaultButton autoFocus text={msg(commonMessages.cancel)} onClick={onCancel}/>
            </DialogFooter>
        </Dialog>);
};
export const UnsavedChangesDialog = injectIntl(UnsavedChangesDialogInjected);
export default UnsavedChangesDialog;
